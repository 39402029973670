import { Button, Link, Navbar, NavRight, Popup, Sheet } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IcClose, IcPassportLarge } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

import type { RedirectToVerifyAccountModalProps } from './RedirectToVerifyAccountModal.types';

import './RedirectToVerifyAccountModal.less';

export const RedirectToVerifyAccountModal: React.FC<RedirectToVerifyAccountModalProps> = ({
    onClick,
    canClose = true,
    ...props
}) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const Modal: React.ComponentClass<Sheet.Props | Popup.Props> = isMobile ? Sheet : Popup;

    return (
        <Modal
            id="redirect-to-verify-account-modal"
            className="redirect-to-verify-account-modal"
            backdrop
            closeByBackdropClick={canClose}
            closeByOutsideClick={canClose}
            closeOnEscape={canClose}
            swipeToClose={canClose}
            {...props}
        >
            {canClose && (
                <Navbar noShadow>
                    <NavRight>
                        <Link popupClose sheetClose iconOnly>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
            )}
            <div className="redirect-to-verify-account-modal__content">
                <IcPassportLarge />
                <h1 className="redirect-to-verify-account-modal__title">{t('Verify Your Account')}</h1>
                <p className="redirect-to-verify-account-modal__text">
                    {t('To switch your account to business, please verify your account')}
                </p>
                <Button fill large round raised onClick={onClick}>
                    {t('Open Passport')}
                </Button>
            </div>
        </Modal>
    );
};

import { FacebookShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share';

import { ShareItem } from './ShareOptions.types';

import { IcFacebook, IcTelegram, IcWhatsapp } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { ShareMessengerName } from '@/types/localConfig';

const shareConfig: Partial<Record<ShareMessengerName, ShareItem | undefined>> = {
    facebook: {
        component: FacebookShareButton,
        text: 'Facebook',
        icon: IcFacebook,
    },
    whatsapp: {
        component: WhatsappShareButton,
        text: 'Whatsapp',
        icon: IcWhatsapp,
    },
    telegram: {
        component: TelegramShareButton,
        text: 'Telegram',
        icon: IcTelegram,
    },
    gem4me: {
        text: 'Gem4me Messenger',
    },
};

export const useShareOptionsConfig = () => {
    const { shareMessengers } = useAppSelector((state) => state.rootReducer.localConfig);
    return shareMessengers?.map((name) => shareConfig[name]);
};

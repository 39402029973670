import {
    FAVORITES_LOAD_ERROR,
    FAVORITES_LOAD_SUCCESS,
    FAVORITES_LOADING,
    FavoritesActions,
} from '@/actions/favoritesActions';
import { Store } from '@/generated/marketplaceapi';

export interface IFavoritesState {
    stores: Store[];
    loading: boolean;
    error: unknown;
}

const initialState: IFavoritesState = {
    stores: [],
    loading: false,
    error: undefined,
};

const favoritesReducer = (state = initialState, action: FavoritesActions): IFavoritesState => {
    switch (action.type) {
        case FAVORITES_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case FAVORITES_LOAD_SUCCESS:
            return {
                ...state,
                stores: action.stores,
                loading: false,
                error: undefined,
            };
        case FAVORITES_LOAD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
};

export default favoritesReducer;

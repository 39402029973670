import cn from 'classnames';
import React, { useCallback } from 'react';

import { ArticleItem } from './ArticleItem';

import { BigItem } from './BigItem';

import { CarouselItemCardProps } from './CarouselItemCard.types';

import { ImagesItem } from './ImagesItem';

import { SmallItem } from './SmallItem';

import { StoreItem } from './StoreItem';

import { TopItem } from './TopItem';

import { XSmallItem } from './XSmallItem';

import { CarouselType } from '@/components/Carousel';
import { ImgWithBlurredFrame } from '@/components/ImgWithBlurredFrame';
import { NotAvailableItem } from '@/components/NotAvailableItem';
import { NotAvailableItemType } from '@/components/NotAvailableItem/NotAvailableItem.types';
import { Zoomer } from '@/components/Zoomer';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getBackgroundStyle, noop } from '@/utils';

import './CarouselItemCard.less';

export const CarouselItemCard = ({
    type,
    item,
    index,
    empty,
    classificator = [],
    onClickAddToWish,
    onClick = noop,
    showFeaturesHighlight,
    videoPlayOnClick,
    showVideoPlayer,
    mobileView,
    showNotAvailableItem,
}: CarouselItemCardProps) => {
    const isMobile = useAppSelector(getIsMobile);

    const image = item?.image;

    const handleClick = useCallback(() => onClick(item, index), [index, item, onClick]);

    const showNotAvailable = showNotAvailableItem && !item;

    switch (type) {
        case CarouselType.top:
            return (
                <div
                    className={cn('carousel-item', 'carousel-item__top', 'link', {
                        'mobile-view': mobileView,
                    })}
                    style={getBackgroundStyle(image)}
                >
                    <TopItem item={item} onClick={handleClick} />
                </div>
            );

        case CarouselType.big:
            return (
                <div
                    className={cn('carousel-item', 'carousel-item__big', 'no-padding', {
                        'mobile-view': mobileView,
                        'not-available': showNotAvailable,
                    })}
                >
                    {showNotAvailable ? (
                        <NotAvailableItem type={NotAvailableItemType.PRODUCT} />
                    ) : (
                        <BigItem
                            item={item}
                            empty={empty}
                            onClickAddToWish={onClickAddToWish}
                            classificator={classificator}
                        />
                    )}
                </div>
            );

        case CarouselType.small:
        case CarouselType.smallHalfScreen:
        case CarouselType.smallDesktopView:
            return (
                <div
                    className={cn('carousel-item', 'carousel-item__small', 'no-padding', {
                        'mobile-view': mobileView,
                        'force-desktop': type === CarouselType.smallDesktopView,
                        'not-available': showNotAvailable,
                    })}
                >
                    {showNotAvailable ? (
                        <NotAvailableItem type={NotAvailableItemType.PRODUCT} textOutOfCard />
                    ) : (
                        <SmallItem
                            mobileView={mobileView}
                            item={item}
                            empty={empty}
                            onClickAddToWish={onClickAddToWish}
                            showFeaturesHighlight={showFeaturesHighlight}
                        />
                    )}
                </div>
            );

        case CarouselType.images: {
            return image ? (
                <div
                    className={cn('carousel-item', {
                        'mobile-view': mobileView,
                    })}
                    onClick={handleClick}
                >
                    <ImgWithBlurredFrame src={image} />
                </div>
            ) : (
                <div
                    className={cn('carousel-item', {
                        'mobile-view': mobileView,
                    })}
                    onClick={handleClick}
                >
                    <ImagesItem item={item} videoPlayOnClick={videoPlayOnClick} showVideoPlayer={showVideoPlayer} />
                </div>
            );
        }

        case CarouselType.imagesWithHoverZoom: {
            return image ? (
                <div
                    onClick={handleClick}
                    className={cn('carousel-item', {
                        'mobile-view': mobileView,
                    })}
                >
                    <Zoomer src={image} zoom={!isMobile}>
                        <ImgWithBlurredFrame src={image} />
                    </Zoomer>
                </div>
            ) : (
                <div
                    className={cn('carousel-item', {
                        'mobile-view': mobileView,
                    })}
                    onClick={handleClick}
                >
                    <ImagesItem item={item} videoPlayOnClick={videoPlayOnClick} showVideoPlayer={showVideoPlayer} />
                </div>
            );
        }

        case CarouselType.fullScreenImages: {
            return image ? (
                <div className="swiper-zoom-container">
                    <img src={image} alt="" />
                </div>
            ) : (
                <div className="carousel-item">
                    <ImagesItem item={item} videoPlayOnClick={videoPlayOnClick} showVideoPlayer={showVideoPlayer} />
                </div>
            );
        }
        case CarouselType.xsmall:
            return (
                <div
                    className={cn('carousel-item', 'carousel-item__xsmall', {
                        'not-available': showNotAvailable,
                    })}
                >
                    {showNotAvailable ? (
                        <NotAvailableItem type={NotAvailableItemType.PRODUCT} textOutOfCard />
                    ) : (
                        <XSmallItem item={item} onClickAddToWish={onClickAddToWish} />
                    )}
                </div>
            );
        case CarouselType.stores:
            return (
                <div
                    className={cn('carousel-item', 'carousel-item__store', {
                        'not-available': showNotAvailable,
                    })}
                    onClick={handleClick}
                >
                    {showNotAvailable ? (
                        <NotAvailableItem type={NotAvailableItemType.STORE} />
                    ) : (
                        <StoreItem item={item} />
                    )}
                </div>
            );
        case CarouselType.articles:
            return (
                <div onClick={handleClick} className="carousel-item carousel-item__article">
                    <ArticleItem item={item} />
                </div>
            );
        default:
            return null;
    }
};

import React from 'react';

export const VimeoPlayer = ({ videoId }: { videoId: string }) => {
    return (
        <iframe
            src={`https://player.vimeo.com/video/${videoId}`}
            loading="lazy"
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            title="Vimeo Player"
        />
    );
};

import cn from 'classnames';
import { Button, F7Popup, F7Sheet, Link, List, Navbar, NavRight, NavTitle, Popup, Sheet } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditSaleProductModalProps } from './EditSaleProductModal.types';

import { IcClose } from '@/assets';
import { CircleDoneButton } from '@/components/CircleDoneButton';
import { CustomInput } from '@/components/CustomInput';
import { useAppSelector } from '@/hooks/store';
import { useControlledInput } from '@/hooks/useControlledInput';
import { SaleProductListItem } from '@/pages/ConfigureSale/ProductsOnSale/SaleProductListItem';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import './EditSaleProductModal.less';

export const EditSaleProductModal = ({ product = {}, onConfirm, className, ...props }: EditSaleProductModalProps) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const Component = useMemo<React.ComponentClass<F7Sheet.Props | F7Popup.Props>>(
        () => (isMobile ? Sheet : Popup),
        [isMobile],
    );

    const selector = useMemo(
        () => (isMobile ? Sheets.EDIT_SALE_PRODUCT_SHEET : Popups.EDIT_SALE_PRODUCT_POPUP),
        [isMobile],
    );

    const { currencyClassificator } = useAppSelector((state) => state.classificatorReducer);
    const inputPrefix = useMemo(
        () => currencyClassificator?.find((currency) => currency.code === product.currencyCode)?.symbol,
        [currencyClassificator, product.currencyCode],
    );

    const [newPrice, setNewPrice] = useState('');

    const handleChange = useControlledInput(setNewPrice);

    const confirmDisabled = useMemo(
        () => !(Number(newPrice) && Number(newPrice) > 0 && Number(newPrice) < product.price),
        [product.price, newPrice],
    );

    const handleConfirm = useCallback(
        () => !confirmDisabled && onConfirm({ ...product, salePrice: Number(newPrice) }),
        [confirmDisabled, onConfirm, product, newPrice],
    );

    useEffect(() => setNewPrice(`${product.salePrice}`), [product.salePrice]);

    return (
        <Component
            {...props}
            id={selector}
            backdrop
            swipeToClose={false}
            className={cn(selector, 'edit-sale-product-modal', className)}
        >
            <Navbar noShadow noHairline>
                <NavTitle>{t('Edit Amount Discounted')}</NavTitle>
                <NavRight>
                    <Link iconOnly popupClose sheetClose>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <List mediaList>
                <SaleProductListItem item={product} />
                <CustomInput
                    startPrefix={inputPrefix}
                    inputmode="decimal"
                    type="number"
                    min={0}
                    onChange={handleChange}
                    value={newPrice}
                    label={t('New Price With Discount')}
                />
                {!isMobile && (
                    <Button
                        className="edit-sale-product-modal__confirm"
                        popupClose
                        fill
                        round
                        onClick={handleConfirm}
                        disabled={confirmDisabled}
                    >
                        {t('Confirm')}
                    </Button>
                )}

                {isMobile && (
                    <CircleDoneButton
                        className={cn('edit-sale-product-modal__confirm-mobile')}
                        onClick={handleConfirm}
                        disabled={confirmDisabled}
                        sheetClose
                    />
                )}
            </List>
        </Component>
    );
};

import React, { useCallback } from 'react';

export const useControlledInput = (
    setState: React.Dispatch<React.SetStateAction<string>>,
    additionalHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void,
) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setState(e.target.value);
            additionalHandler?.(e);
        },
        [additionalHandler, setState],
    );

    return handleChange;
};

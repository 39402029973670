import cn from 'classnames';
import { Block, BlockTitle, Button } from 'framework7-react';
import React from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FileFieldProps } from './FileField.types';

import { IcDelete, IcDocumentStroke } from '@/assets';

import './FileField.less';

export const FileField: React.FC<FileFieldProps> = ({ name, handleClear, disabled, acceptParams }) => {
    const {
        input: { value, onChange, ...input },
        meta,
    } = useField<File | undefined>(name);

    const { t } = useTranslation();
    return (
        <Block className="file-area-wrapper">
            <div
                className={cn('file-area', {
                    empty: !value,
                })}
            >
                <IcDocumentStroke />
                {value ? (
                    <>
                        <div className="file-area-labels">
                            <BlockTitle>{value?.name}</BlockTitle>
                            {value?.size && <p className="block-description">{(value.size / 1024).toFixed(3)} Kb</p>}
                        </div>
                        <Button className="file-area-clear" onClick={handleClear}>
                            <IcDelete />
                        </Button>
                    </>
                ) : (
                    <>
                        <BlockTitle>{t('Add File*')}</BlockTitle>

                        <input
                            {...input}
                            accept={acceptParams}
                            type="file"
                            disabled={disabled}
                            onChange={({ target }) => onChange(target.files?.[0])}
                        />
                    </>
                )}
            </div>
            {meta.error && meta.pristine && meta.touched && <span className="error-message">{meta.error}</span>}
        </Block>
    );
};

import cn from 'classnames';
import { Block, BlockTitle, Icon, Link, List, ListItem, Navbar, NavRight, Popup } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AboutPopupProps } from './AboutPopup.types';

import { messengerService } from '@/api/messengerService';
import { IcClose, IcLogoPath } from '@/assets';
import { ExternalLink } from '@/components/ExternalLink';
import { useAppSelector } from '@/hooks/store';
import { LogoType } from '@/types/localConfig';
import { Popups } from '@/types/popups';

import './AboutPopup.less';

const pjson = require('../../../package.json');
const appName = process.env.APP_NAME;
const appSiteLink = process.env.APP_SITE_LINK;
const appSiteTitle = process.env.APP_SITE_TITLE;
const companyName = process.env.COMPANY_NAME;

export const AboutPopup = ({ onContactSupportClick, className, ...props }: AboutPopupProps) => {
    const { t } = useTranslation();

    const externalLinks = useAppSelector((state) => state.rootReducer.localConfig.aboutUsExternalLinks);
    const logoType = useAppSelector((state) => state.rootReducer.localConfig.logoType);

    const currentYear = new Date().getFullYear();

    const openAppSite = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        messengerService.openExternalLink(appSiteLink);
    }, []);

    return (
        <Popup {...props} id={Popups.ABOUT_POPUP} className={cn(Popups.ABOUT_POPUP, 'about-popup', className)}>
            <Navbar noShadow noHairline className={cn('about-popup__navbar')}>
                <NavRight>
                    <Link popupClose>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <div className="about-popup-content">
                <Block className="text-align-center">
                    <Block>
                        <img src={IcLogoPath} alt="Logo" className="logo" />
                    </Block>
                    {logoType !== LogoType.WITHOUT_TEXT && (
                        <BlockTitle large className="name">
                            {appName}
                        </BlockTitle>
                    )}
                    <p className="version">
                        {t('Version')} {pjson.version}
                    </p>
                </Block>
                <List noHairlines>
                    {externalLinks?.map((link) => (
                        <ExternalLink
                            key={link.name}
                            link={link}
                            renderLink={(props) => (
                                <ListItem {...props} popupClose link title={t(link.title || link.name)}>
                                    {link.icon && <Icon slot="media" f7={link.icon} />}
                                </ListItem>
                            )}
                        />
                    ))}
                    <ListItem
                        link="#"
                        title={t('Contact Support').toString()}
                        onClick={onContactSupportClick}
                        popupClose
                    >
                        <Icon slot="media" f7="headphones" />
                    </ListItem>

                    <ListItem link="#" title={appSiteTitle} onClick={openAppSite}>
                        <Icon slot="media" f7="arrow_up_right_circle" />
                    </ListItem>
                    {messengerService.enabled && process.env.DEBUG_ENABLED === 'true' && (
                        <ListItem link="/debug" title={'Debug'}>
                            <Icon slot="media" f7="stop_circle" />
                        </ListItem>
                    )}
                </List>
                <Block className="copyright">
                    &copy; {currentYear} {companyName}
                </Block>
            </div>
        </Popup>
    );
};

import { Block, BlockTitle } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomDetailsProps } from './CustomDetails.types';

export const CustomDetails = React.memo<CustomDetailsProps>(({ product }) => {
    const { t } = useTranslation();

    const notes = useMemo(
        () =>
            Object.entries(product).reduce(
                (accum, [key, value]) => (key.match(/^customField|Notes/g) ? (accum.push(value), accum) : accum),
                [],
            ),
        [product],
    );

    return notes.length ? (
        <>
            <BlockTitle>{t('More details')}</BlockTitle>
            <Block>
                {notes.map((note, i) => (
                    <span key={i.toString()}>
                        {note}
                        {i < notes.length - 1 && ', '}
                    </span>
                ))}
            </Block>
        </>
    ) : null;
});
CustomDetails.displayName = 'CustomDetails';

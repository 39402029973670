import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIcon } from '@/types/icons';

export const IcOnboardingMessageHomepage: IcIcon = React.memo((props) => {
    const gradUid1 = useNodeUid();
    const gradUid2 = useNodeUid();
    const gradUid3 = useNodeUid();
    const clipPathUid = useNodeUid();

    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath={`url(#${clipPathUid})`}>
                <path
                    d="M24.009 48C37.2494 48 47.9828 37.2548 47.9828 24C47.9828 10.7452 37.2494 0 24.009 0C10.7686 0 0.0351562 10.7452 0.0351562 24C0.0351562 37.2548 10.7686 48 24.009 48Z"
                    fill={`url(${gradUid1})`}
                />
                <path
                    d="M18.1683 30.5226C16.3716 21.5296 11.5993 14.3914 4.91807 9.44531C0.0896158 15.7966 -1.48244 24.4523 1.54938 32.4336C5.08651 41.7076 13.6767 47.553 22.9406 47.9465C20.5825 42.3259 19.1227 36.1432 18.1683 30.5226Z"
                    fill={`url(${gradUid2})`}
                />
                <path
                    d="M29.9541 0.785156C30.684 1.68445 31.3577 2.58375 31.9753 3.53926C39.8917 15.6236 33.3228 29.2817 32.7052 42.3215C32.5929 43.6142 32.5929 44.9632 32.8175 46.3121C45.0009 41.5346 51.0646 27.8203 46.4607 15.5112C43.5412 7.92333 37.1968 2.63996 29.9541 0.785156Z"
                    fill={`url(${gradUid3})`}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 16C9.34315 16 8 17.3431 8 19V51C8 52.6569 9.34315 54 11 54H37C38.6569 54 40 52.6569 40 51V19C40 17.3431 38.6569 16 37 16H11ZM14 24C12.8954 24 12 24.8954 12 26V32C12 33.1046 12.8954 34 14 34H21C22.1046 34 23 33.1046 23 32V26C23 24.8954 22.1046 24 21 24H14ZM26 24C25.4477 24 25 24.4477 25 25C25 25.5523 25.4477 26 26 26H35C35.5523 26 36 25.5523 36 25C36 24.4477 35.5523 24 35 24H26ZM25 29C25 28.4477 25.4477 28 26 28H35C35.5523 28 36 28.4477 36 29C36 29.5523 35.5523 30 35 30H26C25.4477 30 25 29.5523 25 29ZM13 37C12.4477 37 12 37.4477 12 38C12 38.5523 12.4477 39 13 39H35C35.5523 39 36 38.5523 36 38C36 37.4477 35.5523 37 35 37H13ZM12 42C12 41.4477 12.4477 41 13 41H35C35.5523 41 36 41.4477 36 42C36 42.5523 35.5523 43 35 43H13C12.4477 43 12 42.5523 12 42ZM13 45C12.4477 45 12 45.4477 12 46C12 46.5523 12.4477 47 13 47H22C22.5523 47 23 46.5523 23 46C23 45.4477 22.5523 45 22 45H13ZM25 33C25 32.4477 25.4477 32 26 32H32C32.5523 32 33 32.4477 33 33C33 33.5523 32.5523 34 32 34H26C25.4477 34 25 33.5523 25 33Z"
                    fill="white"
                />
                <path
                    d="M8 18C8 16.3431 9.34315 15 11 15H37C38.6569 15 40 16.3431 40 18V21H8V18Z"
                    fill="var(--brand-primary-10)"
                />
                <circle cx="11" cy="18" r="1" fill="var(--brand-primary-30)" />
                <circle cx="14" cy="18" r="1" fill="var(--brand-primary-30)" />
                <circle cx="17" cy="18" r="1" fill="var(--brand-primary-30)" />
            </g>
            <defs>
                <linearGradient
                    id={gradUid1}
                    x1="-13.5229"
                    y1="-9.18087"
                    x2="37.0838"
                    y2="35.466"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid2}
                    x1="-15.9401"
                    y1="19.7926"
                    x2="18.1034"
                    y2="30.3877"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid3}
                    x1="14.9348"
                    y1="7.26485"
                    x2="48.2843"
                    y2="36.6868"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <clipPath id={clipPathUid}>
                    <rect width="48" height="48" rx="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});
IcOnboardingMessageHomepage.displayName = 'IcOnboardingMessageHomepage';

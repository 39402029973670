import cn from 'classnames';
import { FormApi } from 'final-form';
import { Button, f7, Link, List, Navbar, NavLeft, NavRight, Popup } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { loginInitialValues } from './LoginPopup.constants';

import { LoginOptions, LoginPopupProps, LoginValues } from './LoginPopup.types';

import { validateLogin } from './LoginPopup.utils';

import { loginWithUsernamePassword } from '@/actions/sessionActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { IcClose } from '@/assets';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { closeLoginPopup } from '@/reducers/registrationReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Popups } from '@/types/popups';

import './LoginPopup.less';

export const LoginPopup = React.memo(
    ({
        opened,
        onRegister,
        onForgotPassword,
        className,
        doAfterLogin,
        onPopupClosed,
        ...props
    }: LoginPopupProps): JSX.Element => {
        const { t } = useTranslation();

        const isMobile = useAppSelector(getIsMobile);

        const dispatch = useAppDispatch();

        const { loading } = useAppSelector((state) => state.sessionReducer);

        const closePopup = useCallback(() => {
            f7.popup.close(`.${Popups.LOGIN_POPUP}`);
        }, []);

        const [successLogin, setSuccessLogin] = useState(false);

        const afterLoginHandler = useCallback(() => {
            if (successLogin && doAfterLogin) {
                // ? setTimeout because doAfterLogin opens EditRegionPopup before it closes
                setTimeout(doAfterLogin, 400);
            }

            setSuccessLogin(false);
        }, [doAfterLogin, successLogin]);

        const login = useCallback(
            async ({ email, password }: LoginValues, form: FormApi<LoginValues>) => {
                firebaseAnalyticsInstance.loginTry?.();
                f7.preloader.show();
                const username = email;

                const state = await dispatch(loginWithUsernamePassword(username.trim(), password.trim()));

                if (state.sessionReducer.error) {
                    let errorMessage = state.sessionReducer.error as string;

                    if (errorMessage === 'Client is not authorized') {
                        errorMessage = 'Invalid email or password';
                    }

                    f7.dialog.alert(t(errorMessage));
                    return;
                }

                if (opened && state.sessionReducer.logged) {
                    form.reset();
                    f7.preloader.hide();
                    closePopup();
                    firebaseAnalyticsInstance.loginSuccess?.(state.sessionReducer.profile);
                    setSuccessLogin(true);
                }
            },
            [closePopup, dispatch, opened, t],
        );

        const popupClosedHandler = useCallback(() => {
            onPopupClosed?.();
            afterLoginHandler();
            dispatch(closeLoginPopup());
        }, [onPopupClosed, afterLoginHandler, dispatch]);

        return (
            <Popup
                {...props}
                id={Popups.LOGIN_POPUP}
                className={cn(Popups.LOGIN_POPUP, 'login-popup', className)}
                opened={opened}
                onPopupClosed={popupClosedHandler}
            >
                <Navbar noShadow noHairline className="login-popup__navbar">
                    {isMobile && (
                        <>
                            <NavLeft>
                                <Link className="login-popup__close" iconOnly popupClose>
                                    <IcClose />
                                </Link>
                            </NavLeft>

                            <NavRight>
                                <Link className="login-popup__forgot-password" slot="list" onClick={onForgotPassword}>
                                    {t('Forgot Password?')}
                                </Link>
                            </NavRight>
                        </>
                    )}

                    {!isMobile && (
                        <NavRight>
                            <Link className="login-popup__close" iconOnly popupClose>
                                <IcClose />
                            </Link>
                        </NavRight>
                    )}
                </Navbar>

                <Form<LoginValues>
                    onSubmit={login}
                    initialValues={loginInitialValues}
                    validate={(values) => validateLogin(values, LoginOptions.BY_EMAIL)}
                >
                    {({ handleSubmit, valid }) => (
                        <div className="login-popup__content">
                            <p className="login-popup__title">{t('Login')}</p>

                            <List noHairlines form className="login-popup__list">
                                <CustomInputField
                                    name="email"
                                    label={t('LoginUsernameLabel')}
                                    type="text"
                                    clearButton
                                    disabled={loading}
                                    slot="list"
                                    requiredSign
                                />

                                <CustomInputField
                                    name="password"
                                    label={t('Password')}
                                    type="password"
                                    withPasswordToggle
                                    disabled={loading}
                                    slot="list"
                                    requiredSign
                                />

                                {!isMobile && (
                                    <Link
                                        className="login-popup__forgot-password"
                                        slot="list"
                                        onClick={onForgotPassword}
                                    >
                                        {t('Forgot Password?')}
                                    </Link>
                                )}
                            </List>

                            <Button
                                className="login-popup__login-button"
                                fill
                                large
                                round
                                raised
                                onClick={handleSubmit}
                                disabled={!valid || loading}
                            >
                                {t('Login')}
                            </Button>
                        </div>
                    )}
                </Form>

                <p className="login-popup__sign-up-text">
                    {`${t('Don’t have an account yet?')} `}
                    <Link onClick={onRegister}>{t('Sign Up')}</Link>
                </p>
            </Popup>
        );
    },
);

LoginPopup.displayName = 'LoginPopup';

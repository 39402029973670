import { Currency } from '@/generated/commonapi';
import { CartItem, DeliveryMethodOption } from '@/generated/marketplaceapi';

export const getCurrencySymbol = (currencies: Currency[], currencyCode: string) => {
    return currencies.find((c) => c.code === currencyCode)?.symbol ?? '';
};

export const getCheapestDeliveryMethod = (item: CartItem) => {
    const cheapestMethod = {
        price: Infinity,
        id: -1,
        option: undefined as DeliveryMethodOption,
    };

    const { availableDeliveryMethods } = item;
    if (availableDeliveryMethods && availableDeliveryMethods.length) {
        item.availableDeliveryMethods.forEach((method, id) => {
            method.options.forEach((option) => {
                if (option.price < cheapestMethod.price) {
                    cheapestMethod.price = option.price;
                    cheapestMethod.id = id;
                    cheapestMethod.option = option;
                }
            });
        });

        return cheapestMethod.option;
    }
    return undefined;
};

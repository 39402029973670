import dayjs from 'dayjs';

export const getStringFromDates = (val1: Date | string, val2: Date | string) => {
    const date1 = new Date(val1);
    const date2 = new Date(val2);
    const value1 = dayjs(date1).format('MMM D, yyyy');
    const value2 = dayjs(date2).format('MMM D, yyyy');

    let date = '';

    if (date1.getFullYear() === date2.getFullYear()) {
        date = value1.slice(0, value1.indexOf(',')) + ' - ' + value2;
    } else {
        date = value1 + ' ' + value2;
    }

    return date;
};

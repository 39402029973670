import {
    CATEGORY_LIST_LOADING,
    CATEGORY_LIST_LOADING_ERROR,
    CATEGORY_LIST_LOADING_SUCCESS,
    CategoryActions,
} from '@/actions/categoryActions';

export interface ICategoryClassificator {
    categoryName?: string;
    categoryCode?: string;
    productCount?: number;
    dependency?: string;
    shippingAllowed?: boolean;
    returnAllowed?: boolean;
    pickUpAllowed?: boolean;
    seoText?: string;
    iconCode?: string;
    color?: string;
    thumbnailUrl?: string;
    imageUrl?: string;
    children?: ICategoryClassificator[];
    parent?: ICategoryClassificator;
    topParent?: ICategoryClassificator;
    locizeKey?: string;
}

export interface ICategoryClassificatorState {
    isFetching: boolean;
    categories: ICategoryClassificator[];
    flat: ICategoryClassificator[];
}

const initialState: ICategoryClassificatorState = {
    isFetching: false,
    categories: [],
    flat: [],
};

const categoryReducer = (state = initialState, action: CategoryActions): ICategoryClassificatorState => {
    switch (action.type) {
        case CATEGORY_LIST_LOADING:
            return {
                ...state,
                isFetching: true,
            };
        case CATEGORY_LIST_LOADING_SUCCESS: {
            const { categories, flat = [] } = action;
            return {
                ...state,
                categories,
                flat,
                isFetching: false,
            };
        }
        case CATEGORY_LIST_LOADING_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
};

export default categoryReducer;

import { Analytics, CustomEventName, EventNameString, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

import {
    AnalyticsAddress,
    AnalyticsBanner,
    AnalyticsCartItem,
    AnalyticsProduct,
    AnalyticsStore,
} from './analitics.types';

import { Address } from '@/generated/commonapi';
import { Banner, CartItem, Store } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';

export class FirebaseAnalyticsBase<T extends string> {
    constructor(firebaseConfig: FirebaseOptions) {
        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        this.analytics = getAnalytics(app);
        getPerformance(app);
    }

    protected readonly analytics: Analytics;

    protected buildProductData(product: IProduct): AnalyticsProduct {
        return {
            productUid: product.uid,
            productName: product.name,
        };
    }

    protected buildBannerData(banner: Banner, channel: 'mobile' | 'web'): AnalyticsBanner {
        return {
            bannerUid: banner?.uid,
            placement: banner?.label ?? 'NA',
            channel: channel,
            bannerCode: banner?.code ?? 'NA',
            campaign: banner?.name ?? 'NA',
            partner: banner?.description ?? 'NA',
        };
    }

    protected buildStoreData(store: Store): AnalyticsStore {
        return {
            storeUid: store?.uid,
            storeName: store?.name,
        };
    }

    protected buildAddressData(address: Address): AnalyticsAddress {
        return {
            addressUid: address?.uid,
            address: `${
                address?.country?.name ?? address?.country?.code
            } ${address?.city} ${address?.firstAddressLine} ${address?.postalCode}`,
        };
    }

    protected buildCartItemData(cartItem: CartItem): AnalyticsCartItem {
        return {
            productUid: cartItem?.productUid,
            productName: cartItem?.productName,
        };
    }

    protected logEvent(eventName: T | EventNameString, data?: Record<string, string>): void {
        if (this.analytics) {
            logEvent(this.analytics, eventName as CustomEventName<T>, data);
        } else {
            console.error('this.analytics is not defined in firebase.ts');
        }
    }
}

import cn from 'classnames';
import { Link } from 'framework7-react';
import React from 'react';

import { SelectCustomerLocationButtonNavbarProps } from './SelectCustomerLocationButtonNavbar.types';

import { IcLocation } from '@/assets';

import './SelectCustomerLocationButtonNavbar.less';

export const SelectCustomerLocationButtonNavbar = React.memo<SelectCustomerLocationButtonNavbarProps>(
    ({ className, text, locationText, ...rest }) => (
        <Link
            className={cn('no-ripple', 'select-customer-location-button-navbar', 'header-navbar-location', className)}
            {...rest}
        >
            <IcLocation />
            <p className="header-navbar-location-info">
                <span className="header-navbar-location-info__label">{text}</span>
                <span className="header-navbar-location-info__country">{locationText}</span>
            </p>
        </Link>
    ),
);
SelectCustomerLocationButtonNavbar.displayName = 'SelectCustomerLocationButtonNavbar';

import { setUserProperties } from 'firebase/analytics';

import { AnalyticsCustomer, IFirebaseAnalyticsService } from './analitics.types';

import { FirebaseAnalyticsBase } from './firebase.base';

import { Account } from '@/generated/commonapi';
import { Address, Banner, CartItem, Store } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';
import { Profile } from '@/reducers/sessionReducer';
import { combinePhone } from '@/utils';

type FirebaseMarketspaceEventName =
    // Пользователь, который открыл MS, в том числе и те, которые первый раз зашли в MS. Т.е., в ms_open будет также все те, что указаны в ms_register
    | 'ms_open'
    // Добавление продукта в избранное
    | 'ms_add_to_wishlist'
    // Оправка ссылки на продукт кому либо (другому пользователю)
    | 'ms_share_product'
    // Переход в чат с продавцом
    | 'ms_start_chat_with_seller'
    // Открытие формы создания обьявления
    | 'ms_open_create_product'
    // Создание объявления. Т.е., из тех, кто открыл форму (ms_open_create_product) здесь те, кто закончил ввод данных и создал объявление.
    | 'ms_create_product'
    // Добавление продукта в корзину
    | 'ms_add_to_cart'
    // Добавления адреса на странице chekout
    | 'ms_add_address_in_cart'
    // Добавление платежной карты на странице checkout
    | 'ms_add_card_in_cart'
    // Инициализация оплаты
    | 'ms_attempt_to_pay'
    // Успешная опалата
    | 'ms_success_payment'
    // Не успешная оплата
    | 'ms_failed_payment'
    // Открытие формы импорта данных
    | 'ms_open_import'
    // Импорт данных сконфигурен
    | 'ms_configured_import'
    // Открытие формы перехода на юр. лицо
    | 'ms_open_create_BA'
    // Аккаунт переведен на юр. лицо
    | 'ms_created_BA_and_store'
    // Открытие формы создания страницы магазина
    | 'ms_open_create_homepage'
    // Страница магазина создана
    | 'ms_created_homepage'
    // Открытие формы создания распродажи
    | 'ms_open_create_sale'
    // Распродажа создана
    | 'ms_created_sale'
    // Открытие чата с магазином (из формы магазина)
    | 'ms_start_chat_with_store'
    // Добавление магазина в фавориты
    | 'ms_add_to_favorites'
    // Удаление продукта из списка избранных
    | 'ms_remove_from_wishlist'
    // Удаление магазина из фаворитов
    | 'ms_remove_from_favorites'
    //  Открытие формы обращения СПК
    | 'ms_open_contact_support'
    // Отправка запроса в СПК
    | 'ms_sent_req_support'
    // Отправлена ссылка на магазин
    | 'ms_share_store'
    // Продукт опубликован продавцом (не системой, а продавцом)
    | 'ms_publish_product'
    // Banner click
    | 'ms_banner_click'
    // open product details page
    | 'ms_view_item';

// !TODO: add analyticsShareProduct props to ShareMenu in AppNavbar after it's rework
export class FirebaseAnalyticsMarketspace
    extends FirebaseAnalyticsBase<FirebaseMarketspaceEventName>
    implements IFirebaseAnalyticsService
{
    setUserProperties(profile: Account) {
        setUserProperties(this.analytics, this.buildCustomerData(profile));
    }

    public bannerClick(banner: Banner, channel: 'mobile' | 'web') {
        this.logEvent('ms_banner_click', this.buildBannerData(banner, channel));
    }

    public openProductDetails(product: IProduct) {
        this.logEvent('ms_view_item', this.buildProductData(product));
    }

    public openApp() {
        this.logEvent('ms_open');
    }

    public addToWishList(product: IProduct) {
        this.logEvent('ms_add_to_wishlist', this.buildProductData(product));
    }

    public shareProduct(product: IProduct) {
        this.logEvent('ms_share_product', this.buildProductData(product));
    }

    public startChatWithSeller(sellerPhone: string) {
        this.logEvent('ms_start_chat_with_seller', { sellerPhone });
    }

    public openCreateProduct() {
        this.logEvent('ms_open_create_product');
    }

    public productCreated(product: IProduct) {
        this.logEvent('ms_create_product', this.buildProductData(product));
    }

    public addToCart(cartItem: CartItem) {
        this.logEvent('ms_add_to_cart', this.buildCartItemData(cartItem));
    }

    public addAddressOnCheckoutPage(address: Address) {
        this.logEvent('ms_add_address_in_cart', this.buildAddressData(address));
    }

    public addBankCardOnCheckoutPage() {
        this.logEvent('ms_add_card_in_cart');
    }

    public attemptToPay() {
        this.logEvent('ms_attempt_to_pay');
    }

    public paymentSuccess(paymentUid: string) {
        this.logEvent('ms_success_payment', { paymentUid });
    }

    public paymentFailed(paymentUid: string) {
        this.logEvent('ms_failed_payment', { paymentUid });
    }

    public openImportProductsPage() {
        this.logEvent('ms_open_import');
    }

    public importProductsConfigured() {
        this.logEvent('ms_configured_import');
    }

    public openCreateBusinessAccountPage() {
        this.logEvent('ms_open_create_BA');
    }

    public businessAccountAndStoreCreated() {
        this.logEvent('ms_created_BA_and_store');
    }

    public openCreateStoreHomepagePage() {
        this.logEvent('ms_open_create_homepage');
    }

    public storeHomepageCreated(storeHomepageUid: string) {
        this.logEvent('ms_created_homepage', { storeHomepageUid });
    }

    public openCreateSalePage() {
        this.logEvent('ms_open_create_sale');
    }

    public saleCreated(saleUid: string) {
        this.logEvent('ms_created_sale', { saleUid });
    }

    public startChatWithStore(store: Store) {
        this.logEvent('ms_start_chat_with_store', this.buildStoreData(store));
    }

    public addStoreToFavorites(store: Store) {
        this.logEvent('ms_add_to_favorites', this.buildStoreData(store));
    }

    public removeStoreFromFavorites(store: Store) {
        this.logEvent('ms_remove_from_favorites', this.buildStoreData(store));
    }

    public removeFromWishlist(product: IProduct) {
        this.logEvent('ms_remove_from_wishlist', this.buildProductData(product));
    }

    public openContactSupport() {
        this.logEvent('ms_open_contact_support');
    }

    public contactSupportRequestSent() {
        this.logEvent('ms_sent_req_support');
    }

    public shareStore(store: Store) {
        this.logEvent('ms_share_store', this.buildStoreData(store));
    }

    public productPublishedBySeller(product: IProduct) {
        this.logEvent('ms_publish_product', this.buildProductData(product));
    }

    private buildCustomerData(profile: Profile): AnalyticsCustomer {
        const primaryPhone = profile.accountPhones?.find(({ primary }) => primary) ?? profile.accountPhones?.[0];

        return {
            customerUid: combinePhone(primaryPhone?.countryCode, primaryPhone?.number),
            customerName: profile.person?.name + ' ' + profile.person?.surname,
        };
    }
}

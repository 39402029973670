import addBusinessAccountRoutes from '../BusinessAccount/routes';
import { ConfigureSale } from '../ConfigureSale';
import { ImportProducts } from '../ImportProducts';
import { MyGoodsPage } from '../MyGoodsPage';
import { payoutSettingsRoutes } from '../PayoutSettings/routes';
import { VerifyInformation } from '../VerifyInformation';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { openLoginPopup } from '@/reducers/registrationReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';
import { store } from '@/store/Store';
import { AccountType } from '@/types/account';
import { ProductType } from '@/types/productType';
import { EProductTypeQuery, PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getProductTypeByUrl } from '@/utils/getProductTypeByUrl';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

export const sellerAreaRoutes = [
    {
        path: '/store',
        async: (_to, _from, resolve) =>
            import('../AccountStorePage').then((rc) => resolve({ component: rc.AccountStorePage })),
        beforeEnter(to, from, resolve, reject) {
            const router = this;

            const profile = getProfile(store.getState());
            const activeProductType = getActiveProductType(store.getState());
            const isBusiness = activeProductType === ProductType.Product && profile.type === AccountType.BUSINESS;

            if (
                activeProductType &&
                !isBusiness &&
                !(activeProductType === ProductType.Service && profile.serviceProviders?.length > 0)
            ) {
                reject();
                router.navigate(
                    `/profile/seller-area/my-goods/?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(
                        activeProductType,
                    )}`,
                );
                return;
            }
            resolve();
        },
    },
    {
        path: '/my-goods',
        async: (_to, _from, resolve) => import('../MyGoodsPage').then((rc) => resolve({ component: rc.MyGoodsPage })),
        routes: [
            {
                path: '/archive',
                component: MyGoodsPage,
                options: { props: { archive: true } },
            },
            {
                path: '/add',
                options: {
                    props: {
                        isEdit: false,
                    },
                },
                beforeEnter(to, from, resolve, reject) {
                    const isLogged = isLoggedIn(store.getState());

                    if (!isLogged) {
                        store.dispatch(openLoginPopup());

                        reject();
                        return;
                    }

                    resolve();
                },
                async: (to, from, resolve) => {
                    firebaseAnalyticsInstance.openCreateProduct?.();

                    const queryTypes = [getProductTypeByUrl(to.url), getProductTypeByUrl(from.url)];

                    if (queryTypes.includes(EProductTypeQuery.OFFERS)) {
                        return import('../ProductCreate').then((rc) =>
                            resolve({ component: rc.AdvertisementCreateContainer }),
                        );
                    } else if (queryTypes.includes(EProductTypeQuery.SERVICES)) {
                        return import('../ProductCreate').then((rc) =>
                            resolve({ component: rc.ServiceCreateContainer }),
                        );
                    }

                    return import('../ProductCreate').then((rc) => resolve({ component: rc.ProductCreateContainer }));
                },
            },
            {
                path: '/edit/:productUid',
                options: {
                    props: {
                        isEdit: true,
                    },
                },
                async: (to, from, resolve) => {
                    const queryTypes = [getProductTypeByUrl(to.url), getProductTypeByUrl(from.url)];

                    if (queryTypes.includes(EProductTypeQuery.OFFERS)) {
                        return import('../ProductCreate').then((rc) =>
                            resolve({ component: rc.AdvertisementCreateContainer }),
                        );
                    } else if (queryTypes.includes(EProductTypeQuery.SERVICES)) {
                        return import('../ProductCreate').then((rc) =>
                            resolve({ component: rc.ServiceCreateContainer }),
                        );
                    }

                    return import('../ProductCreate').then((rc) => resolve({ component: rc.ProductCreateContainer }));
                },
            },
            {
                path: '/product-details/:uid',
                async: (_to, _from, resolve) =>
                    import('../MyGoodsProductDetailsPage').then((rc) =>
                        resolve({ component: rc.MyGoodsProductDetailsPage }),
                    ),
            },
            {
                path: '/product-details/:uid/promote',
                async: (_to, _from, resolve) =>
                    import('../product-promotion/promote-product').then((rc) => resolve({ component: rc.default })),
            },
            {
                path: '/import-products',
                component: ImportProducts,
                routes: [
                    {
                        path: '/edit',
                        component: ImportProducts,
                        options: { props: { isEdit: true } },
                    },
                    {
                        path: '/history',
                        async: (_to, _from, resolve) =>
                            import('../ImportProducts/ImportHistory').then((rc) =>
                                resolve({ component: rc.ImportHistory }),
                            ),
                    },
                ],
            },
        ],
    },
    {
        path: '/sale',
        async: (_to, _from, resolve) =>
            import('../SalesListPage').then((rc) => resolve({ component: rc.SalesListPage })),
        routes: [
            {
                path: '/:saleUid/edit',
                component: ConfigureSale,
                options: { props: { isEdit: true } },
            },
            {
                path: '/:saleUid/restart',
                component: ConfigureSale,
                options: { props: { isRestart: true } },
            },
            {
                path: '/add',
                component: ConfigureSale,
            },
        ],
    },
    {
        path: '/delivery-methods',
        async: (_to, _from, resolve) =>
            import('../DeliveryMethods/MyDeliveryMethods').then((rc) => resolve({ component: rc.MyDeliveryMethods })),
        routes: [
            {
                path: '/company-delivery',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/CompanyDelivery').then((rc) =>
                        resolve({ component: rc.CompanyDelivery }),
                    ),
            },
            {
                path: '/company/edit/:uid',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/CompanyDelivery').then((rc) =>
                        resolve({ component: rc.CompanyDelivery }),
                    ),
            },
            {
                path: '/new-delivery',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/NewDelivery').then((rc) => resolve({ component: rc.NewDelivery })),
            },
            {
                path: '/delivery/edit/:uid',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/NewDelivery').then((rc) => resolve({ component: rc.NewDelivery })),
            },
            {
                path: '/new-pickup',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/NewPickUp').then((rc) => resolve({ component: rc.NewPickUp })),
            },
            {
                path: '/pickup/edit/:uid',
                async: (_to, _from, resolve) =>
                    import('../DeliveryMethods/NewPickUp').then((rc) => resolve({ component: rc.NewPickUp })),
            },
        ],
    },
    {
        path: '/sellers-orders',
        async: (_to, _from, resolve) =>
            import('../SellersOrdersPage').then((rc) => resolve({ component: rc.SellersOrdersPage })),
        routes: [
            {
                path: '/order-details/:orderNumber',
                async: (_to, _from, resolve) =>
                    import('../SellersOrderDetailsPage').then((rc) =>
                        resolve({ component: rc.SellersOrderDetailsPage }),
                    ),
            },
        ],
    },
    {
        path: '/edit-region',
        async: (_to, _from, resolve) =>
            import('../EditRegionPage').then((rc) => resolve({ component: rc.EditRegionPage })),
    },
    ...payoutSettingsRoutes,
    ...addBusinessAccountRoutes,
    {
        path: '/company-information',
        async: (_to, _from, resolve) =>
            import('../CompanyInfoPage').then((rc) => resolve({ component: rc.CompanyInfoPage })),
    },
    {
        path: '/company-information/edit',
        async: (_to, _from, resolve) =>
            import('../EditCompanyInfoPage').then((rc) => resolve({ component: rc.EditCompanyInfoPage })),
    },
    {
        path: '/company-information/verified',
        component: VerifyInformation,
        options: { props: { isEdit: true } },
    },
];

import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NavbarTopMenu from './components/NavbarTopMenu';
import { NavbarTopProps } from './NavbarTop.types';

import { toggleSelectCountryPopup } from '@/actions/customerLocationActions';
import { ExternalLink } from '@/components/ExternalLink';
import { LanguageLink } from '@/components/LanguageLink/LanguageLink';
import { SelectCustomerLocationButtonNavbar } from '@/components/SelectCustomerLocationButtonNavbar';
import { useAppDispatch, useAppSelector } from '@/hooks/store';

import './NavbarTop.less';

export const NavbarTop = React.memo(
    ({ onChangeProductType, onContactSupportClick, className }: NavbarTopProps): JSX.Element => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        const currentCountry = useAppSelector((state) => state.customerLocationReducer.country);
        const { headerExternalLinks } = useAppSelector((state) => state.rootReducer.localConfig);

        return (
            <div className={cn('navbar-top-container', className)}>
                <div className="navbar-top-container-content">
                    <div className="navbar-top-container-content-left">
                        <NavbarTopMenu onChangeProductType={onChangeProductType} />
                        {headerExternalLinks?.map((link) => (
                            <ExternalLink
                                key={link.name}
                                link={link}
                                className="navbar-top-container-content-left-item"
                            />
                        ))}
                    </div>
                    <div className="navbar-top-container-content-right">
                        <div className="navbar-top-container-content-right-item" onClick={onContactSupportClick}>
                            <p>{t('Support')}</p>
                        </div>
                        <SelectCustomerLocationButtonNavbar
                            text={t('Deliver to').toString()}
                            locationText={currentCountry?.name}
                            onClick={() => dispatch(toggleSelectCountryPopup(true))}
                        />
                        <LanguageLink />
                    </div>
                </div>
            </div>
        );
    },
);
NavbarTop.displayName = 'NavbarTop';

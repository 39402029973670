import cn from 'classnames';
import React from 'react';

import { CircleDoneButtonProps } from './CircleDoneButton.types';

import { IcDone } from '@/assets';
import { CustomButton } from '@/components/CustomButton';

import './CircleDoneButton.less';

export const CircleDoneButton = React.forwardRef<HTMLButtonElement, CircleDoneButtonProps>(
    ({ className, children, onClick, ...props }, ref) => (
        <CustomButton
            type="button"
            {...props}
            onClick={onClick}
            className={cn('circle-done-button', className)}
            ref={ref}
        >
            {children || <IcDone />}
        </CustomButton>
    ),
);
CircleDoneButton.displayName = 'CircleDoneButton';

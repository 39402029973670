import { List, ListItem } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { useTranslation } from 'react-i18next';

import { LocationOptionProps } from './LocationOption.types';

import { IcArrowRight } from '@/assets';
import { LatitudeLongitudeToLatLng } from '@/components/Distance/utils';
import { MapPopup } from '@/components/MapPopup';
import { ProductAddress } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';

export const LocationOption = ({ coordinates, onLocationSelect }: LocationOptionProps) => {
    const { t } = useTranslation();

    const userCoordinates = useAppSelector((state) => state.geolocationReducer);

    const [mapPopupOpened, setMapPopupOpened] = useState(false);
    const [openMapPopup, closeMapPopup] = useBooleanState(setMapPopupOpened);

    const [locationTitle, setLocationTitle] = useState('');

    const handleLocationSelect = useCallback(
        (position: LatLng, place: string, placeId: string, address: ProductAddress) => {
            setLocationTitle(address.city + ' ' + address.firstAddressLine);
            onLocationSelect?.(position, place, placeId, address);
        },
        [onLocationSelect],
    );

    return (
        <>
            <List className="category-sheet__open-categories-options">
                <ListItem title={locationTitle || t('Select location')} onClick={openMapPopup}>
                    <IcArrowRight className="category-sheet__arrow" />
                </ListItem>
            </List>
            <MapPopup
                backdrop
                title={t('Location')}
                initialized
                opened={mapPopupOpened}
                onPopupOpen={openMapPopup}
                onPopupClose={closeMapPopup}
                coordinates={coordinates ?? LatitudeLongitudeToLatLng(userCoordinates) ?? { lat: 1, lng: 1 }}
                onLocationSelect={handleLocationSelect}
            />
        </>
    );
};

import {
    TransactionActions,
    TRANSACTIONS_LOADING,
    TRANSACTIONS_LOADING_ERROR,
    TRANSACTIONS_LOADING_SUCCESS,
} from '@/actions/transactionActions';
import { Transaction } from '@/generated/commonapi';

export interface ITransactionsState {
    loading: boolean;
    error: unknown;
    transactions: Transaction[];
}

const initialState: ITransactionsState = {
    transactions: [],
    loading: false,
    error: undefined,
};

const transactionReducer = (state = initialState, action: TransactionActions): ITransactionsState => {
    switch (action.type) {
        case TRANSACTIONS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case TRANSACTIONS_LOADING_SUCCESS: {
            const { transactions } = action;

            return {
                ...state,
                loading: false,
                error: undefined,
                transactions,
            };
        }
        case TRANSACTIONS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default transactionReducer;

import { RegisterFormValues } from './RegisterPopup.types';

export const defaultInitial: RegisterFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordRepeat: '',
    accept: false,
    country: undefined,
};

export const requiredFields = [
    'email',
    'password',
    'passwordRepeat',
    'firstName',
    'lastName',
    'accept',
    'country',
] as const;

export const SUCCESS_MESSAGE =
    "Your registration has been successful. <br> You will receive an activation e-mail shortly containing further instructions on how to complete your registration.<br>If you didn't receive the email, check your spam folder";

export const titleMapping = {
    0: 'Register',
    1: 'registerComplete',
};

import { Block, Navbar, NavLeft, NavTitle } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';

export const NotFoundPageInner = () => {
    const { t } = useTranslation();
    return (
        <CustomPage>
            <Navbar>
                <NavLeft>
                    <AdaptiveBackLink back>{t('Back')}</AdaptiveBackLink>
                </NavLeft>
                <NavTitle>{t('Not found')}</NavTitle>
            </Navbar>
            <Block strong>
                <p>{t('Sorry')}</p>
                <p>{t('Requested content not found.')}</p>
            </Block>
        </CustomPage>
    );
};

export const NotFoundPage = ConnectPageWrapper(NotFoundPageInner);

import cn from 'classnames';
import { Block, Icon, Link, Navbar, NavRight, NavTitle, Page } from 'framework7-react';
import { GoogleApiWrapper, Map, mapEventHandler, Marker } from 'google-maps-react';
import React from 'react';
import GooglePlacesAutocomplete, { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { initialPosition } from './MapPopup.constants';

import { GooglePlacesAutocompleteSelect, MapPopupMappedProps, MapPopupProps, MapPopupState } from './MapPopup.types';

import { IcClose } from '@/assets';
import { ThemedButton } from '@/components/ThemedButton';
import { ProductAddress } from '@/generated/marketplaceapi';
import { IApplicationStore } from '@/reducers/rootReducer';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { Popups } from '@/types/popups';
import { detectLocation } from '@/utils';

import './MapPopup.less';

class MapPopup extends React.Component<MapPopupMappedProps, MapPopupState> {
    private mounted: boolean;

    constructor(props: Readonly<MapPopupMappedProps>) {
        super(props);
        this.state = {
            position: initialPosition,
            fetchingAddress: false,
            selectValue: {
                label: '',
                value: { description: '', place_id: '' },
            } as GooglePlacesAutocompleteSelect,
        };
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    async componentDidUpdate(prevProps: Readonly<MapPopupMappedProps>) {
        const { initialized, coordinates } = this.props;
        const { position } = this.state;
        if (this.mounted && initialized && !prevProps.initialized && position.lat === 0 && position.lng === 0) {
            if (coordinates && coordinates.lat !== 0 && coordinates.lng !== 0) {
                this.setState({ position: coordinates });
            } else {
                await this.setCurrentPosition();
            }
        }
    }

    setCurrentPosition = async () => {
        const location = await detectLocation();
        if (location && location?.latitude !== 0 && location?.longitude !== 0) {
            const position = {
                lat: location.latitude,
                lng: location.longitude,
            };
            this.setState({ position });
            this.updateNearbyPlaces(position);
        }
    };

    setPositionHandle = (selected) => {
        this.setState({
            place: selected.label,
            placeId: selected.value.place_id,
            fetchingAddress: false,
            selectValue: selected,
        });
        this.setAddress(selected.value.place_id);
    };

    setAddress = (placeId: string) => {
        geocodeByPlaceId(placeId)
            .then((results) => {
                if (results.length > 0) {
                    // TODO
                    this.setState({
                        position: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        },
                    });
                    if (results[0].address_components) {
                        const address: ProductAddress = {
                            firstAddressLine: '',
                        };

                        results[0].address_components.forEach((component) => {
                            if (component.types.includes('route') || component.types.includes('street_number')) {
                                address.firstAddressLine += component.long_name + ' ';
                            }
                            if (component.types.includes('sublocality')) {
                                address.secondAddressLine = component.long_name;
                            }
                            if (component.types.includes('locality')) {
                                address.city = component.long_name;
                            }
                            if (component.types.includes('postal_code')) {
                                address.postalCode = component.long_name;
                            }
                            if (component.types.includes('country')) {
                                address.countryCode = component.short_name;
                            }
                        });

                        address.firstAddressLine = address.firstAddressLine.trim();
                        this.setState({
                            address: address,
                        });
                    }
                }
            })
            .catch((error) => console.error('at MapPopup in setAdress', error));
    };

    selectPosition = () => {
        const { position, place, placeId, address } = this.state;
        this.props.onLocationSelect(position, place, placeId, address);
    };

    fetchPlaces: mapEventHandler = (_, map) => {
        const { position } = this.state;
        this.updateNearbyPlaces(position);
        map.setCenter(position);
    };

    selectPositionOnMap: mapEventHandler = (_mapProps, _map, event) => {
        const position: LatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };

        this.updateNearbyPlaces(position);
        this.setState({
            position,
            selectValue: {
                label: this.state.place,
                value: {
                    ...this.state.selectValue.value,
                    description: this.state.place,
                    place_id: this.state.placeId,
                },
            },
        });
    };

    updateNearbyPlaces(location: LatLng) {
        this.setState({ fetchingAddress: true });
        geocodeByLatLng(location).then((results) => {
            if (results.length > 0) {
                const firstPlace = results[0];
                this.setState({
                    place: firstPlace.formatted_address,
                    placeId: firstPlace.place_id,
                    selectValue: {
                        label: firstPlace.formatted_address,
                        value: {
                            ...this.state.selectValue.value,
                            description: firstPlace.formatted_address,
                            place_id: firstPlace.place_id,
                        },
                    },
                });
                this.setAddress(firstPlace.place_id);
                this.setState({ fetchingAddress: false });
            }
        });
    }

    render() {
        const { initialized, className, t, title, google, ...props } = this.props;
        const { position, selectValue } = this.state;

        const InnerMap = (
            <Block className="map-container">
                {initialized && (
                    <>
                        <Map
                            className="google-map"
                            centerAroundCurrentLocation
                            disableDefaultUI
                            zoom={14}
                            initialCenter={position}
                            center={position}
                            google={this.props.google}
                            containerStyle={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                            }}
                            onReady={this.fetchPlaces}
                            onClick={this.selectPositionOnMap}
                            fullscreenControl
                            fullscreenControlOptions={{
                                position: google.maps.ControlPosition.BOTTOM_LEFT,
                            }}
                            zoomControl
                            zoomControlOptions={{
                                position: google.maps.ControlPosition.BOTTOM_LEFT,
                            }}
                            {...this.props}
                        >
                            <GooglePlacesAutocomplete
                                selectProps={{
                                    value: selectValue,
                                    onMenuOpen: () => {
                                        this.setState({
                                            selectValue: { ...selectValue, label: '' },
                                        });
                                    },
                                    className: 'map-autocomplete',
                                    classNamePrefix: 'google-places',
                                    placeholder: t('Select location'),
                                    onChange: this.setPositionHandle,
                                    noOptionsMessage: () => t('No options').toString(),
                                }}
                            />
                            <Marker position={position} draggable />
                        </Map>
                        <div className="set-location-container">
                            <ThemedButton
                                className="set-location-btn"
                                fill
                                large
                                round
                                disabled={this.state.fetchingAddress}
                                onClick={this.selectPosition}
                                popupClose={!this.props.onlyMap}
                            >
                                {t('Set Location')}
                            </ThemedButton>
                            <ThemedButton className="my-location" fill large round onClick={this.setCurrentPosition}>
                                <Icon material="gps_fixed" />
                            </ThemedButton>
                        </div>
                    </>
                )}
            </Block>
        );

        if (this.props.onlyMap) return <div className="map-only">{InnerMap}</div>;

        return (
            <MSPopup
                {...props}
                className={cn(Popups.MAP_POPUP, 'map-popup', className)}
                opened={props.opened}
                onBackdropClick={props.onPopupClosed}
            >
                <Page>
                    <Navbar noShadow noHairline className="map-popup__navbar">
                        {title && <NavTitle>{title}</NavTitle>}
                        <NavRight>
                            <Link onClick={props.onPopupClosed}>
                                <IcClose />
                            </Link>
                        </NavRight>
                    </Navbar>
                    {InnerMap}
                </Page>
            </MSPopup>
        );
    }
}

const mapStateToProps = (state: IApplicationStore) => ({
    apiKey: state.rootReducer.localConfig ? state.rootReducer.localConfig.GoogleMapAPIkey : '',
    language: state.rootReducer.language,
});

// TODO: This instance must be removed after resolve of google map geocode address problem
export const MapPopupRu = compose<React.ComponentClass<MapPopupProps>>(
    withTranslation(),
    connect(mapStateToProps),
    GoogleApiWrapper(({ apiKey }) => ({
        apiKey,
        libraries: ['places', 'geometry'],
        language: 'ru',
    })),
)(MapPopup);
// ----------------------------------------------

export default compose<React.ComponentClass<MapPopupProps>>(
    withTranslation(),
    connect(mapStateToProps),
    GoogleApiWrapper(({ apiKey, language }) => ({
        apiKey,
        libraries: ['places', 'geometry'],
        language: language,
    })),
)(MapPopup);

import { Link, Navbar, NavRight } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomButton } from '../CustomButton';
import { MascotIcon } from '../MascotIcon';
import { MascotType } from '../MascotIcon/MascotIcon.types';

import { IcClose } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { IMSPopupProps, MSPopup } from '@/shared/UIKit/Popup/Popup';

import './ServiceProviderCreatedPopup.less';
import { IMSSheetProps, MSSheet } from '@/shared/UIKit/Sheet/Sheet';

const ServiceProviderCreatedPopup = ({
    opened,
    openSellerArea,
    onClose,
}: {
    opened: boolean;
    openSellerArea: () => void;
    onClose: () => void;
}) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const Component: React.FC<IMSSheetProps | IMSPopupProps> = isMobile ? MSSheet : MSPopup;

    return (
        <Component className="service-provider-created-popup" opened={opened} onBackdropClick={onClose}>
            <div className="service-provider-created-popup__header">
                <Navbar className="service-provider-created-popup__navbar" noShadow noHairline>
                    <NavRight>
                        <Link className="service-provider-created-popup__close" iconOnly onClick={onClose}>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
            </div>

            <div className="service-provider-created-popup__content">
                <div className="service-provider-created-popup__mascot">
                    <MascotIcon type={MascotType.SUCCESS_PAYMENT} />
                </div>
                <div className="service-provider-created-popup__title">
                    {t('services.start_providing_services.success_popup.title')}
                </div>
                <div className="service-provider-created-popup__subtitle">
                    {t('services.start_providing_services.success_popup.description')}
                </div>
                <CustomButton
                    className="service-provider-created-popup__button"
                    size="large"
                    fullWidth={isMobile}
                    onClick={openSellerArea}
                >
                    {t('services.start_providing_services.success_popup.button.open_my_services')}
                </CustomButton>
            </div>
        </Component>
    );
};

export default ServiceProviderCreatedPopup;

import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';

import { LatitudeLongitude } from './Distance.types';

export const LatLngToLatitudeLongitude = ({ lat, lng }: LatLng): LatitudeLongitude => ({
    longitude: lng,
    latitude: lat,
});

export const LatitudeLongitudeToLatLng = ({ latitude, longitude }: LatitudeLongitude): LatLng => ({
    lng: longitude,
    lat: latitude,
});

export const prepareCoords = (coords: LatLng | LatitudeLongitude) => {
    if ('lat' in coords || 'lng' in coords) {
        return LatLngToLatitudeLongitude(coords);
    }
    return coords;
};

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Sorting } from '@/generated/marketplaceapi';
import { useBooleanState } from '@/hooks/useBooleanState';
import { createUUID } from '@/utils';

export const useFilterModal = (
    withPopup?: boolean,
    filterButtonMobile?: boolean,
    defaultOptions?: string[],
    handleClear?: () => void,
) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultOptions || []);
    const [sortBy, setSortBy] = useState<Sorting>();

    const [bufferSelectedOptions, setBufferSelectedOptions] = useState<string[]>([]);

    const onFilterOptionClick = useCallback(
        (code: string) =>
            setBufferSelectedOptions((prev) =>
                prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code],
            ),
        [],
    );

    const onFilterClear = useCallback(() => {
        handleClear?.();
        setSelectedOptions([]);
    }, [handleClear]);

    const [filterModalOpened, setFilterModalOpened] = useState(false);
    const [openFilterModal, closeFilterModal] = useBooleanState(setFilterModalOpened);

    const filterButtonClassname = useMemo(() => createUUID().replace(/[^a-zA-Z]/g, ''), []);

    const onFilterApply = useCallback(
        (statuses: string[], sortBy?: Sorting) => {
            setSelectedOptions(statuses);
            setSortBy(sortBy);
            if (withPopup) {
                setFilterModalOpened(false);
            }
        },
        [withPopup],
    );

    useEffect(() => {
        if (filterModalOpened) {
            setBufferSelectedOptions(selectedOptions);
        }
    }, [filterModalOpened, selectedOptions]);

    return {
        popoverProps: {
            selected: selectedOptions,
            onSelect: onFilterOptionClick,
            onClear: onFilterClear,
            onApply: onFilterApply,
        },
        filterButtonProps: {
            className: filterButtonClassname,
            onClick: openFilterModal,
            count: selectedOptions.length,
            isMobile: filterButtonMobile,
        },
        popupProps: {
            opened: filterModalOpened && withPopup,
            onPopupClosed: closeFilterModal,
            onPopupOpened: openFilterModal,
            selectedOptions: selectedOptions,
            onSelect: onFilterOptionClick,
            onClear: onFilterClear,
            onApply: onFilterApply,
        },
        selectedOptions,
        bufferSelectedOptions,
        sortBy,
        setSelectedOptions,
    };
};

import cn from 'classnames';
import { Chip, Link } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { sortByTypes } from './constants';

import { SortByButtonsGroupProps } from './SortByButtonsGroup.types';

import { IcDone } from '@/assets';
import { SortBy } from '@/types/sort';

import './SortByButtonsGroup.less';

export const SortByButtonsGroup = ({
    selected = [],
    onClick,
    listView,
    sortByArr = sortByTypes,
}: SortByButtonsGroupProps) => {
    const { t } = useTranslation();

    const makeHandleItemClick = useCallback((item: SortBy) => () => onClick(item), [onClick]);

    return (
        <div className="sort-by-buttons-groups">
            {sortByArr.map((item, i) => (
                <div key={i}>
                    {item.map((item) =>
                        listView ? (
                            <Link
                                key={item.type}
                                onClick={makeHandleItemClick(item.type)}
                                text={`${t(item.text)}`}
                                className="list-view-link"
                            >
                                {selected.includes(item.type) && <IcDone />}
                            </Link>
                        ) : (
                            <Link key={item.type} onClick={makeHandleItemClick(item.type)}>
                                <Chip
                                    text={t(item.text).toString()}
                                    className={cn({ selected: selected.includes(item.type) })}
                                />
                            </Link>
                        ),
                    )}
                </div>
            ))}
        </div>
    );
};

export const deepEqual = <T extends unknown>(a: T, b: T) => {
    // sometimes here can be undefined and anoter type, so we need to check types
    if (a === null || b === null || (typeof a !== 'object' && typeof b !== 'object') || typeof a !== typeof b) {
        return a === b;
    }

    if (Object.keys(a || {}).length !== Object.keys(b).length) return false;

    for (const key in a as Record<string, unknown>) {
        if (!(key in (b as Record<string, unknown>))) return false;
        if (!deepEqual<unknown>(a[key], b[key])) return false;
    }

    return true;
};

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { changeLanguage, getAvailableLanguage } from './actions/classificatorActions';
import { LOCAL_CONFIG_LOADED } from './actions/rootActions';
import { YMapProvider } from './contexts/YMapContext';
import F7App from './F7App';
import { AppDispatch } from './hooks/store';
import i18n, { availableLanguages, language } from './i18n';
import { store, persistor } from './store/Store';
import { getQueryParameterFromURL, isDarkMode, loadLocalConfig, setLayoutTheme } from './utils';

import 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

const Setup: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const initialize = async () => {
            checkTheme();

            if (!isLanguageAvailable()) {
                const availableLanguage = await getAvailableLanguage(language);
                dispatch(changeLanguage(availableLanguage));
                await i18n.changeLanguage(availableLanguage);
            }

            const localConfig = await loadLocalConfig();
            dispatch({ type: LOCAL_CONFIG_LOADED, localConfig });
        };

        initialize();
    }, [dispatch]);

    const checkTheme = () => {
        const isDarkModeEnabled = getQueryParameterFromURL('darkMode') === 'true' || isDarkMode();
        setLayoutTheme(isDarkModeEnabled ? 'dark' : 'light');
    };

    const isLanguageAvailable = () => availableLanguages.includes(language);

    return <F7App />;
};

const Wrappers: React.FC = () => {
    return (
        <React.Suspense fallback={<></>}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <I18nextProvider i18n={i18n}>
                        <YMapProvider>
                            <Setup />
                        </YMapProvider>
                    </I18nextProvider>
                </PersistGate>
            </Provider>
        </React.Suspense>
    );
};

export default Wrappers;

import cn from 'classnames';
import { FormApi } from 'final-form';
import { f7, Icon, Link, List, Navbar, NavLeft, NavRight, Popup } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { defaultInitial, requiredFields, titleMapping } from './RegisterPopup.constants';

import { RegisterFormErrors, RegisterFormValues, RegisterPopupProps, RegSteps } from './RegisterPopup.types';

import {
    getAuthErrorsDialog,
    getFullErrorMessage,
    registerFormValuesToCreateAccountRequest,
    validateRegister,
} from './RegisterPopup.utils';

import { register } from '@/actions/sessionActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { IcClose } from '@/assets';
import { CountrySelectPopup } from '@/components/CountrySelectPopup';
import { CustomButton } from '@/components/CustomButton';
import { CheckboxField } from '@/components/Fields/CheckboxField';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { CustomSelectField } from '@/components/Fields/CustomSelectField';
import { PrivacyAndTerms } from '@/components/PrivacyAndTerms';
import { RoundImageInput } from '@/components/RoundImageInput';
import { COMMONAPI_ERROR_USER_ALREADY_EXISTS } from '@/error-handler';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useCountryOptions } from '@/hooks/useCountryOptions';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { AVAILABLE_COUNTRIES, INVALID_NAME_ERROR, NOT_VALID_ERROR } from '@/shared/constants';
import { Popups } from '@/types/popups';
import { getBase64ImgNoHeader, valueInRange } from '@/utils';

import './RegisterPopup.less';

export const RegisterPopup = React.memo(
    ({ className, opened, onPopupOpen, onPopupClose, onLogIn, doAfterRegister, ...props }: RegisterPopupProps) => {
        const { t } = useTranslation();
        const dispatch = useAppDispatch();

        const isMobile = useAppSelector(getIsMobile);

        const query = f7.view.main?.router?.currentRoute?.query;

        const { referralCode = '' } = query || {};

        const defaultCountryCode = useAppSelector(getCountryCodeFromState);
        const { registrationLoading } = useAppSelector((state) => state.sessionReducer);

        const [step, setStep] = useState(RegSteps.AUTH_DATA);
        const [isCountrySelectPopupOpened, setIsCountrySelectPopupOpened] = useState(false);
        const [openCountryPopup, closeCountryPopup] = useBooleanState(setIsCountrySelectPopupOpened);

        const countries = useCountryOptions({
            withIcons: true,
            filterCountries: AVAILABLE_COUNTRIES,
        });
        const loggedIn = useAppSelector(isLoggedIn);

        const formRef = useRef<FormApi<RegisterFormValues>>();

        const profilePicRef = useRef<HTMLInputElement>(null);
        const [profilePicUrl, setProfilePicUrl] = useState('');
        const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);

        const closePopup = useCallback(() => f7.popup.close(`.${Popups.REGISTER_POPUP}`), []);

        const popupClosedHandler = useCallback(() => {
            setStep(RegSteps.AUTH_DATA);

            onPopupClose?.();
            closePopup();
        }, [onPopupClose, closePopup]);

        const openLogInPopupHandler = useCallback(() => {
            popupClosedHandler?.();
            onLogIn?.();
        }, [popupClosedHandler, onLogIn]);

        const handleRegisterTry = useCallback(() => {
            onPopupOpen?.();
            firebaseAnalyticsInstance.registrationTry?.();
        }, [onPopupOpen]);

        const initialFormValues = useMemo<RegisterFormValues>(
            () =>
                defaultCountryCode
                    ? {
                          ...defaultInitial,
                          referralCode,
                          country: countries?.find((item) => item.value === defaultCountryCode),
                      }
                    : { ...defaultInitial, referralCode },
            [countries, defaultCountryCode, referralCode],
        );

        useEffect(() => {
            if (loggedIn && opened) {
                closePopup();
            }
        }, [closePopup, loggedIn, opened]);

        const handleStepChange = useCallback(
            (back?: boolean) => () => {
                setStep(valueInRange(RegSteps.AUTH_DATA, RegSteps.PERSONAL_INFO, step + 1 * (back ? -1 : 1)));
            },
            [step],
        );

        const getInfoLabel = useCallback(
            (fieldName: string) => (requiredFields.some((field) => field === fieldName) ? t('Required') : undefined),
            [t],
        );

        const handleFileUpload = (form: FormApi<RegisterFormValues>) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const pic = e.target.files[0];
            if (pic) {
                setIsProfilePicLoading(true);
                getBase64ImgNoHeader(pic)
                    .then((base64ImgNoHeader) =>
                        form.batch(() => {
                            form.change('profilePic', base64ImgNoHeader);
                            form.change('profilePicName', pic.name);
                            setProfilePicUrl(URL.createObjectURL(pic));
                        }),
                    )
                    .finally(() => setIsProfilePicLoading(false));
            }
        };

        const handleFileDelete = (form: FormApi<RegisterFormValues>) => () => {
            if (!profilePicUrl && profilePicRef.current) {
                profilePicRef.current.click();
                return;
            }

            form.batch(() => {
                form.change('profilePic', undefined);
                form.change('profilePicName', undefined);
                setProfilePicUrl('');
            });
        };

        const handleRegister = useCallback(
            async (values: RegisterFormValues) => {
                const { sessionReducer } = await dispatch(register(registerFormValuesToCreateAccountRequest(values)));

                if (sessionReducer.registrationError) {
                    f7.dialog.alert(t(`${sessionReducer.registrationError}`), () => {
                        if (sessionReducer.registrationErrorCode === COMMONAPI_ERROR_USER_ALREADY_EXISTS) {
                            openLogInPopupHandler();
                        }
                    });

                    return;
                }

                f7.view.main.router.navigate(`/confirm-email?verificationEmail=${values?.email}`, {
                    reloadCurrent: true,
                    force: true,
                });
                formRef.current?.reset();

                setStep(RegSteps.AUTH_DATA);
                closePopup();
                doAfterRegister?.();
            },
            [dispatch, closePopup, doAfterRegister, t, openLogInPopupHandler],
        );

        const checkErrorsSubmitFnWrapper = useCallback(
            (
                errors: RegisterFormErrors,
                valid: boolean,
                submitFn: () => Promise<Record<string, unknown> | undefined> | undefined,
            ) =>
                () => {
                    if (step === RegSteps.AUTH_DATA) {
                        const messages = getAuthErrorsDialog(errors)
                            .map((v) => t(v))
                            .join('<br/><br/>');

                        if (messages) {
                            f7.dialog.alert(messages);
                            return;
                        }

                        handleStepChange()();
                        return;
                    }

                    if (!valid) {
                        const invalidUserName = errors.lastName || errors.firstName;

                        f7.dialog.alert(t(invalidUserName ? INVALID_NAME_ERROR : NOT_VALID_ERROR));
                        return;
                    }

                    submitFn();
                },
            [handleStepChange, step, t],
        );

        useEffect(() => {
            if (step === RegSteps.PERSONAL_INFO) {
                const el: HTMLElement = document.querySelector('.register-popup__item input[name="firstName"]');
                el?.focus();
            }
        }, [step]);

        return (
            <Popup
                {...props}
                onPopupOpen={handleRegisterTry}
                onPopupClosed={popupClosedHandler}
                id={Popups.REGISTER_POPUP}
                className={cn(Popups.REGISTER_POPUP, 'register-popup', className)}
                opened={opened}
            >
                <Navbar noShadow noHairline>
                    {isMobile && (
                        <NavLeft>
                            {step === RegSteps.AUTH_DATA && (
                                <Link className="login-popup__close" iconOnly popupClose>
                                    <IcClose />
                                </Link>
                            )}

                            {step !== RegSteps.AUTH_DATA && (
                                <Link
                                    iconOnly
                                    onClick={handleStepChange(true)}
                                    className={cn({
                                        disabled: registrationLoading,
                                    })}
                                >
                                    <Icon f7="arrow_left" />
                                </Link>
                            )}
                        </NavLeft>
                    )}

                    {!isMobile && (
                        <NavRight>
                            <Link className="login-popup__close" iconOnly popupClose>
                                <IcClose />
                            </Link>
                        </NavRight>
                    )}
                </Navbar>

                <Form<RegisterFormValues>
                    onSubmit={handleRegister}
                    validate={(values) => validateRegister(values, step)}
                    initialValues={initialFormValues}
                    keepDirtyOnReinitialize
                >
                    {({ handleSubmit, valid, form, errors }) => {
                        formRef.current = form;

                        return (
                            <div className="register-popup__content">
                                <List
                                    noHairlines
                                    form
                                    className={cn('register-form-list', {
                                        'form-list-visible': step === RegSteps.AUTH_DATA,
                                    })}
                                >
                                    <div className="register-popup__header">
                                        <p className="register-popup__title">{t(titleMapping[step])}</p>
                                    </div>

                                    <div className="register-popup__item">
                                        <CustomInputField
                                            name="email"
                                            label={t('E-mail').toString()}
                                            floatingLabel
                                            type="email"
                                            clearButton
                                            slot="list"
                                            requiredSign={!!getInfoLabel('email')}
                                        />
                                    </div>
                                    <div className="register-popup__item">
                                        <CustomInputField
                                            errorInTooltip={t(getFullErrorMessage(errors.password))}
                                            name="password"
                                            label={t('Password').toString()}
                                            floatingLabel
                                            type="password"
                                            slot="list"
                                            requiredSign={!!getInfoLabel('password')}
                                        />
                                    </div>
                                    <div className="register-popup__item">
                                        <CustomInputField
                                            name="passwordRepeat"
                                            label={t('Repeat Password').toString()}
                                            floatingLabel
                                            type="password"
                                            showErrorOnFocus
                                            slot="list"
                                            requiredSign={!!getInfoLabel('passwordRepeat')}
                                        />
                                    </div>

                                    <CheckboxField name="accept" checkbox className="accept-checkbox">
                                        <div className="item-title">
                                            {`${t('I have read and agree on the')} `}

                                            <PrivacyAndTerms />
                                        </div>
                                    </CheckboxField>
                                </List>

                                <List
                                    noHairlines
                                    form
                                    className={cn('register-form-list', {
                                        'form-list-visible': step === RegSteps.PERSONAL_INFO,
                                    })}
                                >
                                    <div className="register-popup__header">
                                        {step > RegSteps.AUTH_DATA && !isMobile && (
                                            <Link
                                                iconOnly
                                                onClick={handleStepChange(true)}
                                                className={cn({
                                                    disabled: registrationLoading,
                                                })}
                                            >
                                                <Icon className="icon-back" />
                                            </Link>
                                        )}
                                        <h1 className="register-popup__title">{t('registerComplete')}</h1>
                                    </div>

                                    <div className="register-popup__profile-pic">
                                        <RoundImageInput
                                            ref={profilePicRef}
                                            imgUrl={profilePicUrl}
                                            loading={isProfilePicLoading}
                                            onChange={handleFileUpload(form)}
                                            onDelete={handleFileDelete(form)}
                                        />
                                    </div>

                                    <div className="register-popup__item">
                                        <CustomInputField
                                            name="firstName"
                                            label={t('First Name').toString()}
                                            floatingLabel
                                            type="text"
                                            clearButton
                                            slot="list"
                                            requiredSign={getInfoLabel('firstName')}
                                        />
                                    </div>

                                    <div className="register-popup__item">
                                        <CustomInputField
                                            name="lastName"
                                            label={t('Surname').toString()}
                                            floatingLabel
                                            type="text"
                                            clearButton
                                            slot="list"
                                            requiredSign={getInfoLabel('lastName')}
                                        />
                                    </div>

                                    <div className="register-popup__item register-popup__country-select">
                                        <CustomSelectField
                                            name="country"
                                            requiredSign
                                            options={countries}
                                            label={t('Country')}
                                            openPopup={openCountryPopup}
                                            withFlags
                                        />
                                    </div>
                                </List>

                                <div className="register-popup__bottom-btns">
                                    <CustomButton
                                        size="large"
                                        fullWidth
                                        onClick={checkErrorsSubmitFnWrapper(errors, valid, handleSubmit)}
                                        disabled={registrationLoading || !valid}
                                    >
                                        {step === RegSteps.PERSONAL_INFO ? t('Done') : t('Continue')}
                                    </CustomButton>
                                </div>

                                <CountrySelectPopup
                                    opened={isCountrySelectPopupOpened}
                                    filterCountries={AVAILABLE_COUNTRIES}
                                    onCountrySelect={(country) => {
                                        form.change('country', {
                                            value: country.code,
                                            label: country.name,
                                        });
                                        closeCountryPopup();
                                    }}
                                    onClose={closeCountryPopup}
                                />
                            </div>
                        );
                    }}
                </Form>

                {step !== RegSteps.PERSONAL_INFO && (
                    <p className="register-popup__sign-in-text">
                        {`${t('Already have an account?')} `}
                        <Link onClick={openLogInPopupHandler}>{t('Log In')}</Link>
                    </p>
                )}
            </Popup>
        );
    },
);

RegisterPopup.displayName = 'RegisterPopup';

import {
    RegionsActions,
    SET_REGION_ERROR,
    SET_REGION_LIST,
    SET_REGION_LIST_ERROR,
    SET_REGION_LIST_LOADING,
    SET_REGION_LOADING,
} from '@/actions/regionsActions';

import type { Region } from '@/generated/commonapi/api';

export interface IRegionsState {
    regionsList: Region[];
    regionsListLoading: boolean;
    regionsListError?: unknown;
    changeRegionLoading: boolean;
    changeRegionError?: unknown;
    regionPopupAlredyOpened: boolean;
}

const initialState: IRegionsState = {
    regionsList: [],
    regionsListLoading: false,
    changeRegionLoading: false,
    regionPopupAlredyOpened: false,
};

const regionsReducer = (state = initialState, action: RegionsActions): IRegionsState => {
    switch (action.type) {
        case SET_REGION_LOADING: {
            return {
                ...state,
                changeRegionLoading: true,
                changeRegionError: undefined,
            };
        }
        case SET_REGION_ERROR: {
            return {
                ...state,
                changeRegionLoading: false,
                changeRegionError: action.err,
            };
        }
        case SET_REGION_LIST: {
            return {
                ...state,
                regionsList: action.regions,
                regionsListLoading: false,
            };
        }
        case SET_REGION_LIST_LOADING: {
            return {
                ...state,
                regionsListLoading: true,
                regionsListError: undefined,
            };
        }
        case SET_REGION_LIST_ERROR: {
            return {
                ...state,
                regionsListLoading: false,
                regionsListError: action.err,
            };
        }
        default: {
            return state;
        }
    }
};

export default regionsReducer;

import { applyMiddleware, compose, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reduxThunk from 'redux-thunk';

import SetTransform from './transforms';

import rootReducer, { IApplicationStore } from '../reducers/rootReducer';

import { client } from '@/axios';

const persistConfig: PersistConfig<IApplicationStore> & {
    whitelist: (keyof IApplicationStore)[];
} = {
    key: 'root',
    storage,
    transforms: [SetTransform],
    whitelist: ['classificatorReducer'],
};

const persistedReducer = persistReducer<IApplicationStore>(persistConfig, rootReducer);

const options = {
    returnRejectedPromiseOnError: true,
};

const middlewares = [reduxThunk, axiosMiddleware(client, options)];

const composeEnhancers = process.env.NODE_ENV === 'development' ? composeWithDevTools({}) : compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

export { store, persistor };

import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CheckboxFieldProps } from './CheckboxField.types';

import { CustomCheckbox } from '@/components/CustomCheckbox';

export const CheckboxField = <T extends boolean | string>(props: CheckboxFieldProps<T>) => {
    const { t } = useTranslation();

    return (
        <Field<T> {...props} type="checkbox">
            {({ input: { checked, onChange }, meta: { error, modified } }) => (
                <CustomCheckbox
                    {...props}
                    checked={checked}
                    onChange={onChange}
                    errorMessage={t(error)}
                    errorMessageForce={props.errorMessageForce ?? (modified && !!error)}
                />
            )}
        </Field>
    );
};

import cn from 'classnames';
import { Link, List, ListButton, ListItem, Sheet } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SortReviewsModalFacadeProps } from './SortReviewsModalFacade.types';

import { IcClose, IcDone } from '@/assets';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ReviewSortingFields, ReviewSortingMap } from '@/shared/constants';
import { Sheets } from '@/types/sheets';
import { noop } from '@/utils';

import './SortReviewsModalFacade.less';

export const SortReviewsModalFacade: React.FC<SortReviewsModalFacadeProps> = ({
    activeSortValue = ReviewSortingFields.recent,
    changeSortValue = noop,
    modalTriggerElement,
    ...props
}) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const [expandedSort, expandSort, collapseSort] = useBoolState(false);

    const sortValues = useMemo(
        () =>
            Object.entries(ReviewSortingMap).map(([sortValue, sortName], idx) => (
                <ListButton
                    key={idx}
                    onClick={() => changeSortValue(sortValue as ReviewSortingFields)}
                    className={cn('sort-reviews-modal__item', {
                        active: sortValue === activeSortValue,
                    })}
                    sheetClose
                >
                    {t(sortName)}
                    <IcDone />
                </ListButton>
            )),
        [t, activeSortValue, changeSortValue],
    );

    switch (isMobile) {
        case true:
            return (
                <>
                    {modalTriggerElement(noop, expandedSort)}
                    <Sheet
                        id={Sheets.SORT_REVIEWS_SHEET}
                        className={cn(Sheets.SORT_REVIEWS_SHEET, 'sort-reviews-modal')}
                        backdrop
                        onSheetOpen={expandSort}
                        onSheetClose={collapseSort}
                        {...props}
                    >
                        <List noHairlinesBetween noHairlines>
                            <ListItem className="sort-reviews-modal__header">
                                {t('Sort By')}
                                <Link sheetClose>
                                    <IcClose />
                                </Link>
                            </ListItem>
                            {sortValues}
                        </List>
                    </Sheet>
                </>
            );
        default:
            return (
                <CustomPopoverWrapper
                    wrapperClassname="sort-reviews-popoover__wrapper"
                    closeByClickInside
                    popoverTriggerElement={modalTriggerElement}
                    className="sort-reviews-modal"
                    {...props}
                >
                    {() => (
                        <List noHairlinesBetween noHairlines>
                            <ListItem className="sort-reviews-modal__header">
                                {t('Sort By')}
                                <Link sheetClose>
                                    <IcClose />
                                </Link>
                            </ListItem>
                            {sortValues}
                        </List>
                    )}
                </CustomPopoverWrapper>
            );
    }
};

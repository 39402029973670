import { AxiosRequestConfig } from 'axios';

import { client } from '.';

import { FetchAPI } from '@/generated/marketplaceapi';

const portableFetch: FetchAPI = (url: string, init?: any): Promise<Response> => {
    const config: AxiosRequestConfig = {
        url,
        data: init.body,
        ...init,
    };
    return client.request(config).then((res) => {
        return {
            ...res,
            json: () => res.data,
        };
    }) as any;
};

export default portableFetch;

import { AlertActions, RESET_ALERT, SET_ALERT } from '@/actions/alertActions';

export interface IAlertState {
    title?: string;
    text: string;
    callback?: Function | undefined;
}

const initialState: IAlertState = {
    title: '',
    text: '',
    callback: undefined,
};

const alertReducer = (state = initialState, action: AlertActions): IAlertState => {
    switch (action.type) {
        case SET_ALERT: {
            return {
                ...state,
                ...action.data,
            };
        }
        case RESET_ALERT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default alertReducer;

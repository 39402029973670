import Compressor from 'compressorjs';

const MAX_FILE_SIZE = 1024 * 1024 * 2;
const MAX_IMAGE_SIZE = 2000;
const DEFAULT_QUALITY = 0.73;

export const getCompressedImage = async (file: File): Promise<File> =>
    new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: DEFAULT_QUALITY,
            convertSize: MAX_FILE_SIZE,
            maxWidth: MAX_IMAGE_SIZE,
            success(result) {
                resolve(result as File);
            },
            error(err) {
                reject(err);
            },
        });
    });

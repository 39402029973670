import { IProduct } from './productReducer';

import {
    MY_GOODS_ARCHIVED_LOADING,
    MY_GOODS_ITEMS_DELETE_LOADING,
    MY_GOODS_ITEMS_DELETE_LOADING_SUCCES,
    MY_GOODS_LIST_LOADING,
    MY_GOODS_LIST_LOADING_ERROR,
    MY_GOODS_LIST_LOADING_SUCCESS,
    MY_GOODS_LIST_UPDATE_COUNT,
    MY_GOODS_SAVE_FILTER,
    MyGoodsActions,
} from '@/actions/myGoodsActions';
import {
    PRODUCT_CREATE_LOADING_SUCCESS,
    PRODUCT_DELETE_SUCCESS,
    ProductCreateActions,
} from '@/actions/productCreateActions';
import { CHANGE_PRODUCT_STATUS_SUCCESS, ProductStatusActions } from '@/actions/productStatusActions';
import { Product } from '@/generated/marketplaceapi';
import { valueInRange } from '@/utils';

export interface IMyGoodsState {
    loading?: boolean;
    error?: unknown;
    products?: IProduct[];
    archiveLoading: boolean;
    filteredCount?: number;
    selectedStatuses?: string[];
    abortController?: AbortController;
}

const initialState: IMyGoodsState = {
    loading: false,
    error: null,
    products: [],
    archiveLoading: false,
};
const myGoodsReducer = (
    state = initialState,
    action: MyGoodsActions | ProductStatusActions | ProductCreateActions,
): IMyGoodsState => {
    switch (action.type) {
        case MY_GOODS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                abortController: action.abortController,
            };
        case MY_GOODS_LIST_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                products: action.products,
                filteredCount: action.products?.length,
            };
        case MY_GOODS_LIST_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PRODUCT_CREATE_LOADING_SUCCESS: {
            const product = action.product;
            const products = [...state.products];

            const current = products.find((item) => item.uid === product.uid);

            if (current) {
                products[products.indexOf(current)] = { ...product };
            } else {
                products.unshift(product);
            }

            return {
                ...state,
                products,
            };
        }
        case PRODUCT_DELETE_SUCCESS:
            return {
                ...state,
                products: state.products.filter((item) => item.uid !== action.uid),
            };
        case CHANGE_PRODUCT_STATUS_SUCCESS: {
            const { item } = action;
            const { products } = state;

            if (item) {
                const searchedItem = products.find((_item) => _item.uid === item.uid);
                if (searchedItem) {
                    products[products.indexOf(searchedItem)] = { ...item };
                }
                return {
                    ...state,
                    products: products.filter((item) => item.status !== Product.StatusEnum.DLT),
                };
            }

            return {
                ...state,
            };
        }
        case MY_GOODS_ARCHIVED_LOADING: {
            return { ...state, archiveLoading: action.archivedLoading };
        }
        case MY_GOODS_ITEMS_DELETE_LOADING: {
            return { ...state, loading: action.loading };
        }
        case MY_GOODS_ITEMS_DELETE_LOADING_SUCCES: {
            return {
                ...state,
                products: state.products?.filter((product) => !action.productsUidList.includes(product.uid)),
                filteredCount: valueInRange(
                    0,
                    state.filteredCount,
                    state.filteredCount - action.productsUidList?.length,
                ),
            };
        }
        case MY_GOODS_LIST_UPDATE_COUNT:
            return {
                ...state,
                loading: false,
                filteredCount: action.count,
            };
        case MY_GOODS_SAVE_FILTER:
            return { ...state, selectedStatuses: action.selectedStatuses };
        default:
            return state;
    }
};

export default myGoodsReducer;

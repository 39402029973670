import cn from 'classnames';
import { ListItem } from 'framework7-react';
import React, { useCallback } from 'react';

import { CustomRadioProps } from './CustomRadio.types';

import './CustomRadio.less';

export const CustomRadio = ({ bordered = false, onChange, afterChange, className, ...props }: CustomRadioProps) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);
            afterChange?.(e);
        },
        [afterChange, onChange],
    );

    return (
        <ListItem {...props} radio onChange={handleChange} className={cn('custom-radio', { bordered }, className)} />
    );
};

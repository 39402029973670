import { CartItem } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';

export const productToCartItem = (product: IProduct, itemUid: string, quantity = 1): CartItem => ({
    itemUid,
    productUid: product.uid,
    quantity,
    productCurrencyCode: product.currencyCode,
    productDescription: product.shortDescription,
    productDiscountedPrice: product.discountedPrice,
    productName: product.name,
    productPrice: product.price,
    productSalePrice: product.salePrice,
    availableDeliveryMethods: product.deliveryMethods,
    imageThumbnailUrl1: product.imageThumbnailUrl1,
    parameters: product.productParams,
    pickupAllowed: product.pickupAllowed,
    sellerEmail: product.sellerEmail,
    sellerIban: product.sellerIban,
    sellerName: product.seller.name,
    sellerPhone: product.sellerPhone,
    sellerUid: product.sellerUid,
    shippingAllowed: product.shippingAllowed,
    type: product.type,
    availableQuantity: product.quantity,
});

import cn from 'classnames';
import { f7, Link, List, ListItem, Sheet } from 'framework7-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { STATUSES_PERMISSIONS, STATUSES_PERMISSIONS_SERVICES } from './ActionsModalFacade.constants';

import { ActionName, ActionsModalFacadeProps } from './ActionsModalFacade.types';

import { archivateProducts } from '@/actions/myGoodsActions';
import { changeProductStatus } from '@/actions/productStatusActions';
import { IcClose } from '@/assets';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { Product } from '@/generated/marketplaceapi';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAlertProductStatusChange } from '@/hooks/useAlertProductStatusChange';
import { useBooleanState } from '@/hooks/useBooleanState';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ProductType } from '@/types/productType';
import { Sheets } from '@/types/sheets';

import './ActionsModalFacade.less';

export const ActionsModalFacade: React.FC<ActionsModalFacadeProps> = ({
    forArchive,
    onClearArchive,
    selectedStatuses,
    selectedProductsSet,
    selectedProduct = {} as Product,
    onChangeQuantityClick,
    className,
    onEditArchiveClick,
    renderTriggerButton,
    ...props
}) => {
    const [actionsModalOpened, setActionsModalOpened] = useState(false);
    const [openActionsModal, closeActionsModal] = useBooleanState(setActionsModalOpened);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(getIsMobile);
    const activeType = useAppSelector(getActiveProductType);

    const statusesPermissions =
        activeType === ProductType.Service ? STATUSES_PERMISSIONS_SERVICES : STATUSES_PERMISSIONS;

    const allovedKeys = selectedStatuses.reduce<ActionName[]>(
        (prev, curr) => {
            const currentPermissions = statusesPermissions[curr];
            return prev?.filter((item) => currentPermissions?.includes(item));
        },
        selectedStatuses[0] ? statusesPermissions[selectedStatuses[0]] : [],
    );

    const deleteProducts = useCallback(() => {
        closeActionsModal();
        dispatch(archivateProducts(Array.from(selectedProductsSet)));
    }, [closeActionsModal, dispatch, selectedProductsSet]);

    const clearArchive = useCallback(() => {
        onClearArchive?.();
        closeActionsModal();
    }, [closeActionsModal, onClearArchive]);

    const handleEditArchiveClick = useCallback(() => {
        onEditArchiveClick?.();
        closeActionsModal();
    }, [closeActionsModal, onEditArchiveClick]);

    const alertStatusChange = useAlertProductStatusChange();

    const makeChangeStatusHandler = useCallback(
        (newStatus: Product.StatusEnum) => () => {
            closeActionsModal();
            dispatch(changeProductStatus(selectedProduct?.uid, selectedProduct?.status, newStatus)).then(
                alertStatusChange,
            );
        },
        [alertStatusChange, closeActionsModal, dispatch, selectedProduct?.status, selectedProduct?.uid],
    );

    const handleChangeQtyClick = useCallback(() => {
        onChangeQuantityClick();
    }, [onChangeQuantityClick]);

    const allItems: Record<ActionName, { title: string; onClick?: () => void } | undefined> = useMemo(
        () => ({
            Delete: { title: t('Delete'), onClick: deleteProducts },
            Change_Quantity: {
                title: t('seller_area.page.list_of_products.selected_items_action.menu.change_quantity'),
                onClick: handleChangeQtyClick,
            },
            Edit: {
                title: t('Edit'),
                onClick: () => {
                    f7.views.main.router.navigate(`/profile/seller-area/my-goods/edit/${selectedProduct?.uid}/`);
                },
            },
            Pause_the_sale_of_goods: {
                title: t('seller_area.page.list_of_products.selected_items_action.menu.suspend'),
                onClick: makeChangeStatusHandler(Product.StatusEnum.SUS),
            },
            Start_the_sale_of_goods: {
                title: t('seller_area.page.list_of_products.selected_items_action.menu.resume'),
                onClick: makeChangeStatusHandler(Product.StatusEnum.PBL),
            },
            Promote_good: { title: t('seller_area.page.list_of_products.selected_items_action.menu.promote_product') },
            Withdraw_from_sale: {
                title: t('Withdraw from sale'),
                onClick: makeChangeStatusHandler(Product.StatusEnum.DSC),
            },
        }),
        [deleteProducts, handleChangeQtyClick, makeChangeStatusHandler, selectedProduct?.uid, t],
    );

    const availableItems = useMemo(
        () =>
            forArchive
                ? isMobile
                    ? [
                          { title: t('Clear Archive'), onClick: clearArchive },
                          { title: t('Edit'), onClick: handleEditArchiveClick },
                      ]
                    : [{ title: t('Clear Archive'), onClick: clearArchive }]
                : allovedKeys?.map((key) => allItems[key]).filter(Boolean),
        [forArchive, isMobile, t, clearArchive, handleEditArchiveClick, allovedKeys, allItems],
    );

    switch (isMobile) {
        case true:
            return (
                <>
                    {renderTriggerButton(openActionsModal, actionsModalOpened)}
                    <Sheet
                        onSheetClose={closeActionsModal}
                        opened={actionsModalOpened}
                        backdrop
                        className={cn(Sheets.MY_GOODS_ACTIONS_SHEET, className)}
                    >
                        <Link sheetClose>
                            <IcClose />
                        </Link>

                        {availableItems?.length ? (
                            <List className="my-goods-action-modal__content" noHairlines noHairlinesBetween>
                                {availableItems?.map((item) => <ListItem key={item.title} {...item} />)}
                            </List>
                        ) : (
                            <span>{t('Selected products do not have common possible actions')}</span>
                        )}
                    </Sheet>
                </>
            );
        default:
            return (
                <CustomPopoverWrapper closeByClickInside popoverTriggerElement={renderTriggerButton} {...props}>
                    {() =>
                        availableItems?.length ? (
                            <List className="my-goods-action-modal__content" noHairlines noHairlinesBetween>
                                {availableItems?.map((item) => <ListItem key={item.title} {...item} />)}
                            </List>
                        ) : (
                            <span>{t('Selected products do not have common possible actions')}</span>
                        )
                    }
                </CustomPopoverWrapper>
            );
    }
};

import * as Sentry from '@sentry/browser';
import axios, { AxiosRequestConfig } from 'axios';
import i18n from 'i18next';

import { LocalStorageItems } from '@/types/localStorage';

const apiURL = '';

export const marketplaceapiURL = '/marketplaceapi';
export const commonapiURL = '/commonapi';
export const paymentapiURL = '/paymentapi';

export const requestConfig: AxiosRequestConfig = {
    baseURL: process.env.HOST + apiURL,
    responseType: 'json',
    withCredentials: false,
};

export const client = axios.create(requestConfig);

let axiosCredentialInterceptorsId: number;

export const updateAxiosClientCredential = () => {
    try {
        client.interceptors.request.eject(axiosCredentialInterceptorsId);
    } catch (error) {
        console.error('at axios in updateAxiosClientCredential', error);
    }

    axiosCredentialInterceptorsId = client.interceptors.request.use((config) => {
        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${localStorage.getItem(LocalStorageItems.ACCESS_TOKEN)}`,
            },
        };
    });
};

const addLanguageQueryParameter = (config: AxiosRequestConfig) => {
    const language = i18n.language;
    if (language && !config.url.includes('language=')) {
        config.params = {
            language,
            ...config.params,
        };
    }
};

client.interceptors.request.use(
    (config) => {
        addLanguageQueryParameter(config);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

client.interceptors.response.use(
    (res) => {
        const accessToken = res.data?.accessToken;
        if (accessToken) {
            localStorage.setItem(LocalStorageItems.ACCESS_TOKEN, accessToken);
            updateAxiosClientCredential();
        }
        return res;
    },
    (error) => {
        Sentry.captureException(error);

        return error;
    },
);

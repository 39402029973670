import cn from 'classnames';
import { Icon, Link, List, ListItem, Navbar, NavRight, NavTitle, PageContent } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SelectCustomerLocationModalProps } from './SelectCustomerLocationModal.types';

import { constructAddressLine } from './SelectCustomerLocationModal.utils';

import {
    changeAddress,
    changeCountry,
    toggleSelectCountryPopup,
    toggleSelectCustomerLocationSheet,
} from '@/actions/customerLocationActions';
import { IcGlobe } from '@/assets';
import { Address } from '@/generated/commonapi';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import './SelectCustomerLocationModal.less';

export const SelectCustomerLocationModal = ({
    className,
    onSheetClosed,
    onPopupClosed,
    ...props
}: SelectCustomerLocationModalProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const isMobile = useAppSelector(getIsMobile);

    const { selectCustomerLocationSheetOpened, addressUid } = useAppSelector((state) => state.customerLocationReducer);

    const addresses = useAppSelector(getProfile)?.addresses ?? [];

    const Component: React.FC<SelectCustomerLocationModalProps> = isMobile ? MSSheet : MSPopup;

    const dialogClosed = useCallback(() => {
        dispatch(toggleSelectCustomerLocationSheet(false));
        if (isMobile && onSheetClosed) {
            onSheetClosed();
        } else if (onPopupClosed) {
            onPopupClosed();
        }
    }, [dispatch, isMobile, onSheetClosed, onPopupClosed]);

    const modalClassNameAndId = isMobile
        ? Sheets.SELECT_CUSTOMER_LOCATION_SHEET
        : Popups.SELECT_CUSTOMER_LOCATION_POPUP;

    const onCountrySelect = useCallback(() => {
        dispatch(toggleSelectCustomerLocationSheet(false));
        dispatch(toggleSelectCountryPopup(true));
    }, [dispatch]);

    const makeOnAddressClick = useCallback(
        (item: Address) => () => {
            dispatch(changeCountry(item.country.code));
            dispatch(changeAddress(item.uid));
            dispatch(toggleSelectCustomerLocationSheet(false));
        },
        [dispatch],
    );

    return (
        <Component
            {...props}
            className={cn(modalClassNameAndId, className)}
            opened={selectCustomerLocationSheetOpened}
            onSheetClosed={dialogClosed}
            onPopupClosed={dialogClosed}
            onBackdropClick={dialogClosed}
        >
            <Navbar noHairline noShadow>
                <NavTitle>{t('Delivery location')}</NavTitle>
                <NavRight>
                    <Link iconOnly onClick={dialogClosed}>
                        <Icon ios="f7:multiply" md="material:close" />
                    </Link>
                </NavRight>
            </Navbar>

            <PageContent className="page-content-delivery-location">
                <List noChevron className="select-country-button">
                    <ListItem link title={t('Select a country').toString()} onClick={onCountrySelect}>
                        <IcGlobe slot="media" />
                    </ListItem>
                </List>
                {addresses?.length && (
                    <List mediaList noHairlines noHairlinesBetween className="saved-addressess">
                        {addresses.map((item) => (
                            <ListItem
                                key={item.uid}
                                radio
                                checked={addressUid === item.uid}
                                onClick={makeOnAddressClick(item)}
                                title={constructAddressLine(item)}
                                header={item.country.name}
                            />
                        ))}
                    </List>
                )}
            </PageContent>
        </Component>
    );
};

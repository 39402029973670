import { IApplicationStore } from '@/reducers/rootReducer';
import { AccountStatus, AccountType } from '@/types/account';
import { ProductType } from '@/types/productType';

export function getIsShowBusinessVerificationBanner({
    sessionReducer,
    profileReducer,
    rootReducer,
}: IApplicationStore) {
    const profile =
        sessionReducer.profile && sessionReducer.profile.uid ? sessionReducer.profile : profileReducer.profile;

    return (
        profile &&
        profile.type === AccountType.BUSINESS &&
        profile.status === AccountStatus.BC &&
        rootReducer.activeProductType === ProductType.Product
    );
}

import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportAdvertisementBlockProps } from './ReportAdvertisementBlock.types';

import { ThemedButton } from '@/components/ThemedButton';
import { useAppSelector } from '@/hooks/store';

import './ReportAdvertisementBlock.less';

export const ReportAdvertisementBlock = React.memo<ReportAdvertisementBlockProps>(({ onReportClick, className }) => {
    const { t } = useTranslation();

    const { resizeEvent } = useAppSelector((state) => state.rootReducer);

    const buttonText = useMemo(
        () => (resizeEvent.width > 567 ? 'Report Advertisement' : 'Report Ad'),
        [resizeEvent.width],
    );

    return (
        <div className={cn('product-details-report-advertisement', className)}>
            <p>{t('Do you have any complaints about this product?')}</p>
            <ThemedButton round fill className="product-details-report-advertisement__button" onClick={onReportClick}>
                <p>{t(buttonText)}</p>
            </ThemedButton>
        </div>
    );
});
ReportAdvertisementBlock.displayName = 'ReportAdvertisementBlock';

import { fillClassificatorProductStatusValue } from './classificatorActions';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { client, marketplaceapiURL } from '@/axios';
import { getErrorMessage, handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { CommonMarketplaceApiResponseProduct, Product, ProductControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IProductCreateFormError, IUploadedImageInfo, ProductCreationSteps } from '@/reducers/productCreateReducer';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getColorFromClassificator } from '@/selectors/getColorFromClassificator';
import { LocalStorageItems } from '@/types/localStorage';
import { imageLinksToIUploadedImageInfos, mapProductDetailsImage } from '@/utils';

// CREATE + UPDATE
export const PRODUCT_CREATE_LOADING = 'PRODUCT_CREATE_LOADING' as const;
export const PRODUCT_CREATE_LOADING_SUCCESS = 'PRODUCT_CREATE_LOADING_SUCCESS' as const;
export const PRODUCT_CREATE_LOADING_ERROR = 'PRODUCT_CREATE_LOADING_ERROR' as const;
export const FIRST_PRODUCT_CREATED = 'FIRST_PRODUCT_CREATED' as const;

// LOAD
export const PRODUCT_CREATE_DETAILS_LOADING = 'PRODUCT_CREATE_DETAILS_LOADING' as const;
export const PRODUCT_CREATE_DETAILS_SUCCESS = 'PRODUCT_CREATE_DETAILS_SUCCESS' as const;
export const PRODUCT_CREATE_DETAILS_ERROR = 'PRODUCT_CREATE_DETAILS_ERROR' as const;
export const PRODUCT_CREATE_DETAILS_ERROR_RESET = 'PRODUCT_CREATE_DETAILS_ERROR_RESET' as const;

// DELETE
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS' as const;

// DRAFT
export const PRODUCT_CREATE_UPDATE_DRAFT = 'PRODUCT_CREATE_UPDATE_DRAFT' as const;
export const PRODUCT_CREATE_RESET_DRAFT = 'PRODUCT_CREATE_RESET_DRAFT' as const;

const productCreateLoadingAction = () => ({
    type: PRODUCT_CREATE_LOADING,
});

const productCreateLoadingSuccessAction = (product: IProduct, images: IUploadedImageInfo[]) => ({
    type: PRODUCT_CREATE_LOADING_SUCCESS,
    product,
    images,
});

const productCreateLoadingErrorAction = (error: unknown, parameters: IProductCreateFormError[]) => ({
    type: PRODUCT_CREATE_LOADING_ERROR,
    error,
    parameters,
});

const productCreateDetailsLoadingAction = () => ({
    type: PRODUCT_CREATE_DETAILS_LOADING,
});

const productCreateDetailsSuccessAction = (product: IProduct, images: IUploadedImageInfo[]) => ({
    type: PRODUCT_CREATE_DETAILS_SUCCESS,
    product,
    images,
});

const productCreateDetailsErrorAction = (error: unknown) => ({
    type: PRODUCT_CREATE_DETAILS_ERROR,
    error,
});

const productDeleteSuccessAction = (uid: string) => ({
    type: PRODUCT_DELETE_SUCCESS,
    uid,
});

export const resetProductDraft = () => ({
    type: PRODUCT_CREATE_RESET_DRAFT,
});

export const updateProductDraft = (
    product: Partial<IProduct>,
    images: IUploadedImageInfo[],
    step?: ProductCreationSteps,
) => ({
    type: PRODUCT_CREATE_UPDATE_DRAFT,
    product,
    images,
    step,
});

export const firstProductCreated = () => ({
    type: FIRST_PRODUCT_CREATED,
});

export const productCreateResetErrorAction = () => ({ type: PRODUCT_CREATE_DETAILS_ERROR_RESET });

export type ProductCreateActions = ReturnType<
    | typeof productCreateLoadingAction
    | typeof productCreateLoadingSuccessAction
    | typeof productCreateLoadingErrorAction
    | typeof productCreateDetailsLoadingAction
    | typeof productCreateDetailsSuccessAction
    | typeof productCreateDetailsErrorAction
    | typeof productDeleteSuccessAction
    | typeof resetProductDraft
    | typeof updateProductDraft
    | typeof firstProductCreated
    | typeof productCreateResetErrorAction
>;

const uploadFileForProduct = async (uid: string, file: File) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };

    let filename = file.name;
    const ext = filename.split('.').pop();
    if (!ext || ext.length < 3) {
        filename += '.jpg';
    }

    const formData = new FormData();
    formData.append('file', file, filename);

    const data: CommonMarketplaceApiResponseProduct = (
        await client.post(marketplaceapiURL + `/product/${uid}/upload`, formData, config)
    ).data;

    return data;
};

export const createOrUpdateProduct =
    (_product: IProduct, images: IUploadedImageInfo[] = [], edit = false) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productCreateLoadingAction());

        const imagesLinks = images.map((oneImage) => oneImage.src);

        const state = getState();
        let product = {
            ...(edit ? { ...state.productCreateReducer.product, images: imagesLinks } : {}),
            ..._product,
        };

        const imageThumbnailUrlList = Array.from({ length: 5 }).map(
            (_, index) => product?.['imageThumbnailUrl' + String(index + 1)],
        );

        const imageUrlList: string[] = Array.from({ length: 5 }).map(
            (_, index) => product?.['imageUrl' + String(index + 1)],
        );

        const filteredImages = [];

        imageUrlList.reduce((imageIndex, url, currentIndex) => {
            if (!url || !images.find((oneImage) => +oneImage.id === currentIndex)) {
                filteredImages.push(undefined);
                return imageIndex;
            } else {
                filteredImages.push(images[imageIndex].file ? undefined : images[imageIndex]);
                return imageIndex + 1;
            }
        }, 0);

        filteredImages.forEach((oneImage, index) => {
            if (oneImage) {
                product['imageThumbnailUrl' + String(index + 1)] = imageThumbnailUrlList[+oneImage.id];
                product['imageUrl' + String(index + 1)] = imageUrlList[+oneImage.id];
            }
        });

        product.status = !product.status && !edit ? Product.StatusEnum.DRF : product.status;

        try {
            if (edit) {
                firebaseAnalyticsInstance.productCreated?.(product);
            }

            const controller = new ProductControllerApi();
            let result = edit
                ? await controller.editProductUsingPOST(product)
                : await controller.addProductUsingPUT(product);

            handleResponseAndThrowAnErrorIfExists(result);

            product = result?.body?.[0];

            const imagesToDelete = state.productCreateReducer.images.filter(
                (img) => !images.find(({ src }) => src === img.src),
            );

            if (edit && product.uid && imagesToDelete.length) {
                for (const image of imagesToDelete) {
                    const resp = await new ProductControllerApi().deleteProductImageUsingDELETE(
                        {
                            imageLink: image.src,
                            uid: product.uid,
                        },
                        { headers: { 'Content-Type': 'application/json' } },
                    );
                    handleResponseAndThrowAnErrorIfExists(resp);

                    product = resp?.body?.[0];
                }
            }

            const imagesToAdd = images.filter((item) => !!item.file);

            if (imagesToAdd.length && product.uid) {
                for (const img of imagesToAdd) {
                    const resp = await uploadFileForProduct(product.uid, img.file);
                    handleResponseAndThrowAnErrorIfExists(resp);
                    product = resp?.body?.[0];
                }
            }

            result = await controller.editProductUsingPOST(product);

            handleResponseAndThrowAnErrorIfExists(result);

            product = result?.body?.[0];

            fillClassificatorProductStatusValue([product], state);
            product.images = mapProductDetailsImage(product);
            if (!state.productCreateReducer.firstProductCreated && state.myGoodsReducer.products.length) {
                dispatch(firstProductCreated());
                localStorage.setItem(LocalStorageItems.FIRST_PRODUCT_CREATED, JSON.stringify(true));
            }
            dispatch(productCreateLoadingSuccessAction(product, imageLinksToIUploadedImageInfos(product.images)));
            return product;
        } catch (error) {
            console.error('at productCreateActions in createOrUpdateProduct', error);

            const message = error?.response?.data?.errorData?.message ?? error?.toString();
            const parameters = error?.response?.data?.errorData?.parameters ?? [];

            dispatch(productCreateLoadingErrorAction(message, parameters));
            return undefined;
        }
    };

export const loadDraftProductDetails =
    (uid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productCreateDetailsLoadingAction());
        try {
            const state = getState();

            const response = await new ProductControllerApi().productDetailsUsingPOST({
                uid,
            });
            handleResponseAndThrowAnErrorIfExists(response);
            const product: IProduct = response.body[0];

            fillClassificatorProductStatusValue([product], state);
            product.images = mapProductDetailsImage(product);
            product.colorValue = getColorFromClassificator(state, product.color);
            dispatch(productCreateDetailsSuccessAction(product, imageLinksToIUploadedImageInfos(product.images)));
        } catch (error) {
            console.error('at productCreateActions in loadDraftProductDetails', error);
            dispatch(productCreateDetailsErrorAction(getErrorMessage(error)));
        }
    };

export const deleteProduct = (uid: string) => async (dispatch: AppDispatch) => {
    try {
        await new ProductControllerApi().deleteProductUsingDELETE({ uid });
        dispatch(productDeleteSuccessAction(uid));
    } catch (error) {
        console.error('at productCreateActions in deleteProduct', error);
    }
};

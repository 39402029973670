import {
    PROMOTION_INFO_LOADING,
    PROMOTION_INFO_LOADING_ERROR,
    PROMOTION_INFO_LOADING_SUCCESS,
    PromotionActions,
} from '@/actions/promotionActions';
import { CarouselItem } from '@/components/Carousel';
import { PromotionPage } from '@/generated/marketplaceapi';

export type StoreWidgetProducts = { categoryId: string; products: CarouselItem[] };

export type StoreProductsSearchParams = { category?: string; hashtags?: string; name?: string };

export interface IPromotionState {
    promotionInfo?: PromotionPage;
    promotionInfoLoading: boolean;
    promotionInfoError?: string;
}

const initialState: IPromotionState = {
    promotionInfoLoading: false,
};

const promotionReducer = (state = initialState, action: PromotionActions): IPromotionState => {
    switch (action.type) {
        case PROMOTION_INFO_LOADING:
        case PROMOTION_INFO_LOADING_ERROR:
        case PROMOTION_INFO_LOADING_SUCCESS:
            return { ...state, ...action.payload };
        default: {
            return state;
        }
    }
};

export default promotionReducer;

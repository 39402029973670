import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { RouteParameters } from '@/routes';
import { EProductTypeQuery } from '@/types/URLParams';
import { getProductTypeByUrl } from '@/utils/getProductTypeByUrl';

const routes: RouteParameters[] = [
    {
        path: '/add-business-account',
        async: (_to, _from, resolve) => {
            firebaseAnalyticsInstance.openCreateBusinessAccountPage?.();

            return import('./AddBusinessAccount').then((rc) => resolve({ component: rc.AddBusinessAccount }));
        },
    },
    {
        path: '/store-info/:uid',
        async: (to, from, resolve) => {
            const queryTypes = [getProductTypeByUrl(to.url), getProductTypeByUrl(from.url)];

            if (queryTypes.includes(EProductTypeQuery.SERVICES)) {
                return import('../EditServiceProvider').then((rc) => resolve({ component: rc.EditServiceProvider }));
            }

            return import('./EditStore').then((rc) => resolve({ component: rc.EditStore }));
        },
    },
    {
        path: '/verify-data',
        async: (_to, _from, resolve) =>
            import('@/pages/VerifyInformation').then((rc) => resolve({ component: rc.VerifyInformation })),
    },
];

export default routes;

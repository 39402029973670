import cn from 'classnames';
import {
    AccordionContent,
    AccordionItem,
    Button,
    Link,
    List,
    ListItem,
    Navbar,
    NavRight,
    NavTitle,
    Popup,
    Row,
    Treeview,
} from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddWidgetPopupProps } from './AddWidgetPopup.types';

import { SubCategoryItem } from './SubCategoryItem';

import { IcClose } from '@/assets';
import { CustomCheckbox } from '@/components/CustomCheckbox';
import { Sale } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { Popups } from '@/types/popups';

import './AddWidgetPopup.less';

export const AddWidgetPopup: React.FC<AddWidgetPopupProps> = ({
    popupProps: { className, ...popupProps } = {},
    allowedCategories,
    onSelectCategoriesSubmit,
    onCloseClick,
    selectedCategoriesState,
    title = 'Select Category',
    description = "Choose which categories you want to add to your store's home page",
    saleList,
    selectedSales: selectedSalesInitial,
}) => {
    const { t } = useTranslation();
    const [selectedCategories, setSelectedCategories] = useState<ICategoryClassificator[]>(
        selectedCategoriesState ?? [],
    );

    const [selectedSales, setSelectedSales] = useState<Sale[]>(selectedSalesInitial ?? []);

    const { categories } = useAppSelector((state) => state.categoryReducer);

    const handleCategoryCheckboxClick = useCallback(
        (category: ICategoryClassificator) => (e: React.ChangeEvent<HTMLInputElement>) => {
            e?.target?.checked
                ? setSelectedCategories((prev) => [...prev, category])
                : setSelectedCategories((prev) => prev.filter((cat) => cat.categoryCode !== category.categoryCode));
        },
        [],
    );

    const handleSubmit = useCallback(() => {
        onSelectCategoriesSubmit(selectedCategories, selectedSales);
    }, [onSelectCategoriesSubmit, selectedCategories, selectedSales]);

    useEffect(() => {
        if (popupProps.opened) {
            setSelectedCategories(selectedCategoriesState);
            setSelectedSales(selectedSalesInitial ?? []);
        }
    }, [popupProps.opened, selectedCategoriesState, selectedSalesInitial]);

    const makeHandleSaleClick = useCallback(
        (sale: Sale) => (e: React.ChangeEvent<HTMLInputElement>) => {
            e?.target?.checked
                ? setSelectedSales((prev) => [...prev, sale])
                : setSelectedSales((prev) => prev.filter((cat) => cat.uid !== sale.uid));
        },
        [],
    );

    return (
        <Popup
            {...popupProps}
            id={Popups.ADD_WIDGET_POPUP}
            className={cn(Popups.ADD_WIDGET_POPUP, 'add-widget-popup', className)}
            slot="fixed"
            backdrop
        >
            <Navbar noHairline noShadow>
                <NavTitle>{t(title)}</NavTitle>
                <NavRight>
                    <Link iconOnly onClick={onCloseClick}>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <ListItem className="widget-categories-accordion" accordionItem accordionItemOpened>
                <AccordionContent>
                    <AccordionItem opened>
                        <div className="add-widget-popup-description">{t(description)}</div>
                        {!!saleList?.length && (
                            <>
                                <h2 className="group-title">{t('Sale')}</h2>
                                <List className="sales-list">
                                    {saleList.map((sale) => (
                                        <CustomCheckbox
                                            title={sale.name}
                                            key={sale.uid}
                                            onChange={makeHandleSaleClick(sale)}
                                        />
                                    ))}
                                </List>
                                <h2 className="group-title">{t('Categories')}</h2>
                            </>
                        )}
                        <Treeview>
                            {categories?.map((category) => (
                                <SubCategoryItem
                                    selectedSubcategories={selectedCategories}
                                    key={category.categoryCode}
                                    category={category}
                                    allowedCategories={allowedCategories}
                                    onCheckboxClick={handleCategoryCheckboxClick}
                                />
                            ))}
                        </Treeview>
                        <Row className="category-footer">
                            <Button className="done-button" fill round onClick={handleSubmit}>
                                {t('Done')}
                            </Button>
                        </Row>
                    </AccordionItem>
                </AccordionContent>
            </ListItem>
        </Popup>
    );
};

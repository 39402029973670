import cn from 'classnames';
import { Link } from 'framework7-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FooterProps } from './Footer.types';

import { IcMasterCard, IcMir, IcVisa } from '@/assets';
import { ContactSupportPopup } from '@/components/ContactSupportPopup';
import { EmailSubscription } from '@/components/EmailSubscription';
import { ExternalLink } from '@/components/ExternalLink';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

import { Popups } from '@/types/popups';

import './Footer.less';

const appName = process.env.APP_NAME;

export const Footer = React.memo(({ slot, desktopOnly = true, isMobile: isMobileProp, className }: FooterProps) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);
    const { footerExternalLinks: externalLinks } = useAppSelector((state) => state.rootReducer.localConfig);

    const [isContactSupportOpen, setIsContactSupportOpen] = useState<boolean>(false);

    const isShown = useMemo(
        () => (desktopOnly && !(isMobile || isMobileProp)) || !desktopOnly,
        [desktopOnly, isMobile, isMobileProp],
    );

    return (
        isShown && (
            <footer id="footer" slot={slot} className={cn('footer', className)}>
                <div className="footer__content">
                    <div className="footer__top">
                        <EmailSubscription />

                        <div className="footer__payment-methods">
                            {process.env.MIR_PAYMENT && <IcMir className="icon-mir" />}
                            <IcVisa className="icon-visa" />
                            <IcMasterCard className="icon-mastercard" />
                        </div>
                    </div>

                    <div className="footer__bottom">
                        <Link
                            text={`©${process.env.COPYRIGHT_NAME}`}
                            className="footer__copyright"
                            href={process.env.COPYRIGHT_URL}
                            external
                        />

                        <div className="footer__links-wrap">
                            <Link text={t('About app', { appName }).toString()} popupOpen={`.${Popups.ABOUT_POPUP}`} />
                            {externalLinks?.map((link) => (
                                <ExternalLink link={link} key={link.name} text={t(link.title || link.name)} />
                            ))}
                            <Link text={t('Support')} onClick={() => setIsContactSupportOpen(true)} />
                        </div>
                    </div>
                </div>

                <ContactSupportPopup
                    opened={isContactSupportOpen}
                    category="Application"
                    closePopup={() => setIsContactSupportOpen(false)}
                />
            </footer>
        )
    );
});

Footer.displayName = 'Footer';

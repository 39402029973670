import cn from 'classnames';
import { f7, List } from 'framework7-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailSubscriptionProps } from './EmailSubscription.types';

import { IcArrowRight, IcEnvelope, IcMasterCard, IcMir, IcVisa } from '@/assets';
import { CircleDoneButton } from '@/components/CircleDoneButton';
import { CustomButton } from '@/components/CustomButton';
import { CustomInput } from '@/components/CustomInput';
import { SubscriptionControllerApi } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { useControlledInput } from '@/hooks/useControlledInput';
import { getIsMobile } from '@/selectors/getIsMobile';
import { INVALID_EMAIL_ERROR } from '@/shared/constants';
import { isEmail } from '@/utils';

import './EmailSubscription.less';

const controller = new SubscriptionControllerApi();

export const EmailSubscription = ({ vertical, withHairline }: EmailSubscriptionProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const [email, setEmail] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    const handleInputChange = useControlledInput(setEmail);

    const handleSubscribe = useCallback(() => {
        controller
            .subscribeUsingPOST1({ email })
            .then((response) => {
                setSubscribed(!!response.successful);
            })
            .catch((err) => {
                console.error('in EmailSubscription at handleSubscribe', err);
                f7.dialog.alert(t(err.message ?? err?.response?.data?.errorData?.message));
            });
    }, [email, t]);

    const isEmailValid = useMemo(() => isEmail(email), [email]);

    return (
        <div
            className={cn('email-subscription', {
                'email-subscription-vertical': vertical,
                'email-subscription-hairlined': withHairline,
            })}
        >
            <div className="email-subscription__icon">
                <IcEnvelope />
            </div>
            {subscribed ? (
                <>
                    <p className="email-subscription__text">{t('Thanks! You are subscribed to the newsletter')}</p>
                </>
            ) : (
                <>
                    <p className="email-subscription__text">
                        {t('Subscribe to the newsletter right now and get a special offers')}
                    </p>
                    <List>
                        <CustomInput
                            name="email"
                            type="email"
                            inputmode="email"
                            label={vertical ? t('Email') : undefined}
                            placeholder={vertical ? undefined : t('Email')}
                            errorMessage={t(INVALID_EMAIL_ERROR)}
                            errorMessageForce={!isEmailValid && !!email}
                            floatingLabel={vertical}
                            onChange={handleInputChange}
                        />
                    </List>
                    {vertical ? (
                        <CustomButton
                            size="large"
                            variant="primary"
                            aria-label="submit email subscription"
                            disabled={!isEmailValid}
                            fullWidth
                            onClick={handleSubscribe}
                        >
                            {t('Subscribe')}
                        </CustomButton>
                    ) : (
                        <CircleDoneButton
                            className="email-subscription__done"
                            aria-label="submit email subscription"
                            disabled={!isEmailValid}
                            onClick={handleSubscribe}
                        >
                            <IcArrowRight />
                        </CircleDoneButton>
                    )}
                </>
            )}
            {isMobile && (
                <div className="email-subscription__payment-methods">
                    {t('We accept the following payment method')}

                    <div className="email-subscription__payment-methods-icons">
                        {process.env.MIR_PAYMENT && <IcMir />}
                        <IcVisa className="icon-visa" />
                        <IcMasterCard />
                    </div>
                </div>
            )}
        </div>
    );
};

import {
    ISearchParams,
    PRODUCT_ADDED_TO_WISH,
    PRODUCT_DETAILS_LOADING,
    PRODUCT_DETAILS_LOADING_ERROR,
    PRODUCT_DETAILS_LOADING_SUCCESS,
    PRODUCT_DETAILS_RESET,
    PRODUCT_LIST_CATEGORY_LOADING_SUCCESS,
    PRODUCT_LIST_LOADING,
    PRODUCT_LIST_LOADING_ERROR,
    PRODUCT_LIST_LOADING_SUCCESS,
    PRODUCT_LIST_TYPE_GROUPS_LOADING,
    PRODUCT_LIST_TYPE_LOADING_SUCCESS,
    PRODUCT_LIST_TYPES_RELOADING_SUCCESS,
    PRODUCT_PRESEARCH_CLEAR,
    PRODUCT_PRESEARCH_ERROR,
    PRODUCT_PRESEARCH_LOADING,
    PRODUCT_PRESEARCH_SUCCESS,
    PRODUCT_SEARCH_CATEGORIES_LOADING,
    PRODUCT_SEARCH_CATEGORIES_LOADING_SUCCESS,
    PRODUCT_WISH_LIST_LOADING,
    PRODUCT_WISH_LIST_LOADING_ERROR,
    PRODUCT_WISH_LIST_LOADING_SUCCESS,
    ProductActions,
    RECOMMENDED_PRODUCTS_LOADING,
    RECOMMENDED_PRODUCTS_LOADING_ERROR,
    RECOMMENDED_PRODUCTS_LOADING_SUCCESS,
    SEARCH_SAVE_LOADING,
    SEARCH_UPDATE_RESULT_COUNT,
    PRODUCT_LIST_TYPES_CLEAR,
} from '@/actions/productActions';
import { Product, ProductCategory, SearchResult } from '@/generated/marketplaceapi';

export interface IProduct extends Product {
    images?: string[];
    imageThumbnails?: string[];
    wish?: boolean;
    colorValue?: string;
    statusValue?: string;
    isProductEditAllowed?: boolean;
    place?: string;
    placeId?: string;
    promotion?: boolean;
}

export interface IProductState {
    loading?: boolean;
    error?: unknown;
    products: IProduct[];
    productsListType?: ProductListType;
    totalCount?: number;
    totalCountPreview?: number;
    count?: number;
    offset?: number;
    searchTerm?: string;
    searchParams?: ISearchParams;
    productsAllGoods?: IProduct[];
    presearchResults?: SearchResult[];
    searchCategories?: ProductCategory[];
    searchCategoriesLoading: boolean;
    presearchLoading?: boolean;
    presearchError?: unknown;
    productDetails?: IProduct;
    productDetailsLoading?: boolean;
    productDetailsLoadingError?: unknown;
    productTypeGroups?: { type: ProductListType; products: IProduct[] }[];
    productCategoryGroups?: { type: ProductListType; products: IProduct[] }[];
    productTypeGroupsLoading?: boolean;
    productsWishListLoading?: boolean;
    productsWishList?: IProduct[];
    productsWishListLoadingError?: unknown;
    recommendedProducts?: Product[];
    recommendedProductsLoading?: boolean;
    recommendedProductsError?: unknown;

    loadingAutocomplete?: boolean;
    productsAutocomplete: IProduct[];
}

export type ProductListType =
    | 'cheapest'
    | 'expensive'
    | 'new'
    | 'popular'
    | 'random'
    | 'recently_viewed'
    | 'hot_deals'
    | 'recommended'
    | 'bestsellers'
    | 'all'
    | string;

const initialState: IProductState = {
    loading: false,
    error: undefined,
    products: [],
    productsListType: null,
    count: 10,
    offset: 0,
    searchTerm: undefined,
    productsAllGoods: [],
    presearchResults: [],
    presearchLoading: false,
    presearchError: undefined,
    productDetails: null,
    productDetailsLoading: false,
    productDetailsLoadingError: null,
    productTypeGroups: [],
    productTypeGroupsLoading: false,
    productCategoryGroups: [],
    productsWishListLoading: null,
    productsWishList: [],
    productsWishListLoadingError: null,
    searchCategoriesLoading: false,
    recommendedProducts: [],
    recommendedProductsLoading: false,
    recommendedProductsError: null,

    loadingAutocomplete: false,
    productsAutocomplete: [],
};

const productReducer = (state = initialState, action: ProductActions): IProductState => {
    switch (action.type) {
        case PRODUCT_LIST_LOADING:
            if (action.autocomplete) {
                return {
                    ...state,
                    loadingAutocomplete: true,
                };
            }

            return {
                ...state,
                loading: true,
                error: null,
            };
        case PRODUCT_LIST_LOADING_SUCCESS: {
            const { products, is_clear, searchTerm, totalCount, is_reset, isResetSorting, searchParams } = action;

            if (action.autocomplete) {
                return {
                    ...state,
                    loadingAutocomplete: false,
                    productsAutocomplete: is_clear ? [] : products,
                };
            }

            const { offset } = state;
            const prevSearchTerm = state.searchTerm;

            if (is_clear) {
                return {
                    ...state,
                    loading: false,
                    error: null,
                    products: [],
                    productsAutocomplete: [],
                    totalCount: null,
                    offset: 0,
                    searchTerm: null,
                };
            }

            if (searchTerm !== prevSearchTerm || isResetSorting) {
                return {
                    ...state,
                    loading: false,
                    error: null,
                    products: [...products],
                    totalCount,
                    offset: initialState.count, /// ?????????????????????????
                    searchTerm,
                    searchParams,
                };
            }

            return {
                ...state,
                loading: false,
                error: null,
                products: is_reset ? [] : products ? [...state.products, ...products] : state.products,
                totalCount,
                offset: offset + (products && products.length ? initialState.count : 0),
                searchTerm: searchTerm,
            };
        }
        case PRODUCT_LIST_LOADING_ERROR: {
            const { error } = action;
            return {
                ...state,
                loading: false,
                error,
            };
        }
        case PRODUCT_LIST_TYPE_GROUPS_LOADING: {
            return { ...state, productTypeGroupsLoading: action.listTypeGroupsLoading };
        }
        case PRODUCT_LIST_TYPE_LOADING_SUCCESS: {
            const { products, listType } = action;
            let productTypeGroups = [...state.productTypeGroups];

            const group = productTypeGroups.find((item) => item.type === listType);

            if (!group) {
                productTypeGroups.push({ type: listType, products: products || [] });
            } else {
                productTypeGroups = productTypeGroups.map((pGroup) => {
                    if (pGroup.type === listType) {
                        return { type: pGroup.type, products: products || pGroup.products };
                    }

                    return pGroup;
                });
            }

            return {
                ...state,
                productTypeGroups,
            };
        }

        case PRODUCT_LIST_CATEGORY_LOADING_SUCCESS: {
            const { products, listType } = action;
            const productCategoryGroups = [...state.productCategoryGroups];

            const group = productCategoryGroups.find((item) => item.type === listType);

            if (!group) {
                productCategoryGroups.push({ type: listType, products: products || [] });
            }

            return {
                ...state,
                productCategoryGroups,
            };
        }
        case PRODUCT_LIST_TYPES_RELOADING_SUCCESS: {
            const { items } = action;

            return {
                ...state,
                productTypeGroups: items,
                productCategoryGroups: items,
            };
        }
        case PRODUCT_LIST_TYPES_CLEAR: {
            return {
                ...state,
                productTypeGroups: initialState.productTypeGroups,
                productCategoryGroups: initialState.productCategoryGroups,
            };
        }
        case PRODUCT_DETAILS_LOADING: {
            return {
                ...state,
                productDetailsLoading: true,
                productDetailsLoadingError: null,
            };
        }
        case PRODUCT_DETAILS_LOADING_SUCCESS: {
            return {
                ...state,
                productDetails: action.product,
                productDetailsLoading: false,
            };
        }
        case PRODUCT_DETAILS_LOADING_ERROR: {
            return {
                ...state,
                productDetails: null,
                productDetailsLoading: false,
                productDetailsLoadingError: action.error,
            };
        }
        case PRODUCT_DETAILS_RESET: {
            return {
                ...state,
                productDetails: undefined,
            };
        }
        case PRODUCT_ADDED_TO_WISH: {
            const { uid } = action;

            const item = state.products.find((item) => item.uid === uid);
            if (item) {
                item.wish = !item.wish;
            }

            const { productTypeGroups } = state;

            const groups = productTypeGroups.filter((item) => !!item.products.find((item) => item.uid === uid));
            groups.forEach((item) => {
                item.products
                    .filter((item) => item.uid === uid)
                    .forEach((item) => {
                        item.wish = !item.wish;
                    });
            });

            const { productsWishList } = state;

            return {
                ...state,
                products: [...state.products],
                productsWishList: productsWishList.find((item) => item.uid === uid)
                    ? productsWishList.filter((item) => item.uid !== uid)
                    : [...productsWishList],
            };
        }
        case PRODUCT_WISH_LIST_LOADING: {
            return {
                ...state,
                productsWishListLoading: true,
                productsWishListLoadingError: null,
            };
        }
        case PRODUCT_WISH_LIST_LOADING_SUCCESS: {
            const { productDetails } = state;
            const wishList = action.products;

            if (productDetails && wishList.filter((item) => item.uid === productDetails.uid)) {
                state.productDetails = { ...productDetails, wish: true };
            }

            return {
                ...state,
                productsWishListLoading: false,
                productsWishList: action.products || [],
            };
        }
        case PRODUCT_WISH_LIST_LOADING_ERROR: {
            return {
                ...state,
                productsWishListLoading: false,
                productsWishListLoadingError: action.error,
            };
        }
        case PRODUCT_PRESEARCH_LOADING: {
            return {
                ...state,
                presearchError: undefined,
                presearchLoading: true,
            };
        }
        case PRODUCT_PRESEARCH_SUCCESS: {
            return {
                ...state,
                presearchLoading: false,
                presearchResults: action.results,
            };
        }
        case PRODUCT_PRESEARCH_ERROR: {
            return {
                ...state,
                presearchLoading: false,
                presearchError: action.error,
            };
        }
        case PRODUCT_PRESEARCH_CLEAR: {
            return {
                ...state,
                presearchResults: [],
                presearchLoading: false,
                presearchError: undefined,
            };
        }
        case SEARCH_UPDATE_RESULT_COUNT: {
            return {
                ...state,
                loading: false,
                error: null,
                totalCountPreview: action.totalCount,
            };
        }
        case SEARCH_SAVE_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case PRODUCT_SEARCH_CATEGORIES_LOADING: {
            return { ...state, searchCategoriesLoading: action.searchCategoriesLoading };
        }
        case PRODUCT_SEARCH_CATEGORIES_LOADING_SUCCESS: {
            return { ...state, searchCategories: action.searchCategories };
        }
        case RECOMMENDED_PRODUCTS_LOADING: {
            return { ...state, recommendedProductsLoading: true, recommendedProductsError: null };
        }
        case RECOMMENDED_PRODUCTS_LOADING_SUCCESS: {
            return {
                ...state,
                recommendedProductsLoading: false,
                recommendedProducts: action.payload.products,
            };
        }
        case RECOMMENDED_PRODUCTS_LOADING_ERROR: {
            return {
                ...state,
                recommendedProductsLoading: false,
                recommendedProductsError: action.payload.error,
            };
        }
        default: {
            return state;
        }
    }
};

export default productReducer;

import cn from 'classnames';
import React from 'react';

import { PersonalAreaShrinkMenuProps } from './PersonalAreaShrinkMenu.types';

import { PageShrinkMenu } from '@/components/PageSideMenu/PageShrinkMenu';

import './PersonalAreaShrinkMenu.less';

export const PersonalAreaShrinkMenu = ({ className, ...props }: PersonalAreaShrinkMenuProps) => (
    <PageShrinkMenu {...props} className={cn('personal-area-shrink-menu', className)} />
);

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { ImageSwitcherProps } from './ImageSwitcher.types';

import { createThumbnailVideoURLLink, getBackgroundStyle } from '@/utils';

import './ImageSwitcher.less';

export const ImageSwitcher = ({ imagesItems, onItemClick, selectedIndex, className }: ImageSwitcherProps) => {
    const shownItems = useMemo(() => imagesItems.slice(0, 6), [imagesItems]);
    const createItemClickHandler = useCallback((index: number) => () => onItemClick(index), [onItemClick]);

    if (imagesItems.length === 1) return null;

    return (
        <div className={cn('image-switcher', className)}>
            {shownItems.map((item, i) => (
                <div
                    key={item.imageThumbnailUrl1 ?? item.videoId ?? item.uid ?? i}
                    className={cn('image-switcher-item', { selected: selectedIndex === i })}
                    onClick={createItemClickHandler(i)}
                >
                    {item.videoId ? (
                        <div
                            className="video-background"
                            style={getBackgroundStyle(createThumbnailVideoURLLink(item.videoId, item.videoType))}
                        />
                    ) : (
                        <img src={item.imageThumbnailUrl1} alt="" />
                    )}
                </div>
            ))}
        </div>
    );
};

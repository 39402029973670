import { Dispatch } from 'redux';

import { loadStoreDetails } from './storeActions';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { FavoriteStoreControllerApi, Store } from '@/generated/marketplaceapi';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { productToIProduct } from '@/utils';

export const FAVORITES_LOADING = 'FAVORITES_LOADING' as const;
export const FAVORITES_LOAD_SUCCESS = 'FAVORITES_LOAD_SUCCESS' as const;
export const FAVORITES_LOAD_ERROR = 'FAVORITES_LOAD_ERROR' as const;
export const FAVORITES_ADD = 'FAVORITES_ADD' as const;
export const FAVORITES_REMOVE = 'FAVORITES_REMOVE' as const;

const favoritesLoading = () => ({
    type: FAVORITES_LOADING,
});

const favoritesSuccess = (stores: Store[]) => ({
    type: FAVORITES_LOAD_SUCCESS,
    stores,
});

const favoritesError = (error: unknown) => ({
    type: FAVORITES_LOAD_ERROR,
    error,
});

export type FavoritesActions = ReturnType<typeof favoritesLoading | typeof favoritesSuccess | typeof favoritesError>;

export const loadFavorites = () => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
    const state = getState();
    const loggedIn = isLoggedIn(state);

    if (!loggedIn) {
        return;
    }

    try {
        dispatch(favoritesLoading());
        const withProducts = !getIsMobile(state);
        const response = await new FavoriteStoreControllerApi().getFavoriteStoresUsingGET(
            getLanguageCode(state),
            withProducts,
        );

        handleResponseAndThrowAnErrorIfExists(response);

        const stores = response.body ?? [];

        stores.forEach((store) => {
            if (store.products) {
                store.products = store.products.map((product) => productToIProduct(product, state, true));
            }
        });

        dispatch(favoritesSuccess(stores));
    } catch (error) {
        console.error('at favoritesActions in loadFavorites', error);
        dispatch(favoritesError(String(error.message)));
    }
};

export const addToFavorite = (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
    const state = getState();
    const loggedIn = isLoggedIn(state);
    if (!loggedIn) {
        return;
    }

    try {
        dispatch(favoritesLoading());
        const withProducts = !getIsMobile(state);
        const response = await new FavoriteStoreControllerApi().addStoreToFavoriteUsingPUT(
            storeUid,
            getLanguageCode(state),
            withProducts,
        );

        handleResponseAndThrowAnErrorIfExists(response);

        const stores = response.body ?? [];

        stores.forEach((store) => {
            if (store.products) {
                store.products = store.products.map((product) => productToIProduct(product, state, true));
            }
        });

        dispatch(favoritesSuccess(stores));

        const addedStore = stores.find(({ uid }) => uid === storeUid);
        firebaseAnalyticsInstance.addStoreToFavorites?.(addedStore);
    } catch (error) {
        console.error('at favoritesActions in addToFavorite', error);
        dispatch(favoritesError(String(error.message)));
    }
};

export const removeFromFavorite =
    (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const loggedIn = isLoggedIn(state);

        if (!loggedIn) {
            return;
        }

        try {
            dispatch(favoritesLoading());
            const withProducts = !getIsMobile(state);
            const response = await new FavoriteStoreControllerApi().removeStoreFromFavoriteUsingDELETE(
                storeUid,
                getLanguageCode(state),
                withProducts,
            );

            handleResponseAndThrowAnErrorIfExists(response);

            const stores = response.body ?? [];

            stores.forEach((store) => {
                if (store.products) {
                    store.products = store.products.map((product) => productToIProduct(product, state, true));
                }
            });

            dispatch(favoritesSuccess(stores));

            const removedStore = await loadStoreDetails(
                storeUid,
                getCountryCodeFromState(getState()),
                getLanguageCode(getState()),
            );
            firebaseAnalyticsInstance.removeStoreFromFavorites?.(removedStore);
        } catch (error) {
            console.error('at favoritesActions in removeFromFavorite', error);
            dispatch(favoritesError(String(error.message)));
        }
    };

import React from 'react';

import { SearchResultType } from './PresearchResultItem.types';

import { IcHashtag, IcSearch, IcStore } from '@/assets';

export const getResultIcon = (type: SearchResultType) => {
    switch (type) {
        case SearchResultType.PRODUCT_NAME: {
            return IcSearch;
        }
        case SearchResultType.HASHTAG: {
            return IcHashtag;
        }
        case SearchResultType.CATEGORY: {
            return IcSearch;
        }
        case SearchResultType.STORE: {
            return IcStore;
        }
        default:
            return React.Fragment;
    }
};

export const getResultType = (type: string): SearchResultType => {
    switch (true) {
        case type === 'product.name': {
            return SearchResultType.PRODUCT_NAME;
        }
        case type === 'hashtag': {
            return SearchResultType.HASHTAG;
        }
        case type === 'store.name': {
            return SearchResultType.STORE;
        }
        case type.includes('category'): {
            return SearchResultType.CATEGORY;
        }
        default:
            return undefined;
    }
};

import classNames from 'classnames';
import { List, ListItem, Sheet } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SaleProductActionsSheetProps } from './SaleProductActionsSheet.types';

import { IcDelete, IcEdit } from '@/assets';
import { Sheets } from '@/types/sheets';

import './SaleProductActionsSheet.less';

export const SaleProductActionsSheet = ({
    onEditClick,
    onDeleteClick,
    className,
    ...props
}: SaleProductActionsSheetProps) => {
    const { t } = useTranslation();

    return (
        <Sheet
            {...props}
            backdrop
            swipeToClose={false}
            id={Sheets.SALE_PRODUCT_ACTIONS_SHEET}
            className={classNames(Sheets.SALE_PRODUCT_ACTIONS_SHEET, 'sale-product-actions-sheet', className)}
        >
            <List noHairlines noChevron>
                <ListItem link sheetClose title={t('Edit Amount Discounted')} onClick={onEditClick}>
                    <IcEdit slot="media" />
                </ListItem>
                <ListItem link sheetClose title={t('Delete Product')} onClick={onDeleteClick}>
                    <IcDelete slot="media" />
                </ListItem>
            </List>
        </Sheet>
    );
};

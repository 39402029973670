import cn from 'classnames';
import { F7Searchbar, Preloader } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PresearchResultItem } from './PresearchResultItem';

import { SearchBarProps } from './SearchBar.types';

import { IcSearch } from '@/assets';
import { CustomChip } from '@/components/CustomChip';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { getIsMobile } from '@/selectors/getIsMobile';

import './SearchBar.less';

export const SearchBar = ({
    searchBarFocus,
    onChange,
    onSearchbarEnable,
    onSearchbarDisable,
    storeData,
    ...props
}: SearchBarProps): JSX.Element => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);
    const { presearchResults, presearchLoading } = useAppSelector((state) => state.productReducer);

    const [enabled, setEnabled] = useState(false);
    const [value, setValue] = useState('');

    const [storeFilterForceRemoved, setStoreFilterForceRemoved] = useState(false);
    const [removeStoreFilter] = useBooleanState(setStoreFilterForceRemoved);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            setValue(value);
            onChange(e);
        },
        [onChange],
    );

    const handleSearchbarEnable = useCallback(() => {
        setEnabled(true);

        if (onSearchbarEnable) {
            onSearchbarEnable();
        }
    }, [onSearchbarEnable]);

    const handleSearchbarClear = useCallback(() => {
        setValue('');
        props.onSearchbarClear?.();
    }, [props]);

    const handleSearchbarDisable = useCallback(() => {
        setEnabled(false);
        onSearchbarDisable?.();
        handleSearchbarClear();
    }, [handleSearchbarClear, onSearchbarDisable]);

    const opened = enabled && searchBarFocus && (isMobile || value);

    const shouldShowSearchResults = enabled && searchBarFocus && value && !presearchLoading;

    return (
        <div className={cn('search-bar-wrap', { opened })}>
            <F7Searchbar
                {...props}
                className={cn('search-bar', 'custom-searchbar', {
                    'with-value': !!value,
                    opened,
                    'not-enabled': !enabled,
                    focused: enabled && searchBarFocus && !opened,
                })}
                value={value}
                clearButton={enabled && searchBarFocus}
                placeholder={t('Search')}
                disableButtonText={t('Cancel')}
                onSearchbarEnable={handleSearchbarEnable}
                onSearchbarDisable={handleSearchbarDisable}
                onFocus={handleSearchbarEnable}
                onSearchbarClear={handleSearchbarClear}
                onChange={handleChange}
            >
                <div
                    className={cn('search-icon', {
                        'with-chip': !!storeData && !storeFilterForceRemoved,
                    })}
                    slot="input-wrap-start"
                >
                    {!storeData || storeFilterForceRemoved ? (
                        isMobile ? (
                            <IcSearch className="search" />
                        ) : (
                            <IcSearch className="search" onClick={handleSearchbarEnable} />
                        )
                    ) : (
                        <CustomChip
                            className="store-chip"
                            text={storeData.name}
                            deletable
                            onDelete={removeStoreFilter}
                        />
                    )}
                </div>
            </F7Searchbar>

            {opened && (
                <div className="autocomplete-search-result">
                    {presearchLoading && (
                        <div className="preloader-container">
                            <Preloader />
                        </div>
                    )}
                    {shouldShowSearchResults &&
                        presearchResults.map((searchResultItem, index) =>
                            searchResultItem ? (
                                <PresearchResultItem
                                    key={`${searchResultItem.result}-${index}`}
                                    response={searchResultItem}
                                    storeData={storeFilterForceRemoved ? undefined : storeData}
                                />
                            ) : (
                                <></>
                            ),
                        )}
                    {shouldShowSearchResults && !presearchResults.length && (
                        <div className="no-results">{t('No results')}</div>
                    )}
                </div>
            )}
        </div>
    );
};

import { Country } from '@/generated/commonapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';

export const CUSTOMER_LOCATION_TOGGLE_SELECT_CUSTOMER_LOCATION_SHEET =
    'CUSTOMER_LOCATION_TOGGLE_SELECT_CUSTOMER_LOCATION_SHEET' as const;
export const CUSTOMER_LOCATION_TOGGLE_SELECT_COUNTRY_POPUP = 'CUSTOMER_LOCATION_TOGGLE_SELECT_COUNTRY_POPUP' as const;
export const CUSTOMER_LOCATION_CHANGE_COUNTRY = 'CUSTOMER_LOCATION_CHANGE_COUNTRY' as const;
export const CUSTOMER_LOCATION_CHANGE_ADDRESS = 'CUSTOMER_LOCATION_CHANGE_ADDRESS' as const;

export const toggleSelectCustomerLocationSheet = (open = false) => ({
    type: CUSTOMER_LOCATION_TOGGLE_SELECT_CUSTOMER_LOCATION_SHEET,
    open,
});

export const toggleSelectCountryPopup = (open = false) => ({
    type: CUSTOMER_LOCATION_TOGGLE_SELECT_COUNTRY_POPUP,
    open,
});

export const changeAddress = (addressUid: string) => ({
    type: CUSTOMER_LOCATION_CHANGE_ADDRESS,
    addressUid,
});

const customerLocationCountryChange = (country: Country, clearAddressUid: boolean) => ({
    type: CUSTOMER_LOCATION_CHANGE_COUNTRY,
    country,
    clearAddressUid,
});

export type CustomerLocationActions = ReturnType<
    | typeof toggleSelectCustomerLocationSheet
    | typeof toggleSelectCountryPopup
    | typeof changeAddress
    | typeof customerLocationCountryChange
>;

export const changeCountry =
    (code: string, clearAddressUid = false) =>
    (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();

        const country = state.classificatorReducer.countryClassificatorMappedByCountryCode[code];

        dispatch(customerLocationCountryChange(country, clearAddressUid));
    };

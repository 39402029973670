export const getConditionalURL = (url?: string) => {
    if (!url) {
        return new URL(window.location.href.replace('/#', ''));
    }

    try {
        return new URL(url);
    } catch (error) {
        const base = `${window.location.protocol}//${window.location.host}${
            process.env.NODE_ENV === 'development' ? '/' : process.env.APP_PATH
        }`;

        return new URL(url, base);
    }
};

import React, { useCallback } from 'react';

import { CarouselItemCardProps } from '@/components/Carousel/CarouselItemCard/CarouselItemCard.types';
import { ProductCard } from '@/components/ProductCard/ProductCard';

export const XSmallItem = ({ item, onClickAddToWish }: Partial<CarouselItemCardProps>): JSX.Element => {
    const onLikeClick = useCallback(() => onClickAddToWish(item.uid), [item.uid, onClickAddToWish]);
    const handleItemClick = useCallback(() => item.onClick?.(item), [item]);

    return <ProductCard product={item} onLikeChange={onLikeClick} onClick={handleItemClick} />;
};

import { useMonth } from '@datepicker-react/hooks';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Day } from './Day';
import { MonthProps } from './Month.types';
import { customDayLabelFormat, customWeekdayLabelFormat } from './utils';

import { createNodeUid } from '@/utils';

import './Month.less';

export const Month = React.memo(({ year, month, firstDayOfWeek, closeModalOnDayClick }: MonthProps): JSX.Element => {
    const { t } = useTranslation();

    // ! do not remove t function from deps
    const monthLabelFormat = useMemo(() => (date: Date) => t(dayjs().localeData().months()[date.getMonth()]), [t]);

    // ! do not remove t function from deps
    const weekdayLabelFormat = useMemo(() => (date: Date) => t(customWeekdayLabelFormat(date)), [t]);

    const { days, weekdayLabels, monthLabel } = useMonth({
        year,
        month,
        firstDayOfWeek,
        dayLabelFormat: customDayLabelFormat,
        weekdayLabelFormat,
        monthLabelFormat,
    });

    const { days: prevDays } = useMonth({
        year,
        month: month - 1,
        firstDayOfWeek,
        dayLabelFormat: customDayLabelFormat,
        weekdayLabelFormat,
    });

    const dayArr = useMemo(() => {
        const emptyDaysNum = days.filter((day) => typeof day !== 'object').length;
        let i = prevDays.length - emptyDaysNum;
        const daysWithDisabled = days.map((day, index) => {
            const prevDay = prevDays[i];
            if (typeof day !== 'object') {
                if (typeof prevDay !== 'object') {
                    return <div key={index} />;
                }
                i += 1;
                return (
                    <button className="day-disabled" key={index}>
                        {prevDay.dayLabel}
                    </button>
                );
            }
            return (
                <Day
                    closeModalOnDayClick={closeModalOnDayClick}
                    key={day.date.toString()}
                    date={day.date}
                    dayLabel={day.dayLabel}
                />
            );
        });
        i = 1;
        while (daysWithDisabled.length % 7 !== 0) {
            daysWithDisabled.push(
                <button className="day-disabled" key={createNodeUid()}>
                    {i}
                </button>,
            );
            i += 1;
        }

        return daysWithDisabled;
    }, [closeModalOnDayClick, days, prevDays]);

    return (
        <div className="datepicker-calendar-month">
            <div className="datepicker-calendar-month-label">{monthLabel}</div>
            <div className="datepicker-calendar-month-weekdays">
                {weekdayLabels.map((dayLabel) => (
                    <div key={dayLabel} className="datepicker-calendar-month-weekday">
                        {dayLabel}
                    </div>
                ))}
            </div>
            <div className="datepicker-calendar-month-days">{dayArr}</div>
        </div>
    );
});

Month.displayName = 'Month';

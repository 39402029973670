import { useMemo } from 'react';

import { useAppSelector } from './store';

import { CustomSelectValue } from '@/components/CustomSelect';
import { countryToCustomSelectValue } from '@/utils';

type useCountryOptionsProps = {
    withIcons?: boolean;
    filterCountries?: string[];
};

export const useCountryOptions = ({ withIcons = false, filterCountries }: useCountryOptionsProps = {}) => {
    const { countryClassificator } = useAppSelector((state) => state.classificatorReducer);

    return useMemo(
        () =>
            countryClassificator
                ?.filter((country) => (filterCountries ? filterCountries.includes(country.code) : true))
                .map<CustomSelectValue>((country) => countryToCustomSelectValue(country, withIcons)) ?? [],
        [countryClassificator, withIcons, filterCountries],
    );
};

import { SearchResult, Store } from '@/generated/marketplaceapi';

export enum SearchResultType {
    PRODUCT_NAME = 'product.name',
    HASHTAG = 'hashtag',
    CATEGORY = 'category',
    STORE = 'store',
}

export type PresearchResultItemProps = {
    response: SearchResult;
    storeData?: Store;
};

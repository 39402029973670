import cn from 'classnames';
import { f7, Link, ListButton, Navbar, NavLeft, NavRight, Sheet } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MessengerMenuProps } from './MessengerMenu.types';

import { PopoverPositions } from '../CustomPopover';

import { IcClose, IcMessages } from '@/assets';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { MessengerOptions } from '@/components/MessengerMenu/MessengerOptions';
import { useAppSelector } from '@/hooks/store';
import { useNodeUid } from '@/hooks/useNodeUid';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ProductType } from '@/types/productType';
import { Sheets } from '@/types/sheets';

import './MessengerMenu.less';

export const MessengerMenu = ({ className, seller, isStore = false }: MessengerMenuProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const activeProductType = useAppSelector(getActiveProductType);

    const id = useNodeUid();

    const handleSendMessage = useCallback(() => {
        f7.sheet.get(`#${Sheets.MESSENGER_SHEET}`).open();
    }, []);

    const getMessageButton = useCallback(
        (onClick: () => void) => {
            if (isStore) {
                return (
                    <ListButton onClick={onClick}>
                        <IcMessages />
                        {t('Send Message')}
                    </ListButton>
                );
            }

            return (
                activeProductType === ProductType.Advertisement && (
                    <Link className={cn('product-details-seller__contact-with-seller')} onClick={onClick}>
                        <IcMessages />
                    </Link>
                )
            );
        },
        [activeProductType, isStore, t],
    );

    const renderMessageButton = useMemo(() => {
        if (isMobile) {
            return getMessageButton(handleSendMessage);
        }

        return (
            <CustomPopoverWrapper
                closeByClickInside
                className={cn('messenger-popover', { left: isStore, right: !isStore })}
                popoverTriggerElement={(togglePopover) => getMessageButton(togglePopover)}
                availablePositions={[PopoverPositions.BOTTOM_LEFT_CORNER]}
            >
                {() => <MessengerOptions seller={seller} />}
            </CustomPopoverWrapper>
        );
    }, [getMessageButton, handleSendMessage, isMobile, isStore, seller]);

    return (
        <div id={id} className={cn('messenger-menu', { 'menu-with-seller': !isStore }, className)}>
            {renderMessageButton}
            <Sheet id={Sheets.MESSENGER_SHEET} className={cn(Sheets.MESSENGER_SHEET, 'messenger-sheet')} backdrop>
                <Navbar className={cn('messenger-sheet__navbar')}>
                    <NavLeft>{t('Send Message')}</NavLeft>
                    <NavRight>
                        <Link sheetClose>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
                <MessengerOptions seller={seller} />
            </Sheet>
        </div>
    );
};

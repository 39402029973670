import dayjs from 'dayjs';

import { CustomSelectValue } from '@/components/CustomSelect';

export const apiHoursToCustomSelectValue = (productRentHours: string): CustomSelectValue => {
    const parsedTime = dayjs(productRentHours, 'HH:mm');
    return {
        label: parsedTime.format('hh:mm A').toUpperCase(),
        value: `${parsedTime.hour()}`,
    };
};

import React, { useMemo } from 'react';

import { ImportProductsMobileStepsProps } from './ImportProductsMobileSteps.types';

import { MobileStepsControls } from '@/components/MobileStepsControls';
import { ImportProductsSteps } from '@/pages/ImportProducts/ImportProducts.types';

import './ImportProductsMobileSteps.less';

export const ImportProductsMobileSteps = ({
    step,
    onPrevStep,
    onNextStep,
    stepChangeDisabled,
    nextButtonText,
}: ImportProductsMobileStepsProps) => {
    const progressSteps = useMemo(
        () =>
            Object.values(ImportProductsSteps).reduce(
                (accum, val) => (typeof val === 'number' ? (accum.push(val), accum) : accum),
                [],
            ),
        [],
    );

    const stepsCount = useMemo(() => progressSteps.length - 2, [progressSteps.length]);

    const progressStep = useMemo(() => progressSteps.indexOf(step), [progressSteps, step]);

    return (
        <MobileStepsControls
            className="import-products-mobile-steps"
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            prevVisible={step !== ImportProductsSteps.CONFIGURE_IMPORT}
            progressStep={progressStep}
            stepChangeDisabled={stepChangeDisabled}
            stepsCount={stepsCount}
            nextButtonText={nextButtonText}
        />
    );
};

import cn from 'classnames';
import React, { useCallback } from 'react';

import { CarouselControlButtonsProps } from './CarouselControlButtons.types';

import { IcArrow } from '@/assets';
import { stopPropagation } from '@/utils';

import './CarouselControlButtons.less';

export const CarouselControlButtons = ({
    slidesLength,
    activeIndex,
    mobileView,
    slidesPerView,
    fullScreenImages,
    swiper,
}: CarouselControlButtonsProps) => {
    const handleLeftArrowClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            swiper.slidePrev();
            stopPropagation(e);
        },
        [swiper],
    );

    const handleRightArrowClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            swiper.slideNext();
            stopPropagation(e);
        },
        [swiper],
    );

    return (
        <>
            {!!activeIndex && !mobileView && !!slidesLength && (
                <div
                    onClick={handleLeftArrowClick}
                    className={cn('carousel__control', { 'full-screen': fullScreenImages }, 'carousel__control-left')}
                >
                    <IcArrow />
                </div>
            )}
            {activeIndex + slidesPerView < slidesLength && !mobileView && !!slidesLength && (
                <div
                    onClick={handleRightArrowClick}
                    className={cn('carousel__control', { 'full-screen': fullScreenImages }, 'carousel__control-right')}
                >
                    <IcArrow />
                </div>
            )}
        </>
    );
};

import { FormApi } from 'final-form';
import { Block, BlockTitle, F7Link, List, ListItem, Toggle } from 'framework7-react';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    ConfigureImportErrors,
    ConfigureImportProps,
    ConfigureImportValues,
    FormSpyRendererProps,
} from './ConfigureImport.types';

import { getAcceptParamsByFileType, validateConfigureImport } from './ConfigureImport.utils';

import { FileField } from './FileField';

import { FileTypeSheetButton } from './FileTypeSheetButton';

import { MobileSourceField } from './MobileSourceField';

import { loadProductSourceTypes, productSourceFileErrorClearAction } from '@/actions/productSourceActions';
import { messengerService } from '@/api/messengerService';
import { CustomSelectValue } from '@/components/CustomSelect';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { CustomSelectField } from '@/components/Fields/CustomSelectField';
import { RadioField } from '@/components/Fields/RadioField';
import { ToggleField } from '@/components/Fields/ToggleField';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { usePrevious } from '@/hooks/usePrevious';
import { ImportContentType } from '@/pages/ImportProducts/ImportProducts.types';
import { getIsMobile } from '@/selectors/getIsMobile';
import { noop } from '@/utils';

import './ConfigureImport.less';

const FormSpyRenderer = ({
    values,
    valid,
    handleDraftChange,
    handleValidity,
    onImportTypeChange,
    onFileTypeChange,
    initializeEdit,
    dirty,
    form,
}: FormSpyRendererProps) => {
    const { productSourceLoading, productSource } = useAppSelector((state) => state.productSourceReducer);
    const prevFileType = usePrevious(values?.typeCode?.value);

    const prevSourceType = usePrevious(values.contentType);

    useEffect(() => {
        if (prevFileType && values.typeCode?.value !== prevFileType) {
            onFileTypeChange();
        }
    }, [onFileTypeChange, prevFileType, values.typeCode?.value]);

    useEffect(() => {
        handleValidity(
            valid && !productSourceLoading && !JSON.parse(productSource?.currentContent?.errorMessage ?? '{}')?.errors,
        );
        if (valid && values.typeCode?.value === prevFileType) {
            handleDraftChange(values);
        }
    }, [
        handleDraftChange,
        handleValidity,
        prevFileType,
        productSource?.currentContent?.errorMessage,
        productSourceLoading,
        valid,
        values,
    ]);

    const handleImportTypeChange = useMemo(
        () =>
            debounce(
                (
                    type: ImportContentType,
                    form: FormApi<ConfigureImportValues>,
                    onImportTypeChange: (type: ImportContentType) => void,
                ) => {
                    form.change('file', undefined);
                    form.change('url', undefined);
                    onImportTypeChange(type);
                },
                500,
            ),
        [],
    );

    useEffect(() => {
        if (prevSourceType && prevSourceType !== values.contentType) {
            handleImportTypeChange(values?.contentType, form, onImportTypeChange);
        }
    }, [form, handleImportTypeChange, onImportTypeChange, prevSourceType, values.contentType]);

    useEffect(() => {
        if (valid && !dirty) {
            initializeEdit();
        }
    }, [dirty, initializeEdit, valid, values, values.url]);

    return null;
};

export const ConfigureImport: React.FC<ConfigureImportProps> = ({
    handleValidity,
    onImportTypeChange,
    contentType,
    draftValues,
    handleDraftChange,
    isEdit,
}) => {
    const isMobile = useAppSelector(getIsMobile);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sourceTypes, productSource } = useAppSelector((state) => state.productSourceReducer);

    const [showAuthFields, setShowAuthFields] = useState(false);
    const toggleAuthFields = useBooleanState(setShowAuthFields)[2];

    const [editInitialized, setEditInitialized] = useState(false);
    const [initializeEdit] = useBooleanState(setEditInitialized);

    const fileTypeOptions = useMemo(
        () => sourceTypes?.map((item) => ({ label: item.name, value: item.code })),
        [sourceTypes],
    );

    const makeHandleSourceClear = useCallback(
        (form: FormApi<ConfigureImportValues>) => () => {
            dispatch(productSourceFileErrorClearAction());
            form.batch(() => {
                form.change('file', undefined);
                form.change('url', undefined);
            });
        },
        [dispatch],
    );

    const makeClearUrlStateOnSheetClose = useCallback(
        (form: FormApi<ConfigureImportValues>) => () => {
            form.change('authUsername', undefined);
            form.change('authPassword', undefined);
            form.resetFieldState('url');
            form.change('url', undefined);
        },
        [],
    );

    useEffect(() => {
        if (!sourceTypes) dispatch(loadProductSourceTypes());
    }, [dispatch, sourceTypes]);

    const uploadErrors = useMemo(
        () => JSON.parse(productSource?.currentContent?.errorMessage ?? '{}')?.errors,
        [productSource?.currentContent?.errorMessage],
    );

    const renderSourceField = useCallback(
        (form: FormApi<ConfigureImportValues>, values: ConfigureImportValues, errors: ConfigureImportErrors) => {
            switch (true) {
                case isMobile: {
                    return (
                        <MobileSourceField
                            configureImportErrors={errors}
                            onCloseWithoutSave={makeClearUrlStateOnSheetClose(form)}
                            sourceType={values.contentType}
                            sourceTypeFieldName="contentType"
                            disabled={!values.typeCode}
                            handleClear={makeHandleSourceClear(form)}
                            fileFieldName="file"
                            acceptParams={getAcceptParamsByFileType(values?.typeCode)}
                        />
                    );
                }
                case contentType === ImportContentType.url: {
                    return (
                        <>
                            <CustomInputField requiredSign name="url" label={t('File URL')} />
                            <ListItem title={t('Authorization to access the link').toString()}>
                                <Toggle checked={showAuthFields} onChange={toggleAuthFields} />
                            </ListItem>
                            {showAuthFields && (
                                <ListItem>
                                    <CustomInputField name="authUsername" label={t('Username')} />
                                    <CustomInputField name="authPassword" label={t('Password')} type="password" />
                                </ListItem>
                            )}
                        </>
                    );
                }
                default: {
                    return (
                        <FileField
                            disabled={!values.typeCode}
                            handleClear={makeHandleSourceClear(form)}
                            name="file"
                            acceptParams={getAcceptParamsByFileType(values?.typeCode)}
                        />
                    );
                }
            }
        },
        [
            contentType,
            isMobile,
            makeClearUrlStateOnSheetClose,
            makeHandleSourceClear,
            showAuthFields,
            t,
            toggleAuthFields,
        ],
    );

    const getExampleUrl = useCallback(
        (typeCode: CustomSelectValue) => sourceTypes?.find((item) => item.code === typeCode?.value)?.exampleUrl,
        [sourceTypes],
    );

    const handleExampleClick = useCallback(
        (value: CustomSelectValue) =>
            messengerService.enabled ? messengerService.openExternalLink(getExampleUrl(value)) : noop,
        [getExampleUrl],
    );

    return (
        <Block className="configure-import">
            <BlockTitle className="step-title">{t('Configure Import')}</BlockTitle>
            <p className="step-description">
                {t('Use your template to import products from any platform into your store.')}
            </p>
            <Form<ConfigureImportValues>
                onSubmit={noop}
                initialValues={draftValues}
                validate={validateConfigureImport(uploadErrors?.[0])}
                keepDirtyOnReinitialize={!isEdit || editInitialized}
            >
                {({ values, form, errors }) => (
                    <form className="configure-import-form">
                        {!isMobile && (
                            <div className="configure-import-form-radio-container">
                                <RadioField value={ImportContentType.file} name="contentType">
                                    {t('File')}
                                </RadioField>
                                <RadioField value={ImportContentType.url} name="contentType">
                                    {t('Link')}
                                </RadioField>
                            </div>
                        )}
                        <List>
                            {isMobile ? (
                                <FileTypeSheetButton label="File type" fileTypes={fileTypeOptions} />
                            ) : (
                                <CustomSelectField
                                    label={t('File Type')}
                                    name="typeCode"
                                    options={fileTypeOptions}
                                    requiredSign
                                    className="configure-import-form-select"
                                />
                            )}
                            {values.typeCode && (
                                <p className="example-url">
                                    {t('An example with completed template can be downloaded ')}
                                    <F7Link
                                        onClick={() => handleExampleClick(values?.typeCode)}
                                        href={getExampleUrl(values?.typeCode)}
                                        target="_blank"
                                        external
                                    >
                                        {t('here')}
                                    </F7Link>
                                </p>
                            )}
                            {renderSourceField(form, values, errors)}
                            <ListItem title={t('Skip New Product').toString()}>
                                <ToggleField name="skipNew" />
                            </ListItem>
                            <ListItem title={t('Update Existing Products').toString()}>
                                <ToggleField name="updateExisting" />
                            </ListItem>
                        </List>
                        <FormSpy<ConfigureImportValues>
                            subscription={{ valid: true, values: true, dirty: true }}
                            render={(props) => (
                                <FormSpyRenderer
                                    {...props}
                                    initializeEdit={initializeEdit}
                                    onFileTypeChange={makeHandleSourceClear(form)}
                                    onImportTypeChange={onImportTypeChange}
                                    handleValidity={handleValidity}
                                    handleDraftChange={handleDraftChange}
                                />
                            )}
                        />
                    </form>
                )}
            </Form>
        </Block>
    );
};

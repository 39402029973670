import { ICategoryClassificator } from '@/reducers/categoryReducer';

export const iCategoryClassificatorToMap = <T>(
    categories: ICategoryClassificator[],
    quantityMap: Map<string, number>,
    keyGetter: (category: ICategoryClassificator) => T,
    allCategory?: ICategoryClassificator,
) => {
    const map = new Map<T, number | undefined>();
    categories.forEach((category) => {
        let current = category;
        while (current) {
            let categoryQuantity = quantityMap.get(category.categoryCode);

            if (map.has(keyGetter(current))) {
                categoryQuantity = categoryQuantity + map.get(keyGetter(current));
            }
            if (!current.parent && current.categoryCode !== 'all' && allCategory) {
                current.parent = allCategory;
            }
            map.set(keyGetter(current), categoryQuantity);
            current = current.parent;
        }
    });
    return map;
};

import cn from 'classnames';
import dayjs from 'dayjs';
import { Icon, Link, List, ListItem } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { changeLanguage, getAvailableLanguage } from '@/actions/classificatorActions';
import { PopoverPositions } from '@/components/CustomPopover/CustomPopover.types';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { useAppSelector } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';

import './LanguageLink.less';

export const LanguageLink = React.memo(() => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const activeCode = useAppSelector((state) => state.rootReducer.language);

    const { languageList } = useSelector((state: IApplicationStore) => state.languageListReducer);

    const handleChange = useCallback(
        async (code: string) => {
            const availableLanguage = await getAvailableLanguage(code);

            dispatch(changeLanguage(code));
            dayjs.locale(code);
            await i18n.changeLanguage(availableLanguage);
        },
        [dispatch, i18n],
    );

    return (
        <CustomPopoverWrapper
            disableScrollOnOpen
            closeByClickInside
            availablePositions={[PopoverPositions.BOTTOM_CENTER, PopoverPositions.BOTTOM_LEFT_CORNER]}
            popoverTriggerElement={(togglePopover) => (
                <Link onClick={togglePopover} className={cn('language-link')}>
                    <span>{i18n.language.toUpperCase()}</span>
                    <Icon material="expand_more" />
                </Link>
            )}
            className={cn('languages-popover')}
        >
            {() => (
                <div className="languages-popover-content">
                    <div className="languages-popover-content-title">
                        <p>{t('Languages list title')}</p>
                    </div>
                    <div className="languages-popover-content-list">
                        <List>
                            {languageList?.map((language) =>
                                language.code === 'id' ? null : (
                                    <ListItem key={language.code} onClick={() => handleChange(language.code)}>
                                        {`${language.name} - ${language.code}`}

                                        {language.code === activeCode && (
                                            <Icon
                                                className="languages-popover-content-list-icon_checked"
                                                slot="root-end"
                                                material="check"
                                            />
                                        )}
                                    </ListItem>
                                ),
                            )}
                        </List>
                    </div>
                </div>
            )}
        </CustomPopoverWrapper>
    );
});
LanguageLink.displayName = 'LanguageLink';

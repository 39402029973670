export enum WEBResourceType {
    TYPE_WEB = 'W',
    TYPE_FACEBOOK = 'F',
    TYPE_TWITTER = 'T',
    TYPE_WHATSAPP = 'WHATSAPP',
    TYPE_TELEGRAM = 'TELEGRAM',
    TYPE_VIBER = 'VIBER',
    TYPE_GEM4ME = 'GEM4ME',
    TYPE_INSTAGRAM = 'INSTAGRAM',
}

import cn from 'classnames';
import { Link, Navbar, NavRight, Page, Popup } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IframeContentPopupProps } from './IframeContentPopup.types';

import { IcClose } from '@/assets';
import { Popups } from '@/types/popups';

import './IframeContentPopup.less';

export const IframeContentPopup = ({ url, title, className, ...props }: IframeContentPopupProps) => {
    const { t } = useTranslation();

    return (
        <Popup
            {...props}
            id={Popups.IFRAME_CONTENT_POPUP}
            className={cn(Popups.IFRAME_CONTENT_POPUP, 'iframe-popup', className)}
        >
            <Page>
                <Navbar title={t(title).toString()} noShadow noHairline className={cn('iframe-popup__navbar')}>
                    <NavRight>
                        <Link popupClose>
                            <IcClose className="close-icon" />
                        </Link>
                    </NavRight>
                </Navbar>
                <iframe
                    id="iframeContent"
                    src={url}
                    loading="lazy"
                    className={cn('iframe-popup__content')}
                    frameBorder="0"
                    title="Iframe Content"
                />
            </Page>
        </Popup>
    );
};

import { ImportContentType, ImportProductsDraft, ImportProductsSteps } from './ImportProducts.types';

export const importProductsDraftInitial: ImportProductsDraft = {
    [ImportProductsSteps.CONFIGURE_IMPORT]: {
        updateExisting: false,
        skipNew: false,
        contentType: ImportContentType.file,
    },
    [ImportProductsSteps.IMPORT_LOG]: {},
    [ImportProductsSteps.CATEGORY_MAPPING]: {},
};

export const defaultValidSteps = new Map(
    Object.values(ImportProductsSteps)
        .filter((key) => typeof key === 'number')
        .map((key: ImportProductsSteps) => [key, !key]),
);

import { HistogramScaleSize } from '@/components/Histogram/Histogram.types';
import { GetStatisticsRequest } from '@/generated/marketplaceapi';

export const getStatisticType = (
    startDate: Date,
    endDate: Date,
    histogramScale: HistogramScaleSize,
): GetStatisticsRequest.TypeEnum => {
    const differentDays = (Number(endDate) - Number(startDate)) / (1000 * 60 * 60 * 24); // ? ms * s * min * hour
    const differentWeeks = Math.floor(differentDays / 7);
    const differentMonths = Math.floor(differentDays / 30);
    const differentYears = Math.floor(differentMonths / 12);

    const getCount = () => {
        switch (histogramScale) {
            case HistogramScaleSize.LARGE:
                return 7;
            case HistogramScaleSize.MEDIUM:
                return 12;
            case HistogramScaleSize.SMALL:
                return 31;
            default:
                return 12;
        }
    };

    const count = getCount();

    switch (true) {
        case differentDays <= count:
            return GetStatisticsRequest.TypeEnum.DAY;
        case differentWeeks <= count:
            return GetStatisticsRequest.TypeEnum.WEEK;
        case differentMonths <= count:
            return GetStatisticsRequest.TypeEnum.MONTH;
        case differentYears <= count:
            return GetStatisticsRequest.TypeEnum.YEAR;
        default:
            return GetStatisticsRequest.TypeEnum.DAY;
    }
};

import { Block, Button } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MSPopup } from '@/shared/UIKit/Popup/Popup';

import type { OkReviewPopupProps } from './OkReviewPopup.types';

import './OkReviewPopup.less';

export const OkReviewPopup: React.FC<OkReviewPopupProps> = ({ opened, isEdit = false, onPopupClosed }) => {
    const { t } = useTranslation();

    return (
        <MSPopup
            className="ok-review-popup"
            opened={opened}
            fullScreen
            onPopupClosed={onPopupClosed}
            onBackdropClick={onPopupClosed}
        >
            <Block>
                <h1>{t(isEdit ? 'The changes has been saved' : 'Thank you for your feedback')}!</h1>
                <p>
                    {t(
                        isEdit
                            ? 'The review will be updated after moderation'
                            : 'The review has been submitted and will be published after moderation',
                    )}
                    .
                </p>
                <Button className="ok-review-popup__button" round large onClick={onPopupClosed}>
                    {t('go back')}
                </Button>
            </Block>
        </MSPopup>
    );
};

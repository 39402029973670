import { IProduct } from './productReducer';

import {
    FIRST_PRODUCT_CREATED,
    PRODUCT_CREATE_DETAILS_ERROR,
    PRODUCT_CREATE_DETAILS_ERROR_RESET,
    PRODUCT_CREATE_DETAILS_LOADING,
    PRODUCT_CREATE_DETAILS_SUCCESS,
    PRODUCT_CREATE_LOADING,
    PRODUCT_CREATE_LOADING_ERROR,
    PRODUCT_CREATE_LOADING_SUCCESS,
    PRODUCT_CREATE_RESET_DRAFT,
    PRODUCT_CREATE_UPDATE_DRAFT,
    PRODUCT_DELETE_SUCCESS,
    ProductCreateActions,
} from '@/actions/productCreateActions';
import { CHANGE_PRODUCT_STATUS_SUCCESS, ProductStatusActions } from '@/actions/productStatusActions';
import { Product } from '@/generated/marketplaceapi';
import { LocalStorageItems } from '@/types/localStorage';

export interface IProductCreateFormError {
    message: string;
    parameters?: string[];
}

export interface IUploadedImageInfo {
    id: string;
    file?: File;
    src: string;
}

// ! DON'T CHANGE ORDER
export enum ProductCreationSteps {
    GENERAL_INFORMATION = 'GENERAL_INFORMATION',
    PRICE = 'PRICE',
    CHARACTERISTICS = 'CHARACTERISTICS',
    //ALLOW_ORDER_FROM = 'ALLOW_ORDER_FROM',
    DELIVERY = 'DELIVERY',
    ADDITIONAL_INFO = 'ADDITIONAL_INFO',
}

export interface IProductCreateState {
    loading: boolean;
    saving: boolean;
    error: unknown;

    product: IProduct;
    images: IUploadedImageInfo[];
    pendingDraft: boolean;
    creationStep: ProductCreationSteps | undefined;
    firstProductCreated: boolean;
}

export const MAX_IMAGES_IN_PRODUCT = 5;
export const MIN_DAYS_BETWEEN_DATES = 21;

const initialState: IProductCreateState = {
    loading: false,
    saving: false,
    error: undefined,
    product: undefined,
    images: [],
    pendingDraft: false,
    creationStep: undefined,
    firstProductCreated: Boolean(JSON.parse(localStorage.getItem(LocalStorageItems.FIRST_PRODUCT_CREATED) ?? 'false')),
};

export const checkProductEditAllowed = (status: Product.StatusEnum) =>
    status && (status === Product.StatusEnum.DRF || status === Product.StatusEnum.DCL);

const productCreateReducer = (
    state = initialState,
    action: ProductCreateActions | ProductStatusActions,
): IProductCreateState => {
    switch (action.type) {
        case PRODUCT_CREATE_UPDATE_DRAFT: {
            const product: Partial<IProduct> = action.product;

            return {
                ...state,
                loading: false,
                product: {
                    ...state.product,
                    ...product,
                    isProductEditAllowed: product.uid
                        ? product.isProductEditAllowed
                        : checkProductEditAllowed(product.status),
                },
                images: action.images ?? state.images,
                error: undefined,
                pendingDraft: true,
                creationStep: action.step,
            };
        }
        case PRODUCT_CREATE_RESET_DRAFT:
            return { ...state, ...initialState };

        case PRODUCT_CREATE_LOADING:
            return {
                ...state,
                loading: true,
                saving: true,
                error: undefined,
            };

        case PRODUCT_CREATE_LOADING_SUCCESS: {
            const product: IProduct = action.product;
            return {
                ...state,
                loading: false,
                saving: false,
                product: {
                    ...product,
                    isProductEditAllowed: checkProductEditAllowed(product.status),
                },
                images: action.images ?? state.images,
                pendingDraft: false,
                creationStep: undefined,
            };
        }

        case PRODUCT_CREATE_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                saving: false,
                error: action.error,
            };
        case PRODUCT_DELETE_SUCCESS:
            return {
                ...state,
                product: undefined,
            };

        case PRODUCT_CREATE_DETAILS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };

        case PRODUCT_CREATE_DETAILS_SUCCESS: {
            const product: IProduct = action.product;
            return {
                ...state,
                loading: false,
                error: undefined,
                product: {
                    ...product,
                    isProductEditAllowed: checkProductEditAllowed(product.status),
                },
                images: action.images ?? [],
            };
        }

        case PRODUCT_CREATE_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CHANGE_PRODUCT_STATUS_SUCCESS: {
            const { item } = action;
            const { product } = state;

            if (item) {
                return {
                    ...state,
                    product: {
                        ...product,
                        status: item.status,
                        isProductEditAllowed: checkProductEditAllowed(item.status),
                    },
                };
            }

            return {
                ...state,
            };
        }

        case FIRST_PRODUCT_CREATED:
            return {
                ...state,
                firstProductCreated: true,
            };
        case PRODUCT_CREATE_DETAILS_ERROR_RESET:
            return { ...state, error: undefined };
        default:
            return state;
    }
};

export default productCreateReducer;

import cn from 'classnames';
import { Button } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FilterButtonProps } from './FilterButton.types';

import { IcFilter } from '@/assets';

import './FilterButton.less';

export const FilterButton = ({ isMobile = false, count, className, ...props }: FilterButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button {...props} round className={cn('filter-button', { mobile: isMobile }, className)}>
            <IcFilter />
            {!isMobile && (
                <>
                    {t('Filter')}
                    {count > 0 && <div className="filter-button__count">{count}</div>}
                </>
            )}
        </Button>
    );
};

import cn from 'classnames';
import { ListItem } from 'framework7-react';
import React, { useCallback } from 'react';

import { CustomCheckboxProps } from './CustomCheckbox.types';

import './CustomCheckbox.less';

export const CustomCheckbox = ({
    bordered = false,
    className,
    errorMessage,
    errorMessageForce,
    onChange,
    afterChange,
    children,
    ...props
}: CustomCheckboxProps) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e);
            afterChange?.(e);
        },
        [afterChange, onChange],
    );

    return (
        <ListItem
            {...props}
            checkbox
            onChange={handleChange}
            className={cn('custom-checkbox', { bordered }, className)}
        >
            {children}
            {errorMessageForce && errorMessage && <div className="custom-checkbox__error">{errorMessage}</div>}
        </ListItem>
    );
};

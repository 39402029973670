import cn from 'classnames';
import { AccordionContent, f7, List } from 'framework7-react';
import React, { Suspense, useCallback } from 'react';

import { PageSideMenuItem as PageSideMenuItemData, PageSideMenuProps } from './PageSideMenu.types';
import { PageSideMenuItem } from './PageSideMenuItem';

import { messengerService } from '@/api/messengerService';

import './PageSideMenu.less';

const EnablePaymentOptionCard = React.lazy(() => import('@/components/EnablePaymentOptionCard'));

export const PageSideMenu = ({
    menuItems,
    menuItemsNames,
    onItemClick,
    selectedName,
    selectedRounded,
    onOnbordingMessageClick,
    onOnbordingMessageClose,
    className,
    selectedSubitemName,
}: PageSideMenuProps) => {
    const handleItemClick = useCallback(
        (item: PageSideMenuItemData) => {
            if (!item.items) {
                f7.panel.close();
                setTimeout(() => {
                    onItemClick(item);
                }, 60);
            }
        },
        [onItemClick],
    );

    const renderMenuItem = useCallback(
        (name: string) =>
            menuItems[name] ? (
                <PageSideMenuItem
                    key={name}
                    menuItem={menuItems[name]}
                    name={name}
                    onClick={handleItemClick}
                    active={selectedName === name}
                    selectedRounded={selectedRounded}
                    onOnbordingMessageClick={onOnbordingMessageClick}
                    onOnbordingMessageClose={onOnbordingMessageClose}
                >
                    {menuItems[name]?.items?.length && (
                        <AccordionContent>
                            {messengerService.enabled && name === 'SellerArea_Products' && (
                                <Suspense fallback={null}>
                                    <EnablePaymentOptionCard className="page-side-menu__enable-payment-option-card" />
                                </Suspense>
                            )}
                            <List>
                                <ul>
                                    {menuItems[name].items?.map((item) => (
                                        <PageSideMenuItem
                                            key={item.name}
                                            menuItem={item}
                                            name={item.name}
                                            onClick={handleItemClick}
                                            active={selectedSubitemName === item.name}
                                            selectedRounded={selectedRounded}
                                        />
                                    ))}
                                </ul>
                            </List>
                        </AccordionContent>
                    )}
                </PageSideMenuItem>
            ) : (
                <React.Fragment key={name} />
            ),
        [
            menuItems,
            handleItemClick,
            selectedName,
            selectedRounded,
            onOnbordingMessageClick,
            onOnbordingMessageClose,
            selectedSubitemName,
        ],
    );

    return (
        <List
            className={cn('page-side-menu', className)}
            noHairlines
            noHairlinesBetween
            accordionList
            accordionOpposite
        >
            {menuItemsNames.map(renderMenuItem)}
        </List>
    );
};

import { Router } from 'framework7/modules/router/router';

import { CategoryMappingValues } from './CategoryMapping/CategoryMapping.types';
import { ConfigureImportValues } from './ConfigureImport/ConfigureImport.types';

export type ImportProductsProps = { f7router: Router.Router; isEdit?: boolean };

// !ORDER IS IMPORTANT, DO NOT CHANGE!!!
export enum ImportProductsSteps {
    INITIAL,
    CONFIGURE_IMPORT,
    CATEGORY_MAPPING,
    IMPORT_LOG,
}

export type ImportProductsStepProps = {
    handleValidity: (validity: boolean) => void;
    handleDraftChange: (values: Partial<ImportProductsDraft[ImportProductsSteps]>) => void;
};

export enum ImportContentType {
    file = 'file',
    url = 'url',
}

export type ImportProductsDraft = {
    [ImportProductsSteps.CONFIGURE_IMPORT]: ConfigureImportValues;
    [ImportProductsSteps.CATEGORY_MAPPING]?: CategoryMappingValues;
    [ImportProductsSteps.IMPORT_LOG]?: {
        stats?: {
            new?: number;
            failed?: number;
            updated?: number;
            skipped?: number;
        };

        totalProducts?: number;
        active?: boolean;
        fileType?: string;
        processed?: boolean;
        contentType?: ImportContentType;
        sourceLabel?: string;
        date?: string;
    };
    [ImportProductsSteps.INITIAL]?: undefined;
};

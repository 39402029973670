import cn from 'classnames';
import { Button, f7, Link, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { messengerService } from '@/api/messengerService';
import { IcBack, IcJob, IcPlus, IcSearch, IcSearchLarge, IcStore } from '@/assets';
import { useAppNavbarSearchbar, useAppNavbarTopNavbar } from '@/components/AppNavBar/AppNavBar.hooks';
import { MainNavbarProps } from '@/components/AppNavBar/AppNavBar.types';
import { MainNavbarActionButtons } from '@/components/AppNavBar/MainNavbarActionButtons';
import { MainNavbarProfileLink } from '@/components/AppNavBar/MainNavbarProfileLink';
import { NavbarTop } from '@/components/AppNavBar/NavbarTop';
import { Logo } from '@/components/Logo';
import { OnbordingMessage } from '@/components/OnbordingMessage';
import { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import { OpenCategoriesButton } from '@/components/open-categories-button';
import { SearchBar } from '@/components/SearchBar';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getIsTablet } from '@/selectors/getIsTablet';
import { getProfile } from '@/selectors/profile';
import { MSButtonBack } from '@/shared/UIKit/ButtonBack/ButtonBack';
import { AccountStatus, AccountType } from '@/types/account';
import { ProductType } from '@/types/productType';
import { isServiceProviderActive } from '@/utils/isServiceProviderActive';

import './MainNavbar.less';

export const MainNavbar = ({
    onClickLogoLink,
    onClickOpenCategoriesMenu,
    categoriesMenuOpened,
    onSearchClickClear,
    onSearchbarDisable,
    onSearchbarEnable,
    showSearchbar,
    showSearchbarToggle,
    showProfileLink,
    showCartLink,
    showShare,
    className,
    onContactSupportClick,
    onStartSellingClick,
    shareUrl,
    shareTitle,
    analyticsShareProduct,
    analyticsShareStore,
    showAddToFav,
    storeData,
    backLink,
    backLinkForce,
    backLinkUrl,
    onSearchInputChange,
    openAboutPopup,
    openInvitePopup,
    openVerifyAccountPopup,
    onLoginClick,
    openAddOffer,
    startProvidingServices,
    onChangeProductType,
    emailVerifySuccess,
    showWishButton,
    slot,
    ...props
}: MainNavbarProps) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);
    const isTablet = useAppSelector(getIsTablet);
    const profile = useAppSelector(getProfile);

    const showSellerArea = profile && profile.type === AccountType.BUSINESS;
    const showAddService = isServiceProviderActive(profile);
    const showOnbordingMessage =
        profile && [AccountStatus.BC, AccountStatus.BR].includes(profile.status as AccountStatus);

    const isMdRes = useAppSelector((state) => state.rootReducer.resizeEvent.width >= 932);

    const [isTabletSearchActive, setIsTabletSearchActive] = useState<boolean>(false);

    const { searchBarFocus, searchbarContainerRef, searchbarDisplayed, searchbarProps, toggleSearchbarInclusion } =
        useAppNavbarSearchbar({
            showSearchbar,
            showSearchbarToggle,
            storeUid: storeData?.uid,
            onSearchClickClear,
            onSearchbarDisable,
            onSearchbarEnable,
            onSearchInputChange,
        });

    const { activeProductType } = useAppSelector((state) => state.rootReducer);

    const topNavbarVisible = useAppNavbarTopNavbar();

    const openAddProduct = () => {
        f7.views.main.router.navigate('/profile/seller-area/my-goods/add/');
    };

    return (
        <Navbar
            noHairline
            noShadow
            className={cn('app-navbar-main-navbar', className, {
                'top-navbar-hidden': !topNavbarVisible || (isMobile && searchBarFocus),
            })}
            innerClassName="app-navbar-main-navbar-inner"
            slot={slot}
        >
            <div
                className={cn('app-navbar-main-navbar__content', {
                    'categories-opened': topNavbarVisible && !categoriesMenuOpened,
                    'top-navbar-visible': topNavbarVisible,
                    'top-navbar-hidden': !topNavbarVisible || (isMobile && searchBarFocus),
                })}
            >
                {!isMobile && (
                    <NavbarTop
                        className={cn('app-navbar-main-navbar__navbar-top', {
                            visible: topNavbarVisible,
                        })}
                        onChangeProductType={onChangeProductType}
                        onContactSupportClick={onContactSupportClick}
                    />
                )}

                {!isTabletSearchActive && (
                    <Navbar
                        noShadow
                        noHairline
                        className={cn('app-navbar-main-navbar__navbar', {
                            'category-opened': categoriesMenuOpened,
                            'top-navbar-visible': topNavbarVisible,
                            'top-navbar-hidden': !topNavbarVisible || (!backLink && isMobile && searchBarFocus),
                        })}
                        {...props}
                    >
                        {(!showSearchbarToggle || !searchBarFocus) && (
                            <NavLeft>
                                {backLink && isMobile && !searchBarFocus && (
                                    <MSButtonBack router={f7.view.main.router} />
                                )}

                                {!backLink && (!searchBarFocus || !isMobile) && (
                                    <>
                                        {messengerService.enabled && isMobile && (
                                            <IcBack
                                                className="navbar-back-to-messenger"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    messengerService.goToMessenger();
                                                }}
                                            />
                                        )}
                                        <Link className="navbar-logo-content">
                                            <Logo
                                                onClickLogo={onClickLogoLink}
                                                onChangeProductType={onChangeProductType}
                                            />
                                        </Link>
                                    </>
                                )}
                            </NavLeft>
                        )}
                        <div className="navbar-center">
                            {!isMobile && (
                                <div className="navbar-center__actions">
                                    <OpenCategoriesButton
                                        text={t('Categories')}
                                        opened={categoriesMenuOpened}
                                        onClick={onClickOpenCategoriesMenu}
                                    />

                                    <div className="navbar-center__actions-gapper" />

                                    {activeProductType === ProductType.Advertisement && (
                                        <Button className="navbar-center__actions-button" round onClick={openAddOffer}>
                                            <IcPlus />
                                            <span>{t('c2c.page.header.button.add_offer')}</span>
                                        </Button>
                                    )}

                                    {activeProductType === ProductType.Service &&
                                        (!showAddService ? (
                                            <Button
                                                className="navbar-center__actions-button"
                                                round
                                                onClick={startProvidingServices}
                                            >
                                                <IcJob className="start_providing_services__logo" />
                                                <span>{t('services.page.header.button.start_providing_services')}</span>
                                            </Button>
                                        ) : (
                                            <Button
                                                className="navbar-center__actions-button"
                                                round
                                                onClick={openAddOffer}
                                            >
                                                <IcPlus />
                                                <span>{t('services.page.header.button.add_service')}</span>
                                            </Button>
                                        ))}

                                    {activeProductType === ProductType.Product &&
                                        (showSellerArea ? (
                                            <div className="button-seller-area__container">
                                                <Button
                                                    className="navbar-center__actions-button"
                                                    round
                                                    onClick={onStartSellingClick}
                                                >
                                                    <IcStore />
                                                    <span>{t('Seller Area')}</span>
                                                </Button>
                                                {showOnbordingMessage && (
                                                    <OnbordingMessage
                                                        className="button-seller-area__onboarding-message"
                                                        type={OnbordingMessageType.ADD_PRODUCTS}
                                                        triangle="left"
                                                        onClick={openAddProduct}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <Button
                                                className="navbar-center__actions-button"
                                                round
                                                onClick={onStartSellingClick}
                                            >
                                                <IcStore />
                                                <span>{t('Start Selling')}</span>
                                            </Button>
                                        ))}
                                </div>
                            )}

                            {(!isMobile || backLink) && searchbarDisplayed && !isTablet && (
                                <div className="search-container" ref={searchbarContainerRef}>
                                    <SearchBar
                                        {...searchbarProps}
                                        storeData={storeData}
                                        noShadow
                                        noHairline
                                        backdrop
                                        customSearch
                                        disableButton
                                    />
                                </div>
                            )}
                        </div>
                        {(!searchBarFocus || !isMobile) && (
                            <NavRight>
                                {isTablet && (
                                    <Link
                                        className="nav-right-action-button-search"
                                        iconOnly
                                        onClick={() => setIsTabletSearchActive(true)}
                                    >
                                        <IcSearchLarge />
                                    </Link>
                                )}

                                <MainNavbarActionButtons
                                    shareUrl={shareUrl}
                                    shareTitle={shareTitle}
                                    showAddToFavButton={showAddToFav}
                                    showCartButton={showCartLink}
                                    showShareButton={showShare}
                                    showWishButton={showWishButton}
                                    storeData={storeData}
                                    analyticsShareProduct={analyticsShareProduct}
                                    analyticsShareStore={analyticsShareStore}
                                    showCartOrWishButton={!isMdRes}
                                    className="app-navbar-main-navbar__action-buttons"
                                >
                                    {showSearchbarToggle && !searchBarFocus && (
                                        <Link
                                            className="nav-right-searchbar-toggle"
                                            iconOnly
                                            onClick={toggleSearchbarInclusion}
                                        >
                                            <IcSearch />
                                        </Link>
                                    )}
                                </MainNavbarActionButtons>

                                {showProfileLink && (
                                    <MainNavbarProfileLink
                                        openAboutPopup={openAboutPopup}
                                        openInvitePopup={openInvitePopup}
                                        openVerifyAccountPopup={openVerifyAccountPopup}
                                        emailVerifySuccess={emailVerifySuccess}
                                        onLoginClick={onLoginClick}
                                        onStartSellingClick={onStartSellingClick}
                                        startProvidingServices={startProvidingServices}
                                    />
                                )}
                            </NavRight>
                        )}
                    </Navbar>
                )}

                {isTabletSearchActive && (
                    <div className="search-tablet">
                        <Link className="search-tablet-logo">
                            <Logo full={false} onClickLogo={onClickLogoLink} />
                        </Link>
                        <div className="search-container" ref={searchbarContainerRef}>
                            <SearchBar
                                {...searchbarProps}
                                storeData={storeData}
                                noShadow
                                noHairline
                                backdrop
                                customSearch
                                disableButton
                            />
                        </div>
                        <Button className="search-tablet-button" onClick={() => setIsTabletSearchActive(false)}>
                            {t('Cancel')}
                        </Button>
                    </div>
                )}

                {!backLink && isMobile && (
                    <div className="search-wrapper">
                        <div className="navbar-bg" />
                        {searchbarDisplayed && (
                            <div className="search-container" ref={searchbarContainerRef}>
                                <SearchBar
                                    {...searchbarProps}
                                    storeData={storeData}
                                    noShadow
                                    noHairline
                                    backdrop
                                    customSearch
                                    disableButton
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Navbar>
    );
};

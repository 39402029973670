import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIcon } from '@/types/icons';

export const IcOnboardingMessageFavoriteStore: IcIcon = React.memo(() => {
    const gradUid1 = useNodeUid();
    const gradUid2 = useNodeUid();
    const gradUid3 = useNodeUid();
    const gradUid4 = useNodeUid();

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill={`url(#${gradUid1})`}
            />
            <path
                d="M18.1683 30.5226C16.3716 21.5296 11.5993 14.3914 4.91807 9.44531C0.0896159 15.7966 -1.48244 24.4523 1.54938 32.4336C5.08651 41.7076 13.6767 47.553 22.9406 47.9465C20.5825 42.3259 19.1227 36.1432 18.1683 30.5226Z"
                fill={`url(#${gradUid2})`}
            />
            <path
                d="M29.954 0.785156C30.6839 1.68445 31.3577 2.58375 31.9753 3.53926C39.8917 15.6236 33.3227 29.2817 32.7051 42.3215C32.5928 43.6142 32.5928 44.9632 32.8174 46.3121C45.0009 41.5346 51.0645 27.8203 46.4606 15.5112C43.5411 7.92333 37.1967 2.63996 29.954 0.785156Z"
                fill={`url(#${gradUid3})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.2943 12.5924C23.3342 10.4692 26.3605 10.4692 27.4005 12.5924L29.822 17.5358L35.263 18.3373C37.5872 18.6796 38.5168 21.5332 36.8401 23.1788L32.8913 27.0547L33.8204 32.5137C34.2162 34.8392 31.7709 36.6073 29.6865 35.5027L24.8474 32.9382L20.0083 35.5027C17.9239 36.6073 15.4786 34.8392 15.8744 32.5137L16.8035 27.0547L12.8546 23.1788C11.178 21.5332 12.1076 18.6796 14.4318 18.3373L19.8728 17.5358L22.2943 12.5924Z"
                fill={`url(#${gradUid4})`}
            />
            <defs>
                <linearGradient
                    id={gradUid1}
                    x1="-13.5729"
                    y1="-9.18087"
                    x2="37.0408"
                    y2="35.5207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid2}
                    x1="-15.9401"
                    y1="19.7926"
                    x2="18.1034"
                    y2="30.3877"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid3}
                    x1="14.9347"
                    y1="7.26485"
                    x2="48.2843"
                    y2="36.6868"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient id={gradUid4} x1="15" y1="13" x2="32.5" y2="36" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--brand-primary-0)" />
                    <stop offset="1" stopColor="var(--brand-primary-10)" />
                </linearGradient>
            </defs>
        </svg>
    );
});
IcOnboardingMessageFavoriteStore.displayName = 'IcOnboardingMessageFavoriteStore';

import cn from 'classnames';
import { f7, Link, List, Navbar, NavRight } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VerifyEmailPopupProps, VerifyEmailSteps } from './VerifyEmailPopup.types';

import { setAlertAction } from '@/actions/alertActions';
import { resendVerificationEmail, resetVerificationErrors } from '@/actions/sessionActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { IcClose, IcMail } from '@/assets';
import { CustomButton } from '@/components/CustomButton';
import { CustomInput } from '@/components/CustomInput';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useControlledInput } from '@/hooks/useControlledInput';
import { INVALID_EMAIL_ERROR } from '@/shared/constants';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { isEmail, noop } from '@/utils';

import './VerifyEmailPopup.less';

export const VerifyEmailPopup = React.memo(({ opened, onLoginClick = noop, onPopupClosed }: VerifyEmailPopupProps) => {
    const query = f7.view.main?.router?.currentRoute?.query;

    const email = query?.verificationEmail;

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [step, setStep] = useState(email ? VerifyEmailSteps.SENDED_MESSAGE : VerifyEmailSteps.RESEND_FORM);

    const [inputValue, setInputValue] = useState('');

    const isValidEmail = useMemo(() => isEmail(inputValue), [inputValue]);

    const handleInputChange = useControlledInput(setInputValue);

    const { emailVerificationError } = useAppSelector((state) => state.sessionReducer);

    useEffect(() => {
        if (email) {
            setStep(VerifyEmailSteps.SENDED_MESSAGE);
        }
    }, [email]);

    const handlePopupClose = useCallback(() => {
        onPopupClosed?.();
        dispatch(resetVerificationErrors());
        f7.view.main.router.navigate('/');
    }, [dispatch, onPopupClosed]);

    const handleResendVerification = useCallback(() => {
        dispatch(resendVerificationEmail(inputValue?.trim() || email)).then(
            ({ sessionReducer: { resendVerificationError, resendVerificationSuccess } }) => {
                if (resendVerificationSuccess) {
                    setStep(VerifyEmailSteps.SENDED_MESSAGE);
                    f7.toast.show({
                        closeTimeout: 3000,
                        text: t('A new verification email has been sent'),
                    });
                } else if (resendVerificationError) {
                    dispatch(
                        setAlertAction({
                            text: resendVerificationError,
                        }),
                    );
                }
            },
        );
    }, [dispatch, email, inputValue, t]);

    const handlePopupOpened = useCallback(() => {
        firebaseAnalyticsInstance.confirmEmailTry?.();
    }, []);

    return (
        <MSPopup
            className="verify-email-popup"
            onPopupClosed={handlePopupClose}
            onPopupOpened={handlePopupOpened}
            onBackdropClick={handlePopupClose}
            opened={opened}
        >
            <Navbar noShadow noHairline>
                <NavRight>
                    <Link className="verify-email-popup__close" iconOnly onClick={handlePopupClose}>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>

            <div className="verify-email-popup__content">
                {!step ? (
                    <List className={cn('verify-email-popup__card', 'email-verification-form')}>
                        {!!emailVerificationError && (
                            <div className="verify-email-popup__card-error">{t('Verification failed')}</div>
                        )}
                        <h2 className="verify-email-popup__card-title">{t('Please verify your email')}</h2>
                        <span className="verify-email-popup__card-description">
                            {t('Please enter email address and we will issue an email with confirmation link.')}
                        </span>
                        <CustomInput
                            errorMessageForce={!!inputValue?.trim() && !isValidEmail}
                            errorMessage={t(INVALID_EMAIL_ERROR)}
                            onChange={handleInputChange}
                            label={t('Email')}
                        />
                        <CustomButton
                            disabled={!isValidEmail}
                            size="large"
                            fullWidth
                            onClick={handleResendVerification}
                        >
                            {t('Send Email')}
                        </CustomButton>
                        <span className="verify-email-popup__card-description sign-in">
                            {`${t('Already have an account')}? `}
                            <Link onClick={onLoginClick} text={t('Log In')} />
                        </span>
                    </List>
                ) : (
                    <>
                        <List className={cn('verify-email-popup__card', 'message-sended')}>
                            <div className="verify-email-popup__card-icon">
                                <IcMail />
                            </div>
                            <h2 className="verify-email-popup__card-title">{t('Please verify your email')}</h2>
                            <span className="verify-email-popup__card-description">
                                {t(
                                    'We sent an email to {{email}}. Just click on the link in that email to complete your sign-up.',
                                    { email },
                                )}
                            </span>
                        </List>
                    </>
                )}
            </div>

            {!!step && (
                <span className="verify-email-popup__resend">
                    {`${t("Can't find the email?")}`}
                    <Link onClick={handleResendVerification} text={t('Resend Verification Email')} />
                </span>
            )}
        </MSPopup>
    );
});

VerifyEmailPopup.displayName = 'VerifyEmailPopup';

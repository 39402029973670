import { getQueryParameterFromURL } from './url/getQueryParameterFromURL';

import { ILocalConfig } from '@/types/localConfig';
import { URLParams } from '@/types/URLParams';

const mapsApiKey = process.env.MAPS_API_KEY;

export const loadLocalConfig = async (): Promise<ILocalConfig> =>
    import('@/static/config/config').then(
        (json: { default: ILocalConfig }) =>
            ({
                ...json.default,
                settingsEnabled: !!process.env.SETTINGS_ENABLED,
                GoogleMapAPIkey: mapsApiKey,
                theme:
                    getQueryParameterFromURL(URLParams.DARK_MODE) === 'true' ? 'dark' : json.default.theme ?? 'light',
            }) as ILocalConfig,
    );

import { Button } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarControlsProps } from './CalendarControls.types';

import { IcClose, IcDone } from '@/assets';

import './CalendarControls.less';

export const CalendarControls = ({
    startDateCb,
    endDateCb,
    focusCb,
    isMobile,
    onApply,
    applyText,
    componentType,
    disableApplyButton,
}: CalendarControlsProps): JSX.Element => {
    const { t } = useTranslation();

    const handleClearClick = useCallback(() => {
        startDateCb(null);
        endDateCb(null);
        focusCb('startDate');
    }, [endDateCb, focusCb, startDateCb]);

    return (
        <div className="datepicker-controls">
            <div className="datepicker-controls-buttons">
                {!isMobile && (
                    <button className="clear" onClick={handleClearClick}>
                        <IcClose className="datepicker-controls-buttons__clear" />
                        <p className="datepicker-controls-buttons__clear-label">{t('Clear Dates')}</p>
                    </button>
                )}
                <Button
                    sheetClose={componentType === 'sheet'}
                    popupClose={componentType === 'popup'}
                    className="submit"
                    onClick={onApply}
                    disabled={disableApplyButton}
                >
                    {isMobile ? <IcDone fill="white" /> : t(applyText ?? 'Apply')}
                </Button>
            </div>
        </div>
    );
};

import cn from 'classnames';
import { Progressbar } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sortRows } from './RelativeRatingStat.utils';

import { SortDirection } from '@/types/sortDirection';
import { noop } from '@/utils';

import type { RelativeRatingStatProps } from './RelativeRatingStat.types';

import './RelativeRatingStat.less';

export const RelativeRatingStat = <
    Stat extends Record<string, unknown>,
    Label extends keyof Stat,
    Percent extends keyof Stat,
>({
    labelKey,
    percentKey,
    rows = [],
    withAltLabel = false,
    getAltLabel = noop,
    sortByLabel = false,
    sortDirection = SortDirection.ASC,
    adaptive = false,
    primary = false,
    fixedLabelSize = false,
    labelSize = 40,
    fullWidthStat,
    withDefaultLabelSize,
}: RelativeRatingStatProps<Stat, Label, Percent>) => {
    const { t } = useTranslation();

    const bars = useMemo(
        () =>
            rows
                .slice()
                .filter((value) => !!value[labelKey])
                .sort(sortRows(sortByLabel ? labelKey : percentKey, sortDirection))
                .map((row, idx) => (
                    <div
                        key={idx}
                        className={cn('relative-rating-stat__row', {
                            'relative-rating-stat__row-full-width': fullWidthStat,
                        })}
                    >
                        <span
                            className={cn('relative-rating-stat__row-label', {
                                [`relative-rating-stat__row-label_size_${labelSize}`]: fixedLabelSize,
                                'relative-rating-stat__row-label_size_default': withDefaultLabelSize,
                            })}
                        >
                            {t(String(withAltLabel ? getAltLabel(row, idx) : row[labelKey]))}
                        </span>
                        <Progressbar progress={(row[percentKey] as number) ?? 0} />
                        <span className="relative-rating-stat__row-percent">{row[percentKey] ?? 0}%</span>
                    </div>
                )),
        [
            rows,
            sortByLabel,
            labelKey,
            percentKey,
            sortDirection,
            fullWidthStat,
            labelSize,
            fixedLabelSize,
            t,
            withAltLabel,
            getAltLabel,
            withDefaultLabelSize,
        ],
    );

    return (
        rows.length > 0 && (
            <div
                className={cn('relative-rating-stat', {
                    'relative-rating-stat__adaptive': adaptive,
                    'relative-rating-stat__primary': primary,
                })}
            >
                {bars}
            </div>
        )
    );
};

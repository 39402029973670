import { useMemo } from 'react';

import { PersonalAreaMenuItemName } from './PersonalAreaMenu.types';

import { getPersonalAreaMenuSettings } from './PersonalAreaMenu.utils';

import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getProfile } from '@/selectors/profile';

export const usePersonalAreaMenuItems = () => {
    const { type: profileType } = useAppSelector(getProfile);

    const activeProductType = useAppSelector(getActiveProductType);

    const menuItems = useMemo(() => getPersonalAreaMenuSettings(activeProductType), [activeProductType]);

    const menuItemNames = useAppSelector(
        (state) =>
            (state.rootReducer.localConfig.personalAreaMenuItems?.filter((configItem) =>
                Object.keys(menuItems).includes(configItem),
            ) ?? []) as PersonalAreaMenuItemName[],
    );

    return { menuItems, menuItemNames, profileType };
};

import { useEffect } from 'react';

import { loadCart } from '@/actions/cartActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadCart = () => {
    const dispatch = useAppDispatch();

    const countryCode = useAppSelector(getCountryCodeFromState);

    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        dispatch(loadCart());
    }, [dispatch, loggedIn, countryCode]);

    return null;
};

import { FormApi } from 'final-form';
import { Link, List, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CreateServiceProviderFormValues, CreateServiceProviderProps } from './CreateServiceProviderPopup.types';

import { validateFormData } from './CreateServiceProviderPopup.utils';

import { AdaptiveBackLink } from '../AdaptiveBackLink';
import { CustomButton } from '../CustomButton';
import { CustomInputField } from '../Fields/CustomInputField';

import { RoundImageInput } from '../RoundImageInput';

import { createServiceProvider } from '@/actions/accountStoresActions';
import { setAlertAction } from '@/actions/alertActions';
import { refreshAuthentication } from '@/actions/sessionActions';
import { IcClose } from '@/assets';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { getBase64ImgNoHeader } from '@/utils';
import { getBase64ImageFromUrl } from '@/utils/files/getBase64ImageFromUrl';
import { trimMultipleSpaces } from '@/utils/string/trimMultipleSpaces';

import './CreateServiceProviderPopup.less';

const CreateServiceProviderPopup = ({ opened, onCreate, onClose }: CreateServiceProviderProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(getIsMobile);
    const profile = useAppSelector(getProfile);

    const profilePicRef = useRef<HTMLInputElement>(null);
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [isProfilePicLoading, setIsProfilePicLoading] = useState(false);

    const formRef = useRef<FormApi>();

    useEffect(() => {}, [profile?.person?.profilePictureUrl]);

    const handlePopupOpen = useCallback(async () => {
        if (profile?.person?.profilePictureUrl) {
            const base64ImgNoHeader = await getBase64ImageFromUrl(profile?.person?.profilePictureUrl);

            formRef.current?.change('profilePic', base64ImgNoHeader);
            setProfilePicUrl(profile?.person?.profilePictureUrl);
        }
    }, [profile?.person?.profilePictureUrl]);

    const handleFileUpload =
        (form: FormApi<CreateServiceProviderFormValues>) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const pic = e.target.files[0];

            if (pic) {
                setIsProfilePicLoading(true);
                getBase64ImgNoHeader(pic)
                    .then((base64ImgNoHeader) => {
                        form.change('profilePic', base64ImgNoHeader);
                        setProfilePicUrl(URL.createObjectURL(pic));
                    })
                    .finally(() => setIsProfilePicLoading(false));
            }
        };

    const handleFileDelete = (form: FormApi<CreateServiceProviderFormValues>) => () => {
        if (!profilePicUrl && profilePicRef.current) {
            profilePicRef.current.click();
            return;
        }

        form.change('profilePic', undefined);
        setProfilePicUrl('');
    };

    const createServicesProfile = useCallback(
        async (values: CreateServiceProviderFormValues) => {
            try {
                const { accountStoresReducer } = await dispatch(
                    createServiceProvider({
                        name: values.name?.trim(),
                        description: values.description?.trim(),
                        imageUrl: values.profilePic,
                        accountUid: profile.uid,
                    }),
                );

                if (accountStoresReducer.error) {
                    dispatch(
                        setAlertAction({
                            text: t(`${accountStoresReducer.error}`),
                        }),
                    );

                    return;
                }

                await dispatch(refreshAuthentication());

                onCreate();
            } catch (error) {
                dispatch(setAlertAction({ text: error }));
            }
        },
        [dispatch, onCreate, profile.uid, t],
    );

    return (
        <MSPopup
            className="create-service-provider-popup"
            opened={opened}
            onBackdropClick={onClose}
            onPopupOpen={handlePopupOpen}
        >
            <Navbar className="create-service-provider-popup__navbar" noShadow noHairline>
                {isMobile ? (
                    <NavLeft>
                        <AdaptiveBackLink onClick={onClose} />
                    </NavLeft>
                ) : (
                    <NavRight>
                        <Link className="create-service-provider-popup__close" iconOnly onClick={onClose}>
                            <IcClose />
                        </Link>
                    </NavRight>
                )}
            </Navbar>

            <div className="create-service-provider-popup__content">
                <div className="create-service-provider-popup__title">{t('start_providing_services.form.title')}</div>

                <Form<CreateServiceProviderFormValues> validate={validateFormData} onSubmit={createServicesProfile}>
                    {({ form, handleSubmit, valid }) => {
                        formRef.current = form;
                        return (
                            <>
                                <div className="create-service-provider-popup__profile-pic">
                                    <RoundImageInput
                                        ref={profilePicRef}
                                        imgUrl={profilePicUrl}
                                        loading={isProfilePicLoading}
                                        onChange={handleFileUpload(form)}
                                        onDelete={handleFileDelete(form)}
                                    />
                                </div>

                                <div className="create-service-provider-popup__placeholder">
                                    {t('start_providing_services.form.placeholder')}
                                </div>

                                <List noHairlines>
                                    <CustomInputField
                                        name="name"
                                        format={trimMultipleSpaces}
                                        formatOnBlur
                                        requiredSign
                                        label={t('start_providing_services.form.field.name.placeholder')}
                                    />
                                    <CustomInputField
                                        name="description"
                                        format={trimMultipleSpaces}
                                        formatOnBlur
                                        requiredSign
                                        label={t('start_providing_services.form.field.description.placeholder')}
                                    />
                                </List>

                                <CustomButton
                                    className="create-service-provider-popup__button"
                                    disabled={!valid}
                                    size="large"
                                    fullWidth
                                    onClick={handleSubmit}
                                >
                                    {t('Done')}
                                </CustomButton>
                            </>
                        );
                    }}
                </Form>
            </div>
        </MSPopup>
    );
};

export default CreateServiceProviderPopup;

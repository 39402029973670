import { SavedCard } from '@/reducers/paymentCardsReducer';

export const generateToken = async (saveCard: SavedCard): Promise<string> => {
    const card = {
        uid: null,
        ...saveCard,
        expDateMonth: saveCard.expMonth.toString(),
        expDateYear: saveCard.expYear.toString(),
    };
    return new Promise((resolve, reject) => {
        window.cardTokenCallback = (token) => {
            window.cardToken = token;
        };
        window.ccTokenizer = new CCTokenizer({
            card: card,
            format: !card.cardNumber ? '3' : '1',
            errorHandler: function (errorCode, errorMessage) {
                reject(`${errorCode} ${errorMessage}`);
            },
        });
        window.ccTokenizer.loadToken = (cardToken: string) => {
            window.cardToken = cardToken;
        };

        const tokenizerScriptUrlWithToken =
            window.ccTokenizer.tokenServiceUrl + '?value=' + encodeURIComponent(window.ccTokenizer.generateToken());

        const tokenScriptId = 'tokenScript_ccTokenizer____script';

        document.querySelectorAll(`#${tokenScriptId}`).forEach((el) => el.remove());

        const tokenScript = document.createElement('script');
        tokenScript.id = tokenScriptId;
        tokenScript.setAttribute('src', tokenizerScriptUrlWithToken);
        tokenScript.async = true;
        tokenScript.addEventListener('error', (ev) => {
            reject(ev.error);
        });
        document.head.appendChild(tokenScript);

        window.cardTokenCallback = (cardToken: string) => resolve(cardToken);
    });
};

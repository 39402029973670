import { ActionName } from './ActionsModalFacade.types';

import { Product } from '@/generated/marketplaceapi';

export const STATUSES_PERMISSIONS: Record<Product.StatusEnum, ActionName[]> = {
    [Product.StatusEnum.DCL]: ['Edit', 'Delete'],
    [Product.StatusEnum.DRF]: ['Edit', 'Delete'],
    [Product.StatusEnum.APR]: [
        'Edit',
        'Change_Quantity',
        'Promote_good',
        'Withdraw_from_sale',
        'Pause_the_sale_of_goods',
    ],
    [Product.StatusEnum.PBL]: [
        'Edit',
        'Change_Quantity',
        'Promote_good',
        'Withdraw_from_sale',
        'Pause_the_sale_of_goods',
    ],
    [Product.StatusEnum.AFR]: ['Delete'],
    [Product.StatusEnum.OOS]: ['Delete'],
    [Product.StatusEnum.EXP]: ['Delete'],
    [Product.StatusEnum.DSC]: ['Delete'],
    [Product.StatusEnum.SUS]: ['Edit', 'Change_Quantity', 'Delete', 'Start_the_sale_of_goods'],
};

export const STATUSES_PERMISSIONS_SERVICES: Record<Product.StatusEnum, ActionName[]> = {
    [Product.StatusEnum.DCL]: ['Edit', 'Delete'],
    [Product.StatusEnum.DRF]: ['Edit', 'Delete'],
    [Product.StatusEnum.APR]: ['Edit', 'Pause_the_sale_of_goods'],
    [Product.StatusEnum.PBL]: ['Edit', 'Pause_the_sale_of_goods'],
    [Product.StatusEnum.AFR]: ['Delete'],
    [Product.StatusEnum.OOS]: ['Delete'],
    [Product.StatusEnum.EXP]: ['Delete'],
    [Product.StatusEnum.DSC]: ['Delete'],
    [Product.StatusEnum.SUS]: ['Edit', 'Delete', 'Start_the_sale_of_goods'],
};

import { f7 } from 'framework7-react';
import { useEffect } from 'react';

export const useErrorAlert = (error: unknown, shouldDo = true, onOk?: () => void) => {
    useEffect(() => {
        if (error && shouldDo) {
            f7.dialog.alert(`${error}`, onOk);
        }
    }, [error, onOk, shouldDo]);
};

import HaversineGeolocation from 'haversine-geolocation';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DistanceProps } from './Distance.types';
import { prepareCoords } from './utils';

const DistanceInner = React.memo(({ coordinatesA, coordinatesB }: DistanceProps): JSX.Element => {
    const { t } = useTranslation();

    const distance = useMemo(
        () => HaversineGeolocation.getDistanceBetween(prepareCoords(coordinatesA), prepareCoords(coordinatesB)),
        [coordinatesA, coordinatesB],
    );

    return distance > 0 ? (
        <span className="distance">
            {distance} {t('km')}
        </span>
    ) : null;
});
DistanceInner.displayName = 'DistanceInner';

export const Distance = ({ coordinatesA, coordinatesB }: DistanceProps): JSX.Element =>
    Object.values(coordinatesA).includes(0) || Object.values(coordinatesB).includes(0) ? (
        <></>
    ) : (
        <DistanceInner coordinatesA={coordinatesA} coordinatesB={coordinatesB} />
    );

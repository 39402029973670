import {
    BannersActions,
    HOME_BANNERS_LIST_LOADING,
    HOME_BANNERS_LIST_LOADING_ERROR,
    HOME_BANNERS_LIST_LOADING_SUCCESS,
    MARKETING_BANNERS_LIST_LOADING,
    MARKETING_BANNERS_LIST_LOADING_ERROR,
    MARKETING_BANNERS_LIST_LOADING_SUCCESS,
    PARTNERS_BANNERS_LIST_LOADING,
    PARTNERS_BANNERS_LIST_LOADING_ERROR,
    PARTNERS_BANNERS_LIST_LOADING_SUCCESS,
} from '@/actions/bannersActions';
import { Banner } from '@/generated/marketplaceapi';

export interface IBannersState {
    loading?: boolean;
    error?: unknown;
    marketingBanners?: Banner[];
    marketingBannersCount?: number;
    homeBanners?: Banner[];
    homeBannersCount?: number;
    partnersBanners?: Banner[];
    partnersBannersCount?: number;
}

const initialState: IBannersState = {
    loading: false,
    error: undefined,
    marketingBanners: undefined,
    marketingBannersCount: 0,
    homeBanners: undefined,
    homeBannersCount: 0,
    partnersBanners: undefined,
    partnersBannersCount: 0,
};

const bannersReducer = (state = initialState, action: BannersActions): IBannersState => {
    switch (action.type) {
        case MARKETING_BANNERS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                marketingBanners: [],
                marketingBannersCount: 0,
            };
        case MARKETING_BANNERS_LIST_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                marketingBanners: action.banners,
                marketingBannersCount: action.count,
            };
        case MARKETING_BANNERS_LIST_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case HOME_BANNERS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                homeBanners: [],
                homeBannersCount: 0,
            };
        case HOME_BANNERS_LIST_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                homeBanners: action.banners,
                homeBannersCount: action.count,
            };
        case HOME_BANNERS_LIST_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PARTNERS_BANNERS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                partnersBanners: [],
                partnersBannersCount: 0,
            };
        case PARTNERS_BANNERS_LIST_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                partnersBanners: action.banners,
                partnersBannersCount: action.count,
            };
        case PARTNERS_BANNERS_LIST_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default bannersReducer;

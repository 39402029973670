import cn from 'classnames';
import { Sheet } from 'framework7-react';
import React from 'react';

import { EditReviewModalContent } from '@/components/EditReviewModalContent';
import { Sheets } from '@/types/sheets';

import type { EditReviewSheetProps } from './EditReviewSheet.types';

import './EditReviewSheet.less';

export const EditReviewSheet: React.FC<EditReviewSheetProps> = ({ onDelete, onEditSelector, uid }) => {
    return (
        <Sheet
            className={cn(Sheets.EDIT_REVIEW_SHEET, 'edit-review-sheet', uid)}
            id={Sheets.EDIT_REVIEW_SHEET}
            backdrop
            closeByBackdropClick
        >
            <EditReviewModalContent onEditSelector={onEditSelector} onDelete={onDelete} />
        </Sheet>
    );
};

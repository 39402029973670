import { Toggle } from 'framework7-react';
import React from 'react';
import { Field } from 'react-final-form';

import { ToggleFieldProps } from './ToggleField.types';

export const ToggleField = <T extends boolean | string>(props: ToggleFieldProps<T>): JSX.Element => (
    <Field<boolean> {...props} type="checkbox">
        {({ input: { checked, onChange } }) => <Toggle {...props} checked={checked} onChange={onChange} />}
    </Field>
);

import type { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import type { CartItem } from '@/generated/marketplaceapi';

export enum LocalStorageItems {
    /**
     * array of products uids
     */
    CART = 'CART',
    /**
     * array of cart item uids
     */
    CART_ITEMS_TO_REMOVE = 'CART_ITEMS_TO_REMOVE',
    /**
     * array of products uids
     */
    WISH_LIST = 'WISH_LIST',
    /**
     * refresh authentication token
     */
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    /**
     * access authentication token
     */
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    /**
     * boolean 'true' | 'false' to show 'SetupPayoutModal'
     */
    FIRST_PRODUCT_CREATED = 'FIRST_PRODUCT_CREATED',
    /**
     * Partial<Record<OnbordingMessageType, boolean>> to show OnbordingMessage component
     * true - was already shown
     */
    ONBORDING_MESSAGES = 'ONBORDING_MESSAGES',
}

export type LocalStorageOnbordingMessagesConf = Partial<Record<OnbordingMessageType, boolean>>;

export type LocalStorageCartItem = Pick<
    CartItem,
    | 'productUid'
    | 'quantity'
    | 'itemUid'
    | 'deliveryMethodOption'
    | 'platformDeliveryMethod'
    | 'parameters'
    | 'deliveryCountryCode'
    | 'deliveryPostalCode'
    | 'deliveryPrice'
    | 'deliveryPriceCurrencyCode'
>;

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { DeliveryMethodControllerApi, PlatformDeliveryProvider } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const DELIVERY_PROVIDERS_LOADING = 'DELIVERY_PROVIDERS_LOADING' as const;
export const ALL_DELIVERY_PROVIDERS_LOADED = 'ALL_DELIVERY_PROVIDERS_LOADED' as const;
export const DELIVERY_PROVIDERS_ERROR = 'DELIVERY_PROVIDERS_ERROR' as const;

const deliverProvidersLoading = () => ({
    type: DELIVERY_PROVIDERS_LOADING,
});

const allDeliveryProvidersSuccess = (items: PlatformDeliveryProvider[]) => ({
    type: ALL_DELIVERY_PROVIDERS_LOADED,
    items,
});

const deliveryProvidersError = (error: unknown) => ({
    type: DELIVERY_PROVIDERS_ERROR,
    error,
});

export type DeliveryProvidersActions = ReturnType<
    typeof deliverProvidersLoading | typeof allDeliveryProvidersSuccess | typeof deliveryProvidersError
>;

export const getAllDeliveryProviders = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    if (!isLoggedIn(state)) {
        return;
    }

    dispatch(deliverProvidersLoading());
    try {
        const result = await new DeliveryMethodControllerApi().getAccountPlatformDeliveryProvidersUsingGET();

        handleResponseAndThrowAnErrorIfExists(result);

        dispatch(allDeliveryProvidersSuccess(result.body || []));
    } catch (error) {
        console.error('at deliveryProvidersActions in getAllDeliveryProviders', error);

        dispatch(deliveryProvidersError(error.message));
    }
};

export enum CartItemParameterCode {
    CdekDeliveryAddress = 'cdek.deliveryAddress',
    CdekAddressLine = 'cdek.addressLine',
    CdekCity = 'cdek.city',
    CdekDeliveryType = 'cdek.deliveryType',
    CdekDeliveryCoordinats = 'cdek.deliveryCoordinats',
    CdekDropPointCode = 'cdek.dropPointCode',
    CdekTariffCode = 'cdek.tariffCode',
    CdekDeliveryMinDays = 'cdek.deliveryMinDays',
    CdekDeliveryMaxDays = 'cdek.deliveryMaxDays',
}

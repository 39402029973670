export enum HistogramScaleSize {
    SMALL = 'SMALL', //? for month and custom
    LARGE = 'LARGE', //? for week
    MEDIUM = 'MEDIUM', //? for year (12 month)
}

export enum HistogramColor {
    BASE = 'var(--base-40)',
    PRIMARY = 'var(--brand-primary-40)',
    WARNING = 'var(--warning-20)',
}

export type HistogramValue = {
    color: HistogramColor;
    value: number;
};

export type HistogramStat = {
    values: HistogramValue[];
    startDate: Date;
    endDate: Date;
};

export type HistogramProps = {
    stats: HistogramStat[];
    labelWithoutDate?: boolean;
    scaleSize?: HistogramScaleSize;
    emptyMessage?: string;
    isEmpty?: boolean;
    nearestValue?: number;
    loading?: boolean;
};

import { TFunction } from 'react-i18next';

import { getCategoryTranslateKey } from './getCategoryTranslateKey';

import { ICategoryClassificator } from '@/reducers/categoryReducer';

export const getFullCategoryName = (
    flatCategories: ICategoryClassificator[],
    categoryCode: string,
    t: TFunction,
    separator = '/',
) => {
    let selectedCategory = flatCategories.find((category) => categoryCode === category.categoryCode);
    let name = t(getCategoryTranslateKey(selectedCategory));
    while (selectedCategory) {
        selectedCategory = selectedCategory.parent;
        name = selectedCategory?.categoryName ? t(getCategoryTranslateKey(selectedCategory)) + separator + name : name;
    }
    return name;
};

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IcAddPhoto, IcDelete, IcPlus } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ACCEPTED_IMG_MIME_TYPES } from '@/types/media';

import type { AddImageProps } from './AddImage.types';

import './AddImage.less';

export const AddImage: React.FC<AddImageProps> = ({
    images,
    onDelete,
    onChange: onFileChange,
    max,
    className,
    text,
}) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const isLarge = !images.length && !isMobile;

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onFileChange(e.target.files[0]);
            e.target.value = null;
        },
        [onFileChange],
    );

    const imageItems = useMemo(
        () =>
            images.map((img, index) => (
                <div key={img} className="add-image__image-item">
                    <img src={img} alt="" />
                    <button
                        type="button"
                        aria-label="delete image"
                        className="add-image__image-item-delete"
                        onClick={() => onDelete(index)}
                    >
                        <IcDelete />
                    </button>
                </div>
            )),
        [images, onDelete],
    );

    return (
        <div className={cn('add-image', className)}>
            {imageItems}
            <div
                className={cn(
                    'add-image__empty-image-item',
                    { 'add-image__empty-image-item_large': isLarge },
                    { 'add-image__empty-image-item_hidden': images.length >= max },
                )}
            >
                <input type="file" accept={ACCEPTED_IMG_MIME_TYPES} onChange={onChange} />
                <div>
                    {isMobile ? <IcAddPhoto /> : <IcPlus />}
                    {isLarge && (text || t('Add Image'))}
                </div>
            </div>
        </div>
    );
};

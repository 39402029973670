import { productToSliderItem } from './productToSliderItem';

import { CarouselItem } from '@/components/Carousel';
import { IProduct } from '@/reducers/productReducer';

export const productsToSliderItems = (
    items: Partial<IProduct>[],
    sliderItemClickHandle: (item: CarouselItem) => void,
    productsWishList: IProduct[] = [],
): CarouselItem[] => items.map((item) => productToSliderItem(item, sliderItemClickHandle, productsWishList));

import { List, ListButton } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EditReviewModalContentProps } from './EditReviewModalContent.types';

import { IcDelete, IcEdit } from '@/assets';

import './EditReviewModalContent.less';

export const EditReviewModalContent: React.FC<EditReviewModalContentProps> = ({ onDelete, onEditSelector }) => {
    const { t } = useTranslation();
    return (
        <List className="edit-review-modal__content" noHairlinesBetween>
            <ListButton popupOpen={onEditSelector} sheetClose>
                <IcEdit />
                {t('Edit Review')}
            </ListButton>
            <ListButton onClick={onDelete} sheetClose>
                <IcDelete />
                {t('Delete Review')}
            </ListButton>
        </List>
    );
};

import cn from 'classnames';
import React, { useMemo } from 'react';

import { AvatarProps } from './Avatar.types';

import { IcAvatarStub } from '@/assets';

import './Avatar.less';

export const Avatar = React.memo(({ profile, className, ...props }: AvatarProps) => {
    const style = useMemo<React.CSSProperties>(
        () =>
            profile?.person?.profilePictureUrl
                ? {
                      backgroundImage: `url(${profile.person.profilePictureUrl})`,
                  }
                : {},
        [profile?.person?.profilePictureUrl],
    );

    return (
        <div
            {...props}
            className={cn('avatar', `avatar-${profile?.profileColorNumber}`, className, {
                'with-initials': profile.person && !profile.person?.profilePictureUrl,
            })}
            style={style}
        >
            {!profile?.person?.profilePictureUrl && (
                <span>
                    {profile.person?.surname && profile.person?.surname[0]}
                    {profile.person?.name && profile.person?.name[0]}
                </span>
            )}
            {!(profile?.uid || profile?.person?.profilePictureUrl) && <IcAvatarStub />}
        </div>
    );
});
Avatar.displayName = 'Avatar';

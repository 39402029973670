import cn from 'classnames';
import React from 'react';

import { ImgWithBlurredFrameProps } from './ImgWithBlurredFrame.types';

import './ImgWithBlurredFrame.less';

export const ImgWithBlurredFrame: React.FC<ImgWithBlurredFrameProps> = ({
    className,
    slot,
    src,
    disabled = false,
    ...props
}) => (
    <div {...props} className={cn('blurred-image-container', { disabled }, className)} slot={slot}>
        <img className="blurred-image-container__blurred-image" alt="blurred" src={src} />
        <img className="blurred-image-container__real-image" alt="real" src={src} />
    </div>
);

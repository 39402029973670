import cn from 'classnames';
import { BlockTitle } from 'framework7-react';
import React, { useEffect, useMemo, useState } from 'react';
import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { useTranslation } from 'react-i18next';

import { containerStyle } from './MapBlock.constants';

import { MapBlockProps } from './MapBlock.types';

import { Distance } from '@/components/Distance';
import { LatitudeLongitudeToLatLng } from '@/components/Distance/utils';
import { Map } from '@/components/Map';
import { useAppSelector } from '@/hooks/store';
import { createAddressLabel, detectLocation, toLatLgnLiteral } from '@/utils';

import './MapBlock.less';

export const MapBlock = ({ product: { address, coordinates }, className }: MapBlockProps) => {
    const { t } = useTranslation();

    const { countryClassificator } = useAppSelector((state) => state.classificatorReducer);

    const [userLatLng, setUserLatLng] = useState<LatLng>({ lat: 0, lng: 0 });

    useEffect(() => {
        detectLocation().then((location) => {
            if (location && location?.latitude !== 0 && location?.longitude !== 0) {
                setUserLatLng(LatitudeLongitudeToLatLng(location));
            }
        });
    }, []);

    const addressString = useMemo(
        () => address && createAddressLabel(address, countryClassificator),
        [address, countryClassificator],
    );

    if (!address) return null;

    return (
        <div className={cn('product-details-map-block', className)}>
            <BlockTitle>{t('Location')}</BlockTitle>
            {addressString && (
                <div className="product-details-map-block__location">
                    <i className="icon ic-location" />
                    <span className="product-details-map-block__address">{addressString}</span>
                    <Distance coordinatesA={toLatLgnLiteral(coordinates)} coordinatesB={userLatLng} />
                </div>
            )}
            {coordinates && (
                <div className="product-details-map-block__map">
                    <Map
                        containerStyle={containerStyle}
                        zoom={12}
                        center={toLatLgnLiteral(coordinates)}
                        disableDefaultUI
                    />
                </div>
            )}
        </div>
    );
};

import cn from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NotAvailableItemProps, NotAvailableItemType } from './NotAvailableItem.types';

import { IcNotAvailableGray } from '@/assets';

import './NotAvailableItem.less';

export const NotAvailableItem = ({ type, horizontal, textOutOfCard, className }: NotAvailableItemProps) => {
    const { t } = useTranslation();

    const title = useMemo(() => {
        switch (type) {
            case NotAvailableItemType.PRODUCT:
                return t('This product is no longer available');
            case NotAvailableItemType.STORE:
                return t('This store is no longer available');
        }
    }, [t, type]);

    return (
        <div className={cn('not-available-item', { horizontal, 'text-out-of-card': textOutOfCard }, className)}>
            <div
                className={cn('not-available-item__icon-container', {
                    'text-out-of-card': textOutOfCard,
                })}
            >
                <IcNotAvailableGray />
            </div>
            <p className={cn('not-available-item__text', { 'text-out-of-card': textOutOfCard })}>{title}</p>
        </div>
    );
};

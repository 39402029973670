import React, { useMemo } from 'react';

import { ConfigureSaleMobileStepsProps } from './ConfigureSaleMobileSteps.types';

import { MobileStepsControls } from '@/components/MobileStepsControls';
import { ConfigureSaleSteps } from '@/pages/ConfigureSale/ConfigureSale.types';

import './ConfigureSaleMobileSteps.less';

export const ConfigureSaleMobileSteps = ({
    step,
    onPrevStep,
    onNextStep,
    stepChangeDisabled,
    nextButtonText,
}: ConfigureSaleMobileStepsProps) => {
    const progressSteps = useMemo(
        () =>
            Object.values(ConfigureSaleSteps).reduce(
                (accum, val) => (typeof val === 'number' ? (accum.push(val), accum) : accum),
                [],
            ),
        [],
    );

    const stepsCount = useMemo(() => progressSteps.length, [progressSteps.length]);

    const progressStep = useMemo(() => progressSteps.indexOf(step), [progressSteps, step]);

    return (
        <MobileStepsControls
            className="configure-sale-mobile-steps"
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            prevVisible={step !== ConfigureSaleSteps.GENERAL_INFO}
            progressStep={progressStep}
            stepChangeDisabled={stepChangeDisabled}
            stepsCount={stepsCount}
            nextButtonText={nextButtonText}
        />
    );
};

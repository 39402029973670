import cn from 'classnames';
import { Badge } from 'framework7-react';
import React from 'react';

import { useAccountStatusText } from './AccountStatus.hooks';
import { AccountStatusBadgeProps } from './AccountStatusBadge.types';

import './AccountStatusBadge.less';

export const AccountStatusBadge: React.FC<AccountStatusBadgeProps> = ({ status }) => {
    const statusText = useAccountStatusText(status);
    return statusText ? (
        <Badge className={cn('account-status-badge', `account-status-badge-${status.toString()}`)}>{statusText}</Badge>
    ) : null;
};

export const getStatusText = (status: string) => {
    switch (status) {
        case 'N':
            return 'Verification Required';
        case 'V':
            return 'Verified';
        case 'E':
            return 'Expired';
        case 'T':
            return 'Transaction';
        default:
            return 'Not found';
    }
};

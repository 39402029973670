import { connect } from 'react-redux';

import { IApplicationStore } from '../reducers/rootReducer';

import { togglePreloader } from '@/actions/preloaderActions';
import { AppDispatch } from '@/hooks/store';

export interface IPreloaderProps {
    preloader?: boolean;
    togglePreloader?(show?: boolean): void;
}

const mapStateToProps = (state: IApplicationStore, ownProps: Pick<IPreloaderProps, 'preloader'>) => ({
    preloader: typeof ownProps.preloader === 'undefined' ? state.preloaderReducer.preloader : ownProps.preloader,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    togglePreloader: (show?: boolean) => dispatch(togglePreloader(show)),
});

export default connect(mapStateToProps, mapDispatchToProps);

import cn from 'classnames';
import { List } from 'framework7-react';
import React from 'react';

import { SideMenuProps } from './SideMenu.types';
import { SideMenuItem } from './SideMenuItem';

import './SideMenu.less';

export const SideMenu = React.memo(
    <S extends React.ReactText = number>({ items, onItemClick, className }: SideMenuProps<S>): JSX.Element => (
        <List className={cn('side-menu__steps', className)}>
            {items.map((item) => (
                <SideMenuItem key={item.title} {...item} onClick={onItemClick} />
            ))}
        </List>
    ),
);
SideMenu.displayName = 'SideMenu';

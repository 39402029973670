import cn from 'classnames';
import { Button, List, ListItem, Navbar, NavLeft, NavRight, NavTitle } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NavbarInitialProps } from './NavbarInitial.types';

import { IcDelete, IcEdit, IcListUnordered, IcMore } from '@/assets';
import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { Sheet } from '@/components/Sheet';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Sheets } from '@/types/sheets';

import './NavbarInitial.less';

export const NavbarInitial: React.FC<NavbarInitialProps> = ({
    showDelete,
    showEdit,
    showMoreButton,
    onDeleteClick,
    onEditClick,
    onImportHistoryClick,
    slot,
    show,
}) => {
    const { t } = useTranslation();
    const [modalOpened, setModalOpened] = useState(false);
    const [openModal, closeModal] = useBooleanState(setModalOpened);
    const isMobile = useAppSelector(getIsMobile);

    const handleDelete = useCallback(() => {
        if (isMobile) {
            closeModal();
        }
        onDeleteClick?.();
    }, [closeModal, isMobile, onDeleteClick]);

    return (
        <Navbar className={cn('import-products-navbar-initial', { show })} noHairline noShadow slot={slot}>
            <NavLeft>
                <AdaptiveBackLink
                    force
                    mobileLink="/profile/seller-area/my-goods/"
                    desktopLink="/profile/seller-area/my-goods/"
                >
                    {t('Back')}
                </AdaptiveBackLink>
            </NavLeft>
            <NavTitle>{t('Import')}</NavTitle>
            {showMoreButton && (
                <NavRight>
                    {showDelete && !isMobile && (
                        <Button className="header-button" onClick={onDeleteClick}>
                            <IcDelete />
                        </Button>
                    )}
                    {showEdit && !isMobile && (
                        <Button className="header-button" onClick={onEditClick}>
                            <IcEdit />
                        </Button>
                    )}
                    <CustomPopoverWrapper
                        wrapperClassname="more-button"
                        closeByClickInside
                        popoverTriggerElement={(triggerPopover) => (
                            <Button
                                onClick={isMobile ? openModal : triggerPopover}
                                className={cn('header-button', {
                                    active: !isMobile && modalOpened,
                                })}
                            >
                                <IcMore />
                            </Button>
                        )}
                    >
                        {() => (
                            <List>
                                {showEdit && isMobile && (
                                    <ListItem title={t('Edit')} onClick={onEditClick}>
                                        <IcEdit slot="media" />
                                    </ListItem>
                                )}
                                {showDelete && isMobile && (
                                    <ListItem title={t('Delete')} onClick={handleDelete}>
                                        <IcDelete slot="media" />
                                    </ListItem>
                                )}
                                <ListItem title={t('Import History')} onClick={onImportHistoryClick}>
                                    {isMobile && <IcListUnordered slot="media" />}
                                </ListItem>
                            </List>
                        )}
                    </CustomPopoverWrapper>
                </NavRight>
            )}
            <Sheet
                opened={modalOpened}
                onSheetClose={closeModal}
                backdrop
                className={cn(Sheets.IMPORT_PRODUCTS_MORE_SHEET)}
            >
                <List>
                    {showEdit && isMobile && (
                        <ListItem title={t('Edit')} onClick={onEditClick}>
                            <IcEdit slot="media" />
                        </ListItem>
                    )}
                    {showDelete && isMobile && (
                        <ListItem title={t('Delete')} onClick={handleDelete}>
                            <IcDelete slot="media" />
                        </ListItem>
                    )}
                    <ListItem title={t('Import History')} onClick={onImportHistoryClick}>
                        {isMobile && <IcListUnordered slot="media" />}
                    </ListItem>
                </List>
            </Sheet>
        </Navbar>
    );
};

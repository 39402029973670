import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { client, marketplaceapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    ProductSource,
    ProductSourceContent,
    ProductSourceControllerApi,
    ProductSourceType,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const PRODUCT_SOURCE_ERROR = 'PRODUCT_SOURCE_ERROR';

export const PRODUCT_SOURCE_FILE_ERROR_CLEAR = 'PRODUCT_SOURCE_FILE_ERROR_CLEAR';

// source types array
export const SOURCE_TYPES_LOADING = 'SOURCE_TYPES_LOADING';
export const SOURCE_TYPES_LOADING_SUCCESS = 'SOURCE_TYPES_LOADING_SUCCESS';

//log message
export const PRODUCT_SOURCE_LOG_LOADING = 'IMPORT_SOURCE_LOG_LOADING';
export const PRODUCT_SOURCE_LOG_LOADING_SUCCESS = 'IMPORT_SOURCE_LOG_LOADING_SUCCESS';

//history
export const PRODUCT_SOURCE_HISTORY_LOADING = 'IMPORT_SOURCE_HISTORY_LOADING';
export const PRODUCT_SOURCE_HISTORY_LOADING_SUCCESS = 'IMPORT_SOURCE_HISTORY_LOADING_SUCCESS';

// load existing import
export const PRODUCT_SOURCE_LOADING = 'IMPORT_SOURCE_LOADING';
export const PRODUCT_SOURCE_LOADING_SUCCESS = 'IMPORT_SOURCE_LOADING_SUCCESS';

// create/update import
export const PRODUCT_SOURCE_CREATE_DRAFT_LOADING = 'PRODUCT_SOURCE_CREATE_DRAFT_LOADING';
export const PRODUCT_SOURCE_CREATE_SAVE_LOADING = 'PRODUCT_SOURCE_CREATE_SAVE_LOADING';
export const PRODUCT_SOURCE_CREATE_LOADING_SUCCESS = 'PRODUCT_SOURCE_CREATE_LOADING_SUCCESS';

export const PRODUCT_SOURCE_DELETE_LOADING = 'PRODUCT_SOURCE_DELETE_LOADING';
export const PRODUCT_SOURCE_DELETE_LOADING_SUCCESS = 'PRODUCT_SOURCE_DELETE_LOADING_SUCCESS';

const productSourceErrorAction = (error?: string) =>
    ({
        type: PRODUCT_SOURCE_ERROR,
        error,
    }) as const;

const sourceTypesLoadingAction = (sourceTypesLoading: boolean) =>
    ({
        type: SOURCE_TYPES_LOADING,
        sourceTypesLoading,
    }) as const;

const sourceTypesLoadingSuccessAction = (sourceTypes: ProductSourceType[]) =>
    ({
        type: SOURCE_TYPES_LOADING_SUCCESS,
        sourceTypes,
    }) as const;

const productSourceLoadingAction = (productSourceLoading: boolean) =>
    ({
        type: PRODUCT_SOURCE_LOADING,
        productSourceLoading,
    }) as const;

const productSourceLoadingSuccessAction = (productSource: ProductSource) =>
    ({
        type: PRODUCT_SOURCE_LOADING_SUCCESS,
        productSource,
    }) as const;

const productSourceCreateDraftLoadingAction = (productSourceLoading: boolean) =>
    ({
        type: PRODUCT_SOURCE_CREATE_DRAFT_LOADING,
        productSourceLoading,
    }) as const;

const productSourceCreateSaveLoadingAction = (productSourceSaveLoading: boolean) =>
    ({
        type: PRODUCT_SOURCE_CREATE_SAVE_LOADING,
        productSourceSaveLoading,
    }) as const;

const productSourceCreateLoadingSuccessAction = (productSource: ProductSource, productSourceLog?: string) =>
    ({
        type: PRODUCT_SOURCE_CREATE_LOADING_SUCCESS,
        productSource,
        productSourceLog,
    }) as const;

const deleteProductSourceLoadingAction = (productSourceLoading: boolean) =>
    ({
        productSourceLoading,
        type: PRODUCT_SOURCE_DELETE_LOADING,
    }) as const;

const deleteProductSourceLoadingSuccessAction = () =>
    ({
        type: PRODUCT_SOURCE_DELETE_LOADING_SUCCESS,
    }) as const;

const productSourceLogLoadingAction = (productSourceLogLoading: boolean) =>
    ({
        productSourceLogLoading,
        type: PRODUCT_SOURCE_LOG_LOADING,
    }) as const;

const productSourceLogLoadingSuccessAction = (productSourceLog: string) =>
    ({
        productSourceLog,
        type: PRODUCT_SOURCE_LOG_LOADING_SUCCESS,
    }) as const;

const productSourceHistoryLoadingAction = (productSourceHistoryLoading: boolean) =>
    ({
        type: PRODUCT_SOURCE_HISTORY_LOADING,
        productSourceHistoryLoading,
    }) as const;

const productSourceHistoryLoadingSuccessAction = (productSourceHistory: ProductSourceContent[]) =>
    ({
        type: PRODUCT_SOURCE_HISTORY_LOADING_SUCCESS,
        productSourceHistory,
    }) as const;

export const productSourceFileErrorClearAction = () => ({ type: PRODUCT_SOURCE_FILE_ERROR_CLEAR }) as const;

export type productSourceActions = ReturnType<
    | typeof productSourceErrorAction
    | typeof sourceTypesLoadingAction
    | typeof sourceTypesLoadingSuccessAction
    | typeof productSourceLoadingAction
    | typeof productSourceCreateDraftLoadingAction
    | typeof productSourceLoadingSuccessAction
    | typeof productSourceCreateLoadingSuccessAction
    | typeof deleteProductSourceLoadingAction
    | typeof deleteProductSourceLoadingSuccessAction
    | typeof productSourceLogLoadingAction
    | typeof productSourceLogLoadingSuccessAction
    | typeof productSourceHistoryLoadingAction
    | typeof productSourceHistoryLoadingSuccessAction
    | typeof productSourceFileErrorClearAction
    | typeof productSourceCreateSaveLoadingAction
>;

const uploadFileForImport = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('bucket', 'import');
    formData.append('file', file);
    const result = await client.post(`${marketplaceapiURL}/file/upload`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });

    handleResponseAndThrowAnErrorIfExists(result.data);

    const url = result?.data?.body[0]?.url;

    return url;
};

export const loadProductSourceTypes = () => async (dispatch: AppDispatch) => {
    dispatch(sourceTypesLoadingAction(true));
    try {
        const result = await new ProductSourceControllerApi().productSourceTypeListUsingGET();
        handleResponseAndThrowAnErrorIfExists(result);
        dispatch(sourceTypesLoadingSuccessAction(result.body ?? []));
    } catch (error) {
        console.error('at productSourceActions in loadProductSourceTypes', error);
        dispatch(productSourceErrorAction(error.message ?? error.toString()));
    } finally {
        dispatch(sourceTypesLoadingAction(false));
    }
};

export const loadProductSource = () => async (dispatch: AppDispatch) => {
    dispatch(productSourceLoadingAction(true));

    try {
        const result = await new ProductSourceControllerApi().productSourceListUsingGET1();
        handleResponseAndThrowAnErrorIfExists(result);

        dispatch(
            productSourceLoadingSuccessAction({
                ...result?.body?.[0],
                currentContent: { ...result?.body?.[0].currentContent },
            }),
        );
    } catch (error) {
        console.error('at productSourceActions in loadProductSource', error);
        dispatch(productSourceErrorAction(error.message ?? error.toString()));
    } finally {
        dispatch(productSourceLoadingAction(false));
    }
};

export const createOrUpdateProductSourceDraft =
    (_productSource: ProductSource, edit = false, file?: File | { name: string }) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productSourceCreateDraftLoadingAction(true));
        const state = getState();
        let productSource = {
            ...(edit ? state.productSourceReducer.productSource : {}),
            ..._productSource,
        };

        try {
            const controller = new ProductSourceControllerApi();
            if ((file as File)?.type && productSource.contentType === 'file') {
                const resp = await uploadFileForImport(file as File);
                productSource.url = resp;
            }

            const result = edit
                ? await controller.updateProductSourceUsingPOST1(productSource)
                : await controller.addProductSourceUsingPUT1(productSource);

            handleResponseAndThrowAnErrorIfExists(result);

            firebaseAnalyticsInstance.importProductsConfigured?.();
            productSource = result.body?.[0];

            dispatch(
                productSourceCreateLoadingSuccessAction({
                    ...productSource,
                    currentContent: { ...productSource?.currentContent },
                }),
            );
        } catch (error) {
            console.error('at productSourceActions in createOrUpdateProductSource', error);
            dispatch(productSourceErrorAction(error.message ?? error.toString()));
        } finally {
            dispatch(productSourceCreateDraftLoadingAction(false));
        }
    };

export const deleteProductSource = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(deleteProductSourceLoadingAction(true));
    try {
        const responce = await new ProductSourceControllerApi().deleteProductSourceUsingDELETE1(
            {
                uid,
            },
            { headers: { 'Content-Type': 'application/json' } },
        );
        handleResponseAndThrowAnErrorIfExists(responce);

        if (responce.successful) {
            dispatch(deleteProductSourceLoadingSuccessAction());
        }
    } catch (error) {
        console.error('at productSourceActions in deleteProductSource', error);
        dispatch(productSourceErrorAction(error.message));
    } finally {
        dispatch(deleteProductSourceLoadingAction(false));
    }
};

export const loadProductSourceLog = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(productSourceLogLoadingAction(true));
    try {
        const responce = await new ProductSourceControllerApi().productSourceHistoryUsingGET1(uid);
        handleResponseAndThrowAnErrorIfExists(responce);
        dispatch(productSourceLogLoadingSuccessAction(responce.body?.[responce.body?.length - 1]?.errorMessage));
    } catch (error) {
        console.error('at productSourceActions in loadProductSourceLog', error);
        dispatch(productSourceErrorAction(error.message ?? error.toString()));
    } finally {
        dispatch(productSourceLogLoadingAction(false));
    }
};

export const loadProductSourceHistory = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(productSourceHistoryLoadingAction(true));
    try {
        const responce = await new ProductSourceControllerApi().productSourceHistoryUsingGET1(uid);
        handleResponseAndThrowAnErrorIfExists(responce);
        dispatch(productSourceHistoryLoadingSuccessAction(responce?.body ?? []));
    } catch (error) {
        console.error('at productSourceActions in loadProductSourceHistory', error);
        dispatch(productSourceErrorAction(error.message ?? error.toString()));
    } finally {
        dispatch(productSourceHistoryLoadingAction(false));
    }
};

export const createProductSource =
    (_productSource: ProductSource) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productSourceCreateSaveLoadingAction(true));
        const state = getState();

        const productSource = {
            ...state.productSourceReducer.productSource,
            ..._productSource,
        };
        try {
            const controller = new ProductSourceControllerApi();
            await controller.updateAccountProductCategoryUsingPOST(
                productSource?.currentContent?.categoryMapping,
                false,
                getLanguageCode(state),
            );
            const result = await controller.updateProductSourceUsingPOST1(productSource);
            handleResponseAndThrowAnErrorIfExists(result);
            dispatch(productSourceCreateLoadingSuccessAction(result?.body?.[0]));
        } catch (error) {
            console.error('at productSourceActions in createProductSource', error);
            dispatch(productSourceErrorAction(error.message ?? error.toString()));
        } finally {
            dispatch(productSourceCreateSaveLoadingAction(false));
        }
    };

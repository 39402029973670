import React from 'react';
import { Field } from 'react-final-form';

import { CustomSelectFieldProps } from './CustomSelectField.types';

import { CustomSelect, CustomSelectValue } from '@/components/CustomSelect';

export const CustomSelectField: React.FC<CustomSelectFieldProps> = (props) => (
    <Field<CustomSelectValue> {...props}>
        {({ input: { value, onChange, onBlur, onFocus }, meta: { error } }) => (
            <CustomSelect
                {...props}
                ref={props.customSelectRef}
                value={value}
                onChange={onChange}
                errorMessage={error}
                validate={!!error}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        )}
    </Field>
);

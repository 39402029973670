import { CategoryMappingValues } from './CategoryMapping.types';

import { REQUIRED_FIELD_ERROR } from '@/shared/constants';

export const validateCategoryMapping = (values: CategoryMappingValues) =>
    Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
            key,
            value.externalCategoryCode ? undefined : REQUIRED_FIELD_ERROR,
        ]),
    );

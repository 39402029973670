import { productToSliderImages } from './productToSliderImages';

import { IProduct } from '@/reducers/productReducer';

export const productToSliderItems = (product: IProduct) => {
    const { video } = product;

    let imagesItems = productToSliderImages(product);
    if (video) {
        /* Video must be first */
        imagesItems = [{ videoId: video.id, videoType: video.type }, ...imagesItems];
    }

    return imagesItems;
};

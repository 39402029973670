import { Button, f7 } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useShareOptionsConfig } from './ShareOptions.hooks';

import { ShareOptionsProps } from './ShareOptions.types';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { IcLink } from '@/assets';

import './ShareOptions.less';

export const ShareOptions = ({ url, shareTitle, analyticsShareProduct, analyticsShareStore }: ShareOptionsProps) => {
    const { t } = useTranslation();

    const shareItems = useShareOptionsConfig();

    const handleAnalytics = useCallback(() => {
        switch (true) {
            case !!analyticsShareProduct:
                return firebaseAnalyticsInstance.shareProduct?.(analyticsShareProduct);
            case !!analyticsShareStore:
                return firebaseAnalyticsInstance.shareStore?.(analyticsShareStore);
        }
    }, [analyticsShareProduct, analyticsShareStore]);

    const copyToClipboard = useCallback(() => {
        navigator.clipboard
            .writeText(url)
            .then(() =>
                f7.toast.show({
                    text: t('link_copied'),
                    closeTimeout: 4000,
                }),
            )
            .catch(() => {
                f7.dialog.alert(t('Something went wrong'));
            });
    }, [t, url]);

    return (
        <div className="share-options-container">
            {shareItems?.map((item) => (
                <div key={item.text} onClick={handleAnalytics}>
                    {item.component ? (
                        <item.component className="share-option-button" url={url} title={shareTitle}>
                            {item.icon && <item.icon />}
                            <span>{item.text}</span>
                        </item.component>
                    ) : (
                        <Button className="share-option-button">
                            {item.icon && <item.icon />}
                            <span>{item.text}</span>
                        </Button>
                    )}
                </div>
            ))}
            <Button onClick={copyToClipboard} className="share-option-button">
                <IcLink />
                <span>{t('Copy Link')}</span>
            </Button>
        </div>
    );
};

import cn from 'classnames';
import { List, ListButton, Sheet } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IcExclamationPointInCircle } from '@/assets';

import { Sheets } from '@/types/sheets';

import type { ReportAbuseSheetProps } from './ReportAbuseSheet.types';

import './ReportAbuseSheet.less';

export const ReportAbuseSheet: React.FC<ReportAbuseSheetProps> = ({ className, ...props }) => {
    const { t } = useTranslation();

    return (
        <Sheet
            className={cn(Sheets.REPORT_ABUSE_SHEET, 'report-abuse-sheet', className)}
            id={Sheets.REPORT_ABUSE_SHEET}
            backdrop
            closeByBackdropClick
            {...props}
        >
            <List>
                <ListButton>
                    <IcExclamationPointInCircle />
                    {t('Report abuse')}
                </ListButton>
            </List>
        </Sheet>
    );
};

import { ORDER_RESET, OrdersActions } from '@/actions/ordersActions';
import {
    PAYMENT_ERROR,
    PAYMENT_LOADING,
    PAYMENT_RESET_ERRORS,
    PAYMENT_SUCCESS,
    PaymentActions,
} from '@/actions/paymentActions';
import { PaymentParameter } from '@/generated/paymentapi';

export interface IPaymentState {
    loading: boolean;
    error: unknown;
    paymentUid: string;
    redirectUrl: string;
    status: string;
    parameters: Array<PaymentParameter>;
    actionConfirmationType: string;
}

const initialState: IPaymentState = {
    loading: false,
    error: undefined,
    paymentUid: undefined,
    redirectUrl: undefined,
    status: undefined,
    parameters: [],
    actionConfirmationType: undefined,
};

const paymentReducer = (state = initialState, action: PaymentActions | OrdersActions): IPaymentState => {
    switch (action.type) {
        case PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PAYMENT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PAYMENT_SUCCESS: {
            const { actionConfirmationType, parameters, paymentUid, redirectUrl, status } = action.payload;
            return {
                ...state,
                loading: false,
                error: undefined,
                actionConfirmationType,
                parameters,
                paymentUid,
                redirectUrl,
                status,
            };
        }
        case ORDER_RESET:
            return initialState;
        case PAYMENT_RESET_ERRORS:
            return {
                ...state,
                error: undefined,
            };
        default:
            return state;
    }
};

export default paymentReducer;

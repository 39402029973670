import { FocusedInput } from '@datepicker-react/hooks';
import React, { useMemo, useState } from 'react';

import { SingleDatePickerProps } from './SingleDatePicker.types';

import { Picker } from '@/components/DatePickers/Picker';

export const SingleDatePicker = ({
    date,
    setDate,
    isEqualDates,
    minBookingDate,
    maxBookingDate,
    ...props
}: SingleDatePickerProps) => {
    const [fictitiousDate, setFictitiousDate] = useState<Date>(null);

    const [focus, setFocus] = useState<FocusedInput>('startDate');

    const minBookingDateValue = useMemo(
        () => minBookingDate ?? (focus === 'endDate' && date ? date : new Date(Date.now() + 1000 * 60 * 60 * 24)),
        [date, focus, minBookingDate],
    );

    return (
        <Picker
            {...props}
            singleDate
            startDateState={[date, setDate]}
            endDateState={isEqualDates ? [date, setDate] : [fictitiousDate, setFictitiousDate]}
            focus={focus}
            setFocus={setFocus}
            minBookingDate={minBookingDateValue}
            maxBookingDate={maxBookingDate}
            initialVisibleMonth={date}
        />
    );
};

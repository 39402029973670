import { RouteParameters } from '@/routes';

export const ordersRoutes: RouteParameters[] = [
    {
        path: '/orders/',
        async: (_to, _from, resolve) => import('./MyOrdersPage').then((rc) => resolve({ component: rc.MyOrdersPage })),
        routes: [
            {
                path: '/details/:uid',
                async: (_to, _from, resolve) =>
                    import('@/pages/MyOrderDetailsPage').then((rc) => resolve({ component: rc.MyOrderDetailsPage })),
            },
        ],
    },
];

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { deliveryKeysTitles } from './DeliveryInformationBlock.constants';
import { DeliveryInformationBlockProps } from './DeliveryInformationBlock.types';

import { IcCheckMark } from '@/assets';

import './DeliveryInformationBlock.less';

export const DeliveryInformationBlock = React.memo<DeliveryInformationBlockProps>(({ product }) => {
    const { t } = useTranslation();

    const items = useMemo(
        () =>
            Object.entries(deliveryKeysTitles)
                .map(
                    ([key, title]) =>
                        product[key] && (
                            <div key={key} className="product-details-delivery-information-block__item">
                                <IcCheckMark /> {t(title)}
                            </div>
                        ),
                )
                .filter(Boolean),
        [product, t],
    );

    return items.length ? <div className="product-details-delivery-information-block">{items}</div> : null;
});
DeliveryInformationBlock.displayName = 'DeliveryInformationBlock';

import { t } from 'i18next';

import { CreateServiceProviderFormErrors, CreateServiceProviderFormValues } from './CreateServiceProviderPopup.types';

import { REQUIRED_FIELD_ERROR } from '@/shared/constants';
import { trimMultipleSpaces } from '@/utils/string/trimMultipleSpaces';

const MAX_LENGTH_NAME = 20;
const MAX_LENGTH_DESCRIPTION = 50;

export const validateFormData = (values: CreateServiceProviderFormValues): CreateServiceProviderFormErrors => ({
    name: !values.name
        ? REQUIRED_FIELD_ERROR
        : trimMultipleSpaces(values.name)?.length > MAX_LENGTH_NAME
        ? t('common_validation_error_message.field_length', { amount: MAX_LENGTH_NAME })
        : undefined,
    description: !values.description
        ? REQUIRED_FIELD_ERROR
        : trimMultipleSpaces(values.description)?.length > MAX_LENGTH_DESCRIPTION
        ? t('common_validation_error_message.field_length', { amount: MAX_LENGTH_DESCRIPTION })
        : undefined,
});

import { Block, BlockTitle, Button } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InitialStepProps } from './InitialStep.types';

import './InitialStep.less';

export const InitialStep: React.FC<InitialStepProps> = React.memo(({ onConfigureClick }) => {
    const { t } = useTranslation();
    return (
        <Block className="import-products-initial-step">
            <BlockTitle>{t('Start importing your products')}</BlockTitle>
            <p className="description">
                {t('Use your template to import products from any platform into your store.')}
            </p>
            <Button large fill round onClick={onConfigureClick}>
                {t('Configure Import')}
            </Button>
        </Block>
    );
});

InitialStep.displayName = 'InitialStep';

import { Block } from 'framework7-react';
import React from 'react';

import { NotAvailableMessageProps } from './NotAvailableMessage.types';

import IcNoResults from '@/assets/mascots/IcNoResults';

import './NotAvailableMessage.less';

export const NotAvailableMessage: React.FC<NotAvailableMessageProps> = ({ title, subtitle }) => {
    return (
        <Block className="not-available-message">
            <IcNoResults className="not-available-message-icon" />
            <h3 className="not-available-message-title">{title}</h3>
            <p className="not-available-message-description">{subtitle}</p>
        </Block>
    );
};

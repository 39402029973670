import {
    SwitchAccountToBusinessFormErrors,
    SwitchAccountToBusinessFormValues,
} from './SwitchAccountToBusinessPopup.types';

import { REQUIRED_FIELD_ERROR } from '@/shared/constants';

export const validateFormData = (values: SwitchAccountToBusinessFormValues): SwitchAccountToBusinessFormErrors => ({
    companyType: !values.companyType ? REQUIRED_FIELD_ERROR : undefined,
    registrationNumber: !values.registrationNumber ? REQUIRED_FIELD_ERROR : undefined,
    taxPayerIn: !values.taxPayerIn ? REQUIRED_FIELD_ERROR : undefined,
    storeName: !values.storeName ? REQUIRED_FIELD_ERROR : undefined,
    storeDescription: !values.storeDescription ? REQUIRED_FIELD_ERROR : undefined,
});

import cn from 'classnames';
import { Button, Link } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_LIST_SIZE, MOBILE_MAX_LIST_SIZE } from './ReviewList.constants';

import { loadProductReviews } from '@/actions/reviewActions';
import { IcArrow } from '@/assets';
import { ReviewList as RList } from '@/components/ReviewList';
import { ReviewListPopup } from '@/components/ReviewListPopup';
import { SortReviewsButton } from '@/components/SortReviewsButton';
import { StarRating } from '@/components/StarRating';
import { Translation } from '@/components/Translation';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { ReviewSortingFields } from '@/shared/constants';
import { Popups } from '@/types/popups';
import { getProductRating } from '@/utils';

import type { ReviewListProps } from './ReviewList.types';

import './ReviewList.less';

export const ReviewList: React.FC<ReviewListProps> = ({ className }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(getIsMobile);
    const language = useAppSelector(getLanguageCode);

    const { sort: activeSortValue, reviews, productRating } = useAppSelector((state) => state.reviewReducer);

    const changeSortValue = useCallback(
        (sort: ReviewSortingFields) => dispatch(loadProductReviews(undefined, sort)),
        [dispatch],
    );

    const [translated, setTranslated] = useState(() => reviews.some((review) => review.languageCode !== language));

    const toggleTranslated = useCallback(() => setTranslated((prev) => !prev), []);
    const [expandedList, setExpandedList] = useState(false);
    const expandList = useCallback(() => setExpandedList(true), []);

    return (
        reviews.length > 0 && (
            <div className={cn('product-details-review-list', className)}>
                <div className="product-details-review-list__header">
                    <h2 className="product-details-review-list__title">
                        {reviews.length} {t('Reviews')}
                    </h2>
                    {isMobile ? (
                        <>
                            <span className="product-details-review-list__rating">
                                <StarRating value={getProductRating(productRating)} readOnly size="small" primary />
                                {getProductRating(productRating).toFixed(2)}
                            </span>
                            <Link
                                className="product-details-review-list__see-all-top"
                                popupOpen={`.${Popups.REVIEW_LIST_POPUP}`}
                            >
                                <IcArrow />
                            </Link>
                        </>
                    ) : (
                        <SortReviewsButton activeSortValue={activeSortValue} changeSortValue={changeSortValue} />
                    )}
                </div>
                {!isMobile && (
                    <Translation translated={translated} onClick={toggleTranslated} source={process.env.APP_NAME} />
                )}
                <RList
                    reviews={reviews}
                    translated={translated}
                    size={isMobile ? MOBILE_MAX_LIST_SIZE : expandedList ? reviews.length : MAX_LIST_SIZE}
                />
                {isMobile && reviews.length > MOBILE_MAX_LIST_SIZE && (
                    <Button
                        className="product-details-review-list__see-all-bottom"
                        popupOpen={`.${Popups.REVIEW_LIST_POPUP}`}
                    >
                        {t('See All {{count}} Reviews', { count: reviews.length })}
                        <IcArrow />
                    </Button>
                )}
                {!isMobile && reviews.length > MAX_LIST_SIZE && !expandedList && (
                    <Button className="product-details-review-list__more" onClick={expandList}>
                        {t('Show More Reviews')}
                        <IcArrow />
                    </Button>
                )}
                <ReviewListPopup
                    reviews={reviews}
                    productRating={productRating}
                    translated={translated}
                    toggleTranslated={toggleTranslated}
                />
            </div>
        )
    );
};

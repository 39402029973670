import { Preloader } from 'framework7-react';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { RoundImageInputProps } from './RoundImageInput.types';

import { setAlertAction } from '@/actions/alertActions';
import { IcCamera, IcDelete } from '@/assets';

import { ACCEPTED_IMG_MIME_TYPES, ACCEPTED_IMG_SIZE } from '@/types/media';

import './RoundImageInput.less';

export const RoundImageInput = React.forwardRef<HTMLInputElement, RoundImageInputProps>(
    ({ loading = false, imgUrl, mobile = false, onDelete, ...props }, ref) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files[0];

            if (file && file.size > ACCEPTED_IMG_SIZE) {
                dispatch(
                    setAlertAction({
                        text: t('common_validation_error_message.image_upload_size_limit'),
                    }),
                );
                return;
            }

            props.onChange?.(e);
        };

        return (
            <div className={'round-image-input'}>
                <div className="round-image-input__image">
                    {!imgUrl && (
                        <input
                            {...props}
                            ref={ref}
                            type="file"
                            accept={ACCEPTED_IMG_MIME_TYPES}
                            title=""
                            onChange={handleChange}
                        />
                    )}
                    {!imgUrl ? (
                        loading ? (
                            <Preloader size="24" />
                        ) : (
                            <IcCamera />
                        )
                    ) : (
                        <div className="round-image-input__image-wrapper">
                            <img className="round-image-input__image" src={imgUrl} alt="" />
                            <div className="round-image-input__image-action" onClick={onDelete}>
                                <IcDelete />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    },
);
RoundImageInput.displayName = 'RoundImageInput';

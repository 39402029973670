import { SortByButtonItem } from './SortByButtonsGroup.types';

import { SortBy } from '@/types/sort';

export const sortByTypes: SortByButtonItem[][] = [
    [
        { text: 'Popular', type: SortBy.popular },
        { text: 'Sales First', type: SortBy.sales_first },
    ],
    [
        { text: 'Price (Low to High)', type: SortBy.price_low_to_high },
        { text: 'Price (High to Low)', type: SortBy.price_high_to_low },
    ],
    [{ text: 'What`s new', type: SortBy.what_s_new }],
];

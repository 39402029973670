import cn from 'classnames';
import React from 'react';

import { ProductImageProps } from './ProductImage.types';

import { SaleBadge } from '@/components/Badges/SaleBadge';
import { LikeButton } from '@/components/LikeButton';
import { stopPropagation } from '@/utils';

import './ProductImage.less';

export const ProductImage = ({
    imgSrc,
    showLikeButton,
    isLiked,
    onLikeChange,
    discount,
    className,
}: ProductImageProps): React.ReactElement => {
    return (
        <div className={cn('product-image__container', className)}>
            {imgSrc && <img className="product-image__image" src={imgSrc} alt="" />}
            {showLikeButton && (
                <LikeButton
                    className="product-image__like-button"
                    checked={isLiked}
                    heartStyle
                    nonCircle
                    onChange={onLikeChange}
                    onLabelClick={stopPropagation}
                />
            )}
            {discount && <SaleBadge className="product-image__sale-badge" label={discount} />}
        </div>
    );
};

import { Profile } from './sessionReducer';

import {
    ACCOUNT_AGREE_TERMS_ERROR,
    ACCOUNT_AGREE_TERMS_LOADING,
    ACCOUNT_AGREE_TERMS_SUCCESS,
    ACCOUNT_AGREEMENTS_LOADING,
    ACCOUNT_AGREEMENTS_LOADING_ERROR,
    ACCOUNT_AGREEMENTS_LOADING_SUCCESS,
    ProfileActions,
} from '@/actions/profileActions';
import { RegionAgreement } from '@/generated/commonapi';

export interface IProfileState {
    profile: Profile;
    updating: boolean;
    error: unknown;
    agreements: RegionAgreement[];
    agreementsLoading: boolean;
    agreementsError: unknown;
}

const initialState: IProfileState = {
    profile: {},
    updating: false,
    error: undefined,
    agreements: [],
    agreementsLoading: false,
    agreementsError: undefined,
};

const profileReducer = (state = initialState, action: ProfileActions): IProfileState => {
    switch (action.type) {
        case ACCOUNT_AGREE_TERMS_LOADING:
            return {
                ...state,
                updating: true,
                error: undefined,
            };
        case ACCOUNT_AGREE_TERMS_SUCCESS:
            return {
                ...state,
                updating: false,
                profile: action.profile,
            };
        case ACCOUNT_AGREE_TERMS_ERROR:
            return {
                ...state,
                updating: false,
                error: action.error,
            };
        case ACCOUNT_AGREEMENTS_LOADING:
            return {
                ...state,
                agreementsLoading: true,
                agreementsError: undefined,
            };
        case ACCOUNT_AGREEMENTS_LOADING_SUCCESS:
            return {
                ...state,
                agreementsLoading: false,
                agreementsError: undefined,
                agreements: action.agreements,
            };
        case ACCOUNT_AGREEMENTS_LOADING_ERROR:
            return {
                ...state,
                agreementsLoading: false,
                agreementsError: action.error,
            };
        default:
            return state;
    }
};

export default profileReducer;

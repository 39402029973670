import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { PlatformDeliveryControllerApi } from '@/generated/marketplaceapi';
import { OrderWsControllerApi, PaymentApiSimpleOrder, ProductOrder } from '@/generated/paymentapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const SELLERS_ORDERS_LOADING = 'SELLERS_ORDERS_LOADING' as const;
export const SELLERS_ORDERS_LOADING_SUCCESS = 'SELLERS_ORDERS_LOADING_SUCCESS' as const;
export const SELLERS_ORDERS_LOADING_ERROR = 'SELLERS_ORDERS_LOADING_ERROR' as const;

export const SELLERS_ORDERS_ORDER_DETAILS_LOADING = 'SELLERS_ORDERS_ORDER_DETAILS_LOADING' as const;
export const SELLERS_ORDERS_ORDER_DETAILS_SUCCESS = 'SELLERS_ORDERS_ORDER_DETAILS_SUCCESS' as const;
export const SELLERS_ORDERS_ORDER_DETAILS_ERROR = 'SELLERS_ORDERS_ORDER_DETAILS_ERROR' as const;

export const SET_ORDER_STATUS_LOADING = 'SET_ORDER_STATUS_LOADING' as const;
export const SET_ORDER_STATUS_SUCCESS = 'SET_ORDER_STATUS_SUCCESS' as const;
export const SET_ORDER_STATUS_ERROR = 'SET_ORDER_STATUS_ERROR' as const;

export const SELLERS_ORDERS_RESET_ERRORS = 'SELLERS_ORDERS_RESET_ERRORS' as const;

const setOrderStatusLoadingAction = () => ({
    type: SET_ORDER_STATUS_LOADING,
});

const setOrderStatusSuccessAction = (order: PaymentApiSimpleOrder) => ({
    type: SET_ORDER_STATUS_SUCCESS,
    order,
});

const setOrderStatusErrorAction = (error: unknown) => ({
    type: SET_ORDER_STATUS_ERROR,
    error,
});

const sellerOrdersLoadingAction = () => ({ type: SELLERS_ORDERS_LOADING });

const sellerOrdersLoadingSuccessAction = (orders: ProductOrder[]) => ({
    type: SELLERS_ORDERS_LOADING_SUCCESS,
    orders,
});

const sellerOrdersLoadingErrorAction = (error: unknown) => ({
    type: SELLERS_ORDERS_LOADING_ERROR,
    error,
});

const sellerOrdersOrderDetailsLoadingAction = () => ({
    type: SELLERS_ORDERS_ORDER_DETAILS_LOADING,
});

const sellerOrdersOrderDetailsSuccessAction = (order: ProductOrder) => ({
    type: SELLERS_ORDERS_ORDER_DETAILS_SUCCESS,
    order,
});

const sellerOrdersOrderDetailsErrorAction = (error: unknown) => ({
    type: SELLERS_ORDERS_ORDER_DETAILS_ERROR,
    error,
});

export const resetSellersOrdersErrors = () => ({
    type: SELLERS_ORDERS_RESET_ERRORS,
});

export type SellersOrdersActions = ReturnType<
    | typeof setOrderStatusLoadingAction
    | typeof setOrderStatusSuccessAction
    | typeof setOrderStatusErrorAction
    | typeof sellerOrdersLoadingAction
    | typeof sellerOrdersLoadingSuccessAction
    | typeof sellerOrdersLoadingErrorAction
    | typeof sellerOrdersOrderDetailsLoadingAction
    | typeof sellerOrdersOrderDetailsSuccessAction
    | typeof sellerOrdersOrderDetailsErrorAction
    | typeof resetSellersOrdersErrors
>;

export const loadOrders = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(sellerOrdersLoadingAction());
    try {
        const response = await new OrderWsControllerApi().getProductOrderListUsingGET(
            false,
            getLanguageCode(getState()),
            undefined,
            undefined,
            true,
        );
        handleResponseAndThrowAnErrorIfExists(response);
        const items = response.orderList || [];

        dispatch(sellerOrdersLoadingSuccessAction(items));
    } catch (error) {
        console.error(error, 'in loadOrders in sellersOrdersActions');
        dispatch(sellerOrdersLoadingErrorAction(error.message));
    }
};

export const loadOrderDetails =
    (orderNumber: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            dispatch(sellerOrdersOrderDetailsLoadingAction());
            const response = await new OrderWsControllerApi().getProductOrderListUsingGET(
                false,
                getLanguageCode(getState()),
                orderNumber,
                null,
                true,
            );

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(sellerOrdersOrderDetailsSuccessAction(response.orderList[0]));
            return getState();
        } catch (error) {
            console.error(error, 'in loadOrderDetails in sellersOrdersActions');
            dispatch(sellerOrdersOrderDetailsErrorAction(error.message));
            return getState();
        }
    };

export const setOrderStatus = (uid: string, status: string) => async (dispatch: AppDispatch) => {
    dispatch(setOrderStatusLoadingAction());

    try {
        const result = await new OrderWsControllerApi().setOrderStatusUsingPOST({
            uid,
            status,
        });
        handleResponseAndThrowAnErrorIfExists(result);

        dispatch(setOrderStatusSuccessAction(result.order));

        dispatch(loadOrders());
    } catch (error) {
        console.error(error, 'in setOrderStatus in sellersOrdersActions');
        dispatch(setOrderStatusErrorAction(error.message));
    }
};

export const setProductOrderStatus =
    (uid: string, status: string, statusExtended?: string, trackingCarrier?: string, trackingNumber?: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(setOrderStatusLoadingAction());

        try {
            const state = getState();
            const result = await new OrderWsControllerApi().setProductOrderStatusUsingPOST(
                {
                    uid,
                    status,
                    statusExtended,
                    trackingCarrier,
                    trackingNumber,
                },
                getLanguageCode(state),
            );

            handleResponseAndThrowAnErrorIfExists(result);

            dispatch(setOrderStatusSuccessAction(result.orderList[0]));
        } catch (error) {
            console.error(error, 'in setProductOrderStatus in sellersOrdersActions');
            dispatch(setOrderStatusErrorAction(error.message));
        }
    };

export const refundOrder = (uid: string, amount: number) => async (dispatch: AppDispatch) => {
    dispatch(setOrderStatusLoadingAction());

    try {
        const result = await new OrderWsControllerApi().refundOrderUsingPOST({
            uid,
            amount,
        });

        handleResponseAndThrowAnErrorIfExists(result);

        const order = (await dispatch(loadOrderDetails(result.order.orderNumber))).sellersOrdersReducer.orderDetails;

        dispatch(setOrderStatusSuccessAction(order));
    } catch (error) {
        console.error(error, 'in refundOrder in sellersOrdersActions');
        dispatch(setOrderStatusErrorAction(error.message));
    }
};

export enum DownloadedDocument {
    WAYBILL = 'waybill',
    BARCODE = 'barcode',
}

export const getSellerOrderDocument =
    (documentType: DownloadedDocument, productOrderUid: string) =>
    (_: AppDispatch, getState: () => IApplicationStore) =>
        new PlatformDeliveryControllerApi()
            .platformDeliveryProductOrderDocumentUsingGET(documentType, productOrderUid, getLanguageCode(getState()), {
                responseType: 'blob',
            })
            .catch((error) => {
                console.error(error, 'in getSellerOrderDocument in sellersOrdersActions');
            });

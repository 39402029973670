import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ShareMenuProps } from './ShareMenu.types';

import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { ShareButton } from '@/components/ShareButton';
import { ShareOptions } from '@/components/ShareOptions';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useNodeUid } from '@/hooks/useNodeUid';
import { useOuterClick } from '@/hooks/useOuterClick';
import { getIsMobile } from '@/selectors/getIsMobile';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';

import './ShareMenu.less';

export const ShareMenu = ({
    renderButton,
    large = false,
    text = '',
    shareUrl,
    shareTitle,
    backdrop,
    analyticsShareProduct,
    analyticsShareStore,
    className,
}: ShareMenuProps) => {
    const isMobile = useAppSelector(getIsMobile);

    const [opened, setOpened] = useState<boolean>(false);
    const [, handleClose, handleToggle] = useBooleanState(setOpened);

    const isNativeShareAvailable = useMemo(() => typeof navigator.share === 'function', []);

    const url = shareUrl ?? window.location.href;

    const id = useNodeUid();

    const title = `${process.env.APP_NAME}: ${
        shareTitle || analyticsShareProduct?.name || analyticsShareStore?.name || document.title
    }`;

    const handleButtonClick = useCallback(() => {
        if (isMobile && isNativeShareAvailable) {
            navigator.share({ title, url, text: title });
        } else {
            handleToggle();
        }
    }, [handleToggle, isMobile, isNativeShareAvailable, title, url]);
    const ref = useRef(null);

    useOuterClick(ref, handleClose);

    const renderShareButton = useMemo(() => {
        switch (isMobile) {
            case true:
                return renderButton ? (
                    renderButton(handleButtonClick)
                ) : (
                    <ShareButton onClick={handleButtonClick} large={large} text={text} />
                );

            default:
                return (
                    <CustomPopoverWrapper
                        closeByClickInside
                        className="share-popover"
                        popoverTriggerElement={(togglePopover) =>
                            renderButton ? (
                                renderButton(togglePopover)
                            ) : (
                                <ShareButton onClick={togglePopover} large={large} text={text} />
                            )
                        }
                    >
                        {() => (
                            <ShareOptions
                                url={url}
                                shareTitle={title}
                                analyticsShareProduct={analyticsShareProduct}
                                analyticsShareStore={analyticsShareStore}
                            />
                        )}
                    </CustomPopoverWrapper>
                );
        }
    }, [
        analyticsShareProduct,
        analyticsShareStore,
        handleButtonClick,
        isMobile,
        large,
        renderButton,
        title,
        text,
        url,
    ]);

    return (
        <div id={id} className={className} ref={ref}>
            {renderShareButton}
            <MSSheet
                className="share-sheet"
                backdrop={backdrop ?? true}
                opened={opened && isMobile && !isNativeShareAvailable}
                onBackdropClick={handleClose}
            >
                <ShareOptions
                    url={url}
                    shareTitle={title}
                    analyticsShareProduct={analyticsShareProduct}
                    analyticsShareStore={analyticsShareStore}
                />
            </MSSheet>
        </div>
    );
};

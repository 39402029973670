import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IApplicationStore } from '@/reducers/rootReducer';

export type AppDispatch = ThunkDispatch<IApplicationStore, any, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IApplicationStore> = useSelector;

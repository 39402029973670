import { SellerAreaMenuItemName, SellerAreaMenuSubitemName } from './SellerAreaMenu.types';

import {
    IcAlertCircle,
    IcCrown,
    IcDelivery,
    IcLocationStroke,
    IcMarketspace,
    IcOrders,
    IcPercent,
    IcSettings,
    IcStore,
    IcWallet,
} from '@/assets';
import { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import { PageSideMenuItem } from '@/components/PageSideMenu/PageSideMenu.types';
import { AccountType } from '@/types/account';
import { ProductType } from '@/types/productType';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

export const getMenuSettings = (
    profileType: string,
    profileStatus: string,
    isMobile: boolean,
    onbordingMessages: Partial<Record<OnbordingMessageType, boolean>>,
    activeProductType: ProductType,
): Partial<Record<SellerAreaMenuItemName, PageSideMenuItem<SellerAreaMenuSubitemName>>> => ({
    SellerArea_Store: !isMobile &&
        activeProductType !== ProductType.Advertisement &&
        (profileType !== AccountType.STANDARD || activeProductType === ProductType.Service) && {
            link: '/profile/seller-area/store/',
            title: activeProductType === ProductType.Product ? 'Store' : 'services.seller_area.menu.button.my_profile',
            icon: IcStore,
        },
    SellerArea_Products: {
        title:
            activeProductType === ProductType.Advertisement
                ? 'page.my_offers.title.my_offers'
                : activeProductType === ProductType.Service
                ? 'services.seller_area.menu.button.my_services'
                : 'My Products',
        icon: IcMarketspace,
        link: isMobile ? '/profile/seller-area/my-goods/' : undefined,
        items: isMobile
            ? undefined
            : [
                  {
                      link: '/profile/seller-area/my-goods/',
                      title:
                          activeProductType === ProductType.Advertisement
                              ? 'page.my_offers.menu.button.offers'
                              : activeProductType === ProductType.Service
                              ? 'services.seller_area.menu.button.my_services'
                              : 'Products',
                      name: 'SellerArea_Products_Active',
                  },
                  {
                      link: `/profile/seller-area/my-goods/archive/?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(
                          activeProductType,
                      )}`,
                      name: 'SellerArea_Products_Archive',
                      title:
                          activeProductType === ProductType.Service
                              ? 'services.seller_area.menu.button.archive'
                              : 'Archive',
                  },
              ],
        onbordingMessage: onbordingMessages.PRODUCTS && {
            type: OnbordingMessageType.PRODUCTS,
            link: '/profile/seller-area/my-goods/add/',
        },
    },
    SellerArea_Orders: activeProductType === ProductType.Product && {
        link: '/profile/seller-area/sellers-orders/',
        title: 'Orders',
        icon: IcOrders,
    },
    SellerArea_ProductPromotion: {
        link: '/profile/seller-area/product-promotion/',
        title: 'Product promotion',
        icon: IcCrown,
    },
    SellerArea_PayoutSettings: activeProductType === ProductType.Product && {
        link: '/profile/seller-area/payout-settings/',
        title: 'Payout Settings',
        icon: IcSettings,
    },
    Switch_To_Business: activeProductType === ProductType.Product &&
        profileType === AccountType.STANDARD && {
            link:
                profileStatus !== 'SF' && process.env.CONFIG === 'marketspace'
                    ? undefined
                    : '/profile/seller-area/add-business-account/',
            title: 'Switch to Business Account',
            icon: IcStore,
            onbordingMessage: onbordingMessages.STORE &&
                profileStatus === 'SF' && {
                    type: OnbordingMessageType.STORE,
                    link: '/profile/seller-area/add-business-account/',
                },
            name: 'Switch_To_Business',
        },
    SellerArea_CompanyInformation: activeProductType !== ProductType.Service &&
        profileType !== AccountType.STANDARD &&
        !isMobile && {
            link: '/profile/seller-area/company-information',
            title: 'Company Information',
            icon: IcAlertCircle,
        },
    SellerArea_Sale: (profileType !== AccountType.STANDARD || activeProductType === ProductType.Service) && {
        link: '/profile/seller-area/sale/',
        title: 'Sale',
        icon: IcPercent,
        onbordingMessage: onbordingMessages.SALE && {
            type: OnbordingMessageType.SALE,
            link: '/profile/seller-area/sale/add/',
        },
    },
    SellerArea_MyDeliveryMethods: activeProductType === ProductType.Product && {
        icon: IcDelivery,
        link: '/profile/seller-area/delivery-methods/',
        title: 'My Delivery Options',
    },
    SellerArea_MyWallet: {
        icon: IcWallet,
        link: '/profile/seller-area/wallet/',
        title: 'Wallet',
    },
    SellerArea_Region: activeProductType !== ProductType.Service && {
        icon: IcLocationStroke,
        link: isMobile ? undefined : '/profile/seller-area/edit-region',
        title: 'Region',
        name: 'SellerArea_Region',
    },
});

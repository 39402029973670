import { Framework7Params } from 'framework7/components/app/app-class';

import routes from './routes';

const f7params: Framework7Params = {
    // Framework7 Parameters
    id: 'com.marketspace.app', // App bundle ID
    name: process.env.APP_NAME, // App name
    theme: 'auto', // Automatic theme detection

    // App routes
    routes: routes,

    // Input settings
    input: {
        scrollIntoViewOnFocus: false,
        scrollIntoViewCentered: false,
    },
    // Cordova Statusbar settings
    statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
    },
    view: {
        pushState: true,
        stackPages: false,
        pushStateSeparator: '#',
        preloadPreviousPage: false,
        reloadDetail: true,
    },
    swipeout: {
        removeElements: false,
    },
    touch: {
        tapHold: true, //enable tap hold events
    },
};

export default f7params;

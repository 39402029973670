import {
    CLASSIFICATOR_LIST_LOADING,
    CLASSIFICATOR_LIST_LOADING_ERROR,
    CLASSIFICATOR_LIST_LOADING_SUCCESS,
    ClassificatorActions,
    EntityClassificatorType,
} from '@/actions/classificatorActions';
import { Country, Currency } from '@/generated/commonapi';
import { Classificator } from '@/generated/marketplaceapi';

export type IClassificator = Classificator;

export type ColorsClassificator = Pick<Classificator, 'entity' | 'field' | 'code' | 'value'>;

export interface IClassificatorState {
    loading?: boolean;
    error?: unknown;
    currencyClassificator: Currency[];
    countryClassificator: Country[];
    countryClassificatorMappedByCountryCode: Record<string, Country>;
    colorsClassificator: ColorsClassificator[];
    entitiesClassificators: {
        [key in EntityClassificatorType]: IClassificator[];
    };
    claimSubjectsClassificators: {
        Application: IClassificator[];
        MyProduct: IClassificator[];
    };
}

const initialState: IClassificatorState = {
    currencyClassificator: [],
    countryClassificator: [],
    countryClassificatorMappedByCountryCode: {},
    colorsClassificator: [],
    entitiesClassificators: {
        Product_Status: [],
        Account_Status: [],
        Account_Type: [],
        Order_State: [],
        Url_app: [],
        Company_BusinessType: [],
        Product_Color: [],
        Discount_RoundType: [],
        Discount_Type: [],
        Sale_Error: [],
        Sale_Status: [],
        Tax_System: [],
    },
    claimSubjectsClassificators: {
        Application: [],
        Order: [],
        Product: [],
        MyProduct: [],
    },
};

const mapCountryClassificator = (countries: Country[]) =>
    countries.reduce<IClassificatorState['countryClassificatorMappedByCountryCode']>(
        (accum, country) => Object.assign(accum, { [country.code]: country }),
        {},
    );

const classificatorReducer = (state = initialState, action: ClassificatorActions): IClassificatorState => {
    switch (action.type) {
        case CLASSIFICATOR_LIST_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CLASSIFICATOR_LIST_LOADING_SUCCESS: {
            const entitiesClassificators = { ...state.entitiesClassificators };
            if (action['classificatorType'] && action['items']) {
                entitiesClassificators[action['classificatorType']] = action['items'];
            }

            const claimSubjectsClassificators = {
                ...state.claimSubjectsClassificators,
            };
            if (action['subjectType'] && action['items']) {
                const subjects = action['items'].reduce((accum, item) => {
                    const subject = accum[item.field];
                    if (subject) {
                        subject.push(item);
                    } else {
                        accum[item.field] = [item];
                    }
                    return accum;
                }, {});

                Object.keys(subjects).forEach((key) => {
                    claimSubjectsClassificators[key] = subjects[key];
                });
            }
            return {
                ...state,
                loading: false,
                currencyClassificator: action['currencyClassificator'] || state.currencyClassificator,
                countryClassificator: action['countryClassificator'] || state.countryClassificator,
                countryClassificatorMappedByCountryCode: mapCountryClassificator(
                    action['countryClassificator'] || state.countryClassificator,
                ),
                entitiesClassificators: { ...entitiesClassificators },
                claimSubjectsClassificators: { ...claimSubjectsClassificators },
            };
        }
        case CLASSIFICATOR_LIST_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
    }
    return state;
};

export default classificatorReducer;

import cn from 'classnames';
import { Block, Button, Col, List, ListItem, PageContent, Row } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPopoverProps } from './FilterPopover.types';

import { messengerService } from '@/api/messengerService';
import { PopoverPositions } from '@/components/CustomPopover/CustomPopover.types';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { FilterButton } from '@/components/FilterButton';

import './FilterPopover.less';

export const FilterPopover = ({
    options = [],
    selected,
    isMobile,
    title = 'Status',
    applyText,
    className,
    buttonProps = {},
    popoverTriggerElement,
    refreshSelectedData,
    onSelect,
    onClear,
    onApply,
    ...props
}: FilterPopoverProps) => {
    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = useState<string[]>(selected ?? []);

    const [firstPartOptions, secondPartOptions] = useMemo(
        () => [options.slice(0, Math.ceil(options.length / 2)), options.slice(Math.ceil(options.length / 2))],
        [options],
    );

    const makeHandleOptionClick = useCallback(
        (option: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedOptions((prev) =>
                e?.target?.checked ? [...prev, option] : prev.filter((item) => item !== option),
            );
            refreshSelectedData?.();
            onSelect?.(option);
        },
        [onSelect, refreshSelectedData],
    );

    const handlePopoverClose = useCallback(() => {
        setSelectedOptions(selected);
    }, [selected]);

    const handleApply = useCallback(() => {
        onApply(selectedOptions);

        if (isMobile) handlePopoverClose();
    }, [handlePopoverClose, isMobile, onApply, selectedOptions]);

    const handleClearAll = useCallback(() => {
        onClear?.();
        setSelectedOptions([]);
    }, [onClear]);

    useEffect(() => {
        if (selected) {
            setSelectedOptions(selected);
        }
    }, [selected]);

    return (
        <CustomPopoverWrapper
            className={cn('filter-popover', { mobile: isMobile }, className)}
            wrapperClassname="filter-popover__wrapper"
            popoverTriggerElement={(triggerPopover, popoverOpened) =>
                popoverTriggerElement?.(triggerPopover, popoverOpened) ?? (
                    <FilterButton {...buttonProps} onClick={triggerPopover} />
                )
            }
            availablePositions={[
                PopoverPositions.BOTTOM_CENTER,
                PopoverPositions.BOTTOM_LEFT_CORNER,
                PopoverPositions.BOTTOM_RIGHT_CORNER,
                PopoverPositions.LEFT_CENTER,
            ]}
            {...props}
        >
            {({ closePopover }) =>
                !isMobile ? (
                    <PageContent>
                        <Block className="title">{t(title)}</Block>
                        <Block className="content">
                            <Row>
                                <Col>
                                    <List noHairlines noHairlinesBetween>
                                        {firstPartOptions.map((option) => (
                                            <ListItem
                                                key={option.value}
                                                checkbox
                                                title={t(option.label)}
                                                name="status"
                                                value={option.label}
                                                checked={selectedOptions.includes(option.value)}
                                                onChange={makeHandleOptionClick(option.value)}
                                            />
                                        ))}
                                    </List>
                                </Col>
                                {secondPartOptions.length && (
                                    <Col>
                                        <List noHairlines noHairlinesBetween>
                                            {secondPartOptions.map((option) => (
                                                <ListItem
                                                    key={option.value}
                                                    checkbox
                                                    title={t(option.label)}
                                                    name="status"
                                                    value={option.label}
                                                    checked={selectedOptions.includes(option.value)}
                                                    onChange={makeHandleOptionClick(option.value)}
                                                />
                                            ))}
                                        </List>
                                    </Col>
                                )}
                            </Row>
                        </Block>
                        <Block className="filter-popover-actions">
                            <Button
                                disabled={!selectedOptions?.length}
                                round
                                iconMaterial="clear"
                                text={t('Clear all')}
                                onClick={() => {
                                    handleClearAll();
                                    closePopover();
                                }}
                            />
                            <Button
                                round
                                fill
                                disabled={
                                    selectedOptions.length === selected.length &&
                                    selectedOptions.every((option) => selected?.includes(option))
                                }
                                text={t('Apply')}
                                onClick={() => {
                                    handleApply();
                                    closePopover();
                                }}
                            />
                        </Block>
                    </PageContent>
                ) : (
                    <PageContent style={{ height: messengerService.isMobileApp ? '100%' : window.innerHeight }}>
                        <Block className="title">{t(title)}</Block>
                        <Block className="content">
                            <Row>
                                <Col>
                                    <List noHairlines noHairlinesBetween>
                                        {options.map((option) => (
                                            <ListItem
                                                key={option.value}
                                                checkbox
                                                title={t(option.label)}
                                                name="status"
                                                value={option.label}
                                                checked={selectedOptions.includes(option.value)}
                                                onChange={makeHandleOptionClick(option.value)}
                                                className={cn({
                                                    checked: selectedOptions.includes(option.value),
                                                })}
                                            />
                                        ))}
                                    </List>
                                </Col>
                            </Row>
                        </Block>
                        <Button
                            className="filter-popover__apply-mobile"
                            round
                            fill
                            text={t(applyText)}
                            onClick={() => {
                                handleApply();
                                closePopover();
                            }}
                        />
                    </PageContent>
                )
            }
        </CustomPopoverWrapper>
    );
};

import { Button, Navbar, NavLeft, NavTitle } from 'framework7-react';
import React, { useState } from 'react';

import { pdfFileInnerText } from './DebugPage.constants';

import { messengerService } from '@/api/messengerService';
import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';

import './DebugPage.less';

const DebugPageInner = () => {
    const [shareText, setShareText] = useState('Share text');
    const [shareUrl, setShareUrl] = useState('https://google.com');

    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('Text message');

    const [openUrl, setOpenUrl] = useState('');

    const shareHandler = () => {
        console.warn('shareHandler', { shareText, shareUrl });
        if (!shareText) {
            alert('Enter share text');
            return;
        }
        if (!shareUrl) {
            alert('Enter share url');
            return;
        }
        messengerService.shareLink(shareText, shareUrl);
    };

    const sendMessageHandler = () => {
        console.warn('sendMessageHandler', { phone, message });
        if (!phone) {
            alert('Enter phone');
            return;
        }
        if (!message) {
            alert('Enter message');
            return;
        }
        messengerService.sendMessage(phone, message);
    };

    const openUrlHandler = () => {
        console.warn('openUrlHandler', { openUrl });
        if (!openUrl) {
            alert('Enter url');
            return;
        }
        messengerService.openExternalLink(openUrl);
    };

    const downloadPDFFile = () => {
        const file = new Blob([pdfFileInnerText], { type: 'application/pdf' });
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', URL.createObjectURL(file));
        linkElement.target = '_blank';
        linkElement.download = 'testFile';
        linkElement.click();
    };

    return (
        <CustomPage>
            <Navbar>
                <NavLeft>
                    <AdaptiveBackLink href="/" />
                </NavLeft>
                <NavTitle>Debug page</NavTitle>
            </Navbar>
            <div className="debug-page__content">
                <input
                    value={shareText}
                    placeholder={'Enter share text'}
                    onChange={({ target: { value } }) => setShareText(value)}
                />
                <input
                    value={shareUrl}
                    placeholder={'Enter share url'}
                    onChange={({ target: { value } }) => setShareUrl(value)}
                />
                <Button fill round onClick={shareHandler}>
                    Share
                </Button>
                <input
                    value={phone}
                    placeholder={'Enter phone number'}
                    onChange={({ target: { value } }) => setPhone(value)}
                />
                <input
                    value={message}
                    placeholder={'Enter message'}
                    onChange={({ target: { value } }) => setMessage(value)}
                />
                <Button fill round onClick={sendMessageHandler}>
                    Open Chat
                </Button>
                <input
                    value={openUrl}
                    placeholder={'Enter url'}
                    onChange={({ target: { value } }) => setOpenUrl(value)}
                />
                <Button fill round onClick={openUrlHandler}>
                    Open Url
                </Button>
                <Button fill round onClick={messengerService.reload}>
                    Reload
                </Button>
                <Button fill round onClick={downloadPDFFile}>
                    Download PDF document
                </Button>
            </div>
        </CustomPage>
    );
};

export const DebugPage = ConnectPageWrapper(DebugPageInner);

import { f7, f7ready } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppNavBarProps } from './AppNavBar.types';

import { MainNavbar } from './MainNavbar';

import CreateServiceProviderPopup from '../CreateServiceProviderPopup/CreateServiceProviderPopup';
import RegistrationBusinessPopup from '../RegistrationBusinessPopup/RegistrationBusinessPopup';
import ServiceProviderCreatedPopup from '../ServiceProviderCreatedPopup/ServiceProviderCreatedPopup';
import StoreCreatedPopup from '../StoreCreatedPopup/StoreCreatedPopup';
import SwitchAccountToBusinessPopup from '../SwitchAccountToBusinessPopup/SwitchAccountToBusinessPopup';

import { productPresearchClearAction } from '@/actions/productActions';
import { verifyEmailCode } from '@/actions/sessionActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { AboutPopup } from '@/components/AboutPopup';
import { AgreementPopup } from '@/components/AgreementPopup';
import { CategoriesMenuDesktop } from '@/components/CategoriesMenuDesktop';
import { ContactSupportPopup } from '@/components/ContactSupportPopup';
import { ForgotPasswordPopUp } from '@/components/ForgotPasswordPopUp';
import { InviteFriendsPopup } from '@/components/InviteFriendsPopup';
import { LoginPopup } from '@/components/LoginPopup';
import { RegisterPopup } from '@/components/RegisterPopup';
import { VerifyAccountPopup } from '@/components/VerifyAccountPopup';
import { VerifyEmailPopup } from '@/components/VerifyEmailPopup';
import { LoadCart } from '@/hoc/LoadCart';
import { LoadWishList } from '@/hoc/LoadWishList';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useBoolState } from '@/hooks/useBoolState';
import { useF7routerBeforeItsReady } from '@/hooks/useF7routerBeforeItsReady';
import { useLoginScenary } from '@/hooks/useLoginScenary';
import { usePopupOpenedOnLoad } from '@/hooks/usePopupOpenedOnLoad';
import {
    closeCreateServiceProviderPopup,
    openBusinessRegistrationPopup,
    openCreateServiceProviderPopup,
    openSwitchToBusinessRegistrationPopup,
} from '@/reducers/registrationReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';
import { AccountType } from '@/types/account';
import { Popups } from '@/types/popups';
import { ProductType } from '@/types/productType';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { noop } from '@/utils';
import { isInsideGem } from '@/utils/isInsideGem';
import { keepCurrentProductType } from '@/utils/keepCurrentProductType';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';
import { updateQueryParameterFromURL } from '@/utils/url/updateQueryParameterFromURL';

export const AppNavBar = ({
    f7router,
    slot = 'fixed',
    navbar,
    loginInitial,
    registerInitial,
    verifyEmailInitial,
    setSearchBarEnable = noop,
    doAfterLogin,
    doAfterRegister,
    className,
}: AppNavBarProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
        isBusinessRegistrationPopupOpen,
        isSwitchToBusinessRegistrationPopupOpen,
        isLoginPopupOpen,
        isCreateServiceProviderPopupOpen,
    } = useAppSelector((state) => state.registrationReducer);
    const { activeProductType } = useAppSelector((state) => state.rootReducer);

    const { isShowStoreCreatedPopup } = useAppSelector((state) => state.storeReducer);

    const [loginPopupOpened, setLoginPopupOpened] = useState(false);
    const [registerPopupOpened, setRegisterPopupOpened] = useState(false);
    const [verifyEmailPopupOpened, setVerifyEmailPopupOpened] = useState(false);

    const [emailVerified, setEmailVerified] = useState(false);
    const [categoriesMenuOpened, setCategoriesMenuOpened] = useState(false);
    const [aboutPopupOpened, setAboutPopupOpened] = useState(false);
    const [forgotPasswordPopupOpened, setForgotPasswordPopupOpened] = useState(false);
    const [verifyAccountPopupOpened, setVerifyAccountPopupOpened] = useState(false);
    const [serviceProviderCreatedPopupOpened, setServiceProviderCreatedPopupOpened] = useState(false);
    const [contactSupportPopupOpened, setContactSupportPopupOpened] = useState(false);
    const [agreementPopupOpened, setAgreementPopupOpened] = useState(false);
    const [isInvitePopupOpened, openInvitePopup, closeInvitePopup] = useBoolState(false);
    const closeCategories = useBooleanState(setCategoriesMenuOpened)[1];

    usePopupOpenedOnLoad(loginInitial || isLoginPopupOpen, setLoginPopupOpened);
    usePopupOpenedOnLoad(verifyEmailInitial, setVerifyEmailPopupOpened);
    usePopupOpenedOnLoad(registerInitial, setRegisterPopupOpened);
    usePopupOpenedOnLoad(registerInitial, setRegisterPopupOpened);

    const loggedIn = useAppSelector(isLoggedIn);
    const profile = useAppSelector(getProfile);

    const entryDirect = useAppSelector(
        (state) => state.rootReducer.entryPageName === 'product-details' && isInsideGem(),
    );

    const searchbarClearHandle = useCallback(() => dispatch(productPresearchClearAction()), [dispatch]);

    const onClickLogoLink = useCallback(async () => {
        f7router.navigate('/');
    }, [f7router]);

    const isMobile = useAppSelector(getIsMobile);

    const toggleCategoriesMenu = useCallback(() => setCategoriesMenuOpened((prev) => !prev), []);

    const openSearchbar = useCallback(() => setSearchBarEnable(true), [setSearchBarEnable]);
    const closeSearchbar = useCallback(() => setSearchBarEnable(false), [setSearchBarEnable]);

    const backLink = !entryDirect && isMobile;

    const openAboutPopup = useCallback(() => setAboutPopupOpened(true), []);
    const openVerifyAccountPopup = useCallback(() => setVerifyAccountPopupOpened(true), []);
    const closeVerifyAccountPopup = useCallback(() => setVerifyAccountPopupOpened(false), []);

    const {
        onForgotPassword,
        openRegisterPopup,
        closeRegisterPopup,
        closeForgotPasswordPopUp,
        openLoginPopup,
        closeLoginPopup,
    } = useLoginScenary(setLoginPopupOpened, setForgotPasswordPopupOpened, setRegisterPopupOpened);

    const openContactSupportPopup = useCallback(() => setContactSupportPopupOpened(true), []);
    const closeContactSupportPopup = useCallback(() => setContactSupportPopupOpened(false), []);

    const closeAboutPopup = useCallback(() => setAboutPopupOpened(false), []);

    const closeAgreementPopup = useCallback(() => setAgreementPopupOpened(false), []);

    const changeProductTypeHandle = useCallback((productType: ProductType) => {
        f7.views.main.router.navigate(
            updateQueryParameterFromURL('/', PRODUCT_TYPE_QUERY_NAME, getQueryByProductType(productType)),
            { reloadCurrent: true },
        );
    }, []);

    const handleStartSellingClick = useCallback(() => {
        if (loggedIn) {
            if (profile?.type === AccountType.BUSINESS) {
                f7.views.main.router.navigate('/profile/seller-area/store/');
            } else {
                dispatch(openSwitchToBusinessRegistrationPopup());
            }
        } else {
            dispatch(openBusinessRegistrationPopup());
        }
    }, [dispatch, loggedIn, profile.type]);

    const startProvidingServicesHandle = useCallback(() => {
        if (loggedIn) {
            dispatch(openCreateServiceProviderPopup());
        } else {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        }
    }, [dispatch, loggedIn]);

    const openAddOfferHandle = useCallback(() => {
        if (loggedIn) {
            f7.views.main.router.navigate('/profile/seller-area/my-goods/add/');
        } else {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        }
    }, [loggedIn]);

    const closeEmailVerifyHandle = useCallback(() => {
        setVerifyEmailPopupOpened(false);
    }, [setVerifyEmailPopupOpened]);

    const openLoginEmailVerifyHandle = useCallback(() => {
        setVerifyEmailPopupOpened(false);
        openLoginPopup();
    }, [openLoginPopup, setVerifyEmailPopupOpened]);

    const closeCreateServiceProviderHandle = useCallback(() => {
        dispatch(closeCreateServiceProviderPopup());
    }, [dispatch]);

    const createServiceProviderHandle = useCallback(() => {
        closeCreateServiceProviderHandle();
        setServiceProviderCreatedPopupOpened(true);
    }, [closeCreateServiceProviderHandle]);

    const closeServiceProviderCreatedHandle = useCallback(() => {
        setServiceProviderCreatedPopupOpened(false);
    }, []);

    const openSellerAreaHandle = useCallback(() => {
        closeServiceProviderCreatedHandle();

        f7.views.main.router.navigate(isMobile ? '/profile/seller-area/' : '/profile/seller-area/store/');
    }, [closeServiceProviderCreatedHandle, isMobile]);

    const query = useF7routerBeforeItsReady(
        (router) => router.currentRoute?.query,
        (query) => Boolean(query),
    );

    useEffect(() => {
        f7ready(() => {
            if (query?.confirmCode) {
                dispatch(verifyEmailCode(query?.confirmCode)).then(
                    ({ sessionReducer: { emailVerificationError, emailVerificationSuccess } }) => {
                        if (emailVerificationSuccess) {
                            setEmailVerified(true);
                            firebaseAnalyticsInstance.confirmEmailSuccess?.();

                            f7.toast.show({
                                text: t('Your email has been verified'),
                                closeTimeout: 4000,
                            });

                            f7router.navigate(keepCurrentProductType('/'));
                        } else if (emailVerificationError) {
                            setVerifyEmailPopupOpened(true);
                        }
                    },
                );
            }
        });
    }, [dispatch, setVerifyEmailPopupOpened, closeRegisterPopup, query?.confirmCode, t, f7router]);

    useEffect(() => {
        f7ready(() => {
            if (query?.contactSupport) {
                openContactSupportPopup();
            }
        });
    }, [openContactSupportPopup, query?.contactSupport]);

    return (
        <React.Suspense fallback={null}>
            <MainNavbar
                openAboutPopup={openAboutPopup}
                openVerifyAccountPopup={openVerifyAccountPopup}
                openInvitePopup={openInvitePopup}
                showCartLink={activeProductType === ProductType.Product}
                showWishButton
                showSearchbar={!isMobile}
                showProfileLink
                openAddOffer={openAddOfferHandle}
                startProvidingServices={startProvidingServicesHandle}
                onContactSupportClick={openContactSupportPopup}
                onClickLogoLink={onClickLogoLink}
                onClickOpenCategoriesMenu={toggleCategoriesMenu}
                categoriesMenuOpened={categoriesMenuOpened}
                onSearchbarEnable={openSearchbar}
                onSearchbarDisable={closeSearchbar}
                onSearchClickClear={searchbarClearHandle}
                backLink={backLink}
                onLoginClick={openLoginPopup}
                onChangeProductType={changeProductTypeHandle}
                onStartSellingClick={handleStartSellingClick}
                emailVerifySuccess={emailVerified}
                slot={slot}
                className={className}
                sliding={false}
                {...navbar}
            />

            <div slot="fixed">
                <CategoriesMenuDesktop
                    className="pure-hidden-xs"
                    opened={categoriesMenuOpened}
                    handleClose={closeCategories}
                />
            </div>

            <LoadCart />
            <LoadWishList />

            <VerifyEmailPopup
                opened={verifyEmailPopupOpened}
                onPopupClosed={closeEmailVerifyHandle}
                onLoginClick={openLoginEmailVerifyHandle}
            />

            <LoginPopup
                opened={loginPopupOpened}
                onPopupOpen={openLoginPopup}
                onPopupClose={closeLoginPopup}
                onRegister={openRegisterPopup}
                onForgotPassword={onForgotPassword}
                doAfterLogin={doAfterLogin}
            />

            <RegisterPopup
                opened={registerPopupOpened}
                onPopupOpen={openRegisterPopup}
                onPopupClose={closeRegisterPopup}
                onLogIn={openLoginPopup}
                doAfterRegister={doAfterRegister}
            />

            <VerifyAccountPopup opened={verifyAccountPopupOpened} onPopupClosed={closeVerifyAccountPopup} />

            <RegistrationBusinessPopup opened={isBusinessRegistrationPopupOpen} openLogin={openLoginPopup} />

            <SwitchAccountToBusinessPopup opened={isSwitchToBusinessRegistrationPopupOpen} />

            <StoreCreatedPopup opened={isShowStoreCreatedPopup} />

            <AboutPopup
                backdrop
                opened={aboutPopupOpened}
                onPopupOpened={openAboutPopup}
                onPopupClosed={closeAboutPopup}
                onContactSupportClick={openContactSupportPopup}
            />

            <ContactSupportPopup
                category="Application"
                opened={contactSupportPopupOpened}
                closePopup={closeContactSupportPopup}
            />

            <AgreementPopup opened={agreementPopupOpened} onPopupClosed={closeAgreementPopup} />

            <InviteFriendsPopup opened={isInvitePopupOpened} onPopupClose={closeInvitePopup} />

            <ForgotPasswordPopUp opened={forgotPasswordPopupOpened} onPopupClosed={closeForgotPasswordPopUp} />

            <CreateServiceProviderPopup
                opened={isCreateServiceProviderPopupOpen}
                onCreate={createServiceProviderHandle}
                onClose={closeCreateServiceProviderHandle}
            />

            <ServiceProviderCreatedPopup
                opened={serviceProviderCreatedPopupOpened}
                openSellerArea={openSellerAreaHandle}
                onClose={closeServiceProviderCreatedHandle}
            />
        </React.Suspense>
    );
};

import cn from 'classnames';
import { f7, Link } from 'framework7-react';
import React, { useMemo } from 'react';

import { MyWishButtonProps } from './MyWishButton.types';

import { IcWishNotFilled } from '@/assets';
import { Badge } from '@/components/Badge';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

import './MyWishButton.less';

export const MyWishButton = ({ count, className, ...props }: MyWishButtonProps) => {
    const activeProductType = useAppSelector(getActiveProductType);

    const shownCount = useMemo(() => (count < 100 ? `${count}` : `99+`), [count]);

    const logged = useAppSelector(isLoggedIn);

    const handleClick = () => {
        if (logged) {
            f7.views.main.router.navigate(
                `/wish-list?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(activeProductType)}`,
            );
        } else {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        }
    };

    return (
        <Link {...props} iconOnly className={cn('my-wish-button', className)} onClick={handleClick}>
            <IcWishNotFilled />
            {!!count && <Badge className="my-wish-button-count">{shownCount}</Badge>}
        </Link>
    );
};

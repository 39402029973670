import { f7, Link } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';

import { AdaptiveBackLinkProps } from './AdaptiveBackLink.types';

import { IcBack } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

export const AdaptiveBackLink = React.memo<AdaptiveBackLinkProps>(
    ({ children, onClick, mobileLink, desktopLink, defaultBackLink = '/', ...props }) => {
        const isMobile = useAppSelector(getIsMobile);
        const [back, setBack] = useState(props.back);
        const [href, setHref] = useState(props.href);

        useEffect(() => {
            setBack(props.back);
        }, [props.back]);

        useEffect(() => {
            if (
                back &&
                (f7.view.main.router.previousRoute?.path === defaultBackLink ||
                    !f7.view.main.router.previousRoute?.path)
            ) {
                setHref(defaultBackLink);
                setBack(false);
            }
        }, [back, defaultBackLink]);

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLAnchorElement>) => {
                onClick?.(e);

                if ((isMobile && mobileLink) || (!isMobile && desktopLink))
                    f7.view.main.router.navigate(isMobile ? mobileLink : desktopLink, props);
            },
            [desktopLink, isMobile, mobileLink, onClick, props],
        );

        return (
            <Link {...props} href={href} back={back} onClick={handleClick}>
                <IcBack />
            </Link>
        );
    },
);
AdaptiveBackLink.displayName = 'AdaptiveBackLink';

import { HistogramColor, HistogramStat } from '@/components/Histogram/Histogram.types';
import { HistogramToolbarOptions } from '@/components/Histogram/HistogramToolbar';
import { StatisticsEntry } from '@/generated/marketplaceapi';

export const statisticsToHistogramStats = (
    statisticsEntry: StatisticsEntry[],
    type: HistogramToolbarOptions,
): HistogramStat[] =>
    statisticsEntry?.map(({ startDate, endDate, views, orders, messages, wishes }) => {
        const data =
            type === HistogramToolbarOptions.ORDERS
                ? orders
                : type === HistogramToolbarOptions.WISHES
                ? wishes
                : messages;

        if (!startDate || !endDate) {
            return {
                values: [
                    {
                        color: HistogramColor.PRIMARY,
                        value: data ?? 0,
                    },
                    {
                        color: HistogramColor.BASE,
                        value: views ?? 0,
                    },
                ],
                startDate: new Date(),
                endDate: new Date(),
            };
        }

        return {
            values: [
                {
                    color: HistogramColor.PRIMARY,
                    value: data ?? 0,
                },
                {
                    color: HistogramColor.BASE,
                    value: views ?? 0,
                },
            ],
            startDate: new Date(startDate),
            endDate: new Date(endDate),
        };
    }) || [];

import { t } from 'i18next';

import { Country } from '@/generated/commonapi';
import { Address } from '@/generated/marketplaceapi';

export const formatAddress = (address: Address | undefined, countryClassificator: Country[]) =>
    [
        countryClassificator.find(({ code }) => code === address?.countryCode)?.name,
        address?.city,
        address?.firstAddressLine,
        address?.secondAddressLine,
        address?.floorNo ? `${t('Floor').toLowerCase()} ${address?.floorNo}` : undefined,
        address?.apartmentNo ? `${t('Apartment').toLowerCase()} ${address?.apartmentNo}` : undefined,
        address?.postalCode,
    ]
        .filter(Boolean)
        .join(', ');

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBooleanState } from './useBooleanState';

export const useIframePopup = () => {
    const { t } = useTranslation();
    const [iframeContentPopupOpened, setIframeContentPopupOpened] = useState(false);
    const [openIframePopup, closeIframePopup] = useBooleanState(setIframeContentPopupOpened);
    const [iframeContent, setIframeContent] = useState({ title: '', url: '' });

    const handlePopupClosed = useCallback(() => {
        closeIframePopup();
        setIframeContent({ title: undefined, url: undefined });
    }, [closeIframePopup]);

    return {
        title: iframeContent.title ? t(iframeContent?.title) : '',
        url: iframeContent.url,
        opened: iframeContentPopupOpened && !!iframeContent?.url,
        onPopupClosed: handlePopupClosed,
        openIframePopup,
        setIframeContent,
    };
};

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { IcStarFilled } from '@/assets';
import { noop } from '@/utils';

import type { StarRatingProps } from './StarRating.types';

import './StarRating.less';

export const StarRating: React.FC<StarRatingProps> = ({
    onChange = noop,
    className,
    readOnly = false,
    value = 0,
    number = 5,
    size,
    primary,
}) => {
    const name = useMemo(() => new Date().getTime().toString(), []);
    const integerValuePart = useMemo(() => Math.floor(value), [value]);
    const gradientOffset = useMemo(() => `${(value - integerValuePart) * 100}%`, [value, integerValuePart]);
    const starStyles = useMemo(
        () => ({
            fill: `url(#${name})`,
        }),
        [name],
    );

    const onClick = useCallback(() => onChange(0), [onChange]);

    const stars = useMemo(
        () =>
            Array.from({ length: number + 1 }, (_, index) => (
                <label
                    key={index}
                    className={cn('star-rating__container', {
                        'star-rating__container_checked': index === integerValuePart,
                    })}
                >
                    <input
                        type="radio"
                        name={name}
                        onChange={() => onChange(index)}
                        onClick={onClick}
                        checked={index === integerValuePart}
                        tabIndex={-1}
                    />
                    <IcStarFilled
                        preserveAspectRatio="xMinYMin"
                        viewBox="2 2 20 19"
                        style={readOnly && index === integerValuePart + 1 ? starStyles : null}
                    />
                </label>
            )),
        [onChange, onClick, integerValuePart, number, name, readOnly, starStyles],
    );

    const gradient = useMemo(
        () => (
            <svg xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id={name}>
                        <stop offset={gradientOffset} />
                        <stop offset={gradientOffset} />
                    </linearGradient>
                </defs>
            </svg>
        ),
        [name, gradientOffset],
    );

    return (
        <div
            className={cn(className, 'star-rating', {
                'star-rating_read-only': readOnly,
                'star-rating_small': size === 'small',
                'star-rating_large': size === 'large',
                'star-rating_primary': primary,
            })}
        >
            {stars}
            <div className="star-rating__gradient">{gradient}</div>
        </div>
    );
};

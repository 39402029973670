import { getCheapestDeliveryMethod } from '@/components/DeliveryOptionSelect/utils';
import { ChosenSdekDelivery } from '@/components/SdekMap/SdekMap.types';
import { CartItem, DeliveryMethodOption, PlatformDeliveryMethod, ProductRentOptions } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IProduct } from '@/reducers/productReducer';
import { createUUID, productToCartItem } from '@/utils';

export const RESERVE_LOAD = 'RESERVE_LOAD';
export const RESERVE_LOAD_SUCCESS = 'RESERVE_LOAD_SUCCESS';
export const RESERVE_LOAD_ERROR = 'RESERVE_LOAD_ERROR';
export const RESERVE_ADD = 'RESERVE_ADD';
export const RESERVE_REMOVE_ITEM = 'RESERVE_REMOVE_ITEM';
export const RESERVE_ADD_DELIVERY_METHOD = 'RESERVE_ADD_DELIVERY_METHOD';
export const RESERVE_ADD_DELIVERY_INFORMATION = 'RESERVE_ADD_DELIVERY_INFORMATION';
export const RESERVE_ADD_CARD = 'RESERVE_ADD_CARD';

const reserveLoad = () => ({ type: RESERVE_LOAD });

const reserveLoadSuccess = (item: CartItem, rentOptions?: ProductRentOptions) => ({
    type: RESERVE_LOAD_SUCCESS,
    payload: { item, rentOptions },
});

const reserveLoadError = (error: unknown) => ({
    type: RESERVE_LOAD_ERROR,
    payload: { error },
});

export const reserveRemove = () => ({
    type: RESERVE_REMOVE_ITEM,
});

export const reserveAddDeliveryMethod = (
    method: PlatformDeliveryMethod | DeliveryMethodOption,
    chosenSdekDelivery?: ChosenSdekDelivery,
) => ({
    type: RESERVE_ADD_DELIVERY_METHOD,
    payload: { method, chosenSdekDelivery },
});

export const reserveProduct = (item: IProduct) => (dispatch: AppDispatch) => {
    dispatch(reserveLoad());
    try {
        const newItem: CartItem = productToCartItem(item, createUUID(), 1);
        newItem.deliveryMethodOption = getCheapestDeliveryMethod(newItem);
        const rentOptions = item.rentOptions;
        dispatch(reserveLoadSuccess(newItem, rentOptions));
    } catch (err) {
        dispatch(reserveLoadError(err));
        console.error('at reserveActions in reserveProduct', err);
    }
};

import { productsToSliderItems } from './productsToSliderItems';

import { CarouselItem } from '@/components/Carousel';
import { IProduct } from '@/reducers/productReducer';

export const getSliderItemsForProductType = (
    productTypeGroups: {
        type: string;
        products: IProduct[];
    }[],
    type: string,
    sliderItemClickHandle: (item: CarouselItem) => void,
    productsWishList?: IProduct[],
): CarouselItem[] => {
    if (productTypeGroups) {
        const productsOfType = productTypeGroups.find((item) => item.type === type);

        if (productsOfType) {
            return productsOfType.products
                ? productsToSliderItems(productsOfType.products, sliderItemClickHandle, productsWishList)
                : [];
        }
    }

    return [];
};

import { PersonalAreaMenuItemName, PersonalAreaMenuSubitemName } from './PersonalAreaMenu.types';

import { IcAddressbook, IcOrders, IcPassport, IcReview, IcSettings, IcTransaction } from '@/assets';
import { PageSideMenuItem } from '@/components/PageSideMenu/PageSideMenu.types';
import { ProductType } from '@/types/productType';

export const getPersonalAreaMenuSettings = (
    productType: ProductType,
): Record<PersonalAreaMenuItemName, PageSideMenuItem<PersonalAreaMenuSubitemName> | undefined> => ({
    PersonalArea_ShippingAddresses: {
        link: '/profile/personal/shipping-addresses/',
        title: 'Shipping Address',
        icon: IcAddressbook,
    },
    PersonalArea_ProfileSettings: {
        link: '/profile/personal/settings/',
        icon: IcSettings,
        title: 'Profile Settings',
    },
    PersonalArea_Transactions:
        productType !== ProductType.Advertisement
            ? {
                  icon: IcTransaction,
                  link: '/profile/personal/transactions/',
                  title: 'Transactions',
              }
            : undefined,
    PersonalArea_MyReviews: {
        icon: IcReview,
        link: '/profile/personal/reviews',
        title: 'My Reviews',
    },
    // PersonalArea_Wallet: {
    //     link: isMobile ? '/profile/personal/wallet/' : undefined,
    //     title: 'Wallet',
    //     icon: IcWallet,
    //     items: isMobile
    //         ? undefined
    //         : [
    //               {
    //                   name: 'PersonalArea_Wallet_Currencies',
    //                   link: '/profile/personal/wallet/currencies/',
    //                   title: 'Currency',
    //               },
    //               {
    //                   name: 'PersonalArea_Wallet_Cards',
    //                   link: '/profile/personal/wallet/cards/',
    //                   title: 'My Сredit / Debit Cards',
    //               },
    //           ],
    // },
    PersonalArea_MyOrders:
        productType !== ProductType.Advertisement
            ? {
                  icon: IcOrders,
                  link: '/profile/personal/orders/',
                  title: 'My Orders',
              }
            : undefined,
    PersonalArea_Verify_Your_Account: {
        icon: IcPassport,
        name: 'PersonalArea_Verify_Your_Account',
        title: 'Passport',
    },
});

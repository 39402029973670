import dayjs from 'dayjs';
import i18n, { use } from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { URLParams } from './types/URLParams';
import { getQueryParameterFromURL } from './utils/url/getQueryParameterFromURL';

export const language = getQueryParameterFromURL(URLParams.LANGUAGE) || process.env.DEFAULT_LANGUAGE;

export const availableLanguages = process.env.AVAILABLE_LANGUAGES?.split(',') ?? ['en', 'ru', 'kk'];

use(HttpBackend)
    .use(initReactI18next)
    .init({
        lng: language,
        fallbackLng: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: process.env.SB !== 'true',
        },
        backend: {
            loadPath: process.env.HOST + '/marketplaceapi/public/locize?language={{lng}}',
        } as HttpBackendOptions,
    })
    .catch(console.error);

dayjs.locale(language);
i18n.on('languageChanged', (lng) => dayjs.locale(lng));

export default i18n;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadSaleInfo } from '@/actions/saleActions';
import { useAppSelector } from '@/hooks/store';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';

type LoadTranslatedSaleInfoProps = {
    saleUid?: string;
    disabled?: boolean;
};

export const LoadTranslatedSaleInfo = ({ saleUid, disabled }: LoadTranslatedSaleInfoProps) => {
    const dispatch = useDispatch();
    const language = useAppSelector(getLanguageCode);
    const isLogged = useAppSelector(isLoggedIn);
    useEffect(() => {
        if (saleUid && isLogged && !disabled) {
            dispatch(loadSaleInfo(saleUid));
        }
    }, [dispatch, isLogged, saleUid, language, disabled]);

    return null;
};

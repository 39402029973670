import classNames from 'classnames';
import { List, ListItem, Sheet } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddProductsSourcesSheetProps } from './AddProductsSourcesSheet.types';

import { IcCategory, IcMarketspace } from '@/assets';
import { Sheets } from '@/types/sheets';

import './AddProductsSourcesSheet.less';

export const AddProductsSourcesSheet = ({
    onAddCategoriesClick,
    onAddProductsClick,
    className,
    ...props
}: AddProductsSourcesSheetProps) => {
    const { t } = useTranslation();

    return (
        <Sheet
            {...props}
            backdrop
            swipeToClose={false}
            id={Sheets.ADD_PRODUCTS_SOURCES_SHEET}
            className={classNames(Sheets.ADD_PRODUCTS_SOURCES_SHEET, 'add-products-sources-sheet', className)}
        >
            <List noChevron noHairlines>
                <ListItem link sheetClose title={t('Add Categories of Products')} onClick={onAddCategoriesClick}>
                    <IcCategory slot="media" />
                </ListItem>
                <ListItem link sheetClose title={t('Add Specific Products')} onClick={onAddProductsClick}>
                    <IcMarketspace slot="media" />
                </ListItem>
            </List>
        </Sheet>
    );
};

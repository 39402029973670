import { useTranslation } from 'react-i18next';

import { RegionAgreement } from '@/generated/commonapi';

export const useAgreementTitle = (agreementType: RegionAgreement.TypeEnum) => {
    const { t } = useTranslation();

    switch (agreementType) {
        case RegionAgreement.TypeEnum.TS:
            return t('Rules for submission of works of art');
        case RegionAgreement.TypeEnum.TC:
            return t('TermsAndConditions');
        case RegionAgreement.TypeEnum.PP:
            return t('Privacy Policy');
        default:
            return '';
    }
};

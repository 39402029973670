import cn from 'classnames';
import {
    f7,
    Icon,
    Link,
    List,
    ListItem,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Searchbar,
    theme,
} from 'framework7-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CountrySelectPopupProps } from './CountrySelectPopup.types';

import { CustomCountryFlag } from '@/components/CustomCountryFlag';
import { Country } from '@/generated/commonapi';
import { useAppSelector } from '@/hooks/store';
import { useNodeUid } from '@/hooks/useNodeUid';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { Popups } from '@/types/popups';

import './CountrySelectPopup.less';

export const CountrySelectPopup = React.memo(
    ({ opened, filterCountries, onCountrySelect, onClose }: CountrySelectPopupProps) => {
        const { t } = useTranslation();

        const countries = useAppSelector((state) =>
            state.classificatorReducer.countryClassificator.filter((country) =>
                filterCountries ? filterCountries.includes(country.code) : true,
            ),
        );

        const [searchValue, setSearchValue] = useState('');

        const handleSearch = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
            [],
        );

        const foundCountries = useMemo(
            () =>
                searchValue?.trim()
                    ? countries.filter(({ name }) => name.toLowerCase().startsWith(searchValue.toLowerCase().trim()))
                    : countries,
            [countries, searchValue],
        );

        const popupUid = useNodeUid();

        const searchbarClassName = useMemo(() => `searchbar-${popupUid}`, [popupUid]);

        const makeCountrySelectHandler = useCallback(
            (country: Country) => () => {
                onCountrySelect(country);
                f7.searchbar.disable(`.${searchbarClassName}`);
            },
            [onCountrySelect, searchbarClassName],
        );

        return (
            <MSPopup
                className={cn(Popups.COUNTRY_SELECT_POPUP, 'country-select-popup')}
                opened={opened}
                onBackdropClick={onClose}
            >
                <Navbar backLink={false} noHairline noShadow>
                    <NavLeft>
                        <Link iconOnly onClick={onClose}>
                            <Icon className="icon-back" />
                        </Link>
                    </NavLeft>
                    <NavTitle>{t('Choose country')}</NavTitle>
                    <NavRight>
                        <Link
                            searchbarEnable={`.${searchbarClassName}`}
                            iconIos="f7:search"
                            iconAurora="f7:search"
                            iconMd="material:search"
                        />
                    </NavRight>
                    <Searchbar
                        className={searchbarClassName}
                        value={searchValue}
                        disableButton={!theme.aurora}
                        placeholder={t('Search')}
                        expandable
                        onChange={handleSearch}
                    />
                </Navbar>
                <List className="searchbar-not-found">
                    <ListItem title={t('Nothing found').toString()} />
                </List>
                {opened && countries.length && (
                    <List
                        className={`search-list-${popupUid} searchbar-found country-select-popup-list`}
                        noChevron
                        noHairlines
                        noHairlinesBetween
                    >
                        {foundCountries.map((country) => (
                            <ListItem
                                link
                                key={country.code}
                                title={country.name}
                                onClick={makeCountrySelectHandler(country)}
                            >
                                <CustomCountryFlag slot="media" code={country.code} width="30" />
                            </ListItem>
                        ))}
                    </List>
                )}
            </MSPopup>
        );
    },
);
CountrySelectPopup.displayName = 'CountrySelectPopup';

import cn from 'classnames';
import { Badge } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductStatusBadgeProps } from './ProductStatusBadge.types';

import { getProductStatusText } from './utils';

import { ReviewEstimationLabel } from '@/components/ReviewEstimationLabel';
import { Product } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';

import './ProductStatusBadge.less';

export const ProductStatusBadge = React.memo<ProductStatusBadgeProps>(
    ({ status, statusText, archived, withEstimation = false }) => {
        const { t } = useTranslation();

        const { Product_Status } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);

        const classificatorStatusText = useMemo(
            () => Product_Status?.find((item) => item.code.toLowerCase() === status.toString().toLowerCase())?.value,
            [Product_Status, status],
        );

        const displayedText = useMemo(
            () =>
                archived ? t('Archived') : classificatorStatusText ?? t(statusText) ?? t(getProductStatusText(status)),
            [archived, classificatorStatusText, status, statusText, t],
        );

        return (
            <div className="product-status-badge">
                <Badge
                    className={cn('product-status-badge__badge', `product-status-badge__badge-${status.toString()}`, {
                        archived,
                    })}
                >
                    {displayedText}
                </Badge>
                {status === Product.StatusEnum.AFR && withEstimation && <ReviewEstimationLabel />}
            </div>
        );
    },
);
ProductStatusBadge.displayName = 'ProductStatusBadge';

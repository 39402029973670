import cn from 'classnames';
import { Block, f7 } from 'framework7-react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AgreementPopupProps } from './AgreementPopup.types';

import { setAlertAction } from '@/actions/alertActions';
import { agreeMarketTerms, TC_AGREEMENT_SETTINGS_KEY } from '@/actions/profileActions';
import { ThemedButton } from '@/components/ThemedButton';
import { useAppSelector } from '@/hooks/store';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';

import './AgreementPopup.less';

const AgreementPopup: React.FC<AgreementPopupProps> = ({ className, opened, onPopupClosed }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { profile, updating, error } = useAppSelector((state) => state.profileReducer);
    const { Url_app: urls } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);

    useEffect(() => {
        if (updating) {
            f7.preloader.show();
        } else {
            f7.preloader.hide();

            if (error) {
                dispatch(
                    setAlertAction({
                        text: t(`${error}`),
                    }),
                );
            }

            const termsSettings = profile?.accountSettings?.filter((s) => s.name === TC_AGREEMENT_SETTINGS_KEY) || [];

            if (termsSettings.length > 0) {
                onPopupClosed();
            }
        }
    }, [updating, error, profile, t, dispatch, onPopupClosed]);

    const getLinkUrl = useCallback(
        (code: string) => {
            const filtered = urls.filter((u) => u.code === code);
            return filtered.length > 0 ? filtered[0].value : '#';
        },
        [urls],
    );

    const handleAcceptButton = useCallback(() => {
        const settings = {
            [TC_AGREEMENT_SETTINGS_KEY]: 'true',
        };

        dispatch(agreeMarketTerms(settings));
    }, [dispatch]);

    const renderIframe = useCallback(() => {
        const url = getLinkUrl('TermsAndConditions');

        return (
            <iframe
                id="iframeContent"
                src={url}
                loading="lazy"
                className="agreement-popup-iframe"
                frameBorder="0"
                title="Terms And Conditions"
            />
        );
    }, [getLinkUrl]);

    return (
        <MSPopup className={cn('agreement-popup', className)} opened={opened} onPopupClosed={onPopupClosed}>
            <Block className="agreement-popup-content">
                {renderIframe()}
                <div className="accept-button-area">
                    <ThemedButton className="accept-button" round fill large onClick={handleAcceptButton}>
                        {t('Accept')}
                    </ThemedButton>
                </div>
            </Block>
        </MSPopup>
    );
};

export default AgreementPopup;

import cn from 'classnames';
import { Preloader } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';

import { ImagesSliderProps } from './ImagesSlider.types';

import { IcClose } from '@/assets';
import { Carousel, CarouselItem, CarouselType } from '@/components/Carousel';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

import './ImagesSlider.less';

export const ImagesSlider = ({
    zoomed,
    onZoomBackdropClick,
    productName,
    slides,
    className,
    onItemClick,
    withSwitcher,
    isModal = false,
    isLoading = false,
    ...props
}: ImagesSliderProps): JSX.Element => {
    const [zoomedActiveIndex, setZoomedActiveIndex] = useState(0);

    const isMobile = useAppSelector(getIsMobile);

    const handleCarouselItemClick = useCallback(
        (item: CarouselItem, index: number) => {
            setZoomedActiveIndex(index);
            onItemClick?.(item, index);
        },
        [onItemClick],
    );

    return (
        <div className="images-slider-container">
            <Carousel
                {...props}
                className={cn('images-slider-custom', className)}
                type={CarouselType.imagesWithHoverZoom}
                slides={slides}
                onChangeActiveIndex={setZoomedActiveIndex}
                withSwitcher={withSwitcher ?? !isMobile}
                onItemClick={handleCarouselItemClick}
            />

            {zoomed &&
                ReactDOM.createPortal(
                    <div id="images-slider" className={cn('slider-zoom-modal', { modal: isModal })}>
                        {isLoading ? (
                            <Preloader />
                        ) : (
                            <div className="slider-zoom-modal-container">
                                <div className="slider-zoom-modal-container-head">
                                    <span
                                        className="slider-zoom-modal-container-head__title"
                                        onClick={onZoomBackdropClick}
                                    >
                                        {isMobile ? (
                                            <span>
                                                <IcClose />
                                                {`${zoomedActiveIndex + 1} from ${slides.length}`}
                                            </span>
                                        ) : (
                                            productName
                                        )}
                                    </span>
                                    {!isMobile && (
                                        <span
                                            className="slider-zoom-modal-container-head__close-button"
                                            onClick={onZoomBackdropClick}
                                        >
                                            <IcClose />
                                        </span>
                                    )}
                                </div>
                                <Carousel
                                    className="slider-images"
                                    slides={slides}
                                    type={CarouselType.fullScreenImages}
                                    zoomed={zoomed}
                                    initialIndex={zoomedActiveIndex}
                                    withSwitcher={!isMobile}
                                    onChangeActiveIndex={setZoomedActiveIndex}
                                    switcherClassname="zoomed-switcher"
                                />
                            </div>
                        )}
                    </div>,
                    document.getElementById('app'),
                )}
        </div>
    );
};

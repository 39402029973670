import React from 'react';

import { CustomCountryFlag } from '@/components/CustomCountryFlag';
import { CustomSelectValue } from '@/components/CustomSelect';
import { Country } from '@/generated/commonapi';

export const countryToCustomSelectValue = (country: Country, withIcon?: boolean): CustomSelectValue =>
    country && {
        label: country.name,
        value: country.code,
        icon: withIcon ? <CustomCountryFlag code={country.code} width="30" /> : undefined,
    };

import { F7Popup } from 'framework7-react';

import { ProductListRequest, Sorting } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';

export enum MyGoodsSortBy {
    price_low_to_high = 'Price (Low to High)',
    price_high_to_low = 'Price (High to Low)',
    what_s_new = 'Last added',
    //? may be need to be replaced with 'last_added'
}

export type StatusFiltersMobilePopupProps = F7Popup.Props & {
    opened: boolean; //required for useEffect
    options: { value: string; label: string }[];
    selectedOptions: string[];
    onApply?(statuses?: string[], filterParams?: Sorting): void;
    priceFrom?: number;
    priceTo?: number;
    presearchCount?: number;
    searchLoading?: boolean;
    handleSearch: (dispatchFn: AppDispatch, params: ProductListRequest) => void;
    saleUid?: string;
};

import { refreshAuthentication } from './sessionActions';

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    Account,
    AccountEmailWsControllerApi,
    AccountPhoneWsControllerApi,
    AccountWebResourcesRequest,
    AccountWsControllerApi,
} from '@/generated/commonapi';
import { Account as MarketPlaceAccount, AccountControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getProfile } from '@/selectors/profile';

export const ACCOUNT_SETTINGS_LOADING = 'ACCOUNT_SETTINGS_LOADING' as const;
export const ACCOUNT_SETTINGS_UPDATE_SUCCESS = 'ACCOUNT_SETTINGS_UPDATE_SUCCESS' as const;
export const ACCOUNT_SETTINGS_ERROR = 'ACCOUNT_SETTINGS_ERROR' as const;

export const GET_ACCOUNT_LOADING = 'GET_ACCOUNT_LOADING' as const;
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS' as const;
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR' as const;

const accountSettingsLoading = () => ({
    type: ACCOUNT_SETTINGS_LOADING,
});

const accountSettingsError = (error: string) => ({
    type: ACCOUNT_SETTINGS_ERROR,
    error,
});

const accountSettingUpdateSuccess = (account: Account) => ({
    type: ACCOUNT_SETTINGS_UPDATE_SUCCESS,
    account,
});

const getAccountLoading = () => ({
    type: GET_ACCOUNT_LOADING,
});

const getAccountSuccess = (account: MarketPlaceAccount) => ({
    type: GET_ACCOUNT_SUCCESS,
    account,
});

const getAccountError = (error: string) => ({
    type: GET_ACCOUNT_ERROR,
    error,
});

export type AccountSettingsActions = ReturnType<
    | typeof accountSettingsLoading
    | typeof accountSettingsError
    | typeof accountSettingUpdateSuccess
    | typeof getAccountLoading
    | typeof getAccountSuccess
    | typeof getAccountError
>;

export const getAccount = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(getAccountLoading());
        const accountControllerApi = new AccountControllerApi();

        const response = await accountControllerApi.getAccountUsingGET();

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(getAccountSuccess(response.body?.[0]));
    } catch (error) {
        console.error('at accountSettingsActions in getAccount', error);
        dispatch(getAccountError(`${error.message}`));
    }
};

export const accountSettingsChangeAvatar =
    (fileName: string | undefined, base64File: string | undefined, deleteAvatar = false) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            dispatch(accountSettingsLoading());
            const response = deleteAvatar
                ? await new AccountWsControllerApi().deleteAccountProfilePictureUsingDELETE()
                : await new AccountWsControllerApi().updateAccountProfilePictureUsingPOST({
                      base64File,
                      fileName,
                  });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(accountSettingUpdateSuccess(response.account));
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangeAvatar', error);
            dispatch(accountSettingsError(`${error.message}`));
        }
        return getState();
    };

export const accountSettingsChangePhone = (oldPhoneUid: string, newPhone: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(accountSettingsLoading());
        const controller = new AccountPhoneWsControllerApi();
        const response = await (oldPhoneUid
            ? controller.updatePhoneUsingPOST({
                  uid: oldPhoneUid,
                  fullNumber: newPhone,
                  primary: true,
              })
            : controller.addPhoneUsingPUT({
                  fullNumber: newPhone,
                  primary: true,
              }));

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(accountSettingUpdateSuccess(response.account));
    } catch (error) {
        console.error('at accountSettingsActions in accountSettingsChangePhone', error);
        dispatch(accountSettingsError(`${error.message}`));
    }
};

export const accountSettingsChangeEmail =
    (newEmail: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            dispatch(accountSettingsLoading());
            const response = await new AccountEmailWsControllerApi().addEmailUsingPUT({
                email: newEmail,
                language: getLanguageCode(getState()),
            });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(accountSettingUpdateSuccess(response.account));
            return getState();
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangeEmail', error);
            dispatch(accountSettingsError(`${error.message}`));
            return getState();
        }
    };

export const accountSettingsChangePassword =
    (oldPassword: string, password: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            dispatch(accountSettingsLoading());
            const response = await new AccountWsControllerApi().changePasswordUsingPOST({
                email: getProfile(getState()).accountEmails.find(({ primary }) => primary).email,
                oldPassword,
                password,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(accountSettingUpdateSuccess(response.account));
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangePassword', error);
            dispatch(accountSettingsError(`${error.message}`));
        }
    };

export const accountSettingsChangeNameSurname =
    (name: string, surname: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            const response = await new AccountWsControllerApi().updateAccountPersonUsingPOST({
                firstName: name,
                lastName: surname,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(accountSettingUpdateSuccess(response.account));
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangeNameSurname', error);
            dispatch(accountSettingsError(`${error.message}`));
        }
        return getState();
    };

export const accountSettingsChangeWebResource =
    (request: AccountWebResourcesRequest) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            const response = await new AccountWsControllerApi().setAccountWebResourcesUsingPOST(request);

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(
                accountSettingUpdateSuccess({
                    ...response.account,
                    webResources: response.account.webResources || [],
                }),
            );
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangeWebResource', error);
            dispatch(accountSettingsError(`${error.message}`));
        }
        return getState();
    };

export const accountSettingsUpdateNameSurmameBirthDate =
    (birthDate: string, firstName: string, lastName: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            const response = await new AccountWsControllerApi().updateAccountWithStatusUsingPOST({
                birthDate: birthDate as unknown as Date,
                firstName,
                lastName,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(accountSettingUpdateSuccess(response.account));
            await dispatch(refreshAuthentication());
        } catch (error) {
            console.error('at accountSettingsActions in accountSettingsChangeBirthDate', error);
            dispatch(accountSettingsError(`${error.message}`));
        }
        return getState();
    };

import React from 'react';

import { ProfileStatusProps } from './ProfileStatus.types';

import { useAppSelector } from '@/hooks/store';

import './ProfileStatus.less';

export const ProfileStatus = ({ profile }: ProfileStatusProps) => {
    const status = useAppSelector(
        (state) =>
            state.classificatorReducer.entitiesClassificators.Account_Status.filter(
                (item) => item.code === profile.status,
            )[0] || {},
    );

    const type = useAppSelector(
        (state) =>
            state.classificatorReducer.entitiesClassificators.Account_Type.filter(
                (item) => item.code === profile.type,
            )[0] || {},
    );

    return (
        <div className="profile-status">
            <span className="type">{type.value}</span>
            <span className="status">{status.value}</span>
        </div>
    );
};

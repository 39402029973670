import { useCallback } from 'react';

export const useScrollToAnchor = () => {
    const handleScrollToAnchor = useCallback((anchor: string) => {
        const element = document.getElementById(anchor);
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, []);
    return handleScrollToAnchor;
};

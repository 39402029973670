import { loadProductDetails } from '@/actions/productActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { ChatControllerApi, Store } from '@/generated/marketplaceapi';
import { ProductOrder } from '@/generated/paymentapi';
import i18n from '@/i18n';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { createProductUrl, getPlatform } from '@/utils';
import { Platform } from '@/utils/getPlatform';
import { isInsideGem } from '@/utils/isInsideGem';

class MessengerService {
    enabled = isInsideGem();

    isMobileApp = window.Android?.hideLoadingScreen || window.webkit?.messageHandlers?.hideLoadingScreen;

    platform = getPlatform();

    setPlatform = (platform: Platform) => {
        this.platform = platform;
    };

    hideLoadingScreen = () => {
        if (!this.enabled) return;
        if (this.platform === Platform.Android) {
            window.Android?.hideLoadingScreen();
        } else if (this.platform === Platform.iOS) {
            window.webkit?.messageHandlers.hideLoadingScreen.postMessage({});
        } else {
            window.parent?.postMessage({ hideLoadingScreen: true }, '*');
        }
    };

    goToMessenger = () => {
        if (!this.enabled) return;

        if (this.platform === Platform.Android) {
            window.Android?.goToMessenger();
        } else if (this.platform === Platform.iOS) {
            window.webkit?.messageHandlers.goToMessenger.postMessage({});
        } else {
            window.parent.postMessage({ goToMessenger: true }, '*');
        }
    };

    shareLink = (title: string, content: string) => {
        if (!this.enabled) return;
        const message = title + '\n\n' + content;
        if (this.platform === Platform.Android) {
            Android.shareLink(message);
        } else if (this.platform === Platform.iOS) {
            window.webkit.messageHandlers.shareLink.postMessage(message);
        } else {
            window.parent?.postMessage(
                {
                    type: 'marketplace',
                    title: title,
                    url: content,
                },
                '*',
            );
        }
    };

    sendMessage = (phone: string, message: string) => {
        if (this.platform === Platform.Android) {
            window.Android.openChat(phone, message);
        } else if (this.platform === Platform.iOS) {
            window.webkit.messageHandlers.jsHandlerMyJSFunc.postMessage({
                userID: phone,
                url: message,
            });
        } else {
            window.parent.postMessage(
                {
                    type: 'marketplace',
                    userID: phone,
                    url: message,
                },
                '*',
            );
        }
    };

    startChatWithProduct = async (item: IProduct, state: IApplicationStore, message?: string) => {
        if (!this.enabled) return undefined;

        const uid = item?.uid;
        let sellerUid = item?.seller?.externalAccountUid;

        try {
            if (!sellerUid) {
                item = await loadProductDetails(uid, state);
                sellerUid = item?.seller?.externalAccountUid;
                if (!sellerUid) return i18n.t('Seller uid not found.');
            }

            const response = await new ChatControllerApi().registerProductChatUsingPUT({
                uid: item.uid,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            if (response?.body[0]) {
                if (!message) {
                    const productUrl = createProductUrl(item.uid);
                    message = i18n.t('ChatMessageProduct', { productUrl });
                }

                firebaseAnalyticsInstance.startChatWithSeller?.(sellerUid);

                this.sendMessage(sellerUid, message);

                return undefined;
            } else {
                console.error('in messengerService at startChatWithProduct');
                return i18n.t('Unknown Error');
            }
        } catch (error) {
            console.error('in messengerService at startChatWithProduct', error);
            return i18n.t((error as Error).message);
        }
    };

    startChatWithStore = (store: Store, message?: string) => {
        if (!this.enabled) return undefined;

        const storeUid = store?.account?.externalAccountUid;

        if (!storeUid) {
            return i18n.t('Store uid not found.');
        }

        firebaseAnalyticsInstance.startChatWithStore?.(store);

        this.sendMessage(storeUid, message);
        return undefined;
    };

    startChatWithOrder = (order: ProductOrder, message: string, type: 'sendToSeller' | 'sendToBuyer') => {
        if (!this.enabled) return undefined;
        const uidToPass =
            type === 'sendToSeller' ? order?.seller?.externalAccountUid : order?.buyer?.externalAccountUid;

        if (!uidToPass) {
            return i18n.t('External account uid not found.');
        }

        if (type === 'sendToSeller') {
            firebaseAnalyticsInstance.startChatWithSeller?.(uidToPass);
        }

        this.sendMessage(uidToPass, message);
        return undefined;
    };

    openExternalLink = (url: string, target: '_self' | '_blank' | '_parent' | '_top' = '_blank', features?: string) => {
        switch (this.platform) {
            case Platform.Android:
                window.Android?.openLinkInBrowser(url);
                break;
            case Platform.iOS:
                window.webkit?.messageHandlers?.openLinkInBrowser?.postMessage(url);
                break;
            default:
                window.open(url, target, features);
        }
    };

    openChatByDeepLink = (link: string, text: string) => {
        if (this.platform === Platform.Android) {
            window.Android.openChatByDeepLink(link, text);
        } else if (this.platform === Platform.iOS) {
            window.webkit.messageHandlers.openChatByDeepLink.postMessage({ deeplink: link, text: text });
        } else {
            window.parent.postMessage(
                {
                    type: 'openChatByDeepLink',
                    deeplink: link,
                    text: text,
                },
                '*',
            );
        }
    };

    reload = () => {
        if (this.platform === Platform.Android) {
            window.Android.reloadPage();
        } else if (this.platform === Platform.iOS) {
            window.webkit.messageHandlers.reloadPage.postMessage({});
        }
    };

    downloadFile = (file: Blob) => {
        return file;
    };

    getPaymentRedirectUrls = (languageCode: string) => {
        if (this.platform === Platform.Android) {
            return {
                failUrl: process.env.PAYMENT_ANDROID_REDIRECT_URL,
                successUrl: process.env.PAYMENT_ANDROID_REDIRECT_URL,
            };
        }

        if (this.platform === Platform.iOS) {
            return {
                failUrl: process.env.PAYMENT_IOS_REDIRECT_URL,
                successUrl: process.env.PAYMENT_IOS_REDIRECT_URL,
            };
        }

        return {
            failUrl: `${process.env.HOST}/payment-processing/?language=${languageCode}`,
            successUrl: `${process.env.HOST}/payment-processing/?language=${languageCode}`,
        };
    };
}

export const messengerService = new MessengerService();

export enum Platform {
    Android,
    iOS,
}

export const getPlatform = () => {
    if (typeof Android !== 'undefined') {
        return Platform.Android;
    } else if (typeof window.webkit !== 'undefined') {
        return Platform.iOS;
    } else {
        return null;
    }
};

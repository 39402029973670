import { Dispatch } from 'redux';

import { fillClassificatorProductStatusValue } from './classificatorActions';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { client, marketplaceapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    CommonMarketplaceApiResponseUploadedImage,
    Product,
    ProductControllerApi,
    ProductListRequest,
    Sale,
    ProductStoreControllerApi,
    StoreHomepage,
    StoreHomepageWidget,
    ServiceProviderControllerApi,
    CommonMarketplaceApiResponseStoreHomepage,
    CommonMarketplaceApiResponseString,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { StoreHomepageConstructorBannerLocal } from '@/pages/StoreHomepageConstructorPage/StoreHomepageConstructorPage.types';
import { checkProductEditAllowed } from '@/reducers/productCreateReducer';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { IStoreHomepageWidgetCategoryProducts } from '@/reducers/storeHomePageReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getColorFromClassificator } from '@/selectors/getColorFromClassificator';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { ProductType } from '@/types/productType';
import { noop, productsToSliderItems } from '@/utils';

export const SHOWN_PRODUCT_STATUSES = [Product.StatusEnum.AFR, Product.StatusEnum.APR, Product.StatusEnum.PBL];
export const SHOWN_SERVICES_STATUSES = [Product.StatusEnum.PBL];

export enum STORE_HOMEPAGE_STATUSES {
    DRF = 'DRF',
    PBL = 'PBL',
}

export const SAVE_STORE_HOMEPAGE = 'SAVE_STORE_HOMEPAGE' as const;
export const SAVE_STORE_HOMEPAGE_LOADING = 'SAVE_STORE_HOMEPAGE_LOADING' as const;
export const STORE_HOMEPAGE_WIDGET_CATEGORIES_PRODUCTS_LOADING_SUCCESS =
    'STORE_HOMEPAGE_WIDGET_CATEGORIES_PRODUCTS_LOADING_SUCCESS' as const;
export const STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS_LOADING =
    'STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS_LOADING' as const;
export const REMOVE_STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS = 'REMOVE_STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS' as const;

export const STORE_CATEGORIES_LOADING = 'STORE_CATEGORIES_LOADING' as const;
export const STORE_HOMEPAGE_CATEGORIES_LOADING_SUCCESS = 'STORE_HOMEPAGE_CATEGORIES_LOADING_SUCCESS' as const;
export const STORE_PRODUCTS_LOADING = 'STORE_PRODUCTS_LOADING' as const;
export const STORE_PRODUCTS_LOADING_SUCCESS = 'STORE_PRODUCTS_LOADING_SUCCESS' as const;
export const STORE_PRODUCTS_COUNT_SAVE = 'STORE_PRODUCTS_COUNT_SAVE' as const;

const storeHomePageLoadingAction = (loading: boolean) => ({
    type: SAVE_STORE_HOMEPAGE_LOADING,
    loading,
});

const saveStoreHomePageAction = (data: StoreHomepage) => ({
    type: SAVE_STORE_HOMEPAGE,
    data,
});

export const removeStoreHomepageWidgetCategoryProductsAction = (categoryCode: string) => ({
    type: REMOVE_STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS,
    category: categoryCode,
});

const loadWidgetCategoriesProductsAction = (storeWidgetsCategories: IStoreHomepageWidgetCategoryProducts[]) => ({
    type: STORE_HOMEPAGE_WIDGET_CATEGORIES_PRODUCTS_LOADING_SUCCESS,
    storeWidgetsCategories,
});

const storeCategoriesLoadingAction = (loading: boolean) => ({
    type: STORE_CATEGORIES_LOADING,
    loading,
});

const storeCategoriesLoadingSuccessAction = (categories: string[]) => ({
    type: STORE_HOMEPAGE_CATEGORIES_LOADING_SUCCESS,
    categories,
});

const storeProductsLoadingAction = (loading: boolean) => ({
    loading,
    type: STORE_PRODUCTS_LOADING,
});

const storeProductsLoadingSuccessAction = (products: IProduct[]) => ({
    products,
    type: STORE_PRODUCTS_LOADING_SUCCESS,
});

const storeProductsCountSaveAction = (count: number) => ({
    type: STORE_PRODUCTS_COUNT_SAVE,
    count,
});

const widgetCategoryProductsLoadingAction = (loading: boolean) => ({
    type: STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS_LOADING,
    loading,
});

export type StoreHomepageActions = ReturnType<
    | typeof storeHomePageLoadingAction
    | typeof saveStoreHomePageAction
    | typeof removeStoreHomepageWidgetCategoryProductsAction
    | typeof loadWidgetCategoriesProductsAction
    | typeof storeCategoriesLoadingAction
    | typeof storeCategoriesLoadingSuccessAction
    | typeof storeProductsLoadingAction
    | typeof widgetCategoryProductsLoadingAction
    | typeof storeProductsLoadingSuccessAction
    | typeof storeProductsCountSaveAction
>;

const uploadFileForBanner = async (file: File) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };

    let filename = file.name;
    const ext = filename.split('.').pop();
    if (!ext || ext.length < 3) {
        filename += '.jpg';
    }

    const formData = new FormData();
    formData.append('file', file, filename);
    formData.append('bucket', 'store');

    const data: CommonMarketplaceApiResponseUploadedImage = (
        await client.post(marketplaceapiURL + `/file/upload`, formData, config)
    ).data;

    return data;
};

export const searchStoreProducts =
    (request = {} as ProductListRequest, getSearchCountOnly?: boolean) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(storeProductsLoadingAction(true));

        const activeProductType = getActiveProductType(getState());

        const searchParams = request.statuses
            ? request
            : {
                  ...request,
                  statuses: (activeProductType === ProductType.Service
                      ? SHOWN_SERVICES_STATUSES
                      : SHOWN_PRODUCT_STATUSES) as unknown as string[],
              };
        const state = getState();
        try {
            const items: IProduct[] =
                (
                    await new ProductControllerApi().productListUsingPOST({
                        ...searchParams,
                        type: getActiveProductType(state),
                    })
                ).body || [];
            fillClassificatorProductStatusValue(items, state);
            // product edit allowed status
            items.forEach((item) => {
                item.category = undefined;
                item.colorValue = getColorFromClassificator(state, item.color);
                item.isProductEditAllowed = checkProductEditAllowed(item.status);
            });
            getSearchCountOnly
                ? dispatch(storeProductsCountSaveAction(items?.length))
                : dispatch(storeProductsLoadingSuccessAction(items));
        } catch (error) {
            console.error('in searchStoreProducts at storeHomePageActions');
        } finally {
            dispatch(storeProductsLoadingAction(false));
        }
    };

export const loadStoreProductCategories =
    (storeUid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(storeCategoriesLoadingAction(true));

        try {
            const activeProductType = getActiveProductType(getState());

            let data: CommonMarketplaceApiResponseString;

            if (activeProductType === ProductType.Service) {
                data = await new ServiceProviderControllerApi().getStoreProductCategoryListUsingPOST1(
                    {
                        productStatusList: SHOWN_SERVICES_STATUSES as unknown as string[],
                    },
                    storeUid,
                );
            } else {
                data = await new ProductStoreControllerApi().getStoreProductCategoryListUsingPOST(
                    {
                        productStatusList: SHOWN_PRODUCT_STATUSES as unknown as string[],
                    },
                    storeUid,
                );
            }

            dispatch(storeCategoriesLoadingSuccessAction((data?.body as unknown as string[]) ?? []));
        } catch (error) {
            console.error('in loadStoreProductCategories at storeHomePageActions');
        } finally {
            dispatch(storeCategoriesLoadingAction(false));
        }
    };

export const loadWidgetCategoriesProducts =
    (widgets: StoreHomepageWidget[], sellerUid?: string) => (dispatch: Dispatch, getState: () => IApplicationStore) => {
        const state = getState();
        dispatch(widgetCategoryProductsLoadingAction(true));
        try {
            // TODO: change to product list request after api fix
            Promise.all(
                widgets.map((widget) =>
                    new ProductControllerApi()
                        .productSearchUsingPOST(
                            {
                                category: widget.category,
                                saleUid: widget.saleUid,
                                count: 10,
                                productType: getActiveProductType(state),
                                sellerUid,
                                // statuses: SHOWN_PRODUCT_STATUSES as unknown as string[],
                            },
                            getCountryCodeFromState(state),
                        )
                        .then((res) => [res, widget] as const),
                ),
            ).then((response) =>
                dispatch(
                    loadWidgetCategoriesProductsAction(
                        response?.map(([res, widget]) => ({
                            entityUid: widget.category ?? widget?.saleUid,
                            products: productsToSliderItems(
                                (res.body ?? []).map((item) => ({
                                    ...item,
                                    sale: widget?.saleUid
                                        ? ({
                                              uid: widget.saleUid,
                                              discountAmountFixed: widget.saleDiscountAmountFixed,
                                              discountAmountPercent: widget.saleDiscountAmountPercent,
                                              name: widget.saleName,
                                          } as Sale)
                                        : undefined,
                                })),
                                noop,
                                [],
                            ),
                        })),
                    ),
                ),
            );
        } catch (e) {
            console.error('in loadWidgetCategoriesProducts at storeHomePageActions', e);
        } finally {
            dispatch(widgetCategoryProductsLoadingAction(false));
        }
    };

export const getStoreHomePage = (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
    dispatch(storeHomePageLoadingAction(true));
    try {
        const activeProductType = getActiveProductType(getState());

        if (activeProductType === ProductType.Service) {
            const data = (await new ServiceProviderControllerApi().getHomepageUsingGET1(storeUid)).body || [];
            dispatch(saveStoreHomePageAction({ ...data[0] }));
        } else {
            const data = (await new ProductStoreControllerApi().getHomepageUsingGET(storeUid)).body || [];
            dispatch(saveStoreHomePageAction({ ...data[0] }));
        }
    } catch (error) {
        console.error('in storeHomePageActions at getStoreHomePage', error, 'error');
    } finally {
        dispatch(storeHomePageLoadingAction(false));
    }
};

export const initStoreHomePage =
    (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        dispatch(storeHomePageLoadingAction(true));
        firebaseAnalyticsInstance.openCreateStoreHomepagePage?.();
        try {
            const activeProductType = getActiveProductType(getState());

            let response: CommonMarketplaceApiResponseStoreHomepage;

            if (activeProductType === ProductType.Service) {
                response = await new ServiceProviderControllerApi().addStoreHomepageUsingPUT1(storeUid);
            } else {
                response = await new ProductStoreControllerApi().addStoreHomepageUsingPUT(storeUid);
            }

            const [homepage] = response.body || [];
            dispatch(saveStoreHomePageAction({ ...homepage }));

            firebaseAnalyticsInstance.storeHomepageCreated?.(homepage?.uid);
        } catch (error) {
            console.error('in storeHomePageActions at initStoreHomePage', error);
        } finally {
            dispatch(storeHomePageLoadingAction(false));
        }
    };

export const storeHomePagePublish =
    (storeHomepageUid: string, storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        dispatch(storeHomePageLoadingAction(true));

        try {
            const activeProductType = getActiveProductType(getState());

            if (activeProductType === ProductType.Service) {
                const data =
                    (
                        await new ServiceProviderControllerApi().publishStoreHomepageUsingPOST1(
                            storeHomepageUid,
                            storeUid,
                        )
                    ).body || [];
                dispatch(saveStoreHomePageAction({ ...data[0] }));
            } else {
                const data =
                    (await new ProductStoreControllerApi().publishStoreHomepageUsingPOST(storeHomepageUid, storeUid))
                        .body || [];
                dispatch(saveStoreHomePageAction({ ...data[0] }));
            }
        } catch (error) {
            console.error('in storeHomePageActions at storeHomePagePublish', error);
        } finally {
            dispatch(storeHomePageLoadingAction(false));
        }
    };

export const storeHomePageUpdate =
    (
        homepage: Omit<StoreHomepage, 'banner'> & { banner?: StoreHomepageConstructorBannerLocal },
        storeUid: string,
        publish: boolean,
    ) =>
    async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        dispatch(storeHomePageLoadingAction(true));
        const state = getState();

        try {
            const activeProductType = getActiveProductType(getState());

            let mobileImageUrl = homepage?.banner?.mobileImage?.url,
                desktopImageUrl = homepage?.banner?.desktopImage?.url;

            if (homepage.banner) {
                if (homepage.banner.mobileImage.file) {
                    mobileImageUrl = (await uploadFileForBanner(homepage.banner.mobileImage.file)).body?.[0].url;
                }
                if (homepage.banner.desktopImage.file) {
                    desktopImageUrl = (await uploadFileForBanner(homepage.banner.desktopImage.file)).body?.[0].url;
                }
            }

            let response: CommonMarketplaceApiResponseStoreHomepage;

            if (activeProductType === ProductType.Service) {
                response = await new ServiceProviderControllerApi().editStoreHomepageDetailsUsingPOST1(
                    homepage.banner
                        ? {
                              ...homepage,
                              banner: {
                                  mobileImageUrl,
                                  desktopImageUrl,
                                  targetUrl: homepage.banner.targetUrl,
                                  title: homepage.banner.title,
                              },
                          }
                        : (homepage as Omit<StoreHomepage, 'banner'>),
                    storeUid,
                    getLanguageCode(state),
                );
            } else {
                response = await new ProductStoreControllerApi().editStoreHomepageDetailsUsingPOST(
                    homepage.banner
                        ? {
                              ...homepage,
                              banner: {
                                  mobileImageUrl,
                                  desktopImageUrl,
                                  targetUrl: homepage.banner.targetUrl,
                                  title: homepage.banner.title,
                              },
                          }
                        : (homepage as Omit<StoreHomepage, 'banner'>),
                    storeUid,
                    getLanguageCode(state),
                );
            }

            handleResponseAndThrowAnErrorIfExists(response);

            if (publish) {
                let published: CommonMarketplaceApiResponseStoreHomepage;

                if (activeProductType === ProductType.Service) {
                    published = await new ServiceProviderControllerApi().publishStoreHomepageUsingPOST1(
                        response?.body?.[0].uid,
                        storeUid,
                    );
                } else {
                    published = await new ProductStoreControllerApi().publishStoreHomepageUsingPOST(
                        response?.body?.[0].uid,
                        storeUid,
                    );
                }

                handleResponseAndThrowAnErrorIfExists(published);
                dispatch(saveStoreHomePageAction(published?.body?.[0]));
                return getState();
            }

            dispatch(saveStoreHomePageAction(response.body?.[0]));
            return getState();
        } catch (error) {
            console.error('in storeHomePageActions at storeHomePageUpdate', error);
            return getState();
        } finally {
            dispatch(storeHomePageLoadingAction(false));
        }
    };

export const storeHomePageDelete =
    (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        dispatch(storeHomePageLoadingAction(true));

        const activeProductType = getActiveProductType(getState());

        try {
            if (activeProductType === ProductType.Service) {
                await new ServiceProviderControllerApi().deleteAllStoreHomepagesUsingDELETE1(storeUid);
            } else {
                await new ProductStoreControllerApi().deleteAllStoreHomepagesUsingDELETE(storeUid);
            }

            dispatch(saveStoreHomePageAction(null));
        } catch (error) {
            console.error('in storeHomePageActions at storeHomePageDelete', error);
        } finally {
            dispatch(storeHomePageLoadingAction(false));
        }
    };

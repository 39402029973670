import { emptyContent } from './AddReviewPopup.constants';

import { ProductReview } from '@/generated/marketplaceapi';

import type { AddReviewPopupContent } from './AddReviewPopup.types';

export const makeContentRequired = (initialContent: ProductReview): Required<AddReviewPopupContent> => ({
    text: initialContent?.originalText ?? emptyContent.text,
    rating: initialContent?.rating ?? emptyContent.rating,
    anonymously: initialContent?.anonymously ?? emptyContent.anonymously,
    images: initialContent?.images ?? emptyContent.images,
});

export const getOrderStatusText = (status: string, statusDescription?: string): string => {
    if (statusDescription && statusDescription !== status) {
        return statusDescription;
    }

    switch (status.toUpperCase()) {
        case 'CA':
            return 'Rejected';
        case 'PA':
        case 'MP':
            return 'Paid';
        case 'EX':
            return 'Expired';
        case 'PRC':
            return 'Processing';
        case 'RCV':
            return 'Ready for Pickup';
        case 'SHP':
            return 'Shipped';
        case 'DLV':
            return 'Completed';
        case 'RWC':
            return 'Awaiting Confirmation';
        case 'RRC':
            return 'Reservation Confirmed';
        case 'RCA':
            return 'Cancelled';
        case 'RRE':
            return 'Rejected';
        default:
            return statusDescription;
    }
};

import { useEffect } from 'react';

export const FocusScrollingManager = () => {
    useEffect(() => {
        const handleScroll = () => document.activeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

        window.addEventListener('keyboardDidShow', handleScroll);

        return () => window.removeEventListener('keyboardDidShow', handleScroll);
    }, []);

    return null;
};

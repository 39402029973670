import Util from 'cleave.js/src/utils/Util';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { messengerService } from '@/api/messengerService';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { Address, Order } from '@/generated/commonapi';
import {
    BankLinkPaymentWsControllerApi,
    CreditCardPaymentRequest,
    CreditCardPaymentResponse,
    CreditCardPaymentWsControllerApi,
    ProductOrder,
} from '@/generated/paymentapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getProfile } from '@/selectors/profile';
import { URLParams } from '@/types/URLParams';
import { combinePhone } from '@/utils';
import { Platform } from '@/utils/getPlatform';

export const PAYMENT_LOADING = 'PAYMENT_LOADING' as const;
export const PAYMENT_ERROR = 'PAYMENT_ERROR' as const;
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS' as const;

export const PAYMENT_RESET_ERRORS = 'PAYMENT_RESET_ERRORS' as const;

const paymentLoadingAction = () => ({
    type: PAYMENT_LOADING,
});

const paymentErrorAction = (error: unknown) => ({
    type: PAYMENT_ERROR,
    error,
});

const paymentSuccessAction = (payload: CreditCardPaymentResponse) => ({
    type: PAYMENT_SUCCESS,
    payload,
});

export const resetPaymentErrors = () => ({
    type: PAYMENT_RESET_ERRORS,
});

export type PaymentActions = ReturnType<
    typeof paymentLoadingAction | typeof paymentErrorAction | typeof paymentSuccessAction | typeof resetPaymentErrors
>;

const getRedirectUrls = (orderUid: string, languageCode?: string, customerLocation?: string) => {
    const protocol = Util.isAndroid() ? 'app:' : window.location.protocol;

    return {
        failUrl: `${protocol}//${window.location.host}${
            process.env.NODE_ENV === 'development' ? '/' : process.env.APP_PATH
        }#/payment-result/${orderUid}/?${URLParams.LANGUAGE}=${languageCode}&${
            URLParams.CUSTOMER_LOCATION
        }=${customerLocation}`,

        successUrl: `${protocol}//${window.location.host}${
            process.env.NODE_ENV === 'development' ? '/' : process.env.APP_PATH
        }#/payment-result/${orderUid}/?${URLParams.LANGUAGE}=${languageCode}&${
            URLParams.CUSTOMER_LOCATION
        }=${customerLocation}`,
    };
};

const getBuiltInRedirectUrls = (orderUid: string, languageCode?: string, customerLocation?: string) => {
    if (messengerService.platform === Platform.Android || messengerService.platform === Platform.iOS) {
        return messengerService.getPaymentRedirectUrls(languageCode);
    }
    return getRedirectUrls(orderUid, languageCode, customerLocation);
};

export const createPaymentForOrder =
    (order: ProductOrder | (Order & { amount: number }), deliveryAddress: Address, token: string, saveCard = false) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(paymentLoadingAction());
        firebaseAnalyticsInstance.attemptToPay?.();
        const state = getState();

        const { accountEmails, accountPhones } = state.sessionReducer.profile;

        const bankCardPaymentOptions: CreditCardPaymentRequest = {
            ...(messengerService.enabled
                ? getBuiltInRedirectUrls(order.uid, getLanguageCode(getState()))
                : getRedirectUrls(order.uid)),
            orderUid: order.uid,
            amount: order.amount,
            currencyCode: order.currencyCode,
            paymentMethod: 'bankcard',
            saveCard,
            token,
        };

        if (deliveryAddress.email) {
            bankCardPaymentOptions['email'] = deliveryAddress.email;
        } else {
            const primaryEmail = accountEmails?.find((item) => item.primary);
            if (primaryEmail) {
                bankCardPaymentOptions['email'] = primaryEmail.email;
            } else {
                const secondaryEmail = accountEmails?.[0];
                if (secondaryEmail) {
                    bankCardPaymentOptions['email'] = secondaryEmail.email;
                } else {
                    const primaryPhone = accountPhones?.find((item) => item.primary);
                    if (primaryPhone) {
                        bankCardPaymentOptions.phone = primaryPhone;
                    }
                }
            }
        }

        try {
            const bankCardPaymentUsingPOSTResult = await new CreditCardPaymentWsControllerApi().paymentUsingPOST1(
                bankCardPaymentOptions,
            );

            handleResponseAndThrowAnErrorIfExists(bankCardPaymentUsingPOSTResult);

            dispatch(paymentSuccessAction(bankCardPaymentUsingPOSTResult));

            firebaseAnalyticsInstance.paymentSuccess?.(bankCardPaymentUsingPOSTResult.paymentUid);

            return getState();
        } catch (err) {
            console.error('at paymentActions in createPaymentForOrder');

            firebaseAnalyticsInstance.paymentFailed?.();

            dispatch(paymentErrorAction(err.toString()));
            return getState();
        }
    };

export const createPaymentForOrderOverBank =
    (order: ProductOrder | (Order & { amount: number }), pspCode: 'paykeeper' | 'sbp') =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(paymentLoadingAction());

        const profile = getProfile(getState());

        const confirmedPhone = profile.accountPhones?.find(({ confirmed }) => confirmed);

        try {
            const response = await new BankLinkPaymentWsControllerApi().paymentRegisteredUsingPOST({
                ...(messengerService.enabled
                    ? getBuiltInRedirectUrls(
                          order.uid,
                          getLanguageCode(getState()),
                          getState().customerLocationReducer.country?.code,
                      )
                    : getRedirectUrls(
                          order.uid,
                          getLanguageCode(getState()),
                          getState().customerLocationReducer.country?.code,
                      )),
                currencyCode: 'RUB',
                amount: order.amount,
                orderUid: order.uid,
                paymentMethod: 'maksekeskus',
                phone: {
                    fullNumber: confirmedPhone
                        ? combinePhone(confirmedPhone.countryCode, confirmedPhone.number)
                        : undefined,
                },
                email: profile.accountEmails?.find(({ confirmed }) => confirmed)?.email,
                pspCode,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(paymentSuccessAction(response));

            return getState();
        } catch (error) {
            console.error('at paymentActions in createPaymentForOrderOverBank');

            dispatch(paymentErrorAction(error.toString()));
            return getState();
        }
    };

import { createAccountRegistrationStore, createAccountStore, isStoreNameAvailable } from './accountStoresActions';

import { sendCartFromLocalStorage } from './cartActions';

import { CHANGE_APP_LANGUAGE } from './classificatorActions';

import { loadMyWallets } from './myWalletsActions';

import { reloadAllProductListTypes, sendWishListFromLocalStorage } from './productActions';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { sessionService } from '@/api';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    Account,
    AccountEmailWsControllerApi,
    AccountForgotPasswordRequest,
    AccountWsControllerApi,
    CreateBusinessAccountRequest,
    TransformToBusinessAccountRequest,
    UpdateBusinessAccountRequest,
} from '@/generated/commonapi';
import { Store } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { CreateAccountRequest, Profile, TokenResponse } from '@/reducers/sessionReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { URLParams } from '@/types/URLParams';
import { getQueryParameterFromURL } from '@/utils';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE' as const;

export const SESSION_RESTORING = 'SESSION_RESTORING' as const;
export const SESSION_LOADING = 'SESSION_LOADING' as const;
export const SESSION_SUCCESS = 'SESSION_SUCCESS' as const;
export const SESSION_LOGOUT = 'SESSION_LOGOUT' as const;
export const SESSION_ERROR = 'SESSION_ERROR' as const;
export const SESSION_REGISTER_LOGIN_TRY = 'SESSION_REGISTER_LOGIN_TRY' as const;

export const REGISTRATION_LOADING = 'REGISTRATION_LOADING' as const;
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS' as const;
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR' as const;
export const REGISTRATION_ERROR_CODE_SAVE = 'REGISTRATION_ERROR_CODE_SAVE' as const;

export const VERIFICATION_EMAIL_CODE_LOADING = 'VERIFICATION_EMAIL_CODE_LOADING' as const;
export const VERIFICATION_EMAIL_CODE_SUCCESS = 'VERIFICATION_EMAIL_CODE_SUCCESS' as const;
export const VERIFICATION_EMAIL_CODE_ERROR = 'VERIFICATION_EMAIL_CODE_ERROR' as const;

export const VERIFICATION_RESEND_LOADING = 'VERIFICATION_RESEND_LOADING' as const;
export const VERIFICATION_RESEND_SUCCESS = 'VERIFICATION_RESEND_SUCCESS' as const;
export const VERIFICATION_RESEND_ERROR = 'VERIFICATION_RESEND_ERROR' as const;

export const VERIFICATION_ERRORS_RESET = 'VERIFICATION_ERRORS_RESET' as const;

export const AUTHORIZATION_CODE_LOADING = 'AUTHORIZATION_CODE_LOADING' as const;
export const AUTHORIZATION_CODE_SUCCESS = 'AUTHORIZATION_CODE_SUCCESS' as const;
export const AUTHORIZATION_CODE_ERROR = 'AUTHORIZATION_CODE_ERROR' as const;

export const ONETIME_PASSWORD_LOADING = 'ONETIME_PASSWORD_LOADING' as const;
export const ONETIME_PASSWORD_SUCCESS = 'ONETIME_PASSWORD_SUCCESS' as const;
export const ONETIME_PASSWORD_ERROR = 'ONETIME_PASSWORD_ERROR' as const;
export const ONETIME_PASSWORD_LOGIN_LOADING = 'ONETIME_PASSWORD_LOGIN_LOADING' as const;
export const ONETIME_PASSWORD_LOGIN_SUCCESS = 'ONETIME_PASSWORD_LOGIN_SUCCESS' as const;
export const ONETIME_PASSWORD_LOGIN_ERROR = 'ONETIME_PASSWORD_LOGIN_ERROR' as const;

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING' as const;
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS' as const;
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR' as const;

export const CHANGE_PROFILE_REGION = 'CHANGE_PROFILE_REGION' as const;

export const TRANSFORM_TO_BUSINESS_ACCOUNT_LOADING = 'TRANSFORM_TO_BUSINESS_ACCOUNT_LOADING' as const;
export const TRANSFORM_TO_BUSINESS_ACCOUNT_SUCCESS = 'TRANSFORM_TO_BUSINESS_ACCOUNT_SUCCESS' as const;
export const TRANSFORM_TO_BUSINESS_ACCOUNT_ERROR = 'TRANSFORM_TO_BUSINESS_ACCOUNT_ERROR' as const;

export const UPDATE_BUSINESS_ACCOUNT_LOADING = 'UPDATE_BUSINESS_ACCOUNT_LOADING' as const;
export const UPDATE_BUSINESS_ACCOUNT_SUCCESS = 'UPDATE_BUSINESS_ACCOUNT_SUCCESS' as const;
export const UPDATE_BUSINESS_ACCOUNT_ERROR = 'UPDATE_BUSINESS_ACCOUNT_ERROR' as const;

const sessionRestoring = () => ({
    type: SESSION_RESTORING,
});

const sessionLoading = () => ({
    type: SESSION_LOADING,
});

const sessionSuccess = (profile: Profile, authData: TokenResponse) => ({
    type: SESSION_SUCCESS,
    profile,
    authData,
});

const sessionError = (error: unknown) => ({
    type: SESSION_ERROR,
    error,
});

const sessionLogout = () => ({
    type: SESSION_LOGOUT,
});

const registerLoginTry = () => ({
    type: SESSION_REGISTER_LOGIN_TRY,
});

const registrationLoading = () => ({
    type: REGISTRATION_LOADING,
});

const registrationSuccess = (profile: Profile) => ({
    type: REGISTRATION_SUCCESS,
    profile,
});

const registrationError = (error: unknown) => ({
    type: REGISTRATION_ERROR,
    error,
});

const emailVerificationLoading = (emailCodeLoading: boolean) => ({
    type: VERIFICATION_EMAIL_CODE_LOADING,
    emailCodeLoading,
});

const emailVerificationSuccess = (profile: Profile) => ({
    type: VERIFICATION_EMAIL_CODE_SUCCESS,
    profile,
});

const emailVerificationError = (error: string) => ({
    type: VERIFICATION_EMAIL_CODE_ERROR,
    error,
});

const resendVerificationLoading = (emailCodeLoading: boolean) => ({
    type: VERIFICATION_RESEND_LOADING,
    emailCodeLoading,
});

const resendVerificationSuccess = (profile: Profile) => ({
    type: VERIFICATION_RESEND_SUCCESS,
    profile,
});

const resendVerificationError = (error: string) => ({
    type: VERIFICATION_RESEND_ERROR,
    error,
});

export const resetVerificationErrors = () => ({
    type: VERIFICATION_ERRORS_RESET,
});

const saveRegistrationErrorCode = (code: number) => ({
    type: REGISTRATION_ERROR_CODE_SAVE,
    code,
});

const changeLanguage = (language: string) => ({
    type: CHANGE_APP_LANGUAGE,
    language,
});

const authorizationCodeLoading = () => ({
    type: AUTHORIZATION_CODE_LOADING,
});

const authorizationCodeSuccess = (code: string) => ({
    type: AUTHORIZATION_CODE_SUCCESS,
    code,
});

const authorizationCodeError = (error: string) => ({
    type: AUTHORIZATION_CODE_ERROR,
    error,
});

const changePasswordLoading = () => ({
    type: CHANGE_PASSWORD_LOADING,
});

const changePasswordError = (error: string) => ({
    type: CHANGE_PASSWORD_ERROR,
    error,
});

const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
});

const oneTimePasswordLoading = () => ({
    type: ONETIME_PASSWORD_LOADING,
});

const oneTimePasswordSuccess = () => ({
    type: ONETIME_PASSWORD_SUCCESS,
});

const oneTimePasswordError = (error: string) => ({
    type: ONETIME_PASSWORD_ERROR,
    error,
});

const oneTimePasswordLoginLoading = () => ({
    type: ONETIME_PASSWORD_LOGIN_LOADING,
});

const oneTimePasswordLoginSuccess = (profile: Account, authData: TokenResponse) => ({
    type: ONETIME_PASSWORD_LOGIN_SUCCESS,
    profile,
    authData,
});

const oneTimePasswordLoginError = (error: string) => ({
    type: ONETIME_PASSWORD_LOGIN_ERROR,
    error,
});

const transformToBusinessAccountLoadingAction = (transformToBusinessAccountLoading: boolean) => ({
    type: TRANSFORM_TO_BUSINESS_ACCOUNT_LOADING,
    transformToBusinessAccountLoading,
});

const transformToBusinessAccountSuccess = (profile: Profile) => ({
    type: TRANSFORM_TO_BUSINESS_ACCOUNT_SUCCESS,
    profile,
});

const transformToBusinessAccountErrorAction = (error?: string) => ({
    type: TRANSFORM_TO_BUSINESS_ACCOUNT_ERROR,
    error,
});

export const changeProfileRegionAction = (region: string) => ({
    type: CHANGE_PROFILE_REGION,
    region,
});

const updateBusinessAccountLoadingAction = (updateBusinessAccountLoading: boolean) => ({
    type: UPDATE_BUSINESS_ACCOUNT_LOADING,
    updateBusinessAccountLoading,
});

const updateBusinessAccountLoadingSuccessAction = (profile: Profile) => ({
    type: UPDATE_BUSINESS_ACCOUNT_SUCCESS,
    profile,
});

const updateBusinessAccountErrorAction = (error?: string) => ({
    type: UPDATE_BUSINESS_ACCOUNT_ERROR,
    error,
});

export type SessionActions = ReturnType<
    | typeof sessionRestoring
    | typeof sessionLoading
    | typeof sessionSuccess
    | typeof sessionError
    | typeof sessionLogout
    | typeof registerLoginTry
    | typeof registrationLoading
    | typeof registrationSuccess
    | typeof registrationError
    | typeof saveRegistrationErrorCode
    | typeof changeLanguage
    | typeof authorizationCodeLoading
    | typeof authorizationCodeSuccess
    | typeof authorizationCodeError
    | typeof changePasswordLoading
    | typeof changePasswordError
    | typeof changePasswordSuccess
    | typeof oneTimePasswordLoading
    | typeof oneTimePasswordSuccess
    | typeof oneTimePasswordError
    | typeof oneTimePasswordLoginLoading
    | typeof oneTimePasswordLoginSuccess
    | typeof oneTimePasswordLoginError
    | typeof transformToBusinessAccountLoadingAction
    | typeof transformToBusinessAccountSuccess
    | typeof transformToBusinessAccountErrorAction
    | typeof emailVerificationError
    | typeof emailVerificationLoading
    | typeof emailVerificationSuccess
    | typeof resendVerificationError
    | typeof resendVerificationLoading
    | typeof resendVerificationSuccess
    | typeof resetVerificationErrors
    | typeof changeProfileRegionAction
    | typeof updateBusinessAccountLoadingAction
    | typeof updateBusinessAccountErrorAction
    | typeof updateBusinessAccountLoadingSuccessAction
>;

export const replaceAccountLanguage =
    (account: Account) => (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const { isLanguageChanged } = getState().rootReducer;
        if (!isLanguageChanged && account && (account.language || account.communicationLanguage)) {
            const languageQueryParam = getQueryParameterFromURL(URLParams.LANGUAGE);
            const accountLanguage = account.language || account.communicationLanguage;
            if (!languageQueryParam && accountLanguage) {
                dispatch(changeLanguage(accountLanguage.code));
                dispatch(reloadAllProductListTypes());
            }
        }
    };

export const restoreSession = () => (dispatch: AppDispatch) => {
    const user = {};
    dispatch(sessionLoading());
    dispatch(sessionRestoring());
    dispatch(sessionSuccess(user, null));
};

export const loginWithOneTimeCode =
    (oneTimeCode: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(sessionLoading());
        try {
            const authData = await sessionService.getAuthorizationDataByOneTimeCode(oneTimeCode);

            if (!authData?.accessToken) {
                throw new Error('Authentication error');
            }
            const profile = await sessionService.fetchProfile(authData.accessToken);

            firebaseAnalyticsInstance.setUserProperties(profile);

            dispatch(sessionSuccess(profile, authData));
            sessionService.saveRefreshTokenToLocalStorage(authData.refreshToken);

            await dispatch(loadMyWallets());
            await dispatch(sendCartFromLocalStorage(getCountryCodeFromState(getState())));
            await dispatch(sendWishListFromLocalStorage());
            return getState();
        } catch (error) {
            console.error('at sessionActions in loginWithCode', error);
            sessionService.removeRefreshTokenFromLocalStorage();
            dispatch(sessionError(error.toString()));
            return getState();
        }
    };

export const loginWithUsernamePassword =
    (username: string, password: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(sessionLoading());

        try {
            if (!username || !password) {
                throw new Error('Empty username or password');
            }

            const authData = await sessionService.getAuthorizationDataByUsernameAndPassword(username, password);

            if (!authData?.accessToken) {
                throw new Error('Authentication error');
            }

            const profile = await sessionService.fetchProfile(authData.accessToken);

            firebaseAnalyticsInstance.setUserProperties(profile);

            dispatch(sessionSuccess(profile, authData));
            sessionService.saveRefreshTokenToLocalStorage(authData.refreshToken);

            await dispatch(loadMyWallets());
            await dispatch(sendCartFromLocalStorage(getCountryCodeFromState(getState())));
            await dispatch(sendWishListFromLocalStorage());

            dispatch(reloadAllProductListTypes());

            return getState();
        } catch (error) {
            console.error('at sessionActions in loginWithUserPassword', error);
            sessionService.removeRefreshTokenFromLocalStorage();
            dispatch(sessionError(error.message.toString()));

            return getState();
        }
    };

export const refreshAuthentication = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();

    const isLoading = state.sessionReducer.loading;

    if (isLoading) {
        return getState();
    }

    try {
        const refreshToken = sessionService.getRefreshTokenFromLocalStorage();

        dispatch(sessionLoading());

        if (!refreshToken) {
            throw new Error('Authentication error');
        }

        const authData = await sessionService.refreshAuthorizationToken(refreshToken);

        if (!authData?.accessToken) {
            throw new Error('Authentication error');
        }

        const profile = await sessionService.fetchProfile(authData.accessToken);

        firebaseAnalyticsInstance.setUserProperties(profile);
        sessionService.saveRefreshTokenToLocalStorage(authData.refreshToken);

        dispatch(sessionSuccess(profile, authData));

        return getState();
    } catch (error) {
        console.error('at sessionActions in refreshAuthentication', error);

        // ? remove refresh token from localStorage only if response is bad, not internet connection
        if (navigator.onLine) {
            sessionService.removeRefreshTokenFromLocalStorage();
        }
        dispatch(sessionError(error.toString()));
        return getState();
    }
};

export const refreshAuthOrLoginWithDefaultCredentials = () => async (dispatch: AppDispatch) => {
    const refreshSuccess = isLoggedIn(await dispatch(refreshAuthentication()));

    if (!refreshSuccess && process.env.USERNAME && process.env.PASSWORD) {
        await dispatch(loginWithUsernamePassword(process.env.USERNAME, process.env.PASSWORD));
    }
};

export const authentificateAccount = () => async (dispatch: AppDispatch) => {
    const oneTimeAuthCode = getQueryParameterFromURL(URLParams.AUTH_CODE);

    if (oneTimeAuthCode) {
        try {
            await dispatch(loginWithOneTimeCode(oneTimeAuthCode ?? ''));
        } catch (error) {
            await dispatch(refreshAuthOrLoginWithDefaultCredentials());
        }
    } else {
        await dispatch(refreshAuthOrLoginWithDefaultCredentials());
    }

    dispatch(registerLoginTry());

    firebaseAnalyticsInstance.openApp?.();
};

export const createAuthorizationCode = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(authorizationCodeLoading());
    try {
        const accessToken = getState().sessionReducer.accessToken;
        const code = await sessionService.getOneTimeAuthCodeByAccessToken(accessToken);
        dispatch(authorizationCodeSuccess(code));
    } catch (error) {
        console.error('at sessionActions in createAuthorizationCode', error);
        dispatch(authorizationCodeError(error.toString()));
    }
};

export const register =
    (request: CreateAccountRequest) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(registrationLoading());
        const state = getState();

        try {
            const response = await new AccountWsControllerApi().createPersonalAccountUsingPOST({
                ...request,
                language: getLanguageCode(state),
            });

            if (!response.errorData) {
                firebaseAnalyticsInstance.registrationSuccess?.(response.account);
                dispatch(registrationSuccess(response.account));
                return getState();
            }

            dispatch(saveRegistrationErrorCode(response?.errorData?.errorCode));

            throw new Error(response.errorData.messageCode || response.errorData.errorMessage);
        } catch (err) {
            console.error('at sessionActions in register', err);
            dispatch(registrationError(err.message));
            return getState();
        }
    };

export const registerBusiness =
    (request: CreateBusinessAccountRequest, storeInfo?: Store) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(registrationLoading());
        const state = getState();

        try {
            if (await isStoreNameAvailable(storeInfo.name)) {
                const response = await new AccountWsControllerApi().createBusinessAccountUsingPOST({
                    ...request,
                    language: getLanguageCode(state),
                });

                if (!response.errorData) {
                    dispatch(registrationSuccess(response.account));
                    dispatch(createAccountRegistrationStore({ ...storeInfo, accountUid: response.account.uid }));

                    return getState();
                }

                dispatch(saveRegistrationErrorCode(response?.errorData?.errorCode));

                throw new Error(response.errorData.messageCode || response.errorData.errorMessage);
            } else {
                throw new Error('Store name is already taken. Please, choose another one.');
            }
        } catch (err) {
            console.error('at sessionActions in register', err);
            dispatch(registrationError(err.response?.data?.errorData?.message || err.message));
            return getState();
        }
    };

export const verifyEmailCode =
    (confirmationCode: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(emailVerificationLoading(true));

        try {
            const response = await new AccountEmailWsControllerApi().confirmEmailUsingPOST(confirmationCode);

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(emailVerificationSuccess(response.account));

            dispatch(refreshAuthentication());
        } catch (err) {
            console.error('at sessionActions in verifyEmailCode', err);
            dispatch(emailVerificationError(err.message));
        } finally {
            dispatch(emailVerificationLoading(false));
        }
        return getState();
    };

export const resendVerificationEmail =
    (email: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(resendVerificationLoading(true));

        try {
            const response = await new AccountEmailWsControllerApi().resendAccountActivationUsingPOST(email);

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(resendVerificationSuccess(response.account));
        } catch (err) {
            console.error('at sessionActions in verifyEmailCode', err);
            dispatch(resendVerificationError(err.message));
        } finally {
            dispatch(resendVerificationLoading(false));
        }
        return getState();
    };

export const logoutUser = () => (dispatch: AppDispatch) => {
    // ? remove refresh token from localStorage only if response is bad, not internet connection
    if (navigator.onLine) {
        sessionService.removeRefreshTokenFromLocalStorage();
    }
    dispatch(sessionLogout());
};

export const changePassword =
    (password: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(changePasswordLoading());
        try {
            if (!password) {
                throw new Error('Empty password');
            }
            const response = await new AccountWsControllerApi().resetPasswordUsingPOST({
                password,
            });
            if (response.errorData && response.errorData.errorMessage) {
                dispatch(changePasswordError(response.errorData.errorMessage));
            } else {
                dispatch(changePasswordSuccess());
            }
        } catch (error) {
            console.error('at sessionActions in changePassword', error);
            dispatch(changePasswordError(error.toString()));
        }
        return getState();
    };

export const loginWithOneTimePassword =
    (username: string, password: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(oneTimePasswordLoginLoading());

        try {
            if (!username || !password) {
                throw new Error('Empty username or password');
            }

            const authData = await sessionService.getAuthorizationDataByUsernameAndPassword(username, password);

            if (authData.errorData && authData.errorData.errorMessage) {
                dispatch(oneTimePasswordLoginError(authData.errorData.errorMessage));
            } else {
                const profile = await sessionService.fetchProfile(authData.accessToken);
                sessionService.saveRefreshTokenToLocalStorage(authData.refreshToken);

                if (profile) {
                    dispatch(loadMyWallets());
                    dispatch(oneTimePasswordLoginSuccess(profile, authData));
                }
            }
        } catch (error) {
            console.error('at sessionActions in loginWithOneTimePassword', error);
            dispatch(oneTimePasswordLoginError(error.toString()));
        }

        return getState();
    };

export const oneTimePassword =
    (username: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(oneTimePasswordLoading());
        try {
            if (!username) {
                throw new Error('Empty username');
            }
            const state = getState();
            const request: AccountForgotPasswordRequest = {
                username,
                language: getLanguageCode(state),
            };
            const response = await new AccountWsControllerApi().oneTimePasswordUsingPOST(request);
            if (response.errorData && response.errorData.errorMessage) {
                dispatch(oneTimePasswordError(response.errorData.errorMessage));
            } else {
                dispatch(oneTimePasswordSuccess());
            }
        } catch (error) {
            console.error('at sessionActions in oneTimePassword', error);
            dispatch(oneTimePasswordError(error.toString()));
        }
        return getState();
    };

export const transformToBusinessAccount =
    (transformData: TransformToBusinessAccountRequest, storeInfo: Store) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        if (!isLoggedIn(getState())) {
            return getState();
        }

        dispatch(transformToBusinessAccountLoadingAction(true));

        try {
            if (await isStoreNameAvailable(storeInfo.name)) {
                const response = await new AccountWsControllerApi().transformAccountUsingPOST(
                    transformData,
                    'business',
                );

                handleResponseAndThrowAnErrorIfExists(response);
                firebaseAnalyticsInstance.businessAccountAndStoreCreated?.();

                dispatch(transformToBusinessAccountSuccess(response.account));
                await dispatch(createAccountStore(storeInfo));

                await dispatch(refreshAuthentication());
            } else {
                throw new Error('Store name is already taken. Please, choose another one.');
            }
        } catch (error) {
            console.error('at sessionActions in transformToBusinessAccount', error);
            dispatch(
                transformToBusinessAccountErrorAction(
                    error.response?.data?.errorData?.message || (error as Error).message,
                ),
            );
        } finally {
            dispatch(transformToBusinessAccountLoadingAction(false));
        }

        return getState();
    };

export const updateBusinessAccount =
    (data: UpdateBusinessAccountRequest) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(updateBusinessAccountLoadingAction(true));
        try {
            const response = await new AccountWsControllerApi().updateBusinessAccountUsingPOST(data);
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(updateBusinessAccountLoadingSuccessAction(response.account));
        } catch (error) {
            console.error('in sessionActions at updateBusinessAccount');
            dispatch(updateBusinessAccountErrorAction((error as Error).message));
        } finally {
            dispatch(updateBusinessAccountLoadingAction(false));
        }
        return getState();
    };

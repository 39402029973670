import { useEffect } from 'react';

import { loadSalesList } from '@/actions/saleActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadTranslatedSalesList = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(getLanguageCode);
    const logged = useAppSelector(isLoggedIn);

    useEffect(() => {
        if (logged) {
            dispatch(loadSalesList());
        }
    }, [dispatch, language, logged]);

    return null;
};

import cn from 'classnames';
import React from 'react';

import { GroupTitleProps } from './GroupTitle.types';

import './GroupTitle.less';

export const GroupTitle = ({ bold, children }: GroupTitleProps) => (
    <h2 className={cn('category-sheet__group-title', { bold })}>{children}</h2>
);

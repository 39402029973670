import cn from 'classnames';
import { ListItem } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SideMenuItemProps } from './SideMenuItem.types';

import { IcCheckActive } from '@/assets';

import './SideMenuItem.less';

export const SideMenuItem = React.memo(
    <S extends string | number>({
        step,
        title,
        active,
        disabled,
        valid,
        onClick,
        showValidHighlight = true,
    }: SideMenuItemProps<S>): JSX.Element => {
        const { t } = useTranslation();

        const handleClick = useCallback(() => !disabled && onClick(step), [disabled, onClick, step]);

        return (
            <ListItem
                title={t(title)}
                className={cn('side-menu__step', { active, valid })}
                onClick={handleClick}
                disabled={disabled}
            >
                <div className="tooltip">{t(title)}</div>
                {valid && showValidHighlight && <IcCheckActive />}
            </ListItem>
        );
    },
);
SideMenuItem.displayName = 'SideMenuItem';

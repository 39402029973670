import dayjs from 'dayjs';

import { RegisterFormErrors, RegisterFormValues, RegSteps } from './RegisterPopup.types';

import { CreateAccountRequest } from '@/reducers/sessionReducer';
import {
    ACCEPT_ERROR,
    INVALID_EMAIL_ERROR,
    INVALID_NAME_ERROR,
    INVALID_PASSWORD_CUT_ERROR,
    INVALID_PASSWORD_ERROR,
    NOT_MATCHING_PASSWORDS_ERROR,
    REQUIRED_FIELD_ERROR,
} from '@/shared/constants';
import { isEmail, isValidPassword, isValidUserName } from '@/utils';

const validateAuthData = (values: RegisterFormValues): RegisterFormErrors => ({
    email: !values.email ? REQUIRED_FIELD_ERROR : !isEmail(values.email) ? INVALID_EMAIL_ERROR : undefined,
    password: !values.password
        ? REQUIRED_FIELD_ERROR
        : !isValidPassword(values.password)
        ? INVALID_PASSWORD_CUT_ERROR
        : undefined,
    passwordRepeat: !values.passwordRepeat
        ? REQUIRED_FIELD_ERROR
        : values.password !== values.passwordRepeat
        ? NOT_MATCHING_PASSWORDS_ERROR
        : undefined,
    accept: !values.accept ? ACCEPT_ERROR : undefined,
});

const validatePersonalData = (values: RegisterFormValues): RegisterFormErrors => ({
    firstName: !values.firstName
        ? REQUIRED_FIELD_ERROR
        : !isValidUserName(values.firstName)
        ? INVALID_NAME_ERROR
        : undefined,
    lastName: !values.lastName
        ? REQUIRED_FIELD_ERROR
        : !isValidUserName(values.lastName)
        ? INVALID_NAME_ERROR
        : undefined,
    country: !values.country?.value ? REQUIRED_FIELD_ERROR : undefined,
});

export const validateRegister = (values: RegisterFormValues, step: RegSteps): RegisterFormErrors => {
    if (step === RegSteps.AUTH_DATA) {
        return validateAuthData(values);
    }

    return validatePersonalData(values);
};

export const getDefaultBirthDateValue = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return dayjs(date).format('YYYY-MM-DD');
};

export const getFullErrorMessage = (shortMessage: string) => {
    switch (shortMessage) {
        case INVALID_PASSWORD_CUT_ERROR:
            return INVALID_PASSWORD_ERROR;
        default:
            return undefined;
    }
};

const ALTERNATE_ERRORS: RegisterFormErrors = {
    email: INVALID_EMAIL_ERROR,
    password: INVALID_PASSWORD_ERROR,
    passwordRepeat: NOT_MATCHING_PASSWORDS_ERROR,
    accept: ACCEPT_ERROR,
};

export const getAuthErrorsDialog = (errors: RegisterFormErrors): string[] =>
    Object.entries(errors)
        .map(([key, item]) => (item ? ALTERNATE_ERRORS[key] : `Field ${key} is required`))
        .filter(Boolean);

export const registerFormValuesToCreateAccountRequest = ({
    email,
    password,
    passwordRepeat,
    firstName,
    lastName,
    country,
    referralCode,
    accept,
    profilePic: profilePictureBase64File,
    profilePicName: profilePictureFileName,
}: RegisterFormValues): CreateAccountRequest => {
    return {
        password,
        passwordRepeat,
        accept,
        email,
        country: country?.value,
        referralCode,
        firstName,
        lastName,
        profilePictureBase64File,
        profilePictureFileName,
    };
};

import { ProductVideo } from '@/generated/marketplaceapi';

export const createVideoURLLink = (id: string, type: ProductVideo.TypeEnum) => {
    switch (type) {
        case ProductVideo.TypeEnum.VIMEO:
            return `https://vimeo.com/${id}`;
        case ProductVideo.TypeEnum.YOUTUBE:
            return `https://www.youtube.com/watch?v=${id}`;
        case ProductVideo.TypeEnum.VK:
            return `https://vk.com/video?q=&z=video-${id}`;
        default:
            return '';
    }
};

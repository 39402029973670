import cn from 'classnames';
import { f7 } from 'framework7-react';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { CarouselProps, CarouselType } from './Carousel.types';

import { getCarouselParams } from './Carousel.utils';

import { CarouselItemCard } from './CarouselItemCard';

import { addToWishList } from '@/actions/productActions';
import { IcPlaceholderSmall, IcPlaceholderText } from '@/assets';
import { CarouselControlButtons } from '@/components/Carousel/CarouselControlButtons';
import { ImageSwitcher } from '@/components/Carousel/ImageSwitcher';
import { PaginationIndicator } from '@/components/PaginationIndicator';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useElementSize } from '@/hooks/useElementSize';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';

import 'swiper/less';
import 'swiper/less/zoom';

import './Carousel.less';

export const Carousel = ({
    slides,
    type,
    zoomed,
    withPagination,
    onClick,
    className,
    initialIndex = 0,
    activeIndex,
    onChangeActiveIndex,
    showFeaturesHighlight,
    mobileView,
    style,
    showIfEmpty,
    switcherClassname,
    withSwitcher,
    onItemClick,
    onClickItemWish,
    showNotAvailableItems,
}: CarouselProps): ReactElement => {
    const isMobile = useAppSelector(getIsMobile);
    const logged = useAppSelector(isLoggedIn);

    const dispatch = useAppDispatch();

    const [currentPoint, setCurrentPoint] = useState(initialIndex);
    const { ref, container } = useElementSize<HTMLDivElement>();
    const [swiperInstance, setSwiperInstance] = useState<SwiperType>(null);

    const onClickAddToWish =
        onClickItemWish ??
        ((uid: string) => {
            if (logged) {
                dispatch(addToWishList(uid));
            } else {
                f7.popup.open(`.${Popups.LOGIN_POPUP}`);
            }
        });

    const slidesPerView = useMemo(
        () => getCarouselParams(type, container?.width ?? 0, isMobile)?.find((item) => item.condition).slidesPerView,
        [container?.width, isMobile, type],
    );

    const emptySlides = useMemo(
        () =>
            slides?.length
                ? []
                : Array.from({ length: slidesPerView }).map((_, index) => (
                      <div className="carousel-item-empty" key={index}>
                          <div className="carousel-item-empty__card">
                              <IcPlaceholderSmall />
                          </div>

                          <IcPlaceholderText />
                      </div>
                  )),
        [slides?.length, slidesPerView],
    );

    const handleCurrentPointChange = useCallback(
        (point: number) => {
            swiperInstance.slideTo(point);
        },
        [swiperInstance],
    );

    const handleSlideChange = useCallback((slide: SwiperType) => {
        setCurrentPoint(slide.activeIndex);
    }, []);

    const handleSwipe = useCallback((swiper: SwiperType) => {
        setSwiperInstance(swiper);
    }, []);

    useEffect(() => {
        onChangeActiveIndex?.(currentPoint);
    }, [currentPoint, onChangeActiveIndex]);

    useEffect(() => {
        setCurrentPoint(initialIndex ?? activeIndex);
    }, [activeIndex, initialIndex]);

    return (
        <div style={style} className={cn('carousel__container', className)}>
            <div ref={ref} onClick={onClick} className="carousel__container-list">
                <Swiper
                    className="carousel"
                    initialSlide={initialIndex}
                    slidesPerView={slidesPerView}
                    onSlideChange={handleSlideChange}
                    onSwiper={handleSwipe}
                    modules={[Zoom]}
                    zoom
                    speed={600}
                    mousewheel
                    spaceBetween={isMobile ? 8 : 24}
                >
                    {slides.map((props, index) => (
                        <SwiperSlide className="carousel-item-wrapper" key={index}>
                            <CarouselItemCard
                                showFeaturesHighlight={showFeaturesHighlight}
                                mobileView={mobileView}
                                showVideoPlayer={zoomed}
                                type={type}
                                item={props}
                                onClick={onItemClick}
                                index={index}
                                showNotAvailableItem={showNotAvailableItems}
                                onClickAddToWish={onClickAddToWish}
                            />
                        </SwiperSlide>
                    ))}
                    {!slides?.length &&
                        showIfEmpty &&
                        emptySlides?.map((slide, ind) => (
                            <SwiperSlide className="carousel-item-wrapper" key={ind}>
                                {slide}
                            </SwiperSlide>
                        ))}
                </Swiper>
                <CarouselControlButtons
                    mobileView={mobileView}
                    activeIndex={currentPoint}
                    slidesLength={slides?.length}
                    slidesPerView={slidesPerView}
                    fullScreenImages={type === CarouselType.fullScreenImages}
                    swiper={swiperInstance}
                />
                {withPagination && <PaginationIndicator totalItems={slides?.length} currentIndex={currentPoint} />}
            </div>
            {withSwitcher && (
                <ImageSwitcher
                    className={switcherClassname}
                    imagesItems={slides}
                    onItemClick={handleCurrentPointChange}
                    selectedIndex={currentPoint}
                />
            )}
        </div>
    );
};

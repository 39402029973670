import React, { SetStateAction, useCallback } from 'react';

export const useLoginScenary = (
    setLoginPopupOpened: React.Dispatch<SetStateAction<boolean>>,
    setForgotPasswordPopupOpened: React.Dispatch<SetStateAction<boolean>>,
    setRegisterPopupOpened: React.Dispatch<SetStateAction<boolean>>,
) => {
    const onForgotPassword = useCallback(() => {
        setForgotPasswordPopupOpened(true);
    }, [setForgotPasswordPopupOpened]);

    const onLoginClick = useCallback(() => {
        setLoginPopupOpened(true);
    }, [setLoginPopupOpened]);

    const openRegisterPopup = useCallback(() => {
        setLoginPopupOpened(false);
        setRegisterPopupOpened(true);
    }, [setRegisterPopupOpened, setLoginPopupOpened]);

    const onRegister = useCallback(() => {
        setLoginPopupOpened(false);
    }, [setLoginPopupOpened]);

    const closeRegisterPopup = useCallback(() => setRegisterPopupOpened(false), [setRegisterPopupOpened]);

    const closeForgotPasswordPopUp = useCallback(
        () => setForgotPasswordPopupOpened(false),
        [setForgotPasswordPopupOpened],
    );

    const openLoginPopup = useCallback(() => {
        setRegisterPopupOpened(false);
        setLoginPopupOpened(true);
    }, [setLoginPopupOpened, setRegisterPopupOpened]);

    const closeLoginPopup = useCallback(() => setLoginPopupOpened(false), [setLoginPopupOpened]);

    return {
        onForgotPassword,
        onLoginClick,
        openRegisterPopup,
        closeRegisterPopup,
        closeForgotPasswordPopUp,
        openLoginPopup,
        closeLoginPopup,
        onRegister,
    };
};

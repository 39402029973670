import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoriesMenuDesktopProps } from './CategoriesMenuDesktop.types';

import { useAppNavbarTopNavbar } from '../AppNavBar/AppNavBar.hooks';

import { CategoryImage } from '../CategoryImage';

import { useAppSelector } from '@/hooks/store';
import { getSortedCategories } from '@/selectors/getSortedCategories';
import { getCategoryTranslateKey, navigateToCategory } from '@/utils';

import './CategoriesMenuDesktop.less';

export const CategoriesMenuDesktop = ({ opened, handleClose, className }: CategoriesMenuDesktopProps) => {
    const { t } = useTranslation();

    const topNavbarVisible = useAppNavbarTopNavbar();
    const categories = useAppSelector(getSortedCategories);

    return (
        <>
            <div
                className={cn('popup-backdrop categories-menu-backdrop', {
                    'backdrop-in': opened,
                })}
                onClick={handleClose}
            />
            {opened && (
                <div
                    className={cn('categories-menu-desktop', className, {
                        opened,
                        'top-navbar-not-visible': !topNavbarVisible,
                    })}
                >
                    <ul className="categories-menu-desktop__list">
                        {categories.map((category) => {
                            const children = (category.children || []).filter((child) => child.productCount > 0);

                            return (
                                <li
                                    key={category.categoryCode}
                                    className={cn('categories-menu-desktop__list-item', {
                                        'categories-menu-desktop__list-item-with-products': category.productCount,
                                    })}
                                    onClick={() =>
                                        category.productCount ? navigateToCategory(category.categoryCode) : null
                                    }
                                >
                                    <CategoryImage
                                        categoryName={category.categoryName}
                                        url={category.imageUrl}
                                        showSoon={!category.productCount}
                                        color={category.color}
                                    />
                                    <span className="categories-menu-desktop__list-item-name">
                                        {t(getCategoryTranslateKey(category))}
                                    </span>
                                    <span className="categories-menu-desktop__list-item-children">
                                        {children
                                            .slice(0, 2)
                                            .map((c) => t(getCategoryTranslateKey(c)))
                                            .join(', ')}
                                        {children.length > 2 && ` ${t('common_and_other')}`}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

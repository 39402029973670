import { ICategoryClassificator } from '@/reducers/categoryReducer';

export const getSubRoutes = (category: ICategoryClassificator): ICategoryClassificator[] => {
    if (!category) {
        return [];
    } else if (category.parent) {
        return getSubRoutes(category.parent).concat(category);
    } else {
        return [category];
    }
};

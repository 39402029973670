import { useEffect } from 'react';

import {
    loadClaimSubjectsClassificator,
    loadClassificator,
    loadCountryClassificator,
    loadCurrencyClassificator,
} from '@/actions/classificatorActions';
import { getLanguages } from '@/actions/languagesActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePrevious } from '@/hooks/usePrevious';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { LANG_DEPENDENT_CLASSIFICATOR_TYPES, LOADING_SUBJECT_CLASSIFICATOR_TYPES } from '@/shared/constants';

export const ReloadClassificatorsOnLanguageChange = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(getLanguageCode);
    const prevLanguage = usePrevious(language);

    useEffect(() => {
        if (language && prevLanguage && prevLanguage !== language) {
            // Entities classificators
            LANG_DEPENDENT_CLASSIFICATOR_TYPES.forEach((type) => void dispatch(loadClassificator(type)));
            dispatch(loadClaimSubjectsClassificator(LOADING_SUBJECT_CLASSIFICATOR_TYPES));
            dispatch(loadCountryClassificator());
            dispatch(loadCurrencyClassificator());
            dispatch(getLanguages());
        }
    }, [dispatch, language, prevLanguage]);

    return null;
};

import { MUST_BE_GT_THAN_ZERO, MUST_BE_INTEGER, NOT_A_NUMBER } from '@/shared/constants';

export const validatePositiveInteger = (x: unknown): string | undefined => {
    if ((typeof x === 'string' && x.trim() === '') || isNaN(Number(x)) || !isFinite(Number(x))) {
        return NOT_A_NUMBER;
    }
    if (Number(x) > Math.trunc(Number(x))) return MUST_BE_INTEGER;
    if (Number(x) <= 0) return MUST_BE_GT_THAN_ZERO;
    return undefined;
};

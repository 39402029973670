import {
    STATISTICS_ERROR,
    STATISTICS_LOADING,
    STATISTICS_LOADING_SUCCESS,
    StatisticsActions,
} from '@/actions/statisticsActions';
import { Statistics, StoreRatingResponse } from '@/generated/marketplaceapi';

export interface IStatisticsState {
    saleStatisticsLoading?: boolean;
    productStatisticsLoading?: boolean;
    statisticsDetails?: Statistics;
    storeRating?: StoreRatingResponse;
    statisticsError?: string;
}

const initialState: IStatisticsState = {
    saleStatisticsLoading: false,
    productStatisticsLoading: false,
};

const statisticsReducer = (state = initialState, action: StatisticsActions): IStatisticsState => {
    switch (action.type) {
        case STATISTICS_ERROR:
        case STATISTICS_LOADING_SUCCESS:
        case STATISTICS_LOADING:
            return { ...state, ...action.payload };
        default: {
            return state;
        }
    }
};

export default statisticsReducer;

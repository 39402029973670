import React from 'react';

import { SellerAreaLayoutProps } from './SellerAreaLayout.types';

import { SellerAreaMenuItemName } from '@/components/SellerAreaMenu/SellerAreaMenu.types';
import { useSellerAreaMenuItems } from '@/components/SellerAreaMenu/useSellerAreaMenuItems';
import { PageSideMenuLayout } from '@/layouts/PageSideMenuLayout';

export const SellerAreaLayout = (props: SellerAreaLayoutProps) => {
    const { menuItemNames, menuItems } = useSellerAreaMenuItems();

    return (
        <PageSideMenuLayout<SellerAreaMenuItemName>
            {...props}
            type="SellerArea"
            menuItems={menuItems}
            menuItemsNames={menuItemNames}
        />
    );
};

import cn from 'classnames';
import { List, ListItem } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoriesOptionsProps } from './CategoriesOptions.types';

import { IcArrowRight, IcDone, IcMarketspace } from '@/assets';
import { CategoryImage } from '@/components/CategoryImage';

import { useAppSelector } from '@/hooks/store';

import './CategoriesOptions.less';
import { getCategoryTranslateKey } from '@/utils';

export const CategoriesOptions = ({
    selectedSubcategory,
    choosenCategories,
    onCategoryClick,
    handleRootAllClick,
    everyLevelMultyselectable,
    everyLevelSelectable,
    deepestOnlySelectable,
    withIcons,
    allowedCategories,
    showAll,
    skipRoot = false,
}: CategoriesOptionsProps) => {
    const { t } = useTranslation();

    const { categories: categoriesTree } = useAppSelector((state) => state.categoryReducer);

    const renderedCategories = useMemo(() => {
        const res = selectedSubcategory?.children ?? categoriesTree ?? [];
        if (showAll) {
            return res;
        }
        return res.filter((category) => category.productCount);
    }, [categoriesTree, selectedSubcategory?.children, showAll]);

    const showAllProductsOption =
        ((!everyLevelMultyselectable && selectedSubcategory) || everyLevelSelectable) &&
        !deepestOnlySelectable &&
        (!skipRoot || selectedSubcategory);

    return (
        <List
            noHairlinesBetween
            noHairlines
            noChevron
            className={cn('category-sheet__options-list', 'categories-options')}
        >
            {showAllProductsOption && (
                <ListItem
                    key={selectedSubcategory?.categoryCode ?? 'all'}
                    link
                    title={t('All products')}
                    onClick={
                        selectedSubcategory ? () => onCategoryClick(selectedSubcategory, true) : handleRootAllClick
                    }
                >
                    {choosenCategories?.includes(selectedSubcategory) && (
                        <IcDone className="category-sheet__check-mark" />
                    )}
                    {withIcons && !selectedSubcategory && (
                        <div className="all-products-icon" slot="media">
                            <IcMarketspace />
                        </div>
                    )}
                </ListItem>
            )}
            {renderedCategories.map(
                (category) =>
                    (!allowedCategories || allowedCategories.has(category)) && (
                        <ListItem
                            key={category.categoryCode}
                            link
                            title={t(getCategoryTranslateKey(category))}
                            onClick={() => onCategoryClick(category)}
                        >
                            {withIcons && !selectedSubcategory && (
                                <CategoryImage
                                    slot="media"
                                    categoryName={category.categoryName}
                                    color={category.color}
                                    url={category.imageUrl}
                                    small
                                ></CategoryImage>
                            )}
                            {allowedCategories instanceof Map && (
                                <span className="item-quantity" slot="after-title">
                                    {allowedCategories.get(category)}
                                </span>
                            )}
                            {category.children.length ? (
                                <IcArrowRight className="category-sheet__arrow" />
                            ) : (
                                choosenCategories?.includes(category) && (
                                    <IcDone className="category-sheet__check-mark" />
                                )
                            )}
                        </ListItem>
                    ),
            )}
        </List>
    );
};

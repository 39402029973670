import cn from 'classnames';
import { List, ListItem } from 'framework7-react';
import React from 'react';

import { SaleOptionsProps } from './SaleOptions.types';

import { IcDone, IcPercent } from '@/assets';

import './SaleOptions.less';

export const SaleOptions = ({ saleList, selectedSales, onSaleClick }: SaleOptionsProps) => (
    <List noHairlinesBetween noHairlines noChevron className={cn('category-sheet__options-list', 'sale-options')}>
        {saleList?.map((item) => (
            <ListItem key={item.uid} link title={item.name} onClick={() => onSaleClick(item)}>
                <IcPercent slot="media" />
                {selectedSales?.includes(item) && <IcDone className="category-sheet__check-mark" />}
            </ListItem>
        ))}
    </List>
);

import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIcon } from '@/types/icons';

export const IcOnboardingMessageSale: IcIcon = React.memo((props) => {
    const gradUid1 = useNodeUid();
    const gradUid2 = useNodeUid();
    const gradUid3 = useNodeUid();
    const gradUid4 = useNodeUid();
    const gradUid5 = useNodeUid();
    const gradUid6 = useNodeUid();

    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill={`url(#${gradUid1})`}
            />
            <path
                d="M18.1683 30.5226C16.3716 21.5296 11.5993 14.3914 4.91807 9.44531C0.0896159 15.7966 -1.48244 24.4523 1.54938 32.4336C5.08651 41.7076 13.6767 47.553 22.9406 47.9465C20.5825 42.3259 19.1227 36.1432 18.1683 30.5226Z"
                fill={`url(#${gradUid2})`}
            />
            <path
                d="M29.954 0.785156C30.6839 1.68445 31.3577 2.58375 31.9753 3.53926C39.8917 15.6236 33.3227 29.2817 32.7051 42.3215C32.5928 43.6142 32.5928 44.9632 32.8174 46.3121C45.0009 41.5346 51.0645 27.8203 46.4606 15.5112C43.5411 7.92333 37.1967 2.63996 29.954 0.785156Z"
                fill={`url(#${gradUid3})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.4916 15.5084C33.9472 15.964 33.9472 16.7027 33.4916 17.1583L17.1583 33.4916C16.7027 33.9472 15.964 33.9472 15.5084 33.4916C15.0528 33.036 15.0528 32.2973 15.5084 31.8417L31.8417 15.5084C32.2973 15.0528 33.036 15.0528 33.4916 15.5084Z"
                fill={`url(#${gradUid4})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0833 16.3333C17.1168 16.3333 16.3333 17.1168 16.3333 18.0833C16.3333 19.0498 17.1168 19.8333 18.0833 19.8333C19.0498 19.8333 19.8333 19.0498 19.8333 18.0833C19.8333 17.1168 19.0498 16.3333 18.0833 16.3333ZM14 18.0833C14 15.8282 15.8282 14 18.0833 14C20.3385 14 22.1667 15.8282 22.1667 18.0833C22.1667 20.3385 20.3385 22.1667 18.0833 22.1667C15.8282 22.1667 14 20.3385 14 18.0833Z"
                fill={`url(#${gradUid5})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.9167 29.1667C29.9502 29.1667 29.1667 29.9502 29.1667 30.9167C29.1667 31.8832 29.9502 32.6667 30.9167 32.6667C31.8832 32.6667 32.6667 31.8832 32.6667 30.9167C32.6667 29.9502 31.8832 29.1667 30.9167 29.1667ZM26.8333 30.9167C26.8333 28.6615 28.6615 26.8333 30.9167 26.8333C33.1718 26.8333 35 28.6615 35 30.9167C35 33.1718 33.1718 35 30.9167 35C28.6615 35 26.8333 33.1718 26.8333 30.9167Z"
                fill={`url(#${gradUid6})`}
            />
            <defs>
                <linearGradient
                    id={gradUid1}
                    x1="-13.5729"
                    y1="-9.18087"
                    x2="37.0408"
                    y2="35.5207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid2}
                    x1="-15.9401"
                    y1="19.7926"
                    x2="18.1034"
                    y2="30.3877"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid3}
                    x1="14.9347"
                    y1="7.26485"
                    x2="48.2843"
                    y2="36.6868"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient id={gradUid4} x1="24.5" y1="14" x2="24.5" y2="35" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--brand-primary-0)" />
                    <stop offset="1" stopColor="var(--brand-primary-10)" />
                </linearGradient>
                <linearGradient id={gradUid5} x1="24.5" y1="14" x2="24.5" y2="35" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--brand-primary-0)" />
                    <stop offset="1" stopColor="var(--brand-primary-10)" />
                </linearGradient>
                <linearGradient id={gradUid6} x1="24.5" y1="14" x2="24.5" y2="35" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--brand-primary-0)" />
                    <stop offset="1" stopColor="var(--brand-primary-10)" />
                </linearGradient>
            </defs>
        </svg>
    );
});
IcOnboardingMessageSale.displayName = 'IcOnboardingMessageSale';

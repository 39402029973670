import {
    ContactSupportActions,
    MESSAGE_FILE_ATTACHING,
    MESSAGE_FILE_ATTACHING_ERROR,
    MESSAGE_FILE_ATTACHING_SUCCESS,
    MESSAGE_FILE_DETACHING_SUCCESS,
    MESSAGE_FILES_CLEAR,
    MESSAGE_SENDING,
    MESSAGE_SENDING_ERROR,
    MESSAGE_SENDING_SUCCESS,
} from '@/actions/contactSupportActions';

export interface SupportMessage {
    email?: string;
    subject?: string;
    message?: string;
    parameters?: Record<string, string>;
}

export interface IUploadedFileInfo {
    imageLink?: string;
    file?: File;
}

export interface IContactSupportState {
    sending?: boolean;
    error?: unknown;
    fileAttaching?: boolean;
    fileAttachingError?: unknown;
    files?: IUploadedFileInfo[];
}

const initialState: IContactSupportState = {
    sending: false,
    error: undefined,
    fileAttaching: false,
    fileAttachingError: undefined,
    files: [],
};

const profileReducer = (state = initialState, action: ContactSupportActions): IContactSupportState => {
    switch (action.type) {
        case MESSAGE_SENDING:
            return {
                ...state,
                sending: true,
                error: undefined,
            };
        case MESSAGE_SENDING_SUCCESS:
            return {
                ...state,
                sending: false,
                error: undefined,
            };
        case MESSAGE_SENDING_ERROR:
            return {
                ...state,
                sending: false,
                error: action.error,
            };
        case MESSAGE_FILE_ATTACHING:
            return {
                ...state,
                fileAttaching: true,
                fileAttachingError: undefined,
            };
        case MESSAGE_FILE_ATTACHING_SUCCESS: {
            const { imageLink, file } = action;

            return {
                ...state,
                fileAttaching: false,
                fileAttachingError: undefined,
                files: [
                    ...state.files,
                    {
                        imageLink,
                        file,
                    },
                ],
            };
        }
        case MESSAGE_FILE_ATTACHING_ERROR:
            return {
                ...state,
                fileAttaching: false,
                fileAttachingError: action.error,
            };
        case MESSAGE_FILE_DETACHING_SUCCESS:
            return {
                ...state,
                fileAttaching: false,
                fileAttachingError: undefined,
                files: state.files.filter((_, index) => action.index !== index),
            };
        case MESSAGE_FILES_CLEAR:
            return {
                ...state,
                fileAttaching: false,
                fileAttachingError: undefined,
                files: [],
            };
        default:
            return state;
    }
};

export default profileReducer;

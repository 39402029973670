export enum BannerLabel {
    PROMOTION = 'promotion',
    CATEGORY = 'category',
    HOME_BIG = 'homebig',
    HOME_SMALL = 'homesmall',
    PARTNERS = 'partners',
}

export enum MarketBannerLabel {
    MAIN_FIRST_HORIZONTAL = 'main_first_horizontal',
    MAIN_SECOND_TWO = 'main_second_two',
    MAIN_THIRD_THREE = 'main_third_three',
}

import {
    AccountSettingsActions,
    GET_ACCOUNT_ERROR,
    GET_ACCOUNT_LOADING,
    GET_ACCOUNT_SUCCESS,
} from '@/actions/accountSettingsActions';
import { Account } from '@/generated/marketplaceapi';

export interface IAccountSettingsState {
    account: Account | undefined;
    accountLoading: boolean;
    error: unknown;
}

const initialState: IAccountSettingsState = {
    account: undefined,
    accountLoading: false,
    error: undefined,
};

const accountSettingsReducer = (state = initialState, action: AccountSettingsActions): IAccountSettingsState => {
    switch (action.type) {
        case GET_ACCOUNT_LOADING: {
            return {
                ...state,
                accountLoading: true,
            };
        }
        case GET_ACCOUNT_SUCCESS:
            return {
                ...state,
                account: action.account,
                accountLoading: false,
            };
        case GET_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.error,
                accountLoading: false,
            };
        default:
            return state;
    }
};

export default accountSettingsReducer;

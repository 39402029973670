import cn from 'classnames';
import { f7ready } from 'framework7-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CustomPopoverWrapperProps } from './CustomPopoverWrapper.types';

import { CustomPopover } from '@/components/CustomPopover';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useOuterClick } from '@/hooks/useOuterClick';
import { usePrevious } from '@/hooks/usePrevious';
import { noop } from '@/utils';

import './CustomPopoverWrapper.less';

export const CustomPopoverWrapper: React.FC<CustomPopoverWrapperProps<HTMLDivElement>> = ({
    popoverTriggerElement,
    children,
    closeByClickOutside = true,
    wrapperClassname,
    closeByClickInside,
    onPopoverContainerClick,
    slot,
    onTriggerClick,
    onPopoverClose,
    onPopoverOpen,
    disableScrollOnOpen = false,
    ...popoverProps
}) => {
    const [popoverOpened, setPopoverOpened] = useState(false);
    const [openPopover, closePopover, togglePopover] = useBooleanState(setPopoverOpened);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleTriggerButtonClick = useCallback(() => {
        togglePopover();
        onTriggerClick?.();
    }, [onTriggerClick, togglePopover]);

    useOuterClick(wrapperRef, closeByClickOutside ? closePopover : noop);

    const handlePopoverClick = useCallback(() => {
        onPopoverContainerClick?.();

        if (closeByClickInside) {
            closePopover();
        }
    }, [closeByClickInside, closePopover, onPopoverContainerClick]);

    const prevPopoverOpened = usePrevious(popoverOpened);

    useEffect(() => {
        if (popoverOpened) {
            onPopoverOpen?.();
        } else if (prevPopoverOpened) {
            onPopoverClose?.();
        }
    }, [onPopoverClose, onPopoverOpen, popoverOpened, prevPopoverOpened]);

    useEffect(() => {
        f7ready((f7) => {
            const pageContent = f7.$('.view-main .page-content');

            if (popoverOpened && disableScrollOnOpen) {
                pageContent.addClass('scroll-disabled');
            } else {
                pageContent.removeClass('scroll-disabled');
            }
        });
    }, [disableScrollOnOpen, popoverOpened]);

    return (
        <div slot={slot} className={cn('custom-popover-wrapper-container', wrapperClassname)} ref={wrapperRef}>
            {popoverTriggerElement(handleTriggerButtonClick, popoverOpened)}
            {popoverOpened && (
                <CustomPopover
                    onPopoverContainerClick={handlePopoverClick}
                    opened={popoverOpened}
                    {...popoverProps}
                    parentRef={wrapperRef}
                >
                    {children({ closePopover, openPopover, togglePopover })}
                </CustomPopover>
            )}
        </div>
    );
};

import { Dom7 } from 'framework7';
import { f7ready } from 'framework7-react';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { presearchProducts } from '@/actions/productActions';
import { AppDispatch, useAppDispatch, useAppSelector } from '@/hooks/store';
import { useOuterClick } from '@/hooks/useOuterClick';
import { getIsMobile } from '@/selectors/getIsMobile';
import { noop } from '@/utils';

type useAppNavbarSearchProps = {
    showSearchbarToggle: boolean;
    showSearchbar: boolean;
    onSearchInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchbarEnable?: () => void;
    onSearchbarDisable?: () => void;
    onSearchClickClear?: () => void;
    storeUid: string | undefined;
};

export const useAppNavbarSearchbar = ({
    showSearchbar,
    showSearchbarToggle,
    onSearchInputChange = noop,
    onSearchbarDisable = noop,
    onSearchbarEnable = noop,
    onSearchClickClear = noop,
    storeUid,
}: useAppNavbarSearchProps) => {
    const dispatch = useAppDispatch();
    const [searchBarFocus, setSearchBarFocus] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchbarEnable = useCallback(() => {
        onSearchbarEnable();
        setSearchBarFocus(true);
    }, [onSearchbarEnable]);

    const handleSearchbarDisable = useCallback(() => {
        onSearchbarDisable();
        setSearchBarFocus(false);
    }, [onSearchbarDisable]);

    const disableSearchbarByOuterClick = useCallback(() => {
        if (searchBarFocus) {
            handleSearchbarDisable();
        }
    }, [handleSearchbarDisable, searchBarFocus]);

    const toggleSearchbarInclusion = useMemo(
        () =>
            searchBarFocus
                ? handleSearchbarDisable
                : (e: MouseEvent) => {
                      e.stopPropagation();
                      handleSearchbarEnable();
                  },
        [handleSearchbarDisable, handleSearchbarEnable, searchBarFocus],
    );

    const handleSearchbarChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onSearchInputChange(e);
            setSearchValue(e.target.value);
        },
        [onSearchInputChange],
    );

    const searchbarContainerRef = useRef<HTMLDivElement>(null);
    useOuterClick(searchbarContainerRef, disableSearchbarByOuterClick);

    const delayedQuery = useMemo(
        () =>
            debounce((dispatch: AppDispatch, val: string, storeUid: string) => {
                dispatch(presearchProducts(val, storeUid));
            }, 500),
        [],
    );

    useEffect(() => {
        if (searchValue) {
            delayedQuery(dispatch, searchValue, storeUid);
        }
    }, [delayedQuery, dispatch, searchValue, storeUid]);

    const searchbarDisplayed = showSearchbarToggle ? showSearchbar && searchBarFocus : showSearchbar;

    return {
        searchbarProps: {
            onSearchbarEnable: handleSearchbarEnable,
            onSearchbarDisable: handleSearchbarDisable,
            onChange: handleSearchbarChange,
            onClickClear: onSearchClickClear,
            searchBarFocus: searchBarFocus,
        },
        searchbarContainerRef,
        toggleSearchbarInclusion,
        searchbarDisplayed,
        searchBarFocus,
        searchValue,
    };
};

export const useAppNavbarTopNavbar = () => {
    const isMobile = useAppSelector(getIsMobile);

    const [topNavbarVisible, setTopNavbarVisible] = useState(true);

    useEffect(() => {
        let prevScrollPos = 0;
        let pageContent;

        const handleScroll = (e: Event) => {
            const isPageScroll = Dom7(e.target)?.closest('.view-main');

            if (isMobile || !isPageScroll) return;

            const currentScrollPos = (e.target as unknown as HTMLElement).scrollTop;

            setTopNavbarVisible(prevScrollPos > currentScrollPos);

            prevScrollPos = currentScrollPos;
        };

        f7ready((f7) => {
            pageContent = f7.$('.view-main .page-content');

            prevScrollPos = pageContent.scrollTop();

            pageContent.on('scroll', handleScroll);
        });

        return () => f7ready(() => pageContent.off('scroll', handleScroll));
    }, [isMobile]);

    return topNavbarVisible;
};

import { f7 } from 'framework7-react';
import { useCallback, useEffect, useMemo } from 'react';

import { cartAllowSelectAll } from '@/actions/cartActions';
import { logoutUser, refreshAuthentication } from '@/actions/sessionActions';
import { messengerService } from '@/api/messengerService';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useInterval } from '@/hooks/useInterval';
import { isLoggedIn } from '@/selectors/isLoggedIn';

// ? authentication executes in F7App.tsx
export const AuthManager = () => {
    const dispatch = useAppDispatch();

    const logged = useAppSelector(isLoggedIn);

    const { expiresIn } = useAppSelector((state) => state.sessionReducer);

    const refreshInterval = useMemo(() => (typeof expiresIn !== 'number' ? 1000 : (expiresIn / 2) * 1000), [expiresIn]);

    const handleAuthRefresh = useCallback(() => {
        if (logged) {
            dispatch(refreshAuthentication())
                .then(isLoggedIn)
                .then((logged) => {
                    if (!logged) {
                        dispatch(logoutUser());
                        f7.view.main.router.navigate('/', { reloadCurrent: true, force: true });
                        if (messengerService.enabled) {
                            messengerService.reload();
                            messengerService.hideLoadingScreen();
                        }
                    }
                });
        }
    }, [dispatch, logged]);

    useInterval(handleAuthRefresh, refreshInterval);

    // ? Allow to force select all items from cart fetched from api
    useEffect(() => {
        dispatch(cartAllowSelectAll());
    }, [dispatch, logged]);

    return null;
};

import cn from 'classnames';
import { Chip, Link } from 'framework7-react';
import React, { useMemo } from 'react';

import { HashtagLinksProps } from './HashtagLinks.types';

import './HashtagLinks.less';

export const HashtagLinks = React.memo<HashtagLinksProps>(({ productHashtags, className }) => {
    const tags = useMemo(() => productHashtags.split(',').filter(Boolean), [productHashtags]);

    return (
        <div className={className}>
            <div className={cn('hashtag-links')}>
                {tags.map((tag) => (
                    <Link className="hashtag-links__link" key={tag} href={`/search/?hashtag=${tag}`}>
                        <Chip text={`#${tag}`} />
                    </Link>
                ))}
            </div>
        </div>
    );
});
HashtagLinks.displayName = 'HashtagLinks';

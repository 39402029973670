import { messengerService } from '@/api/messengerService';
import { ProductType } from '@/types/productType';
import { EProductTypeQuery } from '@/types/URLParams';

export const getQueryByProductType = (productType: ProductType) => {
    switch (productType) {
        case ProductType.Advertisement:
            return EProductTypeQuery.OFFERS;
        case ProductType.Service:
            return EProductTypeQuery.SERVICES;
        default:
            return messengerService.enabled ? EProductTypeQuery.OFFERS : EProductTypeQuery.MARKET;
    }
};

import classNames from 'classnames';
import { Button } from 'framework7-react';
import React from 'react';

import { OpenCategoriesButtonProps } from './OpenCategoriesButton.types';

import { IcAllCategories } from '@/assets';

import './OpenCategoriesButton.less';

export const OpenCategoriesButton = ({ className, text, opened, ...props }: OpenCategoriesButtonProps) => (
    <Button round className={classNames('no-ripple', 'open-categories-button', { opened }, className)} {...props}>
        <IcAllCategories />
        <span>{text}</span>
    </Button>
);

import cn from 'classnames';
import { f7 } from 'framework7-react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { CatalogListItemProps } from './CatalogListItem.types';

import { IcQuestion } from '@/assets';
import { ProductStatusBadge } from '@/components/Badges';
import { Price } from '@/components/Price';
import { AnalyticDetails } from '@/components/ProductDetails';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { ProductType } from '@/types/productType';
import { createUUID, formatDate, noop } from '@/utils';

import './CatalogListItem.less';

export const CatalogListItem: React.FC<CatalogListItemProps> = React.memo(
    ({ showErrors, item, showCheckbox, onItemSelect, selected, onItemClick, archive }) => {
        const { t } = useTranslation();

        const activeType = useAppSelector(getActiveProductType);

        const handleItemSelect = useCallback(() => {
            onItemSelect(item.uid);
        }, [item?.uid, onItemSelect]);

        const handleItemClick = useCallback(() => {
            onItemClick(item?.uid);
        }, [item?.uid, onItemClick]);

        const tooltipTargetSelector = useMemo(() => createUUID().replace(/-/g, '_').replace(/\d/g, 'q'), []);

        const { Sale_Error } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);

        const errorMessage = useMemo(
            () => (item.error ? Sale_Error?.find((err) => err.code === item.error)?.value : undefined),
            [Sale_Error, item.error],
        );

        useEffect(() => {
            if (errorMessage) {
                f7.tooltip.create({
                    text: errorMessage,
                    targetEl: `.${tooltipTargetSelector}`,
                });

                return () => {
                    try {
                        f7.tooltip.destroy(`.${tooltipTargetSelector}`);
                    } catch (err) {
                        console.error(err);
                    }
                };
            }
            return undefined;
        }, [errorMessage, tooltipTargetSelector]);

        const withError = !!item.error && showErrors;

        return (
            <tr
                className={cn('catalog-list-row', {
                    'with-error': withError,
                    'show-error': showErrors,
                })}
                key={item.uid}
            >
                {showCheckbox && (
                    <td className="catalog-list-row-checkbox checkbox-cell">
                        <label className="checkbox" onChange={handleItemSelect}>
                            <input
                                type="checkbox"
                                disabled={withError}
                                checked={selected && !withError}
                                onChange={noop}
                            />
                            <i className="icon-checkbox" />
                        </label>
                    </td>
                )}
                <td
                    className={cn('catalog-list-row-media', 'label-cell', {
                        'with-checkbox': showCheckbox,
                    })}
                    onClick={handleItemClick}
                >
                    <div className="catalog-list-row-media-image">
                        {item.imageThumbnailUrl1 && <img src={item.imageThumbnailUrl1} alt={item.name} />}
                    </div>
                    <p className="catalog-list-row-media-name">{item.name}</p>
                    <div
                        className={cn('catalog-list-row-mob', {
                            'with-error': withError,
                        })}
                    >
                        {item.statusValue && (
                            <ProductStatusBadge statusText={item.statusValue} status={item.status} archived={archive} />
                        )}
                        <Price
                            size="small"
                            price={item.price}
                            negotiatedPrice={item.negotiatedPrice}
                            discountedPrice={item.discountedPrice}
                            currencyCode={item.currencyCode}
                            period={item?.rentOptions?.period}
                        />
                        <p className="catalog-list-row-mob__name">{item.name}</p>
                        <div className="catalog-list-row-mob-view-wish">
                            {activeType === ProductType.Product && (
                                <>
                                    <AnalyticDetails type="sold" count={item.soldItemsQuantity || 0} />
                                    <AnalyticDetails type="reserved" count={item.quantityReserved || 0} />
                                </>
                            )}
                            {activeType === ProductType.Service && (
                                <>
                                    <AnalyticDetails type="view" count={item.viewCount || 0} />
                                    <AnalyticDetails type="wish" count={item.wishCount || 0} />
                                </>
                            )}
                            {activeType === ProductType.Product && (
                                <div className="catalog-list-row-mob-view-wish-row">
                                    <span>{t('In stock')}: </span>
                                    <AnalyticDetails type="quantity" count={item.quantity} />
                                </div>
                            )}
                        </div>
                    </div>
                </td>
                <td className="catalog-list-row-price label-cell">
                    <Price
                        size="small"
                        price={item.price}
                        negotiatedPrice={item.negotiatedPrice}
                        discountedPrice={item.discountedPrice}
                        currencyCode={item.currencyCode}
                        period={item?.rentOptions?.period}
                    />
                </td>
                {activeType === ProductType.Product && (
                    <>
                        <td className="catalog-list-row-sold label-cell">
                            <AnalyticDetails type="sold" count={item.soldItemsQuantity || 0} />
                        </td>
                        <td className="catalog-list-row-reserved label-cell">
                            <AnalyticDetails type="reserved" count={item.quantityReserved || 0} />
                        </td>
                        <td className="catalog-list-row-quantity label-cell">
                            <AnalyticDetails type="quantity" count={item.quantity} />
                        </td>
                    </>
                )}
                {activeType === ProductType.Service && (
                    <>
                        <td className="catalog-list-row-sold label-cell">
                            <AnalyticDetails type="view" count={item.viewCount || 0} />
                        </td>
                        <td className="catalog-list-row-reserved label-cell">
                            <AnalyticDetails type="wish" count={item.wishCount || 0} />
                        </td>
                    </>
                )}
                <td className="catalog-list-row-date label-cell">
                    {item.productDate && formatDate(item.productDate.toString())}
                </td>
                <td className="catalog-list-row-status label-cell">
                    {item.status && (
                        <ProductStatusBadge statusText={item.statusValue} status={item.status} archived={archive} />
                    )}
                </td>
                {withError && <IcQuestion className={cn('error-tip', tooltipTargetSelector)} />}
            </tr>
        );
    },
);

CatalogListItem.displayName = 'CatalogListItem';

import cn from 'classnames';
import { f7, F7Searchbar, Searchbar } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './SellerAreaSearchbar.less';

export const SellerAreaSearchbar = ({ className, ...props }: F7Searchbar.Props) => {
    const { t } = useTranslation();

    return (
        <Searchbar
            className={cn('seller-area-searchbar', className)}
            backdrop={false}
            init
            customSearch
            disableButton={f7.theme !== 'aurora'}
            disableButtonText={t('Cancel')}
            placeholder={t('Search')}
            {...props}
        />
    );
};

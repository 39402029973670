import { Icon } from 'framework7-react';
import React, { useMemo } from 'react';

import { AnalyticDetailsProps } from './AnalyticDetails.types';

import './AnalyticDetails.less';

export const AnalyticDetails = React.memo(({ count, type }: AnalyticDetailsProps): JSX.Element => {
    const icon = useMemo(() => {
        switch (type) {
            case 'view':
                return 'ic-view';
            case 'wish':
                return 'ic-wish-small';
            case 'reserved':
                return 'ic-clock';
            case 'sold':
                return 'ic-cart-small';
            default:
                return null;
        }
    }, [type]);

    return (
        <div className="analytic-details">
            {icon && <Icon className={icon} />}
            <span>{count}</span>
        </div>
    );
});
AnalyticDetails.displayName = 'AnalyticDetails';

import cn from 'classnames';
import { Col, Row } from 'framework7-react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CategoryMapping } from './CategoryMapping';
import { ConfigureImport } from './ConfigureImport';
import { DesktopStepsMenu } from './DesktopStepsMenu';
import { ImportLog } from './ImportLog/ImportLog';
import { defaultValidSteps, importProductsDraftInitial } from './ImportProducts.constants';
import {
    ImportContentType,
    ImportProductsDraft,
    ImportProductsProps,
    ImportProductsSteps,
} from './ImportProducts.types';
import { importProductsDraftToProductSource, productSourceToImportProductsDraft } from './ImportProducts.utils';
import { ImportProductsMobileSteps } from './ImportProductsMobileSteps';
import { InitialStep } from './InitialStep';
import { NavbarInitial } from './NavbarInitial';
import { StepNavbar } from './StepNavbar';

import {
    createOrUpdateProductSourceDraft,
    createProductSource,
    deleteProductSource,
} from '@/actions/productSourceActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { LoadProductSource } from '@/hoc/LoadProductSource';
import { useAppSelector } from '@/hooks/store';
import { usePreloader } from '@/hooks/usePreloader';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { valueInRange } from '@/utils';

import './ImportProducts.less';

const ImportProductsInner: React.FC<ImportProductsProps> = ({ isEdit, f7router }) => {
    const isMobile = useAppSelector(getIsMobile);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const languageCode = useAppSelector(getLanguageCode);
    const { productSource, productSourceLoading, productSourceSaveLoading, sourceTypes } = useAppSelector(
        (state) => state.productSourceReducer,
    );

    const [step, setStep] = useState(isEdit ? ImportProductsSteps.CONFIGURE_IMPORT : ImportProductsSteps.INITIAL);
    const [validSteps, setValidSteps] = useState<Map<ImportProductsSteps, boolean>>(defaultValidSteps);
    const [importType, setImportType] = useState(ImportContentType.file);
    const [draftValues, setDraftValues] = useState<ImportProductsDraft>(
        productSource
            ? productSourceToImportProductsDraft(productSource, sourceTypes?.[0])
            : importProductsDraftInitial,
    );
    const [isProcessing, setIsProcessing] = useState(false);
    const stepsMode = useMemo(
        () => step > ImportProductsSteps.INITIAL && step < ImportProductsSteps.IMPORT_LOG,
        [step],
    );
    const isEditAndDeleteDisabled = useMemo(
        () =>
            productSourceSaveLoading ||
            !productSource ||
            (!productSource?.active && productSource?.contentType !== 'file'),
        [productSource, productSourceSaveLoading],
    );

    const showSideMenu = useMemo(
        () => !isMobile && step > ImportProductsSteps.INITIAL && step < ImportProductsSteps.IMPORT_LOG,
        [isMobile, step],
    );

    const nextButtonText = useMemo(
        () => (step === ImportProductsSteps.CATEGORY_MAPPING ? t('Start Import') : t('Next')),
        [step, t],
    );

    const stepChangeDisabled = useMemo(() => !validSteps.get(step), [step, validSteps]);

    const handleNextStep = useCallback(() => {
        switch (step) {
            case ImportProductsSteps.CONFIGURE_IMPORT: {
                dispatch(
                    createOrUpdateProductSourceDraft(
                        importProductsDraftToProductSource(draftValues, productSource?.languageCode ?? languageCode),
                        isEdit ?? !!productSource?.uid,
                        draftValues[ImportProductsSteps.CONFIGURE_IMPORT].file,
                    ),
                );
                setIsProcessing(true);
                return;
            }
            case ImportProductsSteps.CATEGORY_MAPPING: {
                dispatch(
                    createProductSource(
                        importProductsDraftToProductSource(
                            draftValues,
                            productSource?.languageCode ?? languageCode,
                            true,
                        ),
                    ),
                );
                setIsProcessing(true);
                f7router.navigate('/profile/seller-area/my-goods/import-products/', {
                    force: true,
                });
                return;
            }
        }
        setStep((prev) => prev + 1);
    }, [dispatch, draftValues, f7router, isEdit, languageCode, productSource?.languageCode, productSource?.uid, step]);

    const makeHandleValidity = useCallback(
        (step: ImportProductsSteps) => (validity: boolean) =>
            setValidSteps((prev) => new Map(prev.set(step, validity))),
        [],
    );

    const handleTypeChange = useCallback((type: ImportContentType) => setImportType(type), []);
    const updateDraft = useMemo(
        () =>
            debounce(
                (step: ImportProductsSteps, values: ImportProductsDraft[ImportProductsSteps]) =>
                    setDraftValues((prev) => (!isEqual(prev[step], values) ? { ...prev, [step]: values } : prev)),
                100,
            ),
        [],
    );

    const makeHandleDraftChange = useMemo(
        () => (step: ImportProductsSteps) => (values: ImportProductsDraft[keyof ImportProductsDraft]) =>
            updateDraft(step, values),
        [updateDraft],
    );

    const toggleSourceActive = useCallback(() => {
        dispatch(createOrUpdateProductSourceDraft({ ...productSource, active: !productSource?.active }, true));
    }, [dispatch, productSource]);

    const renderSteps = useMemo(() => {
        switch (step) {
            case ImportProductsSteps.INITIAL: {
                return <InitialStep onConfigureClick={handleNextStep} />;
            }
            case ImportProductsSteps.CONFIGURE_IMPORT: {
                return (
                    <ConfigureImport
                        handleDraftChange={makeHandleDraftChange(ImportProductsSteps.CONFIGURE_IMPORT)}
                        contentType={importType}
                        handleValidity={makeHandleValidity(step)}
                        onImportTypeChange={handleTypeChange}
                        draftValues={draftValues[ImportProductsSteps.CONFIGURE_IMPORT]}
                        isEdit={isEdit}
                    />
                );
            }
            case ImportProductsSteps.CATEGORY_MAPPING: {
                return (
                    <CategoryMapping
                        draftValues={draftValues[ImportProductsSteps.CATEGORY_MAPPING]}
                        handleValidity={makeHandleValidity(step)}
                        handleDraftChange={makeHandleDraftChange(ImportProductsSteps.CATEGORY_MAPPING)}
                    />
                );
            }
            case ImportProductsSteps.IMPORT_LOG: {
                return (
                    <ImportLog
                        logFields={draftValues[ImportProductsSteps.IMPORT_LOG]}
                        isProcessing={isProcessing}
                        onToggleActiveClick={toggleSourceActive}
                    />
                );
            }
        }
    }, [
        draftValues,
        handleNextStep,
        handleTypeChange,
        importType,
        isEdit,
        isProcessing,
        makeHandleDraftChange,
        makeHandleValidity,
        step,
        toggleSourceActive,
    ]);

    const handleDelete = useCallback(() => {
        dispatch(deleteProductSource(productSource?.uid));
    }, [dispatch, productSource?.uid]);

    const handleEditClick = useCallback(() => f7router.navigate('./edit/'), [f7router]);

    const handleCloseImportClick = useCallback(() => {
        isEdit
            ? f7router.navigate('/profile/seller-area/my-goods/import-products/', { force: true })
            : setStep(ImportProductsSteps.INITIAL);
    }, [f7router, isEdit]);

    const handlePrevStep = useCallback(
        () =>
            setStep((prev) =>
                valueInRange(ImportProductsSteps.CONFIGURE_IMPORT, ImportProductsSteps.CATEGORY_MAPPING, prev - 1),
            ),
        [],
    );

    usePreloader(productSourceLoading);

    useEffect(() => firebaseAnalyticsInstance.openImportProductsPage?.(), []);

    useEffect(() => {
        setDraftValues(
            productSource
                ? productSourceToImportProductsDraft(productSource, sourceTypes?.[0])
                : importProductsDraftInitial,
        );
    }, [productSource, sourceTypes]);

    useEffect(() => {
        if (step === ImportProductsSteps.INITIAL && productSource?.uid) {
            setStep(ImportProductsSteps.IMPORT_LOG);
        } else if (step === ImportProductsSteps.IMPORT_LOG && !productSource?.uid && !productSourceLoading) {
            setStep(ImportProductsSteps.INITIAL);
        }
    }, [productSource?.uid, productSourceLoading, step]);

    useEffect(() => {
        if (validSteps.get(step) && !productSourceLoading && isProcessing) {
            setIsProcessing(false);
            setStep((prev) => prev + 1);
        }
    }, [isProcessing, productSourceLoading, step, validSteps]);

    useEffect(() => {
        if (
            isProcessing &&
            !productSourceLoading &&
            JSON.parse(productSource?.currentContent?.errorMessage ?? '{}')?.errors
        )
            setIsProcessing(false);
    }, [isProcessing, productSource?.currentContent?.errorMessage, productSourceLoading]);

    return (
        <CustomPage loggedOnly name="import-products" className="import-products-page">
            <LoadProductSource />
            <StepNavbar
                title={isEdit ? 'Edit' : isMobile ? '' : 'New Import'}
                showNextButton={!isMobile}
                isLastStep={step === ImportProductsSteps.CATEGORY_MAPPING}
                changeStepDisabled={stepChangeDisabled}
                onNextButtonClick={handleNextStep}
                onCloseClick={handleCloseImportClick}
                show={stepsMode}
            />
            <NavbarInitial
                onImportHistoryClick={() => f7router.navigate('./history/')}
                showMoreButton={!isMobile || step === ImportProductsSteps.IMPORT_LOG}
                slot="fixed"
                editDisabled={isEditAndDeleteDisabled}
                deleteDisabled={isEditAndDeleteDisabled}
                onDeleteClick={handleDelete}
                showDelete={step === ImportProductsSteps.IMPORT_LOG}
                showEdit={step === ImportProductsSteps.IMPORT_LOG}
                onEditClick={handleEditClick}
                show={!stepsMode}
            />

            <Row className={cn('import-products-page-container', { 'with-menu': showSideMenu })}>
                {showSideMenu && (
                    <DesktopStepsMenu currentStep={step} setCurrentStep={setStep} validSteps={validSteps} />
                )}
                <Col className="import-products-page-content">{renderSteps}</Col>
            </Row>
            {stepsMode && isMobile && (
                <ImportProductsMobileSteps
                    onNextStep={handleNextStep}
                    step={step}
                    stepChangeDisabled={stepChangeDisabled}
                    onPrevStep={handlePrevStep}
                    nextButtonText={nextButtonText}
                />
            )}
        </CustomPage>
    );
};

export const ImportProducts = ConnectPageWrapper(ImportProductsInner);

import cn from 'classnames';
import React from 'react';

import { InputButtonProps } from './InputButton.types';

import { IcSelect } from '@/assets';
import { CustomInput } from '@/components/CustomInput';

import './InputButton.less';

export const Input = ({
    value,
    placeholder,
    onClick,
    classNames,
    single,
    isAnimated,
    disabled = false,
    withArrow,
    required,
    ...props
}: InputButtonProps): JSX.Element => (
    <div className="datepicker-multi__input-button-wrapper" onClick={disabled ? undefined : onClick}>
        <CustomInput
            {...props}
            className={cn('datepicker-multi__input-button', classNames, { 'not-empty': !!value })}
            name={placeholder}
            label={placeholder}
            floatingLabel
            readonly
            value={value ?? ''}
            disabled={disabled}
            requiredSign={required}
        >
            {(single || withArrow) && (
                <IcSelect slot="inner-end" className={cn('input-button-arrow', { 'arrow-active': isAnimated })} />
            )}
        </CustomInput>
    </div>
);

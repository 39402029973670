import { F7Popup } from 'framework7-react';

export type LoginPopupProps = F7Popup.Props & {
    onRegister: () => void;
    onForgotPassword?: () => void;
    doAfterLogin?: () => void;
};

export enum LoginOptions {
    BY_EMAIL,
    BY_PHONE,
}

export type LoginValues = {
    email?: string;
    password: string;
};

export type LoginErrors = Partial<Record<keyof LoginValues, string | undefined>>;

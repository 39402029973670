import { TreeviewItem } from 'framework7-react';
import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { SubCategoryItemProps } from './SubCategoryItem.types';

import { getCategoryTranslateKey } from '@/utils';

import './SubCategoryItem.less';

const TreeviewLastChildItem = ({
    category,
    closeAccordion,
    onBlur,
    onChange,
    onFocus,
    selectedValue,
}: SubCategoryItemProps): JSX.Element => {
    const { t } = useTranslation();

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
            closeAccordion();
        },
        [closeAccordion, onChange],
    );

    return (
        <label>
            <TreeviewItem
                label={t(getCategoryTranslateKey(category))}
                selectable
                selected={selectedValue === category.categoryCode}
            />
            <input
                value={category.categoryCode}
                type="radio"
                className="subcategory-item__input"
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleChange}
                checked={selectedValue === category.categoryCode}
            />
        </label>
    );
};

export const SubCategoryItem = ({
    category,
    closeAccordion,
    onBlur,
    onChange,
    onFocus,
    selectedValue,
}: SubCategoryItemProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            {category?.children?.length ? (
                <TreeviewItem label={t(getCategoryTranslateKey(category))} itemToggle>
                    {category.children.map((child) => (
                        <SubCategoryItem
                            key={child.categoryName}
                            category={child}
                            closeAccordion={closeAccordion}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onChange={onChange}
                            selectedValue={selectedValue}
                        />
                    ))}
                </TreeviewItem>
            ) : (
                <TreeviewLastChildItem
                    key={category.categoryName}
                    category={category}
                    closeAccordion={closeAccordion}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    selectedValue={selectedValue}
                />
            )}
        </>
    );
};

import { createSlice } from '@reduxjs/toolkit';

export interface IRegistrationState {
    isRegistrationPopupOpen: boolean;
    isBusinessRegistrationPopupOpen: boolean;
    isSwitchToBusinessRegistrationPopupOpen: boolean;
    isLoginPopupOpen: boolean;
    isCreateServiceProviderPopupOpen: boolean;
}

const initialState: IRegistrationState = {
    isRegistrationPopupOpen: false,
    isBusinessRegistrationPopupOpen: false,
    isSwitchToBusinessRegistrationPopupOpen: false,
    isLoginPopupOpen: false,
    isCreateServiceProviderPopupOpen: false,
};

const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        openRegistrationPopup: (state) => {
            state.isRegistrationPopupOpen = true;
        },
        closeRegistrationPopup: (state) => {
            state.isRegistrationPopupOpen = false;
        },
        openBusinessRegistrationPopup: (state) => {
            state.isBusinessRegistrationPopupOpen = true;
        },
        closeBusinessRegistrationPopup: (state) => {
            state.isBusinessRegistrationPopupOpen = false;
        },
        openSwitchToBusinessRegistrationPopup: (state) => {
            state.isSwitchToBusinessRegistrationPopupOpen = true;
        },
        closeSwitchToBusinessRegistrationPopup: (state) => {
            state.isSwitchToBusinessRegistrationPopupOpen = false;
        },
        openLoginPopup: (state) => {
            state.isLoginPopupOpen = true;
        },
        closeLoginPopup: (state) => {
            state.isLoginPopupOpen = false;
        },
        openCreateServiceProviderPopup: (state) => {
            state.isCreateServiceProviderPopupOpen = true;
        },
        closeCreateServiceProviderPopup: (state) => {
            state.isCreateServiceProviderPopupOpen = false;
        },
    },
});

export const {
    openRegistrationPopup,
    closeRegistrationPopup,
    openBusinessRegistrationPopup,
    closeBusinessRegistrationPopup,
    openSwitchToBusinessRegistrationPopup,
    closeSwitchToBusinessRegistrationPopup,
    openLoginPopup,
    closeLoginPopup,
    openCreateServiceProviderPopup,
    closeCreateServiceProviderPopup,
} = registrationSlice.actions;

export default registrationSlice.reducer;

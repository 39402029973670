import { FocusedInput } from '@datepicker-react/hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRangePickerProps } from './DateRangePicker.types';

import { MultiInput } from './MultiInput';

import { formatDate } from './utils';

import { Picker } from '@/components/DatePickers/Picker';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useOuterClick } from '@/hooks/useOuterClick';
import { noop } from '@/utils';

export const DateRangePicker = ({
    startDate,
    endDate,
    startDateCb,
    endDateCb,
    singleInput,
    isMobile,
    startDatePlaceholder = 'Starts',
    endDatePlaceholder = 'Ends',
    className = 'date-range-picker',
    minAvailableDateOffset = 0,
    disabled = false,
    datePerInput = false,
    withArrow,
    visibleMonthForce,
    withoutApplyButton,
    secondInputDisabled,
    required,
    inputStyle,
    withoutNegativeLimit,
    onApply,
    fitLabelLength,
}: DateRangePickerProps): JSX.Element => {
    const { t } = useTranslation();

    const [focus, setFocus] = useState<FocusedInput>('startDate');
    const [isCalendarExpanded, setIsCalendarExpanded] = useState(false);
    const [openCalendar, closeCalendar] = useBooleanState(setIsCalendarExpanded);

    const handleApply = useCallback(() => {
        onApply?.();
        closeCalendar();
    }, [closeCalendar, onApply]);

    const pickerTitle = useMemo(
        () => (datePerInput ? (focus === 'endDate' ? endDatePlaceholder : startDatePlaceholder) : undefined),
        [endDatePlaceholder, focus, datePerInput, startDatePlaceholder],
    );

    const minBookingDate = useMemo(
        () =>
            withoutNegativeLimit
                ? undefined
                : datePerInput && focus === 'endDate' && startDate
                ? startDate
                : new Date(Date.now() + minAvailableDateOffset * 1000 * 60 * 60 * 24),
        [withoutNegativeLimit, datePerInput, focus, startDate, minAvailableDateOffset],
    );

    const maxBookingDate = useMemo(
        () => (datePerInput && focus === 'startDate' && endDate ? endDate : undefined),
        [endDate, focus, datePerInput],
    );

    const initialVisibleMonth = useMemo(
        () => (datePerInput && focus === 'endDate' ? endDate ?? startDate : startDate),
        [endDate, focus, datePerInput, startDate],
    );

    const containerRef = useRef<HTMLDivElement>(null);

    useOuterClick(containerRef, isMobile ? noop : closeCalendar);

    return (
        <div ref={containerRef}>
            <MultiInput
                styleType={inputStyle}
                required={required}
                secondInputDisabled={secondInputDisabled}
                value1={startDate === null ? '' : formatDate(startDate)}
                placeholder1={t(startDatePlaceholder)}
                value2={endDate === null ? '' : formatDate(endDate)}
                placeholder2={t(endDatePlaceholder)}
                focusCb={setFocus}
                single={singleInput}
                expandCalendar={setIsCalendarExpanded}
                isAnimated={isCalendarExpanded}
                disabled={disabled}
                withArrow={withArrow}
                fitLabelLength={fitLabelLength}
            />
            <Picker
                onPopoverContainerClick={withoutApplyButton ? closeCalendar : undefined}
                withoutApplyButton={withoutApplyButton}
                component={isMobile ? 'sheet' : 'popover'}
                startDateState={datePerInput && focus !== 'startDate' ? [endDate, endDateCb] : [startDate, startDateCb]}
                endDateState={datePerInput && focus !== 'endDate' ? [startDate, startDateCb] : [endDate, endDateCb]}
                focus={focus}
                setFocus={setFocus}
                opened={isCalendarExpanded}
                isMobile={isMobile}
                className={className}
                singleDate={datePerInput}
                pickerTitle={pickerTitle}
                minBookingDate={minBookingDate}
                maxBookingDate={maxBookingDate}
                initialVisibleMonth={initialVisibleMonth}
                onSheetOpen={openCalendar}
                onSheetClose={closeCalendar}
                onPopupOpen={openCalendar}
                onPopupClose={closeCalendar}
                visibleMonthForce={visibleMonthForce}
                onApply={handleApply}
            />
        </div>
    );
};

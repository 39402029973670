import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    AccountAddressWsControllerApi,
    AccountRegionWsControllerApi,
    AccountWsControllerApi,
    Address,
    RegionAgreement,
} from '@/generated/commonapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { Profile } from '@/reducers/sessionReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';

export const ACCOUNT_ADD_UPDATE_ADDRESS = 'ACCOUNT_ADD_UPDATE_ADDRESS' as const;
export const ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS = 'ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS' as const;
export const ACCOUNT_ADD_UPDATE_ADDRESS_ERROR = 'ACCOUNT_ADD_UPDATE_ADDRESS_ERROR' as const;

export const ACCOUNT_REMOVE_ADDRESS = 'ACCOUNT_REMOVE_ADDRESS' as const;
export const ACCOUNT_REMOVE_ADDRESS_SUCCESS = 'ACCOUNT_REMOVE_ADDRESS_SUCCESS' as const;
export const ACCOUNT_REMOVE_ADDRESS_ERROR = 'ACCOUNT_REMOVE_ADDRESS_ERROR' as const;

export const ACCOUNT_AGREE_TERMS_LOADING = 'ACCOUNT_AGREE_TERMS_LOADING' as const;
export const ACCOUNT_AGREE_TERMS_SUCCESS = 'ACCOUNT_AGREE_TERMS_SUCCESS' as const;
export const ACCOUNT_AGREE_TERMS_ERROR = 'ACCOUNT_AGREE_TERMS_ERROR' as const;

export const ACCOUNT_AGREEMENTS_LOADING = 'ACCOUNT_AGREEMENTS_LOADING' as const;
export const ACCOUNT_AGREEMENTS_LOADING_SUCCESS = 'ACCOUNT_AGREEMENTS_LOADING_SUCCESS' as const;
export const ACCOUNT_AGREEMENTS_LOADING_ERROR = 'ACCOUNT_AGREEMENTS_LOADING_ERROR' as const;

// ? Not for actions
export const TC_AGREEMENT_SETTINGS_KEY = 'marketTermsAgreed' as const;

const addOrUpdateAddressAction = () => ({
    type: ACCOUNT_ADD_UPDATE_ADDRESS,
});

const addOrUpdateAddressSuccessAction = (addresses: Address[]) => ({
    type: ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS,
    addresses,
});

const addOrUpdateAddressErrorAction = (error: unknown) => ({
    type: ACCOUNT_ADD_UPDATE_ADDRESS_ERROR,
    error,
});

const removeAddressAction = () => ({
    type: ACCOUNT_REMOVE_ADDRESS,
});

const removeAddressSuccessAction = (addresses: Address[]) => ({
    type: ACCOUNT_REMOVE_ADDRESS_SUCCESS,
    addresses,
});

const removeAddressErrorAction = (error: unknown) => ({
    type: ACCOUNT_REMOVE_ADDRESS_ERROR,
    error,
});

const agreeTermsLoadingAction = () => ({
    type: ACCOUNT_AGREE_TERMS_LOADING,
});

const agreeTermsSuccessAction = (profile: Profile) => ({
    type: ACCOUNT_AGREE_TERMS_SUCCESS,
    profile,
});

const agreeTermsErrorAction = (error: unknown) => ({
    type: ACCOUNT_AGREE_TERMS_ERROR,
    error,
});

const accountAgreementsLoading = () => ({
    type: ACCOUNT_AGREEMENTS_LOADING,
});

const accountAgreementsLoadingSuccess = (agreements: RegionAgreement[]) => ({
    type: ACCOUNT_AGREEMENTS_LOADING_SUCCESS,
    agreements,
});

const accountAgreementsLoadingError = (error: unknown) => ({
    type: ACCOUNT_AGREEMENTS_LOADING_ERROR,
    error,
});

export type ProfileActions = ReturnType<
    | typeof addOrUpdateAddressAction
    | typeof addOrUpdateAddressSuccessAction
    | typeof addOrUpdateAddressErrorAction
    | typeof removeAddressAction
    | typeof removeAddressSuccessAction
    | typeof removeAddressErrorAction
    | typeof accountAgreementsLoading
    | typeof accountAgreementsLoadingSuccess
    | typeof accountAgreementsLoadingError
    | typeof agreeTermsLoadingAction
    | typeof agreeTermsSuccessAction
    | typeof agreeTermsErrorAction
>;

export const addOrUpdateAddress =
    (address: Address, update = false) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(addOrUpdateAddressAction());
        if (update) {
            try {
                const response = await new AccountAddressWsControllerApi().updateAddressUsingPOST({
                    address: {
                        city: address.city,
                        countryCode: address.country.code,
                        firstAddressLine: address.firstAddressLine,
                        postalCode: address.postalCode,
                        secondAddressLine: address.secondAddressLine || '',
                        state: address.state || '',
                        name: address.name,
                        surname: address.surname,
                        email: address.email,
                        phoneCountryCode: address.phoneCountryCode,
                        phoneNumber: address.phoneNumber,
                        uid: address.uid,
                    },
                    primary: false,
                    type: 'O',
                });

                handleResponseAndThrowAnErrorIfExists(response);

                const prevAddresses = getProfile(getState()).addresses || [];

                const newAddresses = response.account.addresses || [];

                const [newAddress] = newAddresses.filter(
                    (prevAddr) => !prevAddresses.find(({ uid }) => uid === prevAddr.uid),
                );

                dispatch(addOrUpdateAddressSuccessAction(newAddresses));
                return { state: getState(), newAddress };
            } catch (err) {
                dispatch(addOrUpdateAddressErrorAction(err.message));
                console.error('at profileActions in addOrUpdateAddress', err);
                return { state: getState() };
            }
        } else {
            try {
                const response = await new AccountAddressWsControllerApi().addAddressUsingPUT({
                    address: {
                        city: address.city,
                        countryCode: address.country?.code,
                        firstAddressLine: address.firstAddressLine,
                        postalCode: address.postalCode,
                        secondAddressLine: address?.secondAddressLine || '',
                        state: address?.state || '',
                        name: address.name,
                        phoneCountryCode: address.phoneCountryCode,
                        phoneNumber: address.phoneNumber,
                        surname: address.surname,
                        email: address?.email || '',
                    },
                    primary: false,
                    type: 'O',
                });
                handleResponseAndThrowAnErrorIfExists(response);

                const newAddresses = response.account.addresses || [];

                const prevAddresses = getProfile(getState()).addresses || [];

                const [newAddress] = newAddresses.filter(
                    (prevAddr) => !prevAddresses.find(({ uid }) => uid === prevAddr.uid),
                );

                dispatch(addOrUpdateAddressSuccessAction(newAddresses));
                return { state: getState(), newAddress };
            } catch (err) {
                dispatch(addOrUpdateAddressErrorAction(err.message));
                console.error('at profileActions in addOrUpdateAddress', err);
                return { state: getState() };
            }
        }
    };

export const removeAddress = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(removeAddressAction());
    try {
        const response = await new AccountAddressWsControllerApi().deleteAddressUsingDELETE(
            {
                address: { uid },
            },
            { headers: { 'Content-Type': 'application/json' } },
        );

        handleResponseAndThrowAnErrorIfExists(response);

        const addresses = response.account.addresses || [];
        dispatch(removeAddressSuccessAction(addresses));
    } catch (err) {
        dispatch(removeAddressErrorAction(err.message));
    }
};

export const agreeMarketTerms = (settings: Record<string, string>) => async (dispatch) => {
    dispatch(agreeTermsLoadingAction());

    try {
        const request = {
            settings,
        };
        const result = await new AccountWsControllerApi().addSettingsUsingPOST(request);

        handleResponseAndThrowAnErrorIfExists(result);

        const profile = result.account;

        dispatch(agreeTermsSuccessAction(profile));
    } catch (err) {
        dispatch(agreeTermsErrorAction(err.message));
    }
};

export const loadAgreements = (region?: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const loggedIn = isLoggedIn(getState());

    if (!loggedIn) {
        return getState();
    }

    const profile = getProfile(getState());

    const regionCode = region ?? profile.regionCode;

    try {
        dispatch(accountAgreementsLoading());

        const response = await new AccountRegionWsControllerApi().listRegionAgreementsUsingGET(
            regionCode,
            false,
            getLanguageCode(getState()),
        );

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(accountAgreementsLoadingSuccess(response.regionAgreementList ?? []));
        return getState();
    } catch (error) {
        console.error('at profileActions in loadAgreements', error);
        dispatch(accountAgreementsLoadingError(error.message));
        return getState();
    }
};

export const confirmAgreements =
    (agreements: string[]) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            dispatch(accountAgreementsLoading());

            const response = await new AccountRegionWsControllerApi().confirmAccountAgreementsUsingPOST({
                agreements,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            // TODO: remove filter when back will send actual list
            const agreementsList = getState().profileReducer.agreements.filter(
                (agreement) => !agreements.includes(agreement.uid),
            );

            dispatch(accountAgreementsLoadingSuccess(agreementsList));

            return getState();
        } catch (error) {
            console.error('at profileActions in confirmAgreements', error);
            dispatch(accountAgreementsLoadingError(error.message));
            return getState();
        }
    };

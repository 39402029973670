import cn from 'classnames';
import { Link, Toolbar } from 'framework7-react';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { CategoriesMenuProps } from './CategoriesMenu.types';

import { CategoryImage } from '../CategoryImage';

import { useAppSelector } from '@/hooks/store';
import { getSortedCategories } from '@/selectors/getSortedCategories';

import './CategoriesMenu.less';
import { getCategoryTranslateKey } from '@/utils';

export const CategoriesMenu = React.memo<CategoriesMenuProps>(({ onCategoryClick, className }) => {
    const { t } = useTranslation();

    const categories = useAppSelector(getSortedCategories);

    const categoriesChunks = useMemo(() => {
        if (categories.length < 2) return [categories];

        const half = Math.ceil(categories?.length / 2);

        return [categories.slice(0, half), categories.slice(half)];
    }, [categories]);

    const renderAllCategories = useMemo(() => {
        return (
            <>
                {categoriesChunks?.map((chunk, index) => (
                    <React.Fragment key={index}>
                        {chunk?.map((item) => (
                            <Link
                                key={item.categoryCode}
                                tabLink="#"
                                className={cn('no-ripple', {
                                    'categories-menu__list-item-link-without-product': !item.productCount,
                                })}
                                onClick={() => (item.productCount ? onCategoryClick(item.categoryCode) : null)}
                            >
                                <CategoryImage
                                    categoryName={item.categoryName}
                                    url={item.imageUrl}
                                    showSoon={!item.productCount}
                                    color={item.color}
                                />

                                <span className="tabbar-label">{t(getCategoryTranslateKey(item))}</span>
                            </Link>
                        ))}
                    </React.Fragment>
                ))}
            </>
        );
    }, [categoriesChunks, onCategoryClick, t]);

    return (
        <Toolbar
            className={cn('categories-menu default', className, {
                empty: !categories?.length,
                'categories-menu_compact': categories?.length < 2,
            })}
            scrollable
            noBorder
            noHairline
            noShadow
        >
            {renderAllCategories}
        </Toolbar>
    );
});
CategoriesMenu.displayName = 'CategoriesMenu';

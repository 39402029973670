export enum SortBy {
    'created_new_to_old',
    'created_old_to_new',
    'popular',
    'price_high_to_low',
    'price_low_to_high',
    'published_new_to_old',
    'published_old_to_new',
    'sales_first',
    'what_s_new',
}

import cn from 'classnames';
import { Button, Card, CardContent, CardFooter, CardHeader, Link, List, ListButton } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingReviewCard } from './LoadingReviewCard';

import { IcExclamationPointInCircle, IcMore } from '@/assets';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { EditReviewModalContent } from '@/components/EditReviewModalContent';
import { StarRating } from '@/components/StarRating';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { formatShortDate, isValidDate, noop } from '@/utils';

import type { ReviewCardProps } from './ReviewCard.types';

import './ReviewCard.less';

export const ReviewCard = ({
    loading = false,
    review = {} as ReviewCardProps['review'],
    translated = false,
    onImgClick = noop,
    isMyPage = false,
    onMoreClick: handleMoreClick = noop,
    moreClickMobileSelector: moreClickSelector = '',
    isMyReview,
    ...popoverContentProps
}: ReviewCardProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const isShaded = isMobile && review.images?.length > 3;
    const author = review.author?.name || t('{{appName}} User', { appName: process.env.APP_NAME });
    const text = translated ? review.text : review.originalText;

    const images = useMemo(
        () =>
            review.images?.length > 0 ? (
                <div className="review-card__images">
                    {review.images.map((image, idx) => (
                        <Button
                            key={idx}
                            className={cn('review-card__image-wrap', {
                                shaded: isShaded && idx === 2,
                            })}
                            onClick={() => onImgClick(review.images, idx)}
                        >
                            <img src={image.imageThumbnailUrl} alt="" />
                            {isShaded && idx === 2 && <span>+ {review.images.length - 3}</span>}
                        </Button>
                    ))}
                </div>
            ) : (
                <></>
            ),
        [review, isShaded, onImgClick],
    );

    const shortDate = useMemo(() => {
        const formattedDate = formatShortDate(review.creationDate);
        return isValidDate(formattedDate) ? formattedDate : '';
    }, [review.creationDate]);

    const onMoreClick = useCallback(() => handleMoreClick(review), [review, handleMoreClick]);

    const renderMoreLink = useMemo(() => {
        switch (true) {
            case isMobile:
                return (
                    <Link className="review-card__more" sheetOpen={isMobile && moreClickSelector} onClick={onMoreClick}>
                        <IcMore />
                    </Link>
                );

            case isMyReview:
                return (
                    <CustomPopoverWrapper
                        closeByClickInside
                        onTriggerClick={onMoreClick}
                        popoverTriggerElement={(triggerPopover) => (
                            <Link
                                onClick={triggerPopover}
                                className="review-card__more"
                                sheetOpen={isMobile && moreClickSelector}
                            >
                                <IcMore />
                            </Link>
                        )}
                    >
                        {() => <EditReviewModalContent {...popoverContentProps} />}
                    </CustomPopoverWrapper>
                );

            default:
                return (
                    <CustomPopoverWrapper
                        className="report-abuse-popover"
                        closeByClickInside
                        onTriggerClick={onMoreClick}
                        popoverTriggerElement={(triggerPopover) => (
                            <Link
                                onClick={triggerPopover}
                                className="review-card__more"
                                sheetOpen={isMobile && moreClickSelector}
                            >
                                <IcMore />
                            </Link>
                        )}
                    >
                        {() => (
                            <List>
                                <ListButton>
                                    <IcExclamationPointInCircle />
                                    {t('Report abuse')}
                                </ListButton>
                            </List>
                        )}
                    </CustomPopoverWrapper>
                );
        }
    }, [isMobile, isMyReview, moreClickSelector, onMoreClick, popoverContentProps, t]);

    return loading ? (
        <LoadingReviewCard />
    ) : (
        <Card className="review-card">
            <CardHeader>
                <div className={cn('review-card__avatar-wrap', { square: isMyPage })}>
                    {isMyPage && review.productImageThumbnailUrl && (
                        <img src={review.productImageThumbnailUrl} alt="" />
                    )}
                    {!isMyPage && review.author?.profilePictureUrl && (
                        <img src={review.author.profilePictureUrl} alt="" />
                    )}
                </div>
                <div className="review-card__header-wrap">
                    <h2 className="review-card__title">{isMyPage ? review.productName : author}</h2>
                    <div className="review-card__header-info-wrap">
                        {isMyPage && author}
                        <StarRating
                            className="review-card__star-rating"
                            readOnly
                            primary
                            value={review.rating}
                            size="small"
                        />
                    </div>
                </div>
                {renderMoreLink}
            </CardHeader>
            <CardContent>
                <p className="review-card__content">{text}</p>
                {images}
            </CardContent>
            <CardFooter>
                <div className="review-card__date">{shortDate}</div>
                {review.orderUid && <div className="review-card__verified">{t('Verified purchase')}</div>}
            </CardFooter>
        </Card>
    );
};

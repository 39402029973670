import cn from 'classnames';
import React from 'react';

import { CustomButtonProps } from './CustomButton.types';

import './CustomButton.less';
/**
 * Themed button created with button tag,
 * use where need tabindex,
 * now only "Primary M" & "Primary L" design
 *
 * TODO: Add another variants if needed
 */
export const CustomButton: React.FC<CustomButtonProps> = ({
    className,
    size = 'medium',
    shadowed,
    variant = 'primary',
    fullWidth,
    ...props
}) => {
    return (
        <button
            {...props}
            className={cn('custom-button', `custom-button-${size}`, `custom-button-${variant}`, className, {
                shadowed,
                'full-width': fullWidth,
            })}
        />
    );
};

import dayjs from 'dayjs';

/**
 * Returns date formatted from commonapi date YYYYMMDD000000
 * @param  {string|Date} date - date with Date type, but actually it's a string
 *
 * @returns {Date}
 */

export const destringifyDate = (date: string) => dayjs(date, 'YYYYMMDD000000', true).toDate();

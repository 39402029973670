import { useCallback, useState } from 'react';

import { sleep } from '@/utils';

export const useCopyToClipboard = (text: string, delay = 1000) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = useCallback(() => {
        navigator.clipboard
            .writeText(text)
            .then(() => setIsCopied(true))
            .then(() => sleep(delay))
            .then(() => setIsCopied(false))
            .then(() => false)
            .catch(console.error);
    }, [delay, text]);

    return { copyToClipboard, isCopied };
};

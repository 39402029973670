import dayjs from 'dayjs';

import { loadMyWallets } from './myWalletsActions';

import { createPaymentForOrder, createPaymentForOrderOverBank } from './paymentActions';

import { createSeller } from './paymentCardsActions';

import { loadDraftProductDetails } from './productCreateActions';

import { client, paymentapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    Account,
    Address,
    Order,
    OrderWsControllerApi as CommonAPIOrderWsControllerApi,
    PaymentMethod,
} from '@/generated/commonapi';
import {
    CreditCardPaymentWsControllerApi,
    EWalletPaymentRequest,
    EWalletPaymentWsControllerApi,
    OrderWsControllerApi,
    PaymentApiOrder,
    PaymentApiOrderAddress,
    ProductOrder,
} from '@/generated/paymentapi';
import { AppDispatch } from '@/hooks/store';
import { SavedCard } from '@/reducers/paymentCardsReducer';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getProfile } from '@/selectors/profile';
import { CartItemParameterCode } from '@/types/cartItem';
import { OrderProduct } from '@/types/orderProduct';
import { AvailablePaymentMethod } from '@/types/paymentMethods';
import { generateToken } from '@/utils';

export const ORDERS_PROMO_ORDER_CREATING = 'ORDERS_PROMO_ORDER_CREATING' as const;
export const ORDERS_PROMO_ORDER_CREATING_SUCCESS = 'ORDERS_PROMO_ORDER_CREATING_SUCCESS' as const;
export const ORDERS_PROMO_ORDER_CREATING_ERROR = 'ORDERS_PROMO_ORDER_CREATING_ERROR' as const;

export const ORDERS_PRODUCTS_ORDER_CREATING = 'ORDERS_PRODUCTS_ORDER_CREATING' as const;
export const ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS = 'ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS' as const;
export const ORDERS_PRODUCTS_ORDER_CREATING_ERROR = 'ORDERS_PRODUCTS_ORDER_CREATING_ERROR' as const;

export const ORDERS_LOADING = 'ORDERS_LOADING' as const;
export const ORDERS_LOADING_SUCCESS = 'ORDERS_LOADING_SUCCESS' as const;
export const ORDERS_LOADING_ERROR = 'ORDERS_LOADING_ERROR' as const;

export const ORDER_DETAILS_LOADING = 'ORDER_DETAILS_LOADING' as const;
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS' as const;

export const CHANGE_ORDER_STATUS_LOADING = 'CHANGE_ORDER_STATUS_LOADING' as const;
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS' as const;
export const CHANGE_ORDER_STATUS_ERROR = 'CHANGE_ORDER_STATUS_ERROR' as const;
export const ORDER_RESET = 'ORDER_RESET' as const;

export const CREATE_DEPOSIT_ORDER_LOADING = 'CREATE_DEPOSIT_ORDER_LOADING' as const;
export const CREATE_DEPOSIT_ORDER_SUCCESS = 'CREATE_DEPOSIT_ORDER_SUCCESS' as const;
export const CREATE_DEPOSIT_ORDER_ERROR = 'CREATE_DEPOSIT_ORDER_ERROR' as const;

export const ORDERS_RESET_ERRORS = 'ORDERS_RESET_ERRORS' as const;

const ordersPromoOrderCreating = () => ({
    type: ORDERS_PROMO_ORDER_CREATING,
});

const ordersPromoOrderCreatingSuccess = (order: PaymentApiOrder) => ({
    type: ORDERS_PROMO_ORDER_CREATING_SUCCESS,
    order,
});

const ordersPromoOrderCreatingError = (error: unknown) => ({
    type: ORDERS_PROMO_ORDER_CREATING_ERROR,
    error,
});

const ordersProductsOrderCreating = () => ({
    type: ORDERS_PRODUCTS_ORDER_CREATING,
});

const ordersProductsOrderCreatingSuccess = (order: ProductOrder, paymentMethods: PaymentMethod[] = []) => ({
    type: ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS,
    order,
    paymentMethods,
});

const ordersProductsOrderCreatingError = (error: unknown) => ({
    type: ORDERS_PRODUCTS_ORDER_CREATING_ERROR,
    error,
});

const ordersLoadingAction = () => ({
    type: ORDERS_LOADING,
});

const ordersLoadingSuccessAction = (orders: ProductOrder[], groupByDate: boolean) => ({
    type: ORDERS_LOADING_SUCCESS,
    orders,
    groupByDate,
});

const ordersLoadingErrorAction = (error: unknown) => ({
    type: ORDERS_LOADING_ERROR,
    error,
});

const orderDetailsAction = (order: ProductOrder) => ({
    type: ORDER_DETAILS_SUCCESS,
    order,
});

const orderReset = () => ({
    type: ORDER_RESET,
});

const changeOrderStatusLoading = () => ({
    type: CHANGE_ORDER_STATUS_LOADING,
});

const changeOrderStatusSuccess = (order: ProductOrder) => ({
    type: CHANGE_ORDER_STATUS_SUCCESS,
    order,
});

const changeOrderStatusError = (error: unknown) => ({
    type: CHANGE_ORDER_STATUS_ERROR,
    error,
});

export const createDepositOrderLoading = () => ({
    type: CREATE_DEPOSIT_ORDER_LOADING,
});

export const createDepositOrderSuccess = (order: Order) => ({
    type: CREATE_DEPOSIT_ORDER_SUCCESS,
    order,
});

export const createDepositOrderError = (error: unknown) => ({
    type: CREATE_DEPOSIT_ORDER_ERROR,
    error,
});

export const resetOrdersErrors = () => ({
    type: ORDERS_RESET_ERRORS,
});

export type OrdersActions = ReturnType<
    | typeof ordersPromoOrderCreating
    | typeof ordersPromoOrderCreatingSuccess
    | typeof ordersPromoOrderCreatingError
    | typeof ordersProductsOrderCreating
    | typeof ordersProductsOrderCreatingSuccess
    | typeof ordersProductsOrderCreatingError
    | typeof ordersLoadingAction
    | typeof ordersLoadingSuccessAction
    | typeof ordersLoadingErrorAction
    | typeof orderDetailsAction
    | typeof orderReset
    | typeof changeOrderStatusLoading
    | typeof changeOrderStatusSuccess
    | typeof changeOrderStatusError
    | typeof createDepositOrderLoading
    | typeof createDepositOrderSuccess
    | typeof createDepositOrderError
    | typeof resetOrdersErrors
>;

interface IPaymentApiOrder {
    successUrl?: string;
}

export const createOrder = async (item: IProduct): Promise<IPaymentApiOrder> => {
    const { uid } = item;
    const postData = { productUid: uid, quantity: 1 };
    return (await client.post(paymentapiURL + '/order/product/create', postData)).data;
};

export const createPromotionOrder =
    (
        feature: string,
        _featureCurrency: string,
        productUid: string,
        options: {
            cardUid?: string;
            cvc?: string;
            walletUid?: string;
            source: 'card' | 'wallet';
        },
        savedCard?: SavedCard,
    ) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(ordersPromoOrderCreating());

        const { source, cardUid, cvc } = options;
        const state = getState();
        const wallet = state.myWalletsReducer.wallets.find((item) => item.primary);

        try {
            const createOrderUsingPOST3Result = await new OrderWsControllerApi().createProductFeatureOrderUsingPOST({
                feature,
                productUid,
                currencyCode: wallet.currency.code,
            });
            handleResponseAndThrowAnErrorIfExists(createOrderUsingPOST3Result);
            const { order } = createOrderUsingPOST3Result;

            switch (source) {
                case 'card': {
                    const card = savedCard || state.paymentCardsReducer.cards.find((item) => item.uid === cardUid);

                    /* TODO */
                    const token = await generateToken({
                        cardNumber: null,
                        cvv: cvc,
                        cardHolder: null,
                        expMonth: null,
                        expYear: null,
                        ...card,
                    });

                    const data = {
                        orderUid: order.uid,
                        amount: order.amountTotal,
                        currencyCode: order.currencyCode,
                        paymentMethod: 'bankcard',
                        token,
                        saveCard: savedCard ? savedCard.saveCard : true,
                    };

                    if (order.seller.email) {
                        data['email'] = order.seller.email;
                    } else if (order.seller.phone) {
                        data['phone'] = order.seller.phone;
                    }
                    const paymentUsingPOST1Result = await new CreditCardPaymentWsControllerApi().paymentUsingPOST1(
                        data,
                    );

                    handleResponseAndThrowAnErrorIfExists(paymentUsingPOST1Result);
                    break;
                }
                case 'wallet': {
                    const data: EWalletPaymentRequest = {
                        orderUid: order.uid,
                        amount: order.amountTotal,
                        currencyCode: order.currencyCode,
                        paymentMethod: 'wallet',
                    };

                    if (order.seller.email) {
                        data['email'] = order.seller.email;
                    } else if (order.seller.phone) {
                        data['phone'] = order.seller.phone;
                    }

                    const paymentUsingPOST2Result = await new EWalletPaymentWsControllerApi().paymentUsingPOST2(data);

                    handleResponseAndThrowAnErrorIfExists(paymentUsingPOST2Result);
                    dispatch(loadMyWallets());
                    break;
                }
            }

            dispatch(loadDraftProductDetails(productUid));
            dispatch(ordersPromoOrderCreatingSuccess(order));
        } catch (err) {
            console.error('at ordersActions in createPromotionOrder', err);

            dispatch(ordersPromoOrderCreatingError(err.message));
        }
    };

export const createProductsPurchaseOrder =
    (
        paymentMethod: AvailablePaymentMethod,
        deliveryAddress: Address,
        products: OrderProduct[],
        options: {
            cardUid?: string;
            cvc?: string;
        } = {},
        savedCard?: SavedCard,
    ) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(ordersProductsOrderCreating());

        const state = getState();

        try {
            const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);

            const createOrderResult = await new OrderWsControllerApi().createProductsPurchaseOrderUsingPOST({
                deliveryAddress: Object.assign<PaymentApiOrderAddress, PaymentApiOrderAddress>(
                    {
                        name: deliveryAddress.name,
                        surname: deliveryAddress.surname,
                        phone: deliveryAddress.phone,
                        email: deliveryAddress.email,
                    },
                    products[0]?.platformDeliveryProviderUid
                        ? {
                              countryCode: products[0]?.deliveryCountryCode,
                              postalCode: products[0]?.deliveryPostalCode,
                              city: products[0]?.parameters[CartItemParameterCode.CdekCity] || '-',
                              firstAddressLine:
                                  products[0]?.parameters[CartItemParameterCode.CdekAddressLine] ||
                                  products[0]?.parameters[CartItemParameterCode.CdekDeliveryAddress],
                          }
                        : {
                              countryCode: deliveryAddress.country.code,
                              city: deliveryAddress.city,
                              firstAddressLine: deliveryAddress.firstAddressLine,
                              postalCode: deliveryAddress.postalCode,
                              secondAddressLine: deliveryAddress.secondAddressLine,
                              state: deliveryAddress.state,
                          },
                ),

                platformDeliveryProviderUid: products[0]?.platformDeliveryProviderUid,
                products: products.map((item) => ({
                    productUid: item.productUid,
                    quantity: item.count,
                    parameters: item.parameters,
                    deliveryMethodOptionUid: item?.deliveryMethodOptionUid,
                    rentTimeFrom: item?.rentTimeFrom,
                    rentTimeTo: item?.rentTimeTo,
                })),
                dueDate: dayjs(tomorrow).format('YYYYMMDDHHmmss'),
            });

            handleResponseAndThrowAnErrorIfExists(createOrderResult);

            const { order } = createOrderResult;

            if (paymentMethod === AvailablePaymentMethod.CARD) {
                const { cardUid, cvc } = options;
                const card = state.paymentCardsReducer.cards.find((item) => item.uid === cardUid);

                let token = savedCard && savedCard.uid.includes('_temporary') ? savedCard.token : null;

                if (!token) {
                    token = await generateToken({
                        cardNumber: null,
                        cvv: cvc,
                        cardHolder: null,
                        expMonth: null,
                        expYear: null,
                        saveCard: savedCard ? savedCard.saveCard : false,
                        ...(savedCard ?? card),
                    });
                }

                const saveCard = savedCard ? savedCard.saveCard : false;

                await dispatch(createPaymentForOrder(order, deliveryAddress, token, saveCard));
            }

            if (paymentMethod === AvailablePaymentMethod.BANK_TRANSFER) {
                const response = await new CommonAPIOrderWsControllerApi().getOrderPaymentMethodsUsingGET(order.uid);

                handleResponseAndThrowAnErrorIfExists(response);

                dispatch(ordersProductsOrderCreatingSuccess(order, response.methodList));
                return getState();
            }

            if (paymentMethod === AvailablePaymentMethod.PAY_BY_CARD) {
                await dispatch(createPaymentForOrderOverBank(order, 'paykeeper'));
            }

            if (paymentMethod === AvailablePaymentMethod.SBP) {
                await dispatch(createPaymentForOrderOverBank(order, 'sbp'));
            }

            dispatch(ordersProductsOrderCreatingSuccess(order));
            return getState();
        } catch (err) {
            console.error('at ordersActions in createProductsPurchaseOrder', err);

            dispatch(ordersProductsOrderCreatingError(err.message));
            return getState();
        }
    };

export const createWalletDepositOrder =
    (
        amountTotal: number,
        currencyCode: string,
        options: {
            cardUid?: string;
            cvc?: string;
        },
        savedCard?: SavedCard,
    ) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(createDepositOrderLoading());

        try {
            const response = await new CommonAPIOrderWsControllerApi().createDepositOrderUsingPOST({
                order: {
                    amountTotal,
                    currencyCode,
                    seller: createSeller(getProfile(getState())) as unknown as Account,
                    buyer: getProfile(getState()),
                },
            });

            handleResponseAndThrowAnErrorIfExists(response);

            const { order } = response;

            dispatch(createDepositOrderSuccess(response.order));

            const { cardUid, cvc } = options;
            const card = getState().paymentCardsReducer.cards.find((item) => item.uid === cardUid);

            let token = savedCard && savedCard.uid.includes('_temporary') ? savedCard.token : null;

            if (!token) {
                token = await generateToken({
                    cardNumber: null,
                    cvv: cvc,
                    cardHolder: null,
                    expMonth: null,
                    expYear: null,
                    saveCard: savedCard ? savedCard.saveCard : false,
                    ...(savedCard ?? card),
                });
            }

            const saveCard = savedCard ? savedCard.saveCard : false;

            await dispatch(
                createPaymentForOrder(
                    { ...order, amount: amountTotal },
                    getState().customerLocationReducer,
                    token,
                    saveCard,
                ),
            );
        } catch (error) {
            console.error('error in createWalletDepositOrder at ordersActions', error);

            dispatch(createDepositOrderError(error.message));
        }
    };

export const loadOrders = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(ordersLoadingAction());
    try {
        const state = getState();

        const result = await new OrderWsControllerApi().getProductOrderListUsingGET(
            true,
            getLanguageCode(state),
            null,
            null,
            false,
        );
        handleResponseAndThrowAnErrorIfExists(result);

        const items = result.orderList || [];
        dispatch(ordersLoadingSuccessAction(items, false));
    } catch (error) {
        dispatch(ordersLoadingErrorAction(error.message));
    }
};

export const selectOrder = (order: ProductOrder) => (dispatch: AppDispatch) => {
    dispatch(orderDetailsAction(order));
};

export const changeOrderStatus =
    (uid: string, status?: string, statusExtended?: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(changeOrderStatusLoading());

        try {
            const state = getState();
            const result = await new OrderWsControllerApi().setProductOrderStatusUsingPOST(
                {
                    uid,
                    status,
                    statusExtended,
                },
                getLanguageCode(state),
            );

            handleResponseAndThrowAnErrorIfExists(result);

            dispatch(changeOrderStatusSuccess(result.orderList[0]));

            dispatch(loadOrders());
        } catch (error) {
            console.error('at ordersActions in changeOrderStatus', error);

            dispatch(changeOrderStatusError(`${error.message}`));
        }
        return getState();
    };

export const resetOrder = () => (dispatch: AppDispatch) => dispatch(orderReset());

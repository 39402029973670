import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CustomInputFieldProps } from './CustomInputField.types';

import { CustomInput } from '@/components/CustomInput';

export const CustomInputField = <T extends string | number | unknown[]>({
    showErrorOnFocus,
    instantValidation = false,
    ...props
}: CustomInputFieldProps<T>): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Field<T> {...props}>
            {({ input: { value, onChange, onFocus, onBlur }, meta: { error, touched, visited } }) => (
                <CustomInput
                    value={value}
                    onInput={onChange}
                    errorMessage={t(error)}
                    errorMessageForce={(touched || (showErrorOnFocus && visited) || instantValidation) && !!error}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...props}
                    validate={false}
                />
            )}
        </Field>
    );
};

import { Product, StoreHomepageProduct } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';

export const storeHomePageProductsToIProducts = (
    initial: StoreHomepageProduct[],
    wishlist?: IProduct[],
): Partial<IProduct>[] =>
    initial?.map(
        ({
            productCurrencyCode,
            productName,
            productDescription,
            productDiscountedPrice,
            productPrice,
            productSalePrice,
            productUid,
            status,
            archived,
            imageThumbnailUrl1,
        }) => ({
            uid: productUid,
            imageThumbnailUrl1,
            currencyCode: productCurrencyCode,
            shortDescription: productDescription,
            name: productName,
            discountedPrice: productDiscountedPrice,
            price: productPrice,
            status: status as unknown as Product.StatusEnum,
            archived,
            salePrice: productSalePrice,
            wish: wishlist?.some((item) => item.uid === productUid),
        }),
    );

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './CategoryImage.less';
import { CategoryImageProps } from './CategoryImage.types';

import { IcAppsPath } from '@/assets';

export const CategoryImage: React.FC<CategoryImageProps> = ({
    url,
    categoryName,
    color,
    slot,
    showSoon = false,
    small = false,
}) => {
    const { t } = useTranslation();

    const [imageUrl, setImageUrl] = useState(IcAppsPath);

    useEffect(() => {
        if (url) {
            const image = new Image();

            image.src = url;
            image.onload = () => setImageUrl(url);
        }
    }, [setImageUrl, url]);

    return (
        <span
            className={cn('category-image', {
                disabled: showSoon,
                small: small,
            })}
            style={{ backgroundColor: color }}
            slot={slot}
        >
            <img src={imageUrl} alt={categoryName} className="category-image__image" />
            {showSoon && <span className="category-image__soon-label">{t('common_soon')}</span>}
        </span>
    );
};

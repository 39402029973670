import { FROM_LESS_AMOUNT_ERROR } from './GeneralInfo.constants';

import { GeneralInfoErrors, GeneralInfoValues } from './GeneralInfo.types';

import {
    INVALID_PERCENTAGE_ERROR,
    MIN_SALE_PRICE_ERROR,
    PRICE_FROM_ERROR,
    PRICE_TO_ERROR,
    REQUIRED_FIELD_ERROR,
} from '@/shared/constants';
import { isDefined } from '@/utils';

export const validateGeneralInfo = (values: GeneralInfoValues): GeneralInfoErrors => {
    const validDiscountAmountRangeFrom = (() => {
        switch (true) {
            case values.discountType?.value !== 'A':
                return undefined;
            case !isDefined(values.validDiscountAmountRangeFrom):
                return REQUIRED_FIELD_ERROR;
            case Number(values.discountAmountFixed) > Number(values.validDiscountAmountRangeFrom):
                return FROM_LESS_AMOUNT_ERROR;
            case isDefined(values.validDiscountAmountRangeTo) &&
                Number(values.validDiscountAmountRangeFrom) > Number(values.validDiscountAmountRangeTo):
                return PRICE_FROM_ERROR;
            default:
                return undefined;
        }
    })();

    const validDiscountAmountRangeTo = (() => {
        switch (true) {
            case values.discountType?.value !== 'A':
                return undefined;
            case !isDefined(values.validDiscountAmountRangeTo):
                return REQUIRED_FIELD_ERROR;
            case isDefined(values.validDiscountAmountRangeFrom) &&
                Number(values.validDiscountAmountRangeFrom) > Number(values.validDiscountAmountRangeTo):
                return PRICE_TO_ERROR;
            default:
                return undefined;
        }
    })();
    return {
        name: values.name ? undefined : REQUIRED_FIELD_ERROR,
        discountAmountPercent:
            values.discountType?.value === 'P'
                ? !isDefined(values.discountAmountPercent)
                    ? REQUIRED_FIELD_ERROR
                    : Number(values.discountAmountPercent) >= 100 || Number(values.discountAmountPercent) <= 0
                    ? INVALID_PERCENTAGE_ERROR
                    : undefined
                : undefined,
        discountAmountFixed:
            values.discountType?.value === 'A'
                ? !isDefined(values.discountAmountFixed)
                    ? REQUIRED_FIELD_ERROR
                    : values.discountAmountFixed >= 0.1
                    ? undefined
                    : MIN_SALE_PRICE_ERROR
                : undefined,
        validDiscountAmountRangeFrom,
        validDiscountAmountRangeTo,
        startDate: values.startDate ? undefined : REQUIRED_FIELD_ERROR,
        endDate: values.endDate || values.neverExpires ? undefined : REQUIRED_FIELD_ERROR,
    };
};

export const parseGeneralInfoValuesBeforeSubmit = (values: GeneralInfoValues): GeneralInfoValues => ({
    ...values,

    discountAmountFixed:
        values.discountAmountFixed && values.discountType.value === 'A'
            ? Number(values.discountAmountFixed)
            : undefined,
    discountAmountPercent:
        values.discountAmountPercent && values.discountType.value === 'P'
            ? Number(values.discountAmountPercent)
            : undefined,
    validDiscountAmountRangeFrom:
        values.validDiscountAmountRangeFrom && values.discountType.value === 'A'
            ? Number(values.validDiscountAmountRangeFrom)
            : undefined,
    validDiscountAmountRangeTo:
        values.validDiscountAmountRangeTo && values.discountType.value === 'A'
            ? Number(values.validDiscountAmountRangeTo)
            : undefined,
});

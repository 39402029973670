import { f7, Link, Navbar, NavRight } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomButton } from '../CustomButton';
import { MascotIcon } from '../MascotIcon';
import { MascotType } from '../MascotIcon/MascotIcon.types';

import { showStoreCreatedPopupAction } from '@/actions/storeActions';
import { IcClose } from '@/assets';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { IMSPopupProps, MSPopup } from '@/shared/UIKit/Popup/Popup';

import './StoreCreatedPopup.less';
import { IMSSheetProps, MSSheet } from '@/shared/UIKit/Sheet/Sheet';
import { Popups } from '@/types/popups';

const StoreCreatedPopup = ({ opened }: { opened: boolean }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(getIsMobile);
    const loggedIn = useAppSelector(isLoggedIn);

    const onCloseHandle = () => {
        dispatch(showStoreCreatedPopupAction(false));
    };

    const Component: React.FC<IMSSheetProps | IMSPopupProps> = isMobile ? MSSheet : MSPopup;

    const onGoToSellerArea = () => {
        onCloseHandle();

        if (loggedIn) {
            f7.views.main.router.navigate(isMobile ? '/profile/seller-area/' : '/profile/seller-area/store/');
        } else {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        }
    };

    return (
        <Component className="store-created-popup" opened={opened}>
            <div className="store-created-popup__header">
                <Navbar className="store-created-popup__navbar" noShadow noHairline>
                    <NavRight>
                        <Link className="store-created-popup__close" iconOnly onClick={onCloseHandle}>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
            </div>

            <div className="store-created-popup__content">
                <div className="store-created-popup__mascot">
                    <MascotIcon type={MascotType.SHOP} />
                </div>
                <div className="store-created-popup__title">{t('create_store.success_page.title')}</div>
                <div className="store-created-popup__subtitle">{t('create_store.success_page.description')}</div>
                <CustomButton
                    className="store-created-popup__button"
                    size="large"
                    fullWidth={isMobile}
                    onClick={onGoToSellerArea}
                >
                    {t('create_store.success_page.button.open_seller_area')}
                </CustomButton>
            </div>
        </Component>
    );
};

export default StoreCreatedPopup;

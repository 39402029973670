import { changeProfileRegionAction } from '@/actions/sessionActions';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { AccountRegionWsControllerApi } from '@/generated/commonapi';

import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

import type { Region } from '@/generated/commonapi/api';
import type { AppDispatch } from '@/hooks/store';

export const SET_REGION_LOADING = 'SET_REGION_LOADING' as const;
export const SET_REGION_ERROR = 'SET_REGION_ERROR' as const;

export const SET_REGION_LIST = 'SET_REGION_LIST' as const;
export const SET_REGION_LIST_LOADING = 'SET_REGION_LIST_LOADING' as const;
export const SET_REGION_LIST_ERROR = 'SET_REGION_LIST_ERROR' as const;

export const setRegionLoadingAction = () => ({
    type: SET_REGION_LOADING,
});

export const setRegionErrorAction = (err: unknown) => ({
    type: SET_REGION_ERROR,
    err,
});

export const setRegionListAction = (regions: Region[]) => ({
    type: SET_REGION_LIST,
    regions,
});

export const setRegionListLoadingAction = () => ({
    type: SET_REGION_LIST_LOADING,
});

export const setRegionListErrorAction = (err: unknown) => ({
    type: SET_REGION_LIST_ERROR,
    err,
});

export type RegionsActions = ReturnType<
    | typeof setRegionLoadingAction
    | typeof setRegionErrorAction
    | typeof setRegionListAction
    | typeof setRegionListLoadingAction
    | typeof setRegionListErrorAction
>;

export const changeAccountRegion =
    (regionCode: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(setRegionLoadingAction());
        const state = getState();
        try {
            const response = await new AccountRegionWsControllerApi().changeAccountRegionUsingPATCH(regionCode);
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(
                changeProfileRegionAction(response.account?.regionCode || state.sessionReducer.profile?.regionCode),
            );
        } catch (err) {
            console.error('at regionsActions in changeAccountRegion', err);
            dispatch(setRegionErrorAction(err.message));
        } finally {
            dispatch(setRegionErrorAction(undefined));
        }
    };

export const loadRegionList = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(setRegionListLoadingAction());
    const state = getState();
    const language = getLanguageCode(state);
    try {
        const response = await new AccountRegionWsControllerApi().listRegionsUsingGET(
            language,
            // skin?
            state.rootReducer.localConfig?.appCode,
        );
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(setRegionListAction(response.regionList || []));
    } catch (err) {
        console.error('at regionsActions in loadRegionList', err);
        dispatch(setRegionListErrorAction(err.message));
    }
};

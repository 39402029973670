import { CarouselType } from './Carousel.types';

type CarouselParams = { condition: boolean; slidesPerView: number };

const MIN_CARD_WIDTH = 320;

export const getCarouselParams = (type: CarouselType, containerWidth: number, isMobile: boolean): CarouselParams[] => {
    // ! conditions in array must be sorted by containerWidth, e.g. 768 before 900
    switch (type) {
        case CarouselType.small:
            return [
                { condition: containerWidth >= 1296, slidesPerView: 5 },
                { condition: containerWidth >= 960, slidesPerView: 4 },
                {
                    condition: containerWidth < 960,
                    slidesPerView: isMobile ? Math.min(Math.floor(containerWidth / MIN_CARD_WIDTH) + 0.2, 3.5) : 3,
                },
            ];

        case CarouselType.xsmall:
            return [{ condition: true, slidesPerView: Math.floor(containerWidth / MIN_CARD_WIDTH) }];

        case CarouselType.stores:
            return [
                { condition: containerWidth >= 1296, slidesPerView: 3 },
                { condition: containerWidth >= 960, slidesPerView: 2 },
                {
                    condition: containerWidth < 960,
                    slidesPerView: isMobile ? Math.max(Math.floor(containerWidth / MIN_CARD_WIDTH) + 0.1, 1.1) : 2,
                },
            ];

        case CarouselType.smallHalfScreen:
            return [
                { condition: isMobile, slidesPerView: 2 },
                { condition: containerWidth <= 900, slidesPerView: 2 },
                { condition: containerWidth <= 1140, slidesPerView: 3 },
                { condition: containerWidth > 1140, slidesPerView: 4 },
            ];

        case CarouselType.smallDesktopView:
            return [{ condition: true, slidesPerView: 5 }];
        case CarouselType.big:
            return [
                { condition: containerWidth <= 500, slidesPerView: 1.1 },
                { condition: containerWidth <= 768, slidesPerView: 2.1 },
                { condition: containerWidth > 768, slidesPerView: 4 },
            ];
        case CarouselType.top:
            return [
                { condition: isMobile, slidesPerView: 1.05 },
                { condition: true, slidesPerView: 1 },
            ];
        case CarouselType.articles:
            return [
                { condition: containerWidth >= 960, slidesPerView: 4 },
                {
                    condition: containerWidth < 960,
                    slidesPerView: isMobile ? Math.min(Math.floor(containerWidth / MIN_CARD_WIDTH) + 0.2, 3.5) : 3,
                },
            ];
        default:
            return [{ condition: true, slidesPerView: 1 }];
    }
};

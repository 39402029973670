import React from 'react';

import { TopItemProps } from './TopItem.types';

import './TopItem.less';

export const TopItem = ({ onClick, item }: TopItemProps) => {
    const { name, promo } = item;

    return (
        <div onClick={onClick} className="carousel-item__top__content">
            <p className="name">{name}</p>
            <p className="promo">{promo}</p>
        </div>
    );
};

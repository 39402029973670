import cn from 'classnames';
import { Button, f7, Fab, Icon, Link, Navbar, NavLeft, NavRight, NavTitle, Page, Popup } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { loadAgreements } from '@/actions/profileActions';
import { changeAccountRegion, loadRegionList } from '@/actions/regionsActions';
import { modalService } from '@/api/modalService';
import { IcClose, IcDone } from '@/assets';
import { CustomCountryFlag } from '@/components/CustomCountryFlag';
import { RegionAgreementModal } from '@/components/RegionAgreementModal';
import { RegionAgreement } from '@/generated/commonapi';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { getAgreements } from '@/selectors/getAgreements';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';

import { Popups } from '@/types/popups';

import type { EditRegionPoupProps } from './EditRegionPopup.types';
import type { IApplicationStore } from '@/reducers/rootReducer';

import './EditRegionPopup.less';

export const EditRegionPopup: React.FC<EditRegionPoupProps> = ({
    isFirstTime = false,
    isNeedBackdropForAgreementModal = true,
    onPopupClose,
    onPopupOpened,
    className,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(getIsMobile);
    const loggedIn = useAppSelector(isLoggedIn);

    const settedRegionCode = useAppSelector((state) => state.sessionReducer.profile?.regionCode);
    const { regionsList, changeRegionError } = useAppSelector((state) => state.regionsReducer);

    // ? Terms Of Service should be accepted while product creating
    const agreements = useAppSelector(getAgreements);

    const [selectedRegionCode, setSelectedRegionCode] = useState(settedRegionCode);
    const [isAgreementModalOpened, closeAgreementModal] = useBoolState(false);

    useEffect(() => setSelectedRegionCode(settedRegionCode), [changeRegionError, settedRegionCode]);

    useEffect(() => {
        if (loggedIn) dispatch(loadRegionList());
    }, [dispatch, loggedIn]);

    const handleChangeAgreements = useCallback(
        (regionCode: string, terms: RegionAgreement[]) => {
            if (terms.length > 0) {
                // openAgreementModal();
            } else {
                dispatch(changeAccountRegion(regionCode));
            }
        },
        [dispatch],
    );

    const loadAndHandleAgreements = useCallback(
        (regionCode: string) => {
            dispatch(loadAgreements(regionCode)).then((state) => {
                handleChangeAgreements(regionCode, getAgreements(state));
            });
        },
        [dispatch, handleChangeAgreements],
    );

    const onChange = useCallback(() => {
        f7.popup.close(`.${Popups.EDIT_REGION_POPUP}`);
        loadAndHandleAgreements(selectedRegionCode);
    }, [selectedRegionCode, loadAndHandleAgreements]);

    const handleSubmitAgreements = useCallback(
        (state: IApplicationStore) => {
            handleChangeAgreements(selectedRegionCode, getAgreements(state));
        },
        [selectedRegionCode, handleChangeAgreements],
    );

    const handlePopupClose = useCallback(() => {
        modalService.setIsAlredyOpened(Popups.EDIT_REGION_POPUP, true);
        onPopupClose?.();
    }, [onPopupClose]);

    const handlePopupOpened = useCallback(() => {
        if (regionsList.length === 1 && !settedRegionCode && isFirstTime) {
            setSelectedRegionCode(regionsList[0].code);
            loadAndHandleAgreements(regionsList[0].code);
            f7.popup.close(`.${Popups.EDIT_REGION_POPUP}`, false);
        }
        onPopupOpened?.();
    }, [regionsList, settedRegionCode, isFirstTime, onPopupOpened, loadAndHandleAgreements]);

    return (
        <>
            <Popup
                id={Popups.EDIT_REGION_POPUP}
                className={cn(Popups.EDIT_REGION_POPUP, 'edit-region-popup', className, {
                    'edit-region-popup-first-time': isFirstTime,
                })}
                onPopupClose={handlePopupClose}
                onPopupOpened={handlePopupOpened}
                tabletFullscreen
                closeByBackdropClick={!isFirstTime}
                {...props}
            >
                <Page>
                    <Navbar noShadow noHairline>
                        <NavLeft>
                            <Link popupClose iconOnly>
                                <IcClose />
                            </Link>
                        </NavLeft>
                        <NavTitle>
                            {t(
                                isFirstTime
                                    ? isMobile
                                        ? 'Select region where you are operating'
                                        : 'Region'
                                    : 'Change Region',
                            )}
                        </NavTitle>
                        <NavRight>
                            <Link popupClose iconOnly>
                                <IcClose />
                            </Link>
                        </NavRight>
                    </Navbar>
                    <div className="edit-region-popup__content">
                        <p className="edit-region-popup__sign">{t('Select region where you are operating')}</p>
                        <ul className="edit-region-popup__list">
                            {regionsList.map((region) => (
                                <li key={region.code}>
                                    <Link
                                        className={cn('edit-region-popup__clickable-item', {
                                            disabled: settedRegionCode === region.code,
                                        })}
                                        popupClose={isFirstTime}
                                        onClick={() => {
                                            setSelectedRegionCode(region.code);
                                            if (isFirstTime) loadAndHandleAgreements(region.code);
                                        }}
                                    >
                                        <CustomCountryFlag code={region.code} width={24} />
                                        {region.name}
                                        {selectedRegionCode === region.code && <IcDone />}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                        <Button fill round raised disabled={settedRegionCode === selectedRegionCode} onClick={onChange}>
                            {t('Change')}
                        </Button>
                        <Fab
                            className={cn({
                                disabled: settedRegionCode === selectedRegionCode,
                            })}
                            onClick={onChange}
                        >
                            <Icon ios="f7:checkmark_alt" md="material:send" />
                        </Fab>
                    </div>
                </Page>
            </Popup>
            <RegionAgreementModal
                agreementType={agreements[0]?.type}
                opened={agreements.length > 0 && isAgreementModalOpened}
                onPopupClose={closeAgreementModal}
                onSheetClose={closeAgreementModal}
                handleSubmit={handleSubmitAgreements}
                backdrop={isNeedBackdropForAgreementModal}
                closeByBackdropClick={!isFirstTime}
            />
        </>
    );
};

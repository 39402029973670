import cn from 'classnames';
import { List, ListGroup, ListItem } from 'framework7-react';
import groupBy from 'lodash.groupby';
import map from 'lodash.map';
import React from 'react';
import { compose } from 'redux';

import { CatalogProps } from './Catalog.types';
import { CatalogItem } from './CatalogItem';

import connectCategories from '@/store/connectCategories';
import connectCategoriesClassificator from '@/store/connectCategoriesClassificator';

import './Catalog.less';

const Catalog = ({
    items,
    addToWish,
    categoriesClassificator,
    onClick,
    swipeoutActions,
    groupBy: groupByParam,
    simplePrice,
    showStatus,
    showAnalytics,
    showFeaturesHighlight,
    className,
    mobileView,
    desktopView,
    videoCatalog,
}: CatalogProps) => (
    <List className={cn('catalog', className)} mediaList noChevron noHairlines>
        {!groupByParam
            ? items?.map((item, index) => (
                  <CatalogItem
                      videoItem={videoCatalog}
                      key={`${item.uid}_${index}`}
                      item={item}
                      categories={categoriesClassificator}
                      addToWish={addToWish}
                      slot="list"
                      onClick={onClick}
                      swipeoutActions={swipeoutActions}
                      simplePrice={simplePrice}
                      showStatus={showStatus}
                      mobileView={mobileView}
                      showAnalytics={showAnalytics}
                      showFeaturesHighlight={showFeaturesHighlight}
                      className={cn({ 'mobile-view': mobileView, 'desktop-view': desktopView })}
                  />
              ))
            : map(groupBy(items, groupByParam), (value, key) => (
                  <ListGroup key={key}>
                      <ListItem title={key} groupTitle />
                      {value.map((item) => (
                          <CatalogItem
                              key={item.uid}
                              videoItem={videoCatalog}
                              item={item}
                              categories={categoriesClassificator}
                              addToWish={addToWish}
                              slot="list"
                              onClick={onClick}
                              mobileView={mobileView}
                              swipeoutActions={swipeoutActions}
                              simplePrice={simplePrice}
                              showStatus={showStatus}
                              showAnalytics={showAnalytics}
                              showFeaturesHighlight={showFeaturesHighlight}
                          />
                      ))}
                  </ListGroup>
              ))}
    </List>
);

export default compose<React.FunctionComponent<CatalogProps>>(
    connectCategories,
    connectCategoriesClassificator,
)(Catalog);

import { IProduct } from './productReducer';

import {
    REMOVE_STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS,
    SAVE_STORE_HOMEPAGE,
    SAVE_STORE_HOMEPAGE_LOADING,
    STORE_CATEGORIES_LOADING,
    STORE_HOMEPAGE_CATEGORIES_LOADING_SUCCESS,
    STORE_HOMEPAGE_WIDGET_CATEGORIES_PRODUCTS_LOADING_SUCCESS,
    STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS_LOADING,
    STORE_PRODUCTS_COUNT_SAVE,
    STORE_PRODUCTS_LOADING,
    STORE_PRODUCTS_LOADING_SUCCESS,
    StoreHomepageActions,
} from '@/actions/storeHomePageActions';
import { CarouselItem } from '@/components/Carousel';
import { StoreHomepage } from '@/generated/marketplaceapi';

export interface IStoreHomepageWidgetCategoryProducts {
    entityUid: string;
    products: CarouselItem[];
}
export interface IStoreHomePageState {
    storeHomePage?: StoreHomepage;
    storeHomePageLoading: boolean;
    storeWidgetsCategoryProducts?: IStoreHomepageWidgetCategoryProducts[];
    storeWidgetsCategoryProductsLoading: boolean;
    storeCategories?: string[];
    storeCategoriesLoading: boolean;
    storeProductsLoading: boolean;
    storeProductsFilteredCount?: number;
    storeProducts?: IProduct[];
}

const initialState: IStoreHomePageState = {
    storeHomePage: undefined,
    storeHomePageLoading: false,
    storeWidgetsCategoryProducts: [],
    storeWidgetsCategoryProductsLoading: false,
    storeCategoriesLoading: false,
    storeProductsLoading: false,
};

const storeHomePageReducer = (state = initialState, action: StoreHomepageActions): IStoreHomePageState => {
    switch (action.type) {
        case SAVE_STORE_HOMEPAGE: {
            return {
                ...state,
                storeHomePage: { ...action.data },
            };
        }
        case SAVE_STORE_HOMEPAGE_LOADING: {
            return {
                ...state,
                storeHomePageLoading: action.loading,
            };
        }
        case STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS_LOADING: {
            return { ...state, storeWidgetsCategoryProductsLoading: action.loading };
        }
        case STORE_HOMEPAGE_WIDGET_CATEGORIES_PRODUCTS_LOADING_SUCCESS: {
            return {
                ...state,
                storeWidgetsCategoryProducts: [
                    //...(state.storeWidgetsCategoryProducts ?? []),
                    ...action.storeWidgetsCategories,
                ],
                storeWidgetsCategoryProductsLoading: false,
            };
        }
        case REMOVE_STORE_HOMEPAGE_WIDGET_CATEGORY_PRODUCTS: {
            return {
                ...state,
                storeWidgetsCategoryProducts: state.storeWidgetsCategoryProducts.filter(
                    (item) => item.entityUid !== action.category,
                ),
            };
        }
        case STORE_CATEGORIES_LOADING: {
            return { ...state, storeCategoriesLoading: action.loading };
        }
        case STORE_HOMEPAGE_CATEGORIES_LOADING_SUCCESS: {
            return { ...state, storeCategories: action.categories };
        }
        case STORE_PRODUCTS_LOADING: {
            return { ...state, storeProductsLoading: action.loading };
        }
        case STORE_PRODUCTS_LOADING_SUCCESS: {
            return {
                ...state,
                storeProducts: action.products,
                storeProductsFilteredCount: action.products?.length,
            };
        }
        case STORE_PRODUCTS_COUNT_SAVE: {
            return { ...state, storeProductsFilteredCount: action.count };
        }
    }
    return state;
};

export default storeHomePageReducer;

import { ProductVideo } from '@/generated/marketplaceapi';

export const fetchVideoTitle = (url: string, type: ProductVideo.TypeEnum): Promise<string> => {
    switch (type) {
        case ProductVideo.TypeEnum.YOUTUBE:
        case ProductVideo.TypeEnum.VIMEO:
            return fetch(`https://noembed.com/embed?url=${url}`)
                .then((data) => data.json())
                .then((data) => data.title);
        default:
            return new Promise((resolve) => resolve(''));
    }
};

import cn from 'classnames';
import { Button, Link, List, ListItem, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoriesOptions } from './CategoriesOptions';

import { CategorySheetContentView, CategorySheetProps } from './CategorySheet.types';

import { GroupTitle } from './GroupTitle';

import { LocationOption } from './LocationOption';

import { SaleOptions } from './SaleOptions';

import { SearchCategoryPopup } from './SearchCategoryPopup';

import { CategoryImage } from '../CategoryImage';

import { IcArrowRight, IcClose, IcDone, IcSearch } from '@/assets';
import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { SortByButtonsGroup } from '@/components/SortByButtonsGroup';
import { Sale } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { useSearchResultCount } from '@/hooks/useSearchResultCount';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ALL_CATEGORY_CLASSIFICATOR_CODE } from '@/shared/constants';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';
import { Sheets } from '@/types/sheets';
import { getCategoryTranslateKey } from '@/utils';

import './CategorySheet.less';

export const CategorySheet = ({
    onCategoryClick,
    className,
    everyLevelMultyselectable,
    deepestOnlySelectable,
    withReturnToParentButton,
    allowedCategories,
    withoutSearch,
    selectedCategories,
    onSelectCategoriesSubmit,
    defaultTitle,
    description,
    everyLevelSelectable,
    initialOpenedCategory,
    withIcons,
    saleList,
    selectedSales: selectedSalesInitial,
    fullscreen = false,
    withLocation = false,
    coordinates,
    onLocationSelect,
    withSort,
    selectedSortOptions,
    onSortOptionClick,
    sortByArr,
    hideCategoriesInSeparatedMenu = false,
    withTotalCount = false,
    searchParams,
    startFromRootCategory = false,
    boldGroupTitles = true,
    skipRoot = false,
    opened,
    onSheetOpened,
    onSheetClosed,
    showAll,
    ...props
}: CategorySheetProps) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const [isSelfOpened, setIsSelfOpened] = useState(opened);

    const [isSearchPopupOpened, setIsSearchPopupOpened] = useState(false);

    useEffect(() => {
        setIsSelfOpened(opened);
    }, [opened]);

    const openSearch = useCallback(() => {
        setIsSelfOpened(false);
        setIsSearchPopupOpened(true);
    }, []);

    const closeSearch = useCallback(() => {
        setIsSearchPopupOpened(false);
        setIsSelfOpened(true);
    }, []);

    const [contentView, setContentView] = useState(CategorySheetContentView.ALL);

    const [selectedSales, setSelectedSales] = useState<Sale[]>(selectedSalesInitial ?? []);

    const [selectedSubcategory, setSelectedSubcategory] = useState<ICategoryClassificator | undefined>(undefined);

    const [chosenCategories, setChosenCategories] = useState<ICategoryClassificator[]>(selectedCategories ?? []);

    useEffect(() => {
        setChosenCategories(selectedCategories ?? []);
        setSelectedSales(selectedSalesInitial ?? []);
    }, [selectedCategories, selectedSalesInitial]);

    const fullSearchParams = useMemo(
        () => ({
            ...searchParams,
            category: selectedSubcategory?.categoryCode,
            resetSorting: true,
        }),
        [searchParams, selectedSubcategory?.categoryCode],
    );

    const totalCountPreview = useSearchResultCount({
        searchParams: fullSearchParams,
        shouldDo: isMobile && opened && withTotalCount,
    });

    const closeSelf = useCallback(() => {
        setIsSelfOpened(false);
    }, []);

    const handleSelfOpen = useCallback(() => {
        if (!startFromRootCategory) {
            setSelectedSubcategory(initialOpenedCategory);
        }

        setIsSelfOpened(true);
    }, [initialOpenedCategory, startFromRootCategory]);

    const handleSelfOpened = useCallback(() => {
        onSheetOpened?.();
    }, [onSheetOpened]);

    const handleSelfClosed = useCallback(() => {
        if (opened) {
            closeSelf();
        }

        if (!isSearchPopupOpened) {
            setSelectedSubcategory(undefined);
            setContentView(CategorySheetContentView.ALL);
            setChosenCategories(selectedCategories ?? []);
            onSheetClosed?.();
        }
    }, [closeSelf, isSearchPopupOpened, onSheetClosed, opened, selectedCategories]);

    const submitSingleCategory = useCallback(() => {
        onCategoryClick?.(selectedSubcategory);
        setSelectedSubcategory(undefined);
        closeSelf();
    }, [closeSelf, onCategoryClick, selectedSubcategory]);

    const handleCategoryItemClick = useCallback(
        (category: ICategoryClassificator, isAll?: boolean) => {
            if (!category.children.length || isAll) {
                onCategoryClick?.(category);

                if (!everyLevelMultyselectable) {
                    setSelectedSubcategory(undefined);
                    closeSelf();
                } else {
                    setChosenCategories((prev) =>
                        prev.includes(category)
                            ? prev.filter((item) => item.categoryCode !== category.categoryCode)
                            : [...prev, category],
                    );
                }
                return;
            }

            setSelectedSubcategory(category);
        },
        [closeSelf, everyLevelMultyselectable, onCategoryClick],
    );

    const handleRootAllClick = useCallback(() => {
        onCategoryClick?.({ categoryCode: 'all' });
        closeSelf();
    }, [closeSelf, onCategoryClick]);

    const handleSaleClick = useCallback((sale: Sale) => {
        setSelectedSales((prev) =>
            prev.includes(sale) ? prev.filter((item) => item.uid !== sale.uid) : [...prev, sale],
        );
    }, []);

    const sheetTitle =
        t(getCategoryTranslateKey(selectedSubcategory)) || t(defaultTitle ?? 'category_bottom_sheet.title');

    const handleClickBackArrow = useCallback(() => {
        if (!selectedSubcategory?.parent) {
            setContentView(CategorySheetContentView.ALL);
        }

        const newSubcategory =
            selectedSubcategory?.parent?.categoryCode === ALL_CATEGORY_CLASSIFICATOR_CODE
                ? undefined
                : selectedSubcategory?.parent;

        setSelectedSubcategory(newSubcategory);
    }, [selectedSubcategory?.parent]);

    const handleSelectCategoriesSubmit = useCallback(
        () => onSelectCategoriesSubmit(chosenCategories, selectedSales),
        [chosenCategories, onSelectCategoriesSubmit, selectedSales],
    );

    const categoriesMenuShown = hideCategoriesInSeparatedMenu && contentView === CategorySheetContentView.CATEGORIES;

    const showSalesOptions = !!saleList?.length && !selectedSubcategory && !categoriesMenuShown;

    const showCategoriesGroupTitle =
        showSalesOptions || (hideCategoriesInSeparatedMenu && contentView === CategorySheetContentView.ALL);

    const showSortByGroupTitle = withSort && showCategoriesGroupTitle;

    const showBackLink = categoriesMenuShown || (withReturnToParentButton && selectedSubcategory);

    const actuallySelectedCategory = selectedSubcategory ?? initialOpenedCategory;

    return (
        <>
            <MSSheet
                {...props}
                className={cn(
                    Sheets.CATEGORY_SHEET,
                    'category-sheet',
                    {
                        fullscreen,
                        'with-total-count': withTotalCount,
                        'd-none': isSearchPopupOpened,
                    },
                    className,
                )}
                opened={isSelfOpened}
                onSheetOpen={handleSelfOpen}
                onSheetOpened={handleSelfOpened}
                onSheetClosed={handleSelfClosed}
                onBackdropClick={closeSelf}
            >
                <Navbar className={cn('category-sheet__navbar', { 'root-mode': !selectedSubcategory })}>
                    <NavLeft className={cn({ 'without-back': !showBackLink })}>
                        {showBackLink && <AdaptiveBackLink onClick={handleClickBackArrow} />}
                        <h3>{sheetTitle}</h3>
                    </NavLeft>
                    <NavRight>
                        {!withoutSearch && (
                            <Link className={cn('category-sheet__search')} iconOnly onClick={openSearch}>
                                <IcSearch />
                            </Link>
                        )}
                        <Link className={cn('category-sheet__close')} iconOnly onClick={closeSelf}>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
                {description && !categoriesMenuShown && <p className="category-sheet__description">{t(description)}</p>}
                {showSalesOptions && (
                    <>
                        <GroupTitle bold={boldGroupTitles}>{t('Sale')}</GroupTitle>
                        <SaleOptions saleList={saleList} selectedSales={selectedSales} onSaleClick={handleSaleClick} />
                    </>
                )}
                {showCategoriesGroupTitle && <GroupTitle bold={boldGroupTitles}>{t('Categories')}</GroupTitle>}
                {hideCategoriesInSeparatedMenu && !categoriesMenuShown && (
                    <List className="category-sheet__open-categories-options">
                        <ListItem
                            title={t(getCategoryTranslateKey(actuallySelectedCategory)) || t('All Categories')}
                            footer={actuallySelectedCategory?.seoText}
                            onClick={() => setContentView(CategorySheetContentView.CATEGORIES)}
                        >
                            {actuallySelectedCategory?.imageUrl && (
                                <CategoryImage
                                    slot="media"
                                    categoryName={actuallySelectedCategory.categoryName}
                                    color={actuallySelectedCategory.color}
                                    url={actuallySelectedCategory.imageUrl}
                                    small
                                ></CategoryImage>
                            )}
                            <IcArrowRight className="category-sheet__arrow" />
                        </ListItem>
                    </List>
                )}
                {(categoriesMenuShown || !hideCategoriesInSeparatedMenu) && (
                    <CategoriesOptions
                        allowedCategories={allowedCategories}
                        choosenCategories={chosenCategories}
                        everyLevelMultyselectable={everyLevelMultyselectable}
                        everyLevelSelectable={everyLevelSelectable}
                        deepestOnlySelectable={deepestOnlySelectable}
                        selectedSubcategory={selectedSubcategory}
                        withIcons={withIcons}
                        handleRootAllClick={handleRootAllClick}
                        onCategoryClick={handleCategoryItemClick}
                        showAll={showAll}
                        skipRoot={skipRoot}
                    />
                )}
                {withLocation && <GroupTitle bold={boldGroupTitles}>{t('Location')}</GroupTitle>}
                {withLocation && <LocationOption coordinates={coordinates} onLocationSelect={onLocationSelect} />}
                {showSortByGroupTitle && <GroupTitle bold={boldGroupTitles}>{t('Sort by')}</GroupTitle>}
                {withSort && !categoriesMenuShown && (
                    <div className="category-sheet__sort">
                        <SortByButtonsGroup
                            selected={selectedSortOptions}
                            sortByArr={sortByArr}
                            listView
                            onClick={onSortOptionClick}
                        />
                    </div>
                )}
                {everyLevelMultyselectable && !categoriesMenuShown && (
                    <Button className="apply-button" fill round onClick={handleSelectCategoriesSubmit}>
                        <IcDone />
                    </Button>
                )}
                {withTotalCount && (
                    <Button className="apply-button-with-count" raised large fill round onClick={submitSingleCategory}>
                        {t('Show {{count}} Products', { count: totalCountPreview })}
                    </Button>
                )}
            </MSSheet>
            <SearchCategoryPopup
                className="category-sheet__search-popup"
                opened={isSearchPopupOpened}
                onPopupOpen={openSearch}
                onPopupClose={closeSearch}
                onCategoryClick={handleCategoryItemClick}
            />
        </>
    );
};

import { ConfigureImportErrors, ConfigureImportValues } from './ConfigureImport.types';

import { CustomSelectValue } from '@/components/CustomSelect';
import { ImportContentType } from '@/pages/ImportProducts/ImportProducts.types';
import { REQUIRED_FIELD_ERROR } from '@/shared/constants';

export const validateConfigureImport =
    (uploadError: string) =>
    (values: ConfigureImportValues): ConfigureImportErrors => ({
        contentType: values.contentType ? undefined : REQUIRED_FIELD_ERROR,
        file: uploadError
            ? uploadError
            : values.contentType === ImportContentType.file && !values.file
            ? REQUIRED_FIELD_ERROR
            : undefined,
        typeCode: values.typeCode ? undefined : REQUIRED_FIELD_ERROR,
        url: values.contentType === ImportContentType.url && !values.url ? REQUIRED_FIELD_ERROR : undefined,
    });

export const getAcceptParamsByFileType = (fileType: CustomSelectValue) => {
    switch (true) {
        case fileType?.value === 'yandex-xls': {
            return '.xls, .xlsx';
        }
        case fileType?.value === 'excel': {
            return '.xls, .xlsx';
        }
        case fileType?.value === 'yml': {
            return '.yml';
        }
        case fileType?.value === 'csv': {
            return '.csv';
        }
        default:
            return '*';
    }
};

import { isDigitString } from './isDigitString';

export const isValidABA = (code: string) => {
    if (code.length !== 9 || isDigitString(code)) {
        return false;
    }
    const digits = code.split('').map(Number);
    const sum =
        digits[0] * 7 +
        digits[1] * 3 +
        digits[2] +
        digits[3] * 7 +
        digits[4] * 3 +
        digits[5] +
        digits[6] * 7 +
        digits[7] * 3 +
        digits[8];

    return sum % 10 === 0;
};

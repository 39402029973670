export async function getBase64ImageFromUrl(imageUrl: string) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener(
            'load',
            function () {
                const base64ImgNoHeader = reader.result.toString().replace(/^data:(.*,)?/, '');
                resolve(base64ImgNoHeader);
            },
            false,
        );

        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    });
}

import { CarouselItem } from '@/components/Carousel';
import { IProduct } from '@/reducers/productReducer';

export const productToSliderItem = (
    item: Partial<IProduct>,
    sliderItemClickHandle: (item: CarouselItem) => void,
    productsWishList: IProduct[] = [],
): CarouselItem => ({
    ...item,
    image: item.imageUrl1 ?? item.imageThumbnailUrl1,
    priceDiscount: item.discountedPrice,
    href: `/product-details/${item.uid}/`,
    onClick: sliderItemClickHandle,
    description: item.shortDescription,
    wish: !!productsWishList.find(({ uid }) => item.uid === uid),
});

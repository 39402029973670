import { Link } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLinkProps, RenderExternalLinkProps } from './ExternalLink.types';

import { messengerService } from '@/api/messengerService';
import { IframeContentPopup } from '@/components/IframeContentPopup';
import { useAppSelector } from '@/hooks/store';
import { useIframePopup } from '@/hooks/useIframePopup';
import { getIsMobile } from '@/selectors/getIsMobile';
import { IExternalLink } from '@/types/localConfig';

import './ExternalLink.less';

export const ExternalLink: React.FC<ExternalLinkProps> = ({ link, renderLink, ...props }) => {
    const isMobile = useAppSelector(getIsMobile);
    const { Url_app: urls } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);
    const { t } = useTranslation();

    const getLinkUrl = useCallback(
        (code: string) => {
            const filtered = urls.filter((u) => u.code === code);
            return filtered.length > 0 ? filtered[0].value : '#';
        },
        [urls],
    );
    const { setIframeContent, openIframePopup, ...popupProps } = useIframePopup();
    const makeHandlerOpenIframe = useCallback(
        (link: IExternalLink) => () => {
            setIframeContent({ title: link.title || link.name, url: getLinkUrl(link.name) });
            openIframePopup();
        },
        [getLinkUrl, openIframePopup, setIframeContent],
    );

    const getExternalLinkProps = useCallback(
        (link: IExternalLink): RenderExternalLinkProps => {
            switch (true) {
                case isMobile && link.newTabMobile:
                case !isMobile && link.newTabWeb:
                    return {
                        onClick: () => messengerService.openExternalLink(getLinkUrl(link.name), '_blank'),
                    };
                case isMobile && !link.newTabMobile:
                case !isMobile && !link.newTabWeb:
                    return { onClick: makeHandlerOpenIframe(link) };

                default:
                    return {};
            }
        },
        [getLinkUrl, isMobile, makeHandlerOpenIframe],
    );

    return (
        <>
            {renderLink?.(getExternalLinkProps(link)) || (
                <Link text={t(link.title || link.name)} {...props} {...getExternalLinkProps(link)} />
            )}
            {popupProps.opened && <IframeContentPopup {...popupProps} className="external-link-iframe-popup" />}
        </>
    );
};

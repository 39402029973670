import { END_DATE, START_DATE } from '@datepicker-react/hooks';
import dayjs from 'dayjs';
import { Button, List } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { Input } from './InputButton';
import { MultiInputProps } from './MultiInput.types';

import './MultiInput.less';

export const MultiInput = ({
    value1,
    placeholder1,
    value2,
    placeholder2,
    focusCb,
    single,
    isAnimated,
    expandCalendar,
    disabled = false,
    withArrow,
    secondInputDisabled,
    required,
    styleType,
    fitLabelLength,
}: MultiInputProps): JSX.Element => {
    const handleInputButton1Click = useCallback(() => {
        focusCb(START_DATE);
        expandCalendar((value) => (value ? value : !value));
    }, [expandCalendar, focusCb]);

    const handleInputButton2Click = useCallback(() => {
        focusCb(value1 !== '' ? END_DATE : START_DATE);
        expandCalendar((value) => (value ? value : !value));
    }, [expandCalendar, focusCb, value1]);

    const singleValue = useMemo(() => {
        if (single) {
            if (value1) {
                const date1 = dayjs(value1, 'MMM DD, YYYY').toDate();
                const date2 = dayjs(value2, 'MMM DD, YYYY').toDate();
                if (date1.getFullYear() === date2.getFullYear()) {
                    return value1.slice(0, value1.indexOf(',')) + ' - ' + value2;
                }
                return value1 + ' - ' + value2;
            }
        }
        return '';
    }, [single, value1, value2]);

    return (
        <Button disabled={disabled} className="datepicker-multi__input">
            <List className="datepicker-multi__input__list">
                {single ? (
                    <Input
                        required={required}
                        withArrow={withArrow}
                        single
                        classNames={['input-single', styleType]}
                        placeholder={placeholder1 + '/' + placeholder2}
                        onClick={handleInputButton1Click}
                        value={singleValue}
                        isAnimated={isAnimated}
                        disabled={disabled}
                        size={fitLabelLength ? singleValue?.length - 1 || 1 : undefined}
                    />
                ) : (
                    <>
                        <Input
                            required={required}
                            withArrow={withArrow}
                            placeholder={placeholder1}
                            onClick={handleInputButton1Click}
                            value={value1}
                            disabled={disabled}
                        />
                        <Input
                            required={required}
                            withArrow={withArrow}
                            placeholder={placeholder2}
                            onClick={handleInputButton2Click}
                            value={value2}
                            disabled={disabled || secondInputDisabled}
                        />
                    </>
                )}
            </List>
        </Button>
    );
};

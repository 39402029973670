import { ProductType } from '@/types/productType';
import { EProductTypeQuery } from '@/types/URLParams';

export const getProductTypeByQuery = (routeQuery: EProductTypeQuery) => {
    switch (routeQuery) {
        case EProductTypeQuery.OFFERS:
            return ProductType.Advertisement;
        case EProductTypeQuery.SERVICES:
            return ProductType.Service;
        default:
            return ProductType.Product;
    }
};

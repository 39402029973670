import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationProps } from './Translation.types';

import { IcTranslate } from '@/assets';

import './Translation.less';

export const Translation = React.memo<TranslationProps>(({ translated, onClick, source = 'Google' }) => {
    const { t } = useTranslation();

    return (
        <div className="translation">
            <p className={cn({ 'translation-translated': translated })}>
                <IcTranslate />
                {translated ? (
                    <>
                        <span>
                            {t('Translated by')} {source}
                        </span>
                        <span onClick={onClick}>{t('See Original')}</span>
                    </>
                ) : (
                    <span onClick={onClick}>{t('See Translation')}</span>
                )}
            </p>
        </div>
    );
});
Translation.displayName = 'Translation';

import { useEffect } from 'react';

import { loadProductListType } from '@/actions/productActions';
import { useAppDispatch } from '@/hooks/store';

import type { ProductListType } from '@/reducers/productReducer';

export const useLoadProducts = (productType: ProductListType): void => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadProductListType(productType));
    }, [dispatch, productType]);
};

import {
    PAYOUT_BANK_MESSAGE_LOADING,
    PAYOUT_BANK_MESSAGE_LOADING_ERROR,
    PAYOUT_BANK_MESSAGE_LOADING_SUCCESS,
    PAYOUT_SETTINGS_LOADING,
    PAYOUT_SETTINGS_LOADING_ERROR,
    PAYOUT_SETTINGS_LOADING_SUCCESS,
    PAYOUT_SETTINGS_REMOVE_SUCCESS,
    PAYOUT_SETTINGS_REMOVING,
    PAYOUT_SETTINGS_UPDATE_SUCCESS,
    PAYOUT_SETTINGS_UPDATING,
    PayoutActions,
} from '@/actions/payoutsActions';
import { AccountPayoutSettings } from '@/generated/commonapi';

export type PayoutInterval = 'weekly' | 'bi-weekly' | 'monthly';

export interface IPayoutsState {
    loading: boolean;
    updating: boolean;
    error: unknown;
    settings: AccountPayoutSettings;
    messageLoading: boolean;
    message: string | null;
}

const initialState: IPayoutsState = {
    loading: false,
    updating: false,
    settings: undefined,
    error: undefined,
    messageLoading: false,
    message: undefined,
};

const payoutsReducer = (state = initialState, action: PayoutActions): IPayoutsState => {
    switch (action.type) {
        case PAYOUT_BANK_MESSAGE_LOADING:
            return {
                ...state,
                messageLoading: true,
                error: undefined,
            };
        case PAYOUT_SETTINGS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case PAYOUT_SETTINGS_UPDATING:
        case PAYOUT_SETTINGS_REMOVING:
            return {
                ...state,
                updating: true,
                error: undefined,
            };
        case PAYOUT_SETTINGS_LOADING_SUCCESS:
            if (Object.keys(action.settings).length === 0) {
                action.settings = initialState.settings;
            }
            return {
                ...state,
                loading: false,
                settings: action.settings,
            };
        case PAYOUT_BANK_MESSAGE_LOADING_SUCCESS:
            return {
                ...state,
                messageLoading: false,
                message: action.message,
                error: undefined,
            };
        case PAYOUT_SETTINGS_UPDATE_SUCCESS:
            return {
                ...state,
                updating: false,
                settings: action.settings,
            };
        case PAYOUT_SETTINGS_REMOVE_SUCCESS:
            initialState.settings.pspCode = undefined;
            return {
                ...state,
                updating: false,
                settings: undefined,
            };
        case PAYOUT_BANK_MESSAGE_LOADING_ERROR:
        case PAYOUT_SETTINGS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                updating: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default payoutsReducer;

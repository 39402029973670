import { isValidDate } from '../dateTime/isValidDate';
import { toLocaleISOString } from '../dateTime/toLocaleISOString';

export const dateToProductDate = (date: Date | undefined, withLocaleISO = false) => {
    if (!isValidDate(date)) {
        return '';
    }

    const dateString = !withLocaleISO ? date.toISOString() : toLocaleISOString(date);

    return dateString.replace(/\.\d+Z$/, '');
};

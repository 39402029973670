import { Link } from 'framework7-react';
import React from 'react';

import { ShareButtonProps } from './ShareButton.types';

import { IcShare, IcShareLarge } from '@/assets';

import './ShareButton.less';

export const ShareButton = ({ large = false, ...props }: ShareButtonProps): JSX.Element => (
    <Link className="share-button" href="#" iconOnly {...props}>
        {large ? <IcShareLarge /> : <IcShare />}
    </Link>
);

import { ChosenSdekDelivery } from '@/components/SdekMap/SdekMap.types';
import { CartItem, PlatformDeliveryMethod } from '@/generated/marketplaceapi';
import { CartItemParameterCode } from '@/types/cartItem';

export const changeChosenSdekDeliveryInCartItem = (
    cartItem: CartItem,
    { deliveryPoint, tariff }: Partial<ChosenSdekDelivery> = {},
): CartItem => {
    const parameters =
        deliveryPoint && tariff
            ? [
                  {
                      code: CartItemParameterCode.CdekTariffCode,
                      value: String(tariff?.tariffCode),
                  },
                  {
                      code: CartItemParameterCode.CdekAddressLine,
                      value: deliveryPoint.location?.address,
                  },
                  {
                      code: CartItemParameterCode.CdekCity,
                      value: deliveryPoint.location?.city,
                  },
                  {
                      code: CartItemParameterCode.CdekDeliveryAddress,
                      value: deliveryPoint.location?.address_full,
                  },
                  {
                      code: CartItemParameterCode.CdekDeliveryType,
                      value: deliveryPoint.type,
                  },
                  {
                      code: CartItemParameterCode.CdekDeliveryMaxDays,
                      value: String(tariff.calendarMax),
                  },
                  {
                      code: CartItemParameterCode.CdekDeliveryMinDays,
                      value: String(tariff.calendarMin),
                  },
              ]
            : [];

    if (deliveryPoint?.type === 'PVZ' || deliveryPoint?.type === 'DROP_POINT' || deliveryPoint?.type === 'POSTAMAT') {
        parameters.push({
            code: CartItemParameterCode.CdekDropPointCode,
            value: deliveryPoint.code,
        });
    }

    if (deliveryPoint.location?.latitude && deliveryPoint.location?.longitude) {
        parameters.push({
            code: CartItemParameterCode.CdekDeliveryCoordinats,
            value: `${deliveryPoint.location.latitude},${deliveryPoint.location.longitude}`,
        });
    }

    return {
        ...cartItem,
        platformDeliveryMethod: {
            deliveryProvider: {
                uid: cartItem.platformDeliveryMethod?.deliveryProvider?.uid,
            },
            deliveryType: tariff.deliveryType as unknown as PlatformDeliveryMethod.DeliveryTypeEnum,
        },
        deliveryCountryCode: deliveryPoint?.location?.country_code,
        deliveryPostalCode: deliveryPoint?.location?.postal_code,
        deliveryMethodOption: undefined,
        deliveryPrice: undefined,
        deliveryPriceCalculated: undefined,
        deliveryPriceCurrencyCode: undefined,
        parameters,
    };
};

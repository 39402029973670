import {
    CART_ALLOW_SELECT_ALL,
    CART_DISALLOW_SELECT_ALL,
    CART_ERROR,
    CART_LOAD,
    CART_LOAD_SUCCESS,
    CART_REMOVE,
    CART_SET_SELECTED,
    CartActions,
} from '@/actions/cartActions';
import { CartItem } from '@/generated/marketplaceapi';

export interface ICartState {
    isFetching: boolean;
    error: {
        message?: unknown;
    };
    items: CartItem[];
    selected: string[];
    shouldForceSelectAll: boolean;
}

const initialState: ICartState = {
    isFetching: false,
    error: undefined,
    items: [],
    selected: [],
    shouldForceSelectAll: true,
};

const cartReducer = (state = initialState, action: CartActions): ICartState => {
    switch (action.type) {
        case CART_LOAD: {
            return { ...state, error: undefined, isFetching: true };
        }
        case CART_REMOVE: {
            const { uids } = action.payload;
            return {
                ...state,
                items: state.items.filter(({ itemUid }) => !uids.includes(itemUid)),
                selected: state.selected.filter((selectedUid) => !uids.includes(selectedUid)),
            };
        }
        case CART_ERROR: {
            const { error } = action.payload;
            return { ...state, error, isFetching: false };
        }
        case CART_LOAD_SUCCESS: {
            const { items } = action.payload;
            return {
                ...state,
                items,
                error: undefined,
                isFetching: false,
            };
        }
        case CART_SET_SELECTED: {
            const { selected } = action.payload;
            return { ...state, selected };
        }
        case CART_ALLOW_SELECT_ALL:
            return { ...state, shouldForceSelectAll: true };
        case CART_DISALLOW_SELECT_ALL:
            return { ...state, shouldForceSelectAll: false };
        default:
            return state;
    }
};

export default cartReducer;

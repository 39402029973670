import { loadCategories } from './categoryActions';
import { loadProductWishList, reloadAllProductListTypes } from './productActions';

import { ProductType } from '@/types/productType';

export const F7_INIT = 'F7_INIT';
export const INIT_ENTRY_PAGE_NAME = 'INIT_ENTRY_PAGE_NAME';
export const LOCAL_CONFIG_LOADED = 'LOCAL_CONFIG_LOADED';
export const LOCAL_CONFIG_UPDATED = 'LOCAL_CONFIG_UPDATED';
export const ON_RESIZE_EVENT = 'ON_RESIZE_EVENT';
export const CHANGE_ACTIVE_PRODUCT_TYPE = 'CHANGE_ACTIVE_PRODUCT_TYPE';

export const handleResizeEvent = (width: number, height: number) => ({
    type: ON_RESIZE_EVENT,
    payload: { width, height },
});

export const changeActiveProductType = (activeProductType: ProductType) => ({
    type: CHANGE_ACTIVE_PRODUCT_TYPE,
    payload: { activeProductType },
});

export const updateProductType = (productType: ProductType) => (dispatch) => {
    dispatch(changeActiveProductType(productType));

    dispatch(reloadAllProductListTypes());
    dispatch(loadCategories());
    dispatch(loadProductWishList());
};

export type RootActions = ReturnType<typeof changeActiveProductType | typeof handleResizeEvent>;

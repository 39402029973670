import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AccountStatus } from '@/types/account';

export const useAccountStatusText = (status: AccountStatus) => {
    const { t } = useTranslation();
    const statusText = useMemo(() => {
        switch (status) {
            case AccountStatus.SC:
                return t('Not verified');
            case AccountStatus.ST:
                return t('Verification required');
            case AccountStatus.SF:
                return t('Verified');
            default:
                return '';
        }
    }, [status, t]);

    return statusText;
};

import cn from 'classnames';
import { Navbar, NavLeft, NavTitle } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FavoritesPageProps } from './FavoritesPage.types';

import { AppNavBar } from '@/components/AppNavBar';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { Footer } from '@/components/Footer';
import { NotAvailableMessage } from '@/components/NotAvailableMessage';
import { PersonalAreaBackLink } from '@/components/PersonalAreaBackLink';
import { StoreProductsGroup } from '@/components/StoreProductsGroup';
import { LoadFavorites } from '@/hoc/LoadFavorites';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';

import './FavoritesPage.less';
import { ProductType } from '@/types/productType';

const FavoritesPageInner = ({ f7router }: FavoritesPageProps): JSX.Element => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);
    const activeProductType = useAppSelector(getActiveProductType);

    const { stores } = useAppSelector((state) => state.favoritesReducer);

    return (
        <CustomPage loggedOnly className={cn('favorites-page', { mobile: isMobile })}>
            <AppNavBar
                className="favorites-page__navbar"
                slot="fixed"
                f7router={f7router}
                navbar={{
                    showSearchbar: true,
                }}
            />
            <Navbar className="favorites-page__navbar-mobile" slot="fixed" noHairline noShadow>
                <NavLeft>
                    <PersonalAreaBackLink />
                </NavLeft>
                <NavTitle>
                    {activeProductType === ProductType.Advertisement
                        ? t('c2c.page.favorite_sellers.header')
                        : t('Favorite Stores')}
                </NavTitle>
            </Navbar>
            <LoadFavorites />
            <div className={cn('favorites-page__content', { empty: !stores.length })}>
                {stores.length ? (
                    <>
                        {!isMobile && (
                            <h1 className="favorites-page__title">
                                {activeProductType === ProductType.Advertisement
                                    ? t('c2c.page.favorite_sellers.header')
                                    : t('Favorite Stores')}
                            </h1>
                        )}
                        {stores.map((store, index) => (
                            <StoreProductsGroup
                                key={store.uid ?? index}
                                store={store}
                                className="favorites-page__store"
                            />
                        ))}
                    </>
                ) : (
                    <NotAvailableMessage
                        title={t('No Favorites Yet')}
                        subtitle={
                            activeProductType === ProductType.Advertisement
                                ? t('c2c.page.favorite_sellers.description')
                                : t('Add stores to favorites for quick access to it')
                        }
                    />
                )}
            </div>

            <Footer className="favorites-page__footer" />
        </CustomPage>
    );
};

export const FavoritesPage = ConnectPageWrapper(FavoritesPageInner);

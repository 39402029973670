import { Router } from 'framework7/modules/router/router';

import { GeneralInfoValues } from './GeneralInfo/GeneralInfo.types';

import { SaleProduct } from '@/generated/marketplaceapi';

export type ConfigureSaleProps = {
    f7router: Router.Router;
    isEdit?: boolean;
    saleUid?: string;
    isRestart?: boolean;
};

export enum ConfigureSaleSteps {
    GENERAL_INFO,
    PRODUCTS_SELECT,
}

export type ConfigureSaleDraft = {
    [ConfigureSaleSteps.GENERAL_INFO]: GeneralInfoValues;
    [ConfigureSaleSteps.PRODUCTS_SELECT]: { uid?: string; products?: SaleProduct[] };
};

export type ConfigureSaleStepProps = {
    containerID?: string;
    handleValidity: (vallidity: boolean) => void;
    handleDraftChange: (values: Partial<ConfigureSaleDraft[keyof ConfigureSaleDraft]>) => void;
    draftInitial: ConfigureSaleDraft[keyof ConfigureSaleDraft];
};

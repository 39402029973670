import { isValidABA } from './isValidABA';
import { isValidBICorSwiftCode } from './isValidBICorSwiftCode';

import { BankCodes } from '@/types/bankCodes';

export const isValidSWIFTorABAorBIC = (code: string, codeType: BankCodes) => {
    switch (codeType) {
        case BankCodes.SWIFT:
        case BankCodes.BIC:
            return isValidBICorSwiftCode(code);
        case BankCodes.ABA:
            return isValidABA(code);
    }
};

import { Block, BlockTitle } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MappingItemProps } from './MappingItem.types';

import { IcMapping, IcMenu } from '@/assets';
import { CategoriesAccordion } from '@/components/CategoriesAccordion';
import { CategorySheet } from '@/components/CategorySheet/CategorySheet';
import { CustomInput } from '@/components/CustomInput';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getFullCategoryName } from '@/utils';

import './MappingItem.less';

export const MappingItem: React.FC<MappingItemProps> = ({ item }) => {
    const { t } = useTranslation();

    const flatCategories = useAppSelector((state) => state.categoryReducer.flat);
    const [accordeonOpened, setAccordeonOpened] = useState(false);
    const [openCategoriesSelect, , toggleCategoriesSelect] = useBooleanState(setAccordeonOpened);
    const getCategoryNameByCode = useCallback(
        (code: string) => getFullCategoryName(flatCategories, code, t),
        [flatCategories, t],
    );
    const [isSelectCategorySheetOpened, setIsSelectCategorySheetOpened] = useState(false);
    const [openSelectCategorySheet, closeSelectCategorySheet] = useBooleanState(setIsSelectCategorySheetOpened);

    const isMobile = useAppSelector(getIsMobile);

    const {
        input: { value, ...input },
    } = useField<string>(`key${item.externalCategoryCode}.internalCategoryCode`);

    return (
        <Block className="mapping-item">
            <div className="mapping-item-header">
                <BlockTitle>{item.externalCategoryName}</BlockTitle>
                <IcMapping />
            </div>
            <div className="categories-accordion-wrapper">
                <CustomInput
                    readonly
                    value={getCategoryNameByCode(value)}
                    label={`${t('Mapping to')}`}
                    requiredSign
                    onFocus={openCategoriesSelect}
                />
                <IcMenu onClick={isMobile ? openSelectCategorySheet : toggleCategoriesSelect} />
            </div>
            {isMobile ? (
                <CategorySheet
                    opened={isSelectCategorySheetOpened}
                    onSheetOpened={openSelectCategorySheet}
                    onSheetClosed={closeSelectCategorySheet}
                    onCategoryClick={(cat) => input.onChange(cat.categoryCode)}
                />
            ) : (
                <CategoriesAccordion
                    {...input}
                    opened={accordeonOpened}
                    setOpened={setAccordeonOpened}
                    selectedValue={value}
                />
            )}
        </Block>
    );
};

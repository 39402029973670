import cn from 'classnames';
import { Button, f7, Link, Navbar, NavRight } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { VerifyAccountPopupProps } from './VerifyAccountPopup.types';

import { IcClose, IcPassport } from '@/assets';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';

import './VerifyAccountPopup.less';

export const VerifyAccountPopup = React.memo(
    ({ className, opened = false, onPopupClosed }: VerifyAccountPopupProps) => {
        const { t } = useTranslation();

        const handleOpenPassportClick = useCallback(() => {
            f7.view.main.router.navigate('/profile/personal/verify');
            onPopupClosed();
        }, [onPopupClosed]);

        return (
            <MSPopup className={cn('verify-account-popup', className)} opened={opened} onBackdropClick={onPopupClosed}>
                <Navbar noShadow noHairline>
                    <NavRight>
                        <Link onClick={onPopupClosed}>
                            <IcClose />
                        </Link>
                    </NavRight>
                </Navbar>
                <div className="verify-account-popup__content">
                    <IcPassport />
                    <h3>{t('Verify Your Account')}</h3>
                    <p>
                        {t(
                            'Account verification takes a few minutes. It is mandatory for Sellers willing to accept payments using {{appName}} services.',
                            { appName: process.env.APP_NAME },
                        )}
                    </p>
                    <Button fill round onClick={handleOpenPassportClick}>
                        {t('Open Passport')}
                    </Button>
                </div>
            </MSPopup>
        );
    },
);
VerifyAccountPopup.displayName = 'VerifyAccountPopup';

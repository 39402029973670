import { f7 } from 'framework7-react';
import i18next from 'i18next';

export const openConfirm = (text: string, callbackOk?: () => void, callbackCancel?: () => void) => {
    f7.dialog
        .create({
            title: process.env.APP_NAME,
            text,
            buttons: [
                {
                    text: i18next.t('common_ui_element.button.cancel'),
                    onClick: () => callbackCancel?.(),
                },
                {
                    text: i18next.t('common_ui_element.button.ok'),
                    bold: true,
                    onClick: () => callbackOk?.(),
                },
            ],
        })
        .open();
};

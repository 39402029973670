import { ProductVideo } from '@/generated/marketplaceapi';
import { parseProvider } from '@/utils/parsers/parseProvider';

const YOUTU_REGEXP = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)?([\w-]{11})/i;
const YOUTUBE_REGEXP = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)?([\w-]{11})/i;
const VIMEO_REGEXP =
    /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\d+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i;
const VK_REGEXP = /(vk(?:cdn|pro)?)\.com\/(?:(?:video)([\d+_d+]+))/i;

// Parse URL helpers
const supportedProvidersRegexes: Record<
    string,
    { parse: (url: string) => { id: string; type: ProductVideo.TypeEnum } | null }
> = {
    youtu: {
        parse: (url: string) => {
            const match = YOUTU_REGEXP.exec(url);
            if (!match) {
                return null;
            }
            return { id: match[1], type: ProductVideo.TypeEnum.YOUTUBE };
        },
    },
    youtube: {
        parse: (url: string) => {
            const match = YOUTUBE_REGEXP.exec(url);
            if (!match) {
                return null;
            }
            return { id: match[1], type: ProductVideo.TypeEnum.YOUTUBE };
        },
    },
    vimeo: {
        parse: (url: string) => {
            const match = VIMEO_REGEXP.exec(url);
            if (!match) {
                return null;
            }
            return { id: match[2], type: ProductVideo.TypeEnum.VIMEO };
        },
    },
    vk: {
        parse: (url: string) => {
            const match = VK_REGEXP.exec(url);
            if (!match) {
                return null;
            }
            return { id: match[2], type: ProductVideo.TypeEnum.VK };
        },
    },
};

export const parseVideoURL = (url: string) => {
    const provider = parseProvider(url);
    if (provider && provider in supportedProvidersRegexes) {
        const providerRegex = supportedProvidersRegexes[provider];
        return providerRegex.parse(url);
    }
    return undefined;
};

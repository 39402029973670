import cn from 'classnames';
import { Button, List, ListItem, Sheet } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SourceSelectSheetButtonProps } from './SourceSelectSheetButton.types';

import { IcDocumentStroke, IcLink } from '@/assets';
import { RadioField } from '@/components/Fields/RadioField';
import { useBooleanState } from '@/hooks/useBooleanState';
import { ImportContentType } from '@/pages/ImportProducts/ImportProducts.types';
import { Sheets } from '@/types/sheets';

import './SourceSelectSheetButton.less';

export const SourceSelectSheetButton: React.FC<SourceSelectSheetButtonProps> = ({
    buttonProps = {},
    fileFieldName,
    acceptParams,
    sourceTypeFieldName,
    onUrlOptionClick,
}) => {
    const { t } = useTranslation();
    const [opened, setOpened] = useState(false);
    const [open, close] = useBooleanState(setOpened);

    const {
        input: { onChange: onFileChange, onBlur, onFocus },
    } = useField<File | undefined>(fileFieldName);

    const {
        input: { onChange: onChangeSourceType },
    } = useField<string>(sourceTypeFieldName, { type: 'radio', value: ImportContentType.file });

    const handleFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            onFocus(event);
            onChangeSourceType(ImportContentType.file);
        },
        [onChangeSourceType, onFocus],
    );

    const handleChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onFileChange(target.files?.[0]);
            close();
        },
        [close, onFileChange],
    );

    const handleUrlClick = useCallback(() => {
        close();
        onUrlOptionClick();
    }, [close, onUrlOptionClick]);

    return (
        <>
            <Button
                {...buttonProps}
                onClick={open}
                className={cn('transparent-source-sheet-button', buttonProps?.className)}
            />
            <Sheet className={Sheets.SOURCE_SELECT_SHEET} onSheetClose={close} opened={opened}>
                <List mediaList>
                    <ListItem value={ImportContentType.file} name={sourceTypeFieldName} title={`${t('Choose a File')}`}>
                        <IcDocumentStroke slot="media" />
                        <input
                            onBlur={onBlur}
                            className="hidden-input"
                            accept={acceptParams}
                            type="file"
                            onFocus={handleFocus}
                            onChange={handleChange}
                        />
                    </ListItem>
                    <RadioField
                        value={ImportContentType.url}
                        name={sourceTypeFieldName}
                        className="source-type-option"
                        onClick={handleUrlClick}
                    >
                        {t('Add a Link')}
                        <IcLink slot="media" />
                    </RadioField>
                </List>
            </Sheet>
        </>
    );
};

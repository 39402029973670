import {
    ACCOUNT_SETTINGS_ERROR,
    ACCOUNT_SETTINGS_LOADING,
    ACCOUNT_SETTINGS_UPDATE_SUCCESS,
    AccountSettingsActions,
} from '@/actions/accountSettingsActions';
import {
    ACCOUNT_ADD_UPDATE_ADDRESS,
    ACCOUNT_ADD_UPDATE_ADDRESS_ERROR,
    ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS,
    ACCOUNT_REMOVE_ADDRESS,
    ACCOUNT_REMOVE_ADDRESS_ERROR,
    ACCOUNT_REMOVE_ADDRESS_SUCCESS,
    ProfileActions,
} from '@/actions/profileActions';
import {
    AUTHORIZATION_CODE_ERROR,
    AUTHORIZATION_CODE_LOADING,
    AUTHORIZATION_CODE_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PROFILE_REGION,
    ONETIME_PASSWORD_ERROR,
    ONETIME_PASSWORD_LOADING,
    ONETIME_PASSWORD_LOGIN_ERROR,
    ONETIME_PASSWORD_LOGIN_LOADING,
    ONETIME_PASSWORD_LOGIN_SUCCESS,
    ONETIME_PASSWORD_SUCCESS,
    REGISTRATION_ERROR,
    REGISTRATION_ERROR_CODE_SAVE,
    REGISTRATION_LOADING,
    REGISTRATION_SUCCESS,
    SESSION_ERROR,
    SESSION_LOADING,
    SESSION_LOGOUT,
    SESSION_REGISTER_LOGIN_TRY,
    SESSION_RESTORING,
    SESSION_SUCCESS,
    SessionActions,
    TRANSFORM_TO_BUSINESS_ACCOUNT_SUCCESS,
    UPDATE_BUSINESS_ACCOUNT_ERROR,
    UPDATE_BUSINESS_ACCOUNT_LOADING,
    UPDATE_BUSINESS_ACCOUNT_SUCCESS,
    VERIFICATION_EMAIL_CODE_ERROR,
    VERIFICATION_EMAIL_CODE_LOADING,
    VERIFICATION_EMAIL_CODE_SUCCESS,
    VERIFICATION_ERRORS_RESET,
    VERIFICATION_RESEND_ERROR,
    VERIFICATION_RESEND_LOADING,
    VERIFICATION_RESEND_SUCCESS,
    TRANSFORM_TO_BUSINESS_ACCOUNT_ERROR,
    TRANSFORM_TO_BUSINESS_ACCOUNT_LOADING,
} from '@/actions/sessionActions';
import { Account, CommonApiResponse, CreatePersonalAccountRequest } from '@/generated/commonapi';

export type TokenResponse = CommonApiResponse & {
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    active: boolean;
};

export type Profile = Account;

export interface CreateAccountRequest extends CreatePersonalAccountRequest {
    passwordRepeat: string;
    accept: boolean;
    referralCode: string;
    city?: string;
    addressLine?: string;
    postalCode?: string;
}

export type CreateAccountField = keyof CreateAccountRequest;

export type SessionProfile = Profile & { profileColorNumber?: number };

export interface ICreateAccountFormError {
    message: string;
    parameters: CreateAccountField[];
}

export interface ISessionState {
    restoring: boolean;
    loading: boolean;
    profile?: SessionProfile;
    error: unknown;
    logged: boolean;
    registered: boolean;
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    triedToLogin: boolean;
    accountAddressState: {
        accountAddOrUpdateAddressLoading?: boolean;
        accountAddOrUpdateAddressError?: unknown;
        accountRemoveAddressLoading?: boolean;
        accountRemoveAddressError?: boolean;
    };

    registrationLoading: boolean;
    registrationError: unknown;
    registrationErrorCode?: number;

    emailVerificationLoading?: boolean;
    emailVerificationSuccess?: boolean;
    emailVerificationProfile?: SessionProfile;
    emailVerificationError?: string;

    resendVerificationLoading?: boolean;
    resendVerificationSuccess?: boolean;
    resendVerificationError?: string;

    authorizationCodeLoading: boolean;
    authorizationCode?: string;
    authorizationCodeError?: unknown;

    passwordResetLoading: boolean;
    passwordLoginLoading: boolean;
    passwordChangeLoading: boolean;
    passwordError: string;

    settingsLoading: boolean;
    settingsError: string;

    transformToBusinessAccountLoading?: boolean;
    transformToBusinessAccountError?: string;

    updateBusinessAccountLoading?: boolean;
    updateBusinessAccountError?: string;
}

const initialState: ISessionState = {
    restoring: false,
    loading: false,
    profile: null,
    error: null,
    logged: false,
    registered: false,
    accessToken: null,
    expiresIn: null,
    refreshToken: null,
    triedToLogin: false,
    accountAddressState: {
        accountAddOrUpdateAddressLoading: false,
        accountAddOrUpdateAddressError: null,
        accountRemoveAddressLoading: false,
        accountRemoveAddressError: null,
    },
    registrationLoading: false,
    registrationError: undefined,
    authorizationCodeLoading: false,
    authorizationCode: null,
    authorizationCodeError: null,
    passwordResetLoading: false,
    passwordLoginLoading: false,
    passwordChangeLoading: false,
    passwordError: null,
    settingsLoading: false,
    settingsError: undefined,
};

const sessionReducer = (
    state = initialState,
    action: SessionActions | ProfileActions | AccountSettingsActions,
): ISessionState => {
    switch (action.type) {
        case SESSION_RESTORING:
            return {
                ...state,
                restoring: true,
            };
        case SESSION_LOADING:
            return {
                ...state,
                restoring: false,
                loading: true,
                error: null,
            };
        case SESSION_SUCCESS:
            return {
                ...state,
                restoring: false,
                loading: false,
                profile: { ...action.profile, profileColorNumber: Math.floor(10 * Math.random()) },
                error: null,
                logged: !!action.profile,
                ...action.authData,
            };
        case SESSION_ERROR:
            return {
                ...state,
                restoring: false,
                loading: false,
                profile: null,
                error: action.error,
                logged: false,
            };
        case SESSION_LOGOUT:
            return { ...initialState };
        case SESSION_REGISTER_LOGIN_TRY:
            return {
                ...state,
                triedToLogin: true,
            };
        case REGISTRATION_LOADING:
            return {
                ...state,
                restoring: false,
                loading: false,
                error: null,
                logged: false,
                registered: false,
                registrationLoading: true,
                registrationError: undefined,
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                restoring: false,
                loading: false,
                //profile: action.profile,
                error: null,
                logged: false,
                registered: true,
                registrationLoading: false,
                registrationError: undefined,
            };
        case REGISTRATION_ERROR:
            return {
                ...state,
                restoring: false,
                loading: false,
                profile: null,
                logged: false,
                registered: false,
                registrationLoading: false,
                registrationError: action.error,
            };

        case REGISTRATION_ERROR_CODE_SAVE:
            return { ...state, registrationErrorCode: action.code };

        case ONETIME_PASSWORD_LOADING:
            return {
                ...state,
                passwordError: null,
                logged: false,
                profile: null,
                passwordResetLoading: true,
            };
        case ONETIME_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordError: null,
                logged: false,
                profile: null,
                passwordResetLoading: false,
            };
        case ONETIME_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.error,
                logged: false,
                profile: null,
                passwordResetLoading: false,
            };
        case ONETIME_PASSWORD_LOGIN_LOADING:
            return {
                ...state,
                passwordError: null,
                logged: false,
                profile: null,
                passwordLoginLoading: true,
            };
        case ONETIME_PASSWORD_LOGIN_SUCCESS:
            return {
                ...state,
                logged: false,
                profile: null,
                passwordError: null,
                passwordLoginLoading: false,
                ...action.authData,
            };
        case ONETIME_PASSWORD_LOGIN_ERROR:
            return {
                ...state,
                passwordError: action.error,
                logged: false,
                profile: null,
                passwordLoginLoading: false,
            };
        case CHANGE_PASSWORD_LOADING:
            return {
                ...state,
                passwordError: null,
                passwordChangeLoading: true,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...initialState,
                passwordError: null,
                passwordChangeLoading: false,
            };
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                passwordError: action.error,
                passwordChangeLoading: false,
            };
        case ACCOUNT_ADD_UPDATE_ADDRESS_SUCCESS:
        case ACCOUNT_REMOVE_ADDRESS_SUCCESS: {
            const { addresses } = action;
            const { profile } = state;

            if (!profile) {
                return state;
            }

            profile.addresses = [...addresses];

            return {
                ...state,
                profile: { ...profile },
                accountAddressState: {
                    ...state.accountAddressState,
                    accountAddOrUpdateAddressLoading: false,
                    accountAddOrUpdateAddressError: null,
                },
            };
        }
        case ACCOUNT_ADD_UPDATE_ADDRESS: {
            return {
                ...state,
                accountAddressState: {
                    ...state.accountAddressState,
                    accountAddOrUpdateAddressLoading: true,
                    accountAddOrUpdateAddressError: null,
                },
            };
        }
        case ACCOUNT_ADD_UPDATE_ADDRESS_ERROR: {
            return {
                ...state,
                accountAddressState: {
                    ...state.accountAddressState,
                    accountAddOrUpdateAddressLoading: false,
                    accountAddOrUpdateAddressError: action.error,
                },
            };
        }
        case ACCOUNT_REMOVE_ADDRESS: {
            return {
                ...state,
                accountAddressState: {
                    ...state.accountAddressState,
                    accountRemoveAddressLoading: true,
                    accountRemoveAddressError: null,
                },
            };
        }
        case ACCOUNT_REMOVE_ADDRESS_ERROR: {
            return {
                ...state,
                accountAddressState: {
                    ...state.accountAddressState,
                    accountRemoveAddressLoading: false,
                    accountRemoveAddressError: !!action.error,
                },
            };
        }
        case AUTHORIZATION_CODE_LOADING:
            return {
                ...state,
                authorizationCodeLoading: true,
                authorizationCode: null,
                authorizationCodeError: null,
            };
        case AUTHORIZATION_CODE_SUCCESS:
            return {
                ...state,
                authorizationCodeLoading: false,
                authorizationCode: action.code,
                authorizationCodeError: null,
            };
        case AUTHORIZATION_CODE_ERROR:
            return {
                ...state,
                authorizationCodeLoading: false,
                authorizationCode: null,
                authorizationCodeError: action.error,
            };
        case TRANSFORM_TO_BUSINESS_ACCOUNT_LOADING:
            return { ...state, transformToBusinessAccountLoading: action.transformToBusinessAccountLoading };
        case TRANSFORM_TO_BUSINESS_ACCOUNT_SUCCESS:
            return {
                ...state,
                profile: action.profile,
                transformToBusinessAccountError: undefined,
            };
        case TRANSFORM_TO_BUSINESS_ACCOUNT_ERROR:
            return { ...state, transformToBusinessAccountError: action.error };
        case UPDATE_BUSINESS_ACCOUNT_LOADING:
            return { ...state, updateBusinessAccountLoading: action.updateBusinessAccountLoading };
        case UPDATE_BUSINESS_ACCOUNT_SUCCESS:
            return { ...state, profile: action.profile, updateBusinessAccountError: undefined };
        case UPDATE_BUSINESS_ACCOUNT_ERROR:
            return { ...state, updateBusinessAccountError: action.error };
        case ACCOUNT_SETTINGS_LOADING:
            return {
                ...state,
                settingsError: undefined,
                settingsLoading: true,
            };
        case ACCOUNT_SETTINGS_ERROR:
            return {
                ...state,
                settingsLoading: false,
                settingsError: action.error,
            };
        case ACCOUNT_SETTINGS_UPDATE_SUCCESS:
            return {
                ...state,
                settingsLoading: false,
                settingsError: undefined,
                profile: {
                    ...state.profile,
                    ...action.account,
                },
            };
        case VERIFICATION_EMAIL_CODE_LOADING:
            return { ...state, emailVerificationLoading: action.emailCodeLoading };
        case VERIFICATION_EMAIL_CODE_SUCCESS:
            return { ...state, emailVerificationSuccess: true, emailVerificationProfile: action.profile };

        case VERIFICATION_EMAIL_CODE_ERROR:
            return { ...state, emailVerificationError: action.error };

        case VERIFICATION_RESEND_LOADING:
            return { ...state, resendVerificationLoading: action.emailCodeLoading };
        case VERIFICATION_RESEND_SUCCESS:
            return { ...state, resendVerificationSuccess: true };

        case VERIFICATION_RESEND_ERROR:
            return { ...state, resendVerificationError: action.error };

        case VERIFICATION_ERRORS_RESET:
            return {
                ...state,
                emailVerificationError: undefined,
                resendVerificationError: undefined,
            };
        case CHANGE_PROFILE_REGION: {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    regionCode: action.region,
                },
            };
        }
        default:
            return state;
    }
};

export default sessionReducer;

import { isPhone } from './isPhone';

import { PhoneInputValue } from '@/components/PhoneInput';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { AccountPhoneWsControllerApi } from '@/generated/commonapi';
import { NOT_PHONE_ERROR, PHONE_ALREADY_IN_USE_ERROR } from '@/shared/constants';
import { combinePhone } from '@/utils/combinePhone';

export const validatePhoneAvailability = async (phone: PhoneInputValue, accountUid: string) => {
    try {
        if (phone && isPhone(combinePhone(phone?.label, phone?.phoneNumber))) {
            const response = await new AccountPhoneWsControllerApi().checkAccountPhoneUsingPOST({
                fullNumber: combinePhone(phone?.label, phone?.phoneNumber),
                accountUid,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            return response.available ? undefined : PHONE_ALREADY_IN_USE_ERROR;
        }

        return NOT_PHONE_ERROR;
    } catch (error) {
        return NOT_PHONE_ERROR;
    }
};

import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIcon } from '@/types/icons';

export const IcOnboardingMessageProducts: IcIcon = React.memo((props) => {
    const pathUid1 = useNodeUid();
    const pathUid2 = useNodeUid();

    return (
        <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill={`url(#${pathUid1})`}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.1779 12C15.164 12 14.3105 12.7587 14.1917 13.7657L12.0023 32.3251C12.0008 32.3501 12 32.375 12 32.4C12 33.3548 12.3793 34.2705 13.0544 34.9456C13.7295 35.6207 14.6452 36 15.6 36H32.4C33.3548 36 34.2705 35.6207 34.9456 34.9456C35.6207 34.2705 36 33.3548 36 32.4C36 32.375 35.9992 32.3501 35.9977 32.3251L33.8083 13.7657C33.6895 12.7587 32.836 12 31.8221 12H16.1779ZM20.4 19.2C20.4 18.5373 19.8627 18 19.2 18C18.5373 18 18 18.5373 18 19.2C18 20.7913 18.6321 22.3174 19.7574 23.4426C20.8826 24.5679 22.4087 25.2 24 25.2C25.5913 25.2 27.1174 24.5679 28.2426 23.4426C29.3679 22.3174 30 20.7913 30 19.2C30 18.5373 29.4627 18 28.8 18C28.1373 18 27.6 18.5373 27.6 19.2C27.6 20.1548 27.2207 21.0705 26.5456 21.7456C25.8705 22.4207 24.9548 22.8 24 22.8C23.0452 22.8 22.1295 22.4207 21.4544 21.7456C20.7793 21.0705 20.4 20.1548 20.4 19.2Z"
                fill={`url(#${pathUid2})`}
            />
            <defs>
                <linearGradient
                    id={pathUid1}
                    x1="-13.5729"
                    y1="-9.18087"
                    x2="37.0408"
                    y2="35.5207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stop-color="#FF4D4D" />
                    <stop offset="1" stop-color="#E92F2F" />
                </linearGradient>
                <linearGradient id={pathUid2} x1="10" y1="5.5" x2="33" y2="36" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="0.458333" stop-color="#FFF0EF" />
                    <stop offset="1" stop-color="#FFBFBA" />
                </linearGradient>
            </defs>
        </svg>
    );
});
IcOnboardingMessageProducts.displayName = 'IcOnboardingMessageProducts';

import { isEmail } from './isEmail';

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { AccountEmailWsControllerApi } from '@/generated/commonapi';
import { EMAIL_ALREADY_IN_USE_ERROR, NOT_EMAIL_ERROR } from '@/shared/constants';

export const validateEmailAvailability = async (email: string, accountUid: string) => {
    try {
        if (email && isEmail(email)) {
            const response = await new AccountEmailWsControllerApi().checkAccountEmailUsingPOST({
                email,
                accountUid,
            });

            handleResponseAndThrowAnErrorIfExists(response);

            return response.available ? undefined : EMAIL_ALREADY_IN_USE_ERROR;
        }

        return NOT_EMAIL_ERROR;
    } catch (error) {
        return NOT_EMAIL_ERROR;
    }
};

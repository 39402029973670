export enum AccountStatus {
    /**
     'Standard Automatically Registered'
     * */
    SA = 'SA',
    /**
     'Standard Registration Requested'
     * */
    SR = 'SR',
    /**
     'Standard Registration Confirmed'
     * */
    SC = 'SC',
    /**
     'Standard Customer Identified'
     * */
    SF = 'SF',
    /**
     'Standard Blocked '
     * */
    SB = 'SB',
    /**
     'Standard Closed'
     * */
    SD = 'SD',
    /**
     'Business Registration Requested'
     * */
    BR = 'BR',
    /**
     'Business Registration Confirmed (Read Only)'
     * */
    BC = 'BC',
    /**
     'Business Registration Finished (Agreement Signed)'
     * */
    BF = 'BF',
    /**
     'Business Requires Moderation (Unmoderated)'
     * */
    BM = 'BM',
    /**
     'Business Suspended (Blocked)'
     * */
    BS = 'BS',
    /**
     'Business Closed'
     * */
    BD = 'BD',
    /**
     'Merchant Registration Requested'
     * */
    MR = 'MR',
    /**
     'Merchant Registration Confirmed (Read Only)'
     * */
    MC = 'MC',
    /**
     'Merchant Registration Finished (Agreement Signed)'
     * */
    MF = 'MF',
    /**
     'Merchant Requires Moderation (Unmoderated)'
     * */
    MM = 'MM',
    /**
     'Merchant Suspended (Blocked)'
     * */
    MS = 'MS',
    /**
     'Merchant Closed'
     * */
    MD = 'MD',
    /**
     'Standard: Tier 2'
     * */
    ST = 'ST',
    /**
     'Standard: Tier 4'
     * */
    SX = 'SX',
}

export enum AccountType {
    STANDARD = 'S',
    BUSINESS = 'B',
    MERCHANT = 'M',
    TARIFF = 'T',
    AGENT = 'A',
    PENDING = 'P',
}

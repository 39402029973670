import cn from 'classnames';
import Util from 'cleave.js/src/utils/Util';
import { F7Link } from 'framework7-react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { MESSENGERS } from './MessengerOptions.constants';
import { MessengerOptionsProps } from './MessengerOptions.types';

import { messengerService } from '@/api/messengerService';
import { IcEmail, IcTube } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { WEBResourceType } from '@/types/webResources';

import './MessengerOptions.less';

export const MessengerOptions = ({ seller }: MessengerOptionsProps) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    return (
        <div className={cn('messenger-buttons-container', { 'messenger-buttons-container__reduced': !isMobile })}>
            {seller.phone && (
                <F7Link
                    key="phone"
                    className="messenger-button"
                    href={`tel:+${seller.code}${seller.phone}`}
                    target="_blank"
                    external
                >
                    <IcTube className="messenger-button__icon" />
                    <span>
                        + {seller.code}
                        {seller.phone}
                    </span>
                </F7Link>
            )}
            {seller.email && (
                <F7Link
                    key="email"
                    className="messenger-button"
                    href={`mailto:${seller.email}`}
                    target="_blank"
                    external
                >
                    <IcEmail className="messenger-button__icon" />
                    <span>{seller.email}</span>
                </F7Link>
            )}
            {seller.sellerWebResources?.map((item) => {
                const messenger = MESSENGERS[item.type.toLowerCase()];

                if (!messenger) {
                    return null;
                }

                let url = item.url;
                let messengerLink = messenger.link;

                if (item.type === WEBResourceType.TYPE_VIBER) {
                    url = url.replace('+', '');

                    if (Util.isAndroid()) {
                        messengerLink = messengerLink.replace('+', '');
                    }
                }

                const text = `${messenger.text}/${url}`;
                const hrefLink = messengerLink + url;

                return (
                    <F7Link
                        key={item.type}
                        className="messenger-button"
                        href={hrefLink}
                        target="_blank"
                        external
                        onClick={
                            messengerService.enabled && item.type === WEBResourceType.TYPE_GEM4ME
                                ? (e) => {
                                      e.preventDefault();
                                      messengerService.openChatByDeepLink(
                                          hrefLink,
                                          t('contact_seller.popup.sent_message_to_gemspace', {
                                              url: window.location.href,
                                          }),
                                      );
                                  }
                                : undefined
                        }
                    >
                        {messenger.icon && <messenger.icon />}
                        <span>{text}</span>
                    </F7Link>
                );
            })}
        </div>
    );
};

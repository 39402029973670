import cn from 'classnames';
import { List, ListItem } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteAllModalFacadeProps } from './DeleteAllModalFacade.types';

import { IcRemove } from '@/assets';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';

import './DeleteAllModalFacade.less';

export const DeleteAllModalFacade = ({
    handleDeleteAllClick,
    className,
    modalTriggerElement,
    ...props
}: DeleteAllModalFacadeProps) => {
    const { t } = useTranslation();

    const [deleteAllOpened, setDeleteAllOpened] = useState(false);
    const [openDeleteAll, closeDeleteAll, toggleDeleteAll] = useBooleanState(setDeleteAllOpened);

    const isMobile = useAppSelector(getIsMobile);

    const handleDeleteAllMobileClick = useCallback(() => {
        setDeleteAllOpened(false);
        handleDeleteAllClick();
    }, [handleDeleteAllClick]);

    switch (isMobile) {
        case true:
            return (
                <>
                    {modalTriggerElement(toggleDeleteAll)}
                    <MSSheet
                        className={cn('delete-all-modal', className)}
                        backdrop
                        opened={deleteAllOpened}
                        onBackdropClick={closeDeleteAll}
                        onSheetClose={closeDeleteAll}
                        {...props}
                    >
                        <List noChevron noHairlines noHairlinesBetween>
                            <ListItem title={t('Delete All')} onClick={handleDeleteAllMobileClick}>
                                <IcRemove className="remove-icon" slot="media" />
                            </ListItem>
                        </List>
                    </MSSheet>
                </>
            );

        default:
            return (
                <CustomPopoverWrapper
                    className="delete-all-modal"
                    popoverTriggerElement={modalTriggerElement}
                    {...props}
                >
                    {() => (
                        <List noChevron noHairlines noHairlinesBetween>
                            <ListItem sheetClose title={t('Delete All')} onClick={handleDeleteAllClick}>
                                <IcRemove className="remove-icon" slot="media" />
                            </ListItem>
                        </List>
                    )}
                </CustomPopoverWrapper>
            );
    }
};

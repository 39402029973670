import React from 'react';

import { ArticleItemProps } from './ArticleItem.types';

import './ArticleItem.less';

export const ArticleItem = ({ item, index, onClick }: ArticleItemProps) => (
    <div className="carousel-item__article-content" onClick={() => onClick?.(item, index)}>
        <span className="carousel-item__article-content-title">{item.name}</span>
        <span className="carousel-item__article-content-description">{item.description}</span>
    </div>
);

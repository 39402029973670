import cn from 'classnames';
import { f7 } from 'framework7-react';
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
    ActionMeta,
    components,
    GroupBase,
    OnChangeValue,
    PlaceholderProps,
    SelectInstance,
    ValueContainerProps,
} from 'react-select';

import { CustomSelectProps, CustomSelectValue } from './CustomSelect.types';

import { isMultiValue, isSingleValue } from './CustomSelect.utils';

import { CustomCountryFlag } from '@/components/CustomCountryFlag';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { REQUIRED_FIELD_ERROR } from '@/shared/constants';

import './CustomSelect.less';

const formatOptionLabel = ({ value, label }: CustomSelectValue, withFlags: boolean) => (
    <div className="custom-option">
        {withFlags && <CustomCountryFlag code={value} />}
        {label}
    </div>
);

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = <
    O extends CustomSelectValue = CustomSelectValue,
    IsMulti extends boolean = false,
    Group extends GroupBase<O> = GroupBase<O>,
>({
    children,
    ...props
}: ValueContainerProps<O, IsMulti, Group> & PlaceholderProps<O, IsMulti, Group>) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, (child) => (child && child?.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

export const CustomSelect = forwardRef(
    <
        O extends CustomSelectValue = CustomSelectValue,
        IsMulti extends boolean = false,
        Group extends GroupBase<O> = GroupBase<O>,
    >(
        {
            value,
            onChange,
            openPopup,
            label,
            className = '',
            validate = false,
            errorMessage = REQUIRED_FIELD_ERROR,
            readOnly = false,
            info,
            isSearchable = false,
            requiredSign,
            hideError = false,
            inputMode = 'text',
            components,
            withFlags = false,
            ...props
        }: CustomSelectProps<O, IsMulti, Group>,
        ref,
    ) => {
        const { t } = useTranslation();
        const isMobile = useAppSelector(getIsMobile);

        const [isMenuOpen, setIsMenuOpen] = useState(false);
        const [error, setError] = useState('');

        const selectRef = useRef<SelectInstance<O, IsMulti, Group>>(null);

        const onParentScroll = () => {
            if (isMenuOpen && props.closeMenuOnScroll) {
                handleMenuClose();
            }
        };

        React.useImperativeHandle(ref, () => ({
            onParentScroll,
        }));

        const handleSelectRef = useCallback(
            (ref: SelectInstance<O, IsMulti, Group>) => {
                if (ref?.inputRef) {
                    ref.inputRef.inputMode = inputMode;
                }
                selectRef.current = ref;
            },
            [inputMode],
        );

        const handleMenuClose = useCallback(() => {
            if (!value) setError(t(errorMessage));
            selectRef.current.blur();
            setIsMenuOpen(false);
        }, [value, t, errorMessage]);

        const handleOnMenuOpen = useCallback(() => {
            const isAbleToOpenPopup = isMobile && !!openPopup && !!selectRef.current;
            setIsMenuOpen(!isAbleToOpenPopup);
            if (isAbleToOpenPopup) {
                selectRef.current.blur();
                openPopup();
            }
        }, [isMobile, openPopup]);

        const handleChange = useCallback(
            (newValue: OnChangeValue<O, IsMulti>, meta: ActionMeta<O>) => {
                setIsMenuOpen(false);

                const isValueExist =
                    (isMultiValue(newValue) && newValue.length > 0) ||
                    (isSingleValue(newValue) && Boolean(newValue?.value));

                if (isValueExist) setError(undefined);

                onChange?.(newValue, meta);
            },
            [onChange],
        );

        const isError = !hideError && validate && !!error && !isMenuOpen;

        const customComponents = useMemo(
            () => ({
                ValueContainer: CustomValueContainer,
                ...components,
            }),
            [components],
        );

        return (
            <div
                id="custom-select"
                className={cn(
                    'custom-select',
                    {
                        error: isError,
                        'safari-version': f7.device.ios,
                        search: isSearchable,
                        'read-only': readOnly,
                    },
                    className,
                )}
            >
                <Select<O, IsMulti, Group>
                    {...props}
                    classNamePrefix="custom-select"
                    ref={handleSelectRef}
                    isSearchable={isSearchable}
                    placeholder={label ? `${label}${requiredSign ? '*' : ''}` : undefined}
                    value={value}
                    components={customComponents}
                    menuIsOpen={isMenuOpen}
                    isDisabled={readOnly}
                    formatOptionLabel={(data) => formatOptionLabel(data, withFlags)}
                    noOptionsMessage={() => t('Nothing found')}
                    onMenuOpen={handleOnMenuOpen}
                    onMenuClose={handleMenuClose}
                    onChange={handleChange}
                />
                {isError ? (
                    <div className="error-message">{error}</div>
                ) : (
                    info && <div className="select-info">{info}</div>
                )}
            </div>
        );
    },
);

import { Sorting } from '@/generated/marketplaceapi';
import { SortBy } from '@/types/sort';

const getFieldNameBySortBy = (sortBy: SortBy) => {
    switch (sortBy) {
        case SortBy.popular:
            return 'popular';
        case SortBy.price_high_to_low:
            return 'price';
        case SortBy.price_low_to_high:
            return 'price';
        case SortBy.sales_first:
            return 'sales_first';
        case SortBy.what_s_new:
            return 'what_s_new';
        default:
            return 'price';
    }
};

export const getSortByField = (sortBy: SortBy): Sorting => {
    return {
        direction: sortBy === SortBy.price_high_to_low ? 'DESC' : 'ASC',
        field: getFieldNameBySortBy(sortBy),
        sortingIndex: sortBy === SortBy.price_high_to_low || sortBy === SortBy.price_low_to_high ? 1 : 0,
    };
};

export const getSortByFields = (sortBy: SortBy[]): Sorting[] => {
    return sortBy.map(getSortByField);
};

import cn from 'classnames';
import React from 'react';

import { CustomCountryFlagProps } from './CustomCountryFlag.types';

import './CustomCountryFlag.less';

export const CustomCountryFlag = ({ code, width = 32, className, ...props }: CustomCountryFlagProps) =>
    code ? (
        <img
            {...props}
            width={width}
            className={cn('custom-country-flag', className)}
            src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${code
                .toLocaleLowerCase()
                .slice(0, 2)}.svg`}
            alt=""
        />
    ) : null;

import cn from 'classnames';
import { f7, ListInput } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomInputProps } from './CustomInput.types';

import { IcEye, IcEyeCrossed, IcQuestion } from '@/assets';
import { useNodeUid } from '@/hooks/useNodeUid';

import './CustomInput.less';

export const CustomInput = React.forwardRef<ListInput, CustomInputProps>(
    (
        {
            withPasswordToggle,
            className,
            children,
            type = 'text',
            requiredSign,
            label,
            noCalendar,
            startPrefix,
            errorInTooltip,
            alwaysShowPrefix,
            customIcon,
            ...props
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const tooltipTargetSelector = useNodeUid();

        const computedLabel = useMemo(
            () => (label ? `${label}${requiredSign ? '*' : ''}` : undefined),
            [label, requiredSign],
        );

        const [forsedType, setForsedType] = useState(type);

        const handleTogglePasswordMode = useCallback(() => {
            setForsedType((prev) => (prev === 'password' ? 'text' : 'password'));
        }, []);

        useEffect(() => {
            if (errorInTooltip) {
                f7.tooltip.create({
                    text: errorInTooltip,
                    targetEl: `.${tooltipTargetSelector}`,
                    cssClass: 'custom-input__tooltip',
                });
            }

            return () => {
                if (errorInTooltip) {
                    try {
                        f7.tooltip.destroy(`.${tooltipTargetSelector}`);
                    } catch (err) {
                        console.error(err);
                    }
                }
            };
        }, [errorInTooltip, tooltipTargetSelector]);

        return (
            <ListInput
                id="custom-input"
                className={cn(
                    'custom-input',
                    {
                        'no-calendar': noCalendar,
                        'safari-version': f7.device.ios,
                        'with-eye': withPasswordToggle,
                        'with-tooltip': !!errorInTooltip,
                        'with-prefix': alwaysShowPrefix,
                    },
                    type,

                    className,
                )}
                floatingLabel={!!label}
                type={forsedType}
                errorMessage={t('Required field')}
                ref={ref}
                label={computedLabel}
                {...props}
            >
                {startPrefix && (
                    <span slot={alwaysShowPrefix ? 'inner-start' : 'media'} className="start-prefix">
                        {startPrefix}
                    </span>
                )}
                {errorInTooltip && (
                    <IcQuestion slot="inner-end" className={cn('custom-input__error-tip', tooltipTargetSelector)} />
                )}
                {customIcon && (
                    <div slot="inner-end" className="custom-input__custom-icon-wrap">
                        {customIcon}
                    </div>
                )}
                {children}
                {withPasswordToggle && (
                    <button
                        type="button"
                        slot="inner-end"
                        onClick={handleTogglePasswordMode}
                        className="password-mode-toggle"
                    >
                        {forsedType === 'password' ? <IcEye /> : <IcEyeCrossed />}
                    </button>
                )}
            </ListInput>
        );
    },
);
CustomInput.displayName = 'CustomInput';

import { AvailablePaymentMethod } from './paymentMethods';

export interface ICategory {
    id?: string;
    name?: string;
    color?: string;
    icon?: string;
    large?: boolean;
}

export interface IExternalLink {
    title?: string;
    name: string;
    newTabWeb?: boolean;
    newTabMobile?: boolean;
    icon?: string;
}

export type NavigationTabLink = {
    href: string;
    title: string;
    icon: string;
    selectedIcon?: string;
};

export type ShareMessengerName = 'telegram' | 'whatsapp' | 'facebook' | 'gem4me';

export type MessengerName = 'telegram' | 'whatsapp' | 'viber' | 'instagram' | 'gem4me';

export enum LogoType {
    WITHOUT_TEXT = 'WITHOUT_TEXT',
}

export interface ILocalConfig {
    GoogleMapAPIkey?: string;
    logoType?: LogoType;
    categories: ICategory[];
    settingsEnabled?: boolean;
    theme?: 'light' | 'dark';
    appCode?: string;
    profileMenuItems?: string[][];
    profileMenuItemsNotBusiness?: string[][];
    profileMenuItemsMobile?: string[][];
    profileMenuItemsMobileUnlogged?: string[][];
    myWalletMenuItems?: string[];
    sellerAreaMenuItems?: string[];
    personalAreaMenuItems?: string[];
    footerExternalLinks?: IExternalLink[];
    aboutUsExternalLinks?: IExternalLink[];
    headerExternalLinks?: IExternalLink[];
    mobileCategoriesView?: 'default' | 'chips';
    shareMessengers?: ShareMessengerName[];
    sliderCategories: string[];
    navBarLinks: NavigationTabLink[];
    defaultAvailablePaymentMethods: AvailablePaymentMethod[];
    availablePaymentMethodsPerCurrency?: Record<string, AvailablePaymentMethod[]>;
}

import { ProductOrder } from '@/generated/paymentapi';
import { OrderItemTypes } from '@/types/orderItemTypes';
import { OrderPriceParams } from '@/types/price';
import { formatPrice } from '@/utils/formatters/formatPrice';

export const createOrderPriceParams = (order: ProductOrder, currencySign?: string): OrderPriceParams => {
    const delivery = order.shippingItems
        ? order.shippingItems.reduce((prev, cur) => {
              return prev + cur.price;
          }, 0)
        : 0;

    if (order.rent) {
        const deposit = order.items.reduce((prev, cur) => {
            return prev + (cur.type === OrderItemTypes.D ? cur.price : 0);
        }, 0);
        return {
            deposit: formatPrice(deposit, currencySign ?? order.currencyCode),
            delivery: formatPrice(delivery, currencySign ?? order.currencyCode, true),
            rent: formatPrice(order.amount - deposit - delivery, currencySign ?? order.currencyCode),
        };
    } else {
        return {
            delivery: formatPrice(delivery, currencySign ?? order.currencyCode, true),
            clearItemsPrice: formatPrice(order.amount - delivery, currencySign ?? order.currencyCode, true),
        };
    }
};

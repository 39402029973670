import { ForgotPasswordPopupState } from './ForgotPasswordPopUp.types';

export const initialState: ForgotPasswordPopupState = {
    email: '',
    code: '',
    password: '',
    passwordRepeat: '',
    step: 'init',
    showResend: false,
};

export const RESEND_CODE_TIMEOUT_MINUTES = 2;

export const RESEND_CODE_TIMEOUT = RESEND_CODE_TIMEOUT_MINUTES * 60 * 1000;

export const CODE_SENT_MESSAGE = 'We have sent you the code to your email';

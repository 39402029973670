import { fillClassificatorProductStatusValue } from './classificatorActions';

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { Product, ProductControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';

export const CHANGE_PRODUCT_STATUS_LOADING = 'CHANGE_PRODUCT_STATUS_LOADING' as const;
export const CHANGE_PRODUCT_STATUS_SUCCESS = 'CHANGE_PRODUCT_STATUS_SUCCESS' as const;
export const CHANGE_PRODUCT_STATUS_ERROR = 'CHANGE_PRODUCT_STATUS_ERROR' as const;
export const CHANGE_PRODUCT_STATUS_ERROR_RESET = 'CHANGE_PRODUCT_STATUS_ERROR_RESET' as const;

const changeProductStatusLoadingAction = () => ({
    type: CHANGE_PRODUCT_STATUS_LOADING,
});

const changeProductStatusSuccessAction = (item: IProduct, quantity?: number) => ({
    type: CHANGE_PRODUCT_STATUS_SUCCESS,
    item,
    quantity,
});

const changeProductStatusErrorAction = (error: unknown) => ({
    type: CHANGE_PRODUCT_STATUS_ERROR,
    error,
});

export const changeProductStatusErrorResetAction = () => ({
    type: CHANGE_PRODUCT_STATUS_ERROR_RESET,
});

export type ProductStatusActions = ReturnType<
    | typeof changeProductStatusLoadingAction
    | typeof changeProductStatusSuccessAction
    | typeof changeProductStatusErrorAction
    | typeof changeProductStatusErrorResetAction
>;

export const getProductStatusRules = (): {
    from: Product.StatusEnum;
    to: Product.StatusEnum[];
}[] =>
    'DRF->DRF/AFR/DLT, DCL->DRF, APR->PBL/DSC, PBL->AFR/PBL/SUS/DSC, SUS->SUS/DSC, OOS->OOS/DSC'
        .split(', ')
        .map((item) => ({
            from: Product.StatusEnum[item.substr(0, item.indexOf('->'))],
            to: item
                .substr(item.indexOf('->') + 2)
                .split('/')
                .map((item) => Product.StatusEnum[item]),
        }));

export const getAllowedStatuses = (status: Product.StatusEnum): Product.StatusEnum[] =>
    getProductStatusRules().find((item) => item.from === status)?.to || [];

export const changeProductStatus =
    (uid: string, oldStatus: Product.StatusEnum, newStatus: Product.StatusEnum, quantity?: number) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(changeProductStatusLoadingAction());

        try {
            if (newStatus === oldStatus && !quantity) {
                throw new Error('New status is equal to the old one');
            }

            const data = { uid, status: newStatus, quantity };
            const controller = new ProductControllerApi();

            let result;
            let item: IProduct = null;

            if (newStatus === Product.StatusEnum.DLT) {
                result = await controller.productDetailsUsingPOST({ uid });
                handleResponseAndThrowAnErrorIfExists(result);

                item = result.body[0];
                item.status = Product.StatusEnum.DLT;
                result = await controller.deleteProductUsingDELETE({ uid });
                handleResponseAndThrowAnErrorIfExists(result);
            } else {
                result = quantity
                    ? await controller.updateProductQuantityUsingPOST(data)
                    : await controller.updateProductStatusUsingPOST1(data);
                handleResponseAndThrowAnErrorIfExists(result);

                item = result.body[0];
            }

            fillClassificatorProductStatusValue([item], getState());
            dispatch(changeProductStatusSuccessAction(item, quantity));
            return getState();
        } catch (err) {
            console.error('at productStatusActions in changeProductStatus', err);

            dispatch(changeProductStatusErrorAction(err.message));
            return getState();
        }
    };

export const updateProductExpirationDate =
    (uid: string, expirationDate: Date) => async (dispatch, getState: () => IApplicationStore) => {
        dispatch(changeProductStatusLoadingAction());
        const state = getState();
        try {
            const result = await new ProductControllerApi().updateProductExpirationDateUsingPOST({
                uid,
                expirationDate: expirationDate.toISOString().substr(0, expirationDate.toISOString().lastIndexOf('.')),
            });

            handleResponseAndThrowAnErrorIfExists(result);

            const item = result.body[0];

            fillClassificatorProductStatusValue([item], state);
            dispatch(changeProductStatusSuccessAction(item));
        } catch (err) {
            console.error('at productStatusActions in updateProductExpirationDate', err);
            dispatch(changeProductStatusErrorAction(err.message));
        }
    };

import { ImportContentType, ImportProductsDraft, ImportProductsSteps } from './ImportProducts.types';

import { ProductSource, ProductSourceType } from '@/generated/marketplaceapi';
import i18n from '@/i18n';

export const productSourceToImportProductsDraft = (
    data: ProductSource,
    type?: ProductSourceType,
): ImportProductsDraft => {
    const stats = {
        new: data?.currentContent?.newCount || 0,
        failed: data?.currentContent?.failedCount || 0,
        updated: data?.currentContent?.updatedCount || 0,
        skipped: data?.currentContent?.skippedCount || 0,
    };
    return {
        [ImportProductsSteps.CONFIGURE_IMPORT]: {
            authPassword: data?.authPassword,
            authUsername: data?.authUsername,
            contentType: data?.contentType as ImportContentType,
            file:
                (data?.contentType as ImportContentType) === ImportContentType.file ? { name: data?.title } : undefined,
            skipNew: data.skipNew,
            typeCode: data?.type
                ? { value: data.type?.code, label: data.type?.name }
                : { value: type?.code, label: type?.name },
            updateExisting: data?.updateExisting,
            url: data?.url,
        },
        [ImportProductsSteps.IMPORT_LOG]: {
            stats,
            totalProducts: Object.keys(stats).reduce((sum, key) => sum + stats[key], 0),
            active: data?.active,
            fileType: data?.type?.name ?? data?.type?.code ?? i18n.t('File not found'),
            processed: data?.currentContent?.processed,
            contentType: data?.contentType as ImportContentType,
            sourceLabel:
                data?.contentType === 'file'
                    ? data?.title || i18n.t('File not found')
                    : data?.url || i18n.t('File not found'),
            date: data.currentContent.creationDate ?? data.modificationDate ?? data.creationDate,
        },
        [ImportProductsSteps.CATEGORY_MAPPING]: data?.currentContent?.categoryMapping
            ? Object.fromEntries(
                  data?.currentContent?.categoryMapping?.map(
                      ({ accountUid, externalCategoryCode, externalCategoryName, internalCategoryCode }) => [
                          externalCategoryCode,
                          {
                              accountUid,
                              externalCategoryCode,
                              externalCategoryName,
                              internalCategoryCode,
                          },
                      ],
                  ),
              )
            : {},
    };
};

export const importProductsDraftToProductSource = (
    draft: ImportProductsDraft,
    languageCode: string,
    active = false,
): ProductSource => {
    const title =
        draft[ImportProductsSteps.CONFIGURE_IMPORT].file?.name ?? draft[ImportProductsSteps.CONFIGURE_IMPORT].url;
    return {
        active,
        title: title?.length < 100 ? title : title?.slice(0, 96)?.concat('...'),
        type: {
            name: draft[ImportProductsSteps.CONFIGURE_IMPORT].typeCode?.label,
            code: draft[ImportProductsSteps.CONFIGURE_IMPORT]?.typeCode.value,
        },
        url: draft[1].url,
        languageCode,
        authPassword: draft[ImportProductsSteps.CONFIGURE_IMPORT].authPassword,
        authUsername: draft[ImportProductsSteps.CONFIGURE_IMPORT].authUsername,
        contentType: draft[ImportProductsSteps.CONFIGURE_IMPORT].contentType,
        skipNew: draft[ImportProductsSteps.CONFIGURE_IMPORT].skipNew,
        updateExisting: draft[ImportProductsSteps.CONFIGURE_IMPORT].updateExisting,
        currentContent: {
            categoryMapping: Object.values(draft[ImportProductsSteps.CATEGORY_MAPPING]),
        },
    };
};

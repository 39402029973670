import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteReview, editReview, editReviewLoadingSuccessAction } from '@/actions/reviewActions';
import { AddReviewPopup } from '@/components/AddReviewPopup';
import { EditReviewSheet } from '@/components/EditReviewSheet';
import { ImagesSlider } from '@/components/ImagesSlider';
import { ReportAbuseSheet } from '@/components/ReportAbuseSheet';
import { ReviewCard } from '@/components/ReviewCard';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { useErrorAlert } from '@/hooks/useErrorAlert';
import { useNodeUid } from '@/hooks/useNodeUid';
import { usePreloader } from '@/hooks/usePreloader';
import { getProfile } from '@/selectors/profile';
import { openConfirm } from '@/shared/confirmDialog';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import type { ReviewListProps } from './ReviewList.types';
import type { AddProductReviewRequest, ProductReview, ProductReviewImage } from '@/generated/marketplaceapi';

import './ReviewList.less';

export const ReviewList = React.memo<ReviewListProps>(
    ({ reviews = [], translated = false, size = reviews.length, productView = false }) => {
        const { t } = useTranslation();

        const dispatch = useAppDispatch();

        const { uid: profileUid } = useAppSelector(getProfile);
        const { deleteLoading, deleteError, editReviewLoading, editReviewSuccess, editReviewError } = useAppSelector(
            (state) => state.reviewReducer,
        );

        const editReviewUid = useNodeUid();

        const [selectedReview, setSelectedReview] = useState<ProductReview | undefined>();

        const [images, setImages] = useState<ProductReviewImage[]>([]);
        const [initialSlide, setInitialSlide] = useState(0);
        const [sliderZoom, openSliderZoom, closeSliderZoom] = useBoolState(false);

        const onEditReviewClose = useCallback(() => {
            dispatch(editReviewLoadingSuccessAction(false));
        }, [dispatch]);

        const openDeleteDialog = useCallback(
            () =>
                openConfirm(t('Are you sure you want to delete this review?'), () => {
                    dispatch(deleteReview(selectedReview.productUid, selectedReview.uid));
                }),
            [dispatch, t, selectedReview],
        );

        usePreloader(deleteLoading);

        useErrorAlert(deleteError);

        const onImgClick = useCallback(
            (newImages: ProductReviewImage[], idx: number) => {
                openSliderZoom();
                setImages(newImages);
                setInitialSlide(idx);
            },
            [openSliderZoom],
        );

        const onMoreClick = useCallback((review: ProductReview) => setSelectedReview(() => ({ ...review })), []);

        const onSubmitEditReview = useCallback(
            (request: AddProductReviewRequest, imageFiles: File[], newImages: ProductReviewImage[]) =>
                dispatch(
                    editReview(
                        request,
                        selectedReview.images,
                        newImages,
                        imageFiles,
                        selectedReview.productUid,
                        selectedReview.uid,
                    ),
                ),
            [dispatch, selectedReview],
        );

        const reviewCards = useMemo(
            () =>
                reviews
                    .slice(0, size)
                    .map((review) => (
                        <ReviewCard
                            onEditSelector={`.${Popups.EDIT_REVIEW_POPUP}.${editReviewUid}`}
                            onDelete={openDeleteDialog}
                            key={review.uid}
                            review={review}
                            translated={translated}
                            onImgClick={onImgClick}
                            isMyPage={productView}
                            isMyReview={review.author?.uid === profileUid}
                            moreClickMobileSelector={`${
                                review.author?.uid === profileUid
                                    ? `.${Sheets.EDIT_REVIEW_SHEET}`
                                    : `.${Sheets.REPORT_ABUSE_SHEET}`
                            }.${editReviewUid}`}
                            onMoreClick={onMoreClick}
                        />
                    )),
            [
                reviews,
                size,
                editReviewUid,
                openDeleteDialog,
                translated,
                onImgClick,
                productView,
                profileUid,
                onMoreClick,
            ],
        );

        return (
            <div className="review-list">
                {reviewCards}
                <AddReviewPopup
                    isEdit
                    onPopupClose={onEditReviewClose}
                    loading={editReviewLoading}
                    success={editReviewSuccess}
                    error={editReviewError}
                    review={selectedReview}
                    onSubmit={onSubmitEditReview}
                    className={editReviewUid}
                />
                <EditReviewSheet
                    onEditSelector={`.${Popups.EDIT_REVIEW_POPUP}.${editReviewUid}`}
                    onDelete={openDeleteDialog}
                    uid={editReviewUid}
                />
                <ReportAbuseSheet className={editReviewUid} />
                <ImagesSlider
                    slides={images.map(({ imageUrl, imageThumbnailUrl }, idx) => ({
                        image: imageUrl,
                        imageThumbnailUrl1: imageThumbnailUrl,
                        uid: `${idx}`,
                    }))}
                    activeIndex={initialSlide}
                    zoomed={sliderZoom}
                    onZoomBackdropClick={closeSliderZoom}
                />
            </div>
        );
    },
);
ReviewList.displayName = 'ReviewList';

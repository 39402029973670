import cn from 'classnames';
import { Button } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MobileStepsControlsProps } from './MobileStepsControls.types';

import { IcArrowRight } from '@/assets';
import { StepIndicator } from '@/components/StepIndicator';

import './MobileStepsControls.less';

export const MobileStepsControls = ({
    prevVisible,
    onPrevStep,
    stepChangeDisabled,
    stepsCount,
    progressStep,
    onNextStep,
    nextButtonText = 'Next',
    className,
}: MobileStepsControlsProps) => {
    const { t } = useTranslation();

    return (
        <div className={cn('mobile-steps-controls', className)}>
            <Button
                className={cn('mobile-steps-controls__button', 'prev', {
                    visible: prevVisible,
                })}
                onClick={onPrevStep}
                disabled={stepChangeDisabled}
            >
                <IcArrowRight />
                {t('Back')}
            </Button>
            <StepIndicator stepsCount={stepsCount} progress={progressStep} />
            <Button
                className={cn('mobile-steps-controls__button', 'next')}
                onClick={onNextStep}
                disabled={stepChangeDisabled}
            >
                {nextButtonText}
                <IcArrowRight />
            </Button>
        </div>
    );
};

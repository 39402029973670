import dayjs from 'dayjs';

type FormatCallback = (date: Date | null) => string | null;

const defaultFormat = (date: Date | null) => (!date ? null : dayjs(date).format('MMM DD, YYYY'));

export const formatDate = (date: Date | null, format: FormatCallback = defaultFormat): string | null => {
    if (!date) {
        return null;
    }

    return format(date);
};

import cn from 'classnames';
import { Link, Toolbar } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HistogramToolbarOptions, HistogramToolbarProps } from './HistogramToolbar.types';

import './HistogramToolbar.less';

export const HistogramToolbar = ({ onTypeChange, activeType, className, ...props }: HistogramToolbarProps) => {
    const { t } = useTranslation();

    return (
        <Toolbar {...props} tabbar className={cn('histogram-tabs-toolbar', className)}>
            <Link
                onClick={() => onTypeChange(HistogramToolbarOptions.ORDERS)}
                tabLinkActive={activeType === HistogramToolbarOptions.ORDERS}
            >
                {t('Orders')}
            </Link>
            <Link
                onClick={() => onTypeChange(HistogramToolbarOptions.MESSAGES)}
                tabLinkActive={activeType === HistogramToolbarOptions.MESSAGES}
            >
                {t('Messages from Users')}
            </Link>
        </Toolbar>
    );
};

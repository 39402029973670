import { createContext } from 'react';

import { DateRangePickerContextType } from './DateRangePicker.types';

export const DateRangePickerInitialContext: DateRangePickerContextType = {
    focusedDate: null,
    isDateFocused: () => false,
    isDateSelected: () => false,
    isDateHovered: () => false,
    isDateBlocked: () => false,
    isStartDate: () => false,
    isEndDate: () => false,
    isFirstOrLastSelectedDate: () => false,
    onDateFocus: () => {
        // Empty.
    },
    onDateHover: () => {
        // Empty.
    },
    onDateSelect: () => {
        // Empty.
    },
    firstDayOfWeek: 0,
};

const DateRangePickerContext = createContext(DateRangePickerInitialContext);

export default DateRangePickerContext;

import { getCompressedImage } from './getCompressedImage';

export const getCompressedImageUrl = async (_file: File) => {
    const file = await getCompressedImage(_file);

    let url: string;
    if (URL.createObjectURL) {
        url = URL.createObjectURL(file);
    } /* old */ else {
        const reader = new FileReader();
        reader.onload = (ev: ProgressEvent<FileReader>) => {
            url = ev.target.result.toString();
        };
        reader.readAsDataURL(file);
    }
    return { url, file };
};

import { loadProductDetails } from './productActions';

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { getErrorMessage, handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    CartControllerApi,
    CartItem,
    CommonMarketplaceApiResponseCart,
    DeliveryMethod,
    PlatformDeliveryMethod,
} from '@/generated/marketplaceapi';

import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { LocalStorageCartItem, LocalStorageItems } from '@/types/localStorage';
import { createUUID } from '@/utils';

import type { AppDispatch } from '@/hooks/store';

export const CART_LOAD = 'CART_LOAD' as const;
export const CART_ERROR = 'CART_ERROR' as const;
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS' as const;
export const CART_REMOVE = 'CART_REMOVE' as const;
export const CART_SET_SELECTED = 'CART_SET_SELECTED' as const;

export const CART_DISALLOW_SELECT_ALL = 'CART_DISALLOW_SELECT_ALL' as const;
export const CART_ALLOW_SELECT_ALL = 'CART_ALLOW_SELECT_ALL' as const;

const cartLoad = () => ({ type: CART_LOAD });

const cartError = (error: unknown) => ({
    type: CART_ERROR,
    payload: { error },
});

const cartLoadSuccess = (items: CartItem[]) => ({
    type: CART_LOAD_SUCCESS,
    payload: { items },
});

const cartRemove = (uids: string[]) => ({
    type: CART_REMOVE,
    payload: { uids },
});

export const cartSetSelected = (selected: string[]) => ({
    type: CART_SET_SELECTED,
    payload: { selected },
});

export const cartAllowSelectAll = () => ({
    type: CART_ALLOW_SELECT_ALL,
});

export const cartDisallowSelectAll = () => ({
    type: CART_DISALLOW_SELECT_ALL,
});

export type CartActions = ReturnType<
    | typeof cartLoad
    | typeof cartLoadSuccess
    | typeof cartError
    | typeof cartRemove
    | typeof cartSetSelected
    | typeof cartAllowSelectAll
    | typeof cartDisallowSelectAll
>;

const chooseDeliveryMethodOption = (deliveryMethods: DeliveryMethod[], countryCode?: string) =>
    deliveryMethods?.find(
        (method) => method.options?.some((option) => option.countries?.some((item) => item.code === countryCode)),
    )?.options[0];

const choosePlatformDeliveryMethod = (platformDeliveryMethods: PlatformDeliveryMethod[], countryCode?: string) =>
    platformDeliveryMethods?.find(
        (method) => method.deliveryProvider?.includedCountries?.some((item) => item === countryCode),
    )?.[0];

const productToCartItem = (product: IProduct, itemUid: string, quantity = 1, countryCode?: string): CartItem => ({
    itemUid,
    productUid: product.uid,
    quantity,
    productCurrencyCode: product.currencyCode,
    productDescription: product.shortDescription,
    productDiscountedPrice: product.discountedPrice,
    productName: product.name,
    productPrice: product.price,
    productSalePrice: product.salePrice,
    availableDeliveryMethods: product.deliveryMethods,
    availablePlatformDeliveryMethods: product.platformDeliveryMethods,
    imageThumbnailUrl1: product.imageThumbnailUrl1,
    parameters: product.productParams,
    pickupAllowed: product.pickupAllowed,
    sellerEmail: product.sellerEmail,
    sellerIban: product.sellerIban,
    sellerName: product.seller.name,
    sellerPhone: product.sellerPhone,
    sellerUid: product.sellerUid,
    shippingAllowed: product.shippingAllowed,
    type: product.type,
    availableQuantity: product.quantity,
    deliveryMethodOption: chooseDeliveryMethodOption(product.deliveryMethods, countryCode),
    platformDeliveryMethod: choosePlatformDeliveryMethod(product.platformDeliveryMethods, countryCode),
});

const loadCartItemsFromProducts = async (localStorageItems: LocalStorageCartItem[], state: IApplicationStore) => {
    const products = await Promise.all(
        localStorageItems.map(({ productUid }) => loadProductDetails(productUid, state)),
    );

    return products.map((product, index) =>
        productToCartItem(
            product,
            localStorageItems[index].itemUid,
            localStorageItems[index].quantity,
            getCountryCodeFromState(state),
        ),
    );
};

export const loadCart = (countryCode?: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(cartLoad());
    try {
        let items: CartItem[] = [];

        if (isLoggedIn(getState())) {
            const response = await new CartControllerApi().getAccountCartUsingGET(
                countryCode || getCountryCodeFromState(getState()),
            );

            handleResponseAndThrowAnErrorIfExists(response);

            items = response.body?.[0]?.items ?? [];
        } else {
            const cartItemsFromLocalStorage: LocalStorageCartItem[] =
                JSON.parse(localStorage.getItem(LocalStorageItems.CART)) || [];
            items = await loadCartItemsFromProducts(cartItemsFromLocalStorage, getState());
        }

        if (getState().cartReducer.shouldForceSelectAll) {
            dispatch(cartSetSelected(items.map(({ itemUid }) => itemUid)));
            dispatch(cartDisallowSelectAll());
        }

        dispatch(cartLoadSuccess(items));
    } catch (error) {
        console.error('at cartActions in loadCart', error);

        dispatch(cartError(error.message));
    }
};

export const addToCart =
    (itemsForUpdate: CartItem[], countryCode?: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();

        const updatedItems = itemsForUpdate.map<LocalStorageCartItem>(
            ({
                itemUid,
                productUid,
                quantity,
                deliveryMethodOption,
                platformDeliveryMethod,
                parameters,
                deliveryCountryCode,
                deliveryPostalCode,
                deliveryPrice,
                deliveryPriceCurrencyCode,
            }) => {
                const newItemUid = itemUid || createUUID();

                return {
                    itemUid: newItemUid,
                    productUid,
                    quantity,
                    deliveryMethodOption,
                    platformDeliveryMethod,
                    parameters,
                    deliveryCountryCode,
                    deliveryPostalCode,
                    deliveryPrice,
                    deliveryPriceCurrencyCode,
                };
            },
        );

        try {
            dispatch(cartLoad());

            let items: CartItem[] = state.cartReducer.items || [];

            if (isLoggedIn(state)) {
                const response = await new CartControllerApi().addItemsToCartUsingPOST(
                    updatedItems,
                    countryCode || getCountryCodeFromState(state),
                    getLanguageCode(state),
                );

                items = response.body?.[0]?.items ?? [];
            } else {
                let newItems: LocalStorageCartItem[] = state.cartReducer.items || [];

                for (const updatedItem of updatedItems) {
                    const {
                        productUid,
                        itemUid,
                        quantity,
                        deliveryMethodOption,
                        platformDeliveryMethod,
                        parameters,
                        deliveryCountryCode,
                        deliveryPostalCode,
                        deliveryPrice,
                        deliveryPriceCurrencyCode,
                    } = updatedItem;

                    const searchedItem = state.cartReducer.items.find(
                        (item) => item.productUid === productUid || item.itemUid === itemUid,
                    );

                    if (searchedItem) {
                        newItems = newItems.map<LocalStorageCartItem>((item) =>
                            item.itemUid === itemUid
                                ? {
                                      itemUid: item.itemUid,
                                      productUid: item.productUid,
                                      quantity,
                                      deliveryMethodOption,
                                      platformDeliveryMethod,
                                      parameters,
                                      deliveryCountryCode,
                                      deliveryPostalCode,
                                      deliveryPrice,
                                      deliveryPriceCurrencyCode,
                                  }
                                : {
                                      itemUid: item.itemUid,
                                      productUid: item.productUid,
                                      quantity: item.quantity,
                                      deliveryMethodOption: item.deliveryMethodOption,
                                      platformDeliveryMethod: item.platformDeliveryMethod,
                                      parameters,
                                      deliveryCountryCode: item.deliveryCountryCode,
                                      deliveryPostalCode: item.deliveryPostalCode,
                                      deliveryPrice: item.deliveryPrice,
                                      deliveryPriceCurrencyCode: item.deliveryPriceCurrencyCode,
                                  },
                        );
                        items = items.map<CartItem>((item) =>
                            item.itemUid === searchedItem.itemUid
                                ? {
                                      ...item,
                                      quantity,
                                      deliveryMethodOption,
                                      platformDeliveryMethod,
                                      parameters,
                                      deliveryCountryCode,
                                      deliveryPostalCode,
                                      deliveryPrice,
                                      deliveryPriceCurrencyCode,
                                  }
                                : item,
                        );
                    } else {
                        newItems = [
                            ...newItems.map<LocalStorageCartItem>(
                                ({
                                    itemUid,
                                    productUid,
                                    quantity,
                                    deliveryMethodOption,
                                    platformDeliveryMethod,
                                    parameters,
                                    deliveryCountryCode,
                                    deliveryPostalCode,
                                    deliveryPrice,
                                    deliveryPriceCurrencyCode,
                                }) => ({
                                    itemUid,
                                    productUid,
                                    quantity,
                                    deliveryMethodOption,
                                    platformDeliveryMethod,
                                    parameters,
                                    deliveryCountryCode,
                                    deliveryPostalCode,
                                    deliveryPrice,
                                    deliveryPriceCurrencyCode,
                                }),
                            ),
                            {
                                itemUid,
                                productUid,
                                quantity,
                                deliveryMethodOption,
                                platformDeliveryMethod,
                                parameters,
                                deliveryCountryCode,
                                deliveryPostalCode,
                                deliveryPrice,
                                deliveryPriceCurrencyCode,
                            },
                        ];

                        const product = await loadProductDetails(productUid, state);
                        items = [...items, productToCartItem(product, itemUid, quantity)];
                    }

                    localStorage.setItem(LocalStorageItems.CART, JSON.stringify(newItems));
                }
            }

            itemsForUpdate.forEach((addedItem) => {
                firebaseAnalyticsInstance.addToCart?.(addedItem);
            });

            itemsForUpdate = itemsForUpdate.map((itemForUpdate) => {
                if (!itemForUpdate.itemUid) {
                    const updatedItem = items.find((item) => item.productUid === itemForUpdate.productUid);

                    return { ...itemForUpdate, itemUid: updatedItem.itemUid };
                }

                return itemForUpdate;
            });

            dispatch(
                cartSetSelected(
                    [...getState().cartReducer.selected].concat(itemsForUpdate.map(({ itemUid }) => itemUid)),
                ),
            );

            dispatch(cartLoadSuccess(items));

            return { state: getState() };
        } catch (error) {
            console.error('at cartActions in addToCart', error);

            dispatch(cartError(error.response.data.errorData));

            return { error, state: getState() };
        }
    };

export const removeFromCart = (itemUid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    try {
        dispatch(cartRemove([itemUid]));

        if (isLoggedIn(getState())) {
            dispatch(cartLoad());
            const response = await new CartControllerApi().removeItemFromCartUsingPOST(
                { itemUid },
                getCountryCodeFromState(getState()),
            );

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(cartLoadSuccess(response.body?.[0]?.items ?? []));
        } else {
            localStorage.setItem(
                LocalStorageItems.CART,
                JSON.stringify(getState().cartReducer.items.filter((item) => item.itemUid !== itemUid)),
            );
        }
    } catch (error) {
        console.error('at cartActions in cartRemove', error);

        dispatch(cartError(`${error.message}`));
    }
};

export const removeItemPackFromCart =
    (uids: string[]) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        if (isLoggedIn(getState())) {
            dispatch(cartLoad());
            const api = new CartControllerApi();
            const responses = await Promise.allSettled(
                uids.map((itemUid) =>
                    api
                        .removeItemFromCartUsingPOST({ itemUid }, getCountryCodeFromState(getState()))
                        .then((response) => {
                            handleResponseAndThrowAnErrorIfExists(response);
                            return response;
                        }),
                ),
            );
            const errors: unknown[] = [];
            const successfulItems: CommonMarketplaceApiResponseCart[] = [];

            responses.forEach((resp) => {
                if (resp.status === 'rejected') {
                    errors.push(resp.reason);
                    return;
                }
                successfulItems.push(resp.value);
            });

            if (successfulItems.length > 0) {
                const removedUids = responses.reduce<string[]>(
                    (acc, resp, idx) => (resp.status === 'rejected' ? acc : [...acc, uids[idx]]),
                    [],
                );
                dispatch(cartRemove(removedUids));
                dispatch(cartLoadSuccess(successfulItems.at(-1)?.body?.[0]?.items || []));
            }

            if (errors.length > 0) {
                dispatch(cartError(errors.map(getErrorMessage)));
                console.error('at cartActions in removeItemPackFromCart', ...errors);
            }
            return;
        }

        dispatch(cartRemove(uids));

        localStorage.setItem(
            LocalStorageItems.CART,
            JSON.stringify(getState().cartReducer.items.filter((item) => !uids.includes(item.itemUid))),
        );
    };

export const sendCartFromLocalStorage = (countryCode: string) => async (dispatch: AppDispatch) => {
    try {
        const items: LocalStorageCartItem[] = JSON.parse(localStorage.getItem(LocalStorageItems.CART)) || [];

        if (items.length) {
            const response = await new CartControllerApi().addItemsToCartUsingPOST(items, countryCode);
            dispatch(cartLoadSuccess(response.body[0].items));
            localStorage.removeItem(LocalStorageItems.CART);
        } else {
            dispatch(cartLoadSuccess([]));
        }
    } catch (error) {
        console.error('at cartActions in sendCartFromLocalStorage', error);
        dispatch(cartError(`${error}`));
    }
};

import { Device } from 'framework7';

import { Banner, BannerControllerApi, PublicControllerApi } from '@/generated/marketplaceapi';

import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { BannerLabel, MarketBannerLabel } from '@/types/banner';

import { ProductType } from '@/types/productType';

import type { AppDispatch } from '@/hooks/store';

export const MARKETING_BANNERS_LIST_LOADING = 'MARKETING_BANNERS_LIST_LOADING' as const;
export const MARKETING_BANNERS_LIST_LOADING_SUCCESS = 'MARKETING_BANNERS_LIST_LOADING_SUCCESS' as const;
export const MARKETING_BANNERS_LIST_LOADING_ERROR = 'MARKETING_BANNERS_LIST_LOADING_ERROR' as const;

export const HOME_BANNERS_LIST_LOADING = 'HOME_BANNERS_LIST_LOADING' as const;
export const HOME_BANNERS_LIST_LOADING_SUCCESS = 'HOME_BANNERS_LIST_LOADING_SUCCESS' as const;
export const HOME_BANNERS_LIST_LOADING_ERROR = 'HOME_BANNERS_LIST_LOADING_ERROR' as const;

export const PARTNERS_BANNERS_LIST_LOADING = 'PARTNERS_BANNERS_LIST_LOADING' as const;
export const PARTNERS_BANNERS_LIST_LOADING_SUCCESS = 'PARTNERS_BANNERS_LIST_LOADING_SUCCESS' as const;
export const PARTNERS_BANNERS_LIST_LOADING_ERROR = 'PARTNERS_BANNERS_LIST_LOADING_ERROR' as const;

const CHANEL_PRODUCT_WEB = 'web';
const CHANEL_PRODUCT_MOBILE = 'mobile';
const CHANEL_ADVERTISEMENT_WEB = 'web_c2c';
const CHANEL_ADVERTISEMENT_MOBILE = 'mobile_c2c';
const CHANEL_SERVICES_WEB = 'web_services';
const CHANEL_SERVICES_MOBILE = 'mobile_services';

const marketingBannersLoading = () => ({
    type: MARKETING_BANNERS_LIST_LOADING,
});

const marketingBannersSucess = (banners: Banner[], count: number) => ({
    type: MARKETING_BANNERS_LIST_LOADING_SUCCESS,
    banners,
    count,
});

const marketingBannersError = (error: unknown) => ({
    type: MARKETING_BANNERS_LIST_LOADING_ERROR,
    error,
});

const homeBannersLoading = () => ({
    type: HOME_BANNERS_LIST_LOADING,
});

const homeBannersSucess = (banners: Banner[], count: number) => ({
    type: HOME_BANNERS_LIST_LOADING_SUCCESS,
    banners,
    count,
});

const homeBannersError = (error: unknown) => ({
    type: HOME_BANNERS_LIST_LOADING_ERROR,
    error,
});

const partnersBannersLoading = () => ({
    type: PARTNERS_BANNERS_LIST_LOADING,
});

const partnersBannersSucess = (banners: Banner[], count: number) => ({
    type: PARTNERS_BANNERS_LIST_LOADING_SUCCESS,
    banners,
    count,
});

const partnersBannersError = (error: unknown) => ({
    type: PARTNERS_BANNERS_LIST_LOADING_ERROR,
    error,
});

export type BannersActions = ReturnType<
    | typeof marketingBannersLoading
    | typeof marketingBannersSucess
    | typeof marketingBannersError
    | typeof homeBannersLoading
    | typeof homeBannersSucess
    | typeof homeBannersError
    | typeof partnersBannersLoading
    | typeof partnersBannersSucess
    | typeof partnersBannersError
>;

const getChannel = (productType: ProductType = ProductType.Product) => {
    if (productType === ProductType.Advertisement) {
        return Device.desktop ? CHANEL_ADVERTISEMENT_WEB : CHANEL_ADVERTISEMENT_MOBILE;
    }

    if (productType === ProductType.Service) {
        return Device.desktop ? CHANEL_SERVICES_WEB : CHANEL_SERVICES_MOBILE;
    }

    return Device.desktop ? CHANEL_PRODUCT_WEB : CHANEL_PRODUCT_MOBILE;
};

const getBannerCategoriesCount = (productType: ProductType = ProductType.Product) => {
    if (productType === ProductType.Service) {
        return 11;
    }

    return BANNER_MARETING_CATEGORIES_COUNT;
};

const BANNER_COUNT = 5 as const;
const BANNER_MARETING_PROMOTIONS_COUNT = 5 as const;
const BANNER_MARETING_CATEGORIES_COUNT = 2 as const;
const PARTNERS_BANNERS_COUNT = 8 as const;

const HOME_BANNERS_LABELS = [BannerLabel.HOME_BIG, BannerLabel.HOME_SMALL];
const PARTNERS_BANNERS_LABELS = [BannerLabel.PARTNERS];

const bannerControllerApi = new BannerControllerApi();
const publicControllerApi = new PublicControllerApi();

export const loadMarketingBanners = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    const loggedIn = isLoggedIn(state);

    dispatch(marketingBannersLoading());

    try {
        const activeProductType = state.rootReducer.activeProductType;
        const language = getLanguageCode(state);
        const channel = getChannel(activeProductType);
        const result = await Promise.all(
            (activeProductType === ProductType.Service
                ? [BannerLabel.CATEGORY]
                : [BannerLabel.PROMOTION, BannerLabel.CATEGORY]
            ).map((target) =>
                loggedIn
                    ? new BannerControllerApi().bannerListUsingGET(
                          channel,
                          target === BannerLabel.PROMOTION
                              ? BANNER_MARETING_PROMOTIONS_COUNT
                              : getBannerCategoriesCount(activeProductType),
                          [target],
                          language,
                      )
                    : new PublicControllerApi().bannerListUsingGET1(
                          channel,
                          target === BannerLabel.PROMOTION
                              ? BANNER_MARETING_PROMOTIONS_COUNT
                              : getBannerCategoriesCount(activeProductType),
                          [target],
                          language,
                      ),
            ),
        );

        const body = result.reduce((array, current) => [...array, ...(current.body || [])], []);
        const count = result.reduce((sum, current) => sum + current.totalCount, 0);
        dispatch(marketingBannersSucess(body ?? [], count ?? 0));
    } catch (error) {
        console.error('at registerBannerClick in loadMarketingBanners', error);

        dispatch(marketingBannersError(error));
    }
};

export const loadHomeBanners = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(homeBannersLoading());

    try {
        const language = getLanguageCode(getState());
        const channel = getChannel();

        const res = await new PublicControllerApi().bannerListUsingGET1(
            channel,
            BANNER_COUNT,
            HOME_BANNERS_LABELS,
            language,
        );
        dispatch(homeBannersSucess(res.body ?? [], res.count ?? 0));
    } catch (error) {
        console.error('at registerBannerClick in loadHomeBanners', error);

        dispatch(homeBannersError(error));
    }
};

export const loadPartnersBanners = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(partnersBannersLoading());

    try {
        const language = getLanguageCode(getState());
        const channel = getChannel();

        const res = await new PublicControllerApi().bannerListUsingGET1(
            channel,
            PARTNERS_BANNERS_COUNT,
            PARTNERS_BANNERS_LABELS,
            language,
        );
        dispatch(partnersBannersSucess(res.body ?? [], res.count ?? 0));
    } catch (error) {
        console.error('at registerBannerClick in loadPartnersBanners', error);

        dispatch(partnersBannersError(error));
    }
};

export const registerBannerClick =
    (bannerUid: string, channel?: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();

        try {
            if (!channel) channel = getChannel();
            await (isLoggedIn(state)
                ? new BannerControllerApi().processManualClickUsingGET(bannerUid, channel)
                : new PublicControllerApi().processManualClickUsingGET1(bannerUid, channel));
        } catch (error) {
            console.error('at bannersActions in registerBannerClick', error);

            dispatch(marketingBannersError(error));
        }
    };

export const loadMarketBanners =
    (bannerLabel: MarketBannerLabel, count = 1) =>
    async (_: AppDispatch, getState: () => IApplicationStore) => {
        try {
            const response = isLoggedIn(getState())
                ? await bannerControllerApi.bannerListUsingGET(getChannel(), count, [bannerLabel])
                : await publicControllerApi.bannerListUsingGET1(getChannel(), count, [bannerLabel]);
            return { data: response?.body };
        } catch (error) {
            console.error('at bannersActions in registerBannerClick', error);
            return { error };
        }
    };

import { client, commonapiURL } from '@/axios';
import { ClassificatorWsControllerApi, Country, Currency } from '@/generated/commonapi';
import { Classificator, ClassificatorControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const CLASSIFICATOR_LIST_LOADING = 'CLASSIFICATOR_LIST_LOADING' as const;
export const CLASSIFICATOR_LIST_LOADING_SUCCESS = 'CLASSIFICATOR_LIST_LOADING_SUCCESS' as const;
export const CLASSIFICATOR_LIST_LOADING_ERROR = 'CLASSIFICATOR_LIST_LOADING_ERROR' as const;

export const CHANGE_APP_LANGUAGE = 'CHANGE_APP_LANGUAGE' as const;

const classificatorListLoadingAction = () => ({
    type: CLASSIFICATOR_LIST_LOADING,
});

const classificatorListLoadingSuccessAction = (classificatorType: EntityClassificatorType, items: Classificator[]) => ({
    type: CLASSIFICATOR_LIST_LOADING_SUCCESS,
    classificatorType,
    items,
});

const currencyClassificatorListLoadingSuccessAction = (currencyClassificator: Currency[]) => ({
    type: CLASSIFICATOR_LIST_LOADING_SUCCESS,
    currencyClassificator,
});

const countryClassificatorListLoadingSuccessAction = (countryClassificator: Country[]) => ({
    type: CLASSIFICATOR_LIST_LOADING_SUCCESS,
    countryClassificator,
});

const classificatorListLoadingErrorAction = (error: unknown) => ({
    type: CLASSIFICATOR_LIST_LOADING_ERROR,
    error,
});

const claimSubjectClassificatorListLoadingSuccessAction = (items: Classificator[]) => ({
    type: CLASSIFICATOR_LIST_LOADING_SUCCESS,
    subjectType: 'ClaimSubjects',
    items,
});

export const changeLanguage = (language: string) => ({
    type: CHANGE_APP_LANGUAGE,
    language,
});

export type ClassificatorActions = ReturnType<
    | typeof classificatorListLoadingAction
    | typeof classificatorListLoadingSuccessAction
    | typeof currencyClassificatorListLoadingSuccessAction
    | typeof countryClassificatorListLoadingSuccessAction
    | typeof classificatorListLoadingErrorAction
    | typeof claimSubjectClassificatorListLoadingSuccessAction
    | typeof changeLanguage
>;

export const fillClassificatorProductStatusValue = (products: IProduct[], state: IApplicationStore) => {
    const { Product_Status } = state.classificatorReducer.entitiesClassificators;
    products.forEach((item) => {
        const status = Product_Status.find((status) => status.code === item.status.toString());
        if (status) item.statusValue = status.value;
    });
};

export type EntityClassificatorType =
    | 'Product_Status'
    | 'Account_Status'
    | 'Account_Type'
    | 'Order_State'
    | 'Url_app'
    | 'Company_BusinessType'
    | 'Product_Color'
    | 'Discount_Type'
    | 'Discount_RoundType'
    | 'Sale_Error'
    | 'Sale_Status'
    | 'Tax_System';

export type ClaimSubjectClassificatorType = 'Application' | 'MyProduct';

export const loadClassificator =
    (classificatorType: EntityClassificatorType) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(classificatorListLoadingAction());
        try {
            const entityFields = classificatorType.toString().split('_');
            const entity = entityFields[0];
            let field = entityFields[1];

            const state = getState();
            const app = state.rootReducer.localConfig.appCode;

            if (entity === 'Url') {
                field = app;
            }

            /* TODO */
            if (
                entity === 'Product' ||
                entity === 'Payment' ||
                entity === 'Delivery' ||
                entity === 'Order' ||
                entity === 'Discount' ||
                entity === 'Sale' ||
                entity === 'Store' ||
                entity === 'Tax'
            ) {
                dispatch(
                    classificatorListLoadingSuccessAction(
                        classificatorType,
                        (
                            await new ClassificatorControllerApi().entityListUsingGET(
                                entity,
                                field,
                                getLanguageCode(state),
                            )
                        ).body || [],
                    ),
                );
            } else if (entity === 'Account' || entity === 'Url' || entity === 'Company') {
                dispatch(
                    classificatorListLoadingSuccessAction(
                        classificatorType,
                        (
                            await new ClassificatorWsControllerApi().getClassificatorByEntityAndFieldUsingGET(
                                entity,
                                field,
                                getLanguageCode(state),
                            )
                        ).classificatorList || [],
                    ),
                );
            } else {
                throw new Error('Check entity name!');
            }
        } catch (err) {
            console.error('at classificatorActions in loadClassificator', err);

            dispatch(classificatorListLoadingErrorAction(err.toString()));
        }
    };

export const loadClaimSubjectsClassificator =
    (subjectTypes: ClaimSubjectClassificatorType[]) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(classificatorListLoadingAction());

        const state = getState();
        try {
            dispatch(
                claimSubjectClassificatorListLoadingSuccessAction(
                    (
                        await new ClassificatorControllerApi().entityListByFieldsListUsingGET(
                            'ClaimSubjects',
                            subjectTypes,
                            getLanguageCode(state),
                        )
                    ).body || [],
                ),
            );
        } catch (err) {
            dispatch(classificatorListLoadingErrorAction(err.toString()));
        }
    };

const getActiveSTypeCurrencyList = async (lng: string) =>
    (await new ClassificatorWsControllerApi().getCurrencyListUsingGET(true, lng, false, 'S')).currencyList;

export const loadCurrencyClassificator = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(classificatorListLoadingAction());

    const state = getState();

    try {
        dispatch(
            currencyClassificatorListLoadingSuccessAction(await getActiveSTypeCurrencyList(getLanguageCode(state))),
        );
    } catch (err) {
        dispatch(classificatorListLoadingErrorAction(err.toString()));
    }
};

const getActiveCountryList = async (lng: string) =>
    (await new ClassificatorWsControllerApi().getCountryListUsingGET(lng)).countryList;

export const loadCountryClassificator = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(classificatorListLoadingAction());

    const state = getState();

    try {
        const countries = await getActiveCountryList(getLanguageCode(state));
        dispatch(countryClassificatorListLoadingSuccessAction(countries));
    } catch (err) {
        dispatch(classificatorListLoadingErrorAction(err.toString()));
    }
};

export const getAvailableLanguage = async (language: string) => {
    const response = await client.get(commonapiURL + `/classificator/AvailableLanguage?language=${language}`);
    return response.data.languageList[0].code;
};

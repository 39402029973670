import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIcon } from '@/types/icons';

export const IcOnboardingMessageStore: IcIcon = React.memo(() => {
    const gradUid1 = useNodeUid();
    const gradUid2 = useNodeUid();
    const gradUid3 = useNodeUid();
    const gradUid4 = useNodeUid();

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                fill={`url(#${gradUid1})`}
            />
            <path
                d="M18.1683 30.5226C16.3716 21.5296 11.5993 14.3914 4.91807 9.44531C0.0896159 15.7966 -1.48244 24.4523 1.54938 32.4336C5.08651 41.7076 13.6767 47.553 22.9406 47.9465C20.5825 42.3259 19.1227 36.1432 18.1683 30.5226Z"
                fill={`url(#${gradUid2})`}
            />
            <path
                d="M29.954 0.785156C30.6839 1.68445 31.3577 2.58375 31.9753 3.53926C39.8917 15.6236 33.3227 29.2817 32.7051 42.3215C32.5928 43.6142 32.5928 44.9632 32.8174 46.3121C45.0009 41.5346 51.0645 27.8203 46.4606 15.5112C43.5411 7.92333 37.1967 2.63996 29.954 0.785156Z"
                fill={`url(#${gradUid3})`}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7554 12H27H29.5H31.2446C33.0022 12 34.524 13.2091 34.9053 14.9085L36.9702 20.6362L37 20.9048C37 21.5201 36.8867 22.1093 36.6796 22.6528C36.4374 23.2886 35.8423 23.7709 35.1663 23.6944C33.9587 23.5578 32.9116 22.5405 32.9116 21.2381C32.9116 20.6527 32.5013 20.1621 31.95 20.0331V20.0371C31.8525 20.0129 31.7503 20 31.6452 20L31.6426 20L31.6399 20C30.9495 20 30.3899 20.5543 30.3899 21.2381C30.3899 22.6057 29.2706 23.7143 27.8899 23.7143C26.5092 23.7143 25.3899 22.6057 25.3899 21.2381C25.3899 20.6527 24.9797 20.1621 24.4284 20.0331V20.0359C24.3323 20.0124 24.2318 20 24.1284 20L24.126 20L24.1236 20C23.4332 20 22.8736 20.5543 22.8736 21.2381V21.393C22.7928 22.6884 21.7065 23.7143 20.3784 23.7143C18.9977 23.7143 17.8784 22.6057 17.8784 21.2381C17.8784 20.6527 17.4681 20.1621 16.9168 20.0331C16.8238 20.0112 16.7267 20 16.6269 20L16.6195 20L16.612 20C15.9217 20 15.362 20.5543 15.362 21.2381V21.5102C15.2838 22.2191 14.9034 22.8381 14.3511 23.2382C13.5548 23.815 12.4833 23.2488 12.203 22.3062C12.0709 21.8619 12 21.3915 12 20.9048L12.0298 20.6362L14.0947 14.9085C14.476 13.2091 15.9978 12 17.7554 12ZM13.25 26.1769V34.9999C13.25 36.1045 14.154 36.9999 15.2692 36.9999H20.75V31.7144C20.75 30.6098 21.654 29.7144 22.7692 29.7144H26.2308C27.3459 29.7144 28.25 30.6098 28.25 31.7144V36.9999H33.7308C34.846 36.9999 35.75 36.1045 35.75 34.9999V26.1793C35.6382 26.1867 35.5253 26.1905 35.4116 26.1905C34.4941 26.1905 33.6343 25.9457 32.8952 25.5185L32.8899 25.516V25.519C32.432 25.2617 32.0092 24.9276 31.6426 24.5168C31.4098 24.7777 31.1543 25.0075 30.8817 25.2065C30.0474 25.8245 29.0118 26.1905 27.8899 26.1905C26.9724 26.1905 26.1127 25.9457 25.3736 25.5185C24.9155 25.2612 24.4927 24.9275 24.126 24.5166C23.2098 25.5432 21.8704 26.1905 20.3784 26.1905C19.4609 26.1905 18.6011 25.9457 17.862 25.5185C17.4061 25.2624 16.985 24.9306 16.6195 24.5222C15.7807 25.4593 14.5886 26.0791 13.25 26.1769ZM26.2308 36.9999H22.7692L22.7692 31.7144H26.2308L26.2308 36.9999Z"
                fill={`url(#${gradUid4})`}
            />
            <defs>
                <linearGradient
                    id={gradUid1}
                    x1="-13.5729"
                    y1="-9.18087"
                    x2="37.0408"
                    y2="35.5207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid2}
                    x1="-15.9401"
                    y1="19.7926"
                    x2="18.1034"
                    y2="30.3877"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid3}
                    x1="14.9347"
                    y1="7.26485"
                    x2="48.2843"
                    y2="36.6868"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00236328" stopColor="var(--brand-primary-20)" />
                    <stop offset="1" stopColor="var(--brand-primary-30)" />
                </linearGradient>
                <linearGradient
                    id={gradUid4}
                    x1="8.46635"
                    y1="7"
                    x2="33.9259"
                    y2="37.2405"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="0.458333" stopColor="var(--brand-primary-0)" />
                    <stop offset="1" stopColor="var(--brand-primary-10)" />
                </linearGradient>
            </defs>
        </svg>
    );
});
IcOnboardingMessageStore.displayName = 'IcOnboardingMessageStore';

import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import React, { Fragment } from 'react';

import { noop } from '@/utils';

import './Sheet.less';

export interface IMSSheetProps {
    opened: boolean;
    className?: string;
    children?: React.ReactNode;
    backdrop?: boolean;
    onSheetClose?: () => void;
    onSheetClosed?: () => void;
    onSheetOpen?: () => void;
    onSheetOpened?: () => void;
    onBackdropClick?: () => void;
}

export const MSSheet: React.FC<IMSSheetProps> = React.memo(
    ({
        opened,
        className,
        children,
        backdrop = true,
        onSheetOpen,
        onSheetOpened,
        onSheetClose,
        onSheetClosed,
        onBackdropClick,
    }) => {
        return (
            <Transition show={opened} as={Dialog} onClose={noop}>
                {backdrop && (
                    <Transition.Child
                        enter="fade-in"
                        enterFrom="fade-in-from"
                        enterTo="fade-in-to"
                        leave="fade-in"
                        leaveFrom="fade-in-to"
                        leaveTo="fade-in-from"
                        as={Fragment}
                    >
                        <div className="ms-sheet-overlay" onClick={onBackdropClick} />
                    </Transition.Child>
                )}
                <Transition.Child
                    enter="slide-in"
                    enterFrom="slide-in-from"
                    enterTo="slide-in-to"
                    leave="slide-in"
                    leaveFrom="slide-in-to"
                    leaveTo="slide-in-from"
                    beforeEnter={onSheetOpen}
                    afterEnter={onSheetOpened}
                    beforeLeave={onSheetClose}
                    afterLeave={onSheetClosed}
                    as={Fragment}
                >
                    <div className={cn('ms-sheet', className)}>{children}</div>
                </Transition.Child>
            </Transition>
        );
    },
);

import { AnyAction } from 'redux';

import { SAVE_LANGUAGE_LIST } from '@/actions/languagesActions';
import { Language } from '@/generated/commonapi';
import { availableLanguages } from '@/i18n';

export interface ILanguageListReducerState {
    languageList: Language[];
}

const initialState: ILanguageListReducerState = {
    languageList: [],
};

const languageListReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case SAVE_LANGUAGE_LIST:
            return {
                ...state,
                languageList: (action.payload || []).filter((language: Language) =>
                    availableLanguages.includes(language.code),
                ),
            };
        default:
            return state;
    }
};

export default languageListReducer;

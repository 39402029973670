import { useEffect } from 'react';

export const usePopupOpenedOnLoad = (
    shouldBeOpened: boolean,
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    useEffect(() => {
        if (shouldBeOpened) {
            setOpened(true);
        }
    }, [shouldBeOpened, setOpened]);
};

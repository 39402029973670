import { f7 } from 'framework7-react';
import React, { useCallback } from 'react';

import { SellerAreaMenuProps } from './SellerAreaMenu.types';

import { useSellerAreaMenuItems } from './useSellerAreaMenuItems';

import { onbordingMessagesService } from '@/api/onbordingMessagesService';
import { PageSideMenu } from '@/components/PageSideMenu';
import { PageSideMenuItem } from '@/components/PageSideMenu/PageSideMenu.types';
import { LoadAccountStores } from '@/hoc/LoadAccountStores';
import { LoadMyGoodsList } from '@/hoc/LoadMyGoodsList';
import { LoadTranslatedSalesList } from '@/hoc/LoadTranslatedSalesList';

import './SellerAreaMenu.less';

export const SellerAreaMenu = ({
    selected,
    selectedRounded,
    selectedSubitem,
    handleItemClick,
}: SellerAreaMenuProps) => {
    const { menuItemNames, menuItems } = useSellerAreaMenuItems();

    const onItemClick = useCallback(
        (item: PageSideMenuItem) => {
            handleItemClick?.(item);
            if (item.link) {
                setTimeout(() => {
                    f7.views.main.router.navigate(item.link);
                }, 300);
            }
        },
        [handleItemClick],
    );

    const onOnbordingMessageClick = useCallback(
        (menuItem: PageSideMenuItem) => f7.views.main.router.navigate(menuItem.onbordingMessage.link),
        [],
    );

    // ? no memo because can't detect localStorage changes
    const onbordingMessagesConf = onbordingMessagesService.getConfFromLocalStorage();

    return (
        <>
            {/* // ? Load data to calculate show OnboardingMessage or not */}
            {!onbordingMessagesConf.PRODUCTS && <LoadMyGoodsList />}
            {(!onbordingMessagesConf.PRODUCTS ||
                !onbordingMessagesConf.SALE ||
                !onbordingMessagesConf.STORE_HOMEPAGE) && <LoadAccountStores />}
            {!onbordingMessagesConf.SALE && <LoadTranslatedSalesList />}
            <PageSideMenu
                className="seller-area-menu"
                menuItems={menuItems}
                menuItemsNames={menuItemNames}
                onItemClick={onItemClick}
                selectedName={selected}
                selectedRounded={selectedRounded}
                selectedSubitemName={selectedSubitem}
                onOnbordingMessageClick={onOnbordingMessageClick}
            />
        </>
    );
};

import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect, useRef, useState } from 'react';

export interface IContainerSize {
    width: number | undefined;
    height: number | undefined;
    right: number | undefined;
    left: number | undefined;
    top: number | undefined;
}

export const useElementSize = <T extends HTMLElement>() => {
    const [container, setContainer] = useState<IContainerSize>({
        width: undefined,
        height: undefined,
        left: undefined,
        right: undefined,
        top: undefined,
    });

    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        setContainer(ref.current.getBoundingClientRect());
    }, []);

    // Where the magic happens
    useResizeObserver(ref, (entry) => setContainer(entry.target.getBoundingClientRect()));

    return { ref, container };
};

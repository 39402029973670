import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIconProps } from '@/types/icons';

export const IcGreen = React.memo<IcIconProps>((props) => {
    const maskId = useNodeUid();

    return (
        <svg width={20} height={20} fill="none" {...props}>
            <mask
                id={maskId}
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <circle cx={10} cy={10} r={10} fill="#D9D9D9" />
            </mask>
            <g mask={`url(#${maskId})`}>
                <path d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z" fill="#63CD70" />
                <path d="M10 10H0v10h10V10z" fill="#fff" fillOpacity={0.3} />
                <path d="M10 0H0v10h10V0z" fill="#000" fillOpacity={0.1} />
                <path d="M20 10H10v10h10V10z" fill="#000" fillOpacity={0.5} />
                <path d="M20 0H10v10h10V0z" fill="#000" fillOpacity={0.3} />
            </g>
        </svg>
    );
});
IcGreen.displayName = 'IcGreen';

import {
    ALL_DELIVERY_PROVIDERS_LOADED,
    DELIVERY_PROVIDERS_ERROR,
    DELIVERY_PROVIDERS_LOADING,
    DeliveryProvidersActions,
} from '@/actions/deliveryProvidersActions';
import { PlatformDeliveryProvider } from '@/generated/marketplaceapi';

export interface IDeliveryProvidersState {
    loading?: boolean;
    error?: unknown;
    allDeliveryProviders?: PlatformDeliveryProvider[];
}

const initialState: IDeliveryProvidersState = {
    error: null,
    allDeliveryProviders: [],
};

const deliveryProvidersReducer = (state = initialState, action: DeliveryProvidersActions): IDeliveryProvidersState => {
    switch (action.type) {
        case DELIVERY_PROVIDERS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
                allDeliveryProviders: undefined,
            };
        case DELIVERY_PROVIDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ALL_DELIVERY_PROVIDERS_LOADED:
            return {
                ...state,
                loading: false,
                error: undefined,
                allDeliveryProviders: action.items,
            };
        default:
            return state;
    }
};

export default deliveryProvidersReducer;

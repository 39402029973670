import { ConfigureSaleDraft, ConfigureSaleSteps } from './ConfigureSale.types';
import { DiscountTypeSelectValue } from './GeneralInfo/GeneralInfo.types';

import { Classificator } from '@/generated/commonapi';
import { Sale } from '@/generated/marketplaceapi';
import { classificatorToCustomSelectValue, formatLocalDateForAPIRequest } from '@/utils';

export const draftValuesToSale = (draft: ConfigureSaleDraft, storeUid: string): Sale => ({
    ...draft[ConfigureSaleSteps.GENERAL_INFO],
    ...draft[ConfigureSaleSteps.PRODUCTS_SELECT],
    startDate: formatLocalDateForAPIRequest(
        new Date(draft[ConfigureSaleSteps.GENERAL_INFO].startDate.setHours(0, 0, 0)),
    ) as unknown as Date,
    endDate: draft[ConfigureSaleSteps.GENERAL_INFO].endDate
        ? (formatLocalDateForAPIRequest(
              new Date(draft[ConfigureSaleSteps.GENERAL_INFO].endDate.setHours(23, 59, 59)),
          ) as unknown as Date)
        : undefined,
    discountAmountPercent: Number(draft[ConfigureSaleSteps.GENERAL_INFO].discountAmountPercent),
    roundingType: draft[ConfigureSaleSteps.GENERAL_INFO].roundingType?.value as unknown as Sale.RoundingTypeEnum,
    discountType: undefined,
    status: Sale.StatusEnum.NEW,
    storeUid,
    uid: draft[ConfigureSaleSteps.PRODUCTS_SELECT].uid,
});

export const getDefaultDraft = (
    discountClassificator: Classificator[],
    roundingClassificator: Classificator[],
): ConfigureSaleDraft => {
    const discountType = classificatorToCustomSelectValue(discountClassificator?.[0]) as DiscountTypeSelectValue;
    return {
        [ConfigureSaleSteps.GENERAL_INFO]: {
            discountType,
            roundingType:
                discountType.value === 'P' ? classificatorToCustomSelectValue(roundingClassificator?.[0]) : undefined,
        },
        [ConfigureSaleSteps.PRODUCTS_SELECT]: {},
    };
};

export const saleToConfigureSaleDraft = (
    sale: Sale,
    discountClassificator: Classificator[],
    roundingClassificator: Classificator[],
    clear?: boolean,
): ConfigureSaleDraft => {
    const discountType = discountClassificator?.find((item) => item.code === sale.discountType);
    return {
        [ConfigureSaleSteps.GENERAL_INFO]: {
            name: sale.name,
            neverExpires: !sale.endDate,
            validDiscountAmountRangeFrom: sale.validDiscountAmountRangeFrom,
            validDiscountAmountRangeTo: sale.validDiscountAmountRangeTo,
            discountAmountFixed: sale.discountAmountFixed,
            discountAmountPercent: sale.discountAmountPercent,
            discountType: classificatorToCustomSelectValue(discountType) as DiscountTypeSelectValue,
            roundingType: sale.roundingType
                ? classificatorToCustomSelectValue(
                      roundingClassificator?.find((item) => item.code === (sale.roundingType as unknown as string)),
                  )
                : undefined,
            endDate: sale.endDate && !clear ? new Date(sale.endDate) : undefined,
            startDate: sale.startDate && !clear ? new Date(sale.startDate) : undefined,
        },
        [ConfigureSaleSteps.PRODUCTS_SELECT]: {
            uid: clear ? undefined : sale.uid,
            products: sale.products,
        },
    };
};

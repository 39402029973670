import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    AddProductReviewImageRequest,
    AddProductReviewRequest,
    CommonMarketplaceApiResponseProductReview,
    ProductReview,
    ProductReviewAccountListRequest,
    ProductReviewControllerApi,
    ProductReviewImage,
    PublicControllerApi,
} from '@/generated/marketplaceapi';

import { IApplicationStore } from '@/reducers/rootReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getProfile } from '@/selectors/profile';
import { ReviewSortingFields, ReviewSortingFields as Sort } from '@/shared/constants';
import { Buckets, getFullProductRating, upload2bucket } from '@/utils';

import type { AppDispatch } from '@/hooks/store';
import type { ProductRating } from '@/reducers/reviewReducer';

export const SET_PRODUCT_UID_FOR_REVIEWS = 'SET_PRODUCT_UID_FOR_REVIEWS' as const;

export const REVIEWS_LOADING = 'REVIEWS_LOADING' as const;
export const REVIEWS_LOADING_SUCCESS = 'REVIEWS_LOADING_SUCCESS' as const;
export const REVIEWS_LOADING_ERROR = 'REVIEWS_LOADING_ERROR' as const;

export const SELLER_REVIEWS_LOADING_SUCCESS = 'SELLER_REVIEWS_LOADING_SUCCESS' as const;
export const SELLER_REVIEWS_LOADING = 'SELLER_REVIEWS_LOADING' as const;
export const SELLER_REVIEWS_LOADING_ERROR = 'SELLER_REVIEWS_LOADING_ERROR' as const;

export const MY_REVIEWS_LOADING = 'MY_REVIEWS_LOADING' as const;
export const MY_REVIEWS_LOADING_SUCCESS = 'MY_REVIEWS_LOADING_SUCCESS' as const;
export const MY_REVIEWS_LOADING_ERROR = 'MY_REVIEWS_LOADING_ERROR' as const;
export const SET_MY_REVIEW_SORT_FIELD = 'SET_MY_REVIEW_SORT_FIELD' as const;
export const SET_MY_REVIEW_TOTAL_COUNT = 'SET_MY_REVIEW_TOTAL_COUNT' as const;

export const SORT_MY_REVIEWS = 'SORT_MY_REVIEWS' as const;
export const SORT_MY_REVIEWS_LOADING = 'SORT_MY_REVIEWS_LOADING' as const;
export const SORT_MY_REVIEWS_ERROR = 'SORT_MY_REVIEWS_ERROR' as const;

export const ADD_REVIEW_LOADING = 'ADD_REVIEW_LOADING' as const;
export const ADD_REVIEW_LOADING_SUCCESS = 'ADD_REVIEW_LOADING_SUCCESS' as const;
export const ADD_REVIEW_LOADING_ERROR = 'ADD_REVIEW_LOADING_ERROR' as const;
export const SET_REVIEW_SORT_FIELD = 'SET_REVIEW_SORT_FIELD' as const;

export const PRODUCT_RATING_LOADING = 'PRODUCT_RATING_LOADING' as const;
export const PRODUCT_RATING_LOADING_SUCCESS = 'PRODUCT_RATING_LOADING_SUCCESS' as const;
export const PRODUCT_RATING_LOADING_ERROR = 'PRODUCT_RATING_LOADING_ERROR' as const;
export const SET_PRODUCT_RATING = 'SET_PRODUCT_RATING' as const;

export const EDIT_REVIEW_LOADING = 'EDIT_REVIEW_LOADING' as const;
export const EDIT_REVIEW_LOADING_SUCCESS = 'EDIT_REVIEW_LOADING_SUCCESS' as const;
export const EDIT_REVIEW_LOADING_ERROR = 'EDIT_REVIEW_LOADING_ERROR' as const;

export const DELETE_MY_REVIEW_LOADING = 'DELETE_MY_REVIEW_LOADING' as const;
export const DELETE_MY_REVIEW_LOADING_SUCCESS = 'DELETE_MY_REVIEW_LOADING_SUCCESS' as const;
export const DELETE_MY_REVIEW_LOADING_ERROR = 'DELETE_MY_REVIEW_LOADING_ERROR' as const;
export const DELETE_MY_REVIEW = 'DELETE_MY_REVIEW' as const;

export const SELLER_REVIEWS_PER_LOAD = 10;

export const setProductUidForReviewsAction = (productUid: string) => ({
    type: SET_PRODUCT_UID_FOR_REVIEWS,
    productUid,
});

export const reviewsLoadingAction = () => ({
    type: REVIEWS_LOADING,
});

export const reviewsLoadingSuccessAction = (reviews: ProductReview[]) => ({
    type: REVIEWS_LOADING_SUCCESS,
    reviews,
});

export const reviewsLoadingErrorAction = (error: unknown) => ({
    type: REVIEWS_LOADING_ERROR,
    error,
});

export const sellerReviewsLoadingAction = (sellerReviewsLoading: boolean) => ({
    type: SELLER_REVIEWS_LOADING,
    sellerReviewsLoading,
});

export const sellerReviewsLoadingSuccessAction = (
    sellerReviews: ProductReview[],
    sellerReviewsTotalCount: number,
    isReload: boolean,
) => ({
    type: SELLER_REVIEWS_LOADING_SUCCESS,
    sellerReviews,
    sellerReviewsTotalCount,
    isReload,
});

export const sellerReviewsLoadingErrorAction = (sellerReviewsLoadingError: string) => ({
    type: SELLER_REVIEWS_LOADING_ERROR,
    sellerReviewsLoadingError,
});

export const setReviewSortFieldAction = (sort: Sort) => ({
    type: SET_REVIEW_SORT_FIELD,
    sort,
});

export const myReviewsLoadingAction = (loading: boolean) => ({
    type: MY_REVIEWS_LOADING,
    loading,
});

export const myReviewsLoadingSuccessAction = (response: CommonMarketplaceApiResponseProductReview) => ({
    type: MY_REVIEWS_LOADING_SUCCESS,
    response,
});

export const myReviewsLoadingErrorAction = (error: unknown) => ({
    type: MY_REVIEWS_LOADING_ERROR,
    error,
});

export const sortMyReviewsAction = (response: CommonMarketplaceApiResponseProductReview) => ({
    type: SORT_MY_REVIEWS,
    response,
});

export const sortMyReviewsLoadingAction = () => ({
    type: SORT_MY_REVIEWS_LOADING,
});

export const sortMyReviewsErrorAction = (err: unknown) => ({
    type: SORT_MY_REVIEWS_ERROR,
    err,
});

export const setMyReviewSortFieldAction = (mySort: Sort) => ({
    type: SET_MY_REVIEW_SORT_FIELD,
    mySort,
});

export const addReviewLoadingAction = (loading: boolean) => ({
    type: ADD_REVIEW_LOADING,
    loading,
});

export const addReviewLoadingSuccessAction = (success: boolean) => ({
    type: ADD_REVIEW_LOADING_SUCCESS,
    success,
});

export const addReviewLoadingErrorAction = (error: unknown) => ({
    type: ADD_REVIEW_LOADING_ERROR,
    error,
});

export const editReviewLoadingAction = (loading: boolean) => ({
    type: EDIT_REVIEW_LOADING,
    loading,
});

export const editReviewLoadingSuccessAction = (success: boolean) => ({
    type: EDIT_REVIEW_LOADING_SUCCESS,
    success,
});

export const editReviewLoadingErrorAction = (error: unknown) => ({
    type: EDIT_REVIEW_LOADING_ERROR,
    error,
});

export const deleteMyReviewLoadingAction = (loading: boolean) => ({
    type: DELETE_MY_REVIEW_LOADING,
    loading,
});

export const deleteMyReviewLoadingSuccessAction = (success: boolean) => ({
    type: DELETE_MY_REVIEW_LOADING_SUCCESS,
    success,
});

export const deleteMyReviewLoadingErrorAction = (error: unknown) => ({
    type: DELETE_MY_REVIEW_LOADING_ERROR,
    error,
});

export const deleteMyReviewAction = (uid: string) => ({
    type: DELETE_MY_REVIEW,
    uid,
});

export const productRatingLoadingAction = (loading: boolean) => ({
    type: PRODUCT_RATING_LOADING,
    loading,
});

export const productRatingLoadingSuccessAction = (success: boolean) => ({
    type: PRODUCT_RATING_LOADING_SUCCESS,
    success,
});

export const productRatingLoadingErrorAction = (error: unknown) => ({
    type: PRODUCT_RATING_LOADING_ERROR,
    error,
});

export const setProductRatingAction = (rating: ProductRating[]) => ({
    type: SET_PRODUCT_RATING,
    rating,
});

export type ReviewActions = ReturnType<
    | typeof setProductUidForReviewsAction
    | typeof reviewsLoadingAction
    | typeof reviewsLoadingSuccessAction
    | typeof reviewsLoadingErrorAction
    | typeof sellerReviewsLoadingAction
    | typeof sellerReviewsLoadingSuccessAction
    | typeof sellerReviewsLoadingErrorAction
    | typeof addReviewLoadingAction
    | typeof addReviewLoadingSuccessAction
    | typeof addReviewLoadingErrorAction
    | typeof myReviewsLoadingAction
    | typeof myReviewsLoadingSuccessAction
    | typeof myReviewsLoadingErrorAction
    | typeof setReviewSortFieldAction
    | typeof productRatingLoadingAction
    | typeof productRatingLoadingSuccessAction
    | typeof productRatingLoadingErrorAction
    | typeof setProductRatingAction
    | typeof setMyReviewSortFieldAction
    | typeof editReviewLoadingAction
    | typeof editReviewLoadingSuccessAction
    | typeof editReviewLoadingErrorAction
    | typeof deleteMyReviewLoadingAction
    | typeof deleteMyReviewLoadingSuccessAction
    | typeof deleteMyReviewLoadingErrorAction
    | typeof deleteMyReviewAction
    | typeof sortMyReviewsAction
    | typeof sortMyReviewsErrorAction
    | typeof sortMyReviewsLoadingAction
>;

export const loadProductReviews =
    (productUid?: string, sort?: ReviewSortingFields) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(reviewsLoadingAction());

        const state = getState();
        const language = getLanguageCode(state);

        try {
            const reviews = await new PublicControllerApi().productReviewUsingGET(
                productUid ?? state.reviewReducer.productUid,
                language,
                sort,
            );

            dispatch(reviewsLoadingSuccessAction(reviews.body || []));
            dispatch(setReviewSortFieldAction(sort || ReviewSortingFields.recent));
        } catch (error) {
            console.error('at reviewActions in loadProductReviews', error);
            dispatch(reviewsLoadingErrorAction(error.message));
        }
    };

export const loadMyReviews =
    ({ sort, count, offset, dateFrom, dateTo }: Omit<ProductReviewAccountListRequest, 'sort'> & { sort?: Sort } = {}) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(myReviewsLoadingAction(true));
        const state = getState();
        const language = getLanguageCode(state);
        const sortBy = sort ?? state.reviewReducer.mySort;
        try {
            const response = await new ProductReviewControllerApi().getAccountReviewsUsingPOST(
                {
                    count,
                    offset,
                    dateFrom,
                    dateTo,
                    sort: sortBy,
                },
                language,
            );
            dispatch(myReviewsLoadingSuccessAction(response));
            dispatch(setMyReviewSortFieldAction(sortBy));
        } catch (error) {
            console.error('at myReviewsActions in loadMyReviews', error);
            dispatch(myReviewsLoadingErrorAction(error.message));
        } finally {
            dispatch(myReviewsLoadingAction(false));
        }
    };

export const sortMyReviews =
    ({
        sort,
        dateFrom,
        dateTo,
    }: Omit<ProductReviewAccountListRequest, 'sort' | 'count' | 'offset'> & { sort?: Sort } = {}) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(sortMyReviewsLoadingAction());
        const state = getState();
        const language = getLanguageCode(state);
        try {
            const response = await new ProductReviewControllerApi().getAccountReviewsUsingPOST(
                {
                    dateFrom,
                    dateTo,
                    sort: sort,
                },
                language,
            );
            dispatch(sortMyReviewsAction(response));
            dispatch(setMyReviewSortFieldAction(sort));
        } catch (error) {
            console.error('at myReviewsActions in sortMyReviews', error);
            dispatch(sortMyReviewsErrorAction(error.message));
        }
    };

export const loadSellerReviews =
    (request: ProductReviewAccountListRequest) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const language = getLanguageCode(state);
        const activeProductType = getActiveProductType(state);

        dispatch(sellerReviewsLoadingAction(true));
        try {
            const response = await new PublicControllerApi().getSellerAccountReviewsUsingPOST(
                request,
                getProfile(state)?.uid,
                language,
                activeProductType,
            );
            dispatch(
                sellerReviewsLoadingSuccessAction(response.body ?? [], response.totalCount ?? 0, request.offset === 0),
            );
        } catch (error) {
            console.error('at reviewActions in loadSellerReviews', error);
            dispatch(sellerReviewsLoadingErrorAction(error.message));
        } finally {
            dispatch(sellerReviewsLoadingAction(false));
        }

        return getState();
    };

export const upload2reviewBucket = async (images: File[] = []) =>
    await Promise.all<string>(images.map(upload2bucket(Buckets.review)));

export const addProductReview =
    (request: AddProductReviewRequest, images: File[] = [], customProductUid?: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(addReviewLoadingAction(true));
        const state = getState();
        const language = getLanguageCode(state);
        const productUid = customProductUid ?? state.reviewReducer.productUid;
        try {
            const imagesURLS = await upload2reviewBucket(images);
            const response = await new ProductReviewControllerApi().addProductReviewUsingPUT(
                {
                    ...request,
                    images: imagesURLS,
                },
                productUid,
                language,
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(addReviewLoadingSuccessAction(true));
        } catch (error) {
            console.error('at reviewActions in addProductReview', error);
            dispatch(addReviewLoadingErrorAction(error.message));
        } finally {
            dispatch(addReviewLoadingAction(false));
        }
    };

export const deleteImages = async (productUid: string, images: ProductReviewImage[]) => {
    const api = new ProductReviewControllerApi();
    await Promise.all(
        images.map((image) => api.addProductReviewImageUsingDELETE(image.uid, productUid, image.reviewUid)),
    );
};

export const addImages = async (productUid: string, images: AddProductReviewImageRequest[], reviewUid: string) => {
    const api = new ProductReviewControllerApi();
    await Promise.all(images.map((image) => api.addProductReviewImageUsingPUT(image, productUid, reviewUid)));
};

export const editImages = async (
    productUid: string,
    oldImages: ProductReviewImage[],
    newImages: ProductReviewImage[],
    imageFiles: File[],
    reviewUid: string,
) => {
    await deleteImages(
        productUid,
        oldImages.filter(({ uid }) => !newImages.find((image) => image.uid === uid)),
    );
    const newImageUrls = await upload2reviewBucket(imageFiles);
    await addImages(
        productUid,
        newImageUrls.map((imageUrl) => ({ imageUrl })),
        reviewUid,
    );
};

export const editReview =
    (
        request: AddProductReviewRequest,
        oldImages: ProductReviewImage[] = [],
        newImages: ProductReviewImage[] = [],
        imageFiles: File[] = [],
        productUid: string,
        reviewUid: string,
    ) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(editReviewLoadingAction(true));
        const state = getState();
        const language = getLanguageCode(state);
        try {
            await editImages(productUid, oldImages, newImages, imageFiles, reviewUid);
            const response = await new ProductReviewControllerApi().addProductReviewUsingPOST(
                request,
                productUid,
                reviewUid,
                language,
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(editReviewLoadingSuccessAction(true));
        } catch (error) {
            console.error('at reviewActions in editReview', error);
            dispatch(editReviewLoadingErrorAction(error.message));
        } finally {
            dispatch(editReviewLoadingAction(false));
        }
    };

export const deleteReview = (productUid?: string, reviewUid?: string) => async (dispatch: AppDispatch) => {
    dispatch(deleteMyReviewLoadingSuccessAction(false));
    dispatch(deleteMyReviewLoadingAction(true));
    try {
        await new ProductReviewControllerApi().addProductReviewImageUsingDELETE1(productUid, reviewUid);
        dispatch(deleteMyReviewAction(reviewUid));
        dispatch(deleteMyReviewLoadingSuccessAction(true));
    } catch (error) {
        console.error('at reviewActions in deleteReview', error);
        dispatch(deleteMyReviewLoadingErrorAction(error.message));
    } finally {
        dispatch(deleteMyReviewLoadingAction(false));
    }
};

export const loadProductRating =
    (productUid: string, language: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(setProductRatingAction([]));
        dispatch(productRatingLoadingErrorAction(undefined));
        dispatch(productRatingLoadingSuccessAction(false));
        dispatch(productRatingLoadingAction(true));

        try {
            const response = await new PublicControllerApi().productRatingUsingGET(
                productUid,
                getCountryCodeFromState(getState()),
                language,
            );
            dispatch(setProductRatingAction(getFullProductRating(response.body?.[0]?.rating ?? [])));
            dispatch(productRatingLoadingSuccessAction(true));
        } catch (error) {
            console.error('at reviewActions in loadProductRating', error);
            dispatch(productRatingLoadingErrorAction(error.message));
        } finally {
            dispatch(productRatingLoadingAction(false));
        }
    };

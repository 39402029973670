import { mapProductDetailsImage } from './mapProductDetailsImage';

import { Product } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { isProductInWish } from '@/selectors/isProductInWish';

export const productToIProduct = (product: Product, state: IApplicationStore, thumbnails = false): IProduct =>
    Object.assign(product, {
        wish: isProductInWish(product.uid, state),
        images: mapProductDetailsImage(product, thumbnails),
    });

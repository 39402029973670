import { Link } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { IcPlaySmall } from '@/assets';
import { CarouselItemCardProps } from '@/components/Carousel/CarouselItemCard/CarouselItemCard.types';
import { ImgWithBlurredFrame } from '@/components/ImgWithBlurredFrame';
import { VideoPlayer } from '@/components/VideoPlayer';
import { createThumbnailVideoURLLink } from '@/utils';

import './ImagesItem.less';

export const ImagesItem = ({
    videoPlayOnClick,
    item: { videoId, videoType },
    showVideoPlayer,
}: Partial<CarouselItemCardProps>) => {
    const onClick = useCallback(() => videoPlayOnClick?.(videoId, videoType), [videoId, videoPlayOnClick, videoType]);

    const imgSrc = useMemo(() => createThumbnailVideoURLLink(videoId, videoType), [videoId, videoType]);

    return showVideoPlayer ? (
        <div className="carousel-item_images__iframe-container">
            <VideoPlayer videoId={videoId} videoType={videoType} />
        </div>
    ) : (
        <Link className="carousel-item_images" onClick={onClick}>
            <div className="play-icon">
                <IcPlaySmall />
                <div className="circle" />
            </div>
            <ImgWithBlurredFrame className="video-image" src={imgSrc} />
        </Link>
    );
};

import React, { useCallback } from 'react';

/**
 * @param setBoolean - setStateAction<boolean>
 * @returns [open, close, toggle]
 */
export const useBooleanState = (setBoolean: React.Dispatch<React.SetStateAction<boolean>>) => {
    const open = useCallback(() => setBoolean(true), [setBoolean]);

    const close = useCallback(() => setBoolean(false), [setBoolean]);

    const toggle = useCallback(() => setBoolean((prev) => !prev), [setBoolean]);

    return [open, close, toggle];
};

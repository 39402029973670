import { F7Popup } from 'framework7-react';

import { requiredFields } from './RegisterPopup.constants';

import { CustomSelectValue } from '@/components/CustomSelect/CustomSelect.types';

export enum RegSteps {
    AUTH_DATA,
    PERSONAL_INFO,
}

export type RegisterPopupProps = Partial<F7Popup.Props> & {
    onLogIn?: () => void;
    doAfterRegister?: () => void;
};

export type RequiredRegisterFields = (typeof requiredFields)[number];

export type RegisterFormValues = {
    email?: string;
    password?: string;
    passwordRepeat?: string;
    firstName?: string;
    lastName?: string;
    accept?: boolean;
    referralCode?: string;
    country?: CustomSelectValue;
    profilePic?: string;
    profilePicName?: string;
};

export type RegisterFormErrors = Partial<Record<keyof RegisterFormValues, string | undefined>>;

import cn from 'classnames';
import { Button } from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SortReviewsButtonProps } from './SortReviewsButton.types';

import { IcArrow, IcArrowTop } from '@/assets';
import { SortReviewsModal } from '@/components/SortReviewsModalFacade';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ReviewSortingMap } from '@/shared/constants';
import { Sheets } from '@/types/sheets';

import './SortReviewsButton.less';

export const SortReviewsButton: React.FC<SortReviewsButtonProps> = ({ activeSortValue, changeSortValue }) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);

    return (
        <SortReviewsModal
            modalTriggerElement={(toggleModal, expandedSort) => (
                <Button
                    className={cn('sort-reviews-button', {
                        active: expandedSort,
                    })}
                    round
                    onClick={toggleModal}
                    sheetOpen={isMobile && `.${Sheets.SORT_REVIEWS_SHEET}`}
                >
                    {isMobile ? (
                        <>
                            <IcArrowTop />
                            <IcArrowTop />
                        </>
                    ) : (
                        <>
                            {t(ReviewSortingMap[activeSortValue])}
                            <IcArrow />
                        </>
                    )}
                </Button>
            )}
            activeSortValue={activeSortValue}
            changeSortValue={changeSortValue}
        />
    );
};

import { Link } from 'framework7-react';
import React from 'react';

import { messengerService } from '@/api/messengerService';

const HREF_REGEXP = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi;

export const prepareInnerHTML = (text: string | undefined) => {
    text ??= '';
    //! DON'T MOVE THIS REGEXP, IT'S NECESSARY TO INITIALIZE IT HERE BECAUSE OF MULTIPLE EXEC CALLS
    const ANCHOR_REGEX = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/gi;
    let match: RegExpExecArray | null = null;
    const matches: RegExpExecArray[] = [];
    while ((match = ANCHOR_REGEX.exec(text)) !== null) {
        matches.push(match);
    }

    const { elements, textPos } = matches.reduce<{ textPos: number; elements: JSX.Element[] }>(
        (accum, match) => ({
            elements: accum.elements.concat(
                <span
                    key={`${match[0]}-text`}
                    dangerouslySetInnerHTML={{ __html: text.slice(accum.textPos, match.index) }}
                />,
                <Link
                    key={`${match[0]}-link`}
                    onClick={() => messengerService.openExternalLink(HREF_REGEXP.exec(match[0])?.[2], '_blank')}
                    href="#"
                >
                    {match[2]}
                </Link>,
            ),
            textPos: match.index + match[0].length,
        }),
        { textPos: 0, elements: [] },
    );

    return elements.concat(
        <span key={text.slice(textPos)} dangerouslySetInnerHTML={{ __html: text.slice(textPos) }} />,
    );
};

import { Block } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { SaleBadge } from '@/components/Badges/SaleBadge';
import { CarouselItemCardProps } from '@/components/Carousel/CarouselItemCard/CarouselItemCard.types';
import { ImgWithBlurredFrame } from '@/components/ImgWithBlurredFrame';
import { LikeButton } from '@/components/LikeButton';
import { Price } from '@/components/Price';
import { getCategoryByCategoryId, getCategoryTranslateKey, stopPropagation } from '@/utils';

import './BigItem.less';

export const BigItem = ({ item, empty, onClickAddToWish, classificator }: Partial<CarouselItemCardProps>) => {
    const { t } = useTranslation();

    const {
        image,
        name,
        price,
        negotiatedPrice,
        uid,
        category,
        currencyCode,
        priceDiscount,
        description,
        wish,
        rentOptions,
        discount,
        salePrice,
    } = item;
    const handleItemClick = useCallback(() => item?.onClick?.(item), [item]);

    const displayedCategory = useMemo(
        () => getCategoryByCategoryId(category, classificator),
        [classificator, category],
    );

    const handleLike = useCallback(() => {
        onClickAddToWish(uid);
    }, [uid, onClickAddToWish]);

    return (
        <div className="link slider-link" onClick={handleItemClick}>
            <div className="header-image-wrapper">
                {discount && <SaleBadge label={discount} />}
                <ImgWithBlurredFrame src={image} className="header-image" />
                {!empty && (
                    <LikeButton
                        className="add-wish"
                        checked={wish}
                        heartStyle
                        nonCircle
                        onChange={handleLike}
                        onLabelClick={stopPropagation}
                    />
                )}
            </div>
            <Block className="info">
                <p className="title">{name}</p>
                <span className="category">{t(getCategoryTranslateKey(displayedCategory))}</span>
                <span className="description pure-visible-xl">{description}</span>
                {!empty && (
                    <Price
                        price={price}
                        negotiatedPrice={negotiatedPrice}
                        discountedPrice={salePrice ?? priceDiscount}
                        currencyCode={currencyCode}
                        period={rentOptions?.period}
                        className="carousel-item__big__price"
                    />
                )}
            </Block>
        </div>
    );
};

import { f7 } from 'framework7-react';
import React, { useCallback } from 'react';

import { PersonalAreaMenuProps } from './PersonalAreaMenu.types';

import { usePersonalAreaMenuItems } from './usePersonalAreaMenuItems';

import { PageSideMenu } from '@/components/PageSideMenu';
import { PageSideMenuItem } from '@/components/PageSideMenu/PageSideMenu.types';

export const PersonalAreaMenu = ({
    selected,
    selectedSubitem,
    selectedRounded,
    handleItemClick,
}: PersonalAreaMenuProps) => {
    const { menuItemNames, menuItems } = usePersonalAreaMenuItems();

    const onItemClick = useCallback(
        (item: PageSideMenuItem) => {
            handleItemClick?.(item);

            if (item.link) {
                setTimeout(() => {
                    f7.views.main.router.navigate(item.link);
                }, 300);
            }
        },
        [handleItemClick],
    );

    return (
        <PageSideMenu
            className="personal-area-menu"
            menuItems={menuItems}
            menuItemsNames={menuItemNames}
            onItemClick={onItemClick}
            selectedName={selected}
            selectedSubitemName={selectedSubitem}
            selectedRounded={selectedRounded}
        />
    );
};

import cn from 'classnames';
import { Block, BlockTitle, Button, List, ListItem, Navbar, NavRight, NavTitle, Sheet, Toggle } from 'framework7-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { MobileSourceFieldProps } from './MobileSourceField.types';

import { SourceSelectSheetButton } from './SourceSelectSheetButton';

import { IcClose, IcDelete, IcDocumentStroke, IcDone, IcEdit, IcLink, IcMore } from '@/assets';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { useBooleanState } from '@/hooks/useBooleanState';
import { ImportContentType } from '@/pages/ImportProducts/ImportProducts.types';
import { Sheets } from '@/types/sheets';

import './MobileSourceField.less';

export const MobileSourceField: React.FC<MobileSourceFieldProps> = ({
    fileFieldName,
    handleClear,
    disabled,
    acceptParams,
    sourceTypeFieldName,
    onCloseWithoutSave,
    configureImportErrors,
    sourceType,
}) => {
    const {
        input: { value: file },
        meta,
    } = useField<File | undefined>(fileFieldName);

    const {
        input: { value: url },
    } = useField<string | undefined>('url');

    const { t } = useTranslation();

    const [saveValues, setSaveValues] = useState(false);
    const [blockResetValues, unlockResetValues] = useBooleanState(setSaveValues);

    const [urlSheetOpened, setUrlSheetOpened] = useState(false);
    const [openUrlSheet, closeUrlSheet] = useBooleanState(setUrlSheetOpened);

    const [optionsSheetOpened, setOptionsSheetOpened] = useState(false);
    const [openOptionsSheet, closeOptionsSheet] = useBooleanState(setOptionsSheetOpened);

    const [showAuthFields, setShowAuthFields] = useState(false);
    const [, hideAuthFields, toggleAuthFields] = useBooleanState(setShowAuthFields);
    const handleSheetClose = useCallback(() => {
        if (!saveValues) {
            onCloseWithoutSave();
            hideAuthFields();
        }
        closeUrlSheet();
    }, [closeUrlSheet, hideAuthFields, onCloseWithoutSave, saveValues]);

    const isValid = useMemo(
        () =>
            sourceType === ImportContentType.file ||
            !(configureImportErrors?.authPassword || configureImportErrors?.authUsername || configureImportErrors?.url),
        [
            configureImportErrors?.authPassword,
            configureImportErrors?.authUsername,
            configureImportErrors?.url,
            sourceType,
        ],
    );

    const [isEditLinkMode, setIsEditLinkMode] = useState<boolean>();

    const handleApplyUrFields = useCallback(() => {
        blockResetValues();
        closeUrlSheet();
        setIsEditLinkMode(true);
    }, [blockResetValues, closeUrlSheet]);

    const handleDeleteUrlSource = useCallback(() => {
        unlockResetValues();
        onCloseWithoutSave();
        hideAuthFields();
    }, [hideAuthFields, onCloseWithoutSave, unlockResetValues]);

    const handleEditClick = useCallback(() => {
        closeOptionsSheet();
        openUrlSheet();
    }, [closeOptionsSheet, openUrlSheet]);

    const handleDeleteClick = useCallback(() => {
        closeOptionsSheet();
        handleDeleteUrlSource();
    }, [closeOptionsSheet, handleDeleteUrlSource]);

    return (
        <Block className="mobile-source-area-wrapper">
            <div
                className={cn('mobile-source-area', {
                    empty: !file && !url,
                })}
            >
                <div className="mobile-source-area-empty-content">
                    <BlockTitle>{t('Add File*')}</BlockTitle>
                    <SourceSelectSheetButton
                        sourceTypeFieldName={sourceTypeFieldName}
                        acceptParams={acceptParams}
                        fileFieldName={fileFieldName}
                        buttonProps={{ className: 'hidden-input', disabled }}
                        onUrlOptionClick={openUrlSheet}
                    />
                </div>
                {file && sourceType === ImportContentType.file && (
                    <>
                        <IcDocumentStroke />
                        <div className="mobile-source-area-labels">
                            <BlockTitle>{file?.name}</BlockTitle>
                            {file?.size && <p className="block-description">{(file.size / 1024).toFixed(3)} Kb</p>}
                        </div>
                        <Button className="mobile-source-area-clear" onClick={handleClear}>
                            <IcDelete />
                        </Button>
                    </>
                )}

                {url && sourceType === ImportContentType.url && (
                    <>
                        <IcLink />
                        <BlockTitle className="mobile-source-area-labels-url">{url}</BlockTitle>
                        <Button className="mobile-source-area-clear" onClick={openOptionsSheet}>
                            <IcMore />
                        </Button>
                    </>
                )}
            </div>
            {meta.error && meta.pristine && meta.touched && <span className="error-message">{meta.error}</span>}
            <Sheet
                opened={optionsSheetOpened}
                onSheetClose={closeOptionsSheet}
                className={Sheets.IMPORT_SOURCE_OPTIONS_SHEET}
            >
                <List mediaList>
                    <ListItem title={t('Edit').toString()} onClick={handleEditClick}>
                        <IcEdit slot="media" />
                    </ListItem>
                    <ListItem onClick={handleDeleteClick} title={t('Delete').toString()}>
                        <IcDelete slot="media" />
                    </ListItem>
                </List>
            </Sheet>
            <Sheet
                className={Sheets.SOURCE_URL_PARAMS_SHEET}
                onSheetClose={handleSheetClose}
                opened={urlSheetOpened}
                backdrop
            >
                <Navbar noHairline noShadow>
                    <NavTitle>{isEditLinkMode ? t('Edit') : t('Add a Link')}</NavTitle>
                    <NavRight>
                        <IcClose onClick={handleSheetClose} />
                    </NavRight>
                </Navbar>
                <List>
                    <CustomInputField requiredSign name="url" label={t('File URL')} />
                    <ListItem title={t('Authorization to access the link').toString()}>
                        <Toggle checked={showAuthFields} onChange={toggleAuthFields} />
                    </ListItem>
                    {showAuthFields && (
                        <>
                            <CustomInputField name="authUsername" label={t('Username')} />
                            <CustomInputField name="authPassword" label={t('Password')} type="password" />
                        </>
                    )}
                </List>
                <Button onClick={handleApplyUrFields} disabled={!isValid} className="button-apply" round fill>
                    <IcDone />
                </Button>
            </Sheet>
        </Block>
    );
};

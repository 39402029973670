import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductStatusNotificationProps } from './ProductStatusNotification.types';

import { ProductStatusNotificationCore } from './ProductStatusNotificationCore';

import { Product } from '@/generated/marketplaceapi';
import { formatDate } from '@/utils';

export const ProductStatusNotification = React.memo<ProductStatusNotificationProps>(
    ({ product }: ProductStatusNotificationProps) => {
        const { t } = useTranslation();

        const notifProps = useMemo(() => {
            switch (product.status) {
                case Product.StatusEnum.DCL:
                    return {
                        title: t('Your Good has been declined'),
                        text: t('Your pictures are poor quality. You must replace them and send again'),
                        danger: true,
                    };
                case Product.StatusEnum.APR:
                    return {
                        title: t('Your Good has been approved'),
                        text: t('Your product will be published on', {
                            date: formatDate(product.publishDate?.toString?.()),
                        }),
                        success: true,
                    };
                case Product.StatusEnum.EXP:
                    return {
                        title: t('Product has expired'),
                        text: t('The product was expired on date', {
                            date: formatDate(product.expirationDate?.toString?.()),
                        }),
                        danger: true,
                    };
                case Product.StatusEnum.OOS:
                    return {
                        title: t('The product is out of stock'),
                        text: t('You can replenish the quantity of goods'),
                        danger: true,
                    };
                default:
                    return null;
            }
        }, [product.expirationDate, product.publishDate, product.status, t]);

        return notifProps ? <ProductStatusNotificationCore {...notifProps} /> : null;
    },
);
ProductStatusNotification.displayName = 'ProductStatusNotification';

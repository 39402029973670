import {
    DELIVERY_METHOD_SET_LAST_CREATED_UID,
    DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS,
    DELIVERY_METHODS_ALL_SUCCESS,
    DELIVERY_METHODS_ERROR,
    DELIVERY_METHODS_LOADING,
    DeliveryMethodsActions,
    PLATFORM_DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS,
    PLATFORM_DELIVERY_METHODS_ALL_SUCCESS,
} from '@/actions/deliveryMethodsActions';
import { DeliveryMethod, PlatformDeliveryMethod } from '@/generated/marketplaceapi';

export interface IDeliveryMethodsState {
    loading?: boolean;
    error?: unknown;
    item?: DeliveryMethod;
    allDeliveryMethods?: DeliveryMethod[];
    allPlatformDeliveryMethods?: PlatformDeliveryMethod[];
    prevLastCreatedMethodUid: string | undefined;
    lastCreatedMethodUid: string | undefined;
}

const initialState: IDeliveryMethodsState = {
    error: null,
    allDeliveryMethods: [],
    allPlatformDeliveryMethods: [],
    prevLastCreatedMethodUid: undefined,
    lastCreatedMethodUid: undefined,
};

const deliveryMethodsReducer = (state = initialState, action: DeliveryMethodsActions): IDeliveryMethodsState => {
    switch (action.type) {
        case DELIVERY_METHODS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
                item: undefined,
            };
        case DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS: {
            const current = state.allDeliveryMethods.find((method) => method.uid === action.item.uid);

            return {
                ...state,
                loading: false,
                error: undefined,
                item: action.item,
                allDeliveryMethods: current
                    ? state.allDeliveryMethods.map((method) => (method.uid === current.uid ? action.item : method))
                    : [...state.allDeliveryMethods, action.item],
            };
        }
        case PLATFORM_DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS: {
            const current = state.allPlatformDeliveryMethods.find(
                (method) => method.deliveryProvider.type === action.item.deliveryProvider.type,
            );

            return {
                ...state,
                loading: false,
                error: undefined,
                allPlatformDeliveryMethods: current
                    ? state.allPlatformDeliveryMethods.map((method) =>
                          method.deliveryProvider.type === action.item.deliveryProvider.type ? action.item : method,
                      )
                    : [...state.allPlatformDeliveryMethods, action.item],
            };
        }
        case DELIVERY_METHODS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DELIVERY_METHODS_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                allDeliveryMethods: action.items,
            };
        case PLATFORM_DELIVERY_METHODS_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                allPlatformDeliveryMethods: action.items,
            };
        case DELIVERY_METHOD_SET_LAST_CREATED_UID:
            return {
                ...state,
                prevLastCreatedMethodUid: state.lastCreatedMethodUid,
                lastCreatedMethodUid: action.uid,
            };
        default:
            return state;
    }
};

export default deliveryMethodsReducer;

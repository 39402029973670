import { AppDispatch } from '@/hooks/store';
import { getPlatform } from '@/utils';
import { Platform } from '@/utils/getPlatform';

export const SHARE = 'SHARE';
export const SHARE_SUCCESS = 'SHARE_SUCCESS';
export const SHARE_ERROR = 'SHARE_ERROR';

function shareLinkAndroid(message: string) {
    Android.shareLink(message);
}

function shareLinkIos(message: string) {
    window.webkit.messageHandlers.shareLink.postMessage(message);
}

function shareLinkWeb(title: string, url: string) {
    if (window.parent) {
        window.parent.postMessage(
            {
                type: 'marketplace',
                title: title,
                url: url,
            },
            '*',
        );
    }
}

export const share = (title: string, url: string) => (dispatch: AppDispatch) => {
    dispatch({ type: SHARE });
    try {
        const message = title + '\n\n' + url;
        const platform = getPlatform();
        if (platform === Platform.Android) {
            shareLinkAndroid(message);
        } else if (platform === Platform.iOS) {
            shareLinkIos(message);
        } else {
            shareLinkWeb(title, url);
        }
        dispatch({ type: SHARE_SUCCESS });
    } catch (err) {
        dispatch({ type: SHARE_ERROR, error: err.toString() });
    }
};

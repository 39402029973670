import { LatLng } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';

export const toLatLgnLiteral = (coordinates: string): LatLng => {
    try {
        const [latStr, lngStr] = coordinates?.split(',');
        return { lat: parseFloat(latStr), lng: parseFloat(lngStr) };
    } catch (err) {
        console.error('in toLatLgnLiteral', err);
    }
    return { lat: 0, lng: 0 };
};

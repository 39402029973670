export enum URLParams {
    LANGUAGE = 'language',
    AUTH_CODE = 'authCode',
    DARK_MODE = 'darkMode',
    CUSTOMER_LOCATION = 'customer-location',
}

export const PRODUCT_TYPE_QUERY_NAME = 'type';

export enum EProductTypeQuery {
    MARKET = 'market',
    OFFERS = 'offers',
    SERVICES = 'services',
}

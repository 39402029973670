import cn from 'classnames';
import React from 'react';

import { ProductStatusNotificationCoreProps } from './ProductStatusNotificationCore.types';

import './ProductStatusNotificationCore.less';

export const ProductStatusNotificationCore = React.memo<ProductStatusNotificationCoreProps>(
    ({ danger, success, title, text }) => (
        <div className={cn('product-status-notification-core', { danger, success })}>
            <div className="product-status-notification-core__header">{title}</div>
            <div className="product-status-notification-core__content">{text}</div>
        </div>
    ),
);
ProductStatusNotificationCore.displayName = 'ProductStatusNotificationCore';

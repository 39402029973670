import { Preloader } from 'framework7-react';
import React, { Suspense } from 'react';

import { MascotIconProps, MascotType } from './MascotIcon.types';

const getMascot = (type: MascotType) => {
    const Svg = React.lazy(() => import(`@/assets/mascots/${type}`));
    return <Svg />;
};

export const MascotIcon: React.FC<MascotIconProps> = ({ type }) => {
    return <Suspense fallback={<Preloader />}>{getMascot(type)}</Suspense>;
};

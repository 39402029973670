import {
    MY_WALLETS_LIST_ERROR,
    MY_WALLETS_LIST_LOADING,
    MY_WALLETS_LIST_SUCCESS,
    MY_WALLETS_RESET_ERRORS,
    MyWalletsActions,
} from '@/actions/myWalletsActions';
import { Wallet, WalletTotalBalance } from '@/generated/commonapi';

export interface IMyWalletsState {
    loading?: boolean;
    error?: unknown;
    wallets: Wallet[];
    totalBalance?: WalletTotalBalance;
}

const initialState: IMyWalletsState = {
    wallets: [],
};

const myWalletsReducer = (state = initialState, action: MyWalletsActions): IMyWalletsState => {
    switch (action.type) {
        case MY_WALLETS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case MY_WALLETS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                wallets: action.wallets,
                totalBalance: action.totalBalance,
            };
        case MY_WALLETS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case MY_WALLETS_RESET_ERRORS:
            return {
                ...state,
                error: undefined,
            };
        default:
            return state;
    }
};

export default myWalletsReducer;

import { Col, List } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { DESKTOP_MENU_ITEMS } from './DesktopStepsMenu.constants';

import { DesktopStepsMenuProps } from './DesktopStepsMenu.types';

import { SideMenu } from '@/components/SideMenu';
import { SideMenuItem } from '@/components/SideMenu/SideMenuItem/SideMenuItem.types';
import { ConfigureSaleSteps } from '@/pages/ConfigureSale/ConfigureSale.types';
import { valueInRange } from '@/utils';

import './DesktopStepsMenu.less';

export const DesktopStepsMenu = React.memo(
    ({
        currentStep,
        onStepClick: onNextStep,
        validSteps,
        showStepHighlight,
        isEdit,
    }: DesktopStepsMenuProps): JSX.Element => {
        const stepMenuItems = useMemo<SideMenuItem[]>(
            () =>
                DESKTOP_MENU_ITEMS.map((item) => ({
                    ...item,
                    active: item.step === currentStep && showStepHighlight,
                    disabled: isEdit
                        ? false
                        : !validSteps.get(item.step) &&
                          !validSteps.get(
                              valueInRange(
                                  ConfigureSaleSteps.GENERAL_INFO,
                                  ConfigureSaleSteps.PRODUCTS_SELECT,
                                  item.step - 1,
                              ),
                          ),
                    valid: validSteps.get(item.step),
                    showValidHighlight: !isEdit,
                })),
            [currentStep, isEdit, showStepHighlight, validSteps],
        );

        const onMenuItemClick = useCallback(
            (step: React.ReactText) => onNextStep(step as ConfigureSaleSteps),
            [onNextStep],
        );

        return (
            <Col className="configure-sale-steps-desktop-menu">
                <List className="configure-sale-steps-desktop-menu-steps">
                    <SideMenu items={stepMenuItems} onItemClick={onMenuItemClick} />
                </List>
            </Col>
        );
    },
);
DesktopStepsMenu.displayName = 'DesktopStepsMenu';

import cn from 'classnames';
import { Badge } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderStatusBadgeProps } from './OrderStatusBadge.types';
import { getOrderStatusText } from './OrderStatusBadge.utils';

import { useAppSelector } from '@/hooks/store';

import './OrderStatusBadge.less';

export const OrderStatusBadge = ({ status, statusDescription }: OrderStatusBadgeProps) => {
    const { t } = useTranslation();
    const { Order_State } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);

    const statusFromClassificator = useMemo(
        () => Order_State?.find((item) => item.code === status)?.value,
        [Order_State, status],
    );

    return status ? (
        <Badge className={cn('order-status-badge', `order-status-badge-${status.toString()}`)}>
            {statusFromClassificator ?? t(getOrderStatusText(status, statusDescription))}
        </Badge>
    ) : null;
};

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { Transaction, TransactionWsControllerApi } from '@/generated/commonapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING' as const;
export const TRANSACTIONS_LOADING_SUCCESS = 'TRANSACTIONS_LOADING_SUCCESS' as const;
export const TRANSACTIONS_LOADING_ERROR = 'TRANSACTIONS_LOADING_ERROR' as const;

const transactionsLoadingAction = () => ({
    type: TRANSACTIONS_LOADING,
});

const transactionsLoadingSuccessAction = (transactions: Transaction[]) => ({
    type: TRANSACTIONS_LOADING_SUCCESS,
    transactions,
});

const transactionsLoadingErrorAction = (error: unknown) => ({
    type: TRANSACTIONS_LOADING_ERROR,
    error,
});

export type TransactionActions = ReturnType<
    typeof transactionsLoadingAction | typeof transactionsLoadingErrorAction | typeof transactionsLoadingSuccessAction
>;

export const loadTransactions = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(transactionsLoadingAction());
    const state = getState();
    try {
        const result = await new TransactionWsControllerApi().getTransactionListUsingPOST1({
            language: getLanguageCode(state),
            state: ['P', 'S'],
        });

        handleResponseAndThrowAnErrorIfExists(result);

        const transactions = result.transactionList || [];

        dispatch(transactionsLoadingSuccessAction(transactions));
    } catch (error) {
        console.error('at transactionActions in loadTransactions', error);

        dispatch(transactionsLoadingErrorAction(error.message));
    }
};

import { connect } from 'react-redux';

import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { getCategoriesClassificator } from '@/selectors/getCategoriesClassificator';

export type ConnectCategoriesClassificatorProps = {
    categoriesClassificator: ICategoryClassificator[];
};

export default connect<ConnectCategoriesClassificatorProps>(getCategoriesClassificator);

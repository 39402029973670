import { RESEND_CODE_TIMEOUT_MINUTES } from './ForgotPasswordPopup.constants';

import {
    CodeErrors,
    CodeValues,
    InitErrors,
    InitValues,
    PasswordErrors,
    PasswordValues,
} from './ForgotPasswordPopUp.types';

import i18n from '@/i18n';
import {
    INVALID_CODE_ERROR,
    INVALID_PASSWORD_CUT_ERROR,
    NOT_EMAIL_ERROR,
    PASSWORD_MATCH_ERROR,
    REQUIRED_FIELD_ERROR,
} from '@/shared/constants';
import { isEmail, isValidPassword } from '@/utils';

export const validateInitStep = (values: InitValues): InitErrors => ({
    email: !values.email ? REQUIRED_FIELD_ERROR : !isEmail(values.email) ? NOT_EMAIL_ERROR : undefined,
});

export const validateCodeStep = (values: CodeValues): CodeErrors => ({
    code: !values.code ? REQUIRED_FIELD_ERROR : values.code.length < 6 ? INVALID_CODE_ERROR : undefined,
});

export const validatePasswordStep = (values: PasswordValues): PasswordErrors => ({
    password: !values.password
        ? REQUIRED_FIELD_ERROR
        : !isValidPassword(values.password)
        ? INVALID_PASSWORD_CUT_ERROR
        : !!values.passwordRepeat && values.passwordRepeat !== values.password
        ? PASSWORD_MATCH_ERROR
        : undefined,
    passwordRepeat: !values.passwordRepeat
        ? REQUIRED_FIELD_ERROR
        : !!values.password && values.password !== values.passwordRepeat
        ? PASSWORD_MATCH_ERROR
        : undefined,
});

export const makeResendCodeMessage = (email: string) =>
    i18n.t('We will send new code to your email {{email}}. Next shipment will be available in {{minutes}} minutes.', {
        email,
        minutes: RESEND_CODE_TIMEOUT_MINUTES,
    });

import cn from 'classnames';
import { Button, f7, Link, Navbar, NavRight, NavTitle, Popup } from 'framework7-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { InviteFriendsPopupProps } from './InviteFriendsPopup.types';

import { IcClose, IcDone, IcFacebookFilled, IcLink, IcTwitter } from '@/assets';
import { CustomButton } from '@/components/CustomButton';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { ReferralProgramWsControllerApi } from '@/generated/commonapi';
import { useAppSelector } from '@/hooks/store';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';

import './InviteFriendsPopup.less';

const ReferralProgramWSController = new ReferralProgramWsControllerApi();

export const InviteFriendsPopup = ({ className, opened, ...props }: InviteFriendsPopupProps) => {
    const { t } = useTranslation();
    const [referralCode, setReferralCode] = useState('');

    const isLogged = useAppSelector(isLoggedIn);

    const referralUrl = useMemo(() => {
        const url = window.location.toString();
        const delitemeterIndex = url.indexOf('#');
        return `${
            delitemeterIndex > 0 ? url.slice(0, delitemeterIndex + 1) : url?.slice(0, -1)
        }/register?referralCode=${referralCode}`;
    }, [referralCode]);

    const { isCopied: isCodeCopied, copyToClipboard: copyCode } = useCopyToClipboard(referralCode);

    const { isCopied: isUrlCopied, copyToClipboard: copyUrl } = useCopyToClipboard(`${t(
        'Hello, here is your personal invitation link to become a member of the {{appName}}. Simply click to register!',
        { appName: process.env.APP_NAME },
    )}
${referralUrl}`);

    useEffect(() => {
        if (isLogged && opened) {
            ReferralProgramWSController.getReferralProgramCodeUsingPOST({
                referralProgramName: 'friend',
            })
                .then((resp) => {
                    handleResponseAndThrowAnErrorIfExists(resp);
                    setReferralCode(resp.referralProgramCode);
                })
                .catch((err) => {
                    console.error('in useEffect at InviteFriendsPopup', err);
                    f7.dialog.alert(err.message);
                });
        }
    }, [isLogged, opened]);
    return (
        <Popup
            {...props}
            opened={opened}
            className={cn(Popups.INVITE_FRIENDS_POPUP, 'invite-friends-popup', className)}
        >
            <Navbar>
                <NavTitle>{t('Invite Friends')}</NavTitle>
                <NavRight>
                    <Link popupClose>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <div className="invite-friends-popup__content">
                <span className="invite-friends-popup__content-description">{t('InviteFriendBonusText')}</span>
                <p className="invite-friends-popup__content-code">{referralCode}</p>
                <CustomButton fullWidth size="large" onClick={copyCode}>
                    {isCodeCopied ? t('Copied') : t('Copy Code')}
                </CustomButton>
            </div>
            <div className="invite-friends-popup__footer">
                <p>{t('Or share invitation')}</p>
                <div className="invite-friends-popup__footer-buttons">
                    <Button onClick={copyUrl}>{isUrlCopied ? <IcDone /> : <IcLink />}</Button>
                    <TwitterShareButton
                        url={`${t(
                            'Hello, here is your personal invitation link to become a member of the {{appName}}. Simply click to register!',
                            { appName: process.env.APP_NAME },
                        )}
${referralUrl}`}
                        title={t('Invite to {{appName}}', { appName: process.env.APP_NAME })}
                    >
                        <IcTwitter />
                    </TwitterShareButton>
                    <FacebookShareButton
                        url={referralUrl}
                        title={t('Invite to {{appName}}', { appName: process.env.APP_NAME })}
                    >
                        <IcFacebookFilled />
                    </FacebookShareButton>
                </div>
            </div>
        </Popup>
    );
};

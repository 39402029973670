import { RouteParameters } from '@/routes';

export const payoutSettingsRoutes: RouteParameters[] = [
    {
        path: '/payout-settings/',
        async: (_to, _from, resolve) =>
            import('./PayoutSettingsPage').then((rc) => resolve({ component: rc.PayoutSettingsPage })),
        routes: [
            {
                path: '/edit/bank-account/',
                async: (_to, _from, resolve) =>
                    import('./EditBankAccountPayoutSettingsPage').then((rc) =>
                        resolve({ component: rc.EditBankAccountPayoutSettingsPage }),
                    ),
            },
            {
                path: '/edit/bank-card/',
                async: (_to, _from, resolve) =>
                    import('./EditBankCardPayoutSettingsPage').then((rc) =>
                        resolve({ component: rc.EditBankCardPayoutSettingsPage }),
                    ),
            },
        ],
    },
];

import cn from 'classnames';
import { ListItem } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PageSideMenuItemProps } from './PageSideMenuItem.types';

import { IcArrow } from '@/assets';
import { OnbordingMessage } from '@/components/OnbordingMessage';

import './PageSideMenuItem.less';

export const PageSideMenuItem = ({
    name,
    onClick,
    active,
    menuItem,
    selectedRounded,
    children,
    onOnbordingMessageClick,
    onOnbordingMessageClose,
}: PageSideMenuItemProps) => {
    const { t } = useTranslation();

    const closeOnbordingMessage = useCallback(
        () => onOnbordingMessageClose?.(menuItem),
        [menuItem, onOnbordingMessageClose],
    );

    const handleClickOnOnbordingMessage = useCallback(
        () => onOnbordingMessageClick?.(menuItem),
        [menuItem, onOnbordingMessageClick],
    );

    const handleClick = useCallback(() => onClick(menuItem), [menuItem, onClick]);

    return (
        <ListItem
            className={cn('page-side-menu-item', name, { active, rounded: selectedRounded })}
            accordionItemOpened={!!menuItem?.items && active}
            accordionItem={!!menuItem?.items}
            title={t(menuItem.title)}
            panelClose={!menuItem?.items}
            onClick={handleClick}
        >
            {menuItem.icon ? (
                <menuItem.icon className="page-side-menu-item__icon" slot="media" />
            ) : (
                <div slot="media" />
            )}
            {!!menuItem?.items && <IcArrow className="page-side-menu-item__arrow-icon" slot="after" />}
            {children}
            {!active && menuItem.onbordingMessage ? (
                <OnbordingMessage
                    className="page-side-menu-item__onbording-message"
                    triangle="left"
                    type={menuItem.onbordingMessage.type}
                    onClick={handleClickOnOnbordingMessage}
                    onClose={closeOnbordingMessage}
                />
            ) : (
                <></>
            )}
        </ListItem>
    );
};

import { IMSPopupProps } from '@/shared/UIKit/Popup/Popup';

export type VerifyEmailPopupProps = IMSPopupProps & {
    onLoginClick?: () => void;
};

export enum VerifyEmailSteps {
    RESEND_FORM,
    SENDED_MESSAGE,
}

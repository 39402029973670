import { useDay } from '@datepicker-react/hooks';
import cn from 'classnames';
import { f7 } from 'framework7-react';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import { DayProps } from './Day.types';
import { isDateToday } from './utils';

import DateRangePickerContext from '@/components/DatePickers/DateRangePicker/DateRangePicker.context';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import './Day.less';

export const Day = ({ date, dayLabel, closeModalOnDayClick }: DayProps): JSX.Element => {
    const dayRef = useRef(null);

    const {
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isStartDate,
        isEndDate,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
    } = useContext(DateRangePickerContext);

    const {
        isSelected,
        isWithinHoverRange,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        onMouseEnter: handleMouseEnter,
        tabIndex,
    } = useDay({
        date,
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateFocus,
        onDateSelect,
        onDateHover,
        dayRef,
    });

    const isSelectedStart = useMemo(() => isStartDate(date), [date, isStartDate]);
    const isSelectedEnd = useMemo(() => isEndDate(date), [date, isEndDate]);
    const isToday = useMemo(() => isDateToday(date), [date]);
    const isDisabled = useMemo(() => isDateBlocked(date), [date, isDateBlocked]);

    const handleDayClick = useCallback(() => {
        if (closeModalOnDayClick) {
            f7.sheet.close(`.${Sheets.DATEPICKER_SHEET}`);
            f7.popup.close(`.${Popups.DATEPICKER_POPUP}`);
        }
        handleClick();
    }, [closeModalOnDayClick, handleClick]);

    return (
        <button
            ref={dayRef}
            className={cn(
                'datepicker-calendar-month-day',
                { 'day-start': isSelectedStart },
                { 'day-end': isSelectedEnd },
                { today: isToday },
                { today__border: isToday && !isSelectedStart && !isSelectedEnd },
                { 'day-selected': isSelected || isWithinHoverRange },
                {
                    unavailable: isDisabled,
                },
            )}
            tabIndex={tabIndex}
            onClick={handleDayClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={handleMouseEnter}
        >
            <div className="datepicker-calendar-month-day-content">
                <span>{dayLabel}</span>
            </div>
        </button>
    );
};

import cn from 'classnames';
import {
    Button,
    Checkbox,
    f7,
    Fab,
    Icon,
    Link,
    Navbar,
    NavLeft,
    NavRight,
    Page,
    Popup,
    Toggle,
} from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { emptyContent } from './AddReviewPopup.constants';

import { makeContentRequired } from './AddReviewPopup.utils';

import { IcClose } from '@/assets';
import { AddImage } from '@/components/AddImage';
import { CustomInput } from '@/components/CustomInput';
import { OkReviewPopup } from '@/components/OkReviewPopup';
import { StarRating } from '@/components/StarRating';
import { ProductReviewImage } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { useControlledInput } from '@/hooks/useControlledInput';
import { useErrorAlert } from '@/hooks/useErrorAlert';
import { usePreloader } from '@/hooks/usePreloader';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';
import { noop } from '@/utils';

import type { AddReviewPopupProps } from './AddReviewPopup.types';

import './AddReviewPopup.less';

export const AddReviewPopup: React.FC<AddReviewPopupProps> = ({
    onSubmit: handleSubmit = noop,
    loading = false,
    success = false,
    error,
    review,
    isEdit = false,
    className,
    ...props
}) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const initContent = useMemo(() => makeContentRequired(review), [review]);
    const [{ text, rating, images, anonymously }, setContent] = useState(initContent);
    const imageFiles = useRef<(File | undefined)[]>([]);
    const [inputFocusState, focusInput, blurInput] = useBoolState(false);
    const [okPopupState, openOkPopup, closeOkPopup] = useBoolState(false);
    const [submitted, pressSubmit, unpressSubmit] = useBoolState(false);
    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        setContent(initContent);
        imageFiles.current = Array(initContent.images.length).fill(undefined);
    }, [initContent]);

    const handleChangetext = useControlledInput((newText: string) =>
        setContent((prev) => ({ ...prev, text: newText })),
    );

    const handleChangeRating = useCallback(
        (newRating: number) => setContent((prev) => ({ ...prev, rating: newRating })),
        [],
    );

    const toggleAnonymous = useCallback(() => setContent((prev) => ({ ...prev, anonymously: !prev.anonymously })), []);

    const handleChangeImages = useCallback(
        (newImages: ProductReviewImage[]) => setContent((prev) => ({ ...prev, images: newImages })),
        [],
    );

    const isSubmitButtonDisabled =
        (isEdit
            ? (text === initContent.text || text === '') &&
              (rating === initContent.rating || rating === 0) &&
              anonymously === initContent.anonymously &&
              images.length === initContent.images.length &&
              images.filter((image, idx) => image !== initContent.images[idx]).length === 0
            : !(text && rating)) ||
        loading ||
        !loggedIn;

    const handleDeleteImage = useCallback(
        (i: number) => {
            handleChangeImages(images.filter((_, idx) => i !== idx));
            imageFiles.current = imageFiles.current.filter((_, idx) => i !== idx);
        },
        [images, handleChangeImages],
    );

    const handleAddImage = useCallback(
        (file: File) => {
            handleChangeImages([
                ...images,
                { imageThumbnailUrl: URL.createObjectURL(file), reviewUid: review?.uid || '' },
            ]);
            imageFiles.current.push(file);
        },
        [images, handleChangeImages, review],
    );

    const onSubmit = () => {
        pressSubmit();
        handleSubmit({ text, rating, anonymously }, imageFiles.current.filter(Boolean), images);
    };

    usePreloader(loading);

    useErrorAlert(error);

    const clearState = useCallback(() => {
        setContent(emptyContent);
        imageFiles.current = [];
    }, [setContent]);

    useEffect(() => {
        if (success && submitted) {
            openOkPopup();
            clearState();
            f7.popup.close(isEdit ? `.${Popups.EDIT_REVIEW_POPUP}` : `.${Popups.ADD_REVIEW_POPUP}`);
            unpressSubmit();
        }
    }, [success, clearState, openOkPopup, isEdit, submitted, unpressSubmit]);

    useEffect(() => {
        if (error && submitted) {
            unpressSubmit();
        }
    }, [error, submitted, unpressSubmit]);

    return (
        <Popup
            id={isEdit ? Popups.EDIT_REVIEW_POPUP : Popups.ADD_REVIEW_POPUP}
            className={cn(isEdit ? Popups.EDIT_REVIEW_POPUP : Popups.ADD_REVIEW_POPUP, 'add-review-popup', className)}
            tabletFullscreen
            {...props}
        >
            <Page>
                <Navbar noShadow>
                    <NavLeft>
                        <Link popupClose iconOnly>
                            <IcClose />
                        </Link>
                    </NavLeft>
                    <NavRight>
                        <Button
                            fill
                            round
                            raised
                            onClick={onSubmit}
                            className="add-review-popup__submit-button"
                            disabled={isSubmitButtonDisabled}
                        >
                            {t('Send')}
                        </Button>
                    </NavRight>
                </Navbar>
                <div className="add-review-popup__content">
                    <h1 className="add-review-popup__title">{t(`${isEdit ? 'Edit' : 'Write'} Review`)}</h1>
                    <StarRating
                        value={rating}
                        onChange={handleChangeRating}
                        className="add-review-popup__star-rating"
                    />
                    <div className="add-review-popup__input-wrap">
                        <CustomInput
                            value={text}
                            onChange={handleChangetext}
                            type="textarea"
                            label={t('Your Comment').toString()}
                            floatingLabel={!isMobile}
                            className="add-review-popup__input"
                            onFocus={focusInput}
                            onBlur={blurInput}
                        />
                        <div
                            className={cn('add-review-popup__input-wrap-underline', {
                                focused: inputFocusState,
                            })}
                        />
                    </div>
                    <AddImage
                        className="add-review-popup__add-image"
                        images={images.map(({ imageThumbnailUrl }) => imageThumbnailUrl)}
                        onDelete={handleDeleteImage}
                        onChange={handleAddImage}
                        max={6}
                    />
                    <label className="add-review-popup__anonymous">
                        <Toggle
                            className="add-review-popup__anonymous-toggle"
                            checked={anonymously}
                            onChange={toggleAnonymous}
                        />
                        <Checkbox
                            className="add-review-popup__anonymous-check"
                            checked={anonymously}
                            onChange={toggleAnonymous}
                        />
                        <div className="add-review-popup__anonymous-wrap">
                            <h3 className="add-review-popup__anonymous-title">{t('Publish Anonymously')}</h3>
                            <span className="add-review-popup__anonymous-content">
                                {t('Your personal data (avatar, name and surname) will be hidden')}
                            </span>
                        </div>
                    </label>
                </div>
                <Fab
                    position="right-bottom"
                    className={cn('add-review-popup__fab', {
                        disabled: isSubmitButtonDisabled,
                    })}
                    onClick={onSubmit}
                >
                    <Icon ios="f7:checkmark_alt" md="material:send" />
                </Fab>
            </Page>
            <OkReviewPopup opened={okPopupState} onPopupClosed={closeOkPopup} isEdit={isEdit} />
        </Popup>
    );
};

import { ReactifiedModule } from '@yandex/ymaps3-types/reactify';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

export type YMapContextValue = {
    reactify?: ReactifiedModule<
        typeof import('/home/roman/Documents/Projects/qrent/node_modules/@yandex/ymaps3-types/index')
    >;
    loaded: boolean;
};

export const YMapContext = React.createContext<YMapContextValue>({
    loaded: false,
    reactify: undefined,
});

export const YMapProvider: React.FC = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [reactify, setReactify] =
        useState<
            ReactifiedModule<
                typeof import('/home/roman/Documents/Projects/qrent/node_modules/@yandex/ymaps3-types/index')
            >
        >();

    const updateReactify = useCallback(async () => {
        if (reactify) {
            return;
        }

        const [ymaps3React] = await Promise.all([ymaps3.import('@yandex/ymaps3-reactify'), ymaps3.ready]);
        const ymapsReactify = ymaps3React.reactify.bindTo(React, ReactDOM);

        const reactifyModule = ymapsReactify.module(ymaps3);

        setLoaded(true);
        setReactify(reactifyModule);
    }, [reactify]);

    useEffect(() => {
        void updateReactify();
    }, [updateReactify]);

    const value = useMemo(
        () => ({
            loaded,
            reactify,
        }),
        [loaded, reactify],
    );

    return <YMapContext.Provider value={value} {...props} />;
};

export const useYMap = () => useContext(YMapContext);

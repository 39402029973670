import cn from 'classnames';
import { Link } from 'framework7-react';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { default as LogoTextPath } from './logo-text.svg';
import { LogoProps } from './Logo.types';

import { getLogoDescription, getLogoName } from './Logo.utils';

import { messengerService } from '@/api/messengerService';
import { IcAds, IcClose, IcMarketspace, IcJob, IcLogoPath } from '@/assets';
import { IcSelectDown, IcSelectUp } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';
import { ProductType } from '@/types/productType';

import './Logo.less';

export const Logo = ({ onChangeProductType, onClickLogo, full = true }: LogoProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);

    const { activeProductType } = useAppSelector((state) => state.rootReducer);

    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);

    const onSelectHandle = (productType: ProductType) => {
        setIsSheetOpen(false);
        onChangeProductType?.(productType);
    };

    if (isMobile) {
        return (
            <>
                <div
                    className="logo-container"
                    onClick={() => {
                        setIsSheetOpen(true);
                    }}
                >
                    <img src={IcLogoPath} alt="Logo" className="logo" />
                    <span className="logo-text">{`${t(getLogoName(activeProductType))}`}</span>
                    {isSheetOpen ? <IcSelectUp /> : <IcSelectDown />}
                </div>

                <MSSheet
                    opened={isSheetOpen}
                    onBackdropClick={() => {
                        setIsSheetOpen(false);
                    }}
                >
                    <div className="logo-sheet">
                        <div className="logo-sheet__header">
                            <span className="logo-sheet__title">{`${t(
                                'main_page.mobile.space_selector.title.explore',
                            )} ${process.env.APP_NAME}`}</span>

                            <Link
                                className="logo-sheet__close-button"
                                iconOnly
                                onClick={() => {
                                    setIsSheetOpen(false);
                                }}
                            >
                                <IcClose />
                            </Link>
                        </div>
                        <div className="logo-sheet__content">
                            {!messengerService.enabled && (
                                <div
                                    className={cn('logo-sheet__item', 'products')}
                                    onClick={() => onSelectHandle(ProductType.Product)}
                                >
                                    <IcMarketspace className="logo-sheet__logo" />
                                    <div className="logo-sheet__item-label">
                                        <span className="logo-sheet__item-label-title">
                                            {t(getLogoName(ProductType.Product))}
                                        </span>
                                        <span className="logo-sheet__item-label-description">
                                            {t(getLogoDescription(ProductType.Product))}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div
                                className={cn('logo-sheet__item', 'advertisements')}
                                onClick={() => onSelectHandle(ProductType.Advertisement)}
                            >
                                <IcAds className="logo-sheet__logo" />
                                <div className="logo-sheet__item-label">
                                    <span className="logo-sheet__item-label-title">
                                        {t(getLogoName(ProductType.Advertisement))}
                                    </span>
                                    <span className="logo-sheet__item-label-description">
                                        {t(getLogoDescription(ProductType.Advertisement))}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={cn('logo-sheet__item', 'services')}
                                onClick={() => onSelectHandle(ProductType.Service)}
                            >
                                <IcJob className="logo-sheet__logo" />
                                <div className="logo-sheet__item-label">
                                    <span className="logo-sheet__item-label-title">
                                        {t(getLogoName(ProductType.Service))}
                                    </span>
                                    <span className="logo-sheet__item-label-description">
                                        {t(getLogoDescription(ProductType.Service))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </MSSheet>
            </>
        );
    }

    return (
        <div className="logo-container" onClick={onClickLogo}>
            <img src={IcLogoPath} alt="Logo" className="logo" />
            {full && <img src={LogoTextPath} alt="Logo" className="logo-text-image" />}
        </div>
    );
};

import { FormApi } from 'final-form';
import { f7, Icon, Link, List, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { FC, useState } from 'react';
import { AnyObject, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
    RegistrationBusinessFormStep,
    RegistrationBusinessFormValues,
    RegistrationBusinessPopupProps,
} from './RegistrationBusinessPopup.types';

import { getFullErrorMessage, validateFormData } from './RegistrationBusinessPopup.utils';

import { CountrySelectPopup } from '../CountrySelectPopup';
import { CustomButton } from '../CustomButton';

import { CheckboxField } from '../Fields/CheckboxField';
import { CustomInputField } from '../Fields/CustomInputField';
import { CustomSelectField } from '../Fields/CustomSelectField';
import { SelectSheetField } from '../Fields/SelectSheetField';
import { PrivacyAndTerms } from '../PrivacyAndTerms';

import { setAlertAction } from '@/actions/alertActions';
import { registerBusiness } from '@/actions/sessionActions';
import { IcBack, IcClose } from '@/assets';
import { COMMONAPI_ERROR_USER_ALREADY_EXISTS } from '@/error-handler';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { useCountryOptions } from '@/hooks/useCountryOptions';
import { closeBusinessRegistrationPopup } from '@/reducers/registrationReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { AVAILABLE_COUNTRIES } from '@/shared/constants';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';

import './RegistrationBusinessPopup.less';
import { classificatorToCustomSelectValue } from '@/utils';

const AuthStep: FC<{ onNext: () => void; onLogin: () => void; valid: boolean; errors: AnyObject }> = ({
    onNext,
    onLogin,
    valid,
    errors,
}) => {
    const { t } = useTranslation();

    return (
        <div className="registration-business-popup__form">
            <div className="registration-business-popup__form-header">
                <span className="title">{t('Sign Up')}</span>
            </div>
            <div className="registration-business-popup__form-content">
                <div className="registration-business-popup__form-block">
                    <List form noHairlines>
                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                label={t('E-mail').toString()}
                                name="email"
                                type="email"
                                slot="list"
                                autocomplete="off"
                                floatingLabel
                                clearButton
                                requiredSign
                            />
                        </div>
                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                label={t('Password').toString()}
                                errorInTooltip={t(getFullErrorMessage(errors.password))}
                                name="password"
                                type="password"
                                slot="list"
                                autocomplete="off"
                                withPasswordToggle
                                floatingLabel
                                requiredSign
                            />
                        </div>
                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                name="passwordRepeat"
                                label={t('Repeat Password').toString()}
                                type="password"
                                slot="list"
                                autocomplete="off"
                                withPasswordToggle
                                floatingLabel
                                showErrorOnFocus
                                requiredSign
                            />
                        </div>

                        <CheckboxField name="accept" checkbox className="accept-checkbox">
                            <div className="item-title">
                                {`${t('I have read and agree on the')} `}

                                <PrivacyAndTerms />
                            </div>
                        </CheckboxField>
                    </List>

                    <CustomButton
                        className="registration-business-popup__form-button"
                        disabled={!valid}
                        size="large"
                        fullWidth
                        onClick={onNext}
                    >
                        {t('Continue')}
                    </CustomButton>
                </div>
            </div>
            <p className="registration-business-popup__sign-in-text">
                {`${t('Already have an account?')} `}
                <Link onClick={onLogin}>{t('Log In')}</Link>
            </p>
        </div>
    );
};

const GeneralStep: FC<{
    form: FormApi<RegistrationBusinessFormValues, Partial<RegistrationBusinessFormValues>>;
    onNext: () => void;
    onPrev: () => void;
    valid: boolean;
}> = ({ form, onNext, onPrev, valid }) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);

    const [isCompanyFormSheetOpened, openCompanyFormSheet, closeCompanyFormSheet] = useBoolState(false);
    const [isCountryFormSheetOpened, openCountryFormSheetOpened, closeCountryFormSheetOpened] = useBoolState(false);

    const companyFormOptions = useAppSelector((state) =>
        state.classificatorReducer.entitiesClassificators.Company_BusinessType.map(classificatorToCustomSelectValue),
    );

    const { registrationLoading } = useAppSelector((state) => state.sessionReducer);

    const countries = useCountryOptions({
        withIcons: true,
        filterCountries: AVAILABLE_COUNTRIES,
    });

    return (
        <div className="registration-business-popup__form general">
            <div className="registration-business-popup__form-header general">
                {!isMobile && (
                    <Link iconOnly onClick={onPrev}>
                        <Icon className="icon-back" />
                    </Link>
                )}

                <span className="title">{t('registerComplete')}</span>
            </div>
            <div className="registration-business-popup__form-content">
                <div className="registration-business-popup__form-block">
                    <span className="title">{t('General Info')}</span>
                    <List noHairlines>
                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                name="firstName"
                                label={t('First Name').toString()}
                                floatingLabel
                                type="text"
                                clearButton
                                slot="list"
                                requiredSign
                            />
                        </div>

                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                name="lastName"
                                label={t('Surname').toString()}
                                floatingLabel
                                type="text"
                                clearButton
                                slot="list"
                                requiredSign
                            />
                        </div>
                        <div className="registration-business-popup__form-item registration-business-popup__form-select">
                            <CustomSelectField
                                name="country"
                                requiredSign
                                options={countries}
                                label={t('Country')}
                                openPopup={openCountryFormSheetOpened}
                                withFlags
                            />
                        </div>
                        <div className="registration-business-popup__form-item">
                            <CustomSelectField
                                className="registration-business-popup__form-select"
                                name="companyType"
                                options={companyFormOptions}
                                requiredSign
                                openPopup={openCompanyFormSheet}
                                label={t('Company Legal Form')}
                            />
                        </div>

                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                name="taxPayerIn"
                                requiredSign
                                label={t('Taxpayer Identification Number (INN)')}
                            />
                        </div>

                        <div className="registration-business-popup__form-item">
                            <CustomInputField name="registrationNumber" requiredSign label={t('OGRN')} />
                        </div>
                    </List>
                </div>
                <div className="registration-business-popup__form-block">
                    <span className="title">{t('registration_form.business_account.your_store_section.title')}</span>
                    <span className="sub-title">
                        {t('registration_form.business_account.your_store_section.description')}
                    </span>
                    <List noHairlines>
                        <div className="registration-business-popup__form-item">
                            <CustomInputField name="storeName" requiredSign label={t('Store Name')} />
                        </div>

                        <div className="registration-business-popup__form-item">
                            <CustomInputField
                                name="storeDescription"
                                requiredSign
                                label={t('Description')}
                                info={t('registration_form.business_account.your_store_section.example')}
                            />
                        </div>
                    </List>
                </div>
                <CountrySelectPopup
                    opened={isCountryFormSheetOpened}
                    filterCountries={AVAILABLE_COUNTRIES}
                    onCountrySelect={(country) => {
                        form.change('country', {
                            value: country.code,
                            label: country.name,
                        });
                        closeCountryFormSheetOpened();
                    }}
                    onClose={closeCountryFormSheetOpened}
                />
                <SelectSheetField
                    type="radio"
                    name="companyType"
                    options={companyFormOptions}
                    title={t('Company Legal Form')}
                    opened={isCompanyFormSheetOpened}
                    onSheetOpen={openCompanyFormSheet}
                    onSheetClose={closeCompanyFormSheet}
                />
                <CustomButton
                    className="registration-business-popup__form-button"
                    disabled={registrationLoading || !valid}
                    size="large"
                    fullWidth
                    onClick={onNext}
                >
                    {t('Done')}
                </CustomButton>
            </div>
        </div>
    );
};

const RegistrationBusinessPopup = ({ opened, openLogin }: RegistrationBusinessPopupProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(getIsMobile);

    const [step, setStep] = useState<RegistrationBusinessFormStep>(RegistrationBusinessFormStep.AUTH);

    const createAccount = async (values: RegistrationBusinessFormValues) => {
        const { sessionReducer } = await dispatch(
            registerBusiness(
                {
                    email: values.email,
                    password: values.password,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    country: values.country.value,
                    companyName: '',
                    companyType: +values.companyType.value,
                    taxPayerIn: values.taxPayerIn,
                    registrationNumber: values.registrationNumber,
                },
                {
                    name: values.storeName,
                    description: values.storeDescription,
                },
            ),
        );

        if (sessionReducer.registrationError) {
            dispatch(
                setAlertAction({
                    text: t(`${sessionReducer.registrationError}`),
                    callback: () => {
                        if (sessionReducer.registrationErrorCode === COMMONAPI_ERROR_USER_ALREADY_EXISTS) {
                            onLoginHandle();
                        }
                    },
                }),
            );

            return;
        }

        f7.view.main.router.navigate(`/confirm-email?verificationEmail=${values?.email}`, {
            reloadCurrent: true,
            force: true,
        });

        onCloseHandle();
    };

    const onCloseHandle = () => {
        dispatch(closeBusinessRegistrationPopup());
        setStep(RegistrationBusinessFormStep.AUTH);
    };

    const onLoginHandle = () => {
        onCloseHandle();
        openLogin();
    };

    return (
        <MSPopup className="registration-business-popup" opened={opened}>
            <Navbar className="registration-business-popup__navbar" noShadow noHairline>
                {isMobile && (
                    <NavLeft>
                        <Link
                            className="registration-business-popup__close"
                            iconOnly
                            onClick={() => {
                                if (step === RegistrationBusinessFormStep.AUTH) {
                                    onCloseHandle();
                                } else {
                                    setStep(RegistrationBusinessFormStep.AUTH);
                                }
                            }}
                        >
                            {step === RegistrationBusinessFormStep.AUTH ? <IcClose /> : <IcBack />}
                        </Link>
                    </NavLeft>
                )}

                {!isMobile && (
                    <NavRight>
                        <Link className="registration-business-popup__close" iconOnly onClick={onCloseHandle}>
                            <IcClose />
                        </Link>
                    </NavRight>
                )}
            </Navbar>

            <div className="registration-business-popup__content">
                <Form<RegistrationBusinessFormValues>
                    validate={(values) => validateFormData(values, step)}
                    onSubmit={createAccount}
                >
                    {({ handleSubmit, form, valid, errors }) => (
                        <>
                            {step === RegistrationBusinessFormStep.AUTH && (
                                <AuthStep
                                    onNext={() => {
                                        setStep(RegistrationBusinessFormStep.GENERAL);
                                    }}
                                    onLogin={onLoginHandle}
                                    valid={valid}
                                    errors={errors}
                                />
                            )}
                            {step === RegistrationBusinessFormStep.GENERAL && (
                                <GeneralStep
                                    form={form}
                                    onNext={handleSubmit}
                                    onPrev={() => {
                                        setStep(RegistrationBusinessFormStep.AUTH);
                                    }}
                                    valid={valid}
                                />
                            )}
                        </>
                    )}
                </Form>
            </div>
        </MSPopup>
    );
};

export default RegistrationBusinessPopup;

import { BankCodes } from '@/types/bankCodes';

export const mapProfileRegionToBankCode = (profileRegion: string): BankCodes => {
    switch (profileRegion?.toLowerCase()) {
        case 'us':
            return BankCodes.ABA;
        case 'ru':
            return BankCodes.BIC;
        default:
            return BankCodes.SWIFT;
    }
};

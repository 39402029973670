import { SortBy } from '@/types/sort';

export const applySortByToSortMatrix = (sortByItem: SortBy, matrix: SortBy[]) => {
    let inverted = matrix.includes(sortByItem) ? matrix.filter((item) => item !== sortByItem) : [...matrix, sortByItem];
    // apply rules
    switch (sortByItem) {
        case SortBy.popular:
            inverted = inverted.filter((item) => item !== SortBy.sales_first);
            break;
        case SortBy.sales_first:
            inverted = inverted.filter((item) => item !== SortBy.popular);
            break;
        case SortBy.price_high_to_low:
            inverted = inverted.filter((item) => item !== SortBy.price_low_to_high);
            break;
        case SortBy.price_low_to_high:
            inverted = inverted.filter((item) => item !== SortBy.price_high_to_low);
            break;
    }
    return inverted;
};

import cn from 'classnames';
import { Block, Button, Link, Navbar, NavLeft, NavRight, NavTitle, Popup } from 'framework7-react';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectProductsPopupProps } from './SelectProductsPopup.types';

import { searchStoreProducts, SHOWN_PRODUCT_STATUSES, SHOWN_SERVICES_STATUSES } from '@/actions/storeHomePageActions';
import { IcClose, IcDone, IcFilter, IcReject, IcSearch } from '@/assets';
import { CatalogList } from '@/components/CatalogList';
import { CustomChip } from '@/components/CustomChip';
import { FilterPopover } from '@/components/FilterPopover';
import { SellerAreaSearchbar } from '@/components/SellerAreaSearchbar';
import { StatusFiltersMobilePopup } from '@/components/StatusFiltersMobilePopup';
import { Product, ProductListRequest } from '@/generated/marketplaceapi';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { usePreloader } from '@/hooks/usePreloader';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Popups } from '@/types/popups';
import { ProductType } from '@/types/productType';
import { classificatorToCustomSelectValue } from '@/utils';

import './SelectProductsPopup.less';

export const SelectProductsPopup: React.FC<SelectProductsPopupProps> = ({
    selectedProducts: initial,
    isOpenProductsPopup,
    onAddProductsSubmit,
    handleCloseProductsPopup,
    priceFrom,
    priceTo,
    saleUid,
    validateErrors,
    hideFilters = false,
}): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [selectedProducts, setSelectedProducts] = useState<Set<string>>(new Set());
    const [visibleSelected, setVisibleSelected] = useState<Set<string>>(new Set());
    const [filterPopupOpened, setFilterPopupOpened] = useState(false);
    const [open, close] = useBooleanState(setFilterPopupOpened);
    const isMobile = useAppSelector(getIsMobile);
    const [isSearchbarEnabled, setIsSearchbarEnabled] = useState(false);
    const [onSearchbarEnable, onSearchbarDisable] = useBooleanState(setIsSearchbarEnabled);
    const statuses = useAppSelector((state) => state.classificatorReducer.entitiesClassificators.Product_Status);
    const { storeProducts, storeProductsLoading, storeProductsFilteredCount } = useAppSelector(
        (state) => state.storeHomePageReducer,
    );

    const { activeProductType } = useAppSelector((state) => state.rootReducer);
    const isSP = activeProductType === ProductType.Service;
    const productStatuses = isSP ? SHOWN_SERVICES_STATUSES : SHOWN_PRODUCT_STATUSES;

    usePreloader(storeProductsLoading);

    const filterPopoverOptions = useMemo(
        () =>
            statuses
                .filter((item) => productStatuses.includes(item.code as unknown as Product.StatusEnum))
                .map(classificatorToCustomSelectValue),
        [productStatuses, statuses],
    );

    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');

    const filterProducts = useCallback(
        ({ statuses, sortBy }: ProductListRequest) => {
            dispatch(searchStoreProducts({ statuses, sortBy, priceFrom, priceTo }));
        },
        [dispatch, priceFrom, priceTo],
    );

    const handleItemClick = useCallback((uid: string) => {
        setSelectedProducts((prev) => {
            const updated = new Set(prev);
            if (updated.has(uid)) {
                updated.delete(uid);
                return updated;
            } else {
                return new Set(updated.add(uid));
            }
        });
    }, []);

    const handleSelectAll = useCallback(() => {
        setSelectedProducts((prev) =>
            prev.size !== 0 ? new Set<string>([]) : new Set(storeProducts?.map((item) => item.uid)),
        );
    }, [storeProducts]);

    const handleAddProducts = useCallback(() => {
        onAddProductsSubmit(storeProducts?.filter((item) => visibleSelected.has(item?.uid)));
    }, [onAddProductsSubmit, storeProducts, visibleSelected]);

    const handleSearch = useMemo(
        () =>
            debounce(
                (dispatchFn: typeof dispatch, params: ProductListRequest, countOnly?: boolean) =>
                    dispatchFn(
                        searchStoreProducts(
                            {
                                ...params,
                                statuses: params.statuses?.length ? params.statuses : undefined,
                            },
                            countOnly,
                        ),
                    ),
                700,
            ),
        [],
    );

    const handlePresearch = useMemo(
        () => (dispatchFn: typeof dispatch, params: ProductListRequest) => handleSearch(dispatchFn, params, true),
        [handleSearch],
    );

    const handleSearchInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target?.value);
            handleSearch(dispatch, {
                name: e.target.value,
                statuses: selectedStatuses,
                priceFrom,
                priceTo,
                validateForSaleUid: saleUid,
            });
        },
        [dispatch, handleSearch, priceFrom, priceTo, saleUid, selectedStatuses],
    );

    const clearSearch = useCallback(() => {
        setSearchValue('');
        handleSearch(dispatch, {
            name: '',
            statuses: selectedStatuses,
            priceFrom,
            priceTo,
            validateForSaleUid: saleUid,
        });
    }, [dispatch, handleSearch, priceFrom, priceTo, saleUid, selectedStatuses]);

    const navTitle = useMemo(
        () =>
            !isMobile
                ? isSP
                    ? t('services.form.edit_homepage.added_popular_services.title')
                    : t('Select Popular Products')
                : selectedProducts.size
                ? `${t('Selected')} (${selectedProducts.size})`
                : '',
        [isMobile, isSP, selectedProducts.size, t],
    );

    const getStatusChipText = useCallback(
        (item: string) => filterPopoverOptions?.find((option) => option.value === item)?.label ?? item,
        [filterPopoverOptions],
    );

    const handleRemoveStatusOption = useCallback(
        (item: string) => {
            const filtered = selectedStatuses?.filter((option) => option !== item);
            setSelectedStatuses(filtered);
            filterProducts({ statuses: filtered.length ? filtered : undefined });
        },
        [filterProducts, selectedStatuses],
    );

    useEffect(() => {
        if (isOpenProductsPopup) {
            setSelectedProducts(new Set(initial?.map((item) => item.uid)));
        }
    }, [initial, isOpenProductsPopup]);

    useEffect(() => {
        setVisibleSelected(
            new Set(
                Array.from(selectedProducts).filter(
                    (item) =>
                        storeProducts?.some((product) => product.uid === item && (!validateErrors || !product.error)),
                ),
            ),
        );
    }, [selectedProducts, storeProducts, validateErrors]);

    return (
        <Popup
            backdrop
            slot="fixed"
            id={Popups.ADD_PRODUCTS_POPUP}
            className={cn(Popups.ADD_PRODUCTS_POPUP, 'add-products-popup')}
            opened={isOpenProductsPopup}
            onPopupClosed={handleCloseProductsPopup}
        >
            <Navbar noHairline noShadow className="add-product-popup-navbar">
                {isMobile && (
                    <NavLeft>
                        <Button className="close" onClick={handleCloseProductsPopup}>
                            <IcClose />
                        </Button>
                    </NavLeft>
                )}
                <NavTitle>{navTitle}</NavTitle>
                <NavRight>
                    {isMobile ? (
                        <>
                            {!isSP && (
                                <Button round className={cn('filter-button-mobile')} onClick={open}>
                                    <IcFilter />
                                </Button>
                            )}

                            <Link iconOnly searchbarEnable=".add-products-popup-head__searchbar">
                                <IcSearch className="navbar-link-icon" />
                            </Link>
                        </>
                    ) : (
                        <Link iconOnly className="close" onClick={handleCloseProductsPopup}>
                            <IcClose />
                        </Link>
                    )}
                </NavRight>
                <SellerAreaSearchbar
                    className="add-products-popup-head__searchbar"
                    onSearchbarSearch={(_, query: string) => {
                        handleSearch(dispatch, {
                            name: query,
                            statuses: selectedStatuses,
                            priceFrom,
                            priceTo,
                        });
                    }}
                    // onSearchbarClear={clearSearchbar}
                    expandable
                    onSearchbarEnable={onSearchbarEnable}
                    onSearchbarDisable={onSearchbarDisable}
                />
            </Navbar>
            <div className={cn('popup-content')}>
                <Block className="add-products-popup-head">
                    {isMobile ? (
                        <div className="add-products-popup-head-selected">
                            <div className="mobile-chips-container">
                                {selectedStatuses?.map((item) => (
                                    <CustomChip
                                        value={item}
                                        key={item}
                                        text={getStatusChipText(item)}
                                        deletable
                                        onDelete={handleRemoveStatusOption}
                                    />
                                ))}
                            </div>
                            <p>{t('{{count}} results', { count: storeProducts?.length || 0 })}</p>
                        </div>
                    ) : (
                        <>
                            <div className="add-products-popup-head-selected">
                                <p>
                                    {t('Selected')} ({visibleSelected.size})
                                </p>
                            </div>
                            <div className="add-products-popup-head-filter">
                                {!hideFilters && (
                                    <FilterPopover
                                        popoverTriggerElement={(triggerPopover, filterPopoverOpened) => (
                                            <Button
                                                round
                                                className={cn('filter-button', {
                                                    active: filterPopoverOpened,
                                                })}
                                                onClick={triggerPopover}
                                            >
                                                <IcFilter />
                                                {t('Filter')}
                                            </Button>
                                        )}
                                        options={filterPopoverOptions}
                                        className="status-filter-popover"
                                        selected={selectedStatuses}
                                        onClear={() => setSelectedStatuses([])}
                                        onApply={(statuses) => {
                                            setSelectedStatuses(statuses);
                                            filterProducts({ statuses });
                                        }}
                                        isMobile={isMobile}
                                    />
                                )}

                                <Navbar className={cn('add-products-popup-head-filter-searchbar')} noHairline>
                                    <IcSearch className="search-icon" />
                                    <input
                                        className={cn('add-products-popup-head-filter-searchbar-input')}
                                        value={searchValue}
                                        onChange={handleSearchInputChange}
                                        placeholder={t('Search')}
                                    />
                                    {!!searchValue && (
                                        <NavRight>
                                            <IcReject onClick={clearSearch} className={cn('icon-reject')} />
                                        </NavRight>
                                    )}
                                </Navbar>
                            </div>
                        </>
                    )}
                </Block>
                <Block className="add-products-popup-content">
                    {storeProducts?.length ? (
                        <CatalogList
                            showErrors={validateErrors}
                            data={storeProducts}
                            onClick={handleItemClick}
                            onSelectAllClick={handleSelectAll}
                            selectedProducts={selectedProducts}
                        />
                    ) : (
                        <span className="no-products">{t('No results')}</span>
                    )}
                </Block>
                {(!isMobile || !isSearchbarEnabled) && (
                    <Block className="add-products-popup-footer">
                        <Button fill round raised onClick={handleAddProducts} disabled={!selectedProducts.size}>
                            {isMobile ? <IcDone /> : t('Done')}
                        </Button>
                    </Block>
                )}
            </div>
            {isMobile && !hideFilters && (
                <StatusFiltersMobilePopup
                    saleUid={saleUid}
                    handleSearch={handlePresearch}
                    options={filterPopoverOptions}
                    searchLoading={storeProductsLoading}
                    presearchCount={storeProductsFilteredCount}
                    opened={filterPopupOpened && isMobile}
                    selectedOptions={selectedStatuses}
                    onApply={(statuses, sortBy) => {
                        setSelectedStatuses(statuses);
                        filterProducts({ statuses, sortBy });
                    }}
                    onPopupClose={close}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                />
            )}
        </Popup>
    );
};

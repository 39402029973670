import { useEffect } from 'react';

import { loadProductWishList } from '@/actions/productActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const useLoadProductWishList = (): void => {
    const dispatch = useAppDispatch();
    const logged = useAppSelector(isLoggedIn);

    useEffect(() => {
        if (logged) {
            dispatch(loadProductWishList());
        }
    }, [dispatch, logged]);
};

import cn from 'classnames';
import React, { useCallback } from 'react';

import { PageShrinkMenuProps } from './PageShrinkMenu.types';

import './PageShrinkMenu.less';

export const PageShrinkMenu = ({
    menuItemsNames,
    menuItems,
    selectedName,
    className,
    ...props
}: PageShrinkMenuProps) => {
    const renderItem = useCallback(
        (name: string) => {
            const item = menuItems[name];
            const Icon = item && item.icon;

            return item ? (
                <div
                    key={name}
                    className={cn('page-shrink-menu__item', name, {
                        selected: selectedName === name,
                    })}
                >
                    <Icon />
                </div>
            ) : (
                <React.Fragment key={name} />
            );
        },
        [menuItems, selectedName],
    );

    return (
        <div {...props} className={cn('page-shrink-menu', className)}>
            {menuItemsNames.map(renderItem)}
        </div>
    );
};

import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CatalogListProps } from './CatalogList.types';
import { CatalogListItem } from './CatalogListItem';

import { IcLoad16 } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ProductType } from '@/types/productType';

import './CatalogList.less';
import { SortBy } from '@/types/sort';

export const CatalogList = React.memo(
    ({
        data = [],
        onClick,
        selectedProducts,
        onSelectAllClick,
        onSelectItem,
        archive,
        showErrors,
    }: CatalogListProps): JSX.Element => {
        const { t } = useTranslation();

        const activeType = useAppSelector(getActiveProductType);
        const isMobile = useAppSelector(getIsMobile);

        const [sortBy, setSortBy] = useState<SortBy>(SortBy.created_new_to_old);

        const inputRef = useRef<HTMLInputElement>(null);

        const makeHandleItemSelect = useCallback(
            (uid: string) => () => {
                onSelectItem ? onSelectItem(uid) : onClick(uid);
            },
            [onClick, onSelectItem],
        );

        useEffect(() => {
            if (selectedProducts?.size && selectedProducts?.size < data?.length && inputRef?.current) {
                inputRef.current.indeterminate = true;
            }
        }, [data?.length, selectedProducts?.size]);

        const handleSort = useCallback(() => {
            setSortBy((prev) =>
                prev === SortBy.created_new_to_old ? SortBy.created_old_to_new : SortBy.created_new_to_old,
            );
        }, []);

        const sortedData = isMobile
            ? data
            : (data || []).sort((a, b) => {
                  const aDate = new Date(a.productDate);
                  const bDate = new Date(b.productDate);

                  if (sortBy === SortBy.created_new_to_old) {
                      return bDate.getTime() - aDate.getTime();
                  }

                  return aDate.getTime() - bDate.getTime();
              });

        return (
            <div className="catalog-list data-table data-table-init">
                <table>
                    <thead className="catalog-list-head">
                        <tr>
                            {selectedProducts && (
                                <th className="checkbox-cell">
                                    <label className="checkbox">
                                        <input
                                            ref={inputRef}
                                            type="checkbox"
                                            onChange={onSelectAllClick}
                                            checked={
                                                selectedProducts?.size === data.length && selectedProducts?.size !== 0
                                            }
                                        />
                                        <i className="icon-checkbox" />
                                    </label>
                                </th>
                            )}
                            <th className="label-cell">
                                {activeType === ProductType.Service
                                    ? t('services.seller_area.list_of_services.table.field.service')
                                    : t('Product')}
                            </th>
                            <th className="label-cell catalog-list-head-price">
                                {activeType === ProductType.Service
                                    ? t('services.seller_area.list_of_services.table.field.price')
                                    : t('Price')}
                            </th>
                            {activeType === ProductType.Product && (
                                <>
                                    <th className="label-cell">
                                        {t('seller_area.dropdown_my_products.products.table_header.sold')}
                                    </th>
                                    <th className="label-cell">{t('Reserved')}</th>
                                    <th className="label-cell">{t('In stock')}</th>
                                </>
                            )}
                            {activeType === ProductType.Service && (
                                <>
                                    <th className="label-cell">
                                        {t('services.seller_area.list_of_services.table.field.views')}
                                    </th>
                                    <th className="label-cell">
                                        {t('services.seller_area.list_of_services.table.field.in_wish_list')}
                                    </th>
                                </>
                            )}
                            <th
                                className="label-cell"
                                onClick={handleSort}
                                style={{ cursor: 'pointer', position: 'relative' }}
                            >
                                {activeType === ProductType.Service
                                    ? t('services.seller_area.list_of_services.table.field.added')
                                    : t('Added')}
                                <IcLoad16
                                    className={cn('catalog-list-head-sort-icon', {
                                        'catalog-list-head-sort-icon__to-top': sortBy === SortBy.created_old_to_new,
                                    })}
                                />
                            </th>
                            <th className="label-cell">
                                {activeType === ProductType.Service
                                    ? t('Status')
                                    : t('services.seller_area.list_of_services.table.field.status')}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="catalog-list-body">
                        {!!sortedData.length &&
                            sortedData.map((item) => (
                                <CatalogListItem
                                    key={item.uid}
                                    item={item}
                                    onItemClick={onClick}
                                    onItemSelect={makeHandleItemSelect(item?.uid)}
                                    archive={archive}
                                    selected={selectedProducts?.has(item.uid)}
                                    showCheckbox={!!selectedProducts}
                                    showErrors={showErrors}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        );
    },
);
CatalogList.displayName = 'CatalogList';

import cn from 'classnames';
import { Button, f7 } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddToFavoriteButtonProps } from './AddToFavoriteButton.types';

import { IcStarFilled, IcStarOutlined } from '@/assets';
import { OnbordingMessage } from '@/components/OnbordingMessage';
import { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import { useAppSelector } from '@/hooks/store';
import { useAddStoreToFavorites } from '@/hooks/useAddStoreToFavorites';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';

import './AddToFavoriteButton.less';

export const AddToFavoriteButton = ({
    storeUid,
    withOnbordingMessage = false,
    className,
    ...buttonProps
}: AddToFavoriteButtonProps) => {
    const { t } = useTranslation();

    const { added, onClick } = useAddStoreToFavorites(storeUid);

    const logged = useAppSelector(isLoggedIn);

    const isMobile = useAppSelector(getIsMobile);

    const handleOnbordingMessageClick = useCallback(() => {
        if (!logged) {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        }
    }, [logged]);

    return (
        <div className="add-to-favorite-button-wrapper">
            <Button {...buttonProps} className={cn('add-to-favorite-button', className, { added })} onClick={onClick}>
                {added ? <IcStarFilled /> : <IcStarOutlined />}
                {!isMobile && t(`${added ? 'Added' : 'Add'} To Favorites`)}
            </Button>
            {withOnbordingMessage && (
                <OnbordingMessage
                    className="add-to-favorite-button__onbording-message"
                    type={OnbordingMessageType.FAVORITE_STORE}
                    triangle="right"
                    onClick={handleOnbordingMessageClick}
                />
            )}
        </div>
    );
};

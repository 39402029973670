/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import portableFetch from '@/axios/portableFetchWrapper';
import { Configuration } from "./configuration";

const BASE_PATH = "/commonapi".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AbstractAuthorizedRequest
 */
export interface AbstractAuthorizedRequest {
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {Array<AccountEmail>}
     * @memberof Account
     */
    accountEmails?: Array<AccountEmail>;
    /**
     * 
     * @type {Array<AccountPhone>}
     * @memberof Account
     */
    accountPhones?: Array<AccountPhone>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountSecret?: string;
    /**
     * 
     * @type {Array<AccountSettings>}
     * @memberof Account
     */
    accountSettings?: Array<AccountSettings>;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    actionConfirmationEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    actionConfirmationType?: string;
    /**
     * 
     * @type {Array<Address>}
     * @memberof Account
     */
    addresses?: Array<Address>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    affiliateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    alwaysRefundEWallet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    bannerId?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    campaignId?: string;
    /**
     * 
     * @type {Language}
     * @memberof Account
     */
    communicationLanguage?: Language;
    /**
     * 
     * @type {Company}
     * @memberof Account
     */
    company?: Company;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    confirmLogin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    confirmOutTransaction?: boolean;
    /**
     * 
     * @type {Country}
     * @memberof Account
     */
    country?: Country;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    currencyConversion?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    customParameters?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    externalAccountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id?: number;
    /**
     * 
     * @type {Language}
     * @memberof Account
     */
    language?: Language;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    legalEntities?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    merchantUid?: string;
    /**
     * 
     * @type {Person}
     * @memberof Account
     */
    person?: Person;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    privacyPolicy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    regionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    registrationNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    returnAndRefundPolicy?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    securityQuestion1Id?: number;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    securityQuestion2Id?: number;
    /**
     * 
     * @type {Array<ShortStoreInfo>}
     * @memberof Account
     */
    serviceProviders?: Array<ShortStoreInfo>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    status?: string;
    /**
     * 
     * @type {Array<ShortStoreInfo>}
     * @memberof Account
     */
    stores?: Array<ShortStoreInfo>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    taxPayerIn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    termsAndConditions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    test?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    timezone?: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    uid?: string;
    /**
     * 
     * @type {Array<AccountWebResource>}
     * @memberof Account
     */
    webResources?: Array<AccountWebResource>;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    weekStartsOn?: string;
}
/**
 * 
 * @export
 * @interface AccountAddress
 */
export interface AccountAddress {
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    firstAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    secondAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAddress
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountAddressRequest
 */
export interface AccountAddressRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof AccountAddressRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAddressRequest
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountAddressRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface AccountBirthDateRequest
 */
export interface AccountBirthDateRequest {
    /**
     * 
     * @type {Date}
     * @memberof AccountBirthDateRequest
     */
    birthDate?: Date;
}
/**
 * 
 * @export
 * @interface AccountChangePasswordRequest
 */
export interface AccountChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountChangePasswordRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountChangePasswordRequest
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountChangePasswordRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountChangePasswordRequest
     */
    sendNotification?: boolean;
}
/**
 * 
 * @export
 * @interface AccountConfirmationResponse
 */
export interface AccountConfirmationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AccountConfirmationResponse
     */
    actionConfirmationEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountConfirmationResponse
     */
    confirmedByCurrentTarget?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationResponse
     */
    currentConfirmationTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationResponse
     */
    currentConfirmationType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationResponse
     */
    pendingConfirmationTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationResponse
     */
    pendingConfirmationType?: string;
}
/**
 * 
 * @export
 * @interface AccountConfirmationSettingsRequest
 */
export interface AccountConfirmationSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSettingsRequest
     */
    actionConfirmationType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSettingsRequest
     */
    authKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountConfirmationSettingsRequest
     */
    confirmOutTransaction?: boolean;
}
/**
 * 
 * @export
 * @interface AccountConfirmationSetupConfirmRequest
 */
export interface AccountConfirmationSetupConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupConfirmRequest
     */
    confirmationCode?: string;
}
/**
 * 
 * @export
 * @interface AccountConfirmationSetupRequest
 */
export interface AccountConfirmationSetupRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupRequest
     */
    actionConfirmationType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupRequest
     */
    authKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupRequest
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountConfirmationSetupRequest
     */
    enable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupRequest
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountConfirmationSetupRequest
     */
    phoneNumber?: string;
}
/**
 * 
 * @export
 * @interface AccountConfirmationStatusResponse
 */
export interface AccountConfirmationStatusResponse {
    /**
     * 
     * @type {AccountConfirmationResponse}
     * @memberof AccountConfirmationStatusResponse
     */
    accountConfirmationResponse?: AccountConfirmationResponse;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountConfirmationStatusResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountConfirmationStatusResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountContinueRegistrationRequest
 */
export interface AccountContinueRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountContinueRegistrationRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContinueRegistrationRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContinueRegistrationRequest
     */
    codeType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountContinueRegistrationRequest
     */
    language?: string;
}
/**
 * 
 * @export
 * @interface AccountDevice
 */
export interface AccountDevice {
    /**
     * 
     * @type {boolean}
     * @memberof AccountDevice
     */
    allowNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDevice
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDevice
     */
    deviceModel?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDevice
     */
    deviceOS?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDevice
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDevice
     */
    deviceUid?: string;
    /**
     * 
     * @type {Language}
     * @memberof AccountDevice
     */
    language?: Language;
    /**
     * 
     * @type {Date}
     * @memberof AccountDevice
     */
    lastActivity?: Date;
}
/**
 * 
 * @export
 * @interface AccountDeviceRequest
 */
export interface AccountDeviceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AccountDeviceRequest
     */
    allowNotifications?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    deviceModel?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    deviceOS?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    deviceUid?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceRequest
     */
    language?: string;
}
/**
 * 
 * @export
 * @interface AccountDeviceResponse
 */
export interface AccountDeviceResponse {
    /**
     * 
     * @type {Array<AccountDevice>}
     * @memberof AccountDeviceResponse
     */
    deviceList?: Array<AccountDevice>;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountDeviceResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountDeviceResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountDeviceUidRequest
 */
export interface AccountDeviceUidRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountDeviceUidRequest
     */
    deviceUid?: string;
}
/**
 * 
 * @export
 * @interface AccountDocument
 */
export interface AccountDocument {
    /**
     * 
     * @type {Account}
     * @memberof AccountDocument
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    bucketName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    comments?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocument
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocument
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    documentNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    documentSubtype?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    documentType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    documentUid?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocument
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    fileName1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    fileName2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    fileReference1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    fileReference2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    firstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDocument
     */
    hidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountDocument
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocument
     */
    issuedDate?: Date;
    /**
     * 
     * @type {Country}
     * @memberof AccountDocument
     */
    issuingCountry?: Country;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    objectName1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    objectName2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    placeOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    rawData?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocument
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface AccountDocumentBody
 */
export interface AccountDocumentBody {
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentBody
     */
    filename?: string;
}
/**
 * 
 * @export
 * @interface AccountDocumentRequest
 */
export interface AccountDocumentRequest {
    /**
     * 
     * @type {AccountDocument}
     * @memberof AccountDocumentRequest
     */
    document?: AccountDocument;
}
/**
 * 
 * @export
 * @interface AccountDocumentResponse
 */
export interface AccountDocumentResponse {
    /**
     * 
     * @type {Array<AccountDocument>}
     * @memberof AccountDocumentResponse
     */
    documentList?: Array<AccountDocument>;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountDocumentResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountDocumentResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountDocumentUploadRequest
 */
export interface AccountDocumentUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    base64File1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    base64File2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    bucketName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocumentUploadRequest
     */
    documentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    documentUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    documentUrl2?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocumentUploadRequest
     */
    expirationDate?: Date;
    /**
     * 
     * @type {any}
     * @memberof AccountDocumentUploadRequest
     */
    file1?: any;
    /**
     * 
     * @type {any}
     * @memberof AccountDocumentUploadRequest
     */
    file2?: any;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    fileName1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    fileName2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    objectName1?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    objectName2?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    ownerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    subtype?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentUploadRequest
     */
    uid?: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountDocumentUploadRequest
     */
    uploadDate?: Date;
}
/**
 * 
 * @export
 * @interface AccountEmail
 */
export interface AccountEmail {
    /**
     * 
     * @type {number}
     * @memberof AccountEmail
     */
    accountId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountEmail
     */
    confirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountEmail
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountEmail
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountEmailRequest
 */
export interface AccountEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountEmailRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountEmailRequest
     */
    language?: string;
}
/**
 * 
 * @export
 * @interface AccountForgotPasswordRequest
 */
export interface AccountForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountForgotPasswordRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountForgotPasswordRequest
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface AccountIdentityImpl
 */
export interface AccountIdentityImpl {
    /**
     * 
     * @type {number}
     * @memberof AccountIdentityImpl
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentityImpl
     */
    identityType?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentityImpl
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface AccountLanguageRequest
 */
export interface AccountLanguageRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountLanguageRequest
     */
    communicationLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountLanguageRequest
     */
    language?: string;
}
/**
 * 
 * @export
 * @interface AccountListResponse
 */
export interface AccountListResponse {
    /**
     * 
     * @type {Array<Account>}
     * @memberof AccountListResponse
     */
    accountList?: Array<Account>;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountNoteRequest
 */
export interface AccountNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountNoteRequest
     */
    languageCode?: string;
}
/**
 * 
 * @export
 * @interface AccountNotice
 */
export interface AccountNotice {
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    bodyClass?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    bodyColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotice
     */
    bodyShown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    bodyText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    headerClass?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    headerColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotice
     */
    headerShown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    headerText?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    iconClass?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountNotice
     */
    iconColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountNotice
     */
    iconShown?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountNotice
     */
    priority?: number;
}
/**
 * 
 * @export
 * @interface AccountNoticeListResponse
 */
export interface AccountNoticeListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountNoticeListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof AccountNoticeListResponse
     */
    languageCode?: string;
    /**
     * 
     * @type {Array<AccountNotice>}
     * @memberof AccountNoticeListResponse
     */
    noticeList?: Array<AccountNotice>;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountNoticeListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountPayoutSettings
 */
export interface AccountPayoutSettings {
    /**
     * 
     * @type {BankAccountEditRequest}
     * @memberof AccountPayoutSettings
     */
    bankAccount?: BankAccountEditRequest;
    /**
     * 
     * @type {PayoutExternalAccount}
     * @memberof AccountPayoutSettings
     */
    externalAccount?: PayoutExternalAccount;
    /**
     * 
     * @type {string}
     * @memberof AccountPayoutSettings
     */
    interval?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPayoutSettings
     */
    pspCode?: string;
}
/**
 * 
 * @export
 * @interface AccountPayoutSettingsRequest
 */
export interface AccountPayoutSettingsRequest {
    /**
     * 
     * @type {AccountPayoutSettings}
     * @memberof AccountPayoutSettingsRequest
     */
    payoutSettings?: AccountPayoutSettings;
}
/**
 * 
 * @export
 * @interface AccountPayoutSettingsResponse
 */
export interface AccountPayoutSettingsResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountPayoutSettingsResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {AccountPayoutSettings}
     * @memberof AccountPayoutSettingsResponse
     */
    payoutSettings?: AccountPayoutSettings;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountPayoutSettingsResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountPhone
 */
export interface AccountPhone {
    /**
     * 
     * @type {number}
     * @memberof AccountPhone
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    confirmationCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone
     */
    confirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone
     */
    contactPreference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountPhone0
 */
export interface AccountPhone0 {
    /**
     * 
     * @type {number}
     * @memberof AccountPhone0
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone0
     */
    confirmationCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone0
     */
    confirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone0
     */
    contactPreference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone0
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone0
     */
    number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhone0
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone0
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhone0
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountPhoneRequest
 */
export interface AccountPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    fullNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPhoneRequest
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPhoneRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountPhoneResponse
 */
export interface AccountPhoneResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountPhoneResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {AccountRegistrationPhone}
     * @memberof AccountPhoneResponse
     */
    phone?: AccountRegistrationPhone;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountPhoneResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountProfilePictureRequest
 */
export interface AccountProfilePictureRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountProfilePictureRequest
     */
    base64File?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountProfilePictureRequest
     */
    fileName?: string;
}
/**
 * 
 * @export
 * @interface AccountRegistrationPhone
 */
export interface AccountRegistrationPhone {
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhone
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhone
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhone
     */
    number?: string;
}
/**
 * 
 * @export
 * @interface AccountRegistrationPhoneRequest
 */
export interface AccountRegistrationPhoneRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    fullNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountRegistrationPhoneRequest
     */
    smpUid?: string;
}
/**
 * 
 * @export
 * @interface AccountResetPasswordRequest
 */
export interface AccountResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountResetPasswordRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResetPasswordRequest
     */
    sendNotification?: boolean;
}
/**
 * 
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * 
     * @type {Account}
     * @memberof AccountResponse
     */
    account?: Account;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof AccountResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface AccountSettings
 */
export interface AccountSettings {
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountSettings
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountSettings
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface AccountSettingsRequest
 */
export interface AccountSettingsRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AccountSettingsRequest
     */
    settings?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface AccountWalletRequest
 */
export interface AccountWalletRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountWalletRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AccountWebResource
 */
export interface AccountWebResource {
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource
     */
    relationId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface AccountWebResource0
 */
export interface AccountWebResource0 {
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource0
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource0
     */
    relationId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource0
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource0
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface AccountWebResourceRequest
 */
export interface AccountWebResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountWebResourceRequest
     */
    type?: AccountWebResourceRequest.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResourceRequest
     */
    url?: string;
}

/**
 * @export
 * @namespace AccountWebResourceRequest
 */
export namespace AccountWebResourceRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        F = <any> 'F',
        GEM4ME = <any> 'GEM4ME',
        INSTAGRAM = <any> 'INSTAGRAM',
        T = <any> 'T',
        TELEGRAM = <any> 'TELEGRAM',
        VIBER = <any> 'VIBER',
        W = <any> 'W',
        WHATSAPP = <any> 'WHATSAPP'
    }
}
/**
 * 
 * @export
 * @interface AccountWebResourcesRequest
 */
export interface AccountWebResourcesRequest {
    /**
     * 
     * @type {Array<AccountWebResource>}
     * @memberof AccountWebResourcesRequest
     */
    webResources?: Array<AccountWebResource>;
}
/**
 * 
 * @export
 * @interface ActivateTokenRequest
 */
export interface ActivateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivateTokenRequest
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateTokenRequest
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    accountId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    business?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    correspondence?: boolean;
    /**
     * 
     * @type {Country}
     * @memberof Address
     */
    country?: Country;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    firstAddressLine?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    home?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    legal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    other?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Address
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    secondAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AnswerOption
 */
export interface AnswerOption {
    /**
     * 
     * @type {number}
     * @memberof AnswerOption
     */
    orderNumber?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerOption
     */
    selected?: boolean;
    /**
     * 
     * @type {Array<Question>}
     * @memberof AnswerOption
     */
    subQuestions?: Array<Question>;
    /**
     * 
     * @type {string}
     * @memberof AnswerOption
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerOption
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerOption
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface AvailableTradeOfferListRequest
 */
export interface AvailableTradeOfferListRequest {
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    emailOrPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof AvailableTradeOfferListRequest
     */
    expireDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AvailableTradeOfferListRequest
     */
    expireDateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    pricePerUnitFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    pricePerUnitTo?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    quantityReservedFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    quantityReservedTo?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    quantityTotalFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    quantityTotalTo?: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableTradeOfferListRequest
     */
    rows?: number;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    tradeCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTradeOfferListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BCAccount
 */
export interface BCAccount {
    /**
     * 
     * @type {string}
     * @memberof BCAccount
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAccount
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAccount
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAccount
     */
    surname?: string;
}
/**
 * 
 * @export
 * @interface BCAddress
 */
export interface BCAddress {
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BCAddress
     */
    zipCode?: string;
}
/**
 * 
 * @export
 * @interface BCApplicationRequest
 */
export interface BCApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    cardType?: string;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    currency?: string;
    /**
     * 
     * @type {BCCustomerDetails}
     * @memberof BCApplicationRequest
     */
    customerDetails?: BCCustomerDetails;
    /**
     * 
     * @type {BCAddress}
     * @memberof BCApplicationRequest
     */
    deliveryAddress?: BCAddress;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    ownerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof BCApplicationRequest
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface BCCustomerDetails
 */
export interface BCCustomerDetails {
    /**
     * 
     * @type {BCAddress}
     * @memberof BCCustomerDetails
     */
    address?: BCAddress;
    /**
     * 
     * @type {Date}
     * @memberof BCCustomerDetails
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    passportCountry?: string;
    /**
     * 
     * @type {Date}
     * @memberof BCCustomerDetails
     */
    passportExpireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BCCustomerDetails
     */
    passportIssueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BCCustomerDetails
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface BCDetails
 */
export interface BCDetails {
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof BCDetails
     */
    availableBalanceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    cardSystem?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    issuerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    kycLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    maskedPan?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    ownerAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    panToken?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BCDetails
     */
    trxAmount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BCDetails
     */
    trxAmountLifetime?: number;
    /**
     * 
     * @type {number}
     * @memberof BCDetails
     */
    trxCount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BCDetails
     */
    trxCountLifetime?: number;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof BCDetails
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface BCDetailsResponse
 */
export interface BCDetailsResponse {
    /**
     * 
     * @type {BCDetails}
     * @memberof BCDetailsResponse
     */
    body?: BCDetails;
    /**
     * 
     * @type {ErrorData}
     * @memberof BCDetailsResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BCDetailsResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BCFullDetails
 */
export interface BCFullDetails {
    /**
     * 
     * @type {BCAccount}
     * @memberof BCFullDetails
     */
    account?: BCAccount;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof BCFullDetails
     */
    availableBalanceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    cardSystem?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    issuerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    kycLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    maskedPan?: string;
    /**
     * 
     * @type {BCAccount}
     * @memberof BCFullDetails
     */
    ownerAccount?: BCAccount;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    ownerAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    panToken?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BCFullDetails
     */
    trxAmount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BCFullDetails
     */
    trxAmountLifetime?: number;
    /**
     * 
     * @type {number}
     * @memberof BCFullDetails
     */
    trxCount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BCFullDetails
     */
    trxCountLifetime?: number;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof BCFullDetails
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface BCFullDetailsListResponse
 */
export interface BCFullDetailsListResponse {
    /**
     * 
     * @type {Array<BCFullDetails>}
     * @memberof BCFullDetailsListResponse
     */
    cardList?: Array<BCFullDetails>;
    /**
     * 
     * @type {ErrorData}
     * @memberof BCFullDetailsListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BCFullDetailsListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BCTransaction
 */
export interface BCTransaction {
    /**
     * 
     * @type {number}
     * @memberof BCTransaction
     */
    authAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    bcsTrxId?: string;
    /**
     * 
     * @type {number}
     * @memberof BCTransaction
     */
    billAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    billCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    brandedCardUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    crdrIndicator?: string;
    /**
     * 
     * @type {Date}
     * @memberof BCTransaction
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof BCTransaction
     */
    feeAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof BCTransaction
     */
    inserted?: Date;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    instrumentType?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    mcc?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    merchantName?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    pan?: string;
    /**
     * 
     * @type {number}
     * @memberof BCTransaction
     */
    settleAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    settleCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BCTransaction
     */
    trxAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    trxCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof BCTransaction
     */
    trxType?: string;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    accountId?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    correspondentBankDetails?: string;
    /**
     * 
     * @type {Country}
     * @memberof BankAccount
     */
    country?: Country;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    holderAddress?: string;
    /**
     * 
     * @type {Country}
     * @memberof BankAccount
     */
    holderCountry?: Country;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    holderName?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BankAccount
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    swiftCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BankAccountEditRequest
 */
export interface BankAccountEditRequest {
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    bankAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    correspondentBankDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    holderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    holderCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    holderName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    swiftCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountEditRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BankAccountListResponse
 */
export interface BankAccountListResponse {
    /**
     * 
     * @type {Array<BankAccount>}
     * @memberof BankAccountListResponse
     */
    bankAccountList?: Array<BankAccount>;
    /**
     * 
     * @type {ErrorData}
     * @memberof BankAccountListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof BankAccountListResponse
     */
    newBankAccountUid?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof BankAccountListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BankAccountResponse
 */
export interface BankAccountResponse {
    /**
     * 
     * @type {BankAccount}
     * @memberof BankAccountResponse
     */
    bankAccount?: BankAccount;
    /**
     * 
     * @type {ErrorData}
     * @memberof BankAccountResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BankAccountResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BankAccountUidRequest
 */
export interface BankAccountUidRequest {
    /**
     * 
     * @type {string}
     * @memberof BankAccountUidRequest
     */
    bankAccountUid?: string;
}
/**
 * 
 * @export
 * @interface BrandedCardApplication
 */
export interface BrandedCardApplication {
    /**
     * 
     * @type {Account}
     * @memberof BrandedCardApplication
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    addressLine2?: string;
    /**
     * 
     * @type {Date}
     * @memberof BrandedCardApplication
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    countryCode?: string;
    /**
     * 
     * @type {Currency}
     * @memberof BrandedCardApplication
     */
    currency?: Currency;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryAddressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryAddressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryCity?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    deliveryState?: string;
    /**
     * 
     * @type {AccountEmail}
     * @memberof BrandedCardApplication
     */
    email?: AccountEmail;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    firstName?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    lastName?: string;
    /**
     * 
     * @type {Account}
     * @memberof BrandedCardApplication
     */
    ownerAccount?: Account;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    passportCountry?: string;
    /**
     * 
     * @type {Date}
     * @memberof BrandedCardApplication
     */
    passportExpireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BrandedCardApplication
     */
    passportIssueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    paywsCardUid?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof BrandedCardApplication
     */
    phone?: AccountPhone;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication
     */
    trxAmount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication
     */
    trxAmountLifetime?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication
     */
    trxCount24?: number;
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication
     */
    trxCountLifetime?: number;
}
/**
 * 
 * @export
 * @interface BrandedCardApplication0
 */
export interface BrandedCardApplication0 {
    /**
     * 
     * @type {number}
     * @memberof BrandedCardApplication0
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication0
     */
    cardholder?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardApplication0
     */
    currency?: string;
    /**
     * 
     * @type {BCCustomerDetails}
     * @memberof BrandedCardApplication0
     */
    customerDetails?: BCCustomerDetails;
}
/**
 * 
 * @export
 * @interface BrandedCardApplicationListResponse
 */
export interface BrandedCardApplicationListResponse {
    /**
     * 
     * @type {Array<BrandedCardApplication>}
     * @memberof BrandedCardApplicationListResponse
     */
    applicationList?: Array<BrandedCardApplication>;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardApplicationListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardApplicationListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BrandedCardApplicationResponse
 */
export interface BrandedCardApplicationResponse {
    /**
     * 
     * @type {BrandedCardApplication}
     * @memberof BrandedCardApplicationResponse
     */
    application?: BrandedCardApplication;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardApplicationResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardApplicationResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BrandedCardListResponse
 */
export interface BrandedCardListResponse {
    /**
     * 
     * @type {Array<BCDetails>}
     * @memberof BrandedCardListResponse
     */
    cardList?: Array<BCDetails>;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BrandedCardOwnerChangeRequest
 */
export interface BrandedCardOwnerChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandedCardOwnerChangeRequest
     */
    cardUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardOwnerChangeRequest
     */
    ownerAccountUid?: string;
}
/**
 * 
 * @export
 * @interface BrandedCardTartiffRequest
 */
export interface BrandedCardTartiffRequest {
    /**
     * 
     * @type {number}
     * @memberof BrandedCardTartiffRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTartiffRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTartiffRequest
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTartiffRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BrandedCardTransactionListResponse
 */
export interface BrandedCardTransactionListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardTransactionListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<BCTransaction>}
     * @memberof BrandedCardTransactionListResponse
     */
    transactionList?: Array<BCTransaction>;
    /**
     * 
     * @type {ErrorData}
     * @memberof BrandedCardTransactionListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface BrandedCardTransferMoneyRequest
 */
export interface BrandedCardTransferMoneyRequest {
    /**
     * 
     * @type {number}
     * @memberof BrandedCardTransferMoneyRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTransferMoneyRequest
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTransferMoneyRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTransferMoneyRequest
     */
    fromCardUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandedCardTransferMoneyRequest
     */
    toCardUid?: string;
}
/**
 * 
 * @export
 * @interface BrandedCardUidRequest
 */
export interface BrandedCardUidRequest {
    /**
     * 
     * @type {string}
     * @memberof BrandedCardUidRequest
     */
    cardUid?: string;
}
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    cardHolder?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expYear?: number;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    maskedNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface CardListResponse
 */
export interface CardListResponse {
    /**
     * 
     * @type {Array<Card>}
     * @memberof CardListResponse
     */
    cardList?: Array<Card>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CardListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CardListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CheckAccountEmailRequest
 */
export interface CheckAccountEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckAccountEmailRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckAccountEmailRequest
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface CheckAccountResponse
 */
export interface CheckAccountResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckAccountResponse
     */
    available?: boolean;
    /**
     * 
     * @type {ErrorData}
     * @memberof CheckAccountResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CheckAccountResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CheckIBANRequest
 */
export interface CheckIBANRequest {
    /**
     * 
     * @type {string}
     * @memberof CheckIBANRequest
     */
    iban?: string;
}
/**
 * 
 * @export
 * @interface CheckIBANResponse
 */
export interface CheckIBANResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckIBANResponse
     */
    available?: boolean;
    /**
     * 
     * @type {ErrorData}
     * @memberof CheckIBANResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CheckIBANResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Classificator
 */
export interface Classificator {
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    dependency?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    field?: string;
    /**
     * 
     * @type {number}
     * @memberof Classificator
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface ClassificatorListResponse
 */
export interface ClassificatorListResponse {
    /**
     * 
     * @type {Array<Classificator>}
     * @memberof ClassificatorListResponse
     */
    classificatorList?: Array<Classificator>;
    /**
     * 
     * @type {ErrorData}
     * @memberof ClassificatorListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ClassificatorListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonApiProject
 */
export interface CommonApiProject {
    /**
     * 
     * @type {string}
     * @memberof CommonApiProject
     */
    buildDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CommonApiProject
     */
    currentTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CommonApiProject
     */
    projectVersion?: string;
}
/**
 * 
 * @export
 * @interface CommonApiResponse
 */
export interface CommonApiResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonApiResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonApiResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    acceptUndefinedProvisionChannel?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    accountId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    allowDuplicateOrderExternalId?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    allowNotificationsForBuyer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    allowNotificationsForSeller?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    businessName?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    businessTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    callbackUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    cardStatementName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    cardStatementNameExt?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    documentName?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    kppNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    legalEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    ownerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    responsibleForLoginActions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    rollingReserveHoldDays?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    rollingReservePrc?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    sendCallback?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    taxationSystem?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @interface ConfirmAccountAgreementsRequest
 */
export interface ConfirmAccountAgreementsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ConfirmAccountAgreementsRequest
     */
    agreements?: Array<string>;
}
/**
 * 
 * @export
 * @interface ContactGroup
 */
export interface ContactGroup {
    /**
     * 
     * @type {string}
     * @memberof ContactGroup
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactGroup
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactGroup
     */
    name?: string;
    /**
     * 
     * @type {ContactGroupAccount}
     * @memberof ContactGroup
     */
    owner?: ContactGroupAccount;
    /**
     * 
     * @type {string}
     * @memberof ContactGroup
     */
    ownerUid?: string;
    /**
     * 
     * @type {Array<ContactGroupAccount>}
     * @memberof ContactGroup
     */
    participants?: Array<ContactGroupAccount>;
    /**
     * 
     * @type {string}
     * @memberof ContactGroup
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ContactGroupAccount
 */
export interface ContactGroupAccount {
    /**
     * 
     * @type {string}
     * @memberof ContactGroupAccount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactGroupAccount
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactGroupAccount
     */
    name?: string;
    /**
     * 
     * @type {OrderAccountPhone}
     * @memberof ContactGroupAccount
     */
    phone?: OrderAccountPhone;
    /**
     * 
     * @type {string}
     * @memberof ContactGroupAccount
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactGroupAccount
     */
    surname?: string;
}
/**
 * 
 * @export
 * @interface ContactGroupListResponse
 */
export interface ContactGroupListResponse {
    /**
     * 
     * @type {Array<ContactGroup>}
     * @memberof ContactGroupListResponse
     */
    contactGroupList?: Array<ContactGroup>;
    /**
     * 
     * @type {ErrorData}
     * @memberof ContactGroupListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ContactGroupListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ContactGroupParticipantRequest
 */
export interface ContactGroupParticipantRequest {
    /**
     * 
     * @type {ContactGroupAccount}
     * @memberof ContactGroupParticipantRequest
     */
    participant?: ContactGroupAccount;
    /**
     * 
     * @type {string}
     * @memberof ContactGroupParticipantRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ContactGroupRequest
 */
export interface ContactGroupRequest {
    /**
     * 
     * @type {ContactGroup}
     * @memberof ContactGroupRequest
     */
    contactGroup?: ContactGroup;
}
/**
 * 
 * @export
 * @interface ContactGroupResponse
 */
export interface ContactGroupResponse {
    /**
     * 
     * @type {ContactGroup}
     * @memberof ContactGroupResponse
     */
    contactGroup?: ContactGroup;
    /**
     * 
     * @type {ErrorData}
     * @memberof ContactGroupResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ContactGroupResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ContactGroupUidRequest
 */
export interface ContactGroupUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ContactGroupUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Conversation
 */
export interface Conversation {
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    accountUid?: string;
    /**
     * 
     * @type {Array<ConversationFile>}
     * @memberof Conversation
     */
    attachments?: Array<ConversationFile>;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    externalAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    externalConversationUid?: string;
    /**
     * 
     * @type {Array<ConversationMessage>}
     * @memberof Conversation
     */
    messages?: Array<ConversationMessage>;
    /**
     * 
     * @type {Array<ConversationParameterRequest>}
     * @memberof Conversation
     */
    parameters?: Array<ConversationParameterRequest>;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof Conversation
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof Conversation
     */
    unreadCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Conversation
     */
    updateAt?: Date;
}
/**
 * 
 * @export
 * @interface ConversationFile
 */
export interface ConversationFile {
    /**
     * Attachment Base64 content
     * @type {string}
     * @memberof ConversationFile
     */
    content?: string;
    /**
     * Attachment creation date
     * @type {Date}
     * @memberof ConversationFile
     */
    createdAt?: Date;
    /**
     * Attachment download URL
     * @type {string}
     * @memberof ConversationFile
     */
    downloadUrl?: string;
    /**
     * Attachment expiration date
     * @type {Date}
     * @memberof ConversationFile
     */
    expiryAt?: Date;
    /**
     * Attachment name
     * @type {string}
     * @memberof ConversationFile
     */
    name?: string;
    /**
     * Attachment size
     * @type {number}
     * @memberof ConversationFile
     */
    size?: number;
    /**
     * Attachment type
     * @type {string}
     * @memberof ConversationFile
     */
    type?: string;
    /**
     * Attachment UID
     * @type {string}
     * @memberof ConversationFile
     */
    uid?: string;
    /**
     * Attachment URL
     * @type {string}
     * @memberof ConversationFile
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ConversationListRequest
 */
export interface ConversationListRequest {
    /**
     * 
     * @type {Date}
     * @memberof ConversationListRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversationListRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof ConversationListRequest
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationListRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof ConversationListRequest
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface ConversationListResponse
 */
export interface ConversationListResponse {
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof ConversationListResponse
     */
    conversationList?: Array<Conversation>;
    /**
     * 
     * @type {ErrorData}
     * @memberof ConversationListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ConversationListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ConversationMessage
 */
export interface ConversationMessage {
    /**
     * 
     * @type {Array<ConversationFile>}
     * @memberof ConversationMessage
     */
    attachments?: Array<ConversationFile>;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessage
     */
    content?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConversationMessage
     */
    createdAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationMessage
     */
    incoming?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationMessage
     */
    uid?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConversationMessage
     */
    updateAt?: Date;
}
/**
 * 
 * @export
 * @interface ConversationParameterRequest
 */
export interface ConversationParameterRequest {
    /**
     * Custom parameter key
     * @type {string}
     * @memberof ConversationParameterRequest
     */
    key?: string;
    /**
     * Custom parameter value
     * @type {string}
     * @memberof ConversationParameterRequest
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface ConversationRequest
 */
export interface ConversationRequest {
    /**
     * Conversation attachments
     * @type {Array<ConversationFile>}
     * @memberof ConversationRequest
     */
    attachments?: Array<ConversationFile>;
    /**
     * User email
     * @type {string}
     * @memberof ConversationRequest
     */
    email?: string;
    /**
     * Conversation message
     * @type {string}
     * @memberof ConversationRequest
     */
    message?: string;
    /**
     * Conversation message status
     * @type {string}
     * @memberof ConversationRequest
     */
    messageStatus?: string;
    /**
     * Conversation message UID
     * @type {string}
     * @memberof ConversationRequest
     */
    messageUid?: string;
    /**
     * Conversation custom parameters
     * @type {Array<ConversationParameterRequest>}
     * @memberof ConversationRequest
     */
    parameters?: Array<ConversationParameterRequest>;
    /**
     * Conversation subject
     * @type {string}
     * @memberof ConversationRequest
     */
    subject?: string;
    /**
     * Conversation UID
     * @type {string}
     * @memberof ConversationRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ConversationResponse
 */
export interface ConversationResponse {
    /**
     * 
     * @type {Conversation}
     * @memberof ConversationResponse
     */
    conversation?: Conversation;
    /**
     * 
     * @type {ErrorData}
     * @memberof ConversationResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ConversationResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CorsysAccountDocumentResponse
 */
export interface CorsysAccountDocumentResponse {
    /**
     * 
     * @type {Array<AccountDocument>}
     * @memberof CorsysAccountDocumentResponse
     */
    documentList?: Array<AccountDocument>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysAccountDocumentResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysAccountDocumentResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CorsysOrderListResponse
 */
export interface CorsysOrderListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysOrderListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Order0>}
     * @memberof CorsysOrderListResponse
     */
    orderList?: Array<Order0>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysOrderListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CorsysOrderResponse
 */
export interface CorsysOrderResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysOrderResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Order0}
     * @memberof CorsysOrderResponse
     */
    order?: Order0;
    /**
     * 
     * @type {ErrorData}
     * @memberof CorsysOrderResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CorsysSettings
 */
export interface CorsysSettings {
    /**
     * 
     * @type {string}
     * @memberof CorsysSettings
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CorsysSettings
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    brandedCardsAvailable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    registrationAllowed?: boolean;
}
/**
 * 
 * @export
 * @interface CountryListResponse
 */
export interface CountryListResponse {
    /**
     * 
     * @type {Array<Country>}
     * @memberof CountryListResponse
     */
    countryList?: Array<Country>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CountryListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CountryListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {Account}
     * @memberof Coupon
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    code?: string;
    /**
     * 
     * @type {Currency}
     * @memberof Coupon
     */
    currency?: Currency;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    issueDate?: Date;
    /**
     * 
     * @type {Order0}
     * @memberof Coupon
     */
    order?: Order0;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    orderUid?: string;
    /**
     * 
     * @type {CouponProgram}
     * @memberof Coupon
     */
    program?: CouponProgram;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    receiver?: string;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    requestDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface CouponListResponse
 */
export interface CouponListResponse {
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof CouponListResponse
     */
    couponList?: Array<Coupon>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CouponOrder
 */
export interface CouponOrder {
    /**
     * 
     * @type {OrderAffiliateInfo}
     * @memberof CouponOrder
     */
    affiliateInfo?: OrderAffiliateInfo;
    /**
     * 
     * @type {boolean}
     * @memberof CouponOrder
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CouponOrder
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponOrder
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    batchUid?: string;
    /**
     * 
     * @type {OrderAccount}
     * @memberof CouponOrder
     */
    buyer?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponOrder
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    receiver?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponOrder
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CouponOrder
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CouponOrder
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponOrder
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {OrderAccount}
     * @memberof CouponOrder
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponOrder
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface CouponOrderRequest
 */
export interface CouponOrderRequest {
    /**
     * 
     * @type {CouponOrder}
     * @memberof CouponOrderRequest
     */
    order?: CouponOrder;
}
/**
 * 
 * @export
 * @interface CouponOrderResponse
 */
export interface CouponOrderResponse {
    /**
     * 
     * @type {Coupon}
     * @memberof CouponOrderResponse
     */
    coupon?: Coupon;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponOrderResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ExchangerOrder}
     * @memberof CouponOrderResponse
     */
    order?: ExchangerOrder;
    /**
     * 
     * @type {WarningData}
     * @memberof CouponOrderResponse
     */
    warning?: WarningData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponOrderResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CouponProgram
 */
export interface CouponProgram {
    /**
     * 
     * @type {Account}
     * @memberof CouponProgram
     */
    account?: Account;
    /**
     * 
     * @type {boolean}
     * @memberof CouponProgram
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CouponProgram
     */
    programCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponProgram
     */
    programName?: string;
}
/**
 * 
 * @export
 * @interface CouponResponse
 */
export interface CouponResponse {
    /**
     * 
     * @type {Coupon}
     * @memberof CouponResponse
     */
    coupon?: Coupon;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CouponResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CouponUidRequest
 */
export interface CouponUidRequest {
    /**
     * 
     * @type {string}
     * @memberof CouponUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface CreateBrandedCardRequest
 */
export interface CreateBrandedCardRequest {
    /**
     * 
     * @type {BCApplicationRequest}
     * @memberof CreateBrandedCardRequest
     */
    application?: BCApplicationRequest;
}
/**
 * 
 * @export
 * @interface CreateBusinessAccountRequest
 */
export interface CreateBusinessAccountRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof CreateBusinessAccountRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    affiliateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    bannerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateBusinessAccountRequest
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    campaignId?: string;
    /**
     * 
     * @type {AccountAddress}
     * @memberof CreateBusinessAccountRequest
     */
    companyAddress?: AccountAddress;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessAccountRequest
     */
    companyCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    companyName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof CreateBusinessAccountRequest
     */
    companyPhone?: AccountPhone;
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessAccountRequest
     */
    companyType?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    companyUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    customParameters?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessAccountRequest
     */
    legalEntities?: boolean;
    /**
     * 
     * @type {AccountPhone}
     * @memberof CreateBusinessAccountRequest
     */
    mobilePhone?: AccountPhone;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessAccountRequest
     */
    pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessAccountRequest
     */
    privacyPolicy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    profilePictureBase64File?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    profilePictureFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    registrationNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessAccountRequest
     */
    returnAndRefundPolicy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessAccountRequest
     */
    taxPayerIn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBusinessAccountRequest
     */
    termsAndConditions?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCreditCardRequest
 */
export interface CreateCreditCardRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCreditCardRequest
     */
    creditCardToken?: string;
}
/**
 * 
 * @export
 * @interface CreatePersonalAccountRequest
 */
export interface CreatePersonalAccountRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof CreatePersonalAccountRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    affiliateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    bannerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreatePersonalAccountRequest
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    customParameters?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    lastName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof CreatePersonalAccountRequest
     */
    mobilePhone?: AccountPhone;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonalAccountRequest
     */
    pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonalAccountRequest
     */
    privacyPolicy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    profilePictureBase64File?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    profilePictureFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalAccountRequest
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonalAccountRequest
     */
    returnAndRefundPolicy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePersonalAccountRequest
     */
    termsAndConditions?: boolean;
}
/**
 * 
 * @export
 * @interface CreatePrepaidVirtualBrandedCardRequest
 */
export interface CreatePrepaidVirtualBrandedCardRequest {
    /**
     * 
     * @type {BrandedCardApplication0}
     * @memberof CreatePrepaidVirtualBrandedCardRequest
     */
    application?: BrandedCardApplication0;
}
/**
 * 
 * @export
 * @interface CreditCard
 */
export interface CreditCard {
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    accountId?: number;
    /**
     * 
     * @type {Address}
     * @memberof CreditCard
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    bank?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    cardMask?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    cardholder?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    expYear?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    iin?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    lastFour?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    paywsCardUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCard
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    publicId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    type?: CreditCard.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditCard
     */
    verificationCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCard
     */
    verificationOrderId?: number;
}

/**
 * @export
 * @namespace CreditCard
 */
export namespace CreditCard {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        A = <any> 'A',
        M = <any> 'M',
        U = <any> 'U',
        V = <any> 'V'
    }
}
/**
 * 
 * @export
 * @interface CreditCardEditRequest
 */
export interface CreditCardEditRequest {
    /**
     * 
     * @type {number}
     * @memberof CreditCardEditRequest
     */
    addressId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreditCardEditRequest
     */
    creditCardUid?: string;
    /**
     * 
     * @type {number}
     * @memberof CreditCardEditRequest
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof CreditCardEditRequest
     */
    expYear?: number;
}
/**
 * 
 * @export
 * @interface CreditCardListResponse
 */
export interface CreditCardListResponse {
    /**
     * 
     * @type {Array<CreditCard>}
     * @memberof CreditCardListResponse
     */
    creditCardList?: Array<CreditCard>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CreditCardListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CreditCardListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CreditCardResponse
 */
export interface CreditCardResponse {
    /**
     * 
     * @type {CreditCard}
     * @memberof CreditCardResponse
     */
    creditCard?: CreditCard;
    /**
     * 
     * @type {ErrorData}
     * @memberof CreditCardResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CreditCardResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CreditCardUidRequest
 */
export interface CreditCardUidRequest {
    /**
     * 
     * @type {string}
     * @memberof CreditCardUidRequest
     */
    creditCardUid?: string;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    base?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Currency
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    symbol?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Currency
     */
    test?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Currency
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface CurrencyListResponse
 */
export interface CurrencyListResponse {
    /**
     * 
     * @type {Array<Currency>}
     * @memberof CurrencyListResponse
     */
    currencyList?: Array<Currency>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CurrencyListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof CurrencyListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CurrencyRate
 */
export interface CurrencyRate {
    /**
     * 
     * @type {Date}
     * @memberof CurrencyRate
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CurrencyRate
     */
    dateTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof CurrencyRate
     */
    id?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CurrencyRate
     */
    primaryCurrency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof CurrencyRate
     */
    rate?: number;
    /**
     * 
     * @type {Currency}
     * @memberof CurrencyRate
     */
    secondaryCurrency?: Currency;
}
/**
 * 
 * @export
 * @interface CurrencyRateListResponse
 */
export interface CurrencyRateListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof CurrencyRateListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<CurrencyRate>}
     * @memberof CurrencyRateListResponse
     */
    rateList?: Array<CurrencyRate>;
    /**
     * 
     * @type {ErrorData}
     * @memberof CurrencyRateListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
    /**
     * 
     * @type {number}
     * @memberof ErrorData
     */
    errorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    errorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    messageCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorData
     */
    parameters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    requestUid?: string;
}
/**
 * 
 * @export
 * @interface ExchangerOrder
 */
export interface ExchangerOrder {
    /**
     * 
     * @type {OrderAffiliateInfo}
     * @memberof ExchangerOrder
     */
    affiliateInfo?: OrderAffiliateInfo;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerOrder
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExchangerOrder
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerOrder
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    batchUid?: string;
    /**
     * 
     * @type {OrderAccount}
     * @memberof ExchangerOrder
     */
    buyer?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    currencyCode?: string;
    /**
     * 
     * @type {OrderExchanger}
     * @memberof ExchangerOrder
     */
    exchanger?: OrderExchanger;
    /**
     * 
     * @type {number}
     * @memberof ExchangerOrder
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerOrder
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ExchangerOrder
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ExchangerOrder
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof ExchangerOrder
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {OrderAccount}
     * @memberof ExchangerOrder
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangerOrder
     */
    uid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExchangerOrder
     */
    useConfirmationCode?: boolean;
}
/**
 * 
 * @export
 * @interface ExchangerOrderListResponse
 */
export interface ExchangerOrderListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ExchangerOrderListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<ExchangerOrder>}
     * @memberof ExchangerOrderListResponse
     */
    orderList?: Array<ExchangerOrder>;
    /**
     * 
     * @type {ErrorData}
     * @memberof ExchangerOrderListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ExchangerOrderRequest
 */
export interface ExchangerOrderRequest {
    /**
     * 
     * @type {ExchangerOrder}
     * @memberof ExchangerOrderRequest
     */
    order?: ExchangerOrder;
}
/**
 * 
 * @export
 * @interface ExchangerOrderResponse
 */
export interface ExchangerOrderResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ExchangerOrderResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ExchangerOrder}
     * @memberof ExchangerOrderResponse
     */
    order?: ExchangerOrder;
    /**
     * 
     * @type {WarningData}
     * @memberof ExchangerOrderResponse
     */
    warning?: WarningData;
    /**
     * 
     * @type {ErrorData}
     * @memberof ExchangerOrderResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface GetAccountBrandedCardListRequest
 */
export interface GetAccountBrandedCardListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAccountBrandedCardListRequest
     */
    brandedCardUidList?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountBrandedCardListRequest
     */
    forceBalanceUpdate?: boolean;
}
/**
 * 
 * @export
 * @interface GetAccountIBANListRequest
 */
export interface GetAccountIBANListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetAccountIBANListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountIBANListRequest
     */
    iban?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccountIBANListRequest
     */
    summary?: boolean;
}
/**
 * 
 * @export
 * @interface GetAccountWalletListRequest
 */
export interface GetAccountWalletListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetAccountWalletListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface GetAuthorizationCodeRequest
 */
export interface GetAuthorizationCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationCodeRequest
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationCodeRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationCodeRequest
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface GetAuthorizationCodeResponse
 */
export interface GetAuthorizationCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAuthorizationCodeResponse
     */
    code?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof GetAuthorizationCodeResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {ErrorData}
     * @memberof GetAuthorizationCodeResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface GetBrandedCardTransactionListRequest
 */
export interface GetBrandedCardTransactionListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetBrandedCardTransactionListRequest
     */
    amountFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBrandedCardTransactionListRequest
     */
    amountTo?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandedCardTransactionListRequest
     */
    brandedCardUid?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandedCardTransactionListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandedCardTransactionListRequest
     */
    creditCardUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandedCardTransactionListRequest
     */
    currency?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GetBrandedCardTransactionListRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetBrandedCardTransactionListRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetBrandedCardTransactionListRequest
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof GetBrandedCardTransactionListRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof GetBrandedCardTransactionListRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandedCardTransactionListRequest
     */
    state?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBrandedCardTransactionListRequest
     */
    typeAction?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetOrderRequest
 */
export interface GetOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOrderRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface GetPspByBrandedCardProgramCodeRequest
 */
export interface GetPspByBrandedCardProgramCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetPspByBrandedCardProgramCodeRequest
     */
    programCode?: string;
}
/**
 * 
 * @export
 * @interface GetReferralProgramCodeRequest
 */
export interface GetReferralProgramCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GetReferralProgramCodeRequest
     */
    referralProgramName?: string;
}
/**
 * 
 * @export
 * @interface GetReferralProgramIdRequest
 */
export interface GetReferralProgramIdRequest {
    /**
     * 
     * @type {string}
     * @memberof GetReferralProgramIdRequest
     */
    affiliateId?: string;
}
/**
 * 
 * @export
 * @interface GetSettingsRequest
 */
export interface GetSettingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSettingsRequest
     */
    settingsNames?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetTokenByExternalProviderRequest
 */
export interface GetTokenByExternalProviderRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetTokenByExternalProviderRequest
     */
    parameters?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof GetTokenByExternalProviderRequest
     */
    provider?: GetTokenByExternalProviderRequest.ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof GetTokenByExternalProviderRequest
     */
    token?: string;
}

/**
 * @export
 * @namespace GetTokenByExternalProviderRequest
 */
export namespace GetTokenByExternalProviderRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum ProviderEnum {
        Facebook = <any> 'facebook',
        Google = <any> 'google'
    }
}
/**
 * 
 * @export
 * @interface GetTokenRequest
 */
export interface GetTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequest
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenRequest
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface GetTokenResponse
 */
export interface GetTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTokenResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTokenResponse
     */
    activationType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTokenResponse
     */
    active?: boolean;
    /**
     * 
     * @type {ErrorData}
     * @memberof GetTokenResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof GetTokenResponse
     */
    expiresIn?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTokenResponse
     */
    refreshToken?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof GetTokenResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface GetTransactionListRequest
 */
export interface GetTransactionListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetTransactionListRequest
     */
    amountFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionListRequest
     */
    amountTo?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionListRequest
     */
    creditCardUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTransactionListRequest
     */
    currency?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GetTransactionListRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTransactionListRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionListRequest
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof GetTransactionListRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTransactionListRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTransactionListRequest
     */
    state?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTransactionListRequest
     */
    typeAction?: Array<string>;
}
/**
 * 
 * @export
 * @interface Goal
 */
export interface Goal {
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    amountPerPayout?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    amountReserve?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    amountTargetOneMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    amountTargetOneWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    amountTargetTwoWeeks?: number;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    currencyCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    goalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    goalExistingAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    intervalCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    lastPaymentAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    lastPaymentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    payPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Goal
     */
    paymentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof Goal
     */
    startDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Goal
     */
    userIncome?: number;
}
/**
 * 
 * @export
 * @interface GoalListResponse
 */
export interface GoalListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof GoalListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Goal>}
     * @memberof GoalListResponse
     */
    goalList?: Array<Goal>;
    /**
     * 
     * @type {ErrorData}
     * @memberof GoalListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface GoalRequest
 */
export interface GoalRequest {
    /**
     * 
     * @type {Goal}
     * @memberof GoalRequest
     */
    goal?: Goal;
}
/**
 * 
 * @export
 * @interface GoalResponse
 */
export interface GoalResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof GoalResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Goal}
     * @memberof GoalResponse
     */
    goal?: Goal;
    /**
     * 
     * @type {ErrorData}
     * @memberof GoalResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface IBANResponse
 */
export interface IBANResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof IBANResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<IntBankAccountInfo>}
     * @memberof IBANResponse
     */
    ibanList?: Array<IntBankAccountInfo>;
    /**
     * 
     * @type {ErrorData}
     * @memberof IBANResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface IntBankAccountInfo
 */
export interface IntBankAccountInfo {
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountInfo
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    availableBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    availableBalanceBaseCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    balance?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    balanceBaseCurrency?: number;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountInfo
     */
    baseCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountInfo
     */
    iban?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IntBankAccountInfo
     */
    ibanPrimary?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    pendingBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    pendingBalanceBaseCurrency?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    rollingReserveBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof IntBankAccountInfo
     */
    rollingReserveBalanceBaseCurrency?: number;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountInfo
     */
    walletCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof IntBankAccountInfo
     */
    walletId?: string;
}
/**
 * 
 * @export
 * @interface JsonNode
 */
export interface JsonNode {
}
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    defaultLanguage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * 
     * @type {Language}
     * @memberof Language
     */
    mappingLanguage?: Language;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    mappingLanguageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface LanguageListResponse
 */
export interface LanguageListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof LanguageListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Language>}
     * @memberof LanguageListResponse
     */
    languageList?: Array<Language>;
    /**
     * 
     * @type {ErrorData}
     * @memberof LanguageListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface LinkedCard
 */
export interface LinkedCard {
    /**
     * 
     * @type {Address}
     * @memberof LinkedCard
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    bank?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    cardHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    creditCardUid?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkedCard
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkedCard
     */
    expYear?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    iin?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    lastFour?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    maskedNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkedCard
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    type?: LinkedCard.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkedCard
     */
    uid?: string;
}

/**
 * @export
 * @namespace LinkedCard
 */
export namespace LinkedCard {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        A = <any> 'A',
        M = <any> 'M',
        U = <any> 'U',
        V = <any> 'V'
    }
}
/**
 * 
 * @export
 * @interface LinkedCardEditRequest
 */
export interface LinkedCardEditRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkedCardEditRequest
     */
    addressUid?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkedCardEditRequest
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkedCardEditRequest
     */
    expYear?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkedCardEditRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface LinkedCardListResponse
 */
export interface LinkedCardListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof LinkedCardListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<LinkedCard>}
     * @memberof LinkedCardListResponse
     */
    linkedCardList?: Array<LinkedCard>;
    /**
     * 
     * @type {ErrorData}
     * @memberof LinkedCardListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface LinkedCardResponse
 */
export interface LinkedCardResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof LinkedCardResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {LinkedCard}
     * @memberof LinkedCardResponse
     */
    linkedCard?: LinkedCard;
    /**
     * 
     * @type {ErrorData}
     * @memberof LinkedCardResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface LinkedCardUidRequest
 */
export interface LinkedCardUidRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkedCardUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    languageCode?: string;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    code?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof MessageResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    type?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof MessageResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    empty?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelAndView
     */
    model?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelAndView
     */
    modelMap?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    reference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    status?: ModelAndView.StatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    view?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    viewName?: string;
}

/**
 * @export
 * @namespace ModelAndView
 */
export namespace ModelAndView {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACCEPTED = <any> 'ACCEPTED',
        ALREADYREPORTED = <any> 'ALREADY_REPORTED',
        BADGATEWAY = <any> 'BAD_GATEWAY',
        BADREQUEST = <any> 'BAD_REQUEST',
        BANDWIDTHLIMITEXCEEDED = <any> 'BANDWIDTH_LIMIT_EXCEEDED',
        CHECKPOINT = <any> 'CHECKPOINT',
        CONFLICT = <any> 'CONFLICT',
        CONTINUE = <any> 'CONTINUE',
        CREATED = <any> 'CREATED',
        DESTINATIONLOCKED = <any> 'DESTINATION_LOCKED',
        EXPECTATIONFAILED = <any> 'EXPECTATION_FAILED',
        FAILEDDEPENDENCY = <any> 'FAILED_DEPENDENCY',
        FORBIDDEN = <any> 'FORBIDDEN',
        FOUND = <any> 'FOUND',
        GATEWAYTIMEOUT = <any> 'GATEWAY_TIMEOUT',
        GONE = <any> 'GONE',
        HTTPVERSIONNOTSUPPORTED = <any> 'HTTP_VERSION_NOT_SUPPORTED',
        IMUSED = <any> 'IM_USED',
        INSUFFICIENTSPACEONRESOURCE = <any> 'INSUFFICIENT_SPACE_ON_RESOURCE',
        INSUFFICIENTSTORAGE = <any> 'INSUFFICIENT_STORAGE',
        INTERNALSERVERERROR = <any> 'INTERNAL_SERVER_ERROR',
        IAMATEAPOT = <any> 'I_AM_A_TEAPOT',
        LENGTHREQUIRED = <any> 'LENGTH_REQUIRED',
        LOCKED = <any> 'LOCKED',
        LOOPDETECTED = <any> 'LOOP_DETECTED',
        METHODFAILURE = <any> 'METHOD_FAILURE',
        METHODNOTALLOWED = <any> 'METHOD_NOT_ALLOWED',
        MOVEDPERMANENTLY = <any> 'MOVED_PERMANENTLY',
        MOVEDTEMPORARILY = <any> 'MOVED_TEMPORARILY',
        MULTIPLECHOICES = <any> 'MULTIPLE_CHOICES',
        MULTISTATUS = <any> 'MULTI_STATUS',
        NETWORKAUTHENTICATIONREQUIRED = <any> 'NETWORK_AUTHENTICATION_REQUIRED',
        NONAUTHORITATIVEINFORMATION = <any> 'NON_AUTHORITATIVE_INFORMATION',
        NOTACCEPTABLE = <any> 'NOT_ACCEPTABLE',
        NOTEXTENDED = <any> 'NOT_EXTENDED',
        NOTFOUND = <any> 'NOT_FOUND',
        NOTIMPLEMENTED = <any> 'NOT_IMPLEMENTED',
        NOTMODIFIED = <any> 'NOT_MODIFIED',
        NOCONTENT = <any> 'NO_CONTENT',
        OK = <any> 'OK',
        PARTIALCONTENT = <any> 'PARTIAL_CONTENT',
        PAYLOADTOOLARGE = <any> 'PAYLOAD_TOO_LARGE',
        PAYMENTREQUIRED = <any> 'PAYMENT_REQUIRED',
        PERMANENTREDIRECT = <any> 'PERMANENT_REDIRECT',
        PRECONDITIONFAILED = <any> 'PRECONDITION_FAILED',
        PRECONDITIONREQUIRED = <any> 'PRECONDITION_REQUIRED',
        PROCESSING = <any> 'PROCESSING',
        PROXYAUTHENTICATIONREQUIRED = <any> 'PROXY_AUTHENTICATION_REQUIRED',
        REQUESTEDRANGENOTSATISFIABLE = <any> 'REQUESTED_RANGE_NOT_SATISFIABLE',
        REQUESTENTITYTOOLARGE = <any> 'REQUEST_ENTITY_TOO_LARGE',
        REQUESTHEADERFIELDSTOOLARGE = <any> 'REQUEST_HEADER_FIELDS_TOO_LARGE',
        REQUESTTIMEOUT = <any> 'REQUEST_TIMEOUT',
        REQUESTURITOOLONG = <any> 'REQUEST_URI_TOO_LONG',
        RESETCONTENT = <any> 'RESET_CONTENT',
        SEEOTHER = <any> 'SEE_OTHER',
        SERVICEUNAVAILABLE = <any> 'SERVICE_UNAVAILABLE',
        SWITCHINGPROTOCOLS = <any> 'SWITCHING_PROTOCOLS',
        TEMPORARYREDIRECT = <any> 'TEMPORARY_REDIRECT',
        TOOEARLY = <any> 'TOO_EARLY',
        TOOMANYREQUESTS = <any> 'TOO_MANY_REQUESTS',
        UNAUTHORIZED = <any> 'UNAUTHORIZED',
        UNAVAILABLEFORLEGALREASONS = <any> 'UNAVAILABLE_FOR_LEGAL_REASONS',
        UNPROCESSABLEENTITY = <any> 'UNPROCESSABLE_ENTITY',
        UNSUPPORTEDMEDIATYPE = <any> 'UNSUPPORTED_MEDIA_TYPE',
        UPGRADEREQUIRED = <any> 'UPGRADE_REQUIRED',
        URITOOLONG = <any> 'URI_TOO_LONG',
        USEPROXY = <any> 'USE_PROXY',
        VARIANTALSONEGOTIATES = <any> 'VARIANT_ALSO_NEGOTIATES'
    }
}
/**
 * 
 * @export
 * @interface MoneyRequest
 */
export interface MoneyRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MoneyRequest
     */
    acceptPaymentsIfOrderExpired?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MoneyRequest
     */
    amountCollected?: number;
    /**
     * 
     * @type {number}
     * @memberof MoneyRequest
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof MoneyRequest
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MoneyRequest
     */
    fixedAmountPerPerson?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageThumbnailUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageThumbnailUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageThumbnailUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageThumbnailUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    imageUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    locationData?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    ownerUid?: string;
    /**
     * 
     * @type {Array<MoneyRequestParticipant>}
     * @memberof MoneyRequest
     */
    participants?: Array<MoneyRequestParticipant>;
    /**
     * 
     * @type {OrderAccount}
     * @memberof MoneyRequest
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {boolean}
     * @memberof MoneyRequest
     */
    share?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MoneyRequest
     */
    shareTransactions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequest
     */
    wwwUrl?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestImageDeleteRequest
 */
export interface MoneyRequestImageDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestImageDeleteRequest
     */
    imageLink?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestImageDeleteRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestImageUploadRequest
 */
export interface MoneyRequestImageUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestImageUploadRequest
     */
    base64File?: string;
    /**
     * 
     * @type {any}
     * @memberof MoneyRequestImageUploadRequest
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestImageUploadRequest
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestImageUploadRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestListRequest
 */
export interface MoneyRequestListRequest {
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestListRequest
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestListResponse
 */
export interface MoneyRequestListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof MoneyRequestListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<MoneyRequest>}
     * @memberof MoneyRequestListResponse
     */
    moneyRequestList?: Array<MoneyRequest>;
    /**
     * 
     * @type {ErrorData}
     * @memberof MoneyRequestListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface MoneyRequestParticipant
 */
export interface MoneyRequestParticipant {
    /**
     * 
     * @type {number}
     * @memberof MoneyRequestParticipant
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipant
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipant
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipant
     */
    orderStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipant
     */
    orderUid?: string;
    /**
     * 
     * @type {OrderAccountPhone}
     * @memberof MoneyRequestParticipant
     */
    phone?: OrderAccountPhone;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipant
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestParticipantRequest
 */
export interface MoneyRequestParticipantRequest {
    /**
     * 
     * @type {MoneyRequestParticipant}
     * @memberof MoneyRequestParticipantRequest
     */
    participant?: MoneyRequestParticipant;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestParticipantRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestRequest
 */
export interface MoneyRequestRequest {
    /**
     * 
     * @type {MoneyRequest}
     * @memberof MoneyRequestRequest
     */
    moneyRequest?: MoneyRequest;
}
/**
 * 
 * @export
 * @interface MoneyRequestResponse
 */
export interface MoneyRequestResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof MoneyRequestResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {MoneyRequest}
     * @memberof MoneyRequestResponse
     */
    moneyRequest?: MoneyRequest;
    /**
     * 
     * @type {ErrorData}
     * @memberof MoneyRequestResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface MoneyRequestStatusChangeRequest
 */
export interface MoneyRequestStatusChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestStatusChangeRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestStatusChangeRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface MoneyRequestUidRequest
 */
export interface MoneyRequestUidRequest {
    /**
     * 
     * @type {string}
     * @memberof MoneyRequestUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface NewAccountWalletRequest
 */
export interface NewAccountWalletRequest {
    /**
     * 
     * @type {string}
     * @memberof NewAccountWalletRequest
     */
    currencyCode?: string;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    batchUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {OrderAccount}
     * @memberof Order
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Order0
 */
export interface Order0 {
    /**
     * 
     * @type {boolean}
     * @memberof Order0
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    amountTotal?: number;
    /**
     * 
     * @type {Array<OrderCurrency>}
     * @memberof Order0
     */
    availableCurrencyList?: Array<OrderCurrency>;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    batchUid?: string;
    /**
     * 
     * @type {Account}
     * @memberof Order0
     */
    buyer?: Account;
    /**
     * 
     * @type {AccountIdentityImpl}
     * @memberof Order0
     */
    buyerIdentity?: AccountIdentityImpl;
    /**
     * 
     * @type {Date}
     * @memberof Order0
     */
    creationDate?: Date;
    /**
     * 
     * @type {Currency}
     * @memberof Order0
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    discountAmountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    discountPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof Order0
     */
    dueDate?: Date;
    /**
     * 
     * @type {OrderExtendedInfo}
     * @memberof Order0
     */
    extendedInfo?: OrderExtendedInfo;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    ibanBuyer?: string;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    ibanPayer?: string;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    ibanSeller?: string;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    itemAmountSubtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    itemAmountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    itemDiscountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    itemTaxAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Order0
     */
    lastRefundDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    moneyRequestId?: number;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    moneyRequestPublicId?: string;
    /**
     * 
     * @type {OrderExchanger}
     * @memberof Order0
     */
    orderExchanger?: OrderExchanger;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order0
     */
    orderItems?: Array<OrderItem>;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    orderNumber?: string;
    /**
     * 
     * @type {Account}
     * @memberof Order0
     */
    owner?: Account;
    /**
     * 
     * @type {Order0}
     * @memberof Order0
     */
    parentOrder?: Order0;
    /**
     * 
     * @type {Account}
     * @memberof Order0
     */
    payer?: Account;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    payerCategoryId?: number;
    /**
     * 
     * @type {AccountIdentityImpl}
     * @memberof Order0
     */
    payerIdentity?: AccountIdentityImpl;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    publicId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order0
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Order0
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {Account}
     * @memberof Order0
     */
    seller?: Account;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    sellerCategoryId?: number;
    /**
     * 
     * @type {AccountIdentityImpl}
     * @memberof Order0
     */
    sellerIdentity?: AccountIdentityImpl;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order0
     */
    taxBeforeDiscount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order0
     */
    taxInclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Order0
     */
    testMode?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order0
     */
    totalRefundAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof Order0
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Order1
 */
export interface Order1 {
    /**
     * 
     * @type {boolean}
     * @memberof Order1
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order1
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof Order1
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    batchUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Order1
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order1
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Order1
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order1
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof Order1
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {OrderAccount}
     * @memberof Order1
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Order1
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface OrderAccount
 */
export interface OrderAccount {
    /**
     * 
     * @type {string}
     * @memberof OrderAccount
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAccount
     */
    iban?: string;
    /**
     * 
     * @type {OrderAccountPhone}
     * @memberof OrderAccount
     */
    phone?: OrderAccountPhone;
}
/**
 * 
 * @export
 * @interface OrderAccountPhone
 */
export interface OrderAccountPhone {
    /**
     * 
     * @type {string}
     * @memberof OrderAccountPhone
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAccountPhone
     */
    fullNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAccountPhone
     */
    number?: string;
}
/**
 * 
 * @export
 * @interface OrderAffiliateInfo
 */
export interface OrderAffiliateInfo {
    /**
     * 
     * @type {string}
     * @memberof OrderAffiliateInfo
     */
    affiliateId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAffiliateInfo
     */
    bannerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAffiliateInfo
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderAffiliateInfo
     */
    customParameters?: string;
}
/**
 * 
 * @export
 * @interface OrderCurrency
 */
export interface OrderCurrency {
    /**
     * 
     * @type {number}
     * @memberof OrderCurrency
     */
    amount?: number;
    /**
     * 
     * @type {Currency}
     * @memberof OrderCurrency
     */
    currency?: Currency;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCurrency
     */
    enabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderCurrency
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderCurrency
     */
    rate?: number;
}
/**
 * 
 * @export
 * @interface OrderDraftRequest
 */
export interface OrderDraftRequest {
    /**
     * 
     * @type {string}
     * @memberof OrderDraftRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface OrderExchanger
 */
export interface OrderExchanger {
    /**
     * 
     * @type {string}
     * @memberof OrderExchanger
     */
    clientName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExchanger
     */
    courierNote?: string;
    /**
     * 
     * @type {Currency}
     * @memberof OrderExchanger
     */
    currency?: Currency;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    currencyRateBase?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderExchanger
     */
    fromAccountName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    fromAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    fromAmountDebt?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    fromAmountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    fromCurrencyRate?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderExchanger
     */
    paymentDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExchanger
     */
    toAccountName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    toAmountDebt?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    toAmountPaid?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderExchanger
     */
    toCurrencyRate?: number;
}
/**
 * 
 * @export
 * @interface OrderExtendedInfo
 */
export interface OrderExtendedInfo {
    /**
     * 
     * @type {boolean}
     * @memberof OrderExtendedInfo
     */
    acceptPaymentsIfOrderExpired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    accountWalletPublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    affiliateId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    bankAccountPublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    bannerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    creditCardPublicId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    customParameters?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderExtendedInfo
     */
    estimatedDeliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    externalOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    failUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderExtendedInfo
     */
    issueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    paymentFormUid?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    paymentMethodCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    paymentPageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    provisionChannel?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerAdditionalInfo?: string;
    /**
     * 
     * @type {Address}
     * @memberof OrderExtendedInfo
     */
    sellerAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerBusinessName?: string;
    /**
     * 
     * @type {AccountEmail}
     * @memberof OrderExtendedInfo
     */
    sellerEmail?: AccountEmail;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof OrderExtendedInfo
     */
    sellerPhone?: AccountPhone;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    sellerTaxPayerId?: string;
    /**
     * 
     * @type {AccountWebResource}
     * @memberof OrderExtendedInfo
     */
    sellerWebResource?: AccountWebResource;
    /**
     * 
     * @type {Address}
     * @memberof OrderExtendedInfo
     */
    shippingAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    statusExtended?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    statusExtendedComments?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderExtendedInfo
     */
    statusExtendedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    successUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    terms?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    trackingCarrier?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderExtendedInfo
     */
    trackingNumber?: string;
}
/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    amountSubtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    cryptoAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    cryptoCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    discountAmountCalculated?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    discountPercent?: number;
    /**
     * 
     * @type {Array<OrderItemParam>}
     * @memberof OrderItem
     */
    itemParams?: Array<OrderItemParam>;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    orderDiscountAmountPerItemCalculated?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    priceUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    sellerUid?: string;
    /**
     * 
     * @type {Tax}
     * @memberof OrderItem
     */
    tax?: Tax;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    taxAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface OrderItemParam
 */
export interface OrderItemParam {
    /**
     * 
     * @type {string}
     * @memberof OrderItemParam
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemParam
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface OrderLatestContact
 */
export interface OrderLatestContact {
    /**
     * 
     * @type {string}
     * @memberof OrderLatestContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLatestContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderLatestContact
     */
    surname?: string;
}
/**
 * 
 * @export
 * @interface OrderLatestContactsRequest
 */
export interface OrderLatestContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderLatestContactsRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderLatestContactsRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface OrderLatestContactsResponse
 */
export interface OrderLatestContactsResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderLatestContactsResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<OrderLatestContact>}
     * @memberof OrderLatestContactsResponse
     */
    items?: Array<OrderLatestContact>;
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderLatestContactsResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface OrderLatestItem
 */
export interface OrderLatestItem {
    /**
     * 
     * @type {number}
     * @memberof OrderLatestItem
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderLatestItem
     */
    discountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderLatestItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderLatestItem
     */
    priceUnit?: number;
}
/**
 * 
 * @export
 * @interface OrderLatestItemsRequest
 */
export interface OrderLatestItemsRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderLatestItemsRequest
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface OrderLatestItemsResponse
 */
export interface OrderLatestItemsResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderLatestItemsResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<OrderLatestItem>}
     * @memberof OrderLatestItemsResponse
     */
    items?: Array<OrderLatestItem>;
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderLatestItemsResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface OrderListRequest
 */
export interface OrderListRequest {
    /**
     * 
     * @type {number}
     * @memberof OrderListRequest
     */
    amountFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderListRequest
     */
    amountTo?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    currency?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderListRequest
     */
    dateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderListRequest
     */
    dateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
    /**
     * 
     * @type {Order1}
     * @memberof OrderRequest
     */
    order?: Order1;
}
/**
 * 
 * @export
 * @interface OrderResponse
 */
export interface OrderResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Order}
     * @memberof OrderResponse
     */
    order?: Order;
    /**
     * 
     * @type {WarningData}
     * @memberof OrderResponse
     */
    warning?: WarningData;
    /**
     * 
     * @type {ErrorData}
     * @memberof OrderResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    defaultMethod?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    name?: string;
    /**
     * 
     * @type {Array<Psp>}
     * @memberof PaymentMethod
     */
    pspList?: Array<Psp>;
}
/**
 * 
 * @export
 * @interface PaymentMethodListResponse
 */
export interface PaymentMethodListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof PaymentMethodListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof PaymentMethodListResponse
     */
    methodList?: Array<PaymentMethod>;
    /**
     * 
     * @type {ErrorData}
     * @memberof PaymentMethodListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface PayoutExternalAccount
 */
export interface PayoutExternalAccount {
    /**
     * 
     * @type {Array<PayoutExternalCard>}
     * @memberof PayoutExternalAccount
     */
    cards?: Array<PayoutExternalCard>;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalAccount
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalAccount
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface PayoutExternalCard
 */
export interface PayoutExternalCard {
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    currency?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PayoutExternalCard
     */
    defaultForCurrency?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PayoutExternalCard
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof PayoutExternalCard
     */
    expYear?: number;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    iin?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutExternalCard
     */
    lastFour?: string;
}
/**
 * 
 * @export
 * @interface Person
 */
export interface Person {
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    accountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Person
     */
    birthDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Person
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Person
     */
    pep?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Person
     */
    surname?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {AccountAddress}
     * @memberof Product
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    discountedPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    hashtags?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl5?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    inStock?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    languageCode?: string;
    /**
     * 
     * @type {Array<ProductLocalization>}
     * @memberof Product
     */
    localizations?: Array<ProductLocalization>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ownerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    productDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    publishDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    quantityReserved?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    safeCheckDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    safeCheckStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    weightUnit?: string;
}
/**
 * 
 * @export
 * @interface ProductImageDeleteRequest
 */
export interface ProductImageDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductImageDeleteRequest
     */
    imageLink?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDeleteRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductListRequest
 */
export interface ProductListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ProductListResponse
 */
export interface ProductListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ProductListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductListResponse
     */
    productList?: Array<Product>;
    /**
     * 
     * @type {ErrorData}
     * @memberof ProductListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ProductLocalization
 */
export interface ProductLocalization {
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    weightUnit?: string;
}
/**
 * 
 * @export
 * @interface ProductQuantityRequest
 */
export interface ProductQuantityRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductQuantityRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductQuantityRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductQuantityRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductRequest
 */
export interface ProductRequest {
    /**
     * 
     * @type {Product}
     * @memberof ProductRequest
     */
    product?: Product;
}
/**
 * 
 * @export
 * @interface ProductResponse
 */
export interface ProductResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ProductResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Product}
     * @memberof ProductResponse
     */
    product?: Product;
    /**
     * 
     * @type {ErrorData}
     * @memberof ProductResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ProductSearchListRequest
 */
export interface ProductSearchListRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof ProductSearchListRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    discountedPriceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    discountedPriceTo?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductSearchListRequest
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductSearchListRequest
     */
    publishDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {Sorting}
     * @memberof ProductSearchListRequest
     */
    sortBy?: Sorting;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ProductStatusRequest
 */
export interface ProductStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStatusRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductStatusRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductUidRequest
 */
export interface ProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Psp
 */
export interface Psp {
    /**
     * 
     * @type {string}
     * @memberof Psp
     */
    code?: string;
    /**
     * 
     * @type {Array<Currency>}
     * @memberof Psp
     */
    currencyList?: Array<Currency>;
    /**
     * 
     * @type {number}
     * @memberof Psp
     */
    id?: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof Psp
     */
    method?: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof Psp
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Psp
     */
    recurringEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Psp
     */
    refundEnabled?: boolean;
    /**
     * 
     * @type {Array<PspSetting>}
     * @memberof Psp
     */
    settings?: Array<PspSetting>;
}
/**
 * 
 * @export
 * @interface PspResponse
 */
export interface PspResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof PspResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Psp}
     * @memberof PspResponse
     */
    psp?: Psp;
    /**
     * 
     * @type {ErrorData}
     * @memberof PspResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface PspSetting
 */
export interface PspSetting {
    /**
     * 
     * @type {string}
     * @memberof PspSetting
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof PspSetting
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PspSetting
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    answerValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    description?: string;
    /**
     * 
     * @type {Array<AnswerOption>}
     * @memberof Question
     */
    options?: Array<AnswerOption>;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    orderNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    validationRegExp?: string;
}
/**
 * 
 * @export
 * @interface QuestionAnswer
 */
export interface QuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    answerValue?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionAnswer
     */
    options?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Questionnaire
 */
export interface Questionnaire {
    /**
     * 
     * @type {boolean}
     * @memberof Questionnaire
     */
    available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Questionnaire
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Questionnaire
     */
    completedQuestionCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Questionnaire
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Questionnaire
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Questionnaire
     */
    mandatoryQuestions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Questionnaire
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Questionnaire
     */
    orderNumber?: number;
    /**
     * 
     * @type {Array<QuestionnairePage>}
     * @memberof Questionnaire
     */
    pages?: Array<QuestionnairePage>;
    /**
     * 
     * @type {Array<Questionnaire>}
     * @memberof Questionnaire
     */
    questionnaires?: Array<Questionnaire>;
    /**
     * 
     * @type {number}
     * @memberof Questionnaire
     */
    totalQuestionCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Questionnaire
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface QuestionnaireListResponse
 */
export interface QuestionnaireListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof QuestionnaireListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Questionnaire>}
     * @memberof QuestionnaireListResponse
     */
    questionnaires?: Array<Questionnaire>;
    /**
     * 
     * @type {ErrorData}
     * @memberof QuestionnaireListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface QuestionnairePage
 */
export interface QuestionnairePage {
    /**
     * 
     * @type {number}
     * @memberof QuestionnairePage
     */
    orderNumber?: number;
    /**
     * 
     * @type {Array<Question>}
     * @memberof QuestionnairePage
     */
    questions?: Array<Question>;
}
/**
 * 
 * @export
 * @interface ReferralProgramCodeResponse
 */
export interface ReferralProgramCodeResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ReferralProgramCodeResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramCodeResponse
     */
    referralProgramCode?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof ReferralProgramCodeResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ReferralProgramResponse
 */
export interface ReferralProgramResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof ReferralProgramResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof ReferralProgramResponse
     */
    referralPublicId?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof ReferralProgramResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    companyName?: string;
    /**
     * 
     * @type {Array<Country>}
     * @memberof Region
     */
    countries?: Array<Country>;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionUrl?: string;
}
/**
 * 
 * @export
 * @interface RegionAgreement
 */
export interface RegionAgreement {
    /**
     * 
     * @type {Date}
     * @memberof RegionAgreement
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RegionAgreement
     */
    language?: string;
    /**
     * 
     * @type {Region}
     * @memberof RegionAgreement
     */
    region?: Region;
    /**
     * 
     * @type {string}
     * @memberof RegionAgreement
     */
    skin?: string;
    /**
     * 
     * @type {Date}
     * @memberof RegionAgreement
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RegionAgreement
     */
    type?: RegionAgreement.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RegionAgreement
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionAgreement
     */
    url?: string;
}

/**
 * @export
 * @namespace RegionAgreement
 */
export namespace RegionAgreement {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PP = <any> 'PP',
        TC = <any> 'TC',
        TS = <any> 'TS'
    }
}
/**
 * 
 * @export
 * @interface RegionAgreementListResponse
 */
export interface RegionAgreementListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof RegionAgreementListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<RegionAgreement>}
     * @memberof RegionAgreementListResponse
     */
    regionAgreementList?: Array<RegionAgreement>;
    /**
     * 
     * @type {ErrorData}
     * @memberof RegionAgreementListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface RegionListResponse
 */
export interface RegionListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof RegionListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Region>}
     * @memberof RegionListResponse
     */
    regionList?: Array<Region>;
    /**
     * 
     * @type {ErrorData}
     * @memberof RegionListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface SearchAgreementsRequest
 */
export interface SearchAgreementsRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchAgreementsRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAgreementsRequest
     */
    activeOnly?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgreementsRequest
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchAgreementsRequest
     */
    regionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchAgreementsRequest
     */
    skin?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchAgreementsRequest
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchAgreementsRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface SecurityQuestionRequest
 */
export interface SecurityQuestionRequest {
    /**
     * 
     * @type {SequrityQuestion}
     * @memberof SecurityQuestionRequest
     */
    questions?: SequrityQuestion;
}
/**
 * 
 * @export
 * @interface SecurityQuestionResponse
 */
export interface SecurityQuestionResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof SecurityQuestionResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {SequrityQuestion}
     * @memberof SecurityQuestionResponse
     */
    questions?: SequrityQuestion;
    /**
     * 
     * @type {ErrorData}
     * @memberof SecurityQuestionResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface SequrityQuestion
 */
export interface SequrityQuestion {
    /**
     * 
     * @type {string}
     * @memberof SequrityQuestion
     */
    question1Answer?: string;
    /**
     * 
     * @type {number}
     * @memberof SequrityQuestion
     */
    question1Id?: number;
    /**
     * 
     * @type {string}
     * @memberof SequrityQuestion
     */
    question1Name?: string;
    /**
     * 
     * @type {string}
     * @memberof SequrityQuestion
     */
    question2Answer?: string;
    /**
     * 
     * @type {number}
     * @memberof SequrityQuestion
     */
    question2Id?: number;
    /**
     * 
     * @type {string}
     * @memberof SequrityQuestion
     */
    question2Name?: string;
}
/**
 * 
 * @export
 * @interface SettingsResponse
 */
export interface SettingsResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof SettingsResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<CorsysSettings>}
     * @memberof SettingsResponse
     */
    settings?: Array<CorsysSettings>;
    /**
     * 
     * @type {ErrorData}
     * @memberof SettingsResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface ShortStoreInfo
 */
export interface ShortStoreInfo {
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    direction?: string;
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    field?: string;
}
/**
 * 
 * @export
 * @interface StorageObjectBody
 */
export interface StorageObjectBody {
    /**
     * 
     * @type {string}
     * @memberof StorageObjectBody
     */
    base64File?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageObjectBody
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageObjectBody
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface StorageObjectResponse
 */
export interface StorageObjectResponse {
}
/**
 * 
 * @export
 * @interface SumSubApplicant
 */
export interface SumSubApplicant {
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    applicantPlatform?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    env?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    externalUserId?: string;
    /**
     * 
     * @type {SumSubApplicantFixedInfo}
     * @memberof SumSubApplicant
     */
    fixedInfo?: SumSubApplicantFixedInfo;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    id?: string;
    /**
     * 
     * @type {SumSubApplicantInfo}
     * @memberof SumSubApplicant
     */
    info?: SumSubApplicantInfo;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    inspectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    jobId?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    lang?: string;
    /**
     * 
     * @type {SumSubRequiredIdDocs}
     * @memberof SumSubApplicant
     */
    requiredIdDocs?: SumSubRequiredIdDocs;
    /**
     * 
     * @type {SumSubReview}
     * @memberof SumSubApplicant
     */
    review?: SumSubReview;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicant
     */
    sourceKey?: string;
}
/**
 * 
 * @export
 * @interface SumSubApplicantAddress
 */
export interface SumSubApplicantAddress {
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    buildingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    flatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    subStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantAddress
     */
    town?: string;
}
/**
 * 
 * @export
 * @interface SumSubApplicantFixedInfo
 */
export interface SumSubApplicantFixedInfo {
    /**
     * 
     * @type {Array<SumSubApplicantAddress>}
     * @memberof SumSubApplicantFixedInfo
     */
    addresses?: Array<SumSubApplicantAddress>;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    countryOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    placeOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantFixedInfo
     */
    stateOfBirth?: string;
}
/**
 * 
 * @export
 * @interface SumSubApplicantInfo
 */
export interface SumSubApplicantInfo {
    /**
     * 
     * @type {Array<SumSubApplicantAddress>}
     * @memberof SumSubApplicantInfo
     */
    addresses?: Array<SumSubApplicantAddress>;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    countryOfBirth?: string;
    /**
     * 
     * @type {Array<SumSubCryptoWallet>}
     * @memberof SumSubApplicantInfo
     */
    cryptoWallets?: Array<SumSubCryptoWallet>;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    gender?: string;
    /**
     * 
     * @type {Array<SumSubDoc>}
     * @memberof SumSubApplicantInfo
     */
    idDocs?: Array<SumSubDoc>;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    placeOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubApplicantInfo
     */
    stateOfBirth?: string;
}
/**
 * 
 * @export
 * @interface SumSubAuthResponse
 */
export interface SumSubAuthResponse {
    /**
     * 
     * @type {string}
     * @memberof SumSubAuthResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubAuthResponse
     */
    clientId?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof SumSubAuthResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {string}
     * @memberof SumSubAuthResponse
     */
    externalUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubAuthResponse
     */
    initJson?: string;
    /**
     * 
     * @type {ErrorData}
     * @memberof SumSubAuthResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface SumSubCryptoWallet
 */
export interface SumSubCryptoWallet {
    /**
     * 
     * @type {string}
     * @memberof SumSubCryptoWallet
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubCryptoWallet
     */
    wallet?: string;
}
/**
 * 
 * @export
 * @interface SumSubDoc
 */
export interface SumSubDoc {
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    idDocSubType?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    idDocType?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    issuedDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    placeOfBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubDoc
     */
    validUntil?: string;
}
/**
 * 
 * @export
 * @interface SumSubDocSetEntry
 */
export interface SumSubDocSetEntry {
    /**
     * 
     * @type {string}
     * @memberof SumSubDocSetEntry
     */
    idDocSetType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SumSubDocSetEntry
     */
    types?: Array<string>;
}
/**
 * 
 * @export
 * @interface SumSubRequiredIdDocs
 */
export interface SumSubRequiredIdDocs {
    /**
     * 
     * @type {Array<SumSubDocSetEntry>}
     * @memberof SumSubRequiredIdDocs
     */
    docSets?: Array<SumSubDocSetEntry>;
}
/**
 * 
 * @export
 * @interface SumSubReview
 */
export interface SumSubReview {
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    applicantId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SumSubReview
     */
    completed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    expireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    inspectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    jobId?: string;
    /**
     * 
     * @type {number}
     * @memberof SumSubReview
     */
    notificationFailureCnt?: number;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    reviewDate?: string;
    /**
     * 
     * @type {SumSubReviewResult}
     * @memberof SumSubReview
     */
    reviewResult?: SumSubReviewResult;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    reviewStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReview
     */
    startDate?: string;
}
/**
 * 
 * @export
 * @interface SumSubReviewResult
 */
export interface SumSubReviewResult {
    /**
     * 
     * @type {string}
     * @memberof SumSubReviewResult
     */
    clientComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SumSubReviewResult
     */
    customTouch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SumSubReviewResult
     */
    moderationComment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SumSubReviewResult
     */
    rejectLabels?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SumSubReviewResult
     */
    reviewAnswer?: string;
    /**
     * 
     * @type {string}
     * @memberof SumSubReviewResult
     */
    reviewRejectType?: string;
}
/**
 * 
 * @export
 * @interface Tariff
 */
export interface Tariff {
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    commission?: number;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    commissionAmountAdditional?: number;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    commissionAmountMax?: number;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    commissionAmountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    commissionPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TariffResponse
 */
export interface TariffResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TariffResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Tariff}
     * @memberof TariffResponse
     */
    tariff?: Tariff;
    /**
     * 
     * @type {ErrorData}
     * @memberof TariffResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Tax
 */
export interface Tax {
    /**
     * 
     * @type {string}
     * @memberof Tax
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Tax
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof Tax
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradeCurrency
 */
export interface TradeCurrency {
    /**
     * 
     * @type {boolean}
     * @memberof TradeCurrency
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeCurrency
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeCurrency
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeCurrency
     */
    symbol?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeCurrency
     */
    test?: boolean;
}
/**
 * 
 * @export
 * @interface TradeCurrencyListResponse
 */
export interface TradeCurrencyListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeCurrencyListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<TradeCurrency>}
     * @memberof TradeCurrencyListResponse
     */
    tradeCurrencyList?: Array<TradeCurrency>;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeCurrencyListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TradeDeal
 */
export interface TradeDeal {
    /**
     * 
     * @type {Date}
     * @memberof TradeDeal
     */
    acceptanceExpireDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TradeDeal
     */
    amount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    buyerAcceptedDeal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    buyerCanceledDeal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    buyerCommitmentFulfilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    buyerConfirmedDeal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    buyerDepositOrderUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    buyerDepositPaid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    buyerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    buyerUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    dealCurrency?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeDeal
     */
    dealQuantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof TradeDeal
     */
    expireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    offerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    ownerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    sellerAcceptedDeal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    sellerCanceledDeal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    sellerCommitmentFulfilled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    sellerConfirmedDeal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    sellerDepositOrderUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeDeal
     */
    sellerDepositPaid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    sellerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    sellerUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    status?: string;
    /**
     * 
     * @type {TradeOffer}
     * @memberof TradeDeal
     */
    tradeOffer?: TradeOffer;
    /**
     * 
     * @type {string}
     * @memberof TradeDeal
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradeDealActionRequest
 */
export interface TradeDealActionRequest {
    /**
     * 
     * @type {string}
     * @memberof TradeDealActionRequest
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDealActionRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradeDealListRequest
 */
export interface TradeDealListRequest {
    /**
     * 
     * @type {Date}
     * @memberof TradeDealListRequest
     */
    acceptanceExpireDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeDealListRequest
     */
    acceptanceExpireDateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof TradeDealListRequest
     */
    emailOrPhone?: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeDealListRequest
     */
    expireDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeDealListRequest
     */
    expireDateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof TradeDealListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeDealListRequest
     */
    rows?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeDealListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDealListRequest
     */
    tradeCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeDealListRequest
     */
    tradeType?: string;
}
/**
 * 
 * @export
 * @interface TradeDealListResponse
 */
export interface TradeDealListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeDealListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<TradeDeal>}
     * @memberof TradeDealListResponse
     */
    tradeDealList?: Array<TradeDeal>;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeDealListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TradeDealRequest
 */
export interface TradeDealRequest {
    /**
     * 
     * @type {TradeDeal}
     * @memberof TradeDealRequest
     */
    tradeDeal?: TradeDeal;
}
/**
 * 
 * @export
 * @interface TradeDealResponse
 */
export interface TradeDealResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeDealResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {TradeDeal}
     * @memberof TradeDealResponse
     */
    tradeDeal?: TradeDeal;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeDealResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TradeDealUidRequest
 */
export interface TradeDealUidRequest {
    /**
     * 
     * @type {string}
     * @memberof TradeDealUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradeOffer
 */
export interface TradeOffer {
    /**
     * 
     * @type {AccountAddress}
     * @memberof TradeOffer
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    amountRestriction?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    currency?: string;
    /**
     * 
     * @type {Date}
     * @memberof TradeOffer
     */
    expireDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    hashtags?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    maxTransactionLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    measurmentUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    minTransactionLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    ownerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    ownerUsername?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    quantityReserved?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOffer
     */
    quantityTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    terms?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    tradeCurrency?: string;
    /**
     * 
     * @type {Array<TradePaymentMethod>}
     * @memberof TradeOffer
     */
    tradePaymentMethodList?: Array<TradePaymentMethod>;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOffer
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradeOfferListRequest
 */
export interface TradeOfferListRequest {
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferListRequest
     */
    expireDateFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TradeOfferListRequest
     */
    expireDateTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    pricePerUnitFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    pricePerUnitTo?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    quantityReservedFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    quantityReservedTo?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    quantityTotalFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    quantityTotalTo?: number;
    /**
     * 
     * @type {number}
     * @memberof TradeOfferListRequest
     */
    rows?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferListRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferListRequest
     */
    tradeCurrency?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeOfferListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface TradeOfferListResponse
 */
export interface TradeOfferListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeOfferListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<TradeOffer>}
     * @memberof TradeOfferListResponse
     */
    tradeOfferList?: Array<TradeOffer>;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeOfferListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TradeOfferRequest
 */
export interface TradeOfferRequest {
    /**
     * 
     * @type {TradeOffer}
     * @memberof TradeOfferRequest
     */
    tradeOffer?: TradeOffer;
}
/**
 * 
 * @export
 * @interface TradeOfferResponse
 */
export interface TradeOfferResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeOfferResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {TradeOffer}
     * @memberof TradeOfferResponse
     */
    tradeOffer?: TradeOffer;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradeOfferResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TradeOfferUidRequest
 */
export interface TradeOfferUidRequest {
    /**
     * 
     * @type {string}
     * @memberof TradeOfferUidRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface TradePaymentMethod
 */
export interface TradePaymentMethod {
    /**
     * 
     * @type {boolean}
     * @memberof TradePaymentMethod
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradePaymentMethod
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TradePaymentMethod
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TradePaymentMethod
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TradePaymentMethodListResponse
 */
export interface TradePaymentMethodListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TradePaymentMethodListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<TradePaymentMethod>}
     * @memberof TradePaymentMethodListResponse
     */
    tradePaymentMethodList?: Array<TradePaymentMethod>;
    /**
     * 
     * @type {ErrorData}
     * @memberof TradePaymentMethodListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    buyerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    buyerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    buyerProfilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    buyerUid?: string;
    /**
     * 
     * @type {CreditCard}
     * @memberof Transaction
     */
    creditCard?: CreditCard;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    creditCardUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    payerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    payerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    payerProfilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    payerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sellerProfilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    sellerUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionAction?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionActionName?: string;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    transactionAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof Transaction
     */
    transactionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionFailCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionState?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionStateName?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionType?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionUid?: string;
}
/**
 * 
 * @export
 * @interface TransactionListResponse
 */
export interface TransactionListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TransactionListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof TransactionListResponse
     */
    transactionList?: Array<Transaction>;
    /**
     * 
     * @type {ErrorData}
     * @memberof TransactionListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TransferOrder
 */
export interface TransferOrder {
    /**
     * 
     * @type {OrderAffiliateInfo}
     * @memberof TransferOrder
     */
    affiliateInfo?: OrderAffiliateInfo;
    /**
     * 
     * @type {boolean}
     * @memberof TransferOrder
     */
    allowPartialPayments?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransferOrder
     */
    amountPayed?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferOrder
     */
    amountTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    batchUid?: string;
    /**
     * 
     * @type {OrderAccount}
     * @memberof TransferOrder
     */
    buyer?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    currencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferOrder
     */
    firstPartialPaymentMinAmountPrc?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransferOrder
     */
    recurring?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TransferOrder
     */
    recurringExpirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof TransferOrder
     */
    recurringMaxAmountPerTrx?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferOrder
     */
    recurringMinIntervalDays?: number;
    /**
     * 
     * @type {OrderAccount}
     * @memberof TransferOrder
     */
    seller?: OrderAccount;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    subscriptionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferOrder
     */
    uid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransferOrder
     */
    useConfirmationCode?: boolean;
}
/**
 * 
 * @export
 * @interface TransferOrderRequest
 */
export interface TransferOrderRequest {
    /**
     * 
     * @type {TransferOrder}
     * @memberof TransferOrderRequest
     */
    order?: TransferOrder;
}
/**
 * 
 * @export
 * @interface TransferOrderResponse
 */
export interface TransferOrderResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TransferOrderResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {TransferOrder}
     * @memberof TransferOrderResponse
     */
    order?: TransferOrder;
    /**
     * 
     * @type {WarningData}
     * @memberof TransferOrderResponse
     */
    warning?: WarningData;
    /**
     * 
     * @type {ErrorData}
     * @memberof TransferOrderResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TransferTransaction
 */
export interface TransferTransaction {
    /**
     * 
     * @type {string}
     * @memberof TransferTransaction
     */
    action?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransaction
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransaction
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransaction
     */
    transactionUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransaction
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface TransferTransactionRequest
 */
export interface TransferTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof TransferTransactionRequest
     */
    confirmationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransactionRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferTransactionRequest
     */
    transactionUid?: string;
}
/**
 * 
 * @export
 * @interface TransferTransactionResponse
 */
export interface TransferTransactionResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof TransferTransactionResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {TransferTransaction}
     * @memberof TransferTransactionResponse
     */
    transaction?: TransferTransaction;
    /**
     * 
     * @type {ErrorData}
     * @memberof TransferTransactionResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface TransformToBusinessAccountRequest
 */
export interface TransformToBusinessAccountRequest {
    /**
     * 
     * @type {BankAccountEditRequest}
     * @memberof TransformToBusinessAccountRequest
     */
    bankAccount?: BankAccountEditRequest;
    /**
     * 
     * @type {AccountAddress}
     * @memberof TransformToBusinessAccountRequest
     */
    companyAddress?: AccountAddress;
    /**
     * 
     * @type {number}
     * @memberof TransformToBusinessAccountRequest
     */
    companyCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    companyCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    companyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    companyName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof TransformToBusinessAccountRequest
     */
    companyPhone?: AccountPhone;
    /**
     * 
     * @type {number}
     * @memberof TransformToBusinessAccountRequest
     */
    companyType?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransformToBusinessAccountRequest
     */
    companyUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    kppNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransformToBusinessAccountRequest
     */
    legalEntities?: boolean;
    /**
     * 
     * @type {AccountAddress}
     * @memberof TransformToBusinessAccountRequest
     */
    officeAddress?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    ownerDocumentName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    registrationNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TransformToBusinessAccountRequest
     */
    responsibleForLoginActions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    taxPayerIn?: string;
    /**
     * 
     * @type {string}
     * @memberof TransformToBusinessAccountRequest
     */
    taxationScheme?: string;
    /**
     * 
     * @type {number}
     * @memberof TransformToBusinessAccountRequest
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @interface UidUploadBody
 */
export interface UidUploadBody {
    /**
     * 
     * @type {string}
     * @memberof UidUploadBody
     */
    base64File?: string;
    /**
     * 
     * @type {string}
     * @memberof UidUploadBody
     */
    fileName?: string;
}
/**
 * 
 * @export
 * @interface UidUploadBody1
 */
export interface UidUploadBody1 {
    /**
     * 
     * @type {string}
     * @memberof UidUploadBody1
     */
    base64File?: string;
    /**
     * 
     * @type {string}
     * @memberof UidUploadBody1
     */
    fileName?: string;
}
/**
 * 
 * @export
 * @interface UpdateAccountPersonRequest
 */
export interface UpdateAccountPersonRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountPersonRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountPersonRequest
     */
    lastName?: string;
}
/**
 * 
 * @export
 * @interface UpdateAccountWithStatusRequest
 */
export interface UpdateAccountWithStatusRequest {
    /**
     * 
     * @type {Date}
     * @memberof UpdateAccountWithStatusRequest
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountWithStatusRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountWithStatusRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountWithStatusRequest
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface UpdateBusinessAccountRequest
 */
export interface UpdateBusinessAccountRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof UpdateBusinessAccountRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    companyCountry?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBusinessAccountRequest
     */
    companyUrls?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    kppNumber?: string;
    /**
     * 
     * @type {AccountAddress}
     * @memberof UpdateBusinessAccountRequest
     */
    officeAddress?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    ownerDocumentName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    ownerName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof UpdateBusinessAccountRequest
     */
    phone?: AccountPhone;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBusinessAccountRequest
     */
    responsibleForLoginActions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    taxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    taxPayerIn?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessAccountRequest
     */
    taxationScheme?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBusinessAccountRequest
     */
    vatRate?: number;
}
/**
 * 
 * @export
 * @interface UpdatePersonalAccountRequest
 */
export interface UpdatePersonalAccountRequest {
    /**
     * 
     * @type {AccountAddress}
     * @memberof UpdatePersonalAccountRequest
     */
    address?: AccountAddress;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    affiliateId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    bannerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdatePersonalAccountRequest
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    campaignId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    customParameters?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    lastName?: string;
    /**
     * 
     * @type {AccountPhone}
     * @memberof UpdatePersonalAccountRequest
     */
    mobilePhone?: AccountPhone;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePersonalAccountRequest
     */
    pep?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePersonalAccountRequest
     */
    privacyPolicy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    profilePictureBase64File?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    profilePictureFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePersonalAccountRequest
     */
    returnAndRefundPolicy?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePersonalAccountRequest
     */
    termsAndConditions?: boolean;
}
/**
 * 
 * @export
 * @interface UploadObjectRequest
 */
export interface UploadObjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadObjectRequest
     */
    base64File?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadObjectRequest
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadObjectRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface VerifyCreditCardlRequest
 */
export interface VerifyCreditCardlRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyCreditCardlRequest
     */
    cardUid?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyCreditCardlRequest
     */
    verificationCode?: string;
}
/**
 * 
 * @export
 * @interface VerifyLinkedCardRequest
 */
export interface VerifyLinkedCardRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyLinkedCardRequest
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyLinkedCardRequest
     */
    verificationCode?: string;
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    contentType?: string;
}
/**
 * 
 * @export
 * @interface Voucher
 */
export interface Voucher {
    /**
     * 
     * @type {Array<string>}
     * @memberof Voucher
     */
    accountStatusList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    accountUid?: string;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    code?: string;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    currencyCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    expirationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    lastModificationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Voucher
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    ownerAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    publicOrderId?: string;
}
/**
 * 
 * @export
 * @interface VoucherActivateRequest
 */
export interface VoucherActivateRequest {
    /**
     * 
     * @type {string}
     * @memberof VoucherActivateRequest
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface VoucherListRequest
 */
export interface VoucherListRequest {
    /**
     * 
     * @type {number}
     * @memberof VoucherListRequest
     */
    rowCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherListRequest
     */
    rowFrom?: number;
}
/**
 * 
 * @export
 * @interface VoucherListResponse
 */
export interface VoucherListResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof VoucherListResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {Array<Voucher>}
     * @memberof VoucherListResponse
     */
    voucherList?: Array<Voucher>;
    /**
     * 
     * @type {ErrorData}
     * @memberof VoucherListResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {number}
     * @memberof Wallet
     */
    balance?: number;
    /**
     * 
     * @type {WalletCurrency}
     * @memberof Wallet
     */
    currency?: WalletCurrency;
    /**
     * 
     * @type {boolean}
     * @memberof Wallet
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface WalletCurrency
 */
export interface WalletCurrency {
    /**
     * 
     * @type {string}
     * @memberof WalletCurrency
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletCurrency
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WalletCurrency
     */
    symbol?: string;
}
/**
 * 
 * @export
 * @interface WalletResponse
 */
export interface WalletResponse {
    /**
     * 
     * @type {ErrorData}
     * @memberof WalletResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {WalletTotalBalance}
     * @memberof WalletResponse
     */
    totalBalance?: WalletTotalBalance;
    /**
     * 
     * @type {Array<Wallet>}
     * @memberof WalletResponse
     */
    walletList?: Array<Wallet>;
    /**
     * 
     * @type {ErrorData}
     * @memberof WalletResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface WalletTotalBalance
 */
export interface WalletTotalBalance {
    /**
     * 
     * @type {number}
     * @memberof WalletTotalBalance
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof WalletTotalBalance
     */
    currency?: string;
}
/**
 * 
 * @export
 * @interface WarningData
 */
export interface WarningData {
    /**
     * 
     * @type {number}
     * @memberof WarningData
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof WarningData
     */
    message?: string;
}
/**
 * AccountAddressWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountAddressWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAddressUsingPUT(body: AccountAddressRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addAddressUsingPUT.');
            }
            const localVarPath = `/account/address`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountAddressRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE(body: AccountAddressRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteAddressUsingDELETE.');
            }
            const localVarPath = `/account/address`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountAddressRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setPrimaryAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryAddressUsingPOST(body: AccountAddressRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setPrimaryAddressUsingPOST.');
            }
            const localVarPath = `/account/address/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountAddressRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressUsingPOST(body: AccountAddressRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAddressUsingPOST.');
            }
            const localVarPath = `/account/address`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountAddressRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountAddressWsControllerApi - functional programming interface
 * @export
 */
export const AccountAddressWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAddressUsingPUT(body: AccountAddressRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountAddressWsControllerApiFetchParamCreator(configuration).addAddressUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE(body: AccountAddressRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountAddressWsControllerApiFetchParamCreator(configuration).deleteAddressUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setPrimaryAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryAddressUsingPOST(body: AccountAddressRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountAddressWsControllerApiFetchParamCreator(configuration).setPrimaryAddressUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressUsingPOST(body: AccountAddressRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountAddressWsControllerApiFetchParamCreator(configuration).updateAddressUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountAddressWsControllerApi - factory interface
 * @export
 */
export const AccountAddressWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAddressUsingPUT(body: AccountAddressRequest, options?: any) {
            return AccountAddressWsControllerApiFp(configuration).addAddressUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE(body: AccountAddressRequest, options?: any) {
            return AccountAddressWsControllerApiFp(configuration).deleteAddressUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setPrimaryAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryAddressUsingPOST(body: AccountAddressRequest, options?: any) {
            return AccountAddressWsControllerApiFp(configuration).setPrimaryAddressUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAddress
         * @param {AccountAddressRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressUsingPOST(body: AccountAddressRequest, options?: any) {
            return AccountAddressWsControllerApiFp(configuration).updateAddressUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountAddressWsControllerApi - object-oriented interface
 * @export
 * @class AccountAddressWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountAddressWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addAddress
     * @param {AccountAddressRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAddressWsControllerApi
     */
    public addAddressUsingPUT(body: AccountAddressRequest, options?: any) {
        return AccountAddressWsControllerApiFp(this.configuration).addAddressUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAddress
     * @param {AccountAddressRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAddressWsControllerApi
     */
    public deleteAddressUsingDELETE(body: AccountAddressRequest, options?: any) {
        return AccountAddressWsControllerApiFp(this.configuration).deleteAddressUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setPrimaryAddress
     * @param {AccountAddressRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAddressWsControllerApi
     */
    public setPrimaryAddressUsingPOST(body: AccountAddressRequest, options?: any) {
        return AccountAddressWsControllerApiFp(this.configuration).setPrimaryAddressUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAddress
     * @param {AccountAddressRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountAddressWsControllerApi
     */
    public updateAddressUsingPOST(body: AccountAddressRequest, options?: any) {
        return AccountAddressWsControllerApiFp(this.configuration).updateAddressUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountDeviceWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountDeviceWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountDeviceUsingPUT(body: AccountDeviceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addAccountDeviceUsingPUT.');
            }
            const localVarPath = `/account/device`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDeviceRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAccountDevice
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountDeviceUsingDELETE(body: AccountDeviceUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteAccountDeviceUsingDELETE.');
            }
            const localVarPath = `/account/device`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDeviceUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountDevices
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDevicesUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountDevicesUsingPOST.');
            }
            const localVarPath = `/account/device/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountDeviceActivity
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceActivityUsingPOST(body: AccountDeviceUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountDeviceActivityUsingPOST.');
            }
            const localVarPath = `/account/device/activity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDeviceUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceUsingPOST(body: AccountDeviceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountDeviceUsingPOST.');
            }
            const localVarPath = `/account/device`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDeviceRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountDeviceWsControllerApi - functional programming interface
 * @export
 */
export const AccountDeviceWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountDeviceUsingPUT(body: AccountDeviceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDeviceResponse> {
            const localVarFetchArgs = AccountDeviceWsControllerApiFetchParamCreator(configuration).addAccountDeviceUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAccountDevice
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountDeviceUsingDELETE(body: AccountDeviceUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDeviceResponse> {
            const localVarFetchArgs = AccountDeviceWsControllerApiFetchParamCreator(configuration).deleteAccountDeviceUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountDevices
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDevicesUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDeviceResponse> {
            const localVarFetchArgs = AccountDeviceWsControllerApiFetchParamCreator(configuration).getAccountDevicesUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountDeviceActivity
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceActivityUsingPOST(body: AccountDeviceUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDeviceResponse> {
            const localVarFetchArgs = AccountDeviceWsControllerApiFetchParamCreator(configuration).updateAccountDeviceActivityUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceUsingPOST(body: AccountDeviceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDeviceResponse> {
            const localVarFetchArgs = AccountDeviceWsControllerApiFetchParamCreator(configuration).updateAccountDeviceUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountDeviceWsControllerApi - factory interface
 * @export
 */
export const AccountDeviceWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccountDeviceUsingPUT(body: AccountDeviceRequest, options?: any) {
            return AccountDeviceWsControllerApiFp(configuration).addAccountDeviceUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAccountDevice
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountDeviceUsingDELETE(body: AccountDeviceUidRequest, options?: any) {
            return AccountDeviceWsControllerApiFp(configuration).deleteAccountDeviceUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountDevices
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDevicesUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return AccountDeviceWsControllerApiFp(configuration).getAccountDevicesUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountDeviceActivity
         * @param {AccountDeviceUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceActivityUsingPOST(body: AccountDeviceUidRequest, options?: any) {
            return AccountDeviceWsControllerApiFp(configuration).updateAccountDeviceActivityUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountDevice
         * @param {AccountDeviceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeviceUsingPOST(body: AccountDeviceRequest, options?: any) {
            return AccountDeviceWsControllerApiFp(configuration).updateAccountDeviceUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountDeviceWsControllerApi - object-oriented interface
 * @export
 * @class AccountDeviceWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountDeviceWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addAccountDevice
     * @param {AccountDeviceRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeviceWsControllerApi
     */
    public addAccountDeviceUsingPUT(body: AccountDeviceRequest, options?: any) {
        return AccountDeviceWsControllerApiFp(this.configuration).addAccountDeviceUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAccountDevice
     * @param {AccountDeviceUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeviceWsControllerApi
     */
    public deleteAccountDeviceUsingDELETE(body: AccountDeviceUidRequest, options?: any) {
        return AccountDeviceWsControllerApiFp(this.configuration).deleteAccountDeviceUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountDevices
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeviceWsControllerApi
     */
    public getAccountDevicesUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return AccountDeviceWsControllerApiFp(this.configuration).getAccountDevicesUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountDeviceActivity
     * @param {AccountDeviceUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeviceWsControllerApi
     */
    public updateAccountDeviceActivityUsingPOST(body: AccountDeviceUidRequest, options?: any) {
        return AccountDeviceWsControllerApiFp(this.configuration).updateAccountDeviceActivityUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountDevice
     * @param {AccountDeviceRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDeviceWsControllerApi
     */
    public updateAccountDeviceUsingPOST(body: AccountDeviceRequest, options?: any) {
        return AccountDeviceWsControllerApiFp(this.configuration).updateAccountDeviceUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountDocumentWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountDocumentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {AccountDocumentRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST(body: AccountDocumentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addUsingPOST.');
            }
            const localVarPath = `/document/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDocumentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(uid: string, filename?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling downloadUsingGET.');
            }
            const localVarPath = `/document/download/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST.');
            }
            const localVarPath = `/document/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload
         * @param {AccountDocumentUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(body: AccountDocumentUploadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling uploadUsingPOST.');
            }
            const localVarPath = `/document/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountDocumentUploadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountDocumentWsControllerApi - functional programming interface
 * @export
 */
export const AccountDocumentWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {AccountDocumentRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST(body: AccountDocumentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDocumentResponse> {
            const localVarFetchArgs = AccountDocumentWsControllerApiFetchParamCreator(configuration).addUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(uid: string, filename?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountDocumentWsControllerApiFetchParamCreator(configuration).downloadUsingGET(uid, filename, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDocumentResponse> {
            const localVarFetchArgs = AccountDocumentWsControllerApiFetchParamCreator(configuration).listUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary upload
         * @param {AccountDocumentUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(body: AccountDocumentUploadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDocumentResponse> {
            const localVarFetchArgs = AccountDocumentWsControllerApiFetchParamCreator(configuration).uploadUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountDocumentWsControllerApi - factory interface
 * @export
 */
export const AccountDocumentWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary add
         * @param {AccountDocumentRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST(body: AccountDocumentRequest, options?: any) {
            return AccountDocumentWsControllerApiFp(configuration).addUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(uid: string, filename?: string, options?: any) {
            return AccountDocumentWsControllerApiFp(configuration).downloadUsingGET(uid, filename, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return AccountDocumentWsControllerApiFp(configuration).listUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary upload
         * @param {AccountDocumentUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(body: AccountDocumentUploadRequest, options?: any) {
            return AccountDocumentWsControllerApiFp(configuration).uploadUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountDocumentWsControllerApi - object-oriented interface
 * @export
 * @class AccountDocumentWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountDocumentWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary add
     * @param {AccountDocumentRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDocumentWsControllerApi
     */
    public addUsingPOST(body: AccountDocumentRequest, options?: any) {
        return AccountDocumentWsControllerApiFp(this.configuration).addUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary download
     * @param {string} uid uid
     * @param {string} [filename] filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDocumentWsControllerApi
     */
    public downloadUsingGET(uid: string, filename?: string, options?: any) {
        return AccountDocumentWsControllerApiFp(this.configuration).downloadUsingGET(uid, filename, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDocumentWsControllerApi
     */
    public listUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return AccountDocumentWsControllerApiFp(this.configuration).listUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary upload
     * @param {AccountDocumentUploadRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountDocumentWsControllerApi
     */
    public uploadUsingPOST(body: AccountDocumentUploadRequest, options?: any) {
        return AccountDocumentWsControllerApiFp(this.configuration).uploadUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountEmailWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountEmailWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmailUsingPUT(body: AccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addEmailUsingPUT.');
            }
            const localVarPath = `/account/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountEmailUsingPOST(body: CheckAccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkAccountEmailUsingPOST.');
            }
            const localVarPath = `/account/check/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CheckAccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkRelatedAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountEmailUsingPOST(body: CheckAccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkRelatedAccountEmailUsingPOST.');
            }
            const localVarPath = `/account/relation/check/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CheckAccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmEmail
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailUsingPOST(code: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling confirmEmailUsingPOST.');
            }
            const localVarPath = `/account/email/confirm/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailUsingDELETE(body: AccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteEmailUsingDELETE.');
            }
            const localVarPath = `/account/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resendAccountActivation
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendAccountActivationUsingPOST(email: string, options: any = {}): FetchArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling resendAccountActivationUsingPOST.');
            }
            const localVarPath = `/account/email/{email}/resend_activation`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setPrimaryEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryEmailUsingPOST(body: AccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setPrimaryEmailUsingPOST.');
            }
            const localVarPath = `/account/email/setprimary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary startEmailConfirmation
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startEmailConfirmationUsingPOST(body: AccountEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling startEmailConfirmationUsingPOST.');
            }
            const localVarPath = `/account/email/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountEmailWsControllerApi - functional programming interface
 * @export
 */
export const AccountEmailWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmailUsingPUT(body: AccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).addEmailUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckAccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).checkAccountEmailUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkRelatedAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckAccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).checkRelatedAccountEmailUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary confirmEmail
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailUsingPOST(code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).confirmEmailUsingPOST(code, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailUsingDELETE(body: AccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).deleteEmailUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resendAccountActivation
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendAccountActivationUsingPOST(email: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).resendAccountActivationUsingPOST(email, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setPrimaryEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryEmailUsingPOST(body: AccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).setPrimaryEmailUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary startEmailConfirmation
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startEmailConfirmationUsingPOST(body: AccountEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountEmailWsControllerApiFetchParamCreator(configuration).startEmailConfirmationUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountEmailWsControllerApi - factory interface
 * @export
 */
export const AccountEmailWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmailUsingPUT(body: AccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).addEmailUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).checkAccountEmailUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkRelatedAccountEmail
         * @param {CheckAccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).checkRelatedAccountEmailUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary confirmEmail
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmEmailUsingPOST(code: string, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).confirmEmailUsingPOST(code, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailUsingDELETE(body: AccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).deleteEmailUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resendAccountActivation
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendAccountActivationUsingPOST(email: string, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).resendAccountActivationUsingPOST(email, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setPrimaryEmail
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryEmailUsingPOST(body: AccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).setPrimaryEmailUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary startEmailConfirmation
         * @param {AccountEmailRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startEmailConfirmationUsingPOST(body: AccountEmailRequest, options?: any) {
            return AccountEmailWsControllerApiFp(configuration).startEmailConfirmationUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountEmailWsControllerApi - object-oriented interface
 * @export
 * @class AccountEmailWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountEmailWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addEmail
     * @param {AccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public addEmailUsingPUT(body: AccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).addEmailUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkAccountEmail
     * @param {CheckAccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public checkAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).checkAccountEmailUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkRelatedAccountEmail
     * @param {CheckAccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public checkRelatedAccountEmailUsingPOST(body: CheckAccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).checkRelatedAccountEmailUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary confirmEmail
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public confirmEmailUsingPOST(code: string, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).confirmEmailUsingPOST(code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteEmail
     * @param {AccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public deleteEmailUsingDELETE(body: AccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).deleteEmailUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resendAccountActivation
     * @param {string} email email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public resendAccountActivationUsingPOST(email: string, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).resendAccountActivationUsingPOST(email, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setPrimaryEmail
     * @param {AccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public setPrimaryEmailUsingPOST(body: AccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).setPrimaryEmailUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary startEmailConfirmation
     * @param {AccountEmailRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountEmailWsControllerApi
     */
    public startEmailConfirmationUsingPOST(body: AccountEmailRequest, options?: any) {
        return AccountEmailWsControllerApiFp(this.configuration).startEmailConfirmationUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountNoticeWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountNoticeWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accountNoticeList
         * @param {AccountNoteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountNoticeListUsingPOST(body: AccountNoteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountNoticeListUsingPOST.');
            }
            const localVarPath = `/account/notice/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountNoteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountNoticeWsControllerApi - functional programming interface
 * @export
 */
export const AccountNoticeWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accountNoticeList
         * @param {AccountNoteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountNoticeListUsingPOST(body: AccountNoteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountNoticeListResponse> {
            const localVarFetchArgs = AccountNoticeWsControllerApiFetchParamCreator(configuration).accountNoticeListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountNoticeWsControllerApi - factory interface
 * @export
 */
export const AccountNoticeWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary accountNoticeList
         * @param {AccountNoteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountNoticeListUsingPOST(body: AccountNoteRequest, options?: any) {
            return AccountNoticeWsControllerApiFp(configuration).accountNoticeListUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountNoticeWsControllerApi - object-oriented interface
 * @export
 * @class AccountNoticeWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountNoticeWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary accountNoticeList
     * @param {AccountNoteRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountNoticeWsControllerApi
     */
    public accountNoticeListUsingPOST(body: AccountNoteRequest, options?: any) {
        return AccountNoticeWsControllerApiFp(this.configuration).accountNoticeListUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountPayoutWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountPayoutWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPayoutSettingsUsingDELETE(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteAccountPayoutSettingsUsingDELETE.');
            }
            const localVarPath = `/account/payout/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPayoutSettingsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountPayoutSettingsUsingPOST.');
            }
            const localVarPath = `/account/payout/settings/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountPayoutSettings
         * @param {AccountPayoutSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPayoutSettingsUsingPOST(body: AccountPayoutSettingsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountPayoutSettingsUsingPOST.');
            }
            const localVarPath = `/account/payout/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPayoutSettingsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountPayoutWsControllerApi - functional programming interface
 * @export
 */
export const AccountPayoutWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPayoutSettingsUsingDELETE(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountPayoutSettingsResponse> {
            const localVarFetchArgs = AccountPayoutWsControllerApiFetchParamCreator(configuration).deleteAccountPayoutSettingsUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPayoutSettingsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountPayoutSettingsResponse> {
            const localVarFetchArgs = AccountPayoutWsControllerApiFetchParamCreator(configuration).getAccountPayoutSettingsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountPayoutSettings
         * @param {AccountPayoutSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPayoutSettingsUsingPOST(body: AccountPayoutSettingsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountPayoutSettingsResponse> {
            const localVarFetchArgs = AccountPayoutWsControllerApiFetchParamCreator(configuration).updateAccountPayoutSettingsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountPayoutWsControllerApi - factory interface
 * @export
 */
export const AccountPayoutWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPayoutSettingsUsingDELETE(body: AbstractAuthorizedRequest, options?: any) {
            return AccountPayoutWsControllerApiFp(configuration).deleteAccountPayoutSettingsUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountPayoutSettings
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPayoutSettingsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return AccountPayoutWsControllerApiFp(configuration).getAccountPayoutSettingsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountPayoutSettings
         * @param {AccountPayoutSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPayoutSettingsUsingPOST(body: AccountPayoutSettingsRequest, options?: any) {
            return AccountPayoutWsControllerApiFp(configuration).updateAccountPayoutSettingsUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountPayoutWsControllerApi - object-oriented interface
 * @export
 * @class AccountPayoutWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountPayoutWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary deleteAccountPayoutSettings
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPayoutWsControllerApi
     */
    public deleteAccountPayoutSettingsUsingDELETE(body: AbstractAuthorizedRequest, options?: any) {
        return AccountPayoutWsControllerApiFp(this.configuration).deleteAccountPayoutSettingsUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountPayoutSettings
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPayoutWsControllerApi
     */
    public getAccountPayoutSettingsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return AccountPayoutWsControllerApiFp(this.configuration).getAccountPayoutSettingsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountPayoutSettings
     * @param {AccountPayoutSettingsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPayoutWsControllerApi
     */
    public updateAccountPayoutSettingsUsingPOST(body: AccountPayoutSettingsRequest, options?: any) {
        return AccountPayoutWsControllerApiFp(this.configuration).updateAccountPayoutSettingsUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountPhoneWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountPhoneWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhoneUsingPUT(body: AccountPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPhoneUsingPUT.');
            }
            const localVarPath = `/account/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegistrationPhoneUsingPUT(body: AccountRegistrationPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addRegistrationPhoneUsingPUT.');
            }
            const localVarPath = `/account/registration/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountRegistrationPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkAccountPhoneUsingPOST.');
            }
            const localVarPath = `/account/check/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountRegistrationPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRegistrationPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkRegistrationPhoneUsingPOST.');
            }
            const localVarPath = `/account/registration/phone/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountRegistrationPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkRelatedAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkRelatedAccountPhoneUsingPOST.');
            }
            const localVarPath = `/account/relation/check/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountRegistrationPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPhoneUsingPOST(body: AccountPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmPhoneUsingPOST.');
            }
            const localVarPath = `/account/phone/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhoneUsingDELETE(body: AccountPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deletePhoneUsingDELETE.');
            }
            const localVarPath = `/account/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setPrimaryPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryPhoneUsingPOST(body: AccountPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setPrimaryPhoneUsingPOST.');
            }
            const localVarPath = `/account/phone/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneUsingPOST(body: AccountPhoneRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePhoneUsingPOST.');
            }
            const localVarPath = `/account/phone`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountPhoneRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountPhoneWsControllerApi - functional programming interface
 * @export
 */
export const AccountPhoneWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhoneUsingPUT(body: AccountPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).addPhoneUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegistrationPhoneUsingPUT(body: AccountRegistrationPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountPhoneResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).addRegistrationPhoneUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckAccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).checkAccountPhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRegistrationPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).checkRegistrationPhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkRelatedAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckAccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).checkRelatedAccountPhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary confirmPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPhoneUsingPOST(body: AccountPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).confirmPhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhoneUsingDELETE(body: AccountPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).deletePhoneUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setPrimaryPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryPhoneUsingPOST(body: AccountPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).setPrimaryPhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updatePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneUsingPOST(body: AccountPhoneRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountPhoneWsControllerApiFetchParamCreator(configuration).updatePhoneUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountPhoneWsControllerApi - factory interface
 * @export
 */
export const AccountPhoneWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPhoneUsingPUT(body: AccountPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).addPhoneUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegistrationPhoneUsingPUT(body: AccountRegistrationPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).addRegistrationPhoneUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).checkAccountPhoneUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkRegistrationPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRegistrationPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).checkRegistrationPhoneUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkRelatedAccountPhone
         * @param {AccountRegistrationPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkRelatedAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).checkRelatedAccountPhoneUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary confirmPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmPhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).confirmPhoneUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePhoneUsingDELETE(body: AccountPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).deletePhoneUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setPrimaryPhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPrimaryPhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).setPrimaryPhoneUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updatePhone
         * @param {AccountPhoneRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
            return AccountPhoneWsControllerApiFp(configuration).updatePhoneUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountPhoneWsControllerApi - object-oriented interface
 * @export
 * @class AccountPhoneWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountPhoneWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addPhone
     * @param {AccountPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public addPhoneUsingPUT(body: AccountPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).addPhoneUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addRegistrationPhone
     * @param {AccountRegistrationPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public addRegistrationPhoneUsingPUT(body: AccountRegistrationPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).addRegistrationPhoneUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkAccountPhone
     * @param {AccountRegistrationPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public checkAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).checkAccountPhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkRegistrationPhone
     * @param {AccountRegistrationPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public checkRegistrationPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).checkRegistrationPhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkRelatedAccountPhone
     * @param {AccountRegistrationPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public checkRelatedAccountPhoneUsingPOST(body: AccountRegistrationPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).checkRelatedAccountPhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary confirmPhone
     * @param {AccountPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public confirmPhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).confirmPhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePhone
     * @param {AccountPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public deletePhoneUsingDELETE(body: AccountPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).deletePhoneUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setPrimaryPhone
     * @param {AccountPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public setPrimaryPhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).setPrimaryPhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updatePhone
     * @param {AccountPhoneRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountPhoneWsControllerApi
     */
    public updatePhoneUsingPOST(body: AccountPhoneRequest, options?: any) {
        return AccountPhoneWsControllerApiFp(this.configuration).updatePhoneUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountQuestionnaireWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountQuestionnaireWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {boolean} [withQuestions] withQuestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET(language?: string, questionnaireUid?: string, withQuestions?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/account/questionnaire`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (questionnaireUid !== undefined) {
                localVarQueryParameter['questionnaireUid'] = questionnaireUid;
            }

            if (withQuestions !== undefined) {
                localVarQueryParameter['withQuestions'] = withQuestions;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setQuestionAnswerOption
         * @param {Array<QuestionAnswer>} body answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuestionAnswerOptionUsingPOST(body: Array<QuestionAnswer>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setQuestionAnswerOptionUsingPOST.');
            }
            const localVarPath = `/account/questionnaire/answer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;QuestionAnswer&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountQuestionnaireWsControllerApi - functional programming interface
 * @export
 */
export const AccountQuestionnaireWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {boolean} [withQuestions] withQuestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET(language?: string, questionnaireUid?: string, withQuestions?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuestionnaireListResponse> {
            const localVarFetchArgs = AccountQuestionnaireWsControllerApiFetchParamCreator(configuration).questionnaireListUsingGET(language, questionnaireUid, withQuestions, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setQuestionAnswerOption
         * @param {Array<QuestionAnswer>} body answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuestionAnswerOptionUsingPOST(body: Array<QuestionAnswer>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = AccountQuestionnaireWsControllerApiFetchParamCreator(configuration).setQuestionAnswerOptionUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountQuestionnaireWsControllerApi - factory interface
 * @export
 */
export const AccountQuestionnaireWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {boolean} [withQuestions] withQuestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET(language?: string, questionnaireUid?: string, withQuestions?: boolean, options?: any) {
            return AccountQuestionnaireWsControllerApiFp(configuration).questionnaireListUsingGET(language, questionnaireUid, withQuestions, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setQuestionAnswerOption
         * @param {Array<QuestionAnswer>} body answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuestionAnswerOptionUsingPOST(body: Array<QuestionAnswer>, options?: any) {
            return AccountQuestionnaireWsControllerApiFp(configuration).setQuestionAnswerOptionUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountQuestionnaireWsControllerApi - object-oriented interface
 * @export
 * @class AccountQuestionnaireWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountQuestionnaireWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary questionnaireList
     * @param {string} [language] language
     * @param {string} [questionnaireUid] questionnaireUid
     * @param {boolean} [withQuestions] withQuestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountQuestionnaireWsControllerApi
     */
    public questionnaireListUsingGET(language?: string, questionnaireUid?: string, withQuestions?: boolean, options?: any) {
        return AccountQuestionnaireWsControllerApiFp(this.configuration).questionnaireListUsingGET(language, questionnaireUid, withQuestions, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setQuestionAnswerOption
     * @param {Array<QuestionAnswer>} body answers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountQuestionnaireWsControllerApi
     */
    public setQuestionAnswerOptionUsingPOST(body: Array<QuestionAnswer>, options?: any) {
        return AccountQuestionnaireWsControllerApiFp(this.configuration).setQuestionAnswerOptionUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountRegionWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountRegionWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeAccountRegion
         * @param {string} region region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRegionUsingPATCH(region: string, options: any = {}): FetchArgs {
            // verify required parameter 'region' is not null or undefined
            if (region === null || region === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling changeAccountRegionUsingPATCH.');
            }
            const localVarPath = `/account/regions/{region}`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmAccountAgreements
         * @param {ConfirmAccountAgreementsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountAgreementsUsingPOST(body: ConfirmAccountAgreementsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmAccountAgreementsUsingPOST.');
            }
            const localVarPath = `/account/regions/agreements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConfirmAccountAgreementsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listRegionAgreements
         * @param {string} region region
         * @param {boolean} [includeConfirmed] includeConfirmed
         * @param {string} [language] language
         * @param {string} [skin] skin
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionAgreementsUsingGET(region: string, includeConfirmed?: boolean, language?: string, skin?: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'region' is not null or undefined
            if (region === null || region === undefined) {
                throw new RequiredError('region','Required parameter region was null or undefined when calling listRegionAgreementsUsingGET.');
            }
            const localVarPath = `/account/regions/{region}/agreements`
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (includeConfirmed !== undefined) {
                localVarQueryParameter['includeConfirmed'] = includeConfirmed;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (skin !== undefined) {
                localVarQueryParameter['skin'] = skin;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionsUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/regions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountRegionWsControllerApi - functional programming interface
 * @export
 */
export const AccountRegionWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary changeAccountRegion
         * @param {string} region region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRegionUsingPATCH(region: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountRegionWsControllerApiFetchParamCreator(configuration).changeAccountRegionUsingPATCH(region, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary confirmAccountAgreements
         * @param {ConfirmAccountAgreementsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountAgreementsUsingPOST(body: ConfirmAccountAgreementsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegionAgreementListResponse> {
            const localVarFetchArgs = AccountRegionWsControllerApiFetchParamCreator(configuration).confirmAccountAgreementsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listRegionAgreements
         * @param {string} region region
         * @param {boolean} [includeConfirmed] includeConfirmed
         * @param {string} [language] language
         * @param {string} [skin] skin
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionAgreementsUsingGET(region: string, includeConfirmed?: boolean, language?: string, skin?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegionAgreementListResponse> {
            const localVarFetchArgs = AccountRegionWsControllerApiFetchParamCreator(configuration).listRegionAgreementsUsingGET(region, includeConfirmed, language, skin, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionsUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegionListResponse> {
            const localVarFetchArgs = AccountRegionWsControllerApiFetchParamCreator(configuration).listRegionsUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountRegionWsControllerApi - factory interface
 * @export
 */
export const AccountRegionWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary changeAccountRegion
         * @param {string} region region
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccountRegionUsingPATCH(region: string, options?: any) {
            return AccountRegionWsControllerApiFp(configuration).changeAccountRegionUsingPATCH(region, options)(fetch, basePath);
        },
        /**
         * 
         * @summary confirmAccountAgreements
         * @param {ConfirmAccountAgreementsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAccountAgreementsUsingPOST(body: ConfirmAccountAgreementsRequest, options?: any) {
            return AccountRegionWsControllerApiFp(configuration).confirmAccountAgreementsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listRegionAgreements
         * @param {string} region region
         * @param {boolean} [includeConfirmed] includeConfirmed
         * @param {string} [language] language
         * @param {string} [skin] skin
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionAgreementsUsingGET(region: string, includeConfirmed?: boolean, language?: string, skin?: string, type?: string, options?: any) {
            return AccountRegionWsControllerApiFp(configuration).listRegionAgreementsUsingGET(region, includeConfirmed, language, skin, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegionsUsingGET(language?: string, options?: any) {
            return AccountRegionWsControllerApiFp(configuration).listRegionsUsingGET(language, options)(fetch, basePath);
        },
    };
};

/**
 * AccountRegionWsControllerApi - object-oriented interface
 * @export
 * @class AccountRegionWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountRegionWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary changeAccountRegion
     * @param {string} region region
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountRegionWsControllerApi
     */
    public changeAccountRegionUsingPATCH(region: string, options?: any) {
        return AccountRegionWsControllerApiFp(this.configuration).changeAccountRegionUsingPATCH(region, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary confirmAccountAgreements
     * @param {ConfirmAccountAgreementsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountRegionWsControllerApi
     */
    public confirmAccountAgreementsUsingPOST(body: ConfirmAccountAgreementsRequest, options?: any) {
        return AccountRegionWsControllerApiFp(this.configuration).confirmAccountAgreementsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listRegionAgreements
     * @param {string} region region
     * @param {boolean} [includeConfirmed] includeConfirmed
     * @param {string} [language] language
     * @param {string} [skin] skin
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountRegionWsControllerApi
     */
    public listRegionAgreementsUsingGET(region: string, includeConfirmed?: boolean, language?: string, skin?: string, type?: string, options?: any) {
        return AccountRegionWsControllerApiFp(this.configuration).listRegionAgreementsUsingGET(region, includeConfirmed, language, skin, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listRegions
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountRegionWsControllerApi
     */
    public listRegionsUsingGET(language?: string, options?: any) {
        return AccountRegionWsControllerApiFp(this.configuration).listRegionsUsingGET(language, options)(this.fetch, this.basePath);
    }

}
/**
 * AccountWsControllerApi - fetch parameter creator
 * @export
 */
export const AccountWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addSettings
         * @param {AccountSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSettingsUsingPOST(body: AccountSettingsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSettingsUsingPOST.');
            }
            const localVarPath = `/account/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountSettingsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {AccountChangePasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(body: AccountChangePasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changePasswordUsingPOST.');
            }
            const localVarPath = `/account/changePassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountChangePasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmActionConfirmations
         * @param {AccountConfirmationSetupConfirmRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActionConfirmationsUsingPOST(body: AccountConfirmationSetupConfirmRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmActionConfirmationsUsingPOST.');
            }
            const localVarPath = `/account/settings/confirmation/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountConfirmationSetupConfirmRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary continueRegistration
         * @param {AccountContinueRegistrationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueRegistrationUsingPOST(body: AccountContinueRegistrationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling continueRegistrationUsingPOST.');
            }
            const localVarPath = `/account/continueRegistration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountContinueRegistrationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createBusinessAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccountUsingPOST(body: CreateBusinessAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createBusinessAccountUsingPOST.');
            }
            const localVarPath = `/account/create/business`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateBusinessAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createMerchantAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchantAccountUsingPOST(body: CreateBusinessAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createMerchantAccountUsingPOST.');
            }
            const localVarPath = `/account/create/merchant`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateBusinessAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPersonalAccount
         * @param {CreatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalAccountUsingPOST(body: CreatePersonalAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPersonalAccountUsingPOST.');
            }
            const localVarPath = `/account/create/personal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreatePersonalAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAccountProfilePicture
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountProfilePictureUsingDELETE(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/update/profilePicture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forgotPassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordUsingPOST(body: AccountForgotPasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling forgotPasswordUsingPOST.');
            }
            const localVarPath = `/account/forgotPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountForgotPasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountDocumentList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDocumentListUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountDocumentListUsingPOST.');
            }
            const localVarPath = `/account/documents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getChildAccountList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildAccountListUsingPOST(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/child/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFullAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullAccountUsingPOST(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/full`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSimpleAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleAccountUsingPOST(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/simple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary oneTimePassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimePasswordUsingPOST(body: AccountForgotPasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling oneTimePasswordUsingPOST.');
            }
            const localVarPath = `/account/oneTimePassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountForgotPasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resendSetupActionConfirmationCode
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSetupActionConfirmationCodeUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resendSetupActionConfirmationCodeUsingPOST.');
            }
            const localVarPath = `/account/settings/confirmation/resend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPassword
         * @param {AccountResetPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(body: AccountResetPasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling resetPasswordUsingPOST.');
            }
            const localVarPath = `/account/resetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountResetPasswordRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setAccountWebResource
         * @param {AccountWebResourceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourceUsingPOST(body: AccountWebResourceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAccountWebResourceUsingPOST.');
            }
            const localVarPath = `/account/webresource`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountWebResourceRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setAccountWebResources
         * @param {AccountWebResourcesRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourcesUsingPOST(body: AccountWebResourcesRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAccountWebResourcesUsingPOST.');
            }
            const localVarPath = `/account/webresources`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountWebResourcesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setConfirmationSettings
         * @param {AccountConfirmationSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfirmationSettingsUsingPOST(body: AccountConfirmationSettingsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setConfirmationSettingsUsingPOST.');
            }
            const localVarPath = `/account/settings/confirmation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountConfirmationSettingsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setupActionConfirmations
         * @param {AccountConfirmationSetupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupActionConfirmationsUsingPOST(body: AccountConfirmationSetupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setupActionConfirmationsUsingPOST.');
            }
            const localVarPath = `/account/settings/confirmation/setup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountConfirmationSetupRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transformAccount
         * @param {TransformToBusinessAccountRequest} body request
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transformAccountUsingPOST(body: TransformToBusinessAccountRequest, type: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transformAccountUsingPOST.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling transformAccountUsingPOST.');
            }
            const localVarPath = `/account/transform/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransformToBusinessAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountLanguage
         * @param {AccountLanguageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLanguageUsingPOST(body: AccountLanguageRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountLanguageUsingPOST.');
            }
            const localVarPath = `/account/language`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountLanguageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountPerson
         * @param {UpdateAccountPersonRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPersonUsingPOST(body: UpdateAccountPersonRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountPersonUsingPOST.');
            }
            const localVarPath = `/account/update/person`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAccountPersonRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountProfilePicture
         * @param {AccountProfilePictureRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfilePictureUsingPOST(body: AccountProfilePictureRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountProfilePictureUsingPOST.');
            }
            const localVarPath = `/account/update/profilePicture`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountProfilePictureRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateAccountWithStatus
         * @param {UpdateAccountWithStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountWithStatusUsingPOST(body: UpdateAccountWithStatusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountWithStatusUsingPOST.');
            }
            const localVarPath = `/account/update/additional`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAccountWithStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateBirthDate
         * @param {AccountBirthDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthDateUsingPOST(body: AccountBirthDateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBirthDateUsingPOST.');
            }
            const localVarPath = `/account/birthdate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountBirthDateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateBusinessAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountUsingPOST(body: UpdateBusinessAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBusinessAccountUsingPOST.');
            }
            const localVarPath = `/account/update/business`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateBusinessAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMerchantAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantAccountUsingPOST(body: UpdateBusinessAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMerchantAccountUsingPOST.');
            }
            const localVarPath = `/account/update/merchant`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateBusinessAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePersonalAccount
         * @param {UpdatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalAccountUsingPOST(body: UpdatePersonalAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePersonalAccountUsingPOST.');
            }
            const localVarPath = `/account/update/personal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdatePersonalAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountWsControllerApi - functional programming interface
 * @export
 */
export const AccountWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addSettings
         * @param {AccountSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSettingsUsingPOST(body: AccountSettingsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).addSettingsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {AccountChangePasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(body: AccountChangePasswordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).changePasswordUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary confirmActionConfirmations
         * @param {AccountConfirmationSetupConfirmRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActionConfirmationsUsingPOST(body: AccountConfirmationSetupConfirmRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountConfirmationStatusResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).confirmActionConfirmationsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary continueRegistration
         * @param {AccountContinueRegistrationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueRegistrationUsingPOST(body: AccountContinueRegistrationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).continueRegistrationUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createBusinessAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).createBusinessAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createMerchantAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchantAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).createMerchantAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createPersonalAccount
         * @param {CreatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalAccountUsingPOST(body: CreatePersonalAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).createPersonalAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAccountProfilePicture
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountProfilePictureUsingDELETE(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).deleteAccountProfilePictureUsingDELETE(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary forgotPassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).forgotPasswordUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountDocumentList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDocumentListUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CorsysAccountDocumentResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).getAccountDocumentListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getChildAccountList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildAccountListUsingPOST(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountListResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).getChildAccountListUsingPOST(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getFullAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullAccountUsingPOST(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).getFullAccountUsingPOST(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSimpleAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleAccountUsingPOST(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).getSimpleAccountUsingPOST(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary oneTimePassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimePasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).oneTimePasswordUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resendSetupActionConfirmationCode
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSetupActionConfirmationCodeUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountConfirmationStatusResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).resendSetupActionConfirmationCodeUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetPassword
         * @param {AccountResetPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(body: AccountResetPasswordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).resetPasswordUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setAccountWebResource
         * @param {AccountWebResourceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourceUsingPOST(body: AccountWebResourceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).setAccountWebResourceUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setAccountWebResources
         * @param {AccountWebResourcesRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourcesUsingPOST(body: AccountWebResourcesRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).setAccountWebResourcesUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setConfirmationSettings
         * @param {AccountConfirmationSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfirmationSettingsUsingPOST(body: AccountConfirmationSettingsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).setConfirmationSettingsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setupActionConfirmations
         * @param {AccountConfirmationSetupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupActionConfirmationsUsingPOST(body: AccountConfirmationSetupRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountConfirmationStatusResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).setupActionConfirmationsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary transformAccount
         * @param {TransformToBusinessAccountRequest} body request
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transformAccountUsingPOST(body: TransformToBusinessAccountRequest, type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).transformAccountUsingPOST(body, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountLanguage
         * @param {AccountLanguageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLanguageUsingPOST(body: AccountLanguageRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateAccountLanguageUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountPerson
         * @param {UpdateAccountPersonRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPersonUsingPOST(body: UpdateAccountPersonRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateAccountPersonUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountProfilePicture
         * @param {AccountProfilePictureRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfilePictureUsingPOST(body: AccountProfilePictureRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateAccountProfilePictureUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateAccountWithStatus
         * @param {UpdateAccountWithStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountWithStatusUsingPOST(body: UpdateAccountWithStatusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateAccountWithStatusUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateBirthDate
         * @param {AccountBirthDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthDateUsingPOST(body: AccountBirthDateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateBirthDateUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateBusinessAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateBusinessAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateMerchantAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updateMerchantAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updatePersonalAccount
         * @param {UpdatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalAccountUsingPOST(body: UpdatePersonalAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountResponse> {
            const localVarFetchArgs = AccountWsControllerApiFetchParamCreator(configuration).updatePersonalAccountUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountWsControllerApi - factory interface
 * @export
 */
export const AccountWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addSettings
         * @param {AccountSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSettingsUsingPOST(body: AccountSettingsRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).addSettingsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changePassword
         * @param {AccountChangePasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(body: AccountChangePasswordRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).changePasswordUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary confirmActionConfirmations
         * @param {AccountConfirmationSetupConfirmRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActionConfirmationsUsingPOST(body: AccountConfirmationSetupConfirmRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).confirmActionConfirmationsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary continueRegistration
         * @param {AccountContinueRegistrationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueRegistrationUsingPOST(body: AccountContinueRegistrationRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).continueRegistrationUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createBusinessAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusinessAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).createBusinessAccountUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createMerchantAccount
         * @param {CreateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMerchantAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).createMerchantAccountUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createPersonalAccount
         * @param {CreatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalAccountUsingPOST(body: CreatePersonalAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).createPersonalAccountUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAccountProfilePicture
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountProfilePictureUsingDELETE(accessToken?: string, options?: any) {
            return AccountWsControllerApiFp(configuration).deleteAccountProfilePictureUsingDELETE(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary forgotPassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).forgotPasswordUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountDocumentList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDocumentListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).getAccountDocumentListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getChildAccountList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildAccountListUsingPOST(accessToken?: string, options?: any) {
            return AccountWsControllerApiFp(configuration).getChildAccountListUsingPOST(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getFullAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullAccountUsingPOST(accessToken?: string, options?: any) {
            return AccountWsControllerApiFp(configuration).getFullAccountUsingPOST(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSimpleAccount
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleAccountUsingPOST(accessToken?: string, options?: any) {
            return AccountWsControllerApiFp(configuration).getSimpleAccountUsingPOST(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary oneTimePassword
         * @param {AccountForgotPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oneTimePasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).oneTimePasswordUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resendSetupActionConfirmationCode
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendSetupActionConfirmationCodeUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).resendSetupActionConfirmationCodeUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetPassword
         * @param {AccountResetPasswordRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(body: AccountResetPasswordRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).resetPasswordUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setAccountWebResource
         * @param {AccountWebResourceRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourceUsingPOST(body: AccountWebResourceRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).setAccountWebResourceUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setAccountWebResources
         * @param {AccountWebResourcesRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAccountWebResourcesUsingPOST(body: AccountWebResourcesRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).setAccountWebResourcesUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setConfirmationSettings
         * @param {AccountConfirmationSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setConfirmationSettingsUsingPOST(body: AccountConfirmationSettingsRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).setConfirmationSettingsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setupActionConfirmations
         * @param {AccountConfirmationSetupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setupActionConfirmationsUsingPOST(body: AccountConfirmationSetupRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).setupActionConfirmationsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary transformAccount
         * @param {TransformToBusinessAccountRequest} body request
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transformAccountUsingPOST(body: TransformToBusinessAccountRequest, type: string, options?: any) {
            return AccountWsControllerApiFp(configuration).transformAccountUsingPOST(body, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountLanguage
         * @param {AccountLanguageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLanguageUsingPOST(body: AccountLanguageRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateAccountLanguageUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountPerson
         * @param {UpdateAccountPersonRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountPersonUsingPOST(body: UpdateAccountPersonRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateAccountPersonUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountProfilePicture
         * @param {AccountProfilePictureRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProfilePictureUsingPOST(body: AccountProfilePictureRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateAccountProfilePictureUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateAccountWithStatus
         * @param {UpdateAccountWithStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountWithStatusUsingPOST(body: UpdateAccountWithStatusRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateAccountWithStatusUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateBirthDate
         * @param {AccountBirthDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBirthDateUsingPOST(body: AccountBirthDateRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateBirthDateUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateBusinessAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateBusinessAccountUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateMerchantAccount
         * @param {UpdateBusinessAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMerchantAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updateMerchantAccountUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updatePersonalAccount
         * @param {UpdatePersonalAccountRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalAccountUsingPOST(body: UpdatePersonalAccountRequest, options?: any) {
            return AccountWsControllerApiFp(configuration).updatePersonalAccountUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountWsControllerApi - object-oriented interface
 * @export
 * @class AccountWsControllerApi
 * @extends {BaseAPI}
 */
export class AccountWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addSettings
     * @param {AccountSettingsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public addSettingsUsingPOST(body: AccountSettingsRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).addSettingsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changePassword
     * @param {AccountChangePasswordRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public changePasswordUsingPOST(body: AccountChangePasswordRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).changePasswordUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary confirmActionConfirmations
     * @param {AccountConfirmationSetupConfirmRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public confirmActionConfirmationsUsingPOST(body: AccountConfirmationSetupConfirmRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).confirmActionConfirmationsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary continueRegistration
     * @param {AccountContinueRegistrationRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public continueRegistrationUsingPOST(body: AccountContinueRegistrationRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).continueRegistrationUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createBusinessAccount
     * @param {CreateBusinessAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public createBusinessAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).createBusinessAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createMerchantAccount
     * @param {CreateBusinessAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public createMerchantAccountUsingPOST(body: CreateBusinessAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).createMerchantAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createPersonalAccount
     * @param {CreatePersonalAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public createPersonalAccountUsingPOST(body: CreatePersonalAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).createPersonalAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAccountProfilePicture
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public deleteAccountProfilePictureUsingDELETE(accessToken?: string, options?: any) {
        return AccountWsControllerApiFp(this.configuration).deleteAccountProfilePictureUsingDELETE(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary forgotPassword
     * @param {AccountForgotPasswordRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public forgotPasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).forgotPasswordUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountDocumentList
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public getAccountDocumentListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).getAccountDocumentListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getChildAccountList
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public getChildAccountListUsingPOST(accessToken?: string, options?: any) {
        return AccountWsControllerApiFp(this.configuration).getChildAccountListUsingPOST(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getFullAccount
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public getFullAccountUsingPOST(accessToken?: string, options?: any) {
        return AccountWsControllerApiFp(this.configuration).getFullAccountUsingPOST(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSimpleAccount
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public getSimpleAccountUsingPOST(accessToken?: string, options?: any) {
        return AccountWsControllerApiFp(this.configuration).getSimpleAccountUsingPOST(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary oneTimePassword
     * @param {AccountForgotPasswordRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public oneTimePasswordUsingPOST(body: AccountForgotPasswordRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).oneTimePasswordUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resendSetupActionConfirmationCode
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public resendSetupActionConfirmationCodeUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).resendSetupActionConfirmationCodeUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetPassword
     * @param {AccountResetPasswordRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public resetPasswordUsingPOST(body: AccountResetPasswordRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).resetPasswordUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setAccountWebResource
     * @param {AccountWebResourceRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public setAccountWebResourceUsingPOST(body: AccountWebResourceRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).setAccountWebResourceUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setAccountWebResources
     * @param {AccountWebResourcesRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public setAccountWebResourcesUsingPOST(body: AccountWebResourcesRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).setAccountWebResourcesUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setConfirmationSettings
     * @param {AccountConfirmationSettingsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public setConfirmationSettingsUsingPOST(body: AccountConfirmationSettingsRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).setConfirmationSettingsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setupActionConfirmations
     * @param {AccountConfirmationSetupRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public setupActionConfirmationsUsingPOST(body: AccountConfirmationSetupRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).setupActionConfirmationsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary transformAccount
     * @param {TransformToBusinessAccountRequest} body request
     * @param {string} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public transformAccountUsingPOST(body: TransformToBusinessAccountRequest, type: string, options?: any) {
        return AccountWsControllerApiFp(this.configuration).transformAccountUsingPOST(body, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountLanguage
     * @param {AccountLanguageRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateAccountLanguageUsingPOST(body: AccountLanguageRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateAccountLanguageUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountPerson
     * @param {UpdateAccountPersonRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateAccountPersonUsingPOST(body: UpdateAccountPersonRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateAccountPersonUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountProfilePicture
     * @param {AccountProfilePictureRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateAccountProfilePictureUsingPOST(body: AccountProfilePictureRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateAccountProfilePictureUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateAccountWithStatus
     * @param {UpdateAccountWithStatusRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateAccountWithStatusUsingPOST(body: UpdateAccountWithStatusRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateAccountWithStatusUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateBirthDate
     * @param {AccountBirthDateRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateBirthDateUsingPOST(body: AccountBirthDateRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateBirthDateUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateBusinessAccount
     * @param {UpdateBusinessAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateBusinessAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateBusinessAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateMerchantAccount
     * @param {UpdateBusinessAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updateMerchantAccountUsingPOST(body: UpdateBusinessAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updateMerchantAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updatePersonalAccount
     * @param {UpdatePersonalAccountRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountWsControllerApi
     */
    public updatePersonalAccountUsingPOST(body: UpdatePersonalAccountRequest, options?: any) {
        return AccountWsControllerApiFp(this.configuration).updatePersonalAccountUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * BankAccountWsControllerApi - fetch parameter creator
 * @export
 */
export const BankAccountWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPUT(body: BankAccountEditRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addUsingPUT.');
            }
            const localVarPath = `/bankaccount/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BankAccountEditRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(body: BankAccountUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST.');
            }
            const localVarPath = `/bankaccount/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BankAccountUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST(body: BankAccountEditRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST.');
            }
            const localVarPath = `/bankaccount/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BankAccountEditRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST(body: BankAccountUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST.');
            }
            const localVarPath = `/bankaccount/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BankAccountUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST1(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST1.');
            }
            const localVarPath = `/bankaccount/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST(body: BankAccountUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAsPrimaryUsingPOST.');
            }
            const localVarPath = `/bankaccount/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BankAccountUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankAccountWsControllerApi - functional programming interface
 * @export
 */
export const BankAccountWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPUT(body: BankAccountEditRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountListResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).addUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary delete
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(body: BankAccountUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountListResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).deleteUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST(body: BankAccountEditRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountListResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).editUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST(body: BankAccountUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).getUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST1(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountListResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).listUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST(body: BankAccountUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BankAccountListResponse> {
            const localVarFetchArgs = BankAccountWsControllerApiFetchParamCreator(configuration).setAsPrimaryUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BankAccountWsControllerApi - factory interface
 * @export
 */
export const BankAccountWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary add
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPUT(body: BankAccountEditRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).addUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary delete
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST(body: BankAccountUidRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).deleteUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {BankAccountEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST(body: BankAccountEditRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).editUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST(body: BankAccountUidRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).getUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST1(body: AbstractAuthorizedRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).listUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {BankAccountUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST(body: BankAccountUidRequest, options?: any) {
            return BankAccountWsControllerApiFp(configuration).setAsPrimaryUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * BankAccountWsControllerApi - object-oriented interface
 * @export
 * @class BankAccountWsControllerApi
 * @extends {BaseAPI}
 */
export class BankAccountWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary add
     * @param {BankAccountEditRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public addUsingPUT(body: BankAccountEditRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).addUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary delete
     * @param {BankAccountUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public deleteUsingPOST(body: BankAccountUidRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).deleteUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {BankAccountEditRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public editUsingPOST(body: BankAccountEditRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).editUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {BankAccountUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public getUsingPOST(body: BankAccountUidRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).getUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public listUsingPOST1(body: AbstractAuthorizedRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).listUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setAsPrimary
     * @param {BankAccountUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountWsControllerApi
     */
    public setAsPrimaryUsingPOST(body: BankAccountUidRequest, options?: any) {
        return BankAccountWsControllerApiFp(this.configuration).setAsPrimaryUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * BasicErrorControllerApi - fetch parameter creator
 * @export
 */
export const BasicErrorControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'HEAD' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'OPTIONS' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingHEAD(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingOPTIONS(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPUT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingHEAD(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingOPTIONS(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPUT(options)(fetch, basePath);
        },
    };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingDELETE(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingGET(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingHEAD(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingHEAD(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingOPTIONS(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingOPTIONS(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPATCH(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPOST(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPUT(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPUT(options)(this.fetch, this.basePath);
    }

}
/**
 * BrandedCardWsControllerApi - fetch parameter creator
 * @export
 */
export const BrandedCardWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activateCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCardUsingPOST(body: BrandedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling activateCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/activate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary blockCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCardUsingPOST(body: BrandedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling blockCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/block`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cardOwnerChange
         * @param {BrandedCardOwnerChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardOwnerChangeUsingPOST(body: BrandedCardOwnerChangeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cardOwnerChangeUsingPOST.');
            }
            const localVarPath = `/brandedcard/change/owner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardOwnerChangeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCard
         * @param {CreateBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardUsingPOST(body: CreateBrandedCardRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateBrandedCardRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPrepaidVirtualCard
         * @param {CreatePrepaidVirtualBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrepaidVirtualCardUsingPOST(body: CreatePrepaidVirtualBrandedCardRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPrepaidVirtualCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/prepaid/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreatePrepaidVirtualBrandedCardRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountApplicationList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountApplicationListUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountApplicationListUsingPOST.');
            }
            const localVarPath = `/brandedcard/application`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountBrandedCardList
         * @param {GetAccountBrandedCardListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountBrandedCardListUsingPOST(body: GetAccountBrandedCardListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountBrandedCardListUsingPOST.');
            }
            const localVarPath = `/brandedcard/balance/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountBrandedCardListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountCardList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCardListUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountCardListUsingPOST.');
            }
            const localVarPath = `/brandedcard/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary suspendCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendCardUsingPOST(body: BrandedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling suspendCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/suspend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary tokenCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCardUsingPOST(body: BrandedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling tokenCardUsingPOST.');
            }
            const localVarPath = `/brandedcard/token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary transfer
         * @param {BrandedCardTransferMoneyRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferUsingPOST(body: BrandedCardTransferMoneyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transferUsingPOST.');
            }
            const localVarPath = `/brandedcard/transfer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardTransferMoneyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandedCardWsControllerApi - functional programming interface
 * @export
 */
export const BrandedCardWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activateCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCardUsingPOST(body: BrandedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCDetailsResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).activateCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary blockCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCardUsingPOST(body: BrandedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCDetailsResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).blockCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary cardOwnerChange
         * @param {BrandedCardOwnerChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardOwnerChangeUsingPOST(body: BrandedCardOwnerChangeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardApplicationResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).cardOwnerChangeUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createCard
         * @param {CreateBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardUsingPOST(body: CreateBrandedCardRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardApplicationResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).createCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createPrepaidVirtualCard
         * @param {CreatePrepaidVirtualBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrepaidVirtualCardUsingPOST(body: CreatePrepaidVirtualBrandedCardRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardApplicationResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).createPrepaidVirtualCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountApplicationList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountApplicationListUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardApplicationListResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).getAccountApplicationListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountBrandedCardList
         * @param {GetAccountBrandedCardListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountBrandedCardListUsingPOST(body: GetAccountBrandedCardListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCFullDetailsListResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).getAccountBrandedCardListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountCardList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCardListUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCFullDetailsListResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).getAccountCardListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary suspendCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendCardUsingPOST(body: BrandedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCDetailsResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).suspendCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary tokenCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCardUsingPOST(body: BrandedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BCDetailsResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).tokenCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary transfer
         * @param {BrandedCardTransferMoneyRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferUsingPOST(body: BrandedCardTransferMoneyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardListResponse> {
            const localVarFetchArgs = BrandedCardWsControllerApiFetchParamCreator(configuration).transferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BrandedCardWsControllerApi - factory interface
 * @export
 */
export const BrandedCardWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary activateCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).activateCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary blockCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).blockCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary cardOwnerChange
         * @param {BrandedCardOwnerChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardOwnerChangeUsingPOST(body: BrandedCardOwnerChangeRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).cardOwnerChangeUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createCard
         * @param {CreateBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardUsingPOST(body: CreateBrandedCardRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).createCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createPrepaidVirtualCard
         * @param {CreatePrepaidVirtualBrandedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPrepaidVirtualCardUsingPOST(body: CreatePrepaidVirtualBrandedCardRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).createPrepaidVirtualCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountApplicationList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountApplicationListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).getAccountApplicationListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountBrandedCardList
         * @param {GetAccountBrandedCardListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountBrandedCardListUsingPOST(body: GetAccountBrandedCardListRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).getAccountBrandedCardListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountCardList
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCardListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).getAccountCardListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary suspendCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suspendCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).suspendCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary tokenCard
         * @param {BrandedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).tokenCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary transfer
         * @param {BrandedCardTransferMoneyRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferUsingPOST(body: BrandedCardTransferMoneyRequest, options?: any) {
            return BrandedCardWsControllerApiFp(configuration).transferUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * BrandedCardWsControllerApi - object-oriented interface
 * @export
 * @class BrandedCardWsControllerApi
 * @extends {BaseAPI}
 */
export class BrandedCardWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary activateCard
     * @param {BrandedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public activateCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).activateCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary blockCard
     * @param {BrandedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public blockCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).blockCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary cardOwnerChange
     * @param {BrandedCardOwnerChangeRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public cardOwnerChangeUsingPOST(body: BrandedCardOwnerChangeRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).cardOwnerChangeUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createCard
     * @param {CreateBrandedCardRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public createCardUsingPOST(body: CreateBrandedCardRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).createCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createPrepaidVirtualCard
     * @param {CreatePrepaidVirtualBrandedCardRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public createPrepaidVirtualCardUsingPOST(body: CreatePrepaidVirtualBrandedCardRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).createPrepaidVirtualCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountApplicationList
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public getAccountApplicationListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).getAccountApplicationListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountBrandedCardList
     * @param {GetAccountBrandedCardListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public getAccountBrandedCardListUsingPOST(body: GetAccountBrandedCardListRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).getAccountBrandedCardListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountCardList
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public getAccountCardListUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).getAccountCardListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary suspendCard
     * @param {BrandedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public suspendCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).suspendCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary tokenCard
     * @param {BrandedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public tokenCardUsingPOST(body: BrandedCardUidRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).tokenCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary transfer
     * @param {BrandedCardTransferMoneyRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandedCardWsControllerApi
     */
    public transferUsingPOST(body: BrandedCardTransferMoneyRequest, options?: any) {
        return BrandedCardWsControllerApiFp(this.configuration).transferUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CacheControllerApi - fetch parameter creator
 * @export
 */
export const CacheControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getInvalidateAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateAllUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/cache/invalidate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInvalidate
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateUsingGET(type: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getInvalidateUsingGET.');
            }
            const localVarPath = `/cache/invalidate/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CacheControllerApi - functional programming interface
 * @export
 */
export const CacheControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getInvalidateAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateAllUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = CacheControllerApiFetchParamCreator(configuration).getInvalidateAllUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getInvalidate
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateUsingGET(type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = CacheControllerApiFetchParamCreator(configuration).getInvalidateUsingGET(type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CacheControllerApi - factory interface
 * @export
 */
export const CacheControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getInvalidateAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateAllUsingGET(options?: any) {
            return CacheControllerApiFp(configuration).getInvalidateAllUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getInvalidate
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvalidateUsingGET(type: string, options?: any) {
            return CacheControllerApiFp(configuration).getInvalidateUsingGET(type, options)(fetch, basePath);
        },
    };
};

/**
 * CacheControllerApi - object-oriented interface
 * @export
 * @class CacheControllerApi
 * @extends {BaseAPI}
 */
export class CacheControllerApi extends BaseAPI {
    /**
     * 
     * @summary getInvalidateAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheControllerApi
     */
    public getInvalidateAllUsingGET(options?: any) {
        return CacheControllerApiFp(this.configuration).getInvalidateAllUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getInvalidate
     * @param {string} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CacheControllerApi
     */
    public getInvalidateUsingGET(type: string, options?: any) {
        return CacheControllerApiFp(this.configuration).getInvalidateUsingGET(type, options)(this.fetch, this.basePath);
    }

}
/**
 * CardWsControllerApi - fetch parameter creator
 * @export
 */
export const CardWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCreditCard
         * @param {CreateCreditCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditCardUsingPOST(body: CreateCreditCardRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCreditCardUsingPOST.');
            }
            const localVarPath = `/creditcard/link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateCreditCardRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountCreditCardList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCreditCardListUsingPOST(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/creditcard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verifyCreditCard
         * @param {VerifyCreditCardlRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCreditCardUsingPOST(body: VerifyCreditCardlRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling verifyCreditCardUsingPOST.');
            }
            const localVarPath = `/creditcard/verify`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VerifyCreditCardlRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardWsControllerApi - functional programming interface
 * @export
 */
export const CardWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCreditCard
         * @param {CreateCreditCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditCardUsingPOST(body: CreateCreditCardRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardResponse> {
            const localVarFetchArgs = CardWsControllerApiFetchParamCreator(configuration).createCreditCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountCreditCardList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCreditCardListUsingPOST(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CardListResponse> {
            const localVarFetchArgs = CardWsControllerApiFetchParamCreator(configuration).getAccountCreditCardListUsingPOST(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary verifyCreditCard
         * @param {VerifyCreditCardlRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCreditCardUsingPOST(body: VerifyCreditCardlRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CardListResponse> {
            const localVarFetchArgs = CardWsControllerApiFetchParamCreator(configuration).verifyCreditCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CardWsControllerApi - factory interface
 * @export
 */
export const CardWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createCreditCard
         * @param {CreateCreditCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditCardUsingPOST(body: CreateCreditCardRequest, options?: any) {
            return CardWsControllerApiFp(configuration).createCreditCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountCreditCardList
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCreditCardListUsingPOST(accessToken?: string, options?: any) {
            return CardWsControllerApiFp(configuration).getAccountCreditCardListUsingPOST(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary verifyCreditCard
         * @param {VerifyCreditCardlRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCreditCardUsingPOST(body: VerifyCreditCardlRequest, options?: any) {
            return CardWsControllerApiFp(configuration).verifyCreditCardUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * CardWsControllerApi - object-oriented interface
 * @export
 * @class CardWsControllerApi
 * @extends {BaseAPI}
 */
export class CardWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary createCreditCard
     * @param {CreateCreditCardRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardWsControllerApi
     */
    public createCreditCardUsingPOST(body: CreateCreditCardRequest, options?: any) {
        return CardWsControllerApiFp(this.configuration).createCreditCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountCreditCardList
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardWsControllerApi
     */
    public getAccountCreditCardListUsingPOST(accessToken?: string, options?: any) {
        return CardWsControllerApiFp(this.configuration).getAccountCreditCardListUsingPOST(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary verifyCreditCard
     * @param {VerifyCreditCardlRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardWsControllerApi
     */
    public verifyCreditCardUsingPOST(body: VerifyCreditCardlRequest, options?: any) {
        return CardWsControllerApiFp(this.configuration).verifyCreditCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ClassificatorWsControllerApi - fetch parameter creator
 * @export
 */
export const ClassificatorWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAccountPhoneTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPhoneTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/AccountPhoneType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatusesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/AccountStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/AccountType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountWebResourceTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWebResourceTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/AccountWebResourceType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAddressTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/AddressType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAvailableLanguage
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableLanguageUsingGET(language: string, options: any = {}): FetchArgs {
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling getAvailableLanguageUsingGET.');
            }
            const localVarPath = `/classificator/AvailableLanguage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBusinessInformationStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessInformationStatusesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/BusinessInformationStatus`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getClassificatorByEntityAndFieldAndLanguage
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldAndLanguageUsingGET(entity: string, field: string, language: string, options: any = {}): FetchArgs {
            // verify required parameter 'entity' is not null or undefined
            if (entity === null || entity === undefined) {
                throw new RequiredError('entity','Required parameter entity was null or undefined when calling getClassificatorByEntityAndFieldAndLanguageUsingGET.');
            }
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling getClassificatorByEntityAndFieldAndLanguageUsingGET.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling getClassificatorByEntityAndFieldAndLanguageUsingGET.');
            }
            const localVarPath = `/classificator/{entity}/{field}/{language}`
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getClassificatorByEntityAndField
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldUsingGET(entity: string, field: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'entity' is not null or undefined
            if (entity === null || entity === undefined) {
                throw new RequiredError('entity','Required parameter entity was null or undefined when calling getClassificatorByEntityAndFieldUsingGET.');
            }
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling getClassificatorByEntityAndFieldUsingGET.');
            }
            const localVarPath = `/classificator/{entity}/{field}`
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanyBusinessTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyBusinessTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/CompanyBusinessType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanyCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCategoriesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/CompanyCategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCompanySubCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySubCategoriesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/CompanySubCategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCountryList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryListUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/Country`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCurrencyList
         * @param {boolean} [active] active
         * @param {string} [language] language
         * @param {boolean} [test] test
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET(active?: boolean, language?: string, test?: boolean, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/Currency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCurrencyRateList
         * @param {string} code code
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyRateListUsingGET(code: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getCurrencyRateListUsingGET.');
            }
            const localVarPath = `/classificator/CurrencyRate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLanguageList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageListUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/Language`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderItemTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderItemTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/OrderItemType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/OrderState`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/OrderType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/PaymentMethod`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPersonTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/PersonType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTransactionBankTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBankTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/TransactionBankType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTransactionBookkeeperTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBookkeeperTransactionTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/TransactionBookkeeperTransactionType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTransactionStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/TransactionState`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTypesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/TransactionType`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassificatorWsControllerApi - functional programming interface
 * @export
 */
export const ClassificatorWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAccountPhoneTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPhoneTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAccountPhoneTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatusesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAccountStatusesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAccountTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountWebResourceTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWebResourceTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAccountWebResourceTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAddressTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAddressTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAvailableLanguage
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableLanguageUsingGET(language: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LanguageListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getAvailableLanguageUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getBusinessInformationStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessInformationStatusesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getBusinessInformationStatusesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getClassificatorByEntityAndFieldAndLanguage
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldAndLanguageUsingGET(entity: string, field: string, language: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getClassificatorByEntityAndFieldAndLanguageUsingGET(entity, field, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getClassificatorByEntityAndField
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldUsingGET(entity: string, field: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getClassificatorByEntityAndFieldUsingGET(entity, field, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCompanyBusinessTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyBusinessTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCompanyBusinessTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCompanyCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCategoriesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCompanyCategoriesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCompanySubCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySubCategoriesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCompanySubCategoriesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCountryList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryListUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CountryListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCountryListUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCurrencyList
         * @param {boolean} [active] active
         * @param {string} [language] language
         * @param {boolean} [test] test
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET(active?: boolean, language?: string, test?: boolean, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrencyListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCurrencyListUsingGET(active, language, test, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCurrencyRateList
         * @param {string} code code
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyRateListUsingGET(code: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrencyRateListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getCurrencyRateListUsingGET(code, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getLanguageList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageListUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LanguageListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getLanguageListUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderItemTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderItemTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getOrderItemTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getOrderStatesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getOrderTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentMethodListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getPaymentMethodListUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPersonTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getPersonTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTransactionBankTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBankTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getTransactionBankTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTransactionBookkeeperTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBookkeeperTransactionTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getTransactionBookkeeperTransactionTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTransactionStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getTransactionStatesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTypesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClassificatorListResponse> {
            const localVarFetchArgs = ClassificatorWsControllerApiFetchParamCreator(configuration).getTransactionTypesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClassificatorWsControllerApi - factory interface
 * @export
 */
export const ClassificatorWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getAccountPhoneTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPhoneTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAccountPhoneTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountStatusesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAccountStatusesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAccountTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountWebResourceTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWebResourceTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAccountWebResourceTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAddressTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAddressTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAvailableLanguage
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableLanguageUsingGET(language: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getAvailableLanguageUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getBusinessInformationStatuses
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessInformationStatusesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getBusinessInformationStatusesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getClassificatorByEntityAndFieldAndLanguage
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} language language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldAndLanguageUsingGET(entity: string, field: string, language: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getClassificatorByEntityAndFieldAndLanguageUsingGET(entity, field, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getClassificatorByEntityAndField
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassificatorByEntityAndFieldUsingGET(entity: string, field: string, language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getClassificatorByEntityAndFieldUsingGET(entity, field, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCompanyBusinessTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyBusinessTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCompanyBusinessTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCompanyCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyCategoriesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCompanyCategoriesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCompanySubCategories
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySubCategoriesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCompanySubCategoriesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCountryList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountryListUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCountryListUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCurrencyList
         * @param {boolean} [active] active
         * @param {string} [language] language
         * @param {boolean} [test] test
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET(active?: boolean, language?: string, test?: boolean, type?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCurrencyListUsingGET(active, language, test, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCurrencyRateList
         * @param {string} code code
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyRateListUsingGET(code: string, type?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getCurrencyRateListUsingGET(code, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getLanguageList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguageListUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getLanguageListUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderItemTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderItemTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getOrderItemTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderStatesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getOrderStatesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getOrderTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getPaymentMethodListUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPersonTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getPersonTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTransactionBankTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBankTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getTransactionBankTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTransactionBookkeeperTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionBookkeeperTransactionTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getTransactionBookkeeperTransactionTypesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTransactionStates
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getTransactionStatesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTransactionTypes
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTypesUsingGET(language?: string, options?: any) {
            return ClassificatorWsControllerApiFp(configuration).getTransactionTypesUsingGET(language, options)(fetch, basePath);
        },
    };
};

/**
 * ClassificatorWsControllerApi - object-oriented interface
 * @export
 * @class ClassificatorWsControllerApi
 * @extends {BaseAPI}
 */
export class ClassificatorWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAccountPhoneTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAccountPhoneTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAccountPhoneTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountStatuses
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAccountStatusesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAccountStatusesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAccountTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAccountTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountWebResourceTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAccountWebResourceTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAccountWebResourceTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAddressTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAddressTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAddressTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAvailableLanguage
     * @param {string} language language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getAvailableLanguageUsingGET(language: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getAvailableLanguageUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getBusinessInformationStatuses
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getBusinessInformationStatusesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getBusinessInformationStatusesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getClassificatorByEntityAndFieldAndLanguage
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} language language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getClassificatorByEntityAndFieldAndLanguageUsingGET(entity: string, field: string, language: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getClassificatorByEntityAndFieldAndLanguageUsingGET(entity, field, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getClassificatorByEntityAndField
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getClassificatorByEntityAndFieldUsingGET(entity: string, field: string, language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getClassificatorByEntityAndFieldUsingGET(entity, field, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCompanyBusinessTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCompanyBusinessTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCompanyBusinessTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCompanyCategories
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCompanyCategoriesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCompanyCategoriesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCompanySubCategories
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCompanySubCategoriesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCompanySubCategoriesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCountryList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCountryListUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCountryListUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCurrencyList
     * @param {boolean} [active] active
     * @param {string} [language] language
     * @param {boolean} [test] test
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCurrencyListUsingGET(active?: boolean, language?: string, test?: boolean, type?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCurrencyListUsingGET(active, language, test, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCurrencyRateList
     * @param {string} code code
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getCurrencyRateListUsingGET(code: string, type?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getCurrencyRateListUsingGET(code, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getLanguageList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getLanguageListUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getLanguageListUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderItemTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getOrderItemTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getOrderItemTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderStates
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getOrderStatesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getOrderStatesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getOrderTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getOrderTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPaymentMethodList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getPaymentMethodListUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getPaymentMethodListUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPersonTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getPersonTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getPersonTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTransactionBankTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getTransactionBankTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getTransactionBankTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTransactionBookkeeperTransactionTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getTransactionBookkeeperTransactionTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getTransactionBookkeeperTransactionTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTransactionStates
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getTransactionStatesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getTransactionStatesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTransactionTypes
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorWsControllerApi
     */
    public getTransactionTypesUsingGET(language?: string, options?: any) {
        return ClassificatorWsControllerApiFp(this.configuration).getTransactionTypesUsingGET(language, options)(this.fetch, this.basePath);
    }

}
/**
 * ContactGroupWsControllerApi - fetch parameter creator
 * @export
 */
export const ContactGroupWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST(body: ContactGroupParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addParticipantUsingPOST.');
            }
            const localVarPath = `/contactgroup/participant/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary add
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST1(body: ContactGroupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addUsingPOST1.');
            }
            const localVarPath = `/contactgroup/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST(body: ContactGroupUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteImageUsingPOST.');
            }
            const localVarPath = `/contactgroup/image/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST(body: ContactGroupParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteParticipantUsingPOST.');
            }
            const localVarPath = `/contactgroup/participant/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(body: ContactGroupUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST1.');
            }
            const localVarPath = `/contactgroup/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST(body: ContactGroupParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editParticipantUsingPOST.');
            }
            const localVarPath = `/contactgroup/participant/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST1(body: ContactGroupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST1.');
            }
            const localVarPath = `/contactgroup/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST1(body: ContactGroupUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST1.');
            }
            const localVarPath = `/contactgroup/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactGroupUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST2(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST2.');
            }
            const localVarPath = `/contactgroup/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(uid: string, base64File?: string, fileName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling uploadUsingPOST1.');
            }
            const localVarPath = `/contactgroup/{uid}/upload`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactGroupWsControllerApi - functional programming interface
 * @export
 */
export const ContactGroupWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).addParticipantUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary add
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST1(body: ContactGroupRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).addUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST(body: ContactGroupUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).deleteImageUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).deleteParticipantUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary delete
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(body: ContactGroupUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).deleteUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).editParticipantUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST1(body: ContactGroupRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).editUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST1(body: ContactGroupUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).getUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST2(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupListResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).listUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(uid: string, base64File?: string, fileName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ContactGroupResponse> {
            const localVarFetchArgs = ContactGroupWsControllerApiFetchParamCreator(configuration).uploadUsingPOST1(uid, base64File, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContactGroupWsControllerApi - factory interface
 * @export
 */
export const ContactGroupWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).addParticipantUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary add
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST1(body: ContactGroupRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).addUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteImage
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST(body: ContactGroupUidRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).deleteImageUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).deleteParticipantUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary delete
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST1(body: ContactGroupUidRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).deleteUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editParticipant
         * @param {ContactGroupParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).editParticipantUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {ContactGroupRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST1(body: ContactGroupRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).editUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {ContactGroupUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST1(body: ContactGroupUidRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).getUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST2(body: AbstractAuthorizedRequest, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).listUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(uid: string, base64File?: string, fileName?: string, options?: any) {
            return ContactGroupWsControllerApiFp(configuration).uploadUsingPOST1(uid, base64File, fileName, options)(fetch, basePath);
        },
    };
};

/**
 * ContactGroupWsControllerApi - object-oriented interface
 * @export
 * @class ContactGroupWsControllerApi
 * @extends {BaseAPI}
 */
export class ContactGroupWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addParticipant
     * @param {ContactGroupParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public addParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).addParticipantUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary add
     * @param {ContactGroupRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public addUsingPOST1(body: ContactGroupRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).addUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteImage
     * @param {ContactGroupUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public deleteImageUsingPOST(body: ContactGroupUidRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).deleteImageUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteParticipant
     * @param {ContactGroupParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public deleteParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).deleteParticipantUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary delete
     * @param {ContactGroupUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public deleteUsingPOST1(body: ContactGroupUidRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).deleteUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editParticipant
     * @param {ContactGroupParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public editParticipantUsingPOST(body: ContactGroupParticipantRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).editParticipantUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {ContactGroupRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public editUsingPOST1(body: ContactGroupRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).editUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {ContactGroupUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public getUsingPOST1(body: ContactGroupUidRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).getUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public listUsingPOST2(body: AbstractAuthorizedRequest, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).listUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary upload
     * @param {string} uid uid
     * @param {string} [base64File] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactGroupWsControllerApi
     */
    public uploadUsingPOST1(uid: string, base64File?: string, fileName?: string, options?: any) {
        return ContactGroupWsControllerApiFp(this.configuration).uploadUsingPOST1(uid, base64File, fileName, options)(this.fetch, this.basePath);
    }

}
/**
 * ConversationWsControllerApi - fetch parameter creator
 * @export
 */
export const ConversationWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change conversation status
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMessageStatusUsingPOST(body: ConversationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changeMessageStatusUsingPOST.');
            }
            const localVarPath = `/conversation/message/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(body: ConversationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUsingPOST.');
            }
            const localVarPath = `/conversation/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST2(body: ConversationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST2.');
            }
            const localVarPath = `/conversation/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get conversation list
         * @param {ConversationListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST3(body: ConversationListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST3.');
            }
            const localVarPath = `/conversation/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: ConversationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUsingPOST.');
            }
            const localVarPath = `/conversation/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ConversationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Conversations webhook
         * @param {string} provider provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookUsingPOST(provider: string, options: any = {}): FetchArgs {
            // verify required parameter 'provider' is not null or undefined
            if (provider === null || provider === undefined) {
                throw new RequiredError('provider','Required parameter provider was null or undefined when calling webhookUsingPOST.');
            }
            const localVarPath = `/conversation/webhook/{provider}`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConversationWsControllerApi - functional programming interface
 * @export
 */
export const ConversationWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change conversation status
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMessageStatusUsingPOST(body: ConversationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResponse> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).changeMessageStatusUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create new conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(body: ConversationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResponse> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).createUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST2(body: ConversationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResponse> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).getUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get conversation list
         * @param {ConversationListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST3(body: ConversationListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationListResponse> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).listUsingPOST3(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: ConversationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConversationResponse> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).updateUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Conversations webhook
         * @param {string} provider provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookUsingPOST(provider: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ConversationWsControllerApiFetchParamCreator(configuration).webhookUsingPOST(provider, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ConversationWsControllerApi - factory interface
 * @export
 */
export const ConversationWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Change conversation status
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMessageStatusUsingPOST(body: ConversationRequest, options?: any) {
            return ConversationWsControllerApiFp(configuration).changeMessageStatusUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create new conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST(body: ConversationRequest, options?: any) {
            return ConversationWsControllerApiFp(configuration).createUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST2(body: ConversationRequest, options?: any) {
            return ConversationWsControllerApiFp(configuration).getUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get conversation list
         * @param {ConversationListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST3(body: ConversationListRequest, options?: any) {
            return ConversationWsControllerApiFp(configuration).listUsingPOST3(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update conversation
         * @param {ConversationRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: ConversationRequest, options?: any) {
            return ConversationWsControllerApiFp(configuration).updateUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Conversations webhook
         * @param {string} provider provider
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webhookUsingPOST(provider: string, options?: any) {
            return ConversationWsControllerApiFp(configuration).webhookUsingPOST(provider, options)(fetch, basePath);
        },
    };
};

/**
 * ConversationWsControllerApi - object-oriented interface
 * @export
 * @class ConversationWsControllerApi
 * @extends {BaseAPI}
 */
export class ConversationWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Change conversation status
     * @param {ConversationRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public changeMessageStatusUsingPOST(body: ConversationRequest, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).changeMessageStatusUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create new conversation
     * @param {ConversationRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public createUsingPOST(body: ConversationRequest, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).createUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get conversation
     * @param {ConversationRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public getUsingPOST2(body: ConversationRequest, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).getUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get conversation list
     * @param {ConversationListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public listUsingPOST3(body: ConversationListRequest, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).listUsingPOST3(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update conversation
     * @param {ConversationRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public updateUsingPOST(body: ConversationRequest, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).updateUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Conversations webhook
     * @param {string} provider provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConversationWsControllerApi
     */
    public webhookUsingPOST(provider: string, options?: any) {
        return ConversationWsControllerApiFp(this.configuration).webhookUsingPOST(provider, options)(this.fetch, this.basePath);
    }

}
/**
 * CouponWsControllerApi - fetch parameter creator
 * @export
 */
export const CouponWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get
         * @param {CouponUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST3(body: CouponUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST3.');
            }
            const localVarPath = `/coupon/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST4(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST4.');
            }
            const localVarPath = `/coupon/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponWsControllerApi - functional programming interface
 * @export
 */
export const CouponWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get
         * @param {CouponUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST3(body: CouponUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponResponse> {
            const localVarFetchArgs = CouponWsControllerApiFetchParamCreator(configuration).getUsingPOST3(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST4(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponListResponse> {
            const localVarFetchArgs = CouponWsControllerApiFetchParamCreator(configuration).listUsingPOST4(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CouponWsControllerApi - factory interface
 * @export
 */
export const CouponWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary get
         * @param {CouponUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST3(body: CouponUidRequest, options?: any) {
            return CouponWsControllerApiFp(configuration).getUsingPOST3(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST4(body: AbstractAuthorizedRequest, options?: any) {
            return CouponWsControllerApiFp(configuration).listUsingPOST4(body, options)(fetch, basePath);
        },
    };
};

/**
 * CouponWsControllerApi - object-oriented interface
 * @export
 * @class CouponWsControllerApi
 * @extends {BaseAPI}
 */
export class CouponWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary get
     * @param {CouponUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponWsControllerApi
     */
    public getUsingPOST3(body: CouponUidRequest, options?: any) {
        return CouponWsControllerApiFp(this.configuration).getUsingPOST3(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponWsControllerApi
     */
    public listUsingPOST4(body: AbstractAuthorizedRequest, options?: any) {
        return CouponWsControllerApiFp(this.configuration).listUsingPOST4(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CreditCardWsControllerApi - fetch parameter creator
 * @export
 */
export const CreditCardWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(body: CreditCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST2.');
            }
            const localVarPath = `/creditcard/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreditCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {CreditCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST2(body: CreditCardEditRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST2.');
            }
            const localVarPath = `/creditcard/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreditCardEditRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST4(body: CreditCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST4.');
            }
            const localVarPath = `/creditcard/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreditCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST5(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST5.');
            }
            const localVarPath = `/creditcard/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST1(body: CreditCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAsPrimaryUsingPOST1.');
            }
            const localVarPath = `/creditcard/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreditCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCardWsControllerApi - functional programming interface
 * @export
 */
export const CreditCardWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(body: CreditCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = CreditCardWsControllerApiFetchParamCreator(configuration).deleteUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {CreditCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST2(body: CreditCardEditRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardResponse> {
            const localVarFetchArgs = CreditCardWsControllerApiFetchParamCreator(configuration).editUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST4(body: CreditCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardResponse> {
            const localVarFetchArgs = CreditCardWsControllerApiFetchParamCreator(configuration).getUsingPOST4(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST5(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardListResponse> {
            const localVarFetchArgs = CreditCardWsControllerApiFetchParamCreator(configuration).listUsingPOST5(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST1(body: CreditCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardResponse> {
            const localVarFetchArgs = CreditCardWsControllerApiFetchParamCreator(configuration).setAsPrimaryUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CreditCardWsControllerApi - factory interface
 * @export
 */
export const CreditCardWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary delete
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST2(body: CreditCardUidRequest, options?: any) {
            return CreditCardWsControllerApiFp(configuration).deleteUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {CreditCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST2(body: CreditCardEditRequest, options?: any) {
            return CreditCardWsControllerApiFp(configuration).editUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST4(body: CreditCardUidRequest, options?: any) {
            return CreditCardWsControllerApiFp(configuration).getUsingPOST4(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST5(body: AbstractAuthorizedRequest, options?: any) {
            return CreditCardWsControllerApiFp(configuration).listUsingPOST5(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {CreditCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST1(body: CreditCardUidRequest, options?: any) {
            return CreditCardWsControllerApiFp(configuration).setAsPrimaryUsingPOST1(body, options)(fetch, basePath);
        },
    };
};

/**
 * CreditCardWsControllerApi - object-oriented interface
 * @export
 * @class CreditCardWsControllerApi
 * @extends {BaseAPI}
 */
export class CreditCardWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary delete
     * @param {CreditCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardWsControllerApi
     */
    public deleteUsingPOST2(body: CreditCardUidRequest, options?: any) {
        return CreditCardWsControllerApiFp(this.configuration).deleteUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {CreditCardEditRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardWsControllerApi
     */
    public editUsingPOST2(body: CreditCardEditRequest, options?: any) {
        return CreditCardWsControllerApiFp(this.configuration).editUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {CreditCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardWsControllerApi
     */
    public getUsingPOST4(body: CreditCardUidRequest, options?: any) {
        return CreditCardWsControllerApiFp(this.configuration).getUsingPOST4(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardWsControllerApi
     */
    public listUsingPOST5(body: AbstractAuthorizedRequest, options?: any) {
        return CreditCardWsControllerApiFp(this.configuration).listUsingPOST5(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setAsPrimary
     * @param {CreditCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardWsControllerApi
     */
    public setAsPrimaryUsingPOST1(body: CreditCardUidRequest, options?: any) {
        return CreditCardWsControllerApiFp(this.configuration).setAsPrimaryUsingPOST1(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DocumentControllerApi - fetch parameter creator
 * @export
 */
export const DocumentControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1(uid: string, filename?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling downloadUsingGET1.');
            }
            const localVarPath = `/operator/document/download/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentControllerApi - functional programming interface
 * @export
 */
export const DocumentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1(uid: string, filename?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DocumentControllerApiFetchParamCreator(configuration).downloadUsingGET1(uid, filename, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DocumentControllerApi - factory interface
 * @export
 */
export const DocumentControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary download
         * @param {string} uid uid
         * @param {string} [filename] filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET1(uid: string, filename?: string, options?: any) {
            return DocumentControllerApiFp(configuration).downloadUsingGET1(uid, filename, options)(fetch, basePath);
        },
    };
};

/**
 * DocumentControllerApi - object-oriented interface
 * @export
 * @class DocumentControllerApi
 * @extends {BaseAPI}
 */
export class DocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary download
     * @param {string} uid uid
     * @param {string} [filename] filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentControllerApi
     */
    public downloadUsingGET1(uid: string, filename?: string, options?: any) {
        return DocumentControllerApiFp(this.configuration).downloadUsingGET1(uid, filename, options)(this.fetch, this.basePath);
    }

}
/**
 * DocumentWsControllerApi - fetch parameter creator
 * @export
 */
export const DocumentWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadDocument
         * @param {string} filename filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentUsingGET(filename: string, options: any = {}): FetchArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling downloadDocumentUsingGET.');
            }
            const localVarPath = `/account/document`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadDocument
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(filename?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/document`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filename !== undefined) {
                localVarFormParams.set('filename', filename as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentWsControllerApi - functional programming interface
 * @export
 */
export const DocumentWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadDocument
         * @param {string} filename filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentUsingGET(filename: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DocumentWsControllerApiFetchParamCreator(configuration).downloadDocumentUsingGET(filename, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadDocument
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(filename?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = DocumentWsControllerApiFetchParamCreator(configuration).uploadDocumentUsingPOST(filename, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DocumentWsControllerApi - factory interface
 * @export
 */
export const DocumentWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadDocument
         * @param {string} filename filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentUsingGET(filename: string, options?: any) {
            return DocumentWsControllerApiFp(configuration).downloadDocumentUsingGET(filename, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadDocument
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentUsingPOST(filename?: string, options?: any) {
            return DocumentWsControllerApiFp(configuration).uploadDocumentUsingPOST(filename, options)(fetch, basePath);
        },
    };
};

/**
 * DocumentWsControllerApi - object-oriented interface
 * @export
 * @class DocumentWsControllerApi
 * @extends {BaseAPI}
 */
export class DocumentWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadDocument
     * @param {string} filename filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentWsControllerApi
     */
    public downloadDocumentUsingGET(filename: string, options?: any) {
        return DocumentWsControllerApiFp(this.configuration).downloadDocumentUsingGET(filename, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadDocument
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentWsControllerApi
     */
    public uploadDocumentUsingPOST(filename?: string, options?: any) {
        return DocumentWsControllerApiFp(this.configuration).uploadDocumentUsingPOST(filename, options)(this.fetch, this.basePath);
    }

}
/**
 * GoalWsControllerApi - fetch parameter creator
 * @export
 */
export const GoalWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST2(body: GoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addUsingPOST2.');
            }
            const localVarPath = `/goal/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(body: GoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST3.');
            }
            const localVarPath = `/goal/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST3(body: GoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST3.');
            }
            const localVarPath = `/goal/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST5(body: GoalRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST5.');
            }
            const localVarPath = `/goal/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoalRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST6(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST6.');
            }
            const localVarPath = `/goal/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GoalWsControllerApi - functional programming interface
 * @export
 */
export const GoalWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST2(body: GoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalResponse> {
            const localVarFetchArgs = GoalWsControllerApiFetchParamCreator(configuration).addUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary delete
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(body: GoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = GoalWsControllerApiFetchParamCreator(configuration).deleteUsingPOST3(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST3(body: GoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalResponse> {
            const localVarFetchArgs = GoalWsControllerApiFetchParamCreator(configuration).editUsingPOST3(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST5(body: GoalRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalResponse> {
            const localVarFetchArgs = GoalWsControllerApiFetchParamCreator(configuration).getUsingPOST5(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST6(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoalListResponse> {
            const localVarFetchArgs = GoalWsControllerApiFetchParamCreator(configuration).listUsingPOST6(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GoalWsControllerApi - factory interface
 * @export
 */
export const GoalWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary add
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST2(body: GoalRequest, options?: any) {
            return GoalWsControllerApiFp(configuration).addUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary delete
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST3(body: GoalRequest, options?: any) {
            return GoalWsControllerApiFp(configuration).deleteUsingPOST3(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST3(body: GoalRequest, options?: any) {
            return GoalWsControllerApiFp(configuration).editUsingPOST3(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {GoalRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST5(body: GoalRequest, options?: any) {
            return GoalWsControllerApiFp(configuration).getUsingPOST5(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST6(body: AbstractAuthorizedRequest, options?: any) {
            return GoalWsControllerApiFp(configuration).listUsingPOST6(body, options)(fetch, basePath);
        },
    };
};

/**
 * GoalWsControllerApi - object-oriented interface
 * @export
 * @class GoalWsControllerApi
 * @extends {BaseAPI}
 */
export class GoalWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary add
     * @param {GoalRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalWsControllerApi
     */
    public addUsingPOST2(body: GoalRequest, options?: any) {
        return GoalWsControllerApiFp(this.configuration).addUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary delete
     * @param {GoalRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalWsControllerApi
     */
    public deleteUsingPOST3(body: GoalRequest, options?: any) {
        return GoalWsControllerApiFp(this.configuration).deleteUsingPOST3(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {GoalRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalWsControllerApi
     */
    public editUsingPOST3(body: GoalRequest, options?: any) {
        return GoalWsControllerApiFp(this.configuration).editUsingPOST3(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {GoalRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalWsControllerApi
     */
    public getUsingPOST5(body: GoalRequest, options?: any) {
        return GoalWsControllerApiFp(this.configuration).getUsingPOST5(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GoalWsControllerApi
     */
    public listUsingPOST6(body: AbstractAuthorizedRequest, options?: any) {
        return GoalWsControllerApiFp(this.configuration).listUsingPOST6(body, options)(this.fetch, this.basePath);
    }

}
/**
 * LinkedCardWsControllerApi - fetch parameter creator
 * @export
 */
export const LinkedCardWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(body: LinkedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST4.');
            }
            const localVarPath = `/linkedcard/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LinkedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {LinkedCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST4(body: LinkedCardEditRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST4.');
            }
            const localVarPath = `/linkedcard/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LinkedCardEditRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST6(body: LinkedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST6.');
            }
            const localVarPath = `/linkedcard/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LinkedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST7(accessToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/linkedcard/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST2(body: LinkedCardUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setAsPrimaryUsingPOST2.');
            }
            const localVarPath = `/linkedcard/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LinkedCardUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary verify
         * @param {VerifyLinkedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUsingPOST(body: VerifyLinkedCardRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling verifyUsingPOST.');
            }
            const localVarPath = `/linkedcard/verify`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VerifyLinkedCardRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkedCardWsControllerApi - functional programming interface
 * @export
 */
export const LinkedCardWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(body: LinkedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardListResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).deleteUsingPOST4(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {LinkedCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST4(body: LinkedCardEditRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardListResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).editUsingPOST4(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST6(body: LinkedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).getUsingPOST6(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST7(accessToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardListResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).listUsingPOST7(accessToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST2(body: LinkedCardUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardListResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).setAsPrimaryUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary verify
         * @param {VerifyLinkedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUsingPOST(body: VerifyLinkedCardRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LinkedCardListResponse> {
            const localVarFetchArgs = LinkedCardWsControllerApiFetchParamCreator(configuration).verifyUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LinkedCardWsControllerApi - factory interface
 * @export
 */
export const LinkedCardWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary delete
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST4(body: LinkedCardUidRequest, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).deleteUsingPOST4(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {LinkedCardEditRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST4(body: LinkedCardEditRequest, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).editUsingPOST4(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST6(body: LinkedCardUidRequest, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).getUsingPOST6(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {string} [accessToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST7(accessToken?: string, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).listUsingPOST7(accessToken, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setAsPrimary
         * @param {LinkedCardUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAsPrimaryUsingPOST2(body: LinkedCardUidRequest, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).setAsPrimaryUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary verify
         * @param {VerifyLinkedCardRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyUsingPOST(body: VerifyLinkedCardRequest, options?: any) {
            return LinkedCardWsControllerApiFp(configuration).verifyUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * LinkedCardWsControllerApi - object-oriented interface
 * @export
 * @class LinkedCardWsControllerApi
 * @extends {BaseAPI}
 */
export class LinkedCardWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary delete
     * @param {LinkedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public deleteUsingPOST4(body: LinkedCardUidRequest, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).deleteUsingPOST4(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {LinkedCardEditRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public editUsingPOST4(body: LinkedCardEditRequest, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).editUsingPOST4(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {LinkedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public getUsingPOST6(body: LinkedCardUidRequest, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).getUsingPOST6(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {string} [accessToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public listUsingPOST7(accessToken?: string, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).listUsingPOST7(accessToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setAsPrimary
     * @param {LinkedCardUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public setAsPrimaryUsingPOST2(body: LinkedCardUidRequest, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).setAsPrimaryUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary verify
     * @param {VerifyLinkedCardRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkedCardWsControllerApi
     */
    public verifyUsingPOST(body: VerifyLinkedCardRequest, options?: any) {
        return LinkedCardWsControllerApiFp(this.configuration).verifyUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * MessageWsControllerApi - fetch parameter creator
 * @export
 */
export const MessageWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMessage
         * @param {string} code code
         * @param {string} [languageCode] languageCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET(code: string, languageCode?: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling getMessageUsingGET.');
            }
            const localVarPath = `/message/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMessage
         * @param {MessageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingPOST(body: MessageRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getMessageUsingPOST.');
            }
            const localVarPath = `/message`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MessageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageWsControllerApi - functional programming interface
 * @export
 */
export const MessageWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMessage
         * @param {string} code code
         * @param {string} [languageCode] languageCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET(code: string, languageCode?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResponse> {
            const localVarFetchArgs = MessageWsControllerApiFetchParamCreator(configuration).getMessageUsingGET(code, languageCode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMessage
         * @param {MessageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingPOST(body: MessageRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MessageResponse> {
            const localVarFetchArgs = MessageWsControllerApiFetchParamCreator(configuration).getMessageUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MessageWsControllerApi - factory interface
 * @export
 */
export const MessageWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getMessage
         * @param {string} code code
         * @param {string} [languageCode] languageCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingGET(code: string, languageCode?: string, options?: any) {
            return MessageWsControllerApiFp(configuration).getMessageUsingGET(code, languageCode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMessage
         * @param {MessageRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageUsingPOST(body: MessageRequest, options?: any) {
            return MessageWsControllerApiFp(configuration).getMessageUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * MessageWsControllerApi - object-oriented interface
 * @export
 * @class MessageWsControllerApi
 * @extends {BaseAPI}
 */
export class MessageWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getMessage
     * @param {string} code code
     * @param {string} [languageCode] languageCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageWsControllerApi
     */
    public getMessageUsingGET(code: string, languageCode?: string, options?: any) {
        return MessageWsControllerApiFp(this.configuration).getMessageUsingGET(code, languageCode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMessage
     * @param {MessageRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageWsControllerApi
     */
    public getMessageUsingPOST(body: MessageRequest, options?: any) {
        return MessageWsControllerApiFp(this.configuration).getMessageUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * MoneyRequestWsControllerApi - fetch parameter creator
 * @export
 */
export const MoneyRequestWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addParticipantUsingPOST1.');
            }
            const localVarPath = `/moneyrequest/participant/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {MoneyRequestStatusChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(body: MoneyRequestStatusChangeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changeStatusUsingPOST.');
            }
            const localVarPath = `/moneyrequest/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestStatusChangeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create
         * @param {MoneyRequestRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1(body: MoneyRequestRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUsingPOST1.');
            }
            const localVarPath = `/moneyrequest/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {MoneyRequestImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST1(body: MoneyRequestImageDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteImageUsingPOST1.');
            }
            const localVarPath = `/moneyrequest/image/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestImageDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteParticipantUsingPOST1.');
            }
            const localVarPath = `/moneyrequest/participant/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editParticipantUsingPOST1.');
            }
            const localVarPath = `/moneyrequest/participant/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestParticipantRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMoneyRequestOwnerCreditCard
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoneyRequestOwnerCreditCardUsingPOST(body: MoneyRequestUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getMoneyRequestOwnerCreditCardUsingPOST.');
            }
            const localVarPath = `/moneyrequest/owner/card`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST7(body: MoneyRequestUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST7.');
            }
            const localVarPath = `/moneyrequest/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {MoneyRequestListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST8(body: MoneyRequestListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST8.');
            }
            const localVarPath = `/moneyrequest/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadImage
         * @param {MoneyRequestImageUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST(body: MoneyRequestImageUploadRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling uploadImageUsingPOST.');
            }
            const localVarPath = `/moneyrequest/image/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoneyRequestImageUploadRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MoneyRequestWsControllerApi - functional programming interface
 * @export
 */
export const MoneyRequestWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).addParticipantUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {MoneyRequestStatusChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(body: MoneyRequestStatusChangeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).changeStatusUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary create
         * @param {MoneyRequestRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1(body: MoneyRequestRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).createUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {MoneyRequestImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST1(body: MoneyRequestImageDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).deleteImageUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).deleteParticipantUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).editParticipantUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMoneyRequestOwnerCreditCard
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoneyRequestOwnerCreditCardUsingPOST(body: MoneyRequestUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CreditCardResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).getMoneyRequestOwnerCreditCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST7(body: MoneyRequestUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).getUsingPOST7(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {MoneyRequestListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST8(body: MoneyRequestListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestListResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).listUsingPOST8(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadImage
         * @param {MoneyRequestImageUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST(body: MoneyRequestImageUploadRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MoneyRequestResponse> {
            const localVarFetchArgs = MoneyRequestWsControllerApiFetchParamCreator(configuration).uploadImageUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MoneyRequestWsControllerApi - factory interface
 * @export
 */
export const MoneyRequestWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).addParticipantUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeStatus
         * @param {MoneyRequestStatusChangeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST(body: MoneyRequestStatusChangeRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).changeStatusUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary create
         * @param {MoneyRequestRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPOST1(body: MoneyRequestRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).createUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteImage
         * @param {MoneyRequestImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST1(body: MoneyRequestImageDeleteRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).deleteImageUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).deleteParticipantUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editParticipant
         * @param {MoneyRequestParticipantRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).editParticipantUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMoneyRequestOwnerCreditCard
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoneyRequestOwnerCreditCardUsingPOST(body: MoneyRequestUidRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).getMoneyRequestOwnerCreditCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {MoneyRequestUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST7(body: MoneyRequestUidRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).getUsingPOST7(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {MoneyRequestListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST8(body: MoneyRequestListRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).listUsingPOST8(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadImage
         * @param {MoneyRequestImageUploadRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST(body: MoneyRequestImageUploadRequest, options?: any) {
            return MoneyRequestWsControllerApiFp(configuration).uploadImageUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * MoneyRequestWsControllerApi - object-oriented interface
 * @export
 * @class MoneyRequestWsControllerApi
 * @extends {BaseAPI}
 */
export class MoneyRequestWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addParticipant
     * @param {MoneyRequestParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public addParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).addParticipantUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeStatus
     * @param {MoneyRequestStatusChangeRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public changeStatusUsingPOST(body: MoneyRequestStatusChangeRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).changeStatusUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary create
     * @param {MoneyRequestRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public createUsingPOST1(body: MoneyRequestRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).createUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteImage
     * @param {MoneyRequestImageDeleteRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public deleteImageUsingPOST1(body: MoneyRequestImageDeleteRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).deleteImageUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteParticipant
     * @param {MoneyRequestParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public deleteParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).deleteParticipantUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editParticipant
     * @param {MoneyRequestParticipantRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public editParticipantUsingPOST1(body: MoneyRequestParticipantRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).editParticipantUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMoneyRequestOwnerCreditCard
     * @param {MoneyRequestUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public getMoneyRequestOwnerCreditCardUsingPOST(body: MoneyRequestUidRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).getMoneyRequestOwnerCreditCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {MoneyRequestUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public getUsingPOST7(body: MoneyRequestUidRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).getUsingPOST7(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {MoneyRequestListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public listUsingPOST8(body: MoneyRequestListRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).listUsingPOST8(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadImage
     * @param {MoneyRequestImageUploadRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoneyRequestWsControllerApi
     */
    public uploadImageUsingPOST(body: MoneyRequestImageUploadRequest, options?: any) {
        return MoneyRequestWsControllerApiFp(this.configuration).uploadImageUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * OrderSuggestionWsControllerApi - fetch parameter creator
 * @export
 */
export const OrderSuggestionWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary latestContacts
         * @param {OrderLatestContactsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestContactsUsingPOST(body: OrderLatestContactsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling latestContactsUsingPOST.');
            }
            const localVarPath = `/order/suggestion/contacts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderLatestContactsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary latestItems
         * @param {OrderLatestItemsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestItemsUsingPOST(body: OrderLatestItemsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling latestItemsUsingPOST.');
            }
            const localVarPath = `/order/suggestion/items`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderLatestItemsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderSuggestionWsControllerApi - functional programming interface
 * @export
 */
export const OrderSuggestionWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary latestContacts
         * @param {OrderLatestContactsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestContactsUsingPOST(body: OrderLatestContactsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderLatestContactsResponse> {
            const localVarFetchArgs = OrderSuggestionWsControllerApiFetchParamCreator(configuration).latestContactsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary latestItems
         * @param {OrderLatestItemsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestItemsUsingPOST(body: OrderLatestItemsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderLatestItemsResponse> {
            const localVarFetchArgs = OrderSuggestionWsControllerApiFetchParamCreator(configuration).latestItemsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderSuggestionWsControllerApi - factory interface
 * @export
 */
export const OrderSuggestionWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary latestContacts
         * @param {OrderLatestContactsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestContactsUsingPOST(body: OrderLatestContactsRequest, options?: any) {
            return OrderSuggestionWsControllerApiFp(configuration).latestContactsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary latestItems
         * @param {OrderLatestItemsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        latestItemsUsingPOST(body: OrderLatestItemsRequest, options?: any) {
            return OrderSuggestionWsControllerApiFp(configuration).latestItemsUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * OrderSuggestionWsControllerApi - object-oriented interface
 * @export
 * @class OrderSuggestionWsControllerApi
 * @extends {BaseAPI}
 */
export class OrderSuggestionWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary latestContacts
     * @param {OrderLatestContactsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSuggestionWsControllerApi
     */
    public latestContactsUsingPOST(body: OrderLatestContactsRequest, options?: any) {
        return OrderSuggestionWsControllerApiFp(this.configuration).latestContactsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary latestItems
     * @param {OrderLatestItemsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderSuggestionWsControllerApi
     */
    public latestItemsUsingPOST(body: OrderLatestItemsRequest, options?: any) {
        return OrderSuggestionWsControllerApiFp(this.configuration).latestItemsUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * OrderWsControllerApi - fetch parameter creator
 * @export
 */
export const OrderWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOrderUsingPOST(body: CouponOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCouponOrderUsingPOST.');
            }
            const localVarPath = `/order/coupon`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCurrencyConversionOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCurrencyConversionOrderUsingPOST.');
            }
            const localVarPath = `/order/currency`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepositOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createDepositOrderUsingPOST.');
            }
            const localVarPath = `/order/deposit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangerOrderUsingPOST(body: ExchangerOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createExchangerOrderUsingPOST.');
            }
            const localVarPath = `/order/exchanger`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExchangerOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createLinkCardOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCardOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createLinkCardOrderUsingPOST.');
            }
            const localVarPath = `/order/linkCard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferOrderUsingPOST(body: TransferOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTransferOrderUsingPOST.');
            }
            const localVarPath = `/order/transfer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransferOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWithdrawalOrderUsingPOST.');
            }
            const localVarPath = `/order/withdrawal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWithdrawalToIssuedCardUsingPOST.');
            }
            const localVarPath = `/order/withdrawal/issuedcard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraftOrderUsingPOST(body: OrderDraftRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteDraftOrderUsingPOST.');
            }
            const localVarPath = `/order/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderDraftRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCorsysOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderListUsingPOST(body: OrderListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getCorsysOrderListUsingPOST.');
            }
            const localVarPath = `/order/corsys/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCorsysOrder
         * @param {GetOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderUsingPOST(body: GetOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getCorsysOrderUsingPOST.');
            }
            const localVarPath = `/order/corsys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderListUsingPOST(body: OrderListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getOrderListUsingPOST.');
            }
            const localVarPath = `/order`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderPaymentMethods
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderPaymentMethodsUsingGET(orderUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderUid' is not null or undefined
            if (orderUid === null || orderUid === undefined) {
                throw new RequiredError('orderUid','Required parameter orderUid was null or undefined when calling getOrderPaymentMethodsUsingGET.');
            }
            const localVarPath = `/order/{orderUid}/paymentmethods`
                .replace(`{${"orderUid"}}`, encodeURIComponent(String(orderUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderQRCode
         * @param {string} orderUid orderUid
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderQRCodeUsingGET(orderUid: string, size?: number, options: any = {}): FetchArgs {
            // verify required parameter 'orderUid' is not null or undefined
            if (orderUid === null || orderUid === undefined) {
                throw new RequiredError('orderUid','Required parameter orderUid was null or undefined when calling getOrderQRCodeUsingGET.');
            }
            const localVarPath = `/order/qrcode/{orderUid}`
                .replace(`{${"orderUid"}}`, encodeURIComponent(String(orderUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderTypePaymentMethods
         * @param {string} orderType orderType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypePaymentMethodsUsingGET(orderType: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderType' is not null or undefined
            if (orderType === null || orderType === undefined) {
                throw new RequiredError('orderType','Required parameter orderType was null or undefined when calling getOrderTypePaymentMethodsUsingGET.');
            }
            const localVarPath = `/order/type/{orderType}/paymentmethods`
                .replace(`{${"orderType"}}`, encodeURIComponent(String(orderType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderUid' is not null or undefined
            if (orderUid === null || orderUid === undefined) {
                throw new RequiredError('orderUid','Required parameter orderUid was null or undefined when calling getOrderUsingGET.');
            }
            const localVarPath = `/order/{orderUid}`
                .replace(`{${"orderUid"}}`, encodeURIComponent(String(orderUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDraftOrderUsingPOST(body: OrderDraftRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendDraftOrderUsingPOST.');
            }
            const localVarPath = `/order/send`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderDraftRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponOrderUsingPOST(body: CouponOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCouponOrderUsingPOST.');
            }
            const localVarPath = `/order/coupon/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CouponOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrencyConversionOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCurrencyConversionOrderUsingPOST.');
            }
            const localVarPath = `/order/currency/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepositOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateDepositOrderUsingPOST.');
            }
            const localVarPath = `/order/deposit/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangerOrderUsingPOST(body: ExchangerOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateExchangerOrderUsingPOST.');
            }
            const localVarPath = `/order/exchanger/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExchangerOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferOrderUsingPOST(body: TransferOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTransferOrderUsingPOST.');
            }
            const localVarPath = `/order/transfer/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransferOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalOrderUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWithdrawalOrderUsingPOST.');
            }
            const localVarPath = `/order/withdrawal/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateWithdrawalToIssuedCardUsingPOST.');
            }
            const localVarPath = `/order/withdrawal/issuedcard/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderWsControllerApi - functional programming interface
 * @export
 */
export const OrderWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOrderUsingPOST(body: CouponOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createCouponOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createCurrencyConversionOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepositOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createDepositOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangerOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createExchangerOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createLinkCardOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCardOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createLinkCardOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferOrderUsingPOST(body: TransferOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransferOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createTransferOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createWithdrawalOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).createWithdrawalToIssuedCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraftOrderUsingPOST(body: OrderDraftRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).deleteDraftOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCorsysOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderListUsingPOST(body: OrderListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CorsysOrderListResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getCorsysOrderListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCorsysOrder
         * @param {GetOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderUsingPOST(body: GetOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CorsysOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getCorsysOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderListUsingPOST(body: OrderListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangerOrderListResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderPaymentMethods
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderPaymentMethodsUsingGET(orderUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentMethodListResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderPaymentMethodsUsingGET(orderUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderQRCode
         * @param {string} orderUid orderUid
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderQRCodeUsingGET(orderUid: string, size?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderQRCodeUsingGET(orderUid, size, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderTypePaymentMethods
         * @param {string} orderType orderType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypePaymentMethodsUsingGET(orderType: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentMethodListResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderTypePaymentMethodsUsingGET(orderType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangerOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).getOrderUsingGET(orderUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary sendDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDraftOrderUsingPOST(body: OrderDraftRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangerOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).sendDraftOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponOrderUsingPOST(body: CouponOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CouponOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateCouponOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateCurrencyConversionOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepositOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateDepositOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExchangerOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateExchangerOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferOrderUsingPOST(body: TransferOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransferOrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateTransferOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalOrderUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateWithdrawalOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponse> {
            const localVarFetchArgs = OrderWsControllerApiFetchParamCreator(configuration).updateWithdrawalToIssuedCardUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrderWsControllerApi - factory interface
 * @export
 */
export const OrderWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCouponOrderUsingPOST(body: CouponOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createCouponOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createCurrencyConversionOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepositOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createDepositOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createExchangerOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createLinkCardOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCardOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createLinkCardOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransferOrderUsingPOST(body: TransferOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createTransferOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createWithdrawalOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).createWithdrawalToIssuedCardUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraftOrderUsingPOST(body: OrderDraftRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).deleteDraftOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCorsysOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderListUsingPOST(body: OrderListRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).getCorsysOrderListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCorsysOrder
         * @param {GetOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorsysOrderUsingPOST(body: GetOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).getCorsysOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderList
         * @param {OrderListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderListUsingPOST(body: OrderListRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).getOrderListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderPaymentMethods
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderPaymentMethodsUsingGET(orderUid: string, options?: any) {
            return OrderWsControllerApiFp(configuration).getOrderPaymentMethodsUsingGET(orderUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderQRCode
         * @param {string} orderUid orderUid
         * @param {number} [size] size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderQRCodeUsingGET(orderUid: string, size?: number, options?: any) {
            return OrderWsControllerApiFp(configuration).getOrderQRCodeUsingGET(orderUid, size, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderTypePaymentMethods
         * @param {string} orderType orderType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderTypePaymentMethodsUsingGET(orderType: string, options?: any) {
            return OrderWsControllerApiFp(configuration).getOrderTypePaymentMethodsUsingGET(orderType, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderUid orderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderUid: string, options?: any) {
            return OrderWsControllerApiFp(configuration).getOrderUsingGET(orderUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary sendDraftOrder
         * @param {OrderDraftRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDraftOrderUsingPOST(body: OrderDraftRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).sendDraftOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCouponOrder
         * @param {CouponOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCouponOrderUsingPOST(body: CouponOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateCouponOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCurrencyConversionOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateCurrencyConversionOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDepositOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepositOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateDepositOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateExchangerOrder
         * @param {ExchangerOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateExchangerOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateTransferOrder
         * @param {TransferOrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransferOrderUsingPOST(body: TransferOrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateTransferOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWithdrawalOrder
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalOrderUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateWithdrawalOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWithdrawalToIssuedCard
         * @param {OrderRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any) {
            return OrderWsControllerApiFp(configuration).updateWithdrawalToIssuedCardUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * OrderWsControllerApi - object-oriented interface
 * @export
 * @class OrderWsControllerApi
 * @extends {BaseAPI}
 */
export class OrderWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary createCouponOrder
     * @param {CouponOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createCouponOrderUsingPOST(body: CouponOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createCouponOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createCurrencyConversionOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createCurrencyConversionOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createDepositOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createDepositOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createDepositOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createExchangerOrder
     * @param {ExchangerOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createExchangerOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createLinkCardOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createLinkCardOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createLinkCardOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createTransferOrder
     * @param {TransferOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createTransferOrderUsingPOST(body: TransferOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createTransferOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWithdrawalOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createWithdrawalOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createWithdrawalOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWithdrawalToIssuedCard
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public createWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).createWithdrawalToIssuedCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteDraftOrder
     * @param {OrderDraftRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public deleteDraftOrderUsingPOST(body: OrderDraftRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).deleteDraftOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCorsysOrderList
     * @param {OrderListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getCorsysOrderListUsingPOST(body: OrderListRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getCorsysOrderListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCorsysOrder
     * @param {GetOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getCorsysOrderUsingPOST(body: GetOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getCorsysOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderList
     * @param {OrderListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getOrderListUsingPOST(body: OrderListRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getOrderListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderPaymentMethods
     * @param {string} orderUid orderUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getOrderPaymentMethodsUsingGET(orderUid: string, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getOrderPaymentMethodsUsingGET(orderUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderQRCode
     * @param {string} orderUid orderUid
     * @param {number} [size] size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getOrderQRCodeUsingGET(orderUid: string, size?: number, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getOrderQRCodeUsingGET(orderUid, size, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderTypePaymentMethods
     * @param {string} orderType orderType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getOrderTypePaymentMethodsUsingGET(orderType: string, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getOrderTypePaymentMethodsUsingGET(orderType, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrder
     * @param {string} orderUid orderUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public getOrderUsingGET(orderUid: string, options?: any) {
        return OrderWsControllerApiFp(this.configuration).getOrderUsingGET(orderUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary sendDraftOrder
     * @param {OrderDraftRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public sendDraftOrderUsingPOST(body: OrderDraftRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).sendDraftOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCouponOrder
     * @param {CouponOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateCouponOrderUsingPOST(body: CouponOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateCouponOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCurrencyConversionOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateCurrencyConversionOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateCurrencyConversionOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDepositOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateDepositOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateDepositOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateExchangerOrder
     * @param {ExchangerOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateExchangerOrderUsingPOST(body: ExchangerOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateExchangerOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateTransferOrder
     * @param {TransferOrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateTransferOrderUsingPOST(body: TransferOrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateTransferOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWithdrawalOrder
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateWithdrawalOrderUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateWithdrawalOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWithdrawalToIssuedCard
     * @param {OrderRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderWsControllerApi
     */
    public updateWithdrawalToIssuedCardUsingPOST(body: OrderRequest, options?: any) {
        return OrderWsControllerApiFp(this.configuration).updateWithdrawalToIssuedCardUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductListWsControllerApi - fetch parameter creator
 * @export
 */
export const ProductListWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCheapestProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheapestProductsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getCheapestProductsUsingPOST.');
            }
            const localVarPath = `/product/list/cheapest`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getExpensiveProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensiveProductsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getExpensiveProductsUsingPOST.');
            }
            const localVarPath = `/product/list/expensive`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNewProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProductsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getNewProductsUsingPOST.');
            }
            const localVarPath = `/product/list/new`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPopularProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularProductsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getPopularProductsUsingPOST.');
            }
            const localVarPath = `/product/list/popular`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRandomProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRandomProductsUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getRandomProductsUsingPOST.');
            }
            const localVarPath = `/product/list/random`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductListWsControllerApi - functional programming interface
 * @export
 */
export const ProductListWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCheapestProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheapestProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductListWsControllerApiFetchParamCreator(configuration).getCheapestProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getExpensiveProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensiveProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductListWsControllerApiFetchParamCreator(configuration).getExpensiveProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNewProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductListWsControllerApiFetchParamCreator(configuration).getNewProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPopularProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductListWsControllerApiFetchParamCreator(configuration).getPopularProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRandomProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRandomProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductListWsControllerApiFetchParamCreator(configuration).getRandomProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductListWsControllerApi - factory interface
 * @export
 */
export const ProductListWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getCheapestProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCheapestProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return ProductListWsControllerApiFp(configuration).getCheapestProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getExpensiveProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpensiveProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return ProductListWsControllerApiFp(configuration).getExpensiveProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNewProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return ProductListWsControllerApiFp(configuration).getNewProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPopularProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return ProductListWsControllerApiFp(configuration).getPopularProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRandomProducts
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRandomProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return ProductListWsControllerApiFp(configuration).getRandomProductsUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProductListWsControllerApi - object-oriented interface
 * @export
 * @class ProductListWsControllerApi
 * @extends {BaseAPI}
 */
export class ProductListWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getCheapestProducts
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductListWsControllerApi
     */
    public getCheapestProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return ProductListWsControllerApiFp(this.configuration).getCheapestProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getExpensiveProducts
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductListWsControllerApi
     */
    public getExpensiveProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return ProductListWsControllerApiFp(this.configuration).getExpensiveProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNewProducts
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductListWsControllerApi
     */
    public getNewProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return ProductListWsControllerApiFp(this.configuration).getNewProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPopularProducts
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductListWsControllerApi
     */
    public getPopularProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return ProductListWsControllerApiFp(this.configuration).getPopularProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRandomProducts
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductListWsControllerApi
     */
    public getRandomProductsUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return ProductListWsControllerApiFp(this.configuration).getRandomProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductSearchWsControllerApi - fetch parameter creator
 * @export
 */
export const ProductSearchWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary details
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling detailsUsingGET.');
            }
            const localVarPath = `/product/search/details/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary details
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingPOST(body: ProductUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling detailsUsingPOST.');
            }
            const localVarPath = `/product/search/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {ProductSearchListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST9(body: ProductSearchListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST9.');
            }
            const localVarPath = `/product/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductSearchWsControllerApi - functional programming interface
 * @export
 */
export const ProductSearchWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary details
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingGET(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductSearchWsControllerApiFetchParamCreator(configuration).detailsUsingGET(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary details
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingPOST(body: ProductUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductSearchWsControllerApiFetchParamCreator(configuration).detailsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {ProductSearchListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST9(body: ProductSearchListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductSearchWsControllerApiFetchParamCreator(configuration).listUsingPOST9(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductSearchWsControllerApi - factory interface
 * @export
 */
export const ProductSearchWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary details
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingGET(uid: string, language?: string, options?: any) {
            return ProductSearchWsControllerApiFp(configuration).detailsUsingGET(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary details
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detailsUsingPOST(body: ProductUidRequest, options?: any) {
            return ProductSearchWsControllerApiFp(configuration).detailsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {ProductSearchListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST9(body: ProductSearchListRequest, options?: any) {
            return ProductSearchWsControllerApiFp(configuration).listUsingPOST9(body, options)(fetch, basePath);
        },
    };
};

/**
 * ProductSearchWsControllerApi - object-oriented interface
 * @export
 * @class ProductSearchWsControllerApi
 * @extends {BaseAPI}
 */
export class ProductSearchWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary details
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSearchWsControllerApi
     */
    public detailsUsingGET(uid: string, language?: string, options?: any) {
        return ProductSearchWsControllerApiFp(this.configuration).detailsUsingGET(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary details
     * @param {ProductUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSearchWsControllerApi
     */
    public detailsUsingPOST(body: ProductUidRequest, options?: any) {
        return ProductSearchWsControllerApiFp(this.configuration).detailsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {ProductSearchListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSearchWsControllerApi
     */
    public listUsingPOST9(body: ProductSearchListRequest, options?: any) {
        return ProductSearchWsControllerApiFp(this.configuration).listUsingPOST9(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductWsControllerApi - fetch parameter creator
 * @export
 */
export const ProductWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST3(body: ProductRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addUsingPOST3.');
            }
            const localVarPath = `/product/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeQuantity
         * @param {ProductQuantityRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuantityUsingPOST(body: ProductQuantityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changeQuantityUsingPOST.');
            }
            const localVarPath = `/product/quantity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductQuantityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {ProductStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST1(body: ProductStatusRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling changeStatusUsingPOST1.');
            }
            const localVarPath = `/product/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST2(body: ProductImageDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteImageUsingPOST2.');
            }
            const localVarPath = `/product/image/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductImageDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(body: ProductUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingPOST5.');
            }
            const localVarPath = `/product/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST5(body: ProductRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST5.');
            }
            const localVarPath = `/product/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST8(body: ProductUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST8.');
            }
            const localVarPath = `/product/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST10(body: ProductListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST10.');
            }
            const localVarPath = `/product/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST3(uid: string, base64File?: string, fileName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling uploadUsingPOST3.');
            }
            const localVarPath = `/product/{uid}/upload`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductWsControllerApi - functional programming interface
 * @export
 */
export const ProductWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST3(body: ProductRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).addUsingPOST3(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changeQuantity
         * @param {ProductQuantityRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuantityUsingPOST(body: ProductQuantityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).changeQuantityUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changeStatus
         * @param {ProductStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST1(body: ProductStatusRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).changeStatusUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteImage
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST2(body: ProductImageDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).deleteImageUsingPOST2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary delete
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(body: ProductUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).deleteUsingPOST5(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary edit
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST5(body: ProductRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).editUsingPOST5(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST8(body: ProductUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).getUsingPOST8(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST10(body: ProductListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductListResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).listUsingPOST10(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST3(uid: string, base64File?: string, fileName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductResponse> {
            const localVarFetchArgs = ProductWsControllerApiFetchParamCreator(configuration).uploadUsingPOST3(uid, base64File, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductWsControllerApi - factory interface
 * @export
 */
export const ProductWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary add
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUsingPOST3(body: ProductRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).addUsingPOST3(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeQuantity
         * @param {ProductQuantityRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuantityUsingPOST(body: ProductQuantityRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).changeQuantityUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeStatus
         * @param {ProductStatusRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeStatusUsingPOST1(body: ProductStatusRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).changeStatusUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteImage
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageUsingPOST2(body: ProductImageDeleteRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).deleteImageUsingPOST2(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary delete
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingPOST5(body: ProductUidRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).deleteUsingPOST5(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary edit
         * @param {ProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST5(body: ProductRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).editUsingPOST5(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST8(body: ProductUidRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).getUsingPOST8(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST10(body: ProductListRequest, options?: any) {
            return ProductWsControllerApiFp(configuration).listUsingPOST10(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary upload
         * @param {string} uid uid
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST3(uid: string, base64File?: string, fileName?: string, options?: any) {
            return ProductWsControllerApiFp(configuration).uploadUsingPOST3(uid, base64File, fileName, options)(fetch, basePath);
        },
    };
};

/**
 * ProductWsControllerApi - object-oriented interface
 * @export
 * @class ProductWsControllerApi
 * @extends {BaseAPI}
 */
export class ProductWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary add
     * @param {ProductRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public addUsingPOST3(body: ProductRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).addUsingPOST3(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeQuantity
     * @param {ProductQuantityRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public changeQuantityUsingPOST(body: ProductQuantityRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).changeQuantityUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeStatus
     * @param {ProductStatusRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public changeStatusUsingPOST1(body: ProductStatusRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).changeStatusUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteImage
     * @param {ProductImageDeleteRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public deleteImageUsingPOST2(body: ProductImageDeleteRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).deleteImageUsingPOST2(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary delete
     * @param {ProductUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public deleteUsingPOST5(body: ProductUidRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).deleteUsingPOST5(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary edit
     * @param {ProductRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public editUsingPOST5(body: ProductRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).editUsingPOST5(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {ProductUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public getUsingPOST8(body: ProductUidRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).getUsingPOST8(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {ProductListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public listUsingPOST10(body: ProductListRequest, options?: any) {
        return ProductWsControllerApiFp(this.configuration).listUsingPOST10(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary upload
     * @param {string} uid uid
     * @param {string} [base64File] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductWsControllerApi
     */
    public uploadUsingPOST3(uid: string, base64File?: string, fileName?: string, options?: any) {
        return ProductWsControllerApiFp(this.configuration).uploadUsingPOST3(uid, base64File, fileName, options)(this.fetch, this.basePath);
    }

}
/**
 * ProjectControllerApi - fetch parameter creator
 * @export
 */
export const ProjectControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiProject> {
            const localVarFetchArgs = ProjectControllerApiFetchParamCreator(configuration).getVersionUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getVersion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionUsingGET(options?: any) {
            return ProjectControllerApiFp(configuration).getVersionUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI {
    /**
     * 
     * @summary getVersion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getVersionUsingGET(options?: any) {
        return ProjectControllerApiFp(this.configuration).getVersionUsingGET(options)(this.fetch, this.basePath);
    }

}
/**
 * PspWsControllerApi - fetch parameter creator
 * @export
 */
export const PspWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getByBrandedCardProgramCode
         * @param {GetPspByBrandedCardProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBrandedCardProgramCodeUsingPOST(body: GetPspByBrandedCardProgramCodeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getByBrandedCardProgramCodeUsingPOST.');
            }
            const localVarPath = `/psp/brandedcard/program`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetPspByBrandedCardProgramCodeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PspWsControllerApi - functional programming interface
 * @export
 */
export const PspWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getByBrandedCardProgramCode
         * @param {GetPspByBrandedCardProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBrandedCardProgramCodeUsingPOST(body: GetPspByBrandedCardProgramCodeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PspResponse> {
            const localVarFetchArgs = PspWsControllerApiFetchParamCreator(configuration).getByBrandedCardProgramCodeUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PspWsControllerApi - factory interface
 * @export
 */
export const PspWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getByBrandedCardProgramCode
         * @param {GetPspByBrandedCardProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByBrandedCardProgramCodeUsingPOST(body: GetPspByBrandedCardProgramCodeRequest, options?: any) {
            return PspWsControllerApiFp(configuration).getByBrandedCardProgramCodeUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * PspWsControllerApi - object-oriented interface
 * @export
 * @class PspWsControllerApi
 * @extends {BaseAPI}
 */
export class PspWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getByBrandedCardProgramCode
     * @param {GetPspByBrandedCardProgramCodeRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PspWsControllerApi
     */
    public getByBrandedCardProgramCodeUsingPOST(body: GetPspByBrandedCardProgramCodeRequest, options?: any) {
        return PspWsControllerApiFp(this.configuration).getByBrandedCardProgramCodeUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PublicQuestionnaireControllerApi - fetch parameter creator
 * @export
 */
export const PublicQuestionnaireControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET1(language?: string, questionnaireUid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/public/questionnaire`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (questionnaireUid !== undefined) {
                localVarQueryParameter['questionnaireUid'] = questionnaireUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicQuestionnaireControllerApi - functional programming interface
 * @export
 */
export const PublicQuestionnaireControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET1(language?: string, questionnaireUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<QuestionnaireListResponse> {
            const localVarFetchArgs = PublicQuestionnaireControllerApiFetchParamCreator(configuration).questionnaireListUsingGET1(language, questionnaireUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublicQuestionnaireControllerApi - factory interface
 * @export
 */
export const PublicQuestionnaireControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary questionnaireList
         * @param {string} [language] language
         * @param {string} [questionnaireUid] questionnaireUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireListUsingGET1(language?: string, questionnaireUid?: string, options?: any) {
            return PublicQuestionnaireControllerApiFp(configuration).questionnaireListUsingGET1(language, questionnaireUid, options)(fetch, basePath);
        },
    };
};

/**
 * PublicQuestionnaireControllerApi - object-oriented interface
 * @export
 * @class PublicQuestionnaireControllerApi
 * @extends {BaseAPI}
 */
export class PublicQuestionnaireControllerApi extends BaseAPI {
    /**
     * 
     * @summary questionnaireList
     * @param {string} [language] language
     * @param {string} [questionnaireUid] questionnaireUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicQuestionnaireControllerApi
     */
    public questionnaireListUsingGET1(language?: string, questionnaireUid?: string, options?: any) {
        return PublicQuestionnaireControllerApiFp(this.configuration).questionnaireListUsingGET1(language, questionnaireUid, options)(this.fetch, this.basePath);
    }

}
/**
 * ReferralProgramWsControllerApi - fetch parameter creator
 * @export
 */
export const ReferralProgramWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getReferralProgramCode
         * @param {GetReferralProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramCodeUsingPOST(body: GetReferralProgramCodeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getReferralProgramCodeUsingPOST.');
            }
            const localVarPath = `/referral/code`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetReferralProgramCodeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReferralProgramId
         * @param {GetReferralProgramIdRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramIdUsingPOST(body: GetReferralProgramIdRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getReferralProgramIdUsingPOST.');
            }
            const localVarPath = `/referral/publicId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetReferralProgramIdRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferralProgramWsControllerApi - functional programming interface
 * @export
 */
export const ReferralProgramWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getReferralProgramCode
         * @param {GetReferralProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramCodeUsingPOST(body: GetReferralProgramCodeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferralProgramCodeResponse> {
            const localVarFetchArgs = ReferralProgramWsControllerApiFetchParamCreator(configuration).getReferralProgramCodeUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getReferralProgramId
         * @param {GetReferralProgramIdRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramIdUsingPOST(body: GetReferralProgramIdRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferralProgramResponse> {
            const localVarFetchArgs = ReferralProgramWsControllerApiFetchParamCreator(configuration).getReferralProgramIdUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferralProgramWsControllerApi - factory interface
 * @export
 */
export const ReferralProgramWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getReferralProgramCode
         * @param {GetReferralProgramCodeRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramCodeUsingPOST(body: GetReferralProgramCodeRequest, options?: any) {
            return ReferralProgramWsControllerApiFp(configuration).getReferralProgramCodeUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getReferralProgramId
         * @param {GetReferralProgramIdRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralProgramIdUsingPOST(body: GetReferralProgramIdRequest, options?: any) {
            return ReferralProgramWsControllerApiFp(configuration).getReferralProgramIdUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * ReferralProgramWsControllerApi - object-oriented interface
 * @export
 * @class ReferralProgramWsControllerApi
 * @extends {BaseAPI}
 */
export class ReferralProgramWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getReferralProgramCode
     * @param {GetReferralProgramCodeRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramWsControllerApi
     */
    public getReferralProgramCodeUsingPOST(body: GetReferralProgramCodeRequest, options?: any) {
        return ReferralProgramWsControllerApiFp(this.configuration).getReferralProgramCodeUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getReferralProgramId
     * @param {GetReferralProgramIdRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferralProgramWsControllerApi
     */
    public getReferralProgramIdUsingPOST(body: GetReferralProgramIdRequest, options?: any) {
        return ReferralProgramWsControllerApiFp(this.configuration).getReferralProgramIdUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * RegionControllerApi - fetch parameter creator
 * @export
 */
export const RegionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCountryToRegionUsingPUT(countryCode: string, regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'countryCode' is not null or undefined
            if (countryCode === null || countryCode === undefined) {
                throw new RequiredError('countryCode','Required parameter countryCode was null or undefined when calling addCountryToRegionUsingPUT.');
            }
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling addCountryToRegionUsingPUT.');
            }
            const localVarPath = `/admin/region/{regionCode}/country/{countryCode}`
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)))
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionAgreementUsingPUT(body: RegionAgreement, regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addRegionAgreementUsingPUT.');
            }
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling addRegionAgreementUsingPUT.');
            }
            const localVarPath = `/admin/region/{regionCode}/agreement`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegionAgreement" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionUsingPUT(body: Region, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addRegionUsingPUT.');
            }
            const localVarPath = `/admin/region`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Region" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountryToRegionUsingDELETE(countryCode: string, regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'countryCode' is not null or undefined
            if (countryCode === null || countryCode === undefined) {
                throw new RequiredError('countryCode','Required parameter countryCode was null or undefined when calling deleteCountryToRegionUsingDELETE.');
            }
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling deleteCountryToRegionUsingDELETE.');
            }
            const localVarPath = `/admin/region/{regionCode}/country/{countryCode}`
                .replace(`{${"countryCode"}}`, encodeURIComponent(String(countryCode)))
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRegionAgreement
         * @param {string} regionCode regionCode
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionAgreementUsingDELETE(regionCode: string, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling deleteRegionAgreementUsingDELETE.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling deleteRegionAgreementUsingDELETE.');
            }
            const localVarPath = `/admin/region/{regionCode}/agreement/{uid}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionAgreementUsingPOST(body: RegionAgreement, regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editRegionAgreementUsingPOST.');
            }
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling editRegionAgreementUsingPOST.');
            }
            const localVarPath = `/admin/region/{regionCode}/agreement`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegionAgreement" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionUsingPOST(body: Region, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editRegionUsingPOST.');
            }
            const localVarPath = `/admin/region`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Region" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRegionAgreements
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionAgreementsUsingGET(regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getRegionAgreementsUsingGET.');
            }
            const localVarPath = `/admin/region/{regionCode}/agreement`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRegion
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionUsingGET(regionCode: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'regionCode' is not null or undefined
            if (regionCode === null || regionCode === undefined) {
                throw new RequiredError('regionCode','Required parameter regionCode was null or undefined when calling getRegionUsingGET.');
            }
            const localVarPath = `/admin/region/{regionCode}`
                .replace(`{${"regionCode"}}`, encodeURIComponent(String(regionCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/admin/region`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchRegionAgreement
         * @param {SearchAgreementsRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRegionAgreementUsingPOST(body: SearchAgreementsRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchRegionAgreementUsingPOST.');
            }
            const localVarPath = `/admin/region/agreement/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SearchAgreementsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionControllerApi - functional programming interface
 * @export
 */
export const RegionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCountryToRegionUsingPUT(countryCode: string, regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).addCountryToRegionUsingPUT(countryCode, regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionAgreementUsingPUT(body: RegionAgreement, regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegionAgreement> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).addRegionAgreementUsingPUT(body, regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionUsingPUT(body: Region, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).addRegionUsingPUT(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountryToRegionUsingDELETE(countryCode: string, regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).deleteCountryToRegionUsingDELETE(countryCode, regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRegionAgreement
         * @param {string} regionCode regionCode
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionAgreementUsingDELETE(regionCode: string, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).deleteRegionAgreementUsingDELETE(regionCode, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionAgreementUsingPOST(body: RegionAgreement, regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RegionAgreement> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).editRegionAgreementUsingPOST(body, regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionUsingPOST(body: Region, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).editRegionUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRegionAgreements
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionAgreementsUsingGET(regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RegionAgreement>> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).getRegionAgreementsUsingGET(regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRegion
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionUsingGET(regionCode: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Region> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).getRegionUsingGET(regionCode, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).getRegionsUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary searchRegionAgreement
         * @param {SearchAgreementsRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRegionAgreementUsingPOST(body: SearchAgreementsRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RegionAgreement>> {
            const localVarFetchArgs = RegionControllerApiFetchParamCreator(configuration).searchRegionAgreementUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RegionControllerApi - factory interface
 * @export
 */
export const RegionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCountryToRegionUsingPUT(countryCode: string, regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).addCountryToRegionUsingPUT(countryCode, regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionAgreementUsingPUT(body: RegionAgreement, regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).addRegionAgreementUsingPUT(body, regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRegionUsingPUT(body: Region, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).addRegionUsingPUT(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCountryToRegion
         * @param {string} countryCode countryCode
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCountryToRegionUsingDELETE(countryCode: string, regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).deleteCountryToRegionUsingDELETE(countryCode, regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRegionAgreement
         * @param {string} regionCode regionCode
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRegionAgreementUsingDELETE(regionCode: string, uid: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).deleteRegionAgreementUsingDELETE(regionCode, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editRegionAgreement
         * @param {RegionAgreement} body agreement
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionAgreementUsingPOST(body: RegionAgreement, regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).editRegionAgreementUsingPOST(body, regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editRegion
         * @param {Region} body region
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRegionUsingPOST(body: Region, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).editRegionUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRegionAgreements
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionAgreementsUsingGET(regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).getRegionAgreementsUsingGET(regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRegion
         * @param {string} regionCode regionCode
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionUsingGET(regionCode: string, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).getRegionUsingGET(regionCode, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRegions
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsUsingGET(language?: string, options?: any) {
            return RegionControllerApiFp(configuration).getRegionsUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary searchRegionAgreement
         * @param {SearchAgreementsRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRegionAgreementUsingPOST(body: SearchAgreementsRequest, language?: string, options?: any) {
            return RegionControllerApiFp(configuration).searchRegionAgreementUsingPOST(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * RegionControllerApi - object-oriented interface
 * @export
 * @class RegionControllerApi
 * @extends {BaseAPI}
 */
export class RegionControllerApi extends BaseAPI {
    /**
     * 
     * @summary addCountryToRegion
     * @param {string} countryCode countryCode
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public addCountryToRegionUsingPUT(countryCode: string, regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).addCountryToRegionUsingPUT(countryCode, regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addRegionAgreement
     * @param {RegionAgreement} body agreement
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public addRegionAgreementUsingPUT(body: RegionAgreement, regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).addRegionAgreementUsingPUT(body, regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addRegion
     * @param {Region} body region
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public addRegionUsingPUT(body: Region, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).addRegionUsingPUT(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCountryToRegion
     * @param {string} countryCode countryCode
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public deleteCountryToRegionUsingDELETE(countryCode: string, regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).deleteCountryToRegionUsingDELETE(countryCode, regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRegionAgreement
     * @param {string} regionCode regionCode
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public deleteRegionAgreementUsingDELETE(regionCode: string, uid: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).deleteRegionAgreementUsingDELETE(regionCode, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editRegionAgreement
     * @param {RegionAgreement} body agreement
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public editRegionAgreementUsingPOST(body: RegionAgreement, regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).editRegionAgreementUsingPOST(body, regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editRegion
     * @param {Region} body region
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public editRegionUsingPOST(body: Region, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).editRegionUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRegionAgreements
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public getRegionAgreementsUsingGET(regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).getRegionAgreementsUsingGET(regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRegion
     * @param {string} regionCode regionCode
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public getRegionUsingGET(regionCode: string, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).getRegionUsingGET(regionCode, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRegions
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public getRegionsUsingGET(language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).getRegionsUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary searchRegionAgreement
     * @param {SearchAgreementsRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionControllerApi
     */
    public searchRegionAgreementUsingPOST(body: SearchAgreementsRequest, language?: string, options?: any) {
        return RegionControllerApiFp(this.configuration).searchRegionAgreementUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

}
/**
 * SecurityQuestionWsControllerApi - fetch parameter creator
 * @export
 */
export const SecurityQuestionWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary edit
         * @param {SecurityQuestionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST6(body: SecurityQuestionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editUsingPOST6.');
            }
            const localVarPath = `/securityquestions/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SecurityQuestionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST9(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST9.');
            }
            const localVarPath = `/securityquestions/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityQuestionWsControllerApi - functional programming interface
 * @export
 */
export const SecurityQuestionWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary edit
         * @param {SecurityQuestionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST6(body: SecurityQuestionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = SecurityQuestionWsControllerApiFetchParamCreator(configuration).editUsingPOST6(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST9(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SecurityQuestionResponse> {
            const localVarFetchArgs = SecurityQuestionWsControllerApiFetchParamCreator(configuration).getUsingPOST9(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SecurityQuestionWsControllerApi - factory interface
 * @export
 */
export const SecurityQuestionWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary edit
         * @param {SecurityQuestionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUsingPOST6(body: SecurityQuestionRequest, options?: any) {
            return SecurityQuestionWsControllerApiFp(configuration).editUsingPOST6(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST9(body: AbstractAuthorizedRequest, options?: any) {
            return SecurityQuestionWsControllerApiFp(configuration).getUsingPOST9(body, options)(fetch, basePath);
        },
    };
};

/**
 * SecurityQuestionWsControllerApi - object-oriented interface
 * @export
 * @class SecurityQuestionWsControllerApi
 * @extends {BaseAPI}
 */
export class SecurityQuestionWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary edit
     * @param {SecurityQuestionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityQuestionWsControllerApi
     */
    public editUsingPOST6(body: SecurityQuestionRequest, options?: any) {
        return SecurityQuestionWsControllerApiFp(this.configuration).editUsingPOST6(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityQuestionWsControllerApi
     */
    public getUsingPOST9(body: AbstractAuthorizedRequest, options?: any) {
        return SecurityQuestionWsControllerApiFp(this.configuration).getUsingPOST9(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SettingsWsControllerApi - fetch parameter creator
 * @export
 */
export const SettingsWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettings
         * @param {GetSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsingPOST(body: GetSettingsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSettingsUsingPOST.');
            }
            const localVarPath = `/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetSettingsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsWsControllerApi - functional programming interface
 * @export
 */
export const SettingsWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettings
         * @param {GetSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsingPOST(body: GetSettingsRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SettingsResponse> {
            const localVarFetchArgs = SettingsWsControllerApiFetchParamCreator(configuration).getSettingsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SettingsWsControllerApi - factory interface
 * @export
 */
export const SettingsWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getSettings
         * @param {GetSettingsRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUsingPOST(body: GetSettingsRequest, options?: any) {
            return SettingsWsControllerApiFp(configuration).getSettingsUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * SettingsWsControllerApi - object-oriented interface
 * @export
 * @class SettingsWsControllerApi
 * @extends {BaseAPI}
 */
export class SettingsWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getSettings
     * @param {GetSettingsRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsWsControllerApi
     */
    public getSettingsUsingPOST(body: GetSettingsRequest, options?: any) {
        return SettingsWsControllerApiFp(this.configuration).getSettingsUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * StorageObjectControllerApi - fetch parameter creator
 * @export
 */
export const StorageObjectControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getObjectList
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectListUsingPOST(type: string, options: any = {}): FetchArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getObjectListUsingPOST.');
            }
            const localVarPath = `/storage/object/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary insertObject
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertObjectUsingPOST1(base64File?: string, fileName?: string, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/storage/object`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            if (type !== undefined) {
                localVarFormParams.set('type', type as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageObjectControllerApi - functional programming interface
 * @export
 */
export const StorageObjectControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getObjectList
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectListUsingPOST(type: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StorageObjectResponse> {
            const localVarFetchArgs = StorageObjectControllerApiFetchParamCreator(configuration).getObjectListUsingPOST(type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary insertObject
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertObjectUsingPOST1(base64File?: string, fileName?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StorageObjectResponse> {
            const localVarFetchArgs = StorageObjectControllerApiFetchParamCreator(configuration).insertObjectUsingPOST1(base64File, fileName, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StorageObjectControllerApi - factory interface
 * @export
 */
export const StorageObjectControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getObjectList
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectListUsingPOST(type: string, options?: any) {
            return StorageObjectControllerApiFp(configuration).getObjectListUsingPOST(type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary insertObject
         * @param {string} [base64File] 
         * @param {string} [fileName] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertObjectUsingPOST1(base64File?: string, fileName?: string, type?: string, options?: any) {
            return StorageObjectControllerApiFp(configuration).insertObjectUsingPOST1(base64File, fileName, type, options)(fetch, basePath);
        },
    };
};

/**
 * StorageObjectControllerApi - object-oriented interface
 * @export
 * @class StorageObjectControllerApi
 * @extends {BaseAPI}
 */
export class StorageObjectControllerApi extends BaseAPI {
    /**
     * 
     * @summary getObjectList
     * @param {string} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageObjectControllerApi
     */
    public getObjectListUsingPOST(type: string, options?: any) {
        return StorageObjectControllerApiFp(this.configuration).getObjectListUsingPOST(type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary insertObject
     * @param {string} [base64File] 
     * @param {string} [fileName] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageObjectControllerApi
     */
    public insertObjectUsingPOST1(base64File?: string, fileName?: string, type?: string, options?: any) {
        return StorageObjectControllerApiFp(this.configuration).insertObjectUsingPOST1(base64File, fileName, type, options)(this.fetch, this.basePath);
    }

}
/**
 * SumSubControllerApi - fetch parameter creator
 * @export
 */
export const SumSubControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSumSubApplicant
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubApplicantUsingGET(accountUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'accountUid' is not null or undefined
            if (accountUid === null || accountUid === undefined) {
                throw new RequiredError('accountUid','Required parameter accountUid was null or undefined when calling getSumSubApplicantUsingGET.');
            }
            const localVarPath = `/admin/sumsub/{accountUid}`
                .replace(`{${"accountUid"}}`, encodeURIComponent(String(accountUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSumSubApplicantFixedInfo
         * @param {SumSubApplicantFixedInfo} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSumSubApplicantFixedInfoUsingPOST(body: SumSubApplicantFixedInfo, accountUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSumSubApplicantFixedInfoUsingPOST.');
            }
            // verify required parameter 'accountUid' is not null or undefined
            if (accountUid === null || accountUid === undefined) {
                throw new RequiredError('accountUid','Required parameter accountUid was null or undefined when calling updateSumSubApplicantFixedInfoUsingPOST.');
            }
            const localVarPath = `/admin/sumsub/{accountUid}/fixedInfo`
                .replace(`{${"accountUid"}}`, encodeURIComponent(String(accountUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SumSubApplicantFixedInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SumSubControllerApi - functional programming interface
 * @export
 */
export const SumSubControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSumSubApplicant
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubApplicantUsingGET(accountUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SumSubApplicant> {
            const localVarFetchArgs = SumSubControllerApiFetchParamCreator(configuration).getSumSubApplicantUsingGET(accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSumSubApplicantFixedInfo
         * @param {SumSubApplicantFixedInfo} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSumSubApplicantFixedInfoUsingPOST(body: SumSubApplicantFixedInfo, accountUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SumSubApplicant> {
            const localVarFetchArgs = SumSubControllerApiFetchParamCreator(configuration).updateSumSubApplicantFixedInfoUsingPOST(body, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SumSubControllerApi - factory interface
 * @export
 */
export const SumSubControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getSumSubApplicant
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubApplicantUsingGET(accountUid: string, options?: any) {
            return SumSubControllerApiFp(configuration).getSumSubApplicantUsingGET(accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSumSubApplicantFixedInfo
         * @param {SumSubApplicantFixedInfo} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSumSubApplicantFixedInfoUsingPOST(body: SumSubApplicantFixedInfo, accountUid: string, options?: any) {
            return SumSubControllerApiFp(configuration).updateSumSubApplicantFixedInfoUsingPOST(body, accountUid, options)(fetch, basePath);
        },
    };
};

/**
 * SumSubControllerApi - object-oriented interface
 * @export
 * @class SumSubControllerApi
 * @extends {BaseAPI}
 */
export class SumSubControllerApi extends BaseAPI {
    /**
     * 
     * @summary getSumSubApplicant
     * @param {string} accountUid accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SumSubControllerApi
     */
    public getSumSubApplicantUsingGET(accountUid: string, options?: any) {
        return SumSubControllerApiFp(this.configuration).getSumSubApplicantUsingGET(accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSumSubApplicantFixedInfo
     * @param {SumSubApplicantFixedInfo} body request
     * @param {string} accountUid accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SumSubControllerApi
     */
    public updateSumSubApplicantFixedInfoUsingPOST(body: SumSubApplicantFixedInfo, accountUid: string, options?: any) {
        return SumSubControllerApiFp(this.configuration).updateSumSubApplicantFixedInfoUsingPOST(body, accountUid, options)(this.fetch, this.basePath);
    }

}
/**
 * SumSubWsControllerApi - fetch parameter creator
 * @export
 */
export const SumSubWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary callbackSumSub
         * @param {JsonNode} body callbackBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackSumSubUsingPOST(body: JsonNode, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling callbackSumSubUsingPOST.');
            }
            const localVarPath = `/sumsub/callback`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JsonNode" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSumSubAccessToken
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenUsingPOST(body: AbstractAuthorizedRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSumSubAccessTokenUsingPOST.');
            }
            const localVarPath = `/sumsub/auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AbstractAuthorizedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SumSubWsControllerApi - functional programming interface
 * @export
 */
export const SumSubWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary callbackSumSub
         * @param {JsonNode} body callbackBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackSumSubUsingPOST(body: JsonNode, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = SumSubWsControllerApiFetchParamCreator(configuration).callbackSumSubUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSumSubAccessToken
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenUsingPOST(body: AbstractAuthorizedRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SumSubAuthResponse> {
            const localVarFetchArgs = SumSubWsControllerApiFetchParamCreator(configuration).getSumSubAccessTokenUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SumSubWsControllerApi - factory interface
 * @export
 */
export const SumSubWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary callbackSumSub
         * @param {JsonNode} body callbackBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackSumSubUsingPOST(body: JsonNode, options?: any) {
            return SumSubWsControllerApiFp(configuration).callbackSumSubUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSumSubAccessToken
         * @param {AbstractAuthorizedRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSumSubAccessTokenUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
            return SumSubWsControllerApiFp(configuration).getSumSubAccessTokenUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * SumSubWsControllerApi - object-oriented interface
 * @export
 * @class SumSubWsControllerApi
 * @extends {BaseAPI}
 */
export class SumSubWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary callbackSumSub
     * @param {JsonNode} body callbackBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SumSubWsControllerApi
     */
    public callbackSumSubUsingPOST(body: JsonNode, options?: any) {
        return SumSubWsControllerApiFp(this.configuration).callbackSumSubUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSumSubAccessToken
     * @param {AbstractAuthorizedRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SumSubWsControllerApi
     */
    public getSumSubAccessTokenUsingPOST(body: AbstractAuthorizedRequest, options?: any) {
        return SumSubWsControllerApiFp(this.configuration).getSumSubAccessTokenUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TariffWsControllerApi - fetch parameter creator
 * @export
 */
export const TariffWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getBrandedCardTariff
         * @param {BrandedCardTartiffRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandedCardTariffUsingPOST(body: BrandedCardTartiffRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getBrandedCardTariffUsingPOST.');
            }
            const localVarPath = `/tariff/brandedcard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BrandedCardTartiffRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffWsControllerApi - functional programming interface
 * @export
 */
export const TariffWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getBrandedCardTariff
         * @param {BrandedCardTartiffRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandedCardTariffUsingPOST(body: BrandedCardTartiffRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TariffResponse> {
            const localVarFetchArgs = TariffWsControllerApiFetchParamCreator(configuration).getBrandedCardTariffUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TariffWsControllerApi - factory interface
 * @export
 */
export const TariffWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getBrandedCardTariff
         * @param {BrandedCardTartiffRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandedCardTariffUsingPOST(body: BrandedCardTartiffRequest, options?: any) {
            return TariffWsControllerApiFp(configuration).getBrandedCardTariffUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * TariffWsControllerApi - object-oriented interface
 * @export
 * @class TariffWsControllerApi
 * @extends {BaseAPI}
 */
export class TariffWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getBrandedCardTariff
     * @param {BrandedCardTartiffRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffWsControllerApi
     */
    public getBrandedCardTariffUsingPOST(body: BrandedCardTartiffRequest, options?: any) {
        return TariffWsControllerApiFp(this.configuration).getBrandedCardTariffUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TokenWsControllerApi - fetch parameter creator
 * @export
 */
export const TokenWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activate
         * @param {ActivateTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST(body: ActivateTokenRequest, client: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling activateUsingPOST.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling activateUsingPOST.');
            }
            const localVarPath = `/auth/{client}/token/activate`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ActivateTokenRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAuthorizationCode
         * @param {GetAuthorizationCodeRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizationCodeUsingPOST(body: GetAuthorizationCodeRequest, client: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAuthorizationCodeUsingPOST.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling getAuthorizationCodeUsingPOST.');
            }
            const localVarPath = `/auth/{client}/authorization_code`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAuthorizationCodeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getExternalToken
         * @param {GetTokenByExternalProviderRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTokenUsingPOST(body: GetTokenByExternalProviderRequest, client: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getExternalTokenUsingPOST.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling getExternalTokenUsingPOST.');
            }
            const localVarPath = `/auth/{client}/token/external`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetTokenByExternalProviderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST10(body: GetTokenRequest, client: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getUsingPOST10.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling getUsingPOST10.');
            }
            const localVarPath = `/auth/{client}/token`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetTokenRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refresh
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshUsingPOST(body: GetTokenRequest, client: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling refreshUsingPOST.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling refreshUsingPOST.');
            }
            const localVarPath = `/auth/{client}/token/refresh`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetTokenRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenWsControllerApi - functional programming interface
 * @export
 */
export const TokenWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activate
         * @param {ActivateTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST(body: ActivateTokenRequest, client: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
            const localVarFetchArgs = TokenWsControllerApiFetchParamCreator(configuration).activateUsingPOST(body, client, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAuthorizationCode
         * @param {GetAuthorizationCodeRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizationCodeUsingPOST(body: GetAuthorizationCodeRequest, client: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetAuthorizationCodeResponse> {
            const localVarFetchArgs = TokenWsControllerApiFetchParamCreator(configuration).getAuthorizationCodeUsingPOST(body, client, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getExternalToken
         * @param {GetTokenByExternalProviderRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTokenUsingPOST(body: GetTokenByExternalProviderRequest, client: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
            const localVarFetchArgs = TokenWsControllerApiFetchParamCreator(configuration).getExternalTokenUsingPOST(body, client, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST10(body: GetTokenRequest, client: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
            const localVarFetchArgs = TokenWsControllerApiFetchParamCreator(configuration).getUsingPOST10(body, client, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary refresh
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshUsingPOST(body: GetTokenRequest, client: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GetTokenResponse> {
            const localVarFetchArgs = TokenWsControllerApiFetchParamCreator(configuration).refreshUsingPOST(body, client, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TokenWsControllerApi - factory interface
 * @export
 */
export const TokenWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary activate
         * @param {ActivateTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST(body: ActivateTokenRequest, client: string, options?: any) {
            return TokenWsControllerApiFp(configuration).activateUsingPOST(body, client, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAuthorizationCode
         * @param {GetAuthorizationCodeRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizationCodeUsingPOST(body: GetAuthorizationCodeRequest, client: string, options?: any) {
            return TokenWsControllerApiFp(configuration).getAuthorizationCodeUsingPOST(body, client, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getExternalToken
         * @param {GetTokenByExternalProviderRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalTokenUsingPOST(body: GetTokenByExternalProviderRequest, client: string, options?: any) {
            return TokenWsControllerApiFp(configuration).getExternalTokenUsingPOST(body, client, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingPOST10(body: GetTokenRequest, client: string, options?: any) {
            return TokenWsControllerApiFp(configuration).getUsingPOST10(body, client, options)(fetch, basePath);
        },
        /**
         * 
         * @summary refresh
         * @param {GetTokenRequest} body request
         * @param {string} client client
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshUsingPOST(body: GetTokenRequest, client: string, options?: any) {
            return TokenWsControllerApiFp(configuration).refreshUsingPOST(body, client, options)(fetch, basePath);
        },
    };
};

/**
 * TokenWsControllerApi - object-oriented interface
 * @export
 * @class TokenWsControllerApi
 * @extends {BaseAPI}
 */
export class TokenWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary activate
     * @param {ActivateTokenRequest} body request
     * @param {string} client client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenWsControllerApi
     */
    public activateUsingPOST(body: ActivateTokenRequest, client: string, options?: any) {
        return TokenWsControllerApiFp(this.configuration).activateUsingPOST(body, client, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAuthorizationCode
     * @param {GetAuthorizationCodeRequest} body request
     * @param {string} client client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenWsControllerApi
     */
    public getAuthorizationCodeUsingPOST(body: GetAuthorizationCodeRequest, client: string, options?: any) {
        return TokenWsControllerApiFp(this.configuration).getAuthorizationCodeUsingPOST(body, client, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getExternalToken
     * @param {GetTokenByExternalProviderRequest} body request
     * @param {string} client client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenWsControllerApi
     */
    public getExternalTokenUsingPOST(body: GetTokenByExternalProviderRequest, client: string, options?: any) {
        return TokenWsControllerApiFp(this.configuration).getExternalTokenUsingPOST(body, client, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get
     * @param {GetTokenRequest} body request
     * @param {string} client client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenWsControllerApi
     */
    public getUsingPOST10(body: GetTokenRequest, client: string, options?: any) {
        return TokenWsControllerApiFp(this.configuration).getUsingPOST10(body, client, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary refresh
     * @param {GetTokenRequest} body request
     * @param {string} client client
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenWsControllerApi
     */
    public refreshUsingPOST(body: GetTokenRequest, client: string, options?: any) {
        return TokenWsControllerApiFp(this.configuration).refreshUsingPOST(body, client, options)(this.fetch, this.basePath);
    }

}
/**
 * TradeClassificatorWsControllerApi - fetch parameter creator
 * @export
 */
export const TradeClassificatorWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCurrencyList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET1(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/trade/currency/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET1(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/trade/paymentmethod/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeClassificatorWsControllerApi - functional programming interface
 * @export
 */
export const TradeClassificatorWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getCurrencyList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET1(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeCurrencyListResponse> {
            const localVarFetchArgs = TradeClassificatorWsControllerApiFetchParamCreator(configuration).getCurrencyListUsingGET1(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET1(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradePaymentMethodListResponse> {
            const localVarFetchArgs = TradeClassificatorWsControllerApiFetchParamCreator(configuration).getPaymentMethodListUsingGET1(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TradeClassificatorWsControllerApi - factory interface
 * @export
 */
export const TradeClassificatorWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getCurrencyList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrencyListUsingGET1(language?: string, options?: any) {
            return TradeClassificatorWsControllerApiFp(configuration).getCurrencyListUsingGET1(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPaymentMethodList
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodListUsingGET1(language?: string, options?: any) {
            return TradeClassificatorWsControllerApiFp(configuration).getPaymentMethodListUsingGET1(language, options)(fetch, basePath);
        },
    };
};

/**
 * TradeClassificatorWsControllerApi - object-oriented interface
 * @export
 * @class TradeClassificatorWsControllerApi
 * @extends {BaseAPI}
 */
export class TradeClassificatorWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getCurrencyList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeClassificatorWsControllerApi
     */
    public getCurrencyListUsingGET1(language?: string, options?: any) {
        return TradeClassificatorWsControllerApiFp(this.configuration).getCurrencyListUsingGET1(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPaymentMethodList
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeClassificatorWsControllerApi
     */
    public getPaymentMethodListUsingGET1(language?: string, options?: any) {
        return TradeClassificatorWsControllerApiFp(this.configuration).getPaymentMethodListUsingGET1(language, options)(this.fetch, this.basePath);
    }

}
/**
 * TradeDealWsControllerApi - fetch parameter creator
 * @export
 */
export const TradeDealWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary acceptDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDealUsingPOST(body: TradeDealActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling acceptDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/accept`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDealUsingPOST(body: TradeDealRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancelDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDealUsingPOST(body: TradeDealActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cancelDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/cancel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary claimDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimDealUsingPOST(body: TradeDealActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling claimDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/claim`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary confirmDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmDealUsingPOST(body: TradeDealActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling confirmDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDealUsingPOST(body: TradeDealUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDealUsingPOST(body: TradeDealRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fulfillDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillDealUsingPOST(body: TradeDealActionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling fulfillDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/fulfill`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealActionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealUsingPOST(body: TradeDealUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getDealUsingPOST.');
            }
            const localVarPath = `/trade/deal/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listDeals
         * @param {TradeDealListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDealsUsingPOST(body: TradeDealListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listDealsUsingPOST.');
            }
            const localVarPath = `/trade/deal/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeDealListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeDealWsControllerApi - functional programming interface
 * @export
 */
export const TradeDealWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary acceptDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDealUsingPOST(body: TradeDealActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).acceptDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDealUsingPOST(body: TradeDealRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).addDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary cancelDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDealUsingPOST(body: TradeDealActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).cancelDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary claimDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimDealUsingPOST(body: TradeDealActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).claimDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary confirmDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmDealUsingPOST(body: TradeDealActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).confirmDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDealUsingPOST(body: TradeDealUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).deleteDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDealUsingPOST(body: TradeDealRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).editDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary fulfillDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillDealUsingPOST(body: TradeDealActionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).fulfillDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealUsingPOST(body: TradeDealUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).getDealUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listDeals
         * @param {TradeDealListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDealsUsingPOST(body: TradeDealListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeDealListResponse> {
            const localVarFetchArgs = TradeDealWsControllerApiFetchParamCreator(configuration).listDealsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TradeDealWsControllerApi - factory interface
 * @export
 */
export const TradeDealWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary acceptDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptDealUsingPOST(body: TradeDealActionRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).acceptDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDealUsingPOST(body: TradeDealRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).addDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary cancelDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDealUsingPOST(body: TradeDealActionRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).cancelDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary claimDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claimDealUsingPOST(body: TradeDealActionRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).claimDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary confirmDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmDealUsingPOST(body: TradeDealActionRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).confirmDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDealUsingPOST(body: TradeDealUidRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).deleteDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editDeal
         * @param {TradeDealRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDealUsingPOST(body: TradeDealRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).editDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary fulfillDeal
         * @param {TradeDealActionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fulfillDealUsingPOST(body: TradeDealActionRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).fulfillDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeal
         * @param {TradeDealUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDealUsingPOST(body: TradeDealUidRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).getDealUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listDeals
         * @param {TradeDealListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDealsUsingPOST(body: TradeDealListRequest, options?: any) {
            return TradeDealWsControllerApiFp(configuration).listDealsUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * TradeDealWsControllerApi - object-oriented interface
 * @export
 * @class TradeDealWsControllerApi
 * @extends {BaseAPI}
 */
export class TradeDealWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary acceptDeal
     * @param {TradeDealActionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public acceptDealUsingPOST(body: TradeDealActionRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).acceptDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addDeal
     * @param {TradeDealRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public addDealUsingPOST(body: TradeDealRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).addDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary cancelDeal
     * @param {TradeDealActionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public cancelDealUsingPOST(body: TradeDealActionRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).cancelDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary claimDeal
     * @param {TradeDealActionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public claimDealUsingPOST(body: TradeDealActionRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).claimDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary confirmDeal
     * @param {TradeDealActionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public confirmDealUsingPOST(body: TradeDealActionRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).confirmDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteDeal
     * @param {TradeDealUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public deleteDealUsingPOST(body: TradeDealUidRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).deleteDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editDeal
     * @param {TradeDealRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public editDealUsingPOST(body: TradeDealRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).editDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary fulfillDeal
     * @param {TradeDealActionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public fulfillDealUsingPOST(body: TradeDealActionRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).fulfillDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeal
     * @param {TradeDealUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public getDealUsingPOST(body: TradeDealUidRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).getDealUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listDeals
     * @param {TradeDealListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeDealWsControllerApi
     */
    public listDealsUsingPOST(body: TradeDealListRequest, options?: any) {
        return TradeDealWsControllerApiFp(this.configuration).listDealsUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TradeOfferWsControllerApi - fetch parameter creator
 * @export
 */
export const TradeOfferWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOfferUsingPOST(body: TradeOfferRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferUsingPOST(body: TradeOfferUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary editOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOfferUsingPOST(body: TradeOfferRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferUsingPOST(body: TradeOfferUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listAvailableOffers
         * @param {AvailableTradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOffersUsingPOST(body: AvailableTradeOfferListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listAvailableOffersUsingPOST.');
            }
            const localVarPath = `/trade/offer/list/available`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AvailableTradeOfferListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listOffers
         * @param {TradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffersUsingPOST(body: TradeOfferListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listOffersUsingPOST.');
            }
            const localVarPath = `/trade/offer/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary publishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOfferUsingPOST(body: TradeOfferUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling publishOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/publish`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unpublishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishOfferUsingPOST(body: TradeOfferUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling unpublishOfferUsingPOST.');
            }
            const localVarPath = `/trade/offer/unpublish`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TradeOfferUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeOfferWsControllerApi - functional programming interface
 * @export
 */
export const TradeOfferWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOfferUsingPOST(body: TradeOfferRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).addOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferUsingPOST(body: TradeOfferUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonApiResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).deleteOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary editOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOfferUsingPOST(body: TradeOfferRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).editOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferUsingPOST(body: TradeOfferUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).getOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listAvailableOffers
         * @param {AvailableTradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOffersUsingPOST(body: AvailableTradeOfferListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferListResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).listAvailableOffersUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary listOffers
         * @param {TradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffersUsingPOST(body: TradeOfferListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferListResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).listOffersUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary publishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOfferUsingPOST(body: TradeOfferUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).publishOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary unpublishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishOfferUsingPOST(body: TradeOfferUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TradeOfferResponse> {
            const localVarFetchArgs = TradeOfferWsControllerApiFetchParamCreator(configuration).unpublishOfferUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TradeOfferWsControllerApi - factory interface
 * @export
 */
export const TradeOfferWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOfferUsingPOST(body: TradeOfferRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).addOfferUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).deleteOfferUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary editOffer
         * @param {TradeOfferRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editOfferUsingPOST(body: TradeOfferRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).editOfferUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).getOfferUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listAvailableOffers
         * @param {AvailableTradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailableOffersUsingPOST(body: AvailableTradeOfferListRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).listAvailableOffersUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary listOffers
         * @param {TradeOfferListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOffersUsingPOST(body: TradeOfferListRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).listOffersUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary publishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).publishOfferUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary unpublishOffer
         * @param {TradeOfferUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
            return TradeOfferWsControllerApiFp(configuration).unpublishOfferUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * TradeOfferWsControllerApi - object-oriented interface
 * @export
 * @class TradeOfferWsControllerApi
 * @extends {BaseAPI}
 */
export class TradeOfferWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary addOffer
     * @param {TradeOfferRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public addOfferUsingPOST(body: TradeOfferRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).addOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteOffer
     * @param {TradeOfferUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public deleteOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).deleteOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary editOffer
     * @param {TradeOfferRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public editOfferUsingPOST(body: TradeOfferRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).editOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOffer
     * @param {TradeOfferUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public getOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).getOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listAvailableOffers
     * @param {AvailableTradeOfferListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public listAvailableOffersUsingPOST(body: AvailableTradeOfferListRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).listAvailableOffersUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary listOffers
     * @param {TradeOfferListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public listOffersUsingPOST(body: TradeOfferListRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).listOffersUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary publishOffer
     * @param {TradeOfferUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public publishOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).publishOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary unpublishOffer
     * @param {TradeOfferUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeOfferWsControllerApi
     */
    public unpublishOfferUsingPOST(body: TradeOfferUidRequest, options?: any) {
        return TradeOfferWsControllerApiFp(this.configuration).unpublishOfferUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TransactionWsControllerApi - fetch parameter creator
 * @export
 */
export const TransactionWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTransactionList
         * @param {GetBrandedCardTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST(body: GetBrandedCardTransactionListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getTransactionListUsingPOST.');
            }
            const localVarPath = `/transaction/list/brandedcard`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetBrandedCardTransactionListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTransactionList
         * @param {GetTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST1(body: GetTransactionListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getTransactionListUsingPOST1.');
            }
            const localVarPath = `/transaction/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetTransactionListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionWsControllerApi - functional programming interface
 * @export
 */
export const TransactionWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getTransactionList
         * @param {GetBrandedCardTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST(body: GetBrandedCardTransactionListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BrandedCardTransactionListResponse> {
            const localVarFetchArgs = TransactionWsControllerApiFetchParamCreator(configuration).getTransactionListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTransactionList
         * @param {GetTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST1(body: GetTransactionListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransactionListResponse> {
            const localVarFetchArgs = TransactionWsControllerApiFetchParamCreator(configuration).getTransactionListUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TransactionWsControllerApi - factory interface
 * @export
 */
export const TransactionWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getTransactionList
         * @param {GetBrandedCardTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST(body: GetBrandedCardTransactionListRequest, options?: any) {
            return TransactionWsControllerApiFp(configuration).getTransactionListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTransactionList
         * @param {GetTransactionListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionListUsingPOST1(body: GetTransactionListRequest, options?: any) {
            return TransactionWsControllerApiFp(configuration).getTransactionListUsingPOST1(body, options)(fetch, basePath);
        },
    };
};

/**
 * TransactionWsControllerApi - object-oriented interface
 * @export
 * @class TransactionWsControllerApi
 * @extends {BaseAPI}
 */
export class TransactionWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getTransactionList
     * @param {GetBrandedCardTransactionListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionWsControllerApi
     */
    public getTransactionListUsingPOST(body: GetBrandedCardTransactionListRequest, options?: any) {
        return TransactionWsControllerApiFp(this.configuration).getTransactionListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTransactionList
     * @param {GetTransactionListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionWsControllerApi
     */
    public getTransactionListUsingPOST1(body: GetTransactionListRequest, options?: any) {
        return TransactionWsControllerApiFp(this.configuration).getTransactionListUsingPOST1(body, options)(this.fetch, this.basePath);
    }

}
/**
 * TransferWsControllerApi - fetch parameter creator
 * @export
 */
export const TransferWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accept
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST(body: TransferTransactionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling acceptUsingPOST.');
            }
            const localVarPath = `/transfer/accept`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransferTransactionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancel
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUsingPOST(body: TransferTransactionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cancelUsingPOST.');
            }
            const localVarPath = `/transfer/cancel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransferTransactionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reject
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectUsingPOST(body: TransferTransactionRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling rejectUsingPOST.');
            }
            const localVarPath = `/transfer/reject`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TransferTransactionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransferWsControllerApi - functional programming interface
 * @export
 */
export const TransferWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary accept
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST(body: TransferTransactionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransferTransactionResponse> {
            const localVarFetchArgs = TransferWsControllerApiFetchParamCreator(configuration).acceptUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary cancel
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUsingPOST(body: TransferTransactionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransferTransactionResponse> {
            const localVarFetchArgs = TransferWsControllerApiFetchParamCreator(configuration).cancelUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary reject
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectUsingPOST(body: TransferTransactionRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TransferTransactionResponse> {
            const localVarFetchArgs = TransferWsControllerApiFetchParamCreator(configuration).rejectUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TransferWsControllerApi - factory interface
 * @export
 */
export const TransferWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary accept
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUsingPOST(body: TransferTransactionRequest, options?: any) {
            return TransferWsControllerApiFp(configuration).acceptUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary cancel
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUsingPOST(body: TransferTransactionRequest, options?: any) {
            return TransferWsControllerApiFp(configuration).cancelUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary reject
         * @param {TransferTransactionRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectUsingPOST(body: TransferTransactionRequest, options?: any) {
            return TransferWsControllerApiFp(configuration).rejectUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * TransferWsControllerApi - object-oriented interface
 * @export
 * @class TransferWsControllerApi
 * @extends {BaseAPI}
 */
export class TransferWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary accept
     * @param {TransferTransactionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferWsControllerApi
     */
    public acceptUsingPOST(body: TransferTransactionRequest, options?: any) {
        return TransferWsControllerApiFp(this.configuration).acceptUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary cancel
     * @param {TransferTransactionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferWsControllerApi
     */
    public cancelUsingPOST(body: TransferTransactionRequest, options?: any) {
        return TransferWsControllerApiFp(this.configuration).cancelUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary reject
     * @param {TransferTransactionRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransferWsControllerApi
     */
    public rejectUsingPOST(body: TransferTransactionRequest, options?: any) {
        return TransferWsControllerApiFp(this.configuration).rejectUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * VoucherWsControllerApi - fetch parameter creator
 * @export
 */
export const VoucherWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activate
         * @param {VoucherActivateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST1(body: VoucherActivateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling activateUsingPOST1.');
            }
            const localVarPath = `/voucher/activate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VoucherActivateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list
         * @param {VoucherListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST11(body: VoucherListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listUsingPOST11.');
            }
            const localVarPath = `/voucher/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VoucherListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoucherWsControllerApi - functional programming interface
 * @export
 */
export const VoucherWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary activate
         * @param {VoucherActivateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST1(body: VoucherActivateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherListResponse> {
            const localVarFetchArgs = VoucherWsControllerApiFetchParamCreator(configuration).activateUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary list
         * @param {VoucherListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST11(body: VoucherListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VoucherListResponse> {
            const localVarFetchArgs = VoucherWsControllerApiFetchParamCreator(configuration).listUsingPOST11(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VoucherWsControllerApi - factory interface
 * @export
 */
export const VoucherWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary activate
         * @param {VoucherActivateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateUsingPOST1(body: VoucherActivateRequest, options?: any) {
            return VoucherWsControllerApiFp(configuration).activateUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary list
         * @param {VoucherListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingPOST11(body: VoucherListRequest, options?: any) {
            return VoucherWsControllerApiFp(configuration).listUsingPOST11(body, options)(fetch, basePath);
        },
    };
};

/**
 * VoucherWsControllerApi - object-oriented interface
 * @export
 * @class VoucherWsControllerApi
 * @extends {BaseAPI}
 */
export class VoucherWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary activate
     * @param {VoucherActivateRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherWsControllerApi
     */
    public activateUsingPOST1(body: VoucherActivateRequest, options?: any) {
        return VoucherWsControllerApiFp(this.configuration).activateUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary list
     * @param {VoucherListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoucherWsControllerApi
     */
    public listUsingPOST11(body: VoucherListRequest, options?: any) {
        return VoucherWsControllerApiFp(this.configuration).listUsingPOST11(body, options)(this.fetch, this.basePath);
    }

}
/**
 * WalletWsControllerApi - fetch parameter creator
 * @export
 */
export const WalletWsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary checkAccountIban
         * @param {CheckIBANRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountIbanUsingPOST(body: CheckIBANRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkAccountIbanUsingPOST.');
            }
            const localVarPath = `/account/check/iban`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CheckIBANRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWallet
         * @param {NewAccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletUsingPOST(body: NewAccountWalletRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createWalletUsingPOST.');
            }
            const localVarPath = `/account/wallet/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewAccountWalletRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary disableWallet
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableWalletUsingPOST(body: AccountWalletRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling disableWalletUsingPOST.');
            }
            const localVarPath = `/account/wallet/disable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountWalletRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountIbanList
         * @param {GetAccountIBANListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIbanListUsingPOST(body: GetAccountIBANListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountIbanListUsingPOST.');
            }
            const localVarPath = `/account/iban`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountIBANListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountWallet
         * @param {GetAccountWalletListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWalletUsingPOST(body: GetAccountWalletListRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountWalletUsingPOST.');
            }
            const localVarPath = `/account/wallet`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetAccountWalletListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setWalletAsPrimary
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletAsPrimaryUsingPOST(body: AccountWalletRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling setWalletAsPrimaryUsingPOST.');
            }
            const localVarPath = `/account/wallet/primary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AccountWalletRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletWsControllerApi - functional programming interface
 * @export
 */
export const WalletWsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary checkAccountIban
         * @param {CheckIBANRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountIbanUsingPOST(body: CheckIBANRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CheckIBANResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).checkAccountIbanUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWallet
         * @param {NewAccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletUsingPOST(body: NewAccountWalletRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WalletResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).createWalletUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary disableWallet
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableWalletUsingPOST(body: AccountWalletRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WalletResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).disableWalletUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountIbanList
         * @param {GetAccountIBANListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIbanListUsingPOST(body: GetAccountIBANListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IBANResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).getAccountIbanListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountWallet
         * @param {GetAccountWalletListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWalletUsingPOST(body: GetAccountWalletListRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WalletResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).getAccountWalletUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setWalletAsPrimary
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletAsPrimaryUsingPOST(body: AccountWalletRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WalletResponse> {
            const localVarFetchArgs = WalletWsControllerApiFetchParamCreator(configuration).setWalletAsPrimaryUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WalletWsControllerApi - factory interface
 * @export
 */
export const WalletWsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary checkAccountIban
         * @param {CheckIBANRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountIbanUsingPOST(body: CheckIBANRequest, options?: any) {
            return WalletWsControllerApiFp(configuration).checkAccountIbanUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWallet
         * @param {NewAccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWalletUsingPOST(body: NewAccountWalletRequest, language?: string, options?: any) {
            return WalletWsControllerApiFp(configuration).createWalletUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary disableWallet
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableWalletUsingPOST(body: AccountWalletRequest, language?: string, options?: any) {
            return WalletWsControllerApiFp(configuration).disableWalletUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountIbanList
         * @param {GetAccountIBANListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountIbanListUsingPOST(body: GetAccountIBANListRequest, options?: any) {
            return WalletWsControllerApiFp(configuration).getAccountIbanListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountWallet
         * @param {GetAccountWalletListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountWalletUsingPOST(body: GetAccountWalletListRequest, language?: string, options?: any) {
            return WalletWsControllerApiFp(configuration).getAccountWalletUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setWalletAsPrimary
         * @param {AccountWalletRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setWalletAsPrimaryUsingPOST(body: AccountWalletRequest, language?: string, options?: any) {
            return WalletWsControllerApiFp(configuration).setWalletAsPrimaryUsingPOST(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * WalletWsControllerApi - object-oriented interface
 * @export
 * @class WalletWsControllerApi
 * @extends {BaseAPI}
 */
export class WalletWsControllerApi extends BaseAPI {
    /**
     * 
     * @summary checkAccountIban
     * @param {CheckIBANRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public checkAccountIbanUsingPOST(body: CheckIBANRequest, options?: any) {
        return WalletWsControllerApiFp(this.configuration).checkAccountIbanUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWallet
     * @param {NewAccountWalletRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public createWalletUsingPOST(body: NewAccountWalletRequest, language?: string, options?: any) {
        return WalletWsControllerApiFp(this.configuration).createWalletUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary disableWallet
     * @param {AccountWalletRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public disableWalletUsingPOST(body: AccountWalletRequest, language?: string, options?: any) {
        return WalletWsControllerApiFp(this.configuration).disableWalletUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountIbanList
     * @param {GetAccountIBANListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public getAccountIbanListUsingPOST(body: GetAccountIBANListRequest, options?: any) {
        return WalletWsControllerApiFp(this.configuration).getAccountIbanListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountWallet
     * @param {GetAccountWalletListRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public getAccountWalletUsingPOST(body: GetAccountWalletListRequest, language?: string, options?: any) {
        return WalletWsControllerApiFp(this.configuration).getAccountWalletUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setWalletAsPrimary
     * @param {AccountWalletRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletWsControllerApi
     */
    public setWalletAsPrimaryUsingPOST(body: AccountWalletRequest, language?: string, options?: any) {
        return WalletWsControllerApiFp(this.configuration).setWalletAsPrimaryUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

}

import Accordion from 'framework7/components/accordion/accordion.js';
import Autocomplete from 'framework7/components/autocomplete/autocomplete.js';
import Card from 'framework7/components/card/card.js';
import Checkbox from 'framework7/components/checkbox/checkbox.js';
import Chip from 'framework7/components/chip/chip.js';
import DataTable from 'framework7/components/data-table/data-table.js';
import Dialog from 'framework7/components/dialog/dialog.js';
import Fab from 'framework7/components/fab/fab.js';
import Form from 'framework7/components/form/form.js';
import Grid from 'framework7/components/grid/grid.js';
import InfiniteScroll from 'framework7/components/infinite-scroll/infinite-scroll.js';
import Input from 'framework7/components/input/input.js';
import Lazy from 'framework7/components/lazy/lazy.js';
import Notification from 'framework7/components/notification/notification.js';
import Panel from 'framework7/components/panel/panel.js';
import Picker from 'framework7/components/picker/picker.js';
import Popup from 'framework7/components/popup/popup.js';
import Preloader from 'framework7/components/preloader/preloader.js';
import Progressbar from 'framework7/components/progressbar/progressbar.js';
import Radio from 'framework7/components/radio/radio.js';
import Searchbar from 'framework7/components/searchbar/searchbar.js';
import Sheet from 'framework7/components/sheet/sheet.js';
import Stepper from 'framework7/components/stepper/stepper.js';
import Swipeout from 'framework7/components/swipeout/swipeout.js';
import Swiper from 'framework7/components/swiper/swiper.js';
import Tabs from 'framework7/components/tabs/tabs.js';
import TextEditor from 'framework7/components/text-editor/text-editor.js';
import Toast from 'framework7/components/toast/toast.js';
import Toggle from 'framework7/components/toggle/toggle.js';
import Tooltip from 'framework7/components/tooltip/tooltip.js';
import TreeView from 'framework7/components/treeview/treeview.js';
import Typography from 'framework7/components/typography/typography.js';
import Framework7, { Device, Request, Utils } from 'framework7/framework7-lite.esm.js';

Framework7.use([
    Dialog,
    Popup,
    Sheet,
    Preloader,
    Progressbar,
    Accordion,
    Tabs,
    Panel,
    Card,
    Chip,
    Form,
    Input,
    Checkbox,
    Radio,
    Toggle,
    Grid,
    Picker,
    InfiniteScroll,
    Lazy,
    DataTable,
    Fab,
    Searchbar,
    Swiper,
    Notification,
    Autocomplete,
    Tooltip,
    Typography,
    Stepper,
    TextEditor,
    TreeView,
    Toast,
    Swipeout,
]);

export default Framework7;
export { Device, Request, Utils };

export enum Sheets {
    ADD_TO_CART_SHEET = 'ADD_TO_CART_SHEET',
    CHANGE_ORDER_STATUS_SHEET = 'CHANGE_ORDER_STATUS_SHEET',
    EDIT_TRACKING_INFO_MENU_SHEET = 'EDIT_TRACKING_INFO_MENU_SHEET',
    ORDER_REFUND_SHEET = 'ORDER_REFUND_SHEET',
    PAYMENT_CARD_SELECT_PAYMENT_METHOD_SHEET = 'PAYMENT_CARD_SELECT_PAYMENT_METHOD_SHEET',
    GROUP_INFO_MORE_SHEET = 'GROUP_INFO_MORE_SHEET',
    PRODUCT_ACTIONS_SHEET = 'PRODUCT_ACTIONS_SHEET',
    PROMO_DETAILS_SHEET = 'PROMO_DETAILS_SHEET',
    CHOOSE_PAYOUT_SHEET = 'CHOOSE_PAYOUT_SHEET',
    EDIT_SETTINGS_MENU_SHEET = 'EDIT_SETTINGS_MENU_SHEET',
    DEAL_TYPE_SHEET = 'DEAL_TYPE_SHEET',
    CURRENCY_SHEET = 'CURRENCY_SHEET',
    CATEGORY_SHEET = 'CATEGORY_SHEET',
    SMALL_SELECTOR_SHEET = 'SMALL_SELECTOR_SHEET',
    DELIVERY_ITEM_ACTION_MENU = 'DELIVERY_ITEM_ACTION_MENU',
    SELECT_CUSTOMER_LOCATION_SHEET = 'SELECT_CUSTOMER_LOCATION_SHEET',
    PAYMENT_METHOD_SHEET = 'PAYMENT_METHOD_SHEET',
    CVC_SHEET = 'CVC_SHEET',
    IMPORT_PRODUCTS_MORE_SHEET = 'IMPORT_PRODUCTS_MORE_SHEET',
    SETUP_PAYOUT_SHEET = 'SETUP_PAYOUT_SHEET',
    DATEPICKER_SHEET = 'DATEPICKER_SHEET',
    STORE_INFO_SHEET = 'STORE_INFO_SHEET',
    CHANGE_PASSWORD_SHEET = 'CHANGE_PASSWORD_SHEET',
    CHANGE_EMAIL_SHEET = 'CHANGE_EMAIL_SHEET',
    CHANGE_PHONE_SHEET = 'CHANGE_PHONE_SHEET',
    FILE_TYPE_SHEET = 'FILE_TYPE_SHEET',
    SOURCE_SELECT_SHEET = 'SOURCE_SELECT_SHEET',
    SOURCE_URL_PARAMS_SHEET = 'SOURCE_URL_PARAMS_SHEET',
    IMPORT_SOURCE_OPTIONS_SHEET = 'IMPORT_SOURCE_OPTIONS_SHEET',
    IMPORT_LOG_SHEET = 'IMPORT_LOG_SHEET',
    REGION_AGREEMENT_SHEET = 'REGION_AGREEMENT_SHEET',
    TRANSACTION_INFO_SHEET = 'TRANSACTION_INFO_SHEET',
    SALE_CARD_SHEET = 'SALE_CARD_SHEET',
    SORT_BY_SHEET = 'SORT_BY_SHEET',
    SEND_TO_EMAIL_SHEET = 'SEND_TO_EMAIL_SHEET',
    NEW_DELIVERY_METHOD_SHEET = 'NEW_DELIVERY_METHOD_SHEET',
    WALLET_ACTIONS_SHEET = 'WALLET_ACTIONS_SHEET',
    CARD_ACTIONS_SHEET = 'CARD_ACTIONS_SHEET',
    MY_GOODS_ACTIONS_SHEET = 'MY_GOODS_ACTIONS_SHEET',
    RESTORE_SHEET = 'RESTORE_SHEET',
    START_CHAT_SHEET = 'START_CHAT_SHEET',
    SELECT_SHEET = 'SELECT_SHEET',
    SALE_PRODUCT_ACTIONS_SHEET = 'SALE_PRODUCT_ACTIONS_SHEET',
    ADD_PRODUCTS_SOURCES_SHEET = 'ADD_PRODUCTS_SOURCES_SHEET',
    EDIT_SALE_PRODUCT_SHEET = 'EDIT_SALE_PRODUCT_SHEET',
    EDIT_REVIEW_SHEET = 'EDIT_REVIEW_SHEET',
    REPORT_ABUSE_SHEET = 'REPORT_ABUSE_SHEET',
    SORT_REVIEWS_SHEET = 'SORT_REVIEWS_SHEET',
    SELECT_TIME_PERIOD_SHEET = 'SELECT_TIME_PERIOD_SHEET',
    DELIVERY_OPTION_SELECT_SHEET = 'DELIVERY_OPTION_SELECT_SHEET',
    INVITE_FRIEND_SHEET = 'INVITE_FRIEND_SHEET',
    CHANGE_REGION_SHEET = 'CHANGE_REGION_SHEET',
    EVENT_MODAL_SHEET = 'EVENT_MODAL_SHEET',
    NAVIGATE_TO_3DS_SHEET = 'NAVIGATE_TO_3DS_SHEET',
    PRODUCT_DELIVERIES_SHEET = 'PRODUCT_DELIVERIES_SHEET',
    BUBBLE_MESSAGE_SHEET = 'BUBBLE_MESSAGE_SHEET',
    MESSENGER_SHEET = 'MESSENGER_SHEET',
    SELECTION_TIME_SHEET = 'SELECTION_TIME_SHEET',
    PLATFORM_COURIER_SHEET = 'PLATFORM_COURIER_SHEET',
    SDEK_MAP_SIDE_SHEET = 'SDEK_MAP_SIDE_SHEET',
    SDEK_MAP_TARIFFS_SHEET = 'SDEK_MAP_TARIFFS_SHEET',
}

import cn from 'classnames';
import { f7, F7Navbar, NavLeft, NavTitle } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { WishListPageProps } from './WishListPage.types';

import { addToWishList } from '@/actions/productActions';
import { AppNavBar } from '@/components/AppNavBar';
import { Catalog } from '@/components/Catalog';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { Footer } from '@/components/Footer';
import { NotAvailableMessage } from '@/components/NotAvailableMessage';
import { LoadWishList } from '@/hoc/LoadWishList';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useErrorAlert } from '@/hooks/useErrorAlert';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { MSButtonBack } from '@/shared/UIKit/ButtonBack/ButtonBack';

import { Popups } from '@/types/popups';

import './WishListPage.less';

const WishListPageInner = ({ f7router }: WishListPageProps) => {
    const { t } = useTranslation();
    const logged = useAppSelector(isLoggedIn);

    const dispatch = useAppDispatch();

    const { resizeEvent } = useAppSelector((state) => state.rootReducer);
    const { productsWishListLoading, productsWishListLoadingError, productsWishList } = useAppSelector(
        (state) => state.productReducer,
    );

    useErrorAlert(productsWishListLoadingError);

    const addToWish = useCallback(
        (uid: string) => {
            if (logged) {
                dispatch(addToWishList(uid));
            } else {
                f7.popup.open(`.${Popups.LOGIN_POPUP}`);
            }
        },
        [dispatch, logged],
    );

    return (
        <CustomPage
            id="wish_list"
            className={cn('wish-list-page', {
                empty: !productsWishListLoading && !productsWishList?.length,
            })}
            name="wish-list"
        >
            <LoadWishList />
            {resizeEvent.width < 568 ? (
                <F7Navbar className="wish-list-page__navbar-mobile" noHairline noShadow>
                    <NavLeft>
                        <MSButtonBack router={f7.view.main.router} />
                    </NavLeft>
                    <NavTitle>{t('Your wish list')}</NavTitle>
                </F7Navbar>
            ) : (
                <AppNavBar f7router={f7router} slot="fixed" />
            )}

            <div className="wish-list-page__content">
                {!!productsWishList?.length && (
                    <>
                        <div
                            className={cn('catalog-block-title', 'block-title', 'block-title-medium', 'pure-hidden-xs')}
                        >
                            {t('Your wish list')}
                        </div>
                        <Catalog
                            className="wish-list-catalog"
                            items={productsWishList}
                            showFeaturesHighlight
                            addToWish={addToWish}
                        />
                    </>
                )}
                {!productsWishListLoading && productsWishList && !productsWishList.length && (
                    <NotAvailableMessage
                        title={t('Your wish list is empty')}
                        subtitle={t('Add product to wish list for quick access to it')}
                    />
                )}
            </div>

            <Footer />
        </CustomPage>
    );
};

export const WishListPage = ConnectPageWrapper(WishListPageInner);

import dayjs from 'dayjs';

import { getErrorMessage, handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    DeliveryMethod,
    DeliveryMethodControllerApi,
    PlatformDeliveryControllerApi,
    PlatformDeliveryMethod,
    UidRequest,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const DELIVERY_METHODS_LOADING = 'DELIVERY_METHODS_LOADING' as const;
export const DELIVERY_METHODS_ERROR = 'DELIVERY_METHODS_ERROR' as const;

export const DELIVERY_METHODS_ALL_SUCCESS = 'DELIVERY_METHODS_ALL_SUCCESS' as const;

export const PLATFORM_DELIVERY_METHODS_ALL_SUCCESS = 'PLATFORM_DELIVERY_METHODS_ALL_SUCCESS' as const;

export const DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS = 'DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS' as const;

export const PLATFORM_DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS =
    'PLATFORM_DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS' as const;

export const DELIVERY_METHOD_BY_UID_SUCCESS = 'DELIVERY_METHOD_BY_UID_SUCCESS' as const;

export const DELIVERY_METHOD_SET_LAST_CREATED_UID = 'DELIVERY_METHOD_SET_LAST_CREATED_UID' as const;

const deliveryMethodsLoading = () => ({
    type: DELIVERY_METHODS_LOADING,
});

const deliveryMethodsError = (error: unknown) => ({ type: DELIVERY_METHODS_ERROR, error });

const allDeliveryMethodsSuccess = (items: DeliveryMethod[]) => ({
    type: DELIVERY_METHODS_ALL_SUCCESS,
    items,
});

const allPlatformDeliveryMethodsSuccess = (items: PlatformDeliveryMethod[]) => ({
    type: PLATFORM_DELIVERY_METHODS_ALL_SUCCESS,
    items,
});

const deliveryMethodAddOrUpdateSuccess = (item: DeliveryMethod) => ({
    type: DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS,
    item,
});

const deliveryPlatformMethodAddOrUpdateSuccess = (item: PlatformDeliveryMethod) => ({
    type: PLATFORM_DELIVERY_METHODS_ADD_OR_UPDATE_SUCCESS,
    item,
});

const deliveryMethodByUidSuccess = (item: DeliveryMethod) => ({
    type: DELIVERY_METHOD_BY_UID_SUCCESS,
    item,
});

export const setLastCreatedMethodUid = (uid: string) => ({
    type: DELIVERY_METHOD_SET_LAST_CREATED_UID,
    uid,
});

export type DeliveryMethodsActions = ReturnType<
    | typeof deliveryMethodsLoading
    | typeof deliveryMethodsError
    | typeof allDeliveryMethodsSuccess
    | typeof allPlatformDeliveryMethodsSuccess
    | typeof deliveryMethodAddOrUpdateSuccess
    | typeof deliveryMethodByUidSuccess
    | typeof deliveryPlatformMethodAddOrUpdateSuccess
    | typeof setLastCreatedMethodUid
>;

const deliveryMethodControllerApi = new DeliveryMethodControllerApi();
const platformDeliveryControllerApi = new PlatformDeliveryControllerApi();

export const createOrUpdateDeliveryMethod =
    (deliveryMethod: DeliveryMethod) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(deliveryMethodsLoading());
        try {
            const isUpdate = !!deliveryMethod.uid?.length;

            const result = await (isUpdate
                ? deliveryMethodControllerApi.updateAccountDeliveryMethodUsingPOST(
                      deliveryMethod,
                      getLanguageCode(getState()),
                  )
                : deliveryMethodControllerApi.createAccountDeliveryMethodUsingPUT(
                      deliveryMethod,
                      getLanguageCode(getState()),
                  ));

            handleResponseAndThrowAnErrorIfExists(result);

            dispatch(deliveryMethodAddOrUpdateSuccess(result.body[0]));
            if (!isUpdate) {
                dispatch(setLastCreatedMethodUid(result.body[0].uid));
            }
        } catch (error) {
            console.error('at deliveryMethodsActions in createOrUpdateDeliveryMethod', error);

            dispatch(deliveryMethodsError(error.message));
        }
    };

export const createOrUpdatePlatformDeliveryMethod =
    (deliveryMethod: PlatformDeliveryMethod) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(deliveryMethodsLoading());
        try {
            const result = await deliveryMethodControllerApi.accountPlatformDeliveryMethodsAddEditUsingPOST(
                deliveryMethod,
            );

            handleResponseAndThrowAnErrorIfExists(result);
            dispatch(deliveryPlatformMethodAddOrUpdateSuccess(result.body[0]));

            return getState();
        } catch (error) {
            console.error('at deliveryMethodsActions in createOrUpdatePlatformDeliveryMethod', error);
            dispatch(deliveryMethodsError(getErrorMessage(error)));

            return getState();
        }
    };

export const getAllDeliveryMethods = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    if (!isLoggedIn(state)) {
        return;
    }

    dispatch(deliveryMethodsLoading());
    try {
        const result = await deliveryMethodControllerApi.getAccountDeliveryMethodsUsingGET(getLanguageCode(state));

        handleResponseAndThrowAnErrorIfExists(result);

        dispatch(allDeliveryMethodsSuccess(result.body || []));
    } catch (error) {
        console.error('at deliveryMethodsActions in getAllDeliveryMethods', error);

        dispatch(deliveryMethodsError(error.message));
    }
};

export const getAllPlatformDeliveryMethods = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    if (!isLoggedIn(state)) {
        return;
    }

    dispatch(deliveryMethodsLoading());
    try {
        const result = await deliveryMethodControllerApi.getAccountPlatformDeliveryMethodsUsingGET();

        handleResponseAndThrowAnErrorIfExists(result);

        dispatch(allPlatformDeliveryMethodsSuccess(result.body || []));
    } catch (error) {
        console.error('at deliveryMethodsActions in getAllDeliveryMethods', error);

        dispatch(deliveryMethodsError(error.message));
    }
};

export const deletePlatformDeliveryMethod =
    (method: UidRequest) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        if (!isLoggedIn(state)) {
            return;
        }

        try {
            const result = await deliveryMethodControllerApi.deleteAccountPlatformDeliveryMethodUsingDELETE(method, {
                headers: { 'Content-Type': 'application/json' },
            });

            handleResponseAndThrowAnErrorIfExists(result);

            dispatch(allPlatformDeliveryMethodsSuccess(result.body || []));
        } catch (error) {
            console.error('at deliveryMethodsActions in deletePlatformDeliveryMethod', error);

            dispatch(deliveryMethodsError(error.message));
        }
    };

export const getDeliveryMethodByUid =
    (uid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        if (!isLoggedIn(state)) {
            return;
        }

        dispatch(deliveryMethodsLoading());

        try {
            const result = await deliveryMethodControllerApi.getAccountDeliveryMethodsUsingGET(
                getLanguageCode(state),
                uid,
            );
            handleResponseAndThrowAnErrorIfExists(result);
            dispatch(deliveryMethodByUidSuccess(result.body[0]));
        } catch (error) {
            console.error('at deliveryMethodsActions in getDeliveryMethodByUid', error);

            dispatch(deliveryMethodsError(error.message));
        }
    };

export const deleteDeliveryMethod =
    (uid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        if (!isLoggedIn(state)) {
            return;
        }

        try {
            const result = await deliveryMethodControllerApi.updateAccountDeliveryMethodUsingDELETE(
                { uid },
                getLanguageCode(state),
                { headers: { 'Content-Type': 'application/json' } },
            );
            handleResponseAndThrowAnErrorIfExists(result);

            dispatch(allDeliveryMethodsSuccess(result.body || []));
        } catch (error) {
            console.error('at deliveryMethodsActions in deleteDeliveryMethod', error);

            dispatch(deliveryMethodsError(error.message));
        }
    };

export const getShippingOrderList = (uid: string) => async (dispatch: AppDispatch) => {
    try {
        const result = await platformDeliveryControllerApi.platformDeliveryShippingOrderListUsingPOST({
            productOrderUid: uid,
        });

        return result?.body?.[0] ?? {};
    } catch (error) {
        console.error('at deliveryMethodsActions in getShippingOrderList', error);

        dispatch(deliveryMethodsError(error.message));
        return undefined;
    }
};

export const createCourierCallRequest =
    ({
        uid,
        intakeDate,
        intakeFromHours,
        intakeToHours,
        lunchFromHours,
        lunchToHours,
    }: {
        uid: string;
        intakeDate: Date;
        intakeFromHours: string;
        intakeToHours: string;
        lunchFromHours?: string;
        lunchToHours?: string;
    }) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const formatTime = (hours: string | undefined): string | undefined =>
            !hours ? undefined : `${parseInt(hours) < 10 ? 0 : ''}${hours}:00`;

        try {
            const response = await platformDeliveryControllerApi.platformDeliveryShippingDocumentUsingPOST(
                {
                    shippingOrderUid: [uid],
                    intakeDate: dayjs(intakeDate).format('YYYY-MM-DD'),
                    intakeFrom: formatTime(intakeFromHours),
                    intakeTo: formatTime(intakeToHours),
                    lunchFrom: formatTime(lunchFromHours),
                    lunchTo: formatTime(lunchToHours),
                },
                getLanguageCode(getState()),
            );

            return { error: undefined, data: response.body?.[0] };
        } catch (error) {
            console.error('at deliveryMethodsActions in createCourierCallRequest', error);
            const errorMessage = getErrorMessage(error);
            dispatch(deliveryMethodsError(errorMessage));
            return { error: errorMessage };
        }
    };

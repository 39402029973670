import cn from 'classnames';
import { Block, BlockTitle, f7, FabBackdrop, Link, PageContent, Preloader, Row } from 'framework7-react';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddProductFab } from './AddProductFab';

import { MyGoodsNavbar } from './MyGoodsNavbar';

import { MyGoodsPageProps } from './MyGoodsPage.types';

import {
    archivateProducts,
    deleteMyGoods,
    loadMyGoodsList,
    myGoodsSaveFilterAction,
    restoreProducts,
} from '@/actions/myGoodsActions';
import { changeProductStatus } from '@/actions/productStatusActions';
import { messengerService } from '@/api/messengerService';
import { IcArchive, IcArrowRight, MascotNotFound } from '@/assets';
import { CatalogList } from '@/components/CatalogList';
import { ChangeQuantityPopup } from '@/components/ChangeQuantityPopup';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomChip } from '@/components/CustomChip';
import { CustomPage } from '@/components/CustomPage';
import { CustomSelectValue } from '@/components/CustomSelect';
import { FilterPopover } from '@/components/FilterPopover';
import { useFilterModal } from '@/components/FilterPopover/useFilterModal';
import { SellerAreaSearchbar } from '@/components/SellerAreaSearchbar';
import { SetupPayoutModal } from '@/components/SetupPayoutModal/SetupPayoutModal';
import { StatusFiltersMobilePopup } from '@/components/StatusFiltersMobilePopup';
import { ThemedButton } from '@/components/ThemedButton';
import { Product, ProductListRequest } from '@/generated/marketplaceapi';
import { LoadMyGoodsList } from '@/hoc/LoadMyGoodsList';
import { LoadPayoutSettings } from '@/hoc/LoadPayoutSettings';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAlertProductStatusChange } from '@/hooks/useAlertProductStatusChange';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useErrorAlert } from '@/hooks/useErrorAlert';
import { usePreloader } from '@/hooks/usePreloader';
import { SellerAreaLayout } from '@/layouts/SellerAreaLayout';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsBankAccountSettingsEmpty } from '@/selectors/getIsBankAccountSettingsEmpty';
import { getIsBankCardSettingsEmpty } from '@/selectors/getIsBankCardSettingsEmpty';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getIsShowBusinessVerificationBanner } from '@/selectors/getIsShowBusinessVerificationBanner';

import { openConfirm } from '@/shared/confirmDialog';
import { ProductType } from '@/types/productType';
import { Sheets } from '@/types/sheets';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { classificatorToCustomSelectValue } from '@/utils';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

import './MyGoodsPage.less';

const EnablePaymentOptionCard = React.lazy(() => import('@/components/EnablePaymentOptionCard'));

const MyGoodsPageInner = ({ f7router, archive }: MyGoodsPageProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(getIsMobile);
    const activeType = useAppSelector(getActiveProductType);
    const showBanner = useAppSelector(getIsShowBusinessVerificationBanner);

    const {
        error,
        loading,
        products,
        filteredCount,
        selectedStatuses: selectedStatusesState,
    } = useAppSelector((state) => state.myGoodsReducer);

    const productStatusReducer = useAppSelector((state) => state.productStatusReducer);
    const statuses = useAppSelector((state) => state.classificatorReducer.entitiesClassificators.Product_Status);

    const filterPopoverOptions = useMemo(() => statuses.map(classificatorToCustomSelectValue), [statuses]);

    const [editMode, setEditMode] = useState(!isMobile);
    const [enableEdit, disableEdit] = useBooleanState(setEditMode);

    const clearFilterState = useCallback(() => dispatch(myGoodsSaveFilterAction([])), [dispatch]);

    const {
        popoverProps: statusPopoverProps,
        popupProps: filterPopupProps,
        filterButtonProps,
        selectedOptions: selectedStatuses,
        sortBy,
        setSelectedOptions,
    } = useFilterModal(isMobile, undefined, selectedStatusesState, clearFilterState);

    const selectedStatusesOptions = useMemo(
        () => filterPopoverOptions.filter((item) => selectedStatuses.includes(item.value)),
        [filterPopoverOptions, selectedStatuses],
    );

    const handleDeleteStatusFromFilter = useCallback(
        (val: string) => {
            setSelectedOptions((prev) => prev?.filter((item) => item !== val));
        },
        [setSelectedOptions],
    );

    const productStatusesOptions = useAppSelector<CustomSelectValue[]>((state) =>
        state.classificatorReducer.entitiesClassificators.Product_Status.filter((status) => {
            if (activeType === ProductType.Service) {
                return (
                    (status.code as unknown as Product.StatusEnum) !== Product.StatusEnum.DSC &&
                    (status.code as unknown as Product.StatusEnum) !== Product.StatusEnum.OOS
                );
            }
            return true;
        }).map((status) => ({
            value: status.code,
            label: status.value,
        })),
    );
    const emptyPayoutSettings = useAppSelector(
        (state) => !getIsBankAccountSettingsEmpty(state) && !getIsBankCardSettingsEmpty(state),
    );
    const { firstProductCreated } = useAppSelector((state) => state.productCreateReducer);

    const alertStatusChange = useAlertProductStatusChange();

    useErrorAlert(error);

    useErrorAlert(t(productStatusReducer.error));

    usePreloader(productStatusReducer.loading);

    const [isSetupPayoutModalOpened, setIsSetupPayoutModalOpened] = useState(false);
    const [openSetupPayoutModal, closeSetupPayoutModal] = useBooleanState(setIsSetupPayoutModalOpened);
    const setupPayoutModalAvailable = useMemo(
        () => emptyPayoutSettings && firstProductCreated && isSetupPayoutModalOpened && products?.length === 1,
        [emptyPayoutSettings, firstProductCreated, isSetupPayoutModalOpened, products?.length],
    );

    const [searchedName, setSearchedName] = useState('');
    const [selectedProducts, setSelectedProducts] = useState<Set<string>>(new Set());

    const selectedProductsStatuses = useMemo(
        () =>
            Array.from(new Set(products?.filter((item) => selectedProducts.has(item.uid)).map((item) => item.status))),
        [products, selectedProducts],
    );
    const selectedProduct = useMemo(
        () => (selectedProducts.size === 1 ? products.find((item) => selectedProducts.has(item.uid)) : undefined),
        [products, selectedProducts],
    );

    const handleDisableEdit = useCallback(() => {
        disableEdit();
        setSelectedProducts(new Set());
    }, [disableEdit]);

    const [changeQtyPopupOpened, setChangeQtyPopupOpened] = useState(false);
    const [openQtyPopup, closeQtyPopup] = useBooleanState(setChangeQtyPopupOpened);

    const hasProducts = useMemo(
        () => !!products?.length || !!selectedStatuses?.length || !!searchedName?.trim(),
        [products, searchedName, selectedStatuses?.length],
    );

    const handleSearch = useMemo(
        () => (dispatchFn: typeof dispatch, params: ProductListRequest, updateCount?: boolean) => {
            dispatchFn(
                loadMyGoodsList(
                    {
                        ...params,
                        statuses: params.statuses?.length ? params.statuses : undefined,
                    },
                    updateCount,
                ),
            );
        },
        [],
    );

    const handlePresearch = useMemo(
        () => (dispatchFn: typeof dispatch, params: ProductListRequest) => handleSearch(dispatchFn, params, true),
        [handleSearch],
    );

    const handleItemSelect = useCallback((uid: string) => {
        setSelectedProducts((prev) => {
            const updated = new Set(prev);
            if (updated.has(uid)) {
                updated.delete(uid);
                return updated;
            } else {
                return new Set(updated.add(uid));
            }
        });
    }, []);

    const onSearchbarChange = useCallback((_, query: string) => setSearchedName(query), []);

    const clearSearchbar = useCallback(() => setSearchedName(''), []);

    const selectProductHandle = useCallback(
        (uid: string) => {
            if (!archive) {
                f7router.navigate(`/profile/seller-area/my-goods/product-details/${uid}/`, {
                    reloadCurrent: true,
                    force: true,
                });
            }
        },
        [archive, f7router],
    );

    const handleSelectAll = useCallback(() => {
        setSelectedProducts((prev) =>
            prev.size !== 0 ? new Set<string>([]) : new Set(products?.map((item) => item.uid)),
        );
    }, [products]);

    const handleArchivateAllClick = useCallback(() => {
        openConfirm(t('Are you sure you want to archive selected items?'), () => {
            void dispatch(archivateProducts(Array.from(selectedProducts)));
            setSelectedProducts(new Set());
        });
    }, [dispatch, selectedProducts, t]);

    const handleRestoreProductsClick = useCallback(() => {
        f7.sheet.close(`.${Sheets.RESTORE_SHEET}`);
        void dispatch(restoreProducts(Array.from(selectedProducts)));
        setSelectedProducts(new Set());
        handleSearch(dispatch, {
            name: searchedName,
            archived: archive,
            statuses: selectedStatuses,
            sortBy,
        });
    }, [archive, dispatch, handleSearch, searchedName, selectedProducts, selectedStatuses, sortBy]);

    const handleClearArchive = useCallback(() => {
        openConfirm(t('Are you sure you want to remove all products from archive?'), () => {
            void dispatch(deleteMyGoods(products?.map((item) => item.uid)));
            setSelectedProducts(new Set());
        });
    }, [dispatch, products, t]);

    const handleDeleteFromArchive = useCallback(() => {
        openConfirm(t('Are you sure you want to remove selected products?'), () => {
            void dispatch(deleteMyGoods(Array.from(selectedProducts)));
            setSelectedProducts(new Set());
        });
    }, [dispatch, selectedProducts, t]);

    const handleChangeQtySubmit = useCallback(
        (qty: string) => {
            let quantity: number;
            try {
                quantity = parseFloat(qty);
            } catch (err) {
                console.error(err);
            }
            if (selectedProduct) {
                const { uid, status } = selectedProduct;
                dispatch(changeProductStatus(uid, status, Product.StatusEnum.PBL, quantity)).then(alertStatusChange);
            }
            closeQtyPopup();
        },
        [alertStatusChange, closeQtyPopup, dispatch, selectedProduct],
    );

    const productsList = useMemo(() => {
        if (loading)
            return (
                <Block className="product-list">
                    <Preloader />
                </Block>
            );

        if (!hasProducts) {
            return (
                <Block className="product-list">
                    <MascotNotFound />
                    <BlockTitle medium>
                        {archive
                            ? t('Archive is empty')
                            : activeType === ProductType.Advertisement
                            ? t('page.my_offers.empty_state.title.no_offers')
                            : activeType === ProductType.Service
                            ? t('services.seller_area.my_services.page.list_of_services.empty_state.title')
                            : t('No Products for sale')}
                    </BlockTitle>
                    {!archive && (
                        <>
                            <p style={isMobile ? {} : { width: 344 }}>
                                {activeType === ProductType.Advertisement
                                    ? t('page.my_offers.empty_state.description.add_your_first_offer')
                                    : activeType === ProductType.Service
                                    ? t(
                                          'services.seller_area.my_services.page.list_of_services.empty_state.description',
                                      )
                                    : t('NewProductMessage')}
                            </p>
                            <Block className="button-container">
                                <ThemedButton className="my-goods-page__themed-btn" href="add/" fill large round raised>
                                    {activeType === ProductType.Advertisement
                                        ? t('page.my_offers.button.add_offer')
                                        : activeType === ProductType.Service
                                        ? t(
                                              'services.seller_area.my_services.page.list_of_services.empty_state.button.add_services',
                                          )
                                        : t('Add Product')}
                                </ThemedButton>
                            </Block>
                        </>
                    )}
                </Block>
            );
        }

        return (
            <CatalogList
                archive={archive}
                onSelectAllClick={handleSelectAll}
                onSelectItem={handleItemSelect}
                selectedProducts={editMode ? selectedProducts : undefined}
                data={products}
                onClick={selectProductHandle}
            />
        );
    }, [
        activeType,
        archive,
        editMode,
        handleItemSelect,
        handleSelectAll,
        hasProducts,
        isMobile,
        loading,
        products,
        selectProductHandle,
        selectedProducts,
        t,
    ]);

    useEffect(() => {
        handleSearch(dispatch, {
            name: searchedName,
            archived: archive,
            statuses: selectedStatuses,
            sortBy,
        });
    }, [archive, dispatch, handleSearch, searchedName, selectedStatuses, sortBy]);

    return (
        <CustomPage
            loggedOnly
            id="my_goods"
            name="my-goods"
            className={cn('my-goods-page', { empty: !hasProducts, showBanner: showBanner && !isMobile })}
            pageContent={false}
        >
            <FabBackdrop />
            {!loading && hasProducts && isMobile && !editMode && !archive && (
                <AddProductFab position="right-bottom" slot="fixed" />
            )}

            <LoadMyGoodsList archived={archive} />
            <LoadPayoutSettings />

            <MyGoodsNavbar
                archive={archive}
                clearSearchbar={clearSearchbar}
                editMode={editMode}
                enableEdit={enableEdit}
                disableEdit={handleDisableEdit}
                filterButtonProps={filterButtonProps}
                handleArchivateAllClick={handleArchivateAllClick}
                handleClearArchive={handleClearArchive}
                handleDeleteFromArchive={handleDeleteFromArchive}
                handleRestoreProductsClick={handleRestoreProductsClick}
                hasProducts={hasProducts}
                onSearchbarChange={onSearchbarChange}
                openQtyPopup={openQtyPopup}
                selectedProduct={selectedProduct}
                selectedProducts={selectedProducts}
                selectedProductsStatuses={selectedProductsStatuses}
            />
            <PageContent>
                <SellerAreaLayout
                    selectedSubitem={archive ? 'SellerArea_Products_Archive' : 'SellerArea_Products_Active'}
                    selected="SellerArea_Products"
                    contentClassname="my-goods-page__content"
                >
                    {!isMobile && hasProducts && (
                        <Row resizableFixed className="my-goods-page__search-top">
                            <span className="my-goods-page__search-top-label">{`${t('Selected')}(${
                                selectedProducts.size
                            })`}</span>
                            {!archive && (
                                <FilterPopover
                                    {...statusPopoverProps}
                                    applyText={t('Show {{count}} Products', {
                                        count: filteredCount,
                                    })}
                                    options={productStatusesOptions}
                                    isMobile={isMobile}
                                    buttonProps={filterButtonProps}
                                />
                            )}
                            <SellerAreaSearchbar
                                className="my-goods-page__searchbar"
                                onSearchbarSearch={onSearchbarChange}
                                onSearchbarClear={clearSearchbar}
                            />
                        </Row>
                    )}
                    {isMobile &&
                        (selectedStatusesOptions?.length ? (
                            <div className="mobile-chips-container">
                                {selectedStatusesOptions.map((item) => (
                                    <CustomChip
                                        key={item.value}
                                        text={item.label}
                                        value={item.value}
                                        deletable
                                        onDelete={handleDeleteStatusFromFilter}
                                    />
                                ))}
                            </div>
                        ) : (
                            !archive && (
                                <Link
                                    href={`/profile/seller-area/my-goods/archive?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(
                                        activeType,
                                    )}`}
                                    className="archived-button"
                                >
                                    <div className="archived-button__icon">
                                        <IcArchive />
                                    </div>
                                    <span>{t('Archive')}</span>
                                    <IcArrowRight className="archived-button__chevron" />
                                </Link>
                            )
                        ))}
                    {messengerService.enabled && isMobile && (
                        <Suspense fallback={null}>
                            <EnablePaymentOptionCard className="my-goods-page__enable-payment-option-card" />
                        </Suspense>
                    )}
                    {productsList}
                </SellerAreaLayout>
            </PageContent>

            <StatusFiltersMobilePopup
                options={filterPopoverOptions}
                handleSearch={handlePresearch}
                searchLoading={loading}
                presearchCount={filteredCount}
                {...filterPopupProps}
            />
            <SetupPayoutModal
                opened={setupPayoutModalAvailable}
                onPopupOpened={openSetupPayoutModal}
                onPopupClosed={closeSetupPayoutModal}
                onSheetOpened={openSetupPayoutModal}
                onSheetClosed={closeSetupPayoutModal}
            />
            <ChangeQuantityPopup
                onSubmit={handleChangeQtySubmit}
                onPopupClose={closeQtyPopup}
                opened={changeQtyPopupOpened && !!selectedProducts.size}
            />
        </CustomPage>
    );
};

export const MyGoodsPage = ConnectPageWrapper(MyGoodsPageInner);

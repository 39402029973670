import cn from 'classnames';
import React from 'react';

import { MainNavbarActionButtonsProps } from './MainNavbarActionButtons.types';

import { AddToFavoriteButton } from '@/components/AddToFavoriteButton';
import { CartLink } from '@/components/CartLink';
import { MyWishButton } from '@/components/MyWishButton';
import { ShareMenu } from '@/components/ShareMenu';
import { useAppSelector } from '@/hooks/store';

import './MainNavbarActionButtons.less';

export const MainNavbarActionButtons = ({
    showCartButton,
    showShareButton,
    showWishButton,
    showAddToFavButton,
    storeData,
    shareUrl,
    shareTitle,
    analyticsShareProduct,
    analyticsShareStore,
    showCartOrWishButton,
    className,
    children,
}: MainNavbarActionButtonsProps) => {
    const { items: cartItems } = useAppSelector((state) => state.cartReducer);
    const { productsWishList } = useAppSelector((state) => state.productReducer);

    return (
        <div className={cn('main-navbar-action-buttons', className)}>
            {showShareButton && (
                <ShareMenu
                    large
                    shareUrl={shareUrl}
                    shareTitle={shareTitle ?? ''}
                    analyticsShareProduct={analyticsShareProduct}
                    analyticsShareStore={analyticsShareStore}
                />
            )}
            {showAddToFavButton && storeData && <AddToFavoriteButton storeUid={storeData.uid} />}
            {(showCartOrWishButton || showWishButton) && <MyWishButton count={productsWishList.length} />}
            {showCartButton && <CartLink cartCount={cartItems?.length} />}
            {children}
        </div>
    );
};

import { IProduct } from './productReducer';

import {
    CHANGE_PRODUCT_STATUS_ERROR,
    CHANGE_PRODUCT_STATUS_ERROR_RESET,
    CHANGE_PRODUCT_STATUS_LOADING,
    CHANGE_PRODUCT_STATUS_SUCCESS,
    ProductStatusActions,
} from '@/actions/productStatusActions';

export interface IProductStatusState {
    loading?: boolean;
    error?: string;
    item?: IProduct;
    action?: 'changeStatus' | 'changeQuantity';
}

const initialState: IProductStatusState = {
    loading: false,
    error: undefined,
    item: undefined,
    action: undefined,
};

const productStatusReducer = (state = initialState, action: ProductStatusActions): IProductStatusState => {
    switch (action.type) {
        case CHANGE_PRODUCT_STATUS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
                item: undefined,
                action: undefined,
            };
        case CHANGE_PRODUCT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                item: action.item,
                error: undefined,
                action: action.quantity ? 'changeQuantity' : 'changeStatus',
            };
        case CHANGE_PRODUCT_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: `${action.error}`,
                action: undefined,
            };
        case CHANGE_PRODUCT_STATUS_ERROR_RESET:
            return { ...state, error: undefined };
        default:
            return state;
    }
};

export default productStatusReducer;

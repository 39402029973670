import {
    ARTICLE_DETAILS_SUCCESS,
    ARTICLES_ERROR,
    ARTICLES_LIST_SUCCESS,
    ARTICLES_LOADING,
    ArticlesActions,
} from '@/actions/articlesActions';
import { Article } from '@/generated/marketplaceapi';

export interface IArticlesState {
    error: string;
    loading: boolean;
    articlesList: Article[];
    articlesDetails: Article | undefined;
}

const initialState: IArticlesState = {
    error: undefined,
    loading: false,
    articlesDetails: undefined,
    articlesList: [],
};

const articlesReducer = (state = initialState, action: ArticlesActions): IArticlesState => {
    switch (action.type) {
        case ARTICLES_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case ARTICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case ARTICLES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                articlesList: action.articles,
            };
        case ARTICLE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                articlesDetails: action.article,
            };
        default:
            return state;
    }
};

export default articlesReducer;

import { ProductVideo } from '@/generated/marketplaceapi';

// "YOUTUBE" | "VIMEO" | "VK"
export type SupportedVideoServices = keyof typeof ProductVideo.TypeEnum;

export type VideoLink = {
    url: string;
    id: string;
    type: ProductVideo.TypeEnum;
    name: string;
    enabled: boolean;
};

export const AVAILABLE_IMG_EXTENSIONS = ['JPG', 'JPEG', 'PNG'] as const;
export type AvailableImgExtensions = (typeof AVAILABLE_IMG_EXTENSIONS)[number];
export const ACCEPTED_IMG_MIME_TYPES = '.jpg, .jpeg, .png';
export const ACCEPTED_IMG_SIZE = 2000000; // bytes

import cn from 'classnames';
import { Button, F7Popup, F7Sheet, Link, Navbar, NavLeft, NavRight, Popup, Sheet } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAgreementTitle } from './RegionAgreementModal.hooks';

import { RegionAgreementProps } from './RegionAgreementModal.types';

import { confirmAgreements } from '@/actions/profileActions';
import { IcClose } from '@/assets';
import { CustomCheckbox } from '@/components/CustomCheckbox';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import './RegionAgreementModal.less';

export const RegionAgreementModal = ({ agreementType, handleSubmit, ...props }: RegionAgreementProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const isMobile = useAppSelector(getIsMobile);

    const selector = isMobile ? Sheets.REGION_AGREEMENT_SHEET : Popups.REGION_AGREEMENT_POPUP;

    const Component: React.ComponentClass<F7Sheet.Props | F7Popup.Props> = isMobile ? Sheet : Popup;

    const agreement = useAppSelector((state) =>
        state.profileReducer.agreements.find(({ type }) => type === agreementType),
    );

    const title = useAgreementTitle(agreementType);

    const [isAgreed, setIsAgreed] = useState(false);

    const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setIsAgreed(e.target.checked), []);

    const onSubmit = () => {
        setIsAgreed(false);
        dispatch(confirmAgreements([agreement?.uid])).then((state) => handleSubmit?.(state));
    };

    return (
        <Component {...props} id={selector} className={cn(selector, 'region-agreement-modal')}>
            <Navbar noShadow noHairline className="region-agreement-modal__navbar">
                <NavLeft>{title}</NavLeft>
                <NavRight>
                    <Link popupClose sheetClose>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <div className="region-agreement-modal__content">
                {agreement?.url && (
                    <iframe
                        id="region-agreement-modal__iframe"
                        className="region-agreement-modal__iframe"
                        src={agreement?.url}
                        loading="lazy"
                        frameBorder="0"
                        title="Region Agreement"
                    />
                )}
                <div className="region-agreement-modal__bottom">
                    <CustomCheckbox
                        title={`${t('I have read and agree on the')} ${title}`}
                        checked={isAgreed}
                        onChange={onCheckboxChange}
                    />
                    <Button fill round popupClose sheetClose disabled={!isAgreed} onClick={onSubmit}>
                        {t('Agree and Continue')}
                    </Button>
                </div>
            </div>
        </Component>
    );
};

import { useEffect } from 'react';

import { loadProductSource, loadProductSourceHistory } from '@/actions/productSourceActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadProductSource = () => {
    const isLogged = useAppSelector(isLoggedIn);
    const { productSource } = useAppSelector((state) => state.productSourceReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isLogged) {
            dispatch(loadProductSource());
        }
    }, [dispatch, isLogged]);

    useEffect(() => {
        if (isLogged && productSource?.uid) {
            dispatch(loadProductSourceHistory(productSource?.uid));
        }
    }, [dispatch, isLogged, productSource?.uid]);

    return null;
};

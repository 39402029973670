import React from 'react';

import { PersonalAreaBackLinkProps } from './PersonalAreaBackLink.types';

import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const PersonalAreaBackLink = (props: PersonalAreaBackLinkProps) => {
    const loggedIn = useAppSelector(isLoggedIn);

    return <AdaptiveBackLink {...props} reloadAll mobileLink={loggedIn ? '/profile/' : '/'} desktopLink="/" />;
};

import Framework7 from 'framework7/components/app/app-class';
import { AnyAction, combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist';

import { CATEGORY_LIST_LOADING_SUCCESS } from '@/actions/categoryActions';
import { CHANGE_APP_LANGUAGE } from '@/actions/classificatorActions';
import {
    CHANGE_ACTIVE_PRODUCT_TYPE,
    F7_INIT,
    INIT_ENTRY_PAGE_NAME,
    LOCAL_CONFIG_LOADED,
    LOCAL_CONFIG_UPDATED,
    ON_RESIZE_EVENT,
} from '@/actions/rootActions';
import { CHANGE_LANGUAGE } from '@/actions/sessionActions';
import { language } from '@/i18n';
import accountSettingsReducer, { IAccountSettingsState } from '@/reducers/accountSettingsReducer';
import accountStoresReducer, { IAccountStoresState } from '@/reducers/accountStoresReducer';
import alertReducer, { IAlertState } from '@/reducers/alertReducer';
import allGoodsReducer, { IAllGoodsState } from '@/reducers/allGoodsReducer';
import articlesReducer, { IArticlesState } from '@/reducers/articlesReducer';
import bannersReducer, { IBannersState } from '@/reducers/bannersReducer';
import cartReducer, { ICartState } from '@/reducers/cartReducer';
import categoryReducer, { ICategoryClassificatorState } from '@/reducers/categoryReducer';
import classificatorReducer, { IClassificatorState } from '@/reducers/classificatorReducer';
import contactSupportReducer, { IContactSupportState } from '@/reducers/contactSupportReducer';
import customerLocationReducer, { ICustomerLocationState } from '@/reducers/customerLocationReducer';
import deliveryMethodsReducer, { IDeliveryMethodsState } from '@/reducers/deliveryMethodsReducer';
import deliveryProvidersReducer, { IDeliveryProvidersState } from '@/reducers/deliveryProvidersReducer';
import favoritesReducer, { IFavoritesState } from '@/reducers/favoritesReducer';
import geolocationReducer, { IGeolocationState } from '@/reducers/geolocationReducer';
import languageListReducer, { ILanguageListReducerState } from '@/reducers/languagesListReducer';
import myGoodsReducer, { IMyGoodsState } from '@/reducers/myGoodsReducer';
import myWalletsReducer, { IMyWalletsState } from '@/reducers/myWalletsReducer';
import ordersReducer, { IOrdersState } from '@/reducers/ordersReducer';
import paymentCardsReducer, { IPaymentCardsState } from '@/reducers/paymentCardsReducer';
import paymentReducer, { IPaymentState } from '@/reducers/paymentReducer';
import payoutsReducer, { IPayoutsState } from '@/reducers/payoutsReducer';
import preloaderReducer, { IPreloaderState } from '@/reducers/preloaderReducer';
import productCreateReducer, { IProductCreateState } from '@/reducers/productCreateReducer';
import productPromotionReducer, { IProductPromotionState } from '@/reducers/productPromotionReducer';
import productReducer, { IProductState } from '@/reducers/productReducer';
import productSourceReducer, { IProductSourceState } from '@/reducers/productSourceReducer';
import productStatusReducer, { IProductStatusState } from '@/reducers/productStatusReducer';
import profileReducer, { IProfileState } from '@/reducers/profileReducer';
import promotionReducer, { IPromotionState } from '@/reducers/promotionReducer';
import regionsReducer, { IRegionsState } from '@/reducers/regionsReducer';
import registrationReducer, { IRegistrationState } from '@/reducers/registrationReducer';
import reserveReducer, { IReserveState } from '@/reducers/reserveReducer';
import reviewReducer, { IReviewState } from '@/reducers/reviewReducer';
import saleReducer, { ISaleState } from '@/reducers/saleReducer';
import sellersOrdersReducer, { ISellersOrdersState } from '@/reducers/sellersOrdersReducer';
import sessionReducer, { ISessionState } from '@/reducers/sessionReducer';
import shareReducer, { IShareState } from '@/reducers/shareReducer';
import statisticsReducer, { IStatisticsState } from '@/reducers/statisticsReducer';
import storeHomePageReducer, { IStoreHomePageState } from '@/reducers/storeHomePageReducer';
import storeReducer, { IStoreState } from '@/reducers/storeReducer';
import transactionReducer, { ITransactionsState } from '@/reducers/transactionReducer';
import { ILocalConfig } from '@/types/localConfig';
import { ProductType } from '@/types/productType';

export interface IApplicationStore {
    rootReducer: IAppState;
    sessionReducer: ISessionState;
    categoryReducer: ICategoryClassificatorState;
    productReducer: IProductState;
    profileReducer: IProfileState;
    accountSettingsReducer: IAccountSettingsState;
    allGoodsReducer: IAllGoodsState;
    myGoodsReducer: IMyGoodsState;
    paymentCardsReducer: IPaymentCardsState;
    paymentReducer: IPaymentState;
    productCreateReducer: IProductCreateState;
    shareReducer: IShareState;
    productStatusReducer: IProductStatusState;
    classificatorReducer: IClassificatorState;
    bannersReducer: IBannersState;
    preloaderReducer: IPreloaderState;
    productPromotionReducer: IProductPromotionState;
    myWalletsReducer: IMyWalletsState;
    ordersReducer: IOrdersState;
    transactionReducer: ITransactionsState;
    cartReducer: ICartState;
    contactSupportReducer: IContactSupportState;
    sellersOrdersReducer: ISellersOrdersState;
    payoutsReducer: IPayoutsState;
    deliveryMethodsReducer: IDeliveryMethodsState;
    deliveryProvidersReducer: IDeliveryProvidersState;
    customerLocationReducer: ICustomerLocationState;
    accountStoresReducer: IAccountStoresState;
    storeReducer: IStoreState;
    storeHomePageReducer: IStoreHomePageState;
    languageListReducer: ILanguageListReducerState;
    reserveReducer: IReserveState;
    productSourceReducer: IProductSourceState;
    favoritesReducer: IFavoritesState;
    promotionReducer: IPromotionState;
    saleReducer: ISaleState;
    reviewReducer: IReviewState;
    statisticsReducer: IStatisticsState;
    regionsReducer: IRegionsState;
    articlesReducer: IArticlesState;
    geolocationReducer: IGeolocationState;
    alertReducer: IAlertState;
    registrationReducer: IRegistrationState;
}

export interface ResizeEvent {
    width: number;
    height: number;
    isLandscape: boolean;
    ratio: number;
    isXS: boolean;
    isMD: boolean;
    isLG: boolean;
    isXL: boolean;
}

export interface IAppState {
    f7?: Framework7;
    localConfig?: ILocalConfig;
    language?: string;
    isLanguageChanged?: boolean;
    entryPageName?: string;
    resizeEvent: ResizeEvent;
    activeProductType: ProductType;
}

const initialState: IAppState = {
    f7: undefined,
    localConfig: {
        sliderCategories: [],
        categories: [],
        theme: 'light',
        navBarLinks: [],
        mobileCategoriesView: 'default',
        // ? async load of api key loads maps incorrectly
        GoogleMapAPIkey: process.env.MAPS_API_KEY,
        defaultAvailablePaymentMethods: [],
    },
    language: language,
    isLanguageChanged: false,
    resizeEvent: {
        width: window.innerWidth,
        height: window.innerHeight,
        ratio: window.devicePixelRatio,
        isLG: false,
        isLandscape: false,
        isMD: false,
        isXL: false,
        isXS: false,
    },
    activeProductType: undefined,
};

const rootReducer = (state = initialState, action: AnyAction): IAppState => {
    const isLanguageChanged = state.language !== language;

    switch (action.type) {
        case REHYDRATE:
            return {
                ...state,
                language: isLanguageChanged ? language : state.language,
                isLanguageChanged,
            };
        case F7_INIT:
            return {
                ...state,
                f7: action.f7,
            };
        case LOCAL_CONFIG_LOADED:
            return {
                ...state,
                localConfig: { ...state.localConfig, ...action.localConfig },
            };
        case LOCAL_CONFIG_UPDATED:
            return {
                ...state,
                localConfig: { ...state.localConfig, ...action.localConfig },
            };
        case CATEGORY_LIST_LOADING_SUCCESS: {
            const categories = action.categories;
            const { localConfig } = state;

            if (localConfig && categories) {
                localConfig.categories = categories;
            }

            return {
                ...state,
                localConfig: { ...localConfig },
            };
        }
        case INIT_ENTRY_PAGE_NAME:
            return {
                ...state,
                entryPageName: action.pageName,
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: isLanguageChanged ? language : state.language,
                isLanguageChanged: isLanguageChanged,
            };
        case CHANGE_APP_LANGUAGE:
            return {
                ...state,
                language: action.language,
                isLanguageChanged: true,
            };
        case ON_RESIZE_EVENT: {
            const { width, height } = action.payload;
            return {
                ...state,
                resizeEvent: {
                    ...action.payload,
                    isLandscape: width > height,
                    ratio: width / height,
                    isXL: width > 1280 || height > 1280,
                    isLG: (width > 1024 && width < 1279) || (height > 1024 && height < 1279),
                    isMD: (width > 768 && width < 1023) || (height > 768 && height < 1023),
                    isXS: width <= 567 || height <= 567,
                },
            };
        }
        case CHANGE_ACTIVE_PRODUCT_TYPE:
            return {
                ...state,
                activeProductType: action.payload.activeProductType,
            };
    }
    return state;
};

// https://redux.js.org/recipes/usage-with-typescript#type-checking-reducers
export default combineReducers<IApplicationStore>({
    rootReducer,
    sessionReducer,
    categoryReducer,
    productReducer,
    profileReducer,
    accountSettingsReducer,
    allGoodsReducer,
    reserveReducer,
    myGoodsReducer,
    paymentCardsReducer,
    productCreateReducer,
    shareReducer,
    productStatusReducer,
    classificatorReducer,
    bannersReducer,
    preloaderReducer,
    productPromotionReducer,
    myWalletsReducer,
    ordersReducer,
    transactionReducer,
    cartReducer,
    paymentReducer,
    contactSupportReducer,
    sellersOrdersReducer,
    payoutsReducer,
    deliveryMethodsReducer,
    deliveryProvidersReducer,
    customerLocationReducer,
    accountStoresReducer,
    storeReducer,
    storeHomePageReducer,
    languageListReducer,
    productSourceReducer,
    favoritesReducer,
    promotionReducer,
    saleReducer,
    reviewReducer,
    statisticsReducer,
    regionsReducer,
    articlesReducer,
    geolocationReducer,
    alertReducer,
    registrationReducer,
});

import { Router } from 'framework7/modules/router/router';
import { f7 } from 'framework7-react';
import { useCallback, useEffect } from 'react';

import { updateProductType } from '@/actions/rootActions';
import { messengerService } from '@/api/messengerService';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { ProductType } from '@/types/productType';
import { EProductTypeQuery, PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getQueryParameterFromURL } from '@/utils';
import { getProductTypeByQuery } from '@/utils/productType/getProductTypeByQuery';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';
import { updateQueryParameterFromURL } from '@/utils/url/updateQueryParameterFromURL';

export const RouterManager = () => {
    const dispatch = useAppDispatch();

    const { activeProductType } = useAppSelector((state) => state.rootReducer);

    const routeUpdateWithAppType = useCallback(
        (
            newRoute: Router.Route = f7.view.main.router.currentRoute,
            _previousRoute = undefined,
            router: Router.Router = f7.view.main.router,
        ) => {
            const typeQuery = getQueryParameterFromURL(PRODUCT_TYPE_QUERY_NAME, newRoute.url) as EProductTypeQuery;
            const typeMapped = getProductTypeByQuery(typeQuery);

            if (messengerService.enabled) {
                if (activeProductType === ProductType.Product) {
                    dispatch(updateProductType(ProductType.Advertisement));
                    return;
                } else if (typeMapped === ProductType.Product) {
                    const newUrl = updateQueryParameterFromURL(
                        newRoute.url,
                        PRODUCT_TYPE_QUERY_NAME,
                        getQueryByProductType(ProductType.Advertisement),
                    );

                    router.navigate(newUrl, { reloadCurrent: true });
                    return;
                }
            }

            if (typeQuery && typeMapped !== activeProductType) {
                dispatch(updateProductType(typeMapped));
                return;
            }

            if (!typeQuery) {
                let newUrl = updateQueryParameterFromURL(
                    newRoute.url,
                    PRODUCT_TYPE_QUERY_NAME,
                    getQueryByProductType(activeProductType),
                );

                router.navigate(newUrl, { reloadCurrent: true });
            }
        },
        [activeProductType, dispatch],
    );

    useEffect(() => {
        f7.on('routeChange', routeUpdateWithAppType);

        return () => f7.off('routeChange', routeUpdateWithAppType);
    }, [routeUpdateWithAppType]);

    return null;
};

import { Button, f7 } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddReviewProps } from './AddReview.types';

import { AddReviewPopup } from '@/components/AddReviewPopup';
import { RelativeRatingStat } from '@/components/RelativeRatingStat';
import { StarRating } from '@/components/StarRating';
import { useAppSelector } from '@/hooks/store';
import { useReviewPopup } from '@/hooks/useReviewPopup';

import { isLoggedIn } from '@/selectors/isLoggedIn';
import { Popups } from '@/types/popups';
import { SortDirection } from '@/types/sortDirection';
import { getProductRating, showToast } from '@/utils';

import type { ProductRating } from '@/reducers/reviewReducer';

import './AddReview.less';

export const AddReview: React.FC<AddReviewProps> = ({ isOwnProduct }) => {
    const { t } = useTranslation();

    const logged = useAppSelector(isLoggedIn);

    const { productRating } = useAppSelector((state) => state.reviewReducer);

    const {
        addReviewLoading,
        addReviewSuccess,
        addReviewError,
        isAddReviewPopupOpen,
        openAddReviewPopup,
        onAddReviewClose,
        onSubmitAddReview,
    } = useReviewPopup();

    const getAltStatLabel = useCallback(
        ({ rating }: ProductRating) => String(t(`${rating} ${rating === 1 ? 'Star' : 'Stars'}`)),
        [t],
    );

    const handleAddReviewClick = useCallback(() => {
        if (!logged) {
            f7.popup.open(`.${Popups.LOGIN_POPUP}`);
        } else {
            isOwnProduct
                ? void showToast({
                      text: t('selfProductReviewError'),
                      withMargin: true,
                      isError: true,
                  })
                : openAddReviewPopup();
        }
    }, [isOwnProduct, logged, openAddReviewPopup, t]);

    return (
        <div className="product-details-add-review">
            <div className="product-details-add-review__rating-wrap">
                <div className="product-details-add-review__relative-rating">
                    <span className="product-details-add-review__relative-rating-title">{t('Rating')}</span>
                    <StarRating value={getProductRating(productRating)} readOnly size="small" primary />
                    {getProductRating(productRating).toFixed(2)}
                </div>
                {productRating.reduce((acc, { reviewCount = 0 }) => acc + reviewCount, 0) > 0 && (
                    <div className="product-details-add-review__rating-stat">
                        <RelativeRatingStat
                            rows={productRating}
                            labelKey="rating"
                            percentKey="ratingPercent"
                            getAltLabel={getAltStatLabel}
                            withAltLabel
                            adaptive
                            sortByLabel
                            sortDirection={SortDirection.DESC}
                            withDefaultLabelSize
                        />
                    </div>
                )}
            </div>
            <div className="product-details-add-review__add-review-button-wrap">
                {t('Share your thoughts with others')}
                <Button round large onClick={handleAddReviewClick}>
                    {t('Write Review')}
                </Button>
            </div>
            <AddReviewPopup
                opened={isAddReviewPopupOpen}
                onPopupClose={onAddReviewClose}
                loading={addReviewLoading}
                success={addReviewSuccess}
                error={addReviewError}
                onSubmit={onSubmitAddReview}
            />
        </div>
    );
};

import cn from 'classnames';
import { f7, Link, List } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { AddProductsSourcesSheet } from './AddProductsSourcesSheet';

import { EditSaleProductModal } from './EditSaleProductPopup';

import { ProductsOnSaleProps } from './ProductsOnSale.types';

import { SaleProductActionsSheet } from './SaleProductActionsSheet';

import { SaleProductListItem } from './SaleProductListItem';

import { validateSaleItems } from '@/actions/saleActions';
import { loadStoreProductCategories, searchStoreProducts } from '@/actions/storeHomePageActions';
import { IcMore } from '@/assets';
import { AddNewButton } from '@/components/AddNewButton';
import { AddWidgetPopup } from '@/components/AddWidgetPopup';
import { CategorySheet } from '@/components/CategorySheet';
import { DeleteAllModal } from '@/components/DeleteAllModalFacade';
import { SelectProductsPopup } from '@/components/SelectProductsPopup';
import { SaleProduct } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { usePreloader } from '@/hooks/usePreloader';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { IProduct } from '@/reducers/productReducer';
import { getCategoriesClassificator } from '@/selectors/getCategoriesClassificator';
import { getIsMobile } from '@/selectors/getIsMobile';
import { noop } from '@/utils';

import './ProductsOnSale.less';

export const ProductsOnSale: React.FC<ProductsOnSaleProps> = ({
    handleValidity,
    draftInitial,
    priceFrom,
    priceTo,
    containerID,
    handleDraftChange,
    isEdit = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = useAppSelector(getIsMobile);
    const { saleItemsLoading, validateLoading } = useAppSelector((state) => state.saleReducer);
    const { categoriesClassificator: flatCategories } = useAppSelector(getCategoriesClassificator);
    const { storeCategories } = useAppSelector((state) => state.storeHomePageReducer);
    const { accountStores } = useAppSelector((state) => state.accountStoresReducer);

    const [categoriesPopupOpened, setCategoriesPopupOpened] = useState(false);
    const [openCategoriesModal, closeCategoriesModal] = useBooleanState(setCategoriesPopupOpened);

    const [productsPopupOpened, setProductsPopupOpened] = useState(false);
    const [openProductsPopup, closeProductsPopup] = useBooleanState(setProductsPopupOpened);

    const [selectedProducts, setSelectedProducts] = useState<SaleProduct[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<ICategoryClassificator[]>([]);

    const [currentEditingItem, setCurrentEditingItem] = useState<SaleProduct>(undefined);

    const [editModalOpened, setEditModalOpened] = useState(false);
    const [openEditModal, closeEditModal] = useBooleanState(setEditModalOpened);

    const [productsSourcesSheetOpened, setProductsSourcesSheetOpened] = useState(false);
    const [openProductsSourcesSheet, closeProductsSourcesSheet] = useBooleanState(setProductsSourcesSheetOpened);

    const [productActionsSheetOpened, setProductActionsSheetOpened] = useState(false);
    const [openProductActionsSheet, closeProductActionsSheet] = useBooleanState(setProductActionsSheetOpened);

    const [categorySheetOpened, setCategorySheetOpened] = useState(false);
    const [openCategorySheet, closeCategorySheet] = useBooleanState(setCategorySheetOpened);

    const allowedCategoriesSet = useMemo(() => {
        const set = new Set<ICategoryClassificator>();
        flatCategories
            ?.filter((category) => storeCategories?.includes(category.categoryCode))
            .forEach((item) => {
                let current = item;
                while (current) {
                    set.add(current);
                    current = current.parent;
                }
            });
        return set;
    }, [flatCategories, storeCategories]);

    const errorsCount = useMemo(
        () => selectedProducts?.reduce((acc, cur) => (cur.error ? acc + 1 : acc), 0),
        [selectedProducts],
    );

    const handleCategoriesChangeSubmit = useCallback(
        (cats: ICategoryClassificator[]) => {
            setSelectedCategories(cats);
            closeCategoriesModal();
            closeCategorySheet();
            dispatch(
                validateSaleItems(
                    (cats?.map((item) => ({ category: item.categoryCode })) as SaleProduct[]).concat(selectedProducts),
                    draftInitial?.uid,
                ),
            );
        },
        [closeCategoriesModal, closeCategorySheet, dispatch, draftInitial?.uid, selectedProducts],
    );

    const handleProductsSelectSubmit = useCallback(
        (products: Partial<IProduct>[]) => {
            dispatch(
                validateSaleItems(
                    (
                        products
                            ?.map((item) => (item.error ? undefined : { productUid: item?.uid }))
                            .filter(Boolean) as SaleProduct[]
                    ).concat(selectedProducts),
                    draftInitial?.uid,
                ),
            );
            closeProductsPopup();
        },
        [closeProductsPopup, dispatch, draftInitial?.uid, selectedProducts],
    );

    const handleDeleteAllItems = useCallback(() => {
        f7.dialog
            .create({
                cssClass: 'app-dialog-confirm',
                text: t('Are you sure you want to delete all products from the list?'),
                buttons: [
                    {
                        text: t('Cancel'),
                        cssClass: 'button-cancel',
                        onClick: noop,
                    },
                    {
                        text: t('Delete All'),
                        cssClass: 'button-confirm',
                        onClick: () => {
                            setSelectedProducts([]);
                        },
                    },
                ],
            })
            .open();
    }, [t]);

    const handleDeleteItem = useCallback(
        (item: SaleProduct) =>
            setSelectedProducts((prev) => prev?.filter((product) => product.productUid !== item.productUid)),
        [],
    );

    const handleEditClick = useCallback(
        (item: SaleProduct) => {
            setCurrentEditingItem(item);
            openEditModal();
        },
        [openEditModal],
    );

    const handleEditSubmit = useCallback(
        (item: SaleProduct) => {
            dispatch(
                validateSaleItems(
                    selectedProducts?.map((prevItem) => (item.productUid === prevItem.productUid ? item : prevItem)),
                    draftInitial?.uid,
                ),
            );
        },
        [dispatch, draftInitial?.uid, selectedProducts],
    );

    const onMobileProductOptionsClick = useCallback(
        (product: SaleProduct) => {
            setCurrentEditingItem(product);
            openProductActionsSheet();
        },
        [openProductActionsSheet],
    );

    useEffect(() => {
        if (!storeCategories) dispatch(loadStoreProductCategories(accountStores?.[0]?.uid));

        if (draftInitial?.uid) {
            dispatch(searchStoreProducts({ priceFrom, priceTo, validateForSaleUid: draftInitial.uid }));
        }
    }, [accountStores, dispatch, draftInitial.uid, priceFrom, priceTo, storeCategories]);

    useEffect(() => {
        handleValidity(!!selectedProducts?.length && !errorsCount && !saleItemsLoading);
    }, [errorsCount, handleValidity, saleItemsLoading, selectedCategories?.length, selectedProducts?.length]);

    useEffect(() => {
        if (draftInitial?.products) {
            setSelectedProducts([...new Map(draftInitial?.products?.map((item) => [item.productUid, item]))?.values()]);
        }
    }, [draftInitial?.products]);

    useEffect(() => {
        dispatch(searchStoreProducts({ priceFrom, priceTo }));
    }, [dispatch, priceFrom, priceTo]);

    useEffect(() => {
        setSelectedCategories([]);
        if (draftInitial.uid) {
            handleDraftChange({ products: selectedProducts, uid: draftInitial.uid });
        }
    }, [draftInitial.uid, handleDraftChange, selectedProducts]);

    usePreloader(validateLoading);

    return (
        <div id={containerID} className="products-on-sale">
            <h2 className={cn('products-on-sale-title', { edit: isEdit })}>{t('Products on Sale')}</h2>
            <p className="products-on-sale-description">
                {t('You can apply discounts to specific categories or specific items')}
            </p>
            {!isMobile && (
                <p className="products-on-sale-description">
                    {t('Add')}&nbsp;
                    <Link onClick={openCategoriesModal}>{t('Categories of Products')}</Link>&nbsp;
                    {t('or')} <Link onClick={openProductsPopup}>{t('Specific Products')}</Link>
                </p>
            )}
            {isMobile && (
                <AddNewButton className="products-on-sale__mobile-add-products" onClick={openProductsSourcesSheet}>
                    {t('Add Products')}
                </AddNewButton>
            )}
            {!!selectedProducts?.length && (
                <div className="products-on-sale-items-container">
                    <h3 className="products-on-sale-subtitle">
                        {t('Сheck New Prices')}
                        <DeleteAllModal
                            closeByClickInside
                            modalTriggerElement={(triggerModal) => (
                                <Link iconOnly onClick={triggerModal}>
                                    <IcMore className="more-icon" />
                                </Link>
                            )}
                            handleDeleteAllClick={handleDeleteAllItems}
                        />
                    </h3>
                    <div className="products-on-sale-items-labels">
                        <p className="products-on-sale-count-label">
                            {t('Added')}: {selectedProducts?.length}
                        </p>
                        {!!errorsCount && (
                            <p className="products-on-sale-count-label">
                                {t('Errors')}: {errorsCount}
                            </p>
                        )}
                    </div>
                    <List className="products-on-sale-items" mediaList>
                        {selectedProducts?.map((item) => (
                            <SaleProductListItem
                                onDeleteClick={handleDeleteItem}
                                key={item.productUid}
                                item={item}
                                withActionButtons
                                onEditClick={handleEditClick}
                                onMobileOptionsClick={() => onMobileProductOptionsClick(item)}
                            />
                        ))}
                    </List>
                </div>
            )}
            <EditSaleProductModal
                opened={editModalOpened}
                onPopupOpen={openEditModal}
                onPopupClose={closeEditModal}
                onSheetOpen={openEditModal}
                onSheetClose={closeEditModal}
                product={currentEditingItem}
                onConfirm={handleEditSubmit}
            />
            <AddWidgetPopup
                popupProps={{
                    opened: categoriesPopupOpened && !isMobile,
                    onPopupClose: closeCategoriesModal,
                }}
                allowedCategories={allowedCategoriesSet}
                selectedCategoriesState={selectedCategories}
                onCloseClick={closeCategoriesModal}
                onSelectCategoriesSubmit={handleCategoriesChangeSubmit}
                title={t('Add Categories')}
                description={t('Choose categories on Sale')}
            />
            <SelectProductsPopup
                validateErrors
                saleUid={draftInitial.uid}
                priceFrom={priceFrom}
                priceTo={priceTo}
                selectedProducts={selectedProducts}
                onAddProductsSubmit={handleProductsSelectSubmit}
                handleCloseProductsPopup={closeProductsPopup}
                isOpenProductsPopup={productsPopupOpened}
            />
            <SaleProductActionsSheet
                opened={productActionsSheetOpened}
                onSheetOpen={openProductActionsSheet}
                onSheetClose={closeProductActionsSheet}
                onEditClick={() => handleEditClick(currentEditingItem)}
                onDeleteClick={() => handleDeleteItem(currentEditingItem)}
            />
            <AddProductsSourcesSheet
                opened={productsSourcesSheetOpened}
                onSheetOpen={openProductsSourcesSheet}
                onSheetClose={closeProductsSourcesSheet}
                onAddCategoriesClick={openCategorySheet}
                onAddProductsClick={openProductsPopup}
            />
            <CategorySheet
                everyLevelMultyselectable
                defaultTitle={t('Add Categories')}
                allowedCategories={allowedCategoriesSet}
                selectedCategories={selectedCategories}
                opened={categorySheetOpened}
                onSelectCategoriesSubmit={handleCategoriesChangeSubmit}
                onSheetOpened={openCategorySheet}
                onSheetClosed={closeCategorySheet}
            />
        </div>
    );
};

import cn from 'classnames';
import { Button, f7 } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StoreProductsGroupProps } from './StoreProductsGroup.types';

import { AddToFavoriteButton } from '@/components/AddToFavoriteButton';
import { Carousel, CarouselType } from '@/components/Carousel';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { productsToSliderItems } from '@/utils';

import './StoreProductsGroup.less';

export const StoreProductsGroup = ({ store, className }: StoreProductsGroupProps): JSX.Element => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const slides = useMemo(
        () =>
            store.products
                ? productsToSliderItems(store.products, (item) =>
                      f7.view.main.router.navigate(`/product-details/${item.uid}/`),
                  )
                : undefined,
        [store.products],
    );

    const onVisit = useCallback(() => f7.view.main.router.navigate(`/store/${store.code}/`), [store.code]);

    return (
        <div className={cn('store-products-group', className)}>
            <div className="store-products-group__head">
                <img
                    src={store.imageUrl}
                    className="store-products-group__head__logo"
                    onClick={isMobile && onVisit}
                    alt=""
                />
                <div className="store-products-group__head__info" onClick={isMobile && onVisit}>
                    <h4>{store.name}</h4>
                    <p>{store.description}</p>
                </div>
                <div className="store-products-group__head__controls">
                    <AddToFavoriteButton storeUid={store.uid} />
                    {!isMobile && (
                        <Button fill round className="store-products-group__head__controls__visit" onClick={onVisit}>
                            {t('Visit Store')}
                        </Button>
                    )}
                </div>
            </div>
            {slides && !isMobile && (
                <div className="store-products-group__products">
                    <Carousel slides={slides} type={CarouselType.xsmall} />
                </div>
            )}
        </div>
    );
};

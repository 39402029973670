import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { StoreItemProps } from './StoreItem.types';

import { EmptyImagePath, IcNext, IcStore } from '@/assets';

import './StoreItem.less';

export const StoreItem = ({ item }: StoreItemProps) => {
    const [productUrls, setProductUrls] = useState([EmptyImagePath, EmptyImagePath, EmptyImagePath]);

    useEffect(() => {
        // fix problem if the picture failed to load
        (item.products || []).forEach((item, index) => {
            const image = new Image();

            image.src = item.imageThumbnailUrl1;
            image.onload = () => {
                setProductUrls((urls) => {
                    urls[index] = item.imageThumbnailUrl1;
                    return [...urls];
                });
            };
            image.onerror = () => {
                setProductUrls((urls) => {
                    urls[index] = EmptyImagePath;
                    return [...urls];
                });
            };
        });
    }, [item.products]);

    return (
        <div className="store-item">
            <div className="store-item__header">
                <div>
                    <div className="store-item__title-container">
                        <IcStore />
                        <span className="store-item__title">{item.name}</span>
                    </div>
                    <span className="store-item__description">{item.description}</span>
                </div>
                <IcNext />
            </div>
            <div className="store-item__products">
                {productUrls.map((url, index) => (
                    <div key={index} className={cn('store-item__product', `store-item__product${index + 1}`)}>
                        <img src={url} className="store-item__product-image" alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
};

import cn from 'classnames';
import { Block, Link, Navbar, NavLeft, NavRight, Popup } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { RelativeRatingStat } from '@/components/RelativeRatingStat';
import { ReviewList } from '@/components/ReviewList';
import { StarRating } from '@/components/StarRating';
import { Translation } from '@/components/Translation';

import { Popups } from '@/types/popups';
import { SortDirection } from '@/types/sortDirection';
import { getProductRating, noop } from '@/utils';

import type { ReviewListPopupProps } from './ReviewListPopup.types';
import type { ProductRating } from '@/reducers/reviewReducer';

import './ReviewListPopup.less';

export const ReviewListPopup: React.FC<ReviewListPopupProps> = ({
    reviews = [],
    productRating = [],
    translated = false,
    toggleTranslated = noop,
    ...props
}) => {
    const { t } = useTranslation();

    const getAltStatLabel = useCallback(
        ({ rating }: ProductRating) => String(t(`${rating} ${rating === 1 ? 'Star' : 'Stars'}`)),
        [t],
    );

    return (
        <Popup
            id={Popups.REVIEW_LIST_POPUP}
            className={cn(Popups.REVIEW_LIST_POPUP, 'review-list-popup')}
            tabletFullscreen
            backdrop={false}
            {...props}
        >
            <Navbar noShadow>
                <NavLeft>
                    <AdaptiveBackLink popupClose />
                </NavLeft>
                <NavRight>
                    <Link popupOpen={`.${Popups.ADD_REVIEW_POPUP}`}>{t('Write Review')}</Link>
                </NavRight>
            </Navbar>
            <Block>
                <div className="review-list-popup__rating-wrap">
                    <div className="review-list-popup__relative-rating">
                        <span className="review-list-popup__relative-rating-title">{t('Rating')}</span>
                        <StarRating value={getProductRating(productRating)} readOnly size="small" primary />
                        {getProductRating(productRating).toFixed(2)}
                    </div>
                    <div className="review-list-popup__rating-stat">
                        <RelativeRatingStat
                            rows={productRating}
                            labelKey="rating"
                            percentKey="ratingPercent"
                            getAltLabel={getAltStatLabel}
                            withAltLabel
                            adaptive
                            sortByLabel
                            sortDirection={SortDirection.DESC}
                            withDefaultLabelSize
                        />
                    </div>
                </div>
                <div className="review-list-popup__header">
                    <h1 className="review-list-popup__title">
                        {reviews.length} {t('Reviews')}
                    </h1>
                </div>
                <Translation translated={translated} onClick={toggleTranslated} source={process.env.APP_NAME} />
                <ReviewList reviews={reviews} translated={translated} />
            </Block>
        </Popup>
    );
};

import { useState } from 'react';

import { useBooleanState } from './useBooleanState';
/**
 * @param bool - boolean
 * @returns [state, open, close, toggle]
 */
export const useBoolState = (bool = false) => {
    const [state, setState] = useState(bool);

    return [state, ...useBooleanState(setState)] as const;
};

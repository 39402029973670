import { useMemo } from 'react';

import { SellerAreaMenuItemName } from './SellerAreaMenu.types';

import { getMenuSettings } from './SellerAreaMenu.utils';

import { onbordingMessagesService } from '@/api/onbordingMessagesService';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { AccountType } from '@/types/account';

export const useSellerAreaMenuItems = () => {
    const { type: profileType, status: profileStatus } = useAppSelector(getProfile);
    const isMobile = useAppSelector(getIsMobile);
    const activeProductType = useAppSelector(getActiveProductType);

    const { accountStores } = useAppSelector((state) => state.accountStoresReducer);

    const { products } = useAppSelector((state) => state.myGoodsReducer);

    const { saleList } = useAppSelector((state) => state.saleReducer);

    // ? true - need to show
    const onboardingMessagesConf = useMemo(
        () => ({
            PRODUCTS: accountStores.length && !products.length,
            SALE: accountStores.length && !saleList?.length,
            STORE: profileType === AccountType.STANDARD,
            STORE_HOMEPAGE: !accountStores.some((store) => !!store.homepage),
        }),
        [accountStores, products.length, profileType, saleList?.length],
    );

    const menuItems = useMemo(() => {
        const shownItems = onbordingMessagesService.getConfFromLocalStorage();

        return getMenuSettings(
            profileType,
            profileStatus,
            isMobile,
            Object.entries(onboardingMessagesConf).reduce(
                (accum, [key, value]) => Object.assign(accum, { [key]: value && !shownItems[key] }),
                {},
            ),
            activeProductType,
        );
    }, [profileType, profileStatus, isMobile, onboardingMessagesConf, activeProductType]);

    const menuItemNames = useAppSelector(
        (state) =>
            (state.rootReducer.localConfig.sellerAreaMenuItems?.filter((configItem) => configItem in menuItems) ??
                []) as SellerAreaMenuItemName[],
    );

    return { menuItems, menuItemNames, profileType };
};

export const tcWrapper = <TFunc extends (...args: unknown[]) => ReturnType<TFunc>>(
    fn: TFunc,
    customErrorHandler = console.error,
) => {
    return (...args: Parameters<TFunc>): ReturnType<TFunc> | void => {
        try {
            return fn(...args);
        } catch (cause) {
            customErrorHandler(cause);
        }
    };
};

import React, { CSSProperties } from 'react';

import { Product, ProductVideo } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';

export type CarouselItem = Partial<IProduct> & {
    uid?: string;
    href?: string;
    targetUrl?: string;
    name?: string;
    image?: string;
    promo?: string;
    description?: string;
    category?: string;
    categoryName?: string;
    price?: number;
    priceDiscount?: number;
    currencyCode?: string;
    onClick?(item: CarouselItem): void;
    wish?: boolean;
    sellerPhone?: string;
    featureCodes?: string[];
    videoId?: string;
    videoType?: ProductVideo.TypeEnum;
    shortDescription?: string;
    count?: number;
    totalCount?: number;
    products?: Product[];
};

export enum CarouselType {
    top = 'top',
    big = 'big',
    small = 'small',
    images = 'images',
    fullScreenImages = 'fullScreenImages',
    smallHalfScreen = 'smallHalfScreen',
    smallDesktopView = 'smallDesktopView',
    xsmall = 'xsmall',
    stores = 'stores',
    articles = 'articles',
    imagesWithHoverZoom = 'imagesWithHoverZoom',
}

export interface CarouselProps {
    slides: CarouselItem[];
    type: CarouselType;
    zoomed?: boolean;
    withPagination?: boolean;
    onItemClick?: (item: CarouselItem, index: number) => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    initialIndex?: number;
    onChangeActiveIndex?: (activeIndex: number) => void;
    activeIndex?: number;
    showFeaturesHighlight?: boolean;
    /**
     * if 'slides.lenght === 0' will show carousel with gray squares
     */
    showIfEmpty?: boolean;
    /**
     * if 'slides' prop contains undefined values will display NotAvailableItem on this position
     */
    showNotAvailableItems?: boolean;
    mobileView?: boolean;
    style?: CSSProperties;
    withSwitcher?: boolean;
    switcherClassname?: string;
    onClickItemWish?: (uid: string) => void;
}

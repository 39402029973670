import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

import './ButtonIcon.less';

type MSButtonIconProps = {
    icon: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const MSButtonIcon = ({ icon, className, type = 'button', ...props }: MSButtonIconProps) => {
    return (
        <button className={cn('ms-button', className)} type={type} {...props}>
            {icon}
        </button>
    );
};

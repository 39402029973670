import { Badge, Link, ListItem } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { SaleProductListItemProps } from './SaleProductListItem.types';

import { IcDelete, IcEdit, IcMore } from '@/assets';
import { Price } from '@/components/Price';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

import './SaleProductListItem.less';

export const SaleProductListItem: React.FC<SaleProductListItemProps> = React.memo(
    ({ item, withActionButtons, onDeleteClick, onEditClick, onMobileOptionsClick }) => {
        const isMobile = useAppSelector(getIsMobile);

        const handleEditClick = useCallback(() => {
            onEditClick?.(item);
        }, [item, onEditClick]);

        const handleDeleteClick = useCallback(() => {
            onDeleteClick?.(item);
        }, [item, onDeleteClick]);

        const { Sale_Error } = useAppSelector((state) => state.classificatorReducer.entitiesClassificators);

        const errorMessage = useMemo(
            () => (item.error ? Sale_Error?.find((err) => err.code === item.error)?.value : undefined),
            [Sale_Error, item.error],
        );

        return (
            <ListItem className="sale-item" title={item.name} subtitle={item.categoryName}>
                <img className="sale-item-image" slot="media" src={item.imageThumbnailUrl1} alt="" />
                <div slot="text">
                    {item.error ? (
                        <Badge className="sale-error-badge">{errorMessage}</Badge>
                    ) : (
                        <Price
                            size={isMobile ? 'medium' : 'small'}
                            price={item.price}
                            currencyCode={item.currencyCode}
                            discountedPrice={item.salePrice}
                            // period={item.rentOptions?.period}
                        />
                    )}
                </div>
                {withActionButtons && !isMobile && (
                    <div slot="after" className="sale-item-action-buttons">
                        {!item.error && <IcEdit onClick={handleEditClick} />}
                        <IcDelete onClick={handleDeleteClick} />
                    </div>
                )}
                {withActionButtons && isMobile && (
                    <Link slot="after" iconOnly onClick={onMobileOptionsClick}>
                        <IcMore />
                    </Link>
                )}
            </ListItem>
        );
    },
);

SaleProductListItem.displayName = 'SaleProductListItem';

import { Dispatch } from 'redux';

import { CarouselItem } from '@/components/Carousel';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    ClassificatorControllerApi,
    ProductCategory,
    ProductControllerApi,
    PublicControllerApi,
    Sale,
    Store,
    StoreHomepageWidget,
} from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { StoreProductsSearchParams, StoreWidgetProducts } from '@/reducers/storeReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { deepEqual, productsToSliderItems, productToIProduct, storeToCarouselItem } from '@/utils';

export const STORE_INFO_LOADING_SUCCESS = 'STORE_INFO_LOADING_SUCCESS';
export const RESET_STORE_DATA = 'RESET_STORE_DATA';
export const STORE_INFO_LOADING = 'STORE_INFO_LOADING';

export const STORE_PRODUCTS_LOADING = 'STORE_PRODUCTS_LOADING';
export const STORE_PRODUCTS_LOADING_SUCCESS = 'STORE_PRODUCTS_LOADING_SUCCESS';

export const STORE_CATEGORIES_LOADING = 'STORE_CATEGORIES_LOADING';
export const STORE_CATEGORIES_LOADING_SUCCESS = 'STORE_CATEGORIES_LOADING_SUCCESS';

export const STORE_WIDGET_PRODUCTS_LOADING_SUCCESS = 'STORE_WIDGET_PRODUCTS_LOADING_SUCCESS';
export const STORE_WIDGET_PRODUCTS_LOADING = 'STORE_WIDGET_PRODUCTS_LOADING';

export const TOP_STORES_LOADING = 'TOP_STORES_LOADING' as const;
export const TOP_STORES_LOADING_SUCCESS = 'TOP_STORES_LOADING_SUCCESS' as const;

export const STORE_CREATED_POPUP_SHOWING = 'STORE_CREATED_POPUP_SHOWING' as const;

const topStoresLoadingAction = (topStoresLoading = false) => ({
    type: TOP_STORES_LOADING,
    topStoresLoading,
});

const topStoresLoadingSuccessAction = (topStores?: CarouselItem[]) => ({
    type: TOP_STORES_LOADING_SUCCESS,
    topStores,
});

const storeInfoLoadingSuccessAction = (storeInfo: Store) =>
    ({
        type: STORE_INFO_LOADING_SUCCESS,
        storeInfo,
    }) as const;

const storeProductsLoadingSuccessAction = (
    products: IProduct[] = [],
    updateExisting: boolean,
    searchCount: number,
    searchParams?: StoreProductsSearchParams,
) =>
    ({
        type: STORE_PRODUCTS_LOADING_SUCCESS,
        products,
        updateExisting,
        searchParams,
        searchCount,
    }) as const;

const storeInfoLoadingAction = (loading: boolean) =>
    ({
        type: STORE_INFO_LOADING,
        loading,
    }) as const;

const storeProductsLoadingAction = (loading: boolean) =>
    ({
        type: STORE_PRODUCTS_LOADING,
        loading,
    }) as const;

const storeWidgetsProductsLoadingAction = (loading: boolean) =>
    ({
        type: STORE_WIDGET_PRODUCTS_LOADING,
        loading,
    }) as const;

const storeWidgetsProductsLoadingSuccessAction = (widgetsProducts: StoreWidgetProducts[]) =>
    ({
        type: STORE_WIDGET_PRODUCTS_LOADING_SUCCESS,
        widgetsProducts,
    }) as const;

const storeCategoriesLoadingSuccessAction = (storeCategories: ProductCategory[]) =>
    ({ type: STORE_CATEGORIES_LOADING_SUCCESS, storeCategories }) as const;

const storeCategoriesLoadingAction = (loading: boolean) => ({ type: STORE_CATEGORIES_LOADING, loading }) as const;

export const showStoreCreatedPopupAction = (isShow = false) => ({
    type: STORE_CREATED_POPUP_SHOWING,
    isShow,
});

export const resetStoreDataAction = () =>
    ({
        type: RESET_STORE_DATA,
    }) as const;

export type StoreActions = ReturnType<
    | typeof storeInfoLoadingSuccessAction
    | typeof storeProductsLoadingSuccessAction
    | typeof storeProductsLoadingAction
    | typeof storeInfoLoadingAction
    | typeof storeCategoriesLoadingAction
    | typeof storeCategoriesLoadingSuccessAction
    | typeof resetStoreDataAction
    | typeof storeWidgetsProductsLoadingAction
    | typeof storeWidgetsProductsLoadingSuccessAction
    | typeof topStoresLoadingAction
    | typeof topStoresLoadingSuccessAction
    | typeof showStoreCreatedPopupAction
>;

export const loadStoreDetails = async (storeUid: string, countryCode: string, languageCode: string) => {
    const response = await new PublicControllerApi().storeInfoUsingGET(storeUid, countryCode, languageCode);

    handleResponseAndThrowAnErrorIfExists(response);

    return response.body[0];
};

export const loadStoreInfo = (storeUid: string) => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
    dispatch(storeInfoLoadingAction(true));

    const state = getState();

    try {
        const store = await loadStoreDetails(storeUid, getCountryCodeFromState(state), getLanguageCode(state));
        dispatch(storeInfoLoadingSuccessAction(store));
    } catch (error) {
        console.error('at storeActions in loadStoreInfo', error);
    } finally {
        dispatch(storeInfoLoadingAction(false));
    }
};

export const loadStoreWidgetsProducts =
    (widgets: StoreHomepageWidget[], storeUid: string, onProductItemClick: (item: CarouselItem) => void) =>
    (dispatch: Dispatch, getState: () => IApplicationStore) => {
        const state = getState();
        dispatch(storeWidgetsProductsLoadingAction(true));
        try {
            Promise.all(
                widgets.map((widget) =>
                    (!isLoggedIn(state)
                        ? new PublicControllerApi().productSearchUsingPOST1(
                              {
                                  storeUid,
                                  category: widget.category,
                                  saleUid: widget.saleUid,
                                  count: 10,
                                  productType: getActiveProductType(state),
                              },
                              getCountryCodeFromState(state),
                              getLanguageCode(state),
                          )
                        : new ProductControllerApi().productSearchUsingPOST(
                              {
                                  category: widget.category,
                                  saleUid: widget.saleUid,
                                  storeUid,
                                  count: 10,
                                  productType: getActiveProductType(state),
                              },
                              getCountryCodeFromState(state),
                              getLanguageCode(state),
                          )
                    ).then((res) => [res, widget] as const),
                ),
            ).then((response) =>
                dispatch(
                    storeWidgetsProductsLoadingSuccessAction(
                        response?.map(([res, widget]) => ({
                            categoryId: widget.category ?? widget.saleUid,
                            products: productsToSliderItems(
                                (res.body ?? []).map((item) => ({
                                    ...item,
                                    sale: widget?.saleUid
                                        ? ({
                                              uid: widget.saleUid,
                                              discountAmountFixed: widget.saleDiscountAmountFixed,
                                              discountAmountPercent: widget.saleDiscountAmountPercent,
                                              name: widget.saleName,
                                          } as Sale)
                                        : undefined,
                                })),
                                onProductItemClick,
                                state.productReducer.productsWishList,
                            ),
                        })),
                    ),
                ),
            );
        } catch (error) {
            console.error('at storeActions in loadStoreWidgetsProducts', error);
        } finally {
            dispatch(storeWidgetsProductsLoadingAction(false));
        }
    };

export const loadStoreProducts =
    (storeUid: string, infiniteScroll?: boolean, params: StoreProductsSearchParams = {}, forceRequest = false) =>
    async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        if (!storeUid) {
            return;
        }

        const state = getState();

        if (
            state.storeReducer.searchEndReached &&
            deepEqual(state.storeReducer.searchParams, params) &&
            !forceRequest
        ) {
            return;
        }

        dispatch(storeProductsLoadingAction(true));

        const request = {
            ...params,
            storeUid,
            count: 10,
            offset: infiniteScroll ? state.storeReducer.storeProducts?.length : undefined,
            productType: getActiveProductType(state),
        };

        try {
            const response = await (!isLoggedIn(getState())
                ? new PublicControllerApi().productSearchUsingPOST1(
                      request,
                      getCountryCodeFromState(getState()),
                      getLanguageCode(getState()),
                  )
                : new ProductControllerApi().productSearchUsingPOST(
                      request,
                      getCountryCodeFromState(getState()),
                      getLanguageCode(getState()),
                  ));
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(
                storeProductsLoadingSuccessAction(
                    response?.body?.map((item) => productToIProduct(item, getState())),
                    !!infiniteScroll,
                    response.totalCount,
                    params,
                ),
            );
        } catch (error) {
            console.error('at storeActions in loadStoreProducts', error);
        } finally {
            dispatch(storeProductsLoadingAction(false));
        }
    };

export const loadStoreCategories =
    (
        storeUid: string,
        searchRequest: {
            hashtag?: string;
            category?: string;
            name?: string;
            saleUid?: string;
        } = {},
    ) =>
    async (dispatch: Dispatch, getState: () => IApplicationStore) => {
        dispatch(storeCategoriesLoadingAction(true));
        const state = getState();
        try {
            const response = await new ClassificatorControllerApi().productSearchCategoryUsingPOST(
                { ...searchRequest, storeUid, productType: getActiveProductType(state) },
                getCountryCodeFromState(state),
                false,
                getLanguageCode(state),
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(storeCategoriesLoadingSuccessAction(response?.body ?? []));
        } catch (error) {
            console.error('at storeActions in loadStoreCategories ', error);
        } finally {
            dispatch(storeCategoriesLoadingAction(false));
        }
    };

export const loadTopStores = () => async (dispatch: Dispatch, getState: () => IApplicationStore) => {
    dispatch(topStoresLoadingAction(true));
    const state = getState();
    try {
        const response = await new PublicControllerApi().storeTopUsingGET(
            getCountryCodeFromState(state),
            getLanguageCode(state),
        );
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(topStoresLoadingSuccessAction(response?.body?.map(storeToCarouselItem)));
    } catch (error) {
        console.error('in storeActions at loadTopStores');
    } finally {
        dispatch(topStoresLoadingAction(false));
    }
};

import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIconProps } from '@/types/icons';

export const IcGoogle = React.memo<IcIconProps>((props) => {
    const maskId = useNodeUid();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
            <g mask={`url(#${maskId})`}>
                <path
                    fill="#4285F4"
                    d="M21.103 12.23c0-.68-.055-1.364-.173-2.032h-9.432v3.85H16.9a4.629 4.629 0 01-2 3.04v2.498h3.223c1.892-1.742 2.98-4.314 2.98-7.356z"
                />
                <path
                    fill="#34A853"
                    d="M11.498 22c2.697 0 4.972-.886 6.629-2.414l-3.222-2.499c-.897.61-2.054.956-3.403.956-2.609 0-4.82-1.76-5.615-4.127H2.562v2.576A10.001 10.001 0 0011.498 22z"
                />
                <path
                    fill="#FBBC04"
                    d="M5.883 13.916a5.99 5.99 0 010-3.829V7.513H2.562a10.009 10.009 0 000 8.98l3.321-2.576z"
                />
                <path
                    fill="#EA4335"
                    d="M11.498 5.957a5.434 5.434 0 013.836 1.5L18.19 4.6A9.61 9.61 0 0011.5 2a9.998 9.998 0 00-8.937 5.512l3.322 2.575c.79-2.37 3.005-4.13 5.614-4.13z"
                />
            </g>
            <defs>
                <clipPath id={maskId}>
                    <path fill="#fff" d="M0 0H19.603V20H0z" transform="translate(1.5 2)" />
                </clipPath>
            </defs>
        </svg>
    );
});

IcGoogle.displayName = 'IcGoogle';

import React from 'react';

import { SellerAreaBackLinkProps } from './SellerAreaBackLink.types';

import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const SellerAreaBackLink = (props: SellerAreaBackLinkProps) => {
    const loggedIn = useAppSelector(isLoggedIn);

    return (
        <AdaptiveBackLink {...props} reloadAll mobileLink={loggedIn ? '/profile/seller-area/' : '/'} desktopLink="/" />
    );
};

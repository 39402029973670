import cn from 'classnames';
import React, { useMemo } from 'react';

import { PaginationIndicatorProps } from './PaginationIndicator.types';

import './PaginationIndicator.less';

export const PaginationIndicator = ({ totalItems, currentIndex }: PaginationIndicatorProps) => {
    const indicators = useMemo(
        () =>
            Array.from({ length: totalItems }, (_, index) => (
                <div
                    key={index}
                    className={cn('carousel__pagination-indicator', {
                        active: index === currentIndex,
                    })}
                />
            )),
        [currentIndex, totalItems],
    );
    return totalItems > 1 ? <div className="carousel__pagination">{indicators}</div> : null;
};

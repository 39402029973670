import { GoogleApiWrapper, IMapProps, Map, mapEventHandler } from 'google-maps-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { IApplicationStore } from '@/reducers/rootReducer';

class MapContainer extends Component<IMapProps> {
    handleReady: mapEventHandler = (mapProps, map, e) => {
        const { google } = this.props;
        const latLng = new google.maps.LatLng(mapProps.center.lat, mapProps.center.lng);

        map.setCenter(latLng);
        new google.maps.Circle({
            strokeColor: '#595959',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#A3A3A3',
            fillOpacity: 0.5,
            map,
            center: latLng,
            radius: 1000,
        });
        this.props.onReady?.(mapProps, map, e);
    };

    render() {
        const { center, zoom } = this.props;

        return (
            <Map
                centerAroundCurrentLocation
                google={this.props.google}
                zoom={zoom}
                center={center}
                initialCenter={center}
                {...this.props}
                onReady={this.handleReady}
            />
        );
    }
}

const mapStateToProps = (state: IApplicationStore) => ({
    apiKey: state.rootReducer.localConfig ? state.rootReducer.localConfig.GoogleMapAPIkey : '',
});

export default compose<React.ComponentClass<Omit<IMapProps, 'google'>>>(
    connect(mapStateToProps),
    GoogleApiWrapper(({ apiKey }) => {
        return {
            apiKey,
            language: 'ru',
            region: 'ru',
        };
    }),
)(MapContainer);

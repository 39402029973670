import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    Product,
    ProductControllerApi,
    ProductListRequest,
    Sale,
    SaleControllerApi,
    SaleProduct,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const SALE_ERROR = 'SALE_ERROR' as const;

export const SALE_LIST_LOADING = 'SALE_LIST_LOADING' as const;
export const SALE_LIST_LOADING_SUCCESS = 'SALE_LIST_LOADING_SUCCESS' as const;

export const SALE_INFO_LOADING = 'SALE_INFO_LOADING' as const;
export const SALE_INFO_LOADING_SUCCESS = 'SALE_INFO_LOADING_SUCCESS' as const;

export const SALE_VALIDATION_LOADING = 'SALE_VALIDATION_LOADING' as const;
export const SALE_VALIDATION_LOADING_SUCCESS = 'SALE_VALIDATION_LOADING_SUCCESS' as const;

export const SALE_PRODUCTS_OR_CATEGORIES_LOADING = 'SALE_PRODUCTS_OR_CATEGORIES_LOADING' as const;

export const DELETE_SALE_LOADING = 'DELETE_SALE_LOADING' as const;
export const DELETE_SALE_LOADING_SUCCESS = 'DELETE_SALE_LOADING_SUCCESS' as const;

export const SALE_PRODUCTS_SEARCH_LOADING = 'SALE_PRODUCTS_SEARCH_LOADING' as const;
export const SALE_PRODUCTS_SEARCH_LOADING_SUCCESS = 'SALE_PRODUCTS_SEARCH_LOADING_SUCCESS' as const;

export const SALE_STATUS_CHANGE_LOADING = 'SALE_STATUS_CHANGE_LOADING' as const;
export const SALE_STATUS_CHANGE_LOADING_SUCCESS = 'SALE_STATUS_CHANGE_LOADING_SUCCESS' as const;

export const RESTART_SALE_LOADING = 'RESTART_SALE_LOADING' as const;
export const RESTART_SALE_LOADING_SUCCESS = 'RESTART_SALE_LOADING_SUCCESS' as const;
export const SALE_INFO_RESET = 'SALE_INFO_RESET' as const;

const saleSearchLoadingAction = (saleSearchLoading: boolean) => ({
    type: SALE_PRODUCTS_SEARCH_LOADING,
    payload: { saleSearchLoading },
});

const saleSearchLoadingSuccessAction = (searchedProducts: Product[]) => ({
    type: SALE_PRODUCTS_SEARCH_LOADING_SUCCESS,
    payload: { searchedProducts },
});

const saleListLoadingAction = (saleListLoading: boolean) => ({
    type: SALE_LIST_LOADING,
    payload: { saleListLoading },
});

const saleListLoadingSuccessAction = (saleList: Sale[]) => ({
    type: SALE_LIST_LOADING_SUCCESS,
    payload: { saleList },
});

const deleteSaleLoadingAction = (saleInfoLoading: boolean) => ({
    type: DELETE_SALE_LOADING,
    payload: { saleInfoLoading },
});

const deleteSaleLoadingSuccessAction = (saleUid?: string) => ({
    type: DELETE_SALE_LOADING_SUCCESS,
    payload: { saleUid },
});

const saleCategoriesOrProductsLoadingAction = (saleItemsLoading: boolean) => ({
    type: SALE_PRODUCTS_OR_CATEGORIES_LOADING,
    payload: { saleItemsLoading },
});

const saleInfoLoadingAction = (saleInfoLoading: boolean) => ({
    type: SALE_INFO_LOADING,
    payload: { saleInfoLoading },
});

const saleInfoLoadingSuccessAction = (saleInfo: Sale) => ({
    type: SALE_INFO_LOADING_SUCCESS,
    payload: { saleInfo },
});

const saleErrorAction = (saleError: string) => ({
    type: SALE_ERROR,
    payload: { saleError },
});

const saleStatusChangeLoadingAction = (saleStatusChangeLoading: boolean) => ({
    payload: { saleStatusChangeLoading },
    type: SALE_STATUS_CHANGE_LOADING,
});

const saleStatusChangeLoadingSuccessAction = (sale: Sale) => ({
    type: SALE_STATUS_CHANGE_LOADING_SUCCESS,
    payload: { sale },
});

const saleRestartLoadingAction = (saleRestartLoading: boolean) => ({
    type: RESTART_SALE_LOADING,
    payload: { saleRestartLoading },
});

const saleRestartLoadingSuccessAction = (sale: Sale) => ({
    type: RESTART_SALE_LOADING_SUCCESS,
    payload: { sale },
});

const validateSaleLoadingAction = (validateLoading: boolean) => ({
    type: SALE_VALIDATION_LOADING,
    payload: { validateLoading },
});

const validateSaleLoadingSuccessAction = (products: SaleProduct[]) => ({
    type: SALE_VALIDATION_LOADING_SUCCESS,
    payload: { products },
});

export const resetSaleInfoAction = () => ({ type: SALE_INFO_RESET });

export type SaleActions = ReturnType<
    | typeof saleInfoLoadingAction
    | typeof saleErrorAction
    | typeof saleInfoLoadingSuccessAction
    | typeof saleCategoriesOrProductsLoadingAction
    | typeof deleteSaleLoadingAction
    | typeof deleteSaleLoadingSuccessAction
    | typeof saleListLoadingAction
    | typeof saleListLoadingSuccessAction
    | typeof saleSearchLoadingAction
    | typeof saleSearchLoadingSuccessAction
    | typeof saleStatusChangeLoadingAction
    | typeof saleStatusChangeLoadingSuccessAction
    | typeof saleRestartLoadingAction
    | typeof saleRestartLoadingSuccessAction
    | typeof resetSaleInfoAction
    | typeof validateSaleLoadingAction
    | typeof validateSaleLoadingSuccessAction
>;

export const loadSalesList = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    dispatch(saleListLoadingAction(true));
    try {
        const response = await new SaleControllerApi().getSalesUsingGET(getLanguageCode(state));
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(saleListLoadingSuccessAction(response?.body));
    } catch (error) {
        console.error('in loadSalesList at saleActions');
    } finally {
        dispatch(saleListLoadingAction(false));
    }
};

export const createOrEditSaleGeneralInfo =
    (saleGeneralInfo: Sale) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(saleInfoLoadingAction(true));
        const state = getState();
        try {
            const response = saleGeneralInfo?.uid
                ? await new SaleControllerApi().editSaleUsingPOST(
                      saleGeneralInfo,
                      saleGeneralInfo.uid,
                      getLanguageCode(state),
                  )
                : await new SaleControllerApi().addSaleUsingPUT(saleGeneralInfo, getLanguageCode(state));
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(saleInfoLoadingSuccessAction(response.body?.[0]));
        } catch (error) {
            console.error('in createOrEditSaleGeneralInfo at saleActions');
        } finally {
            dispatch(saleInfoLoadingAction(false));
        }
        return getState();
    };

export const uploadSaleProducts = (sale: Sale) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(saleInfoLoadingAction(true));
    const state = getState();
    try {
        const response = await new SaleControllerApi().editSaleProductsUsingPOST(
            sale.products?.map((item) => ({
                productUid: item.productUid,
                salePrice: item.salePrice,
            })),
            sale.uid,
            getLanguageCode(state),
        );

        handleResponseAndThrowAnErrorIfExists(response);

        return getState();
    } catch (error) {
        console.error('in uploadSaleProducts at saleActions', error);
        return getState();
    } finally {
        dispatch(saleInfoLoadingAction(false));
    }
};

export const validateSaleItems =
    (products: SaleProduct[], saleUid: string, ignorePrice?: boolean) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(validateSaleLoadingAction(true));
        const state = getState();
        try {
            const response = await new SaleControllerApi().validateProductsUsingPOST(
                products?.map((item) => ({
                    productUid: item.productUid,
                    category: item.productUid ? undefined : item.category,
                    salePrice: ignorePrice ? undefined : item.salePrice,
                })),
                saleUid,
                getLanguageCode(state),
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(validateSaleLoadingSuccessAction(response?.body as unknown as SaleProduct[]));
        } catch (error) {
            console.error('in validateSaleItems at saleActions', error);
            dispatch(saleErrorAction((error as Error)?.message || `${error}`));
        } finally {
            dispatch(validateSaleLoadingAction(false));
        }
    };

export const loadSaleInfo = (saleUID: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(saleInfoLoadingAction(true));
    const state = getState();
    try {
        const response = await new SaleControllerApi().getSaleUsingGET1(saleUID, getLanguageCode(state));
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(saleInfoLoadingSuccessAction(response.body?.[0]));
    } catch (error) {
        console.error('at saleActions in loadSaleInfo', error);
        dispatch(saleErrorAction((error as Error)?.message || `${error}`));
    } finally {
        dispatch(saleInfoLoadingAction(false));
    }
};

export const editSaleProduct =
    (product: SaleProduct, saleUid: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(saleCategoriesOrProductsLoadingAction(true));
        const state = getState();
        try {
            const response = await new SaleControllerApi().editSaleProductsUsingPOST(
                [product],
                saleUid,
                getLanguageCode(state),
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(saleInfoLoadingSuccessAction(response?.body?.[0]));
        } catch (error) {
            console.error('in editSaleProduct at saleActions', error);
            dispatch(saleErrorAction((error as Error)?.message || `${error}`));
        } finally {
            dispatch(saleCategoriesOrProductsLoadingAction(false));
        }
    };

export const deleteSale = (saleUid: string) => async (dispatch: AppDispatch) => {
    dispatch(deleteSaleLoadingAction(true));
    try {
        const response = await new SaleControllerApi().deleteSaleUsingDELETE(saleUid);
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(deleteSaleLoadingSuccessAction(saleUid));
    } catch (error) {
        console.error('in deleteSale at saleActions', error);
        dispatch(saleErrorAction((error as Error)?.message || `${error}`));
    } finally {
        dispatch(deleteSaleLoadingAction(false));
    }
};

export const searchSaleProducts =
    (searchParams: ProductListRequest = {}) =>
    async (dispatch: AppDispatch) => {
        dispatch(saleSearchLoadingAction(true));
        try {
            const response = await new ProductControllerApi().productListUsingPOST(searchParams);
            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(saleSearchLoadingSuccessAction(response.body));
        } catch (error) {
            console.error('in saerchSaleProducts at saleActions', error);
        } finally {
            dispatch(saleSearchLoadingAction(false));
        }
    };

export const stopSale = (sale: Sale) => async (dispatch: AppDispatch) => {
    dispatch(saleStatusChangeLoadingAction(true));
    try {
        const response = await new SaleControllerApi().expireSaleUsingPOST(sale.uid);
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(saleStatusChangeLoadingSuccessAction(response.body?.[0]));
    } catch (error) {
        console.error('in saleActions at stopSale', error);
        dispatch(saleErrorAction((error as Error).message));
    } finally {
        dispatch(saleStatusChangeLoadingAction(false));
    }
};

export const restartSale = (sale: Sale) => async (dispatch: AppDispatch) => {
    dispatch(saleRestartLoadingAction(true));
    try {
        const response = await new SaleControllerApi().addSaleUsingPUT({
            ...sale,
            uid: undefined,
            sourceSaleUid: sale.uid,
        });
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(saleRestartLoadingSuccessAction(response.body?.[0]));
    } catch (error) {
        console.error('in saleActions at restartSale');
        dispatch(saleErrorAction((error as Error).message));
    } finally {
        dispatch(saleRestartLoadingAction(false));
    }
};

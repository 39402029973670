import { PublicControllerApi } from '@/generated/marketplaceapi';
import { Location } from '@/types/location';

const LANGUAGE = 'ru';

const publicControllerApi = new PublicControllerApi();

type IpApiResponse = {
    location: {
        latitude: number;
        longitude: number;
        accuracy_radius: number;
    };
    country: {
        geoname_id: number;
        iso_code: string;
        names: any[];
    };
    city: {
        geoname_id: number;
        names: any[];
    };
    continent: {
        geoname_id: number;
        names: any[];
    };
    postal: {
        code: string;
    };
};

export const detectIPLocation = async (): Promise<Location> => {
    const data = (await publicControllerApi.getLocationInfoUsingGET()) as unknown as IpApiResponse;

    return {
        latitude: data.location.latitude,
        longitude: data.location.longitude,
        accuracy: data.location.accuracy_radius,
        countryCode: data.country.iso_code,
        countryName: data.country.names[LANGUAGE],
        city: data.city.names[LANGUAGE],
        postalCode: data.postal.code,
    };
};

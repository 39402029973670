import cn from 'classnames';
import { Button } from 'framework7-react';
import React from 'react';

import { AddNewButtonProps } from './AddNewButton.types';

import { IcPlus } from '@/assets';

import './AddNewButton.less';

export const AddNewButton = ({ dashed = false, children, className, ...props }: AddNewButtonProps) => (
    <Button className={cn('add-new-button', { dashed }, className)} {...props}>
        <IcPlus />
        {children}
    </Button>
);

import { useEffect } from 'react';

import { loadMyGoodsList } from '@/actions/myGoodsActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadMyGoodsList = ({ archived }: { archived?: boolean }) => {
    const dispatch = useAppDispatch();

    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        if (loggedIn) {
            dispatch(loadMyGoodsList({ archived }));
        }
    }, [archived, dispatch, loggedIn]);

    return null;
};

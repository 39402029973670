import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReviewEstimationLabelProps } from './ReviewEstimationLabel.types';

import './ReviewEstimationLabel.less';

export const ReviewEstimationLabel: React.FC<ReviewEstimationLabelProps> = () => {
    const { t } = useTranslation();

    return <p className="review-estimation-label">{t('productReviewEstimation')}</p>;
};

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { FeatureControllerApi, Product, ProductControllerApi, ProductFeatureType } from '@/generated/marketplaceapi';
import { IApplicationStore } from '@/reducers/rootReducer';

export const SERVICE_PACKAGES_LOADING = 'SERVICE_PACKAGES_LOADING' as const;
export const SERVICE_PACKAGES_LOADING_SUCCESS = 'SERVICE_PACKAGES_LOADING_SUCCESS' as const;
export const SERVICE_PACKAGES_LOADING_ERROR = 'SERVICE_PACKAGES_LOADING_ERROR' as const;

export const PUBLISHED_PRODUCTS_LOADING = 'PUBLISHED_PRODUCTS_LOADING' as const;
export const PUBLISHED_PRODUCTS_LOADING_SUCCESS = 'PUBLISHED_PRODUCTS_LOADING_SUCCESS' as const;
export const PUBLISHED_PRODUCTS_LOADING_ERROR = 'PUBLISHED_PRODUCTS_LOADING_ERROR' as const;

const servicePackagesLoadingAction = () => ({
    type: SERVICE_PACKAGES_LOADING,
});

const servicePackagesLoadingSuccessAction = (items: ProductFeatureType[]) => ({
    type: SERVICE_PACKAGES_LOADING_SUCCESS,
    items,
});

const servicePackagesLoadingErrorAction = (error: unknown) => ({
    type: SERVICE_PACKAGES_LOADING_ERROR,
    error,
});

const publishedProductsLoading = () => ({
    type: PUBLISHED_PRODUCTS_LOADING,
});

const publishedProductsLoadingSuccess = (items: Product[]) => ({
    type: PUBLISHED_PRODUCTS_LOADING_SUCCESS,
    items,
});

const publishedProductsLoadingError = (error: unknown) => ({
    type: PUBLISHED_PRODUCTS_LOADING_ERROR,
    error,
});

export type ProductPromotionActions = ReturnType<
    | typeof servicePackagesLoadingAction
    | typeof servicePackagesLoadingSuccessAction
    | typeof servicePackagesLoadingErrorAction
    | typeof publishedProductsLoading
    | typeof publishedProductsLoadingSuccess
    | typeof publishedProductsLoadingError
>;

export const loadServicePackages = () => async (dispatch, getState: () => IApplicationStore) => {
    dispatch(servicePackagesLoadingAction());

    const state = getState();

    try {
        const language = state.sessionReducer.profile.language.code;
        const currency = state.myWalletsReducer.wallets.length
            ? state.myWalletsReducer.wallets.filter((item) => item.primary)[0].currency.code
            : 'USD';

        const result = await new FeatureControllerApi().productFeatureListUsingGET(currency, language);

        handleResponseAndThrowAnErrorIfExists(result);

        const items = result.body || [];

        dispatch(servicePackagesLoadingSuccessAction(items));
    } catch (err) {
        console.error('at productPromotionActions in loadServicePackages', err);

        dispatch(servicePackagesLoadingErrorAction(err.message));
    }
};

export const loadPublishedProducts = () => async (dispatch) => {
    dispatch({ type: PUBLISHED_PRODUCTS_LOADING });
    try {
        const result = await new ProductControllerApi().productListUsingPOST({
            status: Product.StatusEnum.PBL.toString(),
        });

        handleResponseAndThrowAnErrorIfExists(result);

        const items = result.body || [];
        dispatch(publishedProductsLoadingSuccess(items));
    } catch (err) {
        console.error('at productPromotionActions in loadPublishedProducts', err);

        dispatch(publishedProductsLoadingError(err.message));
    }
};

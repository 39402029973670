import cn from 'classnames';
import React from 'react';

import { SellerAreaShrinkMenuProps } from './SellerAreaShrinkMenu.types';

import { PageShrinkMenu } from '@/components/PageSideMenu/PageShrinkMenu';

import './SellerAreaShrinkMenu.less';

export const SellerAreaShrinkMenu = ({ className, ...props }: SellerAreaShrinkMenuProps) => (
    <PageShrinkMenu {...props} className={cn('seller-area-shrink-menu', className)} />
);

import cn from 'classnames';
import { Link } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OnbordingMessageProps, OnbordingMessageType } from './OnbordingMessage.types';

import { onbordingMessagesService } from '@/api/onbordingMessagesService';
import {
    IcClose,
    IcMail,
    IcOnboardingMessageFavoriteStore,
    IcOnboardingMessageHomepage,
    IcOnboardingMessageProducts,
    IcOnboardingMessageSale,
    IcOnboardingMessageStore,
    IcUnion,
} from '@/assets';

import './OnbordingMessage.less';

export const OnbordingMessage = React.memo<OnbordingMessageProps>(
    ({ type, triangle = false, onClose, onClick, className, visibleInitially = false, ...props }) => {
        const { t } = useTranslation();

        const [hidden, setHidden] = useState(true);

        const [messageClosed, setMessageClosed] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => setHidden(false), 500);
            return () => clearTimeout(timer);
        }, []);

        const Icon = useMemo(() => {
            switch (type) {
                case OnbordingMessageType.PRODUCTS:
                    return IcOnboardingMessageProducts;
                case OnbordingMessageType.SALE:
                    return IcOnboardingMessageSale;
                case OnbordingMessageType.STORE:
                    return IcOnboardingMessageStore;
                case OnbordingMessageType.STORE_HOMEPAGE:
                    return IcOnboardingMessageHomepage;
                case OnbordingMessageType.FAVORITE_STORE:
                    return IcOnboardingMessageFavoriteStore;
                case OnbordingMessageType.EMAIL_VERIFIED:
                    return IcMail;
                case OnbordingMessageType.ADD_PRODUCTS:
                    return IcOnboardingMessageProducts;
                case OnbordingMessageType.DELIVERY:
                    return IcUnion;
                default:
                    return IcOnboardingMessageProducts;
            }
        }, [type]);

        const header = useMemo(() => {
            switch (type) {
                case OnbordingMessageType.PRODUCTS:
                    return t('Add products');
                case OnbordingMessageType.SALE:
                    return t('Set up your first sale');
                case OnbordingMessageType.STORE:
                    return t('Create your store');
                case OnbordingMessageType.STORE_HOMEPAGE:
                    return t('Create Homepage');
                case OnbordingMessageType.FAVORITE_STORE:
                    return t('Add this store to favorite');
                case OnbordingMessageType.EMAIL_VERIFIED:
                    return t('Your email has been verified!');
                case OnbordingMessageType.ADD_PRODUCTS:
                    return t('add_products');
                case OnbordingMessageType.DELIVERY:
                    return t('delivery.tip');
                default:
                    return t('Add products');
            }
        }, [t, type]);

        const text = useMemo(() => {
            switch (type) {
                case OnbordingMessageType.PRODUCTS:
                    return t('Start selling! Add products to your store');
                case OnbordingMessageType.SALE:
                    return t('Offer discounts on your items');
                case OnbordingMessageType.STORE:
                    return t('Switch to business account');
                case OnbordingMessageType.STORE_HOMEPAGE:
                    return t("Customize your online store's design");
                case OnbordingMessageType.FAVORITE_STORE:
                    return t('To get quick access to it');
                case OnbordingMessageType.EMAIL_VERIFIED:
                    return t('Log In to continue.');
                case OnbordingMessageType.ADD_PRODUCTS:
                    return t('add_product_tip');
                case OnbordingMessageType.DELIVERY:
                    return '';
                default:
                    return t('Start selling! Add products to your store');
            }
        }, [t, type]);

        const handleClose = useCallback(
            (e: React.MouseEvent<HTMLDivElement>) => {
                onClose?.();
                setMessageClosed(true);
                onbordingMessagesService.setMessageToLocalStorage(type, true);
                e.stopPropagation();
            },
            [onClose, type],
        );

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLDivElement>) => {
                onClick?.(e);
                onbordingMessagesService.setMessageToLocalStorage(type, true);
            },
            [onClick, type],
        );

        if (
            (hidden && !visibleInitially) ||
            messageClosed ||
            onbordingMessagesService.getMessageFromLocalStorage(type)
        ) {
            return <></>;
        }

        return (
            <div
                {...props}
                onClick={handleClick}
                className={cn('onboarding-message', { triangle }, `triangle-${triangle}`, className)}
            >
                <div className="onboarding-message__icon">
                    <Icon />
                </div>
                <div className="onboarding-message__text">
                    <h4>{header}</h4>
                    <p>{text}</p>
                </div>
                <Link iconOnly className="onboarding-message__close" onClick={handleClose}>
                    <IcClose />
                </Link>
            </div>
        );
    },
);
OnbordingMessage.displayName = 'OnbordingMessage';

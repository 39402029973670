export const getDiffInDays = (date1: Date | null, date2: Date | null) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    if (date1 === null || date2 === null) return 0;

    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.abs(Math.floor((utc2 - utc1) / MS_PER_DAY)) + 1;
};

import cn from 'classnames';
import { BlockTitle, f7, Link, ListButton } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { messengerService } from '@/api/messengerService';
import { IcStore } from '@/assets';
import { MessengerMenu } from '@/components/MessengerMenu';
import { useAppSelector } from '@/hooks/store';

import { getActiveProductType } from '@/selectors/getActiveProductType';

import { ProductType } from '@/types/productType';

import type { SellerProps } from './Seller.types';

import './Seller.less';

export const Seller = ({ className, onSendMessageClick }: SellerProps): JSX.Element => {
    const { t } = useTranslation();

    const { store, seller } = useAppSelector((state) => state.productReducer.productDetails);
    const activeType = useAppSelector(getActiveProductType);

    const isStore = Boolean(store?.homepage);

    const avatarUrl = useMemo(() => {
        if (seller.imageUrl) return seller.imageUrl;
        return isStore ? store?.imageUrl : store?.account?.profilePictureUrl;
    }, [isStore, seller?.imageUrl, store?.account?.profilePictureUrl, store?.imageUrl]);

    const storeCode = useMemo(() => store?.homepage?.match(/[^/]*$/)?.[0], [store?.homepage]);

    const name = useMemo(() => {
        if (seller.name) return seller.name;

        if (isStore) {
            return store?.name ?? `${process.env.APP_NAME} Store`;
        }

        if (!store?.account?.name && !store?.account?.surname) {
            return `${process.env.APP_NAME} User`;
        }

        return `${store?.account?.name ?? ''} ${store?.account?.surname ?? ''}`;
    }, [isStore, seller.name, store?.account?.name, store?.account?.surname, store?.name]);

    const handleSellerClick = useCallback(() => {
        if (isStore) {
            f7.views.main.router.navigate(`/store/${storeCode}/`);
        }
    }, [isStore, storeCode]);

    const hasContacts =
        messengerService.enabled || !!seller?.sellerWebResources?.length || seller.email || seller.phone;

    return (
        <div className={cn('product-details-seller', className)}>
            <BlockTitle>{t('Seller')}</BlockTitle>
            <div className="product-details-seller__main">
                <Link className="product-details-seller__main-link" onClick={handleSellerClick}>
                    <div className="product-details-seller__avatar-wrap">
                        {avatarUrl && <img src={avatarUrl} alt="store img" />}
                    </div>
                    <div className="product-details-seller__info">
                        <header>{name}</header>
                    </div>
                </Link>

                {!isStore && hasContacts && <MessengerMenu onSendMessageClick={onSendMessageClick} seller={seller} />}
            </div>
            {isStore && hasContacts && (
                <div className="product-details-seller__options">
                    {activeType !== ProductType.Product && (
                        <MessengerMenu onSendMessageClick={onSendMessageClick} seller={seller} isStore />
                    )}
                    <ListButton className={cn({ disabled: !storeCode })} href={`/store/${storeCode}`}>
                        <IcStore />
                        {t('Visit Seller’s Store')}
                    </ListButton>
                </div>
            )}
        </div>
    );
};

import cn from 'classnames';
import React from 'react';

import { LikeButtonProps } from './LikeButton.types';

import { IcHeart } from '@/assets';
import { noop } from '@/utils';

import './LikeButton.less';

export const LikeButton = React.forwardRef<HTMLInputElement, LikeButtonProps>(
    ({ nonCircle, heartStyle, className, onLabelClick = noop, ...props }, ref) => (
        <label
            className={cn(
                'like-button',
                {
                    'like-button_active': props.checked,
                    'like-button_non-circle': nonCircle,
                    'like-button_heart-style': heartStyle,
                },
                className,
            )}
            onClick={onLabelClick}
        >
            <input aria-label="like" {...props} type="checkbox" ref={ref} />
            <IcHeart />
        </label>
    ),
);

LikeButton.displayName = 'LikeButton';

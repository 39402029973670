// Import the functions you need from the SDKs you need
import { setUserProperties } from 'firebase/analytics';

import { AnalyticsCustomer, IFirebaseAnalyticsService } from './analitics.types';

import { FirebaseAnalyticsBase } from './firebase.base';

import { Banner } from '@/generated/marketplaceapi';
import { IProduct } from '@/reducers/productReducer';
import { Profile } from '@/reducers/sessionReducer';

type FirebaseCommonEventName =
    | 'sign_up_try'
    | 'confirm_email_try'
    | 'confirm_email_success'
    | 'login_try'
    | 'banner_click';

export class FirebaseCommonAnalytics
    extends FirebaseAnalyticsBase<FirebaseCommonEventName>
    implements IFirebaseAnalyticsService
{
    public setUserProperties(profile: Profile) {
        setUserProperties(this.analytics, this.buildCustomerData(profile));
    }

    public registrationTry() {
        this.logEvent('sign_up_try');
    }

    public registrationSuccess(profile: Profile) {
        this.logEvent('sign_up', this.buildCustomerData(profile));
    }

    public confirmEmailTry() {
        this.logEvent('confirm_email_try');
    }

    public confirmEmailSuccess() {
        this.logEvent('confirm_email_success');
    }

    public loginTry() {
        this.logEvent('login_try');
    }

    public loginSuccess(profile: Profile) {
        this.logEvent('login', this.buildCustomerData(profile));
    }

    public bannerClick(banner: Banner, channel: 'mobile' | 'web') {
        this.logEvent('banner_click', this.buildBannerData(banner, channel));
    }

    public openProductDetails(product: IProduct) {
        this.logEvent('view_item', this.buildProductData(product));
    }

    private buildCustomerData(profile: Profile): AnalyticsCustomer {
        return {
            customerUid: profile.uid,
            customerName: profile.person?.name + ' ' + profile.person?.surname,
        };
    }
}

import { Router } from 'framework7/modules/router/router';
import { f7 } from 'framework7-react';
import { useEffect, useRef, useState } from 'react';

export const useF7routerBeforeItsReady = <T>(
    selector: (f7router: Partial<Router.Router>) => T,
    predicate = (routerValue: T) => routerValue !== undefined,
): T => {
    const [routerValue, setRouterValue] = useState<T>();

    const selectorRef = useRef(selector);
    const predicateRef = useRef(predicate);

    useEffect(() => {
        let timer: number;

        const checkAgain = () => {
            if (!selectorRef.current) return;

            const candidate = selectorRef.current(f7.view.main.router);

            if (predicateRef.current(candidate)) {
                setRouterValue(candidate);
                return;
            }

            timer = window.setTimeout(checkAgain, 100);
        };

        checkAgain();

        return () => clearTimeout(timer);
    }, []);

    return routerValue;
};

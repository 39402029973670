/* eslint-disable no-self-compare */
import cn from 'classnames';
import { Icon, Link, List, ListItem, Navbar, NavRight, NavTitle } from 'framework7-react';
import React, { useCallback } from 'react';

import { SelectSheetProps } from './SelectSheet.types';

import { IcClose } from '@/assets';
import { CircleDoneButton } from '@/components/CircleDoneButton';
import { CustomCheckbox } from '@/components/CustomCheckbox';
import { CustomSelectValue } from '@/components/CustomSelect';
import { MSSheet } from '@/shared/UIKit/Sheet/Sheet';
import { Sheets } from '@/types/sheets';

import './SelectSheet.less';

export const SelectSheet = ({ options, title, className, ...props }: SelectSheetProps) => {
    const isChecked = useCallback(
        (option: CustomSelectValue) =>
            props.type === 'radio'
                ? option.value === option.value // TODO
                : !!props.value?.find?.((item) => item.value === option.value),
        [props.type, props.value],
    );

    return (
        <MSSheet opened={props.opened} {...props} className={cn(Sheets.SELECT_SHEET, 'select-sheet', className)}>
            <Navbar noHairline noShadow className="select-sheet__navbar">
                <NavTitle>{title}</NavTitle>
                <NavRight>
                    <Link iconOnly onClick={props.onSheetClose}>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <List noChevron noHairlines className={cn('select-sheet__options', props.type)}>
                {options.map((option) => {
                    switch (props.type) {
                        case 'checkbox':
                            props.value ??= [];

                            return (
                                <CustomCheckbox
                                    key={`${option.label}${option.value}`}
                                    title={option.label}
                                    checked={isChecked(option)}
                                    onChange={() =>
                                        props.onChange(
                                            isChecked(option)
                                                ? props.value.filter(({ value }) => value !== option.value)
                                                : [...props.value, option],
                                        )
                                    }
                                >
                                    <span slot="after">{option.icon}</span>
                                </CustomCheckbox>
                            );

                        case 'radio':
                            return (
                                <ListItem
                                    key={`${option.label}${option.value}`}
                                    link
                                    sheetClose
                                    title={option.label}
                                    onClick={() => {
                                        props.onChange(option);
                                        props.onSheetClose();
                                    }}
                                >
                                    <span slot="after">{option.icon}</span>
                                    {props.withCheckedIcon && props.value?.value === option.value && (
                                        <Icon ios="f7:checkmark_alt" md="material:check" slot="after-title" />
                                    )}
                                </ListItem>
                            );

                        default:
                            return null;
                    }
                })}
            </List>

            {props.type === 'checkbox' && (
                <CircleDoneButton className="select-sheet__done" onClick={() => props.onSheetClose()} />
            )}
        </MSSheet>
    );
};

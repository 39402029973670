import { f7 } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ExtendDateModalProps } from './ExtendDateModal.types';

import { updateProductExpirationDate } from '@/actions/productStatusActions';
import { SingleDatePicker } from '@/components/DatePickers/SingleDatePicker';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';

import './ExtendDateModal.less';

export const ExtendDateModal = ({ productUid, ...props }: ExtendDateModalProps): JSX.Element => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const isMobile = useAppSelector(getIsMobile);

    const [expirationDate, setExpirationDate] = useState<Date>(null);

    const extendDate = useCallback(() => {
        if (!expirationDate || expirationDate.getTime() < Date.now()) {
            f7.dialog.alert(t('Expiration date must be greater or equal to the current date'));
            return;
        }
        dispatch(updateProductExpirationDate(productUid, expirationDate));
    }, [dispatch, expirationDate, productUid, t]);

    return (
        <SingleDatePicker
            date={expirationDate}
            setDate={setExpirationDate}
            component={isMobile ? 'sheet' : 'popup'}
            isMobile={isMobile}
            pickerTitle={t('New expiration date')}
            onApply={extendDate}
            applyText={t('Extend')}
            {...props}
        />
    );
};

import { unionBy } from 'lodash';

import {
    ADD_REVIEW_LOADING,
    ADD_REVIEW_LOADING_ERROR,
    ADD_REVIEW_LOADING_SUCCESS,
    DELETE_MY_REVIEW,
    DELETE_MY_REVIEW_LOADING,
    DELETE_MY_REVIEW_LOADING_ERROR,
    DELETE_MY_REVIEW_LOADING_SUCCESS,
    EDIT_REVIEW_LOADING,
    EDIT_REVIEW_LOADING_ERROR,
    EDIT_REVIEW_LOADING_SUCCESS,
    MY_REVIEWS_LOADING,
    MY_REVIEWS_LOADING_ERROR,
    MY_REVIEWS_LOADING_SUCCESS,
    PRODUCT_RATING_LOADING,
    PRODUCT_RATING_LOADING_ERROR,
    PRODUCT_RATING_LOADING_SUCCESS,
    ReviewActions,
    REVIEWS_LOADING,
    REVIEWS_LOADING_ERROR,
    REVIEWS_LOADING_SUCCESS,
    SELLER_REVIEWS_LOADING,
    SELLER_REVIEWS_LOADING_ERROR,
    SELLER_REVIEWS_LOADING_SUCCESS,
    SET_MY_REVIEW_SORT_FIELD,
    SET_PRODUCT_RATING,
    SET_PRODUCT_UID_FOR_REVIEWS,
    SET_REVIEW_SORT_FIELD,
    SORT_MY_REVIEWS,
    SORT_MY_REVIEWS_ERROR,
    SORT_MY_REVIEWS_LOADING,
} from '@/actions/reviewActions';

import { ReviewSortingFields as Sort } from '@/shared/constants';

import type { ProductRating as Rating, ProductReview } from '@/generated/marketplaceapi';

export type ProductRating = Pick<Required<Rating>, keyof Rating>;

export interface IReviewState {
    productUid: string;
    loading: boolean;
    error?: unknown;
    reviews: ProductReview[];
    addReviewLoading: boolean;
    addReviewError?: unknown;
    addReviewSuccess: boolean;
    editReviewLoading: boolean;
    editReviewError?: unknown;
    editReviewSuccess: boolean;
    myReviewsLoading: boolean;
    sellerReviews?: ProductReview[];
    sellerReviewsLoading?: boolean;
    sellerReviewsLoadingError?: string;
    sellerReviewsTotalCount: number;
    myReviewsError?: unknown;
    myReviews: ProductReview[];
    myReviewsTotalCount: number;
    sort: Sort;
    productRatingLoading: boolean;
    productRatingSuccess: boolean;
    productRatingError?: unknown;
    productRating: ProductRating[];
    mySort: Sort;
    deleteError?: unknown;
    deleteLoading: boolean;
    deleteSuccess: boolean;
    sortMyReviewsLoading: boolean;
    sortMyReviewsError?: unknown;
}

const initialState: IReviewState = {
    productUid: undefined,
    loading: false,
    error: undefined,
    reviews: [],
    addReviewLoading: false,
    addReviewError: undefined,
    addReviewSuccess: false,
    editReviewLoading: false,
    editReviewError: undefined,
    editReviewSuccess: false,
    myReviewsLoading: false,
    myReviewsError: undefined,
    myReviews: [],
    myReviewsTotalCount: Infinity,
    sort: Sort.recent,
    productRatingLoading: false,
    productRatingSuccess: false,
    productRatingError: undefined,
    productRating: [],
    mySort: Sort.recent,
    deleteLoading: false,
    deleteSuccess: false,
    deleteError: undefined,
    sortMyReviewsLoading: false,
    sellerReviewsTotalCount: 0,
};

const reviewReducer = (state = initialState, action: ReviewActions): IReviewState => {
    switch (action.type) {
        case SET_PRODUCT_UID_FOR_REVIEWS: {
            return {
                ...state,
                productUid: action.productUid,
            };
        }
        case REVIEWS_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case REVIEWS_LOADING_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: undefined,
                reviews: action.reviews || [],
            };
        }
        case REVIEWS_LOADING_ERROR: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case ADD_REVIEW_LOADING: {
            return {
                ...state,
                addReviewLoading: action.loading,
            };
        }
        case ADD_REVIEW_LOADING_SUCCESS: {
            return {
                ...state,
                addReviewSuccess: action.success,
                addReviewError: undefined,
            };
        }
        case ADD_REVIEW_LOADING_ERROR: {
            return {
                ...state,
                addReviewError: action.error,
            };
        }
        case EDIT_REVIEW_LOADING: {
            return {
                ...state,
                editReviewLoading: action.loading,
            };
        }
        case EDIT_REVIEW_LOADING_ERROR: {
            return {
                ...state,
                editReviewError: action.error,
            };
        }
        case EDIT_REVIEW_LOADING_SUCCESS: {
            return {
                ...state,
                editReviewSuccess: action.success,
            };
        }
        case MY_REVIEWS_LOADING: {
            return {
                ...state,
                myReviewsLoading: action.loading,
            };
        }
        case MY_REVIEWS_LOADING_SUCCESS: {
            return {
                ...state,
                myReviews: unionBy(state.myReviews, action.response.body || [], 'uid'),
                myReviewsTotalCount: action.response.totalCount ?? Infinity,
                error: undefined,
            };
        }
        case MY_REVIEWS_LOADING_ERROR: {
            return {
                ...state,
                myReviewsError: action.error,
            };
        }
        case SET_REVIEW_SORT_FIELD: {
            return {
                ...state,
                sort: action.sort,
            };
        }
        case PRODUCT_RATING_LOADING: {
            return {
                ...state,
                productRatingLoading: action.loading,
            };
        }
        case PRODUCT_RATING_LOADING_SUCCESS: {
            return {
                ...state,
                productRatingSuccess: action.success,
            };
        }
        case PRODUCT_RATING_LOADING_ERROR: {
            return {
                ...state,
                productRatingError: action.error,
            };
        }
        case SET_PRODUCT_RATING: {
            return {
                ...state,
                productRating: action.rating,
            };
        }
        case SET_MY_REVIEW_SORT_FIELD: {
            return {
                ...state,
                mySort: action.mySort,
            };
        }
        case DELETE_MY_REVIEW_LOADING: {
            return {
                ...state,
                deleteLoading: action.loading,
            };
        }
        case DELETE_MY_REVIEW_LOADING_SUCCESS: {
            return {
                ...state,
                deleteSuccess: action.success,
                deleteError: undefined,
            };
        }
        case DELETE_MY_REVIEW_LOADING_ERROR: {
            return {
                ...state,
                deleteError: action.error,
            };
        }
        case DELETE_MY_REVIEW: {
            return {
                ...state,
                reviews: state.reviews.filter((review) => review.uid !== action.uid),
                myReviews: state.myReviews.filter((review) => review.uid !== action.uid),
            };
        }
        case SORT_MY_REVIEWS: {
            return {
                ...state,
                myReviews: action.response.body || [],
                sortMyReviewsLoading: false,
            };
        }
        case SORT_MY_REVIEWS_LOADING: {
            return {
                ...state,
                sortMyReviewsLoading: true,
                sortMyReviewsError: undefined,
            };
        }
        case SORT_MY_REVIEWS_ERROR: {
            return {
                ...state,
                sortMyReviewsError: action.err,
            };
        }
        case SELLER_REVIEWS_LOADING:
            return {
                ...state,
                sellerReviewsLoading: action.sellerReviewsLoading,
                sellerReviewsLoadingError: undefined,
            };
        case SELLER_REVIEWS_LOADING_ERROR:
            return { ...state, sellerReviewsLoadingError: action.sellerReviewsLoadingError };
        case SELLER_REVIEWS_LOADING_SUCCESS:
            return {
                ...state,
                sellerReviews: action.isReload
                    ? action.sellerReviews ?? []
                    : (state.sellerReviews ?? []).concat(action.sellerReviews),
                sellerReviewsTotalCount: action.sellerReviewsTotalCount ?? 0,
            };
        default: {
            return state;
        }
    }
};

export default reviewReducer;

import { f7, Fab, FabButton, FabButtons, Icon } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddProductFabProps } from './AddProductFab.types';

import { IcProduct } from '@/assets';

import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { ProductType } from '@/types/productType';

import './AddProductFab.less';

export const AddProductFab = React.memo<AddProductFabProps>((props) => {
    const { t } = useTranslation();

    const activeType = useAppSelector(getActiveProductType);

    const onAdd = useCallback(() => f7.view.main.router.navigate('./add/'), []);

    return (
        <Fab position="right-bottom" slot="fixed" {...props} className="add-product-fab">
            <Icon ios="f7:plus" md="material:add" />
            <Icon ios="f7:xmark" md="material:close" />

            <FabButtons>
                <FabButton
                    onClick={onAdd}
                    fabClose
                    className="add-new-fab"
                    label={
                        activeType === ProductType.Advertisement
                            ? t('page.my_offers.button.add_offer')
                            : activeType === ProductType.Service
                            ? t('services.page.header.button.add_service')
                            : t('Add New Product')
                    }
                >
                    <IcProduct />
                </FabButton>
            </FabButtons>
        </Fab>
    );
});
AddProductFab.displayName = 'AddProductFab';

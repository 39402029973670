import { useEffect } from 'react';

import { loadFavorites } from '@/actions/favoritesActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadFavorites = () => {
    const dispatch = useAppDispatch();

    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        if (loggedIn) {
            dispatch(loadFavorites());
        }
    }, [dispatch, loggedIn]);

    return null;
};

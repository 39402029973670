import { Card } from '@/generated/commonapi';

export const getCardTypeAndNumber = (card: Card) => {
    let cardType: string;

    switch (card.type) {
        case 'V': {
            cardType = 'Visa';
            break;
        }
        case 'M': {
            cardType = 'MasterCard';
            break;
        }
        default: {
            cardType = '';
            break;
        }
    }

    return cardType + ' •••• ' + card.maskedNumber?.slice(12);
};

import { MILLISECONDS_PER_DAY } from '@/shared/constants';
import { AnalyticsPeriod } from '@/types/analyticsPeriod';

export const getDateParamsFromPeriod = (period?: AnalyticsPeriod): { dateFrom: Date; dateTo: Date } | undefined => {
    const currentDate = new Date();

    switch (period) {
        case 'week':
            return {
                dateFrom: new Date(Date.now() - MILLISECONDS_PER_DAY * 6),
                dateTo: currentDate,
            };
        case 'month':
            return {
                dateFrom: new Date(Date.now() - MILLISECONDS_PER_DAY * 30),
                dateTo: currentDate,
            };

        case 'year':
            return {
                dateFrom: new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate() + 1),
                dateTo: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
            };
        default:
            return undefined;
    }
};

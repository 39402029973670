import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    changeCountry,
    toggleSelectCountryPopup,
    toggleSelectCustomerLocationSheet,
} from '@/actions/customerLocationActions';
import { CountrySelectPopup } from '@/components/CountrySelectPopup';
import { SelectCustomerLocationModal } from '@/components/SelectCustomerLocationModal';
import { Country } from '@/generated/commonapi';
import { useAppSelector } from '@/hooks/store';
import { AVAILABLE_DELIVERY_COUNTRIES } from '@/shared/constants';

export const SelectCustomerLocationContainer = React.memo(() => {
    const { countrySelectPopupOpened, selectCustomerLocationSheetOpened } = useAppSelector(
        (state) => state.customerLocationReducer,
    );

    const dispatch = useDispatch();

    const onCountrySelect = useCallback(
        (country: Country) => {
            if (country) {
                dispatch(changeCountry(country.code, true));
                dispatch(toggleSelectCountryPopup(false));
            }
        },
        [dispatch],
    );

    const dialogClosed = useCallback(() => {
        dispatch(toggleSelectCustomerLocationSheet(false));
    }, [dispatch]);

    const selectDialogClosed = useCallback(() => {
        dispatch(toggleSelectCountryPopup(false));
    }, [dispatch]);

    return (
        <div>
            <SelectCustomerLocationModal
                opened={selectCustomerLocationSheetOpened}
                onPopupClosed={dialogClosed}
                onSheetClosed={dialogClosed}
                backdrop
                closeByBackdropClick
            />
            <CountrySelectPopup
                opened={countrySelectPopupOpened}
                onCountrySelect={onCountrySelect}
                filterCountries={AVAILABLE_DELIVERY_COUNTRIES}
                onClose={selectDialogClosed}
            />
        </div>
    );
});
SelectCustomerLocationContainer.displayName = 'SelectCustomerLocationContainer';

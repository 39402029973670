import React from 'react';

import { VimeoPlayer } from './VimeoPlayer';
import { VkVideoPlayer } from './VkVideoPlayer';
import { YoutubePlayer } from './YoutubePlayer';

import { ProductVideo } from '@/generated/marketplaceapi';

const VideoPlayer = ({
    videoId,
    videoType,
    autoplay,
}: {
    autoplay?: boolean;
    videoId: string;
    videoType: ProductVideo.TypeEnum;
}): React.ReactElement => {
    switch (videoType) {
        case ProductVideo.TypeEnum.YOUTUBE:
            return <YoutubePlayer autoplay={autoplay} videoId={videoId} />;
        case ProductVideo.TypeEnum.VIMEO:
            return <VimeoPlayer videoId={videoId} />;
        case ProductVideo.TypeEnum.VK:
            return <VkVideoPlayer videoId={videoId} />;
        default:
            return null;
    }
};

export default VideoPlayer;

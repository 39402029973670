import { detectIPLocation } from './detectIPLocation';

import { Location } from '@/types/location';

export const detectLocation = async (): Promise<Location | undefined> => {
    let location: Location | undefined;

    try {
        location = await detectIPLocation();
    } catch (error) {
        console.error('at detectLocation calling detectIPLocation', error);
    }

    return location;
};

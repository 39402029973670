import dayjs from 'dayjs';

/**
 * Returns date formatted for commonapi request YYYYMMDD000000
 * @param  {string} day
 *
 * @param  {string} month
 *
 * @param  {string} year
 *
 * @returns {string}
 */

export const stringifyDateFromParts = (day: string, month: string, year: string) => {
    const date = dayjs(`${'0'.concat(day).slice(-2)}-${month}-${year}`, 'DD-MM-YYYY', true);

    return date.format('YYYYMMDD') + '000000';
};

import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    BaseProductSearchListRequest,
    ClassificatorControllerApi,
    CommonMarketplaceApiResponseProduct,
    Product,
    ProductCategory,
    ProductControllerApi,
    ProductPageControllerApi,
    ProductSearchListRequest,
    PublicControllerApi,
    SearchResult,
    WishListControllerApi,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import {
    ALWAYS_LOADING_PRODUCT_TYPES,
    LOGGED_LOADING_PRODUCT_TYPES,
} from '@/pages/Home/HomepageBests/HomepageBests.constants';
import { IProduct, ProductListType } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getColorFromClassificator } from '@/selectors/getColorFromClassificator';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { LocalStorageItems } from '@/types/localStorage';
import { ProductPageCode } from '@/types/product';
import { SortBy as SortbyEnum } from '@/types/sort';
import { mapProductDetailsImage, productToIProduct } from '@/utils';
import { getSortByFields } from '@/utils/getSortByFields';

export const PRODUCT_LIST_LOADING = 'PRODUCT_LIST_LOADING' as const;
export const PRODUCT_LIST_LOADING_SUCCESS = 'PRODUCT_LIST_LOADING_SUCCESS' as const;
export const PRODUCT_LIST_LOADING_ERROR = 'PRODUCT_LIST_LOADING_ERROR' as const;

export const PRODUCT_LIST_TYPE_GROUPS_LOADING = 'PRODUCT_LIST_TYPE_GROUPS_LOADING' as const;

export const PRODUCT_LIST_TYPE_LOADING_SUCCESS = 'PRODUCT_LIST_TYPE_LOADING_SUCCESS' as const;
export const PRODUCT_LIST_TYPE_LOADING_ERROR = 'PRODUCT_LIST_TYPE_LOADING_ERROR' as const;

export const PRODUCT_LIST_CATEGORY_LOADING_SUCCESS = 'PRODUCT_LIST_CATEGORY_LOADING_SUCCESS' as const;

export const PRODUCT_LIST_TYPES_RELOADING_SUCCESS = 'PRODUCT_LIST_TYPES_RELOADING_SUCCESS' as const;
export const PRODUCT_LIST_TYPES_CLEAR = 'PRODUCT_LIST_TYPES_CLEAR' as const;

export const PRODUCT_DETAILS_LOADING = 'PRODUCT_DETAILS_LOADING' as const;
export const PRODUCT_DETAILS_LOADING_SUCCESS = 'PRODUCT_DETAILS_LOADING_SUCCESS' as const;
export const PRODUCT_DETAILS_LOADING_ERROR = 'PRODUCT_DETAILS_LOADING_ERROR' as const;
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET' as const;

export const PRODUCT_ADDED_TO_WISH = 'PRODUCT_ADDED_TO_WISH' as const;

export const PRODUCT_WISH_LIST_LOADING = 'PRODUCT_WISH_LIST_LOADING' as const;
export const PRODUCT_WISH_LIST_LOADING_SUCCESS = 'PRODUCT_WISH_LIST_LOADING_SUCCESS' as const;
export const PRODUCT_WISH_LIST_LOADING_ERROR = 'PRODUCT_WISH_LIST_LOADING_ERROR' as const;

export const SEARCH_UPDATE_RESULT_COUNT = 'SEARCH_UPDATE_RESULT_COUNT' as const;
export const SEARCH_SAVE_LOADING = 'SEARCH_SAVE_LOADING' as const;

export const PRODUCT_PRESEARCH_LOADING = 'PRODUCT_PRESEARCH_LOADING' as const;
export const PRODUCT_PRESEARCH_SUCCESS = 'PRODUCT_PRESEARCH_SUCCESS' as const;
export const PRODUCT_PRESEARCH_ERROR = 'PRODUCT_PRESEARCH_ERROR' as const;
export const PRODUCT_PRESEARCH_CLEAR = 'PRODUCT_PRESEARCH_CLEAR' as const;

export const PRODUCT_SEARCH_CATEGORIES_LOADING = 'PRODUCT_SEARCH_CATEGORIES_LOADING' as const;
export const PRODUCT_SEARCH_CATEGORIES_LOADING_SUCCESS = 'PRODUCT_SEARCH_CATEGORIES_LOADING_SUCCESS' as const;

export const RECOMMENDED_PRODUCTS_LOADING = 'RECOMMENDED_PRODUCTS_LOADING' as const;
export const RECOMMENDED_PRODUCTS_LOADING_SUCCESS = 'RECOMMENDED_PRODUCTS_LOADING_SUCCESS' as const;
export const RECOMMENDED_PRODUCTS_LOADING_ERROR = 'RECOMMENDED_PRODUCTS_LOADING_ERROR' as const;

export const SEARCH_PARAM_COUNT = 10;

export type SortBy = {
    direction?: 'ASC' | 'DESC';
    field?: string;
    sortingIndex?: number;
};

export type ISearchParams = Pick<
    ProductSearchListRequest,
    | 'address'
    | 'category'
    | 'coordinates'
    | 'hashtags'
    | 'inStock'
    | 'name'
    | 'count'
    | 'offset'
    | 'sortBy'
    | 'type'
    | 'sellerUid'
    | 'currentProductUid'
    | 'autoHashtags'
    | 'hashtags'
    | 'priceFrom'
    | 'priceTo'
> & {
    resetSearch?: boolean;
    resetSorting?: boolean;
    clear?: boolean;
    storeUid?: string;
};

const productListLoadingErrorAction = (error: unknown) => ({
    type: PRODUCT_LIST_LOADING_ERROR,
    error,
});

const productListTypeGroupsLoadingAction = (listTypeGroupsLoading: boolean) => ({
    type: PRODUCT_LIST_TYPE_GROUPS_LOADING,
    listTypeGroupsLoading,
});

const productListTypeLoadingSuccessAction = (products: IProduct[], listType: ProductListType) => ({
    type: PRODUCT_LIST_TYPE_LOADING_SUCCESS,
    products,
    listType,
});

const productListCategoryLoadingSuccessAction = (products: IProduct[], listType: ProductListType) => ({
    type: PRODUCT_LIST_CATEGORY_LOADING_SUCCESS,
    products,
    listType,
});

const productListTypesReloadingSuccessAction = (items: { type: ProductListType; products: IProduct[] }[]) => ({
    type: PRODUCT_LIST_TYPES_RELOADING_SUCCESS,
    items,
});

const productListTypeLoadingErrorAction = (error: unknown, listType: ProductListType) => ({
    type: PRODUCT_LIST_TYPE_LOADING_ERROR,
    error,
    listType,
});

const productDetailsLoadingAction = () => ({
    type: PRODUCT_DETAILS_LOADING,
});

const productDetailsLoadingSuccessAction = (product: IProduct) => ({
    type: PRODUCT_DETAILS_LOADING_SUCCESS,
    product,
});

const productDetailsLoadingErrorAction = (error: unknown) => ({
    type: PRODUCT_DETAILS_LOADING_ERROR,
    error,
});

const productListTypesClear = () => ({
    type: PRODUCT_LIST_TYPES_CLEAR,
});

export const resetProductDetailsAction = () => ({
    type: PRODUCT_DETAILS_RESET,
});

const productWishListLoadingAction = () => ({
    type: PRODUCT_WISH_LIST_LOADING,
});

const productWishListLoadingSuccessAction = (products: IProduct[]) => ({
    type: PRODUCT_WISH_LIST_LOADING_SUCCESS,
    products,
});

const productWishListLoadingErrorAction = (error: unknown) => ({
    type: PRODUCT_WISH_LIST_LOADING_ERROR,
    error,
});

const productAddedToWish = (uid: string) =>
    ({
        type: PRODUCT_ADDED_TO_WISH,
        uid,
    }) as const;

const productListLoadingAction = (autocomplete = false) => ({
    type: PRODUCT_LIST_LOADING,
    autocomplete,
});

const productPresearchLoadingAction = () => ({
    type: PRODUCT_PRESEARCH_LOADING,
});

const productPresearchSuccessAction = (results: SearchResult[]) => ({
    type: PRODUCT_PRESEARCH_SUCCESS,
    results,
});

const productPresearchErrorAction = (error: unknown) => ({
    type: PRODUCT_PRESEARCH_ERROR,
    error,
});

export const productPresearchClearAction = () => ({
    type: PRODUCT_PRESEARCH_CLEAR,
});

export const saveSearchLoading = (payload) => ({
    type: SEARCH_SAVE_LOADING,
    payload,
});

export const searchUpdateResultCount = (totalCount: number) => ({
    type: SEARCH_UPDATE_RESULT_COUNT,
    totalCount,
});

export const productSearchCategoriesLoadingAction = (searchCategoriesLoading: boolean) => ({
    type: PRODUCT_SEARCH_CATEGORIES_LOADING,
    searchCategoriesLoading,
});

export const productSearchCategoriesLoadingSuccessAction = (searchCategories: ProductCategory[]) => ({
    type: PRODUCT_SEARCH_CATEGORIES_LOADING_SUCCESS,
    searchCategories,
});

const productListLoadingSuccessAction = (
    products: IProduct[],
    is_clear?: boolean,
    searchTerm?: string,
    totalCount?: number,
    is_reset?: boolean,
    isResetSorting?: boolean,
    autocomplete?: boolean,
    offset?: number,
    searchParams?: ISearchParams,
) => ({
    type: PRODUCT_LIST_LOADING_SUCCESS,
    products,
    is_clear,
    searchTerm,
    totalCount,
    is_reset,
    isResetSorting,
    autocomplete,
    offset,
    searchParams,
});

const recommendedProductsPageLoadingAction = () => ({
    type: RECOMMENDED_PRODUCTS_LOADING,
});

const recommendedProductsPageLoadingSuccessAction = (products: Product[]) => ({
    type: RECOMMENDED_PRODUCTS_LOADING_SUCCESS,
    payload: { products },
});

const recommendedProductsPageLoadingErrorAction = (error: unknown) => ({
    type: RECOMMENDED_PRODUCTS_LOADING_ERROR,
    payload: { error },
});

export type ProductAddedToWishAction = ReturnType<typeof productAddedToWish>;

export type ProductActions = ReturnType<
    | typeof productListLoadingAction
    | typeof productListLoadingErrorAction
    | typeof productListTypeGroupsLoadingAction
    | typeof productListTypeLoadingSuccessAction
    | typeof productListTypesReloadingSuccessAction
    | typeof productListCategoryLoadingSuccessAction
    | typeof productListTypeLoadingErrorAction
    | typeof productDetailsLoadingAction
    | typeof productDetailsLoadingSuccessAction
    | typeof productDetailsLoadingErrorAction
    | typeof productListTypesClear
    | typeof resetProductDetailsAction
    | typeof productWishListLoadingAction
    | typeof productWishListLoadingSuccessAction
    | typeof productWishListLoadingErrorAction
    | typeof productAddedToWish
    | typeof productPresearchLoadingAction
    | typeof productPresearchSuccessAction
    | typeof productPresearchErrorAction
    | typeof productPresearchClearAction
    | typeof saveSearchLoading
    | typeof productListLoadingSuccessAction
    | typeof searchUpdateResultCount
    | typeof productSearchCategoriesLoadingAction
    | typeof productSearchCategoriesLoadingSuccessAction
    | typeof recommendedProductsPageLoadingAction
    | typeof recommendedProductsPageLoadingSuccessAction
    | typeof recommendedProductsPageLoadingErrorAction
>;

export const loadProductDetails = async (uid: string, state: IApplicationStore): Promise<IProduct> => {
    const language = getLanguageCode(state);

    const productController = new ProductControllerApi();

    const result = isLoggedIn(state)
        ? await productController.productSearchDetailsUsingPOST({ uid }, getCountryCodeFromState(state), language)
        : await productController.productSearchDetailsUsingGET(uid, getCountryCodeFromState(state), language);

    handleResponseAndThrowAnErrorIfExists(result);

    const item = productToIProduct(result.body[0], state);

    return item;
};

export const presearchProducts =
    (searchString: string, storeUid?: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productPresearchLoadingAction());
        const state = getState();
        const languageCode = getLanguageCode(state);
        const countryCode = getCountryCodeFromState(state);

        const searchParams = {
            limit: 3,
            searchString,
            storeUid,
            productType: getActiveProductType(state),
        };

        try {
            const response = await new ProductControllerApi().productPresearchUsingPOST(
                searchParams,
                countryCode,
                languageCode,
            );

            handleResponseAndThrowAnErrorIfExists(response);

            const body = response.body || [];

            dispatch(productPresearchSuccessAction(body));
        } catch (err) {
            console.error('at productActions in presearchProducts', err);

            dispatch(productPresearchErrorAction(err.message));
        }
    };

const fetchProductSearch = async (params: ISearchParams, state: IApplicationStore, nameOnly = false) => {
    const language = getLanguageCode(state);
    const { count, offset, searchTerm } = state.productReducer;

    const searchParams = {
        ...params,
        category: nameOnly ? undefined : params.category,
        name: params.name || searchTerm,
        count: params.count ?? count ?? SEARCH_PARAM_COUNT,
        offset: params.offset ?? (params.name === searchTerm ? offset : 0),
        productType: getActiveProductType(state),
    };

    if (!searchParams.sortBy?.length) {
        searchParams.sortBy = getSortByFields([SortbyEnum.popular]);
    }

    const response = isLoggedIn(state)
        ? await new ProductControllerApi().productSearchUsingPOST(
              searchParams,
              getCountryCodeFromState(state),
              language,
          )
        : await new PublicControllerApi().productSearchUsingPOST1(
              searchParams,
              getCountryCodeFromState(state),
              language,
          );

    handleResponseAndThrowAnErrorIfExists(response);

    return response;
};

export const searchProducts =
    (searchParams: ISearchParams, autocomplete = false, nameOnly = false) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productListLoadingAction(autocomplete));
        const state = getState();

        try {
            const response = await fetchProductSearch(searchParams, getState(), nameOnly);

            const products = (response.body || []).map((prod) => productToIProduct(prod, state));

            dispatch(
                productListLoadingSuccessAction(
                    products,
                    searchParams.clear,
                    searchParams.name,
                    response.totalCount,
                    searchParams.resetSearch,
                    searchParams.resetSorting,
                    autocomplete,
                    searchParams.offset || 0,
                    searchParams || state.productReducer.searchParams,
                ),
            );
        } catch (err) {
            console.error('at productActions in searchProducts', err);

            dispatch(productListLoadingErrorAction(err.message));
        } finally {
            dispatch(saveSearchLoading(false));
        }
    };

export const updateSearchCategories =
    (searchParams: BaseProductSearchListRequest) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        dispatch(productSearchCategoriesLoadingAction(true));
        try {
            const response = await new ClassificatorControllerApi().productSearchCategoryUsingPOST(
                {
                    ...searchParams,
                    productType: getActiveProductType(state),
                },
                getCountryCodeFromState(state),
                false,
                getLanguageCode(state),
            );
            handleResponseAndThrowAnErrorIfExists(response);
            dispatch(productSearchCategoriesLoadingSuccessAction(response?.body));
        } catch (error) {
            console.error(error, 'at productActions in updateSearchCategories');
        } finally {
            dispatch(productSearchCategoriesLoadingAction(false));
        }
    };

export const searchClear = (autocomplete = false) =>
    productListLoadingSuccessAction([], true, null, 0, true, true, autocomplete);

export const reloadProducts = () => (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();
    const { searchParams } = state.productReducer;

    dispatch(searchClear());
    dispatch(searchProducts(searchParams));
};

export const updateSearchResultCount =
    (searchParams: ISearchParams) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productListLoadingAction());

        try {
            const result = await fetchProductSearch(searchParams, getState());
            const totalCount = result.totalCount;

            dispatch(searchUpdateResultCount(totalCount));
        } catch (error) {
            console.error('at productActions in updateSearchResultCount', error);
        } finally {
            dispatch(saveSearchLoading(false));
        }
    };

export const loadProductListType =
    (listType: ProductListType, currentProductUid = undefined) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            let response: CommonMarketplaceApiResponseProduct;

            const logged = isLoggedIn(getState());
            const language = getLanguageCode(getState());
            const country = getCountryCodeFromState(getState());
            const activeProductType = getActiveProductType(getState());

            if (logged) {
                const controller = new ProductControllerApi();
                response =
                    listType === 'all'
                        ? await controller.productSearchUsingPOST(
                              {
                                  productType: activeProductType,
                              },
                              country,
                              language,
                          )
                        : await controller.productWidgetListUsingGET(
                              listType,
                              country,
                              currentProductUid,
                              language,
                              activeProductType,
                          );
            } else {
                const availableTypes = [
                    'new',
                    'popular',
                    'cheapest',
                    'expensive',
                    'random',
                    'bestsellers',
                    'hot_deals',
                ];
                if (!availableTypes.includes(listType)) {
                    throw new Error(`${listType} in not allowed to be called when not logged`);
                }
                const controller = new PublicControllerApi();
                response =
                    listType === 'all'
                        ? await controller.productSearchUsingPOST1(
                              {
                                  productType: activeProductType,
                              },
                              getCountryCodeFromState(getState()),
                              language,
                          )
                        : await controller.productWidgetListUsingGET1(
                              listType,
                              getCountryCodeFromState(getState()),
                              language,
                              activeProductType,
                          );
            }

            handleResponseAndThrowAnErrorIfExists(response);

            const products = (response.body || []).map((prod) => productToIProduct(prod, getState()));
            dispatch(productListTypeLoadingSuccessAction(products, listType));
        } catch (error) {
            console.error('at productActions in loadProductListType', error);

            dispatch(productListTypeLoadingErrorAction(error.message, listType));
        }
    };

export const loadProductListCategory =
    (listType: ProductListType) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        try {
            const logged = isLoggedIn(getState());
            const language = getLanguageCode(getState());
            const activeProductType = getActiveProductType(getState());

            const parameters = {
                category: listType,
                count: 7,
                offset: 0,
                productType: activeProductType,
            };

            const response = logged
                ? await new ProductControllerApi().productSearchUsingPOST(
                      parameters,
                      getCountryCodeFromState(getState()),
                      language,
                  )
                : await new PublicControllerApi().productSearchUsingPOST1(
                      parameters,
                      getCountryCodeFromState(getState()),
                      language,
                  );

            handleResponseAndThrowAnErrorIfExists(response);

            const products = (response.body || []).map((prod) => productToIProduct(prod, getState()));

            dispatch(productListCategoryLoadingSuccessAction(products, listType));
        } catch (error) {
            console.error('at productActions in loadProductListCategory', error);

            dispatch(productListTypeLoadingErrorAction(error.message, listType));
        }
    };

export const reloadAllProductListTypes = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(productListTypeGroupsLoadingAction(true));
    dispatch(productListTypesClear());

    const state = getState();
    const logged = isLoggedIn(state);

    const listPromises = (!logged ? ALWAYS_LOADING_PRODUCT_TYPES : LOGGED_LOADING_PRODUCT_TYPES).map((type) =>
        dispatch(loadProductListType(type)),
    );
    await Promise.all(listPromises);

    dispatch(productListTypeGroupsLoadingAction(false));
};

export const loadProductDetail =
    (uid?: string, loadSellerProducts = false, params: { active: boolean } = { active: true }) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(productDetailsLoadingAction());

        const state = getState();

        try {
            const data = await loadProductDetails(uid, state);

            if (params.active === false) {
                return data;
            }

            dispatch(
                productDetailsLoadingSuccessAction({
                    ...data,
                    colorValue: getColorFromClassificator(state, data.color),
                }),
            );

            if (loadSellerProducts && params.active) {
                const searchParams: ISearchParams = {
                    sellerUid: data.sellerUid,
                    currentProductUid: uid,
                    count: SEARCH_PARAM_COUNT,
                    offset: 0,
                    resetSorting: true,
                    resetSearch: true,
                };

                dispatch(searchProducts(searchParams));
            }

            return data;
        } catch (error) {
            console.error('at productActions in loadProductDetail', error);

            dispatch(productDetailsLoadingErrorAction(`${error}`));
            return undefined;
        }
    };

export const loadProductWishList = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(productWishListLoadingAction());

    const state = getState();
    const language = getLanguageCode(state);

    let items: IProduct[];

    try {
        if (isLoggedIn(state)) {
            // ! this handler doesnt provide rentOptions
            const result = await new WishListControllerApi().wishListUsingGET(language, getActiveProductType(state));
            handleResponseAndThrowAnErrorIfExists(result);

            items = result.body || [];
        } else {
            const wishListUidsFromLocalStorage: string[] =
                JSON.parse(localStorage.getItem(LocalStorageItems.WISH_LIST)) || [];

            items = await Promise.all(wishListUidsFromLocalStorage.map((uid) => loadProductDetails(uid, state)));
        }

        items = items.map((item) => ({
            ...item,
            wish: true,
            images: mapProductDetailsImage(item),
        }));

        dispatch(productWishListLoadingSuccessAction(items));
    } catch (error) {
        console.error('at productActions in loadProductWishList', error);

        dispatch(productWishListLoadingErrorAction(error.message));
    }
};

export const addToWishList = (uid?: string) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    /* get before call productAddedToWish! */
    const state = getState();
    const { productsWishList } = state.productReducer;
    const productAlreadyAdded = !!productsWishList.find((item) => item.uid === uid);

    try {
        const product = await loadProductDetails(uid, getState());
        if (productAlreadyAdded) {
            firebaseAnalyticsInstance.removeFromWishlist?.(product);
        } else {
            firebaseAnalyticsInstance.addToWishList?.(product);
        }
    } catch (error) {
        console.error(error);
    }

    dispatch(productAddedToWish(uid));

    try {
        if (isLoggedIn(state)) {
            const controller = new WishListControllerApi();
            const result = productAlreadyAdded
                ? await controller.removeFromWishListUsingDELETE(uid, getActiveProductType(state))
                : await controller.addToWishListUsingPUT(uid, getActiveProductType(state));
            handleResponseAndThrowAnErrorIfExists(result);

            const items: IProduct[] = result.body || [];
            items.forEach((item) => {
                item.wish = true;
                item.images = mapProductDetailsImage(item);
            });
            dispatch(productWishListLoadingSuccessAction(items));
            return;
        }

        let newWishList = [...productsWishList];
        if (productAlreadyAdded) {
            newWishList = newWishList.filter((prod) => prod.uid !== uid);
        } else {
            const addedItem = await loadProductDetails(uid, state);
            addedItem.wish = true;
            newWishList.push(addedItem);
        }

        localStorage.setItem(LocalStorageItems.WISH_LIST, JSON.stringify(newWishList.map(({ uid }) => uid)));

        dispatch(productWishListLoadingSuccessAction(newWishList));
    } catch (err) {
        console.error('at productActions in addToWishList', err);
    }
};

export const loadDifferenceProducts = (options) => async () => {
    try {
        const response = await new ProductControllerApi().productListUsingPOST({
            ...options,
        });
        return response.body || [];
    } catch (err) {
        console.error('at productActions in loadDifferenceProducts', err);
        return undefined;
    }
};

export const sendWishListFromLocalStorage = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    const state = getState();

    try {
        const uids: string[] = JSON.parse(localStorage.getItem(LocalStorageItems.WISH_LIST)) || [];
        const response = await new WishListControllerApi().addProductsToWishListUsingPUT(
            uids,
            getActiveProductType(state),
        );
        const items = (response.body || []).map((item) => ({
            ...item,
            wish: true,
            images: mapProductDetailsImage(item),
        }));
        dispatch(productWishListLoadingSuccessAction(items));
        localStorage.removeItem(LocalStorageItems.WISH_LIST);
    } catch (error) {
        console.error('at productActions in sendWishListFromLocalStorage', error);
        dispatch(productWishListLoadingErrorAction(error?.toString()));
    }
};

const RECOMMENDED_PRODUCTS_LIMIT = 15;

export const loadRecommendedProducts =
    (page: ProductPageCode, rowLimit = RECOMMENDED_PRODUCTS_LIMIT) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();

        dispatch(recommendedProductsPageLoadingAction());

        try {
            const productPageController = new ProductPageControllerApi();

            const response = await productPageController.getProductListByPageUsingGET(
                page,
                getCountryCodeFromState(state),
                getLanguageCode(state),
                rowLimit,
            );

            handleResponseAndThrowAnErrorIfExists(response);

            dispatch(recommendedProductsPageLoadingSuccessAction(response.body));
        } catch (error) {
            console.error('at productActions in loadRecommendedProducts', error);
            dispatch(recommendedProductsPageLoadingErrorAction(error?.toString()));
        }
    };

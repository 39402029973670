import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    CommonMarketplaceApiResponseStoreRating,
    GetStatisticsRequest,
    Statistics,
    StatisticsControllerApi,
    ProductStoreControllerApi,
    StoreRatingResponse,
    ServiceProviderControllerApi,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { ProductType } from '@/types/productType';

export const STATISTICS_ERROR = 'STATISTICS_ERROR' as const;

export const STATISTICS_LOADING = 'STATISTICS_LOADING' as const;

export const STATISTICS_LOADING_SUCCESS = 'STATISTICS_LOADING_SUCCESS' as const;

const statisticDetailsLoading = (saleStatisticsLoading?: boolean) => ({
    type: STATISTICS_LOADING,
    payload: { saleStatisticsLoading },
});

const statisticDetailsLoadingSuccess = (statisticsDetails: Statistics, storeRating?: StoreRatingResponse) => ({
    type: STATISTICS_LOADING_SUCCESS,
    payload: { statisticsDetails, storeRating },
});

const statisticDetailsLoadingError = (statisticsError: string) => ({
    type: STATISTICS_ERROR,
    payload: { statisticsError },
});

export type StatisticsActions = ReturnType<
    typeof statisticDetailsLoading | typeof statisticDetailsLoadingError | typeof statisticDetailsLoadingSuccess
>;

const fetchStatisticsDetails = async (
    type: 'sale' | 'product' | 'store',
    request: GetStatisticsRequest,
    uid: string,
    languageCode: string,
) => {
    switch (type) {
        case 'sale':
            return new StatisticsControllerApi().getSaleStatisticsUsingPOST(request, uid, languageCode);
        case 'product':
            return new StatisticsControllerApi().getProductStatisticsUsingPOST(request, uid, languageCode);
        case 'store':
            return new StatisticsControllerApi().getStoreStatisticsUsingPOST(request, uid, languageCode);
    }
};

export const loadStatistics =
    (type: 'sale' | 'product' | 'store', request: GetStatisticsRequest, uid: string) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(statisticDetailsLoading(true));

        try {
            let storeRatingResponse: CommonMarketplaceApiResponseStoreRating | undefined;

            const statisticsDetailsResponse = await fetchStatisticsDetails(
                type,
                request,
                uid,
                getLanguageCode(getState()),
            );

            handleResponseAndThrowAnErrorIfExists(statisticsDetailsResponse);

            if (type === 'store') {
                const activeProductType = getActiveProductType(getState());

                storeRatingResponse =
                    activeProductType === ProductType.Service
                        ? await new ServiceProviderControllerApi().accountStoreRatingUsingPOST2(
                              {
                                  dateFrom: request.dateFrom,
                                  dateTo: request.dateTo,
                              },
                              uid,
                          )
                        : await new ProductStoreControllerApi().accountStoreRatingUsingPOST(
                              {
                                  dateFrom: request.dateFrom,
                                  dateTo: request.dateTo,
                              },
                              uid,
                          );

                handleResponseAndThrowAnErrorIfExists(storeRatingResponse);
            }

            dispatch(statisticDetailsLoadingSuccess(statisticsDetailsResponse.body[0], storeRatingResponse?.body?.[0]));
        } catch (error) {
            console.error('in statisticsActions at loadSaleStatistics', error);
            dispatch(statisticDetailsLoadingError((error as Error).message));
        } finally {
            dispatch(statisticDetailsLoading(false));
        }
    };

import cn from 'classnames';
import { Link } from 'framework7-react';
import React from 'react';

import { ProfileLinkProps } from './ProfileLink.types';

import { Avatar } from '@/components/Avatar';

import './ProfileLink.less';

export const ProfileLink = React.memo(({ profile, className, ...props }: ProfileLinkProps) => (
    <Link href={profile && profile.uid ? '/profile/' : '#'} {...props} className={cn('profile-link', className)}>
        <Avatar className="small" profile={profile || {}} />
    </Link>
));
ProfileLink.displayName = 'ProfileLink';

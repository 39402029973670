import { IProduct } from './productReducer';

import {
    ALL_GOODS_LOADING,
    ALL_GOODS_LOADING_ERROR,
    ALL_GOODS_LOADING_SUCCESS,
    ALL_GOODS_RELOADING_SUCCESS,
    AllGoodsActions,
} from '@/actions/allGoodsActions';
import { PRODUCT_ADDED_TO_WISH, ProductActions } from '@/actions/productActions';

export const DEFAULT_ALL_GOODS_PRODUCTS_COUNT = 10;

export interface IAllGoodsState {
    loading?: boolean;
    error?: unknown;
    products: IProduct[];
    count?: number;
    totalCount: number;
    offset?: number;
}

const initialState: IAllGoodsState = {
    loading: null,
    error: null,
    products: [],
    count: DEFAULT_ALL_GOODS_PRODUCTS_COUNT,
    offset: 0,
    totalCount: 0,
};

const allGoodsReducer = (state = initialState, action: AllGoodsActions | ProductActions): IAllGoodsState => {
    switch (action.type) {
        case ALL_GOODS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ALL_GOODS_LOADING_SUCCESS: {
            const { products = [], totalCount } = action;
            const { offset } = state;
            return {
                ...state,
                totalCount: totalCount ?? 0,
                loading: false,
                products: [...state.products, ...products],
                offset: offset + (products?.length ? initialState.count : 0),
                count: products?.length && products.length === initialState.count ? initialState.count : 0,
            };
        }
        case ALL_GOODS_RELOADING_SUCCESS: {
            const { products = [], totalCount } = action;
            return {
                ...state,
                totalCount: totalCount ?? 0,
                loading: false,
                products: products,
                offset: products?.length ? initialState.count : 0,
                count: products?.length && products.length === initialState.count ? initialState.count : 0,
            };
        }
        case ALL_GOODS_LOADING_ERROR:
            return {
                ...state,
                loading: null,
                error: action.error,
            };
        case PRODUCT_ADDED_TO_WISH: {
            const { uid } = action;
            const products = state.products?.map((item) =>
                item.uid === uid
                    ? {
                          ...item,
                          wish: !item.wish,
                      }
                    : item,
            );

            return {
                ...state,
                products,
            };
        }
        default:
            return state;
    }
};

export default allGoodsReducer;

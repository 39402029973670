import cn from 'classnames';
import React from 'react';

import { ProductCardProps } from './ProductCard.types';

import { Price } from '@/components/Price';
import { ProductImage } from '@/components/ProductImage';
import { StarRating } from '@/components/StarRating';
import { useAppSelector } from '@/hooks/store';
import { isProductInWish } from '@/selectors/isProductInWish';

import './ProductCard.less';

export const ProductCard = ({
    product,
    onLikeChange,
    className,
    showHorizontally,
    onClick,
}: ProductCardProps): React.ReactElement => {
    // todo: we can use product.isWishlist from back but it is always empty
    const isWish = useAppSelector((state) => isProductInWish(product.uid, state));

    return (
        <div
            className={cn('product-card__container', className, {
                horizontally: showHorizontally,
            })}
            onClick={onClick}
        >
            <ProductImage
                className="product-card__image"
                imgSrc={product.imageThumbnailUrl1}
                showLikeButton={true}
                isLiked={isWish}
                discount={product.discount}
                onLikeChange={onLikeChange}
            />
            <div className="product-card__content">
                {(product.price || product.negotiatedPrice) && (
                    <Price
                        size="medium"
                        price={product.price}
                        negotiatedPrice={product.negotiatedPrice}
                        discountedPrice={product.salePrice || product.discountedPrice}
                        currencyCode={product.currencyCode}
                        period={product.rentOptions?.period}
                        withSaleIcon={!!product.sale}
                    />
                )}
                {product.rating && (
                    <StarRating className="product-card__rating" size="small" value={product.rating} primary readOnly />
                )}
                <span className="product-card__name">{product.name}</span>
            </div>
        </div>
    );
};

import React from 'react';

import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary.types';

import { ErrorPage } from '@/pages/ErrorPage';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        console.error(error, errorInfo);
        this.setState({
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render(): React.ReactNode {
        const { error, errorInfo } = this.state;

        if (error) {
            console.log(error.message);
            console.log(errorInfo.componentStack);
            // Error path
            return <ErrorPage error={error} errorInfo={errorInfo.componentStack} />;
        }
        // Normally, just render children
        return this.props.children;
    }
}

export enum NotAvailableItemType {
    PRODUCT = 'product',
    STORE = 'store',
}

export type NotAvailableItemProps = {
    type: NotAvailableItemType;
    horizontal?: boolean;
    textOutOfCard?: boolean;
    className?: string;
};

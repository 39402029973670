import {
    DELETE_SALE_LOADING,
    DELETE_SALE_LOADING_SUCCESS,
    RESTART_SALE_LOADING,
    RESTART_SALE_LOADING_SUCCESS,
    SALE_ERROR,
    SALE_INFO_LOADING,
    SALE_INFO_LOADING_SUCCESS,
    SALE_INFO_RESET,
    SALE_LIST_LOADING,
    SALE_LIST_LOADING_SUCCESS,
    SALE_PRODUCTS_OR_CATEGORIES_LOADING,
    SALE_PRODUCTS_SEARCH_LOADING,
    SALE_PRODUCTS_SEARCH_LOADING_SUCCESS,
    SALE_STATUS_CHANGE_LOADING,
    SALE_STATUS_CHANGE_LOADING_SUCCESS,
    SALE_VALIDATION_LOADING,
    SALE_VALIDATION_LOADING_SUCCESS,
    SaleActions,
} from '@/actions/saleActions';
import { Product, Sale } from '@/generated/marketplaceapi';

export interface ISaleState {
    saleInfo?: Sale;
    saleInfoLoading: boolean;
    saleItemsLoading: boolean;
    saleError?: string;
    saleList?: Sale[];
    saleListLoading: boolean;
    searchedProducts?: Product[];
    saleSearchLoading: boolean;
    saleStatusChangeLoading?: boolean;
    saleRestartLoading?: boolean;
    validateLoading?: boolean;
}

const initialState: ISaleState = {
    saleInfoLoading: false,
    saleItemsLoading: false,
    saleListLoading: false,
    saleSearchLoading: false,
};

const saleReducer = (state = initialState, action: SaleActions): ISaleState => {
    switch (action.type) {
        case SALE_ERROR:
        case SALE_INFO_LOADING:
        case SALE_INFO_LOADING_SUCCESS:
        case SALE_PRODUCTS_OR_CATEGORIES_LOADING:
        case DELETE_SALE_LOADING:
        case SALE_LIST_LOADING:
        case SALE_LIST_LOADING_SUCCESS:
        case SALE_PRODUCTS_SEARCH_LOADING:
        case SALE_PRODUCTS_SEARCH_LOADING_SUCCESS:
        case SALE_STATUS_CHANGE_LOADING:
        case RESTART_SALE_LOADING:
        case SALE_VALIDATION_LOADING:
            return { ...state, ...action.payload };
        case DELETE_SALE_LOADING_SUCCESS:
            return {
                ...state,
                saleInfo: undefined,
                saleList: state.saleList?.filter((item) => item.uid !== action.payload.saleUid),
            };
        case SALE_STATUS_CHANGE_LOADING_SUCCESS:
            return {
                ...state,
                saleList: state.saleList.map((item) =>
                    item.uid === action.payload.sale.uid ? action.payload.sale : item,
                ),
            };
        case RESTART_SALE_LOADING_SUCCESS: {
            return { ...state, saleList: state.saleList.concat(action.payload.sale) };
        }
        case SALE_INFO_RESET:
            return { ...state, saleInfo: undefined };
        case SALE_VALIDATION_LOADING_SUCCESS:
            return { ...state, saleInfo: { ...state.saleInfo, products: action.payload.products } };
        default: {
            return state;
        }
    }
};

export default saleReducer;

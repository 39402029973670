import { useEffect } from 'react';

import { getAccountStores, resetAccountStores } from '@/actions/accountStoresActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';
import { AccountType } from '@/types/account';
import { LoaderHocProps } from '@/types/loaders';

export const LoadAccountStores = ({ shouldDo = true }: LoaderHocProps): JSX.Element => {
    const loggedIn = useAppSelector(isLoggedIn);

    const profile = useAppSelector(getProfile);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (shouldDo && loggedIn) {
            dispatch(getAccountStores());
        }
    }, [dispatch, loggedIn, shouldDo]);

    useEffect(() => {
        if (!loggedIn || profile?.type === AccountType.STANDARD) {
            dispatch(resetAccountStores());
        }
    }, [dispatch, loggedIn, profile?.type]);

    return null;
};

export enum Popups {
    ABOUT_POPUP = 'ABOUT_POPUP',
    CONTACT_SUPPORT_POPUP = 'CONTACT_SUPPORT_POPUP',
    COUNTRY_SELECT_POPUP = 'COUNTRY_SELECT_POPUP',
    IFRAME_CONTENT_POPUP = 'IFRAME_CONTENT_POPUP',
    MAP_POPUP = 'MAP_POPUP',
    LOGIN_POPUP = 'LOGIN_POPUP',
    RESERVE_POPUP = 'RESERVE_POPUP',
    ADD_TRACKING_INFO_POPUP = 'ADD_TRACKING_INFO_POPUP',
    CHANGE_ORDER_STATUS_POPUP = 'CHANGE_ORDER_STATUS_POPUP',
    ORDER_REFUND_POPUP = 'ORDER_REFUND_POPUP',
    REGISTER_POPUP = 'REGISTER_POPUP',
    PAYMENT_CARD_CREATE_POPUP = 'PAYMENT_CARD_CREATE',
    CONGRATULATIONS_POPUP = 'CONGRATULATIONS_POPUP',
    PAYMENT_FAILED_POPUP = 'PAYMENT_FAILED_POPUP',
    CARD_POPUP = 'CARD_POPUP',
    CVC_POPUP = 'CVC_POPUP',
    NEW_DELIVERY_METHOD_POPUP = 'NEW_DELIVERY_METHOD_POPUP',
    ADD_BANNER_POPUP = 'ADD_BANNER_POPUP',
    ADD_PRODUCTS_POPUP = 'ADD_PRODUCTS_POPUP',
    ADD_WIDGET_POPUP = 'ADD_WIDGET_POPUP',
    CHECKBOX_RADIO_POPUP = 'CHECKBOX_RADIO_POPUP',
    ADD_DELIVERY_INFORMATION_POPUP = 'ADD_DELIVERY_INFORMATION_POPUP',
    MOBILE_DELIVERY_LIST_POPUP = 'MOBILE_DELIVERY_LIST_POPUP',
    REMOVAL_CONFIRMATION_POPUP = 'REMOVAL_CONFIRMATION_POPUP',
    ADD_NEW_CARD_POPUP = 'ADD_NEW_CARD_POPUP',
    LANGUAGES_POPUP = 'LANGUAGES_POPUP',
    SELECT_CUSTOMER_LOCATION_POPUP = 'SELECT_CUSTOMER_LOCATION_POPUP',
    IMPORT_LOG_POPUP = 'IMPORT_LOG_POPUP',
    SETUP_PAYOUT_POPUP = 'SETUP_PAYOUT_POPUP',
    HOMEPAGE_MOBILE_FILTERS_POPUP = 'HOMEPAGE_MOBILE_FILTERS_POPUP',
    CHANGE_PASSWORD_POPUP = 'CHANGE_PASSWORD_POPUP',
    CHANGE_EMAIL_POPUP = 'CHANGE_EMAIL_POPUP',
    CHANGE_PHONE_POPUP = 'CHANGE_PHONE_POPUP',
    DATEPICKER_POPUP = 'DATEPICKER_POPUP',
    REGION_AGREEMENT_POPUP = 'REGION_AGREEMENT_POPUP',
    VIDEO_PLAYER_POPUP = 'VIDEO_PLAYER_POPUP',
    ADD_REVIEW_POPUP = 'ADD_REVIEW_POPUP',
    EDIT_SALE_PRODUCT_POPUP = 'EDIT_SALE_PRODUCT_POPUP',
    REVIEW_LIST_POPUP = 'REVIEW_LIST_POPUP',
    SALE_PRODUCTS_POPUP = 'SALE_PRODUCTS_POPUP',
    BANK_TRANSFER_POPUP = 'BANK_TRANSFER_POPUP',
    CURRENCY_POPUP = 'CURRENCY_POPUP',
    REPLENISH_POPUP = 'REPLENISH_POPUP',
    REPLENISHMENT_STATUS_POPUP = 'REPLENISHMENT_STATUS_POPUP',
    CHANGE_QUANTITY_POPUP = 'CHANGE_QUANTITY_POPUP',
    EDIT_REVIEW_POPUP = 'EDIT_REVIEW_POPUP',
    RENT_PER_HOUR_POPUP = 'RENT_PER_HOUR_POPUP',
    RENT_PER_MONTH_POPUP = 'RENT_PER_MONTH_POPUP',
    STATISTICS_POPUP = 'STATISTICS_POPUP',
    EDIT_REGION_POPUP = 'EDIT_REGION_POPUP',
    DELIVERY_OPTION_SELECT_POPUP = 'DELIVERY_OPTION_SELECT_POPUP',
    STORE_REVIEW_ANALYTICS_POPUP = 'STORE_REVIEW_ANALYTICS_POPUP',
    INVITE_FRIENDS_POPUP = 'INVITE_FRIENDS_POPUP',
    EVENT_MODAL_POPUP = 'EVENT_MODAL_POPUP',
    START_CHAT_POPUP = 'START_CHAT_POPUP',
    MARK_EVENT_DONE_POPUP = 'MARK_EVENT_DONE_POPUP',
    SELECT_PHONE_CODE_POPUP = 'SELECT_PHONE_CODE_POPUP',
    STORE_INFO_POPUP = 'STORE_INFO_POPUP',
    SDEK_MAP_POPUP = 'SDEK_MAP_POPUP',
    NAVIGATE_TO_3DS_POPUP = 'NAVIGATE_TO_3DS_POPUP',
    SEARCH_CATEGORY_POPUP = 'SEARCH_CATEGORY_POPUP',
    PRODUCT_DELIVERIES_POPUP = 'PRODUCT_DELIVERIES_POPUP',
    REQUEST_PICKUP_POPUP = 'REQUEST_PICKUP_POPUP',
}

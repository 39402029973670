import { useEffect } from 'react';

import { loadCategories } from '@/actions/categoryActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePrevious } from '@/hooks/usePrevious';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const ReloadCategoriesOnLanguageChange = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(getLanguageCode);
    const prevLanguage = usePrevious(language);

    const { productDetails, products } = useAppSelector((state) => state.productReducer);

    useEffect(() => {
        if (language && prevLanguage && prevLanguage !== language) {
            dispatch(loadCategories());
        }
    }, [dispatch, language, prevLanguage, productDetails?.uid, products?.length]);

    return null;
};

import { useEffect } from 'react';

import { ISearchParams, updateSearchResultCount } from '@/actions/productActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { ALL_CATEGORY_CLASSIFICATOR_CODE } from '@/shared/constants';
import { getNotEmptyEntriesCount } from '@/utils';

type useSearchResultCountProps = {
    searchParams: ISearchParams;
    shouldDo?: boolean;
};

export const useSearchResultCount = ({ searchParams, shouldDo = true }: useSearchResultCountProps) => {
    const dispatch = useAppDispatch();

    const { totalCountPreview } = useAppSelector((state) => state.productReducer);

    useEffect(() => {
        if (getNotEmptyEntriesCount(searchParams) && shouldDo) {
            dispatch(
                updateSearchResultCount({
                    ...searchParams,
                    category:
                        searchParams.category === ALL_CATEGORY_CLASSIFICATOR_CODE ? undefined : searchParams.category,
                }),
            );
        }
    }, [dispatch, searchParams, shouldDo]);

    return totalCountPreview;
};

import {
    AccountStoreActions,
    RESET_ACCOUNT_STORES,
    SAVE_ACCOUNT_STORES_ERROR,
    SAVE_ACCOUNT_STORES_LOADING,
    SAVE_ACCOUNT_STORES_SUCCESS,
} from '@/actions/accountStoresActions';
import { Store } from '@/generated/marketplaceapi';

export interface IAccountStoresState {
    accountStores: Store[];
    accountStoresLoading: boolean;
    error: unknown;
}

const initialState: IAccountStoresState = {
    accountStores: [],
    accountStoresLoading: false,
    error: undefined,
};

const accountStoresReducer = (state = initialState, action: AccountStoreActions): IAccountStoresState => {
    switch (action.type) {
        case SAVE_ACCOUNT_STORES_SUCCESS: {
            return {
                ...state,
                accountStores: action.accountStores,
                error: undefined,
            };
        }
        case SAVE_ACCOUNT_STORES_LOADING: {
            return {
                ...state,
                accountStoresLoading: action.loading,
            };
        }
        case SAVE_ACCOUNT_STORES_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case RESET_ACCOUNT_STORES:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};

export default accountStoresReducer;

import { ProductVideo } from '@/generated/marketplaceapi';

export const createThumbnailVideoURLLink = (id: string, type: ProductVideo.TypeEnum) => {
    switch (type) {
        case ProductVideo.TypeEnum.VIMEO:
            return `https://api.vimeo.com/videos/${id}/pictures`;
        case ProductVideo.TypeEnum.YOUTUBE:
            return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
        case ProductVideo.TypeEnum.VK:
            return '';
        default:
            return '';
    }
};

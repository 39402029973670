/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import portableFetch from '@/axios/portableFetchWrapper';
import { Configuration } from "./configuration";

const BASE_PATH = "/marketplaceapi".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyLegalAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    companyRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    externalAccountUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isAllowedSellUsingMarketServices?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lastLoginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    uid?: string;
    /**
     * 
     * @type {Array<AccountWebResourceEvent>}
     * @memberof Account
     */
    webResources?: Array<AccountWebResourceEvent>;
}
/**
 * 
 * @export
 * @interface AccountCategory
 */
export interface AccountCategory {
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    externalCategoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    externalCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    internalCategoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    internalCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCategory
     */
    internalCategoryPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCategory
     */
    _new?: boolean;
}
/**
 * 
 * @export
 * @interface AccountProductStat
 */
export interface AccountProductStat {
    /**
     * 
     * @type {boolean}
     * @memberof AccountProductStat
     */
    hasOffers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountProductStat
     */
    hasProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountProductStat
     */
    hasServices?: boolean;
}
/**
 * 
 * @export
 * @interface AccountWebResource
 */
export interface AccountWebResource {
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountWebResource
     */
    relationId?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResource
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface AccountWebResourceEvent
 */
export interface AccountWebResourceEvent {
    /**
     * 
     * @type {string}
     * @memberof AccountWebResourceEvent
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountWebResourceEvent
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ActiveFeatureDetails
 */
export interface ActiveFeatureDetails {
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    expireDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    startAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    typeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    typeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    typeUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveFeatureDetails
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface AddProductReviewImageRequest
 */
export interface AddProductReviewImageRequest {
    /**
     * 
     * @type {string}
     * @memberof AddProductReviewImageRequest
     */
    imageUrl?: string;
}
/**
 * 
 * @export
 * @interface AddProductReviewRequest
 */
export interface AddProductReviewRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AddProductReviewRequest
     */
    anonymously?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddProductReviewRequest
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddProductReviewRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {number}
     * @memberof AddProductReviewRequest
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof AddProductReviewRequest
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface AddPromotionPageProductRequest
 */
export interface AddPromotionPageProductRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPromotionPageProductRequest
     */
    productUid?: string;
}
/**
 * 
 * @export
 * @interface AddPromotionPageRequest
 */
export interface AddPromotionPageRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPromotionPageRequest
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Address
     */
    addressParams?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    apartmentNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    firstAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    floorNo?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    placeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    secondAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
}
/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {ArticleAuthor}
     * @memberof Article
     */
    author?: ArticleAuthor;
    /**
     * 
     * @type {Array<ArticleContent>}
     * @memberof Article
     */
    contents?: Array<ArticleContent>;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    imageThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    productCategoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    publishedDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    status: Article.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    summaryOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    titleOriginal?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    translationLanguageCode?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof Article
     */
    uid?: string;
}

/**
 * @export
 * @namespace Article
 */
export namespace Article {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NEW = <any> 'NEW',
        DRF = <any> 'DRF',
        PBL = <any> 'PBL'
    }
}
/**
 * 
 * @export
 * @interface ArticleAuthor
 */
export interface ArticleAuthor {
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleAuthor
     */
    profilePictureUrl?: string;
}
/**
 * 
 * @export
 * @interface ArticleContent
 */
export interface ArticleContent {
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    articleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    content?: string;
    /**
     * 
     * @type {Array<ArticleContentItem>}
     * @memberof ArticleContent
     */
    items?: Array<ArticleContentItem>;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    originalContent?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleContent
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    translationLanguageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    type: ArticleContent.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    uid?: string;
}

/**
 * @export
 * @namespace ArticleContent
 */
export namespace ArticleContent {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        TEXT = <any> 'TEXT',
        SUBTITLE = <any> 'SUBTITLE',
        IMAGES = <any> 'IMAGES',
        PRODUCT = <any> 'PRODUCT',
        PRODUCTSLIDER = <any> 'PRODUCT_SLIDER',
        STORE = <any> 'STORE',
        STORESLIDER = <any> 'STORE_SLIDER',
        CATEGORIES = <any> 'CATEGORIES'
    }
}
/**
 * 
 * @export
 * @interface ArticleContentItem
 */
export interface ArticleContentItem {
    /**
     * 
     * @type {string}
     * @memberof ArticleContentItem
     */
    articleContentUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleContentItem
     */
    articleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleContentItem
     */
    item?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleContentItem
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleContentItem
     */
    type: ArticleContentItem.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArticleContentItem
     */
    uid?: string;
}

/**
 * @export
 * @namespace ArticleContentItem
 */
export namespace ArticleContentItem {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IMAGE = <any> 'IMAGE',
        PRODUCT = <any> 'PRODUCT',
        STORE = <any> 'STORE',
        CATEGORY = <any> 'CATEGORY'
    }
}
/**
 * 
 * @export
 * @interface Banner
 */
export interface Banner {
    /**
     * 
     * @type {boolean}
     * @memberof Banner
     */
    authorized?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Banner
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    targetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Banner
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface BaseProductSearchListRequest
 */
export interface BaseProductSearchListRequest {
    /**
     * 
     * @type {ProductAddress}
     * @memberof BaseProductSearchListRequest
     */
    address?: ProductAddress;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    allowedCountryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProductSearchListRequest
     */
    autoHashtags?: boolean;
    /**
     * Product category OR product list type (new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers)
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    dealType?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    discountedPriceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    discountedPriceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    expirationDate?: string;
    /**
     * 
     * @type {Array<CharacteristicFilter>}
     * @memberof BaseProductSearchListRequest
     */
    filters?: Array<CharacteristicFilter>;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    fullAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProductSearchListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    minProductRating?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProductSearchListRequest
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseProductSearchListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    promotionPageUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    publishDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProductSearchListRequest
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    saleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseProductSearchListRequest
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    storeUid?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProductSearchListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface BaseUidObject
 */
export interface BaseUidObject {
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof BaseUidObject
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface BuildProperties
 */
export interface BuildProperties {
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    artifact?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof BuildProperties
     */
    time?: Date;
    /**
     * 
     * @type {string}
     * @memberof BuildProperties
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface CDEKWebhook
 */
export interface CDEKWebhook {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CDEKWebhook
     */
    attributes?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CDEKWebhook
     */
    dateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CDEKWebhook
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CDEKWebhook
     */
    uuid?: string;
}
/**
 * 
 * @export
 * @interface CalculateDeliveryRequest
 */
export interface CalculateDeliveryRequest {
    /**
     * 
     * @type {string}
     * @memberof CalculateDeliveryRequest
     */
    cartUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculateDeliveryRequest
     */
    combineShipping?: boolean;
    /**
     * 
     * @type {Address}
     * @memberof CalculateDeliveryRequest
     */
    deliveryAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof CalculateDeliveryRequest
     */
    deliveryDate?: string;
    /**
     * 
     * @type {Array<CartItem>}
     * @memberof CalculateDeliveryRequest
     */
    items?: Array<CartItem>;
    /**
     * 
     * @type {number}
     * @memberof CalculateDeliveryRequest
     */
    orderItemTotalAmount?: number;
}
/**
 * 
 * @export
 * @interface CalculateDeliveryResponse
 */
export interface CalculateDeliveryResponse {
    /**
     * 
     * @type {number}
     * @memberof CalculateDeliveryResponse
     */
    bonusMoneyAmountForOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculateDeliveryResponse
     */
    bonusMoneyAmountTotal?: number;
    /**
     * 
     * @type {Array<ProductDelivery>}
     * @memberof CalculateDeliveryResponse
     */
    productDeliveryList?: Array<ProductDelivery>;
    /**
     * 
     * @type {string}
     * @memberof CalculateDeliveryResponse
     */
    shippingPriceCurrency?: string;
    /**
     * 
     * @type {number}
     * @memberof CalculateDeliveryResponse
     */
    totalShippingPrice?: number;
}
/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    createDate?: string;
    /**
     * 
     * @type {Array<CartItem>}
     * @memberof Cart
     */
    items?: Array<CartItem>;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    status: Cart.StatusEnum;
    /**
     * Card UID. Required for update operations only.
     * @type {string}
     * @memberof Cart
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    updateDate?: string;
}

/**
 * @export
 * @namespace Cart
 */
export namespace Cart {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        O = <any> 'O'
    }
}
/**
 * 
 * @export
 * @interface CartItem
 */
export interface CartItem {
    /**
     * 
     * @type {Array<DeliveryMethod>}
     * @memberof CartItem
     */
    availableDeliveryMethods?: Array<DeliveryMethod>;
    /**
     * 
     * @type {Array<PlatformDeliveryMethod>}
     * @memberof CartItem
     */
    availablePlatformDeliveryMethods?: Array<PlatformDeliveryMethod>;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    availableQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CartItem
     */
    deliveryConfigured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    deliveryCountryCode?: string;
    /**
     * 
     * @type {DeliveryMethodOption}
     * @memberof CartItem
     */
    deliveryMethodOption?: DeliveryMethodOption;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    deliveryPostalCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    deliveryPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    deliveryPriceCalculated?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    deliveryPriceCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    itemUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CartItem
     */
    outOfStock?: boolean;
    /**
     * 
     * @type {Array<ProductItemParam>}
     * @memberof CartItem
     */
    parameters?: Array<ProductItemParam>;
    /**
     * 
     * @type {boolean}
     * @memberof CartItem
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {PlatformDeliveryMethod}
     * @memberof CartItem
     */
    platformDeliveryMethod?: PlatformDeliveryMethod;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    productCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    productDiscountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    productName?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    productSalePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof CartItem
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    sellerIban?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    sellerName?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CartItem
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CartItem
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Characteristic
 */
export interface Characteristic {
    /**
     * 
     * @type {Array<CharacteristicCategory>}
     * @memberof Characteristic
     */
    categories?: Array<CharacteristicCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof Characteristic
     */
    filter?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Characteristic
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Characteristic
     */
    locizeKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Characteristic
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Characteristic
     */
    type?: string;
    /**
     * 
     * @type {Array<CharacteristicValue>}
     * @memberof Characteristic
     */
    values?: Array<CharacteristicValue>;
}
/**
 * 
 * @export
 * @interface CharacteristicCategory
 */
export interface CharacteristicCategory {
    /**
     * 
     * @type {string}
     * @memberof CharacteristicCategory
     */
    categoryCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CharacteristicCategory
     */
    characteristicId?: number;
    /**
     * 
     * @type {number}
     * @memberof CharacteristicCategory
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CharacteristicFilter
 */
export interface CharacteristicFilter {
    /**
     * 
     * @type {number}
     * @memberof CharacteristicFilter
     */
    characteristicId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CharacteristicFilter
     */
    possibleValues?: Array<number>;
}
/**
 * 
 * @export
 * @interface CharacteristicValue
 */
export interface CharacteristicValue {
    /**
     * 
     * @type {number}
     * @memberof CharacteristicValue
     */
    characteristicId?: number;
    /**
     * 
     * @type {number}
     * @memberof CharacteristicValue
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CharacteristicValue
     */
    locizeKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CharacteristicValue
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {number}
     * @memberof City
     */
    confidence?: number;
    /**
     * 
     * @type {number}
     * @memberof City
     */
    geonameId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof City
     */
    names?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Classificator
 */
export interface Classificator {
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    dependency?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    seoText?: string;
    /**
     * 
     * @type {string}
     * @memberof Classificator
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponse
 */
export interface CommonMarketplaceApiResponse {
    /**
     * 
     * @type {Array<any>}
     * @memberof CommonMarketplaceApiResponse
     */
    body?: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponse
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponse
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponse
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseAccount
 */
export interface CommonMarketplaceApiResponseAccount {
    /**
     * 
     * @type {Array<Account>}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    body?: Array<Account>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccount
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseAccountCategory
 */
export interface CommonMarketplaceApiResponseAccountCategory {
    /**
     * 
     * @type {Array<AccountCategory>}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    body?: Array<AccountCategory>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccountCategory
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseAccountProductStat
 */
export interface CommonMarketplaceApiResponseAccountProductStat {
    /**
     * 
     * @type {Array<AccountProductStat>}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    body?: Array<AccountProductStat>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseAccountProductStat
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseArticle
 */
export interface CommonMarketplaceApiResponseArticle {
    /**
     * 
     * @type {Array<Article>}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    body?: Array<Article>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseArticle
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseBanner
 */
export interface CommonMarketplaceApiResponseBanner {
    /**
     * 
     * @type {Array<Banner>}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    body?: Array<Banner>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseBanner
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseBuildProperties
 */
export interface CommonMarketplaceApiResponseBuildProperties {
    /**
     * 
     * @type {Array<BuildProperties>}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    body?: Array<BuildProperties>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseBuildProperties
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseCalculateDeliveryResponse
 */
export interface CommonMarketplaceApiResponseCalculateDeliveryResponse {
    /**
     * 
     * @type {Array<CalculateDeliveryResponse>}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    body?: Array<CalculateDeliveryResponse>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCalculateDeliveryResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseCart
 */
export interface CommonMarketplaceApiResponseCart {
    /**
     * 
     * @type {Array<Cart>}
     * @memberof CommonMarketplaceApiResponseCart
     */
    body?: Array<Cart>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCart
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCart
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCart
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseCart
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCart
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCart
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseCharacteristic
 */
export interface CommonMarketplaceApiResponseCharacteristic {
    /**
     * 
     * @type {Array<Characteristic>}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    body?: Array<Characteristic>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseCharacteristic
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseClassificator
 */
export interface CommonMarketplaceApiResponseClassificator {
    /**
     * 
     * @type {Array<Classificator>}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    body?: Array<Classificator>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseClassificator
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryCity
 */
export interface CommonMarketplaceApiResponseDeliveryCity {
    /**
     * 
     * @type {Array<DeliveryCity>}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    body?: Array<DeliveryCity>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryCity
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryMethod
 */
export interface CommonMarketplaceApiResponseDeliveryMethod {
    /**
     * 
     * @type {Array<DeliveryMethod>}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    body?: Array<DeliveryMethod>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryMethod
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryPoint
 */
export interface CommonMarketplaceApiResponseDeliveryPoint {
    /**
     * 
     * @type {Array<DeliveryPoint>}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    body?: Array<DeliveryPoint>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryPoint
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryShippingOrder
 */
export interface CommonMarketplaceApiResponseDeliveryShippingOrder {
    /**
     * 
     * @type {Array<ShippingOrder>}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    body?: Array<ShippingOrder>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingOrder
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDeliveryShippingPrice
 */
export interface CommonMarketplaceApiResponseDeliveryShippingPrice {
    /**
     * 
     * @type {Array<DeliveryShippingPrice>}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    body?: Array<DeliveryShippingPrice>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDeliveryShippingPrice
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseDigitalContent
 */
export interface CommonMarketplaceApiResponseDigitalContent {
    /**
     * 
     * @type {Array<DigitalContent>}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    body?: Array<DigitalContent>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseDigitalContent
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseHashtagSearchResult
 */
export interface CommonMarketplaceApiResponseHashtagSearchResult {
    /**
     * 
     * @type {Array<HashtagSearchResult>}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    body?: Array<HashtagSearchResult>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseHashtagSearchResult
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseOmnideskTicket
 */
export interface CommonMarketplaceApiResponseOmnideskTicket {
    /**
     * 
     * @type {Array<OmnideskTicket>}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    body?: Array<OmnideskTicket>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseOmnideskTicket
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponsePlatformDeliveryMethod
 */
export interface CommonMarketplaceApiResponsePlatformDeliveryMethod {
    /**
     * 
     * @type {Array<PlatformDeliveryMethod>}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    body?: Array<PlatformDeliveryMethod>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryMethod
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponsePlatformDeliveryProvider
 */
export interface CommonMarketplaceApiResponsePlatformDeliveryProvider {
    /**
     * 
     * @type {Array<PlatformDeliveryProvider>}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    body?: Array<PlatformDeliveryProvider>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePlatformDeliveryProvider
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProduct
 */
export interface CommonMarketplaceApiResponseProduct {
    /**
     * 
     * @type {Array<Product>}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    body?: Array<Product>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProduct
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductCategory
 */
export interface CommonMarketplaceApiResponseProductCategory {
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    body?: Array<ProductCategory>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductCategory
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductChat
 */
export interface CommonMarketplaceApiResponseProductChat {
    /**
     * 
     * @type {Array<ProductChat>}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    body?: Array<ProductChat>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductChat
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductExt
 */
export interface CommonMarketplaceApiResponseProductExt {
    /**
     * 
     * @type {Array<ProductExt>}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    body?: Array<ProductExt>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductExt
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductFeatureType
 */
export interface CommonMarketplaceApiResponseProductFeatureType {
    /**
     * 
     * @type {Array<ProductFeatureType>}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    body?: Array<ProductFeatureType>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductFeatureType
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductRating
 */
export interface CommonMarketplaceApiResponseProductRating {
    /**
     * 
     * @type {Array<ProductRatingResponse>}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    body?: Array<ProductRatingResponse>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductRating
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductRatingResponse
 */
export interface CommonMarketplaceApiResponseProductRatingResponse {
    /**
     * 
     * @type {Array<ProductRatingResponse>}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    body?: Array<ProductRatingResponse>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductRatingResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductReview
 */
export interface CommonMarketplaceApiResponseProductReview {
    /**
     * 
     * @type {Array<ProductReview>}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    body?: Array<ProductReview>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductReview
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductSource
 */
export interface CommonMarketplaceApiResponseProductSource {
    /**
     * 
     * @type {Array<ProductSource>}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    body?: Array<ProductSource>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSource
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductSourceContent
 */
export interface CommonMarketplaceApiResponseProductSourceContent {
    /**
     * 
     * @type {Array<ProductSourceContent>}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    body?: Array<ProductSourceContent>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSourceContent
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseProductSourceType
 */
export interface CommonMarketplaceApiResponseProductSourceType {
    /**
     * 
     * @type {Array<ProductSourceType>}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    body?: Array<ProductSourceType>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseProductSourceType
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponsePromotionPage
 */
export interface CommonMarketplaceApiResponsePromotionPage {
    /**
     * 
     * @type {Array<PromotionPage>}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    body?: Array<PromotionPage>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsePromotionPage
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseSale
 */
export interface CommonMarketplaceApiResponseSale {
    /**
     * 
     * @type {Array<Sale>}
     * @memberof CommonMarketplaceApiResponseSale
     */
    body?: Array<Sale>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSale
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSale
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSale
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseSale
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSale
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSale
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseSaleProduct
 */
export interface CommonMarketplaceApiResponseSaleProduct {
    /**
     * 
     * @type {Array<SaleProduct>}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    body?: Array<SaleProduct>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSaleProduct
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseSearchResult
 */
export interface CommonMarketplaceApiResponseSearchResult {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    body?: Array<SearchResult>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseSearchResult
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseShippingOrder
 */
export interface CommonMarketplaceApiResponseShippingOrder {
    /**
     * 
     * @type {Array<ShippingOrder>}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    body?: Array<ShippingOrder>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShippingOrder
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseShortStoreInfo
 */
export interface CommonMarketplaceApiResponseShortStoreInfo {
    /**
     * 
     * @type {Array<ShortStoreInfo>}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    body?: Array<ShortStoreInfo>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShortStoreInfo
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseShortStoreSearchInfo
 */
export interface CommonMarketplaceApiResponseShortStoreSearchInfo {
    /**
     * 
     * @type {Array<ShortStoreSearchInfo>}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    body?: Array<ShortStoreSearchInfo>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseShortStoreSearchInfo
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStatistics
 */
export interface CommonMarketplaceApiResponseStatistics {
    /**
     * 
     * @type {Array<Statistics>}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    body?: Array<Statistics>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStatistics
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStore
 */
export interface CommonMarketplaceApiResponseStore {
    /**
     * 
     * @type {Array<Store>}
     * @memberof CommonMarketplaceApiResponseStore
     */
    body?: Array<Store>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStore
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStore
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStore
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStore
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStore
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStore
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStoreCheck
 */
export interface CommonMarketplaceApiResponseStoreCheck {
    /**
     * 
     * @type {Array<StoreCheck>}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    body?: Array<StoreCheck>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreCheck
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStoreHomepage
 */
export interface CommonMarketplaceApiResponseStoreHomepage {
    /**
     * 
     * @type {Array<StoreHomepage>}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    body?: Array<StoreHomepage>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreHomepage
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStoreRating
 */
export interface CommonMarketplaceApiResponseStoreRating {
    /**
     * 
     * @type {Array<StoreRatingResponse>}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    body?: Array<StoreRatingResponse>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreRating
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseStoreRatingResponse
 */
export interface CommonMarketplaceApiResponseStoreRatingResponse {
    /**
     * 
     * @type {Array<StoreRatingResponse>}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    body?: Array<StoreRatingResponse>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseStoreRatingResponse
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseString
 */
export interface CommonMarketplaceApiResponseString {
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonMarketplaceApiResponseString
     */
    body?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseString
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseString
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseString
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseString
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseString
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseString
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseTicket
 */
export interface CommonMarketplaceApiResponseTicket {
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    body?: Array<Ticket>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseTicket
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseUnreviewedProduct
 */
export interface CommonMarketplaceApiResponseUnreviewedProduct {
    /**
     * 
     * @type {Array<UnreviewedProduct>}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    body?: Array<UnreviewedProduct>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseUnreviewedProduct
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseUploadedImage
 */
export interface CommonMarketplaceApiResponseUploadedImage {
    /**
     * 
     * @type {Array<UploadedImage>}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    body?: Array<UploadedImage>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseUploadedImage
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponseVoid
 */
export interface CommonMarketplaceApiResponseVoid {
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponseVoid
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface CommonMarketplaceApiResponsestring
 */
export interface CommonMarketplaceApiResponsestring {
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonMarketplaceApiResponsestring
     */
    body?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsestring
     */
    count?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsestring
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsestring
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonMarketplaceApiResponsestring
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CommonMarketplaceApiResponsestring
     */
    totalCount?: number;
    /**
     * 
     * @type {ErrorData}
     * @memberof CommonMarketplaceApiResponsestring
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface Continent
 */
export interface Continent {
    /**
     * 
     * @type {string}
     * @memberof Continent
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof Continent
     */
    geonameId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Continent
     */
    names?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    confidence?: number;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    geonameId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Country
     */
    isInEuropeanUnion?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    isoCode?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Country
     */
    names?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface CreateShippingOrderRequest
 */
export interface CreateShippingOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShippingOrderRequest
     */
    productOrderUid?: string;
}
/**
 * 
 * @export
 * @interface DeliveryCalculateRequest
 */
export interface DeliveryCalculateRequest {
    /**
     * 
     * @type {CartItem}
     * @memberof DeliveryCalculateRequest
     */
    cartItem?: CartItem;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCalculateRequest
     */
    deliveryProviderUid?: string;
}
/**
 * 
 * @export
 * @interface DeliveryCity
 */
export interface DeliveryCity {
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    fiasGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCity
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCity
     */
    longitude?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryCity
     */
    postalCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    region?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCity
     */
    regionCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    subRegion?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCity
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface DeliveryCityRequest
 */
export interface DeliveryCityRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityRequest
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryCityRequest
     */
    countryCodes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityRequest
     */
    deliveryProviderType?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityRequest
     */
    fiasGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityRequest
     */
    pageNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCityRequest
     */
    postalCode?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityRequest
     */
    regionCode?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryCityRequest
     */
    rowLimit?: number;
}
/**
 * 
 * @export
 * @interface DeliveryCountry
 */
export interface DeliveryCountry {
    /**
     * 
     * @type {string}
     * @memberof DeliveryCountry
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryCountry
     */
    excluded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryCountry
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DeliveryDimension
 */
export interface DeliveryDimension {
    /**
     * 
     * @type {number}
     * @memberof DeliveryDimension
     */
    depth?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDimension
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryDimension
     */
    width?: number;
}
/**
 * 
 * @export
 * @interface DeliveryMethod
 */
export interface DeliveryMethod {
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethod
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethod
     */
    name: string;
    /**
     * 
     * @type {Array<DeliveryMethodOption>}
     * @memberof DeliveryMethod
     */
    options?: Array<DeliveryMethodOption>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethod
     */
    type: DeliveryMethod.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethod
     */
    typeName?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof DeliveryMethod
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethod
     */
    updateDate?: string;
}

/**
 * @export
 * @namespace DeliveryMethod
 */
export namespace DeliveryMethod {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DELIVERY = <any> 'DELIVERY',
        PICKUP = <any> 'PICKUP'
    }
}
/**
 * 
 * @export
 * @interface DeliveryMethodOption
 */
export interface DeliveryMethodOption {
    /**
     * Used for delivery options only.
     * @type {Array<DeliveryCountry>}
     * @memberof DeliveryMethodOption
     */
    countries?: Array<DeliveryCountry>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    createDate?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    deliveryMethodUid?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryMethodOption
     */
    deliveryTimeDaysMax: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryMethodOption
     */
    deliveryTimeDaysMin: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    description?: string;
    /**
     * 
     * @type {Address}
     * @memberof DeliveryMethodOption
     */
    pickupAddress?: Address;
    /**
     * 
     * @type {number}
     * @memberof DeliveryMethodOption
     */
    price: number;
    /**
     * Delivery method option UID. Required for update operations only.
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryMethodOption
     */
    updateDate?: string;
}
/**
 * 
 * @export
 * @interface DeliveryPoint
 */
export interface DeliveryPoint {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    addressComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    allowedCod?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    code?: string;
    /**
     * 
     * @type {Array<DeliveryDimension>}
     * @memberof DeliveryPoint
     */
    dimensions?: Array<DeliveryDimension>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    fulfillment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    haveCash?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    haveCashless?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    isDressingRoom?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    isHandout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    isReception?: boolean;
    /**
     * 
     * @type {DeliveryPointLocation}
     * @memberof DeliveryPoint
     */
    location?: DeliveryPointLocation;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    nearestMetroStation?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    nearestStation?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    ownerCode?: string;
    /**
     * 
     * @type {Array<DeliveryPointPhone>}
     * @memberof DeliveryPoint
     */
    phones?: Array<DeliveryPointPhone>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    site?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPoint
     */
    takeOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    weightMax?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    weightMin?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    workTime?: string;
    /**
     * 
     * @type {Array<DeliveryTimeWorkTimeException>}
     * @memberof DeliveryPoint
     */
    workTimeExceptions?: Array<DeliveryTimeWorkTimeException>;
    /**
     * 
     * @type {Array<DeliveryTimeWorkTime>}
     * @memberof DeliveryPoint
     */
    workTimeList?: Array<DeliveryTimeWorkTime>;
}
/**
 * 
 * @export
 * @interface DeliveryPointLocation
 */
export interface DeliveryPointLocation {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    addressFull?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    cityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    fiasGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    latitude?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    longitude?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointLocation
     */
    regionCode?: string;
}
/**
 * 
 * @export
 * @interface DeliveryPointPhone
 */
export interface DeliveryPointPhone {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointPhone
     */
    additional?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointPhone
     */
    number?: string;
}
/**
 * 
 * @export
 * @interface DeliveryPointRequest
 */
export interface DeliveryPointRequest {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    cityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    deliveryProviderType?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    fiasGuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPointRequest
     */
    isHandout?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPointRequest
     */
    isReception?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    regionCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryPointRequest
     */
    takeOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPointRequest
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPointRequest
     */
    weightMax?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPointRequest
     */
    weightMin?: number;
}
/**
 * 
 * @export
 * @interface DeliveryShippingPrice
 */
export interface DeliveryShippingPrice {
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    calendarMax?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    calendarMin?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryShippingPrice
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryShippingPrice
     */
    deliveryFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    deliverySum?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryShippingPrice
     */
    deliveryTo?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryShippingPrice
     */
    deliveryType?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    periodMax?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    periodMin?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryShippingPrice
     */
    tariffCode?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryShippingPrice
     */
    tariffName?: string;
}
/**
 * 
 * @export
 * @interface DeliveryTimeWorkTime
 */
export interface DeliveryTimeWorkTime {
    /**
     * 
     * @type {number}
     * @memberof DeliveryTimeWorkTime
     */
    day?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeWorkTime
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface DeliveryTimeWorkTimeException
 */
export interface DeliveryTimeWorkTimeException {
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeWorkTimeException
     */
    date?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryTimeWorkTimeException
     */
    isWorking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryTimeWorkTimeException
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface DigitalContent
 */
export interface DigitalContent {
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    desktopImageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalContent
     */
    free?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DigitalContent
     */
    itemCount?: number;
    /**
     * 
     * @type {Array<DigitalContentItem>}
     * @memberof DigitalContent
     */
    items?: Array<DigitalContentItem>;
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    mobileImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    productUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DigitalContent
     */
    purchased?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DigitalContent
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface DigitalContentItem
 */
export interface DigitalContentItem {
    /**
     * 
     * @type {string}
     * @memberof DigitalContentItem
     */
    contentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalContentItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DigitalContentItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DigitalContentItem
     */
    sortOrderNo?: number;
    /**
     * 
     * @type {string}
     * @memberof DigitalContentItem
     */
    uid?: string;
    /**
     * 
     * @type {VideoContent}
     * @memberof DigitalContentItem
     */
    videoContent?: VideoContent;
}
/**
 * 
 * @export
 * @interface EditProductReviewRequest
 */
export interface EditProductReviewRequest {
    /**
     * 
     * @type {boolean}
     * @memberof EditProductReviewRequest
     */
    anonymously?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditProductReviewRequest
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditProductReviewRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {number}
     * @memberof EditProductReviewRequest
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof EditProductReviewRequest
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface ErrorData
 */
export interface ErrorData {
    /**
     * 
     * @type {number}
     * @memberof ErrorData
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ErrorData
     */
    parameters?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ErrorData
     */
    requestUid?: string;
}
/**
 * 
 * @export
 * @interface ExternalProductRequest
 */
export interface ExternalProductRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalProductRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalProductRequest
     */
    externalUid?: string;
}
/**
 * 
 * @export
 * @interface FileUploadBody
 */
export interface FileUploadBody {
    /**
     * bucket
     * @type {any}
     * @memberof FileUploadBody
     */
    bucket?: any;
    /**
     * file
     * @type {Blob}
     * @memberof FileUploadBody
     */
    file: Blob;
}
/**
 * 
 * @export
 * @interface GeoIPData
 */
export interface GeoIPData {
    /**
     * 
     * @type {City}
     * @memberof GeoIPData
     */
    city?: City;
    /**
     * 
     * @type {Continent}
     * @memberof GeoIPData
     */
    continent?: Continent;
    /**
     * 
     * @type {Country}
     * @memberof GeoIPData
     */
    country?: Country;
    /**
     * 
     * @type {Location}
     * @memberof GeoIPData
     */
    location?: Location;
    /**
     * 
     * @type {Postal}
     * @memberof GeoIPData
     */
    postal?: Postal;
}
/**
 * 
 * @export
 * @interface GetStatisticsRequest
 */
export interface GetStatisticsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetStatisticsRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatisticsRequest
     */
    dateTo?: string;
    /**
     * 
     * @type {number}
     * @memberof GetStatisticsRequest
     */
    rowLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof GetStatisticsRequest
     */
    type?: GetStatisticsRequest.TypeEnum;
}

/**
 * @export
 * @namespace GetStatisticsRequest
 */
export namespace GetStatisticsRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DAY = <any> 'DAY',
        WEEK = <any> 'WEEK',
        MONTH = <any> 'MONTH',
        YEAR = <any> 'YEAR'
    }
}
/**
 * 
 * @export
 * @interface HashtagSearchResult
 */
export interface HashtagSearchResult {
    /**
     * 
     * @type {string}
     * @memberof HashtagSearchResult
     */
    hashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof HashtagSearchResult
     */
    productCount?: number;
}
/**
 * 
 * @export
 * @interface InputStream
 */
export interface InputStream {
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    accuracyRadius?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    averageIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    metroCode?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    populationDensity?: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    empty?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelAndView
     */
    model?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelAndView
     */
    modelMap?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    reference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    status?: ModelAndView.StatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    view?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    viewName?: string;
}

/**
 * @export
 * @namespace ModelAndView
 */
export namespace ModelAndView {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        _100CONTINUE = <any> '100 CONTINUE',
        _101SWITCHINGPROTOCOLS = <any> '101 SWITCHING_PROTOCOLS',
        _102PROCESSING = <any> '102 PROCESSING',
        _103CHECKPOINT = <any> '103 CHECKPOINT',
        _200OK = <any> '200 OK',
        _201CREATED = <any> '201 CREATED',
        _202ACCEPTED = <any> '202 ACCEPTED',
        _203NONAUTHORITATIVEINFORMATION = <any> '203 NON_AUTHORITATIVE_INFORMATION',
        _204NOCONTENT = <any> '204 NO_CONTENT',
        _205RESETCONTENT = <any> '205 RESET_CONTENT',
        _206PARTIALCONTENT = <any> '206 PARTIAL_CONTENT',
        _207MULTISTATUS = <any> '207 MULTI_STATUS',
        _208ALREADYREPORTED = <any> '208 ALREADY_REPORTED',
        _226IMUSED = <any> '226 IM_USED',
        _300MULTIPLECHOICES = <any> '300 MULTIPLE_CHOICES',
        _301MOVEDPERMANENTLY = <any> '301 MOVED_PERMANENTLY',
        _302FOUND = <any> '302 FOUND',
        _302MOVEDTEMPORARILY = <any> '302 MOVED_TEMPORARILY',
        _303SEEOTHER = <any> '303 SEE_OTHER',
        _304NOTMODIFIED = <any> '304 NOT_MODIFIED',
        _305USEPROXY = <any> '305 USE_PROXY',
        _307TEMPORARYREDIRECT = <any> '307 TEMPORARY_REDIRECT',
        _308PERMANENTREDIRECT = <any> '308 PERMANENT_REDIRECT',
        _400BADREQUEST = <any> '400 BAD_REQUEST',
        _401UNAUTHORIZED = <any> '401 UNAUTHORIZED',
        _402PAYMENTREQUIRED = <any> '402 PAYMENT_REQUIRED',
        _403FORBIDDEN = <any> '403 FORBIDDEN',
        _404NOTFOUND = <any> '404 NOT_FOUND',
        _405METHODNOTALLOWED = <any> '405 METHOD_NOT_ALLOWED',
        _406NOTACCEPTABLE = <any> '406 NOT_ACCEPTABLE',
        _407PROXYAUTHENTICATIONREQUIRED = <any> '407 PROXY_AUTHENTICATION_REQUIRED',
        _408REQUESTTIMEOUT = <any> '408 REQUEST_TIMEOUT',
        _409CONFLICT = <any> '409 CONFLICT',
        _410GONE = <any> '410 GONE',
        _411LENGTHREQUIRED = <any> '411 LENGTH_REQUIRED',
        _412PRECONDITIONFAILED = <any> '412 PRECONDITION_FAILED',
        _413PAYLOADTOOLARGE = <any> '413 PAYLOAD_TOO_LARGE',
        _413REQUESTENTITYTOOLARGE = <any> '413 REQUEST_ENTITY_TOO_LARGE',
        _414URITOOLONG = <any> '414 URI_TOO_LONG',
        _414REQUESTURITOOLONG = <any> '414 REQUEST_URI_TOO_LONG',
        _415UNSUPPORTEDMEDIATYPE = <any> '415 UNSUPPORTED_MEDIA_TYPE',
        _416REQUESTEDRANGENOTSATISFIABLE = <any> '416 REQUESTED_RANGE_NOT_SATISFIABLE',
        _417EXPECTATIONFAILED = <any> '417 EXPECTATION_FAILED',
        _418IAMATEAPOT = <any> '418 I_AM_A_TEAPOT',
        _419INSUFFICIENTSPACEONRESOURCE = <any> '419 INSUFFICIENT_SPACE_ON_RESOURCE',
        _420METHODFAILURE = <any> '420 METHOD_FAILURE',
        _421DESTINATIONLOCKED = <any> '421 DESTINATION_LOCKED',
        _422UNPROCESSABLEENTITY = <any> '422 UNPROCESSABLE_ENTITY',
        _423LOCKED = <any> '423 LOCKED',
        _424FAILEDDEPENDENCY = <any> '424 FAILED_DEPENDENCY',
        _425TOOEARLY = <any> '425 TOO_EARLY',
        _426UPGRADEREQUIRED = <any> '426 UPGRADE_REQUIRED',
        _428PRECONDITIONREQUIRED = <any> '428 PRECONDITION_REQUIRED',
        _429TOOMANYREQUESTS = <any> '429 TOO_MANY_REQUESTS',
        _431REQUESTHEADERFIELDSTOOLARGE = <any> '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
        _451UNAVAILABLEFORLEGALREASONS = <any> '451 UNAVAILABLE_FOR_LEGAL_REASONS',
        _500INTERNALSERVERERROR = <any> '500 INTERNAL_SERVER_ERROR',
        _501NOTIMPLEMENTED = <any> '501 NOT_IMPLEMENTED',
        _502BADGATEWAY = <any> '502 BAD_GATEWAY',
        _503SERVICEUNAVAILABLE = <any> '503 SERVICE_UNAVAILABLE',
        _504GATEWAYTIMEOUT = <any> '504 GATEWAY_TIMEOUT',
        _505HTTPVERSIONNOTSUPPORTED = <any> '505 HTTP_VERSION_NOT_SUPPORTED',
        _506VARIANTALSONEGOTIATES = <any> '506 VARIANT_ALSO_NEGOTIATES',
        _507INSUFFICIENTSTORAGE = <any> '507 INSUFFICIENT_STORAGE',
        _508LOOPDETECTED = <any> '508 LOOP_DETECTED',
        _509BANDWIDTHLIMITEXCEEDED = <any> '509 BANDWIDTH_LIMIT_EXCEEDED',
        _510NOTEXTENDED = <any> '510 NOT_EXTENDED',
        _511NETWORKAUTHENTICATIONREQUIRED = <any> '511 NETWORK_AUTHENTICATION_REQUIRED'
    }
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    absolute?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    absoluteFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    absolutePath?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    canonicalFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    canonicalPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    directory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    file?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    freeSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    parent?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    parentFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    totalSpace?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    usableSpace?: number;
}
/**
 * 
 * @export
 * @interface MultipleMarketplaceApiResponseProductAndProductCategory
 */
export interface MultipleMarketplaceApiResponseProductAndProductCategory {
    /**
     * 
     * @type {{ [key: string]: Array<Product>; }}
     * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
     */
    body?: { [key: string]: Array<Product>; };
    /**
     * 
     * @type {ErrorData}
     * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
     */
    successful?: boolean;
    /**
     * 
     * @type {ErrorData}
     * @memberof MultipleMarketplaceApiResponseProductAndProductCategory
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface MultipleMarketplaceApiResponseProductCategoryProduct
 */
export interface MultipleMarketplaceApiResponseProductCategoryProduct {
    /**
     * 
     * @type {{ [key: string]: Array<Product>; }}
     * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
     */
    body?: { [key: string]: Array<Product>; };
    /**
     * 
     * @type {ErrorData}
     * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
     */
    errorData?: ErrorData;
    /**
     * 
     * @type {boolean}
     * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
     */
    successful?: boolean;
    /**
     * 
     * @type {ErrorData}
     * @memberof MultipleMarketplaceApiResponseProductCategoryProduct
     */
    warningData?: ErrorData;
}
/**
 * 
 * @export
 * @interface OmnideskTicket
 */
export interface OmnideskTicket {
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    accountUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OmnideskTicket
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    companyName?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof OmnideskTicket
     */
    customParameters?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof OmnideskTicket
     */
    subject?: string;
}
/**
 * 
 * @export
 * @interface OwnerUidUploadBody
 */
export interface OwnerUidUploadBody {
    /**
     * base64File
     * @type {string}
     * @memberof OwnerUidUploadBody
     */
    base64File: string;
    /**
     * bucket
     * @type {string}
     * @memberof OwnerUidUploadBody
     */
    bucket?: string;
    /**
     * fileName
     * @type {string}
     * @memberof OwnerUidUploadBody
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface PlatformDeliveryMethod
 */
export interface PlatformDeliveryMethod {
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    accountUid?: string;
    /**
     * 
     * @type {Address}
     * @memberof PlatformDeliveryMethod
     */
    address?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformDeliveryMethod
     */
    availableForAllProducts?: boolean;
    /**
     * 
     * @type {PlatformDeliveryProvider}
     * @memberof PlatformDeliveryMethod
     */
    deliveryProvider?: PlatformDeliveryProvider;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    deliveryType: PlatformDeliveryMethod.DeliveryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryMethod
     */
    phoneNumber?: string;
}

/**
 * @export
 * @namespace PlatformDeliveryMethod
 */
export namespace PlatformDeliveryMethod {
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryTypeEnum {
        COURIER = <any> 'COURIER',
        DROPPOINT = <any> 'DROP_POINT',
        PVZ = <any> 'PVZ'
    }
}
/**
 * 
 * @export
 * @interface PlatformDeliveryProvider
 */
export interface PlatformDeliveryProvider {
    /**
     * 
     * @type {boolean}
     * @memberof PlatformDeliveryProvider
     */
    courierSupported?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformDeliveryProvider
     */
    excludedCountries?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlatformDeliveryProvider
     */
    includedCountries?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformDeliveryProvider
     */
    insuranceSupported?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryProvider
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlatformDeliveryProvider
     */
    pickUpSupported?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryProvider
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDeliveryProvider
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Postal
 */
export interface Postal {
    /**
     * 
     * @type {string}
     * @memberof Postal
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof Postal
     */
    confidence?: number;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {{ [key: string]: Array<ActiveFeatureDetails>; }}
     * @memberof Product
     */
    activeFeatures?: { [key: string]: Array<ActiveFeatureDetails>; };
    /**
     * 
     * @type {ProductAddress}
     * @memberof Product
     */
    address?: ProductAddress;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    allowAutoReview?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    allowedCountries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    categoryExternalUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<ProductCharacteristic>}
     * @memberof Product
     */
    characteristics?: Array<ProductCharacteristic>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    color?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    colors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    currencySymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    customField3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    dealType?: Product.DealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    declineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    declinedBy?: string;
    /**
     * 
     * @type {Array<DeliveryMethod>}
     * @memberof Product
     */
    deliveryMethods?: Array<DeliveryMethod>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    disallowedCountries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    discount?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    discountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    expirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    externalUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    externalUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    featureCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    hashtagList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    hashtags?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageThumbnailUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl5?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    inStock?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isWishlist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    languageCode: string;
    /**
     * 
     * @type {Array<ProductLocalization>}
     * @memberof Product
     */
    localizations?: Array<ProductLocalization>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    manufacturerPartNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    negotiatedPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    originalLanguageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ownerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    placeId?: string;
    /**
     * 
     * @type {Array<PlatformDeliveryMethod>}
     * @memberof Product
     */
    platformDeliveryMethods?: Array<PlatformDeliveryMethod>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productDate?: string;
    /**
     * 
     * @type {Array<ProductParam>}
     * @memberof Product
     */
    productParams?: Array<ProductParam>;
    /**
     * 
     * @type {ProductSize}
     * @memberof Product
     */
    productSize?: ProductSize;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    publishDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    purchasable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    quantityReserved?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    rating?: number;
    /**
     * 
     * @type {ProductRentOptions}
     * @memberof Product
     */
    rentOptions?: ProductRentOptions;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    reviewCount?: number;
    /**
     * 
     * @type {Sale}
     * @memberof Product
     */
    sale?: Sale;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    salePrice?: number;
    /**
     * 
     * @type {Seller}
     * @memberof Product
     */
    seller?: Seller;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerExternalAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerIban?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerProfilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sellerUid?: string;
    /**
     * 
     * @type {Array<AccountWebResource>}
     * @memberof Product
     */
    sellerWebResources?: Array<AccountWebResource>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    shippingAllowedInOtherCountries?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    size?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    soldItemsQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status: Product.StatusEnum;
    /**
     * 
     * @type {Store}
     * @memberof Product
     */
    store?: Store;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    type: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof Product
     */
    uid?: string;
    /**
     * 
     * @type {ProductVideo}
     * @memberof Product
     */
    video?: ProductVideo;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    viewCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    viewCountString?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    weightUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    wishCount?: number;
}

/**
 * @export
 * @namespace Product
 */
export namespace Product {
    /**
     * @export
     * @enum {string}
     */
    export enum DealTypeEnum {
        RENT = <any> 'RENT',
        SELL = <any> 'SELL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRF = <any> 'DRF',
        DLT = <any> 'DLT',
        AFR = <any> 'AFR',
        DCL = <any> 'DCL',
        FRB = <any> 'FRB',
        APR = <any> 'APR',
        PBL = <any> 'PBL',
        EXP = <any> 'EXP',
        DSC = <any> 'DSC',
        SUS = <any> 'SUS',
        OOS = <any> 'OOS',
        CDS = <any> 'CDS'
    }
}
/**
 * 
 * @export
 * @interface ProductAddress
 */
export interface ProductAddress {
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    firstAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    secondAddressLine?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAddress
     */
    state?: string;
}
/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    categoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof ProductCategory
     */
    children?: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    dependency?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    iconCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    locizeKey?: string;
    /**
     * 
     * @type {ProductCategory}
     * @memberof ProductCategory
     */
    parent?: ProductCategory;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategory
     */
    pickUpAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    productCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    productType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategory
     */
    returnAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    seoText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductCategory
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategory
     */
    thumbnailUrl?: string;
}
/**
 * 
 * @export
 * @interface ProductCharacteristic
 */
export interface ProductCharacteristic {
    /**
     * 
     * @type {number}
     * @memberof ProductCharacteristic
     */
    characteristicId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCharacteristic
     */
    characteristicValueId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    nameLocizeKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCharacteristic
     */
    valueLocizeKey?: string;
}
/**
 * 
 * @export
 * @interface ProductChat
 */
export interface ProductChat {
    /**
     * 
     * @type {string}
     * @memberof ProductChat
     */
    accountIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductChat
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductChat
     */
    productUid?: string;
}
/**
 * 
 * @export
 * @interface ProductDelivery
 */
export interface ProductDelivery {
    /**
     * 
     * @type {DeliveryMethod}
     * @memberof ProductDelivery
     */
    deliveryMethod?: DeliveryMethod;
    /**
     * 
     * @type {DeliveryMethodOption}
     * @memberof ProductDelivery
     */
    deliveryMethodOption?: DeliveryMethodOption;
    /**
     * 
     * @type {PlatformDeliveryMethod}
     * @memberof ProductDelivery
     */
    platformDeliveryMethod?: PlatformDeliveryMethod;
    /**
     * 
     * @type {DeliveryShippingPrice}
     * @memberof ProductDelivery
     */
    platformDeliveryMethodShippingPrice?: DeliveryShippingPrice;
    /**
     * 
     * @type {string}
     * @memberof ProductDelivery
     */
    productUid?: string;
}
/**
 * 
 * @export
 * @interface ProductExpirationDateRequest
 */
export interface ProductExpirationDateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductExpirationDateRequest
     */
    expirationDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExpirationDateRequest
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface ProductExt
 */
export interface ProductExt {
    /**
     * 
     * @type {{ [key: string]: Array<ActiveFeatureDetails>; }}
     * @memberof ProductExt
     */
    activeFeatures?: { [key: string]: Array<ActiveFeatureDetails>; };
    /**
     * 
     * @type {ProductAddress}
     * @memberof ProductExt
     */
    address?: ProductAddress;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    allowAutoReview?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductExt
     */
    allowedCountries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    categoryExternalUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    categoryName?: string;
    /**
     * 
     * @type {Array<ProductCharacteristic>}
     * @memberof ProductExt
     */
    characteristics?: Array<ProductCharacteristic>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    color?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductExt
     */
    colors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    coordinates?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    currencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    currencySymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    customField1?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    customField2?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    customField3?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    dealType?: ProductExt.DealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    declineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    declinedBy?: string;
    /**
     * 
     * @type {Array<DeliveryMethod>}
     * @memberof ProductExt
     */
    deliveryMethods?: Array<DeliveryMethod>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductExt
     */
    disallowedCountries?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    discount?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    discountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    expirationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    externalUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    externalUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductExt
     */
    featureCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductExt
     */
    hashtagList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    hashtags?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageThumbnailUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageThumbnailUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageThumbnailUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageThumbnailUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    imageUrl5?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    inStock?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    isWishlist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    languageCode: string;
    /**
     * 
     * @type {Array<ProductLocalization>}
     * @memberof ProductExt
     */
    localizations?: Array<ProductLocalization>;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    manufacturerPartNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    negotiatedPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    originalCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    originalDiscountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    originalLanguageCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    originalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    ownerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    placeId?: string;
    /**
     * 
     * @type {Array<PlatformDeliveryMethod>}
     * @memberof ProductExt
     */
    platformDeliveryMethods?: Array<PlatformDeliveryMethod>;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    productDate?: string;
    /**
     * 
     * @type {Array<ProductParam>}
     * @memberof ProductExt
     */
    productParams?: Array<ProductParam>;
    /**
     * 
     * @type {ProductSize}
     * @memberof ProductExt
     */
    productSize?: ProductSize;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    publishDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    purchasable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    quantityReserved?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    rating?: number;
    /**
     * 
     * @type {ProductRentOptions}
     * @memberof ProductExt
     */
    rentOptions?: ProductRentOptions;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    reviewCount?: number;
    /**
     * 
     * @type {Sale}
     * @memberof ProductExt
     */
    sale?: Sale;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    salePrice?: number;
    /**
     * 
     * @type {Seller}
     * @memberof ProductExt
     */
    seller?: Seller;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerExternalAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerIban?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerProfilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    sellerUid?: string;
    /**
     * 
     * @type {Array<AccountWebResource>}
     * @memberof ProductExt
     */
    sellerWebResources?: Array<AccountWebResource>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductExt
     */
    shippingAllowedInOtherCountries?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    size?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    soldItemsQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    status: ProductExt.StatusEnum;
    /**
     * 
     * @type {Store}
     * @memberof ProductExt
     */
    store?: Store;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    type: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof ProductExt
     */
    uid?: string;
    /**
     * 
     * @type {ProductVideo}
     * @memberof ProductExt
     */
    video?: ProductVideo;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    viewCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    viewCountString?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    weight?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExt
     */
    weightUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductExt
     */
    wishCount?: number;
}

/**
 * @export
 * @namespace ProductExt
 */
export namespace ProductExt {
    /**
     * @export
     * @enum {string}
     */
    export enum DealTypeEnum {
        RENT = <any> 'RENT',
        SELL = <any> 'SELL'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRF = <any> 'DRF',
        DLT = <any> 'DLT',
        AFR = <any> 'AFR',
        DCL = <any> 'DCL',
        FRB = <any> 'FRB',
        APR = <any> 'APR',
        PBL = <any> 'PBL',
        EXP = <any> 'EXP',
        DSC = <any> 'DSC',
        SUS = <any> 'SUS',
        OOS = <any> 'OOS',
        CDS = <any> 'CDS'
    }
}
/**
 * 
 * @export
 * @interface ProductFeatureType
 */
export interface ProductFeatureType {
    /**
     * 
     * @type {Array<ProductFeatureType>}
     * @memberof ProductFeatureType
     */
    bonusFeatures?: Array<ProductFeatureType>;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductFeatureType
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    typeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    typeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    typeName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFeatureType
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductImageDeleteRequest
 */
export interface ProductImageDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductImageDeleteRequest
     */
    imageLink: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImageDeleteRequest
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface ProductItemParam
 */
export interface ProductItemParam {
    /**
     * 
     * @type {string}
     * @memberof ProductItemParam
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItemParam
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItemParam
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface ProductListRequest
 */
export interface ProductListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ProductListRequest
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListRequest
     */
    featured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductListRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    saleUid?: string;
    /**
     * 
     * @type {Sorting}
     * @memberof ProductListRequest
     */
    sortBy?: Sorting;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    status?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductListRequest
     */
    statuses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductListRequest
     */
    validateForSaleUid?: string;
}
/**
 * 
 * @export
 * @interface ProductLocalization
 */
export interface ProductLocalization {
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    customField1?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    customField2?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    customField3?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductLocalization
     */
    empty?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    originalLanguageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductLocalization
     */
    weightUnit?: string;
}
/**
 * 
 * @export
 * @interface ProductPage
 */
export interface ProductPage {
    /**
     * 
     * @type {string}
     * @memberof ProductPage
     */
    pageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPage
     */
    productUid?: string;
}
/**
 * 
 * @export
 * @interface ProductParam
 */
export interface ProductParam {
    /**
     * 
     * @type {string}
     * @memberof ProductParam
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductParam
     */
    mandatory?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductParam
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParam
     */
    validationRegExp?: string;
}
/**
 * 
 * @export
 * @interface ProductQuantityRequest
 */
export interface ProductQuantityRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductQuantityRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof ProductQuantityRequest
     */
    status: ProductQuantityRequest.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductQuantityRequest
     */
    uid: string;
}

/**
 * @export
 * @namespace ProductQuantityRequest
 */
export namespace ProductQuantityRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRF = <any> 'DRF',
        DLT = <any> 'DLT',
        AFR = <any> 'AFR',
        DCL = <any> 'DCL',
        FRB = <any> 'FRB',
        APR = <any> 'APR',
        PBL = <any> 'PBL',
        EXP = <any> 'EXP',
        DSC = <any> 'DSC',
        SUS = <any> 'SUS',
        OOS = <any> 'OOS',
        CDS = <any> 'CDS'
    }
}
/**
 * 
 * @export
 * @interface ProductRating
 */
export interface ProductRating {
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    ratingPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRating
     */
    reviewCount?: number;
}
/**
 * 
 * @export
 * @interface ProductRatingResponse
 */
export interface ProductRatingResponse {
    /**
     * 
     * @type {string}
     * @memberof ProductRatingResponse
     */
    productUid?: string;
    /**
     * 
     * @type {Array<ProductRating>}
     * @memberof ProductRatingResponse
     */
    rating?: Array<ProductRating>;
}
/**
 * 
 * @export
 * @interface ProductRentOptions
 */
export interface ProductRentOptions {
    /**
     * 
     * @type {string}
     * @memberof ProductRentOptions
     */
    dayRentEndTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRentOptions
     */
    dayRentStartTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRentOptions
     */
    dayRentTimezone?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRentOptions
     */
    deposit: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRentOptions
     */
    minPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRentOptions
     */
    period: ProductRentOptions.PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRentOptions
     */
    productValue: number;
}

/**
 * @export
 * @namespace ProductRentOptions
 */
export namespace ProductRentOptions {
    /**
     * @export
     * @enum {string}
     */
    export enum PeriodEnum {
        HOUR = <any> 'HOUR',
        DAY = <any> 'DAY',
        MONTH = <any> 'MONTH'
    }
}
/**
 * 
 * @export
 * @interface ProductReview
 */
export interface ProductReview {
    /**
     * 
     * @type {boolean}
     * @memberof ProductReview
     */
    anonymously?: boolean;
    /**
     * 
     * @type {ProductReviewAuthor}
     * @memberof ProductReview
     */
    author?: ProductReviewAuthor;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    creationDate?: string;
    /**
     * 
     * @type {Array<ProductReviewImage>}
     * @memberof ProductReview
     */
    images?: Array<ProductReviewImage>;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    orderUid: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    originalLanguageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    originalText?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    productImageThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    productUid: string;
    /**
     * 
     * @type {number}
     * @memberof ProductReview
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReview
     */
    text?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof ProductReview
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductReviewAccountListRequest
 */
export interface ProductReviewAccountListRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductReviewAccountListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAccountListRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAccountListRequest
     */
    dateTo?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductReviewAccountListRequest
     */
    offset?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAccountListRequest
     */
    sort?: string;
}
/**
 * 
 * @export
 * @interface ProductReviewAuthor
 */
export interface ProductReviewAuthor {
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAuthor
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAuthor
     */
    profilePictureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAuthor
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewAuthor
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductReviewImage
 */
export interface ProductReviewImage {
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    imageThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    reviewUid: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewImage
     */
    status?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof ProductReviewImage
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ProductReviewListRequest
 */
export interface ProductReviewListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductReviewListRequest
     */
    ratingFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductReviewListRequest
     */
    ratingTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    reviewStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    sellerAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    sort?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewListRequest
     */
    storeCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductReviewListRequest
     */
    verified?: boolean;
}
/**
 * 
 * @export
 * @interface ProductReviewStatusRequest
 */
export interface ProductReviewStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductReviewStatusRequest
     */
    reviewUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductReviewStatusRequest
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface ProductSearchListRequest
 */
export interface ProductSearchListRequest {
    /**
     * 
     * @type {ProductAddress}
     * @memberof ProductSearchListRequest
     */
    address?: ProductAddress;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    allowedCountryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    autoHashtags?: boolean;
    /**
     * Product category OR product list type (new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers)
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    coordinates?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    currentProductUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    dealType?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    discountedPriceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    discountedPriceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    expirationDate?: string;
    /**
     * 
     * @type {Array<CharacteristicFilter>}
     * @memberof ProductSearchListRequest
     */
    filters?: Array<CharacteristicFilter>;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    fullAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    minProductRating?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSearchListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    promotionPageUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    publishDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    saleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSearchListRequest
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof ProductSearchListRequest
     */
    sortBy?: Array<Sorting>;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    storeUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSearchListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface ProductSize
 */
export interface ProductSize {
    /**
     * 
     * @type {number}
     * @memberof ProductSize
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSize
     */
    length?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductSize
     */
    width?: number;
}
/**
 * 
 * @export
 * @interface ProductSource
 */
export interface ProductSource {
    /**
     * 
     * @type {boolean}
     * @memberof ProductSource
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    authPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    authUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    creationDate?: string;
    /**
     * 
     * @type {ProductSourceContent}
     * @memberof ProductSource
     */
    currentContent?: ProductSourceContent;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    languageCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    nextExecutionDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSource
     */
    skipNew?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    title: string;
    /**
     * 
     * @type {ProductSourceType}
     * @memberof ProductSource
     */
    type: ProductSourceType;
    /**
     * Source UID. Required for update operations only.
     * @type {string}
     * @memberof ProductSource
     */
    uid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSource
     */
    updateExisting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductSource
     */
    url: string;
}
/**
 * 
 * @export
 * @interface ProductSourceContent
 */
export interface ProductSourceContent {
    /**
     * 
     * @type {Array<AccountCategory>}
     * @memberof ProductSourceContent
     */
    categoryMapping?: Array<AccountCategory>;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceContent
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceContent
     */
    errorMessage?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSourceContent
     */
    failedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceContent
     */
    hash?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSourceContent
     */
    newCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSourceContent
     */
    processed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductSourceContent
     */
    skippedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceContent
     */
    sourceUid?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSourceContent
     */
    updatedCount?: number;
}
/**
 * 
 * @export
 * @interface ProductSourceType
 */
export interface ProductSourceType {
    /**
     * 
     * @type {string}
     * @memberof ProductSourceType
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceType
     */
    exampleUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceType
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSourceType
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ProductStatusRequest
 */
export interface ProductStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductStatusRequest
     */
    status: ProductStatusRequest.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductStatusRequest
     */
    uid: string;
}

/**
 * @export
 * @namespace ProductStatusRequest
 */
export namespace ProductStatusRequest {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRF = <any> 'DRF',
        DLT = <any> 'DLT',
        AFR = <any> 'AFR',
        DCL = <any> 'DCL',
        FRB = <any> 'FRB',
        APR = <any> 'APR',
        PBL = <any> 'PBL',
        EXP = <any> 'EXP',
        DSC = <any> 'DSC',
        SUS = <any> 'SUS',
        OOS = <any> 'OOS',
        CDS = <any> 'CDS'
    }
}
/**
 * 
 * @export
 * @interface ProductUidListRequest
 */
export interface ProductUidListRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUidListRequest
     */
    uid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductUidListRequest
     */
    uidList?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductUidRequest
 */
export interface ProductUidRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductUidRequest
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface ProductUidUploadBody
 */
export interface ProductUidUploadBody {
    /**
     * file
     * @type {Blob}
     * @memberof ProductUidUploadBody
     */
    file: Blob;
    /**
     * language
     * @type {any}
     * @memberof ProductUidUploadBody
     */
    language?: any;
}
/**
 * 
 * @export
 * @interface ProductVideo
 */
export interface ProductVideo {
    /**
     * 
     * @type {boolean}
     * @memberof ProductVideo
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductVideo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductVideo
     */
    type: ProductVideo.TypeEnum;
}

/**
 * @export
 * @namespace ProductVideo
 */
export namespace ProductVideo {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        YOUTUBE = <any> 'YOUTUBE',
        VIMEO = <any> 'VIMEO',
        VK = <any> 'VK'
    }
}
/**
 * 
 * @export
 * @interface PromotionPage
 */
export interface PromotionPage {
    /**
     * 
     * @type {PromotionPageBanner}
     * @memberof PromotionPage
     */
    banner?: PromotionPageBanner;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof PromotionPage
     */
    categories?: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof PromotionPage
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPage
     */
    modificationDate?: string;
    /**
     * 
     * @type {Array<PromotionPageProduct>}
     * @memberof PromotionPage
     */
    products?: Array<PromotionPageProduct>;
    /**
     * 
     * @type {string}
     * @memberof PromotionPage
     */
    title?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof PromotionPage
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface PromotionPageBanner
 */
export interface PromotionPageBanner {
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    desktopImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    mobileImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    targetUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageBanner
     */
    title: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof PromotionPageBanner
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface PromotionPageProduct
 */
export interface PromotionPageProduct {
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionPageProduct
     */
    discountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionPageProduct
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    productUid?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    rootCategoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageProduct
     */
    rootCategoryName?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionPageProduct
     */
    salePrice?: number;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof PromotionPageProduct
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface RandomProductListRequest
 */
export interface RandomProductListRequest {
    /**
     * 
     * @type {string}
     * @memberof RandomProductListRequest
     */
    allowedCountryCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RandomProductListRequest
     */
    categoryList?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RandomProductListRequest
     */
    coordinates?: string;
    /**
     * 
     * @type {number}
     * @memberof RandomProductListRequest
     */
    distance?: number;
    /**
     * 
     * @type {number}
     * @memberof RandomProductListRequest
     */
    limitPerCategory?: number;
}
/**
 * 
 * @export
 * @interface RemoveCartItemRequest
 */
export interface RemoveCartItemRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveCartItemRequest
     */
    itemUid?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveCartItemRequest
     */
    sellerUid?: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof Resource
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {InputStream}
     * @memberof Resource
     */
    inputStream?: InputStream;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {URI}
     * @memberof Resource
     */
    uri?: URI;
    /**
     * 
     * @type {URL}
     * @memberof Resource
     */
    url?: URL;
}
/**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    activeProducts?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    creationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    disabledProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    discountAmountFixed?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    discountAmountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    discountType?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    name?: string;
    /**
     * 
     * @type {Array<SaleProduct>}
     * @memberof Sale
     */
    products?: Array<SaleProduct>;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    roundingType: Sale.RoundingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    sourceSaleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    status: Sale.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    storeUid?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof Sale
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    validDiscountAmountRangeFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    validDiscountAmountRangeTo?: number;
}

/**
 * @export
 * @namespace Sale
 */
export namespace Sale {
    /**
     * @export
     * @enum {string}
     */
    export enum RoundingTypeEnum {
        _00 = <any> '00',
        _49 = <any> '49',
        _99 = <any> '99',
        NOROUNDING = <any> 'NO_ROUNDING'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        NEW = <any> 'NEW',
        ACT = <any> 'ACT',
        EXP = <any> 'EXP',
        DLT = <any> 'DLT'
    }
}
/**
 * 
 * @export
 * @interface SaleProduct
 */
export interface SaleProduct {
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof SaleProduct
     */
    discountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SaleProduct
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof SaleProduct
     */
    salePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleProduct
     */
    saleUid?: string;
}
/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    searchString?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    storeUid?: string;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    categoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    result?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    resultType?: string;
}
/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    externalAccountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    lastLoginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    phone?: string;
    /**
     * 
     * @type {Array<AccountWebResource>}
     * @memberof Seller
     */
    sellerWebResources?: Array<AccountWebResource>;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ShippingOrder
 */
export interface ShippingOrder {
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    externalOrderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    fromAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    fromCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    fromCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    fromPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    intakeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    intakeLunchFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    intakeLunchTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingOrder
     */
    intakeOrdered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    intakeTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    intakeTimeTo?: string;
    /**
     * 
     * @type {Array<ShippingOrderPackage>}
     * @memberof ShippingOrder
     */
    packages?: Array<ShippingOrderPackage>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShippingOrder
     */
    productOrderUid?: Array<string>;
    /**
     * 
     * @type {PlatformDeliveryProvider}
     * @memberof ShippingOrder
     */
    provider?: PlatformDeliveryProvider;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    toAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    toCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    toCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    toPostalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrder
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ShippingOrderIntakeRequest
 */
export interface ShippingOrderIntakeRequest {
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderIntakeRequest
     */
    intakeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderIntakeRequest
     */
    intakeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderIntakeRequest
     */
    intakeTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderIntakeRequest
     */
    lunchFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderIntakeRequest
     */
    lunchTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingOrderIntakeRequest
     */
    needCall?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShippingOrderIntakeRequest
     */
    shippingOrderUid?: Array<string>;
}
/**
 * 
 * @export
 * @interface ShippingOrderPackage
 */
export interface ShippingOrderPackage {
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackage
     */
    comments?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackage
     */
    height?: number;
    /**
     * 
     * @type {Array<ShippingOrderPackageItem>}
     * @memberof ShippingOrderPackage
     */
    items?: Array<ShippingOrderPackageItem>;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackage
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackage
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackage
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackage
     */
    width?: number;
}
/**
 * 
 * @export
 * @interface ShippingOrderPackageItem
 */
export interface ShippingOrderPackageItem {
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackageItem
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackageItem
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackageItem
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackageItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackageItem
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderPackageItem
     */
    uid?: string;
    /**
     * 
     * @type {number}
     * @memberof ShippingOrderPackageItem
     */
    weight?: number;
}
/**
 * 
 * @export
 * @interface ShippingOrderRequest
 */
export interface ShippingOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderRequest
     */
    dateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingOrderRequest
     */
    productOrderUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShippingOrderRequest
     */
    status?: Array<string>;
}
/**
 * 
 * @export
 * @interface ShortStoreInfo
 */
export interface ShortStoreInfo {
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreInfo
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface ShortStoreSearchInfo
 */
export interface ShortStoreSearchInfo {
    /**
     * 
     * @type {string}
     * @memberof ShortStoreSearchInfo
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreSearchInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreSearchInfo
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortStoreSearchInfo
     */
    maxPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortStoreSearchInfo
     */
    minPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreSearchInfo
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShortStoreSearchInfo
     */
    negotiatedPrice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShortStoreSearchInfo
     */
    storeId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortStoreSearchInfo
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    direction?: string;
    /**
     * 
     * @type {string}
     * @memberof Sorting
     */
    field?: string;
    /**
     * 
     * @type {number}
     * @memberof Sorting
     */
    sortingIndex?: number;
}
/**
 * 
 * @export
 * @interface Statistics
 */
export interface Statistics {
    /**
     * 
     * @type {Array<StatisticsCategory>}
     * @memberof Statistics
     */
    categories?: Array<StatisticsCategory>;
    /**
     * 
     * @type {Array<StatisticsCountry>}
     * @memberof Statistics
     */
    countries?: Array<StatisticsCountry>;
    /**
     * 
     * @type {Array<StatisticsEntry>}
     * @memberof Statistics
     */
    data?: Array<StatisticsEntry>;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    totalMessages?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    totalOrders?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    totalViews?: number;
    /**
     * 
     * @type {number}
     * @memberof Statistics
     */
    totalWishes?: number;
}
/**
 * 
 * @export
 * @interface StatisticsCategory
 */
export interface StatisticsCategory {
    /**
     * 
     * @type {string}
     * @memberof StatisticsCategory
     */
    categoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticsCategory
     */
    categoryName?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsCategory
     */
    percent?: number;
}
/**
 * 
 * @export
 * @interface StatisticsCountry
 */
export interface StatisticsCountry {
    /**
     * 
     * @type {string}
     * @memberof StatisticsCountry
     */
    countryName?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsCountry
     */
    percent?: number;
}
/**
 * 
 * @export
 * @interface StatisticsEntry
 */
export interface StatisticsEntry {
    /**
     * 
     * @type {string}
     * @memberof StatisticsEntry
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEntry
     */
    messages?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEntry
     */
    orders?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsEntry
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEntry
     */
    views?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsEntry
     */
    wishes?: number;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {Account}
     * @memberof Store
     */
    account?: Account;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    feedbackScore?: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    homepage?: string;
    /**
     * 
     * @type {StoreHomepage}
     * @memberof Store
     */
    homepageConfig?: StoreHomepage;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    imageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Store
     */
    license?: boolean;
    /**
     * 
     * @type {Array<StoreLink>}
     * @memberof Store
     */
    links?: Array<StoreLink>;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    originalDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    phoneCountryCode?: string;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Store
     */
    productCategories?: Array<ProductCategory>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Store
     */
    products?: Array<Product>;
    /**
     * 
     * @type {Array<StoreRating>}
     * @memberof Store
     */
    rating?: Array<StoreRating>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof Store
     */
    sales?: Array<Sale>;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    statusDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    storeLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    storeType?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof Store
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    workTimeFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    workTimeTo?: string;
}
/**
 * 
 * @export
 * @interface StoreCategoryListRequest
 */
export interface StoreCategoryListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreCategoryListRequest
     */
    productStatusList?: Array<string>;
}
/**
 * 
 * @export
 * @interface StoreCheck
 */
export interface StoreCheck {
    /**
     * 
     * @type {string}
     * @memberof StoreCheck
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCheck
     */
    codeAvailable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreCheck
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreCheck
     */
    nameAvailable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreCheck
     */
    offeredCode?: string;
}
/**
 * 
 * @export
 * @interface StoreHomepage
 */
export interface StoreHomepage {
    /**
     * 
     * @type {string}
     * @memberof StoreHomepage
     */
    accountUid?: string;
    /**
     * 
     * @type {StoreHomepageBanner}
     * @memberof StoreHomepage
     */
    banner?: StoreHomepageBanner;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepage
     */
    creationDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreHomepage
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepage
     */
    modificationDate?: string;
    /**
     * 
     * @type {Array<StoreHomepageProduct>}
     * @memberof StoreHomepage
     */
    products?: Array<StoreHomepageProduct>;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepage
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepage
     */
    storeUid?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof StoreHomepage
     */
    uid?: string;
    /**
     * 
     * @type {Array<StoreHomepageWidget>}
     * @memberof StoreHomepage
     */
    widgets?: Array<StoreHomepageWidget>;
}
/**
 * 
 * @export
 * @interface StoreHomepageBanner
 */
export interface StoreHomepageBanner {
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    desktopImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    mobileImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    targetUrl: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageBanner
     */
    title: string;
}
/**
 * 
 * @export
 * @interface StoreHomepageProduct
 */
export interface StoreHomepageProduct {
    /**
     * 
     * @type {boolean}
     * @memberof StoreHomepageProduct
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    discount?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    imageThumbnailUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    productCurrencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    productCurrencySymbol?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageProduct
     */
    productDiscountedPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    productName?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageProduct
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageProduct
     */
    productSalePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageProduct
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageProduct
     */
    storeHomepageUid?: string;
}
/**
 * 
 * @export
 * @interface StoreHomepageProductsRequest
 */
export interface StoreHomepageProductsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreHomepageProductsRequest
     */
    productUid?: Array<string>;
}
/**
 * 
 * @export
 * @interface StoreHomepageWidget
 */
export interface StoreHomepageWidget {
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    modificationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    rootCategoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    rootCategoryName?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageWidget
     */
    saleDiscountAmountFixed?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageWidget
     */
    saleDiscountAmountPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    saleEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    saleName?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    saleStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    saleUid?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreHomepageWidget
     */
    sortOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    storeHomepageUid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    type?: string;
    /**
     * UID. Required for update operations only.
     * @type {string}
     * @memberof StoreHomepageWidget
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface StoreLink
 */
export interface StoreLink {
    /**
     * 
     * @type {string}
     * @memberof StoreLink
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreLink
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface StoreListRequest
 */
export interface StoreListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreListRequest
     */
    codeList?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreListRequest
     */
    uidList?: Array<string>;
}
/**
 * 
 * @export
 * @interface StoreRating
 */
export interface StoreRating {
    /**
     * 
     * @type {number}
     * @memberof StoreRating
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreRating
     */
    ratingPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreRating
     */
    reviewCount?: number;
}
/**
 * 
 * @export
 * @interface StoreRatingRequest
 */
export interface StoreRatingRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreRatingRequest
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreRatingRequest
     */
    dateTo?: string;
}
/**
 * 
 * @export
 * @interface StoreRatingResponse
 */
export interface StoreRatingResponse {
    /**
     * 
     * @type {string}
     * @memberof StoreRatingResponse
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreRatingResponse
     */
    dateTo?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreRatingResponse
     */
    newReviewCount?: number;
    /**
     * 
     * @type {Array<StoreRating>}
     * @memberof StoreRatingResponse
     */
    rating?: Array<StoreRating>;
    /**
     * 
     * @type {string}
     * @memberof StoreRatingResponse
     */
    storeUid?: string;
}
/**
 * 
 * @export
 * @interface StoreSearchListRequest
 */
export interface StoreSearchListRequest {
    /**
     * 
     * @type {ProductAddress}
     * @memberof StoreSearchListRequest
     */
    address?: ProductAddress;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    allowedCountryCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearchListRequest
     */
    autoHashtags?: boolean;
    /**
     * Product category OR product list type (new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers)
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    coordinates?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    currencyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    dealType?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    discountedPriceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    discountedPriceTo?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    distance?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    expirationDate?: string;
    /**
     * 
     * @type {Array<CharacteristicFilter>}
     * @memberof StoreSearchListRequest
     */
    filters?: Array<CharacteristicFilter>;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    fullAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    hashtags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearchListRequest
     */
    inStock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    minProductRating?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    offset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearchListRequest
     */
    pickupAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    priceFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreSearchListRequest
     */
    priceTo?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    promotionPageUid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    publishDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearchListRequest
     */
    returnAccepted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    saleUid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    sellerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    sellerPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    sellerUid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreSearchListRequest
     */
    shippingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    storeUid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchListRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface StoreSearchRequest
 */
export interface StoreSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    accountUid?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    createdFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    createdTo?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    storeType?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSearchRequest
     */
    uid?: string;
}
/**
 * 
 * @export
 * @interface SubscribeRequest
 */
export interface SubscribeRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscribeRequest
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    accountUid?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Ticket
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    companyName?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Ticket
     */
    customParameters?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    subject?: string;
}
/**
 * 
 * @export
 * @interface URI
 */
export interface URI {
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    absolute?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    fragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    host?: string;
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    opaque?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URI
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawAuthority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawFragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawPath?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawQuery?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawSchemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawUserInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    schemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    userInfo?: string;
}
/**
 * 
 * @export
 * @interface URL
 */
export interface URL {
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    authority?: string;
    /**
     * 
     * @type {any}
     * @memberof URL
     */
    content?: any;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    defaultPort?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    ref?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    userInfo?: string;
}
/**
 * 
 * @export
 * @interface UidRequest
 */
export interface UidRequest {
    /**
     * 
     * @type {string}
     * @memberof UidRequest
     */
    uid: string;
}
/**
 * 
 * @export
 * @interface UnreviewedProduct
 */
export interface UnreviewedProduct {
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    orderUid?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    productDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    productImageThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    productStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof UnreviewedProduct
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof UnreviewedProduct
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof UnreviewedProduct
     */
    reviewCount?: number;
}
/**
 * 
 * @export
 * @interface UploadBase64Body
 */
export interface UploadBase64Body {
    /**
     * base64File
     * @type {string}
     * @memberof UploadBase64Body
     */
    base64File: string;
    /**
     * bucket
     * @type {string}
     * @memberof UploadBase64Body
     */
    bucket?: string;
    /**
     * fileName
     * @type {string}
     * @memberof UploadBase64Body
     */
    fileName: string;
}
/**
 * 
 * @export
 * @interface UploadBase64Body1
 */
export interface UploadBase64Body1 {
    /**
     * base64File
     * @type {string}
     * @memberof UploadBase64Body1
     */
    base64File: string;
    /**
     * fileName
     * @type {string}
     * @memberof UploadBase64Body1
     */
    fileName: string;
    /**
     * language
     * @type {string}
     * @memberof UploadBase64Body1
     */
    language?: string;
}
/**
 * 
 * @export
 * @interface UploadMultipartBody
 */
export interface UploadMultipartBody {
    /**
     * bucket
     * @type {any}
     * @memberof UploadMultipartBody
     */
    bucket?: any;
    /**
     * file
     * @type {Blob}
     * @memberof UploadMultipartBody
     */
    file: Blob;
}
/**
 * 
 * @export
 * @interface UploadMultipartBody1
 */
export interface UploadMultipartBody1 {
    /**
     * file
     * @type {Blob}
     * @memberof UploadMultipartBody1
     */
    file: Blob;
    /**
     * language
     * @type {any}
     * @memberof UploadMultipartBody1
     */
    language?: any;
}
/**
 * 
 * @export
 * @interface UploadedImage
 */
export interface UploadedImage {
    /**
     * 
     * @type {string}
     * @memberof UploadedImage
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ValidateProductForSaleRequest
 */
export interface ValidateProductForSaleRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateProductForSaleRequest
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateProductForSaleRequest
     */
    productUid?: string;
    /**
     * 
     * @type {number}
     * @memberof ValidateProductForSaleRequest
     */
    salePrice?: number;
}
/**
 * 
 * @export
 * @interface VideoContent
 */
export interface VideoContent {
    /**
     * 
     * @type {string}
     * @memberof VideoContent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof VideoContent
     */
    type: VideoContent.TypeEnum;
}

/**
 * @export
 * @namespace VideoContent
 */
export namespace VideoContent {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        YOUTUBE = <any> 'YOUTUBE',
        VIMEO = <any> 'VIMEO',
        VK = <any> 'VK'
    }
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    contentType?: string;
}
/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
export const AccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/account`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccount> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).getAccountUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET(options?: any) {
            return AccountControllerApiFp(configuration).getAccountUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public getAccountUsingGET(options?: any) {
        return AccountControllerApiFp(this.configuration).getAccountUsingGET(options)(this.fetch, this.basePath);
    }

}
/**
 * AdminAccountControllerApi - fetch parameter creator
 * @export
 */
export const AdminAccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET1(uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getAccountUsingGET1.');
            }
            const localVarPath = `/admin/account/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle allowed-sell-using-market parameter for account
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAllowedSellUsingMarketUsingPATCH(uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling toggleAllowedSellUsingMarketUsingPATCH.');
            }
            const localVarPath = `/admin/account/{uid}/toggle-allowed-sell-using-market`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAccountControllerApi - functional programming interface
 * @export
 */
export const AdminAccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET1(uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccount> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).getAccountUsingGET1(uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Toggle allowed-sell-using-market parameter for account
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAllowedSellUsingMarketUsingPATCH(uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccount> {
            const localVarFetchArgs = AdminAccountControllerApiFetchParamCreator(configuration).toggleAllowedSellUsingMarketUsingPATCH(uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminAccountControllerApi - factory interface
 * @export
 */
export const AdminAccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account data
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountUsingGET1(uid: string, options?: any) {
            return AdminAccountControllerApiFp(configuration).getAccountUsingGET1(uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Toggle allowed-sell-using-market parameter for account
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleAllowedSellUsingMarketUsingPATCH(uid: string, options?: any) {
            return AdminAccountControllerApiFp(configuration).toggleAllowedSellUsingMarketUsingPATCH(uid, options)(fetch, basePath);
        },
    };
};

/**
 * AdminAccountControllerApi - object-oriented interface
 * @export
 * @class AdminAccountControllerApi
 * @extends {BaseAPI}
 */
export class AdminAccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account data
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public getAccountUsingGET1(uid: string, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).getAccountUsingGET1(uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Toggle allowed-sell-using-market parameter for account
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAccountControllerApi
     */
    public toggleAllowedSellUsingMarketUsingPATCH(uid: string, options?: any) {
        return AdminAccountControllerApiFp(this.configuration).toggleAllowedSellUsingMarketUsingPATCH(uid, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminArticleControllerApi - fetch parameter creator
 * @export
 */
export const AdminArticleControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete article
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(body: UidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteUsingDELETE.');
            }
            const localVarPath = `/admin/article`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUsingPUT(body: Article, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling insertUsingPUT.');
            }
            const localVarPath = `/admin/article`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Article" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: Article, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUsingPOST.');
            }
            const localVarPath = `/admin/article`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Article" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminArticleControllerApi - functional programming interface
 * @export
 */
export const AdminArticleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete article
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(body: UidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseArticle> {
            const localVarFetchArgs = AdminArticleControllerApiFetchParamCreator(configuration).deleteUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Insert article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUsingPUT(body: Article, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseArticle> {
            const localVarFetchArgs = AdminArticleControllerApiFetchParamCreator(configuration).insertUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: Article, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseArticle> {
            const localVarFetchArgs = AdminArticleControllerApiFetchParamCreator(configuration).updateUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminArticleControllerApi - factory interface
 * @export
 */
export const AdminArticleControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Delete article
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(body: UidRequest, options?: any) {
            return AdminArticleControllerApiFp(configuration).deleteUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Insert article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertUsingPUT(body: Article, options?: any) {
            return AdminArticleControllerApiFp(configuration).insertUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update article
         * @param {Article} body article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPOST(body: Article, options?: any) {
            return AdminArticleControllerApiFp(configuration).updateUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * AdminArticleControllerApi - object-oriented interface
 * @export
 * @class AdminArticleControllerApi
 * @extends {BaseAPI}
 */
export class AdminArticleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete article
     * @param {UidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArticleControllerApi
     */
    public deleteUsingDELETE(body: UidRequest, options?: any) {
        return AdminArticleControllerApiFp(this.configuration).deleteUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Insert article
     * @param {Article} body article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArticleControllerApi
     */
    public insertUsingPUT(body: Article, options?: any) {
        return AdminArticleControllerApiFp(this.configuration).insertUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update article
     * @param {Article} body article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminArticleControllerApi
     */
    public updateUsingPOST(body: Article, options?: any) {
        return AdminArticleControllerApiFp(this.configuration).updateUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminControllerApi - fetch parameter creator
 * @export
 */
export const AdminControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search account stores
         * @param {StoreSearchRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingPOST(body: StoreSearchRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountStoreListUsingPOST.');
            }
            const localVarPath = `/admin/store/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreSearchRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT(body: ProductSource, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductSourceUsingPUT.');
            }
            const localVarPath = `/admin/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add promotion page banner
         * @param {PromotionPageBanner} body banner
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageBannerUsingPUT(body: PromotionPageBanner, pageUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPromotionPageBannerUsingPUT.');
            }
            // verify required parameter 'pageUid' is not null or undefined
            if (pageUid === null || pageUid === undefined) {
                throw new RequiredError('pageUid','Required parameter pageUid was null or undefined when calling addPromotionPageBannerUsingPUT.');
            }
            const localVarPath = `/admin/promotionpage/{pageUid}/banner`
                .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PromotionPageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add promotion page product
         * @param {AddPromotionPageProductRequest} body request
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageProductUsingPUT(body: AddPromotionPageProductRequest, pageUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPromotionPageProductUsingPUT.');
            }
            // verify required parameter 'pageUid' is not null or undefined
            if (pageUid === null || pageUid === undefined) {
                throw new RequiredError('pageUid','Required parameter pageUid was null or undefined when calling addPromotionPageProductUsingPUT.');
            }
            const localVarPath = `/admin/promotionpage/{pageUid}/product`
                .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddPromotionPageProductRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create promotion page
         * @param {AddPromotionPageRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageUsingPUT(body: AddPromotionPageRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPromotionPageUsingPUT.');
            }
            const localVarPath = `/admin/promotionpage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddPromotionPageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE(body: UidRequest, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteProductSourceUsingDELETE.');
            }
            const localVarPath = `/admin/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete promotion page banner
         * @param {string} bannerUid bannerUid
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bannerUid' is not null or undefined
            if (bannerUid === null || bannerUid === undefined) {
                throw new RequiredError('bannerUid','Required parameter bannerUid was null or undefined when calling deletePromotionPageBannerUsingDELETE.');
            }
            // verify required parameter 'pageUid' is not null or undefined
            if (pageUid === null || pageUid === undefined) {
                throw new RequiredError('pageUid','Required parameter pageUid was null or undefined when calling deletePromotionPageBannerUsingDELETE.');
            }
            const localVarPath = `/admin/promotionpage/{pageUid}/banner/{bannerUid}`
                .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)))
                .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete promotion page product
         * @param {string} pageUid pageUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'pageUid' is not null or undefined
            if (pageUid === null || pageUid === undefined) {
                throw new RequiredError('pageUid','Required parameter pageUid was null or undefined when calling deletePromotionPageProductUsingDELETE.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling deletePromotionPageProductUsingDELETE.');
            }
            const localVarPath = `/admin/promotionpage/{pageUid}/product/{productUid}`
                .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)))
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete promotion page
         * @param {string} pageUid pageUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageUsingDELETE(pageUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'pageUid' is not null or undefined
            if (pageUid === null || pageUid === undefined) {
                throw new RequiredError('pageUid','Required parameter pageUid was null or undefined when calling deletePromotionPageUsingDELETE.');
            }
            const localVarPath = `/admin/promotionpage/{pageUid}`
                .replace(`{${"pageUid"}}`, encodeURIComponent(String(pageUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product review details
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewDetailsUsingGET(reviewUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'reviewUid' is not null or undefined
            if (reviewUid === null || reviewUid === undefined) {
                throw new RequiredError('reviewUid','Required parameter reviewUid was null or undefined when calling getProductReviewDetailsUsingGET.');
            }
            const localVarPath = `/admin/product/review/{reviewUid}`
                .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product review list
         * @param {ProductReviewListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingPOST(body: ProductReviewListRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getProductReviewsUsingPOST.');
            }
            const localVarPath = `/admin/product/review`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductReviewListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling getProductSourceUsingGET.');
            }
            const localVarPath = `/admin/product/import/source/{sourceUid}`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling productSourceHistoryUsingGET.');
            }
            const localVarPath = `/admin/product/import/source/{sourceUid}/history`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET(accountUid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/admin/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account store
         * @param {Store} body store
         * @param {string} [accountUid] accountUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountStoreUsingPOST(body: Store, accountUid?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountStoreUsingPOST.');
            }
            const localVarPath = `/admin/store`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Store" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product review status
         * @param {ProductReviewStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductReviewStatusUsingPOST(body: ProductReviewStatusRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductReviewStatusUsingPOST.');
            }
            const localVarPath = `/admin/product/review/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductReviewStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST(body: ProductSource, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSourceUsingPOST.');
            }
            const localVarPath = `/admin/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST(body: ProductStatusRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductStatusUsingPOST.');
            }
            const localVarPath = `/admin/product/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling validateProductSourceUsingGET.');
            }
            const localVarPath = `/admin/product/import/source/{sourceUid}/validate`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminControllerApi - functional programming interface
 * @export
 */
export const AdminControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search account stores
         * @param {StoreSearchRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingPOST(body: StoreSearchRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).accountStoreListUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT(body: ProductSource, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addProductSourceUsingPUT(body, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add promotion page banner
         * @param {PromotionPageBanner} body banner
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageBannerUsingPUT(body: PromotionPageBanner, pageUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageBannerUsingPUT(body, pageUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add promotion page product
         * @param {AddPromotionPageProductRequest} body request
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageProductUsingPUT(body: AddPromotionPageProductRequest, pageUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageProductUsingPUT(body, pageUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create promotion page
         * @param {AddPromotionPageRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageUsingPUT(body: AddPromotionPageRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).addPromotionPageUsingPUT(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE(body: UidRequest, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deleteProductSourceUsingDELETE(body, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete promotion page banner
         * @param {string} bannerUid bannerUid
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deletePromotionPageBannerUsingDELETE(bannerUid, pageUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete promotion page product
         * @param {string} pageUid pageUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deletePromotionPageProductUsingDELETE(pageUid, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete promotion page
         * @param {string} pageUid pageUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageUsingDELETE(pageUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).deletePromotionPageUsingDELETE(pageUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product review details
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewDetailsUsingGET(reviewUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).getProductReviewDetailsUsingGET(reviewUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product review list
         * @param {ProductReviewListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingPOST(body: ProductReviewListRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).getProductReviewsUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).getProductSourceUsingGET(sourceUid, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).productSourceHistoryUsingGET(sourceUid, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET(accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).productSourceListUsingGET(accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update account store
         * @param {Store} body store
         * @param {string} [accountUid] accountUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountStoreUsingPOST(body: Store, accountUid?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateAccountStoreUsingPOST(body, accountUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product review status
         * @param {ProductReviewStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductReviewStatusUsingPOST(body: ProductReviewStatusRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateProductReviewStatusUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST(body: ProductSource, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateProductSourceUsingPOST(body, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST(body: ProductStatusRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).updateProductStatusUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
            const localVarFetchArgs = AdminControllerApiFetchParamCreator(configuration).validateProductSourceUsingGET(sourceUid, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminControllerApi - factory interface
 * @export
 */
export const AdminControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Search account stores
         * @param {StoreSearchRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingPOST(body: StoreSearchRequest, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).accountStoreListUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT(body: ProductSource, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).addProductSourceUsingPUT(body, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add promotion page banner
         * @param {PromotionPageBanner} body banner
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageBannerUsingPUT(body: PromotionPageBanner, pageUid: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).addPromotionPageBannerUsingPUT(body, pageUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add promotion page product
         * @param {AddPromotionPageProductRequest} body request
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageProductUsingPUT(body: AddPromotionPageProductRequest, pageUid: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).addPromotionPageProductUsingPUT(body, pageUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create promotion page
         * @param {AddPromotionPageRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionPageUsingPUT(body: AddPromotionPageRequest, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).addPromotionPageUsingPUT(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE(body: UidRequest, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).deleteProductSourceUsingDELETE(body, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete promotion page banner
         * @param {string} bannerUid bannerUid
         * @param {string} pageUid pageUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).deletePromotionPageBannerUsingDELETE(bannerUid, pageUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete promotion page product
         * @param {string} pageUid pageUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).deletePromotionPageProductUsingDELETE(pageUid, productUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete promotion page
         * @param {string} pageUid pageUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionPageUsingDELETE(pageUid: string, options?: any) {
            return AdminControllerApiFp(configuration).deletePromotionPageUsingDELETE(pageUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product review details
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewDetailsUsingGET(reviewUid: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).getProductReviewDetailsUsingGET(reviewUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product review list
         * @param {ProductReviewListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingPOST(body: ProductReviewListRequest, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).getProductReviewsUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).getProductSourceUsingGET(sourceUid, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).productSourceHistoryUsingGET(sourceUid, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET(accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).productSourceListUsingGET(accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update account store
         * @param {Store} body store
         * @param {string} [accountUid] accountUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountStoreUsingPOST(body: Store, accountUid?: string, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).updateAccountStoreUsingPOST(body, accountUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product review status
         * @param {ProductReviewStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductReviewStatusUsingPOST(body: ProductReviewStatusRequest, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).updateProductReviewStatusUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST(body: ProductSource, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).updateProductSourceUsingPOST(body, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST(body: ProductStatusRequest, language?: string, options?: any) {
            return AdminControllerApiFp(configuration).updateProductStatusUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {string} [accountUid] accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
            return AdminControllerApiFp(configuration).validateProductSourceUsingGET(sourceUid, accountUid, options)(fetch, basePath);
        },
    };
};

/**
 * AdminControllerApi - object-oriented interface
 * @export
 * @class AdminControllerApi
 * @extends {BaseAPI}
 */
export class AdminControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search account stores
     * @param {StoreSearchRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public accountStoreListUsingPOST(body: StoreSearchRequest, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).accountStoreListUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new product sources
     * @param {ProductSource} body source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public addProductSourceUsingPUT(body: ProductSource, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).addProductSourceUsingPUT(body, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add promotion page banner
     * @param {PromotionPageBanner} body banner
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public addPromotionPageBannerUsingPUT(body: PromotionPageBanner, pageUid: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).addPromotionPageBannerUsingPUT(body, pageUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add promotion page product
     * @param {AddPromotionPageProductRequest} body request
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public addPromotionPageProductUsingPUT(body: AddPromotionPageProductRequest, pageUid: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).addPromotionPageProductUsingPUT(body, pageUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create promotion page
     * @param {AddPromotionPageRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public addPromotionPageUsingPUT(body: AddPromotionPageRequest, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).addPromotionPageUsingPUT(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete existing product sources
     * @param {UidRequest} body request
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deleteProductSourceUsingDELETE(body: UidRequest, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).deleteProductSourceUsingDELETE(body, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete promotion page banner
     * @param {string} bannerUid bannerUid
     * @param {string} pageUid pageUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deletePromotionPageBannerUsingDELETE(bannerUid: string, pageUid: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).deletePromotionPageBannerUsingDELETE(bannerUid, pageUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete promotion page product
     * @param {string} pageUid pageUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deletePromotionPageProductUsingDELETE(pageUid: string, productUid: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).deletePromotionPageProductUsingDELETE(pageUid, productUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete promotion page
     * @param {string} pageUid pageUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public deletePromotionPageUsingDELETE(pageUid: string, options?: any) {
        return AdminControllerApiFp(this.configuration).deletePromotionPageUsingDELETE(pageUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product review details
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProductReviewDetailsUsingGET(reviewUid: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getProductReviewDetailsUsingGET(reviewUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product review list
     * @param {ProductReviewListRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProductReviewsUsingPOST(body: ProductReviewListRequest, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getProductReviewsUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public getProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).getProductSourceUsingGET(sourceUid, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public productSourceHistoryUsingGET(sourceUid: string, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).productSourceHistoryUsingGET(sourceUid, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of product sources
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public productSourceListUsingGET(accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).productSourceListUsingGET(accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update account store
     * @param {Store} body store
     * @param {string} [accountUid] accountUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateAccountStoreUsingPOST(body: Store, accountUid?: string, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).updateAccountStoreUsingPOST(body, accountUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product review status
     * @param {ProductReviewStatusRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateProductReviewStatusUsingPOST(body: ProductReviewStatusRequest, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).updateProductReviewStatusUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing product sources
     * @param {ProductSource} body source
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateProductSourceUsingPOST(body: ProductSource, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).updateProductSourceUsingPOST(body, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product status
     * @param {ProductStatusRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public updateProductStatusUsingPOST(body: ProductStatusRequest, language?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).updateProductStatusUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {string} [accountUid] accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminControllerApi
     */
    public validateProductSourceUsingGET(sourceUid: string, accountUid?: string, options?: any) {
        return AdminControllerApiFp(this.configuration).validateProductSourceUsingGET(sourceUid, accountUid, options)(this.fetch, this.basePath);
    }

}
/**
 * AdminProductControllerApi - fetch parameter creator
 * @export
 */
export const AdminProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert and replace product images
         * @param {string} uid uid
         * @param {boolean} [deleteAfterReplace] deleteAfterReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAndReplaceImagesUsingPOST(uid: string, deleteAfterReplace?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling convertAndReplaceImagesUsingPOST.');
            }
            const localVarPath = `/admin/product/{uid}/convert-and-replace-images`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deleteAfterReplace !== undefined) {
                localVarQueryParameter['deleteAfterReplace'] = deleteAfterReplace;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductControllerApi - functional programming interface
 * @export
 */
export const AdminProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert and replace product images
         * @param {string} uid uid
         * @param {boolean} [deleteAfterReplace] deleteAfterReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAndReplaceImagesUsingPOST(uid: string, deleteAfterReplace?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = AdminProductControllerApiFetchParamCreator(configuration).convertAndReplaceImagesUsingPOST(uid, deleteAfterReplace, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminProductControllerApi - factory interface
 * @export
 */
export const AdminProductControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Convert and replace product images
         * @param {string} uid uid
         * @param {boolean} [deleteAfterReplace] deleteAfterReplace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertAndReplaceImagesUsingPOST(uid: string, deleteAfterReplace?: boolean, options?: any) {
            return AdminProductControllerApiFp(configuration).convertAndReplaceImagesUsingPOST(uid, deleteAfterReplace, options)(fetch, basePath);
        },
    };
};

/**
 * AdminProductControllerApi - object-oriented interface
 * @export
 * @class AdminProductControllerApi
 * @extends {BaseAPI}
 */
export class AdminProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Convert and replace product images
     * @param {string} uid uid
     * @param {boolean} [deleteAfterReplace] deleteAfterReplace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductControllerApi
     */
    public convertAndReplaceImagesUsingPOST(uid: string, deleteAfterReplace?: boolean, options?: any) {
        return AdminProductControllerApiFp(this.configuration).convertAndReplaceImagesUsingPOST(uid, deleteAfterReplace, options)(this.fetch, this.basePath);
    }

}
/**
 * BannerControllerApi - fetch parameter creator
 * @export
 */
export const BannerControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET(channel: string, count?: number, label?: Array<string>, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling bannerListUsingGET.');
            }
            const localVarPath = `/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (label) {
                localVarQueryParameter['label'] = label;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bannerUid' is not null or undefined
            if (bannerUid === null || bannerUid === undefined) {
                throw new RequiredError('bannerUid','Required parameter bannerUid was null or undefined when calling processManualClickUsingGET.');
            }
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling processManualClickUsingGET.');
            }
            const localVarPath = `/banner/{bannerUid}/click`
                .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerControllerApi - functional programming interface
 * @export
 */
export const BannerControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET(channel: string, count?: number, label?: Array<string>, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
            const localVarFetchArgs = BannerControllerApiFetchParamCreator(configuration).bannerListUsingGET(channel, count, label, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
            const localVarFetchArgs = BannerControllerApiFetchParamCreator(configuration).processManualClickUsingGET(bannerUid, channel, refId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerControllerApi - factory interface
 * @export
 */
export const BannerControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET(channel: string, count?: number, label?: Array<string>, language?: string, options?: any) {
            return BannerControllerApiFp(configuration).bannerListUsingGET(channel, count, label, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options?: any) {
            return BannerControllerApiFp(configuration).processManualClickUsingGET(bannerUid, channel, refId, options)(fetch, basePath);
        },
    };
};

/**
 * BannerControllerApi - object-oriented interface
 * @export
 * @class BannerControllerApi
 * @extends {BaseAPI}
 */
export class BannerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {Array<string>} [label] label
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerControllerApi
     */
    public bannerListUsingGET(channel: string, count?: number, label?: Array<string>, language?: string, options?: any) {
        return BannerControllerApiFp(this.configuration).bannerListUsingGET(channel, count, label, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerControllerApi
     */
    public processManualClickUsingGET(bannerUid: string, channel: string, refId?: string, options?: any) {
        return BannerControllerApiFp(this.configuration).processManualClickUsingGET(bannerUid, channel, refId, options)(this.fetch, this.basePath);
    }

}
/**
 * BannerProxyControllerApi - fetch parameter creator
 * @export
 */
export const BannerProxyControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bannerUid' is not null or undefined
            if (bannerUid === null || bannerUid === undefined) {
                throw new RequiredError('bannerUid','Required parameter bannerUid was null or undefined when calling processClickUsingGET.');
            }
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling processClickUsingGET.');
            }
            const localVarPath = `/banner/{bannerUid}`
                .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bannerUid' is not null or undefined
            if (bannerUid === null || bannerUid === undefined) {
                throw new RequiredError('bannerUid','Required parameter bannerUid was null or undefined when calling processClickUsingPOST.');
            }
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling processClickUsingPOST.');
            }
            const localVarPath = `/banner/{bannerUid}`
                .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerProxyControllerApi - functional programming interface
 * @export
 */
export const BannerProxyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BannerProxyControllerApiFetchParamCreator(configuration).processClickUsingGET(bannerUid, channel, accountUid, refId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = BannerProxyControllerApiFetchParamCreator(configuration).processClickUsingPOST(bannerUid, channel, accountUid, refId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BannerProxyControllerApi - factory interface
 * @export
 */
export const BannerProxyControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
            return BannerProxyControllerApiFp(configuration).processClickUsingGET(bannerUid, channel, accountUid, refId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Public proxy service to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
            return BannerProxyControllerApiFp(configuration).processClickUsingPOST(bannerUid, channel, accountUid, refId, options)(fetch, basePath);
        },
    };
};

/**
 * BannerProxyControllerApi - object-oriented interface
 * @export
 * @class BannerProxyControllerApi
 * @extends {BaseAPI}
 */
export class BannerProxyControllerApi extends BaseAPI {
    /**
     * 
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerProxyControllerApi
     */
    public processClickUsingGET(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
        return BannerProxyControllerApiFp(this.configuration).processClickUsingGET(bannerUid, channel, accountUid, refId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Public proxy service to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerProxyControllerApi
     */
    public processClickUsingPOST(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
        return BannerProxyControllerApiFp(this.configuration).processClickUsingPOST(bannerUid, channel, accountUid, refId, options)(this.fetch, this.basePath);
    }

}
/**
 * BasicErrorControllerApi - fetch parameter creator
 * @export
 */
export const BasicErrorControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'HEAD' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'OPTIONS' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingHEAD(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingOPTIONS(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPUT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingHEAD(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingOPTIONS(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPUT(options)(fetch, basePath);
        },
    };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingDELETE(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingGET(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingHEAD(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingHEAD(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingOPTIONS(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingOPTIONS(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPATCH(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPOST(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPUT(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPUT(options)(this.fetch, this.basePath);
    }

}
/**
 * CallbackControllerApi - fetch parameter creator
 * @export
 */
export const CallbackControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CDEK webhook endpoint
         * @param {CDEKWebhook} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdekCallbackUsingPOST(body: CDEKWebhook, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cdekCallbackUsingPOST.');
            }
            const localVarPath = `/callback/cdek`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CDEKWebhook" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallbackControllerApi - functional programming interface
 * @export
 */
export const CallbackControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CDEK webhook endpoint
         * @param {CDEKWebhook} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdekCallbackUsingPOST(body: CDEKWebhook, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryCity> {
            const localVarFetchArgs = CallbackControllerApiFetchParamCreator(configuration).cdekCallbackUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CallbackControllerApi - factory interface
 * @export
 */
export const CallbackControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary CDEK webhook endpoint
         * @param {CDEKWebhook} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cdekCallbackUsingPOST(body: CDEKWebhook, options?: any) {
            return CallbackControllerApiFp(configuration).cdekCallbackUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * CallbackControllerApi - object-oriented interface
 * @export
 * @class CallbackControllerApi
 * @extends {BaseAPI}
 */
export class CallbackControllerApi extends BaseAPI {
    /**
     * 
     * @summary CDEK webhook endpoint
     * @param {CDEKWebhook} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallbackControllerApi
     */
    public cdekCallbackUsingPOST(body: CDEKWebhook, options?: any) {
        return CallbackControllerApiFp(this.configuration).cdekCallbackUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CartControllerApi - fetch parameter creator
 * @export
 */
export const CartControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new item to cart or update existing item
         * @param {CartItem} body item
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemToCartUsingPOST(body: CartItem, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addItemToCartUsingPOST.');
            }
            const localVarPath = `/cart/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CartItem" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new items to cart or update existing items
         * @param {Array<CartItem>} body items
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemsToCartUsingPOST(body: Array<CartItem>, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addItemsToCartUsingPOST.');
            }
            const localVarPath = `/cart/items/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;CartItem&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate delivery price based on cartUid or product list
         * @param {CalculateDeliveryRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST(body: CalculateDeliveryRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling calculateDeliveryUsingPOST.');
            }
            const localVarPath = `/cart/calculateDelivery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CalculateDeliveryRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCartUsingDELETE(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling deleteCartUsingDELETE.');
            }
            const localVarPath = `/cart/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account cart
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCartUsingGET(country?: string, language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/cart`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getCartUsingGET.');
            }
            const localVarPath = `/cart/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove item from cart
         * @param {RemoveCartItemRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeItemFromCartUsingPOST(body: RemoveCartItemRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeItemFromCartUsingPOST.');
            }
            const localVarPath = `/cart/remove`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RemoveCartItemRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartControllerApi - functional programming interface
 * @export
 */
export const CartControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new item to cart or update existing item
         * @param {CartItem} body item
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemToCartUsingPOST(body: CartItem, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).addItemToCartUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new items to cart or update existing items
         * @param {Array<CartItem>} body items
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemsToCartUsingPOST(body: Array<CartItem>, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).addItemsToCartUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Calculate delivery price based on cartUid or product list
         * @param {CalculateDeliveryRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST(body: CalculateDeliveryRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCalculateDeliveryResponse> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).calculateDeliveryUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCartUsingDELETE(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).deleteCartUsingDELETE(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account cart
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCartUsingGET(country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).getAccountCartUsingGET(country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).getCartUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove item from cart
         * @param {RemoveCartItemRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeItemFromCartUsingPOST(body: RemoveCartItemRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCart> {
            const localVarFetchArgs = CartControllerApiFetchParamCreator(configuration).removeItemFromCartUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CartControllerApi - factory interface
 * @export
 */
export const CartControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add new item to cart or update existing item
         * @param {CartItem} body item
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemToCartUsingPOST(body: CartItem, country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).addItemToCartUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new items to cart or update existing items
         * @param {Array<CartItem>} body items
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemsToCartUsingPOST(body: Array<CartItem>, country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).addItemsToCartUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Calculate delivery price based on cartUid or product list
         * @param {CalculateDeliveryRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST(body: CalculateDeliveryRequest, language?: string, options?: any) {
            return CartControllerApiFp(configuration).calculateDeliveryUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCartUsingDELETE(uid: string, country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).deleteCartUsingDELETE(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account cart
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCartUsingGET(country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).getAccountCartUsingGET(country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account cart by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).getCartUsingGET(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove item from cart
         * @param {RemoveCartItemRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeItemFromCartUsingPOST(body: RemoveCartItemRequest, country?: string, language?: string, options?: any) {
            return CartControllerApiFp(configuration).removeItemFromCartUsingPOST(body, country, language, options)(fetch, basePath);
        },
    };
};

/**
 * CartControllerApi - object-oriented interface
 * @export
 * @class CartControllerApi
 * @extends {BaseAPI}
 */
export class CartControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new item to cart or update existing item
     * @param {CartItem} body item
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public addItemToCartUsingPOST(body: CartItem, country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).addItemToCartUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new items to cart or update existing items
     * @param {Array<CartItem>} body items
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public addItemsToCartUsingPOST(body: Array<CartItem>, country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).addItemsToCartUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Calculate delivery price based on cartUid or product list
     * @param {CalculateDeliveryRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public calculateDeliveryUsingPOST(body: CalculateDeliveryRequest, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).calculateDeliveryUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public deleteCartUsingDELETE(uid: string, country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).deleteCartUsingDELETE(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account cart
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public getAccountCartUsingGET(country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).getAccountCartUsingGET(country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account cart by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public getCartUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).getCartUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove item from cart
     * @param {RemoveCartItemRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartControllerApi
     */
    public removeItemFromCartUsingPOST(body: RemoveCartItemRequest, country?: string, language?: string, options?: any) {
        return CartControllerApiFp(this.configuration).removeItemFromCartUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

}
/**
 * CharacteristicControllerApi - fetch parameter creator
 * @export
 */
export const CharacteristicControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get characteristic list
         * @param {string} categoryCode categoryCode
         * @param {boolean} [filters] filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCharacteristicsListUsingGET(categoryCode: string, filters?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'categoryCode' is not null or undefined
            if (categoryCode === null || categoryCode === undefined) {
                throw new RequiredError('categoryCode','Required parameter categoryCode was null or undefined when calling getAllCharacteristicsListUsingGET.');
            }
            const localVarPath = `/public/characteristics/list/{categoryCode}`
                .replace(`{${"categoryCode"}}`, encodeURIComponent(String(categoryCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacteristicControllerApi - functional programming interface
 * @export
 */
export const CharacteristicControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get characteristic list
         * @param {string} categoryCode categoryCode
         * @param {boolean} [filters] filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCharacteristicsListUsingGET(categoryCode: string, filters?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCharacteristic> {
            const localVarFetchArgs = CharacteristicControllerApiFetchParamCreator(configuration).getAllCharacteristicsListUsingGET(categoryCode, filters, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CharacteristicControllerApi - factory interface
 * @export
 */
export const CharacteristicControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get characteristic list
         * @param {string} categoryCode categoryCode
         * @param {boolean} [filters] filters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCharacteristicsListUsingGET(categoryCode: string, filters?: boolean, options?: any) {
            return CharacteristicControllerApiFp(configuration).getAllCharacteristicsListUsingGET(categoryCode, filters, options)(fetch, basePath);
        },
    };
};

/**
 * CharacteristicControllerApi - object-oriented interface
 * @export
 * @class CharacteristicControllerApi
 * @extends {BaseAPI}
 */
export class CharacteristicControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get characteristic list
     * @param {string} categoryCode categoryCode
     * @param {boolean} [filters] filters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacteristicControllerApi
     */
    public getAllCharacteristicsListUsingGET(categoryCode: string, filters?: boolean, options?: any) {
        return CharacteristicControllerApiFp(this.configuration).getAllCharacteristicsListUsingGET(categoryCode, filters, options)(this.fetch, this.basePath);
    }

}
/**
 * ChatControllerApi - fetch parameter creator
 * @export
 */
export const ChatControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register for chat support
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProductChatUsingPUT(body: ProductUidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling registerProductChatUsingPUT.');
            }
            const localVarPath = `/chat/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatControllerApi - functional programming interface
 * @export
 */
export const ChatControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register for chat support
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProductChatUsingPUT(body: ProductUidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductChat> {
            const localVarFetchArgs = ChatControllerApiFetchParamCreator(configuration).registerProductChatUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ChatControllerApi - factory interface
 * @export
 */
export const ChatControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Register for chat support
         * @param {ProductUidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProductChatUsingPUT(body: ProductUidRequest, options?: any) {
            return ChatControllerApiFp(configuration).registerProductChatUsingPUT(body, options)(fetch, basePath);
        },
    };
};

/**
 * ChatControllerApi - object-oriented interface
 * @export
 * @class ChatControllerApi
 * @extends {BaseAPI}
 */
export class ChatControllerApi extends BaseAPI {
    /**
     * 
     * @summary Register for chat support
     * @param {ProductUidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatControllerApi
     */
    public registerProductChatUsingPUT(body: ProductUidRequest, options?: any) {
        return ChatControllerApiFp(this.configuration).registerProductChatUsingPUT(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ClassificatorControllerApi - fetch parameter creator
 * @export
 */
export const ClassificatorControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get classificator list by fields list
         * @param {string} entity entity
         * @param {Array<string>} fields fields
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListByFieldsListUsingGET(entity: string, fields: Array<string>, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'entity' is not null or undefined
            if (entity === null || entity === undefined) {
                throw new RequiredError('entity','Required parameter entity was null or undefined when calling entityListByFieldsListUsingGET.');
            }
            // verify required parameter 'fields' is not null or undefined
            if (fields === null || fields === undefined) {
                throw new RequiredError('fields','Required parameter fields was null or undefined when calling entityListByFieldsListUsingGET.');
            }
            const localVarPath = `/classificator/{entity}`
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (fields) {
                localVarQueryParameter['fields'] = fields;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get classificator list
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListUsingGET(entity: string, field: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'entity' is not null or undefined
            if (entity === null || entity === undefined) {
                throw new RequiredError('entity','Required parameter entity was null or undefined when calling entityListUsingGET.');
            }
            // verify required parameter 'field' is not null or undefined
            if (field === null || field === undefined) {
                throw new RequiredError('field','Required parameter field was null or undefined when calling entityListUsingGET.');
            }
            const localVarPath = `/classificator/{entity}/{field}`
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product Category list
         * @param {string} [accountUid] accountUid
         * @param {string} [categoryCode] categoryCode
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryUsingGET(accountUid?: string, categoryCode?: string, country?: string, filterEmpty?: boolean, language?: string, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/classificator/productCategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['categoryCode'] = categoryCode;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (filterEmpty !== undefined) {
                localVarQueryParameter['filterEmpty'] = filterEmpty;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product Search Category list
         * @param {BaseProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchCategoryUsingPOST(body: BaseProductSearchListRequest, country?: string, filterEmpty?: boolean, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productSearchCategoryUsingPOST.');
            }
            const localVarPath = `/classificator/searchProductCategory`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (filterEmpty !== undefined) {
                localVarQueryParameter['filterEmpty'] = filterEmpty;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BaseProductSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassificatorControllerApi - functional programming interface
 * @export
 */
export const ClassificatorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get classificator list by fields list
         * @param {string} entity entity
         * @param {Array<string>} fields fields
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListByFieldsListUsingGET(entity: string, fields: Array<string>, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseClassificator> {
            const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).entityListByFieldsListUsingGET(entity, fields, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get classificator list
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListUsingGET(entity: string, field: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseClassificator> {
            const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).entityListUsingGET(entity, field, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Product Category list
         * @param {string} [accountUid] accountUid
         * @param {string} [categoryCode] categoryCode
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryUsingGET(accountUid?: string, categoryCode?: string, country?: string, filterEmpty?: boolean, language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductCategory> {
            const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).productCategoryUsingGET(accountUid, categoryCode, country, filterEmpty, language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Product Search Category list
         * @param {BaseProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchCategoryUsingPOST(body: BaseProductSearchListRequest, country?: string, filterEmpty?: boolean, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductCategory> {
            const localVarFetchArgs = ClassificatorControllerApiFetchParamCreator(configuration).productSearchCategoryUsingPOST(body, country, filterEmpty, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClassificatorControllerApi - factory interface
 * @export
 */
export const ClassificatorControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get classificator list by fields list
         * @param {string} entity entity
         * @param {Array<string>} fields fields
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListByFieldsListUsingGET(entity: string, fields: Array<string>, language?: string, options?: any) {
            return ClassificatorControllerApiFp(configuration).entityListByFieldsListUsingGET(entity, fields, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get classificator list
         * @param {string} entity entity
         * @param {string} field field
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entityListUsingGET(entity: string, field: string, language?: string, options?: any) {
            return ClassificatorControllerApiFp(configuration).entityListUsingGET(entity, field, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Product Category list
         * @param {string} [accountUid] accountUid
         * @param {string} [categoryCode] categoryCode
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productCategoryUsingGET(accountUid?: string, categoryCode?: string, country?: string, filterEmpty?: boolean, language?: string, type?: string, options?: any) {
            return ClassificatorControllerApiFp(configuration).productCategoryUsingGET(accountUid, categoryCode, country, filterEmpty, language, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Product Search Category list
         * @param {BaseProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {boolean} [filterEmpty] filterEmpty
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchCategoryUsingPOST(body: BaseProductSearchListRequest, country?: string, filterEmpty?: boolean, language?: string, options?: any) {
            return ClassificatorControllerApiFp(configuration).productSearchCategoryUsingPOST(body, country, filterEmpty, language, options)(fetch, basePath);
        },
    };
};

/**
 * ClassificatorControllerApi - object-oriented interface
 * @export
 * @class ClassificatorControllerApi
 * @extends {BaseAPI}
 */
export class ClassificatorControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get classificator list by fields list
     * @param {string} entity entity
     * @param {Array<string>} fields fields
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorControllerApi
     */
    public entityListByFieldsListUsingGET(entity: string, fields: Array<string>, language?: string, options?: any) {
        return ClassificatorControllerApiFp(this.configuration).entityListByFieldsListUsingGET(entity, fields, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get classificator list
     * @param {string} entity entity
     * @param {string} field field
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorControllerApi
     */
    public entityListUsingGET(entity: string, field: string, language?: string, options?: any) {
        return ClassificatorControllerApiFp(this.configuration).entityListUsingGET(entity, field, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Product Category list
     * @param {string} [accountUid] accountUid
     * @param {string} [categoryCode] categoryCode
     * @param {string} [country] country
     * @param {boolean} [filterEmpty] filterEmpty
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorControllerApi
     */
    public productCategoryUsingGET(accountUid?: string, categoryCode?: string, country?: string, filterEmpty?: boolean, language?: string, type?: string, options?: any) {
        return ClassificatorControllerApiFp(this.configuration).productCategoryUsingGET(accountUid, categoryCode, country, filterEmpty, language, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Product Search Category list
     * @param {BaseProductSearchListRequest} body request
     * @param {string} [country] country
     * @param {boolean} [filterEmpty] filterEmpty
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassificatorControllerApi
     */
    public productSearchCategoryUsingPOST(body: BaseProductSearchListRequest, country?: string, filterEmpty?: boolean, language?: string, options?: any) {
        return ClassificatorControllerApiFp(this.configuration).productSearchCategoryUsingPOST(body, country, filterEmpty, language, options)(this.fetch, this.basePath);
    }

}
/**
 * DeliveryMethodControllerApi - fetch parameter creator
 * @export
 */
export const DeliveryMethodControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add/Edit account platform delivery method
         * @param {PlatformDeliveryMethod} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPlatformDeliveryMethodsAddEditUsingPOST(body: PlatformDeliveryMethod, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountPlatformDeliveryMethodsAddEditUsingPOST.');
            }
            const localVarPath = `/account/platform-delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PlatformDeliveryMethod" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductDeliveryMethodUsingPUT(deliveryMethodUid: string, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryMethodUid' is not null or undefined
            if (deliveryMethodUid === null || deliveryMethodUid === undefined) {
                throw new RequiredError('deliveryMethodUid','Required parameter deliveryMethodUid was null or undefined when calling addProductDeliveryMethodUsingPUT.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductDeliveryMethodUsingPUT.');
            }
            const localVarPath = `/product/{productUid}/delivery/method/{deliveryMethodUid}`
                .replace(`{${"deliveryMethodUid"}}`, encodeURIComponent(String(deliveryMethodUid)))
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountDeliveryMethodUsingPUT(body: DeliveryMethod, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAccountDeliveryMethodUsingPUT.');
            }
            const localVarPath = `/account/delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryMethod" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account platform delivery method by delivery provider UID
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPlatformDeliveryMethodUsingDELETE(body: UidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteAccountPlatformDeliveryMethodUsingDELETE.');
            }
            const localVarPath = `/account/platform-delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid: string, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryMethodUid' is not null or undefined
            if (deliveryMethodUid === null || deliveryMethodUid === undefined) {
                throw new RequiredError('deliveryMethodUid','Required parameter deliveryMethodUid was null or undefined when calling deleteProductDeliveryMethodUsingDELETE.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling deleteProductDeliveryMethodUsingDELETE.');
            }
            const localVarPath = `/product/{productUid}/delivery/method/{deliveryMethodUid}`
                .replace(`{${"deliveryMethodUid"}}`, encodeURIComponent(String(deliveryMethodUid)))
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return account delivery methods or account specified delivery method, if uid is not specified
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return account platform delivery methods or account specified platform delivery method, if uid is not specified
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType?: string, deliveryProviderUid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/platform-delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deliveryProviderType !== undefined) {
                localVarQueryParameter['deliveryProviderType'] = deliveryProviderType;
            }

            if (deliveryProviderUid !== undefined) {
                localVarQueryParameter['deliveryProviderUid'] = deliveryProviderUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of supported platform delivery provides
         * @param {string} [countryCode] countryCode
         * @param {boolean} [courierSupported] courierSupported
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {boolean} [notConfiguredOnly] notConfiguredOnly
         * @param {boolean} [pickUpSupported] pickUpSupported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryProvidersUsingGET(countryCode?: string, courierSupported?: boolean, deliveryProviderType?: string, deliveryProviderUid?: string, notConfiguredOnly?: boolean, pickUpSupported?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/account/platform-delivery/provider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (courierSupported !== undefined) {
                localVarQueryParameter['courierSupported'] = courierSupported;
            }

            if (deliveryProviderType !== undefined) {
                localVarQueryParameter['deliveryProviderType'] = deliveryProviderType;
            }

            if (deliveryProviderUid !== undefined) {
                localVarQueryParameter['deliveryProviderUid'] = deliveryProviderUid;
            }

            if (notConfiguredOnly !== undefined) {
                localVarQueryParameter['notConfiguredOnly'] = notConfiguredOnly;
            }

            if (pickUpSupported !== undefined) {
                localVarQueryParameter['pickUpSupported'] = pickUpSupported;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing delivery method
         * @param {UidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingDELETE(body: UidRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountDeliveryMethodUsingDELETE.');
            }
            const localVarPath = `/account/delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingPOST(body: DeliveryMethod, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountDeliveryMethodUsingPOST.');
            }
            const localVarPath = `/account/delivery/method`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryMethod" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product delivery methods
         * @param {Array<BaseUidObject>} body deliveryMethodList
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductDeliveryMethodsUsingPOST(body: Array<BaseUidObject>, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductDeliveryMethodsUsingPOST.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling updateProductDeliveryMethodsUsingPOST.');
            }
            const localVarPath = `/product/{productUid}/delivery`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;BaseUidObject&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryMethodControllerApi - functional programming interface
 * @export
 */
export const DeliveryMethodControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add/Edit account platform delivery method
         * @param {PlatformDeliveryMethod} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPlatformDeliveryMethodsAddEditUsingPOST(body: PlatformDeliveryMethod, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePlatformDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).accountPlatformDeliveryMethodsAddEditUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductDeliveryMethodUsingPUT(deliveryMethodUid: string, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).addProductDeliveryMethodUsingPUT(deliveryMethodUid, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountDeliveryMethodUsingPUT(body: DeliveryMethod, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).createAccountDeliveryMethodUsingPUT(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete account platform delivery method by delivery provider UID
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPlatformDeliveryMethodUsingDELETE(body: UidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePlatformDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).deleteAccountPlatformDeliveryMethodUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid: string, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return account delivery methods or account specified delivery method, if uid is not specified
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).getAccountDeliveryMethodsUsingGET(language, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return account platform delivery methods or account specified platform delivery method, if uid is not specified
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType?: string, deliveryProviderUid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePlatformDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType, deliveryProviderUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of supported platform delivery provides
         * @param {string} [countryCode] countryCode
         * @param {boolean} [courierSupported] courierSupported
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {boolean} [notConfiguredOnly] notConfiguredOnly
         * @param {boolean} [pickUpSupported] pickUpSupported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryProvidersUsingGET(countryCode?: string, courierSupported?: boolean, deliveryProviderType?: string, deliveryProviderUid?: string, notConfiguredOnly?: boolean, pickUpSupported?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePlatformDeliveryProvider> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).getAccountPlatformDeliveryProvidersUsingGET(countryCode, courierSupported, deliveryProviderType, deliveryProviderUid, notConfiguredOnly, pickUpSupported, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete existing delivery method
         * @param {UidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingDELETE(body: UidRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).updateAccountDeliveryMethodUsingDELETE(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingPOST(body: DeliveryMethod, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).updateAccountDeliveryMethodUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product delivery methods
         * @param {Array<BaseUidObject>} body deliveryMethodList
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductDeliveryMethodsUsingPOST(body: Array<BaseUidObject>, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryMethod> {
            const localVarFetchArgs = DeliveryMethodControllerApiFetchParamCreator(configuration).updateProductDeliveryMethodsUsingPOST(body, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DeliveryMethodControllerApi - factory interface
 * @export
 */
export const DeliveryMethodControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add/Edit account platform delivery method
         * @param {PlatformDeliveryMethod} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountPlatformDeliveryMethodsAddEditUsingPOST(body: PlatformDeliveryMethod, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).accountPlatformDeliveryMethodsAddEditUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductDeliveryMethodUsingPUT(deliveryMethodUid: string, productUid: string, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).addProductDeliveryMethodUsingPUT(deliveryMethodUid, productUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountDeliveryMethodUsingPUT(body: DeliveryMethod, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).createAccountDeliveryMethodUsingPUT(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete account platform delivery method by delivery provider UID
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccountPlatformDeliveryMethodUsingDELETE(body: UidRequest, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).deleteAccountPlatformDeliveryMethodUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove product delivery method
         * @param {string} deliveryMethodUid deliveryMethodUid
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid: string, productUid: string, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid, productUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return account delivery methods or account specified delivery method, if uid is not specified
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).getAccountDeliveryMethodsUsingGET(language, uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return account platform delivery methods or account specified platform delivery method, if uid is not specified
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType?: string, deliveryProviderUid?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType, deliveryProviderUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of supported platform delivery provides
         * @param {string} [countryCode] countryCode
         * @param {boolean} [courierSupported] courierSupported
         * @param {string} [deliveryProviderType] deliveryProviderType
         * @param {string} [deliveryProviderUid] deliveryProviderUid
         * @param {boolean} [notConfiguredOnly] notConfiguredOnly
         * @param {boolean} [pickUpSupported] pickUpSupported
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountPlatformDeliveryProvidersUsingGET(countryCode?: string, courierSupported?: boolean, deliveryProviderType?: string, deliveryProviderUid?: string, notConfiguredOnly?: boolean, pickUpSupported?: boolean, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).getAccountPlatformDeliveryProvidersUsingGET(countryCode, courierSupported, deliveryProviderType, deliveryProviderUid, notConfiguredOnly, pickUpSupported, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete existing delivery method
         * @param {UidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingDELETE(body: UidRequest, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).updateAccountDeliveryMethodUsingDELETE(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing delivery method
         * @param {DeliveryMethod} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDeliveryMethodUsingPOST(body: DeliveryMethod, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).updateAccountDeliveryMethodUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product delivery methods
         * @param {Array<BaseUidObject>} body deliveryMethodList
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductDeliveryMethodsUsingPOST(body: Array<BaseUidObject>, productUid: string, language?: string, options?: any) {
            return DeliveryMethodControllerApiFp(configuration).updateProductDeliveryMethodsUsingPOST(body, productUid, language, options)(fetch, basePath);
        },
    };
};

/**
 * DeliveryMethodControllerApi - object-oriented interface
 * @export
 * @class DeliveryMethodControllerApi
 * @extends {BaseAPI}
 */
export class DeliveryMethodControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add/Edit account platform delivery method
     * @param {PlatformDeliveryMethod} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public accountPlatformDeliveryMethodsAddEditUsingPOST(body: PlatformDeliveryMethod, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).accountPlatformDeliveryMethodsAddEditUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public addProductDeliveryMethodUsingPUT(deliveryMethodUid: string, productUid: string, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).addProductDeliveryMethodUsingPUT(deliveryMethodUid, productUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new delivery method
     * @param {DeliveryMethod} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public createAccountDeliveryMethodUsingPUT(body: DeliveryMethod, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).createAccountDeliveryMethodUsingPUT(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete account platform delivery method by delivery provider UID
     * @param {UidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public deleteAccountPlatformDeliveryMethodUsingDELETE(body: UidRequest, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).deleteAccountPlatformDeliveryMethodUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove product delivery method
     * @param {string} deliveryMethodUid deliveryMethodUid
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid: string, productUid: string, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).deleteProductDeliveryMethodUsingDELETE(deliveryMethodUid, productUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return account delivery methods or account specified delivery method, if uid is not specified
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public getAccountDeliveryMethodsUsingGET(language?: string, uid?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).getAccountDeliveryMethodsUsingGET(language, uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return account platform delivery methods or account specified platform delivery method, if uid is not specified
     * @param {string} [deliveryProviderType] deliveryProviderType
     * @param {string} [deliveryProviderUid] deliveryProviderUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType?: string, deliveryProviderUid?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).getAccountPlatformDeliveryMethodsUsingGET(deliveryProviderType, deliveryProviderUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of supported platform delivery provides
     * @param {string} [countryCode] countryCode
     * @param {boolean} [courierSupported] courierSupported
     * @param {string} [deliveryProviderType] deliveryProviderType
     * @param {string} [deliveryProviderUid] deliveryProviderUid
     * @param {boolean} [notConfiguredOnly] notConfiguredOnly
     * @param {boolean} [pickUpSupported] pickUpSupported
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public getAccountPlatformDeliveryProvidersUsingGET(countryCode?: string, courierSupported?: boolean, deliveryProviderType?: string, deliveryProviderUid?: string, notConfiguredOnly?: boolean, pickUpSupported?: boolean, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).getAccountPlatformDeliveryProvidersUsingGET(countryCode, courierSupported, deliveryProviderType, deliveryProviderUid, notConfiguredOnly, pickUpSupported, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete existing delivery method
     * @param {UidRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public updateAccountDeliveryMethodUsingDELETE(body: UidRequest, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).updateAccountDeliveryMethodUsingDELETE(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing delivery method
     * @param {DeliveryMethod} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public updateAccountDeliveryMethodUsingPOST(body: DeliveryMethod, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).updateAccountDeliveryMethodUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product delivery methods
     * @param {Array<BaseUidObject>} body deliveryMethodList
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryMethodControllerApi
     */
    public updateProductDeliveryMethodsUsingPOST(body: Array<BaseUidObject>, productUid: string, language?: string, options?: any) {
        return DeliveryMethodControllerApiFp(this.configuration).updateProductDeliveryMethodsUsingPOST(body, productUid, language, options)(this.fetch, this.basePath);
    }

}
/**
 * DigitalContentControllerApi - fetch parameter creator
 * @export
 */
export const DigitalContentControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return digital content available for account
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDigitalContentListUsingGET(language?: string, uid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/account/digitalContent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalContentControllerApi - functional programming interface
 * @export
 */
export const DigitalContentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return digital content available for account
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDigitalContentListUsingGET(language?: string, uid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDigitalContent> {
            const localVarFetchArgs = DigitalContentControllerApiFetchParamCreator(configuration).getAccountDigitalContentListUsingGET(language, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DigitalContentControllerApi - factory interface
 * @export
 */
export const DigitalContentControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Return digital content available for account
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDigitalContentListUsingGET(language?: string, uid?: string, options?: any) {
            return DigitalContentControllerApiFp(configuration).getAccountDigitalContentListUsingGET(language, uid, options)(fetch, basePath);
        },
    };
};

/**
 * DigitalContentControllerApi - object-oriented interface
 * @export
 * @class DigitalContentControllerApi
 * @extends {BaseAPI}
 */
export class DigitalContentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return digital content available for account
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalContentControllerApi
     */
    public getAccountDigitalContentListUsingGET(language?: string, uid?: string, options?: any) {
        return DigitalContentControllerApiFp(this.configuration).getAccountDigitalContentListUsingGET(language, uid, options)(this.fetch, this.basePath);
    }

}
/**
 * FavoriteStoreControllerApi - fetch parameter creator
 * @export
 */
export const FavoriteStoreControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add store to favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreToFavoriteUsingPUT(storeUid: string, language?: string, withProducts?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreToFavoriteUsingPUT.');
            }
            const localVarPath = `/store/favorite/{storeUid}`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (withProducts !== undefined) {
                localVarQueryParameter['withProducts'] = withProducts;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get favorite stores
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteStoresUsingGET(language?: string, withProducts?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/store/favorite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (withProducts !== undefined) {
                localVarQueryParameter['withProducts'] = withProducts;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove store from favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, withProducts?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling removeStoreFromFavoriteUsingDELETE.');
            }
            const localVarPath = `/store/favorite/{storeUid}`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (withProducts !== undefined) {
                localVarQueryParameter['withProducts'] = withProducts;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FavoriteStoreControllerApi - functional programming interface
 * @export
 */
export const FavoriteStoreControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add store to favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreToFavoriteUsingPUT(storeUid: string, language?: string, withProducts?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(configuration).addStoreToFavoriteUsingPUT(storeUid, language, withProducts, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get favorite stores
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteStoresUsingGET(language?: string, withProducts?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(configuration).getFavoriteStoresUsingGET(language, withProducts, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove store from favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, withProducts?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = FavoriteStoreControllerApiFetchParamCreator(configuration).removeStoreFromFavoriteUsingDELETE(storeUid, language, withProducts, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FavoriteStoreControllerApi - factory interface
 * @export
 */
export const FavoriteStoreControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add store to favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreToFavoriteUsingPUT(storeUid: string, language?: string, withProducts?: boolean, options?: any) {
            return FavoriteStoreControllerApiFp(configuration).addStoreToFavoriteUsingPUT(storeUid, language, withProducts, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get favorite stores
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteStoresUsingGET(language?: string, withProducts?: boolean, options?: any) {
            return FavoriteStoreControllerApiFp(configuration).getFavoriteStoresUsingGET(language, withProducts, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove store from favorites
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {boolean} [withProducts] withProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, withProducts?: boolean, options?: any) {
            return FavoriteStoreControllerApiFp(configuration).removeStoreFromFavoriteUsingDELETE(storeUid, language, withProducts, options)(fetch, basePath);
        },
    };
};

/**
 * FavoriteStoreControllerApi - object-oriented interface
 * @export
 * @class FavoriteStoreControllerApi
 * @extends {BaseAPI}
 */
export class FavoriteStoreControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add store to favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {boolean} [withProducts] withProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteStoreControllerApi
     */
    public addStoreToFavoriteUsingPUT(storeUid: string, language?: string, withProducts?: boolean, options?: any) {
        return FavoriteStoreControllerApiFp(this.configuration).addStoreToFavoriteUsingPUT(storeUid, language, withProducts, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get favorite stores
     * @param {string} [language] language
     * @param {boolean} [withProducts] withProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteStoreControllerApi
     */
    public getFavoriteStoresUsingGET(language?: string, withProducts?: boolean, options?: any) {
        return FavoriteStoreControllerApiFp(this.configuration).getFavoriteStoresUsingGET(language, withProducts, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove store from favorites
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {boolean} [withProducts] withProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteStoreControllerApi
     */
    public removeStoreFromFavoriteUsingDELETE(storeUid: string, language?: string, withProducts?: boolean, options?: any) {
        return FavoriteStoreControllerApiFp(this.configuration).removeStoreFromFavoriteUsingDELETE(storeUid, language, withProducts, options)(this.fetch, this.basePath);
    }

}
/**
 * FeatureControllerApi - fetch parameter creator
 * @export
 */
export const FeatureControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return available product features
         * @param {string} [currency] currency
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productFeatureListUsingGET(currency?: string, language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/feature/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureControllerApi - functional programming interface
 * @export
 */
export const FeatureControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return available product features
         * @param {string} [currency] currency
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productFeatureListUsingGET(currency?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductFeatureType> {
            const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).productFeatureListUsingGET(currency, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeatureControllerApi - factory interface
 * @export
 */
export const FeatureControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Return available product features
         * @param {string} [currency] currency
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productFeatureListUsingGET(currency?: string, language?: string, options?: any) {
            return FeatureControllerApiFp(configuration).productFeatureListUsingGET(currency, language, options)(fetch, basePath);
        },
    };
};

/**
 * FeatureControllerApi - object-oriented interface
 * @export
 * @class FeatureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return available product features
     * @param {string} [currency] currency
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public productFeatureListUsingGET(currency?: string, language?: string, options?: any) {
        return FeatureControllerApiFp(this.configuration).productFeatureListUsingGET(currency, language, options)(this.fetch, this.basePath);
    }

}
/**
 * FileUploadControllerApi - fetch parameter creator
 * @export
 */
export const FileUploadControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload file to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUsingPOST(bucket: any, file: Blob, options: any = {}): FetchArgs {
            // verify required parameter 'bucket' is not null or undefined
            if (bucket === null || bucket === undefined) {
                throw new RequiredError('bucket','Required parameter bucket was null or undefined when calling uploadFileUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFileUsingPOST.');
            }
            const localVarPath = `/file/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (bucket !== undefined) {
                localVarFormParams.set('bucket', bucket as any);
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByFormUsingPOST(base64File: string, bucket: string, fileName: string, ownerUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'base64File' is not null or undefined
            if (base64File === null || base64File === undefined) {
                throw new RequiredError('base64File','Required parameter base64File was null or undefined when calling uploadImageByFormUsingPOST.');
            }
            // verify required parameter 'bucket' is not null or undefined
            if (bucket === null || bucket === undefined) {
                throw new RequiredError('bucket','Required parameter bucket was null or undefined when calling uploadImageByFormUsingPOST.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling uploadImageByFormUsingPOST.');
            }
            // verify required parameter 'ownerUid' is not null or undefined
            if (ownerUid === null || ownerUid === undefined) {
                throw new RequiredError('ownerUid','Required parameter ownerUid was null or undefined when calling uploadImageByFormUsingPOST.');
            }
            const localVarPath = `/image/{ownerUid}/upload/base64`
                .replace(`{${"ownerUid"}}`, encodeURIComponent(String(ownerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (bucket !== undefined) {
                localVarFormParams.set('bucket', bucket as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByMultipartUsingPOST(bucket: any, file: Blob, ownerUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'bucket' is not null or undefined
            if (bucket === null || bucket === undefined) {
                throw new RequiredError('bucket','Required parameter bucket was null or undefined when calling uploadImageByMultipartUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadImageByMultipartUsingPOST.');
            }
            // verify required parameter 'ownerUid' is not null or undefined
            if (ownerUid === null || ownerUid === undefined) {
                throw new RequiredError('ownerUid','Required parameter ownerUid was null or undefined when calling uploadImageByMultipartUsingPOST.');
            }
            const localVarPath = `/image/{ownerUid}/upload/multipart`
                .replace(`{${"ownerUid"}}`, encodeURIComponent(String(ownerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (bucket !== undefined) {
                localVarFormParams.set('bucket', bucket as any);
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST1(base64File: string, bucket: string, fileName: string, ownerUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'base64File' is not null or undefined
            if (base64File === null || base64File === undefined) {
                throw new RequiredError('base64File','Required parameter base64File was null or undefined when calling uploadImageUsingPOST1.');
            }
            // verify required parameter 'bucket' is not null or undefined
            if (bucket === null || bucket === undefined) {
                throw new RequiredError('bucket','Required parameter bucket was null or undefined when calling uploadImageUsingPOST1.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling uploadImageUsingPOST1.');
            }
            // verify required parameter 'ownerUid' is not null or undefined
            if (ownerUid === null || ownerUid === undefined) {
                throw new RequiredError('ownerUid','Required parameter ownerUid was null or undefined when calling uploadImageUsingPOST1.');
            }
            const localVarPath = `/image/{ownerUid}/upload`
                .replace(`{${"ownerUid"}}`, encodeURIComponent(String(ownerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (bucket !== undefined) {
                localVarFormParams.set('bucket', bucket as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload product BASE64 image
         * @param {string} base64File 
         * @param {string} fileName 
         * @param {string} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByFormUsingPOST(base64File: string, fileName: string, language: string, productUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'base64File' is not null or undefined
            if (base64File === null || base64File === undefined) {
                throw new RequiredError('base64File','Required parameter base64File was null or undefined when calling uploadProductFileByFormUsingPOST.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling uploadProductFileByFormUsingPOST.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling uploadProductFileByFormUsingPOST.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling uploadProductFileByFormUsingPOST.');
            }
            const localVarPath = `/product/{productUid}/upload/base64`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (base64File !== undefined) {
                localVarFormParams.set('base64File', base64File as any);
            }

            if (fileName !== undefined) {
                localVarFormParams.set('fileName', fileName as any);
            }

            if (language !== undefined) {
                localVarFormParams.set('language', language as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByMultipartUsingPOST(file: Blob, language: any, productUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadProductFileByMultipartUsingPOST.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling uploadProductFileByMultipartUsingPOST.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling uploadProductFileByMultipartUsingPOST.');
            }
            const localVarPath = `/product/{productUid}/upload/multipart`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            if (language !== undefined) {
                localVarFormParams.set('language', language as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(file: Blob, language: any, productUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadUsingPOST1.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling uploadUsingPOST1.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling uploadUsingPOST1.');
            }
            const localVarPath = `/product/{productUid}/upload`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            if (language !== undefined) {
                localVarFormParams.set('language', language as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileUploadControllerApi - functional programming interface
 * @export
 */
export const FileUploadControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload file to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUsingPOST(bucket: any, file: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadFileUsingPOST(bucket, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByFormUsingPOST(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageByFormUsingPOST(base64File, bucket, fileName, ownerUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload image to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByMultipartUsingPOST(bucket: any, file: Blob, ownerUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageByMultipartUsingPOST(bucket, file, ownerUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST1(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUploadedImage> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadImageUsingPOST1(base64File, bucket, fileName, ownerUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload product BASE64 image
         * @param {string} base64File 
         * @param {string} fileName 
         * @param {string} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByFormUsingPOST(base64File: string, fileName: string, language: string, productUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadProductFileByFormUsingPOST(base64File, fileName, language, productUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByMultipartUsingPOST(file: Blob, language: any, productUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadProductFileByMultipartUsingPOST(file, language, productUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(file: Blob, language: any, productUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = FileUploadControllerApiFetchParamCreator(configuration).uploadUsingPOST1(file, language, productUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FileUploadControllerApi - factory interface
 * @export
 */
export const FileUploadControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Upload file to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUsingPOST(bucket: any, file: Blob, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadFileUsingPOST(bucket, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByFormUsingPOST(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadImageByFormUsingPOST(base64File, bucket, fileName, ownerUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload image to storage
         * @param {any} bucket 
         * @param {Blob} file 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageByMultipartUsingPOST(bucket: any, file: Blob, ownerUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadImageByMultipartUsingPOST(bucket, file, ownerUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload BASE64 image to storage
         * @param {string} base64File 
         * @param {string} bucket 
         * @param {string} fileName 
         * @param {string} ownerUid ownerUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImageUsingPOST1(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadImageUsingPOST1(base64File, bucket, fileName, ownerUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload product BASE64 image
         * @param {string} base64File 
         * @param {string} fileName 
         * @param {string} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByFormUsingPOST(base64File: string, fileName: string, language: string, productUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadProductFileByFormUsingPOST(base64File, fileName, language, productUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProductFileByMultipartUsingPOST(file: Blob, language: any, productUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadProductFileByMultipartUsingPOST(file, language, productUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Upload product image
         * @param {Blob} file 
         * @param {any} language 
         * @param {string} productUid productUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST1(file: Blob, language: any, productUid: string, options?: any) {
            return FileUploadControllerApiFp(configuration).uploadUsingPOST1(file, language, productUid, options)(fetch, basePath);
        },
    };
};

/**
 * FileUploadControllerApi - object-oriented interface
 * @export
 * @class FileUploadControllerApi
 * @extends {BaseAPI}
 */
export class FileUploadControllerApi extends BaseAPI {
    /**
     * 
     * @summary Upload file to storage
     * @param {any} bucket 
     * @param {Blob} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadFileUsingPOST(bucket: any, file: Blob, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadFileUsingPOST(bucket, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload BASE64 image to storage
     * @param {string} base64File 
     * @param {string} bucket 
     * @param {string} fileName 
     * @param {string} ownerUid ownerUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadImageByFormUsingPOST(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadImageByFormUsingPOST(base64File, bucket, fileName, ownerUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload image to storage
     * @param {any} bucket 
     * @param {Blob} file 
     * @param {string} ownerUid ownerUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadImageByMultipartUsingPOST(bucket: any, file: Blob, ownerUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadImageByMultipartUsingPOST(bucket, file, ownerUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload BASE64 image to storage
     * @param {string} base64File 
     * @param {string} bucket 
     * @param {string} fileName 
     * @param {string} ownerUid ownerUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadImageUsingPOST1(base64File: string, bucket: string, fileName: string, ownerUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadImageUsingPOST1(base64File, bucket, fileName, ownerUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload product BASE64 image
     * @param {string} base64File 
     * @param {string} fileName 
     * @param {string} language 
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadProductFileByFormUsingPOST(base64File: string, fileName: string, language: string, productUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadProductFileByFormUsingPOST(base64File, fileName, language, productUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload product image
     * @param {Blob} file 
     * @param {any} language 
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadProductFileByMultipartUsingPOST(file: Blob, language: any, productUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadProductFileByMultipartUsingPOST(file, language, productUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Upload product image
     * @param {Blob} file 
     * @param {any} language 
     * @param {string} productUid productUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUploadControllerApi
     */
    public uploadUsingPOST1(file: Blob, language: any, productUid: string, options?: any) {
        return FileUploadControllerApiFp(this.configuration).uploadUsingPOST1(file, language, productUid, options)(this.fetch, this.basePath);
    }

}
/**
 * InfoControllerApi - fetch parameter creator
 * @export
 */
export const InfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Show current build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/version`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoControllerApi - functional programming interface
 * @export
 */
export const InfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Show current build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBuildProperties> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).versionUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoControllerApi - factory interface
 * @export
 */
export const InfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Show current build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        versionUsingGET(options?: any) {
            return InfoControllerApiFp(configuration).versionUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * InfoControllerApi - object-oriented interface
 * @export
 * @class InfoControllerApi
 * @extends {BaseAPI}
 */
export class InfoControllerApi extends BaseAPI {
    /**
     * 
     * @summary Show current build information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public versionUsingGET(options?: any) {
        return InfoControllerApiFp(this.configuration).versionUsingGET(options)(this.fetch, this.basePath);
    }

}
/**
 * OmnideskTicketControllerApi - fetch parameter creator
 * @export
 */
export const OmnideskTicketControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {OmnideskTicket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOmnideskTicketUsingPUT(body: OmnideskTicket, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOmnideskTicketUsingPUT.');
            }
            const localVarPath = `/omnidesk/ticket`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OmnideskTicket" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OmnideskTicketControllerApi - functional programming interface
 * @export
 */
export const OmnideskTicketControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {OmnideskTicket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOmnideskTicketUsingPUT(body: OmnideskTicket, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseOmnideskTicket> {
            const localVarFetchArgs = OmnideskTicketControllerApiFetchParamCreator(configuration).createOmnideskTicketUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OmnideskTicketControllerApi - factory interface
 * @export
 */
export const OmnideskTicketControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {OmnideskTicket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOmnideskTicketUsingPUT(body: OmnideskTicket, options?: any) {
            return OmnideskTicketControllerApiFp(configuration).createOmnideskTicketUsingPUT(body, options)(fetch, basePath);
        },
    };
};

/**
 * OmnideskTicketControllerApi - object-oriented interface
 * @export
 * @class OmnideskTicketControllerApi
 * @extends {BaseAPI}
 */
export class OmnideskTicketControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create Omnidesk ticket
     * @param {OmnideskTicket} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmnideskTicketControllerApi
     */
    public createOmnideskTicketUsingPUT(body: OmnideskTicket, options?: any) {
        return OmnideskTicketControllerApiFp(this.configuration).createOmnideskTicketUsingPUT(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PlatformDeliveryControllerApi - fetch parameter creator
 * @export
 */
export const PlatformDeliveryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate delivery provider shipping price
         * @param {DeliveryCalculateRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCalculateUsingPOST(body: DeliveryCalculateRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryCalculateUsingPOST.');
            }
            const localVarPath = `/platform-delivery/calculateDelivery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryCalculateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get city list by delivery provider
         * @param {DeliveryCityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCityListUsingPOST(body: DeliveryCityRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryCityListUsingPOST.');
            }
            const localVarPath = `/platform-delivery/classifier/city`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryCityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get delivery point list by delivery provider
         * @param {DeliveryPointRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryPointListUsingPOST(body: DeliveryPointRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryPointListUsingPOST.');
            }
            const localVarPath = `/platform-delivery/classifier/point`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryPointRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} productOrderUid productOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryProductOrderDocumentUsingGET(documentType: string, productOrderUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling platformDeliveryProductOrderDocumentUsingGET.');
            }
            // verify required parameter 'productOrderUid' is not null or undefined
            if (productOrderUid === null || productOrderUid === undefined) {
                throw new RequiredError('productOrderUid','Required parameter productOrderUid was null or undefined when calling platformDeliveryProductOrderDocumentUsingGET.');
            }
            const localVarPath = `/platform-delivery/shipping/productOrder/{productOrderUid}/print/{documentType}`
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)))
                .replace(`{${"productOrderUid"}}`, encodeURIComponent(String(productOrderUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create intake request for shipping orders
         * @param {ShippingOrderIntakeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingDocumentUsingPOST(body: ShippingOrderIntakeRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryShippingDocumentUsingPOST.');
            }
            const localVarPath = `/platform-delivery/shipping/order/intake`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ShippingOrderIntakeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create shipping order
         * @param {CreateShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderCreateUsingPOST(body: CreateShippingOrderRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryShippingOrderCreateUsingPOST.');
            }
            const localVarPath = `/platform-delivery/shipping/order`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateShippingOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} shippingOrderUid shippingOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderDocumentUsingGET(documentType: string, shippingOrderUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'documentType' is not null or undefined
            if (documentType === null || documentType === undefined) {
                throw new RequiredError('documentType','Required parameter documentType was null or undefined when calling platformDeliveryShippingOrderDocumentUsingGET.');
            }
            // verify required parameter 'shippingOrderUid' is not null or undefined
            if (shippingOrderUid === null || shippingOrderUid === undefined) {
                throw new RequiredError('shippingOrderUid','Required parameter shippingOrderUid was null or undefined when calling platformDeliveryShippingOrderDocumentUsingGET.');
            }
            const localVarPath = `/platform-delivery/shipping/shippingOrder/{shippingOrderUid}/print/{documentType}`
                .replace(`{${"documentType"}}`, encodeURIComponent(String(documentType)))
                .replace(`{${"shippingOrderUid"}}`, encodeURIComponent(String(shippingOrderUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shipping order list
         * @param {ShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderListUsingPOST(body: ShippingOrderRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling platformDeliveryShippingOrderListUsingPOST.');
            }
            const localVarPath = `/platform-delivery/shipping/order/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ShippingOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformDeliveryControllerApi - functional programming interface
 * @export
 */
export const PlatformDeliveryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate delivery provider shipping price
         * @param {DeliveryCalculateRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCalculateUsingPOST(body: DeliveryCalculateRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryShippingPrice> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryCalculateUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get city list by delivery provider
         * @param {DeliveryCityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCityListUsingPOST(body: DeliveryCityRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryCity> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryCityListUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get delivery point list by delivery provider
         * @param {DeliveryPointRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryPointListUsingPOST(body: DeliveryPointRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryPoint> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryPointListUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} productOrderUid productOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryProductOrderDocumentUsingGET(documentType: string, productOrderUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Resource> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryProductOrderDocumentUsingGET(documentType, productOrderUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create intake request for shipping orders
         * @param {ShippingOrderIntakeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingDocumentUsingPOST(body: ShippingOrderIntakeRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryShippingOrder> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryShippingDocumentUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create shipping order
         * @param {CreateShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderCreateUsingPOST(body: CreateShippingOrderRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryShippingOrder> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryShippingOrderCreateUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} shippingOrderUid shippingOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderDocumentUsingGET(documentType: string, shippingOrderUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Resource> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryShippingOrderDocumentUsingGET(documentType, shippingOrderUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Shipping order list
         * @param {ShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderListUsingPOST(body: ShippingOrderRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDeliveryShippingOrder> {
            const localVarFetchArgs = PlatformDeliveryControllerApiFetchParamCreator(configuration).platformDeliveryShippingOrderListUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PlatformDeliveryControllerApi - factory interface
 * @export
 */
export const PlatformDeliveryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Calculate delivery provider shipping price
         * @param {DeliveryCalculateRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCalculateUsingPOST(body: DeliveryCalculateRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryCalculateUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get city list by delivery provider
         * @param {DeliveryCityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryCityListUsingPOST(body: DeliveryCityRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryCityListUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get delivery point list by delivery provider
         * @param {DeliveryPointRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryPointListUsingPOST(body: DeliveryPointRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryPointListUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} productOrderUid productOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryProductOrderDocumentUsingGET(documentType: string, productOrderUid: string, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryProductOrderDocumentUsingGET(documentType, productOrderUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create intake request for shipping orders
         * @param {ShippingOrderIntakeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingDocumentUsingPOST(body: ShippingOrderIntakeRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryShippingDocumentUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create shipping order
         * @param {CreateShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderCreateUsingPOST(body: CreateShippingOrderRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryShippingOrderCreateUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get shipping documents such as waybill or barcode
         * @param {string} documentType waybill|barcode
         * @param {string} shippingOrderUid shippingOrderUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderDocumentUsingGET(documentType: string, shippingOrderUid: string, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryShippingOrderDocumentUsingGET(documentType, shippingOrderUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Shipping order list
         * @param {ShippingOrderRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformDeliveryShippingOrderListUsingPOST(body: ShippingOrderRequest, language?: string, options?: any) {
            return PlatformDeliveryControllerApiFp(configuration).platformDeliveryShippingOrderListUsingPOST(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * PlatformDeliveryControllerApi - object-oriented interface
 * @export
 * @class PlatformDeliveryControllerApi
 * @extends {BaseAPI}
 */
export class PlatformDeliveryControllerApi extends BaseAPI {
    /**
     * 
     * @summary Calculate delivery provider shipping price
     * @param {DeliveryCalculateRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryCalculateUsingPOST(body: DeliveryCalculateRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryCalculateUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get city list by delivery provider
     * @param {DeliveryCityRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryCityListUsingPOST(body: DeliveryCityRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryCityListUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get delivery point list by delivery provider
     * @param {DeliveryPointRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryPointListUsingPOST(body: DeliveryPointRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryPointListUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get shipping documents such as waybill or barcode
     * @param {string} documentType waybill|barcode
     * @param {string} productOrderUid productOrderUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryProductOrderDocumentUsingGET(documentType: string, productOrderUid: string, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryProductOrderDocumentUsingGET(documentType, productOrderUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create intake request for shipping orders
     * @param {ShippingOrderIntakeRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryShippingDocumentUsingPOST(body: ShippingOrderIntakeRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryShippingDocumentUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create shipping order
     * @param {CreateShippingOrderRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryShippingOrderCreateUsingPOST(body: CreateShippingOrderRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryShippingOrderCreateUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get shipping documents such as waybill or barcode
     * @param {string} documentType waybill|barcode
     * @param {string} shippingOrderUid shippingOrderUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryShippingOrderDocumentUsingGET(documentType: string, shippingOrderUid: string, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryShippingOrderDocumentUsingGET(documentType, shippingOrderUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Shipping order list
     * @param {ShippingOrderRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformDeliveryControllerApi
     */
    public platformDeliveryShippingOrderListUsingPOST(body: ShippingOrderRequest, language?: string, options?: any) {
        return PlatformDeliveryControllerApiFp(this.configuration).platformDeliveryShippingOrderListUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

}
/**
 * PrivateProductControllerApi - fetch parameter creator
 * @export
 */
export const PrivateProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate delivery price
         * @param {CalculateDeliveryRequest} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST1(body: CalculateDeliveryRequest, accountUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling calculateDeliveryUsingPOST1.');
            }
            // verify required parameter 'accountUid' is not null or undefined
            if (accountUid === null || accountUid === undefined) {
                throw new RequiredError('accountUid','Required parameter accountUid was null or undefined when calling calculateDeliveryUsingPOST1.');
            }
            const localVarPath = `/private/product/calculateDelivery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CalculateDeliveryRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search product by external UID
         * @param {ExternalProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalProductListUsingPOST(body: ExternalProductRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling externalProductListUsingPOST.');
            }
            const localVarPath = `/private/product/external`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExternalProductRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get extended product info
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productExtInfoUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling productExtInfoUsingGET.');
            }
            const localVarPath = `/private/product/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Simple product list
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleProductListUsingPOST(body: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling simpleProductListUsingPOST.');
            }
            const localVarPath = `/private/product/simpleList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateProductControllerApi - functional programming interface
 * @export
 */
export const PrivateProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate delivery price
         * @param {CalculateDeliveryRequest} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST1(body: CalculateDeliveryRequest, accountUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseCalculateDeliveryResponse> {
            const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).calculateDeliveryUsingPOST1(body, accountUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search product by external UID
         * @param {ExternalProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalProductListUsingPOST(body: ExternalProductRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
            const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).externalProductListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get extended product info
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productExtInfoUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
            const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).productExtInfoUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Simple product list
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleProductListUsingPOST(body: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductExt> {
            const localVarFetchArgs = PrivateProductControllerApiFetchParamCreator(configuration).simpleProductListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PrivateProductControllerApi - factory interface
 * @export
 */
export const PrivateProductControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Calculate delivery price
         * @param {CalculateDeliveryRequest} body request
         * @param {string} accountUid accountUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateDeliveryUsingPOST1(body: CalculateDeliveryRequest, accountUid: string, options?: any) {
            return PrivateProductControllerApiFp(configuration).calculateDeliveryUsingPOST1(body, accountUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search product by external UID
         * @param {ExternalProductRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalProductListUsingPOST(body: ExternalProductRequest, options?: any) {
            return PrivateProductControllerApiFp(configuration).externalProductListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get extended product info
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productExtInfoUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return PrivateProductControllerApiFp(configuration).productExtInfoUsingGET(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Simple product list
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simpleProductListUsingPOST(body: Array<string>, options?: any) {
            return PrivateProductControllerApiFp(configuration).simpleProductListUsingPOST(body, options)(fetch, basePath);
        },
    };
};

/**
 * PrivateProductControllerApi - object-oriented interface
 * @export
 * @class PrivateProductControllerApi
 * @extends {BaseAPI}
 */
export class PrivateProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Calculate delivery price
     * @param {CalculateDeliveryRequest} body request
     * @param {string} accountUid accountUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateProductControllerApi
     */
    public calculateDeliveryUsingPOST1(body: CalculateDeliveryRequest, accountUid: string, options?: any) {
        return PrivateProductControllerApiFp(this.configuration).calculateDeliveryUsingPOST1(body, accountUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search product by external UID
     * @param {ExternalProductRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateProductControllerApi
     */
    public externalProductListUsingPOST(body: ExternalProductRequest, options?: any) {
        return PrivateProductControllerApiFp(this.configuration).externalProductListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get extended product info
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateProductControllerApi
     */
    public productExtInfoUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return PrivateProductControllerApiFp(this.configuration).productExtInfoUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Simple product list
     * @param {Array<string>} body productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateProductControllerApi
     */
    public simpleProductListUsingPOST(body: Array<string>, options?: any) {
        return PrivateProductControllerApiFp(this.configuration).simpleProductListUsingPOST(body, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductControllerApi - fetch parameter creator
 * @export
 */
export const ProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductUsingPUT(body: Product, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductUsingPUT.');
            }
            const localVarPath = `/product`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Product" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive active products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProductsUsingPOST(body: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling archiveProductsUsingPOST.');
            }
            const localVarPath = `/product/archive`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product image
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductImageUsingDELETE(body: ProductImageDeleteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteProductImageUsingDELETE.');
            }
            const localVarPath = `/product/image`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductImageDeleteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing product
         * @param {ProductUidListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(body: ProductUidListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteProductUsingDELETE.');
            }
            const localVarPath = `/product`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductUsingPOST(body: Product, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editProductUsingPOST.');
            }
            const localVarPath = `/product`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Product" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return account product stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProductStatsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/product/stat`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for matches by hashtags
         * @param {SearchRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hashtagPresearchUsingPOST(body: SearchRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling hashtagPresearchUsingPOST.');
            }
            const localVarPath = `/product/hashtag/presearch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SearchRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {ProductUidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDetailsUsingPOST(body: ProductUidRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productDetailsUsingPOST.');
            }
            const localVarPath = `/product/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productListUsingPOST(body: ProductListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productListUsingPOST.');
            }
            const localVarPath = `/product/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for matches by keywords
         * @param {SearchRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPresearchUsingPOST(body: SearchRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productPresearchUsingPOST.');
            }
            const localVarPath = `/product/presearch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SearchRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling productSearchDetailsUsingGET.');
            }
            const localVarPath = `/product/search/details/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST(body: ProductUidRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productSearchDetailsUsingPOST.');
            }
            const localVarPath = `/product/search/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST(body: ProductSearchListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productSearchUsingPOST.');
            }
            const localVarPath = `/product/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
         * @param {string} [country] country
         * @param {string} [currentProductUid] currentProductUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET(listType: string, country?: string, currentProductUid?: string, language?: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'listType' is not null or undefined
            if (listType === null || listType === undefined) {
                throw new RequiredError('listType','Required parameter listType was null or undefined when calling productWidgetListUsingGET.');
            }
            const localVarPath = `/product/list/{listType}`
                .replace(`{${"listType"}}`, encodeURIComponent(String(listType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (currentProductUid !== undefined) {
                localVarQueryParameter['currentProductUid'] = currentProductUid;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return random products by category
         * @param {RandomProductListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomProductSearchUsingPOST(body: RandomProductListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling randomProductSearchUsingPOST.');
            }
            const localVarPath = `/product/random`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RandomProductListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore archived products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreProductsUsingPOST(body: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling restoreProductsUsingPOST.');
            }
            const localVarPath = `/product/restore`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product expiration date
         * @param {ProductExpirationDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductExpirationDateUsingPOST(body: ProductExpirationDateRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductExpirationDateUsingPOST.');
            }
            const localVarPath = `/product/expiration`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductExpirationDateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product quantity
         * @param {ProductQuantityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductQuantityUsingPOST(body: ProductQuantityRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductQuantityUsingPOST.');
            }
            const localVarPath = `/product/quantity`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductQuantityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product seller email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerEmailUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/product/seller/email/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product seller phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerPhoneUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/product/seller/phone/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST1(body: ProductStatusRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductStatusUsingPOST1.');
            }
            const localVarPath = `/product/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductStatusRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductUsingPUT(body: Product, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).addProductUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Archive active products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProductsUsingPOST(body: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).archiveProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete product image
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductImageUsingDELETE(body: ProductImageDeleteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).deleteProductImageUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete existing product
         * @param {ProductUidListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(body: ProductUidListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).deleteProductUsingDELETE(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductUsingPOST(body: Product, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).editProductUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return account product stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProductStatsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccountProductStat> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).getAccountProductStatsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search for matches by hashtags
         * @param {SearchRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hashtagPresearchUsingPOST(body: SearchRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseHashtagSearchResult> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).hashtagPresearchUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {ProductUidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDetailsUsingPOST(body: ProductUidRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productDetailsUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productListUsingPOST(body: ProductListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productListUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search for matches by keywords
         * @param {SearchRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPresearchUsingPOST(body: SearchRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSearchResult> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productPresearchUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchDetailsUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST(body: ProductUidRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchDetailsUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST(body: ProductSearchListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productSearchUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
         * @param {string} [country] country
         * @param {string} [currentProductUid] currentProductUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET(listType: string, country?: string, currentProductUid?: string, language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).productWidgetListUsingGET(listType, country, currentProductUid, language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return random products by category
         * @param {RandomProductListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomProductSearchUsingPOST(body: RandomProductListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MultipleMarketplaceApiResponseProductAndProductCategory> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).randomProductSearchUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Restore archived products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreProductsUsingPOST(body: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).restoreProductsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product expiration date
         * @param {ProductExpirationDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductExpirationDateUsingPOST(body: ProductExpirationDateRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductExpirationDateUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product quantity
         * @param {ProductQuantityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductQuantityUsingPOST(body: ProductQuantityRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductQuantityUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product seller email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerEmailUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponse> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductSellerEmailUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product seller phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerPhoneUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponse> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductSellerPhoneUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST1(body: ProductStatusRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductControllerApiFetchParamCreator(configuration).updateProductStatusUsingPOST1(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add new product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductUsingPUT(body: Product, options?: any) {
            return ProductControllerApiFp(configuration).addProductUsingPUT(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Archive active products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProductsUsingPOST(body: Array<string>, options?: any) {
            return ProductControllerApiFp(configuration).archiveProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete product image
         * @param {ProductImageDeleteRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductImageUsingDELETE(body: ProductImageDeleteRequest, options?: any) {
            return ProductControllerApiFp(configuration).deleteProductImageUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete existing product
         * @param {ProductUidListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(body: ProductUidListRequest, options?: any) {
            return ProductControllerApiFp(configuration).deleteProductUsingDELETE(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing product
         * @param {Product} body product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProductUsingPOST(body: Product, options?: any) {
            return ProductControllerApiFp(configuration).editProductUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return account product stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountProductStatsUsingGET(options?: any) {
            return ProductControllerApiFp(configuration).getAccountProductStatsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search for matches by hashtags
         * @param {SearchRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hashtagPresearchUsingPOST(body: SearchRequest, options?: any) {
            return ProductControllerApiFp(configuration).hashtagPresearchUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product details
         * @param {ProductUidRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productDetailsUsingPOST(body: ProductUidRequest, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).productDetailsUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product list
         * @param {ProductListRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productListUsingPOST(body: ProductListRequest, options?: any) {
            return ProductControllerApiFp(configuration).productListUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search for matches by keywords
         * @param {SearchRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productPresearchUsingPOST(body: SearchRequest, country?: string, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).productPresearchUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).productSearchDetailsUsingGET(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST(body: ProductUidRequest, country?: string, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).productSearchDetailsUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST(body: ProductSearchListRequest, country?: string, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).productSearchUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
         * @param {string} [country] country
         * @param {string} [currentProductUid] currentProductUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET(listType: string, country?: string, currentProductUid?: string, language?: string, type?: string, options?: any) {
            return ProductControllerApiFp(configuration).productWidgetListUsingGET(listType, country, currentProductUid, language, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return random products by category
         * @param {RandomProductListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        randomProductSearchUsingPOST(body: RandomProductListRequest, country?: string, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).randomProductSearchUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Restore archived products
         * @param {Array<string>} body productUidList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreProductsUsingPOST(body: Array<string>, options?: any) {
            return ProductControllerApiFp(configuration).restoreProductsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product expiration date
         * @param {ProductExpirationDateRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductExpirationDateUsingPOST(body: ProductExpirationDateRequest, options?: any) {
            return ProductControllerApiFp(configuration).updateProductExpirationDateUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product quantity
         * @param {ProductQuantityRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductQuantityUsingPOST(body: ProductQuantityRequest, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).updateProductQuantityUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product seller email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerEmailUsingPOST(options?: any) {
            return ProductControllerApiFp(configuration).updateProductSellerEmailUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product seller phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSellerPhoneUsingPOST(options?: any) {
            return ProductControllerApiFp(configuration).updateProductSellerPhoneUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update product status
         * @param {ProductStatusRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductStatusUsingPOST1(body: ProductStatusRequest, language?: string, options?: any) {
            return ProductControllerApiFp(configuration).updateProductStatusUsingPOST1(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new product
     * @param {Product} body product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public addProductUsingPUT(body: Product, options?: any) {
        return ProductControllerApiFp(this.configuration).addProductUsingPUT(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Archive active products
     * @param {Array<string>} body productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public archiveProductsUsingPOST(body: Array<string>, options?: any) {
        return ProductControllerApiFp(this.configuration).archiveProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete product image
     * @param {ProductImageDeleteRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public deleteProductImageUsingDELETE(body: ProductImageDeleteRequest, options?: any) {
        return ProductControllerApiFp(this.configuration).deleteProductImageUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete existing product
     * @param {ProductUidListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public deleteProductUsingDELETE(body: ProductUidListRequest, options?: any) {
        return ProductControllerApiFp(this.configuration).deleteProductUsingDELETE(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing product
     * @param {Product} body product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public editProductUsingPOST(body: Product, options?: any) {
        return ProductControllerApiFp(this.configuration).editProductUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return account product stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getAccountProductStatsUsingGET(options?: any) {
        return ProductControllerApiFp(this.configuration).getAccountProductStatsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search for matches by hashtags
     * @param {SearchRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public hashtagPresearchUsingPOST(body: SearchRequest, options?: any) {
        return ProductControllerApiFp(this.configuration).hashtagPresearchUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product details
     * @param {ProductUidRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productDetailsUsingPOST(body: ProductUidRequest, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productDetailsUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product list
     * @param {ProductListRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productListUsingPOST(body: ProductListRequest, options?: any) {
        return ProductControllerApiFp(this.configuration).productListUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search for matches by keywords
     * @param {SearchRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productPresearchUsingPOST(body: SearchRequest, country?: string, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productPresearchUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productSearchDetailsUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productSearchDetailsUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get published product details
     * @param {ProductUidRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productSearchDetailsUsingPOST(body: ProductUidRequest, country?: string, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productSearchDetailsUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productSearchUsingPOST(body: ProductSearchListRequest, country?: string, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productSearchUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|deals|recently_viewed|hot_deals|recommended|bestsellers
     * @param {string} [country] country
     * @param {string} [currentProductUid] currentProductUid
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public productWidgetListUsingGET(listType: string, country?: string, currentProductUid?: string, language?: string, type?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).productWidgetListUsingGET(listType, country, currentProductUid, language, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return random products by category
     * @param {RandomProductListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public randomProductSearchUsingPOST(body: RandomProductListRequest, country?: string, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).randomProductSearchUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Restore archived products
     * @param {Array<string>} body productUidList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public restoreProductsUsingPOST(body: Array<string>, options?: any) {
        return ProductControllerApiFp(this.configuration).restoreProductsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product expiration date
     * @param {ProductExpirationDateRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProductExpirationDateUsingPOST(body: ProductExpirationDateRequest, options?: any) {
        return ProductControllerApiFp(this.configuration).updateProductExpirationDateUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product quantity
     * @param {ProductQuantityRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProductQuantityUsingPOST(body: ProductQuantityRequest, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).updateProductQuantityUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product seller email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProductSellerEmailUsingPOST(options?: any) {
        return ProductControllerApiFp(this.configuration).updateProductSellerEmailUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product seller phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProductSellerPhoneUsingPOST(options?: any) {
        return ProductControllerApiFp(this.configuration).updateProductSellerPhoneUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update product status
     * @param {ProductStatusRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public updateProductStatusUsingPOST1(body: ProductStatusRequest, language?: string, options?: any) {
        return ProductControllerApiFp(this.configuration).updateProductStatusUsingPOST1(body, language, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductPageControllerApi - fetch parameter creator
 * @export
 */
export const ProductPageControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add product to page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToPageUsingPUT(body: ProductPage, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductToPageUsingPUT.');
            }
            const localVarPath = `/product/page`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductPage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove product from page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromPageUsingDELETE(body: ProductPage, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteProductFromPageUsingDELETE.');
            }
            const localVarPath = `/product/page`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductPage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product list by page
         * @param {string} pageCode pageCode
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductListByPageUsingGET(pageCode: string, country?: string, language?: string, rowLimit?: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageCode' is not null or undefined
            if (pageCode === null || pageCode === undefined) {
                throw new RequiredError('pageCode','Required parameter pageCode was null or undefined when calling getProductListByPageUsingGET.');
            }
            const localVarPath = `/product/page/{pageCode}`
                .replace(`{${"pageCode"}}`, encodeURIComponent(String(pageCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (rowLimit !== undefined) {
                localVarQueryParameter['rowLimit'] = rowLimit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPageControllerApi - functional programming interface
 * @export
 */
export const ProductPageControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add product to page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToPageUsingPUT(body: ProductPage, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductPageControllerApiFetchParamCreator(configuration).addProductToPageUsingPUT(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Remove product from page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromPageUsingDELETE(body: ProductPage, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductPageControllerApiFetchParamCreator(configuration).deleteProductFromPageUsingDELETE(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product list by page
         * @param {string} pageCode pageCode
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductListByPageUsingGET(pageCode: string, country?: string, language?: string, rowLimit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = ProductPageControllerApiFetchParamCreator(configuration).getProductListByPageUsingGET(pageCode, country, language, rowLimit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductPageControllerApi - factory interface
 * @export
 */
export const ProductPageControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add product to page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductToPageUsingPUT(body: ProductPage, country?: string, language?: string, options?: any) {
            return ProductPageControllerApiFp(configuration).addProductToPageUsingPUT(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Remove product from page
         * @param {ProductPage} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromPageUsingDELETE(body: ProductPage, country?: string, language?: string, options?: any) {
            return ProductPageControllerApiFp(configuration).deleteProductFromPageUsingDELETE(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product list by page
         * @param {string} pageCode pageCode
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductListByPageUsingGET(pageCode: string, country?: string, language?: string, rowLimit?: number, options?: any) {
            return ProductPageControllerApiFp(configuration).getProductListByPageUsingGET(pageCode, country, language, rowLimit, options)(fetch, basePath);
        },
    };
};

/**
 * ProductPageControllerApi - object-oriented interface
 * @export
 * @class ProductPageControllerApi
 * @extends {BaseAPI}
 */
export class ProductPageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add product to page
     * @param {ProductPage} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPageControllerApi
     */
    public addProductToPageUsingPUT(body: ProductPage, country?: string, language?: string, options?: any) {
        return ProductPageControllerApiFp(this.configuration).addProductToPageUsingPUT(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Remove product from page
     * @param {ProductPage} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPageControllerApi
     */
    public deleteProductFromPageUsingDELETE(body: ProductPage, country?: string, language?: string, options?: any) {
        return ProductPageControllerApiFp(this.configuration).deleteProductFromPageUsingDELETE(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product list by page
     * @param {string} pageCode pageCode
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPageControllerApi
     */
    public getProductListByPageUsingGET(pageCode: string, country?: string, language?: string, rowLimit?: number, options?: any) {
        return ProductPageControllerApiFp(this.configuration).getProductListByPageUsingGET(pageCode, country, language, rowLimit, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductReviewControllerApi - fetch parameter creator
 * @export
 */
export const ProductReviewControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete product review image
         * @param {string} imageUid imageUid
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE(imageUid: string, productUid: string, reviewUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'imageUid' is not null or undefined
            if (imageUid === null || imageUid === undefined) {
                throw new RequiredError('imageUid','Required parameter imageUid was null or undefined when calling addProductReviewImageUsingDELETE.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductReviewImageUsingDELETE.');
            }
            // verify required parameter 'reviewUid' is not null or undefined
            if (reviewUid === null || reviewUid === undefined) {
                throw new RequiredError('reviewUid','Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingDELETE.');
            }
            const localVarPath = `/product/{productUid}/review/{reviewUid}/image/{imageUid}`
                .replace(`{${"imageUid"}}`, encodeURIComponent(String(imageUid)))
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
                .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product review
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductReviewImageUsingDELETE1.');
            }
            // verify required parameter 'reviewUid' is not null or undefined
            if (reviewUid === null || reviewUid === undefined) {
                throw new RequiredError('reviewUid','Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingDELETE1.');
            }
            const localVarPath = `/product/{productUid}/review/{reviewUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
                .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product review image
         * @param {AddProductReviewImageRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingPUT(body: AddProductReviewImageRequest, productUid: string, reviewUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductReviewImageUsingPUT.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductReviewImageUsingPUT.');
            }
            // verify required parameter 'reviewUid' is not null or undefined
            if (reviewUid === null || reviewUid === undefined) {
                throw new RequiredError('reviewUid','Required parameter reviewUid was null or undefined when calling addProductReviewImageUsingPUT.');
            }
            const localVarPath = `/product/{productUid}/review/{reviewUid}/image`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
                .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddProductReviewImageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit product review
         * @param {EditProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPOST(body: EditProductReviewRequest, productUid: string, reviewUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductReviewUsingPOST.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductReviewUsingPOST.');
            }
            // verify required parameter 'reviewUid' is not null or undefined
            if (reviewUid === null || reviewUid === undefined) {
                throw new RequiredError('reviewUid','Required parameter reviewUid was null or undefined when calling addProductReviewUsingPOST.');
            }
            const localVarPath = `/product/{productUid}/review/{reviewUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)))
                .replace(`{${"reviewUid"}}`, encodeURIComponent(String(reviewUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditProductReviewRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product review
         * @param {AddProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPUT(body: AddProductReviewRequest, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductReviewUsingPUT.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addProductReviewUsingPUT.');
            }
            const localVarPath = `/product/{productUid}/review`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddProductReviewRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all reviews by account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getAccountReviewsUsingPOST.');
            }
            const localVarPath = `/product/reviews`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductReviewAccountListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of product reviews
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {string} [orderUid] orderUid
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingGET(productUid: string, language?: string, orderUid?: string, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling getProductReviewsUsingGET.');
            }
            const localVarPath = `/product/{productUid}/review`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (orderUid !== undefined) {
                localVarQueryParameter['orderUid'] = orderUid;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of unreviewed products
         * @param {number} [count] count
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreviewedProductsUsingGET(count?: number, language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/product/unreviewed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductReviewControllerApi - functional programming interface
 * @export
 */
export const ProductReviewControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete product review image
         * @param {string} imageUid imageUid
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE(imageUid: string, productUid: string, reviewUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewImageUsingDELETE(imageUid, productUid, reviewUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete product review
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewImageUsingDELETE1(productUid, reviewUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product review image
         * @param {AddProductReviewImageRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingPUT(body: AddProductReviewImageRequest, productUid: string, reviewUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewImageUsingPUT(body, productUid, reviewUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit product review
         * @param {EditProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPOST(body: EditProductReviewRequest, productUid: string, reviewUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewUsingPOST(body, productUid, reviewUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product review
         * @param {AddProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPUT(body: AddProductReviewRequest, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).addProductReviewUsingPUT(body, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of all reviews by account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).getAccountReviewsUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of product reviews
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {string} [orderUid] orderUid
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingGET(productUid: string, language?: string, orderUid?: string, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).getProductReviewsUsingGET(productUid, language, orderUid, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of unreviewed products
         * @param {number} [count] count
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreviewedProductsUsingGET(count?: number, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseUnreviewedProduct> {
            const localVarFetchArgs = ProductReviewControllerApiFetchParamCreator(configuration).getUnreviewedProductsUsingGET(count, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductReviewControllerApi - factory interface
 * @export
 */
export const ProductReviewControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Delete product review image
         * @param {string} imageUid imageUid
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE(imageUid: string, productUid: string, reviewUid: string, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingDELETE(imageUid, productUid, reviewUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete product review
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingDELETE1(productUid, reviewUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product review image
         * @param {AddProductReviewImageRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewImageUsingPUT(body: AddProductReviewImageRequest, productUid: string, reviewUid: string, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).addProductReviewImageUsingPUT(body, productUid, reviewUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit product review
         * @param {EditProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} reviewUid reviewUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPOST(body: EditProductReviewRequest, productUid: string, reviewUid: string, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).addProductReviewUsingPOST(body, productUid, reviewUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product review
         * @param {AddProductReviewRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductReviewUsingPUT(body: AddProductReviewRequest, productUid: string, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).addProductReviewUsingPUT(body, productUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of all reviews by account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).getAccountReviewsUsingPOST(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of product reviews
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {string} [orderUid] orderUid
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductReviewsUsingGET(productUid: string, language?: string, orderUid?: string, sort?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).getProductReviewsUsingGET(productUid, language, orderUid, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of unreviewed products
         * @param {number} [count] count
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreviewedProductsUsingGET(count?: number, language?: string, options?: any) {
            return ProductReviewControllerApiFp(configuration).getUnreviewedProductsUsingGET(count, language, options)(fetch, basePath);
        },
    };
};

/**
 * ProductReviewControllerApi - object-oriented interface
 * @export
 * @class ProductReviewControllerApi
 * @extends {BaseAPI}
 */
export class ProductReviewControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete product review image
     * @param {string} imageUid imageUid
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public addProductReviewImageUsingDELETE(imageUid: string, productUid: string, reviewUid: string, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingDELETE(imageUid, productUid, reviewUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete product review
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public addProductReviewImageUsingDELETE1(productUid: string, reviewUid: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingDELETE1(productUid, reviewUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product review image
     * @param {AddProductReviewImageRequest} body request
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public addProductReviewImageUsingPUT(body: AddProductReviewImageRequest, productUid: string, reviewUid: string, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).addProductReviewImageUsingPUT(body, productUid, reviewUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit product review
     * @param {EditProductReviewRequest} body request
     * @param {string} productUid productUid
     * @param {string} reviewUid reviewUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public addProductReviewUsingPOST(body: EditProductReviewRequest, productUid: string, reviewUid: string, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).addProductReviewUsingPOST(body, productUid, reviewUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product review
     * @param {AddProductReviewRequest} body request
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public addProductReviewUsingPUT(body: AddProductReviewRequest, productUid: string, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).addProductReviewUsingPUT(body, productUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of all reviews by account
     * @param {ProductReviewAccountListRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public getAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).getAccountReviewsUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of product reviews
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {string} [orderUid] orderUid
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public getProductReviewsUsingGET(productUid: string, language?: string, orderUid?: string, sort?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).getProductReviewsUsingGET(productUid, language, orderUid, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of unreviewed products
     * @param {number} [count] count
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductReviewControllerApi
     */
    public getUnreviewedProductsUsingGET(count?: number, language?: string, options?: any) {
        return ProductReviewControllerApiFp(this.configuration).getUnreviewedProductsUsingGET(count, language, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductSourceControllerApi - fetch parameter creator
 * @export
 */
export const ProductSourceControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account product category mapping
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProductCategoryListUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/product/import/category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT1(body: ProductSource, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductSourceUsingPUT1.');
            }
            const localVarPath = `/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE1(body: UidRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteProductSourceUsingDELETE1.');
            }
            const localVarPath = `/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling getProductSourceUsingGET1.');
            }
            const localVarPath = `/product/import/source/{sourceUid}`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling productSourceHistoryUsingGET1.');
            }
            const localVarPath = `/product/import/source/{sourceUid}/history`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET1(options: any = {}): FetchArgs {
            const localVarPath = `/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of product sources types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceTypeListUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/product/import/source/type`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update account product category mapping
         * @param {Array<AccountCategory>} body categoryMapping
         * @param {boolean} [deleteUnused] deleteUnused
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProductCategoryUsingPOST(body: Array<AccountCategory>, deleteUnused?: boolean, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAccountProductCategoryUsingPOST.');
            }
            const localVarPath = `/product/import/category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (deleteUnused !== undefined) {
                localVarQueryParameter['deleteUnused'] = deleteUnused;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;AccountCategory&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST1(body: ProductSource, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductSourceUsingPOST1.');
            }
            const localVarPath = `/product/import/source`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSource" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET1(sourceUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'sourceUid' is not null or undefined
            if (sourceUid === null || sourceUid === undefined) {
                throw new RequiredError('sourceUid','Required parameter sourceUid was null or undefined when calling validateProductSourceUsingGET1.');
            }
            const localVarPath = `/product/import/source/{sourceUid}/validate`
                .replace(`{${"sourceUid"}}`, encodeURIComponent(String(sourceUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductSourceControllerApi - functional programming interface
 * @export
 */
export const ProductSourceControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account product category mapping
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProductCategoryListUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccountCategory> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).accountProductCategoryListUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT1(body: ProductSource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).addProductSourceUsingPUT1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE1(body: UidRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).deleteProductSourceUsingDELETE1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET1(sourceUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).getProductSourceUsingGET1(sourceUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET1(sourceUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).productSourceHistoryUsingGET1(sourceUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).productSourceListUsingGET1(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of product sources types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceTypeListUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceType> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).productSourceTypeListUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update account product category mapping
         * @param {Array<AccountCategory>} body categoryMapping
         * @param {boolean} [deleteUnused] deleteUnused
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProductCategoryUsingPOST(body: Array<AccountCategory>, deleteUnused?: boolean, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseAccountCategory> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).updateAccountProductCategoryUsingPOST(body, deleteUnused, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST1(body: ProductSource, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSource> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).updateProductSourceUsingPOST1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET1(sourceUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductSourceContent> {
            const localVarFetchArgs = ProductSourceControllerApiFetchParamCreator(configuration).validateProductSourceUsingGET1(sourceUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductSourceControllerApi - factory interface
 * @export
 */
export const ProductSourceControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account product category mapping
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountProductCategoryListUsingGET(language?: string, options?: any) {
            return ProductSourceControllerApiFp(configuration).accountProductCategoryListUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductSourceUsingPUT1(body: ProductSource, options?: any) {
            return ProductSourceControllerApiFp(configuration).addProductSourceUsingPUT1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete existing product sources
         * @param {UidRequest} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductSourceUsingDELETE1(body: UidRequest, options?: any) {
            return ProductSourceControllerApiFp(configuration).deleteProductSourceUsingDELETE1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return product sources details
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductSourceUsingGET1(sourceUid: string, options?: any) {
            return ProductSourceControllerApiFp(configuration).getProductSourceUsingGET1(sourceUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return product sources import history (10 max)
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceHistoryUsingGET1(sourceUid: string, options?: any) {
            return ProductSourceControllerApiFp(configuration).productSourceHistoryUsingGET1(sourceUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of product sources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceListUsingGET1(options?: any) {
            return ProductSourceControllerApiFp(configuration).productSourceListUsingGET1(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of product sources types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSourceTypeListUsingGET(options?: any) {
            return ProductSourceControllerApiFp(configuration).productSourceTypeListUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update account product category mapping
         * @param {Array<AccountCategory>} body categoryMapping
         * @param {boolean} [deleteUnused] deleteUnused
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountProductCategoryUsingPOST(body: Array<AccountCategory>, deleteUnused?: boolean, language?: string, options?: any) {
            return ProductSourceControllerApiFp(configuration).updateAccountProductCategoryUsingPOST(body, deleteUnused, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing product sources
         * @param {ProductSource} body source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductSourceUsingPOST1(body: ProductSource, options?: any) {
            return ProductSourceControllerApiFp(configuration).updateProductSourceUsingPOST1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Validate product source
         * @param {string} sourceUid sourceUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductSourceUsingGET1(sourceUid: string, options?: any) {
            return ProductSourceControllerApiFp(configuration).validateProductSourceUsingGET1(sourceUid, options)(fetch, basePath);
        },
    };
};

/**
 * ProductSourceControllerApi - object-oriented interface
 * @export
 * @class ProductSourceControllerApi
 * @extends {BaseAPI}
 */
export class ProductSourceControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account product category mapping
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public accountProductCategoryListUsingGET(language?: string, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).accountProductCategoryListUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new product sources
     * @param {ProductSource} body source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public addProductSourceUsingPUT1(body: ProductSource, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).addProductSourceUsingPUT1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete existing product sources
     * @param {UidRequest} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public deleteProductSourceUsingDELETE1(body: UidRequest, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).deleteProductSourceUsingDELETE1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return product sources details
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public getProductSourceUsingGET1(sourceUid: string, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).getProductSourceUsingGET1(sourceUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return product sources import history (10 max)
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public productSourceHistoryUsingGET1(sourceUid: string, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).productSourceHistoryUsingGET1(sourceUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of product sources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public productSourceListUsingGET1(options?: any) {
        return ProductSourceControllerApiFp(this.configuration).productSourceListUsingGET1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of product sources types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public productSourceTypeListUsingGET(options?: any) {
        return ProductSourceControllerApiFp(this.configuration).productSourceTypeListUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update account product category mapping
     * @param {Array<AccountCategory>} body categoryMapping
     * @param {boolean} [deleteUnused] deleteUnused
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public updateAccountProductCategoryUsingPOST(body: Array<AccountCategory>, deleteUnused?: boolean, language?: string, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).updateAccountProductCategoryUsingPOST(body, deleteUnused, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing product sources
     * @param {ProductSource} body source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public updateProductSourceUsingPOST1(body: ProductSource, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).updateProductSourceUsingPOST1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Validate product source
     * @param {string} sourceUid sourceUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductSourceControllerApi
     */
    public validateProductSourceUsingGET1(sourceUid: string, options?: any) {
        return ProductSourceControllerApiFp(this.configuration).validateProductSourceUsingGET1(sourceUid, options)(this.fetch, this.basePath);
    }

}
/**
 * ProductStoreControllerApi - fetch parameter creator
 * @export
 */
export const ProductStoreControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/store/short`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET(country?: string, language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/store`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST(body: StoreRatingRequest, uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountStoreRatingUsingPOST.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling accountStoreRatingUsingPOST.');
            }
            const localVarPath = `/store/{uid}/rating`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreRatingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling accountStoreUsingGET.');
            }
            const localVarPath = `/store/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageProductUsingPUT.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageProductUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageProductsUsingPUT.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductsUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageProductsUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/products`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProductsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT(storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWidgetUsingPUT.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWidgetsUsingPUT.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widgets`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageWidget&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT(body: StoreHomepage, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWithDetailsUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWithDetailsUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/full`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT(body: Store, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreUsingPUT.');
            }
            const localVarPath = `/store`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Store" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST(body: StoreCheck, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkAccountStoreUsingPOST.');
            }
            const localVarPath = `/store/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreCheck" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStoreHomepageBannerUsingPUT.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling createStoreHomepageBannerUsingPUT.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling createStoreHomepageBannerUsingPUT.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE(storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteAllStoreHomepagesUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageBannerUsingDELETE.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageProductUsingDELETE.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageProductsUsingDELETE.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/products`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageProduct&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widgets`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageWidget&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST(body: StoreHomepage, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editStoreHomepageDetailsUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling editStoreHomepageDetailsUsingPOST.');
            }
            const localVarPath = `/store/{storeUid}/homepage/full`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getHomepageUsingGET.');
            }
            const localVarPath = `/store/{uid}/homepage`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST(body: StoreListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getPublicStoreListUsingPOST.');
            }
            const localVarPath = `/store/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling getStoreHomepageByUidUsingGET.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getStoreHomepageByUidUsingGET.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{uid}`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST(body: StoreCategoryListRequest, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getStoreProductCategoryListUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling getStoreProductCategoryListUsingPOST.');
            }
            const localVarPath = `/store/{storeUid}/category`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreCategoryListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST(homepageUid: string, uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'homepageUid' is not null or undefined
            if (homepageUid === null || homepageUid === undefined) {
                throw new RequiredError('homepageUid','Required parameter homepageUid was null or undefined when calling publishStoreHomepageUsingPOST.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling publishStoreHomepageUsingPOST.');
            }
            const localVarPath = `/store/{uid}/homepage/{homepageUid}/publish`
                .replace(`{${"homepageUid"}}`, encodeURIComponent(String(homepageUid)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST(body: StoreSearchListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling storeSearchUsingPOST.');
            }
            const localVarPath = `/store/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageBannerUsingPOST.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageBannerUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageBannerUsingPOST.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageProductUsingPOST.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageProductUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageProductUsingPOST.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageWidgetUsingPOST.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST.');
            }
            const localVarPath = `/store/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST(body: Store, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreUsingPOST.');
            }
            const localVarPath = `/store`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Store" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductStoreControllerApi - functional programming interface
 * @export
 */
export const ProductStoreControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).accountShortStoreInfoListUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET(country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).accountStoreListUsingGET(country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST(body: StoreRatingRequest, uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreRating> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).accountStoreRatingUsingPOST(body, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).accountStoreUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageProductUsingPUT(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageProductsUsingPUT(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT(storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageUsingPUT(storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetUsingPUT(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetsUsingPUT(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT(body: StoreHomepage, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreHomepageWithDetailsUsingPUT(body, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT(body: Store, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).addStoreUsingPUT(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST(body: StoreCheck, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreCheck> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).checkAccountStoreUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).createStoreHomepageBannerUsingPUT(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE(storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteAllStoreHomepagesUsingDELETE(storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageBannerUsingDELETE(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageProductUsingDELETE(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageProductsUsingDELETE(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageUsingDELETE(storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageWidgetUsingDELETE(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).deleteStoreHomepageWidgetsUsingDELETE(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST(body: StoreHomepage, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).editStoreHomepageDetailsUsingPOST(body, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).getHomepageUsingGET(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST(body: StoreListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).getPublicStoreListUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).getStoreHomepageByUidUsingGET(storeUid, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST(body: StoreCategoryListRequest, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseString> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).getStoreProductCategoryListUsingPOST(body, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST(homepageUid: string, uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).publishStoreHomepageUsingPOST(homepageUid, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST(body: StoreSearchListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseString> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).storeSearchUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).updateStoreHomepageBannerUsingPOST(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).updateStoreHomepageProductUsingPOST(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).updateStoreHomepageWidgetUsingPOST(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST(body: Store, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ProductStoreControllerApiFetchParamCreator(configuration).updateStoreUsingPOST(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProductStoreControllerApi - factory interface
 * @export
 */
export const ProductStoreControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET(language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).accountShortStoreInfoListUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET(country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).accountStoreListUsingGET(country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST(body: StoreRatingRequest, uid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).accountStoreRatingUsingPOST(body, uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).accountStoreUsingGET(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageProductUsingPUT(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageProductsUsingPUT(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT(storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageUsingPUT(storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageWidgetUsingPUT(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageWidgetsUsingPUT(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreHomepageWithDetailsUsingPUT(body, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT(body: Store, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).addStoreUsingPUT(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST(body: StoreCheck, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).checkAccountStoreUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).createStoreHomepageBannerUsingPUT(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE(storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteAllStoreHomepagesUsingDELETE(storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageBannerUsingDELETE(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageProductUsingDELETE(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageProductsUsingDELETE(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageUsingDELETE(storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageWidgetUsingDELETE(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).deleteStoreHomepageWidgetsUsingDELETE(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).editStoreHomepageDetailsUsingPOST(body, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET(uid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).getHomepageUsingGET(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST(body: StoreListRequest, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).getPublicStoreListUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).getStoreHomepageByUidUsingGET(storeUid, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST(body: StoreCategoryListRequest, storeUid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).getStoreProductCategoryListUsingPOST(body, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST(homepageUid: string, uid: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).publishStoreHomepageUsingPOST(homepageUid, uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST(body: StoreSearchListRequest, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).storeSearchUsingPOST(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).updateStoreHomepageBannerUsingPOST(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).updateStoreHomepageProductUsingPOST(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).updateStoreHomepageWidgetUsingPOST(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST(body: Store, country?: string, language?: string, options?: any) {
            return ProductStoreControllerApiFp(configuration).updateStoreUsingPOST(body, country, language, options)(fetch, basePath);
        },
    };
};

/**
 * ProductStoreControllerApi - object-oriented interface
 * @export
 * @class ProductStoreControllerApi
 * @extends {BaseAPI}
 */
export class ProductStoreControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account store list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public accountShortStoreInfoListUsingGET(language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).accountShortStoreInfoListUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public accountStoreListUsingGET(country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).accountStoreListUsingGET(country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store rating statistics
     * @param {StoreRatingRequest} body request
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public accountStoreRatingUsingPOST(body: StoreRatingRequest, uid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).accountStoreRatingUsingPOST(body, uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public accountStoreUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).accountStoreUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product to account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageProductUsingPUT(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageProductUsingPUT(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product list to account store homepage
     * @param {StoreHomepageProductsRequest} body request
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageProductsUsingPUT(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageProductsUsingPUT(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageUsingPUT(storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageUsingPUT(storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add widget to account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageWidgetUsingPUT(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageWidgetUsingPUT(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add widget to account store homepage
     * @param {Array<StoreHomepageWidget>} body widgets
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageWidgetsUsingPUT(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageWidgetsUsingPUT(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store homepage with details
     * @param {StoreHomepage} body homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreHomepageWithDetailsUsingPUT(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreHomepageWithDetailsUsingPUT(body, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store
     * @param {Store} body store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public addStoreUsingPUT(body: Store, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).addStoreUsingPUT(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check account name or code availability
     * @param {StoreCheck} body check
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public checkAccountStoreUsingPOST(body: StoreCheck, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).checkAccountStoreUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public createStoreHomepageBannerUsingPUT(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).createStoreHomepageBannerUsingPUT(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete account store homepage
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteAllStoreHomepagesUsingDELETE(storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteAllStoreHomepagesUsingDELETE(storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageBannerUsingDELETE(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageBannerUsingDELETE(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete product in account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageProductUsingDELETE(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageProductUsingDELETE(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete products in account store homepage
     * @param {Array<StoreHomepageProduct>} body products
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageProductsUsingDELETE(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageProductsUsingDELETE(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageUsingDELETE(storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageUsingDELETE(storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete widget in account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageWidgetUsingDELETE(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageWidgetUsingDELETE(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete widgets in account store homepage
     * @param {Array<StoreHomepageWidget>} body widgets
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public deleteStoreHomepageWidgetsUsingDELETE(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).deleteStoreHomepageWidgetsUsingDELETE(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit account store homepage with details
     * @param {StoreHomepage} body homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public editStoreHomepageDetailsUsingPOST(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).editStoreHomepageDetailsUsingPOST(body, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store homepage
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public getHomepageUsingGET(uid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).getHomepageUsingGET(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get public store list
     * @param {StoreListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public getPublicStoreListUsingPOST(body: StoreListRequest, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).getPublicStoreListUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public getStoreHomepageByUidUsingGET(storeUid: string, uid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).getStoreHomepageByUidUsingGET(storeUid, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get store product category list
     * @param {StoreCategoryListRequest} body request
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public getStoreProductCategoryListUsingPOST(body: StoreCategoryListRequest, storeUid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).getStoreProductCategoryListUsingPOST(body, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Publish account store homepage
     * @param {string} homepageUid homepageUid
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public publishStoreHomepageUsingPOST(homepageUid: string, uid: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).publishStoreHomepageUsingPOST(homepageUid, uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get store list
     * @param {StoreSearchListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public storeSearchUsingPOST(body: StoreSearchListRequest, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).storeSearchUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public updateStoreHomepageBannerUsingPOST(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).updateStoreHomepageBannerUsingPOST(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit product in account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public updateStoreHomepageProductUsingPOST(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).updateStoreHomepageProductUsingPOST(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit widget in account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public updateStoreHomepageWidgetUsingPOST(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).updateStoreHomepageWidgetUsingPOST(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing account store
     * @param {Store} body store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductStoreControllerApi
     */
    public updateStoreUsingPOST(body: Store, country?: string, language?: string, options?: any) {
        return ProductStoreControllerApiFp(this.configuration).updateStoreUsingPOST(body, country, language, options)(this.fetch, this.basePath);
    }

}
/**
 * PromotionPageControllerApi - fetch parameter creator
 * @export
 */
export const PromotionPageControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get promotion page
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getPageUsingGET.');
            }
            const localVarPath = `/promotionpage/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionPageControllerApi - functional programming interface
 * @export
 */
export const PromotionPageControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get promotion page
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponsePromotionPage> {
            const localVarFetchArgs = PromotionPageControllerApiFetchParamCreator(configuration).getPageUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PromotionPageControllerApi - factory interface
 * @export
 */
export const PromotionPageControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get promotion page
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return PromotionPageControllerApiFp(configuration).getPageUsingGET(uid, country, language, options)(fetch, basePath);
        },
    };
};

/**
 * PromotionPageControllerApi - object-oriented interface
 * @export
 * @class PromotionPageControllerApi
 * @extends {BaseAPI}
 */
export class PromotionPageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get promotion page
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionPageControllerApi
     */
    public getPageUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return PromotionPageControllerApiFp(this.configuration).getPageUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

}
/**
 * PublicArticleControllerApi - fetch parameter creator
 * @export
 */
export const PublicArticleControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return article
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getArticleUsingGET.');
            }
            const localVarPath = `/public/article/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return article list
         * @param {string} [language] language
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesUsingGET(language?: string, limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/public/article`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicArticleControllerApi - functional programming interface
 * @export
 */
export const PublicArticleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return article
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleUsingGET(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseArticle> {
            const localVarFetchArgs = PublicArticleControllerApiFetchParamCreator(configuration).getArticleUsingGET(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return article list
         * @param {string} [language] language
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesUsingGET(language?: string, limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseArticle> {
            const localVarFetchArgs = PublicArticleControllerApiFetchParamCreator(configuration).getArticlesUsingGET(language, limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublicArticleControllerApi - factory interface
 * @export
 */
export const PublicArticleControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Return article
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleUsingGET(uid: string, language?: string, options?: any) {
            return PublicArticleControllerApiFp(configuration).getArticleUsingGET(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return article list
         * @param {string} [language] language
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesUsingGET(language?: string, limit?: number, options?: any) {
            return PublicArticleControllerApiFp(configuration).getArticlesUsingGET(language, limit, options)(fetch, basePath);
        },
    };
};

/**
 * PublicArticleControllerApi - object-oriented interface
 * @export
 * @class PublicArticleControllerApi
 * @extends {BaseAPI}
 */
export class PublicArticleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return article
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicArticleControllerApi
     */
    public getArticleUsingGET(uid: string, language?: string, options?: any) {
        return PublicArticleControllerApiFp(this.configuration).getArticleUsingGET(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return article list
     * @param {string} [language] language
     * @param {number} [limit] limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicArticleControllerApi
     */
    public getArticlesUsingGET(language?: string, limit?: number, options?: any) {
        return PublicArticleControllerApiFp(this.configuration).getArticlesUsingGET(language, limit, options)(this.fetch, this.basePath);
    }

}
/**
 * PublicControllerApi - fetch parameter creator
 * @export
 */
export const PublicControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST1(body: StoreRatingRequest, uid: string, country?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountStoreRatingUsingPOST1.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling accountStoreRatingUsingPOST1.');
            }
            const localVarPath = `/public/store/{uid}/rating`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreRatingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET1(channel: string, count?: number, label?: Array<string>, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling bannerListUsingGET1.');
            }
            const localVarPath = `/public/banner`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (label) {
                localVarQueryParameter['label'] = label;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/public/info/ip`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return locize json
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocizeUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/public/locize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sale data
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getSaleUsingGET.');
            }
            const localVarPath = `/public/sale/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all reviews by seller account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} sellerUid sellerUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, sellerUid: string, language?: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSellerAccountReviewsUsingPOST.');
            }
            // verify required parameter 'sellerUid' is not null or undefined
            if (sellerUid === null || sellerUid === undefined) {
                throw new RequiredError('sellerUid','Required parameter sellerUid was null or undefined when calling getSellerAccountReviewsUsingPOST.');
            }
            const localVarPath = `/public/product/seller/{sellerUid}/reviews`
                .replace(`{${"sellerUid"}}`, encodeURIComponent(String(sellerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductReviewAccountListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of all reviews by store uid or store code
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} store store
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, store: string, language?: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getStoreAccountReviewsUsingPOST.');
            }
            // verify required parameter 'store' is not null or undefined
            if (store === null || store === undefined) {
                throw new RequiredError('store','Required parameter store was null or undefined when calling getStoreAccountReviewsUsingPOST.');
            }
            const localVarPath = `/public/product/store/{store}/reviews`
                .replace(`{${"store"}}`, encodeURIComponent(String(store)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductReviewAccountListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET1(bannerUid: string, channel: string, accountUid?: string, refId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'bannerUid' is not null or undefined
            if (bannerUid === null || bannerUid === undefined) {
                throw new RequiredError('bannerUid','Required parameter bannerUid was null or undefined when calling processManualClickUsingGET1.');
            }
            // verify required parameter 'channel' is not null or undefined
            if (channel === null || channel === undefined) {
                throw new RequiredError('channel','Required parameter channel was null or undefined when calling processManualClickUsingGET1.');
            }
            const localVarPath = `/public/banner/{bannerUid}/click`
                .replace(`{${"bannerUid"}}`, encodeURIComponent(String(bannerUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (accountUid !== undefined) {
                localVarQueryParameter['accountUid'] = accountUid;
            }

            if (channel !== undefined) {
                localVarQueryParameter['channel'] = channel;
            }

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product rating
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingUsingGET(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling productRatingUsingGET.');
            }
            const localVarPath = `/public/product/{uid}/rating`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product reviews
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewUsingGET(uid: string, language?: string, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling productReviewUsingGET.');
            }
            const localVarPath = `/public/product/{uid}/review`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET1(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling productSearchDetailsUsingGET1.');
            }
            const localVarPath = `/public/product/search/details/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST1(body: ProductUidRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productSearchDetailsUsingPOST1.');
            }
            const localVarPath = `/public/product/search/details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUidRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST1(body: ProductSearchListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling productSearchUsingPOST1.');
            }
            const localVarPath = `/public/product/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET1(listType: string, country?: string, language?: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'listType' is not null or undefined
            if (listType === null || listType === undefined) {
                throw new RequiredError('listType','Required parameter listType was null or undefined when calling productWidgetListUsingGET1.');
            }
            const localVarPath = `/public/product/list/{listType}`
                .replace(`{${"listType"}}`, encodeURIComponent(String(listType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store data
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [productCategory] productCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeInfoUsingGET(storeUid: string, country?: string, language?: string, productCategory?: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling storeInfoUsingGET.');
            }
            const localVarPath = `/public/store/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (productCategory !== undefined) {
                localVarQueryParameter['productCategory'] = productCategory;
            }

            if (storeUid !== undefined) {
                localVarQueryParameter['storeUid'] = storeUid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary TOP store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTopUsingGET(country?: string, language?: string, rowLimit?: number, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/public/store/top`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (rowLimit !== undefined) {
                localVarQueryParameter['rowLimit'] = rowLimit;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Random store list with products
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/public/store/widget`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (rowLimit !== undefined) {
                localVarQueryParameter['rowLimit'] = rowLimit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST(body: SubscribeRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling subscribeUsingPOST.');
            }
            const localVarPath = `/public/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubscribeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST1(body: StoreRatingRequest, uid: string, country?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreRating> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).accountStoreRatingUsingPOST1(body, uid, country, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET1(channel: string, count?: number, label?: Array<string>, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).bannerListUsingGET1(channel, count, label, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GeoIPData> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).getLocationInfoUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return locize json
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocizeUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).getLocizeUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get sale data
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).getSaleUsingGET(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of all reviews by seller account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} sellerUid sellerUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, sellerUid: string, language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).getSellerAccountReviewsUsingPOST(body, sellerUid, language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return list of all reviews by store uid or store code
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} store store
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, store: string, language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).getStoreAccountReviewsUsingPOST(body, store, language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET1(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseBanner> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).processManualClickUsingGET1(bannerUid, channel, accountUid, refId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product rating
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingUsingGET(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductRating> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productRatingUsingGET(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product reviews
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewUsingGET(uid: string, language?: string, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProductReview> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productReviewUsingGET(uid, language, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET1(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchDetailsUsingGET1(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST1(body: ProductUidRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchDetailsUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST1(body: ProductSearchListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productSearchUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET1(listType: string, country?: string, language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).productWidgetListUsingGET1(listType, country, language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get store data
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [productCategory] productCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeInfoUsingGET(storeUid: string, country?: string, language?: string, productCategory?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeInfoUsingGET(storeUid, country, language, productCategory, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary TOP store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTopUsingGET(country?: string, language?: string, rowLimit?: number, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeTopUsingGET(country, language, rowLimit, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Random store list with products
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).storeWidgetUsingGET(country, language, rowLimit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST(body: SubscribeRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseVoid> {
            const localVarFetchArgs = PublicControllerApiFetchParamCreator(configuration).subscribeUsingPOST(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST1(body: StoreRatingRequest, uid: string, country?: string, options?: any) {
            return PublicControllerApiFp(configuration).accountStoreRatingUsingPOST1(body, uid, country, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return N marketing banners in random order
         * @param {string} channel channel
         * @param {number} [count] count
         * @param {Array<string>} [label] label
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bannerListUsingGET1(channel: string, count?: number, label?: Array<string>, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).bannerListUsingGET1(channel, count, label, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationInfoUsingGET(options?: any) {
            return PublicControllerApiFp(configuration).getLocationInfoUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return locize json
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocizeUsingGET(language?: string, options?: any) {
            return PublicControllerApiFp(configuration).getLocizeUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get sale data
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET(uid: string, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).getSaleUsingGET(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of all reviews by seller account
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} sellerUid sellerUid
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, sellerUid: string, language?: string, type?: string, options?: any) {
            return PublicControllerApiFp(configuration).getSellerAccountReviewsUsingPOST(body, sellerUid, language, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return list of all reviews by store uid or store code
         * @param {ProductReviewAccountListRequest} body request
         * @param {string} store store
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, store: string, language?: string, type?: string, options?: any) {
            return PublicControllerApiFp(configuration).getStoreAccountReviewsUsingPOST(body, store, language, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Manually register banner click to get banner click statistics
         * @param {string} bannerUid bannerUid
         * @param {string} channel channel
         * @param {string} [accountUid] accountUid
         * @param {string} [refId] refId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processManualClickUsingGET1(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
            return PublicControllerApiFp(configuration).processManualClickUsingGET1(bannerUid, channel, accountUid, refId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product rating
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productRatingUsingGET(uid: string, country?: string, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).productRatingUsingGET(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product reviews
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productReviewUsingGET(uid: string, language?: string, sort?: string, options?: any) {
            return PublicControllerApiFp(configuration).productReviewUsingGET(uid, language, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get product details
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingGET1(uid: string, country?: string, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).productSearchDetailsUsingGET1(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get published product details
         * @param {ProductUidRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchDetailsUsingPOST1(body: ProductUidRequest, country?: string, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).productSearchDetailsUsingPOST1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Search published products using different criteria
         * @param {ProductSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productSearchUsingPOST1(body: ProductSearchListRequest, country?: string, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).productSearchUsingPOST1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
         * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productWidgetListUsingGET1(listType: string, country?: string, language?: string, type?: string, options?: any) {
            return PublicControllerApiFp(configuration).productWidgetListUsingGET1(listType, country, language, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get store data
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {string} [productCategory] productCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeInfoUsingGET(storeUid: string, country?: string, language?: string, productCategory?: string, options?: any) {
            return PublicControllerApiFp(configuration).storeInfoUsingGET(storeUid, country, language, productCategory, options)(fetch, basePath);
        },
        /**
         * 
         * @summary TOP store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTopUsingGET(country?: string, language?: string, rowLimit?: number, type?: string, options?: any) {
            return PublicControllerApiFp(configuration).storeTopUsingGET(country, language, rowLimit, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Random store list with products
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {number} [rowLimit] rowLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
            return PublicControllerApiFp(configuration).storeWidgetUsingGET(country, language, rowLimit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST(body: SubscribeRequest, language?: string, options?: any) {
            return PublicControllerApiFp(configuration).subscribeUsingPOST(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account store rating statistics
     * @param {StoreRatingRequest} body request
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public accountStoreRatingUsingPOST1(body: StoreRatingRequest, uid: string, country?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).accountStoreRatingUsingPOST1(body, uid, country, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return N marketing banners in random order
     * @param {string} channel channel
     * @param {number} [count] count
     * @param {Array<string>} [label] label
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public bannerListUsingGET1(channel: string, count?: number, label?: Array<string>, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).bannerListUsingGET1(channel, count, label, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store rating statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getLocationInfoUsingGET(options?: any) {
        return PublicControllerApiFp(this.configuration).getLocationInfoUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return locize json
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getLocizeUsingGET(language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).getLocizeUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get sale data
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getSaleUsingGET(uid: string, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).getSaleUsingGET(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of all reviews by seller account
     * @param {ProductReviewAccountListRequest} body request
     * @param {string} sellerUid sellerUid
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getSellerAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, sellerUid: string, language?: string, type?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).getSellerAccountReviewsUsingPOST(body, sellerUid, language, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return list of all reviews by store uid or store code
     * @param {ProductReviewAccountListRequest} body request
     * @param {string} store store
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getStoreAccountReviewsUsingPOST(body: ProductReviewAccountListRequest, store: string, language?: string, type?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).getStoreAccountReviewsUsingPOST(body, store, language, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Manually register banner click to get banner click statistics
     * @param {string} bannerUid bannerUid
     * @param {string} channel channel
     * @param {string} [accountUid] accountUid
     * @param {string} [refId] refId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public processManualClickUsingGET1(bannerUid: string, channel: string, accountUid?: string, refId?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).processManualClickUsingGET1(bannerUid, channel, accountUid, refId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product rating
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productRatingUsingGET(uid: string, country?: string, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productRatingUsingGET(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product reviews
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productReviewUsingGET(uid: string, language?: string, sort?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productReviewUsingGET(uid, language, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get product details
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productSearchDetailsUsingGET1(uid: string, country?: string, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productSearchDetailsUsingGET1(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get published product details
     * @param {ProductUidRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productSearchDetailsUsingPOST1(body: ProductUidRequest, country?: string, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productSearchDetailsUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Search published products using different criteria
     * @param {ProductSearchListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productSearchUsingPOST1(body: ProductSearchListRequest, country?: string, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productSearchUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Return 10 new, popular, cheapest, expensive, random e.t.c products
     * @param {string} listType new|popular|cheapest|expensive|random|bestsellers|hot_deals
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public productWidgetListUsingGET1(listType: string, country?: string, language?: string, type?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).productWidgetListUsingGET1(listType, country, language, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get store data
     * @param {string} storeUid storeUid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {string} [productCategory] productCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public storeInfoUsingGET(storeUid: string, country?: string, language?: string, productCategory?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).storeInfoUsingGET(storeUid, country, language, productCategory, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary TOP store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public storeTopUsingGET(country?: string, language?: string, rowLimit?: number, type?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).storeTopUsingGET(country, language, rowLimit, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Random store list with products
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {number} [rowLimit] rowLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public storeWidgetUsingGET(country?: string, language?: string, rowLimit?: number, options?: any) {
        return PublicControllerApiFp(this.configuration).storeWidgetUsingGET(country, language, rowLimit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Subscribe
     * @param {SubscribeRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public subscribeUsingPOST(body: SubscribeRequest, language?: string, options?: any) {
        return PublicControllerApiFp(this.configuration).subscribeUsingPOST(body, language, options)(this.fetch, this.basePath);
    }

}
/**
 * PublicDigitalContentControllerApi - fetch parameter creator
 * @export
 */
export const PublicDigitalContentControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return digital content list
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalContentListUsingGET(language?: string, uid?: string, options: any = {}): FetchArgs {
            const localVarPath = `/public/digitalContent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicDigitalContentControllerApi - functional programming interface
 * @export
 */
export const PublicDigitalContentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return digital content list
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalContentListUsingGET(language?: string, uid?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseDigitalContent> {
            const localVarFetchArgs = PublicDigitalContentControllerApiFetchParamCreator(configuration).getDigitalContentListUsingGET(language, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PublicDigitalContentControllerApi - factory interface
 * @export
 */
export const PublicDigitalContentControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Return digital content list
         * @param {string} [language] language
         * @param {string} [uid] uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDigitalContentListUsingGET(language?: string, uid?: string, options?: any) {
            return PublicDigitalContentControllerApiFp(configuration).getDigitalContentListUsingGET(language, uid, options)(fetch, basePath);
        },
    };
};

/**
 * PublicDigitalContentControllerApi - object-oriented interface
 * @export
 * @class PublicDigitalContentControllerApi
 * @extends {BaseAPI}
 */
export class PublicDigitalContentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Return digital content list
     * @param {string} [language] language
     * @param {string} [uid] uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicDigitalContentControllerApi
     */
    public getDigitalContentListUsingGET(language?: string, uid?: string, options?: any) {
        return PublicDigitalContentControllerApiFp(this.configuration).getDigitalContentListUsingGET(language, uid, options)(this.fetch, this.basePath);
    }

}
/**
 * SaleControllerApi - fetch parameter creator
 * @export
 */
export const SaleControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new sale products or categories
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleProductsUsingPUT(body: Array<SaleProduct>, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSaleProductsUsingPUT.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling addSaleProductsUsingPUT.');
            }
            const localVarPath = `/sale/{uid}/products`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;SaleProduct&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new sale
         * @param {Sale} body sale
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleUsingPUT(body: Sale, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSaleUsingPUT.');
            }
            const localVarPath = `/sale`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Sale" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete sale product
         * @param {Array<SaleProduct>} body saleProduct
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleProductUsingDELETE(body: Array<SaleProduct>, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteSaleProductUsingDELETE.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling deleteSaleProductUsingDELETE.');
            }
            const localVarPath = `/sale/{uid}/products`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;SaleProduct&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete sale
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleUsingDELETE(uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling deleteSaleUsingDELETE.');
            }
            const localVarPath = `/sale/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add/Edit sale product
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleProductsUsingPOST(body: Array<SaleProduct>, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editSaleProductsUsingPOST.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling editSaleProductsUsingPOST.');
            }
            const localVarPath = `/sale/{uid}/products`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;SaleProduct&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit sale
         * @param {Sale} body sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleUsingPOST(body: Sale, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editSaleUsingPOST.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling editSaleUsingPOST.');
            }
            const localVarPath = `/sale/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Sale" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSaleUsingPOST(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling expireSaleUsingPOST.');
            }
            const localVarPath = `/sale/{uid}/expire`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET1(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getSaleUsingGET1.');
            }
            const localVarPath = `/sale/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sale list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesUsingGET(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sale`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate product or categories for sale
         * @param {Array<ValidateProductForSaleRequest>} body request
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductsUsingPOST(body: Array<ValidateProductForSaleRequest>, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling validateProductsUsingPOST.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling validateProductsUsingPOST.');
            }
            const localVarPath = `/sale/{uid}/products/validate`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;ValidateProductForSaleRequest&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleControllerApi - functional programming interface
 * @export
 */
export const SaleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new sale products or categories
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleProductsUsingPUT(body: Array<SaleProduct>, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).addSaleProductsUsingPUT(body, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new sale
         * @param {Sale} body sale
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleUsingPUT(body: Sale, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).addSaleUsingPUT(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete sale product
         * @param {Array<SaleProduct>} body saleProduct
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleProductUsingDELETE(body: Array<SaleProduct>, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).deleteSaleProductUsingDELETE(body, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete sale
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleUsingDELETE(uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).deleteSaleUsingDELETE(uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add/Edit sale product
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleProductsUsingPOST(body: Array<SaleProduct>, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).editSaleProductsUsingPOST(body, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit sale
         * @param {Sale} body sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleUsingPOST(body: Sale, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).editSaleUsingPOST(body, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSaleUsingPOST(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).expireSaleUsingPOST(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET1(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).getSaleUsingGET1(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get sale list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesUsingGET(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSale> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).getSalesUsingGET(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Validate product or categories for sale
         * @param {Array<ValidateProductForSaleRequest>} body request
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductsUsingPOST(body: Array<ValidateProductForSaleRequest>, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseSaleProduct> {
            const localVarFetchArgs = SaleControllerApiFetchParamCreator(configuration).validateProductsUsingPOST(body, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SaleControllerApi - factory interface
 * @export
 */
export const SaleControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add new sale products or categories
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleProductsUsingPUT(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).addSaleProductsUsingPUT(body, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new sale
         * @param {Sale} body sale
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSaleUsingPUT(body: Sale, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).addSaleUsingPUT(body, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete sale product
         * @param {Array<SaleProduct>} body saleProduct
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleProductUsingDELETE(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).deleteSaleProductUsingDELETE(body, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete sale
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSaleUsingDELETE(uid: string, options?: any) {
            return SaleControllerApiFp(configuration).deleteSaleUsingDELETE(uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add/Edit sale product
         * @param {Array<SaleProduct>} body saleProducts
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleProductsUsingPOST(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).editSaleProductsUsingPOST(body, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit sale
         * @param {Sale} body sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editSaleUsingPOST(body: Sale, uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).editSaleUsingPOST(body, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expireSaleUsingPOST(uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).expireSaleUsingPOST(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get sale
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleUsingGET1(uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).getSaleUsingGET1(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get sale list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesUsingGET(language?: string, options?: any) {
            return SaleControllerApiFp(configuration).getSalesUsingGET(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Validate product or categories for sale
         * @param {Array<ValidateProductForSaleRequest>} body request
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProductsUsingPOST(body: Array<ValidateProductForSaleRequest>, uid: string, language?: string, options?: any) {
            return SaleControllerApiFp(configuration).validateProductsUsingPOST(body, uid, language, options)(fetch, basePath);
        },
    };
};

/**
 * SaleControllerApi - object-oriented interface
 * @export
 * @class SaleControllerApi
 * @extends {BaseAPI}
 */
export class SaleControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new sale products or categories
     * @param {Array<SaleProduct>} body saleProducts
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public addSaleProductsUsingPUT(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).addSaleProductsUsingPUT(body, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new sale
     * @param {Sale} body sale
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public addSaleUsingPUT(body: Sale, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).addSaleUsingPUT(body, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete sale product
     * @param {Array<SaleProduct>} body saleProduct
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public deleteSaleProductUsingDELETE(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).deleteSaleProductUsingDELETE(body, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete sale
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public deleteSaleUsingDELETE(uid: string, options?: any) {
        return SaleControllerApiFp(this.configuration).deleteSaleUsingDELETE(uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add/Edit sale product
     * @param {Array<SaleProduct>} body saleProducts
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public editSaleProductsUsingPOST(body: Array<SaleProduct>, uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).editSaleProductsUsingPOST(body, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit sale
     * @param {Sale} body sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public editSaleUsingPOST(body: Sale, uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).editSaleUsingPOST(body, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public expireSaleUsingPOST(uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).expireSaleUsingPOST(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get sale
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public getSaleUsingGET1(uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).getSaleUsingGET1(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get sale list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public getSalesUsingGET(language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).getSalesUsingGET(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Validate product or categories for sale
     * @param {Array<ValidateProductForSaleRequest>} body request
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleControllerApi
     */
    public validateProductsUsingPOST(body: Array<ValidateProductForSaleRequest>, uid: string, language?: string, options?: any) {
        return SaleControllerApiFp(this.configuration).validateProductsUsingPOST(body, uid, language, options)(this.fetch, this.basePath);
    }

}
/**
 * ServiceProviderControllerApi - fetch parameter creator
 * @export
 */
export const ServiceProviderControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET1(language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/service-provider/short`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET1(country?: string, language?: string, options: any = {}): FetchArgs {
            const localVarPath = `/service-provider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST2(body: StoreRatingRequest, uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling accountStoreRatingUsingPOST2.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling accountStoreRatingUsingPOST2.');
            }
            const localVarPath = `/service-provider/{uid}/rating`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreRatingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET1(uid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling accountStoreUsingGET1.');
            }
            const localVarPath = `/service-provider/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageProductUsingPUT1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageProductUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT1(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageProductsUsingPUT1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageProductsUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageProductsUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/products`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProductsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT1(storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWidgetUsingPUT1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWidgetsUsingPUT1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWidgetsUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/widgets`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageWidget&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT1(body: StoreHomepage, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreHomepageWithDetailsUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling addStoreHomepageWithDetailsUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/full`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT1(body: Store, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addStoreUsingPUT1.');
            }
            const localVarPath = `/service-provider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Store" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST1(body: StoreCheck, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling checkAccountStoreUsingPOST1.');
            }
            const localVarPath = `/service-provider/check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreCheck" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createStoreHomepageBannerUsingPUT1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling createStoreHomepageBannerUsingPUT1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling createStoreHomepageBannerUsingPUT1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE1(storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteAllStoreHomepagesUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageBannerUsingDELETE1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageBannerUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageProductUsingDELETE1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE1(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageProductsUsingDELETE1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageProductsUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/products`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageProduct&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE1(storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling deleteStoreHomepageWidgetsUsingDELETE1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/widgets`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StoreHomepageWidget&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST1(body: StoreHomepage, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editStoreHomepageDetailsUsingPOST1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling editStoreHomepageDetailsUsingPOST1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/full`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepage" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET1(uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getHomepageUsingGET1.');
            }
            const localVarPath = `/service-provider/{uid}/homepage`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST1(body: StoreListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getPublicStoreListUsingPOST1.');
            }
            const localVarPath = `/service-provider/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET1(storeUid: string, uid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling getStoreHomepageByUidUsingGET1.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling getStoreHomepageByUidUsingGET1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{uid}`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST1(body: StoreCategoryListRequest, storeUid: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getStoreProductCategoryListUsingPOST1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling getStoreProductCategoryListUsingPOST1.');
            }
            const localVarPath = `/service-provider/{storeUid}/category`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreCategoryListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST1(homepageUid: string, uid: string, options: any = {}): FetchArgs {
            // verify required parameter 'homepageUid' is not null or undefined
            if (homepageUid === null || homepageUid === undefined) {
                throw new RequiredError('homepageUid','Required parameter homepageUid was null or undefined when calling publishStoreHomepageUsingPOST1.');
            }
            // verify required parameter 'uid' is not null or undefined
            if (uid === null || uid === undefined) {
                throw new RequiredError('uid','Required parameter uid was null or undefined when calling publishStoreHomepageUsingPOST1.');
            }
            const localVarPath = `/service-provider/{uid}/homepage/{homepageUid}/publish`
                .replace(`{${"homepageUid"}}`, encodeURIComponent(String(homepageUid)))
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST1(body: StoreSearchListRequest, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling storeSearchUsingPOST1.');
            }
            const localVarPath = `/service-provider/search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreSearchListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageBannerUsingPOST1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageBannerUsingPOST1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageBannerUsingPOST1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/banner`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageBanner" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageProductUsingPOST1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageProductUsingPOST1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageProductUsingPOST1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/product`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageProduct" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreHomepageWidgetUsingPOST1.');
            }
            // verify required parameter 'storeHomepageUid' is not null or undefined
            if (storeHomepageUid === null || storeHomepageUid === undefined) {
                throw new RequiredError('storeHomepageUid','Required parameter storeHomepageUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST1.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling updateStoreHomepageWidgetUsingPOST1.');
            }
            const localVarPath = `/service-provider/{storeUid}/homepage/{storeHomepageUid}/widget`
                .replace(`{${"storeHomepageUid"}}`, encodeURIComponent(String(storeHomepageUid)))
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StoreHomepageWidget" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST1(body: Store, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStoreUsingPOST1.');
            }
            const localVarPath = `/service-provider`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Store" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceProviderControllerApi - functional programming interface
 * @export
 */
export const ServiceProviderControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET1(language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).accountShortStoreInfoListUsingGET1(language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET1(country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).accountStoreListUsingGET1(country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST2(body: StoreRatingRequest, uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreRating> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).accountStoreRatingUsingPOST2(body, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET1(uid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).accountStoreUsingGET1(uid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageProductUsingPUT1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT1(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageProductsUsingPUT1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT1(storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageUsingPUT1(storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetUsingPUT1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageWidgetsUsingPUT1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT1(body: StoreHomepage, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreHomepageWithDetailsUsingPUT1(body, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT1(body: Store, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).addStoreUsingPUT1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST1(body: StoreCheck, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreCheck> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).checkAccountStoreUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).createStoreHomepageBannerUsingPUT1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE1(storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteAllStoreHomepagesUsingDELETE1(storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageBannerUsingDELETE1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageProductUsingDELETE1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE1(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageProductsUsingDELETE1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE1(storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageUsingDELETE1(storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageWidgetUsingDELETE1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).deleteStoreHomepageWidgetsUsingDELETE1(body, storeHomepageUid, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST1(body: StoreHomepage, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).editStoreHomepageDetailsUsingPOST1(body, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET1(uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).getHomepageUsingGET1(uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST1(body: StoreListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).getPublicStoreListUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET1(storeUid: string, uid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).getStoreHomepageByUidUsingGET1(storeUid, uid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST1(body: StoreCategoryListRequest, storeUid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseString> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).getStoreProductCategoryListUsingPOST1(body, storeUid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST1(homepageUid: string, uid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).publishStoreHomepageUsingPOST1(homepageUid, uid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST1(body: StoreSearchListRequest, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseString> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).storeSearchUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).updateStoreHomepageBannerUsingPOST1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).updateStoreHomepageProductUsingPOST1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStoreHomepage> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).updateStoreHomepageWidgetUsingPOST1(body, storeHomepageUid, storeUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST1(body: Store, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStore> {
            const localVarFetchArgs = ServiceProviderControllerApiFetchParamCreator(configuration).updateStoreUsingPOST1(body, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ServiceProviderControllerApi - factory interface
 * @export
 */
export const ServiceProviderControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get account store list
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountShortStoreInfoListUsingGET1(language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).accountShortStoreInfoListUsingGET1(language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store list
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreListUsingGET1(country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).accountStoreListUsingGET1(country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store rating statistics
         * @param {StoreRatingRequest} body request
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreRatingUsingPOST2(body: StoreRatingRequest, uid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).accountStoreRatingUsingPOST2(body, uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store by uid
         * @param {string} uid uid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountStoreUsingGET1(uid: string, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).accountStoreUsingGET1(uid, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product to account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductUsingPUT1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageProductUsingPUT1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product list to account store homepage
         * @param {StoreHomepageProductsRequest} body request
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageProductsUsingPUT1(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageProductsUsingPUT1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageUsingPUT1(storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageUsingPUT1(storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetUsingPUT1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageWidgetUsingPUT1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add widget to account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWidgetsUsingPUT1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageWidgetsUsingPUT1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreHomepageWithDetailsUsingPUT1(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreHomepageWithDetailsUsingPUT1(body, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add new account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStoreUsingPUT1(body: Store, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).addStoreUsingPUT1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check account name or code availability
         * @param {StoreCheck} body check
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccountStoreUsingPOST1(body: StoreCheck, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).checkAccountStoreUsingPOST1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStoreHomepageBannerUsingPUT1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).createStoreHomepageBannerUsingPUT1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllStoreHomepagesUsingDELETE1(storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteAllStoreHomepagesUsingDELETE1(storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageBannerUsingDELETE1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageBannerUsingDELETE1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductUsingDELETE1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageProductUsingDELETE1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete products in account store homepage
         * @param {Array<StoreHomepageProduct>} body products
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageProductsUsingDELETE1(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageProductsUsingDELETE1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete account store homepage
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageUsingDELETE1(storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageUsingDELETE1(storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetUsingDELETE1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageWidgetUsingDELETE1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete widgets in account store homepage
         * @param {Array<StoreHomepageWidget>} body widgets
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreHomepageWidgetsUsingDELETE1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).deleteStoreHomepageWidgetsUsingDELETE1(body, storeHomepageUid, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit account store homepage with details
         * @param {StoreHomepage} body homepage
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editStoreHomepageDetailsUsingPOST1(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).editStoreHomepageDetailsUsingPOST1(body, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomepageUsingGET1(uid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).getHomepageUsingGET1(uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get public store list
         * @param {StoreListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicStoreListUsingPOST1(body: StoreListRequest, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).getPublicStoreListUsingPOST1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get account store homepage
         * @param {string} storeUid storeUid
         * @param {string} uid uid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreHomepageByUidUsingGET1(storeUid: string, uid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).getStoreHomepageByUidUsingGET1(storeUid, uid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get store product category list
         * @param {StoreCategoryListRequest} body request
         * @param {string} storeUid storeUid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreProductCategoryListUsingPOST1(body: StoreCategoryListRequest, storeUid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).getStoreProductCategoryListUsingPOST1(body, storeUid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Publish account store homepage
         * @param {string} homepageUid homepageUid
         * @param {string} uid uid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishStoreHomepageUsingPOST1(homepageUid: string, uid: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).publishStoreHomepageUsingPOST1(homepageUid, uid, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get store list
         * @param {StoreSearchListRequest} body request
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeSearchUsingPOST1(body: StoreSearchListRequest, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).storeSearchUsingPOST1(body, country, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update banner for account store homepage
         * @param {StoreHomepageBanner} body banner
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageBannerUsingPOST1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).updateStoreHomepageBannerUsingPOST1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit product in account store homepage
         * @param {StoreHomepageProduct} body product
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageProductUsingPOST1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).updateStoreHomepageProductUsingPOST1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Edit widget in account store homepage
         * @param {StoreHomepageWidget} body widget
         * @param {string} storeHomepageUid storeHomepageUid
         * @param {string} storeUid storeUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreHomepageWidgetUsingPOST1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).updateStoreHomepageWidgetUsingPOST1(body, storeHomepageUid, storeUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Update existing account store
         * @param {Store} body store
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStoreUsingPOST1(body: Store, country?: string, language?: string, options?: any) {
            return ServiceProviderControllerApiFp(configuration).updateStoreUsingPOST1(body, country, language, options)(fetch, basePath);
        },
    };
};

/**
 * ServiceProviderControllerApi - object-oriented interface
 * @export
 * @class ServiceProviderControllerApi
 * @extends {BaseAPI}
 */
export class ServiceProviderControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get account store list
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public accountShortStoreInfoListUsingGET1(language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).accountShortStoreInfoListUsingGET1(language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store list
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public accountStoreListUsingGET1(country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).accountStoreListUsingGET1(country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store rating statistics
     * @param {StoreRatingRequest} body request
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public accountStoreRatingUsingPOST2(body: StoreRatingRequest, uid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).accountStoreRatingUsingPOST2(body, uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store by uid
     * @param {string} uid uid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public accountStoreUsingGET1(uid: string, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).accountStoreUsingGET1(uid, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product to account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageProductUsingPUT1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageProductUsingPUT1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product list to account store homepage
     * @param {StoreHomepageProductsRequest} body request
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageProductsUsingPUT1(body: StoreHomepageProductsRequest, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageProductsUsingPUT1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageUsingPUT1(storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageUsingPUT1(storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add widget to account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageWidgetUsingPUT1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageWidgetUsingPUT1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add widget to account store homepage
     * @param {Array<StoreHomepageWidget>} body widgets
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageWidgetsUsingPUT1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageWidgetsUsingPUT1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store homepage with details
     * @param {StoreHomepage} body homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreHomepageWithDetailsUsingPUT1(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreHomepageWithDetailsUsingPUT1(body, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add new account store
     * @param {Store} body store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public addStoreUsingPUT1(body: Store, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).addStoreUsingPUT1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check account name or code availability
     * @param {StoreCheck} body check
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public checkAccountStoreUsingPOST1(body: StoreCheck, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).checkAccountStoreUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public createStoreHomepageBannerUsingPUT1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).createStoreHomepageBannerUsingPUT1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete account store homepage
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteAllStoreHomepagesUsingDELETE1(storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteAllStoreHomepagesUsingDELETE1(storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageBannerUsingDELETE1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageBannerUsingDELETE1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete product in account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageProductUsingDELETE1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageProductUsingDELETE1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete products in account store homepage
     * @param {Array<StoreHomepageProduct>} body products
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageProductsUsingDELETE1(body: Array<StoreHomepageProduct>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageProductsUsingDELETE1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete account store homepage
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageUsingDELETE1(storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageUsingDELETE1(storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete widget in account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageWidgetUsingDELETE1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageWidgetUsingDELETE1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete widgets in account store homepage
     * @param {Array<StoreHomepageWidget>} body widgets
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public deleteStoreHomepageWidgetsUsingDELETE1(body: Array<StoreHomepageWidget>, storeHomepageUid: string, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).deleteStoreHomepageWidgetsUsingDELETE1(body, storeHomepageUid, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit account store homepage with details
     * @param {StoreHomepage} body homepage
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public editStoreHomepageDetailsUsingPOST1(body: StoreHomepage, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).editStoreHomepageDetailsUsingPOST1(body, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store homepage
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public getHomepageUsingGET1(uid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).getHomepageUsingGET1(uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get public store list
     * @param {StoreListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public getPublicStoreListUsingPOST1(body: StoreListRequest, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).getPublicStoreListUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get account store homepage
     * @param {string} storeUid storeUid
     * @param {string} uid uid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public getStoreHomepageByUidUsingGET1(storeUid: string, uid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).getStoreHomepageByUidUsingGET1(storeUid, uid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get store product category list
     * @param {StoreCategoryListRequest} body request
     * @param {string} storeUid storeUid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public getStoreProductCategoryListUsingPOST1(body: StoreCategoryListRequest, storeUid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).getStoreProductCategoryListUsingPOST1(body, storeUid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Publish account store homepage
     * @param {string} homepageUid homepageUid
     * @param {string} uid uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public publishStoreHomepageUsingPOST1(homepageUid: string, uid: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).publishStoreHomepageUsingPOST1(homepageUid, uid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get store list
     * @param {StoreSearchListRequest} body request
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public storeSearchUsingPOST1(body: StoreSearchListRequest, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).storeSearchUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update banner for account store homepage
     * @param {StoreHomepageBanner} body banner
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public updateStoreHomepageBannerUsingPOST1(body: StoreHomepageBanner, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).updateStoreHomepageBannerUsingPOST1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit product in account store homepage
     * @param {StoreHomepageProduct} body product
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public updateStoreHomepageProductUsingPOST1(body: StoreHomepageProduct, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).updateStoreHomepageProductUsingPOST1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Edit widget in account store homepage
     * @param {StoreHomepageWidget} body widget
     * @param {string} storeHomepageUid storeHomepageUid
     * @param {string} storeUid storeUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public updateStoreHomepageWidgetUsingPOST1(body: StoreHomepageWidget, storeHomepageUid: string, storeUid: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).updateStoreHomepageWidgetUsingPOST1(body, storeHomepageUid, storeUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Update existing account store
     * @param {Store} body store
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceProviderControllerApi
     */
    public updateStoreUsingPOST1(body: Store, country?: string, language?: string, options?: any) {
        return ServiceProviderControllerApiFp(this.configuration).updateStoreUsingPOST1(body, country, language, options)(this.fetch, this.basePath);
    }

}
/**
 * StatisticsControllerApi - fetch parameter creator
 * @export
 */
export const StatisticsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Product statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatisticsUsingPOST(body: GetStatisticsRequest, productUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getProductStatisticsUsingPOST.');
            }
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling getProductStatisticsUsingPOST.');
            }
            const localVarPath = `/statistics/product/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetStatisticsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Sale statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} saleUid saleUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleStatisticsUsingPOST(body: GetStatisticsRequest, saleUid: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getSaleStatisticsUsingPOST.');
            }
            // verify required parameter 'saleUid' is not null or undefined
            if (saleUid === null || saleUid === undefined) {
                throw new RequiredError('saleUid','Required parameter saleUid was null or undefined when calling getSaleStatisticsUsingPOST.');
            }
            const localVarPath = `/statistics/sale/{saleUid}`
                .replace(`{${"saleUid"}}`, encodeURIComponent(String(saleUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetStatisticsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Store statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreStatisticsUsingPOST(body: GetStatisticsRequest, storeUid: string, country?: string, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getStoreStatisticsUsingPOST.');
            }
            // verify required parameter 'storeUid' is not null or undefined
            if (storeUid === null || storeUid === undefined) {
                throw new RequiredError('storeUid','Required parameter storeUid was null or undefined when calling getStoreStatisticsUsingPOST.');
            }
            const localVarPath = `/statistics/store/{storeUid}`
                .replace(`{${"storeUid"}}`, encodeURIComponent(String(storeUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GetStatisticsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsControllerApi - functional programming interface
 * @export
 */
export const StatisticsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Product statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatisticsUsingPOST(body: GetStatisticsRequest, productUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStatistics> {
            const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getProductStatisticsUsingPOST(body, productUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Sale statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} saleUid saleUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleStatisticsUsingPOST(body: GetStatisticsRequest, saleUid: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStatistics> {
            const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getSaleStatisticsUsingPOST(body, saleUid, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get Store statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreStatisticsUsingPOST(body: GetStatisticsRequest, storeUid: string, country?: string, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseStatistics> {
            const localVarFetchArgs = StatisticsControllerApiFetchParamCreator(configuration).getStoreStatisticsUsingPOST(body, storeUid, country, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatisticsControllerApi - factory interface
 * @export
 */
export const StatisticsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Get Product statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} productUid productUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductStatisticsUsingPOST(body: GetStatisticsRequest, productUid: string, language?: string, options?: any) {
            return StatisticsControllerApiFp(configuration).getProductStatisticsUsingPOST(body, productUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Sale statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} saleUid saleUid
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleStatisticsUsingPOST(body: GetStatisticsRequest, saleUid: string, language?: string, options?: any) {
            return StatisticsControllerApiFp(configuration).getSaleStatisticsUsingPOST(body, saleUid, language, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get Store statistics
         * @param {GetStatisticsRequest} body request
         * @param {string} storeUid storeUid
         * @param {string} [country] country
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreStatisticsUsingPOST(body: GetStatisticsRequest, storeUid: string, country?: string, language?: string, options?: any) {
            return StatisticsControllerApiFp(configuration).getStoreStatisticsUsingPOST(body, storeUid, country, language, options)(fetch, basePath);
        },
    };
};

/**
 * StatisticsControllerApi - object-oriented interface
 * @export
 * @class StatisticsControllerApi
 * @extends {BaseAPI}
 */
export class StatisticsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Product statistics
     * @param {GetStatisticsRequest} body request
     * @param {string} productUid productUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getProductStatisticsUsingPOST(body: GetStatisticsRequest, productUid: string, language?: string, options?: any) {
        return StatisticsControllerApiFp(this.configuration).getProductStatisticsUsingPOST(body, productUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Sale statistics
     * @param {GetStatisticsRequest} body request
     * @param {string} saleUid saleUid
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getSaleStatisticsUsingPOST(body: GetStatisticsRequest, saleUid: string, language?: string, options?: any) {
        return StatisticsControllerApiFp(this.configuration).getSaleStatisticsUsingPOST(body, saleUid, language, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get Store statistics
     * @param {GetStatisticsRequest} body request
     * @param {string} storeUid storeUid
     * @param {string} [country] country
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getStoreStatisticsUsingPOST(body: GetStatisticsRequest, storeUid: string, country?: string, language?: string, options?: any) {
        return StatisticsControllerApiFp(this.configuration).getStoreStatisticsUsingPOST(body, storeUid, country, language, options)(this.fetch, this.basePath);
    }

}
/**
 * SubscriptionControllerApi - fetch parameter creator
 * @export
 */
export const SubscriptionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST1(body: SubscribeRequest, language?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling subscribeUsingPOST1.');
            }
            const localVarPath = `/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubscribeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionControllerApi - functional programming interface
 * @export
 */
export const SubscriptionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST1(body: SubscribeRequest, language?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseVoid> {
            const localVarFetchArgs = SubscriptionControllerApiFetchParamCreator(configuration).subscribeUsingPOST1(body, language, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubscriptionControllerApi - factory interface
 * @export
 */
export const SubscriptionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Subscribe
         * @param {SubscribeRequest} body request
         * @param {string} [language] language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeUsingPOST1(body: SubscribeRequest, language?: string, options?: any) {
            return SubscriptionControllerApiFp(configuration).subscribeUsingPOST1(body, language, options)(fetch, basePath);
        },
    };
};

/**
 * SubscriptionControllerApi - object-oriented interface
 * @export
 * @class SubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class SubscriptionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Subscribe
     * @param {SubscribeRequest} body request
     * @param {string} [language] language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionControllerApi
     */
    public subscribeUsingPOST1(body: SubscribeRequest, language?: string, options?: any) {
        return SubscriptionControllerApiFp(this.configuration).subscribeUsingPOST1(body, language, options)(this.fetch, this.basePath);
    }

}
/**
 * TicketControllerApi - fetch parameter creator
 * @export
 */
export const TicketControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {Ticket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketUsingPUT(body: Ticket, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createTicketUsingPUT.');
            }
            const localVarPath = `/ticket`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Ticket" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketControllerApi - functional programming interface
 * @export
 */
export const TicketControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {Ticket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketUsingPUT(body: Ticket, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseOmnideskTicket> {
            const localVarFetchArgs = TicketControllerApiFetchParamCreator(configuration).createTicketUsingPUT(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TicketControllerApi - factory interface
 * @export
 */
export const TicketControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create Omnidesk ticket
         * @param {Ticket} body request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketUsingPUT(body: Ticket, options?: any) {
            return TicketControllerApiFp(configuration).createTicketUsingPUT(body, options)(fetch, basePath);
        },
    };
};

/**
 * TicketControllerApi - object-oriented interface
 * @export
 * @class TicketControllerApi
 * @extends {BaseAPI}
 */
export class TicketControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create Omnidesk ticket
     * @param {Ticket} body request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControllerApi
     */
    public createTicketUsingPUT(body: Ticket, options?: any) {
        return TicketControllerApiFp(this.configuration).createTicketUsingPUT(body, options)(this.fetch, this.basePath);
    }

}
/**
 * WishListControllerApi - fetch parameter creator
 * @export
 */
export const WishListControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add products to WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToWishListUsingPUT(body: Array<string>, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addProductsToWishListUsingPUT.');
            }
            const localVarPath = `/wishlist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToWishListUsingPUT(productUid: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling addToWishListUsingPUT.');
            }
            const localVarPath = `/wishlist/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete from WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromWishListUsingDELETE(productUid: string, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'productUid' is not null or undefined
            if (productUid === null || productUid === undefined) {
                throw new RequiredError('productUid','Required parameter productUid was null or undefined when calling removeFromWishListUsingDELETE.');
            }
            const localVarPath = `/wishlist/{productUid}`
                .replace(`{${"productUid"}}`, encodeURIComponent(String(productUid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete products from WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductsFromWishListUsingDELETE(body: Array<string>, type?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeProductsFromWishListUsingDELETE.');
            }
            const localVarPath = `/wishlist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarHeaderParameter['Content-Type'] = '*/*';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get WishList
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishListUsingGET(language?: string, type?: string, options: any = {}): FetchArgs {
            const localVarPath = `/wishlist`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("authorization", ["global"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WishListControllerApi - functional programming interface
 * @export
 */
export const WishListControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add products to WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToWishListUsingPUT(body: Array<string>, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).addProductsToWishListUsingPUT(body, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add product to WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToWishListUsingPUT(productUid: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).addToWishListUsingPUT(productUid, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete from WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromWishListUsingDELETE(productUid: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).removeFromWishListUsingDELETE(productUid, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete products from WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductsFromWishListUsingDELETE(body: Array<string>, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).removeProductsFromWishListUsingDELETE(body, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get WishList
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishListUsingGET(language?: string, type?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommonMarketplaceApiResponseProduct> {
            const localVarFetchArgs = WishListControllerApiFetchParamCreator(configuration).wishListUsingGET(language, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WishListControllerApi - factory interface
 * @export
 */
export const WishListControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add products to WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToWishListUsingPUT(body: Array<string>, type?: string, options?: any) {
            return WishListControllerApiFp(configuration).addProductsToWishListUsingPUT(body, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add product to WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToWishListUsingPUT(productUid: string, type?: string, options?: any) {
            return WishListControllerApiFp(configuration).addToWishListUsingPUT(productUid, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete from WishList
         * @param {string} productUid productUid
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromWishListUsingDELETE(productUid: string, type?: string, options?: any) {
            return WishListControllerApiFp(configuration).removeFromWishListUsingDELETE(productUid, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete products from WishList
         * @param {Array<string>} body productUidList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductsFromWishListUsingDELETE(body: Array<string>, type?: string, options?: any) {
            return WishListControllerApiFp(configuration).removeProductsFromWishListUsingDELETE(body, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get WishList
         * @param {string} [language] language
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wishListUsingGET(language?: string, type?: string, options?: any) {
            return WishListControllerApiFp(configuration).wishListUsingGET(language, type, options)(fetch, basePath);
        },
    };
};

/**
 * WishListControllerApi - object-oriented interface
 * @export
 * @class WishListControllerApi
 * @extends {BaseAPI}
 */
export class WishListControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add products to WishList
     * @param {Array<string>} body productUidList
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListControllerApi
     */
    public addProductsToWishListUsingPUT(body: Array<string>, type?: string, options?: any) {
        return WishListControllerApiFp(this.configuration).addProductsToWishListUsingPUT(body, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add product to WishList
     * @param {string} productUid productUid
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListControllerApi
     */
    public addToWishListUsingPUT(productUid: string, type?: string, options?: any) {
        return WishListControllerApiFp(this.configuration).addToWishListUsingPUT(productUid, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete from WishList
     * @param {string} productUid productUid
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListControllerApi
     */
    public removeFromWishListUsingDELETE(productUid: string, type?: string, options?: any) {
        return WishListControllerApiFp(this.configuration).removeFromWishListUsingDELETE(productUid, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete products from WishList
     * @param {Array<string>} body productUidList
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListControllerApi
     */
    public removeProductsFromWishListUsingDELETE(body: Array<string>, type?: string, options?: any) {
        return WishListControllerApiFp(this.configuration).removeProductsFromWishListUsingDELETE(body, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get WishList
     * @param {string} [language] language
     * @param {string} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListControllerApi
     */
    public wishListUsingGET(language?: string, type?: string, options?: any) {
        return WishListControllerApiFp(this.configuration).wishListUsingGET(language, type, options)(this.fetch, this.basePath);
    }

}

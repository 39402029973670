import { Button, Link, List, Navbar, NavRight, NavTitle, Popup } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChangeQuantityPopupProps } from './ChangeQuantityPopup.types';

import { IcClose } from '@/assets';
import { CustomInput } from '@/components/CustomInput';
import { useControlledInput } from '@/hooks/useControlledInput';
import { Popups } from '@/types/popups';

import './ChangeQuantityPopup.less';

export const ChangeQuantityPopup: React.FC<ChangeQuantityPopupProps> = ({
    onSubmit,
    buttonText = 'Change',
    title = 'Change Quantity',
    ...props
}) => {
    const [value, setValue] = useState('');
    const handleChange = useControlledInput(setValue);
    const handleSubmit = useCallback(() => {
        onSubmit(value);
    }, [onSubmit, value]);

    const { t } = useTranslation();
    return (
        <Popup className={Popups.CHANGE_QUANTITY_POPUP} {...props}>
            <Navbar noHairline noShadow>
                <NavTitle>{t(title)}</NavTitle>
                <NavRight>
                    <Link popupClose>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <List>
                <CustomInput onChange={handleChange} label={t('Count of Product')} />
                <Button onClick={handleSubmit} fill round>
                    {t(buttonText)}
                </Button>
            </List>
        </Popup>
    );
};

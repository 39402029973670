import React from 'react';

import { useNodeUid } from '@/hooks/useNodeUid';
import { IcIconProps } from '@/types/icons';

export const IcMulticolor = React.memo<IcIconProps>((props) => {
    const gradientId = useNodeUid();

    return (
        <svg width={20} height={20} fill="none" {...props}>
            <mask
                id={gradientId}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'alpha',
                }}
                x={0}
                y={0}
                width={20}
                height={20}
            >
                <circle cx={10} cy={10} r={10} fill="#ECD2BB" />
            </mask>
            <g mask={`url(#${gradientId})`}>
                <circle cx={10} cy={10} r={10} fill="#C8CACB" />
                <path d="M0 20L-6.99382e-07 10L10 10L0 20Z" fill="#59B865" />
                <path d="M0 20L10 20L10 10L0 20Z" fill="#24A0D6" />
                <path d="M0 0H10V10L0 0Z" fill="#EAAD35" />
                <path d="M10 10H20V20L10 10Z" fill="#888CE2" />
                <path d="M10 10L10 0L20 -6.99382e-07L10 10Z" fill="#E55151" />
                <path d="M0 0V10H10L0 0Z" fill="#FFEE4D" />
                <path d="M10 10V20H20L10 10Z" fill="#57CCFF" />
                <path d="M10 10L20 10L20 -6.99382e-07L10 10Z" fill="#C973C5" />
            </g>
        </svg>
    );
});
IcMulticolor.displayName = 'IcMulticolor';

import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { CarouselItemCardProps } from '@/components/Carousel/CarouselItemCard/CarouselItemCard.types';
import { hasFeatureCode } from '@/components/Catalog/CatalogItem/utils';
import { ProductCard } from '@/components/ProductCard/ProductCard';
import { noop, stopPropagation } from '@/utils';

import './SmallItem.less';

export const SmallItem = ({
    item,
    onClickAddToWish,
    showFeaturesHighlight,
}: Partial<CarouselItemCardProps>): JSX.Element => {
    const { featureCodes } = useMemo(() => item, [item]);

    const onLikeClick = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            stopPropagation(e);
            onClickAddToWish(item.uid);
        },
        [item.uid, onClickAddToWish],
    );

    const handleItemClick = useCallback(() => item?.onClick?.(item), [item]);

    const isPremium = useMemo(() => hasFeatureCode('higlight_bold', featureCodes), [featureCodes]);

    return (
        <>
            <div
                className={cn('link', {
                    'feature-higlight-bold': showFeaturesHighlight && isPremium,
                })}
                onClick={handleItemClick}
            >
                <ProductCard product={item} onLikeChange={onLikeClick} onClick={noop} />
            </div>
        </>
    );
};

import cn from 'classnames';
import { Button, Link, List, ListItem, Navbar, NavLeft, NavTitle, Popup } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MyGoodsSortBy, StatusFiltersMobilePopupProps } from './StatusFiltersMobilePopup.types';

import { getSortByFields } from './StatusFiltersMobilePopup.utils';

import { IcClose, IcDone } from '@/assets';
import { CustomChip } from '@/components/CustomChip';
import { useAppDispatch } from '@/hooks/store';
import { Popups } from '@/types/popups';

import './StatusFiltersMobilePopup.less';

export const StatusFiltersMobilePopup: React.FC<StatusFiltersMobilePopupProps> = ({
    options,
    selectedOptions: selected,
    onApply,
    onPopupClose,
    className,
    priceFrom,
    priceTo,
    handleSearch,
    presearchCount,
    searchLoading,
    opened,
    saleUid,
    ...popupProps
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [selectedOptions, setSelectedOptions] = useState<string[]>(selected ?? []);
    const [sortBy, setSortBy] = useState(MyGoodsSortBy.what_s_new);

    const handleOptionClick = useCallback((option: string) => {
        setSelectedOptions((prev) =>
            !prev.includes(option) ? [...prev, option] : prev.filter((item) => item !== option),
        );
    }, []);

    const handleApply = useCallback(() => {
        onApply(selectedOptions, getSortByFields(sortBy));
        onPopupClose?.();
    }, [onApply, onPopupClose, selectedOptions, sortBy]);

    const handleClose = useCallback(() => {
        onPopupClose?.();
        setSelectedOptions(selected ?? []);
    }, [onPopupClose, selected]);

    useEffect(() => {
        if (opened) {
            handleSearch(dispatch, {
                priceFrom,
                priceTo,
                statuses: selectedOptions,
                validateForSaleUid: saleUid,
            });
        }
    }, [dispatch, handleSearch, opened, priceFrom, priceTo, saleUid, selectedOptions]);

    useEffect(() => {
        setSelectedOptions(selected);
    }, [selected]);

    return (
        <Popup
            {...popupProps}
            className={cn(Popups.HOMEPAGE_MOBILE_FILTERS_POPUP, 'homepage-mobile-filters-popup', className)}
            onPopupClose={handleClose}
            opened={opened}
        >
            <Navbar noHairline noShadow>
                <NavLeft>
                    <Link href="#" popupClose iconOnly>
                        <IcClose />
                    </Link>
                </NavLeft>
                <NavTitle>{t('All Filters')}</NavTitle>
            </Navbar>
            <div className="homepage-mobile-filters-popup__content">
                <p className="group-label">{t('Product Status')}</p>
                <div>
                    {options?.map((option) => (
                        <CustomChip
                            active={selectedOptions.includes(option.value)}
                            key={option.value}
                            value={option.value}
                            text={`${t(option.label)}`}
                            onClick={handleOptionClick}
                        />
                    ))}
                </div>
                <p className="group-label">{t('Sort By')}</p>

                <List noHairlines noHairlinesBetween className="sort-by-list">
                    {Object.entries(MyGoodsSortBy)?.map(([key, value]) => (
                        <ListItem title={`${t(value)}`} key={key} onClick={() => setSortBy(value)}>
                            {value === sortBy && <IcDone />}
                        </ListItem>
                    ))}
                </List>
                <Button onClick={handleApply} className="button-apply" fill round disabled={searchLoading}>
                    {t('Show {{count}} Products', { count: presearchCount ?? 0 })}
                </Button>
            </div>
        </Popup>
    );
};

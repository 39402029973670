import { AnyAction } from 'redux';

import {
    RESERVE_ADD_DELIVERY_METHOD,
    RESERVE_LOAD,
    RESERVE_LOAD_ERROR,
    RESERVE_LOAD_SUCCESS,
    RESERVE_REMOVE_ITEM,
} from '@/actions/reserveActions';
import { CartItem, ProductRentOptions } from '@/generated/marketplaceapi';
import { changeChosenSdekDeliveryInCartItem, isDeliveryMethodOption } from '@/utils';

export interface IReserveState {
    isFetching: boolean;
    error?: unknown;
    rentOptions?: ProductRentOptions;
    item: CartItem;
}

const initialState: IReserveState = {
    isFetching: false,
    item: undefined,
};

const reserveReducer = (state = initialState, { type, payload }: AnyAction): IReserveState => {
    switch (type) {
        case RESERVE_LOAD: {
            return { ...state, isFetching: true };
        }
        case RESERVE_REMOVE_ITEM: {
            return {
                ...state,
                item: undefined,
                rentOptions: undefined,
            };
        }
        case RESERVE_LOAD_ERROR: {
            const { error } = payload;
            return { ...state, error, isFetching: false };
        }
        case RESERVE_LOAD_SUCCESS: {
            const { item, rentOptions } = payload;
            return { ...state, item, isFetching: false, rentOptions };
        }
        case RESERVE_ADD_DELIVERY_METHOD: {
            const { method, chosenSdekDelivery } = payload;

            if (!isDeliveryMethodOption(method) && chosenSdekDelivery) {
                return {
                    ...state,
                    item: changeChosenSdekDeliveryInCartItem(
                        {
                            ...state.item,
                            deliveryMethodOption: undefined,
                            platformDeliveryMethod: isDeliveryMethodOption(method)
                                ? state.item.platformDeliveryMethod
                                : method,
                        },
                        chosenSdekDelivery,
                    ),
                };
            }

            return {
                ...state,
                item: {
                    ...state.item,
                    deliveryMethodOption: isDeliveryMethodOption(method) ? method : state.item.deliveryMethodOption,
                    platformDeliveryMethod: undefined,
                },
            };
        }
        default:
            return state;
    }
};

export default reserveReducer;

import { f7, Page } from 'framework7-react';
import React, { useCallback, useEffect } from 'react';

import { CustomPageProps } from './CustomPage.types';

import { useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

export const CustomPage = ({ loggedOnly, allowedUserTypes, children, onPageAfterOut, ...props }: CustomPageProps) => {
    const logged = useAppSelector(isLoggedIn);
    const profile = useAppSelector(getProfile);
    const { triedToLogin } = useAppSelector((state) => state.sessionReducer);

    const handlePageAfterOut = useCallback(() => {
        Object.values(Popups).forEach((popup) => f7.popup.close(`.${popup}`));
        Object.values(Sheets).forEach((sheet) => f7.sheet.close(`.${sheet}`));
        onPageAfterOut?.();
    }, [onPageAfterOut]);

    useEffect(() => {
        const unauthorizedOnPrivateRoute = loggedOnly && triedToLogin && !logged;
        const insufficientRights = allowedUserTypes && !allowedUserTypes.includes(profile?.type);

        if (unauthorizedOnPrivateRoute || insufficientRights) {
            f7.view.main.router.navigate('/', { reloadCurrent: true, force: true });
        }
    }, [allowedUserTypes, logged, loggedOnly, profile?.type, triedToLogin]);

    return (
        <Page onPageAfterOut={handlePageAfterOut} {...props}>
            {children}
        </Page>
    );
};

import cn from 'classnames';
import { f7, F7SwipeoutActions, F7SwipeoutButton, ListItem } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { CatalogItemProps } from './CatalogItem.types';

import { hasFeatureCode } from './utils';

import { ProductCard } from '@/components/ProductCard/ProductCard';
import { AnalyticDetails } from '@/components/ProductDetails';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { stopPropagation } from '@/utils';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';
import { updateQueryParameterFromURL } from '@/utils/url/updateQueryParameterFromURL';

import './CatalogItem.less';

export const CatalogItem = ({
    categories,
    item,
    item: {
        uid,
        name,
        category,
        price,
        currencyCode,
        discountedPrice,
        salePrice,
        discount,
        wish,
        imageThumbnailUrl1,
        status,
        featureCodes,
        sale,
        rentOptions,
        video,
        rating,
    },
    addToWish,
    onClick,
    swipeoutActions,
    simplePrice,
    showStatus,
    showAnalytics,
    showFeaturesHighlight,
    className,
    videoItem,
    mobileView = false,
    ...props
}: CatalogItemProps): JSX.Element => {
    const link = useMemo(() => (onClick ? '#' : `/product-details/${uid}/`), [onClick, uid]);

    const isMobile = useAppSelector(getIsMobile);
    const activeProductType = useAppSelector(getActiveProductType);

    const onItemClick = useCallback(
        (e) => {
            stopPropagation(e);
            e.preventDefault();

            if (onClick) {
                onClick(uid);
            } else {
                const url = updateQueryParameterFromURL(
                    link,
                    PRODUCT_TYPE_QUERY_NAME,
                    getQueryByProductType(activeProductType),
                );
                f7.view.main.router.navigate(url);
            }
        },
        [onClick, uid, link, activeProductType],
    );

    const onLikeButtonClick = useCallback(() => addToWish(uid), [addToWish, uid]);

    return (
        <ListItem
            noChevron
            swipeout={!!swipeoutActions?.length}
            className={cn('catalog-item', className, {
                'feature-higlight-bold': showFeaturesHighlight && hasFeatureCode('higlight_bold', featureCodes),
                'with-video-preview': videoItem,
            })}
            {...props}
        >
            <ProductCard
                product={item}
                onLikeChange={onLikeButtonClick}
                showHorizontally={isMobile || mobileView}
                onClick={onItemClick}
            />

            {showAnalytics && (
                <div slot="inner-end">
                    <div className="product-stats">
                        <AnalyticDetails type="view" count={item.viewCountString} />
                        <AnalyticDetails type="wish" count={item.wishCount} />
                    </div>
                </div>
            )}

            {!!swipeoutActions?.length &&
                swipeoutActions.map((_item, i) => (
                    <F7SwipeoutActions key={i.toString()} {..._item}>
                        {_item.buttons.map((__item, i) => (
                            <F7SwipeoutButton
                                key={i.toString()}
                                {...{ ...__item, onClick: () => __item.onClick(item) }}
                            />
                        ))}
                    </F7SwipeoutActions>
                ))}
        </ListItem>
    );
};

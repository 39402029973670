import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from './store';

import { useBoolState } from './useBoolState';

import { addProductReview, addReviewLoadingSuccessAction } from '@/actions/reviewActions';
import { AddProductReviewRequest } from '@/generated/marketplaceapi';

export const useReviewPopup = (orderUid?: string, productUid?: string) => {
    const dispatch = useAppDispatch();
    const { addReviewLoading, addReviewSuccess, addReviewError } = useAppSelector((state) => state.reviewReducer);
    const [isAddReviewPopupOpen, openAddReviewPopup, closeAddReviewPopup] = useBoolState(false);

    const onAddReviewClose = useCallback(() => {
        closeAddReviewPopup();
        dispatch(addReviewLoadingSuccessAction(false));
    }, [dispatch, closeAddReviewPopup]);

    const onSubmitAddReview = useCallback(
        (request: AddProductReviewRequest, imageFiles: File[]) =>
            dispatch(addProductReview({ orderUid, ...request }, imageFiles, productUid)),
        [dispatch, orderUid, productUid],
    );

    return {
        addReviewLoading,
        addReviewSuccess,
        addReviewError,
        isAddReviewPopupOpen,
        openAddReviewPopup,
        onAddReviewClose,
        closeAddReviewPopup,
        onSubmitAddReview,
    };
};

import {
    RegistrationBusinessFormErrors,
    RegistrationBusinessFormStep,
    RegistrationBusinessFormValues,
} from './RegistrationBusinessPopup.types';

import {
    ACCEPT_ERROR,
    INVALID_EMAIL_ERROR,
    INVALID_NAME_ERROR,
    INVALID_PASSWORD_CUT_ERROR,
    INVALID_PASSWORD_ERROR,
    NOT_MATCHING_PASSWORDS_ERROR,
    REQUIRED_FIELD_ERROR,
} from '@/shared/constants';
import { isEmail, isValidPassword, isValidUserName } from '@/utils';

const validateAuthData = (values: RegistrationBusinessFormValues): RegistrationBusinessFormErrors => ({
    email: !values.email ? REQUIRED_FIELD_ERROR : !isEmail(values.email) ? INVALID_EMAIL_ERROR : undefined,
    password: !values.password
        ? REQUIRED_FIELD_ERROR
        : !isValidPassword(values.password)
        ? INVALID_PASSWORD_CUT_ERROR
        : undefined,
    passwordRepeat: !values.passwordRepeat
        ? REQUIRED_FIELD_ERROR
        : values.password !== values.passwordRepeat
        ? NOT_MATCHING_PASSWORDS_ERROR
        : undefined,
    accept: !values.accept ? ACCEPT_ERROR : undefined,
});

const validateGeneralData = (values: RegistrationBusinessFormValues): RegistrationBusinessFormErrors => ({
    firstName: !values.firstName
        ? REQUIRED_FIELD_ERROR
        : !isValidUserName(values.firstName)
        ? INVALID_NAME_ERROR
        : undefined,
    lastName: !values.lastName
        ? REQUIRED_FIELD_ERROR
        : !isValidUserName(values.lastName)
        ? INVALID_NAME_ERROR
        : undefined,
    country: !values.country?.value ? REQUIRED_FIELD_ERROR : undefined,
    companyType: !values.companyType?.value ? REQUIRED_FIELD_ERROR : undefined,
    taxPayerIn: !values.taxPayerIn ? REQUIRED_FIELD_ERROR : undefined,
    registrationNumber: !values.registrationNumber ? REQUIRED_FIELD_ERROR : undefined,
    storeName: !values.storeName ? REQUIRED_FIELD_ERROR : undefined,
    storeDescription: !values.storeDescription ? REQUIRED_FIELD_ERROR : undefined,
});

export const validateFormData = (
    values: RegistrationBusinessFormValues,
    step: RegistrationBusinessFormStep,
): RegistrationBusinessFormErrors => {
    if (step === RegistrationBusinessFormStep.AUTH) {
        return validateAuthData(values);
    }

    return validateGeneralData(values);
};

export const getFullErrorMessage = (shortMessage: string) => {
    switch (shortMessage) {
        case INVALID_PASSWORD_CUT_ERROR:
            return INVALID_PASSWORD_ERROR;
        default:
            return undefined;
    }
};

import cn from 'classnames';
import {
    Block,
    BlockHeader,
    BlockTitle,
    Button,
    Chip,
    f7,
    Fab,
    Icon,
    Link,
    List,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
} from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactSupportPopupProps } from './ContactSupportPopup.types';

import { setAlertAction } from '@/actions/alertActions';
import { attachFile, deleteFiles, detachFile, sendMessage } from '@/actions/contactSupportActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { IcClose } from '@/assets';
import { AddImage } from '@/components/AddImage';
import { CustomInput } from '@/components/CustomInput';
import { Classificator } from '@/generated/marketplaceapi';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useErrorAlert } from '@/hooks/useErrorAlert';
import { usePreloader } from '@/hooks/usePreloader';
import { IClassificator } from '@/reducers/classificatorReducer';
import { SupportMessage } from '@/reducers/contactSupportReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { Popups } from '@/types/popups';

import './ContactSupportPopup.less';

export const ContactSupportPopup = ({
    initialSelectedSubjectCode = '',
    errorMessage,
    product,
    category,
    opened,
    className,
    closePopup,
}: ContactSupportPopupProps) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const profile = useAppSelector(getProfile);
    const primaryEmail = (profile?.accountEmails || []).find(({ primary }) => primary)?.email;

    const [selectedSubject, setSelectedSubject] = useState<Classificator | undefined>();
    const [email, setEmail] = useState(primaryEmail || '');
    const [message, setMessage] = useState('');

    const isMobile = useAppSelector(getIsMobile);

    const { sending, error, files } = useAppSelector((state) => state.contactSupportReducer);
    const subjects = useAppSelector((state) => state.classificatorReducer.claimSubjectsClassificators);

    useEffect(() => {
        setSelectedSubject(subjects[category].find(({ code }) => code === initialSelectedSubjectCode));
    }, [category, initialSelectedSubjectCode, subjects]);

    useEffect(() => {
        dispatch(deleteFiles());
    }, [dispatch]);

    usePreloader(sending);

    useErrorAlert(error);

    const sendMessageHandle = useCallback(() => {
        if (!selectedSubject) {
            dispatch(
                setAlertAction({
                    text: t('Please select message subject'),
                }),
            );
            return;
        }

        if (!email || !message) {
            dispatch(
                setAlertAction({
                    text: t('Please fill out all fields'),
                }),
            );
            return;
        }

        f7.preloader.show();

        const parameters = {};
        if (product) {
            parameters['productUid'] = product.uid;
            parameters['productName'] = product.name;
        }

        const fullMessage = errorMessage ? `${message} \n Error info: ${errorMessage}` : message;

        const supportMessage: SupportMessage = {
            subject: selectedSubject?.value,
            email,
            message: fullMessage,
            parameters,
        };

        dispatch(sendMessage(supportMessage)).then((getState) => {
            firebaseAnalyticsInstance.contactSupportRequestSent?.();

            if (!getState().contactSupportReducer.error) {
                closePopup();

                f7.toast
                    .create({
                        text: t('The message has been sent'),
                        closeTimeout: 3000,
                        position: 'bottom',
                        horizontalPosition: 'center',
                    })
                    .open();
            }
        });
    }, [closePopup, dispatch, email, errorMessage, message, product, selectedSubject, t]);

    const onSelectFile = useCallback((file?: File) => dispatch(attachFile(file)), [dispatch]);

    const onDeleteFile = useCallback((index: number) => dispatch(detachFile(index)), [dispatch]);

    const onMessageChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value), []);

    const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), []);

    const handleOnPopupClose = useCallback(() => {
        dispatch(deleteFiles());
    }, [dispatch]);

    const handleOnPopupOpen = useCallback(() => {
        firebaseAnalyticsInstance.openContactSupport?.();
    }, []);

    const handleCloseSelf = useCallback(() => {
        closePopup();
    }, [closePopup]);

    return (
        <MSPopup
            className={cn(Popups.CONTACT_SUPPORT_POPUP, 'contact-support', className)}
            opened={opened}
            fullScreen
            onPopupClose={handleOnPopupClose}
            onPopupOpen={handleOnPopupOpen}
            onBackdropClick={handleCloseSelf}
        >
            <Navbar noHairline noShadow>
                <NavLeft>
                    <Link onClick={handleCloseSelf}>
                        <IcClose className={cn('contact-support__close')} />
                    </Link>
                </NavLeft>
                {!isMobile && <NavTitle>{t('Contact Support')}</NavTitle>}
                {!isMobile && (
                    <NavRight>
                        <Button fill large round raised disabled={sending} onClick={sendMessageHandle}>
                            {t('Send')}
                        </Button>
                    </NavRight>
                )}
            </Navbar>
            <div className="contact-support__content">
                <Block className={cn('contact-support__subject')}>
                    <BlockTitle>{t('Contact Support')}</BlockTitle>
                    <BlockHeader>{t('Help us understand whats happening. How would you describe it?')}</BlockHeader>
                    <div className="contact-support__subject__chips">
                        {subjects[category]?.map((subject: IClassificator) => (
                            <Link key={subject.code} onClick={() => setSelectedSubject(subject)}>
                                <Chip
                                    text={subject.value}
                                    className={selectedSubject?.code === subject.code ? 'select' : ''}
                                />
                            </Link>
                        ))}
                    </div>
                </Block>
                <List noHairlines form className={cn('contact-support__form', 'block')}>
                    <CustomInput
                        label={t('E-mail')}
                        type="email"
                        requiredSign
                        required
                        clearButton={!primaryEmail}
                        validateOnBlur
                        disabled={!!primaryEmail}
                        onInput={onEmailChange}
                        value={email}
                    />
                    <CustomInput
                        label={t('Your comment')}
                        type="textarea"
                        requiredSign
                        required
                        clearButton
                        validateOnBlur
                        onInput={onMessageChange}
                    />
                </List>
                <Block>
                    <AddImage
                        max={5}
                        className="contact-support__images"
                        images={files.map((item) => item.imageLink)}
                        onDelete={onDeleteFile}
                        onChange={onSelectFile}
                    />
                </Block>

                {isMobile && (
                    <Fab position="right-bottom" slot="fixed" onClick={sendMessageHandle}>
                        <Icon ios="f7:checkmark_alt" md="material:send" />
                    </Fab>
                )}
            </div>
        </MSPopup>
    );
};

import { useEffect } from 'react';

import { loadAllGoods } from '@/actions/allGoodsActions';
import { loadProductDetail, reloadAllProductListTypes, reloadProducts } from '@/actions/productActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePrevious } from '@/hooks/usePrevious';
import { DEFAULT_ALL_GOODS_PRODUCTS_COUNT } from '@/reducers/allGoodsReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const ReloadAllProductsOnLanguageChange = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(getLanguageCode);
    const prevLanguage = usePrevious(language);

    const { productDetails, products } = useAppSelector((state) => state.productReducer);

    useEffect(() => {
        if (language && prevLanguage && prevLanguage !== language) {
            if (productDetails?.uid) {
                dispatch(loadProductDetail(productDetails.uid));
            }
            dispatch(reloadAllProductListTypes());
            dispatch(loadAllGoods({ count: DEFAULT_ALL_GOODS_PRODUCTS_COUNT }, true));
            if (products?.length) {
                dispatch(reloadProducts());
            }
        }
    }, [dispatch, language, prevLanguage, productDetails?.uid, products?.length]);

    return null;
};

import { client, marketplaceapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import {
    Store,
    ProductStoreControllerApi,
    ServiceProviderControllerApi,
    CommonMarketplaceApiResponseStore,
} from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { getProfile } from '@/selectors/profile';
import { ProductType } from '@/types/productType';

export const SAVE_ACCOUNT_STORES_SUCCESS = 'SAVE_ACCOUNT_STORES_SUCCESS' as const;
export const SAVE_ACCOUNT_STORES_LOADING = 'SAVE_ACCOUNT_STORES_LOADING' as const;
export const SAVE_ACCOUNT_STORES_ERROR = 'SAVE_ACCOUNT_STORES_ERROR' as const;

export const RESET_ACCOUNT_STORES = 'RESET_ACCOUNT_STORES' as const;

const accountStoresLoading = (loading: boolean) => ({
    type: SAVE_ACCOUNT_STORES_LOADING,
    loading,
});

const saveAccountStoresSuccess = (accountStores: Store[]) => ({
    type: SAVE_ACCOUNT_STORES_SUCCESS,
    accountStores,
});

const saveAccountStoresError = (error: unknown) => ({
    type: SAVE_ACCOUNT_STORES_ERROR,
    error,
});

export const resetAccountStores = () => ({ type: RESET_ACCOUNT_STORES });

export type AccountStoreActions = ReturnType<
    | typeof accountStoresLoading
    | typeof saveAccountStoresSuccess
    | typeof saveAccountStoresError
    | typeof resetAccountStores
>;

export const getAccountStores = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(accountStoresLoading(true));
    try {
        const activeProductType = getActiveProductType(getState());

        const response =
            activeProductType === ProductType.Service
                ? await new ServiceProviderControllerApi().accountStoreListUsingGET1()
                : await new ProductStoreControllerApi().accountStoreListUsingGET();

        handleResponseAndThrowAnErrorIfExists(response);

        const accountStores = response.body || [];

        dispatch(saveAccountStoresSuccess(accountStores));
    } catch (error) {
        console.error('at accountStoresActions in getAccountStores', error);
        dispatch(saveAccountStoresError(error.message));
    } finally {
        dispatch(accountStoresLoading(false));
    }
};

const attachStoreImage = async (base64ImgNoHeader: string, filename: string, profileUid: string): Promise<string> => {
    const params = new URLSearchParams();
    params.append('base64File', base64ImgNoHeader);
    params.append('fileName', filename);
    params.append('bucket', 'store');

    const result = await client.post(`${marketplaceapiURL}/image/${profileUid}/upload/base64`, params, {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
    });
    const avatar = result?.data?.body?.[0]?.url;

    return avatar;
};

export const isStoreNameAvailable = async (storeName: string) => {
    const storeController = new ProductStoreControllerApi();

    try {
        const response = await storeController.checkAccountStoreUsingPOST({ name: storeName });

        return response.body[0].nameAvailable;
    } catch (error) {
        console.error('at isStoreNameAvailable', error);
        throw error;
    }
};

export const isServiceProviderNameAvailable = async (serviceProviderName: string) => {
    const spController = new ServiceProviderControllerApi();

    try {
        const response = await spController.checkAccountStoreUsingPOST1({ name: serviceProviderName });

        return response.body[0].nameAvailable;
    } catch (error) {
        console.error('at isServiceProviderNameAvailable', error);
        throw error;
    }
};

export const createAccountStore =
    (storeInfo: Store) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const loggedIn = isLoggedIn(state);

        if (!loggedIn) {
            return;
        }

        const profile = getProfile(state);

        try {
            dispatch(accountStoresLoading(true));

            if (await isStoreNameAvailable(storeInfo.name)) {
                const imageUrl = await attachStoreImage(storeInfo.imageUrl, storeInfo.name, profile.uid);

                let response = await new ProductStoreControllerApi().addStoreUsingPUT(
                    { ...storeInfo, imageUrl, accountUid: profile.uid },
                    getCountryCodeFromState(state),
                    getLanguageCode(state),
                );

                dispatch(saveAccountStoresSuccess(response.body));
            } else {
                throw new Error('Store name is already taken. Please, choose another one.');
            }
        } catch (error) {
            console.error('at accountStoresActions in createAccountStore', error);
            dispatch(saveAccountStoresError(JSON.stringify(error)));
        } finally {
            dispatch(accountStoresLoading(false));
        }
    };

export const createServiceProvider =
    (storeInfo: Store) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const loggedIn = isLoggedIn(state);

        if (!loggedIn) {
            return getState();
        }

        const profile = getProfile(state);

        try {
            dispatch(accountStoresLoading(true));

            if (await isServiceProviderNameAvailable(storeInfo.name)) {
                const imageUrl = await attachStoreImage(storeInfo.imageUrl, storeInfo.name, profile.uid);
                const response = await new ServiceProviderControllerApi().addStoreUsingPUT1(
                    { ...storeInfo, imageUrl, accountUid: profile.uid },
                    getCountryCodeFromState(state),
                    getLanguageCode(state),
                );

                dispatch(saveAccountStoresSuccess(response.body));
            } else {
                throw new Error(
                    'services.form.start_providing_services.validation_error_message.check_name_uniqueness',
                );
            }
        } catch (error) {
            console.error('at accountStoresActions in createServiceProvider', error);
            dispatch(saveAccountStoresError(error.message));
        } finally {
            dispatch(accountStoresLoading(false));
        }

        return getState();
    };

export const createAccountRegistrationStore =
    (storeInfo: Store) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();

        try {
            dispatch(accountStoresLoading(true));

            const activeProductType = getActiveProductType(getState());

            let response: CommonMarketplaceApiResponseStore;

            if (activeProductType === ProductType.Service) {
                response = await new ServiceProviderControllerApi().addStoreUsingPUT1(
                    storeInfo,
                    getCountryCodeFromState(state),
                    getLanguageCode(state),
                );
            } else {
                response = await new ProductStoreControllerApi().addStoreUsingPUT(
                    storeInfo,
                    getCountryCodeFromState(state),
                    getLanguageCode(state),
                );
            }

            dispatch(saveAccountStoresSuccess(response.body));
        } catch (error) {
            console.error('at accountStoresActions in createAccountStore', error);
            dispatch(saveAccountStoresError(JSON.stringify(error)));
        } finally {
            dispatch(accountStoresLoading(false));
        }
    };

export const editAccountStore =
    (storeData: Store) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const loggedIn = isLoggedIn(state);
        const profile = getProfile(state);
        const prevStore = state.accountStoresReducer.accountStores.find(({ uid }) => storeData.uid === uid);

        if (!loggedIn) {
            return;
        }

        try {
            dispatch(accountStoresLoading(true));
            let imageUrl = prevStore.imageUrl;

            if (prevStore.imageUrl !== storeData.imageUrl) {
                imageUrl = await attachStoreImage(storeData.imageUrl, storeData.name, profile.uid);
            }

            const response = await new ProductStoreControllerApi().updateStoreUsingPOST(
                Object.assign(storeData, { imageUrl }),
                getCountryCodeFromState(state),
                getLanguageCode(state),
            );

            dispatch(saveAccountStoresSuccess(response.body));
        } catch (error) {
            console.error('at accountStoresActions in editAccountStore', error);
            dispatch(saveAccountStoresError(JSON.stringify(error)));
        } finally {
            dispatch(accountStoresLoading(false));
        }
    };

export const editServiceProvider =
    (storeData: Store) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        const loggedIn = isLoggedIn(state);
        const profile = getProfile(state);
        const prevStore = state.accountStoresReducer.accountStores.find(({ uid }) => storeData.uid === uid);

        if (!loggedIn) {
            return getState();
        }

        try {
            dispatch(accountStoresLoading(true));

            if (storeData.name === prevStore.name || (await isServiceProviderNameAvailable(storeData.name))) {
                let imageUrl = prevStore.imageUrl;

                if (prevStore.imageUrl !== storeData.imageUrl) {
                    imageUrl = await attachStoreImage(storeData.imageUrl, storeData.name, profile.uid);
                }

                let response = await new ServiceProviderControllerApi().updateStoreUsingPOST1(
                    Object.assign(storeData, { imageUrl }),
                    getCountryCodeFromState(state),
                    getLanguageCode(state),
                );

                dispatch(saveAccountStoresSuccess(response.body));
            } else {
                throw new Error(
                    'services.form.start_providing_services.validation_error_message.check_name_uniqueness',
                );
            }
        } catch (error) {
            console.error('at accountStoresActions in editServiceProvider', error);
            dispatch(saveAccountStoresError(error.message));
        } finally {
            dispatch(accountStoresLoading(false));
        }

        return getState();
    };

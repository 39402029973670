import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PriceProps } from './Price.types';

import { IcSale } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { formatPrice } from '@/utils';

import './Price.less';

export const Price = ({
    price,
    discountedPrice,
    currencyCode,
    className,
    negotiatedPrice = false,
    period,
    withSaleIcon = false,
    direction = 'row',
    size = 'medium',
}: PriceProps): JSX.Element => {
    const { t } = useTranslation();

    const { currencyClassificator } = useAppSelector((state) => state.classificatorReducer);

    const currencySign = useMemo(
        () => currencyClassificator?.find((item) => item.code === currencyCode)?.symbol ?? currencyCode,
        [currencyClassificator, currencyCode],
    );

    const makeFormattedPrice = useCallback(
        (price: number) => formatPrice(price, currencySign, false, period ? t(`per ${period}`.toLowerCase()) : ''),
        [currencySign, period, t],
    );

    return (
        <div className={cn('price', { discounted: !!discountedPrice && !negotiatedPrice }, direction, size, className)}>
            {discountedPrice && !negotiatedPrice ? (
                <>
                    <ins className={size}>
                        {withSaleIcon && <IcSale className={cn('icon', size)} />}
                        <span>{makeFormattedPrice(discountedPrice)}</span>
                    </ins>
                    <del className={size}>
                        <span>{makeFormattedPrice(price)}</span>
                    </del>
                </>
            ) : (
                <ins className={size}>
                    <span>
                        {negotiatedPrice ? t('form.view_service.price.negotiable_price') : makeFormattedPrice(price)}
                    </span>
                </ins>
            )}
        </div>
    );
};

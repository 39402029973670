/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultDisplayedUrls } from './PrivacyAndTerms.constants';

import { PrivacyAndTermsProps } from './PrivacyAndTerms.types';

import { messengerService } from '@/api/messengerService';
import { useAppSelector } from '@/hooks/store';

import './PrivacyAndTerms.less';

export const PrivacyAndTerms = ({
    displayedUrls = defaultDisplayedUrls,
    className,
    ...props
}: PrivacyAndTermsProps) => {
    const { t } = useTranslation();

    const urls = useAppSelector((state) => state.classificatorReducer.entitiesClassificators.Url_app);

    const getAcceptLinkUrl = useCallback((code: string) => urls.find((url) => url.code === code)?.value ?? '#', [urls]);

    const handleLinkClick = useCallback(
        (linkName: string) => (event: React.MouseEvent) => {
            event.preventDefault();
            messengerService.openExternalLink(getAcceptLinkUrl(linkName), '_blank');
        },
        [getAcceptLinkUrl],
    );

    return (
        <>
            {displayedUrls.map(({ urlCode, title }, index, arr) => (
                <React.Fragment key={urlCode}>
                    <a
                        tabIndex={1}
                        {...props}
                        href="#"
                        className={cn('privacy-and-terms__link', className)}
                        onClick={handleLinkClick(urlCode)}
                    >
                        {t(title)}
                    </a>
                    {index !== arr.length - 1 && <>&nbsp;{t('and')}&nbsp;</>}
                </React.Fragment>
            ))}
        </>
    );
};

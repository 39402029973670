import cn from 'classnames';
import { Button, f7, Link, Navbar, NavLeft, NavRight, NavTitle } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ConfigureSaleNavbarProps } from './ConfigureSaleNavbar.types';

import { deleteSale } from '@/actions/saleActions';
import { IcArrowRight, IcClose } from '@/assets';
import { openConfirm } from '@/shared/confirmDialog';

import './ConfigureSaleNavbar.less';

export const ConfigureSaleNavbar = React.memo<ConfigureSaleNavbarProps>(
    ({ f7router, isMobile, isEdit, onNextStep, stepChangeDisabled, nextButtonText, showArrow, saleUid, ...props }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const handleClose = useCallback(
            () =>
                openConfirm(
                    t('All changes will be lost. Are you sure you would like to exit screen and discard the changes?'),
                    () => {
                        if (!isEdit && saleUid) {
                            dispatch(deleteSale(saleUid));
                        }
                        f7router.navigate('/profile/seller-area/sale', {
                            force: true,
                        });
                    },
                ),
            [dispatch, f7router, isEdit, saleUid, t],
        );

        return (
            <Navbar
                noHairline
                noShadow
                className={cn('configure-sale-navbar', {
                    mobile: isMobile,
                    'with-icon': showArrow,
                })}
                {...props}
            >
                <NavLeft>
                    <Link iconOnly onClick={handleClose}>
                        <IcClose />
                    </Link>
                </NavLeft>
                <NavTitle>{t(isEdit ? 'Edit' : 'New Sale')}</NavTitle>
                {!isMobile && (
                    <NavRight>
                        <Button
                            className="nav-button next"
                            fill
                            round
                            onClick={onNextStep}
                            disabled={stepChangeDisabled}
                        >
                            {t(nextButtonText)}
                            {showArrow && <IcArrowRight />}
                        </Button>
                    </NavRight>
                )}
            </Navbar>
        );
    },
);
ConfigureSaleNavbar.displayName = 'ProductCreateNavbar';

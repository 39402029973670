import {
    ProductPromotionActions,
    PUBLISHED_PRODUCTS_LOADING,
    PUBLISHED_PRODUCTS_LOADING_ERROR,
    PUBLISHED_PRODUCTS_LOADING_SUCCESS,
    SERVICE_PACKAGES_LOADING,
    SERVICE_PACKAGES_LOADING_ERROR,
    SERVICE_PACKAGES_LOADING_SUCCESS,
} from '@/actions/productPromotionActions';
import { Product, ProductFeatureType } from '@/generated/marketplaceapi';

export interface IProductPromotionState {
    servicePackagesLoading?: boolean;
    servicePackagesLoadingError?: unknown;
    servicePackages: ProductFeatureType[];
    publishedProductsLoading?: boolean;
    publishedProductsLoadingError?: unknown;
    publishedProducts: Product[];
}

const initialState: IProductPromotionState = {
    servicePackagesLoading: false,
    servicePackagesLoadingError: null,
    servicePackages: [],
    publishedProductsLoading: false,
    publishedProductsLoadingError: null,
    publishedProducts: [],
};

const productPromotionReducer = (state = initialState, action: ProductPromotionActions): IProductPromotionState => {
    switch (action.type) {
        case SERVICE_PACKAGES_LOADING:
            return {
                ...state,
                servicePackagesLoading: true,
                servicePackagesLoadingError: null,
            };
        case SERVICE_PACKAGES_LOADING_SUCCESS:
            return {
                ...state,
                servicePackagesLoading: false,
                servicePackages: action.items,
            };
        case SERVICE_PACKAGES_LOADING_ERROR:
            return {
                ...state,
                servicePackagesLoading: false,
                servicePackagesLoadingError: action.error,
            };
        case PUBLISHED_PRODUCTS_LOADING:
            return {
                ...state,
                publishedProductsLoading: true,
                publishedProductsLoadingError: null,
            };
        case PUBLISHED_PRODUCTS_LOADING_SUCCESS:
            return {
                ...state,
                publishedProductsLoading: false,
                publishedProducts: action.items,
            };
        case PUBLISHED_PRODUCTS_LOADING_ERROR:
            return {
                ...state,
                publishedProductsLoading: false,
                publishedProductsLoadingError: action.error,
            };
        default:
            return state;
    }
};

export default productPromotionReducer;

import { F7Toolbar } from 'framework7-react';

export enum HistogramToolbarOptions {
    ORDERS = 'ORDERS',
    MESSAGES = 'MESSAGES',
    WISHES = 'WISHES',
}

export type HistogramToolbarProps = F7Toolbar.Props & {
    onTypeChange: (type: HistogramToolbarOptions) => void;
    activeType: HistogramToolbarOptions;
    className?: string;
};

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { PromotionPage, PromotionPageControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const PROMOTION_INFO_LOADING = 'PROMOTION_INFO_LOADING' as const;
export const PROMOTION_INFO_LOADING_ERROR = 'PROMOTION_INFO_LOADING_ERROR' as const;
export const PROMOTION_INFO_LOADING_SUCCESS = 'PROMOTION_INFO_LOADING_SUCCESS' as const;

const promotionInfoLoadingAction = (promotionInfoLoading: boolean) => ({
    type: PROMOTION_INFO_LOADING,
    payload: { promotionInfoLoading },
});

const promotionInfoLoadingSuccessAction = (promotionInfo: PromotionPage) => ({
    type: PROMOTION_INFO_LOADING_SUCCESS,
    payload: { promotionInfo },
});

const promotionInfoLoadingErrorAction = (promotionInfoError: string) => ({
    type: PROMOTION_INFO_LOADING_ERROR,
    payload: { promotionInfoError },
});

export type PromotionActions = ReturnType<
    | typeof promotionInfoLoadingAction
    | typeof promotionInfoLoadingErrorAction
    | typeof promotionInfoLoadingSuccessAction
>;

export const loadPromoInfo = (promotionUID) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(promotionInfoLoadingAction(true));
    const state = getState();
    try {
        const response = await new PromotionPageControllerApi().getPageUsingGET(
            promotionUID,
            getCountryCodeFromState(state),
            getLanguageCode(state),
        );
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(promotionInfoLoadingSuccessAction(response.body?.[0]));
    } catch (error) {
        console.error(error, 'in promotionActions in loadPromoInfo');
        dispatch(promotionInfoLoadingErrorAction((error as Error)?.message || `${error}`));
    } finally {
        dispatch(promotionInfoLoadingAction(false));
    }
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShippingInfoProps } from './ShippingInfo.types';

import { DeliveryInformationBlock } from '@/components/ProductDetails/DeliveryInformationBlock';
import { DeliveryMethod } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { formatPrice } from '@/utils';

import './ShippingInfo.less';

export const ShippingInfo = ({ country, product }: ShippingInfoProps) => {
    const { t } = useTranslation();
    const { currencyClassificator } = useAppSelector((state) => state.classificatorReducer);

    const message = useMemo(() => {
        let text = '';
        const countryCode = country ? country.code : '_______';
        const deliveryMethods = product.deliveryMethods || [];
        const byCountry = deliveryMethods.filter(
            (method) =>
                method.options?.some(
                    (option) =>
                        option.countries?.some(({ code }) => code === countryCode) ||
                        option.pickupAddress?.countryCode === countryCode,
                ),
        );

        const pickupPoints = byCountry.filter((method) => method.type === DeliveryMethod.TypeEnum.PICKUP);

        if (!deliveryMethods.length) {
            text = t('Contact the seller to discuss shipping details');
        } else if (deliveryMethods.length && !byCountry.length && !pickupPoints.length) {
            text = t('No delivery to selected country');
        } else {
            const getLowestOptionPrice = (method: DeliveryMethod) =>
                method.options && method.options.length
                    ? method.options
                          .filter((option) => option.countries.some(({ code }) => code === countryCode))
                          .sort((a, b) => a.price - b.price)[0].price
                    : 0;

            const lowestPriceMethod = byCountry
                .filter((m) => m.type === DeliveryMethod.TypeEnum.DELIVERY)
                .sort((a, b) => getLowestOptionPrice(a) - getLowestOptionPrice(b))[0];

            if (lowestPriceMethod && lowestPriceMethod.options && lowestPriceMethod.options.length) {
                const lowestPriceOption = lowestPriceMethod.options[0];
                text = `+ ${t('Shipping price', {
                    price: formatPrice(
                        lowestPriceOption.price,
                        currencyClassificator.find((item) => item.code === product.currencyCode)?.symbol ??
                            product.currencyCode,
                    ),
                })}`;
            } else if (pickupPoints[0]) {
                const point = pickupPoints[0];
                if (point.options && point.options[0]) {
                    const option = point.options[0];
                    text = [
                        option.pickupAddress.countryCode,
                        option.pickupAddress.city,
                        option.pickupAddress.firstAddressLine,
                        option.pickupAddress.postalCode,
                    ]
                        .filter(Boolean)
                        .join(', ');
                }
            }
        }

        return text;
    }, [country, currencyClassificator, product.currencyCode, product.deliveryMethods, t]);

    if (product.type === 'S') {
        return null;
    }

    if (!product.shippingAllowed && !product.pickupAllowed) {
        return null;
    }

    return (
        <div className="shipping-info">
            <p className="shipping-info__message">{message}</p>
            <DeliveryInformationBlock product={product} />
        </div>
    );
};

import { Button, Col, f7, Link, Navbar, NavLeft, NavTitle, Row } from 'framework7-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { VerifyInformationProps } from './VerifyInformation.types';

import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { useAppSelector } from '@/hooks/store';
import { getProfile } from '@/selectors/profile';

import './VerifyInformation.less';
import { AccountType } from '@/types/account';

export const VerifyInformationInner = React.memo(({ f7router, isEdit }: VerifyInformationProps) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector((state) => state.rootReducer.resizeEvent.width < 768);
    const profile = useAppSelector(getProfile);

    const desktopLink =
        profile.type !== AccountType.BUSINESS ? '/profile/seller-area/my-goods/' : '/profile/seller-area/store';

    const navigateToSellerArea = useCallback(() => {
        f7router.navigate(isMobile ? '/profile/seller-area/' : desktopLink);
    }, [desktopLink, f7router, isMobile]);

    const hidePreloader = useCallback(() => f7.preloader.hide(), []);

    return (
        <CustomPage
            loggedOnly
            id="verify_business_account_page"
            name="verify_business_account_page"
            onPageAfterIn={hidePreloader}
        >
            <Navbar noHairline noShadow>
                <NavLeft>
                    {!isMobile ? (
                        <Link href={desktopLink} iconF7="xmark" iconOnly />
                    ) : (
                        <Link href="/profile/seller-area/" iconF7="xmark" iconOnly panelClose />
                    )}
                </NavLeft>
                {isEdit && !isMobile && <NavTitle>{t('Edit')}</NavTitle>}
            </Navbar>

            <Row resizableFixed>
                <Col width="100">
                    <div className="main-content">
                        <h2>{t('Your data is being verified')}</h2>
                        <p>
                            {t(
                                isEdit
                                    ? 'We will check your data soon. After passing the test the information will be changed.'
                                    : 'Your data will be checked soon. Meanwhile, you can add products and set up your homepage.',
                            )}
                        </p>
                        <Button fill round onClick={navigateToSellerArea}>
                            {t(isEdit ? 'Go Back' : 'Seller Area')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </CustomPage>
    );
});

VerifyInformationInner.displayName = 'VerifyInformationInner';

export const VerifyInformation = ConnectPageWrapper(VerifyInformationInner);

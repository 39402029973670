import {
    GeoLocationActions,
    INIT_GEOLOCATION_ERROR,
    INIT_GEOLOCATION_LOADING,
    INIT_GEOLOCATION_SUCCESS,
} from '@/actions/geolocationActions';

export interface IGeolocationState {
    geolocationLoading: boolean;
    accuracy: number;
    latitude: number;
    longitude: number;
    country: { long_name: string; short_name: string };
    countryName: string;
    countryCode: string;
    city: string;
    postalCode: string;
}

const initialState: IGeolocationState = {
    geolocationLoading: false,
    accuracy: 0,
    city: '',
    country: { long_name: '', short_name: '' },
    countryCode: '',
    countryName: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
};

const geolocationReducer = (state = initialState, action: GeoLocationActions): IGeolocationState => {
    switch (action.type) {
        case INIT_GEOLOCATION_LOADING: {
            return {
                ...state,
                geolocationLoading: true,
            };
        }
        case INIT_GEOLOCATION_SUCCESS: {
            return {
                ...state,
                ...action.data,
                geolocationLoading: false,
            };
        }
        case INIT_GEOLOCATION_ERROR: {
            return {
                ...state,
                ...initialState,
                geolocationLoading: false,
            };
        }
        default:
            return state;
    }
};

export default geolocationReducer;

import cn from 'classnames';
import { Button, Navbar, NavLeft, NavRight, NavTitle } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StepNavbarProps } from './StepNavbar.types';

import { IcArrowRight, IcClose } from '@/assets';

import './StepNavbar.less';

export const StepNavbar: React.FC<StepNavbarProps> = ({
    title,
    showNextButton,
    onCloseClick,
    onNextButtonClick,
    isLastStep,
    changeStepDisabled,
    isEdit,
    show,
}) => {
    const { t } = useTranslation();
    const nextButtonContent = useMemo(
        () =>
            isLastStep ? (
                isEdit ? (
                    t('Save')
                ) : (
                    t('Start Import')
                )
            ) : (
                <>
                    <span>{t('Next')}</span>
                    <IcArrowRight />
                </>
            ),
        [isEdit, isLastStep, t],
    );
    return (
        <Navbar className={cn('import-products-steps-navbar', { show })} noHairline noShadow>
            <NavLeft>
                <IcClose onClick={onCloseClick} />
            </NavLeft>
            <NavTitle>{t(title)}</NavTitle>
            {showNextButton && (
                <NavRight>
                    <Button
                        onClick={onNextButtonClick}
                        fill
                        round
                        disabled={changeStepDisabled}
                        className={cn({ 'last-step': isLastStep })}
                    >
                        {nextButtonContent}
                    </Button>
                </NavRight>
            )}
        </Navbar>
    );
};

import { useMemo } from 'react';

import {
    IcAddressbook,
    IcAlertCircle,
    IcGlobe,
    IcLocationStroke,
    IcLogin,
    IcMarketspace,
    IcOrders,
    IcPassport,
    IcPlus,
    IcReview,
    IcSettings,
    IcStarOutlined,
    IcTransaction,
    IcUser,
    IcUsers,
    IcWishNotFilled,
} from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { Profile } from '@/reducers/sessionReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getProfile } from '@/selectors/profile';
import { AccountStatus, AccountType } from '@/types/account';
import { ProductType } from '@/types/productType';
import { ProfileMenuItem, ProfileMenuItemName } from '@/types/profileMenuItems';

export const getProfileMenuConfig = (
    profile: Profile,
    mobile: boolean,
    productType?: ProductType,
): Partial<Record<ProfileMenuItemName, ProfileMenuItem | undefined>> => ({
    About: {
        name: 'About Us',
        icon: IcAlertCircle,
        iconClassName: 'about-icon',
    },
    AddProduct: {
        name: 'Add Product',
        icon: IcPlus,
        link: '/profile/seller-area/my-goods/add/',
        iconClassName: 'add-product-icon',
    },
    MyFavorites:
        productType === ProductType.Advertisement
            ? undefined
            : {
                  name:
                      productType === ProductType.Service
                          ? 'services.profile_menu.button.my_favorite_profiles'
                          : 'Favorite Stores',
                  icon: IcStarOutlined,
                  link: '/favorites/',
                  iconClassName: 'favorites-icon',
              },
    MyOrders:
        productType !== ProductType.Product
            ? undefined
            : {
                  name: 'My Orders',
                  icon: IcOrders,
                  link: '/profile/personal/orders/',
                  iconClassName: 'my-orders-icon',
              },
    // MyWallet: {
    //     name: 'Wallet',
    //     icon: IcWallet,
    //     link: mobile ? '/profile/personal/wallet/' : '/profile/personal/wallet/currencies/',
    //     iconClassName: 'wallet-icon',
    // },
    MyWishList: {
        name: 'Wish List',
        icon: IcWishNotFilled,
        link: '/wish-list/',
        iconClassName: 'wishlist-icon',
    },
    ProfileSettings: {
        link: '/profile/personal/settings/',
        name: 'Profile Settings',
        icon: IcSettings,
        iconClassName: 'settings-icon',
    },
    SellerArea:
        productType === ProductType.Product && profile.type !== AccountType.STANDARD
            ? {
                  name: 'Seller Area',
                  link: mobile ? '/profile/seller-area/' : `/profile/seller-area/store/`,
                  icon: IcMarketspace,
                  iconClassName: 'seller-area-icon',
              }
            : undefined,
    ShippingAddresses:
        productType === ProductType.Product
            ? {
                  link: '/profile/personal/shipping-addresses/',
                  name: 'Shipping Address',
                  icon: IcAddressbook,
                  iconClassName: 'shipping-addresses-icon',
              }
            : undefined,
    StartSelling:
        productType === ProductType.Product && profile.type !== AccountType.BUSINESS
            ? {
                  name: 'Start Selling',
                  icon: IcMarketspace,
                  iconClassName: 'seller-area-icon',
              }
            : productType === ProductType.Service && !(profile.serviceProviders?.length > 0)
            ? {
                  name: 'services.profile_menu.button.start_providing_services',
                  icon: IcMarketspace,
                  iconClassName: 'seller-area-icon',
              }
            : undefined,
    Transactions:
        productType === ProductType.Product
            ? {
                  link: '/profile/personal/transactions/',
                  icon: IcTransaction,
                  name: 'Transactions',
                  iconClassName: 'transactions-icon',
              }
            : undefined,
    Logout: {
        name: 'Log Out',
        icon: IcLogin,
        mobileDanger: false,
        mobileNoIcon: false,
        mobileNoChevron: false,
        iconClassName: 'logout-icon',
    },
    DeliverTo: {
        name: 'Deliver to',
        icon: IcLocationStroke,
        iconClassName: 'deliver-to-icon',
        mobileNoIcon: false,
        mobileNoChevron: false,
    },
    Language: {
        icon: IcGlobe,
        iconClassName: 'language-icon',
        name: 'Language',
        mobileNoChevron: false,
        mobileNoIcon: false,
    },
    MyPersonal: {
        name: 'My Profile',
        link: mobile ? '/profile/personal/' : '/profile/personal/settings/',
        icon: IcUser,
    },
    Verify: {
        icon: IcPassport,
        link: profile.status === AccountStatus.SC ? undefined : '/profile/personal/verify',
        name: profile.status !== AccountStatus.SF ? 'Verify Your Account' : 'Passport',
    },
    MyReviews: {
        name: 'My Reviews',
        link: '/profile/personal/reviews',
        icon: IcReview,
    },
    Invite: {
        name: 'Invite Friends',
        icon: IcUsers,
    },
    MyOffers:
        productType === ProductType.Advertisement
            ? {
                  name: 'profile_menu.button.my_offers',
                  link: '/profile/seller-area/my-goods/',
                  icon: IcMarketspace,
                  iconClassName: 'seller-area-icon',
              }
            : productType === ProductType.Service && profile.serviceProviders?.length > 0
            ? {
                  name: 'services.profile_menu.button.my_services',
                  link: mobile ? '/profile/seller-area/' : '/profile/seller-area/store/',
                  icon: IcMarketspace,
                  iconClassName: 'seller-area-icon',
              }
            : undefined,
});

export const PROFILE_MENU_STORE_PREFIX = 'STORE:';

export const useProfileMenuConfigItems = (isMobile: boolean, isLogged: boolean) => {
    const activeProductType = useAppSelector(getActiveProductType);
    const profile = useAppSelector(getProfile);

    const { profileMenuItems, profileMenuItemsNotBusiness, profileMenuItemsMobile, profileMenuItemsMobileUnlogged } =
        useAppSelector((state) => state.rootReducer.localConfig);

    const menuItems = useMemo(
        () => getProfileMenuConfig(profile, isMobile, activeProductType),
        [isMobile, profile, activeProductType],
    );

    const menuItemsKeys = useMemo(() => {
        let menu: string[][] = [];

        if (isMobile) {
            menu = isLogged ? profileMenuItemsMobile : profileMenuItemsMobileUnlogged;
        } else {
            menu =
                profile.type !== AccountType.STANDARD
                    ? activeProductType === ProductType.Service
                        ? [...profileMenuItemsNotBusiness, ...profileMenuItems]
                        : profileMenuItems
                    : [...profileMenuItemsNotBusiness, ...profileMenuItems];
        }

        if (isMobile && profile.type === AccountType.BUSINESS && activeProductType === ProductType.Product) {
            const index = menu.findIndex((group) => group.includes('StartSelling'));
            if (index) {
                menu = [...menu];
                menu[index] = (profile?.stores || []).map((store) => `${PROFILE_MENU_STORE_PREFIX}${store.uid}`);
            }
        }

        return menu
            ?.map((item) => item?.filter((key) => menuItems[key] || key.startsWith(PROFILE_MENU_STORE_PREFIX)))
            .filter((group) => !!group.length);
    }, [
        activeProductType,
        isLogged,
        isMobile,
        menuItems,
        profile?.stores,
        profile.type,
        profileMenuItems,
        profileMenuItemsMobile,
        profileMenuItemsMobileUnlogged,
        profileMenuItemsNotBusiness,
    ]);

    return { menuItems, menuItemsKeys };
};

export const useProfileMenuItems = (isMobile?: boolean, isLogged = true) => {
    const items = useProfileMenuConfigItems(isMobile, isLogged);

    return items;
};

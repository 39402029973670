import { useEffect } from 'react';

import { loadPayoutSettings } from '@/actions/payoutsActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadPayoutSettings = () => {
    const dispatch = useAppDispatch();
    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        if (loggedIn) {
            dispatch(loadPayoutSettings());
        }
    }, [dispatch, loggedIn]);

    return null;
};

import { useEffect } from 'react';

import { loadProductWishList } from '@/actions/productActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const LoadWishList = () => {
    const dispatch = useAppDispatch();

    const loggedIn = useAppSelector(isLoggedIn);

    useEffect(() => {
        dispatch(loadProductWishList());
    }, [dispatch, loggedIn]);

    return null;
};

import React, { useMemo } from 'react';

import { CalendarProps } from './Calendar.types';
import { Month } from './Month';

import './Calendar.less';

export const Calendar = ({
    children: activeMonths,
    firstDayOfWeek,
    closeModalOnDayClick,
}: CalendarProps): JSX.Element => {
    const months = useMemo(() => {
        if (!activeMonths) {
            return [<div key={1} />];
        }
        return activeMonths.map((month) => (
            <Month
                closeModalOnDayClick={closeModalOnDayClick}
                key={`${month.year}-${month.month}`}
                year={month.year}
                month={month.month}
                firstDayOfWeek={firstDayOfWeek}
            />
        ));
    }, [activeMonths, closeModalOnDayClick, firstDayOfWeek]);

    return <div className="datepicker-calendar">{months}</div>;
};

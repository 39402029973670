import { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import { LocalStorageItems, LocalStorageOnbordingMessagesConf } from '@/types/localStorage';

class OnbordingMessagesService {
    getConfFromLocalStorage = () =>
        (JSON.parse(localStorage.getItem(LocalStorageItems.ONBORDING_MESSAGES)) ||
            {}) as LocalStorageOnbordingMessagesConf;

    getMessageFromLocalStorage = (type: OnbordingMessageType) => this.getConfFromLocalStorage()[type];

    setConfToLocalStorage = (conf: LocalStorageOnbordingMessagesConf) =>
        localStorage.setItem(LocalStorageItems.ONBORDING_MESSAGES, JSON.stringify(conf));

    setMessageToLocalStorage = (type: OnbordingMessageType, shown: boolean) => {
        const newLsConf = { ...this.getConfFromLocalStorage(), [type]: shown };
        localStorage.setItem(LocalStorageItems.ONBORDING_MESSAGES, JSON.stringify(newLsConf));
    };

    resetMessages = () => localStorage.removeItem(LocalStorageItems.ONBORDING_MESSAGES);
}

export const onbordingMessagesService = new OnbordingMessagesService();

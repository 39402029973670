import {
    CHANGE_ORDER_STATUS_ERROR,
    CHANGE_ORDER_STATUS_LOADING,
    CHANGE_ORDER_STATUS_SUCCESS,
    CREATE_DEPOSIT_ORDER_ERROR,
    CREATE_DEPOSIT_ORDER_LOADING,
    CREATE_DEPOSIT_ORDER_SUCCESS,
    ORDER_DETAILS_SUCCESS,
    ORDER_RESET,
    ORDERS_LOADING,
    ORDERS_LOADING_ERROR,
    ORDERS_LOADING_SUCCESS,
    ORDERS_PRODUCTS_ORDER_CREATING,
    ORDERS_PRODUCTS_ORDER_CREATING_ERROR,
    ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS,
    ORDERS_PROMO_ORDER_CREATING,
    ORDERS_PROMO_ORDER_CREATING_ERROR,
    ORDERS_PROMO_ORDER_CREATING_SUCCESS,
    ORDERS_RESET_ERRORS,
    OrdersActions,
} from '@/actions/ordersActions';
import { Order, PaymentMethod } from '@/generated/commonapi';
import { ProductOrder } from '@/generated/paymentapi';

export interface IOrdersState {
    orderCreating?: boolean;
    orderCreatingError?: unknown;
    order?: ProductOrder;
    paymentMethods?: PaymentMethod[];
    ordersLoading?: boolean;
    ordersLoadingError?: unknown;
    orders?: ProductOrder[];
    selectedOrder?: ProductOrder;
    orderStatusLoading?: boolean;
    orderStatusLoadingError?: unknown;
    depositOrder?: Order;
    depositOrderLoading?: boolean;
    depositOrderError?: unknown;
}

const initialState: IOrdersState = {
    orderCreating: false,
    orderCreatingError: undefined,
    ordersLoadingError: undefined,
    ordersLoading: false,
    orderStatusLoading: false,
    orderStatusLoadingError: undefined,
    orders: undefined,
    order: undefined,
    paymentMethods: [],
    selectedOrder: undefined,
    depositOrder: undefined,
    depositOrderLoading: false,
    depositOrderError: undefined,
};

const ordersReducer = (state = initialState, action: OrdersActions): IOrdersState => {
    switch (action.type) {
        case ORDERS_PROMO_ORDER_CREATING:
            return {
                ...state,
                orderCreating: true,
                orderCreatingError: null,
            };
        case ORDERS_PROMO_ORDER_CREATING_SUCCESS:
            return {
                ...state,
                orderCreating: false,
                order: action.order,
            };
        case ORDERS_PROMO_ORDER_CREATING_ERROR:
            return {
                ...state,
                orderCreating: false,
                orderCreatingError: action.error,
            };
        case ORDERS_PRODUCTS_ORDER_CREATING:
            return {
                ...state,
                orderCreating: true,
                orderCreatingError: null,
            };
        case ORDERS_PRODUCTS_ORDER_CREATING_SUCCESS:
            return {
                ...state,
                orderCreating: false,
                order: action.order,
                paymentMethods: action.paymentMethods,
            };
        case ORDERS_PRODUCTS_ORDER_CREATING_ERROR:
            return {
                ...state,
                orderCreating: false,
                orderCreatingError: action.error,
            };
        case ORDERS_LOADING:
            return {
                ...state,
                ordersLoading: true,
                ordersLoadingError: null,
            };
        case ORDERS_LOADING_SUCCESS: {
            const { orders } = action;
            return {
                ...state,
                ordersLoading: false,
                orders: [...orders],
            };
        }
        case ORDERS_LOADING_ERROR:
            return {
                ...state,
                ordersLoading: false,
                ordersLoadingError: action.error,
            };
        case ORDER_DETAILS_SUCCESS: {
            const { order } = action;
            return {
                ...state,
                orderStatusLoadingError: undefined,
                selectedOrder: order,
            };
        }
        case CHANGE_ORDER_STATUS_LOADING:
            return {
                ...state,
                orderStatusLoading: true,
                orderStatusLoadingError: null,
            };
        case CHANGE_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                orderStatusLoading: false,
                selectedOrder: action.order,
                orderStatusLoadingError: undefined,
            };
        case CHANGE_ORDER_STATUS_ERROR:
            return {
                ...state,
                orderStatusLoading: false,
                orderStatusLoadingError: action.error,
            };
        case CREATE_DEPOSIT_ORDER_LOADING:
            return {
                ...state,
                depositOrderLoading: true,
                depositOrderError: undefined,
            };
        case CREATE_DEPOSIT_ORDER_SUCCESS:
            return {
                ...state,
                depositOrderLoading: false,
                depositOrderError: undefined,
                depositOrder: action.order,
            };
        case CREATE_DEPOSIT_ORDER_ERROR:
            return {
                ...state,
                depositOrderLoading: false,
                depositOrderError: action.error,
            };
        case ORDER_RESET:
            return initialState;
        case ORDERS_RESET_ERRORS:
            return {
                ...state,
                depositOrderError: undefined,
                orderCreatingError: undefined,
                orderStatusLoadingError: undefined,
                ordersLoadingError: undefined,
            };
        default:
            return state;
    }
};

export default ordersReducer;

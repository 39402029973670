import { CarouselItem } from '@/components/Carousel';
import { Product, Store } from '@/generated/marketplaceapi';

export const storeToCarouselItem = (store: Store): CarouselItem => ({
    ...store,
    rating: store.rating?.reduce((prev, curr) => (prev += curr.rating * curr.ratingPercent), 0),
    status: store.status as unknown as Product.StatusEnum,
    targetUrl: `/store/${store.code ?? store.uid}`,
    image: store.imageUrl,
});

import cn from 'classnames';
import { Button, f7 } from 'framework7-react';
import React, { useCallback } from 'react';

import { MainNavbarProfileLinkProps } from './MainNavbarProfileLink.types';

import { IcContacts } from '@/assets';
import { OnbordingMessage } from '@/components/OnbordingMessage';
import { OnbordingMessageType } from '@/components/OnbordingMessage/OnbordingMessage.types';
import { ProfileLink } from '@/components/ProfileLink';
import { ProfilePopover } from '@/components/ProfilePopover';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { AccountStatus } from '@/types/account';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

import './MainNavbarProfileLink.less';

export const MainNavbarProfileLink = ({
    openAboutPopup,
    openInvitePopup,
    openVerifyAccountPopup,
    emailVerifySuccess,
    onLoginClick,
    onStartSellingClick,
    startProvidingServices,
}: MainNavbarProfileLinkProps) => {
    const activeProductType = useAppSelector(getActiveProductType);
    const isMobile = useAppSelector(getIsMobile);
    const profile = useAppSelector(getProfile);

    const showOnbordingMessage =
        profile && [AccountStatus.BC, AccountStatus.BR].includes(profile.status as AccountStatus);

    const showOnboardingMessageAddProducts =
        showOnbordingMessage && isMobile && f7.view.main.router.currentRoute.path === '/';

    const handleOnbordingMessage = useCallback(
        () => f7.view.main.router.navigate('profile/seller-area/my-goods/add'),
        [],
    );

    const goToProfile = useCallback(
        () =>
            f7.view.main.router.navigate(
                `/profile/?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(activeProductType)}`,
            ),
        [activeProductType],
    );

    if (!profile.uid) {
        return (
            <div className="main-navbar-profile-link">
                {!isMobile ? (
                    <Button className="main-navbar-profile-link__login" round onClick={onLoginClick}>
                        <IcContacts />
                    </Button>
                ) : (
                    <Button className={cn('main-navbar-profile-link__login', 'is-mobile')} round onClick={goToProfile}>
                        <IcContacts />
                    </Button>
                )}
                {emailVerifySuccess && (
                    <OnbordingMessage
                        className="main-navbar-profile-link__email-verify-onbording-message"
                        type={OnbordingMessageType.EMAIL_VERIFIED}
                        triangle="right"
                    />
                )}
            </div>
        );
    }

    return (
        <div className={cn('main-navbar-profile-link', 'logged')}>
            {isMobile ? (
                <ProfileLink profile={profile} />
            ) : (
                <ProfilePopover
                    popoverTriggerElement={(togglePopover) => (
                        <ProfileLink profile={profile} href="#" onClick={togglePopover} />
                    )}
                    onAboutClick={openAboutPopup}
                    onVerifyClick={openVerifyAccountPopup}
                    onInviteClick={openInvitePopup}
                    onStartSellingClick={onStartSellingClick}
                    onStartProvidingServicesClick={startProvidingServices}
                />
            )}

            {showOnboardingMessageAddProducts && (
                <OnbordingMessage
                    className="main-navbar-profile-link__profile-onbording-message"
                    type={OnbordingMessageType.ADD_PRODUCTS}
                    triangle="right"
                    onClick={handleOnbordingMessage}
                />
            )}
        </div>
    );
};

import cn from 'classnames';
import React, { useMemo } from 'react';

import { StepIndicatorProps } from './StepIndicator.types';

import './StepIndicator.less';

export const StepIndicator = React.memo(({ stepsCount, progress }: StepIndicatorProps) => {
    const items = useMemo(
        () => Array.from({ length: stepsCount }, (_, index) => index <= progress),
        [progress, stepsCount],
    );

    return (
        <div className="step-indicator">
            {items.map((checked, index) => (
                <div key={index} className={cn('step-indicator__item', { checked })} />
            ))}
        </div>
    );
});
StepIndicator.displayName = 'StepIndicator';

import { Col, Row } from 'framework7-react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultDraft } from './ConfigureSale.constants';
import { ConfigureSaleDraft, ConfigureSaleProps, ConfigureSaleSteps } from './ConfigureSale.types';
import { draftValuesToSale, getDefaultDraft, saleToConfigureSaleDraft } from './ConfigureSale.utils';
import { SALE_FORM_ROUTE_REGEX } from './ConfigureSaleConstants';
import { ConfigureSaleMobileSteps } from './ConfigureSaleMobileSteps';
import { ConfigureSaleNavbar } from './ConfigureSaleNavbar/ConfigureSaleNavbar';

import { DesktopStepsMenu } from './DesktopStepsMenu';

import { GeneralInfo } from './GeneralInfo';

import { ProductsOnSale } from './ProductsOnSale';

import { EntityClassificatorType, loadClassificator } from '@/actions/classificatorActions';
import { createOrEditSaleGeneralInfo, uploadSaleProducts, validateSaleItems } from '@/actions/saleActions';
import { firebaseAnalyticsInstance } from '@/analytics/firebase';
import { CircleDoneButton } from '@/components/CircleDoneButton';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { LoadAccountStores } from '@/hoc/LoadAccountStores';
import { LoadTranslatedSaleInfo } from '@/hoc/LoadTranslatedSaleInfo';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useScrollToAnchor } from '@/hooks/useScrollToAnchor';
import { getIsMobile } from '@/selectors/getIsMobile';
import { createUUID, valueInRange } from '@/utils';

import './ConfigureSale.less';

const ConfigureSaleInner: React.FC<ConfigureSaleProps> = ({ isEdit, f7router, saleUid, isRestart }) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);
    const dispatch = useAppDispatch();

    useEffect(() => {
        ['Discount_RoundType', 'Discount_Type'].forEach(
            (type: EntityClassificatorType) => void dispatch(loadClassificator(type)),
        );
    }, [dispatch]);

    useEffect(() => {
        if (!isEdit && !isRestart) {
            firebaseAnalyticsInstance.openCreateSalePage?.();
        }
    }, [isEdit, isRestart]);

    const { Discount_RoundType, Discount_Type } = useAppSelector(
        (state) => state.classificatorReducer.entitiesClassificators,
    );
    const { accountStores } = useAppSelector((state) => state.accountStoresReducer);
    const { saleInfo, saleInfoLoading } = useAppSelector((state) => state.saleReducer);

    const [isProcessing, setIsProcessing] = useState(false);

    const [step, setStep] = useState(ConfigureSaleSteps.GENERAL_INFO);
    const [validSteps, setValidSteps] = useState<Map<ConfigureSaleSteps, boolean>>(
        new Map(
            Object.values(ConfigureSaleSteps)
                .filter((key) => typeof key === 'number')
                .map((key: ConfigureSaleSteps) => [key, isEdit]),
        ),
    );
    const [draft, setDraft] = useState<ConfigureSaleDraft>(defaultDraft);
    const stepChangeDisabled = useMemo(
        () =>
            isEdit
                ? !Array.from(validSteps.entries()).reduce<boolean>((prev, [, valid]) => prev && valid, true) &&
                  !draft?.[1].uid
                : !validSteps.get(step),
        [draft, isEdit, step, validSteps],
    );

    const makeHandleValidity = useCallback(
        (step: ConfigureSaleSteps) => (validity: boolean) => setValidSteps((prev) => new Map(prev.set(step, validity))),
        [],
    );

    const handleScrollToAnchor = useScrollToAnchor();
    const firstStepId = useMemo(() => createUUID(), []);
    const secondStepUUID = useMemo(() => createUUID(), []);

    const handleEditSubmit = useCallback(() => {
        const newSaleInfo = draftValuesToSale(draft, accountStores?.[0]?.uid);
        // TODO: ask correct format at backend, now it doesn't work
        dispatch(createOrEditSaleGeneralInfo(newSaleInfo))
            .then(() => dispatch(uploadSaleProducts(newSaleInfo)))
            .catch(console.error);
        f7router.navigate('/profile/seller-area/sale/', { reloadCurrent: true, force: true });
    }, [accountStores, dispatch, draft, f7router]);

    const handleNextStep = useCallback(() => {
        switch (true) {
            case isEdit && step === ConfigureSaleSteps.GENERAL_INFO:
                handleScrollToAnchor(secondStepUUID);
                setStep((prev) =>
                    valueInRange(ConfigureSaleSteps.GENERAL_INFO, ConfigureSaleSteps.PRODUCTS_SELECT, prev + 1),
                );
                break;

            case step === ConfigureSaleSteps.GENERAL_INFO:
                {
                    setIsProcessing(true);
                    const products = [...(draft[ConfigureSaleSteps.PRODUCTS_SELECT].products ?? [])];

                    dispatch(createOrEditSaleGeneralInfo(draftValuesToSale(draft, accountStores?.[0]?.uid))).then(
                        ({ saleReducer: { saleInfo } }) => {
                            if (products?.length && saleInfo?.uid) {
                                dispatch(validateSaleItems(products, saleInfo?.uid, true));
                            }
                        },
                    );
                }
                break;
            case step === ConfigureSaleSteps.PRODUCTS_SELECT:
                {
                    const sale = draftValuesToSale(draft, accountStores?.[0]?.uid);
                    dispatch(uploadSaleProducts(sale)).then(() => firebaseAnalyticsInstance.saleCreated?.(sale.uid));
                    f7router.navigate('/profile/seller-area/sale/', {
                        reloadCurrent: true,
                        force: true,
                    });
                }

                break;
            default:
                break;
        }
    }, [accountStores, dispatch, draft, f7router, handleScrollToAnchor, isEdit, secondStepUUID, step]);

    const updateDraft = useMemo(
        () =>
            debounce((step: ConfigureSaleSteps, values: ConfigureSaleDraft[ConfigureSaleSteps]) => {
                setDraft((prev) => (!isEqual(prev[step], values) ? { ...prev, [step]: values } : prev));
            }, 100),
        [],
    );

    const handlePrevStep = useCallback(() => {
        setStep((prev) => valueInRange(ConfigureSaleSteps.GENERAL_INFO, ConfigureSaleSteps.PRODUCTS_SELECT, prev - 1));
        if (isEdit) {
            step === ConfigureSaleSteps.PRODUCTS_SELECT
                ? handleScrollToAnchor(firstStepId)
                : handleScrollToAnchor(secondStepUUID);
        }
    }, [firstStepId, handleScrollToAnchor, isEdit, secondStepUUID, step]);

    const handleSidebarStepClick = useCallback(
        (item: ConfigureSaleSteps) => {
            if (item < step) {
                handlePrevStep();
            } else if (item > step) {
                handleNextStep();
            }
        },
        [handleNextStep, handlePrevStep, step],
    );

    const createHandleDraftChange = useMemo(
        () => (step: ConfigureSaleSteps) => (values: ConfigureSaleDraft[keyof ConfigureSaleDraft]) =>
            updateDraft(step, values),
        [updateDraft],
    );

    const shownStep = useMemo(() => {
        switch (true) {
            case isEdit:
                return (
                    <>
                        <GeneralInfo
                            isEdit
                            // containerID={firstStepId}
                            draftInitial={draft[ConfigureSaleSteps.GENERAL_INFO]}
                            handleDraftChange={createHandleDraftChange(ConfigureSaleSteps.GENERAL_INFO)}
                            handleValidity={makeHandleValidity(ConfigureSaleSteps.GENERAL_INFO)}
                        />
                        <ProductsOnSale
                            isEdit
                            containerID={secondStepUUID}
                            draftInitial={draft[ConfigureSaleSteps.PRODUCTS_SELECT]}
                            handleDraftChange={createHandleDraftChange(ConfigureSaleSteps.PRODUCTS_SELECT)}
                            handleValidity={makeHandleValidity(ConfigureSaleSteps.PRODUCTS_SELECT)}
                            priceFrom={draft[ConfigureSaleSteps.GENERAL_INFO].validDiscountAmountRangeFrom}
                            priceTo={draft[ConfigureSaleSteps.GENERAL_INFO].validDiscountAmountRangeTo}
                        />
                    </>
                );

            case step === ConfigureSaleSteps.GENERAL_INFO:
                return (
                    <GeneralInfo
                        draftInitial={draft[ConfigureSaleSteps.GENERAL_INFO]}
                        handleDraftChange={createHandleDraftChange(ConfigureSaleSteps.GENERAL_INFO)}
                        handleValidity={makeHandleValidity(ConfigureSaleSteps.GENERAL_INFO)}
                    />
                );
            case step === ConfigureSaleSteps.PRODUCTS_SELECT:
                return (
                    <ProductsOnSale
                        draftInitial={draft[ConfigureSaleSteps.PRODUCTS_SELECT]}
                        handleDraftChange={createHandleDraftChange(ConfigureSaleSteps.PRODUCTS_SELECT)}
                        handleValidity={makeHandleValidity(ConfigureSaleSteps.PRODUCTS_SELECT)}
                        priceFrom={draft[ConfigureSaleSteps.GENERAL_INFO].validDiscountAmountRangeFrom}
                        priceTo={draft[ConfigureSaleSteps.GENERAL_INFO].validDiscountAmountRangeTo}
                    />
                );
            default:
                return null;
        }
    }, [createHandleDraftChange, draft, isEdit, makeHandleValidity, secondStepUUID, step]);

    const nextButtonText = useMemo(
        () => (isEdit ? 'Save' : step < ConfigureSaleSteps.PRODUCTS_SELECT ? 'Next' : 'Set Up'),
        [isEdit, step],
    );

    useEffect(() => {
        if (Discount_Type && Discount_RoundType && saleInfo) {
            setDraft(
                saleToConfigureSaleDraft(
                    saleInfo,
                    Discount_Type,
                    Discount_RoundType,
                    saleInfo?.uid === saleUid && isRestart,
                ),
            );
        } else if (Discount_RoundType && Discount_Type) {
            setDraft(getDefaultDraft(Discount_Type, Discount_RoundType));
        }
    }, [Discount_RoundType, Discount_Type, isRestart, saleInfo, saleUid]);

    useEffect(() => {
        if (validSteps.get(step) && !saleInfoLoading && isProcessing) {
            setIsProcessing(false);
            setStep((prev) => prev + 1);
        }
    }, [isProcessing, saleInfoLoading, step, validSteps]);

    return (
        <CustomPage loggedOnly className="configure-sale">
            <ConfigureSaleNavbar
                slot="fixed"
                onNextStep={isEdit ? handleEditSubmit : handleNextStep}
                stepChangeDisabled={stepChangeDisabled}
                nextButtonText={nextButtonText}
                isMobile={isMobile}
                f7router={f7router}
                isEdit={isEdit}
                showArrow={step < ConfigureSaleSteps.PRODUCTS_SELECT && !isEdit}
                saleUid={draft[ConfigureSaleSteps.PRODUCTS_SELECT]?.uid}
            />
            <LoadAccountStores />
            <LoadTranslatedSaleInfo
                disabled={
                    !SALE_FORM_ROUTE_REGEX?.test(f7router?.currentRoute?.url) &&
                    f7router.currentRoute?.params?.saleUid === saleUid
                }
                saleUid={saleUid}
            />
            <Row id={firstStepId} className="configure-sale__row">
                {!isMobile && (
                    <DesktopStepsMenu
                        showStepHighlight
                        isEdit={isEdit}
                        currentStep={step}
                        onStepClick={handleSidebarStepClick}
                        validSteps={validSteps}
                    />
                )}
                <Col className="configure-sale__row-content">
                    {shownStep}
                    {isMobile && !isEdit && (
                        <ConfigureSaleMobileSteps
                            step={step}
                            onPrevStep={handlePrevStep}
                            onNextStep={handleNextStep}
                            stepChangeDisabled={stepChangeDisabled}
                            nextButtonText={t(nextButtonText)}
                        />
                    )}
                    {isMobile && isEdit && (
                        <CircleDoneButton
                            className="configure-sale__confirm-mobile"
                            onClick={handleNextStep}
                            disabled={stepChangeDisabled}
                        />
                    )}
                </Col>
            </Row>
        </CustomPage>
    );
};

export const ConfigureSale = ConnectPageWrapper(ConfigureSaleInner);

import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

export type ModalConfig = {
    isAlredyOpened?: boolean;
};

class ModalService {
    getConfig(modal: Popups | Sheets): ModalConfig | null {
        return JSON.parse(localStorage.getItem(modal));
    }

    setIsAlredyOpened(modal: Popups | Sheets, flag: boolean): void {
        localStorage.setItem(modal, JSON.stringify({ isAlredyOpened: flag }));
    }
}

export const modalService = new ModalService();

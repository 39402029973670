import {
    AccordionContent,
    AccordionItem,
    Button,
    Col,
    Icon,
    Link,
    ListItem,
    Navbar,
    NavRight,
    NavTitle,
    Row,
    Treeview,
} from 'framework7-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoriesAccordionProps } from './CategoriesAccordion.types';

import { SubCategoryItem } from './SubCategoryItem';

import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';

import './CategoriesAccordion.less';

export const CategoriesAccordion = React.memo<CategoriesAccordionProps>(
    ({ opened, setOpened, onBlur, onChange, onFocus, selectedValue }) => {
        const { t } = useTranslation();

        const [open, close] = useBooleanState(setOpened);

        const { categories } = useAppSelector((state) => state.categoryReducer);

        return (
            <ListItem
                className="categories-accordion"
                accordionItemOpened={opened}
                accordionItem
                onAccordionClosed={close}
                onAccordionOpened={open}
            >
                <AccordionContent>
                    <AccordionItem opened>
                        <Navbar noHairline noShadow>
                            <NavTitle>{t('Select Category')}</NavTitle>
                            <NavRight>
                                <Link iconOnly onClick={close}>
                                    <Icon ios="f7:multiply" md="material:close" />
                                </Link>
                            </NavRight>
                        </Navbar>
                        <Treeview>
                            {categories.map((category) => (
                                <SubCategoryItem
                                    key={category.categoryCode}
                                    category={category}
                                    selectedValue={selectedValue}
                                    closeAccordion={close}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    onFocus={onFocus}
                                />
                            ))}
                        </Treeview>
                        <Row className="category-footer">
                            <Col width="80" />
                            <Col width="20">
                                <Button className="done-button" fill round onClick={close}>
                                    {t('Done')}
                                </Button>
                            </Col>
                        </Row>
                    </AccordionItem>
                </AccordionContent>
            </ListItem>
        );
    },
);
CategoriesAccordion.displayName = 'CategoriesAccordion';

export function isInsideGem() {
    // return process.env.INSIDE_MESSENGER === 'true';

    if (window.Android?.isGemApp) {
        return true;
    }
    if (window.webkit?.messageHandlers?.isGemApp) {
        return true;
    }
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

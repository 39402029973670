import React from 'react';
import { useTranslation } from 'react-i18next';

import { StarRating } from '@/components/StarRating';
import { useAppSelector } from '@/hooks/store';
import { getProductRating } from '@/utils';

import './ProductStatistics.less';

export const ProductStatistics: React.FC = () => {
    const { t } = useTranslation();

    const { reviews, productRating } = useAppSelector((state) => state.reviewReducer);

    return (
        <div className="product-statistics">
            <StarRating readOnly value={getProductRating(productRating)} size="small" primary />
            &nbsp;
            <span className="product-statistics__relative-rating">{getProductRating(productRating).toFixed(2)}</span>
            &nbsp;/ {reviews.length} {t('Reviews')}
        </div>
    );
};

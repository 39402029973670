import { client, commonapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { AccountWsControllerApi, CommonApiResponse } from '@/generated/commonapi';
import { TokenResponse } from '@/reducers/sessionReducer';
import { LocalStorageItems } from '@/types/localStorage';

class SessionService {
    /**
     * Creates one-time code for linked apps auth
     * @param accessToken authentification token for current user
     * @returns one time auth code
     */
    getOneTimeAuthCodeByAccessToken = async (accessToken: string): Promise<string> => {
        const response = await client.post<CommonApiResponse & { code: string }>(
            commonapiURL + `/auth/mobile/authorization_code`,
            {
                accessToken,
            },
        );
        handleResponseAndThrowAnErrorIfExists(response.data);

        return response.data.code;
    };

    /**
     * Use this function only wuth two factor authentification enabled in project
     * @param accessToken token that is not workint yet
     * @param code code from Google Authentificator or another two-factor auth app
     * @returns auth date
     */
    getTwoFactorAuthorizationDataByAccessToken = async (accessToken: string, code: string) => {
        const response = await client.post<TokenResponse>(commonapiURL + `/auth/mobile/token/activate`, {
            accessToken,
            code,
        });
        handleResponseAndThrowAnErrorIfExists(response.data);

        return response.data;
    };

    /**
     * Refresh authorization tokens
     * @param refreshToken refreshToken
     * @returns auth data
     */
    refreshAuthorizationToken = async (refreshToken: string) => {
        const response = await client.post<TokenResponse>(commonapiURL + '/auth/mobile/token/refresh', {
            refreshToken,
        });
        handleResponseAndThrowAnErrorIfExists(response.data);

        return response.data;
    };

    /**
     * Log in by standart credentials
     * @param username username
     * @param password password
     * @returns auth data
     */
    getAuthorizationDataByUsernameAndPassword = async (username: string, password: string) => {
        const response = await client.post<TokenResponse>(commonapiURL + `/auth/mobile/token`, {
            password,
            username,
        });
        handleResponseAndThrowAnErrorIfExists(response.data);

        return response.data;
    };

    /**
     * Parse url query string and pass authCode to this function to log in
     * @param code one time auth code from query param
     * @returns ayth data
     */
    getAuthorizationDataByOneTimeCode = async (code: string) => {
        const response = await client.post<TokenResponse>(commonapiURL + `/auth/mobile/token`, {
            code,
        });
        handleResponseAndThrowAnErrorIfExists(response.data);

        return response.data;
    };

    fetchProfile = async (token?: string) =>
        (await new AccountWsControllerApi().getFullAccountUsingPOST(token)).account;

    getRefreshTokenFromLocalStorage = () => localStorage.getItem(LocalStorageItems.REFRESH_TOKEN);

    saveRefreshTokenToLocalStorage = (refreshToken: string) =>
        localStorage.setItem(LocalStorageItems.REFRESH_TOKEN, refreshToken);

    removeRefreshTokenFromLocalStorage = () => localStorage.removeItem(LocalStorageItems.REFRESH_TOKEN);
}

export const sessionService = new SessionService();

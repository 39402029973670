import React from 'react';
import { Field } from 'react-final-form';

import { RadioFieldProps } from './RadioField.types';

import { CustomRadio } from '@/components/CustomRadio';

export const RadioField = <T extends string | number>(props: RadioFieldProps<T>) => (
    <Field<T> {...props} type="radio">
        {({ input }) => <CustomRadio {...props} {...input} />}
    </Field>
);

import React from 'react';
import { Field } from 'react-final-form';

import { SelectSheetFieldProps } from './SelectSheetField.types';

import { SelectSheet } from '@/components/SelectSheet';
import { SelectSheetProps } from '@/components/SelectSheet/SelectSheet.types';

export const SelectSheetField = (props: SelectSheetFieldProps) => (
    <Field {...props} type={undefined}>
        {({ input: { value, onChange } }) => <SelectSheet {...({ ...props, value, onChange } as SelectSheetProps)} />}
    </Field>
);

import { Block, BlockTitle, List, ListItem } from 'framework7-react';
import React, { useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CategoryMappingProps, FormSpyRendererProps } from './CategoryMapping.types';

import { validateCategoryMapping } from './CategoryMapping.utils';
import { MappingItem } from './MappingItem';

import { destringifyKeys, noop, stringifyKeys } from '@/utils';

import './CategoryMapping.less';

const FormSpyRenderer = ({ valid, handleValidity, handleDraftChange, values }: FormSpyRendererProps) => {
    useEffect(() => {
        handleValidity(valid);
        if (valid) {
            handleDraftChange(destringifyKeys(values));
        }
    }, [handleDraftChange, handleValidity, valid, values]);

    return null;
};

export const CategoryMapping: React.FC<CategoryMappingProps> = ({ handleValidity, draftValues, handleDraftChange }) => {
    const { t } = useTranslation();
    return (
        <Block className="category-mapping">
            <BlockTitle className="step-title">{t('Category Mapping')}</BlockTitle>
            <p className="step-description">
                {t(
                    'The categories provided into file have been matched automatically by the system. Please change this mapping if required.',
                )}
            </p>
            <Form onSubmit={noop} initialValues={stringifyKeys(draftValues)} validate={validateCategoryMapping}>
                {() => (
                    <List>
                        {draftValues &&
                            Object.entries(draftValues).map(([key, value]) => (
                                <ListItem key={key}>
                                    <MappingItem item={value} />
                                </ListItem>
                            ))}
                        <FormSpy
                            subscription={{ values: true, valid: true }}
                            render={(props) => (
                                <FormSpyRenderer
                                    {...props}
                                    handleDraftChange={handleDraftChange}
                                    handleValidity={handleValidity}
                                />
                            )}
                        />
                    </List>
                )}
            </Form>
        </Block>
    );
};

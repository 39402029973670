import { client, marketplaceapiURL } from '@/axios';
import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { Ticket, TicketControllerApi } from '@/generated/marketplaceapi';
import { SupportMessage } from '@/reducers/contactSupportReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getCompressedImage, getPlatform } from '@/utils';
import { Platform } from '@/utils/getPlatform';

const pjson = require('../../package.json');

export const MESSAGE_SENDING = 'MESSAGE_SENDING' as const;
export const MESSAGE_SENDING_SUCCESS = 'MESSAGE_SENDING_SUCCESS' as const;
export const MESSAGE_SENDING_ERROR = 'MESSAGE_SENDING_ERROR' as const;

export const MESSAGE_FILE_ATTACHING = 'MESSAGE_FILE_ATTACHING' as const;
export const MESSAGE_FILE_ATTACHING_SUCCESS = 'MESSAGE_FILE_ATTACHING_SUCCESS' as const;
export const MESSAGE_FILE_ATTACHING_ERROR = 'MESSAGE_FILE_ATTACHING_ERROR' as const;

export const MESSAGE_FILE_DETACHING_SUCCESS = 'MESSAGE_FILE_DETACHING_SUCCESS' as const;

export const MESSAGE_FILES_CLEAR = 'MESSAGE_FILES_CLEAR' as const;

const sendMessageAction = () => ({
    type: MESSAGE_SENDING,
});

const sendMessageSuccessAction = () => ({
    type: MESSAGE_SENDING_SUCCESS,
});

const sendMessageErrorAction = (error: unknown) => ({
    type: MESSAGE_SENDING_ERROR,
    error,
});

const messageFileAttachingAction = () => ({
    type: MESSAGE_FILE_ATTACHING,
});

const messageFileAttachingSuccessAction = (imageLink: string, file: File) => ({
    type: MESSAGE_FILE_ATTACHING_SUCCESS,
    imageLink,
    file,
});

const messageFileAttachingErrorAction = (error: unknown) => ({
    type: MESSAGE_FILE_ATTACHING_ERROR,
    error,
});

const messageFileDetachingSuccessAction = (index: number) => ({
    type: MESSAGE_FILE_DETACHING_SUCCESS,
    index,
});

export const deleteFiles = () => ({ type: MESSAGE_FILES_CLEAR });

export type ContactSupportActions = ReturnType<
    | typeof sendMessageAction
    | typeof sendMessageSuccessAction
    | typeof sendMessageErrorAction
    | typeof messageFileAttachingAction
    | typeof messageFileAttachingSuccessAction
    | typeof messageFileAttachingErrorAction
    | typeof messageFileDetachingSuccessAction
    | typeof deleteFiles
>;

const uploadFile = async (accountUid: string, bucket: string, file: File) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };
    const url = `${marketplaceapiURL}/image/${accountUid}/upload`;
    const formData = new FormData();
    formData.append('bucket', bucket);
    formData.append('file', file);
    const response = await client.post(url, formData, config);
    if (!response.data) {
        throw new Error('Could not upload image');
    }
    return response.data.body[0].url;
};

export const sendMessage = (message: SupportMessage) => async (dispatch, getState: () => IApplicationStore) => {
    dispatch(sendMessageAction());

    const state = getState();
    const profile = state.sessionReducer.profile;

    try {
        const ticket: Ticket = {
            accountUid: profile?.uid,
            firstName: profile?.person?.name,
            lastName: profile?.person?.surname,
            languageCode: getLanguageCode(state),
        };

        const phone = profile?.accountPhones?.find?.((p) => p.primary);
        if (phone) {
            ticket.phone = phone.countryCode + phone.number;
        }

        ticket.email = message.email;
        ticket.subject = message.subject;
        ticket.body = message.message;
        ticket.customParameters = message.parameters || {};

        ticket.customParameters.version = pjson.version;

        const platform = getPlatform();
        switch (platform) {
            case Platform.Android:
                ticket.customParameters.platform = 'Android';
                break;
            case Platform.iOS:
                ticket.customParameters.platform = 'iOS';
                break;
            default:
                ticket.customParameters.platform = 'WEB';
        }

        const attachments = [];
        const files = state.contactSupportReducer.files;
        if (files.length > 0) {
            const bucket = 'omnidesk';
            for (const fileInfo of files) {
                const url = await uploadFile(profile?.uid, bucket, fileInfo.file);
                attachments.push(url);
            }
            ticket.attachments = attachments;
        }

        const response = await new TicketControllerApi().createTicketUsingPUT(ticket);

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(sendMessageSuccessAction());
    } catch (err) {
        console.error('at contactSupportActions in sendMessage', err);

        let errorText = err.message;
        if (err.response && err.response.data && err.response.data.error) {
            errorText = err.response.data.error;
        }
        if (!errorText) {
            errorText = err.response.data?.errorData?.message;
        }
        dispatch(sendMessageErrorAction(errorText));
    }
    return getState;
};

export const attachFile = (file: File) => async (dispatch) => {
    dispatch(messageFileAttachingAction());
    try {
        file = await getCompressedImage(file);
        let imageDataAs: string;
        const reader = new FileReader();
        reader.onload = (ev: ProgressEvent<FileReader>) => {
            imageDataAs = ev.target.result.toString();
            dispatch(messageFileAttachingSuccessAction(imageDataAs, file));
        };
        reader.readAsDataURL(file);
    } catch (error) {
        dispatch(messageFileAttachingErrorAction(error.toString()));
    }
};

export const detachFile = (index: number) => (dispatch) => {
    dispatch(messageFileDetachingSuccessAction(index));
};

import { IProduct } from './productReducer';

import { PRODUCT_ADDED_TO_WISH, ProductAddedToWishAction } from '@/actions/productActions';
import {
    RESET_STORE_DATA,
    STORE_CATEGORIES_LOADING,
    STORE_CATEGORIES_LOADING_SUCCESS,
    STORE_INFO_LOADING,
    STORE_INFO_LOADING_SUCCESS,
    STORE_PRODUCTS_LOADING,
    STORE_PRODUCTS_LOADING_SUCCESS,
    STORE_WIDGET_PRODUCTS_LOADING,
    STORE_WIDGET_PRODUCTS_LOADING_SUCCESS,
    StoreActions,
    TOP_STORES_LOADING,
    TOP_STORES_LOADING_SUCCESS,
    STORE_CREATED_POPUP_SHOWING,
} from '@/actions/storeActions';
import { CarouselItem } from '@/components/Carousel';
import { ProductCategory, ProductSearchListRequest, Store } from '@/generated/marketplaceapi';

export type StoreWidgetProducts = { categoryId: string; products: CarouselItem[] };

export type StoreProductsSearchParams = Omit<ProductSearchListRequest, 'storeUid' | 'count' | 'offset'>;

export interface IStoreState {
    storeInfo: Store;
    storeInfoLoading: boolean;
    storeProducts?: IProduct[];
    searchCount?: number;
    storeProductsLoading: boolean;
    searchParams?: StoreProductsSearchParams;
    searchEndReached: boolean;
    storeCategories?: ProductCategory[];
    storeCategoriesLoading: boolean;
    storeWidgetsProducts?: StoreWidgetProducts[];
    storeWidgetsProductsLoading: boolean;
    topStores?: CarouselItem[];
    topStoresLoading?: boolean;
    isShowStoreCreatedPopup?: boolean;
}

const initialState: IStoreState = {
    storeInfo: {},
    storeInfoLoading: false,
    storeProductsLoading: false,
    searchEndReached: false,
    storeCategoriesLoading: false,
    storeWidgetsProductsLoading: false,
    isShowStoreCreatedPopup: false,
};

const storeReducer = (state = initialState, action: StoreActions | ProductAddedToWishAction): IStoreState => {
    switch (action.type) {
        case STORE_INFO_LOADING_SUCCESS: {
            return {
                ...state,
                storeInfo: action.storeInfo,
            };
        }
        case STORE_INFO_LOADING: {
            return {
                ...state,
                storeInfoLoading: action.loading,
            };
        }
        case RESET_STORE_DATA: {
            return { ...initialState };
        }
        case STORE_PRODUCTS_LOADING: {
            return { ...state, storeProductsLoading: action.loading };
        }
        case STORE_PRODUCTS_LOADING_SUCCESS: {
            const storeProducts = action.updateExisting
                ? action.products?.length
                    ? [...(state.storeProducts ?? []), ...action.products]
                    : state.storeProducts
                : action.products;
            return {
                ...state,
                searchCount: action.searchCount || storeProducts.length,
                searchParams: action.searchParams,
                searchEndReached: !action.products?.length,
                storeProducts: storeProducts,
            };
        }

        case STORE_CATEGORIES_LOADING: {
            return { ...state, storeCategoriesLoading: action.loading };
        }
        case STORE_CATEGORIES_LOADING_SUCCESS: {
            return { ...state, storeCategories: action.storeCategories };
        }

        case STORE_WIDGET_PRODUCTS_LOADING: {
            return { ...state, storeWidgetsProductsLoading: action.loading };
        }
        case STORE_WIDGET_PRODUCTS_LOADING_SUCCESS: {
            return { ...state, storeWidgetsProducts: action.widgetsProducts };
        }
        case PRODUCT_ADDED_TO_WISH: {
            const { uid } = action;
            const { storeProducts, storeWidgetsProducts } = state;
            return {
                ...state,
                storeProducts: storeProducts?.map((item) => (item.uid === uid ? { ...item, wish: !item.wish } : item)),
                storeWidgetsProducts: storeWidgetsProducts?.map((widget) => ({
                    categoryId: widget.categoryId,
                    products: widget.products?.map((item) => (item.uid === uid ? { ...item, wish: !item.wish } : item)),
                })),
            };
        }
        case TOP_STORES_LOADING:
            return { ...state, topStoresLoading: action.topStoresLoading };
        case TOP_STORES_LOADING_SUCCESS:
            return { ...state, topStores: action.topStores };
        case STORE_CREATED_POPUP_SHOWING:
            return { ...state, isShowStoreCreatedPopup: action.isShow };
        default: {
            return state;
        }
    }
};

export default storeReducer;

import cn from 'classnames';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { BlockTitle, Button, f7ready, Row } from 'framework7-react';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ImportLogProps } from './ImportLog.types';

import { IcLoad } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { ImportContentType } from '@/pages/ImportProducts/ImportProducts.types';

import './ImportLog.less';

export const ImportLog = ({
    isProcessing,
    logFields: { stats, totalProducts, sourceLabel, active, contentType, fileType, processed, date: logDate },
    onToggleActiveClick,
    withoutTitle,
    withoutButton,
}: ImportLogProps): ReactElement => {
    const { t } = useTranslation();

    const { productSourceLoading, productSourceLog, productSourceLogLoading, productSourceSaveLoading } =
        useAppSelector((state) => state.productSourceReducer);

    const getIndicatorStyle = useCallback(
        (stat: keyof typeof stats) => ({
            width: stats && totalProducts ? `${(stats[stat] * 100) / totalProducts}%` : 0,
        }),
        [stats, totalProducts],
    );

    const handleClickDownloadLog = useCallback(() => {
        const blob = new Blob([productSourceLog], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, `import_log-${dayjs(logDate).format('MM-DD-YY')}.txt`);
    }, [logDate, productSourceLog]);

    useEffect(() => {
        if (isProcessing || productSourceSaveLoading) {
            f7ready((f7) => {
                const toast = f7.toast.create({
                    text: t(
                        "We're currently exporting your products into your store. This should take less than five minutes.",
                    ),
                    position: 'bottom',
                    closeTimeout: 3000,
                });
                toast.open();
            });
        }
    }, [isProcessing, productSourceSaveLoading, t]);

    const inactive = useMemo(
        () => !active && !productSourceLoading && contentType !== ImportContentType.file,
        [active, contentType, productSourceLoading],
    );
    const date = useMemo(() => dayjs(logDate).format('LLL'), [logDate]);
    return (
        <Row className={cn('import-log', { inactive })}>
            {!withoutTitle && <BlockTitle>{t('Import Log')}</BlockTitle>}
            <div>
                <div className="log-item">
                    <div className="log-item-label">{t('Import date')}</div>
                    <div className="log-item-data">{date}</div>
                </div>
                <div className="log-item">
                    <div className="log-item-label">{t('File Type')}</div>
                    <div className="log-item-data">{fileType}</div>
                </div>
                <div className="log-item">
                    <div className="log-item-label">{t('Source')}</div>
                    <div className="log-item-data">{sourceLabel}</div>
                </div>
                <div className="log-item">
                    <div className="log-item-label">{t('Statistics')}</div>
                    {!(isProcessing || productSourceSaveLoading) ? (
                        <div className="stats">
                            <div className="stats-lines">
                                {Object.keys(stats ?? {}).map(
                                    (key, index) =>
                                        !!stats[key] && (
                                            <div
                                                key={index}
                                                className={key}
                                                style={getIndicatorStyle(key as keyof typeof stats)}
                                            />
                                        ),
                                )}
                            </div>
                            <div className="stats-legend">
                                {Object.keys(stats ?? {}).map((key, index) => (
                                    <div key={index}>
                                        <div className={`stats-legend-dot ${key}`} />
                                        {t(key)}&nbsp;{stats[key]}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="log-item-data">{t('Importing')}...</div>
                    )}
                </div>
            </div>
            {!productSourceSaveLoading && (
                <Button
                    className="download-log-button"
                    onClick={handleClickDownloadLog}
                    disabled={productSourceLogLoading}
                >
                    <IcLoad />
                    {t('Download Log File')}
                </Button>
            )}

            {!withoutButton && contentType !== ImportContentType.file && processed && (
                <Button className="pause-import-button" fill round large onClick={onToggleActiveClick}>
                    {!active ? t('Restart Import') : t('Pause Import')}
                </Button>
            )}
        </Row>
    );
};

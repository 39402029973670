import { f7 } from 'framework7-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Product } from '@/generated/marketplaceapi';
import { IApplicationStore } from '@/reducers/rootReducer';

export const useAlertProductStatusChange = () => {
    const { t } = useTranslation();

    return useCallback(
        (store: IApplicationStore) => {
            const { action, error, item } = store.productStatusReducer;

            if (!item || error) {
                return;
            }

            let message = '';

            switch (action) {
                case 'changeQuantity':
                    message = t(`Quantity changed to`, {
                        quantity: item.quantity,
                    });
                    break;
                case 'changeStatus':
                    if (item.status === Product.StatusEnum.AFR) {
                        message = t(`product_has_been_sent_for_review`);
                        break;
                    }

                    message = t(`Status changed`);
                    break;
            }

            if (message) {
                f7.dialog.alert(message);
            }

            if (item.status === Product.StatusEnum.DLT) {
                f7.view.main.router.back();
            }
        },
        [t],
    );
};

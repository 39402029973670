import { Col, f7 } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { PresearchResultItemProps, SearchResultType } from './PresearchResultItem.types';

import { getResultIcon, getResultType } from './utils';

import { productPresearchClearAction, searchClear } from '@/actions/productActions';
import { useAppSelector } from '@/hooks/store';
import { getCategoriesClassificator } from '@/selectors/getCategoriesClassificator';
import { getCategory } from '@/utils';

import './PresearchResultItem.less';

export const PresearchResultItem = ({
    response: { categoryCode, categoryName, result, resultType, code },
    storeData,
}: PresearchResultItemProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { categoriesClassificator } = useAppSelector(getCategoriesClassificator);

    const type = useMemo(() => getResultType(resultType), [resultType]);
    const Icon = useMemo(() => getResultIcon(type), [type]);
    const parsedCategoryName = useMemo(
        () => categoryName ?? getCategory(categoriesClassificator, categoryCode)?.categoryName ?? '',
        [categoriesClassificator, categoryCode, categoryName],
    );

    const text = useMemo(() => {
        switch (type) {
            case SearchResultType.CATEGORY: {
                return (
                    <Col>
                        <span className="text">{parsedCategoryName}</span>
                    </Col>
                );
            }
            case SearchResultType.HASHTAG: {
                return <span className="text">{result}</span>;
            }
            case SearchResultType.PRODUCT_NAME: {
                return (
                    <Col>
                        {parsedCategoryName && (
                            <span className="description">{t('in') + ' ' + parsedCategoryName}</span>
                        )}
                        <span className="text">{result}</span>
                    </Col>
                );
            }
            case SearchResultType.STORE: {
                return <span className="text">{result}</span>;
            }
            default: {
                return <></>;
            }
        }
    }, [parsedCategoryName, result, t, type]);

    const handleClick = useCallback(() => {
        dispatch(productPresearchClearAction());
        dispatch(searchClear());
        switch (type) {
            case SearchResultType.CATEGORY: {
                f7.view.main.router.navigate(
                    storeData
                        ? `/store/${storeData?.code}/?category=${categoryCode}`
                        : `/search/?category=${categoryCode}`,
                );
                break;
            }
            case SearchResultType.HASHTAG: {
                f7.view.main.router.navigate(
                    storeData
                        ? `/store/${storeData?.code}/?hashtag=${result.replace('#', '')}`
                        : `/search/?hashtag=${result.replace('#', '')}`,
                );
                break;
            }
            case SearchResultType.PRODUCT_NAME: {
                f7.view.main.router.navigate(
                    storeData
                        ? `/store/${storeData?.code}/?${categoryCode ? `category=${categoryCode}&` : ''}&name=${result}`
                        : `/search/?${categoryCode ? `category=${categoryCode}&` : ''}name=${result}`,
                    { reloadCurrent: true },
                );
                break;
            }
            case SearchResultType.STORE: {
                f7.view.main.router.navigate(`/store/${code}`);
                break;
            }
            default: {
                return;
            }
        }
    }, [categoryCode, code, dispatch, result, storeData, type]);

    return (
        <div className="block-presearch-result__item" onClick={handleClick}>
            <Icon className="item-icon" />
            {text}
        </div>
    );
};

import {
    SELLERS_ORDERS_LOADING,
    SELLERS_ORDERS_LOADING_ERROR,
    SELLERS_ORDERS_LOADING_SUCCESS,
    SELLERS_ORDERS_ORDER_DETAILS_ERROR,
    SELLERS_ORDERS_ORDER_DETAILS_LOADING,
    SELLERS_ORDERS_ORDER_DETAILS_SUCCESS,
    SellersOrdersActions,
    SET_ORDER_STATUS_ERROR,
    SET_ORDER_STATUS_LOADING,
    SET_ORDER_STATUS_SUCCESS,
} from '@/actions/sellersOrdersActions';
import { ProductOrder } from '@/generated/paymentapi';

export interface ISellersOrdersState {
    loading?: boolean;
    error?: unknown;
    orders: ProductOrder[];
    orderDetails: ProductOrder;
    setOrderStatusLoading?: boolean;
    setOrderStatusError?: unknown;
}

const initialState: ISellersOrdersState = {
    loading: false,
    error: undefined,
    orders: [],
    orderDetails: undefined,
    setOrderStatusLoading: false,
    setOrderStatusError: undefined,
};

const sellersOrdersReducer = (state = initialState, action: SellersOrdersActions): ISellersOrdersState => {
    switch (action.type) {
        case SELLERS_ORDERS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case SELLERS_ORDERS_LOADING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                orders: action.orders,
            };
        case SELLERS_ORDERS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SELLERS_ORDERS_ORDER_DETAILS_LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case SELLERS_ORDERS_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                orderDetails: action.order,
            };
        case SELLERS_ORDERS_ORDER_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SET_ORDER_STATUS_LOADING:
            return {
                ...state,
                setOrderStatusError: undefined,
                setOrderStatusLoading: true,
            };
        case SET_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                setOrderStatusLoading: false,
                setOrderStatusError: undefined,
                orders: state.orders.reduce<ProductOrder[]>((accum, order) => {
                    accum.push(order.uid === action.order.uid ? action.order : order);
                    return accum;
                }, []),
                orderDetails: action.order,
            };
        case SET_ORDER_STATUS_ERROR:
            return {
                ...state,
                setOrderStatusLoading: false,
                setOrderStatusError: action.error,
            };
        default:
            return state;
    }
};

export default sellersOrdersReducer;

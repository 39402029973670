import { fillClassificatorProductStatusValue } from './classificatorActions';

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { ProductControllerApi, ProductListRequest } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { checkProductEditAllowed } from '@/reducers/productCreateReducer';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getColorFromClassificator } from '@/selectors/getColorFromClassificator';
import { isLoggedIn } from '@/selectors/isLoggedIn';

export const MY_GOODS_LIST_LOADING = 'MY_GOODS_LIST_LOADING' as const;
export const MY_GOODS_LIST_LOADING_SUCCESS = 'MY_GOODS_LIST_LOADING_SUCCESS' as const;
export const MY_GOODS_LIST_LOADING_ERROR = 'MY_GOODS_LIST_LOADING_ERROR' as const;

export const MY_GOODS_LIST_UPDATE_COUNT = 'MY_GOODS_LIST_UPDATE_COUNT' as const;

export const MY_GOODS_ARCHIVED_LOADING = 'MY_GOODS_ARCHIVED_LOADING' as const;
export const MY_GOODS_ARCHIVED_LOADING_SUCCESS = 'MY_GOODS_ARCHIVED_LOADING_SUCCESS' as const;

export const MY_GOODS_ITEMS_DELETE_LOADING = 'MY_GOODS_ITEMS_DELETE_LOADING' as const;
export const MY_GOODS_ITEMS_DELETE_LOADING_SUCCES = 'MY_GOODS_ITEMS_DELETE_LOADING_SUCCES' as const;

export const MY_GOODS_SAVE_FILTER = 'MY_GOODS_SAVE_FILTER' as const;

const myGoodsListLoadingAction = (abortController: AbortController) => ({
    type: MY_GOODS_LIST_LOADING,
    abortController,
});

const myGoodsListLoadingSuccessAction = (products: IProduct[]) => ({
    type: MY_GOODS_LIST_LOADING_SUCCESS,
    products,
});

const myGoodsListUpdateCountAction = (count: number) => ({
    type: MY_GOODS_LIST_UPDATE_COUNT,
    count,
});

const myGoodsListLoadingErrorAction = (error: unknown) => ({
    type: MY_GOODS_LIST_LOADING_ERROR,
    error,
});

export const myGoodsArchivedLoadingAction = (archivedLoading?: boolean) => ({
    type: MY_GOODS_ARCHIVED_LOADING,
    archivedLoading,
});

export const myGoodsArchivedLoadingSuccessAction = (products: IProduct[]) => ({
    type: MY_GOODS_ARCHIVED_LOADING_SUCCESS,
    products,
});

export const myGoodsItemsDeleteLoadingAction = (loading: boolean) => ({
    type: MY_GOODS_ITEMS_DELETE_LOADING,
    loading,
});

export const myGoodsItemsDeleteLoadingSuccesAction = (productsUidList: string[]) => ({
    type: MY_GOODS_ITEMS_DELETE_LOADING_SUCCES,
    productsUidList,
});

export const myGoodsSaveFilterAction = (selectedStatuses?: string[]) => ({
    type: MY_GOODS_SAVE_FILTER,
    selectedStatuses,
});

export type MyGoodsActions = ReturnType<
    | typeof myGoodsListLoadingAction
    | typeof myGoodsListLoadingSuccessAction
    | typeof myGoodsListLoadingErrorAction
    | typeof myGoodsArchivedLoadingAction
    | typeof myGoodsArchivedLoadingSuccessAction
    | typeof myGoodsItemsDeleteLoadingAction
    | typeof myGoodsItemsDeleteLoadingSuccesAction
    | typeof myGoodsListUpdateCountAction
    | typeof myGoodsSaveFilterAction
>;

export const archivateProducts =
    (productsIds: string[]) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        dispatch(myGoodsArchivedLoadingAction(true));

        try {
            const controller = new ProductControllerApi();
            const response = await controller.archiveProductsUsingPOST(productsIds);
            handleResponseAndThrowAnErrorIfExists(response);
            const newData = await controller.productListUsingPOST({
                archived: false,
                type: getActiveProductType(state),
            });
            handleResponseAndThrowAnErrorIfExists(newData);
            const items: IProduct[] = newData.body || [];
            fillClassificatorProductStatusValue(items, state);
            // product edit allowed status
            items.forEach((item) => {
                item.colorValue = getColorFromClassificator(state, item.color);
                item.isProductEditAllowed = checkProductEditAllowed(item.status);
            });
            dispatch(myGoodsListLoadingSuccessAction(items));
        } catch (error) {
            console.error('at archivateProducts in myGoodsActions');
            dispatch(myGoodsListLoadingErrorAction((error as Error).message));
        } finally {
            dispatch(myGoodsArchivedLoadingAction(false));
        }
        return getState();
    };

export const loadMyGoodsList =
    (request: ProductListRequest = {}, updateCountOnly?: boolean) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        if (!isLoggedIn(state)) {
            return;
        }

        if (state.myGoodsReducer.loading && state.myGoodsReducer.abortController) {
            state.myGoodsReducer.abortController.abort();
        }

        try {
            const controller = new AbortController();

            dispatch(myGoodsListLoadingAction(controller));

            const response = await new ProductControllerApi().productListUsingPOST(
                {
                    ...request,
                    type: getActiveProductType(state),
                },
                { signal: controller.signal },
            );

            // it's strange, but AbortController doesn't cancel request
            if (controller.signal.aborted) {
                return;
            }

            const items: IProduct[] = response.body || [];

            if (updateCountOnly) {
                dispatch(myGoodsListUpdateCountAction(response.count));
            } else {
                fillClassificatorProductStatusValue(items, state);
                // product edit allowed status
                items.forEach((item) => {
                    item.colorValue = getColorFromClassificator(state, item.color);
                    item.isProductEditAllowed = checkProductEditAllowed(item.status);
                });
                dispatch(myGoodsListLoadingSuccessAction(items));
            }
        } catch (err) {
            console.error('at myGoodsActions in loadMyGoodsList', err);

            let errorText = err.toString();
            if (err.response && err.response.data && err.response.data.errorData) {
                errorText = err.response.data.errorData.message;
            }
            dispatch(myGoodsListLoadingErrorAction(errorText));
        }
    };

export const deleteMyGoods = (uidList: string[]) => async (dispatch: AppDispatch) => {
    dispatch(myGoodsItemsDeleteLoadingAction(true));
    try {
        const response = await new ProductControllerApi().deleteProductUsingDELETE(
            { uidList },
            { headers: { 'Content-Type': 'application/json' } },
        );
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(myGoodsItemsDeleteLoadingSuccesAction(uidList));
    } catch (error) {
        console.error('in myGoodsActions at deleteMyGoods', error);
        dispatch(myGoodsListLoadingErrorAction((error as Error).message));
    } finally {
        dispatch(myGoodsItemsDeleteLoadingAction(false));
    }
};

export const restoreProducts =
    (productsIds: string[]) => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        const state = getState();
        dispatch(myGoodsArchivedLoadingAction(true));

        try {
            const controller = new ProductControllerApi();
            const response = await controller.restoreProductsUsingPOST(productsIds);
            handleResponseAndThrowAnErrorIfExists(response);
            const newData = await controller.productListUsingPOST({
                archived: true,
                type: getActiveProductType(state),
            });
            handleResponseAndThrowAnErrorIfExists(newData);
            const items: IProduct[] = newData.body || [];
            fillClassificatorProductStatusValue(items, state);
            // product edit allowed status
            items.forEach((item) => {
                item.colorValue = getColorFromClassificator(state, item.color);
                item.isProductEditAllowed = checkProductEditAllowed(item.status);
            });
            dispatch(myGoodsListLoadingSuccessAction(items));
        } catch (error) {
            console.error('at archivateProducts in myGoodsActions');
            dispatch(myGoodsListLoadingErrorAction((error as Error).message));
        } finally {
            dispatch(myGoodsArchivedLoadingAction(false));
        }
        return getState();
    };

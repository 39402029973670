import { ISearchParams, saveSearchLoading } from './productActions';

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { ProductControllerApi, PublicControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IProduct } from '@/reducers/productReducer';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { productToIProduct } from '@/utils';

export const ALL_GOODS_LOADING = 'ALL_GOODS_LOADING' as const;
export const ALL_GOODS_LOADING_SUCCESS = 'ALL_GOODS_LOADING_SUCCESS' as const;
export const ALL_GOODS_LOADING_ERROR = 'ALL_GOODS_LOADING_ERROR' as const;

export const ALL_GOODS_RELOADING_SUCCESS = 'ALL_GOODS_RELOADING_SUCCESS' as const;

const allGoodsLoadingAction = () => ({
    type: ALL_GOODS_LOADING,
});

const allGoodsLoadingSuccessAction = (products: IProduct[], totalCount: number) => ({
    type: ALL_GOODS_LOADING_SUCCESS,
    products,
    totalCount,
});

const allGoodsReloadingSuccessAction = (products: IProduct[], totalCount: number) => ({
    type: ALL_GOODS_RELOADING_SUCCESS,
    products,
    totalCount,
});

const allGoodsLoadingErrorAction = (error: unknown) => ({
    type: ALL_GOODS_LOADING_ERROR,
    error,
});

export type AllGoodsActions = ReturnType<
    | typeof allGoodsLoadingAction
    | typeof allGoodsLoadingSuccessAction
    | typeof allGoodsReloadingSuccessAction
    | typeof allGoodsLoadingErrorAction
>;

export const loadAllGoods =
    (searchParams: ISearchParams, isReload?: boolean) =>
    async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
        dispatch(allGoodsLoadingAction());
        const { language } = getState().rootReducer;
        const country = getCountryCodeFromState(getState());

        try {
            const result = isLoggedIn(getState())
                ? await new ProductControllerApi().productSearchUsingPOST(searchParams, country, language)
                : await new PublicControllerApi().productSearchUsingPOST1(searchParams, country, language);

            handleResponseAndThrowAnErrorIfExists(result);

            const items = result.body || [];
            const products = items.map((prod) => productToIProduct(prod, getState()));
            isReload
                ? dispatch(allGoodsReloadingSuccessAction(products, result.totalCount))
                : dispatch(allGoodsLoadingSuccessAction(products, result.totalCount));
        } catch (error) {
            console.error('at allGoodsActions in loadAllGoods', error);

            dispatch(allGoodsLoadingErrorAction(error.message));
        } finally {
            dispatch(saveSearchLoading(false));
        }
    };

import dayjs from 'dayjs';
import React from 'react';

import { ClaimSubjectClassificatorType, EntityClassificatorType } from '@/actions/classificatorActions';
import {
    IcBlack,
    IcBlue,
    IcFramecard,
    IcGoldYellow,
    IcGraySilver,
    IcGreen,
    IcMastercardLarge,
    IcMulticolor,
    IcOrangeBrown,
    IcPink,
    IcPurple,
    IcRed,
    IcVisaSmall,
    IcWhite,
} from '@/assets';
import { CustomSelectValue } from '@/components/CustomSelect';
import { ICategory } from '@/types/localConfig';
import { OrderStatuses } from '@/types/orderStatuses';
import { OrderStatusesExtended } from '@/types/orderStatusesExtended';

export const REQUIRED_FIELD_ERROR = 'Please fill out this field';
export const PRICE_OR_FREE_ERROR = 'Enter price or choose free';
export const MIN_ERROR = 'Min should be less then Max';
export const MAX_ERROR = 'Max should be greater then Min';
export const AT_LEAST_ONE_ERROR = 'Choose at least one';
export const DISCOUNTED_ERROR = 'product_offer_form.dicount_price_amount.validation_error';
export const NOT_URL_ERROR = 'Please enter a URL';
export const NOT_PHONE_ERROR = 'Please enter a phone number';
export const NOT_POSTAL_CODE_ERROR = 'Please enter a postal code';
export const NOT_EMAIL_ERROR = 'Please enter an Email';
export const TRANSPARENT_BACKDROP_SELECTOR = 'transparent-backdrop';
export const INVALID_EMAIL_ERROR = 'Please make sure your email is correct';
export const INVALID_PASSWORD_CUT_ERROR = 'Invalid password format';
export const VALID_PASSWORD_REQUIREMENT =
    'The password must be at least 8 characters long and consist of uppercase and lowercase Latin letters of the as well as numbers and one of special characters [!@#$%^&*-_]';
export const INVALID_PASSWORD_ERROR = `Invalid password. ${VALID_PASSWORD_REQUIREMENT}`;
export const INVALID_DATE = 'Invalid date';
export const INVALID_TIME_ERROR = 'Invalid Time';
export const INVALID_TIME_PERIOD_ERROR = 'Invalid time period';
export const NOT_MATCHING_PASSWORDS_ERROR = 'Please make sure your passwords match';
export const INCORRECT_PHONE_NUMBER_CUT_ERROR = 'Invalid phone';
export const INCORRECT_PHONE_NUMBER_ERROR = 'contact_information.form.validation_error.phone_number';
export const INCORRECT_NICKNAME_ERROR = 'contact_information.form.validation_error.nickname';
export const ACCEPT_ERROR = 'You must accept our Privacy Policy and Terms of Use before continue';
export const NOT_VALID_ERROR = 'Request validation error';
export const WRONG_USERNAME_ERROR = 'E-mail or phone number';
export const ENTER_PASSWORD_ERROR = 'Enter password';
export const PASSWORD_MATCH_ERROR = 'Please make sure your passwords match';
export const YEAR_FORMAT_ERROR = 'Incorrect year format';
export const PRICE_FROM_ERROR = 'Minimum price is greater than maximum';
export const PRICE_TO_ERROR = 'Maximum price is less than minimum';
export const IBAN_ERROR = 'Invalid IBAN';
export const CODE_ERROR = 'Invalid code';
export const NEW_PASSWORD_ERROR = 'New password must be different from the old one';
export const INVALID_PERCENTAGE_ERROR = 'Invalid percentage value';
export const MIN_SALE_PRICE_ERROR = 'Value shold be not less than 0.1';
export const MAX_RENT_PER_HOUR_PERIOD_ERROR = 'Max 72 hours available';
export const INVALID_FORMAT_ERROR = 'Invalid format';
export const LATIN_CHARACTERS_ONLY_ERROR = 'Latin characters only.';
export const INVALID_CARD_NUMBER_ERROR = 'You entered the number incorrectly. Check it out and try again.';
export const INVALID_CVC_ERROR = 'Enter the correct CVC.';
export const INVALID_EXPIRE_DATE_ERROR = 'Enter a valid date.';
export const ONLY_NUMBERS_ERROR = 'Please use only numbers for phone number';
export const INVALID_CODE_ERROR = 'You entered an invalid code';
export const INVALID_NAME_ERROR = 'Please, enter a real name without numbers';
export const EMAIL_ALREADY_IN_USE_ERROR = 'This email has been associated with another account';
export const PHONE_ALREADY_IN_USE_ERROR = 'This phone number has been associated with another account';
export const NOT_VALID = 'Invalid value';
export const NOT_A_NUMBER = 'Not a number';
export const MUST_BE_INTEGER = 'Must be integer';
export const MUST_BE_GT_THAN_ZERO = 'Must be positive';

export const LOREM_IPSUM_STRING =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit. Aliquam vestibulum vitae lorem ac iaculis. Praesent nec pharetra massa, at blandit lectus. Sed tincidunt, lectus eu convallis elementum, nibh nisi aliquet urna, nec imperdiet felis sapien at enim.';

export const INVALID_BIRTH_DATE_ERROR = 'Please enter a valid date of birth';
export const REJECTED_OR_REFUNDED_STATUSES_EXTENDED = [OrderStatusesExtended.RCA, OrderStatusesExtended.RRE];
export const PAID_ORDER_STATUSES = [OrderStatuses.MP, OrderStatuses.PA];
export const cardIcons = {
    unknown: <IcFramecard />,
    visa: <IcVisaSmall />,
    mastercard: <IcMastercardLarge />,
};

export const LANG_DEPENDENT_CLASSIFICATOR_TYPES: EntityClassificatorType[] = [
    'Product_Status',
    'Account_Status',
    'Account_Type',
    'Order_State',
    'Company_BusinessType',
    'Product_Color',
    'Sale_Error',
    'Sale_Status',
    'Tax_System',
];
export const LOADING_CLASSIFICATOR_TYPES: EntityClassificatorType[] = [
    ...LANG_DEPENDENT_CLASSIFICATOR_TYPES,
    'Url_app',
];

export const LOADING_SUBJECT_CLASSIFICATOR_TYPES: ClaimSubjectClassificatorType[] = ['Application', 'MyProduct'];

export enum AuthActions {
    LOGIN = 'login',
    REGISTER = 'register',
    VERIFY_EMAIL = 'verify_email',
}

export const LETTERS_LIST =
    'a-zA-Zа-яА-ЯёЁàáâäæãåāèéêëēėęÿûüùúūîïíīįìôöòóœøōõßśšłžźżçćčñńЁЁÀÁÂÄÆÃÅĀÈÉÊËĒĖĘŸÛÜÙÚŪÎÏÍĪĮÌÔÖÒÓŒØŌÕSSŚŠŁŽŹŻÇĆČÑŃ';

export const URL_REGEX = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm);

export const URL_PATTERN = `${URL_REGEX}`.slice(1, `${URL_REGEX}`?.length - 3);

export const LAST_SLASH_REGEX = /\/$/;

export const FILTERS_CATEGORY: ICategory = {
    id: 'all_filtres',
    name: 'All Filtres',
    color: '',
    icon: 'ic-Filter',
};

export const ALL_CATEGORY_CLASSIFICATOR_CODE = 'all';

export const MONTHS = Array.from({ length: 12 }).map((_, index) => ({
    label: new Date(2022, index, 1, 0, 0, 0, 0),
    value: `0${index + 1}`.slice(-2),
}));

export const HOURS_OPTIONS = Array.from({ length: 24 }).map<CustomSelectValue>((_, index) => ({
    label: dayjs(new Date(2022, 1, 1, index, 0, 0, 0))
        .format('hh:mm A')
        .toUpperCase(),
    value: String(index),
}));

export enum ReviewSortingFields {
    recent = 'recent',
    lowest = 'rating_low',
    highest = 'rating_high',
}

export const ReviewSortingMap: Record<ReviewSortingFields, string> = {
    [ReviewSortingFields.recent]: 'Recent',
    [ReviewSortingFields.highest]: 'Rating: Highest First',
    [ReviewSortingFields.lowest]: 'Rating: Lowest First',
};

export const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export const TIMEZONE_OPTIONS = Array.from({ length: 25 }).map<CustomSelectValue>((_, index) => ({
    label: `GMT${index - 12 >= 0 ? '+' : ''}${index - 12}`,
    value: String(index - 12),
}));

export const APP_TIMEZONE = new Date().getTimezoneOffset() / -60;
export const PERIOD_OPTIONS = [
    { label: '7 Days', value: 'week' as const },
    { label: '31 Days', value: 'month' as const },
    { label: 'Year', value: 'year' as const },
];

export const colorIcons = {
    black: <IcBlack />,

    white: <IcWhite />,

    blue: <IcBlue />,

    brown: <IcOrangeBrown />,

    yellow: <IcGoldYellow />,

    green: <IcGreen />,

    pink: <IcPink />,

    purple: <IcPurple />,

    red: <IcRed />,

    grey: <IcGraySilver />,

    multicolour: <IcMulticolor />,
};

export const AVAILABLE_COUNTRIES = ['BY', 'KZ', 'RU'];
export const AVAILABLE_DELIVERY_COUNTRIES = ['RU'];
export const AVAILABLE_DELIVERY_COUNTRIES_ADVERTISEMENT = ['BY', 'KZ', 'RU'];

export const DEFAULT_COUNTRY_CODE = 'RU';
export const DEFAULT_CURRENCY_CODE = 'RUB';

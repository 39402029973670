import { MutableRefObject, useEffect } from 'react';

export const useOuterClick = <T extends HTMLElement | null>(ref: MutableRefObject<T>, handler: (e: Event) => void) => {
    useEffect(() => {
        const handleClickOutside = (e: Event) => {
            if (!ref?.current?.contains(e.target as Node)) {
                handler(e);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [handler, ref]);
};

import cn from 'classnames';
import { Link } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { CustomChipProps } from './CustomChip.types';

import { IcArrow } from '@/assets';

import './CustomChip.less';

export const CustomChip: React.FC<CustomChipProps> = ({
    text,
    value,
    onClick,
    onDelete,
    deletable = false,
    deleteIcon = 'icon ic-close-s',
    className,
    active = false,
    large,
    arrowDirection,
    textName,
}) => {
    const delIcon = useMemo(
        () => (typeof deleteIcon === 'string' ? <i className={deleteIcon} /> : deleteIcon),
        [deleteIcon],
    );

    const handleClick = useCallback(() => onClick?.(value ?? text), [onClick, text, value]);

    const handleDelete = useCallback(() => onDelete?.(value ?? text), [onDelete, text, value]);

    return (
        <div className={cn('custom-chip', 'chip', { active, deletable, large }, className)} onClick={handleClick}>
            {textName && (
                <>
                    <span>{textName}</span>:&nbsp;
                </>
            )}
            <span>{text}</span>
            {deletable && <Link onClick={handleDelete}>{delIcon}</Link>}
            {arrowDirection && (
                <Link iconOnly className={cn('arrow', arrowDirection)}>
                    <IcArrow />
                </Link>
            )}
        </div>
    );
};

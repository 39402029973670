import { f7, Icon, Link, List, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { CODE_SENT_MESSAGE, initialState, RESEND_CODE_TIMEOUT } from './ForgotPasswordPopup.constants';

import {
    CodeValues,
    ForgotPasswordPopupProps,
    ForgotPasswordStep,
    InitValues,
    PasswordValues,
} from './ForgotPasswordPopUp.types';

import {
    makeResendCodeMessage,
    validateCodeStep,
    validateInitStep,
    validatePasswordStep,
} from './ForgotPasswordPopup.utils';

import { setAlertAction } from '@/actions/alertActions';
import {
    changePassword as changePasswordAction,
    loginWithOneTimePassword,
    oneTimePassword,
} from '@/actions/sessionActions';
import { IcClose } from '@/assets';
import { CustomButton } from '@/components/CustomButton';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { Timer } from '@/components/Timer';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { usePreloader } from '@/hooks/usePreloader';
import { getIsMobile } from '@/selectors/getIsMobile';
import { openConfirm } from '@/shared/confirmDialog';
import { INVALID_PASSWORD_ERROR } from '@/shared/constants';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { isValidPassword } from '@/utils';

import './ForgotPasswordPopUp.less';

export const ForgotPasswordPopUp = ({ opened, onPopupClosed }: ForgotPasswordPopupProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(getIsMobile);

    const { passwordChangeLoading: changing, passwordLoginLoading: loading } = useAppSelector(
        (state) => state.sessionReducer,
    );

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [step, setStep] = useState<ForgotPasswordStep>('init');
    const [showResend, setShowResend] = useState(false);

    const _resendTimeout = useRef<NodeJS.Timeout>();

    const stepTitle = useMemo(() => {
        switch (step) {
            case 'code':
                return t('Verification Code');
            case 'password':
                return t('Reset Password');
            default:
                return t('Forgot password');
        }
    }, [step, t]);

    const activateResendTimeout = useCallback(() => {
        setShowResend(false);

        clearTimeout(_resendTimeout.current);

        _resendTimeout.current = setTimeout(() => {
            clearTimeout(_resendTimeout.current);
            setShowResend(true);
        }, RESEND_CODE_TIMEOUT);
    }, []);

    const clearState = useCallback(() => {
        setCode(initialState.code);
        setEmail(initialState.email);
        setPassword(initialState.password);
        setPasswordRepeat(initialState.passwordRepeat);
        setStep(initialState.step);
        setShowResend(initialState.showResend);
    }, []);

    const showError = useCallback(
        (error: string) => {
            if (error === 'Client is not authorized') {
                error = 'Incorrect code. Please try again.';
            }

            dispatch(setAlertAction({ text: t(error) }));
        },
        [dispatch, t],
    );

    const processStepResult = useCallback(
        (step: ForgotPasswordStep, error?: string) => {
            f7.preloader.hide();

            if (error) {
                clearState();
                showError(error);
            } else {
                setStep(step);
            }
        },
        [clearState, showError],
    );

    usePreloader(changing);

    const sendOneTimePassword = useCallback(
        (email: string, withToast?: boolean) => {
            activateResendTimeout();

            f7.preloader.show();
            dispatch(oneTimePassword(email)).then((state) => {
                processStepResult('code', state.sessionReducer.passwordError);

                if (withToast) {
                    f7.toast.show({
                        text: t('A new verification code has been sent'),
                        closeTimeout: 3000,
                    });
                }
            });
        },
        [activateResendTimeout, dispatch, processStepResult, t],
    );

    const onResendCode = useCallback(
        () => openConfirm(makeResendCodeMessage(email), () => sendOneTimePassword(email, true)),
        [email, sendOneTimePassword],
    );

    const loginWithCode = useCallback(
        (code: string) => {
            f7.preloader.show();

            dispatch(loginWithOneTimePassword(email, code)).then((state) =>
                processStepResult('password', state.sessionReducer.passwordError),
            );
        },
        [dispatch, email, processStepResult],
    );

    const onInitStepSubmit = ({ email }: InitValues) => {
        setEmail(email);
        sendOneTimePassword(email);
    };

    const onCodeStepSubmit = ({ code }: CodeValues) => {
        setCode(code);
        loginWithCode(code);
    };

    const onClose = useCallback(() => {
        onPopupClosed();
        clearState();
    }, [onPopupClosed, clearState]);

    useEffect(() => {
        return () => {
            clearTimeout(_resendTimeout.current);
        };
    }, []);

    const changePassword = useCallback(
        (password: string) => {
            f7.preloader.show();

            dispatch(changePasswordAction(password)).then((state) => {
                if (state.sessionReducer.passwordError) {
                    showError(state.sessionReducer.passwordError);
                } else {
                    onClose();
                }
            });
        },
        [onClose, dispatch, showError],
    );

    const onPasswordStepSubmit = ({ password, passwordRepeat }: PasswordValues) => {
        if (!isValidPassword(password)) {
            f7.dialog.alert(t(INVALID_PASSWORD_ERROR));
            return;
        }

        setPassword(password);
        setPasswordRepeat(passwordRepeat);
        changePassword(password);
    };

    return (
        <MSPopup className="forgot-password-popup" opened={opened} onPopupClosed={onClose} onBackdropClick={onClose}>
            <Navbar className="forgot-password-popup__navbar" noShadow noHairline>
                {isMobile && (
                    <NavLeft>
                        <Link className="login-popup__close" iconOnly onClick={onClose}>
                            <Icon f7="arrow_left" />
                        </Link>
                    </NavLeft>
                )}

                {!isMobile && (
                    <NavRight>
                        <Link className="forgot-password-popup__close" iconOnly onClick={onClose}>
                            <IcClose />
                        </Link>
                    </NavRight>
                )}
            </Navbar>
            <div className="forgot-password-popup__content">
                <p className="forgot-password-popup__title">{stepTitle}</p>

                {step === 'init' && (
                    <div className="forgot-password-popup__step">
                        <p className="step-hint">{t('To reset your password, enter your email')}</p>

                        <Form<InitValues>
                            initialValues={{ email }}
                            validate={validateInitStep}
                            onSubmit={onInitStepSubmit}
                        >
                            {({ handleSubmit, valid }) => (
                                <>
                                    <List noHairlines form>
                                        <CustomInputField
                                            label={t('E-mail')}
                                            name="email"
                                            type="email"
                                            autocomplete="username"
                                            requiredSign
                                            required
                                        />
                                    </List>
                                    <div className="forgot-password-popup__step-btn">
                                        <CustomButton
                                            disabled={loading || !valid}
                                            size="large"
                                            fullWidth
                                            onClick={handleSubmit}
                                        >
                                            {t('Reset Password')}
                                        </CustomButton>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                )}
                {step === 'code' && (
                    <div className="forgot-password-popup__step">
                        <div className="step-hint">
                            {`${t(CODE_SENT_MESSAGE)} `}
                            <strong>{email}</strong>
                        </div>

                        <Form<CodeValues>
                            onSubmit={onCodeStepSubmit}
                            validate={validateCodeStep}
                            initialValues={{ code }}
                        >
                            {({ handleSubmit, valid }) => (
                                <>
                                    <List noHairlines form>
                                        <CustomInputField
                                            label={t('Enter Code')}
                                            name="code"
                                            slot="list"
                                            requiredSign
                                        />
                                    </List>
                                    <div className="forgot-password-popup__step-btn">
                                        <CustomButton
                                            disabled={loading || !valid}
                                            size="large"
                                            fullWidth
                                            onClick={handleSubmit}
                                        >
                                            {t('Continue')}
                                        </CustomButton>

                                        {showResend ? (
                                            <p className="sign-up-text">
                                                {`${t('Did not get a Code?')} `}
                                                <Link onClick={onResendCode}>{t('Resend')}</Link>
                                            </p>
                                        ) : (
                                            <p className="resend-text">
                                                {`${t('You can try again in')} `}
                                                <Timer secondsLeft={RESEND_CODE_TIMEOUT / 1000} />
                                            </p>
                                        )}
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                )}
                {step === 'password' && (
                    <Form<PasswordValues>
                        onSubmit={onPasswordStepSubmit}
                        validate={validatePasswordStep}
                        initialValues={{
                            password,
                            passwordRepeat,
                        }}
                    >
                        {({ handleSubmit, valid }) => (
                            <div className="forgot-password-popup__step">
                                <List form noHairlines>
                                    <CustomInputField
                                        className="forgot-password-popup__step-input__with-info"
                                        label={t('New Password')}
                                        errorInTooltip={t(
                                            'At least 1 letter, a number or symbol (!@#$%^&*-_), at least 8 characters',
                                        )}
                                        name="password"
                                        type="password"
                                        slot="list"
                                        requiredSign
                                    />
                                    <CustomInputField
                                        label={t('Repeat Password')}
                                        name="passwordRepeat"
                                        type="password"
                                        slot="list"
                                        requiredSign
                                    />
                                </List>
                                <div className="forgot-password-popup__step-btn">
                                    <CustomButton
                                        disabled={loading || !valid}
                                        size="large"
                                        fullWidth
                                        onClick={handleSubmit}
                                    >
                                        {t('Done')}
                                    </CustomButton>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </div>
        </MSPopup>
    );
};

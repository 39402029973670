import { MessengerItem } from './MessengerOptions.types';

import { IcInstagram, IcTelegram, IcViber, IcWhatsapp, IcGem } from '@/assets';
import { MessengerName } from '@/types/localConfig';

export const MESSENGERS: Record<MessengerName, MessengerItem> = {
    telegram: {
        text: 't.me',
        icon: IcTelegram,
        link: 'https://t.me/',
    },
    whatsapp: {
        text: 'we.me',
        icon: IcWhatsapp,
        link: 'https://wa.me/',
    },
    viber: {
        text: 'viber.com',
        icon: IcViber,
        link: 'viber://chat?number=+',
    },
    instagram: {
        text: 'instagram.com',
        icon: IcInstagram,
        link: 'https://www.instagram.com/',
    },
    gem4me: {
        text: 'gemspace.com',
        icon: IcGem,
        link: 'https://links.gemspace.com/invite/',
    },
};

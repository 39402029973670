import cn from 'classnames';
import { List } from 'framework7-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FormSpyRendererProps, GeneralInfoProps, GeneralInfoValues } from './GeneralInfo.types';

import { parseGeneralInfoValuesBeforeSubmit, validateGeneralInfo } from './GeneralInfo.utils';

import { CustomSelectValue } from '@/components/CustomSelect';
import { CheckboxField } from '@/components/Fields/CheckboxField';
import { CustomInputField } from '@/components/Fields/CustomInputField';
import { CustomSelectField } from '@/components/Fields/CustomSelectField';
import { DateRangePickerField } from '@/components/Fields/DateRangePickerField';
import { SelectSheetField } from '@/components/Fields/SelectSheetField';
import { ToggleField } from '@/components/Fields/ToggleField';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { usePrevious } from '@/hooks/usePrevious';
import { getIsMobile } from '@/selectors/getIsMobile';
import { noop } from '@/utils';

import './GeneralInfo.less';

const FormSpyRenderer = ({
    valid,
    values,
    handleDraftChange,
    handleValidity,
    draftInitial = {},
    form,
}: FormSpyRendererProps) => {
    const prevType = usePrevious(values.discountType?.value);
    useEffect(() => {
        if (prevType && prevType !== values.discountType?.value) {
            form.batch(() => {
                form.change('discountAmountPercent', draftInitial.discountAmountPercent);
                form.change('discountAmountFixed', draftInitial.discountAmountFixed);
                form.change('roundingType', draftInitial?.roundingType);
                form.change('validDiscountAmountRangeFrom', draftInitial.validDiscountAmountRangeFrom);
                form.change('validDiscountAmountRangeTo', draftInitial.validDiscountAmountRangeTo);
            });
        }
    }, [
        draftInitial.discountAmountFixed,
        draftInitial.discountAmountPercent,
        draftInitial?.roundingType,
        draftInitial.validDiscountAmountRangeFrom,
        draftInitial.validDiscountAmountRangeTo,
        form,
        prevType,
        values.discountType?.value,
    ]);

    useEffect(() => {
        handleValidity(valid);
        if (valid) {
            handleDraftChange(parseGeneralInfoValuesBeforeSubmit(values));
        }
    }, [handleDraftChange, handleValidity, valid, values]);

    if (values.neverExpires) {
        form.change('endDate', undefined);
    }

    return null;
};

export const GeneralInfo: React.FC<GeneralInfoProps> = ({
    draftInitial,
    handleDraftChange,
    handleValidity,
    containerID,
    isEdit,
}) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const { Discount_RoundType, Discount_Type } = useAppSelector(
        (state) => state.classificatorReducer.entitiesClassificators,
    );

    const roundingTypes: CustomSelectValue[] = useMemo(
        () => Discount_RoundType?.map((item) => ({ label: item.value, value: item.code })),
        [Discount_RoundType],
    );

    const discountTypes = useMemo(
        () => Discount_Type?.map((item) => ({ label: item.value, value: item.code })),
        [Discount_Type],
    );

    const [discountTypeSheetOpened, setDiscountTypeSheetOpened] = useState(false);
    const [openDiscountTypeSheet, closeDiscountTypeSheet] = useBooleanState(setDiscountTypeSheetOpened);

    const [roundingTypeSheetOpened, setRoundingTypeSheetOpened] = useState(false);
    const [openRoundingTypeSheet, closeRoundingTypeSheet] = useBooleanState(setRoundingTypeSheetOpened);

    return (
        <div id={containerID} className="sale-general-info__container">
            <h2 className="sale-general-info__container-title">{t('General Information')}</h2>
            <Form<GeneralInfoValues> validate={validateGeneralInfo} onSubmit={noop} initialValues={draftInitial}>
                {({ values }) => (
                    <List className="sale-general-info__container-list">
                        <CustomInputField<string> name="name" label={`${t('Sale Name')}`} requiredSign />
                        <p className="sale-general-info__container-description">
                            {t('This name will be shown near the price and in the tab on the homepage')}
                        </p>
                        <CustomSelectField
                            className="sale-general-info__container-list-item"
                            requiredSign
                            label={`${t('Type of Discount')}`}
                            name="discountType"
                            options={discountTypes}
                            readOnly={isEdit}
                            openPopup={openDiscountTypeSheet}
                        />
                        {values.discountType?.value === 'P' ? (
                            <>
                                <CustomInputField<number>
                                    requiredSign
                                    name="discountAmountPercent"
                                    label={`${t('Discount Amount')}`}
                                    startPrefix="%"
                                    type="number"
                                    disabled={isEdit}
                                />
                                <CustomSelectField
                                    className="sale-general-info__container-list-item"
                                    label={`${t('Rounded to')}`}
                                    name="roundingType"
                                    options={roundingTypes}
                                    readOnly={isEdit}
                                    openPopup={openRoundingTypeSheet}
                                />
                                <p className="sale-general-info__container-description">
                                    {t(
                                        'We use rounding to make long decimal numbers shorter. To calculate the discount, the amount will be rounded to .49 or .99',
                                    )}
                                </p>
                            </>
                        ) : (
                            <>
                                <CustomInputField<number>
                                    requiredSign
                                    name="discountAmountFixed"
                                    label={`${t('Discount Amount')}`}
                                    type="number"
                                    disabled={isEdit}
                                />
                                <p className="sale-general-info__container-label">{t('Price Range')}</p>
                                <div className="sale-general-info__container-price">
                                    <CustomInputField<number>
                                        label={`${t('From')}`}
                                        requiredSign
                                        type="number"
                                        name="validDiscountAmountRangeFrom"
                                        disabled={isEdit}
                                    />
                                    <CustomInputField<number>
                                        type="number"
                                        requiredSign
                                        label={`${t('To')}`}
                                        name="validDiscountAmountRangeTo"
                                        disabled={isEdit}
                                    />
                                </div>
                            </>
                        )}
                        <h3 className="sale-general-info__container-subtitle">{t('Start and End Dates')}</h3>
                        <div className="sale-general-info__container-datepicker">
                            <DateRangePickerField
                                required
                                withoutApplyButton
                                withArrow
                                endName="endDate"
                                startName="startDate"
                                datePerInput
                                visibleMonthForce={1}
                                secondInputDisabled={values.neverExpires}
                                minAvailableDateOffset={0}
                                disabled={isEdit}
                                isMobile={isMobile}
                                className="sale-general-info__container-datepicker-popover"
                            />
                        </div>
                        {isMobile ? (
                            <div className={cn('sale-general-info__container__never-expires-toggle')}>
                                {t('Never Expires')}
                                <ToggleField disabled={isEdit} name="neverExpires" />
                            </div>
                        ) : (
                            <CheckboxField
                                className={cn({ 'read-only': isEdit })}
                                disabled={isEdit}
                                title={t('Never Expires')}
                                name="neverExpires"
                            />
                        )}
                        <SelectSheetField
                            type="radio"
                            name="discountType"
                            options={discountTypes}
                            title={t('Type of Discount')}
                            opened={discountTypeSheetOpened}
                            onSheetOpen={openDiscountTypeSheet}
                            onSheetClose={closeDiscountTypeSheet}
                        />
                        <SelectSheetField
                            type="radio"
                            name="roundingType"
                            options={roundingTypes}
                            title={t('Rounded to')}
                            opened={roundingTypeSheetOpened}
                            onSheetOpen={openRoundingTypeSheet}
                            onSheetClose={closeRoundingTypeSheet}
                        />
                        <FormSpy<GeneralInfoValues>>
                            {(props) => (
                                <FormSpyRenderer
                                    {...props}
                                    draftInitial={draftInitial}
                                    handleValidity={handleValidity}
                                    handleDraftChange={handleDraftChange}
                                />
                            )}
                        </FormSpy>
                    </List>
                )}
            </Form>
        </div>
    );
};

export enum OrderStatusesExtended {
    RCV = 'RCV',
    SHP = 'SHP',
    DLV = 'DLV',
    RCA = 'RCA',
    RRE = 'RRE',
    RRC = 'RRC',
    PRC = 'PRC',
    RWC = 'RWC',
}

import {
    PRODUCT_SOURCE_CREATE_DRAFT_LOADING,
    PRODUCT_SOURCE_CREATE_LOADING_SUCCESS,
    PRODUCT_SOURCE_CREATE_SAVE_LOADING,
    PRODUCT_SOURCE_DELETE_LOADING,
    PRODUCT_SOURCE_DELETE_LOADING_SUCCESS,
    PRODUCT_SOURCE_ERROR,
    PRODUCT_SOURCE_FILE_ERROR_CLEAR,
    PRODUCT_SOURCE_HISTORY_LOADING,
    PRODUCT_SOURCE_HISTORY_LOADING_SUCCESS,
    PRODUCT_SOURCE_LOADING,
    PRODUCT_SOURCE_LOADING_SUCCESS,
    PRODUCT_SOURCE_LOG_LOADING,
    PRODUCT_SOURCE_LOG_LOADING_SUCCESS,
    productSourceActions,
    SOURCE_TYPES_LOADING,
    SOURCE_TYPES_LOADING_SUCCESS,
} from '@/actions/productSourceActions';
import { ProductSource, ProductSourceContent, ProductSourceType } from '@/generated/marketplaceapi';

export interface IProductSourceState {
    error?: string;
    sourceTypes?: ProductSourceType[];
    sourceTypesLoading?: boolean;
    productSourceLoading?: boolean;
    productSourceSaveLoading?: boolean;
    productSource?: ProductSource;
    productSourceLog?: string;
    productSourceLogLoading?: boolean;
    productSourceHistory?: ProductSourceContent[];
    productSourceHistoryLoading?: boolean;
}

const initialState: IProductSourceState = {
    productSourceLog: '{}',
};

const productSourceReducer = (state = initialState, action: productSourceActions): IProductSourceState => {
    switch (action.type) {
        case SOURCE_TYPES_LOADING:
            return {
                ...state,
                sourceTypesLoading: action.sourceTypesLoading,
                error: null,
            };
        case SOURCE_TYPES_LOADING_SUCCESS:
            return {
                ...state,
                sourceTypes: action.sourceTypes,
            };
        case PRODUCT_SOURCE_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case PRODUCT_SOURCE_LOADING:
        case PRODUCT_SOURCE_CREATE_DRAFT_LOADING:
        case PRODUCT_SOURCE_DELETE_LOADING: {
            return {
                ...state,
                productSourceLoading: action.productSourceLoading,
            };
        }
        case PRODUCT_SOURCE_CREATE_SAVE_LOADING: {
            return {
                ...state,
                productSourceSaveLoading: action.productSourceSaveLoading,
            };
        }
        case PRODUCT_SOURCE_LOADING_SUCCESS:
        case PRODUCT_SOURCE_CREATE_LOADING_SUCCESS: {
            return {
                ...state,
                productSource: action.productSource,
            };
        }

        case PRODUCT_SOURCE_DELETE_LOADING_SUCCESS: {
            return {
                ...state,
                productSource: undefined,
            };
        }
        case PRODUCT_SOURCE_LOG_LOADING: {
            return {
                ...state,
                productSourceLogLoading: action.productSourceLogLoading,
            };
        }

        case PRODUCT_SOURCE_LOG_LOADING_SUCCESS: {
            return {
                ...state,
                productSourceLog: action.productSourceLog,
            };
        }
        case PRODUCT_SOURCE_HISTORY_LOADING: {
            return { ...state, productSourceHistoryLoading: action.productSourceHistoryLoading };
        }
        case PRODUCT_SOURCE_HISTORY_LOADING_SUCCESS: {
            return { ...state, productSourceHistory: action.productSourceHistory };
        }

        case PRODUCT_SOURCE_FILE_ERROR_CLEAR: {
            return {
                ...state,
                productSource: state.productSource
                    ? {
                          ...state.productSource,
                          currentContent: {
                              ...(state?.productSource?.currentContent ?? {}),
                              errorMessage: undefined,
                          },
                      }
                    : undefined,
            };
        }

        default:
            return state;
    }
};

export default productSourceReducer;

import { RuleLink } from './PrivacyAndTerms.types';

export const defaultDisplayedUrls: RuleLink[] = [
    {
        urlCode: 'PrivacyPolicy',
        title: 'PrivacyPolicy',
    },
    {
        urlCode: 'TermsAndConditions',
        title: 'TermsAndConditions',
    },
    {
        urlCode: 'RefundPolicy',
        title: 'RefundPolicy',
    },
];

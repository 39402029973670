import { FirebaseOptions } from 'firebase/app';

import { IFirebaseAnalyticsService } from './analitics.types';
import { FirebaseCommonAnalytics } from './firebase.common';
import { FirebaseAnalyticsMarketspace } from './firebase.marketspace';

import { isInsideGem } from '@/utils/isInsideGem';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = require(`./${process.env.FIREBASE_CONFIG}`);

export const firebaseAnalyticsInstance: IFirebaseAnalyticsService = isInsideGem()
    ? new FirebaseAnalyticsMarketspace(firebaseConfig)
    : new FirebaseCommonAnalytics(firebaseConfig);

import cn from 'classnames';

import { f7 } from 'framework7-react';

import type { Toast } from 'framework7/components/toast/toast';

export const showToast = ({
    text,
    cssClass,
    closeTimeout = 4000,
    position = 'bottom',
    horizontalPosition = 'center',
    isError = false,
    withMargin = false,
    ...params
}: Toast.Parameters & { isError?: boolean; withMargin?: boolean } = {}): Toast.Toast =>
    f7.toast
        .create({
            text,
            closeTimeout,
            position,
            horizontalPosition,
            cssClass: cn(cssClass, { error: isError, 'with-margin': withMargin }),
            ...params,
        })
        .open();

import cn from 'classnames';
import { Device } from 'framework7';
import Framework7, { Framework7Params } from 'framework7/components/app/app-class';
import { Router } from 'framework7/modules/router/router';
import { App, f7, View } from 'framework7-react';
import cloneDeep from 'lodash.clonedeep';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { loadCategories } from './actions/categoryActions';
import {
    loadClaimSubjectsClassificator,
    loadClassificator,
    loadCountryClassificator,
    loadCurrencyClassificator,
} from './actions/classificatorActions';
import { initGeolocation } from './actions/geolocationActions';
import { getLanguages } from './actions/languagesActions';
import { F7_INIT, handleResizeEvent, INIT_ENTRY_PAGE_NAME } from './actions/rootActions';
import { authentificateAccount } from './actions/sessionActions';
import { messengerService } from './api/messengerService';
import { SelectCustomerLocationContainer } from './components/SelectCustomerLocationContainer';
import f7params from './f7params';
import { AuthManager } from './hoc/AuthManager';
import { FocusScrollingManager } from './hoc/FocusScrollingManager';
import { ReloadAllProductsOnLanguageChange } from './hoc/ReloadAllProductsOnLanguageChange';
import { ReloadCategoriesOnLanguageChange } from './hoc/ReloadCategoriesOnLanguageChange';
import { ReloadClassificatorsOnLanguageChange } from './hoc/ReloadClassificatorsOnLanguageChange';
import { RouterManager } from './hoc/RouterManager';
import { AppDispatch } from './hooks/store';
import { IApplicationStore } from './reducers/rootReducer';
import { ISessionState } from './reducers/sessionReducer';
import { getIsMobile } from './selectors/getIsMobile';
import {
    LOADING_CLASSIFICATOR_TYPES,
    LOADING_SUBJECT_CLASSIFICATOR_TYPES,
    TRANSPARENT_BACKDROP_SELECTOR,
} from './shared/constants';
import { MSAlert } from './shared/UIKit/Alert/Alert';
import connectPreloader, { IPreloaderProps } from './store/connectPreloader';
import { getPlatform } from './utils';

type Props = IPreloaderProps &
    WithTranslation & {
        initF7?(f7instance: Framework7): void;
        initEntryPageName?(pageName: string): void;
        session?: ISessionState;
        getLanguages?: () => void;
        handleResizeEvent: (width: number, height: number) => void;
        loadClassificators?: () => Promise<void>;
        loadCountryClassificator?: () => Promise<void>;
        authentificateAccount?: () => Promise<void>;
        initGeolocation?: () => Promise<void>;
        isMobile?: boolean;
    };

type State = {
    f7params: Framework7Params;
    f7Init?: boolean;
};

class F7App extends React.Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            f7params: null,
            f7Init: false,
        };
    }

    handleBackKeyDown(this: null) {
        if (f7.view.main.router.currentRoute.name !== 'HomePage' && f7.view.main.router.history.length > 2) {
            if (f7.$('.modal-in').length > 0) {
                f7.popup.close();
                return false;
            } else if (f7.$('.card-opened').length > 0) {
                f7.card.close('.card-expandable');
            } else {
                f7.view.main.router.back(undefined, { force: true, reloadCurrent: true });
            }
        }

        return true;
    }

    componentDidMount() {
        this.initF7Params();

        const handlef7ready: (f7: Framework7) => void = async (f7) => {
            // ? ~~~~~~~~~~~~~~~ F7 initialized ~~~~~~~~~~~~~~~

            this.toggleF7PreloaderSafe(this.props.preloader);

            f7.once('pageInit', this.handleOnPageInitOnce);
            f7.on('resize', this.handleOnResize);

            this.handleOnResize();

            this.props.initF7(f7);

            await this.props.loadCountryClassificator();
            await this.props.authentificateAccount();
            await this.props.initGeolocation();

            this.setState({
                f7Init: true,
            });
            messengerService.setPlatform(getPlatform());
            messengerService.hideLoadingScreen();
        };

        document.addEventListener('backbutton', this.handleBackKeyDown.bind(null), false);

        this.$f7ready(handlef7ready);
    }

    initF7Params() {
        let initParams = cloneDeep(f7params);
        const { t } = this.props;

        initParams.view.animate = false;
        initParams.view.iosSwipeBack = false;

        if (Device.desktop) {
            initParams.view.animate = false;
            initParams.theme = 'md'; // theme for desktop must be Material
            initParams.touch = { ...initParams.touch, mdTouchRipple: false };
        }

        // Check localStorage access
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            window.localStorage.length;
        } catch (err) {
            initParams.view.pushState = false;
        }

        initParams = {
            ...initParams,
            dialog: {
                buttonOk: t('OK'),
                buttonCancel: t('Cancel'),
            },
        };

        this.setState({ f7params: initParams });
    }

    handleOnPageInitOnce = (page: Router.Page) => {
        this.props.initEntryPageName(page.name);
        this.props.getLanguages();
    };

    _timeout: NodeJS.Timeout;

    handleOnResize = () => {
        /* TODO move to actions */
        clearTimeout(this._timeout);

        this._timeout = setTimeout(() => {
            clearTimeout(this._timeout);

            const { innerWidth, innerHeight } = window;

            this.props.handleResizeEvent(innerWidth, innerHeight);
        }, 350);
    };

    componentDidUpdate(prevProps: Props) {
        this.handlePreloader(prevProps);

        const { loading, restoring, profile, triedToLogin, logged } = this.props.session;

        this.toggleF7PreloaderSafe((loading && !logged) || restoring);

        if (
            triedToLogin !== prevProps.session.triedToLogin ||
            (triedToLogin && prevProps.session.profile?.uid !== profile?.uid)
        ) {
            this.props.loadClassificators();
        }
    }

    handlePreloader(prevProps: Props) {
        const { preloader } = this.props;
        if (preloader && (preloader !== prevProps.preloader || typeof prevProps.preloader === 'undefined')) {
            this.toggleF7PreloaderSafe(preloader);
        }
    }

    toggleF7PreloaderSafe(show = true) {
        this.$f7ready((f7) => (show ? f7.preloader.show() : f7.preloader.hide()));
    }

    render() {
        const { f7params, f7Init } = this.state;
        return (
            f7params && (
                <App
                    params={f7params}
                    className={cn({
                        'built-in-version': messengerService.enabled,
                    })}
                >
                    {f7Init && (
                        <>
                            <View className={cn('safe-areas')} main url="/" />
                            <SelectCustomerLocationContainer />
                            <div className={cn('custom-modal-backdrop', TRANSPARENT_BACKDROP_SELECTOR)} />
                            <AuthManager />
                            <RouterManager />
                            <FocusScrollingManager />
                            <ReloadCategoriesOnLanguageChange />
                            <ReloadAllProductsOnLanguageChange />
                            <ReloadClassificatorsOnLanguageChange />
                            <MSAlert />
                        </>
                    )}
                </App>
            )
        );
    }
}

const mapStateToProps = (state: IApplicationStore) => ({
    session: state.sessionReducer,
    isMobile: getIsMobile(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    initF7: (f7: Framework7) => dispatch({ type: F7_INIT, f7 }),
    initEntryPageName: (pageName: string) => dispatch({ type: INIT_ENTRY_PAGE_NAME, pageName }),
    getLanguages: () => dispatch(getLanguages()),
    handleResizeEvent: (width: number, height: number) => dispatch(handleResizeEvent(width, height)),
    loadClassificators: () => {
        // Entities classificators
        LOADING_CLASSIFICATOR_TYPES.forEach((type) => void dispatch(loadClassificator(type)));
        // Claim Subject classificators
        dispatch(loadClaimSubjectsClassificator(LOADING_SUBJECT_CLASSIFICATOR_TYPES));
        // Single classificators
        dispatch(loadCurrencyClassificator());
        dispatch(loadCountryClassificator());
        dispatch(loadCategories());
    },
    loadCountryClassificator: () => dispatch(loadCountryClassificator()),
    authentificateAccount: () => dispatch(authentificateAccount()),
    initGeolocation: () => dispatch(initGeolocation()),
});

export default compose<React.ComponentClass<Pick<Props, 'preloader'>>>(
    withTranslation(),
    connectPreloader,
    connect(mapStateToProps, mapDispatchToProps),
)(F7App);

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { TariffWsControllerApi, Wallet, WalletTotalBalance, WalletWsControllerApi } from '@/generated/commonapi';
import { AppDispatch } from '@/hooks/store';

export const MY_WALLETS_LIST_LOADING = 'MY_WALLETS_LIST_LOADING' as const;
export const MY_WALLETS_LIST_SUCCESS = 'MY_WALLETS_LIST_SUCCESS' as const;
export const MY_WALLETS_LIST_ERROR = 'MY_WALLETS_LIST_ERROR' as const;

export const MY_WALLETS_RESET_ERRORS = 'MY_WALLETS_RESET_ERRORS' as const;

const myWalletsListLoadingAction = () => ({
    type: MY_WALLETS_LIST_LOADING,
});

const myWalletsListSuccessAction = (wallets: Wallet[], totalBalance: WalletTotalBalance | undefined) => ({
    type: MY_WALLETS_LIST_SUCCESS,
    wallets,
    totalBalance,
});

const myWalletsListErrorAction = (error: unknown) => ({
    type: MY_WALLETS_LIST_ERROR,
    error,
});

export const resetMyWalletsErrors = () => ({
    type: MY_WALLETS_RESET_ERRORS,
});

export type MyWalletsActions = ReturnType<
    | typeof myWalletsListLoadingAction
    | typeof myWalletsListSuccessAction
    | typeof myWalletsListErrorAction
    | typeof resetMyWalletsErrors
>;

export const loadMyWallets = () => async (dispatch: AppDispatch) => {
    dispatch(myWalletsListLoadingAction());
    try {
        const res = await new WalletWsControllerApi().getAccountWalletUsingPOST({});
        handleResponseAndThrowAnErrorIfExists(res);
        dispatch(myWalletsListSuccessAction(res.walletList, res.totalBalance));
    } catch (err) {
        console.error('at myWalletsActions in loadMyWallets', err);

        dispatch(myWalletsListErrorAction(err.message));
    }
};

export const addWallet = (currencyCode: string) => async (dispatch: AppDispatch) => {
    dispatch(myWalletsListLoadingAction());
    try {
        const res = await new WalletWsControllerApi().createWalletUsingPOST({
            currencyCode,
        });
        handleResponseAndThrowAnErrorIfExists(res);

        const items = res.walletList || [];
        dispatch(myWalletsListSuccessAction(items, res.totalBalance));
    } catch (err) {
        console.error('at myWalletsActions in addWallet', err);

        dispatch(myWalletsListErrorAction(err.message));
    }
};

export const setWalletAsPrimary = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(myWalletsListLoadingAction());
    try {
        const res = await new WalletWsControllerApi().setWalletAsPrimaryUsingPOST({
            uid,
        });
        handleResponseAndThrowAnErrorIfExists(res);

        const items = res.walletList || [];
        dispatch(myWalletsListSuccessAction(items, res.totalBalance));
    } catch (err) {
        console.error('at myWalletsActions in setWalletAsPrimary', err);

        dispatch(myWalletsListErrorAction(err.message));
    }
};

export const disableWallet = (uid: string) => async (dispatch: AppDispatch) => {
    dispatch(myWalletsListLoadingAction());
    try {
        const res = await new WalletWsControllerApi().disableWalletUsingPOST({
            uid,
        });
        handleResponseAndThrowAnErrorIfExists(res);
        dispatch(myWalletsListSuccessAction(res.walletList, res.totalBalance));
    } catch (err) {
        console.error('at myWalletsActions in disableWallet', err);

        dispatch(myWalletsListErrorAction(err.message));
    }
};

// ? will be used to get replenishment tarrif
export const findOutReplenishTarrif = (amount: number, currencyCode: string, cardType: string) => async () => {
    const response = await new TariffWsControllerApi().getBrandedCardTariffUsingPOST({
        amount,
        currencyCode,
        type: cardType,
    });
    console.warn(response);
};

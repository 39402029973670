export enum OnbordingMessageType {
    ADD_PRODUCTS = 'ADD_PRODUCTS',
    EMAIL_VERIFIED = 'EMAIL_VERIFIED',
    DELIVERY = 'DELIVERY',
    FAVORITE_STORE = 'FAVORITE_STORE',
    FIRST_PRODUCT = 'FIRST_PRODUCT',
    PRODUCTS = 'PRODUCTS',
    SALE = 'SALE',
    STORE = 'STORE',
    STORE_HOMEPAGE = 'STORE_HOMEPAGE',
}

export type OnbordingMessageProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    type: OnbordingMessageType;
    onClose?: () => void;
    triangle?: 'left' | 'center' | 'right';
    visibleInitially?: boolean;
};

import { client, marketplaceapiURL } from '@/axios';

export enum Buckets {
    review = 'review',
    store = 'store',
    product = 'product',
}

export const upload2bucket = (bucket: Buckets) => async (file: File) => {
    const formData = new FormData();
    formData.append('bucket', bucket);
    formData.append('file', file);
    const result = await client.post(`${marketplaceapiURL}/file/upload`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
    return result?.data?.body[0]?.url;
};

const YEARS_AHEAD = 20;

export function normalizeYear(year: number) {
    // Century fix
    if (year < 100) {
        const nowYear = new Date().getFullYear();
        year += Math.floor(nowYear / 100) * 100;
        if (year > nowYear + YEARS_AHEAD) {
            year -= 100;
        } else if (year <= nowYear - 100 + YEARS_AHEAD) {
            year += 100;
        }
    }
    return year;
}

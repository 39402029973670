const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 50;
const STRONG_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\-_])[a-zA-Z0-9!@#$%^&*\-_]+$/;

export const isValidPassword = (password = '') => {
    const isOutOfRange = password.length > MAX_PASSWORD_LENGTH || password.length < MIN_PASSWORD_LENGTH;
    const passwordValid = STRONG_PASSWORD_REGEX.test(password);

    return !isOutOfRange && passwordValid;
};

import { Checkbox, TreeviewItem } from 'framework7-react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { SubCategoryItemProps } from './SubCategoryItem.types';

import { getCategoryTranslateKey } from '@/utils';

export const SubCategoryItem: React.FC<SubCategoryItemProps> = ({
    category,
    onCheckboxClick,
    selectedSubcategories,
    allowedCategories,
}): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            {allowedCategories.has(category) &&
                (category?.children && category.children.length !== 0 ? (
                    <TreeviewItem label={t(getCategoryTranslateKey(category))} itemToggle>
                        <Checkbox
                            slot="content-start"
                            onChange={onCheckboxClick(category)}
                            checked={selectedSubcategories.some((item) => item.categoryCode === category.categoryCode)}
                        />
                        {category.children?.map((child) => (
                            <SubCategoryItem
                                key={child.categoryCode}
                                category={child}
                                onCheckboxClick={onCheckboxClick}
                                selectedSubcategories={selectedSubcategories}
                                allowedCategories={allowedCategories}
                            />
                        ))}
                    </TreeviewItem>
                ) : (
                    <label>
                        <TreeviewItem label={t(getCategoryTranslateKey(category))} selectable>
                            <Checkbox
                                slot="content-start"
                                onChange={onCheckboxClick(category)}
                                checked={selectedSubcategories.some(
                                    (item) => item.categoryCode === category.categoryCode,
                                )}
                            />
                        </TreeviewItem>
                    </label>
                ))}
        </>
    );
};

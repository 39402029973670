import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import React, { Fragment } from 'react';

import { noop } from '@/utils';

import './Popup.less';

export interface IMSPopupProps {
    opened: boolean;
    className?: string;
    children?: React.ReactNode;
    fullScreen?: boolean;
    onPopupClose?: () => void;
    onPopupClosed?: () => void;
    onPopupOpen?: () => void;
    onPopupOpened?: () => void;
    onBackdropClick?: () => void;
}

export const MSPopup: React.FC<IMSPopupProps> = React.memo(
    ({
        opened,
        className,
        children,
        fullScreen = false,
        onPopupOpen,
        onPopupOpened,
        onPopupClose,
        onPopupClosed,
        onBackdropClick,
    }) => {
        return (
            <Transition show={opened} as={Dialog} onClose={noop}>
                <Transition.Child
                    enter="fade-in"
                    enterFrom="fade-in-from"
                    enterTo="fade-in-to"
                    leave="fade-in"
                    leaveFrom="fade-in-to"
                    leaveTo="fade-in-from"
                    as={Fragment}
                >
                    <div className="ms-popup-overlay" onClick={onBackdropClick} />
                </Transition.Child>
                <Transition.Child
                    enter="slide-in"
                    enterFrom="slide-in-from"
                    enterTo="slide-in-to"
                    leave="slide-in"
                    leaveFrom="slide-in-to"
                    leaveTo="slide-in-from"
                    beforeEnter={onPopupOpen}
                    afterEnter={onPopupOpened}
                    beforeLeave={onPopupClose}
                    afterLeave={onPopupClosed}
                    as={Fragment}
                >
                    <div className={cn('ms-popup', className, { fullscreen: fullScreen })}>{children}</div>
                </Transition.Child>
            </Transition>
        );
    },
);

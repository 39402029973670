import { IAlertState } from '@/reducers/alertReducer';

export const SET_ALERT = 'SET_ALERT' as const;
export const RESET_ALERT = 'RESET_ALERT' as const;

export const setAlertAction = (data: IAlertState) => ({
    type: SET_ALERT,
    data,
});

export const resetAlertAction = () => ({
    type: RESET_ALERT,
});

export type AlertActions = ReturnType<typeof setAlertAction | typeof resetAlertAction>;

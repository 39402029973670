import { F7Sheet } from 'framework7-react';

import { ISearchParams } from '@/actions/productActions';
import { MapPopupProps } from '@/components/MapPopup/MapPopup.types';
import { SortByButtonItem } from '@/components/SortByButtonsGroup/SortByButtonsGroup.types';
import { Sale } from '@/generated/marketplaceapi';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { SortBy } from '@/types/sort';

export type CategorySheetProps = Omit<F7Sheet.Props, 'onSheetOpen' | 'onSheetClose'> &
    Partial<Pick<MapPopupProps, 'coordinates' | 'onLocationSelect'>> & {
        onCategoryClick?: (category: ICategoryClassificator | undefined) => void;
        everyLevelMultyselectable?: boolean;
        withReturnToParentButton?: boolean;
        withoutSearch?: boolean;
        allowedCategories?: Set<ICategoryClassificator> | Map<ICategoryClassificator, number>;
        selectedCategories?: ICategoryClassificator[];
        onSelectCategoriesSubmit?: (categories: ICategoryClassificator[], sales: Sale[] | undefined) => void;
        defaultTitle?: string;
        description?: string;
        everyLevelSelectable?: boolean;
        deepestOnlySelectable?: boolean;
        withIcons?: boolean;
        initialOpenedCategory?: ICategoryClassificator;
        saleList?: Sale[];
        selectedSales?: Sale[];
        fullscreen?: boolean;
        withSort?: boolean;
        selectedSortOptions?: SortBy[];
        onSortOptionClick?: (sortBy: SortBy) => void;
        sortByArr?: SortByButtonItem[][];
        hideCategoriesInSeparatedMenu?: boolean;
        withTotalCount?: boolean;
        searchParams?: ISearchParams;
        startFromRootCategory?: boolean;
        withLocation?: boolean;
        boldGroupTitles?: boolean;
        showAll?: boolean;
        skipRoot?: boolean;
    };

export enum CategorySheetContentView {
    ALL = 'ALL',
    CATEGORIES = 'CATEGORIES',
}

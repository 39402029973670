import cn from 'classnames';
import React from 'react';

import type { ZoomerProps } from './Zoomer.types';

import './Zoomer.less';

export const Zoomer = ({ src, children, zoom = true }: ZoomerProps): JSX.Element => {
    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!zoom) {
            return;
        }
        const zoomer = e.currentTarget;
        const { x, y } = zoomer.getBoundingClientRect();
        const xPos = ((e.nativeEvent.clientX - x) / zoomer.offsetWidth) * 100;
        const yPos = ((e.nativeEvent.clientY - y) / zoomer.offsetHeight) * 100;
        zoomer.style.backgroundPosition = `${xPos}% ${yPos}%`;
    };

    return (
        <div
            className={cn('zoomer', { zoomer_active: zoom })}
            onMouseMove={onMouseMove}
            style={{ backgroundImage: `url(${src})` }}
        >
            <div className="zoomer__wrap">{children}</div>
        </div>
    );
};

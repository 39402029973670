import cn from 'classnames';
import { Link } from 'framework7-react';
import React, { useMemo } from 'react';

import { CartLinkProps } from './CartLink.types';

import { IcCart } from '@/assets';
import { Badge } from '@/components/Badge';
import { useAppSelector } from '@/hooks/store';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

import './CartLink.less';

export const CartLink = ({ className, cartCount, ...props }: CartLinkProps) => {
    const activeProductType = useAppSelector(getActiveProductType);

    const shownCount = useMemo(() => (cartCount < 100 ? `${cartCount}` : `99+`), [cartCount]);

    return (
        <Link
            href={`/cart/?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(activeProductType)}`}
            {...props}
            className={cn('cart-link', className, { empty: !cartCount })}
        >
            <IcCart />
            {!!cartCount && <Badge>{shownCount}</Badge>}
        </Link>
    );
};

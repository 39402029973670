import cn from 'classnames';
import { Button, Link, Navbar, NavLeft, NavRight, NavTitle, Sheet } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MyGoodsNavbarProps } from './MyGoodsNavbar.types';

import { IcClose, IcDelete, IcEdit, IcMore, IcSearch } from '@/assets';
import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { FilterButton } from '@/components/FilterButton';
import { SellerAreaBackLink } from '@/components/SellerAreaBackLink';
import { SellerAreaSearchbar } from '@/components/SellerAreaSearchbar';
import { ThemedButton } from '@/components/ThemedButton';
import { Product } from '@/generated/marketplaceapi';
import { useAppSelector } from '@/hooks/store';
import { ActionsModalFacade } from '@/pages/MyGoodsPage/ActionsModalFacade';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getIsMobile } from '@/selectors/getIsMobile';
import { ProductType } from '@/types/productType';
import { Sheets } from '@/types/sheets';

import './MyGoodsNavbar.less';

export const MyGoodsNavbar: React.FC<MyGoodsNavbarProps> = ({
    archive,
    handleArchivateAllClick,
    handleDeleteFromArchive,
    hasProducts,
    handleClearArchive,
    handleRestoreProductsClick,
    openQtyPopup,
    selectedProduct,
    selectedProducts,
    selectedProductsStatuses,
    editMode,
    filterButtonProps,
    clearSearchbar,
    enableEdit,
    disableEdit,
    onSearchbarChange,
}) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);
    const activeType = useAppSelector(getActiveProductType);

    const title = useMemo(
        () =>
            isMobile
                ? selectedProducts.size
                    ? `${t('Selected')} (${selectedProducts.size})`
                    : archive
                    ? t('Archive')
                    : activeType === ProductType.Advertisement
                    ? t('page.my_offers.menu.button.offers')
                    : activeType === ProductType.Service
                    ? t('services.seller_area.menu.button.my_services')
                    : t('Products')
                : activeType === ProductType.Advertisement
                ? t('page.my_offers.title.my_offers')
                : activeType === ProductType.Service
                ? t('services.seller_area.menu.button.my_services')
                : t('Seller Area'),
        [activeType, archive, isMobile, selectedProducts.size, t],
    );

    const desktopNavbarButtons = useMemo(() => {
        switch (true) {
            case selectedProducts.size && !archive:
                return (
                    <NavRight>
                        <Link onClick={handleArchivateAllClick}>
                            <IcDelete />
                        </Link>
                        {selectedProduct && (
                            <ActionsModalFacade
                                renderTriggerButton={(triggerModal) => (
                                    <Link onClick={triggerModal}>
                                        <IcMore />
                                    </Link>
                                )}
                                selectedProduct={selectedProduct}
                                onChangeQuantityClick={openQtyPopup}
                                selectedStatuses={selectedProductsStatuses}
                                selectedProductsSet={selectedProducts}
                            />
                        )}
                    </NavRight>
                );
            case !archive:
                return (
                    <NavRight>
                        {/* {profile.type === 'B' && (
                            <ThemedButton
                                className="import-button"
                                fill
                                round
                                href="./import-products/"
                            >
                                {t('Import')}
                            </ThemedButton>
                        )} */}
                        {hasProducts && (
                            <ThemedButton fill round href="add/">
                                {activeType === ProductType.Advertisement
                                    ? t('page.my_offers.button.add_offer')
                                    : activeType === ProductType.Service
                                    ? t('services.page.header.button.add_service')
                                    : t('Add Product')}
                            </ThemedButton>
                        )}
                    </NavRight>
                );
            case archive && hasProducts && !selectedProducts.size:
                return (
                    <NavRight>
                        <ActionsModalFacade
                            renderTriggerButton={(triggerModal, popoverOpened) => (
                                <Link
                                    onClick={triggerModal}
                                    className={cn({
                                        'more-button-popover-opened': popoverOpened,
                                    })}
                                >
                                    <IcMore />
                                </Link>
                            )}
                            onClearArchive={handleClearArchive}
                            forArchive
                            selectedProduct={selectedProduct}
                            onChangeQuantityClick={openQtyPopup}
                            selectedStatuses={selectedProductsStatuses}
                            selectedProductsSet={selectedProducts}
                        />
                    </NavRight>
                );
            case archive && hasProducts:
                return (
                    <NavRight>
                        <Link onClick={handleDeleteFromArchive}>
                            <IcDelete />
                        </Link>
                        <ThemedButton className="import-button" fill round onClick={handleRestoreProductsClick}>
                            {t('Restore')}
                        </ThemedButton>
                    </NavRight>
                );
            default:
                return null;
        }
    }, [
        activeType,
        archive,
        handleArchivateAllClick,
        handleClearArchive,
        handleDeleteFromArchive,
        handleRestoreProductsClick,
        hasProducts,
        openQtyPopup,
        selectedProduct,
        selectedProducts,
        selectedProductsStatuses,
        t,
    ]);

    const handleMobileEditClick = useCallback(() => {
        enableEdit();
    }, [enableEdit]);

    const mobileNavbarButtons = useMemo(() => {
        switch (true) {
            case !hasProducts:
                return null;
            case !editMode && !archive:
                return (
                    <>
                        <NavLeft>
                            <SellerAreaBackLink force clearPreviousHistory />
                        </NavLeft>
                        <NavRight>
                            <Link searchbarEnable=".my-goods-page__searchbar" iconOnly>
                                <IcSearch className="navbar-link-icon" />
                            </Link>
                            <FilterButton {...filterButtonProps} className="my-goods-page__filter-button" isMobile />
                            <Link iconOnly onClick={enableEdit}>
                                <IcEdit />
                            </Link>
                        </NavRight>
                        <SellerAreaSearchbar
                            className="my-goods-page__searchbar"
                            expandable
                            onSearchbarSearch={onSearchbarChange}
                            onSearchbarClear={clearSearchbar}
                        />
                    </>
                );
            case editMode && !archive:
                return (
                    <>
                        <NavLeft>
                            <Link onClick={disableEdit} className="navbar-mobile__icon-button">
                                <IcClose />
                            </Link>
                        </NavLeft>
                        {!!selectedProducts.size && <NavTitle>{title}</NavTitle>}
                        <NavRight>
                            <Button
                                className="navbar-mobile__icon-button"
                                disabled={!selectedProducts.size}
                                onClick={handleArchivateAllClick}
                            >
                                <IcDelete />
                            </Button>

                            <ActionsModalFacade
                                renderTriggerButton={(triggerModal) => (
                                    <Button
                                        className="navbar-mobile__icon-button"
                                        disabled={!selectedProduct}
                                        onClick={triggerModal}
                                    >
                                        <IcMore />
                                    </Button>
                                )}
                                selectedProduct={selectedProduct}
                                selectedStatuses={selectedProductsStatuses}
                                selectedProductsSet={selectedProducts}
                                onChangeQuantityClick={openQtyPopup}
                            />
                        </NavRight>
                    </>
                );
            // ! do not try conditional reder with editMode here
            case archive && hasProducts:
                return (
                    <>
                        <NavLeft className="archive-edit-mode">
                            <Link className="navbar-mobile__icon-button" onClick={disableEdit}>
                                <IcClose />
                            </Link>
                        </NavLeft>

                        {!!selectedProducts.size && <NavTitle className="archive-edit-mode">{title}</NavTitle>}

                        <NavLeft className="archive-mode">
                            <AdaptiveBackLink back={false} mobileLink="/profile/seller-area/my-goods/" desktopLink="" />
                        </NavLeft>

                        {!selectedProducts.size && <NavTitle className="archive-edit-mode">{t('Archive')}</NavTitle>}

                        <NavRight>
                            <Button
                                className={cn('navbar-mobile__icon-button', 'archive-edit-mode')}
                                disabled={!selectedProducts.size}
                                onClick={handleDeleteFromArchive}
                            >
                                <IcDelete />
                            </Button>

                            <ActionsModalFacade
                                renderTriggerButton={(triggerModal) => (
                                    <Button
                                        className={cn('navbar-mobile__icon-button', 'archive-mode')}
                                        disabled={selectedProduct?.status === Product.StatusEnum.DLT}
                                        onClick={triggerModal}
                                    >
                                        <IcMore />
                                    </Button>
                                )}
                                selectedProduct={selectedProduct}
                                selectedStatuses={selectedProductsStatuses}
                                selectedProductsSet={selectedProducts}
                                forArchive
                                onClearArchive={handleClearArchive}
                                onEditArchiveClick={handleMobileEditClick}
                                onChangeQuantityClick={openQtyPopup}
                            />

                            <Button
                                className={cn('navbar-mobile__icon-button', 'archive-edit-mode')}
                                disabled={!selectedProducts.size}
                                sheetOpen={`.${Sheets.RESTORE_SHEET}`}
                            >
                                <IcMore />
                                <Sheet
                                    className={cn(Sheets.RESTORE_SHEET, 'my-goods-navbar-restore-sheet')}
                                    backdropEl={`.sheet-backdrop`}
                                    backdrop
                                >
                                    <Link sheetClose>
                                        <IcClose />
                                    </Link>

                                    <div className="restore-item" onClick={handleRestoreProductsClick}>
                                        {t('Restore')}
                                    </div>
                                </Sheet>
                            </Button>
                        </NavRight>
                    </>
                );

            default:
                return null;
        }
    }, [
        archive,
        clearSearchbar,
        disableEdit,
        editMode,
        enableEdit,
        filterButtonProps,
        handleArchivateAllClick,
        handleClearArchive,
        handleDeleteFromArchive,
        handleMobileEditClick,
        handleRestoreProductsClick,
        hasProducts,
        onSearchbarChange,
        openQtyPopup,
        selectedProduct,
        selectedProducts,
        selectedProductsStatuses,
        t,
        title,
    ]);

    const navButtons = isMobile ? mobileNavbarButtons : desktopNavbarButtons;

    return (
        <Navbar className={cn('my-goods-page__navbar', { 'archive-edit': archive && editMode })} noHairline noShadow>
            {(!isMobile || !hasProducts) && (
                <>
                    <NavLeft>
                        <SellerAreaBackLink clearPreviousHistory force />
                    </NavLeft>
                    <NavTitle>{title}</NavTitle>
                </>
            )}
            {navButtons}
        </Navbar>
    );
};

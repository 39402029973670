import cn from 'classnames';
import { Badge } from 'framework7-react';
import React from 'react';

import { SaleBadgeProps } from './SaleBadge.types';

import './SaleBadge.less';

export const SaleBadge: React.FC<SaleBadgeProps> = ({ label, className, ...props }) => {
    return (
        <Badge className={cn(className, 'sale-badge')} {...props}>
            {label}
        </Badge>
    );
};

import { useEffect } from 'react';

import { loadMarketingBanners } from '@/actions/bannersActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const LoadMarketingBanners = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(getLanguageCode);

    useEffect(() => {
        dispatch(loadMarketingBanners());
    }, [dispatch, language]);

    return null;
};

export enum MascotType {
    'SUCCESS_PAYMENT' = 'IcSuccessPayment',
    'FAILED_PAYMENT' = 'IcFailedPayment',
    'NO_RESULTS' = 'IcNoResults',
    'SHOP' = 'IcShop',
}

export type MascotIconProps = {
    type: MascotType;
};

import { Profile } from '@/reducers/sessionReducer';
import { AccountStatus } from '@/types/account';

export const isServiceProviderActive = (profile: Profile) => {
    return (
        profile.serviceProviders?.length > 0 &&
        [AccountStatus.BC, AccountStatus.BF, AccountStatus.SF, AccountStatus.SC].includes(
            profile.status as AccountStatus,
        )
    );
};

import { CustomSelectValue } from '../CustomSelect';

const requiredFields = ['companyType', 'taxPayerIn', 'registrationNumber', 'storeName', 'storeDescription'] as const;

export type RegistrationBusinessPopupProps = {
    opened: boolean;
    openLogin?: () => void;
};

export type RegistrationBusinessFields = (typeof requiredFields)[number];

export type RegistrationBusinessFormValues = {
    email?: string;
    password?: string;
    passwordRepeat?: string;
    firstName?: string;
    lastName?: string;
    accept?: boolean;
    country?: CustomSelectValue;
    companyName?: string;
    companyType?: CustomSelectValue;
    taxPayerIn?: string;
    registrationNumber?: string;
    storeName?: string;
    storeDescription?: string;
};

export type RegistrationBusinessFormErrors = Partial<Record<keyof RegistrationBusinessFormValues, string | undefined>>;

export enum RegistrationBusinessFormStep {
    AUTH,
    GENERAL,
}

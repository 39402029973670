import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from './store';

import { addToFavorite, removeFromFavorite } from '@/actions/favoritesActions';
import { isStoreInFavorites } from '@/selectors/isStoreInFavorites';

export const useAddStoreToFavorites = (storeUid: string) => {
    const dispatch = useAppDispatch();

    const isAdded = useAppSelector((state) => isStoreInFavorites(storeUid, state));

    const [added, setAdded] = useState(isAdded);

    useEffect(() => {
        setAdded(isAdded);
    }, [isAdded]);

    const fetchAddToFavorite = useMemo(
        () =>
            debounce(
                (dispatch, storeUid: string, add: boolean) =>
                    add ? dispatch(addToFavorite(storeUid)) : dispatch(removeFromFavorite(storeUid)),
                1000,
            ),
        [],
    );

    const onClick = useCallback(() => {
        setAdded((prev) => {
            fetchAddToFavorite(dispatch, storeUid, !prev);
            return !prev;
        });
    }, [dispatch, fetchAddToFavorite, storeUid]);

    return { added, onClick, fetchAddToFavorite };
};

import { OnDatesChangeProps, useDatepicker } from '@datepicker-react/hooks';
import cn from 'classnames';
import { F7Popup, F7Sheet, Link, Navbar, NavRight, NavTitle, Popup, Sheet } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Calendar } from './Calendar';

import { CalendarControls } from './CalendarControls';

import { Menu } from './Menu';

import { PickerProps } from './Picker.types';

import { IcClose } from '@/assets';
import { CustomPopover } from '@/components/CustomPopover';
import DateRangePickerContext from '@/components/DatePickers/DateRangePicker/DateRangePicker.context';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';
import { stopPropagation } from '@/utils';

import './Picker.less';

export const Picker = ({
    startDateState,
    endDateState,
    focus,
    setFocus,
    minPeriod,
    className,
    isMobile,
    onSheetClose,
    singleDate,
    pickerTitle,
    minBookingDate,
    maxBookingDate,
    initialVisibleMonth,
    component = 'popover',
    onApply,
    applyText,
    visibleMonthForce,
    withoutApplyButton,
    moreContent,
    disableApplyButton,
    ...props
}: PickerProps): JSX.Element => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = startDateState;
    const [endDate, setEndDate] = endDateState;

    const handleDatesChange = useCallback(
        (data: OnDatesChangeProps) => {
            setStartDate(data.startDate);
            setEndDate(data.endDate);
            if (!singleDate) {
                setFocus(data.focusedInput ?? 'startDate');
            }
        },
        [setEndDate, setFocus, setStartDate, singleDate],
    );

    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isStartDate,
        isEndDate,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        goToDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths,
    } = useDatepicker({
        startDate,
        endDate,
        focusedInput: focus,
        onDatesChange: handleDatesChange,
        minBookingDays: minPeriod ?? 1,
        exactMinBookingDays: !!singleDate,
        firstDayOfWeek: 1,
        numberOfMonths: visibleMonthForce ?? isMobile ? 1 : 2,
        changeActiveMonthOnSelect: !singleDate,
        minBookingDate,
        maxBookingDate,
        initialVisibleMonth: initialVisibleMonth ? new Date(initialVisibleMonth) : undefined,
    });
    const [Component, componentSelector] = useMemo<
        | [React.ComponentClass<F7Sheet.Props | F7Popup.Props>, Sheets | Popups]
        | [typeof CustomPopover, 'datepicker-popover']
    >(() => {
        switch (component) {
            case 'popover':
                return [CustomPopover, 'datepicker-popover'];
            case 'sheet':
                return [Sheet, Sheets.DATEPICKER_SHEET];
            case 'popup':
                return [Popup, Popups.DATEPICKER_POPUP];
            default:
                return [CustomPopover, 'datepicker-popover'];
        }
    }, [component]);

    const handleUpdateInitialDate = useCallback(() => goToDate(initialVisibleMonth), [goToDate, initialVisibleMonth]);

    return (
        <DateRangePickerContext.Provider
            value={{
                focusedDate,
                isDateFocused,
                isDateSelected,
                isDateHovered,
                isDateBlocked,
                isStartDate,
                isEndDate,
                isFirstOrLastSelectedDate,
                onDateSelect,
                onDateFocus,
                onDateHover,
                firstDayOfWeek,
            }}
        >
            <Component
                id={componentSelector}
                className={cn(componentSelector, 'datepicker-wrapper', className, {
                    'datepicker-mobile': isMobile,
                })}
                onSheetClose={onSheetClose}
                onSheetOpen={handleUpdateInitialDate}
                backdrop
                {...props}
            >
                {isMobile && (
                    <Navbar noHairline noShadow className="datepicker-mobile-navbar">
                        <NavTitle>{t(pickerTitle ?? 'Date')}</NavTitle>
                        <NavRight>
                            <Link popupClose={component === 'popup'} sheetClose={component === 'sheet'}>
                                <IcClose />
                            </Link>
                        </NavRight>
                    </Navbar>
                )}
                {moreContent}
                <Menu
                    handleLeftButtonClick={(e) => {
                        stopPropagation(e);
                        goToPreviousMonths();
                    }}
                    handleRightButtonClick={(e) => {
                        stopPropagation(e);
                        goToNextMonths();
                    }}
                />
                <Calendar closeModalOnDayClick={withoutApplyButton} firstDayOfWeek={firstDayOfWeek}>
                    {activeMonths}
                </Calendar>
                {!withoutApplyButton && (
                    <CalendarControls
                        startDateCb={setStartDate}
                        endDateCb={setEndDate}
                        focusCb={setFocus}
                        isMobile={isMobile}
                        onApply={onApply}
                        applyText={applyText}
                        componentType={component}
                        disableApplyButton={disableApplyButton}
                    />
                )}
            </Component>
        </DateRangePickerContext.Provider>
    );
};

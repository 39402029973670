import { Button } from 'framework7-react';
import React, { useCallback } from 'react';

import { ShareAndWishButtonsProps } from './ShareAndWishButtons.types';

import { IcShareLarge } from '@/assets';
import { LikeButton } from '@/components/LikeButton';
import { ShareMenu } from '@/components/ShareMenu';

import './ShareAndWishButtons.less';

export const ShareAndWishButtons = ({ product, addToWish, addedInWish }: ShareAndWishButtonsProps) => {
    const renderShareButton = useCallback(
        (handler: () => void) => (
            <Button round onClick={handler} className="product-details-share-and-wish__share">
                <IcShareLarge />
            </Button>
        ),
        [],
    );

    return (
        <div className="product-details-share-and-wish">
            <LikeButton checked={addedInWish} onChange={addToWish} />
            <ShareMenu productUid={product.uid} analyticsShareProduct={product} renderButton={renderShareButton} />
        </div>
    );
};

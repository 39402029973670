import { getQueryParameterFromURL } from './url/getQueryParameterFromURL';

import { updateQueryParameterFromURL } from './url/updateQueryParameterFromURL';

import { EProductTypeQuery, PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';

export const keepCurrentProductType = (newUrl: string) => {
    const currentUrl = window.location.href.split('#')[1];

    if (!currentUrl) {
        return newUrl;
    }

    const currentTypeQuery = getQueryParameterFromURL(PRODUCT_TYPE_QUERY_NAME, currentUrl) as EProductTypeQuery;
    const newTypeQuery = getQueryParameterFromURL(PRODUCT_TYPE_QUERY_NAME, newUrl) as EProductTypeQuery;

    if (!currentTypeQuery || newTypeQuery) {
        return newUrl;
    }

    return updateQueryParameterFromURL(newUrl, PRODUCT_TYPE_QUERY_NAME, currentTypeQuery);
};

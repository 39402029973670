import { MyGoodsSortBy } from './StatusFiltersMobilePopup.types';

import { Sorting } from '@/generated/marketplaceapi';

const getFieldNameBySortBy = (sortBy: MyGoodsSortBy) => {
    switch (sortBy) {
        case MyGoodsSortBy.price_high_to_low:
            return 'price';
        case MyGoodsSortBy.price_low_to_high:
            return 'price';
        case MyGoodsSortBy.what_s_new:
            return 'what_s_new';
        default:
            return undefined;
    }
};

export const getSortByFields = (sortBy: MyGoodsSortBy): Sorting => {
    return {
        direction: sortBy === MyGoodsSortBy.price_high_to_low ? 'DESC' : 'ASC',
        field: getFieldNameBySortBy(sortBy),
        sortingIndex: sortBy === MyGoodsSortBy.price_high_to_low || sortBy === MyGoodsSortBy.price_low_to_high ? 1 : 0,
    };
};

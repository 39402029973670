import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { AccountPayoutSettings, AccountPayoutWsControllerApi } from '@/generated/commonapi';
import { ClassificatorControllerApiFactory } from '@/generated/marketplaceapi';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';
import { getRegionCode } from '@/selectors/getRegionCode';

export const PAYOUT_SETTINGS_LOADING = 'PAYOUT_SETTINGS_LOADING' as const;
export const PAYOUT_SETTINGS_LOADING_SUCCESS = 'PAYOUT_SETTINGS_LOADING_SUCCESS' as const;
export const PAYOUT_SETTINGS_UPDATING = 'PAYOUT_SETTINGS_UPDATING' as const;
export const PAYOUT_SETTINGS_UPDATE_SUCCESS = 'PAYOUT_SETTINGS_UPDATE_SUCCESS' as const;
export const PAYOUT_SETTINGS_LOADING_ERROR = 'PAYOUT_SETTINGS_LOADING_ERROR' as const;
export const PAYOUT_SETTINGS_REMOVING = 'PAYOUT_SETTINGS_REMOVING' as const;
export const PAYOUT_SETTINGS_REMOVE_SUCCESS = 'PAYOUT_SETTINGS_REMOVE_SUCCESS' as const;
export const PAYOUT_BANK_MESSAGE_LOADING = 'PAYOUT_BANK_MESSAGE_LOADING' as const;
export const PAYOUT_BANK_MESSAGE_LOADING_SUCCESS = 'PAYOUT_BANK_MESSAGE_LOADING_SUCCESS' as const;
export const PAYOUT_BANK_MESSAGE_LOADING_ERROR = 'PAYOUT_BANK_MESSAGE_LOADING_ERROR' as const;

const payoutSettingsLoadingAction = () => ({
    type: PAYOUT_SETTINGS_LOADING,
});

const payoutSettingsUpdatingAction = () => ({
    type: PAYOUT_SETTINGS_UPDATING,
});

const payoutSettingsLoadingSuccessAction = (settings: AccountPayoutSettings) => ({
    type: PAYOUT_SETTINGS_LOADING_SUCCESS,
    settings,
});

const payoutSettingsUpdateSuccessAction = (settings: AccountPayoutSettings) => ({
    type: PAYOUT_SETTINGS_UPDATE_SUCCESS,
    settings,
});

const payoutSettingsLoadingErrorAction = (error: unknown) => ({
    type: PAYOUT_SETTINGS_LOADING_ERROR,
    error,
});

const payoutSettingsRemovingAction = () => ({
    type: PAYOUT_SETTINGS_REMOVING,
});

const payoutSettingsRemovingSuccessAction = () => ({
    type: PAYOUT_SETTINGS_REMOVE_SUCCESS,
});

const payoutBankMessageLoadingAction = () => ({
    type: PAYOUT_BANK_MESSAGE_LOADING,
});

const payoutBankMessageLoadingSuccessAction = (message: string | null) => ({
    type: PAYOUT_BANK_MESSAGE_LOADING_SUCCESS,
    message,
});

const payoutBankMessageLoadingErrorAction = (error: unknown) => ({
    type: PAYOUT_BANK_MESSAGE_LOADING_ERROR,
    error,
});

export type PayoutActions = ReturnType<
    | typeof payoutSettingsLoadingAction
    | typeof payoutSettingsUpdatingAction
    | typeof payoutSettingsLoadingSuccessAction
    | typeof payoutSettingsUpdateSuccessAction
    | typeof payoutSettingsLoadingErrorAction
    | typeof payoutSettingsRemovingAction
    | typeof payoutSettingsRemovingSuccessAction
    | typeof payoutBankMessageLoadingAction
    | typeof payoutBankMessageLoadingSuccessAction
    | typeof payoutBankMessageLoadingErrorAction
>;

export const loadPayoutSettings = () => async (dispatch) => {
    dispatch(payoutSettingsLoadingAction());
    try {
        const response = await new AccountPayoutWsControllerApi().getAccountPayoutSettingsUsingPOST({});
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(payoutSettingsLoadingSuccessAction(response.payoutSettings));
    } catch (error) {
        console.error('at payoutsActions in loadPayoutSettings', error);

        dispatch(payoutSettingsLoadingErrorAction(error.message));
    }
};

export const updatePayoutSettings = (settings: AccountPayoutSettings) => async (dispatch) => {
    dispatch(payoutSettingsUpdatingAction());
    try {
        const request: AccountPayoutSettings = {
            interval: settings.interval,
        };

        if (settings.pspCode) {
            request.pspCode = settings.pspCode;
        } else if (settings.bankAccount && !settings.bankAccount.type) {
            request.bankAccount = settings.bankAccount;
            request.bankAccount.type = 'I';
        }

        const response = await new AccountPayoutWsControllerApi().updateAccountPayoutSettingsUsingPOST({
            payoutSettings: request,
        });
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(payoutSettingsUpdateSuccessAction(response.payoutSettings));
    } catch (error) {
        console.error('at payoutsActions in updatePayoutSettings', error);

        dispatch(payoutSettingsLoadingErrorAction(error.message));
    }
};

export const removePayoutSettings = () => async (dispatch) => {
    dispatch(payoutSettingsRemovingAction());
    try {
        const response = await new AccountPayoutWsControllerApi().deleteAccountPayoutSettingsUsingDELETE({});
        handleResponseAndThrowAnErrorIfExists(response);
        dispatch(payoutSettingsRemovingSuccessAction());
    } catch (error) {
        console.error('at payoutsActions in removePayoutSettings', error);

        dispatch(payoutSettingsLoadingErrorAction(error.message));
    }
};

export const getPayoutDescription = () => async (dispatch, getState: () => IApplicationStore) => {
    dispatch(payoutBankMessageLoadingAction());
    try {
        const classificators = await ClassificatorControllerApiFactory().entityListUsingGET(
            'Payout',
            'BankAccountText',
            getLanguageCode(getState()),
        );

        const bankMessage = classificators.body.find((item) => item.code === getRegionCode(getState()))?.value;

        dispatch(payoutBankMessageLoadingSuccessAction(bankMessage));
    } catch (error) {
        console.error('at getLanguages at languagesActions', error);
        dispatch(payoutBankMessageLoadingErrorAction(error.message));
    }
};

import cn from 'classnames';
import { f7ready } from 'framework7-react';
import React, { useCallback, useEffect, useRef } from 'react';

import { allAvailablePopoverPositions, CustomPopoverProps, PopoverPositions } from './CustomPopover.types';

import { useAppSelector } from '@/hooks/store';

import './CustomPopover.less';

// ? was 0
const NAVBAR_OFFSET = 112;

export const CustomPopover = <T extends HTMLElement>({
    parentRef,
    children,
    availablePositions = allAvailablePopoverPositions,
    isTriangleShown = false,
    isWrappingStyled = true,
    childrenRect,
    className,
    opened,
    onPopoverContainerClick,
}: CustomPopoverProps<T>) => {
    const popoverRef = useRef<HTMLDivElement>(null);

    const windowSize = useAppSelector((state) => state.rootReducer.resizeEvent);

    const getPositioningHandlers = useCallback(() => {
        if (!parentRef?.current || !popoverRef?.current) {
            return [];
        }
        const offsetWidth = Math.abs(childrenRect ? childrenRect.width : popoverRef.current.offsetWidth);
        const offsetHeight = Math.abs(childrenRect ? childrenRect.height : popoverRef.current.offsetHeight);
        const parentRect = parentRef.current.getBoundingClientRect();
        const { offsetWidth: parentWidth, offsetHeight: parentHeight } = parentRef.current;

        const isTop =
            parentRect.top - offsetHeight > NAVBAR_OFFSET && parentRect.top - offsetHeight <= windowSize.height;

        const isBottom = parentRect.bottom + offsetHeight <= windowSize.height;
        const isLeft = parentRect.left - offsetWidth > 0 && parentRect.left - offsetWidth <= windowSize.width;
        const isRight = parentRect.right + offsetWidth <= windowSize.width;
        const centerX =
            parentRect.left > 0 &&
            parentRect.left - Math.abs((parentWidth - offsetWidth) / 2) - windowSize.width < 0 &&
            parentRect.right + Math.abs((parentWidth - offsetWidth) / 2) - windowSize.width < 0;
        const centerY =
            parentRect.top > 0 &&
            parentRect.top - Math.abs((parentHeight - offsetHeight) / 2) - windowSize.height < 0 &&
            parentRect.bottom + Math.abs((parentHeight - offsetHeight) / 2) - windowSize.height < 0;

        return [
            {
                position: PopoverPositions.TOP_LEFT_CORNER,
                condition: isTop && isLeft,
                handler: () => {
                    popoverRef.current.style.bottom = '';
                    popoverRef.current.style.top = `-${offsetHeight}px`;
                    popoverRef.current.style.left = `-${offsetWidth}px`;
                    popoverRef.current.style.right = '';
                },
            },

            {
                position: PopoverPositions.TOP_CENTER,
                condition: isTop && centerX,
                handler: () => {
                    popoverRef.current.style.bottom = '';
                    popoverRef.current.style.top = `-${offsetHeight}px`;
                    popoverRef.current.style.left = `${(parentWidth - offsetWidth) / 2}px`;
                    popoverRef.current.style.right = '';
                },
            },

            {
                position: PopoverPositions.TOP_RIGHT_CORNER,
                condition: isTop && isRight,
                handler: () => {
                    popoverRef.current.style.bottom = '';
                    popoverRef.current.style.top = `-${offsetHeight}px`;
                    popoverRef.current.style.left = '';
                    popoverRef.current.style.right = `-${offsetWidth}px`;
                },
            },

            {
                position: PopoverPositions.RIGHT_CENTER,
                condition: isRight && centerY,
                handler: () => {
                    popoverRef.current.style.top = '';
                    popoverRef.current.style.bottom = `${(parentHeight - offsetHeight) / 2}px`;
                    popoverRef.current.style.left = '';
                    popoverRef.current.style.right = `-${offsetWidth}px`;
                },
            },

            {
                position: PopoverPositions.BOTTOM_RIGHT_CORNER,
                condition: isBottom && isRight,
                handler: () => {
                    popoverRef.current.style.top = '';
                    popoverRef.current.style.bottom = `-${offsetHeight}px`;
                    popoverRef.current.style.left = '';
                    popoverRef.current.style.right = `-${offsetWidth}px`;
                },
            },

            {
                position: PopoverPositions.BOTTOM_CENTER,
                condition: isBottom && centerX,
                handler: () => {
                    popoverRef.current.style.top = '';
                    popoverRef.current.style.bottom = `-${offsetHeight}px`;
                    popoverRef.current.style.left = `${(parentWidth - offsetWidth) / 2}px`;
                    popoverRef.current.style.right = '';
                },
            },

            {
                position: PopoverPositions.BOTTOM_LEFT_CORNER,
                condition: isBottom && isLeft,
                handler: () => {
                    popoverRef.current.style.top = '';
                    popoverRef.current.style.bottom = `-${offsetHeight}px`;
                    popoverRef.current.style.left = `-${offsetWidth - parentWidth}px`;
                    popoverRef.current.style.right = '';
                },
            },

            {
                position: PopoverPositions.LEFT_CENTER,
                condition: isLeft && centerY,
                handler: () => {
                    popoverRef.current.style.top = `${(parentHeight - offsetHeight) / 2}px`;
                    popoverRef.current.style.bottom = '';
                    popoverRef.current.style.left = `-${offsetWidth}px`;
                    popoverRef.current.style.right = '';
                },
            },
        ];
    }, [childrenRect, parentRef, windowSize]);

    const handleRect = useCallback(() => {
        const handlers = getPositioningHandlers();
        let isHandeled = false;

        availablePositions.forEach((position) => {
            const current = handlers.find((pos) => pos.position === position);
            popoverRef.current?.classList.remove(position);
            if (!isHandeled && current?.condition) {
                current.handler();
                popoverRef.current.classList.add(isTriangleShown ? position : undefined);
                isHandeled = true;
            }
        });
    }, [availablePositions, isTriangleShown, getPositioningHandlers]);

    useEffect(() => {
        const handlers = getPositioningHandlers();
        handlers.find((handler) => handler.position === availablePositions[0])?.handler();
        popoverRef.current.classList.add(isTriangleShown ? availablePositions[0] : undefined);
    }, [availablePositions, getPositioningHandlers, isTriangleShown]);

    useEffect(() => {
        f7ready((f7) => {
            const pageContent = f7.$('.view-main .page-content');

            handleRect();

            pageContent.on('scroll', () => {
                handleRect();
            });
            pageContent.on('resize', () => {
                handleRect();
            });
        });
    }, [availablePositions, getPositioningHandlers, handleRect, isTriangleShown]);

    return (
        <div
            onClick={onPopoverContainerClick}
            ref={popoverRef}
            className={cn(
                'custom-popover',
                {
                    triangle: isTriangleShown && isWrappingStyled,
                    styled: isWrappingStyled,
                    'custom-popover__opened': opened,
                },

                className,
            )}
        >
            {children}
        </div>
    );
};

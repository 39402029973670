import { List, ListItem, Navbar, NavRight, Sheet } from 'framework7-react';
import React, { useCallback, useState } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { FileTypeSheetButtonProps } from './FileTypeSheetButton.types';

import { IcClose, IcSelect } from '@/assets';
import { CustomInput } from '@/components/CustomInput';
import { CustomSelectValue } from '@/components/CustomSelect';
import { useBooleanState } from '@/hooks/useBooleanState';
import { Sheets } from '@/types/sheets';

import './FileTypeSheetButton.less';

export const FileTypeSheetButton: React.FC<FileTypeSheetButtonProps> = ({ fileTypes, label }) => {
    const {
        input: { onChange, value },
    } = useField<CustomSelectValue>('typeCode');
    const { t } = useTranslation();
    const [sheetOpened, setSheetOpened] = useState(false);
    const [open, close] = useBooleanState(setSheetOpened);
    const makeHandleItemClick = useCallback(
        (item: CustomSelectValue) => () => {
            onChange(item);
            close();
        },
        [close, onChange],
    );

    const handleFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            e?.preventDefault();
            e?.stopPropagation();
            open();
        },
        [open],
    );

    return (
        <>
            <List className="file-type-button">
                <CustomInput label={`${t(label)}`} value={value?.label} readonly onFocus={handleFocus} />
                <IcSelect />
            </List>
            <Sheet className={Sheets.FILE_TYPE_SHEET} onSheetClose={close} opened={sheetOpened}>
                <Navbar noHairline noShadow title={t(label)}>
                    <NavRight>
                        <IcClose onClick={close} />
                    </NavRight>
                </Navbar>
                <List>
                    {fileTypes?.map((type) => (
                        <ListItem onClick={makeHandleItemClick(type)} key={type.value}>
                            {type.label}
                        </ListItem>
                    ))}
                </List>
            </Sheet>
        </>
    );
};

import cn from 'classnames';
import { BlockTitle, Link, List, ListItem } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ProfilePopoverProps } from './ProfilePopover.types';

import { logoutUser } from '@/actions/sessionActions';
import { Avatar } from '@/components/Avatar';
import { PopoverPositions } from '@/components/CustomPopover/CustomPopover.types';
import { CustomPopoverWrapper } from '@/components/CustomPopoverWrapper';
import { ProfileStatus } from '@/components/ProfileStatus';
import { useAppSelector } from '@/hooks/store';
import { useProfileMenuItems } from '@/hooks/useProfileMenuItems';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getProfile } from '@/selectors/profile';
import { AccountStatus } from '@/types/account';
import { ProductType } from '@/types/productType';
import { ProfileMenuItemName } from '@/types/profileMenuItems';
import { PRODUCT_TYPE_QUERY_NAME } from '@/types/URLParams';
import { isInsideGem } from '@/utils/isInsideGem';
import { getQueryByProductType } from '@/utils/productType/getQueryByProductType';

import './ProfilePopover.less';

export const ProfilePopover = ({
    onAboutClick,
    onVerifyClick,
    onInviteClick,
    onStartSellingClick,
    onStartProvidingServicesClick,
    className,
    ...props
}: ProfilePopoverProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const activeProductType = useAppSelector(getActiveProductType);

    const { menuItems, menuItemsKeys } = useProfileMenuItems();

    const profile = useAppSelector(getProfile);

    const profileVerified = useMemo(() => ['SF', 'MF', 'BF'].includes(profile.status), [profile.status]);

    const logout = useCallback(() => dispatch(logoutUser()), [dispatch]);

    const renderProfileItem = useCallback(
        (key: ProfileMenuItemName, closePopoverHandler?: () => void) => {
            const item = menuItems[key];
            const shouldOpenVerifyPopup = key === 'Verify' && profile?.status === AccountStatus.SC;

            const handleItemClick = () => {
                switch (key) {
                    case 'About':
                        onAboutClick?.();
                        break;
                    case 'Verify':
                        shouldOpenVerifyPopup && onVerifyClick?.();
                        break;
                    case 'Logout':
                        logout();
                        break;
                    case 'Invite':
                        onInviteClick?.();
                        break;
                    case 'StartSelling':
                        activeProductType === ProductType.Service
                            ? onStartProvidingServicesClick?.()
                            : onStartSellingClick?.();
                        break;
                    default:
                        break;
                }

                closePopoverHandler?.();
            };

            const isNotMessangerLoggoutButton = key !== 'Logout' || !isInsideGem();

            const itemDisplayed = item && (key !== 'Verify' || !profileVerified) && isNotMessangerLoggoutButton;

            return itemDisplayed ? (
                <ListItem
                    key={key}
                    link={
                        shouldOpenVerifyPopup
                            ? '#'
                            : item.link
                            ? `${item.link}?${PRODUCT_TYPE_QUERY_NAME}=${getQueryByProductType(activeProductType)}`
                            : item.link
                    }
                    onClick={handleItemClick}
                    title={`${t(item.name)}`}
                >
                    {item.icon && <item.icon slot="media" className={item.iconClassName} />}
                </ListItem>
            ) : (
                <React.Fragment key={key} />
            );
        },
        [
            activeProductType,
            logout,
            menuItems,
            onAboutClick,
            onInviteClick,
            onStartProvidingServicesClick,
            onStartSellingClick,
            onVerifyClick,
            profile?.status,
            profileVerified,
            t,
        ],
    );

    return (
        <CustomPopoverWrapper
            availablePositions={[PopoverPositions.BOTTOM_LEFT_CORNER]}
            className={cn('profile-popover', className)}
            {...props}
        >
            {({ closePopover }) => (
                <>
                    <Link className="avatar-container" href="/profile/personal/settings/">
                        <Avatar profile={profile} />
                        <BlockTitle large className="profile-name">
                            {profile.person?.name} {profile.person?.surname}
                        </BlockTitle>
                        {profile && <ProfileStatus profile={profile} />}
                    </Link>
                    <List noChevron>
                        {menuItemsKeys?.map((group, index) => (
                            <div className="items-group" key={index}>
                                {group?.map((item) => renderProfileItem(item as ProfileMenuItemName, closePopover))}
                            </div>
                        ))}
                    </List>
                </>
            )}
        </CustomPopoverWrapper>
    );
};

import { Link } from 'framework7-react';
import React from 'react';

import { MenuButtonProps } from './MenuButton.types';

import { IcArrowLeft, IcArrowRight } from '@/assets';

import './MenuButton.less';

export const MenuButton = ({ onClick, direction }: MenuButtonProps) => (
    <Link className="datepicker-menu-button" onClick={onClick}>
        {direction === 'right' ? <IcArrowRight /> : <IcArrowLeft />}
    </Link>
);

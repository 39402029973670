/* eslint-disable react/display-name */
import { Router } from 'framework7/modules/router/router';
import React from 'react';

import { ErrorBoundary } from '@/components/ErrorBoundary';

export const ConnectPageWrapper =
    <T extends unknown>(Page: React.ComponentClass<T> | React.FC<T> | ((props: T) => JSX.Element)) =>
    (props: T & { f7route?: Router.Route }) => (
        <ErrorBoundary>
            <Page {...props} />
        </ErrorBoundary>
    );
/* eslint-enable react/display-name */

import { Block, Button, f7, PageContent } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPageProps } from './ErrorPage.types';

import { createUIErrorDataForSupport } from './ErrorPage.utils';

import { ContactSupportPopup } from '@/components/ContactSupportPopup';
import { CustomPage } from '@/components/CustomPage';
import { useBoolState } from '@/hooks/useBoolState';

import './ErrorPage.less';

export const ErrorPage = ({ error, errorInfo }: ErrorPageProps) => {
    const { t } = useTranslation();

    const [contactSupportPopupOpened, openContactSupportPopup, closeContactSupportPopup] = useBoolState(false);

    const handleHomeClick = useCallback(() => {
        f7.views.main.router.navigate('/', { force: true, clearPreviousHistory: true });
    }, []);

    const errorDataForSupport = useMemo(() => createUIErrorDataForSupport(error, errorInfo), [error, errorInfo]);

    return (
        <CustomPage id="error-page">
            <PageContent>
                <Block className="title">{t('An unexpected error has occurred')}</Block>
                <Button fill round className="home-button" onClick={handleHomeClick}>
                    {t('Go Home')}
                </Button>
                <Button onClick={openContactSupportPopup} className="contact-support-button">
                    {t('Contact Support')}
                </Button>
            </PageContent>
            <ContactSupportPopup
                category="Application"
                opened={contactSupportPopupOpened}
                closePopup={closeContactSupportPopup}
                errorMessage={errorDataForSupport}
                initialSelectedSubjectCode="technical"
            />
        </CustomPage>
    );
};

import { f7, Preloader } from 'framework7-react';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import { HomeProps } from './Home.types';

import { loadAllGoods as loadAllGoodsAction } from '@/actions/allGoodsActions';
import { ISearchParams, loadProductWishList, searchClear } from '@/actions/productActions';
import { modalService } from '@/api/modalService';
import { AppNavBar } from '@/components/AppNavBar';
import { CategoriesMenu } from '@/components/CategoriesMenu';
import { CategorySheet } from '@/components/CategorySheet';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { EditRegionPopup } from '@/components/EditRegionPopup';
import { Footer } from '@/components/Footer';
import { LoadMarketingBanners } from '@/hoc/LoadMarketingBanners';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { getActiveProductType } from '@/selectors/getActiveProductType';
import { getCategoriesClassificator } from '@/selectors/getCategoriesClassificator';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { isLoggedIn } from '@/selectors/isLoggedIn';
import { AuthActions } from '@/shared/constants';
import { Popups } from '@/types/popups';

import './Home.less';
import { ProductType } from '@/types/productType';

const HomepageContent = React.lazy(() =>
    process.env.HOME_VIEW === 'mspace' ? import('./HomepageBests') : import('./HomepageRegular'),
);

const infiniteScrollEnabled = process.env.HOME_VIEW !== 'mspace';

const HomeInner = ({ f7router, authAction }: HomeProps) => {
    const isMobile = useAppSelector(getIsMobile);

    const [isReloadingAllGoogs, setIsReloadingAllGoogs] = useState(false);
    const countryCode = useAppSelector(getCountryCodeFromState);

    useEffect(() => {
        setIsReloadingAllGoogs(true);
    }, [countryCode]);

    const { loading: productsLoading } = useAppSelector((state) => state.productReducer);
    const loggedIn = useAppSelector(isLoggedIn);
    const activeType = useAppSelector(getActiveProductType);

    const {
        loading: allGoodsLoading,
        count: allGoodsCount,
        offset: allGoodsOffset,
        error: allGoodsError,
    } = useAppSelector((state) => state.allGoodsReducer);

    const { categoriesClassificator } = useAppSelector(getCategoriesClassificator);

    const [regionPopupIsAlredyOpened, setRegionPopupIsAlredyOpened] = useState(
        modalService.getConfig(Popups.EDIT_REGION_POPUP)?.isAlredyOpened,
    );

    const doAfterLogin = useCallback(() => {
        modalService.setIsAlredyOpened(Popups.EDIT_REGION_POPUP, false);
        setRegionPopupIsAlredyOpened(false);
    }, []);

    const doAfterRegister = useCallback(() => {
        modalService.setIsAlredyOpened(Popups.EDIT_REGION_POPUP, false);
        setRegionPopupIsAlredyOpened(false);
    }, []);

    const regionCode = useAppSelector((state) => state.sessionReducer.profile?.regionCode);
    const { regionsList } = useAppSelector((state) => state.regionsReducer);

    const dispatch = useAppDispatch();

    const [selectedCategory, setSelectedCategory] = useState<ICategoryClassificator>();
    const [isSearchbarEnabled, enableSearchBar, disableSearchbar] = useBoolState(false);
    const [selectCategorySheetOpened, openCategorySheet, closeCategorySheet] = useBoolState(false);

    const _lock = useRef(false);

    const handlePageInit = useCallback(() => {
        disableSearchbar();
        closeCategorySheet();
    }, [closeCategorySheet, disableSearchbar]);

    const categoryClickHandle = useCallback(
        (catid: string) => {
            setSelectedCategory(categoriesClassificator?.find((cat) => cat.categoryCode === catid));
            openCategorySheet();
        },
        [categoriesClassificator, openCategorySheet],
    );

    const searchbarDisableHandle = useCallback(() => {
        disableSearchbar();
        _lock.current = false;
        dispatch(searchClear());
    }, [disableSearchbar, dispatch]);

    const handleInfiniteScroll = useCallback(() => {
        const isFilterEnable = isSearchbarEnabled && isMobile;

        if (_lock.current || !allGoodsCount || allGoodsError) {
            return;
        }
        _lock.current = true;

        if (!isSearchbarEnabled && !isFilterEnable && !allGoodsLoading && infiniteScrollEnabled) {
            dispatch(loadAllGoodsAction({ count: allGoodsCount, offset: allGoodsOffset }));
        }
    }, [allGoodsCount, allGoodsError, allGoodsLoading, allGoodsOffset, dispatch, isMobile, isSearchbarEnabled]);

    const onClickLogoLink = useCallback(() => {
        f7.searchbar.disable('.search-bar');
        dispatch(searchClear());
        disableSearchbar();

        f7router.refreshPage();
    }, [disableSearchbar, dispatch, f7router]);

    const handleMobileCategorySelect = useCallback(
        (category: ICategoryClassificator) => f7router.navigate(`/search/?category=${category.categoryCode}`),
        [f7router],
    );

    useEffect(() => {
        if (!loggedIn) {
            dispatch(loadProductWishList());
        }
    }, [dispatch, loggedIn]);

    useEffect(() => {
        if (!infiniteScrollEnabled) {
            return;
        }
        const params: ISearchParams = {
            count: allGoodsCount,
            offset: 0,
        };
        dispatch(loadAllGoodsAction(params, isReloadingAllGoogs)).then(() => setIsReloadingAllGoogs(false));
    }, [allGoodsCount, dispatch, isReloadingAllGoogs]);

    useEffect(() => {
        _lock.current = productsLoading || allGoodsLoading;
    }, [allGoodsLoading, productsLoading]);

    return (
        <CustomPage
            id="home-page"
            // ? needed because f7 infinite do not work with lazy import
            // TODO: move to util if multiple skins with infinite
            infinite={infiniteScrollEnabled}
            infiniteDistance={300}
            infinitePreloader={false}
            onInfinite={handleInfiniteScroll}
            onPageInit={handlePageInit}
            subnavbar={isMobile}
        >
            <AppNavBar
                className="home-page__navbar"
                f7router={f7router}
                slot="fixed"
                navbar={{
                    backLink: undefined,
                    onSearchbarEnable: enableSearchBar,
                    onSearchbarDisable: searchbarDisableHandle,
                    showSearchbar: true,
                    onClickLogoLink: onClickLogoLink,
                }}
                loginInitial={authAction === AuthActions.LOGIN}
                registerInitial={authAction === AuthActions.REGISTER}
                verifyEmailInitial={authAction === AuthActions.VERIFY_EMAIL}
                doAfterLogin={doAfterLogin}
                doAfterRegister={doAfterRegister}
            />

            {activeType !== ProductType.Service && (
                <CategoriesMenu className="home-page__mobile-categories" onCategoryClick={categoryClickHandle} />
            )}

            <CategorySheet
                className="home-page__mobile-category-sheet"
                initialOpenedCategory={selectedCategory}
                withIcons
                withReturnToParentButton
                opened={selectCategorySheetOpened}
                onSheetOpened={openCategorySheet}
                onSheetClosed={closeCategorySheet}
                onCategoryClick={handleMobileCategorySelect}
            />
            <EditRegionPopup
                isFirstTime
                onPopupClose={() => setRegionPopupIsAlredyOpened(true)}
                animate={regionsList.length > 1}
                opened={loggedIn && !regionPopupIsAlredyOpened && !regionCode && regionsList.length > 0}
            />
            <LoadMarketingBanners />
            <Suspense fallback={<Preloader />}>
                <HomepageContent />
            </Suspense>

            <Footer />
        </CustomPage>
    );
};

export const Home = ConnectPageWrapper(HomeInner);

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { Article, PublicArticleControllerApi } from '@/generated/marketplaceapi';
import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getLanguageCode } from '@/selectors/getLanguageCode';

export const ARTICLES_LOADING = 'ARTICLES_LOADING' as const;
export const ARTICLES_LIST_SUCCESS = 'ARTICLES_LIST_SUCCESS' as const;
export const ARTICLE_DETAILS_SUCCESS = 'ARTICLE_DETAILS_SUCCESS' as const;
export const ARTICLES_ERROR = 'ARTICLES_ERROR' as const;

const articlesLoading = () => ({
    type: ARTICLES_LOADING,
});

const articlesListLoadingSuccess = (articles: Article[]) => ({
    type: ARTICLES_LIST_SUCCESS,
    articles,
});

const articleDetailsLoadingSuccess = (article: Article) => ({
    type: ARTICLE_DETAILS_SUCCESS,
    article,
});

const articlesError = (error: string) => ({
    type: ARTICLES_ERROR,
    error,
});

export type ArticlesActions = ReturnType<
    | typeof articlesLoading
    | typeof articlesListLoadingSuccess
    | typeof articleDetailsLoadingSuccess
    | typeof articlesError
>;

const publicArticleControllerApi = new PublicArticleControllerApi();

export const loadArticlesList = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    try {
        dispatch(articlesLoading());

        const response = await publicArticleControllerApi.getArticlesUsingGET(getLanguageCode(getState()));

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(articlesListLoadingSuccess(response.body ?? []));
    } catch (error) {
        dispatch(articlesError(`${error.message}`));
    }
};

export const loadArticleDetails = (uid: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(articlesLoading());

        const response = await publicArticleControllerApi.getArticleUsingGET(uid);

        handleResponseAndThrowAnErrorIfExists(response);

        dispatch(articleDetailsLoadingSuccess(response.body[0]));
    } catch (error) {
        dispatch(articlesError(`${error.message}`));
    }
};

import { ProductType } from '@/types/productType';

export const getLogoName = (type: ProductType) => {
    if (type === ProductType.Product) {
        return 'main_page.space_selector.button.products';
    }
    if (type === ProductType.Service) {
        return 'main_page.space_selector.button.services';
    }

    return 'main_page.space_selector.button.offers';
};

export const getLogoDescription = (type: ProductType) => {
    if (type === ProductType.Product) {
        return 'main_page.space_selector.button_placeholder.products';
    }
    if (type === ProductType.Service) {
        return 'main_page.space_selector.button_placeholder.services';
    }

    return 'main_page.space_selector.button_placeholder.offers';
};

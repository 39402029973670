import { doesCartItemShip } from './doesCartItemShip';
import { getCartItemParameters } from './getCartItemParameters';
import {
    hasCartItemPlatformDeliveryAddress,
    platformDeliveryRequiredParameters,
} from './hasCartItemPlatformDeliveryAddress';

import { CartItem } from '@/generated/marketplaceapi';

export const isCartItemsOrderDisabled = (items: CartItem[], selectedItems?: CartItem[]): boolean => {
    if (!items?.length || selectedItems?.length < 1) {
        return true;
    }

    const target = selectedItems ?? items;

    const notServiceItems = target?.filter(({ type }) => type !== 'S');

    if (notServiceItems.length === 0) {
        return false;
    }

    const isAllItemsShip = notServiceItems.every(doesCartItemShip);
    if (!isAllItemsShip) {
        return true;
    }

    const hasAllItemsPlatformDelivery = notServiceItems.every(({ platformDeliveryMethod }) => platformDeliveryMethod);
    const hasAllItemsCustomerDelivery = notServiceItems.every(({ deliveryMethodOption }) => deliveryMethodOption);
    const hasAllItemsSameDelivery = hasAllItemsPlatformDelivery || hasAllItemsCustomerDelivery;
    if (!hasAllItemsSameDelivery) {
        return true;
    }

    const hasAllItemsSamePlatformDelivery = notServiceItems.every(
        ({ platformDeliveryMethod }) =>
            platformDeliveryMethod?.deliveryProvider?.uid ===
            notServiceItems[0]?.platformDeliveryMethod?.deliveryProvider?.uid,
    );
    if (!hasAllItemsSamePlatformDelivery) {
        return true;
    }

    const platformDeliveryItems = notServiceItems.filter(({ platformDeliveryMethod }) => platformDeliveryMethod);

    const hasAllItemsPlatformDeliveryAddress = platformDeliveryItems.every(hasCartItemPlatformDeliveryAddress);
    if (!hasAllItemsPlatformDeliveryAddress) {
        return true;
    }

    const hasAllItemsSamePlatformDeliveryAddress = platformDeliveryItems.every(
        (cartItem) =>
            getCartItemParameters(cartItem, platformDeliveryRequiredParameters).join('') ===
            getCartItemParameters(platformDeliveryItems[0], platformDeliveryRequiredParameters).join(''),
    );
    if (!hasAllItemsSamePlatformDeliveryAddress) {
        return true;
    }

    return false;
};

import { BlockTitle, Button } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppNavBar } from '@/components/AppNavBar';
import { Carousel, CarouselItem, CarouselType } from '@/components/Carousel';
import { ConnectPageWrapper } from '@/components/ConnectPageWrapper';
import { CustomPage } from '@/components/CustomPage';
import { Footer } from '@/components/Footer';
import { NotAvailableMessage } from '@/components/NotAvailableMessage';
import { useLoadProducts } from '@/hooks/loaders/useLoadProducts';
import { useLoadProductWishList } from '@/hooks/loaders/useLoadProductWishList';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getSliderItemsForProductType } from '@/utils';

import type { NotAvailablePageProps } from './NotAvailablePage.types';

import './NotAvailablePage.less';

const NotAvailablePageInner: React.FC<NotAvailablePageProps> = ({ f7router }) => {
    const { t } = useTranslation();
    const isMobile = useAppSelector(getIsMobile);

    const { productTypeGroups, productsWishList } = useAppSelector((state) => state.productReducer);

    useLoadProducts('popular');
    useLoadProductWishList();

    const popularProducts = useMemo(
        () =>
            getSliderItemsForProductType(
                productTypeGroups,
                'popular',
                (item: CarouselItem) => f7router.navigate(item.href),
                productsWishList,
            ),
        [productTypeGroups, productsWishList, f7router],
    );

    const navigateToMainPage = useCallback(() => {
        f7router.navigate('/', { force: true, reloadAll: true, clearPreviousHistory: true });
    }, [f7router]);

    return (
        <CustomPage id="not_available" name="not_available" className="not-available-page">
            {!isMobile && <AppNavBar f7router={f7router} slot="fixed" />}
            <div className="not-available-page__content">
                <div className="message-wrapper">
                    <NotAvailableMessage
                        title={t('This product is no longer available')}
                        subtitle={t('Check out most popular products.')}
                    />
                </div>
                {isMobile && (
                    <Button fill round raised onClick={navigateToMainPage} className="button-to-main">
                        {t('To main page')}
                    </Button>
                )}
                <BlockTitle medium>{t('Most popular')}</BlockTitle>
                <Carousel
                    slides={popularProducts}
                    type={isMobile ? CarouselType.small : CarouselType.big}
                    showIfEmpty
                />
            </div>
            <Footer />
        </CustomPage>
    );
};

export const NotAvailablePage = ConnectPageWrapper(NotAvailablePageInner);

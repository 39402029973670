import { Card, CardContent, CardFooter, CardHeader, Link } from 'framework7-react';
import React from 'react';

import { skeletonLengths } from './LoadingReviewCard.constants';

import { IcMore } from '@/assets';
import { StarRating } from '@/components/StarRating';
import { generateSkeletonText } from '@/utils';

export const LoadingReviewCard = React.memo(() => (
    <Card className="review-card skeleton-text skeleton-effect-fade">
        <CardHeader className="skeleton-text">
            <div className="review-card__avatar-wrap" />
            <div className="review-card__header-wrap">
                <span>{generateSkeletonText(skeletonLengths.header)}</span>
                <StarRating className="review-card__star-rating" readOnly primary size="small" />
            </div>
            <Link className="review-card__more">
                <IcMore />
            </Link>
        </CardHeader>
        <CardContent className="skeleton-text">{generateSkeletonText(skeletonLengths.content)}</CardContent>
        <CardFooter className="skeleton-text">{generateSkeletonText(skeletonLengths.footer)}</CardFooter>
    </Card>
));
LoadingReviewCard.displayName = 'LoadingReviewCard';

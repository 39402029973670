import React from 'react';

import { IcIconProps } from '@/types/icons';

const IcNoResults = React.memo<IcIconProps>((props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="148" height="140" fill="none" viewBox="0 0 148 140" {...props}>
            <path
                fill="#941830"
                fillRule="evenodd"
                d="M16.585 55.67a1.692 1.692 0 011.993-1.324c2.026.409 3.314 1.705 4.196 3.119.717 1.15 1.256 2.535 1.727 3.745l.233.598c.571 1.442 1.089 2.576 1.778 3.366.608.697 1.355 1.134 2.578 1.134 1.134 0 1.472-.228 1.554-.295.097-.08.202-.218.394-.807l.064-.2c.163-.518.423-1.345.937-2.077.656-.932 1.638-1.642 3.086-2.055 2.9-.829 5.386.178 7.02 1.245a12.312 12.312 0 012.45 2.119 7.498 7.498 0 01.19.225l.014.018.005.007.003.002v.001c0 .001.001.002-1.33 1.045l1.331-1.043a1.692 1.692 0 01-2.66 2.092s0 .001 0 0l-.001-.001-.002-.002m.003.003l-.015-.019a7.74 7.74 0 00-.448-.484 8.937 8.937 0 00-1.39-1.128c-1.182-.772-2.64-1.283-4.24-.826-.802.23-1.092.528-1.247.748-.194.276-.288.567-.468 1.122l-.085.262c-.205.625-.549 1.62-1.467 2.372-.932.763-2.173 1.061-3.698 1.061-2.276 0-3.93-.92-5.128-2.293-1.118-1.282-1.815-2.932-2.375-4.347l-.263-.67c-.48-1.228-.887-2.268-1.422-3.127-.588-.943-1.194-1.43-1.993-1.592a1.692 1.692 0 01-1.324-1.994"
                clipRule="evenodd"
            />
            <path
                fill="#941830"
                d="M19.32 52.45c-2.729-4.162-8.24-2.78-9.155 1.68-1.14 5.516 2.551 5.781 5.124 5.587 3.041-.215 6.393-3.659 4.032-7.267z"
            />
            <path
                fill="#941830"
                d="M13.5 50.14s-6.812-2.95-7.478-.705c-.738 2.54 5.56 4.476 6.782 4.758 1.222.282 3.324-3.261.696-4.052z"
            />
            <path
                fill="#941830"
                d="M11.839 53.071s-6.852-1.567-7.427.93c-.576 2.498 5.092 2.774 6.62 2.602 1.53-.172 2.968-3.194.807-3.532z"
            />
            <path
                fill="#941830"
                d="M11.164 55.57c-.317.032-5.9-.016-5.846 2.018.054 2.034 6.178 2.242 7.18 1.482.995-.783-.18-3.575-1.334-3.5zM19.521 52.549s-2.885-7.069-4.89-6.323c-2.005.745-.28 4.693-.28 4.693s-.369 3.194 1.622 3.451c1.966.264 4.387.16 3.548-1.821zM62.605 123.85c-.027 0-.027 0 0 0-1.089 0-1.96-.904-1.96-1.972 0-.082.082-7.944.191-15.752.245-16.409.3-16.518.79-17.175.625-.877 1.85-1.096 2.72-.439.654.493.953 1.315.763 2.055-.19 1.945-.436 20.162-.572 31.366 0 1.041-.87 1.917-1.932 1.917z"
            />
            <path
                fill="#941830"
                d="M64.78 122.702c-.87 3.095-18.642 3.068-19.295-.11-.762-3.726 4.19-7.78 10.45-7.479 7.212.357 9.498 5.288 8.845 7.589z"
            />
            <path
                fill="#A91E3D"
                d="M54.658 115.823c4.381-.027 3.347 3.233-1.062 3.041-4.381-.192-3.075-3.013 1.062-3.041z"
            />
            <path
                fill="#941830"
                d="M87.087 123.849c.028 0 .028 0 0 0 1.089 0 1.96-.904 1.96-1.972 0-.082-.082-7.944-.19-15.752-.246-16.409-.3-16.518-.79-17.175-.626-.877-1.85-1.096-2.721-.439-.654.493-.953 1.315-.762 2.055.19 1.945.462 20.162.571 31.366 0 1.041.871 1.917 1.932 1.917zm-2.204-32.653s.027 0 0 0z"
            />
            <path
                fill="#941830"
                d="M84.911 122.702c.871 3.095 18.642 3.068 19.296-.11.762-3.726-4.191-7.78-10.45-7.479-7.213.357-9.499 5.288-8.846 7.589z"
            />
            <path
                fill="#A91E3D"
                d="M95.033 115.822c-4.382-.027-3.348 3.233 1.06 3.041 4.383-.192 3.076-3.013-1.06-3.041z"
            />
            <path
                fill="#DB2D46"
                d="M74.851 109.771l48.687-48.624-21.118-29.722-54.403-.247-21.445 29.394 48.28 49.199z"
            />
            <path
                fill="#A62041"
                d="M74.853 110.948c-.3 0-.6-.137-.817-.356l-48.279-49.2c-.408-.41-.435-1.04-.109-1.506l21.445-29.393c.218-.302.572-.466.926-.466l54.402.246c.381 0 .708.192.925.494l21.119 29.695a1.133 1.133 0 01-.136 1.479l-48.66 48.651a1.177 1.177 0 01-.816.356zM28.098 60.462l46.782 47.692 47.163-47.117-20.221-28.435-53.232-.247-20.492 28.107z"
            />
            <path fill="#C7274B" d="M121.934 61.065l-29.936-.137-17.145 47.089 47.081-46.952z" />
            <path fill="#EB363F" d="M91.997 60.928l-31.814-.136 14.668 47.226 17.146-47.09z" />
            <path fill="#FF9275" d="M60.181 60.788l-32.032-.3 20.303-28.025 11.73 28.325z" />
            <path fill="#F74A4E" d="M60.183 60.79l14.995-28.297 16.819 28.435-31.814-.137z" />
            <path fill="#EB363F" d="M91.996 60.928l9.742-28.298-26.561-.137 16.819 28.435z" />
            <path fill="#EB363F" d="M48.452 32.63l11.73 28.16 14.995-28.297-26.725.137z" />
            <path fill="#FE645A" d="M28.15 60.49l46.7 47.528L60.18 60.791 28.15 60.49z" />
            <path
                fill="#FFB9AB"
                d="M37.104 52.38c-1.388-.959-4.545 3.233-2.966 3.945 1.578.712 4.19-3.096 2.966-3.945zM33.486 57.011c-.735-.274-1.66 1.287-1.007 1.753.653.466 1.742-1.506 1.007-1.753z"
            />
            <path
                fill="#941830"
                d="M91.78 16.988c-2.178 3.013 7.266 8.492 9.008 5.67 2.013-3.26-6.64-8.957-9.008-5.67z"
            />
            <path
                fill="#2B0404"
                d="M62.605 55.585c-.463 0-.898-.274-1.061-.74a1.149 1.149 0 01.68-1.48c11.73-4.465 21.826-.739 22.234-.575.6.22.898.904.68 1.48-.217.602-.87.904-1.469.684-.109-.027-9.743-3.56-20.629.576-.163.027-.299.055-.435.055z"
            />
            <path
                fill="#941830"
                d="M54.413 17.371c2.231 2.959-7.076 8.657-8.9 5.863-2.068-3.206 6.477-9.095 8.9-5.863z"
            />
            <path
                fill="#fff"
                d="M65.652 41.15a13.097 13.097 0 01-1.497 6.273 30.303 30.303 0 00-3.429-.986 26.87 26.87 0 00-2.395-.438 20.596 20.596 0 00-2.34-.22 26.296 26.296 0 00-8.382.822c-.762.192-1.524.439-2.314.713a29.38 29.38 0 00-3.483 1.534c-1.47-2.11-2.313-4.657-2.368-7.397-.081-7.287 5.742-13.258 12.982-13.34C59.638 28 65.57 33.862 65.652 41.15z"
            />
            <path
                fill="#2B0404"
                d="M41.784 49.973a1.1 1.1 0 01-.925-.493 14.35 14.35 0 01-2.558-8.054c-.082-7.917 6.232-14.41 14.097-14.491a14.254 14.254 0 0110.124 4.081 14.252 14.252 0 014.272 10.109 14.41 14.41 0 01-1.632 6.82c-.273.494-.871.74-1.388.548a26.765 26.765 0 00-3.293-.958 34.113 34.113 0 00-2.314-.411 20.345 20.345 0 00-2.259-.22 25.225 25.225 0 00-5.388.247c-.898.137-1.796.329-2.667.548-.735.192-1.497.411-2.232.685-1.143.41-2.259.904-3.347 1.48-.136.081-.327.109-.49.109zm10.75-20.737h-.136c-6.586.082-11.92 5.533-11.839 12.162.028 2.11.572 4.137 1.633 5.918.898-.411 1.797-.795 2.695-1.124.789-.3 1.605-.547 2.422-.74a34.286 34.286 0 012.912-.602 29.632 29.632 0 015.85-.274c.817.055 1.634.137 2.423.247.816.11 1.66.274 2.477.438.87.192 1.741.438 2.585.712.653-1.534.98-3.177.952-4.848a11.946 11.946 0 00-3.592-8.465 11.865 11.865 0 00-8.382-3.424z"
            />
            <path
                fill="#3BA4F2"
                d="M62.197 41.175c0 .302 0 .603-.028.905a9.722 9.722 0 01-1.442 4.328 26.87 26.87 0 00-2.395-.439 20.573 20.573 0 00-2.34-.219 26.302 26.302 0 00-8.383.822c-.762.192-1.523.438-2.313.712a9.378 9.378 0 01-2.095-5.917c-.055-5.287 4.164-9.615 9.416-9.67 4.545-.055 8.382 3.123 9.362 7.397.136.657.218 1.37.218 2.081z"
            />
            <path
                fill="#2B0404"
                d="M60.02 41.205c0 .576-.055 1.123-.164 1.644a7.392 7.392 0 01-1.524 3.15 20.62 20.62 0 00-2.34-.219 26.302 26.302 0 00-8.383.822 7.392 7.392 0 01-2.231-5.232 7.35 7.35 0 017.239-7.451 7.304 7.304 0 016.94 4.82c.272.74.435 1.59.462 2.466z"
            />
            <path fill="#fff" d="M62.905 40.437c-.19-2.794-6.776-2-6.504.575.272 2.575 6.722 2.493 6.504-.575z" />
            <path
                fill="#fff"
                d="M53.46 35.642c-2.042-1.068-4.519 1.945-2.178 3.452 2.34 1.507 4.98-2 2.177-3.452zM56.373 44.956c-.054.329-.218.575-.408.794a26.302 26.302 0 00-5.607.247c-.571-.247-1.088-.575-1.47-.931-1.85-1.808-1.931-5.808.137-5.835 1.334-.028 1.497 2.684 2.994 2.958 1.47.274 4.925.192 4.354 2.767z"
                opacity="0.17"
            />
            <path
                fill="#941830"
                d="M39.173 51.506c-.326 0-.653-.137-.87-.411-.409-.493-.327-1.205.163-1.616.217-.192 5.551-4.548 14.723-4.876 8.328-.302 13.009 2.849 13.226 2.958a1.15 1.15 0 01.3 1.589 1.133 1.133 0 01-1.579.301c-.081-.054-4.327-2.849-11.838-2.575-4.355.165-7.73 1.315-9.825 2.274-2.259 1.04-3.51 2.055-3.51 2.082a1.375 1.375 0 01-.79.274z"
            />
            <path
                fill="#fff"
                d="M81.165 40.957c.027 2.274.599 4.383 1.633 6.246.925-.356 2.068-.74 3.402-1.068a26.347 26.347 0 012.394-.493c.735-.11 1.525-.22 2.341-.274 1.742-.137 3.62-.11 5.606.137.925.11 1.85.274 2.803.52.762.192 1.552.411 2.341.658 1.17.383 2.34.876 3.511 1.452a13.241 13.241 0 002.204-7.452c-.082-7.286-6.015-13.121-13.254-13.066-7.239.11-13.063 6.054-12.981 13.34z"
            />
            <path
                fill="#2B0404"
                d="M105.194 49.314a1.17 1.17 0 01-.517-.11 25.82 25.82 0 00-3.375-1.397 22.688 22.688 0 00-2.232-.63 21.79 21.79 0 00-2.667-.493 27.174 27.174 0 00-5.388-.137c-.762.055-1.497.137-2.232.274a22.54 22.54 0 00-2.286.466c-1.116.274-2.231.63-3.266 1.013a1.167 1.167 0 01-1.415-.52c-1.143-2.055-1.742-4.41-1.769-6.794a14.447 14.447 0 014.055-10.19c2.667-2.74 6.232-4.274 10.042-4.301a14.252 14.252 0 0110.124 4.082 14.25 14.25 0 014.273 10.108 14.289 14.289 0 01-2.395 8.108c-.218.33-.599.521-.952.521zM93.11 44.137c1.198 0 2.395.082 3.593.219.98.137 1.96.301 2.912.52.816.192 1.633.411 2.422.685.925.301 1.823.657 2.721 1.068a11.941 11.941 0 001.497-5.971 11.945 11.945 0 00-3.592-8.465 11.85 11.85 0 00-8.355-3.424h-.136c-3.184.027-6.178 1.315-8.437 3.616-2.231 2.3-3.456 5.342-3.402 8.547a12.192 12.192 0 001.062 4.82c.843-.273 1.687-.547 2.558-.766.816-.192 1.66-.384 2.476-.493.79-.137 1.606-.22 2.422-.302a60.665 60.665 0 012.26-.054z"
            />
            <path
                fill="#3BA4F2"
                d="M84.62 40.93c0 .3.027.602.055.904a9.41 9.41 0 001.551 4.3 26.338 26.338 0 012.395-.493c.735-.11 1.524-.219 2.34-.274 1.742-.137 3.62-.11 5.606.137.926.11 1.851.274 2.804.52.762.193 1.551.412 2.34.658a9.644 9.644 0 001.987-5.944c-.055-5.287-4.355-9.533-9.607-9.478-4.545.054-8.3 3.287-9.199 7.588-.217.657-.299 1.37-.272 2.082z"
            />
            <path
                fill="#2B0404"
                d="M86.797 40.903c0 .575.082 1.123.19 1.644.273 1.178.844 2.218 1.579 3.095.735-.11 1.524-.22 2.34-.274 1.742-.137 3.62-.11 5.606.137.926.11 1.851.274 2.804.52a7.297 7.297 0 002.122-5.26c-.054-4.08-3.347-7.34-7.402-7.286-3.184.028-5.851 2.11-6.83 4.959a7.318 7.318 0 00-.41 2.465z"
            />
            <path fill="#fff" d="M83.887 40.218c.136-2.795 6.722-2.137 6.504.438-.218 2.575-6.64 2.63-6.504-.438z" />
            <path
                fill="#fff"
                d="M93.22 35.203c2.014-1.123 4.545 1.836 2.231 3.397-2.286 1.562-4.98-1.863-2.231-3.397zM90.526 44.575c.082.328.218.575.408.794 1.742-.137 3.62-.11 5.607.137.571-.274 1.088-.603 1.442-.959 1.796-1.835 1.796-5.862-.245-5.835-1.334.028-1.442 2.74-2.912 3.041-1.47.301-4.926.274-4.3 2.822z"
                opacity="0.17"
            />
            <path
                fill="#941830"
                d="M107.509 50.735c-.245 0-.49-.082-.707-.246-.055-.028-5.09-3.945-13.417-4.054-7.593-.11-11.757 2.794-11.784 2.821a1.163 1.163 0 01-1.606-.274 1.181 1.181 0 01.272-1.616c.19-.137 4.817-3.37 13.145-3.26 9.171.137 14.587 4.383 14.805 4.575.49.383.571 1.123.19 1.616a1.103 1.103 0 01-.898.438z"
            />
            <path
                fill="#941830"
                fillRule="evenodd"
                d="M130.505 55.669a1.692 1.692 0 01-1.323 1.994c-.8.161-1.406.65-1.994 1.592-.535.859-.942 1.9-1.422 3.127-.085.218-.172.441-.263.67-.56 1.415-1.257 3.065-2.375 4.347-1.199 1.374-2.851 2.293-5.128 2.293-1.525 0-2.766-.298-3.698-1.061-.918-.752-1.262-1.746-1.467-2.372a24.77 24.77 0 01-.085-.262c-.18-.555-.274-.846-.468-1.122-.155-.22-.445-.519-1.247-.748-1.601-.457-3.058.054-4.241.826a8.956 8.956 0 00-1.753 1.515l-.084.097-.014.019v-.001l.002-.003a1.692 1.692 0 01-2.663-2.088l1.332 1.043a162.227 162.227 0 01-1.331-1.045l.003-.003.005-.007.014-.018.043-.053c.036-.043.085-.102.147-.172a12.299 12.299 0 012.449-2.118c1.635-1.068 4.121-2.075 7.021-1.246 1.448.413 2.43 1.123 3.086 2.055.515.733.774 1.559.937 2.077l.064.2c.193.589.297.728.394.807.082.067.42.296 1.554.296 1.223 0 1.97-.438 2.578-1.135.689-.79 1.207-1.924 1.778-3.366l.233-.598c.471-1.21 1.01-2.594 1.727-3.745.882-1.413 2.17-2.71 4.196-3.12a1.692 1.692 0 011.993 1.325z"
                clipRule="evenodd"
            />
            <path
                fill="#941830"
                d="M127.77 52.45c2.73-4.162 8.241-2.78 9.156 1.68 1.139 5.516-2.552 5.781-5.124 5.587-3.042-.215-6.393-3.659-4.032-7.267z"
            />
            <path
                fill="#941830"
                d="M133.591 50.14s6.812-2.95 7.477-.705c.739 2.54-5.559 4.476-6.781 4.758-1.223.282-3.324-3.261-.696-4.052z"
            />
            <path
                fill="#941830"
                d="M135.252 53.071s6.852-1.567 7.427.93c.575 2.498-5.092 2.774-6.621 2.602-1.529-.172-2.967-3.194-.806-3.532z"
            />
            <path
                fill="#941830"
                d="M135.927 55.57c.316.032 5.899-.016 5.845 2.018-.053 2.034-6.177 2.242-7.179 1.482-.996-.783.179-3.575 1.334-3.5zM127.57 52.549s2.885-7.069 4.89-6.323c2.004.745.279 4.693.279 4.693s.37 3.194-1.621 3.451c-1.967.264-4.388.16-3.548-1.821z"
            />
        </svg>
    );
});

IcNoResults.displayName = 'IcNoResults';

export default IcNoResults;

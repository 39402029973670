import { f7 } from 'framework7-react';
import { useEffect } from 'react';

export const usePreloader = (loading: boolean | undefined) => {
    useEffect(() => {
        if (loading) {
            f7.preloader.show();
        } else {
            f7.preloader.hide();
        }

        return () => {
            f7.preloader.hide();
        };
    }, [loading]);
};

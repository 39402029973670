import cn from 'classnames';
import { Col, f7, Panel, Row } from 'framework7-react';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { PageSideMenuItem, PageSideMenuLayoutProps } from './PageSideMenuLayout.types';

import { PageShrinkMenuProps } from '@/components/PageSideMenu/PageShrinkMenu/PageShrinkMenu.types';
import { TypedPageSideMenuProps } from '@/components/PageSideMenu/PageSideMenu.types';
import { PersonalAreaMenu } from '@/components/PersonalAreaMenu';
import { PersonalAreaMenuItemName } from '@/components/PersonalAreaMenu/PersonalAreaMenu.types';
import { PersonalAreaShrinkMenu } from '@/components/PersonalAreaMenu/PersonalAreaShrinkMenu';
import { RedirectToVerifyAccountModal } from '@/components/RedirectToVerifyAccountModal';
import { SellerAreaMenu } from '@/components/SellerAreaMenu';
import { SellerAreaMenuItemName } from '@/components/SellerAreaMenu/SellerAreaMenu.types';
import { SellerAreaShrinkMenu } from '@/components/SellerAreaMenu/SellerAreaShrinkMenu';
import { VerifyAccountPopup } from '@/components/VerifyAccountPopup';
import { useAppSelector } from '@/hooks/store';
import { useBooleanState } from '@/hooks/useBooleanState';
import { useBoolState } from '@/hooks/useBoolState';
import { getIsMobile } from '@/selectors/getIsMobile';
import { getProfile } from '@/selectors/profile';
import { Breakpoints } from '@/types/breakpoints';

import './PageSideMenuLayout.less';

export const PageSideMenuLayout = <T extends PersonalAreaMenuItemName | SellerAreaMenuItemName>({
    type,
    selected,
    children,
    menuClassName,
    contentClassname,
    menuItems,
    menuItemsNames,
    selectedSubitem,
}: PageSideMenuLayoutProps<T>) => {
    const isMobile = useAppSelector(getIsMobile);

    const ShrinkMenuComponent = useMemo<React.FC<PageShrinkMenuProps<T>>>(
        () =>
            type === 'PersonalArea'
                ? (PersonalAreaShrinkMenu as unknown as React.FC<PageShrinkMenuProps<T>>)
                : (SellerAreaShrinkMenu as unknown as React.FC<PageShrinkMenuProps<T>>),
        [type],
    );

    const MenuComponent = useMemo(
        () =>
            type === 'PersonalArea'
                ? (PersonalAreaMenu as unknown as React.FC<TypedPageSideMenuProps<T>>)
                : (SellerAreaMenu as unknown as React.FC<TypedPageSideMenuProps<T>>),
        [type],
    );

    const withPanel = useAppSelector((state) => state.rootReducer.resizeEvent.width <= Breakpoints['1024px']);

    const [panelOpened, setPanelOpened] = useState(false);
    const [openPanel, closePanel] = useBooleanState(setPanelOpened);

    const [fakePanelOpened, setFakePanelOpened] = useState(false);
    const [fakeOpenPanel, fakeClosePanel] = useBooleanState(setFakePanelOpened);

    const [popupWasOpened, setPopupWasOpened] = useState(false);

    const debouncedTogglePanel = useMemo(() => debounce((opened: boolean) => setPanelOpened(opened), 100), []);

    useEffect(() => {
        debouncedTogglePanel(fakePanelOpened);
    }, [debouncedTogglePanel, fakePanelOpened]);

    const { status: profileStatus } = useAppSelector(getProfile);

    const [redirectToVerifyAccountModalOpened, openRedirectToVerifyAccountModal, closeRedirectToVerifyAccountModal] =
        useBoolState(false);

    const onRedirectToVerifyAccountModal = useCallback(() => {
        f7.views.main.router.navigate('/profile/personal/verify/', { reloadAll: true });
    }, []);

    const handleCloseVerifyAccountPopup = useCallback(() => {
        setPopupWasOpened(false);
    }, [setPopupWasOpened]);

    const handleMenuItemClick = useCallback(
        (i: PageSideMenuItem) => {
            switch (i.name) {
                case 'PersonalArea_Verify_Your_Account':
                    setPopupWasOpened(true);
                    break;
                case 'Switch_To_Business': {
                    if (profileStatus !== 'SF' && process.env.CONFIG === 'marketspace') {
                        openRedirectToVerifyAccountModal();
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        },
        [profileStatus, openRedirectToVerifyAccountModal, setPopupWasOpened],
    );

    return (
        <Row className={cn('page-side-menu-layout', { 'with-panel': withPanel })} resizableFixed>
            {!isMobile && (
                <Col className={cn('page-side-menu-layout__menu', { 'with-panel': withPanel }, menuClassName)}>
                    {withPanel && (
                        <ShrinkMenuComponent
                            menuItemsNames={menuItemsNames}
                            menuItems={menuItems}
                            selectedName={selected}
                            onItemClick={openPanel}
                            onMouseEnter={fakeOpenPanel}
                            className="page-side-menu-layout__shrink"
                        />
                    )}
                    {withPanel ? (
                        <Panel
                            left
                            cover
                            opened={panelOpened}
                            onPanelOpen={openPanel}
                            onPanelClose={closePanel}
                            className={cn('page-side-menu-layout__panel')}
                        >
                            <div className="page-side-menu-layout__panel__menu-wrapper" onMouseLeave={fakeClosePanel}>
                                <MenuComponent
                                    selectedSubitem={selectedSubitem}
                                    selected={selected}
                                    handleItemClick={handleMenuItemClick}
                                />
                            </div>
                        </Panel>
                    ) : (
                        <MenuComponent
                            selectedSubitem={selectedSubitem}
                            selected={selected}
                            selectedRounded
                            handleItemClick={handleMenuItemClick}
                        />
                    )}
                </Col>
            )}
            <Col className={cn('page-side-menu-layout__content', contentClassname)}>{children}</Col>
            <VerifyAccountPopup opened={popupWasOpened} onPopupClosed={handleCloseVerifyAccountPopup} />
            <RedirectToVerifyAccountModal
                canClose={false}
                opened={redirectToVerifyAccountModalOpened}
                onPopupClose={closeRedirectToVerifyAccountModal}
                onSheetClose={closeRedirectToVerifyAccountModal}
                onClick={onRedirectToVerifyAccountModal}
            />
        </Row>
    );
};

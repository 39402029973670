import { IApplicationStore } from '@/reducers/rootReducer';

export const getSortedCategories = (state: IApplicationStore) => {
    const allCategories = state.categoryReducer.categories;

    return allCategories.sort((c1, c2) => {
        if (c1.productCount && c2.productCount) {
            return 0;
        } else if (c1.productCount) {
            return -1;
        } else if (c2.productCount) {
            return 1;
        } else {
            return 0;
        }
    });
};

import { getCartItemParameters } from './getCartItemParameters';

import { CartItem } from '@/generated/marketplaceapi';
import { CartItemParameterCode } from '@/types/cartItem';

export const platformDeliveryRequiredParameters = [
    CartItemParameterCode.CdekDeliveryAddress,
    CartItemParameterCode.CdekDeliveryType,
    CartItemParameterCode.CdekTariffCode,
];

export const hasCartItemPlatformDeliveryAddress = (item: CartItem) =>
    getCartItemParameters(item, platformDeliveryRequiredParameters).length >= platformDeliveryRequiredParameters.length;

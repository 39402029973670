import { Router } from 'framework7/modules/router/router';
import React, { MouseEvent } from 'react';

import { IcBack } from '@/assets';
import { MSButtonIcon } from '@/shared/UIKit/ButtonIcon/ButtonIcon';

type MSButtonBackProps = {
    router: Router.Router;
};

export const MSButtonBack = ({ router }: MSButtonBackProps) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (router.history.length > 1) {
            router.back();
        } else {
            router.navigate('/');
        }
    };

    return <MSButtonIcon onClick={handleClick} icon={<IcBack />}></MSButtonIcon>;
};

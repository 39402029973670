import { Link, List, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useRef } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { SwitchAccountToBusinessFormValues, SwitchAccountToBusinessProps } from './SwitchAccountToBusinessPopup.types';

import { validateFormData } from './SwitchAccountToBusinessPopup.utils';

import { AdaptiveBackLink } from '../AdaptiveBackLink';
import { CustomButton } from '../CustomButton';
import { CustomInputField } from '../Fields/CustomInputField';
import { CustomSelectField } from '../Fields/CustomSelectField';
import { SelectSheetField } from '../Fields/SelectSheetField';

import { setAlertAction } from '@/actions/alertActions';
import { transformToBusinessAccount } from '@/actions/sessionActions';
import { showStoreCreatedPopupAction } from '@/actions/storeActions';
import { IcClose } from '@/assets';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useBoolState } from '@/hooks/useBoolState';
import { closeSwitchToBusinessRegistrationPopup } from '@/reducers/registrationReducer';
import { getIsMobile } from '@/selectors/getIsMobile';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { classificatorToCustomSelectValue } from '@/utils';

import './SwitchAccountToBusinessPopup.less';

const SwitchAccountToBusinessPopup = ({ opened }: SwitchAccountToBusinessProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const customSelectRef = useRef(null);

    const isMobile = useAppSelector(getIsMobile);
    const { transformToBusinessAccountLoading } = useAppSelector((state) => state.sessionReducer);

    const [isCompanyFormSheetOpened, openCompanyFormSheet, closeCompanyFormSheet] = useBoolState(false);

    const companyFormOptions = useAppSelector((state) =>
        state.classificatorReducer.entitiesClassificators.Company_BusinessType.map(classificatorToCustomSelectValue),
    );

    const switchAccount = async (values: SwitchAccountToBusinessFormValues) => {
        const { sessionReducer } = await dispatch(
            transformToBusinessAccount(
                {
                    companyName: '', // please, don't remove
                    companyType: parseInt(values.companyType.value, 10),
                    taxPayerIn: values.taxPayerIn,
                    registrationNumber: values.registrationNumber,
                },
                {
                    name: values.storeName,
                    description: values.storeDescription,
                },
            ),
        );

        if (sessionReducer.transformToBusinessAccountError) {
            dispatch(
                setAlertAction({
                    text: t(`${sessionReducer.transformToBusinessAccountError}`),
                }),
            );

            return;
        }

        onCloseHandle();
        dispatch(showStoreCreatedPopupAction(true));
    };

    const onCloseHandle = () => {
        dispatch(closeSwitchToBusinessRegistrationPopup());
    };

    return (
        <MSPopup className="switch-account-to-business-popup" opened={opened}>
            <div className="switch-account-to-business-popup__header">
                <Navbar className="switch-account-to-business-popup__navbar" noShadow noHairline>
                    {isMobile ? (
                        <NavLeft>
                            <AdaptiveBackLink onClick={onCloseHandle} />
                        </NavLeft>
                    ) : (
                        <NavRight>
                            <Link className="switch-account-to-business-popup__close" iconOnly onClick={onCloseHandle}>
                                <IcClose />
                            </Link>
                        </NavRight>
                    )}
                </Navbar>
                <span className="title">{t('Start Selling')}</span>
            </div>

            <div
                className="switch-account-to-business-popup__content"
                onScroll={() => {
                    if (customSelectRef.current) {
                        customSelectRef.current.onParentScroll?.();
                    }
                }}
            >
                <Form<SwitchAccountToBusinessFormValues> validate={validateFormData} onSubmit={switchAccount}>
                    {({ handleSubmit, valid }) => (
                        <>
                            <div className="switch-account-to-business-popup__block">
                                <span className="title">{t('General Info')}</span>
                                <List noHairlines>
                                    <CustomSelectField
                                        className="switch-account-to-business-popup__select"
                                        name="companyType"
                                        options={companyFormOptions}
                                        withFlags={false}
                                        requiredSign
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 11001 }) }}
                                        menuShouldScrollIntoView={false}
                                        closeMenuOnScroll
                                        customSelectRef={customSelectRef}
                                        openPopup={openCompanyFormSheet}
                                        label={t('Company Legal Form')}
                                    />
                                    <CustomInputField
                                        name="taxPayerIn"
                                        requiredSign
                                        label={t('Taxpayer Identification Number (INN)')}
                                    />
                                    <CustomInputField name="registrationNumber" requiredSign label={t('OGRN')} />
                                </List>
                            </div>
                            <div className="switch-account-to-business-popup__block">
                                <span className="title">
                                    {t('registration_form.business_account.your_store_section.title')}
                                </span>
                                <span className="sub-title">
                                    {t('registration_form.business_account.your_store_section.description')}
                                </span>
                                <List noHairlines>
                                    <CustomInputField name="storeName" requiredSign label={t('Store Name')} />
                                    <CustomInputField
                                        name="storeDescription"
                                        requiredSign
                                        label={t('Description')}
                                        info={t('registration_form.business_account.your_store_section.example')}
                                    />
                                </List>
                            </div>

                            <SelectSheetField
                                type="radio"
                                name="companyType"
                                options={companyFormOptions}
                                title={t('Company Legal Form')}
                                opened={isCompanyFormSheetOpened}
                                onSheetOpen={openCompanyFormSheet}
                                onSheetClose={closeCompanyFormSheet}
                            />
                            <CustomButton
                                className="switch-account-to-business-popup__button"
                                disabled={transformToBusinessAccountLoading || !valid}
                                size="large"
                                fullWidth
                                onClick={handleSubmit}
                            >
                                {t('Done')}
                            </CustomButton>
                        </>
                    )}
                </Form>
            </div>
        </MSPopup>
    );
};

export default SwitchAccountToBusinessPopup;

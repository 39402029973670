import cn from 'classnames';
import { Link, List, ListItem, Navbar, NavLeft, NavRight } from 'framework7-react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchCategoryPopupProps } from './SearchCategoryPopup.types';

import { IcClose } from '@/assets';
import { AdaptiveBackLink } from '@/components/AdaptiveBackLink';
import { useAppSelector } from '@/hooks/store';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { MSPopup } from '@/shared/UIKit/Popup/Popup';
import { Popups } from '@/types/popups';
import { getFullCategoryName } from '@/utils';

import './SearchCategoryPopup.less';

export const SearchCategoryPopup = ({
    opened,
    onCategoryClick,
    onPopupOpen,
    onPopupClose,
    className,
    ...props
}: SearchCategoryPopupProps) => {
    const { t } = useTranslation();

    const { flat } = useAppSelector((state) => state.categoryReducer);

    const [searchValue, setSearchValue] = useState('');

    const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value), []);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleSheetOpened = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleSheetOpen = useCallback(() => {
        onPopupOpen?.();
    }, [onPopupOpen]);

    const findedCategories = useMemo(
        () =>
            searchValue
                ? flat.filter(({ categoryName }) => categoryName.toLowerCase().includes(searchValue.toLowerCase()))
                : [],
        [flat, searchValue],
    );

    const getCategoryNameByCode = useCallback((code: string) => getFullCategoryName(flat, code, t), [flat, t]);

    const handleCategoryClick = useCallback(
        (category: ICategoryClassificator) => {
            setSearchValue('');
            onCategoryClick(category);
            onPopupClose();
        },
        [onCategoryClick, onPopupClose],
    );

    const clearSearch = useCallback(() => setSearchValue(''), []);

    return (
        <MSPopup
            {...props}
            opened={opened}
            className={cn(Popups.SEARCH_CATEGORY_POPUP, 'search-category-popup', className)}
            onPopupOpened={handleSheetOpened}
            onPopupOpen={handleSheetOpen}
            onPopupClosed={onPopupClose}
        >
            <Navbar className={cn('search-category-popup__navbar')}>
                <NavLeft>
                    <AdaptiveBackLink onClick={onPopupClose} />
                </NavLeft>
                <input
                    className={cn('search-category-popup__search')}
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder={t('Search')}
                    ref={inputRef}
                />
                {!!searchValue && (
                    <NavRight>
                        <Link iconOnly onClick={clearSearch}>
                            <IcClose />
                        </Link>
                    </NavRight>
                )}
            </Navbar>
            <List className={cn('search-category-popup__categories')}>
                {findedCategories.map((category) => (
                    <ListItem
                        key={category.categoryCode}
                        header={category.categoryName}
                        title={getCategoryNameByCode(category.categoryCode)}
                        onClick={() => handleCategoryClick(category)}
                    />
                ))}
            </List>
        </MSPopup>
    );
};

import { handleResponseAndThrowAnErrorIfExists } from '@/error-handler';
import { ClassificatorControllerApi } from '@/generated/marketplaceapi';
import { ICategoryClassificator } from '@/reducers/categoryReducer';
import { IApplicationStore } from '@/reducers/rootReducer';

export const CATEGORY_LIST_LOADING = 'CATEGORY_LIST_LOADING' as const;
export const CATEGORY_LIST_LOADING_SUCCESS = 'CATEGORY_LIST_LOADING_SUCCESS' as const;
export const CATEGORY_LIST_LOADING_ERROR = 'CATEGORY_LIST_LOADING_ERROR' as const;

const categoryListLoadingAction = () => ({
    type: CATEGORY_LIST_LOADING,
});

const categoryListLoadingSuccessAction = (categories: ICategoryClassificator[], flat: ICategoryClassificator[]) => ({
    type: CATEGORY_LIST_LOADING_SUCCESS,
    categories,
    flat,
});

const categoryListLoadingErrorAction = (error: unknown) => ({
    type: CATEGORY_LIST_LOADING_ERROR,
    error,
});

export type CategoryActions = ReturnType<
    typeof categoryListLoadingAction | typeof categoryListLoadingSuccessAction | typeof categoryListLoadingErrorAction
>;

function mapCategories(
    categories: ICategoryClassificator[],
    allCategories: ICategoryClassificator[],
    parent?: ICategoryClassificator,
    top?: ICategoryClassificator,
) {
    return categories.map((item) => {
        const { categoryCode } = item;
        item.children = mapCategories(
            allCategories.filter((item) => item.dependency === categoryCode),
            allCategories,
            item,
            !parent ? item : top,
        );

        item.parent = parent;
        /* TODO */
        item.topParent = top;

        return item;
    });
}

export const loadCategories = () => async (dispatch, getState: () => IApplicationStore) => {
    const state = getState();
    const { language } = state.rootReducer;
    const activeProductType = state.rootReducer.activeProductType;

    if (!activeProductType) {
        return;
    }

    dispatch(categoryListLoadingAction());

    try {
        const result = await new ClassificatorControllerApi().productCategoryUsingGET(
            undefined,
            undefined,
            undefined,
            undefined,
            language,
            activeProductType,
        );
        handleResponseAndThrowAnErrorIfExists(result);

        const categories: ICategoryClassificator[] = await result.body;

        dispatch(
            categoryListLoadingSuccessAction(
                mapCategories(
                    categories.filter((item) => !item.dependency /* root elements */),
                    categories,
                ),
                categories,
            ),
        );
    } catch (err) {
        dispatch(categoryListLoadingErrorAction(err.toString()));
    }
};

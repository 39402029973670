import React from 'react';

export const YoutubePlayer = ({ videoId, autoplay }: { videoId: string; autoplay?: boolean }) => {
    return (
        <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}${autoplay ? '?autoplay=1' : ''}`}
            loading="lazy"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Youtube Player"
        />
    );
};

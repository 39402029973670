import { changeCountry } from './customerLocationActions';

import { AppDispatch } from '@/hooks/store';
import { IApplicationStore } from '@/reducers/rootReducer';
import { getCountryCodeFromState } from '@/selectors/getCountryCodeFromState';
import { AVAILABLE_DELIVERY_COUNTRIES } from '@/shared/constants';
import { Location } from '@/types/location';
import { URLParams } from '@/types/URLParams';
import { detectLocation, getQueryParameterFromURL } from '@/utils';

export const INIT_GEOLOCATION_LOADING = 'INIT_GEOLOCATION_LOADING' as const;
export const INIT_GEOLOCATION_SUCCESS = 'INIT_GEOLOCATION_SUCCESS' as const;
export const INIT_GEOLOCATION_ERROR = 'INIT_GEOLOCATION_ERROR' as const;

const geoLoading = () => ({
    type: INIT_GEOLOCATION_LOADING,
});

const geoLoadingSuccess = (data: Location & { country: { long_name: string; short_name: string } }) => ({
    type: INIT_GEOLOCATION_SUCCESS,
    data,
});

const geoLocationError = (error: unknown) => ({
    type: INIT_GEOLOCATION_ERROR,
    error,
});

export type GeoLocationActions = ReturnType<typeof geoLoading | typeof geoLoadingSuccess | typeof geoLocationError>;

export const initGeolocation = () => async (dispatch: AppDispatch, getState: () => IApplicationStore) => {
    dispatch(geoLoading());

    const defaultCountryCode = getCountryCodeFromState(getState());

    const countryFromQuery =
        getState().classificatorReducer.countryClassificatorMappedByCountryCode[
            getQueryParameterFromURL(URLParams.CUSTOMER_LOCATION)?.toUpperCase()
        ];

    let data: Location = undefined;

    try {
        data = await detectLocation();
    } catch (error) {
        console.error('at geolocationActions in initGeolocation', error);

        dispatch(geoLocationError(error.toString()));
    } finally {
        let code = countryFromQuery?.code ?? data?.countryCode ?? defaultCountryCode;

        if (!AVAILABLE_DELIVERY_COUNTRIES.includes(code)) {
            code = defaultCountryCode;
            data = undefined;
        }

        dispatch(changeCountry(countryFromQuery?.code ?? data?.countryCode ?? defaultCountryCode));
        dispatch(
            geoLoadingSuccess({
                ...data,
                country: {
                    long_name: data?.countryName ?? defaultCountryCode,
                    short_name: data?.countryCode ?? defaultCountryCode,
                },
            }),
        );
    }
};

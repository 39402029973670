import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { resetAlertAction } from '@/actions/alertActions';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { noop, stopPropagation } from '@/utils';

import './Alert.less';

const APP_NAME = process.env.APP_NAME;

export const MSAlert: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { title, text, callback } = useAppSelector((state) => state.alertReducer);

    const isOpen = text !== '';

    const closeHandler = () => {
        callback?.();
        dispatch(resetAlertAction());
    };

    return (
        <Transition show={isOpen} as={Dialog} onClose={noop}>
            <Transition.Child
                enter="fade-in"
                enterFrom="fade-in-from"
                enterTo="fade-in-to"
                leave="fade-in"
                leaveFrom="fade-in-to"
                leaveTo="fade-in-from"
                as={Fragment}
            >
                <div className="ms-alert-overlay" onClick={stopPropagation} />
            </Transition.Child>
            <Transition.Child
                enter="slide-in"
                enterFrom="slide-in-from"
                enterTo="slide-in-to"
                leave="slide-in"
                leaveFrom="slide-in-to"
                leaveTo="slide-in-from"
                as={Fragment}
            >
                <div className="ms-alert">
                    <div className="ms-alert-content">
                        <div className="ms-alert-content-title">{title || APP_NAME}</div>
                        <div className="ms-alert-content-description">{text}</div>
                    </div>
                    <div className="ms-alert-actions">
                        <button className="ms-alert-actions-button" onClick={closeHandler}>
                            {t('OK')}
                        </button>
                    </div>
                </div>
            </Transition.Child>
        </Transition>
    );
};

import { Col, List } from 'framework7-react';
import React, { useCallback, useMemo } from 'react';

import { DESKTOP_MENU_ITEMS } from './constants';

import { DesktopStepsMenuProps } from './DesktopStepsMenu.types';

import { SideMenu } from '@/components/SideMenu';
import { SideMenuItem } from '@/components/SideMenu/SideMenuItem/SideMenuItem.types';
import { ImportProductsSteps } from '@/pages/ImportProducts/ImportProducts.types';

import './DesktopStepsMenu.less';

export const DesktopStepsMenu = React.memo(
    ({ currentStep, setCurrentStep, validSteps }: DesktopStepsMenuProps): JSX.Element => {
        const stepMenuItems = useMemo<SideMenuItem[]>(
            () =>
                DESKTOP_MENU_ITEMS.map((item) => ({
                    ...item,
                    active: item.step === currentStep,
                    disabled:
                        (!validSteps.get(currentStep) && item.step !== currentStep) ||
                        (!validSteps.get(item.step) && !validSteps.get(item.step - 1)),
                    valid: validSteps.get(item.step),
                })),
            [currentStep, validSteps],
        );

        const onMenuItemClick = useCallback(
            (step: React.ReactText) => setCurrentStep(step as ImportProductsSteps),
            [setCurrentStep],
        );

        return (
            <Col className="import-steps-desktop-menu">
                <List className="import-steps-desktop-menu-steps">
                    <SideMenu items={stepMenuItems} onItemClick={onMenuItemClick} />
                </List>
            </Col>
        );
    },
);
DesktopStepsMenu.displayName = 'DesktopStepsMenu';

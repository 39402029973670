import React from 'react';

export enum PopoverPositions {
    TOP_CENTER = 'top-center',
    BOTTOM_CENTER = 'bottom-center',
    RIGHT_CENTER = 'right-center',
    LEFT_CENTER = 'left-center',
    TOP_LEFT_CORNER = 'top-left-corner',
    BOTTOM_LEFT_CORNER = 'bottom-left-corner',
    TOP_RIGHT_CORNER = 'top-right-corner',
    BOTTOM_RIGHT_CORNER = 'bottom-right-corner',
}

// ! Don't change the order
export const allAvailablePopoverPositions = [
    PopoverPositions.BOTTOM_CENTER,
    PopoverPositions.BOTTOM_LEFT_CORNER,
    PopoverPositions.TOP_CENTER,
    PopoverPositions.RIGHT_CENTER,
    PopoverPositions.LEFT_CENTER,
    PopoverPositions.TOP_LEFT_CORNER,
    PopoverPositions.TOP_RIGHT_CORNER,
    PopoverPositions.BOTTOM_RIGHT_CORNER,
];

export interface PopoverChildRect {
    height: number;
    width: number;
}

export interface CustomPopoverProps<ChildrenType extends HTMLElement> {
    parentRef?: React.MutableRefObject<ChildrenType>;
    children: JSX.Element | JSX.Element[];
    availablePositions?: PopoverPositions[];
    isTriangleShown?: boolean;
    isWrappingStyled?: boolean;
    childrenRect?: PopoverChildRect;
    className?: string;
    opened?: boolean;
    onPopoverContainerClick?: () => void;
}

import React from 'react';

import { MenuProps } from './Menu.types';
import { MenuButton } from './MenuButton';

import './Menu.less';

export const Menu = React.memo<MenuProps>(
    ({ handleLeftButtonClick, handleRightButtonClick }): JSX.Element => (
        <ul className="datepicker-menu">
            <li>
                <MenuButton direction="left" onClick={handleLeftButtonClick} />
            </li>
            <li>
                <MenuButton direction="right" onClick={handleRightButtonClick} />
            </li>
        </ul>
    ),
);
Menu.displayName = 'Menu';

import cn from 'classnames';
import { Button, F7Popup, F7Sheet, Link, Navbar, NavRight, Popup, Sheet } from 'framework7-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SetupPayoutModalProps } from './SetupPayoutModal.types';

import { IcBankAccount, IcClose } from '@/assets';
import { useAppSelector } from '@/hooks/store';
import { getIsMobile } from '@/selectors/getIsMobile';
import { Popups } from '@/types/popups';
import { Sheets } from '@/types/sheets';

import './SetupPayoutModal.less';

export const SetupPayoutModal = React.memo<SetupPayoutModalProps>(({ className, ...props }) => {
    const { t } = useTranslation();

    const isMobile = useAppSelector(getIsMobile);

    const [Component, selector] = useMemo<[React.ComponentClass<F7Popup.Props | F7Sheet.Props>, string]>(
        () => (isMobile ? [Sheet, Sheets.SETUP_PAYOUT_SHEET] : [Popup, Popups.SETUP_PAYOUT_POPUP]),
        [isMobile],
    );

    return (
        <Component {...props} backdrop className={cn(selector, 'setup-payout-modal', className)}>
            <Navbar noShadow noHairline className="setup-payout-modal__navbar">
                <NavRight>
                    <Link popupClose={`.${selector}`} sheetClose={`.${selector}`}>
                        <IcClose />
                    </Link>
                </NavRight>
            </Navbar>
            <div className="setup-payout-modal__content">
                <IcBankAccount />
                <h1>{t('Set up your payouts')}</h1>
                <p>{t('To receive your earns, please setup your payout profile')}</p>
                <Button fill round href="/profile/seller-area/payout-settings/">
                    {t('Set Up')}
                </Button>
            </div>
        </Component>
    );
});
SetupPayoutModal.displayName = 'SetupPayoutPopup';

import { normalizeYear } from '@/utils/dateTime/normalizeYear';

const CARD_REGEXP = /^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/;

export const isValidCardExpDate = (value: string /* MM/YY */) => {
    const match = CARD_REGEXP.exec(value);
    if (!match) {
        return false;
    }
    const exp = new Date(normalizeYear(1 * parseInt(match[2])), 1 * parseInt(match[1]) - 1, 1).valueOf();
    const now = new Date();
    const currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
    return exp >= currMonth;
};

import React from 'react';
import { useField } from 'react-final-form';

import { DateRangePickerFieldProps } from './DateRangePickerField.types';

import { DateRangePicker } from '@/components/DatePickers/DateRangePicker';

export const DateRangePickerField = ({ startName, endName, ...props }: DateRangePickerFieldProps) => {
    const {
        input: { onChange: startDateCb, value: startDate },
    } = useField<Date>(startName);
    const {
        input: { onChange: endDateCb, value: endDate },
    } = useField<Date>(endName);
    return (
        <DateRangePicker
            endDate={endDate}
            endDateCb={endDateCb}
            startDate={startDate}
            startDateCb={startDateCb}
            {...props}
        />
    );
};
